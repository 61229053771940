import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import MessageItem from './MessageItem.react';

export default createReactClass({

	getDefaultProps () {
		return {
			messages: []
		};
	},

	componentDidMount () {
		this._scrollToBottom();
	},

	componentDidUpdate () {
		this._scrollToBottom();
	},

	shouldComponentUpdate (prevProps) {
		return prevProps.isIdle !== this.props.isIdle ||
			prevProps.messages.length !== this.props.messages.length ||
			this.didUpdateMessages(prevProps.messages, this.props.messages);
	},

	// checks if order of messages changed
	didUpdateMessages (prevMessages, messages) {
		return prevMessages.filter( (msg, i) => {
			return msg.id !== messages[i].id;
		}).length > 0;
	},

	render () {
		return (
			<div className="dialog-list-container" ref="messageList">

				<ul className="dialog-list">
					{this.props.messages.map( message => {
						var mr = Math.random()*1000;
						return (
							<MessageItem
								message={message}
								messageType={message.messageType}
								isRead={message.isRead}
								key={message.id || mr}
								messageId={message.id || mr}
								relative={message.isMostRecentMessage}
								isMostRecentMessage={message.isMostRecentMessage}
							/>
						)
					})}
				</ul>
			</div>
		)
	},

	animate(ul) {
		let st = ul.scrollTop, sh = ul.scrollHeight;
		if(!ul) return;
		if ((Math.floor(sh - st) > ul.clientHeight)) {
			let ratio = Math.round(sh/st);
			ul.scrollTop += (10 * ratio);
			requestAnimationFrame(() => {
				this.animate(ul);
			});
		}
	},

	_scrollToBottom () {
		const ul = ReactDOM.findDOMNode(this.refs.messageList).parentNode.parentNode.parentNode;
		let addToHeight = 0;

		if (!ul) return;

		// if we're at the top.. scroll down.
		if (ul.scrollTop === 0) {
			ul.scrollTop = ul.scrollHeight;
		}

		let domLastInserted = ReactDOM.findDOMNode(this.refs.messageList).firstChild.lastChild;
		if (domLastInserted) {
			addToHeight = domLastInserted.offsetHeight;
		}

		// scroll down only when in ~ bottom
		if( (ul.scrollHeight - ul.scrollTop)-(100+addToHeight) < ul.clientHeight) {
			requestAnimationFrame(() => {
				this.animate(ul);
			});
		}
	}

});
