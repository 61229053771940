export default function () {
	const model = {
		weekStart: null
	};

	const service = {
		update: update,
		get: get
	};
	return service;

	function get() {
		return model;
	}

	function update(m) {
		if (m.weekStart) {
			model.weekStart = m.weekStart;
		}
	}
}
