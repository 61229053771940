export default {
	'en-GB': {
		dashboard: {
			HEADER: 'Dashboard',
			STATISTICS_IS_DELAYED_TIP: 'The data fields that appear greyed out unfortunately has a delay of approximately',
			YOUR_APPLICATIONS: 'Let\'s start working',
			LAST_UPDATE: 'Last update',
			VISITORS_ON_SITE: 'Visitors on site',
			VISITORS_WAITING: 'Visitors waiting for dialog',
			VISITORS_IN_DIALOG: 'Active dialogs',
			COMPLETED_DIALOGS_TODAY: 'Completed dialogs today',
			AGENTS_IN_DESKTOP: 'Agents in desktop',
			myStats: {
				TODAY: 'Today',
				YESTERDAY: 'Yesterday',
				'7DAYS': '7 days',
				'30DAYS': '30 days',
				DIALOGS: 'Dialogs',
				SUCCESS: 'Success',
				CASES_CLOSED: 'Cases closed',
				AVG_DIALOG_TIME: 'Avg dialog time'
			},
			usersOnline: {
				HEADER: 'Agents in desktop',
				VISITORS_IN_DIALOGS: 'Active dialogs',
				AGENTS: 'Agents',
				NO_AGENTS: 'No agents in desktop'
			},
			dialogUsage: {
				HEADER: 'Dialogs this month',
				DATE: 'Date',
				TOTAL: 'Total'
			},
			userUsage: {
            	// Not currently in use
				HEADER: 'Agent usage this month',
				USERS: 'Users',
				DATE: 'Date'
			},
			groupOverview: {
				HEADER: 'Group overview',
				AVG_WAITING_TIME: 'Average waiting time',
				LAST_15_MIN: '(last 15 min)',
				QUEUE: 'Group',
				ONLINE_AGENTS: 'Online agents in desktop',
				VISITORS_IN_QUEUE: 'Visitors in queue',
				VISITORS_IN_DIALOG: 'Active dialogs',
				AVG_DIALOG: 'Average dialog',
				AVG_WAITING: 'Avg. waiting time',
				AVG_WAITING_TIME_HELP: 'Last 15 minutes',
				LONGEST_WAITING: 'Longest waiting time',
				LONGEST_DIALOG: 'Longest dialog time',
				SUMMARY: 'Summary:',
				THRESHOLD_EXCEEDED: 'The threshold values for this queue have been exceeded',
				MAX_ALLOWED_USERS_EXCEEDED: 'The allowed amount of visitors in queue ({{amount}}) has been exceeded',
				MAX_ALLOWED_WAITING_TIME_EXCEEDED: 'The max allowed waiting time in queue ({{maxTime}}) has been exceeded',
				LONGEST_TIME_IN_QUEUE_HELP: 'Current visitor in queue',
				LONGEST_DIALOG_TIME_HELP: 'Active dialogs'
			},
			casesAndVisits: {
				// Not currently in use
				HEADER: 'Total visits',
				LAST_MONTH: 'Last month',
				THIS_MONTH: 'This month'
			}
		}
	},
	'x-sv-SE': {
		dashboard: {
			HEADER: 'Dashboard',
			STATISTICS_IS_DELAYED_TIP: 'The data fields that appear greyed out unfortunately has a delay of approximately',
			YOUR_APPLICATIONS: 'Let\'s start working',
			LAST_UPDATE: 'Last update',
			VISITORS_ON_SITE: 'Visitors on site',
			VISITORS_WAITING: 'Visitors waiting for dialog',
			VISITORS_IN_DIALOG: 'Active dialogs',
			COMPLETED_DIALOGS_TODAY: 'Completed dialogs today',
			AGENTS_IN_DESKTOP: 'Agents in desktop',
			myStats: {
				TODAY: 'I dag',
				YESTERDAY: 'I går',
				'7DAYS': '7 dagar',
				'30DAYS': '30 dagar',
				DIALOGS: 'Dialogs',
				SUCCESS: 'Success',
				CASES_CLOSED: 'Cases closed',
				AVG_DIALOG_TIME: 'Avg dialog time'
			},
			usersOnline: {
				HEADER: 'Agents in desktop',
				VISITORS_IN_DIALOGS: 'Active dialogs',
				AGENTS: 'Agents',
				NO_AGENTS: 'No agents in desktop'
			},
			dialogUsage: {
				HEADER: 'Dialogs this month',
				DATE: 'Date',
				TOTAL: 'Total'
			},
			userUsage: {
				// Not currently in use
				HEADER: 'Agent usage this month',
				USERS: 'Users',
				DATE: 'Date'
			},
			groupOverview: {
				HEADER: 'Group overview',
				AVG_WAITING_TIME: 'Average waiting time',
				LAST_15_MIN: '(last 15 min)',
				QUEUE: 'Group',
				ONLINE_AGENTS: 'Online agents in desktop',
				VISITORS_IN_QUEUE: 'Visitors in queue',
				VISITORS_IN_DIALOG: 'Active dialogs',
				AVG_DIALOG: 'Average dialog',
				AVG_WAITING: 'Avg. waiting time',
				AVG_WAITING_TIME_HELP: 'Last 15 minutes',
				LONGEST_WAITING: 'Longest waiting time',
				LONGEST_DIALOG: 'Longest dialog time',
				SUMMARY: 'Summary:',
				THRESHOLD_EXCEEDED: 'The threshold values for this queue have been exceeded',
				MAX_ALLOWED_USERS_EXCEEDED: 'The allowed amount of visitors in queue ({{amount}}) has been exceeded',
				MAX_ALLOWED_WAITING_TIME_EXCEEDED: 'The max allowed waiting time in queue ({{maxTime}}) has been exceeded',
				LONGEST_TIME_IN_QUEUE_HELP: 'Current visitor in queue',
				LONGEST_DIALOG_TIME_HELP: 'Active dialogs'
			},
			casesAndVisits: {
				// Not currently in use
				HEADER: 'Total visits',
				LAST_MONTH: 'Last month',
				THIS_MONTH: 'This month'
			}
		}
	},
	'fr-FR': {
		dashboard: {
			HEADER: 'Tableau de bord',
			STATISTICS_IS_DELAYED_TIP: 'Les champs grisés ont malheureusement un retard d\'environ',
			YOUR_APPLICATIONS: 'Commençons ',
			LAST_UPDATE: 'Dernière mise à jour',
			VISITORS_ON_SITE: 'Visiteurs sur le site',
			VISITORS_WAITING: 'Visiteurs en attente d\'une boîte de dialogue',
			VISITORS_IN_DIALOG: 'Active dialogs',
			COMPLETED_DIALOGS_TODAY: 'Dialogues terminés aujourd\'hui',
			AGENTS_IN_DESKTOP: 'Agents au bureau',
			myStats: {
				TODAY: 'Aujourd\'hui',
				YESTERDAY: 'Hier',
				'7DAYS': '7 jours',
				'30DAYS': '30 jours',
				DIALOGS: 'Dialogues',
				SUCCESS: 'Succès',
				CASES_CLOSED: 'Dossiers clos',
				AVG_DIALOG_TIME: 'Durée moyenne de dialogue'
			},
			usersOnline: {
				HEADER: 'Agents sur le bureau',
				VISITORS_IN_DIALOGS: 'Active dialogs',
				AGENTS: 'Agents',
				NO_AGENTS: 'Aucun agent sur le bureau'
			},
			dialogUsage: {
				HEADER: 'Dialogues ce mois-ci',
				DATE: 'Date',
				TOTAL: 'Total'
			},
			userUsage: {
				// Not currently in use
				HEADER: 'Utilisation de l\'agent ce mois-ci',
				USERS: 'Utilisateurs',
				DATE: 'Date'
			},
			groupOverview: {
				HEADER: 'Vue d\'ensemble du groupe',
				AVG_WAITING_TIME: 'Temps d\'attente moyen',
				LAST_15_MIN: '(Depuis 15 min)',
				QUEUE: 'Groupe',
				ONLINE_AGENTS: 'Agents en ligne sur le bureau',
				VISITORS_IN_QUEUE: 'Visiteurs en file d\'attente',
				VISITORS_IN_DIALOG: 'Active dialogs',
				AVG_DIALOG: 'Moyenne des dialogues',
				AVG_WAITING: 'Moy. temps d\'attente',
				AVG_WAITING_TIME_HELP: '15 dernières minutes',
				LONGEST_WAITING: 'Longest waiting time',
				LONGEST_DIALOG: 'Longest dialog time',
				SUMMARY: 'Résumé:',
				THRESHOLD_EXCEEDED: 'Les valeurs limites de cette file d\'attente ont été dépassées',
				MAX_ALLOWED_USERS_EXCEEDED: 'Le nombre de visiteurs autorisés dans la file d\'attente ({{montant}}) a été dépassé',
				MAX_ALLOWED_WAITING_TIME_EXCEEDED: 'La durée totale dans la file d\'attente ({{maxTime}}) a été dépassée',
				LONGEST_TIME_IN_QUEUE_HELP: 'Visiteur actuel dans la file',
				LONGEST_DIALOG_TIME_HELP: 'Active dialogs'
			},
			casesAndVisits: {
				// Not currently in use
				HEADER: 'Total des visites',
				LAST_MONTH: 'Le mois dernier',
				THIS_MONTH: 'Ce mois-ci'
			}
		}
	}
};
