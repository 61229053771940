import _find from 'lodash/find';
import _indexOf from 'lodash/indexOf';

export default function ($q) {
	'ngInject';

	const getAuthorizationRefValue = authorization => {
		// Generate "unique" id refValue for an authorization (they don't have proper id:s!)
		return authorization.resource + '___' + authorization.action;
	};

	return {
		formatAuthorizations: authorizations => {
			return authorizations.map(authorization => {
				return {
					refValue: getAuthorizationRefValue(authorization),
					displayName: authorization.resource + '(' + authorization.action + ')'
				};
			});
		},

		findAuthorizationByRefValue: (authorizations, refValue) => {
			return authorizations.find(authorization => getAuthorizationRefValue(authorization) === refValue);
		},

		formatSelectableDependsOn: function (selectableValues, thisClaim) {
			var formattedValues = [];
			angular.forEach(selectableValues, function (selectableValue) {
				if (selectableValue.claimType !== thisClaim.claimType) {
					formattedValues.push({
						refValue: selectableValue.claimType,
						displayName: selectableValue.displayName
					});
				}
			});
			return formattedValues;
		},

		formatSelectableAccounts: function (selectableValues) {
			var formattedValues = [];
			angular.forEach(selectableValues, function (selectableValue) {
				formattedValues.push({
					refValue: selectableValue.data.id,
					displayName: selectableValue.data.displayName
				});
			});
			return formattedValues;
		},

		// [ TL ] Added a method for updating a roleClaim-list with a specific (possibly updated) roleClaim
		replaceRoleClaim: function (list, replacementObj) {
			return $q(function (resolve, reject) {
				// Replace roleClaim item in systemConfiguration.roleClaims-list
				var foundRoleClaim = _find(list, function (roleClaim) {
					return roleClaim.id === replacementObj.id;
				});
				if (foundRoleClaim) {
					var index = _indexOf(list, foundRoleClaim);
					list.splice(index, 1, replacementObj);
					resolve();
				} else {
					//list.push(replacementObj); // Unknown id - should we add the replacementObj to list instead?
					reject();
				}
			});
		}
	};
}
