import ViewActions from '../app/desktop.OLD/actions/ViewActions';
import VisitorProfileStore from '../app/desktop.OLD/stores/VisitorProfileStore';
import easyrtc from './../../vendor/easyrtc_v1.1.1-rc2/easyrtc';

var APP_NAME = 'vergicChat';
//var SOCKET_URL = 'http://localhost:8989';
//var SOCKET_URL = 'https://rtc.vergic.com';		// [ TL ] Do not hardcode rtc-server. Moved to parameter 'videoStreamingServer' to initApp() below, i.e. handle it in the same way as flash video (use setting "Account/Visitor Behavior/Override default video streaming server" as streaming server for rtc-video also)
var DEFAULT_SOCKET_URL = 'https://rtc.vergic.com';	// [ TL ] Default rtc-server (if no server specified in "Account/Visitor Behavior/Override default video streaming server")

var SOCKET_CONFIG = {
	'force new connection': true,
	'connect timeout': 10000,
	transports: ['websocket']
};



function loginSuccess() {
}

function loginFailure(err){
	if (err === easyrtc.errCodes.MEDIA_ERR) {
		easyrtc.connect(APP_NAME, loginSuccess, connectFailure);
	}
}

function connectFailure(){
	console.error('easyrtc::', 'failed manual easyrtc.connect after Media Error');
}


function appDispose(roomId) {
	var roomToLeave = roomId.replace(/\-/g,'');
	var ms = easyrtc.getLocalStream();

	easyrtc.leaveRoom(
		roomToLeave,
		function leaveRoomSuccess(){
			if (ms) {
				easyrtc.closeLocalMediaStream(ms[0]);
			}
			easyrtc.disconnect();
		},
		function leaveRoomFailure(){easyrtc.disconnect();}
	);

}


//http://stackoverflow.com/questions/23552756/using-easyrtcs-room-occupant-listener-how-do-i-call-other-users-as-soon-as-the
var needToCallOtherUsers;

function roomListener(roomName, otherPeers) {
	var easyrtcid;
	//console.warn('roomListener', roomName, otherPeers);
	if (needToCallOtherUsers) {
		for (easyrtcid in otherPeers) {
			easyrtc.call(
				easyrtcid,
				function success(otherCaller, mediaType) {
					console.log('success: ', otherCaller, mediaType);
				},
				function failure(errorCode, errorMessage) {
					console.log('failure: ', errorCode, errorMessage);
				},
				function(accepted, bywho) {
					console.log((accepted ? 'accepted' : 'rejected') + ' by ' + bywho);
				}
			);
		}
		needToCallOtherUsers = false;
	}
}

function getVideoEl (easyrtcid){
	var role = easyrtc.idToName(easyrtcid);
	if (role === 'agent' || role === 'guest') {
		role = 'other';
	}

	return document.getElementById(role + 'Video');
}


function setVideoSrc(easyrtcid, stream){
	var el = getVideoEl(easyrtcid);
	if (el) {
		if (!stream) {
			stream = '';
		}
		easyrtc.setVideoObjectSrc(el, stream);
		var count = easyrtc.getConnectionCount();
		document.getElementById('videowrap').classList.toggle('v-zoom-v', count === 1);
	} else {
		console.info('element for ', easyrtc.idToName(easyrtcid), ' does not exist');
	}
}
var isStarted = false;
function initApp(roomId, role, videoStreamingServer) {
	videoStreamingServer = videoStreamingServer || psPlugin.application.configuration.visitor.videoStreamingServer || DEFAULT_SOCKET_URL;

	var roomToJoin = roomId.replace(/\-/g,'');

	function connectFailure(err){
		console.info('easyrtc::', 'failed manual easyrtc.connect after Media Error', err);
		if (err === easyrtc.errCodes.MEDIA_ERR) {
			easyrtc.connect(APP_NAME, loginSuccess, function(errCode){console.error('fatal error: ', errCode)});
		}
	}

	function connectSuccess(){
		//Send actual video conversationMessage
		if (role === 'agent'){
			const roomKey = VisitorProfileStore.getTurnServerCredentials(roomId);
			easyrtc.setCredential({
				'password': roomKey,
				'room': roomId
			});

			easyrtc.sendServerMessage('roomCredentials', {room: roomToJoin, roomKey: roomKey},
				function(type, data){
					console.info('success', type, data);
					// Message Was Successfully Sent to Server and a response was received
					// with a the data available in the (data) variable.

				}, function(code, message) {
					console.info('error', type, data);
					// Something went wrong with sending the message... To be safe you
					// could disconnect the client so you don't end up with an orphaned
					// user with no metadata.

				}
			);

			//let serverIce = easyrtc.getServerIce();
			//console.log('serverIce', serverIce);

			ViewActions.sendVideoChatInvitation(roomId);
		}
		console.info('Successfully connected to signaling server');

	}

	//easyrtc.enableDebug(true);
	//console.info('initApp', role, roomId, easyrtc.getConnectStatus());


	if (easyrtc.getConnectStatus() === easyrtc.NOT_CONNECTED) {

		if (role) {
			if (!easyrtc.setUsername(role) ){
				console.info('that user role was already registered');
				//return;
			}
		}

		easyrtc.setOnError( function(errEvent) {
			console.error('easyrtc::' + errEvent.errorCode, errEvent.errorText);
		});

		easyrtc.setSocketUrl(videoStreamingServer, SOCKET_CONFIG);
		easyrtc.setRoomEntryListener(function(entry, roomName) {
			if (entry){
				needToCallOtherUsers = true;
			}
		});
		easyrtc.setRoomOccupantListener(roomListener);
		easyrtc.joinRoom(roomToJoin, {roomAutoCreateEnable: true},
			function(){console.log('RTC: joined room');},
			function(err) {console.warn('RTC: err joining room', err);}
		);



		//when we're receiving an incoming stream
		easyrtc.setStreamAcceptor(setVideoSrc);
		easyrtc.setOnStreamClosed(setVideoSrc);


		// make camera and audio non-mandatory.
		// easyrtc.setAutoInitUserMedia(true);

		easyrtc.initMediaSource(
			function(){
				var selfVideo = document.getElementById('selfie');
				easyrtc.setVideoObjectSrc(selfVideo, easyrtc.getLocalStream());
				easyrtc.connect(APP_NAME, connectSuccess, connectFailure);
			},
			connectFailure
		);

		//easyrtc.setDisconnectListener( function() {
		//	easyrtc.showError('LOST-CONNECTION', 'Lost connection to signaling server');
		//});

		//easyrtc.addEventListener('roomOccupants', function(eventName, eventData) {
		//	console.info('easyrtc::event:roomOccupants', eventName, eventData);
		//});

	}


}
export default {
	start: initApp,
	leave: appDispose
};
