/*eslint curly:0 */
import helpers from '../jsExt';
import Notification from './notification';

var psForm = function (params) {


	if (typeof params === 'undefined') {
		throw 'no params';
	}
	this.validations = {
		email: /^(?:[a-z0-9!#$%&'*+\/=?\^_`{|}~-]\.?){0,63}[a-z0-9!#$%&'*+\/=?\^_`{|}~-]@(?:(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)*[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\])$/i,
		url: /^(https?|ftp|rmtp|mms):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i
	};
	var callback = null;
	var onValidate = null;
	var onSuccess = null;
	var formObj = this;
	var cssBase = 'psForm';
	var template;
	var container = document.createElement('form');
	container.className = cssBase;
	container.setAttribute('action', '');
	container.setAttribute('autocomplete', '');
	container.setAttribute('method', 'POST');
	container.setAttribute('onsubmit', 'return false;');
	this.close = function () {
		delete this.elem;
	}; // ?? valid scope?
	this.elem = container;
	this.formElems = {};
	this.autofocusEl = false; // since there can be only one element that has autofocus per document
	var labels = {};
	var data = {};
	this.setData = function (params) {

		var i, l;

		// var s = {};
		// console.log('FORM incoming data',params.data);
		// for (var d in params.data) {
		// 	if (typeof params.data[d] === 'object') {
		// 		for (var sd in params.data[d]) {
		// 			data[sd] = params.data[d][sd];
		// 		}
		// 	} else {
		// 		data[d] = params.data[d];
		// 	}
		// }
		// console.log('FORM processed data', data);

		data = params.data;


		//console.log('FORM data', data);
		(params.id) ? container.setAttribute('id', params.id): false;
		if (typeof params.className !== 'undefined') cssBase += ' ' + params.className;
		//container.setAttribute('id', ((typeof params.id !== 'undefined') ? params.id : psPlugin.core.jsExt.guid()));

		//TODO check this
		if (typeof params.onValidate === 'function') onValidate = params.onValidate;
		callback = (typeof params.callback === 'undefined') ? 'alert(\'action missing\');': params.callback;
		onSuccess = (typeof params.onSuccess === 'undefined') ? 'alert(\'onSuccess missing\');': params.onSuccess;
		var templateElems = params.template;

		//for (var n in template[o]) break;
		//if (n === 'structured') { templateElems=templateElems[n];}
		if (psPlugin.core.jsExt.isArray(templateElems)) {
			for (i = 0, l = templateElems.length; i < l; i++) {
				//console.log('FORM ELEM', i, ' data:', data);
				var res = this.createNode(templateElems[i], data, labels);
				container.appendChild(res.node);
				this.formElems = psPlugin.core.jsExt.extendObj(this.formElems, res.elems);
			}
		}
		/*else {
	for (var tmpl in templateElems) {
		if (typeof templateElems[tmpl] === 'function') continue;
		var res = helpers.createNode(templateElems[tmpl], data, labels);
		container.appendChild(res.node);
		this.formElems = psPlugin.core.jsExt.extendObj(this.formElems, res.elems);
	}
};
*/

		var btnContainer = document.createElement('div');
		btnContainer.className = 'psPlugin-btn-row psForm-buttons';

		if (params.buttons) {
			var btn, bEl;
			//for (var b in params.buttons) {
			for (i = 0, l = params.buttons.length; i < l; i++) {
				btn = params.buttons[i];

				if (btn.type === 'submit') {

					bEl = document.createElement('input');
					bEl.setAttribute('type', 'submit');
					bEl.value = btn.label;
					bEl.className = btn.className || '';
					// if no text on button, hide it (Form can be submitted by this.submitForm());
					if (btn.buttonText === '') bEl.style.display = 'none';
					btnContainer.appendChild(bEl);

					bEl.onclick = this.submitForm;

				} else {

					bEl = document.createElement('input');
					bEl.setAttribute('type', 'button');
					bEl.value = btn.label;
					bEl.className = btn.className || '';
					// if no text on button, hide it (Form can be submitted by this.submitForm());
					if (btn.label === '' || typeof btn.label === 'undefined') bEl.style.display = 'none';
					btnContainer.appendChild(bEl);

				}
			}

		} else {

			//adding the button
			var btnSave = document.createElement('input');
			btnSave.setAttribute('type', 'submit');
			btnSave.value = params.buttonText;

			// if no text on button, hide it (Form can be submitted by this.submitForm());
			if (params.buttonText === '') btnContainer.style.display = 'none';

			btnSave.onclick = this.submitForm;
			btnContainer.appendChild(btnSave);

		}

		container.appendChild(btnContainer);

	};
	this.setRelations = function () {
		var key, el;

		for (key in this.formElems) {

			el = this.formElems[key];
			if (typeof el === 'function') continue;
			if (typeof el.related === 'object') {
				var relatedElem = this.formElems[el.related.to] || false;
				if (!relatedElem) continue;
				var relatedValue = (psPlugin.core.jsExt.isFunction(relatedElem.value)) ? relatedElem.value(): relatedElem.value;
				el.options.length = 0;
				el.setChildren(el.related.callFunc(relatedValue));
				if (data[key]) el.value = data[key];

				//TODO: this needs to be fixed!!
				relatedElem.relatedTo = el;
				relatedElem.onchange = function () {
					this.relatedTo.options.length = 0;
					this.relatedTo.setChildren(this.relatedTo.related.callFunc(this.value));
				};
			}
		}
	};

	this.getData = function () {
		var res = {}, key, el;
		for (key in this.formElems) {
			el = this.formElems[key];
			res[key] = (psPlugin.core.jsExt.isFunction(el.value)) ? el.value(): el.value;
			if (!res[key]) res[key] = '';
		}
		return res;
	};

	this.submitForm = function (e) {
		if (e && e.preventDefault) e.preventDefault();
		if (formObj.validate()) {
			if (psPlugin.core.jsExt.isFunction(onValidate) === true && !onValidate(formObj.getData())) return false;
			if (psPlugin.core.jsExt.isFunction(callback) === true) {
				callback(formObj.getData());
				return true;
			}
		} else {
			//console.log('form is not valid');
			return false;
		}
	};

	this.getAsHtml = function () {
		//console.log('LABELS', labels);
		var data = formObj.getData();
		var str = '';
		for (var l in data) {
			//console.log(l);
			if (typeof data[l] === 'function') continue;
			//str += labels[l] + ':<b> ' + data[l] + '</b></br>';
			str += labels[l] + ': *' + data[l] + '*  \n';
		}
		return str;
	};

	if (params) {
		this.setData(params);
		this.setRelations();
	} else { params = {}; }
};

psForm.prototype.validate = function () {

	var key, el,
		valid = true;

	for (key in this.formElems) {

		el = this.formElems[key];

		var value = (psPlugin.core.jsExt.isFunction(el.value)) ? el.value(): el.value;
		if (el.validation !== false) {
			if (typeof this.validations[el.validation] !== 'undefined') {
				el.valid = this.validations[el.validation].test(value);
			} else { el.valid = true; }
		} else { el.valid = true; }

		/* REQUIRED*/
		if ((el.required === 'required' || el.required === true) && value === '') {
			helpers.addClass(el, 'required');
			el.valid = false;
		} else {
			helpers.removeClass(el, 'required');
		}

		//set valid/invalid classes
		if (el.valid === false) {
			helpers.addClass(el, 'invalid');
			helpers.removeClass(el, 'valid');
		} else {
			helpers.addClass(el, 'valid');
			helpers.removeClass(el, 'invalid');
		}
	}

	//loop valid items
	for (key in this.formElems) {

		el = this.formElems[key];

		if ((el.valid === false)) {

			if (typeof el.errorMessage !== 'undefined') {
				setTimeout(function () {
					var errMsg = new Notification({
						title: el.errorMessage.header,
						content: el.errorMessage.content,
						showAt: el
					});
				}, 300);
			}

			el.focus();
			//el.scrollIntoView();
			valid = false;
			return valid;
		}
	}
	return valid;
};

/*
 * -----------------------------------------------------------
 * Create Nodes
 * -----------------------------------------------------------
 */
psForm.prototype.createNode = function (nodeObj, data, labels) {
	var node  = null,
		elems = {},
		label,
		labelText,
		subLabel,
		subLbl,
		subNode;

	//convert to lowercase to avoid configuration issues
	nodeObj.type = nodeObj.type.toLowerCase();

	if (nodeObj.label && nodeObj.type !== 'section') {

		if (nodeObj.type !== 'radiogroup' && nodeObj.type !== 'checkboxgroup') {
			label = document.createElement('label');
			label.innerHTML = nodeObj.label;
			//label.style.display = 'block';
		} else {
			label = document.createElement('div');
			subLabel = document.createElement('label');
			subLabel.innerHTML = nodeObj.label;
			label.appendChild(subLabel);
		}

	} else { label = null; }

	labels[nodeObj.name] = nodeObj.label;

	switch (nodeObj.type) {

		case 'section':
			var sectionContainer = document.createElement('fieldset');
			helpers.addClass(sectionContainer, 'formSection');
			(nodeObj.id) ? sectionContainer.setAttribute('id', nodeObj.id): false;
			//var formElems = {};
			if (nodeObj.label) {
				var title = document.createElement('legend');
				title.innerHTML = nodeObj.label;
				sectionContainer.appendChild(title);
			}
			for (var f = 0; f < nodeObj.childElements.length; f++) {
				var res = this.createNode(nodeObj.childElements[f], data, labels);
				sectionContainer.appendChild(res.node);
				for (var o in res.elems) {
					elems[o] = res.elems[o];
				}
			}
			node = sectionContainer;
			break;

		case 'text':
		case 'email':
		case 'tel':
		case 'url':
		case 'date':
		case 'number':
		case 'password':
		case 'hidden':
		case 'color':

			node = document.createElement('input');
			node.setAttribute('type', nodeObj.type);
			if (typeof nodeObj.autocomplete !== 'undefined') node.setAttribute('autocomplete', nodeObj.autocomplete);

			//set autofocus, but only on the first element
			//according to the spec: http://dev.w3.org/html5/spec/attributes-common-to-form-controls.html#autofocusing-a-form-control
			if (nodeObj.autofocus && this.autofocusEl === false) {
				this.autofocusEl = true; //could also be set to node to keep a reference for polyfills...
				node.setAttribute('autofocus', 'autofocus');
			}

			node.setAttribute('name', nodeObj.name);
			(nodeObj.placeholder) ? node.setAttribute('placeholder', nodeObj.placeholder): false;
			node.value = (typeof nodeObj.value === 'undefined') ? '': nodeObj.value;
			if (nodeObj.type === 'url' || nodeObj.type === 'email') { nodeObj.validation = nodeObj.type; }
			if (nodeObj.type === 'number') {
				(typeof nodeObj.min === 'undefined') ? false: node.setAttribute('min', nodeObj.min);
				(typeof nodeObj.max === 'undefined') ? false: node.setAttribute('max', nodeObj.max);
				(typeof nodeObj.step === 'undefined') ? false: node.setAttribute('step', nodeObj.step);
			}
			if (typeof nodeObj.readonly !== 'undefined') node.setAttribute('readonly', nodeObj.readonly);

			//add classNames to text fields...
			if (nodeObj.type !== 'hidden' && nodeObj.type !== 'color') {
				var cls = nodeObj.className || '';
				node.className = cls + ' psForm-textfield';
			}

			elems[nodeObj.name] = node;

			break;


		case 'textarea':
		case 'code':
			node = document.createElement('textarea');
			node.setAttribute('name', nodeObj.name);
			node.value = (typeof nodeObj.value === 'undefined') ? '': nodeObj.value;
			//node.rows = (typeof nodeObj.rows === 'undefined') ? 2 : nodeObj.rows;
			if (typeof nodeObj.rows !== 'undefined') node.setAttribute('rows', nodeObj.rows);
			if (typeof nodeObj.cols !== 'undefined') node.setAttribute('cols', nodeObj.cols);
			//node.cols = (typeof nodeObj.cols === 'undefined') ? 1 : nodeObj.cols;


			if (typeof nodeObj.autocomplete !== 'undefined') node.setAttribute('autocomplete', nodeObj.autocomplete);
			if (typeof nodeObj.autocorrect !== 'undefined') node.setAttribute('autocorrect', nodeObj.autocorrect);
			if (typeof nodeObj.spellcheck !== 'undefined') node.setAttribute('spellcheck', nodeObj.spellcheck);

			(nodeObj.placeholder) ? node.setAttribute('placeholder', nodeObj.placeholder): false;

			//add classNames to text fields...
			node.className += ' psForm-textarea';

			elems[nodeObj.name] = node;
			break;

		case 'select':
		case 'multiselect':
			node = document.createElement('select');
			(nodeObj.type === 'multiselect') ? node.setAttribute('multiple', 'multiple'): false;
			node.setAttribute('name', nodeObj.name);
			node.elems = {};
			if (nodeObj.type !== 'multiselect') {
				var optn = document.createElement('option');
				(nodeObj.placeholder) ? optn.text = nodeObj.placeholder: optn.text = '';
				optn.value = 'null';
				optn.disabled = 'disabled';
				optn.selected = 'selected';
				node.options.add(optn);
				node.elems[nodeObj.name] = optn;
			}
			// TODO: break out of HTML node add a level of javascript actions above the html element ex node.elem and node.setChildren
			node.setChildren = function (childElements) {
				//for (var opt in childElements) {
				for (var opt = 0, l = childElements.length; opt < l; opt++) {
					//console.log('select option:', childElements[opt]);
					var optn = document.createElement('option');
					optn.value = childElements[opt].value;
					//if (typeof data[nodeObj.name] !== 'undefined' && childElements[opt].value === data[nodeObj.name]) opt.selected = 'selected';
					optn.text = childElements[opt].label;
					this.options.add(optn);
					this.elems[opt] = optn;
				}
			};
			//console.log('select option:',data[nodeObj.name]);

			//add classNames to text fields...
			node.className += ' psForm-select';


			node.setChildren(nodeObj.childElements);
			elems[nodeObj.name] = node;
			break;

		case 'radiogroup':
			node = document.createElement('div');
			node.elems = {};
			//for (var c in nodeObj.childElements) {
			for (var c = 0, l = nodeObj.childElements.length; c < l; c++) {
				labelText = document.createElement('span');
				labelText.innerHTML = nodeObj.childElements[c].label;

				subLbl = document.createElement('label');

				subNode = document.createElement('input');
				subNode.setAttribute('type', 'radio');
				subNode.setAttribute('name', nodeObj.name);
				subNode.setAttribute('value', nodeObj.childElements[c].value);

				subLbl.style.display = 'block';
				subLbl.appendChild(subNode);
				subLbl.appendChild(labelText);

				node.elems[nodeObj.childElements[c].label] = subNode;
				node.appendChild(subLbl);
			}

			node.value = function (opt) {
				var c;
				if (opt) {
					for (c in this.elems) {
						if (c === opt || this.elems[c].value === opt) {
							this.elems[c].checked = true;
							return this.elems[c].value;
						}
					}
				} else {
					for (c in this.elems) { if (this.elems[c].checked) return this.elems[c].value; }
				}
				return '';
			};

			node.value((typeof nodeObj.value === 'undefined') ? '': nodeObj.value);
			elems[nodeObj.name] = node;
			break;

		case 'checkboxgroup':
			node = document.createElement('div');

			node.elems = {};
			//for (c in nodeObj.childElements) {
			for (var c = 0, l = nodeObj.childElements.length; c < l; c++) {
				labelText = document.createElement('span');
				labelText.innerHTML = nodeObj.childElements[c].label;

				subLbl = document.createElement('label');

				/*
		if (nodeObj.elementStack) { (nodeObj.elementStack === 'horizontal') ? subLbl.style.display = 'inline' : false; }
		subLbl.innerHTML = nodeObj.childElements[c].label;
		*/

				subNode = document.createElement('input');
				subNode.setAttribute('type', 'checkbox');
				subNode.setAttribute('name', nodeObj.name);
				subNode.setAttribute('value', nodeObj.childElements[c].value);
				//subLbl.appendChild(subNode);
				//console.log('checkbox', nodeObj);

				subLbl.style.display = 'block';
				subLbl.appendChild(subNode);
				subLbl.appendChild(labelText);

				node.appendChild(subLbl);
				node.elems[nodeObj.childElements[c].label] = subNode;
			}

			node.value = function (opt) {
				//console.log('CHECKBOX node.value', opt);
				var res = [], c;
				if (opt) {
					if (typeof opt === 'string') opt = [opt];
					for (c in this.elems) {
						if (psPlugin.core.jsExt.inArray(c, opt) || psPlugin.core.jsExt.inArray(this.elems[c].value, opt)) {
							this.elems[c].checked = true;
							res.push(this.elems[c].value);
						}
					}
				} else {
					for (c in this.elems) { if (this.elems[c].checked) { res.push(this.elems[c].value); } }
				}
				return res;
			};

			node.value((typeof nodeObj.value === 'undefined') ? '': nodeObj.value);
			elems[nodeObj.name] = node;
			break;
		default:
			node = document.createElement('input');
			node.setAttribute('type', nodeObj.type);
			node.setAttribute('name', nodeObj.name);
			if (nodeObj.value) node.setAttribute('value', nodeObj.value);
			if (nodeObj.onclick) node.setAttribute('onclick', nodeObj.onclick);
			if (nodeObj.placeholder) node.setAttribute('placeholder', nodeObj.placeholder);
			elems[nodeObj.name] = node;
			break;
	}

	if (nodeObj.id) node.setAttribute('id', nodeObj.id);

	if (nodeObj.description) node.setAttribute('desc', nodeObj.description);

	//if (nodeObj.disabled) node.setAttribute('disabled', 'disabled');
	if (typeof nodeObj.disabled !== 'undefined' && nodeObj.disabled === true) {
		node.setAttribute('disabled', 'disabled');
	}

	if (nodeObj.onchange) node.setAttribute('onchange', nodeObj.onchange);

	if (typeof nodeObj.required !== 'undefined') {
		node.required = nodeObj.required;
	}

	node.validation = (typeof nodeObj.validation === 'undefined') ? false: nodeObj.validation;

	// error message
	if (typeof nodeObj.errorMessage !== 'undefined') node.errorMessage = nodeObj.errorMessage;

	// related
	if (typeof nodeObj.related === 'object') node.related = nodeObj.related;

	// set data
	if (typeof data[nodeObj.name] !== 'undefined') {
		(psPlugin.core.jsExt.isFunction(node.value)) ? node.value(data[nodeObj.name]): node.value = data[nodeObj.name];
	}


	//wrap label+input in div.psForm-unit
	var nNode;
	if (label) {
		nNode = document.createElement('DIV');
		nNode.className = 'psForm-unit';
		label.className = 'psForm-label';
		nNode.appendChild(label);
		nNode.appendChild(node);
	} else {
		nNode = node;
	}


	return {
		label: label,
		elems: elems,
		node: nNode
	};
};

export default psForm;
//psForm
/*
 * EX:
 *  var formConfig = {
		name: '', // required
		type: 'section',  // email | tel | url | date | number | password | text | hidden | section | select | option | textarea | radiogroup | multiselect | checkboxrequired: true, // default: true
		disabled: false, // default: false
		required: false, // false,
		errorMessage:{
			header:'some header',
			content:'some content'
		},
		label: 'my cool section label', // optional
		pattern: 'reg exp', // optional
		description: 'can be HTML to provide rich display', // optional
		placeholder: '', // optional
		elementStack: 'vertical', // vertical (default) | horizontal  - only available for radioGroup, multiSelect and section
		value: '', // optional value
		childElements: [ // optional
			{
				name: 'name',
				type: 'text',
				label: 'Name',
				placeholder: 'Please type in your name',
			}, {
				name: 'gender', // maps to your key
				type: 'select',
				label: 'Gender',
				childElements: [{label: 'Male',value: '0'}, {label: 'Female',value: '1'}]
			}
		]
	};
 */
