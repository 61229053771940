import AccountConfig from './AccountConfig';
import Browser from './../constants/Browser';
import VideoRole from './../constants/VideoRole';
import VideoLib from '../reducers/libs/videoLib';
import easyrtcFactory from './../../../../vendor/easyrtc_v1.1.1-rc2/easyrtc_factory';

const easyrtc = easyrtcFactory.newEasyRTC();

const getConnectionStatus = easyrtcid => {
	return easyrtc.getConnectStatus(easyrtcid);
};

const isPeerConnected = easyrtcid => {
	const status = getConnectionStatus(easyrtcid);
	return status === easyrtc.IS_CONNECTED;
};

const getRoomsJoined = () => {
	return easyrtc.getRoomsJoined();
};

const isInRoom = roomId => {
	const rooms = getRoomsJoined();
	const parsedRoomId = VideoLib.parseRoomId(roomId);
	const room = rooms[parsedRoomId];

	return room !== undefined;
};

const getRoleFromId = easyrtcid => {
	return easyrtc.idToName(easyrtcid);
};

const isIdVisitor = easyrtcid => {
	const role = getRoleFromId(easyrtcid);
	return isRoleVisitor(role);
};

const isIdAgent = easyrtcid => {
	const role = getRoleFromId(easyrtcid);
	return isRoleAgent(role);
};

const isRoleVisitor = role => {
	return role === VideoRole.VISITOR;
};

const isRoleAgent = role => {
	return role === VideoRole.AGENT;
};

const getLocalStream = () => {
	return easyrtc.getLocalStream();
};

const getRemoteStreamForId = (easyrtcid, streamName) => {
	return easyrtc.getRemoteStream(easyrtcid, streamName);
};

const getRemoteStreamTracks = easyrtcid => {
	const stream = getRemoteStreamForId(easyrtcid);
	if (stream) {
		return stream.getTracks();
	}

	return [];
};

const getLocalStreamTracks = () => {
	const stream = getLocalStream();
	if (stream) {
		return stream.getTracks();
	}

	return [];
};

const isTrackKindAudio = track => {
	return track.kind === 'audio';
};

const isTrackKindVideo = track => {
	return track.kind === 'video';
};

const connectStreamToElement = (easyrtcid, element) => {
	const stream = getRemoteStreamForId(easyrtcid);
	setVideoObjectSrc(element, stream);
};

const closeLocalStream = () => {
	const localStream = getLocalStream();
	if (localStream) {
		getLocalStreamTracks().forEach(track => track.stop());
		easyrtc.closeLocalMediaStream(localStream[0]);
	}
};

const getSocketConfig = () => {
	return {
		'force new connection': true,
		'connect timeout': 10000,
		transports: ['websocket']
	};
};

const getAppName = () => {
	return 'vergicChat';
};

const getVideoStreamingServerUrl = () => {
	return AccountConfig.getStreamingServerUrl();
};

const callPeer = peerId => {
	return new Promise((resolve, reject) => {
		easyrtc.call(
			peerId,
			(otherCaller, mediaType) => {
				resolve();
			},
			(errorCode, errorMessage) => {
				reject({errorCode, errorMessage});
			},
			(accepted, user) => {
				//resolve();
			}
		);
	});
};

const disconnect = () => {
	easyrtc.disconnect();
};

const setOnError = callback => {
	easyrtc.setOnError(callback);
};

const setRoomOccupantListener = callback => {
	easyrtc.setRoomOccupantListener(callback);
};

const setStreamAcceptor = callback => {
	easyrtc.setStreamAcceptor(callback);
};

const setOnStreamClosed = callback => {
	easyrtc.setOnStreamClosed(callback);
};

const setSocketUrl = (url, config) => {
	easyrtc.setSocketUrl(url, config);
};

const clearMediaStream = element => {
	easyrtc.clearMediaStream(element);
};

const setVideoObjectSrc = (element, stream) => {
	easyrtc.setVideoObjectSrc(element, stream);
};

const enableCamera = enabled => {
	easyrtc.enableCamera(enabled);
};

const enableVideo = enabled => {
	easyrtc.enableVideo(enabled);
};

const enableVideoReceive = enabled => {
	easyrtc.enableVideoReceive(enabled);
};

const enableMicrophone = enabled => {
	easyrtc.enableMicrophone(enabled);
};

const setCredential = credentials => {
	easyrtc.setCredential(credentials);
};

const setIceCandidateFilter = filter => {
	easyrtc.setIceCandidateFilter(filter);
};

const setDisconnectListener = listener => {
	easyrtc.setDisconnectListener(listener);
};

const sendServerMessage = (type, data, successCallback, errorCallback) => {
	easyrtc.sendServerMessage(type, data, successCallback, errorCallback);
};

const leaveRoom = (parsedRoomId, successCallback, errorCallback) => {
	easyrtc.leaveRoom(
		parsedRoomId,
		successCallback,
		errorCallback
	);
};

const joinRoom = (parsedRoomId, settings, successCallback, errorCallback) => {
	easyrtc.joinRoom(
		parsedRoomId,
		settings,
		successCallback,
		errorCallback
	);
};

const connect = (appName, successCallback, errorCallback) => {
	easyrtc.connect(
		appName,
		successCallback,
		errorCallback
	);
};

const initMediaSource = (successCallback, errorCallback) => {
	easyrtc.initMediaSource(
		successCallback,
		errorCallback
	);
};

const enableDebug = enabled => {
	easyrtc.enableDebug(enabled);
};

const peerInfo = () => {
	easyrtc.dumpPeerConnectionInfo();
};

const addStreamToCall = (easyrtcId, streamName, receiptHandler) => {
	easyrtc.addStreamToCall(easyrtcId, streamName, receiptHandler);
};

const setUserName = username => {
	easyrtc.setUsername(username);
};

const isVisitorBrowserSupported = (browserName, browserVer) => {
	return browserName === Browser.CHROME ||
		browserName === Browser.FIREFOX ||
		browserName === Browser.SAFARI ||
		browserName === Browser.EDGE && typeof browserVer === 'string' && parseInt(browserVer.split('.')[0], 10) >= 76; // Also allow Edge versions >= 76 (Chromium based)
};

const isAgentBrowserSupported = () => {
	const userAgent = navigator.userAgent;
	const vendor = navigator.vendor;
	const isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(vendor);
	const isFirefox = /Firefox/.test(userAgent);
	const isSafari = /Safari/.test(userAgent);

	return isChrome ||
		isFirefox ||
		isSafari;
};

export default {
	getAppName,
	getSocketConfig,
	isPeerConnected,
	getConnectionStatus,
	getRoomsJoined,
	isInRoom,
	getRoleFromId,
	isIdVisitor,
	isIdAgent,
	isRoleVisitor,
	isRoleAgent,
	connectStreamToElement,
	getVideoStreamingServerUrl,
	callPeer,
	getRemoteStreamForId,
	getLocalStream,
	getRemoteStreamTracks,
	closeLocalStream,
	isTrackKindAudio,
	isTrackKindVideo,
	disconnect,
	setOnError,
	setRoomOccupantListener,
	setStreamAcceptor,
	setOnStreamClosed,
	setSocketUrl,
	clearMediaStream,
	setVideoObjectSrc,
	enableCamera,
	enableVideo,
	enableVideoReceive,
	enableMicrophone,
	setCredential,
	sendServerMessage,
	leaveRoom,
	joinRoom,
	connect,
	initMediaSource,
	enableDebug,
	peerInfo,
	addStreamToCall,
	setUserName,
	isVisitorBrowserSupported,
	isAgentBrowserSupported,
	setIceCandidateFilter,
	setDisconnectListener
};
