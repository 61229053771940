import immutable from 'immutable';
import {sharedReducerLib} from '../libs/sharedReducerLib';

export function unitTest(state, action) {
	state = immutable.fromJS(action.newState);
	return state;
}

export function unitTestInc(state, action) {
	state = state.update(action.prop, n => n + 1);
	return state;
}

export function receiveVisitors(state, action) {
	// get diff on previous visitors and new
	const incomingVisitors = action.visitors.filter(o => o.state <= 2 && o.state > 0);
	const previousVisitIds = state.get('visitors').map(visitor => visitor.get('visitId'));

	// console.log('prev',previousVisitIds);
	// console.log('incoming',incomingVisitors);
	// console.log('test',incomingVisitors.filter(visitor => !previousVisitIds.contains(visitor.visitId)));
	const newVisitors = incomingVisitors.filter(visitor => !previousVisitIds.contains(visitor.visitId)).map(visitor => visitor.visitId);
	// console.log('new',newVisitors);
	state = state.set('newVisitors', immutable.fromJS(newVisitors));
	return state.set('visitors', immutable.fromJS(incomingVisitors));
}

export function queuePanelPinned(state, action) {
	return state = state.updateIn(['userPreferences', 'queuePanelPinned'], n => !n);
}

export function toggleUserPreferences(state, action) {
	return state.update('displayUserPreferences', n => !n);
}

export function toggleGroupStatus(state, action) {
	return state.update('displayGroupStatus', n => !n);
}

export function setUserPreferences(state, action) {
	state = state.set('userPreferences', immutable.fromJS(action.userPreferences));
	return state;
}

export function setStartCode(state, action) {
	state = state.set('startCode', action.code);
	return state;
}

export function toggleStartCodeView(state, action) {
	state = state.update('displayStartCode', n => !n);
	return state;
}

export function addSelectAnimation(state, action) {
	state = sharedReducerLib.addSelectAnimation(state, action.conversationId, action.startY);
	return state;
}

export function removeSelectAnimation(state, action) {
	state = sharedReducerLib.removeSelectAnimation(state, action.conversationId);
	return state;
}


