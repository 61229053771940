import ViewActions from '../actions/ViewActions';

export default {

	load: function () {

		psPlugin.application.events.onCoWorkerLoaded.subscribe('chat', function (visitor) {
			try {

				visitor.coWorker.chatModule = new psPlugin.application.modules.chat.panel(visitor);
				visitor.coWorker.chatModule.participants = new psPlugin.application.modules.chat.participants(visitor);

				//if (DesktopService.configuration.account.displayVisitorProfile) {
				if (psPlugin.application.configuration.account.displayVisitorProfile) {
					visitor.coWorker.chatModule.visitorProfilePanel = new psPlugin.application.modules.chat.visitorProfilePanel(visitor);
				}

				visitor.coWorker.chatModule.notesPanel = new psPlugin.application.modules.chat.notesPanel(visitor);
			} catch (err) {
				console.log(err);
			}
		});

		psPlugin.application.events.onLockedList.subscribe('chat', function (list) {
			for (var i in list) {
				var visitor = psPlugin.application.visitorHandler.visitors[list[i]];
				try {
					if (typeof visitor.coWorker.chatModule != 'undefined') {
						var typeStatus = visitor.coWorker.chatModule.typeCheck.check();
						if (!typeStatus.notified) {
							ViewActions.participantWriting(visitor.properties.sessionId, typeStatus.status);
						}
					}
				} catch (err) {
					console.log('TODO: chat application, clean up this', err);
				}
			}
		});
	},

	helpers: {

		captureEnter: function (elem, callback) {

			function stopEnterPropagation(e) {
				var evt = (e) ? e: (window.event) ? window.event: null;
				if (evt) {
					var key = (evt.charCode) ? evt.charCode: ((evt.keyCode) ? evt.keyCode: ((evt.which) ? evt.which: 0));
					if (key === 13) {
						if (evt.stopPropagation) { evt.stopPropagation(); } else { evt.cancelBubble = true; }
					}
				}
				return;
			}

			elem.onkeyup = function (e) {
				var evt = (e) ? e: (window.event) ? window.event: null;
				if (evt) {
					var key = (evt.charCode) ? evt.charCode: ((evt.keyCode) ? evt.keyCode: ((evt.which) ? evt.which: 0));
					if (key === 13) {
						if (evt.preventDefault) { evt.preventDefault(); } else { evt.returnValue = false; }
						if (evt.stopPropagation) { evt.stopPropagation(); } else { evt.cancelBubble = true; }
						if (!evt.shiftKey) {
							callback();
						}
						//if (evt.preventDefault) { evt.preventDefault(); } else { evt.returnValue = false; }
					}
				}
				return true;
			};

			elem.onkeydown = stopEnterPropagation;
			elem.onkeypress = stopEnterPropagation;
		},

		blink: function (elem) {

			var newTitle = '?';
			var timeOut = parseInt(psPlugin.application.configuration.account.chatMessagePendingTimeoutForVisitor, 10) || 0;


			var oldTitle = document.title;
			var blinkingElem = function (elem) {
				var timeoutTimer = false;
				var on = true;
				var interval = setInterval(function () {
					if (on) {
						document.title = oldTitle;
						elem.style.opacity = 0.6;
						on = false;
					} else {

						document.title = newTitle;
						elem.style.opacity = '';
						on = true;
					}
				}, 300);
				var stop = function () {
					clearInterval(interval);
					document.title = oldTitle;
					elem.style.opacity = '';
					if (timeoutTimer) {
						clearInterval(timeoutTimer);
						timeoutTimer = false;
					}
				};
				this.stop = stop;

				if (timeOut > 0) {
					timeoutTimer = setInterval(function () {
						stop();
					}, (timeOut * 1000));
				}
			};
			return new blinkingElem(elem);
		},
		writingNowCheck: function (chatInput) {
			var typeCheck = function (chatInput) {
				var response = {
					status: false,
					notified: true
				};
				var check = function () {
					if (typeof chatInput.value === 'undefined' || chatInput.value === '') {
						if (response.status === false) {
							response.notified = true;
						} else {
							response.notified = false;
						}
						response.status = false;
					} else {
						if (response.status === true) {
							response.notified = true;
						} else {
							response.notified = false;
						}
						response.status = true;
					}
					return response;
				};
				this.check = check;
			};
			return new typeCheck(chatInput);
		}
	}
};
