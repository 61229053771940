/**
 * LocalSync
 * simple module to store data in sessionStorage
 */
export default {

	setConversationInput (key, data) {
		sessionStorage.setItem('vngage.composer_'+key, data);
	},

	getConversationInput (key) {
		return sessionStorage.getItem('vngage.composer_'+key);
	},

	clearConversationInput (key) {
		sessionStorage.removeItem('vngage.composer_'+key);
	}
}
