export default function ($q, APIEndpointService) {
	'ngInject';

	return {
		getCases: function (visitId) {
			return $q(function (resolve, reject) {
				APIEndpointService.visitorCases.query({
					visitId: visitId
				}).$promise
					.then(function (result) {
						resolve(result);
					}, function (error) {
						reject(error);
					});
			});
		},

		getNavigation: function (visitId) {
			return $q(function (resolve, reject) {
				APIEndpointService.visitorNavigation.query({
					visitId: visitId
				}).$promise
					.then(function (result) {
						resolve(result);
					}, function (error) {
						reject(error);
					});
			});
		}
	};
}
