import _isString from 'lodash/isString';

export default function () {
	'ngInject';

	function generate() {
		/*jshint bitwise:false */
		return ('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		}));
	}

	function test(s) {
		if (!_isString(s)) {
			return false;
		}
		return /[0-9abcdef]{8}-[0-9abcdef]{4}-[0-9abcdef]{4}-[0-9abcdef]{4}-[0-9abcdef]{12}/i.test(s);
	}


	return {
		test: test,
		generate: generate
	};

};
