export default function(WhiteLabelService) {
	'ngInject';

	return {
		restrict: 'AE',
		replace: true,
		scope: {},
		template: '<a class="logo-container">' +
					'<img class="logo-expanded" name="logo" ng-src="{{logos.urlLogoExpanded}}"/>' +
					'<img class="logo-collapsed" name="logo" ng-src="{{logos.urlLogoCollapsed}}"/>' +
				  '</a>',
		link: function($scope) {
			$scope.logos = WhiteLabelService.getLogoUrls();

			// Implement using observer pattern instead of $watch:ing function WhiteLabelService.getLogoUrls() for better performance
			WhiteLabelService.registerUpdateObserver(whiteLabelConfig => {
				$scope.logos = {
					urlLogoExpanded: whiteLabelConfig.urlLogoExpanded,
					urlLogoCollapsed: whiteLabelConfig.urlLogoCollapsed
				}
			})
		}
	};
};
