export default {
    'en-GB': {
        identityProvider: {
            ADD_HEADER: 'New IDP',
            EDIT_HEADER: 'Edit',
            LIST_HEADER: 'IDP',
            LIST_TITLE: 'List of identity providers for this account',
            LIST_DESCRIPTION: 'Vergic Engage supports many different kinds of login. It\'s possible to use the default identity provider, or a company specific provider.',
            LOGIN_URL: 'Login URL',
            PUBLIC_CERTIFICATE: 'Public certificate',
            SP_CERTIFICATE: 'SP certificate',
            SP_CERTIFICATE_PASSWORD: 'SP certificate password',
            NAME_ID_FORMAT: 'Name id format',
            ADD_ATTRIBUTE_CONDITION: 'Add attribute condition',
            NAME: 'Name',
            DISPLAY_NAME: 'Display name',
            ACTIVE: 'Active',
            VALUE: 'Value'
        }
    },
    'sv-SE': {
        identityProvider: {
            ADD_HEADER: 'New IDP',
            EDIT_HEADER: 'Edit',
            LIST_HEADER: 'IDP',
            LIST_TITLE: 'List of identity providers for this account',
            LIST_DESCRIPTION: 'Vergic Engage supports many different kinds of login. It\'s possible to use the default identity provider, or a company specific provider.',
            LOGIN_URL: 'Login URL',
            PUBLIC_CERTIFICATE: 'Public certificate',
            SP_CERTIFICATE: 'SP certificate',
            SP_CERTIFICATE_PASSWORD: 'SP certificate password',
            NAME_ID_FORMAT: 'Name id format',
            ADD_ATTRIBUTE_CONDITION: 'Add attribute condition',
            NAME: 'Name',
            DISPLAY_NAME: 'Display name',
            ACTIVE: 'Active',
            VALUE: 'Value'
        }
    }
};
