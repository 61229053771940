import React from 'react';
import CopyMessage from './CopyMessage.react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import mmd from '../../../../../common/vngageMarkdown/mmd';
import {useGetTranslation} from './../../LangContext';

const MessageNavigation = (props) => {
	const getTranslation = useGetTranslation();
	const tooltipLink = <Tooltip id="tooltip">{props.txt}</Tooltip>;
	const rawMarkup = mmd('*' + getTranslation('sysMsgNavigation') + '* ' + props.txt);

	return (
		<div>
			<OverlayTrigger placement="top" overlay={tooltipLink}>
				<div className="message lineClampEllipsis" dangerouslySetInnerHTML={{__html: rawMarkup}}/>
			</OverlayTrigger>
			<CopyMessage txt={props.txt}/>
		</div>
	)
};
export default MessageNavigation

