import moment from 'moment-timezone';
export default function() {
    'ngInject';

    var service = {
        formatTimeAllocsForCalendar: formatTimeAllocsForCalendar,
        getDurationHHmm: getDurationHHmm,
        getEndTimeHHmm: getEndTimeHHmm
    };

    function formatTimeAllocsForCalendar(timeAllocs) {
        var formattedAllocs = [];

        var weekMap = getThisWeekMoment();

        for (var i = 0, len = timeAllocs.length; i < len; i++ ) {
            var alloc = timeAllocs[i];

            var allocStartHour = alloc.startTime.split(':')[0];
            var allocStartMin = alloc.startTime.split(':')[1];
            var allocDurationMin = getHHmmAsMinutes(alloc.duration);
            var allocWeekdayNr = parseInt(alloc.dayOfWeek, 7);

            var startMomentObj = weekMap[allocWeekdayNr].clone();
            startMomentObj.hour(allocStartHour);
            startMomentObj.minute(allocStartMin);

            var endMomentObj = startMomentObj.clone();
            endMomentObj.add(allocDurationMin, 'minutes');
            if (endMomentObj.format('d') !== startMomentObj.format('d')) {
                // duration stretches until next day, set 23:59 on the start day
                endMomentObj = startMomentObj.clone();
                endMomentObj.hour('23');
                endMomentObj.minute('59');
            }

            var extraComment = timeAllocs[i].name ?  ' - ' + timeAllocs[i].name : '';

            var allocationTypeMap = {
                0: {
                    title: 'Open' + extraComment,
                    color: '#84D27C',
                    textColor: 'black'
                },
                1: {
                    title: 'Away' + extraComment,
                    color: '#FFFE99',
                    textColor: 'black'
                },
                2: {
                    title: 'Closed' + extraComment,
                    color: '#E8797F',
                    textColor: 'black'
                }
            };

            var allocObj = {
                start: startMomentObj,
                end: endMomentObj,
                allDay: false,
                originalIndex: i
            };

            angular.extend(allocObj, allocationTypeMap[alloc.groupAllocationType]);

            // Since all days are calculated with start on the current day, it's also necessary to add
            // time allocations for the previous (and next?) 7 days to display days before and after the current
            // calculated allocations
            formattedAllocs.push(allocObj);

            var allocObjNextWeek = angular.copy(allocObj);
            allocObjNextWeek.start.add(1, 'week');
            allocObjNextWeek.end.add(1, 'week');
            formattedAllocs.push(allocObjNextWeek);

            var allocObjLastWeek = angular.copy(allocObj);
            allocObjLastWeek.start.subtract(1, 'week');
            allocObjLastWeek.end.subtract(1, 'week');
            formattedAllocs.push(allocObjLastWeek);
        }

        // returns an array with a moment object for each of the week days
        function getThisWeekMoment() {
            var todayWeedayNr = parseInt(moment().format('d'), 10);
            var weekdayDateMap = [];
            for (var j = 0; j < 7; j++) {
                var dayDiff = parseInt(todayWeedayNr, 10) - j;
                var momentDay = moment();

                if (dayDiff > 0) {
                    momentDay.subtract(dayDiff, 'days');
                } else if (dayDiff < 0) {
                    momentDay.add(Math.abs(dayDiff), 'days');
                }
                weekdayDateMap.push(momentDay);
            }
            return weekdayDateMap;
        }

        return formattedAllocs;
    }

    function getDurationHHmm(startTimeDateObj, endTimeDateObj) {
        var start = moment(startTimeDateObj);
        var end = moment(endTimeDateObj);
        var duration = moment.duration(end.diff(start));
        var durationHours = duration.hours().toString().length > 1 ? duration.hours() : '0' + duration.hours();
        var durationMinutes = duration.minutes().toString().length > 1 ? duration.minutes() : '0' + duration.minutes();
        var durationHHmm = durationHours + ':' + durationMinutes;
        return durationHHmm;
    }

    function getEndTimeHHmm(startTimeHHmm, durationHHmm) {
        return moment(startTimeHHmm, 'HH:mm').add(getHHmmAsMinutes(durationHHmm), 'minutes').format('HH:mm');
    }

    function getHHmmAsMinutes(duration) {
        return parseInt(duration.split(':')[0], 10) * 60 + parseInt(duration.split(':')[1], 10);
    }

    return service;
};
