/*jshint camelcase:false */



//mouseenter/mouseleave helper functions,
//used in addEventListener
// source: http://perfectionkills.com/detecting-event-support-without-browser-sniffing/
function isMouseEventSupported(eventName) {
	var el = document.createElement('div');
	eventName = 'on' + eventName;
	var isSupported = (eventName in el);
	if (!isSupported) {
		el.setAttribute(eventName, 'return;');
		isSupported = typeof el[eventName] == 'function';
	}
	el = null;
	return isSupported;
}

function fixMouseEnter(fct) {
	return function (evt) {
		var relTarget = evt.relatedTarget;
		if (this === relTarget || isChildOf(this, relTarget)) return;
		fct.call(this, evt);
	};
}

function isChildOf(parent, child) {
	if (parent === child) return false;
	while (child && child !== parent) child = child.parentNode;
	return child === parent;
}


//TODO: respect padding

function scrollDist() {
	var html = document.getElementsByTagName('html')[0];
	if (html.scrollTop && document.documentElement.scrollTop) {
		return [html.scrollLeft, html.scrollTop];
	} else if (html.scrollTop || document.documentElement.scrollTop) {
		return [
			html.scrollLeft + document.documentElement.scrollLeft,
			html.scrollTop + document.documentElement.scrollTop
		];
	} else if (document.body.scrollTop) {
		return [document.body.scrollLeft, document.body.scrollTop];
	}
	return [0, 0];
}

function getStyle(obj, styleProp) {
	if (obj.currentStyle) {
		return obj.currentStyle[styleProp];
	} else if (window.getComputedStyle) {
		return window.getComputedStyle(obj, null)[styleProp];
	}
	return false;
}

var regex = {

	//does not allow non-latin chars:
	//email: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i

	//allows for non-latin characters, but also (erronously) allows 1-char length of last tld
	email: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
};



var jsExt = {
	purifyURL: function (url) {
		if (typeof ps_purifyURL === 'function') return ps_purifyURL(url);
		return url;
	},
	validateEmailAddress: function (email) {
		return regex.email.test(email);
	},

	// Parse a query string, like a url.
	// return object containing found key:value pairs.
	parseQueryString: function (str) {
		var params = {};
		str = str || window.location.search;
		str.replace(
			new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
			function ($0, $1, $2, $3) {
				params[$1] = $3;
			}
		);
		return params;
	},

	// used for temporarily format banner a.k.a. solution items
	// and prefix them with template name so it doesn't have to be in the text
	prefixBannerNamesWithTemplate: function (item) {
		var str = item.name || '';
		var tpl = item.template || '';

		if (/fixedPosition/i.test(tpl)) {
			tpl = '[tabbanner] ';
		} else if (/takeover/i.test(tpl)) {
			tpl = '[takeover] ';
		} else if (/injected/i.test(tpl)) {
			tpl = '[injected] ';
		} else if (/codeStarter/i.test(tpl)) {
			tpl = '[phone2web] ';
		} else if (/proactive/i.test(tpl)) {
			tpl = '[proactive] ';
		}

		//str = str.replace(/((»|\&raquo\;)\s?)|(\[[\w\s]*\]\s*)/ig, '');
		str = str.replace(/((»|\&raquo\;)\s?)/ig, '');

		return tpl + str;
	},

	/*
	* -----------------------------------------------------------
	* extend objects
	* -----------------------------------------------------------
	*/
	extendObj: function (o1, o2) {
		function object(o) {
			function F() {
			}

			F.prototype = o;
			return new F();
		}

		var o = object(o2),
			key;
		for (key in o1) {
			o[key] = o1[key];
		}
		return o;
	},
	// Create Global "extend" method
	extend: function (obj, extObj) {
		var i;
		if (arguments.length > 2) {
			for (i = 1; i < arguments.length; i++) {
				this.extend(obj, arguments[i]);
			}
		} else {
			for (i in extObj) {
				obj[i] = extObj[i];
			}
		}
		return obj;
	},
	scrollToPos: function (yPos, opts, after) {
		//todo: integrate support opts.el and calculate positions
		//el = (typeof el === 'string') ? document.querySelector(el) : el,
		opts = opts || {};
		var duration = opts.duration || 600,
			threshold = opts.threshold || 100,
			easing = opts.easing || function (pos) {
					return (-Math.cos(pos * Math.PI) / 2) + 0.5;
				},
			startTime = +new Date(),
			finish = startTime + duration,
			startY = isNaN(window.pageYOffset) ? document.documentElement.scrollTop: window.pageYOffset,
			distance = yPos - startY;

		if (Math.abs(distance) < threshold) {
			window.scrollTo(0, yPos);
			return;
		}

		var interval = setInterval(function () {
			var time = +new Date(),
				pos = time > finish ? 1: (time - startTime) / duration;

			window.scrollTo(0, startY + distance * easing(pos));

			if (time > finish) {
				clearInterval(interval);
				opts.after && opts.after();
				after && setTimeout(after, 1);
			}
		}, 15);
	},


	//get elementPosition, including offset and border. Padding is a bit shoddy at the moment
	findPos: function (el) {
		/*jshint boss:true */
		// because of the while ... (el = el.offsetParent) thingy
		var curleft = 0,
			curtop = 0,
			fixed = false,
			scr = el,
			scrDist,
			padX,
			padY;

		while ((scr = scr.parentNode) && scr != document.body) {
			curleft -= scr.scrollLeft || 0;
			curtop -= scr.scrollTop || 0;
			fixed = (getStyle(scr, 'position') === 'fixed');
		}
		if (fixed && !window.opera) {
			scrDist = scrollDist();
			curleft += scrDist[0];
			curtop += scrDist[1];
		}
		do {
			padX = getStyle(el, 'borderLeft');
			padY = getStyle(el, 'borderTop');
			curleft += el.offsetLeft;
			curtop += el.offsetTop;
			if (padX !== '') curleft += parseInt(padX, 10);
			if (padY !== '') curtop += parseInt(padY, 10);

		} while (el = el.offsetParent);
		return [curleft, curtop];
	},

	cursorPosition: function (e) {
		e = e || window.event;
		var cursor = {x: 0, y: 0};
		if (e.pageX || e.pageY) {
			cursor.x = e.pageX;
			cursor.y = e.pageY;
		}
		else {
			var de = document.documentElement;
			var b = document.body;
			cursor.x = e.clientX +
			(de.scrollLeft || b.scrollLeft) - (de.clientLeft || 0);
			cursor.y = e.clientY +
			(de.scrollTop || b.scrollTop) - (de.clientTop || 0);
		}
		return cursor;
	},
	getAbsolutePosition: function (el) {
		/*jshint boss:true */
		// because of the while ... (el = el.offsetParent) thingy
		var top = 0,
			scrollTop = 0,
			left = 0;

		if (el.offsetParent) {
			do {
				top += el.offsetTop;
				scrollTop += parseInt(el.offsetParent ? el.offsetParent.scrollTop: 0, 10);
				left += parseInt(el.offsetLeft, 10);
			} while (el = el.offsetParent);
		}
		return {
			top: (top - scrollTop),
			left: left
		};
	},
	/*
	* -----------------------------------------------------------
	* isFunction
	* -----------------------------------------------------------
	*/
	isFunction: function (fn) {
		return (typeof fn === 'function') ? true: false;
	},

	isArray: function (val) {
		if (!Array.isArray) {
			return Object.prototype.toString.call(val) === '[object Array]';
		}
		return Array.isArray(val);
	},

	byId: function (id) {
		return document.getElementById(id) || false;
	},

	objToArr: function (obj) {
		var a = [];
		for (var k in obj) {
			a.push(obj[k]);
		}
		return a;
	},

	// create fake GUID
	guid: function () {
		/*jshint bitwise:false */
		return ('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 | 0, v = c == 'x' ? r: (r & 0x3 | 0x8);
			return v.toString(16);
		}));
	},
	inArray: function (key, arr) {
		var res = false;
		for (var k in arr) {
			if (key == arr[k]) res = true;
		}
		return res;
	},
	checkForValidMouseOut: function (e, elem) {
		if (!e) e = window.event;
		var reltg = (e.relatedTarget) ? e.relatedTarget: e.toElement;
		if (typeof reltg == 'undefined' || !reltg) return true;
		try {
			while (reltg.tagName != 'BODY') {
				try {
					if (reltg.id == elem.id) {
						return false;
					}
					reltg = reltg.parentNode;
				} catch (err) { }
			}
		} catch (err) {
			return true;
		}
		return true;
		//quirksmode, Supposidly not so good
		// the following lines were never run, thus I deleted them. /bso
	},
	matchUrlPart: function (urls) {
		if (typeof against === 'undefined') {
			against = document.URL || '';
			if (typeof psPlugin.application.URL !== 'undefined') against = psPlugin.application.URL;
		}
		for (var u in urls) {
			if (typeof urls[u] == 'string' && against.indexOf(urls[u]) != -1) return true;
		}
		return false;
	},

	windowSize: function () {
		var w = 0, h = 0;
		if (typeof (window.innerWidth) == 'number') {
			//Non-IE
			w = window.innerWidth;
			h = window.innerHeight;
		} else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
			//IE 6+ in 'standards compliant mode'
			w = document.documentElement.clientWidth;
			h = document.documentElement.clientHeight;
		} else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
			//IE 4 compatible
			w = document.body.clientWidth;
			h = document.body.clientHeight;
		}
		return {height: h, width: w};
	},


	/*	screenSize, with optional minSize parameter.

	Usage:
	psPlugin.shell.jsExt.screenSize() --> actualSize:number (the smallest value of screen.width/height)
	psPlugin.shell.jsExt.screenSize(720) --> true if the actualSize is greater than 721

	Used for checking smallscreen devices.
	some android devices changes width & height depending on device orientation.
	iOS devices does not, hence the check whether width is smaller than height.
	Samsung Galaxy SIII reports portrait dimensions even if in landscape mode.
	*/
	screenSize: function (minSize) {

		var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 0,
			h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0,
			wSize = (w > h) ? w: h,

			sSize = (screen.width > screen.height) ? screen.height: screen.width,
			size = (sSize > wSize) ? wSize: sSize;

		//console.log(size, '...', wSize, sSize);

		//return size if we get no minSize argument
		if (typeof minSize !== 'number') return size;

		//if (size < minSize) psPlugin.shell.console.log('Small screen detected (' + size + 'px).');
		//otherwise return a boolean
		return (size > minSize);
	},

	return2br: function (str) {
		return str.replace(/(\r\n|[\r\n])/g, '<br />');
	},

	htmlEncode: function (str) {
		var el = document.createElement('div');
		if (str) {
			el.innerText = el.textContent = str;
			return el.innerHTML;
		}
		return str;
	},

	htmlDecode: function (str) {
		var el = document.createElement('div');

		if (str) {
			el.innerHTML = str;
			return el.textContent || el.innerText || '';
		}
		return str;
	},
	/*
	* -----------------------------------------------------------
	* createIcon
	* -----------------------------------------------------------
	* Usage:
	* psHelperLib.createIcon('big','title here','user','gray',true);
	* psHelperLib.createIcon('small',false,'user','gray',true);
	* psHelperLib.createIcon('wide','title here','user','gray',true);
	*/
	createIcon: function (type, title, icon, color, shadow) {
		var el;

		icon = icon || 'webpage';
		color = color || 'lightGray';

		if (type === 'small') {
			el = document.createElement('i');
			el.className = 'vngage-url-category vngage-icon-' + icon + ' vngage-bg-' + color;
			return el;
		}

		// container with border
		el = document.createElement('div');
		el.className = 'ps_icon_border ps_icon_' + type + ' vngage-bg-' + color;

		// icon
		var ic = document.createElement('i');
		ic.className = 'vngage-icon vngage-icon-' + icon;
		el.appendChild(ic);

		// title
		var t = document.createElement('span');
		t.className = 'ps_icon_title';
		if (shadow) t.className += ' ps_icon_shadow';
		t.innerHTML = title;
		t.title = title;
		el.appendChild(t);
		return el;
	},

	iconMap: function (id) {
		var c = {};

		switch (id) {

			case 0:
			case 1:
			case 'webpage':
				c.bgColor = 'blue';
				c.className = 'webpage';
				c.id = 1;
				break;

			case 2:
			case 'info':
				c.bgColor = 'lightGray';
				c.className = 'info';
				c.id = 2;
				break;

			case 3:
			case 'product':
				c.bgColor = 'orange';
				c.className = 'product';
				c.id = 3;
				break;

			case 4:
			case 'shoppingcart':
				c.bgColor = 'purple';
				c.className = 'shoppingcart';
				c.id = 4;
				break;

			case 5:
			case 'pay':
				c.bgColor = 'purple';
				c.className = 'pay';
				c.id = 5;
				break;

			case 6:
			case 'shipping':
				c.bgColor = 'red';
				c.className = 'shipping';
				c.id = 6;
				break;

			case 7:
			case 'action':
				c.bgColor = 'gray';
				c.className = 'action';
				c.id = 7;
				break;

			default:
				c.bgColor = 'blue';
				c.className = 'webpage';
				c.id = 1;
				break;
		}

		return c;
	},

	// Cross browser addEventListener
	addEventListener: function (eventName, element, handler) {
		if (!element) throw new Error('The element doesn\'t exist');

		if (element.addEventListener) {
			// currently, only IE and firefox has native support for mouseenter/leave.
			// others gets mouseover/out + check for mouseout events caused by mouseover on child elements.
			if (eventName === 'mouseenter' || eventName === 'mouseleave') {
				if (!isMouseEventSupported(eventName)) {
					switch (eventName) {
						case 'mouseleave':
							eventName = 'mouseout';
							break;
						case 'mouseenter':
							eventName = 'mouseover';
							break;
					}
					handler = fixMouseEnter(handler);
				}
			}
			element.addEventListener(eventName, handler, false);

		} else if (element.attachEvent) {
			element.attachEvent('on' + eventName, handler);
		} else {
			element['on' + eventName] = handler;
		}
	},


	/*Cross browser removeEventListener
*/
	removeEventListener: function (eventName, element, handler) {
		if (!element) throw new Error('The element doesn\'t exist');

		if (element.addEventListener) {
			element.removeEventListener(eventName, handler, false);
		} else if (element.detachEvent) {
			element.detachEvent('on' + eventName, handler);
		} else {
			element['on' + eventName] = null;
		}
	},


	//CSS-related helpers:

	hasClass: function (ele, cls) {
		return ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
	},
	addClass: function (ele, cls) {
		if (!this.hasClass(ele, cls)) ele.className += ' ' + cls;
	},
	removeClass: function (ele, cls) {
		if (this.hasClass(ele, cls)) {
			var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
			ele.className = ele.className.replace(reg, ' ').replace(/\s+/g, ' ');
		}
	},
	getStyle: function (elem) {/* inline style */
		var res = '';
		var styleText = elem.getAttribute('style');
		if (styleText === null) {
			res = '';
			return res;
		}
		if (typeof styleText === 'string') { res = styleText; } else { res = styleText.cssText; }
		if (res.substring(res.length - 1) !== ';') { res += ';'; }
		return res;
	},
	setStyle: function (elem, styleText) {/* inline style */
		if (elem.style.setAttribute) { elem.style.setAttribute('cssText', styleText); } else { elem.setAttribute('style', styleText); }
	},


	dump: function (arr) {
		var dump = function (arr, level) {
			var text = '';
			if (!level) level = 0;

			//The indent given at the beginning of the line.
			var indent = '';
			for (var j = 0; j < level + 1; j++) indent += '    ';

			if (typeof arr === 'object') { //Array/Hashes/Objects
				for (var item in arr) {
					var value = arr[item];

					if (typeof (value) == 'object') { //If it is an array,
						text += indent + '"' + item + '" ...\n';
						text += dump(value, level + 1);
					} else {
						text += indent + '"' + item + '" => "' + value + '"\n';
					}
				}
			} else { //Stings/Chars/Numbers etc.
				text = '===>' + arr + '<===(' + typeof (arr) + ')';
			}
			return text;
		};
		return dump(arr);
	},

	//used for selecting all cells in a table - under statistics
	selectElementContents: function (el) {
		var range, sel;
		if (!el) return false;
		if (document.createRange && window.getSelection) {
			range = document.createRange();
			sel = window.getSelection();
			sel.removeAllRanges();
			try {
				range.selectNodeContents(el);
				sel.addRange(range);
			} catch (e) {
				range.selectNode(el);
				sel.addRange(range);
			}
		} else if (document.body.createTextRange) {
			range = document.body.createTextRange();
			range.moveToElementText(el);
			range.select();
		}
	}
};

export default jsExt;
