import CoBrowsing from '../../actionCreators/CoBrowsing';
import PluginActions from '../../actions/PluginActions';

const actionFormats = {
	setCoBrowsing: {
		conversationId: 'conversationId',
		status: 'string'
	},
	setMeta: {
		meta: 'meta'
	},
	notify: {
		text: 'string',
		showToast: 'boolean'
	}
};

const actionMap = {
	setCoBrowsing: ({conversationId, status}) => dispatch => dispatch(CoBrowsing.setCoBro(conversationId, status)),
	setMeta: ({pluginId, meta}) => (dispatch) => dispatch(PluginActions.setMeta(pluginId, meta)),
	notify: ({pluginId, text, showToast}) => (dispatch) => dispatch(PluginActions.notify(pluginId, text, showToast)),
};

export default {
	actionFormats,
	actionMap
};