import ConnectionActions from './../actions/ConnectionActions';

export default {
	init (restUrl) {
		Offline.options = {
			checks: {
				xhr: {
					url: restUrl+'Command/Noop',
					timeout: 20000
				}
			},

			// Should we check the connection status immediatly on page load.
			checkOnLoad: false,

			// Should we monitor AJAX requests to help decide if we have a connection.
			interceptRequests: true,

			// Should we automatically retest periodically when the connection is down (set to false to disable).
			reconnect: {
				// How many seconds should we wait before rechecking.
				initialDelay: 20,

				// How long should we wait between retries.
				delay: 20
			},

			// Should we store and attempt to remake requests which fail while the connection is down.
			requests: true,

			// Should we show a snake game while the connection is down to keep the user entertained?
			// It's not included in the normal build, you should bring in js/snake.js in addition to
			// offline.min.js.
			game: false
		};

		Offline.on('up', () => {
			//console.log(' --- offline --- ', 'up');
			ConnectionActions.connectionUp();
		});

		Offline.on('down', () => {
			//console.log(' --- offline --- ', 'down');
			ConnectionActions.connectionDown();
		});

		Offline.on('confirmed-up', () => {
			console.log(' --- offline --- ', 'confirmed-up');
		});

		Offline.on('confirmed-down', () => {
			//console.log(' --- offline --- ', 'confirmed-down');
			//ConnectionActions.connectionDown();
		});

		Offline.on('checking', () => {
			//console.log(' --- offline --- ', 'checking');
		});

		Offline.on('reconnect:started', () => {
			//console.log(' --- offline --- ', 'reconnect:started');
		});

		Offline.on('reconnect:stopped', () => {
			//console.log(' --- offline --- ', 'reconnect:stopped');
		});

		Offline.on('reconnect:tick', () => {
			//console.log(' --- offline --- ', 'reconnect:tick');
		});

		Offline.on('reconnect:connecting', () => {
			//console.log(' --- offline --- ', 'reconnect:connecting');
		});

		Offline.on('reconnect:failure', () => {
			//console.log(' --- offline --- ', 'reconnect:failure');
		});

		Offline.on('requests:flush', () => {
			//console.log(' --- offline --- ', 'requests:flush');
		});

		Offline.on('requests:hold', () => {
			//console.log(' --- offline --- ', 'requests:hold');
		});
	}
}
