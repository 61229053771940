export default {
	'en-GB': {
		caseType: {
			ADD_HEADER: 'New case type',
			EDIT_HEADER: 'Edit case type',
			LIST_HEADER: 'Case types',
			LIST_TITLE: 'List of case types for working site',
			LIST_DESCRIPTION: 'Cases are like containers for other items such as action panels, canned responses, closures and more. A case defines the area where the visitor requests assistance e.g. Technical support, Car insurances etc',
			SITE_ID: 'Site ID',
			ACTION_PANELS: 'Action panels',
			CLOSURES: 'Closures',
			FORMS: 'Forms',
			CANNED_RESPONSES: 'Canned responses',
			NAME: 'Name',
			TIME_TO_LIVE_TITLE: 'Time to live',
			TIME_TO_LIVE_HELP: 'Time until the case and its related conversations are deleted.',
			TTL_DAYS: 'Days',
			TTL_HOURS: 'Hours',
			TTL_MINUTES: 'Minutes',
			TTL_SECONDS: 'Seconds',
			IMPORT_HEADER: 'Import case types from a file',

			REMOVE_HEADER: 'Remove customer case "{{ name }}"',
			REMOVE_MESSAGE: 'Note that any existing references to this customer case from groups and solutions also will be removed.'
		}
	},
	'x-sv-SE': {
		caseType: {
			ADD_HEADER: 'New case type',
			EDIT_HEADER: 'Edit case type',
			LIST_HEADER: 'Case types',
			LIST_TITLE: 'List of case types for working site',
			LIST_DESCRIPTION: 'Cases are like containers for other items such as action panels, canned responses, closures and more. A case defines the area where the visitor requests assistance e.g. Technical support, Car insurances etc',
			SITE_ID: 'Site ID',
			ACTION_PANELS: 'Action panels',
			CLOSURES: 'Closures',
			FORMS: 'Forms',
			CANNED_RESPONSES: 'Canned responses',
			NAME: 'Name',
			TIME_TO_LIVE_TITLE: 'Time to live',
			TIME_TO_LIVE_HELP: 'Time until the case and its related conversations are deleted.',
			TTL_DAYS: 'Dagar',
			TTL_HOURS: 'Timmar',
			TTL_MINUTES: 'Minuter',
			TTL_SECONDS: 'Sekunder',
			IMPORT_HEADER: 'Import case types from a file',

			REMOVE_HEADER: 'Remove customer case "{{ name }}"',
			REMOVE_MESSAGE: 'Note that any existing references to this customer case from groups and solutions also will be removed.'
		}
	}
};
