import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import {useGetTranslation} from '../LangContext';

const ConfirmModal = ({title, text, confirmText, confirm, cancel}) => {
	const getTranslation = useGetTranslation();
	return (
		<Modal show={true} onHide={cancel}>
			<Modal.Header closeButton>
				<Modal.Title>
					{title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{text}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="default" onClick={cancel}>{getTranslation('btnCancel')}</Button>
				<Button onClick={confirm}>{confirmText}</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ConfirmModal;
