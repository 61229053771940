import React from 'react';
import QueueSelectedItem from './QueueSelectedItem.react';

const QueueSelectedItems = (props) => {
	const items = props.selectedItemPositions.entrySeq().toArray().map(([conversationId, obj], index) => {
		const startY = obj.get('position');
		const payload = {
			startX: props.startX,
			startY: startY + 50,
			endX: props.endX - props.addX * (index - 1),
			endY: props.endY,
		};
		return (<QueueSelectedItem key={conversationId} {...payload}/>);
	});

	return (
		<div className="selected-items">
			{items}
		</div>
	);
};

QueueSelectedItems.displayName = 'QueueSelectedItems';
export default QueueSelectedItems;

