import immutable from 'immutable';
import {sharedReducerLib} from '../libs/sharedReducerLib';

export function activatePanel(state, action) {
	state = state.set('activePanel', action.conversationId);
	state = state.set('panelAnimating', false);
	return state;
}

export function setPanelPosition(state, action) {
	state = sharedReducerLib.setPanelPosition(state, action.conversationId, action.panelPosition);
	return state;
}

export function showModal(state, action) {
	state = state.set('modal', action.modalType);
	return state;
}

export function hideModal(state, action) {
	state = state.set('modal', null);
	return state;

}

