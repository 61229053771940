import GroupActions from '../actions/GroupActions';
import WebAPIUtilsFilters from '../utils/WebAPIUtilsFilters';
import PollRequest from './PollRequest';

export default class InteractionOverviewRequest extends PollRequest {
	constructor(reqGuid, restUrl) {
		super(reqGuid, restUrl);
	}

	get asRequest() {
		return {
			method: 'get',
			tag: this.guid,
			uri: `${this.restUrl}Statistics/InteractionOverview`
		}
	}

	resolve(response, statusCode) {
		return new Promise((resolve, reject) => {
			if (statusCode > 399) {
				reject(response);
			} else {
				resolve(GroupActions.receiveGroupStatus(WebAPIUtilsFilters.getTrimmedGroupStatus(response)));
			}
		});
	}
}
