import BannerPreviewUrlModalTemplate from './BannerPreviewUrlModalTemplate';

export default function ($scope, $sce, $uibModal, BannerPreview, $window, $state) {
	'ngInject';

	var vm = this;
	vm.preview = BannerPreview;
	vm.urlModal = urlModal;
	vm.trustUrl = trustUrl;


	function urlModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: BannerPreviewUrlModalTemplate,
			controller: 'BannerPreviewUrlModalController as vm'
		});
		modal.result.then(newAddress => {
			BannerPreview.address = newAddress;
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function trustUrl(address) {
		address = /^https?/.test(address) ? address: 'http://' + address;
		return $sce.trustAsResourceUrl(address);
	}

	var stickyElement = document.getElementById('sticky');
	var stickyClass = ' position-fixed';
	var main = document.getElementById('main-container');
	var orginClass = stickyElement.className;

	var onScroll = function () {
		if (stickyElement && $state.$current.name === 'root.engage.banner.edit.css') {
			var scrollTop     = main.scrollTop,
				fromElement   = document.querySelector('.nav.nav-tabs').parentElement,
				elementOffset = fromElement.offsetTop,
				distance      = elementOffset - scrollTop;

			stickyElement.className = orginClass + (distance < -120 ? stickyClass: '');
			responsive(stickyElement, $window);
		}
	};
	var onResize = function () {
		if (stickyElement) {
			responsive(stickyElement, $window);
		}
	};

	angular.element(main).bind('scroll', onScroll);
	angular.element($window).bind('resize', onResize);

	$scope.$on('$destroy', function handler() {
		angular.element(main).unbind('scroll', onScroll);
		angular.element($window).unbind('resize', onResize);
	});
}

function responsive(stickyElement, $window) {
	var fromElement = document.querySelector('.nav.nav-tabs').parentElement;
	var style = fromElement.currentStyle || $window.getComputedStyle(fromElement);
	var padding = parseInt(style.paddingLeft.substring(0, 2)) + parseInt(style.paddingRight.substring(0, 2));
	stickyElement.style.width = (fromElement.offsetWidth - padding) + 'px';
}
