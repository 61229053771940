import systemTranslations from './systemTranslations';
import './account/SystemAccountModule';
import './authorization/SystemAuthorizationModule';
import './role/SystemRoleModule';
import './setting/SystemSettingModule';
import SystemContentTemplate from './SystemContentTemplate';
import SystemMenuTemplate from './SystemMenuTemplate';
import '../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.system', [
    'ui.router',
    'vngageApp.system.account',
    'vngageApp.system.authorization',
    'vngageApp.system.role',
    'vngageApp.system.setting',
	'vngageApp.workspaceTranslation'
])

.constant('systemTranslations', systemTranslations)

.constant('systemModuleConfig', {
    state: 'root.system',
    access: 'System'
})

.config(function($stateProvider, $urlRouterProvider, $translateProvider, systemTranslations, systemModuleConfig, VngageWorkspaceTranslationServiceProvider) {
	'ngInject';
	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(systemTranslations);


    $urlRouterProvider.when('/system/', function (transitionService,
        systemAccountModuleConfig,
        systemAuthorizationModuleConfig,
        systemRoleModuleConfig,
        systemSettingModuleConfig) {
		'ngInject';

        transitionService.navigate([
            systemAccountModuleConfig,
            systemAuthorizationModuleConfig,
            systemRoleModuleConfig,
            systemSettingModuleConfig
        ]);
    });

    $stateProvider.state(systemModuleConfig.state, {
        url: '/system/',
        views: {
            content: {
                templateUrl: SystemContentTemplate
            },
            menu: {
                templateUrl: SystemMenuTemplate
            }
        },
        data: {
            access: systemModuleConfig.access,
            ncyBreadcrumbLabel: 'System'
        }
    });
});
