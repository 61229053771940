import PollRequest from './PollRequest';
import ConversationsStore from '../stores/ConversationsStore';
import ServerActions from '../actions/ServerActions';

export default class ConversationRequest extends PollRequest{
	constructor(reqGuid, restUrl, conversationId) {
		super(reqGuid, restUrl);
		this.conversationId = conversationId;
	}

	get asRequest () {
		return {
			body: {},
			method: 'get',
			tag: this.guid,
			uri: `${this.restUrl}Conversation/${this.conversationId}`
		}
	}

	resolve (response, statusCode) {
		return new Promise((resolve, reject) => {
			if (statusCode >399) {
				console.log('<State ERROR> ', response);
				reject(response);
			} else {
				console.log('<State> ', response);
				ServerActions.receiveAsState(response, this.conversationId);
				resolve('state ok');
			}
		});
	}
}
