import React from 'react';
import Format from '../../utils/Format'
import NavigationHistoryCompact from './NavigationHistoryCompact.react.js';
import NavigationHistoryExpanded from './NavigationHistoryExpanded.react.js';

export default function VisitorMeta({visitorJoined, meta, channel, navigation, displayNavigationHistory, toggleNavigationHistory}) {

	const metaInfo = meta.get('metaInfo') || '';
	if (metaInfo) {
		const device = meta.getIn(['os', 'deviceName']) || channel;
		const {className, style} = Format.asDeviceIcon(device);
		const deviceIcon = <i className={className} style={style}/>;

		const navigationHistory = displayNavigationHistory
			? <NavigationHistoryExpanded navigation={navigation}/>
			: <NavigationHistoryCompact navigation={navigation}/>;

		return (<div>
			<span className="device fadeIn">{deviceIcon}</span>
			<span className="meta">{metaInfo}</span>
			<div className="navigation-history fadeIn" onClick={toggleNavigationHistory}>
				{navigationHistory}
			</div>
			<div className="removeMe_separator"/>
		</div>);
	} else {
		return visitorJoined ? <span className="loading-meta-spinner"/>: <div/>;
	}

};

