export default {
	'en-GB': {
		calendar: {
			menu: {
				RESERVATIONS: 'Reservations',
				OPENING_HOURS: 'Opening hours',
				HOLIDAYS: 'Holidays',
				MY_HISTORY: 'My history'
			},
			LOADING: 'Loading...'
		}
	},
	'sv-SE': {
		calendar: {
			menu: {
				RESERVATIONS: 'Reservationer',
				OPENING_HOURS: 'Öppertider',
				HOLIDAYS: 'Helgdagar',
				MY_HISTORY: 'Min historik'
			},
			LOADING: 'Loading...'
		}
	},
	'fr-FR': {
		calendar: {
			menu: {
				RESERVATIONS: 'Réservations',
				OPENING_HOURS: 'Heures d\'ouverture',
				HOLIDAYS: 'Vacances',
				MY_HISTORY: 'Mon historique'
			},
			LOADING: 'Chargement...'
		}
	}
};
