import SolutionAddModalTemplate from './SolutionAddModalTemplate';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

export default function (APIEndpointService, $uibModal, $state, lists) {
	'ngInject';

	var vm = this;
	vm.solutionList = lists.solutions;

	vm.tableOptions = {
		stateLink: {
			state: 'root.engage.solution.edit',
			property: 'data.id'
		},
		createItem: {
			langKey: 'solution.ADD_HEADER',
			method: addNew
		}
	};
	vm.tableColumns = [{
		header: 'solution.NAME',
		property: 'data.name',
		sortDefault: true,
		useInFilter: true
	}, {
		header: 'GUID',
		property: 'data.id',
		hidden: true,
		useInFilter: true
	}, {
		header: 'solution.GROUP',
		property: 'data.section.groupId',
		transform: getGroupName
	}, {
		header: 'solution.CASE_TYPE',
		property: 'data.section.caseTypeId',
		transform: getCaseTypeName
	}, {
		header: 'solution.BANNER_NAME',
		property: 'data.section',
		transform: getBannerName,
		type: 'label-list'
	}, {
		header: 'solution.OPPORTUNITY_NAME',
		property: 'data',
		transform: getOpportunityName,
		type: 'label-list'
	}];

	function openAddModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: SolutionAddModalTemplate,
			controller: 'SolutionAddModalController as vm',
		});
		modal.result.then(newSolution => {
			vm.solutionList.push(newSolution);
			$state.go('.edit', {id: newSolution.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		$state.go('root.engage.solution').then(openAddModal);
	}

	function getGroupName(guid) {
		return findResourceName(guid, lists.groups);
	}

	function getCaseTypeName(guid) {
		return findResourceName(guid, lists.caseTypes);
	}

	function getBannerName(guid) {
		return findBannerName(guid, lists.banners);
	}

	function getOpportunityName(guid) {
		return findOpportunityName(guid, lists.opportunities);
	}

	function findResourceName(guid, resourceList) {
		var resource = _find(resourceList, function (resource) {
			return resource.data.id === guid;
		});
		return resource ? resource.data.name: '';
	}

	function findBannerName(guid, resourceList) {
		var resource = _map(_filter(resourceList, function (resource) {
			return resource.data.id === guid.bannerId || resource.data.id === guid.bannerIdGroupBusy || resource.data.id === guid.bannerIdGroupClosed || resource.data.id === guid.bannerIdGroupOpen;

		}), function (res) {
			return res.data.name;
		});
		return resource;
	}

	function findOpportunityName(guid, resourceList) {
		var resource = _map(_filter(resourceList, function (resource) {

			return _find(resource.data.section.solutions, function (solution) {
				return guid.id === solution;
			});

		}), function (res) {
			return res.data.name;
		});
		return resource;
	}

}
