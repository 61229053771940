import React from 'react';
import createReactClass from 'create-react-class';
import EventStore from '../stores/EventStore';
import ConversationsStore from '../stores/ConversationsStore';
import VisitorProfileStore from './../stores/VisitorProfileStore';
import HangUp from './HangUp.react';
import TransferToGroup from './TransferToGroup.react';
import BlockParticipant from './BlockParticipant.react';
import StartDialog from './StartDialog.react';
import EndDialog from './EndDialog.react';
import {Button, ButtonToolbar} from 'react-bootstrap';
import ViewActions from '../actions/ViewActions';
import moment from 'moment-timezone';
import _find from 'lodash/find';
import { injectIntl } from 'react-intl';

function getStateFromStores(conversationId, visitId) {
	var conversationReservation = EventStore.getReservationOfConversation(conversationId);
	return {
		groups: EventStore.getGroupStatus() || [],
		conversationState: ConversationsStore.getConversationState(conversationId),
		title: (conversationReservation && conversationReservation.reservation ? conversationReservation.reservation.title : VisitorProfileStore.getTitle(visitId))
	};
}

function parseMessage(message) {
	const re = /<%(.+?)%>/g;
	let cursor = 0,
		title = '',
		meetingStartTime = '',
		match;

	const extractTitle = function(line) {
		title += line != '' ? line.replace(/"/g, '\\"') : '';
		title = (title).replace(/[\r\t\n]/g, ' ').trim();
	};

	while (match = re.exec(message)) {
		meetingStartTime = match[1];
		cursor = match.index + match[0].length;
	}
	extractTitle(message ? message.substr(cursor, message.length - cursor) : '');

	return {
		title: title,
		meetingStartTime: meetingStartTime
	};
}

export default injectIntl(createReactClass({

	getInitialState () {
		return getStateFromStores(this.props.conversationId, this.props.visitId);
	},

	componentDidMount () {
		EventStore.addChangeListener(this._onChange);
	},

	componentWillUnmount () {
		EventStore.removeChangeListener(this._onChange);
	},

	onStartDialog (){
		ViewActions.startSuspendedDialog(this.props.conversationId);
	},

	getCurrentGroupName (groupList, activeGroupId) {
		let theGroup = _find(groupList, group => {
			return group.id === activeGroupId
		});
		return theGroup ? theGroup.name : null;
	},

	render () {
		var conversationState = this.state.conversationState;
		var cid = this.props.conversationId;
		var isStarted = conversationState !== 'suspended' && conversationState !=='pendingStart';
		let meetingStartTime = parseMessage(this.state.title).meetingStartTime;
		if (meetingStartTime && meetingStartTime.length>5) {
			// Assume we have a full time string if meetingStartTime.length>5
			// In that case format it as HH:mm
			// And at the same time convert it to the browser's timezone (maybe should be group's timezone in the future?)
			try {
				meetingStartTime = moment(meetingStartTime).format('HH:mm');
			} catch (err) {}
		}
		const currentGroupName = this.getCurrentGroupName(this.props.groupList, this.props.currentGroupId);
		const shouldDisplay = (meetingStartTime && meetingStartTime.length>0);

		//TODO: render a regular button to enable transfers to the current group
		var hasGroups = !!this.state.groups.length;
		return (
			<ButtonToolbar className="legacy-tool-bar">
				{ shouldDisplay ?
				<div style={{margin: '-3px 5px 3px'}}>
					<strong>{this.props.intl.formatMessage({id: 'meetingStart'})}</strong> {meetingStartTime}
					{ currentGroupName ?
						<span><br/><strong>{this.props.intl.formatMessage({id: 'activeGroupName'})}</strong> {currentGroupName}</span>
					: null }
				</div>
					: null }
				{ isStarted ? '' : <StartDialog conversationId={cid}/>}
				<BlockParticipant conversationId={cid} conversationState={conversationState} />
				{ hasGroups ? <TransferToGroup groups={this.state.groups} conversationId={cid} conversationState={conversationState} currentGroupId={this.props.currentGroupId} /> : ''}
				{ !isStarted ? '' : <EndDialog conversationId={cid} conversationState={conversationState} />}
				{ !isStarted ? '' : <HangUp conversationId={cid} conversationState={conversationState} />}
			</ButtonToolbar>
		)

	},

	_onChange () {
		this.setState(getStateFromStores(this.props.conversationId, this.props.visitId));
	}

}));
