import React from 'react';
import createReactClass from 'create-react-class';

export default createReactClass({
	render () {
		return (
			<ul className="meta-participants">
				{this.props.children}
			</ul>
		)
	}
});
