import React from 'react';
import CopyMessage from './CopyMessage.react';
import Format from '../../../utils/Format';
import mmd from '../../../../../common/vngageMarkdown/mmd';

const MessageNote = (props) => {
	const rawMarkup = mmd(props.txt);
	const strippedMsg = Format.stripMarkdown(props.txt);

	return (
		<div>
			<div className="message" dangerouslySetInnerHTML={{__html: rawMarkup}}/>
			<CopyMessage txt={strippedMsg}/>
		</div>
	)

};
export default MessageNote


