import {Record, Map, List, fromJS} from 'immutable';

export const UserInfoRecord = Record({

	name: '',
	owner: false,
	titleId: '',
	role: ''

}, 'UserInfoRecord');

export const ParticipantRecord = Record({

	id: '',
	userId: '',
	visitId: '',
	connectionState: '',
	state: '',
	info: new UserInfoRecord(),
	addedAuthorizations: new List(),
	removedAuthorizations: new List()

}, 'ParticipantRecord');

export const StreamingRecord = Record({

	video: false,
	audio: false

}, 'StreamingRecord');

export const UIRecord = Record({

	userIsTyping: false,
	isCollapsed: false,
	panelHeaderColor: '',
	activeSections: {},
	unreadMessages: 0,
	oldestMessageRanking: 8,
	inputMenuIsVisible: false,
	currentMessage: '',
	newCaseAfterClose: false,
	emailConversationTranscript: false,
	currentNote: '',
	isMessageCopied: false,
	pendingRemoval: false,
	updateInputText: false,
	selectedClosure: '',
	eligibleUntil: '',
	currentInput: 'input'

}, 'UIRecord')

export const ConversationRecord = Record({

	me: '',
	conversationStatus: '',
	connectionStatus: '',
	refNumber: 0,
	title: '',
	browserInfo: '',
	events: new List(),
	flowState: '',
	localMessages: new List(),
	messages: new List(),
	activities: new List(),
	participants: new Map(),
	idle: null,
	since: '',
	abort: false,
	showMeta: true,
	streaming: new StreamingRecord(),
	inviteOptions: null,
	groupId: '',
	caseId: '',
	visitId: '',
	currentCaseType: '',
	solutionId: '',
	isTyping: false,
	externalRouting: '',
	forms: new List(),
	claimFieldsPatch: new Map(),
	UI: new UIRecord(),

}, 'ConversationRecord');