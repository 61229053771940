import SystemAccountConfigurationController from './SystemAccountConfiguration/SystemAccountConfigurationController';
import SystemAccountDetailsController from './SystemAccountDetails/SystemAccountDetailsController';
import SystemAccountTrustedRolesController from './SystemAccountTrustedRoles/SystemAccountTrustedRolesController';
import TrustRelationshipCreateService from './SystemAccountTrustedRoles/TrustRelationshipCreateService';
import TrustRelationshipRemoveService from './SystemAccountTrustedRoles/TrustRelationshipRemoveService';
import TrustRelationshipService from './SystemAccountTrustedRoles/TrustRelationshipService';
import SystemAccountTrustsController from './SystemAccountTrusts/SystemAccountTrustsController';
import SystemAccountAddModalController from './SystemAccountAddModalController';
import SystemAccountEditController from './SystemAccountEditController';
import SystemAccountListController from './SystemAccountListController';
import systemAccountListTableConfig from './SystemAccountListTableConfig';
import systemAccountTranslations from './SystemAccountTranslations';

import SystemAccountListTemplate from './SystemAccountListTemplate';
import SystemAccountEditTemplate from './SystemAccountEditTemplate';
import SystemAccountDetailsTemplate from './SystemAccountDetails/SystemAccountDetailsTemplate';
import SystemAccountConfigurationTemplate from './SystemAccountConfiguration/SystemAccountConfigurationTemplate';
import SystemAccountTrustsTemplate from './SystemAccountTrusts/SystemAccountTrustsTemplate';
import SystemAccountTrustedRolesTemplate from './SystemAccountTrustedRoles/SystemAccountTrustedRolesTemplate';

import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.system.account', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('SystemAccountConfigurationController', SystemAccountConfigurationController)
	.controller('SystemAccountDetailsController', SystemAccountDetailsController)
	.controller('SystemAccountTrustedRolesController', SystemAccountTrustedRolesController)
	.factory('TrustRelationshipCreateService', TrustRelationshipCreateService)
	.factory('TrustRelationshipRemoveService', TrustRelationshipRemoveService)
	.factory('TrustRelationshipService', TrustRelationshipService)
	.controller('SystemAccountTrustsController', SystemAccountTrustsController)
	.controller('SystemAccountAddModalController', SystemAccountAddModalController)
	.controller('SystemAccountEditController', SystemAccountEditController)
	.controller('SystemAccountListController', SystemAccountListController)
	.value('systemAccountListTableConfig', systemAccountListTableConfig)
	.constant('systemAccountTranslations', systemAccountTranslations)

	.constant('systemAccountModuleConfig', {
		state: 'root.system.account',
		access: 'System/Accounts'
	})

	.config(function ($stateProvider, $urlRouterProvider, $translateProvider, systemAccountTranslations, systemAccountModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(systemAccountTranslations);

		$stateProvider.state(systemAccountModuleConfig.state, {
			url: 'account/',
			controller: 'SystemAccountListController as vm',
			templateUrl: SystemAccountListTemplate,
			data: {
				ncyBreadcrumbLabel: 'Accounts',
				access: systemAccountModuleConfig.access
			},
			resolve: {
				accountList: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.account.query().$promise;
				}
			}
		});
		$stateProvider.state('root.system.account.edit', {
			url: ':id',
			params: {
				account: null,
				currentList: []
			},
			controller: 'SystemAccountEditController as vm',
			templateUrl: SystemAccountEditTemplate,
			resolve: {
				accountId: function ($stateParams) {
					'ngInject';
					return $stateParams.id;
				},
				account: function (APIEndpointService, NotificationService, $stateParams, accountList, VngageUtil, $q) {
					'ngInject';
					if ($stateParams.account) {
						// When a $stateParams.account exists, use that (happens after transition from "Add account" and "edit details")
						return $stateParams.account;
					} else {
						// Fetch account object from REST
						return $q(function (resolve, reject) {
							APIEndpointService.account.tryGet({id: $stateParams.id}).$promise.then(account => {
								// After an account was successfully fetched: Replace the corresponding account object in accountList with the newly fetched object to reflect any recent updates in BE
								VngageUtil.replaceResource(accountList, account);
								resolve(account);
							}, err => {
								NotificationService.error({
									header: 'Error',
									body: 'Could not get account: ' + $stateParams.id
								});
							});
						});
					}
				}
			},
			data: {
				ncyBreadcrumbLabel: 'Edit',
				access: {action: 'GET', resource: 'Account'}
			}
		});

		// DETAILS
		// -------
		$stateProvider.state('root.system.account.edit.details', {
			controller: 'SystemAccountDetailsController as vm',
			templateUrl: SystemAccountDetailsTemplate,
			data: {
				ncyBreadcrumbLabel: 'Details',
				access: {action: 'GET', resource: 'Account'}
			},
		});

		// CONFIGURATION
		// -------------
		$stateProvider.state('root.system.account.edit.configuration', {
			controller: 'SystemAccountConfigurationController as vm',
			templateUrl: SystemAccountConfigurationTemplate,
			data: {
				ncyBreadcrumbLabel: 'Configuration',
				access: {action: 'GET', resource: 'Account'}
			},
			resolve: {
				accountConfiguration: function (APIEndpointService, $stateParams) {
					'ngInject';
					return APIEndpointService.accountConfiguration.get({id: $stateParams.id}).$promise;
				},
				timeZones: function (TimeZoneService) {
					'ngInject';
					return TimeZoneService.query();
				}
			}
		});

		// TRUSTS
		// ------
		$stateProvider.state('root.system.account.edit.trusts', {
			controller: 'SystemAccountTrustsController as vm',
			templateUrl: SystemAccountTrustsTemplate,
			data: {
				ncyBreadcrumbLabel: 'Trusts',
				access: {action: 'GET', resource: 'Account'}
			},
			resolve: {
				trustRelationships: function (APIEndpointService, $stateParams) {
					'ngInject';
					return APIEndpointService.trustRelationship.query({accountId: $stateParams.id}).$promise;
				}
			}
		});

		// TRUSTED ROLES
		// -------------
		$stateProvider.state('root.system.account.edit.trustedRoles', {
			controller: 'SystemAccountTrustedRolesController as vm',
			templateUrl: SystemAccountTrustedRolesTemplate,
			data: {
				ncyBreadcrumbLabel: 'Trusted Roles',
				access: {action: 'GET', resource: 'Account'}
			},
			resolve: {
				trustRelationships: function (APIEndpointService, $stateParams) {
					'ngInject';
					return APIEndpointService.trustRelationship.query({accountId: $stateParams.id}).$promise;
				},
				accountRoleClaim: function (APIEndpointService, $stateParams) {
					'ngInject';
					return APIEndpointService.accountRoleClaim.query({id: $stateParams.id}).$promise;
				}
			}
		});
	});
