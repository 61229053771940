import ConversationActions from './ConversationActions';
import LocalStorage from '../stores/LocalStorageWrapper';

export default {
	setPauseDebugFlag: value => dispatch => {
		LocalStorage.setItem('pauseDebugLogging', JSON.stringify(value));
		dispatch(ConversationActions.setDebugFlag('pauseDebugLogging', value));
	},

	setLogTypeFlag: value => dispatch => {
		const loggingPredicates = ['verbose', 'errors', 'plugins', 'requests', 'none'];
		const debugLogType = loggingPredicates.indexOf(value) !== -1 ? value: 'none';
		LocalStorage.setItem('vngageLogType', debugLogType);
		dispatch(ConversationActions.setDebugFlag('logType', debugLogType));
	}
};
