import React from 'react';
import CopyMessage from './CopyMessage.react';
import {useGetTranslation} from './../../LangContext';

const MessageVisitorProfile = (props) => {
	const getTranslation = useGetTranslation();
	const {messageData} = props;
	const copyText = messageData.claims.map(({type, values}) => `${type}: ${values[0]}`).join('\n');

	const claims = messageData.claims.map(({type, values}, i) => {
		return (
			<span key={i}>
				<strong>{type}:</strong> {values[0]}<br/>
			</span>
		);
	});

	return (
		<span>
			<span className="message">
				<strong>{getTranslation('visitorProfileDataTitle')}<br/> </strong>
				{claims}
			</span>
			<CopyMessage txt={copyText}/>
		</span>
	);
};
// never update
export default React.memo(MessageVisitorProfile, () => true);
