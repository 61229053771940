import _find from 'lodash/find';

const whitelistedProtocols = ['http', 'https'];

/**
 * add parameter 'param' to url
 * @param {string} url
 * @param {string} param
 * @returns {string}
 */
function injectParamToURL(url, param) {
	var urlSplit = url.split('#');
	var paramSplit = param.split('=');
	var paramProp = paramSplit[0];

	var urlParams = urlSplit[0].split('?');
	var misplacedParams = urlParams[0].split('&');
	var paramslist = urlParams.length > 1 ? urlParams[1].split('&'): [];

	// save removed obj for rebuild later
	var urlStart = misplacedParams.splice(0, 1);

	paramslist = paramslist.concat(misplacedParams);

	var found = false;

	for (var i = 0; paramslist.length > i; i++) {
		if (paramslist[i].indexOf(paramProp + '=') === 0) {
			paramslist[i] = param;
			found = true;
		}
	}

	if (!found) {
		paramslist.push(param);
	}

	urlSplit.splice(0, 1);

	// Strip trailing hashish
	while (urlSplit.length > 0 && urlSplit[urlSplit.length - 1] === '') {
		urlSplit.pop();
	}

	return urlStart + (paramslist.length > 0 ? '?' + paramslist.join('&'): '') + (urlSplit.length > 0 ? '#' + urlSplit.join('#'): '');

}

/**
 * remove parameter 'param' from url
 * @param {string} url
 * @param {string} param
 * @returns {string}
 */
function removeParamFromURL(url, param) {
	var urlSplit = url.split('#');
	var paramSplit = param.split('=');
	var paramProp = paramSplit[0];
	var urlParams = urlSplit[0].split('?');
	var misplacedParams = urlParams[0].split('&');
	var paramslist = urlParams.length > 1 ? urlParams[1].split('&'): [];

	// save removed obj for rebuild later
	var urlStart = misplacedParams.splice(0, 1);

	paramslist = paramslist.concat(misplacedParams)

	for (var i = paramslist.length - 1; i >= 0; i--) {
		if (paramslist[i].indexOf(paramProp + '=') === 0) {
			paramslist.splice(i, 1);
		}
	}

	urlSplit.splice(0, 1);

	// Strip trailing hashish
	while (urlSplit.length > 0 && urlSplit[urlSplit.length - 1] === '') {
		urlSplit.pop();
	}

	return urlStart + (paramslist.length > 0 ? '?' + paramslist.join('&'): '') + (urlSplit.length > 0 ? '#' + urlSplit.join('#'): '');
}

/**
 * remove vngage-specific parameters from url
 * @param {string} url
 * @returns {string}
 */
function removeVngageParamsFromURL(url) {
	url = removeParamFromURL(url, 'inDialog');
	url = removeParamFromURL(url, 'vngagetrans');
	return url;
}

/**
 * make the url protocol-relative, eg. //someurl.com/test/
 * @param {string} url
 * @returns {string}
 */
function stripProtocol(url) {
	return url.replace(/^(https?|ftp):\/\//, '//');
}

/**
 * convert url to https://
 * @param url
 * @returns {string}
 */
function makeSecure(url) {
	return url.replace(/^(http:\/\/|\/\/)/, 'https://');
}

function _extractDomainFromUrl(url) {
	if (!url) {
		return '';
	}
	let parts = url.split('://');
	if ((parts.length < 2) || (whitelistedProtocols.indexOf(parts[0].toLowerCase()) === -1)) {
		return '';
	}
	parts = parts[1].split('/')[0];
	parts = parts.split(':')[0];
	parts = parts.split('?')[0];
	parts = parts.split('#')[0];
	parts = parts.split('&')[0]; // Also handle slightly defect urls...
	return parts;
}

function _wildcardMatcher(str, rule) {
	return new RegExp('^' + rule.split('*').join('.*') + '$').test(str);
}

function urlIsWhitelisted(url, domainWhiteList) {
	const domain = _extractDomainFromUrl(url);
	return !!(_find(domainWhiteList, item => {
		return _wildcardMatcher(domain, item);
	}));
}

/**
 * extract origin from an url (no native support in IE, Edge, Safari, Opera)
 * @param urlString
 * @returns {string|null}
 */
function getOriginFromURL(urlString) {
	try {
		if (typeof URL === 'function') {
			const url = new URL(urlString);
			if (url.origin) {
				// Newer Chrome, Firefox and Edge
				return url.origin;
			} else if (url.protocol && url.hostname) {
				// This may work in some older IE (URL and URL.origin is broken in IE 11)
				return url.protocol + "//" + url.hostname + (url.port ? ':' + url.port: '');
			}
		}
	} catch (e) {}

	// If we get here, we have no support for URL or URL.origin or URL.protocol and URL.hostname, or something failed
	// Manually extract origin from urlString...
	const protocolSplit = urlString.split('://');
	if (protocolSplit.length > 1) {
		const protocol = protocolSplit[0];
		const origin = protocolSplit[1].split('/')[0].split('#')[0].split('?')[0].split('&')[0];
		return protocol + '://' + origin;
	}
	return null;
}

const doesUrlExist = async url => {
	if (url.includes('localhost')) {
		return true;
	}

	const request = window.XMLHttpRequest ?
		new XMLHttpRequest():
		new ActiveXObject("Microsoft.XMLHTTP");

	request.open('GET', url, false);

	try {
		await request.send(null);
	} catch (error) {
		console.log('error', error)
		return false;
	}

	return true;
};

const getUrlParameter = (name) => {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
	const results = regex.exec(location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export default {
	injectParamToURL,
	removeParamFromURL,
	removeVngageParamsFromURL,
	stripProtocol,
	makeSecure,
	urlIsWhitelisted,
	getOriginFromURL,
	doesUrlExist,
	getUrlParameter
};
