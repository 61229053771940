import ActivityMessageRecord from './ActivityMessageRecord';
import AskDomUploadMessageRecord from './AskDomUploadMessageRecord';
import ChatMessageRecord from './ChatMessageRecord';
import DomHighlightMessageRecord from './DomHighlightMessageRecord';
import LinkMessageRecord from './LinkMessageRecord';
import MetaMessageRecord from './MetaMessageRecord';
import NavigationMessageRecord from './NavigationMessageRecord';
import NoteMessageRecord from './NoteMessageRecord';
import VideoMessageRecord from './VideoMessageRecord';
import MetadataExchangeRecord from './MetadataExchangeRecord';

export default {
	ActivityMessageRecord: ActivityMessageRecord,
	AskDomUploadMessageRecord: AskDomUploadMessageRecord,
	ChatMessageRecord: ChatMessageRecord,
	DomHighlightMessageRecord: DomHighlightMessageRecord,
	LinkMessageRecord: LinkMessageRecord,
	MetaMessageRecord: MetaMessageRecord,
	NavigationMessageRecord: NavigationMessageRecord,
	NoteMessageRecord: NoteMessageRecord,
	VideoMessageRecord: VideoMessageRecord,
	MetadataExchangeRecord: MetadataExchangeRecord
};
