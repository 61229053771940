export default function($scope, APIEndpointService, $state, VngageUtil, allowedAuthorization) {
    'ngInject';

    var vm = this;
    vm.allowedAuthorization = allowedAuthorization;
    vm.remove = remove;

    function remove() {
        if (VngageUtil.confirmSimple('sure you want to delete?')) {
            APIEndpointService.systemConfAuthorization.remove(vm.allowedAuthorization).$promise.then(function() {
                $state.go('^', null, { reload: true });
            });
        }
    }
};
