import React from 'react';
import {Button} from 'react-bootstrap';
import PanelPlaceholder from './PanelPlaceholder.react';
import {useGetTranslation} from './../LangContext';

const PanelLoading = (props) => {
	const getTranslation = useGetTranslation();
	const {conversationId, latestError, removeConversation, hasError} = props;

	return (
		<div className="loading-content-container">
			<div className="loader-header">
				<div className="loader-dialog-connector">
					<div className="rect1"/>
					<div className="rect2"/>
					<div className="rect3"/>
				</div>
				<span className="title">{getTranslation('preparingDialog')}</span>

			</div>
			{hasError
				? (
					<div className="close-button-container">
						<h3>{getTranslation(latestError.get('langKey'))}</h3>
						<Button
							className="close-button"
							variant="danger"
							onClick={() => removeConversation(conversationId)}
						>{getTranslation('dialogButtonEnd')}</Button>
					</div>
				)
				: <PanelPlaceholder/>}
		</div>
	);
};

PanelLoading.displayName = 'PanelLoading';
export default PanelLoading;

