import React, {useState, useRef} from 'react';
import {Button} from 'react-bootstrap';
import AccountConfigurationWrapper from '../../utils/AccountConfigurationWrapper';
import DebugAnalysis from '../../utils/DebugAnalysis';
import DebugAnalysisModal from './DebugAnalysisModal.react';
import DebugPollStatsModal from './DebugPollStatsModal.react';
import {MyDetailsModal} from './MyDetailsModal';
import StateHistory from '../../utils/StateHistory';
import DebugRequestsModal from './DebugRequestsModal.react';

const DebugAnalysisTab = (props) => {
	const [modalVisible, setModalVisible] = useState(false);
	const [currentModal, setCurrentModal] = useState('');
	const actionText = useRef();
	const searchText = useRef();


	const hideModal = () => {
		setModalVisible(false);
	};

	const showPollModal = () => {
		setModalVisible(true);
		setCurrentModal('poll');
	};

	const showAnalysisModal = () => {
		setModalVisible(true);
		setCurrentModal('account');
	};

	const showRequestsModal = () => {
		setModalVisible(true);
		setCurrentModal('requests');
	};

	const showMyDetailsModal = () => {
		setModalVisible(true);
		setCurrentModal('myDetails');
	};


	const findInStates = () => {
		const actionString = actionText.current.value;
		const searchString = searchText.current.value;
		return props.setPois(DebugAnalysis.findInStates(StateHistory.getEveryThing().history, actionString.toLowerCase(), searchString.toLowerCase(), props.getStateAt));
	};

	const findInActions = () => {
		const actionString = actionText.current.value;
		const searchString = searchText.current.value;
		return props.setPois(DebugAnalysis.findInActions(StateHistory.getEveryThing().history, actionString.toLowerCase(), searchString.toLowerCase()));
	};

	const findErrorActions = () => {
		props.setPois(DebugAnalysis.findErrorActions(StateHistory.getEveryThing().history));
	};

	const findBadConnection = () => {
		props.setPois(DebugAnalysis.findBadConnection(StateHistory.getEveryThing().history));
	};

	const findBlockedUrlsBackend = () => {
		props.setPois(DebugAnalysis.findBlockedUrls(StateHistory.getEveryThing().history));
	};

	const findAllBlockedUrls = () => {
		props.setPois(DebugAnalysis.findInStates(StateHistory.getEveryThing().history, 'since', '"blocked":["', props.getStateAt));
	};

	// const poiStyle = {}
	const poiElements = props.pois.map(poi => <a key={poi} href="#"
												 onClick={() => props.moveToAbsolutePosition(poi)}>{poi} </a>);


	const debugAnalysisModal = modalVisible && currentModal === 'account'
		? <DebugAnalysisModal {...{
			hideModal: hideModal,
			data: DebugAnalysis.deepAnalysis(AccountConfigurationWrapper.configuration)
		}}/>
		: null;

	const debugPollStatsModal = modalVisible && currentModal === 'poll'
		? <DebugPollStatsModal {...{
			hideModal: hideModal,
			moveToAbsolutePosition: props.moveToAbsolutePosition,
			data: DebugAnalysis.getPollData(DebugAnalysis.getPollActions(StateHistory.getEveryThing().history))
		}}/>
		: null;

	const debugRequestsModal = modalVisible && currentModal === 'requests'
		? <DebugRequestsModal{...{
			hideModal: hideModal,
			moveToAbsolutePosition: props.moveToAbsolutePosition,
			data: DebugAnalysis.getRequestsData(StateHistory.getEveryThing().history),
			setPois: props.setPois
		}}/>
		: null;

	const myDetailsModal = modalVisible && currentModal === 'myDetails' && <MyDetailsModal {...{
		hideModal: hideModal,
		moveToAbsolutePosition: props.moveToAbsolutePosition,
		data: DebugAnalysis.findRemovalPoints(StateHistory.getEveryThing().history),
		setPois: props.setPois,
	}}/>;

	const analysisContent = (
		<div style={{width: '100%'}}>
			<span className="badge">action</span>
			<div className="filter-wrapper">
				<input
					ref={actionText}
					type="text"
					className="filter"
					onChange={(event) => props.saveActionText(event.target.value)}
					defaultValue={props.actionText || ''}
				/>
			</div>
			<span className="badge">string</span>
			<div className="filter-wrapper">
				<input
					ref={searchText}
					type="text"
					className="filter"
					onChange={(event) => props.saveSearchText(event.target.value)}
					defaultValue={props.searchText || ''}
				/>
			</div>

			<Button variant="default" className="dark" block onClick={() => findInActions()}>search in actions</Button>
			<Button variant="default" className="dark" block onClick={() => findInStates()}>search in states</Button>
			<br/>
			<Button variant="default" className="dark" block onClick={() => showAnalysisModal()}>account</Button>
			<Button variant="default" className="dark" block onClick={() => showPollModal()}>polls</Button>
			<Button variant="default" className="dark" block onClick={() => showRequestsModal()}>requests</Button>
			<Button variant="default" className="dark" block onClick={() => showMyDetailsModal()}>myDetails</Button>

			<Button variant="default" className="dark" block onClick={() => findErrorActions()}>net errors</Button>
			<Button variant="default" className="dark" block onClick={() => findBadConnection()}>bad connection</Button>
			<Button variant="default" className="dark" block onClick={() => findBlockedUrlsBackend()}>blocked by
				backend</Button>
			<Button variant="default" className="dark" block onClick={() => findAllBlockedUrls()}>all blocked</Button>
			{debugAnalysisModal}
			{debugPollStatsModal}
			{debugRequestsModal}
			{myDetailsModal}
		</div>
	);

	return (
		<div className="debugger-tool-container">
			{analysisContent}
			<div style={{width: '100%'}}>
				{poiElements}
			</div>
		</div>
	);
};

export default DebugAnalysisTab;
