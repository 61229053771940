import React, {useState, useEffect, useRef, useCallback} from 'react';
import {connect} from 'react-redux';
import Conversation from '../../actionCreators/Conversation';
import Navigation from '../../actionCreators/Navigation';
import InputArea from './Message/InputArea.react.js';
import List from './Message/List.react.js';
import VisitorStatus from './Message/VisitorStatus.react';
import UploadButton from './UploadButton.react';
import EmojiButton from './EmojiButton.react';
import EmojiPicker from './EmojiPicker.react';
import NoteButton from './NoteButton.react';
import immutable from 'immutable';

export const Chat = (props) => {
	const [pickerEnabled, setPickerEnabled] = useState(false);

	const [chatText, setChatText] = useState(props.currentMessage);
	const [chatSelection, setChatSelection] = useState([0, 0]);

	const [noteText, setNoteText] = useState(props.currentNote);
	const [noteSelection, setNoteSelection] = useState([0, 0]);

	const {conversationId, typingChatText, typingNoteText} = props;

	const typingChatTextCallback = useCallback((text) => {
		setChatText(text);
		typingChatText(conversationId, text);
	}, [typingChatText, conversationId]);

	const typingNoteTextCallback = useCallback((text) => {
		setNoteText(text);
		typingNoteText(conversationId, text);
	}, [typingNoteText, conversationId]);

	const insertEmoji = (text) => {
		if (props.noteMode) {
			const [selectionStart, selectionEnd] = noteSelection;
			typingNoteTextCallback(noteText.slice(0, selectionStart) + text + noteText.slice(selectionEnd));
			setNoteSelection([selectionStart + text.length, selectionStart + text.length]);
		} else {
			const [selectionStart, selectionEnd] = chatSelection;
			typingChatTextCallback(chatText.slice(0, selectionStart) + text + chatText.slice(selectionEnd));
			setChatSelection([selectionStart + text.length, selectionStart + text.length]);
		}
		togglePicker();
	};

	const selectOrActivate = () => {
		const selectedText = window.getSelection().toString();
		if (!selectedText) {
			props.activatePanel(props.conversationId);
		}
	};

	const togglePicker = () => {
		setPickerEnabled(!pickerEnabled);
	};

	// set focus when picker is closed
	useEffect(() => {
		if (!pickerEnabled) {
			const elem = document.getElementById('input' + conversationId);
			elem.focus();
		}
	}, [pickerEnabled, conversationId]);

	// set text in input from outside, used by plugins
	const savedProps = useRef(props);
	useEffect(() => {
		const prevProps = savedProps.current;
		const nextProps = props;

		if ((nextProps.updateInputText > prevProps.updateInputText)) {
			setChatText(props.currentMessage);
			setChatSelection([0, 0]);
		}
		savedProps.current = props;
	}, [props]);


	useEffect(() => {
		return () => {
			setPickerEnabled(false);
		};

	}, [props.panelPosition]);

	const inputAreaPayload = {
		conversationId: props.conversationId,

		chatSelection,
		setChatSelection,
		typingChatText: typingChatTextCallback,
		chatText,

		noteSelection,
		setNoteSelection,
		typingNoteText: typingNoteTextCallback,
		noteText,
	};

	const disabled = props.connectionStatus === 'terminate';
	const statusCls = props.connectionStatus === 'terminate' ? 'lost': '';

	const cls = `conversation connection-status-${statusCls}`;
	return (
		<div className={cls} onClick={selectOrActivate}>
			{pickerEnabled
				? <EmojiPicker conversationId={props.conversationId} insertEmoji={insertEmoji}
							   togglePicker={togglePicker}/>
				: null}

			<div className="read-write">
				<List conversationId={props.conversationId}/>
				<div className="message-toolbar">
					<div className="message-toolbar-left-box">
						<VisitorStatus {...props}/>
					</div>
					<div className="message-toolbar-right-box">
						{props.uploadEnabled
							? <UploadButton conversationId={props.conversationId} disabled={disabled}/>
							: null}
						<EmojiButton togglePicker={togglePicker} disabled={disabled}/>
						<NoteButton
							noteMode={props.noteMode}
							toggleNoteMode={props.toggleNoteMode}
							conversationId={props.conversationId}
						/>
					</div>
				</div>
				<InputArea {...inputAreaPayload}/>
			</div>
		</div>
	);
};

function mapStateToProps(state, ownProps) {
	const conversationId = ownProps.conversationId;
	const conversation = state.getIn(['conversations', conversationId]) || immutable.Map();
	return {
		connectionStatus: conversation.get('connectionStatus'),
		noteMode: conversation.getIn(['UI', 'currentInput']) === 'note',
		// used in VisitorStatus
		userIsTyping: conversation.getIn(['UI', 'userIsTyping']),
		uploadEnabled: !!conversation.getIn(['capabilities', 'groupFeatures', 'fileSharing', 'enabled']),
		panelPosition: conversation.getIn(['UI', 'panelPosition']),
		currentMessage: conversation.getIn(['UI', 'currentMessage']) || '',
		currentNote: conversation.getIn(['UI', 'currentNote']),
		updateInputText: conversation.getIn(['UI', 'updateInputText']) || 0,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleNoteMode: (conversationId) => dispatch(Conversation.toggleNoteModeWithFocus(conversationId)),
		activatePanel: (conversationId) => dispatch(Navigation.activatePanel(conversationId)),
		typingChatText: (conversationId, msg) => dispatch(Conversation.typingChatText(conversationId, msg)),
		typingNoteText: (conversationId, msg) => dispatch(Conversation.typingNoteText(conversationId, msg)),
	};
}

Chat.displayName = 'Chat';
export default connect(mapStateToProps, mapDispatchToProps)(Chat);
