import moment from 'moment-timezone';

export default function (APIEndpointService, $q, Session, WorkspaceSettingsService) {
		'ngInject';

		var groupOverviewSettings = null;
		var initialized = false;

		function initialize() {
			if (initialized) {
				return $q(function (resolve) {
					resolve();
				});
			}

			return $q(function (resolve) {
				WorkspaceSettingsService.getSections('general').then(generalWorkspaceSettings => {
					if (generalWorkspaceSettings &&
						generalWorkspaceSettings.dashboard &&
						generalWorkspaceSettings.dashboard.groupOverview) {
						groupOverviewSettings = parse(generalWorkspaceSettings.dashboard.groupOverview);
					}
					resolve();
				}, () => {
					resolve();
				}).finally(function () {
					initialized = true;
				});
			});
		}

		function validate(group) {
			var result = {
				maxWaitingTimeMinutesSeconds: null,
				maxNumberOfVisitorsWaitingForDialog: null,
				maxWaitingTimeReached: false,
				maxNumberOfVisitorsWaitingReached: false
			};
/*
			if (!groupOverviewSettings) {
				return result;
			}

			//TODO: this is wrong in dashboard now since we changed backend from MM:SS to Ms
			result.maxWaitingTimeMinutesSeconds = groupOverviewSettings.maxWaitingTime;
			result.maxWaitingTimeReached = group.maxWaitingDurationMs > groupOverviewSettings.maxWaitingTimeMs;
			result.maxNumberOfVisitorsWaitingForDialog = groupOverviewSettings.maxNumberOfVisitorsWaitingForDialog;
			result.maxNumberOfVisitorsWaitingReached = group.visitorsWaitingForDialog > groupOverviewSettings.maxNumberOfVisitorsWaitingForDialog;
*/
			if (groupOverviewSettings && groupOverviewSettings.maxWaitingTime) {
				//TODO: this is wrong in dashboard now since we changed backend from MM:SS to Ms (original comment by Boketoft 2015-02-23 - don't know if it's still valid)
				result.maxWaitingTimeMinutesSeconds = groupOverviewSettings.maxWaitingTime;
				result.maxWaitingTimeReached = group.maxWaitingDurationMs > groupOverviewSettings.maxWaitingTimeMs;
			}

			if (groupOverviewSettings && groupOverviewSettings.maxNumberOfVisitorsWaitingForDialog) {
				result.maxNumberOfVisitorsWaitingForDialog = groupOverviewSettings.maxNumberOfVisitorsWaitingForDialog;
				result.maxNumberOfVisitorsWaitingReached = group.visitorsWaitingForDialog > groupOverviewSettings.maxNumberOfVisitorsWaitingForDialog;
			}

			return result;
		}

		function parse(settings) {
			if (settings && settings.maxWaitingTime) {
				settings.maxWaitingTimeMs = convertToMs(settings.maxWaitingTime);
			}
			return settings;
		}

		function convertToMs(format) {
			if (!format) {
				return 0;
			}
			if (typeof format !== 'string') {
				return format;
			}
			var durations = format.split(':');
			if (durations.length < 2) {
				return 0;
			}

			var minutesMs = +(durations[0]) * 60 * 1000;
			var secondsMs = +(durations[1]) * 1000;

			return minutesMs + secondsMs;
		}

		return {
			initialize: initialize,
			validate: validate
		};
	}
