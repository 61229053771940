import {Store} from '../stores/Store';
import PollManager from '../utils/PollManager'

export function getAsBatchData($restUrl, REQUESTS) {
	const batchSize = 20;
	let allItems = [];
	REQUESTS.forEach(item => allItems.push(item.asRequest));
	// IE .fill fix
	const a = Array.apply(null, new Array(Math.ceil(allItems.length / batchSize))).map(Number.prototype.valueOf, 0);
	return a
		.map((batch, index) =>
			({
				url: $restUrl + 'Batch',
				items: allItems.slice(index * batchSize, (index * batchSize + batchSize))
			})
		);
}

export default function (postFunc, $restUrl, REQUESTS, netId) {
	return new Promise((topResolve, topReject) => {
		let errors = [];

		const posts = getAsBatchData($restUrl, REQUESTS)
			.map(({url, items}) =>
				postFunc(url, JSON.stringify({items}))
					.then(response => {
						const netIdOk = Store.getState().get('netId') <= netId;
						const isValid = PollManager.isValid(netId);
						return netIdOk && isValid ? response.data.items.map(item => REQUESTS.get(item.tag).resolve(item.body, item.statusCode)): [];
					}, err => {
						errors.push({type: 'error', err});
						return Promise.reject(err);
					})
			);
		Promise.all(posts)
			.then(response => {
				const all = response.reduce((allPromises, promises) => allPromises.concat(promises), []);
				let err = '';
				if (Store.getState().get('netId') > netId) {
					err = 'Abort poll: netId ' + netId + ' too low! (state.netId=' + Store.getState().get('netId') + ')';
					errors.push({type: 'warning', err});
					topReject({errors, netId});
				} else if (!PollManager.isValid(netId)) {
					err = 'Abort poll: Not last poll. netId=' + netId;
					errors.push({type: 'warning', err});
					topReject({errors, netId});
				} else {
					Promise.all(all).then(reqResponses => {
						topResolve({errors, netId, reqResponses});
					})
						.catch(err => {
							errors.push({type: 'error', err});
							topReject({errors, netId});
						});
				}
			})
			.catch(err => {
				errors.push({type: 'error', err});
				topReject({errors, netId});
			})
	});
}

