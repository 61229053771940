export default function ($window, $state, $stateParams) {
    'ngInject';

    function handle(res) {
        var handler, handlers;

        handlers = {

            409: function() {
                if ($window.confirm('There was a conflict when saving. Reload?')) {
                    $state.transitionTo($state.current, $stateParams, {
                        reload: true,
                        inherit: false,
                        notify: true
                    });
                }

            }
        };

        handler = handlers[res.status];

        if (handler) {
            handler(res);
        }
    }

    return {
        handle: handle
    };
};
