export default function ($httpProvider) {
	'ngInject';

	// when one request has failed and the application is being routed to 'login'
	// don't let any more redirects towards 'login' go through
	var loginRedirectLock = false;

	$httpProvider.interceptors.push(function ($q, $injector, usSpinnerService, $translate) {
		'ngInject';

		return {

			request: function (config) {

				var SessionService = $injector.get('SessionService');
				config.headers = config.headers || {};

				if (!config.skipAuthorization) {
					// If sessionId exists, add SessionId to request
					const authHeader = SessionService.getAuthHeader();
					if (authHeader) {
						config.headers.Authorization = authHeader;
					}
				}

				return config;
			},

			responseError: function (response) {
				if (response.config.skipErrorHandling) {
					return $q.reject(response);
				}

				if (response.config.url.indexOf('api/v1/Batch') > 0) {
					// console.log('Batch response-error muted',response);
					response.config.muteNotification = true;
				}

				switch (response.status) {

					/**
					 * 400 Bad Request
					 * The server cannot or will not process the request due to something that is
					 * perceived to be a client error (e.g., malformed request syntax, invalid
					 * request message framing, or deceptive request routing).
					 **/
					case 400:
						var msg = {
							header: $translate.instant('notification.ERROR'),
							body: response.statusText + ' (' + response.status + '). '
						};

						if (response.data) {
							msg.body += $translate.instant('notification.ERROR_SERVER_RESPONDED_WITH') + '"' + (response.data.message || response.data) + '"';
						}

						$injector.get('NotificationService').error(msg);
						break;

					/**
					 * 401 Unauthorized
					 * Similar to 403 Forbidden, but specifically for use when authentication is
					 * required and has failed or has not yet been provided. The response must
					 * include a WWW-Authenticate header field containing a challenge applicable
					 * to the requested resource.
					 **/
					case 401:
						// console.log('401', loginRedirectLock);
						// Login session is over
						if (!loginRedirectLock) {
							loginRedirectLock = true;
							$injector.get('AuthenticationService').login(true);
						}
						break;

					/**
					 * 403 Forbidden
					 * The request was a valid request, but the server is refusing to respond to it.
					 * Unlike a 401 Unauthorized response, authenticating will make no difference.
					 **/
					case 403:
						var SessionService = $injector.get('SessionService');

						// The first API call when entering the app (/User/Me)
						// is allowed to be done without session id without forcing login
						if (response.config.url.indexOf('/User/Me') > 0
							// If the sessionId is missing, the user is not authenticated
							|| !SessionService.isAuthenticated()) {
							$injector.get('AuthenticationService').login(true);
						} else {
							$injector.get('NotificationService').error({
								header: $translate.instant('notification.ERROR'),
								body: $translate.instant('notification.ERROR_NOT_AUTHORIZED')
							});
						}

						break;

					/**
					 * 405 Method Not Allowed
					 * A request was made of a resource using a request method not supported by that
					 * resource; for example, using GET on a form which requires data to be presented
					 * via POST, or using PUT on a read-only resource.
					 **/
					case 405:
						$injector.get('NotificationService').error({
							header: $translate.instant('notification.ERROR'),
							body: response.statusText
						});
						break;

					/**
					 * 404 Not Found
					 * The requested resource could not be found but may be available again in the future.
					 * Subsequent requests by the client are permissible.
					 **/
					case 404:
					/**
					 * 410 Gone
					 * Indicates that the resource requested is no longer available and will not be available
					 * again. This should be used when a resource has been intentionally removed and the
					 * resource should be purged.
					 **/
					case 410:
					case 0:
						if (response.config.muteNotification === true) {
							break;
						}

						var apiBegin = response.config.url.indexOf('/api/');
						var urlNice = apiBegin > 0 ? response.config.url.slice(apiBegin) : response.config.url;
						$injector.get('NotificationService').error({
							header: $translate.instant('notification.ERROR_NOT_FOUND'),
							body: urlNice
						});
						break;

					/**
					 * 500 Internal Server Error
					 * A generic error message, given when an unexpected condition was encountered and no
					 * more specific message is suitable.
					 **/
					case 500:
						if (response.config.muteNotification === true) {
							break;
						}

						$injector.get('NotificationService').error({
							header: $translate.instant('notification.ERROR'),
							body: response.data ? response.data.message : response.statusText
						});
						break;
				}

				usSpinnerService.stop('spinner-page-loading');

				return $q.reject(response);
			}
		};
	});
};
