import DelegationController from './DelegationController';
import delegationTranslations from './DelegationTranslations';
import DelegationTemplate from './DelegationTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.account.delegation', [
    'ui.router',
    'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

.controller('DelegationController', DelegationController)
.constant('delegationTranslations', delegationTranslations)

.constant('delegationModuleConfig', {
    state: 'root.account.delegation',
    access: 'Account/Delegation'
})

.config(function($stateProvider, $translateProvider, delegationTranslations, delegationModuleConfig, VngageWorkspaceTranslationServiceProvider) {
	'ngInject';

	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(delegationTranslations);

    $stateProvider.state(delegationModuleConfig.state, {
        url: 'delegation/',
        controller: 'DelegationController as vm',
        templateUrl: DelegationTemplate,
        data: {
            access: delegationModuleConfig.access,
            ncyBreadcrumbLabel: 'Delegation'
        },
        resolve: {
            sessionTrustList: function(IdentityService, APIEndpointService) {
				'ngInject';
                if (!IdentityService.currentlyDelegated()) {
                    return APIEndpointService.sessionTrusts.query().$promise;
                } else {
                    return 'delegated';
                }
            }
        }
    });
});
