import React, {useState, useEffect} from 'react';
import Clipboard from 'react-copy-to-clipboard';
import {Modal, Button} from 'react-bootstrap';
import {useGetTranslation} from './../LangContext';

const StartCodeModal = (props) => {
	const getTranslation = useGetTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [isCopied, setIsCopied] = useState(false);

	const handleClick = () => {
		props.getStartCode();
		setIsLoading(true);
	};

	const onCopy = () => {
		setIsCopied(true);
	};


	useEffect(() => {
		const t = setTimeout(() => {
			setIsLoading(false);
			setIsCopied(false);
		}, 1000);

		return () => {
			clearInterval(t);
		};

	}, [isLoading, isCopied]);

	const isAnimating = isLoading || isCopied ? ' animate': '';
	const refreshCls = isLoading ? ' animate-rotate refresh-code-icon': 'refresh-code-icon';
	const copyCls = isCopied ? ' animate-copy copy-code-msg': 'copy-code-msg';
	const cls = `code-start-modal ${isAnimating}`;

	return (
		<Modal className={cls} show={true} onHide={props.toggleStartCodeView}>
			<Modal.Header closeButton>
				<Modal.Title>{getTranslation('generateCodeTitle')}</Modal.Title>
			</Modal.Header>

			<Modal.Body className="row">
				<div className="col-12">
					<p>{getTranslation('generateCodeDescription')}</p>
					<div className="col-5 offset-3 code-start-wrapper">
						<div className="code-start-number">{props.startCode}</div>
						<i className={refreshCls}/>
						<div className={copyCls}><span>{getTranslation('feedbackCopyCode')}</span></div>
						<Button onClick={handleClick}>{getTranslation('actionNewCode')}</Button>
						<Clipboard onCopy={onCopy}
								   text={props.startCode}><Button>{getTranslation('actionCopyCode')}</Button></Clipboard>
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button onClick={props.toggleStartCodeView}>{getTranslation('btnClose')}</Button>
			</Modal.Footer>

		</Modal>
	);

};
export default StartCodeModal;

