export default function () {
	'ngInject';

	return {
		restrict: 'E',
		scope: false,
		require: ['^form'],
		compile: function (tElem, tAttrs) {
			var model             = tAttrs.model,
				name              = tAttrs.name,
				validationWarning = tAttrs.validationWarning,
				refresh           = tAttrs.refresh,
				modelOptions      = '{\'updateOn\': \'default submit blur\',\'debounce\':{\'default\':500, \'submit\':0, \'blur\':0} }',
				options           = '{mode: \'text/html\', lineNumbers: true,lineWrapping: true,matchBrackets: true,autoMatchParens: true,autoClearEmptyLines: true}',
				el                = '<textarea ' +
					'ng-model-options="' + modelOptions + '" ' +
					'ng-model="' + model + '" ' +
					'name="' + name + '" ' +
					'validate-html-only-warn="' + validationWarning + '" ' +
					'ui-codemirror ' +
					'ui-refresh="' + refresh + '" ' +
					'data-ui-codemirror-opts="' + options + '" ' +
					'></textarea>';

			//tElem.replaceWith(el);
			tElem.html(el);

		}
	};
};
