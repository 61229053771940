import Window from '../utils/WindowWrapper';
import SoundManager from '../utils/SoundManager';
import DateWrapper from '../utils/DateWrapper'

export default {
	notificationTimes: [0, 0, 0],

	addNotificationTime(time) {
		this.notificationTimes.shift();
		this.notificationTimes.push(time);
	},

	removeNotificationTime(time) {
		this.notificationTimes = this.notificationTimes.filter(n => n !== time);
		if (this.notificationTimes.length < 3) {
			this.notificationTimes.unshift(0);
		}
	},

	checkForRoom() {
		return this.notificationTimes[0] === 0;
	},

	show(title, body, icon, time, sound) {
		const notification = Window.Notification;

		if (!notification) {
			return;
		}

		if (notification.permission === "denied") {
			return;
		}

		if (Window.isVisible() && Window.hasFocus()) {
			return;
		}

		let self = this;

		notification.requestPermission((result) => {
			const timeStamp = DateWrapper.now();

			if (result === 'denied') {
				// Permission wasn't granted
				return;
			}

			if (result === 'default') {
				// The permission request was dismissed
				return;
			}

			if (sound) {
				SoundManager.play(sound);
			}

			let note = Window.newNotification(title, {
				body,
				icon
			});

			self.addNotificationTime(timeStamp);

			note.addEventListener('click', () => {
				note.close();
				Window.focus();
			}, false);

			note.addEventListener('close', () => {
				self.removeNotificationTime(timeStamp);
			}, false);

			if (time > 0) {
				setTimeout(() => note.close(), time * 1000);
			}

			return note;
		});
	}
}

