import moment from 'moment-timezone';

export default function (d3) {
	'ngInject';

	const calculations = {
		'addition': (data, ...terms) => {
			// This calculator adds (or subtracts) all 'terms'
			// Each term can be either a number or a metric in 'data' (referenced by name as a string).
			// To subtract (rather than adding) a metric, prefix it's name with '-'
			let sum = 0;
			for (let term of terms) {
				if (typeof term === 'string') {
					if (term.indexOf('-') == 0) {
						sum -= data[term.substring(1)];
					} else {
						sum += data[term];
					}
				} else {
					sum += term;
				}
			}
			return sum;
		},
		'division': (data, a, b) => {
			// This calculator divides parameter a with parameter b
			// a and b can be numbers or strings used to lookup metrics in 'data'
			if (typeof a === 'string') {
				a = data[a];
			}
			if (typeof b === 'string') {
				b = data[b];
			}
			if (!a || !b || b === 0) {
				return '';
			}
			return a / b;
		},
		'timeDiff': (data, a, b) => {
			if (typeof a === 'string') {
				a = data[a];
			}
			if (typeof b === 'string') {
				b = data[b];
			}
			if (!a || !b) {
				return null;
			}
			return moment(b).diff(a); // returns time diff in ms
		},
		'formatMeasureWithParenthesis': (data, a, b) => {
			return data[a] + ' <span class="parenthesisStyle">(' + data[b] + ')</span>';
		},
		'formatPercentageWithColors': (data, a, colorRules) => {
			// colorRules is an array with objects defining how to color the values

			const value = data[a];
			let color = '#000';	// Default color is '#000'
			if (typeof colorRules === 'object') {
				colorRules.forEach(colRule => {
					if (value >= colRule.from) {
						color = colRule.color;
					}
				});
			}
			return value ? ' <span class="text-white rounded col-8px satisfaction-score ng-binding" style="background-color:'+color+'">' + value + '%</span>' : '';
		},
		'formatUserNameInGroupSite': (data, ...params) => {
			return data.userName + ' in "' + data.groupName + '" <span class="parenthesisStyle">(' + data.siteName + ')</span>';
		},
		'formatSolutionFromOpportunitySite': (data, ...params) => {
			return '"' + data.solutionName + '" from "' + data.opportunityName + '" <span class="parenthesisStyle">(' + data.siteName + ')</span>';
		},
		'formatDuration': (data, a, b) => {
			// This calculator formats a duration 'a' (in milliseconds)
			// If typeof 'a' is a string, use the value of metric 'a' in 'data' instead
			// If typeof 'b' is a string, use the value of metric 'b' in 'data' instead
			// If parameter 'b' exists and is >0, also display a progress-bar with 'a' as percentage of 'b'
			// If parameter 'b' is missing or 0, simply display 'a' as a duration

			//console.log('[ TL ] formatRelativeDuration. data,a,b:',data,a,b);
			if (typeof a === 'string') {
				a = data[a];
			}
			if (typeof b === 'string') {
				b = data[b];
			}
			if (!a || a < 1) {
				return '';
			}
			if (!b || b < 1) {
				return formats.duration(a);
			}
			const widthPercentage = d3.format('.1f')(Math.min(a / b, 1) * 100);
			return '<div class="progress-bar" role="progressbar" aria-valuenow="' + widthPercentage + '" aria-valuemin="0" aria-valuemax="100" style="width: ' + widthPercentage + '%"><div>' + formats.duration(a) + '</div></div>';
		},
		'formatNumberWithPercentage': (data, a, b) => {
			// This calculator displays 'a' and show its relation to 'b' with a progress-bar and a percentage
			// If typeof 'a' is a string, use the value of metric 'a' in 'data' instead
			// If typeof 'b' is a string, use the value of metric 'b' in 'data' instead
			// If parameter 'b' exists and is >0, display a progress-bar with 'a' as percentage of 'b'
			// If parameter 'b' is missing or 0, simply display 'a'
			//console.log('[ TL ] formatNumberWithPercentage. data,a,b:',data,a,b);
			if (typeof a === 'string') {
				a = data[a];
			}
			if (typeof b === 'string') {
				b = data[b];
			}
			if (!a || a < 1) {
				return '';
			}
			if (!b || b < 1) {
				return a;
			}
			const displayPercentage = d3.format('.1f')(a / b * 100);
			const widthPercentage = d3.format('.1f')(Math.min(a / b, 1) * 100);
			return '<div class="progress-bar" role="progressbar" aria-valuenow="' + widthPercentage + '" aria-valuemin="0" aria-valuemax="100" style="width: ' + widthPercentage + '%"><div>' + a + ' <span class="parenthesisStyle">(' + displayPercentage + '%)</span></div></div>';
		}
	};

	const formats = {
		duration: (ms) => {
			if ((typeof ms !== 'number') || (ms === 0)) {
				return '';
			}

			function pad(n) {
				return (n > 9) ? +n: '0' + n;
			}

			const seconds = ms / 1000,
				  h       = Math.floor(seconds / 3600),
				  m       = Math.floor(seconds / 60) % 60,
				  s       = Math.floor(seconds) % 60;
			return pad(h) + ':' + pad(m) + ':' + pad(s);
		},
		tableTime: (dtg) => {
			// Formats/styles the moment object 'dtg' for the time column in the data table
			return dtg.format('HH:mm') + ' <span class="dateStyle">' + dtg.format('ddd DD') + '</span>';
		},
		totalWithPercentage: (value) => {
			return '<span class="totalWithPercentage">' + value.toFixed(0) + ' </span>';
		}
	};

	const formatters = {
		percentage: () => {
			return d3.format('%');
		},
		dtgFormat: () => {
			return d3.time.format('%Y-%m-%dT%H:%M:%S%Z');
		}
	};


	function calculate(calc, data) {
		if (!calc.type || !calculations[calc.type]) {
			console.log('[ TL ] No calc.type or non-existing calculation type: ', calc.type, calculations[calc.type]);
			return '';
		}
		return calculations[calc.type](data, ...calc.parameters);
	}

	function format(type, data) {
		if (!type || !formats[type]) {
			console.log('[ TL ] Missing type or non-existing format type: ', type);
			return '';
		}
		return formats[type](data);
	}

	function getFormatter(type) {
		if (!type || !formatters[type]) {
			console.log('[ TL ] Missing type or non-existing formatter type: ', type);
			return '';
		}
		return formatters[type]();
	}

	return {
		calculate: calculate,
		format: format,
		getFormatter: getFormatter
	};
}
