import Actionsv100 from '../1.0.0/Actions'
import Data from './Data'
import Subscriptionsv100 from '../1.0.0/Subscriptions'
import Subscriptions from './Subscriptions'

// only subscriptions are updated from v1.0.0
export default {
	...Actionsv100,
	...Data,
	subscriptions: {...Subscriptionsv100.subscriptions, ...Subscriptions.subscriptions}
}

