import SessionStorageWrapper from '../stores/SessionStorageWrapper';
import LocalStorageWrapper from '../stores/LocalStorageWrapper';

const createStorage = (storage) => {
	const setItem = async (key, value) => {
		return await storage.setItem(key, value);
	};

	const getItem = async (key) => {
		return await storage.getItem(key);
	};

	const removeItem = async (key) => {
		return await storage.removeItem(key);
	};

	return {
		setItem,
		removeItem,
		getItem
	};
};
const SessionStorage = createStorage(SessionStorageWrapper);
const LocalStorage = createStorage(LocalStorageWrapper);

export {
	createStorage,
	SessionStorage,
	LocalStorage
};
