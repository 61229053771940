import DesktopPluginsEditController from './DesktopPluginsEditController';
import DesktopPluginsEditTemplate from './DesktopPluginsEditTemplate.html';

import DesktopPluginsListController from './DesktopPluginsListController';
import DesktopPluginsListTemplate from './DesktopPluginsListTemplate.html';
import DesktopPluginsAddModalController from './DesktopPluginsAddModalController';
import DesktopPluginsAddActionMessageMappingController from './DesktopPluginsAddActionMessageMappingController';

import desktopPluginsTranslations from './DesktopPluginsTranslations';

export default angular.module('vngageApp.desktopPlugins', [
	'ui.router',
])

	.controller('DesktopPluginsAddModalController', DesktopPluginsAddModalController)
	.controller('DesktopPluginsListController', DesktopPluginsListController)
	.controller('DesktopPluginsEditController', DesktopPluginsEditController)
	.controller('DesktopPluginsAddActionMessageMappingController', DesktopPluginsAddActionMessageMappingController)

	.constant('desktopPluginsTranslations', desktopPluginsTranslations)
	.constant('desktopPluginsModuleConfig', {
		state: 'root.desktopPlugins',
		access: 'DesktopPlugins'
	})

	.config(function ($stateProvider, $translateProvider, desktopPluginsModuleConfig, desktopPluginsTranslations, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(desktopPluginsTranslations);

		$stateProvider.state(desktopPluginsModuleConfig.state, {
			url: '/desktopPlugins/',
			data: {
				access: desktopPluginsModuleConfig.access,
				ncyBreadcrumbLabel: 'DesktopPlugins'
			},
			views: {
				content: {
					templateUrl: DesktopPluginsListTemplate,
					controller: 'DesktopPluginsListController as vm',
				}
			},
			resolve: {
				desktopPluginsSettings: function (WorkspaceSettingsService) {
					'ngInject';
					return WorkspaceSettingsService.forceGetSections('desktopPlugins').catch((error) => {
						// Catch reject to avoid red error notification
						if (error && error.status !== 404) {
							// Only log errors != 404 (which is normal when workspaceSetting is missing)
							console.error('Could not get workspaceSettings:', error);
						}
					});
				}
			}
		});

		$stateProvider.state('root.desktopPlugins.edit', {
			url: ':id',
			data: {
				access: desktopPluginsModuleConfig.access,
				ncyBreadcrumbLabel: 'Edit'
			},
			params: {currentList: []},
			templateUrl: DesktopPluginsEditTemplate,
			controller: 'DesktopPluginsEditController as vm',
			resolve: {
				desktopPluginsSettings: function (WorkspaceSettingsService, $state) {
					'ngInject';
					return WorkspaceSettingsService.forceGetSections('desktopPlugins').catch((error) => {
						// Catch reject to avoid red error notification
						if (error && error.status !== 404) {
							// Only log errors != 404 (which is normal when workspaceSetting is missing)
							console.error('Could not get workspaceSettings:', error);
						}
						$state.go('root.desktopPlugins');
					});
				},
				plugin: function ($stateParams, $q, $state, WorkspaceSettingsService, NotificationService, desktopPluginsSettings) {
					'ngInject';
					return $q((resolve, reject) => {
						// Do a WorkspaceSettingsService.forceGetSections() here to fetch any late changes
						// (don't resolve workspaceSettingsSections into the controller.  We don't want to overwrite the ref to "desktopPluginsSection" in the parent state - we want to be able to update it on save so the list updates)
						let plugin;
						if (desktopPluginsSettings &&
							Array.isArray(desktopPluginsSettings.pluginsList) &&
							(plugin = desktopPluginsSettings.pluginsList.find(plugin => plugin.id === $stateParams.id))) {

							// Plugin found: Resolve it and return
							resolve(plugin);
						} else {
							// If we get here, the plugin was not found. Show error...
							NotificationService.error({
								header: 'Plugin',
								body: 'Could not load plugin: ' + $stateParams.id
							});
							reject();
							$state.go('root.desktopPlugins');
						}
					});
				},
				groupList: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.group.query().$promise;
				},
				actionMessagesList: function (APIConfigurationSectionService) {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.action, false);
				}
			}
		});
	});


