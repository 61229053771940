import React from 'react';

const PanelPendingRemoval = () => {
	return (
		<div className="dialog-panel remove-out">
			<span className="dialog-container">
					 <div className="closing-dialog vngage-icon-ok"/>
			</span>
		</div>
	);
};

PanelPendingRemoval.displayName = 'PanelPendingRemoval';
export default PanelPendingRemoval;

