import assign from 'object-assign';
import ServerActions from './../actions/ServerActions';
import GuidGenerator from '../../../components/utils/GuidGenerator';
import ConversationRequest from '../requests/ConversationRequest';
import ConversationSinceRequest from '../requests/ConversationSinceRequest';

import ParticipantActions from './../actions/ParticipantActions';
import ConversationsStore from './../stores/ConversationsStore';

import ConnectionStatus from './ConnectionStatus';

var $http,
	$restUrl,
	$batchRestUrl,
	shouldPoll = false;

const REQUESTS = new Map();
const BATCH_RETRIES = 200;  // On fail: Retry max 200 polls ( = 10 minutes) as a emergency safety break to not overload servers on server failure
let batchDidRetries = 0;

export default {

	init(http, conversationRef, vngageConfig) {

		// clear ConversationsStore
		ServerActions.clearConversations();

		if (!conversationRef) {
			return;
		}

		$http = http;
		$restUrl = vngageConfig.restUrl;
		$batchRestUrl = vngageConfig.batchRestUrl;

		// kick up offline check
		ConnectionStatus.init($restUrl);

		// call action participant is waiting authorization
		ParticipantActions.participantAuthorizationPending();

		// try to join conversation then start fetching conversation data
		this.joinConversation(conversationRef)
			.then(() => {
				shouldPoll = true;
				this.poll();
			}, err => {
				console.warn('not found', err);
			});
	},

	joinConversation(conversationRef) {
		return new Promise((resolve, reject) => {
			$http.post(`${$restUrl}Conversation/Join/${conversationRef}`, {
				headers: {
					'Accept': 'application/json'
				}
			}).then(response => {
				// Add Conversation
				ServerActions.addConversation(response.data.id);
				ServerActions.updateConversation(response.data.stateTime);
				// TODO: handle from server?
				response.data.startedAt = !!response.data.messages.length ? response.data.messages[0].createdAt : response.data.stateTime;
				ParticipantActions.participantAuthorized(response.data.id);
				ServerActions.receiveAsState(response.data, response.data.id, conversationRef);
				resolve();
			}, error => {
				ParticipantActions.participantUnauthorized(error.data);
				reject(error);
			});
		});
	},

	poll() {
		REQUESTS.clear();

		if (!shouldPoll) {
			return false;
		}

		ConversationsStore.all.forEach((value, key) => {
			var reqGuid;
			if (!value.initialized) {
				reqGuid = GuidGenerator.create();
				REQUESTS.set(reqGuid, new ConversationRequest(reqGuid, $batchRestUrl, key));
			} else {
				reqGuid = GuidGenerator.create();
				REQUESTS.set(reqGuid, new ConversationSinceRequest(reqGuid, $batchRestUrl, key, value.since));
			}

		});

		var requestItems = {
			items: []
		};

		for (var item of REQUESTS.values()) {
			requestItems.items.push(item.asRequest);
		}

		if (requestItems.items.length > 0) {
			let resolvePromises = [];
			$http.post($restUrl + 'Batch', JSON.stringify(requestItems))
				.then(response => {
					batchDidRetries = 0;

					if (!shouldPoll) {
						return false;
					}

					response.data.items.forEach(item => {
						let requestItem = REQUESTS.get(item.tag);
						resolvePromises.push(requestItem.resolve(item.body, item.statusCode));
					});

					requestItems = {
						items: []
					};

					Promise.all(resolvePromises)
						.then(response => {
							setTimeout(() => {
								this.poll();
							}, 3000);
						}, error => {
							console.log('resolvePromises rejected', error);
							// this.dispose();
							setTimeout(() => {
								this.poll();
							}, 3000);
						});

				}, err => {
					if (batchDidRetries < BATCH_RETRIES) {
						batchDidRetries++;
						setTimeout(() => {
							this.poll();
						}, 3000);
					} else {
						// Reload after 10 minutes of failure (200 polls)
						location.reload();
					}
				});
		} else {
			setTimeout(() => {
				this.poll();
			}, 3000);
		}

	},

	/**
	 * create chat conversationMessage
	 * @param message
	 * @param conversationId
	 */
	createMessage(message, conversationId) {
		let createdMessage = assign(message, {
			type: message.type || 'conversationMessage'
		});

		$http.post(`${$restUrl}Conversation/${conversationId}/Message`, createdMessage)
			.then(response => {
				//ServerActions.receiveCreatedMessage(response.data);
			}, err => {
				console.log('<DG createMessage ERROR> ', err);
				ServerActions.requestError(conversationId, err.status, err.data.errorCode);
			});
	},

	/**
	 * create activity conversationMessage
	 * @param conversationId
	 * @param data
	 */
	createActivityMessage(conversationId, data) {
		let createdMessage = assign({
			messageType: 'activity',
			type: 'conversationMessage'
		}, data);

		$http.post(`${$restUrl}Conversation/${conversationId}/Message`, createdMessage)
			.then(response => {
				//ServerActions.receiveCreatedMessage(response.data);
			}, err => {
				console.log('<DG createActivityMessage ERROR> ', err);
				ServerActions.requestError(conversationId, err.status, err.data.errorCode);
			});
	},

	leaveConversation(conversationId) {
		this.dispose(conversationId);
		$http.post(`${$restUrl}Conversation/${conversationId}/Leave`)
			.then(response => {
				shouldPoll = false;
				ServerActions.conversationLeave();
			}, err => {
				console.log('error leaving conversation', err);
			});
	},

	dispose() {
		shouldPoll = false;
		// this should also abort ongoing requests
	}

};
