export default function ($scope, $uibModalInstance, options, holidaysService, $translate, NotificationService) {
	'ngInject';

	var vm = this;
	vm.options = options;
	vm.cancel = vm.close = $uibModalInstance.close;

	vm.saveModel = function () {
		holidaysService.querySaveHoliday(vm.model.item).then(res => {
			vm.options.refetchEvents(res);
			vm.close();
		}).catch(err => {
			if (!err.data) {
				// Will happen if ID has wrong format (will cause a "Response to preflight request doesn't pass access control check" from BE, and data will be empty)
				NotificationService.error({
					header: $translate.instant('holidays.NOTIFICATION_UNKNOWN_ERROR'),
					body: $translate.instant('holidays.NOTIFICATION_ERROR_SAVING_HOLIDAY')
				});
			}
		});
	};

	vm.removeModel = function () {
		holidaysService.queryRemoveHoliday(vm.model.item).then(res => {
			vm.options.refetchEvents(res);
			vm.close();
		}).catch(err => {
			if (!err.data) {
				// Will happen if ID has wrong format (will cause a "Response to preflight request doesn't pass access control check" from BE, and data will be empty)
				NotificationService.error({
					header: $translate.instant('holidays.NOTIFICATION_UNKNOWN_ERROR'),
					body: $translate.instant('holidays.NOTIFICATION_ERROR_DELETING_HOLIDAY')
				});
			}
		});
	};

	vm.model = options.model;
};
