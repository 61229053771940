// AppName@VendorName:BusinessUnitNumber
const getAuthCode = (applicationName, vendorName, businessUnit) => window.btoa(`${applicationName}@${vendorName}:${businessUnit}`);

const formatAwayReasons = (awayReasons) => {
	const formatted = awayReasons.filter(reason => reason.IsActive).map(item => ({name: item.OutStateDescription, id: item.OutStateId}));
	const defaultItem = {
		name: 'Away',
		id: 0
	};
	return [defaultItem, ...formatted]
};

export default {
	getAuthCode,
	formatAwayReasons,
}
