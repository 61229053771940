import React from 'react';
import {useGetTranslation} from './../../LangContext';

const ENTER_KEY_CODE = 13;
const MAX_LENGTH = 1000;

const NoteTextarea = (props) => {
	const getTranslation = useGetTranslation();

	const onChange = (event) => {
		const newText = event.target.value;
		props.typingNoteText(newText);
	};

	const onKeyDown = (event) => {
		if (event.keyCode === ENTER_KEY_CODE) {
			if (!event.shiftKey && !event.ctrlKey) {
				event.preventDefault();
				if (props.noteText.trim()) {
					props.typingNoteText('');
					props.sendNote(props.conversationId, '*' + getTranslation('sysMsgNote') + '* ' + props.noteText);
					props.toggleNoteMode(props.conversationId);
				}
			}
		}
	};
	const onFocus = (event) => {
		// set selection
		const [selectionStart, selectionEnd] = props.noteSelection;
		event.target.setSelectionRange(selectionStart, selectionEnd);
	};

	const onBlur = (event) => {
		// save selection position
		props.setNoteSelection([event.target.selectionStart, event.target.selectionEnd]);
	};

	const disabled = props.panelPosition === 'collapsed' || props.panelAnimating;
	return (
		<textarea
			id={'input' + props.conversationId}
			className="note-composer form-control"
			rows="3"
			disabled={disabled}
			onChange={onChange}
			onKeyDown={onKeyDown}
			maxLength={MAX_LENGTH}
			onFocus={onFocus}
			onBlur={onBlur}
			tabIndex="-1"
			value={props.noteText}
		/>
	)
};
export default NoteTextarea

