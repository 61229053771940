import React from 'react';
import WorkArea from './components/WorkArea.react';
import PendingAuthorization from './components/PendingAuthorization.react';
import Unauthorized from './components/Unauthorized.react';
import ConnectionStore from './stores/ConnectionStore';
import AuthorizationStore from './stores/AuthorizationStore';
import EventStore from './stores/EventStore';
import i18nLoader from '../../i18n';
import createReactClass from 'create-react-class';

function getStateFromStores(conversationRef = '') {
	return {
		connectionStatus: ConnectionStore.getStatus(),
		authorized: AuthorizationStore.getStatus(),
		authError: AuthorizationStore.getErrorCode(),
		conversation: EventStore.getConversationByRef(conversationRef) || {}
	}
}

export default createReactClass({

	getInitialState () {
		return getStateFromStores();
	},

	componentDidMount () {
		ConnectionStore.addChangeListener(this._onChange);
		AuthorizationStore.addChangeListener(this._onChange);
		EventStore.addChangeListener(this._onChange);
	},

	componentWillUnmount () {
		ConnectionStore.removeChangeListener(this._onChange);
		AuthorizationStore.removeChangeListener(this._onChange);
		EventStore.removeChangeListener(this._onChange);
	},

	_onChange () {
		this.setState(getStateFromStores(this.props.conversationRef));
	},

	render () {
		let cls = 'dialog-wrapper connection-' + this.state.connectionStatus,
			authorized = this.state.authorized,
			appView;

		switch (authorized) {
			case 'PENDING' :
				appView = <PendingAuthorization />;
				break;
			case 'AUTHORIZED' :
				appView = <WorkArea conversationId={this.state.conversation.id} groupList={this.props.groupList} />;
 				break;
			case 'NOT_AUTHORIZED' :
				appView = <Unauthorized authError={this.state.authError}/>;
				break;
		}

		return (
			<div className={cls}>
				<div className="react-container">
					{appView}
				</div>
			</div>
		);
	}
});
