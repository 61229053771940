import immutable from 'immutable';
import {pluginLib} from '../libs/pluginLib';


export function receiveAsState(state, action) {
	return pluginLib.receiveAsState(state, action.conversationId, action.conversationState.messages);
}

export function receiveSince(state, action) {
	return pluginLib.receiveSince(state, action.conversationId, action.rawMessages);
}

export function conversationReady(state, action) {
	return state.setIn(['pluginData', 'conversationId'], action.conversationId);
}

export function visitorProfileAdd(state, action) {
	return pluginLib.visitorProfileAdd(state, action.profile);
}

export function togglePluginsEnabled(state, action) {
	return state.update('pluginsEnabled', n => !n);
}

export function togglePlugin(state, action) {
	return state.updateIn(['plugins', action.pluginNr, 'enabled'], n => !n);
}

export function pluginReady(state, action) {
	return pluginLib.pluginReady(action, state);
}

export function addPlugins(state, action) {
	return pluginLib.addPlugins(state, action.plugins);
}

export function pluginButtonClicked(state, action) {
	return pluginLib.pluginButtonClicked(state, action.conversationId, action.pluginId);
}

export function setPluginButtonState(state, action) {
	return pluginLib.setSectionButtonState(action, state);
}

export function setMeta(state, action) {
	return state.setIn(['pluginData', 'meta'], immutable.fromJS({...action.meta, pluginId: action.pluginId}));
}

export function updatePluginMeta(state, action) {
	return state.updateIn(['pluginData', 'meta'], prev => prev.merge(immutable.fromJS(action.metaUpdate)));
}

export function setSelectedPlugin(state, action) {
	return pluginLib.setSelectedPlugin(action, state);
}

export function notify(state, action) {
	return pluginLib.notify(action, state);
}

export function processConversationDetails(state, action) {
	return pluginLib.processConversationDetails(action, state);
}


