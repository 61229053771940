import React, {useState, useRef, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import immutable from 'immutable';
import Conversation from '../../actionCreators/Conversation';
import ServerActions from '../../actionCreators/ServerActions';
import ToolBarSectionType from '../../constants/ToolBarSectionType';
import {Button} from 'react-bootstrap';
import Format from '../../utils/Format';
import TimeFormat from '../../utils/TimeFormat';
import DateWrapper from '../../utils/DateWrapper';
import {useGetTranslation} from './../LangContext';

const beaconLimits = [5, 10, 1000];
const beaconClasses = ['danger', 'warning', 'none'];
const fallbackTime = 30;

export const StartOverlay = (props) => {
	const getTranslation = useGetTranslation();
	const [currentTime, setCurrentTime] = useState(0);
	const [buttonActive, setButtonActive] = useState(true);
	const timer = useRef();

	const {
			  reservation,
			  inReservationSection,
			  toggleSection,
			  conversationId,
			  eligibleUntil
		  } = props;
	const getTime = (dateStr) => {
		return new Date(dateStr).getTime();
	};

	const onClickStart = (event) => {
		event.stopPropagation();
		props.startRoutedConversation(props.conversationId);
		setButtonActive(false);
	};


	const reservationInfo = useMemo(() => {
		const showReservationInfoAction = (event) => {
			toggleSection(conversationId, ToolBarSectionType.RESERVATION);
		};

		if (!reservation) {
			return getTranslation('urgingActionMsg');
		}
		let reservationButtonCls = 'toolbar-btn';
		if (inReservationSection) {
			reservationButtonCls += ' selected';
		}

		let meetingStartTime = Format.getBookedMeetingTime(reservation.get('title'));
		const meetingTitle = Format.getBookedMeetingTitle(reservation.get('title'));

		if (meetingStartTime) {
			if (meetingStartTime.length > 5) {
				// Assume we have a full time string if meetingStartTime.length>5
				// In that case, try to format it as HH:mm
				// And at the same time convert it to the browser's timezone (maybe should be group's timezone in the future?)
				meetingStartTime = TimeFormat.asReservationTime(meetingStartTime);
			}
			meetingStartTime = getTranslation('meetingStart') + meetingStartTime;
		}

		return <div style={{'paddingBottom': '20px'}}>
			<div style={{'paddingBottom': '12px'}}>{getTranslation('urgingActionMsgReservation')}</div>
			<div style={{'paddingBottom': '8px'}}>{meetingTitle}</div>
			<div style={{'paddingBottom': '12px'}}>{meetingStartTime}</div>
			<div key={ToolBarSectionType.RESERVATION} className="toolbar-btn-container"
				 onClick={showReservationInfoAction}>
				<Button size="sm" tabIndex="-1" className={reservationButtonCls}>
					<span>{getTranslation('bookedMeetingShowInformationButtonText')}</span>
					<i className={'vngage-icon-calendar'}/>
				</Button>
			</div>
		</div>;
	}, [reservation, inReservationSection, getTranslation, toggleSection, conversationId]);

	useEffect(() => {
		let time = Math.ceil((getTime(eligibleUntil) - DateWrapper.now()) / 1000);
		if (time < 0) {
			time = fallbackTime;
		}
		timer.current = setInterval(() => {
			const cTime = Math.ceil((getTime(eligibleUntil) - DateWrapper.now()) / 1000);
			setCurrentTime(cTime < 0 ? 0: cTime);
		}, 1000);

		setCurrentTime(time);
		return () => {
			clearInterval(timer.current);
		};
	}, [eligibleUntil]);

	const beaconClassIndex = beaconLimits.findIndex(n => currentTime <= n);
	let btnCls = `select-btn beacon ${beaconClasses[beaconClassIndex]}`;

	return (
		<div className="urging-action-container">
			{buttonActive
				? <div>
					<div className="urging-action-message">
						{reservationInfo}<br/>
						{getTranslation('urgingTimerMsg')}
						<span className="urging-timer">
							{currentTime}{getTranslation('urgingTimerUnit')}
						</span>
					</div>
					<div className="buttons-container">
						<div className="label-container">
							<button className={btnCls} onClick={onClickStart}>
								<i className="vngage-icon-multi-chat-line"/>
							</button>
							<span
								className="select-btn-label">{getTranslation('btnAutoRoutingStart')}</span>
						</div>
					</div>
				</div>
				: <div className="urging-action-message">
					<span className="loading-spinner"/>
				</div>
			}
		</div>
	);
};

function mapStateToProps(state, ownProps) {
	// INCOMING
	// conversationId
	const conversationId = ownProps.conversationId;
	const conversation = state.getIn(['conversations', conversationId]) || immutable.fromJS({events: []});

	const events = conversation.get('events') || immutable.List();
	const event = events.findLast(event => event.get('type') === 'conversationReservation');
	const reservation = event
		? event.get('reservation')
		: null;


	return {
		conversationId,
		groupId: conversation.get('groupId'),
		reservation,
		eligibleUntil: conversation.getIn(['UI', 'eligibleUntil']),
		inReservationSection: conversation.getIn(['UI', 'activeSections', ToolBarSectionType.RESERVATION]),
	};
}

function mapDispatchToProps(dispatch) {

	return {
		toggleSection: (conversationId, sectionType) => dispatch(Conversation.toggleSection(conversationId, sectionType)),
		startRoutedConversation: (conversationId) => dispatch(ServerActions.startRoutedConversation(conversationId)),
	};
}

export default connect(mapStateToProps,
	mapDispatchToProps)(StartOverlay);


