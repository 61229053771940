import keyboardJS from 'keyboardjs';
import {Store} from '../stores/Store';
import NavActions from '../actions/NavigationActions';
import ConversationActions from '../actions/ConversationActions';
import Navigation from '../actionCreators/Navigation'

keyboardJS.withContext('dialog', function () {
	keyboardJS.bind(['alt+right', 'tab'], function () {
		Store.dispatch(Navigation.move('right'))
	});
	keyboardJS.bind(['alt+left', 'shift+tab'], function () {
		Store.dispatch(Navigation.move('left'));
	});
	keyboardJS.bind('alt+down', function () {
		Store.dispatch(Navigation.move('down'));
	});
	keyboardJS.bind('alt+up', function () {
		Store.dispatch(Navigation.move('up'));
	});
});

keyboardJS.withContext('queue', function () {
	// keyboardJS.bind('right', () => console.log('queue:right'));
	// keyboardJS.bind('left', () => console.log('queue:left'));
});

export default keyboardJS;
