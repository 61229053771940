import moment from 'moment-timezone';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';

export default function ($scope, $uibModalInstance, $http, vngageConfig, options, reservationsService, Session, $translate, APIEndpointService, caseBrowserModalService, usSpinnerService) {
	'ngInject';

	const vm = this;
	vm.model = {};
	vm.showMessageContainer = false;
	vm.isCancelled = false;
	vm.isCancelling = false;
	vm.isWritingEmail = false;
	vm.model.reply = {
		ingress: '',
		message: '',
		placeholder: '',
		value: '',
		allowReply: false,
		cancelValue: ''
	};
	vm.options = options;
	vm.advanced = false; // default false
	vm.loadingCase = false;

	vm.reservation = options.reservation;

	vm.viewCase = function (caseId) {
		vm.loadingCase = true;
		usSpinnerService.spin('spinner-view-case-loading');
		APIEndpointService.case.get({id: caseId}).$promise.then(function (res) {
			caseBrowserModalService.open({
				caseItem: res,
				userList: vm.options.userList
			}).catch(() => {
				// Modal dismissed...
			});
		}).finally(function () {
			vm.loadingCase = false;
			usSpinnerService.stop('spinner-view-case-loading');
		});
	};

	vm.reservationDetailsMapping = [{
		displayName: $translate.instant('reservations.DETAILS_RESERVATION_ID_NAME'),
		key: 'id'
	}, {
		displayName: $translate.instant('reservations.DETAILS_CASE_ID_NAME'),
		key: 'caseId',
		action: vm.viewCase
	}, {
		displayName: $translate.instant('reservations.DETAILS_CONVERSATION_ID_NAME'),
		key: 'conversationId'
	}];

	vm.customFormsFilter = function (customForm) {
		// Don't list firstName, lastName or forms with name starting with '__'
		return (customForm.name !== 'name' && customForm.name !== 'firstName' && customForm.name !== 'lastName' && customForm.name.indexOf('__') !== 0);
	};

	vm.setCustomFormsDataFromLegacyFormat = function (reservation) {
		if (!reservation.metadata.customForms) {
			reservation.metadata.customForms = {};
		}

		// Convert to array, sorted on 'position'...
		reservation.metadata.customForms = _sortBy(reservation.metadata.customForms, ['position']);

		// Remove customForm elements that are 'hiddenForAgent'
		reservation.metadata.customForms = reservation.metadata.customForms.filter(item => !item.hiddenForAgent);

		if (reservation.name && !_find(reservation.metadata.customForms, {'name': 'firstName'}) && !_find(reservation.metadata.customForms, {'name': 'name'})) {
			// For backwards compatibility: Add firstName from reservation base object if missing in metadata.customForms
			reservation.metadata.customForms.push({
				'name': 'firstName',
				'label': $translate.instant('reservations.FIRST_NAME_LABEL'),
				'type': 'text',
				'validateAs': 'letters',
				'value': reservation.name
			});
		}
		if (reservation.lastName && !_find(reservation.metadata.customForms, {'name': 'lastName'})) {
			// For backwards compatibility: Add lastName from reservation base object if missing in metadata.customForms
			reservation.metadata.customForms.push({
				'name': 'lastName',
				'label': $translate.instant('reservations.LAST_NAME_LABEL'),
				'type': 'text',
				'validateAs': 'letters',
				'value': reservation.lastName
			});
		}
		if (reservation.email && !_find(reservation.metadata.customForms, {'name': 'email'})) {
			// For backwards compatibility: Add email from reservation base object if missing in metadata.customForms
			reservation.metadata.customForms.push({
				'name': 'email',
				'label': $translate.instant('reservations.EMAIL_LABEL'),
				'type': 'text',
				'validateAs': 'email',
				'value': reservation.email
			});
		}
		if (reservation.phone && !_find(reservation.metadata.customForms, {'name': 'phone'})) {
			// For backwards compatibility: Add phone from reservation base object if missing in metadata.customForms
			reservation.metadata.customForms.push({
				'name': 'phone',
				'label': $translate.instant('reservations.PHONE_LABEL'),
				'type': 'text',
				'validateAs': 'phone',
				'value': reservation.phone
			});
		}
		reservation.metadata.customForms_analyzed = true;
	};

	vm.setCustomFormsDataFromLegacyFormat(vm.reservation);
	const firstNameItem = _find(vm.reservation.metadata.customForms, function (data) {
		return (data.name === 'firstName' || data.name === 'name');
	});
	const lastNameItem = _find(vm.reservation.metadata.customForms, {'name': 'lastName'});
	vm.fullName = (firstNameItem ? firstNameItem.value: '?') + ' ' + (lastNameItem ? lastNameItem.value: '?');

	vm.conversation = [];

	reservationsService.getConversation({
		reservationId: vm.reservation.id
	}).then(function (res) {
		//console.log('FIXED RESPONSE: ',res);
		var messages = res || [];
		messages.forEach(function (message) {
			message.created = moment(message.created).format('dddd D MMMM  HH:mm');
		});
		messages.push({
			created: moment(vm.reservation.created).format('dddd D MMMM  HH:mm'),
			message: vm.reservation.comment,
			name: vm.reservation.name,
		});
		messages.reverse();
		//console.log('RESERVATIONS: ',messages);
		vm.conversation = messages;
	});

	vm.reply = function (data) {
		vm.isWritingEmail = true;
		if (vm.model.reply.value !== '' && vm.showMessageContainer && !vm.isCancelling) {
			reservationsService.sendEmail({
				accountId: vm.reservation.accountId,
				siteId: vm.reservation.siteId,
				groupId: vm.reservation.groupId,
				id: vm.reservation.id,
				userId: Session.user.id,
				userName: Session.user.profile.displayName,
				ingress: 'New ingress!',
				message: vm.model.reply.value,
				allowReply: vm.model.reply.allowReply
			}).then(res => {
				vm.isWritingEmail = false;
				vm.model.reply.value = '';
				vm.showMessageContainer = false;
				vm.close();
			}, err => {
				console.log('error sending email: ', err);
			});
			return;
		}
		if (vm.isCancelling) {
			vm.model.reply.value = '';
			vm.isCancelling = false;
		}
		vm.showMessageContainer = true;
	};

	vm.cancel = function (data) {
		if (!vm.isCancelling) {
			vm.model.reply.value = vm.model.reply.cancelValue;
		}
		vm.model.reply.allowReply = false;
		vm.isWritingEmail = false;
		if (vm.isCancelling && vm.showMessageContainer) {
			reservationsService.cancelReservation({
				id: vm.reservation.id,
				message: vm.model.reply.value
			}).then(res => {
				vm.showMessageContainer = false;
				vm.isCancelled = true;
				vm.isCancelling = false;
				vm.model.reply.value = '';
				vm.close();
			}, err => {
				// Error cancelling reservation
				console.log('Error cancelling reservation: ', err)
			});
			return;
		}
		vm.isCancelling = true;
		vm.showMessageContainer = true;
	};

	vm.dismiss = vm.close = $uibModalInstance.close;

	vm.getMessageColClass = function (message) {
		return (vm.isVisitor(message) ? 'col-11' : 'col-11 offset-1');
	};

	vm.getMessageColor = function (message) {
		return 'panel-default' + (vm.isVisitor(message) ? ' visitor' : ' user');
	};

	vm.isVisitor = function (message) {
		return !message.userName;
	};
};

