import moment from 'moment';
import _values from 'lodash/values';
import _forEach from 'lodash/forEach';

import calendar1Html from './BookedMeetingsViews/calendar1.html?raw';
import calendar2Html from './BookedMeetingsViews/calendar2.html?raw';
import reservedHtml from './BookedMeetingsViews/reserved.html?raw';
import enter1Html from './BookedMeetingsViews/enter1.html?raw';
import enter2Html from './BookedMeetingsViews/enter2.html?raw';
import enter3Html from './BookedMeetingsViews/enter3.html?raw';
import enter4Html from './BookedMeetingsViews/enter4.html?raw';
import enter5Html from './BookedMeetingsViews/enter5.html?raw';
import cancelHtml from './BookedMeetingsViews/cancel.html?raw';
import cancelledHtml from './BookedMeetingsViews/cancelled.html?raw';

export default function (BannerPreview) {
	'ngInject';

	// Booked meetings preview markup in files below should be updated to sync with the actual implementation in visitor
	const viewsHtml = {
		calendar1: calendar1Html,
		calendar2: calendar2Html,
		reserved: reservedHtml,
		enter1: enter1Html,
		enter2: enter2Html,
		enter3: enter3Html,
		enter4: enter4Html,
		enter5: enter5Html,
		cancel: cancelHtml,
		cancelled: cancelledHtml,
	};

	const generateICalPreviewUrl = (doTVariables) => {
		const enterLink = 'https://www.example.com/contact/book-a-meeting?vngageReservationId=3DE5EBE3-0B62-46A1-B6FE-8346EFF949C9&vngageId=0cf241a2-c2a5-43d4-a6d0-69abf8488e76&vngageReservation=enter';
		const cancelLink = 'https://www.example.com/contact/book-a-meeting?vngageReservationId=3DE5EBE3-0B62-46A1-B6FE-8346EFF949C9&vngageId=0cf241a2-c2a5-43d4-a6d0-69abf8488e76&vngageReservation=cancel';
		const iCalData = BannerPreview.compileHtml('BEGIN:VCALENDAR\r\n' +
			'VERSION:2.0\r\n' +
			'PRODID:-//Vergic AB//NONSGML v1.0//EN\r\n' +
			'BEGIN:VEVENT\r\n' +
			'DTSTART:20200123T090000Z\r\n' +
			'DTEND:20200123T093000Z\r\n' +
			'SUMMARY:' + '{{!=conf.ICAL_SUMMARY_TEXT}}' + '\r\n' +
			'DESCRIPTION:' +
			'{{!=conf.ICAL_DESCRIPTION_HEADER}}' + '\\n' +
			(doTVariables.ICAL_DESCRIPTION_JOIN_TEXT ? '{{!=conf.ICAL_DESCRIPTION_JOIN_TEXT}}' + ' ' + enterLink + '\\n' : '') +
			(doTVariables.ICAL_DESCRIPTION_CANCEL_TEXT ? '{{!=conf.ICAL_DESCRIPTION_CANCEL_TEXT}}' + ' ' + cancelLink + '\\n' : '') +
			'{{!=conf.ICAL_DESCRIPTION_FOOTER}}' + '\\n\r\n' +
			'END:VEVENT\r\n' +
			'END:VCALENDAR', doTVariables, null, {strip: false});
		const blob = new Blob([iCalData], {type: 'text/calendar; charset=UTF-8'});
		return window.URL.createObjectURL(blob);
	};

	const service = {
		getPreviewVariables: (banner, translationId, doTVariables, wcagEnabled, bannerPreview) => {
			const addAriaLabel = (label) => {
				if (wcagEnabled && label) {
					return 'aria-label="'+label+'"';
				} else {
					return '';
				}
			};

			let textDirectionClass = 'ltr';
			let _preview_extraBannerClasses = banner.data.section.extraClasses || '';
			let _preview_item_preview_class = (bannerPreview.bookedMeetingsShowForm ? '' : 'queueReservationHidden');
			let _preview_locale = '';
			let _preview_time_format = '';
			let _preview_date_format = '';
			let _preview_short_date_time_format = '';
			let _preview_long_date_time_format = '';
			let _preview_reserved_start_time = '10.00';
			let _preview_wcagCalendarTimeRangeMarkup = '';

			if (banner.data.section.translations && translationId && banner.data.section.translations[translationId]) {
				const translation = banner.data.section.translations[translationId];
				if (translation.textDirection === 'RTL') {
					textDirectionClass = 'rtl';
				}
				_preview_locale = translation.locale || 'sv';
				_preview_date_format = translation.dateFormat || banner.data.section.settings.timeFormat || 'L';
				_preview_time_format = translation.timeFormat || 'HH.mm';
				_preview_short_date_time_format = translation.shortDateTimeFormat || 'D MMM LT';
				_preview_long_date_time_format = translation.longDateTimeFormat || 'dddd Do MMMM LT';

				const startTime = moment('2020-01-23 10:00:00');
				startTime.locale([_preview_locale, 'sv', 'en']);
				_preview_reserved_start_time = startTime.format(_preview_time_format);
			}

			const subjectMappingNames = _values(banner.data.section.subjectMapping).sort((a, b) => {
				return (a.position > b.position ? 1 : -1);
			}).map(mapping => {
				return mapping.subjectMappingName;
			});
			let subjectMappingsOptions = '';
			_forEach(subjectMappingNames, name => {
				subjectMappingsOptions += '<option>' + name + '</option>';
			});

			// The banner html needs to be compiled (as it may use the translation variables),
			const bannerHtml = (banner.data.section.html ? BannerPreview.compileHtml(banner.data.section.html, doTVariables) : '');

			let weekNavFirstAvailableMarkup = '',
				queueReservationFirstAvailableTimeItemClass = '',
				queueReservationFirstAvailableTooltipMarkup = '';

			if (banner.data.section.settings.startWithFirstAvailableWeek) {
				weekNavFirstAvailableMarkup = BannerPreview.compileHtml('<div class="vngageCalendarButton firstAvailable vngage-focusable" tabindex="0" ' + addAriaLabel(doTVariables.WCAG_LABEL_FIRST_AVAILABLE) + '><span>{{!=conf.CAL_FIRST_AVAILABLE}}</span></div>', doTVariables);
				queueReservationFirstAvailableTimeItemClass = 'queueReservationFirstAvailableTimeItem';
				queueReservationFirstAvailableTooltipMarkup = BannerPreview.compileHtml('<div class="queueReservationFirstAvailableTimeTooltip"><div class="queueReservationFirstAvailableTimeDivTooltipText">{{!=conf.FIRST_AVAILABLE_TIME_TEXT}}</div></div>', doTVariables);
			}

			if (wcagEnabled && doTVariables.WCAG_SELECTED_WEEK_LABEL) {
				_preview_wcagCalendarTimeRangeMarkup = '<span class="vngage-invisible" id="wcagCalendarTimeRange" aria-live="polite">';
				_preview_wcagCalendarTimeRangeMarkup += doTVariables.WCAG_SELECTED_WEEK_LABEL + ': 2020/01/20';
				if (doTVariables.WCAG_TO) {
					_preview_wcagCalendarTimeRangeMarkup += ' ' + doTVariables.WCAG_TO + ' 2020/01/26';
				}
				_preview_wcagCalendarTimeRangeMarkup += '</span>';
			}

			const iCalUrl = (bannerPreview.bookedMeetingsView === 'reserved' ? generateICalPreviewUrl(doTVariables) : '');

			return {
				_preview_textDirectionClass: textDirectionClass,
				_preview_extraBannerClasses: _preview_extraBannerClasses,
				_preview_subjectMappingsOptions: subjectMappingsOptions,
				_preview_bannerHtml: bannerHtml,
				_preview_weekNavFirstAvailableMarkup: weekNavFirstAvailableMarkup,
				_preview_queueReservationFirstAvailableTimeItemClass: queueReservationFirstAvailableTimeItemClass,
				_preview_queueReservationFirstAvailableTooltipMarkup: queueReservationFirstAvailableTooltipMarkup,
				_preview_iCalUrl: iCalUrl,
				_preview_lengthOfWeekClass: (+banner.data.section.settings.lengthOfWeek === 5 ? 'fiveDaysWeek' : ''),
				_preview_item_preview_class,
				_preview_locale,
				_preview_time_format,
				_preview_date_format,
				_preview_short_date_time_format,
				_preview_long_date_time_format,

				_preview_reserved_start_time,
				_preview_wcagCalendarTimeRangeMarkup,
				_preview_wcag_enabled: !!wcagEnabled
			};
		},
		getQueueReservationMarkup: (view) => {
			view = view || 'calendar1';
			return (viewsHtml[view] || 'Unknown booked meetings template: "' + view + '"');
		},

		localizePreviewElement: (el, view, variables, wcagEnabled) => {
			// Transform the content of el and change all its date & time values into the configured formats
			// THIS IS QUITE HACKY!!! ...but the "easiest" way to produce a correct preview from the static html preview documents... :/

			const localizeCalendar = (el) => {
				const calendarItems = Array.prototype.slice.call(el.querySelectorAll('.queueReservationCalendarItem'));
				calendarItems.forEach(item => {
					const parentTd = item.parentNode;
					if (parentTd) {
						const dateTime = moment(parentTd.getAttribute('id'));
						dateTime.locale([variables._preview_locale, 'sv', 'en']);
						item.setAttribute('title', dateTime.format(variables._preview_short_date_time_format));
						if (wcagEnabled) {
							item.setAttribute('aria-label', variables.WCAG_AVAILABLE_SLOT + ': ' + dateTime.format(variables._preview_long_date_time_format));
						}

						const availableTimeItem = item.querySelector('.reservationToGroupAvailable .time');
						if (availableTimeItem) {
							availableTimeItem.innerHTML = dateTime.format(variables._preview_time_format);
						}

						const unavailableTimeItem = item.querySelector('.reservationToGroupUnavailable');
						if (unavailableTimeItem) {
							unavailableTimeItem.innerHTML = dateTime.format(variables._preview_time_format) + ' ' + variables.UNAVAILABLE;
							if (wcagEnabled) {
								item.setAttribute('aria-label', variables.WCAG_UNAVAILABLE_SLOT + ': ' + dateTime.format(variables._preview_long_date_time_format));
							}
						}
					}
				});
				const timeRangeItem = el.querySelector('#reservationToGroupTimeRange');
				if (timeRangeItem) {
					const weekStart = moment('2020-01-23').startOf('week');
					weekStart.locale([variables._preview_locale, 'sv', 'en']);
					const weekEnd = weekStart.endOf('week');
					timeRangeItem.innerHTML = weekStart.format(variables._preview_date_format) + ' - ' + weekEnd.format(variables._preview_date_format);
				}

				const wcagTimeRangeItem = el.querySelector('#wcagCalendarTimeRange');
				if (wcagTimeRangeItem && wcagEnabled && variables.WCAG_SELECTED_WEEK_LABEL) {
					const weekStart = moment('2020-01-23').startOf('week');
					weekStart.locale([variables._preview_locale, 'sv', 'en']);
					const weekEnd = weekStart.endOf('week');
					let text = variables.WCAG_SELECTED_WEEK_LABEL + ': ' + weekStart.format(variables._preview_date_format);
					if (variables.WCAG_TO) {
						text += ' ' + variables.WCAG_TO + ' ' + weekEnd.format(variables._preview_date_format);
					}
					wcagTimeRangeItem.innerHTML = text;
				}
			};

			switch (view) {
				case 'calendar1':
				case 'calendar2':
					localizeCalendar(el);
					break;
				default:
					// Do nothing for other views atm...
			}
		}
	};

	return service;
}
