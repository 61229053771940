import UserAddModalController from './UserAddModalController';
import ImportModalController from '../../../common/vngageImportModal/ImportModalController';
import UserEditController from './UserEditController';
import UserListController from './UserListController';
import userListTableConfig from './UserListTableConfig';
import UserService from './UserService';
import userTranslations from './UserTranslations';
import UserListTemplate from './UserListTemplate';
import UserEditTemplate from './UserEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.account.user', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('UserAddModalController', UserAddModalController)
	.controller('ImportModalController', ImportModalController)
	.controller('UserEditController', UserEditController)
	.controller('UserListController', UserListController)
	.value('userListTableConfig', userListTableConfig)
	.factory('UserService', UserService)
	.constant('userTranslations', userTranslations)

	.constant('userModuleConfig', {
		state: 'root.account.user',
		access: 'Account/Users'
	})

	.config(function ($stateProvider, $translateProvider, userTranslations, userModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(userTranslations);

		$stateProvider.state(userModuleConfig.state, {
			url: 'user/',
			templateUrl: UserListTemplate,
			controller: 'UserListController as vm',
			data: {
				ncyBreadcrumbLabel: 'Users',
				access: userModuleConfig.access
			},
			resolve: {
				userList: function (APIEndpointService, IdentityService) {
					'ngInject';
					const params = {limit: 10000};
					return APIEndpointService.user.query(params).$promise.then(function (userList) {
						if (IdentityService.currentlyDelegated()) {
							return userList;
						} else {
							// Only return non-delegated users
							return userList.filter(function (user) {
								return user.data.type === 0;
							});
						}
					});
				},
				groupList: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.group.query().$promise;
				},
				roleClaimList: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.roleClaim.query().$promise;
				},
				timeZones: function (TimeZoneService) {
					'ngInject';
					return TimeZoneService.query();
				},
				accountConfiguration: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.accountCurrentConfiguration.get().$promise;
				}
			}
		});
		$stateProvider.state('root.account.user.edit', {
			url: ':id',
			controller: 'UserEditController as vm',
			templateUrl: UserEditTemplate,
			data: {
				ncyBreadcrumbLabel: 'Edit',
				access: {action: 'GET', resource: 'User/*'}
			},
			params: {currentList: []},
			resolve: {
				roleClaimList: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.roleClaim.query().$promise;
				},
				user: function ($stateParams, userList, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(userList, $stateParams.id);
				},
				timeZones: function (TimeZoneService) {
					'ngInject';
					return TimeZoneService.query();
				},
				accountConfiguration: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.accountCurrentConfiguration.get().$promise;
				}
			}
		});
	});
