import PointOfInterestAddModalController from './PointOfInterestAddModalController';
import PointOfInterestEditController from './PointOfInterestEditController';
import PointOfInterestListController from './PointOfInterestListController';
import pointOfInterestTranslations from './PointOfInterestTranslations';
import pointOfInterestTypes from './PointOfInterestTypes';
import PointOfInterestListTemplate from './PointOfInterestListTemplate';
import PointOfInterestEditTemplate from './PointOfInterestEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.analyze.pointOfInterest', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('PointOfInterestAddModalController', PointOfInterestAddModalController)
	.controller('PointOfInterestEditController', PointOfInterestEditController)
	.controller('PointOfInterestListController', PointOfInterestListController)
	.constant('pointOfInterestTranslations', pointOfInterestTranslations)
	.value('pointOfInterestTypes', pointOfInterestTypes)

	.constant('pointOfInterestModuleConfig', {
		access: 'Engage/PointOfInterest',
		state: 'root.analyze.pointOfInterest'
	})

	.config(function ($stateProvider, $translateProvider, pointOfInterestTranslations, pointOfInterestModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(pointOfInterestTranslations);

		$stateProvider.state(pointOfInterestModuleConfig.state, {
			url: 'pointofinterest/',
			controller: 'PointOfInterestListController as vm',
			templateUrl: PointOfInterestListTemplate,
			data: {
				access: pointOfInterestModuleConfig.access,
				ncyBreadcrumbLabel: 'Point Of Interest'
			},
			resolve: {
				pointOfInterestList: function (APIEndpointService, AccountSitesService) {
					'ngInject';
					var siteId = AccountSitesService.getSelectedSiteId();
					return APIEndpointService.pointOfInterest.query({siteId: siteId}).$promise;
				}
			}
		});

		$stateProvider.state('root.analyze.pointOfInterest.edit', {
			url: ':id',
			controller: 'PointOfInterestEditController as vm',
			templateUrl: PointOfInterestEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				pointOfInterest: function ($stateParams, pointOfInterestList, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(pointOfInterestList, $stateParams.id);
				}
			}
		});
	});
