import React from 'react';
import {useGetTranslation} from './../../LangContext';

const StatusBar = (props) => {
	const getTranslation = useGetTranslation();
	let coBroStatusText = '';
	let icon = '';
	let template = '';
	let coBroStatus = '';

	if (props.paused) {
		coBroStatus = 'paused';
	} else if (props.doNotDisturb) {
		coBroStatus = 'doNotDisturb';
	} else if (props.restricted) {
		coBroStatus = 'restricted';
	}

	switch (coBroStatus) {
		case 'restricted':
			coBroStatusText = getTranslation('coStatusRestricted');
			icon = 'vngage-icon-block-1';
			template = 'restricted';
			break;
		case 'doNotDisturb':
			coBroStatusText = getTranslation('coStatusDoNotDisturb');
			icon = 'vngage-icon-eye-1';
			template = 'view-only';
			break;
		case 'paused':
			coBroStatusText = getTranslation('coStatusPaused');
			icon = 'vngage-icon-pause';
			template = 'deactivated';
			break;
		default:
			break;
	}
	let wiggle = template === 'view-only' || template === 'conn-error' ? 'wiggle1': '';
	let cls = `co-status ${template} ${wiggle}`;
	return (
		<div className={cls}>
			<i className={icon}/><span>{coBroStatusText}</span>
		</div>
	)
};
export default StatusBar;

