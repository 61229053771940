import OpportunityGeneratorTemplate from './OpportunityGeneratorTemplate';

export default function(VngageUtil, OpportunityGeneratorService) {
    'ngInject';

    return {
        restrict: 'E',
        require: '^?form',
        scope: {
            opportunity: '=',
            reloadAfterSave: '=',
            pointOfInterestList: '='
        },
        templateUrl: OpportunityGeneratorTemplate,
        link: function(scope, iElem, iAttrs, formCtrl) {

            scope.conditionOperators = OpportunityGeneratorService.getConditionOperators();

            // HACK fixes the first condition being reloaded properly when saving the opportunity
            scope.reloadAfterSave = true;
            scope.fakeRepeater = [1];
            scope.$watch('reloadAfterSave', function(trigger) {
                if (trigger) {
                    scope.fakeRepeater[0]++;
                    scope.reloadAfterSave = false;
                }
            });

            initializeBaseCondition();

            // Initialize the base condition model
            function initializeBaseCondition() {
                if (!scope.opportunity.condition) {
                    // if the opportunity.condition is empty, populate it with a template object
                    angular.extend(scope.opportunity, OpportunityGeneratorService.getConditionEmptyTemplate(true));
                } else if (!scope.opportunity.condition.conditions) {
                    // if the opportunity.condition.conditions is missing, assign an empty array
                    angular.extend(scope.opportunity.condition, { conditions: [] });
                }
            }

            scope.addCondition = function() {
                scope.opportunity.condition.conditions.push(OpportunityGeneratorService.getConditionEmptyTemplate().condition);
                if (formCtrl) {
                    formCtrl.$setDirty();
                }
            };

            scope.removeCondition = function() {
                if (VngageUtil.confirmSimple('Sure you want to delete?')) {
                    scope.opportunity.condition.conditions.splice(this.$index, 1);
                    if (formCtrl) {
                        formCtrl.$setDirty();
                    }
                }
            };
        }
    };
};
