import vngageAddModalTemplate from '../../../common/vngageAddModalTemplate';
import vngageImportModalTemplate from '../../../common/vngageImportModal/vngageImportModalTemplate';
import {fileImportHeaders} from './FileImportHeaders';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

export default function ($uibModal, $state, dataLists, formList) {
	'ngInject';

	const vm = this;
	vm.caseTypeList = dataLists.customerCase;

	vm.tableColumns = [{
		header: 'Name',
		property: 'data.name',
		sortDefault: true,
		useInFilter: true,
		width: '25%'
	}, {
		header: 'GUID',
		property: 'data.id',
		hidden: true,
		useInFilter: true
	}, {
		header: 'Action Panel',
		property: 'data.section.actionPanels',
		transform: getActionPanelName,
		type: 'label-list'
	}, {
		header: 'Canned Responses',
		property: 'data.section.cannedResponses',
		transform: getCannedResponseName,
		type: 'label-list'
	}, {
		header: 'Closures',
		property: 'data.section.closures',
		transform: getClosureName,
		type: 'label-list'
	}];
	vm.tableOptions = {
		stateLink: {
			state: 'root.configure.caseType.edit',
			property: 'data.id'
		},
		createItem: {
			langKey: 'caseType.ADD_HEADER',
			method: addNew
		},
		importCSV: {
			langKey: 'uiElement.IMPORT_CSV',
			method: importNew,
			id: 'import-btn'
		}
	};


	// create an object that maps numbers to days
	const caseTimeToLiveValues = Array(2000).fill(0).map((value, index) => index + 1 + '');
	const caseTimeToLiveValueMap = caseTimeToLiveValues.reduce((obj, nbr) => ({
		...obj,
		[nbr]: `${nbr}.00:00:00`
	}), {});


	// map data to headers
	vm.headers = fileImportHeaders.map(header => {
		return header.name === 'actionPanels'
			? {
				...header,
				values: dataLists.actionPanel.map(item => item.key),
			}
			: header;
	}).map(header => {
		return header.name === 'cannedResponses'
			? {
				...header,
				values: dataLists.cannedResponse.map(item => item.key),
			}
			: header;
	}).map(header => {
		return header.name === 'closures'
			? {
				...header,
				values: dataLists.closure.map(item => item.key),
			}
			: header;
	}).map(header => {
		return header.name === 'forms'
			? {
				...header,
				values: formList.map(item => item.key),
			}
			: header;
	});

	const openAddModal = function () {
		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: vngageAddModalTemplate,
			controller: 'CaseTypeAddModalController as vm',
			resolve: {
				options: () => {
					return {
						header: 'caseType.ADD_HEADER',
						label: 'caseType.NAME'
					};
				}
			}
		});
		modal.result.then(newCaseType => {
			vm.caseTypeList.push(newCaseType);
			$state.go('.edit', {id: newCaseType.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	};

	function openImportModal() {
		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: vngageImportModalTemplate,
			controller: 'ImportModalController as vm',
			size: 'lg',
			resolve: {
				options: () => {
					return {header: 'caseType.IMPORT_HEADER'};
				},
				itemsList: () => {
					return vm.caseTypeList;
				},
				itemType: () => 'customerCase',
				isSection: () => true,
				headers: () => vm.headers,
				extraData: () => {
					return {'name': {uniqueValues: vm.caseTypeList.map(caseType => caseType.data.name)}};
				}
			}
		});
		modal.result.then(newCaseType => {
		}).catch(() => {
			// Modal dismissed...
		});

	}

	function importNew() {
		$state.go('root.configure.caseType').then(openImportModal);
	}

	function addNew() {
		$state.go('root.configure.caseType').then(openAddModal);
	}

	function getClosureName(closureList) {
		const list = closureList || [];
		const trimmedList = list.length > 6 ? list.slice(0, 5): list.slice(0, 6);
		const resource = _map(_filter(dataLists.closure, caseTypeClosure => {
			return _find(trimmedList, closure => {
				return caseTypeClosure.data.id === closure;
			});
		}), res => {
			return res.data.name;
		});
		if (trimmedList.length < list.length) {
			resource.push('+' + (list.length - trimmedList.length) + ' more');
		}
		return resource;
	}


	function getCannedResponseName(cannedResponsesList) {
		const list = cannedResponsesList || [];
		const trimmedList = list.length > 6 ? list.slice(0, 5): list.slice(0, 6);

		const resource = _map(_filter(dataLists.cannedResponse, resource => {
			return _find(trimmedList, cannedResponses => {
				return cannedResponses === resource.data.id;
			});
		}), res => {
			return res.data.name;
		});
		if (trimmedList.length < list.length) {
			resource.push('+' + (list.length - trimmedList.length) + ' more');
		}
		return resource;
	}

	function getActionPanelName(actionPanelList) {
		const list = actionPanelList || [];
		const trimmedList = list.length > 6 ? list.slice(0, 5): list.slice(0, 6);
		const resource = _map(_filter(dataLists.actionPanel, resource => {
			return _find(trimmedList, actionPanel => {
				return actionPanel === resource.data.id;
			});
		}), res => {
			return res.data.name;
		});
		if (trimmedList.length < list.length) {
			resource.push('+' + (list.length - trimmedList.length) + ' more');
		}
		return resource;
	}
};
