import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _reject from 'lodash/reject';
import assign from 'object-assign';
import AppDispatcher from '../dispatcher/AppDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import StoreFactory from '../../../components/utils/StoreFactory';
import WebAPIUtils from '../utils/WebAPIUtils';
import MessageUtils from '../../../components/utils/MessageUtils';
import Activity from '../../../components/utils/Activity';
import ParticipantUtils from '../../../components/utils/ParticipantUtils';
import ProfileStore from './ProfileStore';

const ActionTypes = AppConstants.ActionTypes;

var state = {
	participants : [],
	writings : [],
	events: {}
};

var ParticipantStore = StoreFactory.create({

	/**
	 * return currently logged in user as participant
	 * @returns {Object} matched participant or undefined
	 */
		getParticipantMe () {
		return _find(state.participants, {userId: ProfileStore.getProfileId()});
	},

	/**
	 * returns all active participants of conversation
	 * @param {String} conversationId
	 * @returns {Array} participants filtered array
	 */
		getParticipantsOfConversation (conversationId) {
		return _filter(state.participants, p => {
			return p.conversationId === conversationId && p.state !== 'left';
		});
	},

	/**
	 * return specific participant of conversation based on entity id
	 * @param {String} conversationId
	 * @param {String} participantId
	 * @returns {Object} matched participant or undefined
	 */
		getParticipantOfConversationById (conversationId, participantId) {
		return _find(state.participants, p => {
			let id = ParticipantUtils.getParticipantEntityId(p);
			return id === participantId && p.conversationId === conversationId;
		});
	},

	/**
	 * returns participants in conversation currently typing 'activity'
	 * @param {String} conversationId
	 * @returns {Array} participants filtered array
	 */
		getWritingParticipantsOfConversation (conversationId) {
		return _filter(state.writings, participant => {
			let speakerId = participant.speaker.userId || participant.speaker.visitId;
			return participant.conversationId === conversationId && speakerId !== ProfileStore.getProfileId();
		});
	},

	/**
	 * get first found agent participant of conversation
	 * @param {String} conversationId
	 * @returns {Object} matched participant or undefined
	 */
		getAgentParticipantOfConversation (conversationId) {
		return _find(state.participants, participant => {
			return participant.info.titleId === 'agent' && participant.conversationId === conversationId;
		});
	},

	/**
	 * get first found visitor participant of conversation
	 * @param {String} conversationId
	 * @returns {Object} matched participant or undefined
	 */
		getVisitorParticipantOfConversation (conversationId) {
		return _find(state.participants, participant => {
			return participant.info.titleId === 'visitor' && participant.conversationId === conversationId;
		});
	},

	/**
	 * get first found guest participant of conversation
	 * @param {String} conversationId
	 * @returns {Object} matched participant or undefined
	 */
		getGuestParticipantOfConversation (conversationId) {
		return _find(state.participants, participant => {
			return participant.info.titleId === 'guest' && participant.state === 'joined' && participant.conversationId === conversationId;
		});
	}
});

const registeredCallback = function(payload) {
	const action = payload.action;
	const participant = action.participant;
	const conversationId = action.conversationId;
	let msgInConversation = [];


	switch (action.type) {

		case ActionTypes.RECEIVE_AS_STATE :
			state.participants = _reject(state.participants, p => p.conversationId === conversationId);
			msgInConversation = MessageUtils.addConversationIdToMsg(action.conversationState.participants, conversationId);
			addParticipants(msgInConversation);
			break;

		case ActionTypes.RECEIVE_SINCE :
			msgInConversation = MessageUtils.addConversationIdToMsg(action.rawMessages, conversationId);

			let participantConnectionMsgs = _filter(msgInConversation, msg => msg.type === 'participantConnectionStatus'),
				participantActivityMsgs   = _filter(msgInConversation, msg => msg.messageType === 'activity');

			participantConnectionMsgs.forEach( m => {
				let participantId = ParticipantUtils.getParticipantEntityId(m);
				let p = ParticipantStore.getParticipantOfConversationById(conversationId, participantId);
				if (p) {
					p.connectionState = m.connectionState;
				}
			});

			// handles only participantWrting activity for now
			let writingsChange = false;
			if (participantActivityMsgs.length) {
				let msgsWithoutMe = MessageUtils.getMessagesWithoutMe(participantActivityMsgs, ProfileStore.getProfileId());
				let writingPreMap = state.writings.slice(0);
				state.writings = Activity.filterWritings(conversationId, msgsWithoutMe, state.writings);

				if (JSON.stringify(state.writings) !== JSON.stringify(writingPreMap)) {
					writingsChange = true;
				}
			}
			if (!writingsChange && participantConnectionMsgs.length === 0) {
				return false;
			}

			break;

		case ActionTypes.PARTICIPANT_JOINED:
			let joinedFiltered = participant.filter( p => {
				let conversationEvents = state.events[conversationId];
				let processed = conversationEvents && conversationEvents.indexOf(p.eventId) > -1;
				if (!processed) {
					state.events[conversationId] = state.events[conversationId] || [];
					state.events[conversationId].push(p.eventId);
				}
				return !processed;
			});

			joinedFiltered.map( p => {
				const pid = ParticipantUtils.getParticipantEntityId(p);
				addParticipant(ParticipantUtils.mapJoinedParticipant(conversationId, p, pid));
			});

			break;

		case ActionTypes.PARTICIPANT_LEFT:
			let leftFiltered = participant.filter( p => {
				let conversationEvents = state.events[conversationId];
				let processed = conversationEvents && conversationEvents.indexOf(p.eventId) > -1;
				if (!processed) {
					state.events[conversationId] = state.events[conversationId] || [];
					state.events[conversationId].push(p.eventId);
				}
				return !processed;
			});

			let didLeaveFromConversation;
			leftFiltered.map(participant => {
				if (ParticipantUtils.mapParticipantLeft(conversationId, state.participants, participant)) {
					didLeaveFromConversation = true;
				}
			});

			if (!didLeaveFromConversation) {
				return false;
			}

			break;

		case ActionTypes.PARTICIPANT_AUTHORIZATION :
			state.participants = state.participants.map( p => {
				if (action.participantId === p.id) {
					p = ParticipantUtils.setAbility(p, action.authorization, action.value);
				}
				return p;
			});
			break;

		case ActionTypes.CONVERSATION_CLOSED :
			state.participants = _reject(state.participants, p => p.conversationId === conversationId);
			state.writings = _reject(state.writings, p => p.conversationId === conversationId);
			delete state.events[conversationId];
			return false;

			break;

		default :
			return true;
	}

	// if we made it down to here, emit change
	ParticipantStore.emitChange();
};


function addParticipant(participant) {
	let speakerId = ParticipantUtils.getParticipantEntityId(participant),
		participantRecord;

	state.participants = ParticipantUtils.rejectMatchingParticipant(state.participants, participant, speakerId);
	participant.colorId = ParticipantUtils.mapParticipantColor(participant, 'internalExpert');

	// mark participant if same as current logged in user
	if (speakerId === ProfileStore.getProfileId()) {
		participant.info.isCurrentUser = true;
	}

	participantRecord = ParticipantUtils.createParticipant(participant);

	participantRecord.abilities.coBrowsing = ParticipantUtils.hasAbility(participantRecord, 'coBrowsing');
	participantRecord.abilities.video = ParticipantUtils.hasAbility(participantRecord, 'video');

	state.participants.push(participantRecord);
}

function addParticipants(participants) {
	participants.map(addParticipant);
}

ParticipantStore.dispatchToken = AppDispatcher.register( registeredCallback );

export default ParticipantStore;
