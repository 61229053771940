import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import {connect, useSelector, useDispatch} from 'react-redux';
import {useTransition, animated, config} from 'react-spring';
import {useWhyDidYouUpdate} from './../../ReactHooks';
import immutable from 'immutable';
import PanelWrapper from './PanelWrapper.react';
import QueueSelectedItems from './../QueueSelectedItems.react';

const panelWidth = 273;
const panelMargin = 25;
const renderedPanel = panelWidth + panelMargin;
const overlapLimit = 50;// overlap changed from 10

export const View = (props) => {

	// useWhyDidYouUpdate('View', props);

	const [containerDim, setContainerDim] = useState({panelContainerHeight: 510, panelContainerWidth: 1141});
	const container = useRef();

	// handler for container resize
	useEffect(() => {
		const panelContainerHeight = container.current.offsetHeight;
		const panelContainerWidth = container.current.offsetWidth;
		setContainerDim({panelContainerHeight, panelContainerWidth});

	}, [props.queuePanelPinned, props.windowHeight, props.windowWidth, props.selectedPlugin]);

	const panelPositionParams = useMemo(() => {
		const width = containerDim.panelContainerWidth;
		const size = props.panelOrder.size;
		const sizeDenominator = size - 1 ? size - 1: 1;
		const containerDiff = (width - renderedPanel) / sizeDenominator;
		const hoverSub = 0;//10; upward movement on hover disabled
		const normalPos = 10;
		const collapsedPos = (containerDim.panelContainerHeight * 0.8) - 30;
		const hiddenPos = containerDim.panelContainerHeight;

		const activePanel = props.activePanel;
		const inCoBro = !!props.coBroConversationId;
		const selectedConv = activePanel;


		let selectedConvIndex = props.panelOrder.indexOf(selectedConv);
		selectedConvIndex = selectedConvIndex < 0 ? 1000: selectedConvIndex;

		let panelSub = Math.min(renderedPanel, containerDiff);
		let xStart = (panelSub < renderedPanel) ? 0: width - (size * panelSub);

		if (inCoBro) {
			// coBrowsing open
			panelSub = (width - (renderedPanel * 2)) / sizeDenominator;
			xStart = renderedPanel;
		}

		const selectedConvPos = xStart + (selectedConvIndex * panelSub);
		const preSubSize = selectedConvIndex > 1 ? selectedConvIndex - 1: 1;
		let preSub = Math.max((selectedConvPos - renderedPanel) / preSubSize, overlapLimit);
		if (inCoBro) {
			// coBrowsing open
			preSub = Math.max((selectedConvPos - renderedPanel - renderedPanel) / preSubSize, overlapLimit);
		}

		const postSubSize = (size - selectedConvIndex - 2) > 0 ? size - selectedConvIndex - 2: 1;
		const postSub = (width - (selectedConvPos + renderedPanel + renderedPanel)) / postSubSize;
		const lastPos = width - renderedPanel;
		const doSub = panelSub !== renderedPanel && size > 3;
		return {
			collapsedPos, normalPos, hiddenPos,
			selectedConv, selectedConvIndex, selectedConvPos,
			hoverSub, doSub,
			panelSub,
			preSub, postSub,
			xStart,
			renderedPanel,
			lastPos
		};
	}, [containerDim, props.panelOrder, props.activePanel, props.coBroConversationId]);


	const coords = useMemo(() => {
		const getXYZPos = (conversationId, indexPos) => {
			let xPos = 0;
			const panelPosition = props.panelPositions.get(indexPos);
			const isCollapsed = panelPosition === 'collapsed';
			const isCoBro = panelPosition === 'coBro';
			let yPos = isCollapsed ? panelPositionParams.collapsedPos: panelPositionParams.normalPos;
			yPos = panelPositionParams.selectedConv === conversationId ? yPos - panelPositionParams.hoverSub: yPos;
			if (!isCoBro) {
				if (panelPositionParams.selectedConvIndex !== 1000 && panelPositionParams.doSub) {
					if (indexPos === panelPositionParams.selectedConvIndex) {
						// hovered item
						xPos = panelPositionParams.xStart + (indexPos * panelPositionParams.panelSub);
					} else if (indexPos < panelPositionParams.selectedConvIndex) {
						// left of hovered item
						xPos = panelPositionParams.xStart + (indexPos * panelPositionParams.preSub);
					} else {
						// right of hovered item
						xPos = panelPositionParams.selectedConvPos + renderedPanel + ((indexPos - panelPositionParams.selectedConvIndex - 1) * panelPositionParams.postSub);
					}

				} else {
					// no hover
					xPos = panelPositionParams.xStart + (indexPos * panelPositionParams.panelSub);
				}
				xPos = Math.min(xPos, panelPositionParams.lastPos);
			} else {
				// in coBro
				xPos = 0;
				yPos = 0;
			}
			// zPos is used for z-index, normal:20, coBro:10, active +5
			const zPos = (isCoBro ? 10: 20) + (panelPositionParams.selectedConv === conversationId ? 5: 0);
			return [xPos, yPos, zPos];
		};

		return props.panelOrder
			.map((conversationId, indexPos) => {
				const [xPos, yPos, zPos] = getXYZPos(conversationId, indexPos);
				return {
					conversationId,
					xPos,
					yPos,
					zPos
				};
			}).toJS();
	}, [props.panelOrder, panelPositionParams, props.panelPositions]);

	const transitions = useTransition(coords,
		item => item.conversationId,
		{
			from: item => ({transform: `translate3d(${item.xPos}px,${item.yPos}px,0)`}),
			leave: item => ({transform: `translate3d(${item.xPos}px,${item.yPos}px,0)`}),
			enter: item => ({transform: `translate3d(${item.xPos}px,${item.yPos}px,0)`}),
			update: item => ({transform: `translate3d(${item.xPos}px,${item.yPos}px,0)`}),
			// config: {tension: 230, clamp: true}
			config: {duration: 200}
		});
	const width = containerDim.panelContainerWidth;
	const size = props.panelOrder.size;
	const endX = width - (renderedPanel * size) - (renderedPanel / 2);
	const endY = containerDim.panelContainerHeight - 250;
	const QueueSelectedItemsPayload = {
		startX: width,
		endX,
		endY,
		addX: renderedPanel,
		selectedItemPositions: props.selectedItemPositions,
	};

	const disableSelectStyle = {
		// Style for fixing issue where Firefox selected everything (also inside dialog panels) when clicking the dialog-panel-wrapper (background)
		// And for some weird reason, we need to specify *BOTH* MozUserSelect and WebkitUserSelect - one of them is not enough (probably a Firefox bug)
		// Note: This style should be "unset" in each child component that should have selectable content, as it will otherwise be inherited ...
		MozUserSelect: 'none',
		WebkitUserSelect: 'none',
		// msUserSelect: 'none',
		// userSelect: 'none'
	};

	const enableSelectStyle = {
		// Resets default select style
		MozUserSelect: 'text',
		WebkitUserSelect: 'text',
		// msUserSelect: 'text',
		// userSelect: 'text'
	};

	const panelPayload = {
		...containerDim
	};

	return (
		<div className="dialog-panel-wrapper" ref={container} style={disableSelectStyle}>
			<QueueSelectedItems {...QueueSelectedItemsPayload} style={enableSelectStyle}/>
			{transitions.map(({item, props, key}) => {
				return item &&
					<animated.div key={key} style={{...props, zIndex: item.zPos}}>
						<PanelWrapper conversationId={item.conversationId} {...panelPayload}/>
					</animated.div>;
			})}
		</div>
	);
};

const ViewContainer = (props) => {
	const panelOrder = useSelector(state => state.get('panelOrder'), immutable.is);
	const panelPositions = useSelector(state => state.get('panelOrder').map(conversationId => state.getIn(['conversations', conversationId, 'UI', 'panelPosition'])), immutable.is);
	const queuePanelPinned = useSelector(state => !!state.getIn(['userPreferences', 'queuePanelPinned']));
	const selectedItemPositions = useSelector(state => state.get('selectedItemPositions'));
	const activePanel = useSelector(state => state.get('activePanel'));
	const coBroConversationId = useSelector(state => state.getIn(['coBro', 'conversationId']));
	const selectedPlugin = useSelector(state => state.get('selectedPlugin'));
	const {windowHeight, windowWidth} = props;

	const payload = {
		panelOrder,
		panelPositions,
		queuePanelPinned,
		selectedItemPositions,
		activePanel,
		coBroConversationId,
		selectedPlugin,
		windowHeight,
		windowWidth
	};
	return <View {...payload}/>;
};

View.displayName = 'View';
ViewContainer.displayName = 'ViewContainer';
export default ViewContainer;


