import _find from 'lodash/find';

export default function (GroupOverviewKPIService, APIStatisticsService, APIEndpointService, TimeRangeService, UserProfilePictureCacheService) {
	'ngInject';

	const groupOverview = {
		statistics: {}
	};

	let rawRealtimeData;
	let rawAggregateData;
	let rawConversationData;

	const service = {
		setRealTimeData: setRealTimeData, // Call this manually when new data from realtimeData has been fetched
		queryAndMapGroupOverview: queryAndMapGroupOverview,
		groupOverview: groupOverview // Access to parsed data
	};

	function setRealTimeData(newRealtimeData) {
		rawRealtimeData = newRealtimeData.interactionOverview;
		rawConversationData = newRealtimeData.conversationCurrent;
		mapRealTime();
	}

	function setAggregateData(newAggregateData) {
		rawAggregateData = newAggregateData.data;
		mapRealTime();
	}

	function queryAndMapGroupOverview() {
		const today = TimeRangeService.create('today');
		const range = {
			from: today.from(),
			to: today.to()
		};

		return APIStatisticsService.queryGroupOverview(range).then(function (res) {
			setAggregateData(res);
		});
	}

	function mapKPIs() {
		GroupOverviewKPIService.initialize().then(function () {
			if (groupOverview && groupOverview.statistics && groupOverview.statistics.groups) {
				groupOverview.statistics.groups.forEach(function (group) {
					group.kpis = GroupOverviewKPIService.validate(group);
				});
			}
		});
	}

	function mapRealTime() {
		const groupOverviewData = rawAggregateData || [];	// Data from endpoint "/Statistics/GroupOverview"
		const interactionOverviewData = rawRealtimeData && rawRealtimeData.groups ? rawRealtimeData : {groups: []};	// Data from endpoint "/Statistics/InteractionOverview"
		const conversationData = rawConversationData || [];	// Data from endpoint "/Conversation/Current"

		let maxWaitingDurationMs     = 0,
			numberOfOnlineUsers      = 0,
			numberOfVisitorsInQueue  = 0,
			numberOfVisitorsInDialog = 0;

		// VEP-3476: We need to store some more data to be able to calculate avg. and max values for summary
		let globalNumberOfVisitorsInQueueCalculated  = 0,
			globalTotalWaitingDurationMs             = 0,
			globalAvgWaitingDurationCalculatedMs     = 0,
			globalMaxWaitingDurationCalculatedMs     = 0,

			globalNumberOfVisitorsInDialogCalculated = 0,
			globalTotalDialogDurationMs              = 0,
			globalAvgDialogDurationCalculatedMs      = 0,
			globalMaxDialogDurationCalculatedMs      = 0,

			globalHungUpDialogsCalculated            = 0,
			globalTotalHungUpDurationMs              = 0,
			globalAvgHungUpDurationCalculatedMs      = 0,
			globalMaxHungUpDurationCalculatedMs      = 0;

		// if no realtime-data present or if actual statistics data present then
		// just set groupOverview.statistics to its current value
		if (!interactionOverviewData) {
			groupOverview.statistics.groups = groupOverviewData;
			mapKPIs();
			return groupOverview.statistics.groups;
		}

		if (interactionOverviewData.users) {
			interactionOverviewData.users.forEach(function (user) {
				user.profilePicture = UserProfilePictureCacheService.get(user.userId);
				if (user.availableInGroups.length > 0) {
					numberOfOnlineUsers++;
				}
			});
		}

		// honor groups of realtime-data
		const newGroupOverviewData = [];
		interactionOverviewData.groups.forEach(function (g) {
			const statisticsGroup = _find(groupOverviewData, {groupId: g.groupId}) || {};
			newGroupOverviewData.push({
				'groupId': g.groupId,
				'numberOfOnlineUsers': g.numberOfOnlineUsers,
				'numberOfVisitorsInQueue': g.numberOfVisitorsInQueue,
				'numberOfVisitorsInDialog': g.numberOfVisitorsInDialog,
				'calculatedAt': statisticsGroup.calculatedAt || new Date(),
				'groupName': g.name,
				'maxWaitingDurationMs': g.maxWaitTimeMs || 0,
				'avgDialogDurationMs': statisticsGroup.avgDialogDurationMs || 0,
				'avgWaitingDurationMs': statisticsGroup.avgWaitingDurationMs || 0,

				// VEP-3476: We need to store some more data to be able to calculate avg. and max values for this group (values calculated below when analyzing conversationData)
				'numberOfVisitorsInQueueCalculated': 0,
				'totalWaitingDurationCalculatedMs': 0,
				'avgWaitingDurationCalculatedMs': 0,
				'maxWaitingDurationCalculatedMs': 0,

				'numberOfVisitorsInDialogCalculated': 0,
				'totalDialogDurationCalculatedMs': 0,
				'avgDialogDurationCalculatedMs': 0,
				'maxDialogDurationCalculatedMs': 0,

				'hungUpDialogsCalculated': 0,
				'totalHungUpDurationCalculatedMs': 0,
				'avgHungUpDurationCalculatedMs': 0,
				'maxHungUpDurationCalculatedMs': 0
			});

			if (g.maxWaitTimeMs > maxWaitingDurationMs) {
				maxWaitingDurationMs = g.maxWaitTimeMs;
			}
			numberOfVisitorsInQueue += g.numberOfVisitorsInQueue || 0;
			numberOfVisitorsInDialog += g.numberOfVisitorsInDialog || 0;
		});

		// VEP-3476: Analyze conversationData (from endpoint "/Conversation/Current")
		conversationData.forEach(function (conversation) {
			const currentDataGroup = _find(newGroupOverviewData, {groupId: conversation.groupId});
			if (currentDataGroup) {
				// Only process conversations belonging to known groups

				switch (conversation.state) {
					case 1:
						// Conversation for visitor waiting in queue
						globalNumberOfVisitorsInQueueCalculated++;
						currentDataGroup.numberOfVisitorsInQueueCalculated++;

						const waitingTime = conversation.durationSinceStateChangeMs; // Or use conversation.durationSinceRequestConversationMs?
						globalTotalWaitingDurationMs += waitingTime;
						currentDataGroup.totalWaitingDurationCalculatedMs += waitingTime;

						currentDataGroup.avgWaitingDurationCalculatedMs = (currentDataGroup.totalWaitingDurationCalculatedMs / currentDataGroup.numberOfVisitorsInQueueCalculated);

						if (waitingTime > globalMaxWaitingDurationCalculatedMs) {
							globalMaxWaitingDurationCalculatedMs = waitingTime;
						}
						if (waitingTime > currentDataGroup.maxWaitingDurationCalculatedMs) {
							currentDataGroup.maxWaitingDurationCalculatedMs = waitingTime;
						}
						break;
					case 4:
						// Conversation in dialog
						globalNumberOfVisitorsInDialogCalculated++;
						currentDataGroup.numberOfVisitorsInDialogCalculated++;

						globalTotalDialogDurationMs += conversation.durationSinceStateChangeMs;
						currentDataGroup.totalDialogDurationCalculatedMs += conversation.durationSinceStateChangeMs;

						currentDataGroup.avgDialogDurationCalculatedMs = (currentDataGroup.totalDialogDurationCalculatedMs / currentDataGroup.numberOfVisitorsInDialogCalculated);

						if (conversation.durationSinceStateChangeMs > globalMaxDialogDurationCalculatedMs) {
							globalMaxDialogDurationCalculatedMs = conversation.durationSinceStateChangeMs;
						}
						if (conversation.durationSinceStateChangeMs > currentDataGroup.maxDialogDurationCalculatedMs) {
							currentDataGroup.maxDialogDurationCalculatedMs = conversation.durationSinceStateChangeMs;
						}
						break;

					case 5:
						// Conversation Hung-up
						globalHungUpDialogsCalculated++;
						currentDataGroup.hungUpDialogsCalculated++;

						globalTotalHungUpDurationMs += conversation.durationSinceStateChangeMs;
						currentDataGroup.totalHungUpDurationCalculatedMs += conversation.durationSinceStateChangeMs;

						currentDataGroup.avgHungUpDurationCalculatedMs = (currentDataGroup.totalHungUpDurationCalculatedMs / currentDataGroup.hungUpDialogsCalculated);

						if (conversation.durationSinceStateChangeMs > globalMaxHungUpDurationCalculatedMs) {
							globalMaxHungUpDurationCalculatedMs = conversation.durationSinceStateChangeMs;
						}
						if (conversation.durationSinceStateChangeMs > currentDataGroup.maxHungUpDurationCalculatedMs) {
							currentDataGroup.maxHungUpDurationCalculatedMs = conversation.durationSinceStateChangeMs;
						}
						break;
					default:
				}
			}
		});
		if (globalNumberOfVisitorsInQueueCalculated > 0) {
			globalAvgWaitingDurationCalculatedMs = (globalTotalWaitingDurationMs / globalNumberOfVisitorsInQueueCalculated);
		}
		if (globalNumberOfVisitorsInDialogCalculated > 0) {
			globalAvgDialogDurationCalculatedMs = (globalTotalDialogDurationMs / globalNumberOfVisitorsInDialogCalculated);
		}
		if (globalHungUpDialogsCalculated > 0) {
			globalAvgHungUpDurationCalculatedMs = (globalTotalHungUpDurationMs / globalHungUpDialogsCalculated);
		}

		groupOverview.statistics.summary = {
			numberOfOnlineUsers: numberOfOnlineUsers,
			numberOfVisitorsInQueue: numberOfVisitorsInQueue,
			numberOfVisitorsInDialog: numberOfVisitorsInDialog,
			maxWaitingDurationMs: maxWaitingDurationMs,
			// numberOfVisitorsInQueue: 	globalNumberOfVisitorsInQueueCalculated,
			// numberOfVisitorsInDialog: 	globalNumberOfVisitorsInDialogCalculated,
			avgWaitingDurationCalculatedMs: globalAvgWaitingDurationCalculatedMs, // VEP-3476: Can't be used as long as /Conversation/Current contains ghost visitors in queue. Show -- in UI instead
			maxWaitingDurationCalculatedMs: globalMaxWaitingDurationCalculatedMs, // VEP-3476: Can't be used as long as /Conversation/Current contains ghost visitors in queue. Show the old "maxWaitingDurationMs" instead
			avgDialogDurationCalculatedMs: globalAvgDialogDurationCalculatedMs,
			maxDialogDurationCalculatedMs: globalMaxDialogDurationCalculatedMs,
			avgHungUpDurationCalculatedMs: globalAvgHungUpDurationCalculatedMs,
			maxHungUpDurationCalculatedMs: globalMaxHungUpDurationCalculatedMs,
		};

		// assign mapped data to statistics
		groupOverview.statistics.groups = newGroupOverviewData;
		mapKPIs();
	}

	return service;
}
