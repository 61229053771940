export default function ($scope, $rootScope, $parse, $state, $transclude, VngageUtil, StateReloadService, vngageColumnTableResourceService, vngageConfig) {
	'ngInject';

	const vm = this;
	vm.$onInit = function () {
		vm.resource = vngageColumnTableResourceService(vm.resourceSource, vm.options, vm.columns);
		vm.raisePrio = raisePriority;
		vm.lowerPrio = lowerPriority;
		vm.remove = removeItem;
		vm.transform = transformValue;
		vm.goToState = goToState;
		vm.value = getValue;
		vm.defaultAvatar = vngageConfig.staticWorkspaceUrl + 'img/gravatar.png';
		vm.authorize = $rootScope.authorize;

		$scope.$watchCollection('vm.resourceSource', function () {
			vm.resource.update(vm.resourceSource);
		});

		$scope.$watch('vm.filter', function () {
			vm.resource.filter(vm.filter);
		});
	};

	$transclude(clone => {
		vm.heading = clone.text();
	});

	function transformValue(value, method) {
		if (angular.isFunction(method)) {
			return method(value);
		}
		return value;
	}

	// Required, since item[property] does not work in template
	// if property has a format similar to: data.config.value
	function getValue(property, context) {
		const isNonEmptyString = property && angular.isString(property);
		return isNonEmptyString ? $parse(property)(context): context;
	}

	function raisePriority($event, item) {
		$event.stopPropagation();

		if (vm.options.priority.canRaise(item)) {
			vm.options.priority.raise(item);
			vm.resource.update();
		}
	}

	function lowerPriority($event, item) {
		$event.stopPropagation();

		if (vm.options.priority.canLower(item)) {
			vm.options.priority.lower(item);
			vm.resource.update();
		}
	}

	function removeItem($event, item) {
		$event.stopPropagation();

		if (VngageUtil.confirmSimple('Are you sure you want to delete?')) {
			item.$remove().then(function () {
				StateReloadService.reload();
			});
		}
	}

	function goToState(item) {
		const currentList = vm.resource && vm.resource.list ? vm.resource.list: [];
		const state = vm.options.stateLink.state;
		let stateId;

		if (angular.isFunction(vm.options.stateLink.method)) {
			stateId = vm.options.stateLink.method(item);
		} else if (angular.isString(vm.options.stateLink.property)) {
			stateId = $parse(vm.options.stateLink.property)(item);
		} else if (angular.isFunction(vm.options.stateLink.property)) {
			stateId = vm.options.stateLink.property(item);
		}

		if (state && stateId) {
			$state.go(state, {id: stateId, currentList});
		}
	}
}
