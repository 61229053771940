export default {
    'en-GB': {
        cannedResponse: {
            EDIT_HEADER: 'Edit canned response',
			EDIT_PREVIEW_MESSAGE: 'Preview',
            MESSAGE: 'Message',
            ADD_HEADER: 'New canned response',
			IMPORT_HEADER: 'Import canned responses from a file',
			LIST_HEADER: 'Canned responses',
			LIST_TITLE: 'List of canned responses for working site',
			LIST_DESCRIPTION: 'Agents can speed up their response times in dialogues by making frequent use of canned responses. These are texts that you can easily insert into the chat while in the desktop application, and edit them before you send.',
            LANGUAGE: 'Language',
            NAME: 'Name',
            DESCRIPTION: 'Description',

            REMOVE_HEADER: 'Remove canned response "{{ name }}"',
            REMOVE_MESSAGE: 'Note that any existing references to this canned response from case types also will be removed.'
        }
    },
	'sv-SE': {
		cannedResponse: {
			EDIT_HEADER: 'Edit canned response',
			EDIT_PREVIEW_MESSAGE: 'Preview',
			MESSAGE: 'Message',
			ADD_HEADER: 'New canned response',
			IMPORT_HEADER: 'Import canned responses from a file',
			LIST_HEADER: 'Canned responses',
			LIST_TITLE: 'List of canned responses for working site',
			LIST_DESCRIPTION: 'Agents can speed up their response times in dialogues by making frequent use of canned responses. These are texts that you can easily insert into the chat while in the desktop application, and edit them before you send.',
			LANGUAGE: 'Language',
			NAME: 'Name',
			DESCRIPTION: 'Description',

			REMOVE_HEADER: 'Remove canned response "{{ name }}"',
			REMOVE_MESSAGE: 'Note that any existing references to this canned response from case types also will be removed.'
		}
	}
};
