export default function ($scope, $uibModalInstance, APIConfigurationSectionService, options, cannedResponseList) {
	'ngInject';

	var vm = this;
	vm.options = options;
	vm.saveNew = saveNew;
	vm.cancelNew = $uibModalInstance.dismiss;
	vm.cannedResponseList = cannedResponseList;

	function saveNew() {
		var type = APIConfigurationSectionService.sectionMap.cannedResponse;
		vm.isSaving = true;
		$uibModalInstance.freeze(true);
		APIConfigurationSectionService.create(vm.newData, type, true)
			.then(function (res) {
				$uibModalInstance.close(res);
			})
			.catch(function () {
				$uibModalInstance.freeze(false);
				vm.isSaving = false;
			});
	}
}
