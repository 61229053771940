export default {
	'en-GB': {
		closure: {
			ADD_HEADER: 'New closure',
			EDIT_HEADER: 'Edit closure',
			LIST_HEADER: 'Closures',
			LIST_TITLE: 'List of closures for working site',
			LIST_DESCRIPTION: 'Closures defines the possible outcomes and reasons of a case. A closure can have a positive/neutral/negative outcome and a reason could be e.g. "Customer purchased insurance"',
			OUTCOME: 'Outcome',
			TEXT_TO_VISITOR: 'Text to Visitor',
			NAME: 'Name',
			DESCRIPTION: 'Description',
			IMPORT_HEADER: 'Import closures from a file',

			REMOVE_HEADER: 'Remove closure "{{ name }}"',
			REMOVE_MESSAGE: 'Note that any existing references to this closure from case types also will be removed.'
		}
	},
	'sv-SE': {
		closure: {
			ADD_HEADER: 'New closure',
			EDIT_HEADER: 'Edit closure',
			LIST_HEADER: 'Closures',
			LIST_TITLE: 'List of closures for working site',
			LIST_DESCRIPTION: 'Closures defines the possible outcomes and reasons of a case. A closure can have a positive/neutral/negative outcome and a reason could be e.g. "Customer purchased insurance"',
			OUTCOME: 'Outcome',
			TEXT_TO_VISITOR: 'Text to Visitor',
			NAME: 'Name',
			DESCRIPTION: 'Description',
			IMPORT_HEADER: 'Import closures from a file',

			REMOVE_HEADER: 'Remove closure "{{ name }}"',
			REMOVE_MESSAGE: 'Note that any existing references to this closure from case types also will be removed.'
		}
	}
};
