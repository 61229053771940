import moment from 'moment-timezone';
import _filter from '../../../../node_modules/lodash/filter';

export default function (dc, d3, crossfilter, MetricFormatter) {
	'ngInject';

	////////// Internal functions
	function resetDimensions(charts) {
		for (let key in charts) {
			if (charts[key].dimension) {
				charts[key].dimension.filterAll();
			}
		}
	}
	function clearAllCharts(charts) {
		for (let key in charts) {
			const c=charts[key];
			if (c.chart) {
				c.chart.expireCache();
				dc.deregisterChart(c.chart);
				c.chart.selectAll().remove();
			}
			if (key==='totals') {
				Object.keys(charts.totals).forEach(key => {
					charts.totals[key].selectAll().remove();
				});
			}
		}
		dc.deregisterAllCharts();
	}
	function metricTypeIsSummable(metricType) {
		return (metricType === 'number' || metricType === 'duration');
	}

	////////// Public functions
	function renderView(viewConfig, viewData) {
		const data = viewData.rawData;
		if (!viewData.charts) {
			// If !charts, this view has been destroyed while waiting for API-call => Abort further update/rendering...
			return;
		}

		if (!viewData.facts) {
			viewData.facts = crossfilter(data);
		} else {
			resetDimensions(viewData.charts); // Remove all applied filters
			viewData.facts.remove();
			viewData.facts.add(data);
		}
		clearAllCharts(viewData.charts);

		/** -------------------------------------------------------------------------------------* Bar charts */
		viewConfig.barFilters.forEach(m => {
			// Loop through and create charts for each object in config.viewConfig.barFilters

			if (!viewData.charts[m.dimension]) {
				// Create the dimension for this chart
				viewData.charts[m.dimension]={};
				viewData.charts[m.dimension].dimension = viewData.facts.dimension(d => {
					return d[m.dimension];
				});
				// Create the group for this chart
				const metricType = m.type || viewConfig.measureLookup[m.valueAccessor].type;
				if (metricTypeIsSummable(metricType)) {
					viewData.charts[m.dimension].dimensionGroup = viewData.charts[m.dimension].dimension.group().reduceSum(d => {
						return d[m.valueAccessor];
					});
				} else {
					viewData.charts[m.dimension].dimensionGroup = viewData.charts[m.dimension].dimension.group().reduceCount();
				}
				viewData.charts[m.dimension].barChartsValueOrder=false;
			}

			// Create and configure the actual chart
			viewData.charts[m.dimension].chart = dc.rowChart('#dc-' + m.dimension + '-chart');
			viewData.charts[m.dimension].chart.width(200)
				.height((viewData.charts[m.dimension].dimensionGroup.size() * 22) + 40)
				.margins({top: 0, right: 0, bottom: 40, left: 0})
				.x(d3.scale.linear().domain([6, 20]))
				.elasticX(true)
				.dimension(viewData.charts[m.dimension].dimension)
				.filterAll()
				.label(d => {
					return d.key + ' (' + d.value +')';
				})
				.renderLabel(true)
				.gap(1)
				.colors('#00b7c9')
				.transitionDuration(300)
				.group(viewData.charts[m.dimension].dimensionGroup)
				.ordering(d => {
					return (viewData.charts[m.dimension].barChartsValueOrder ? -d.value : d.key);
				})
				.on('filtered', function(chart, filter) {
					viewData.filtersUpdated();
				})
				.xAxis().ticks(4);
		});


		/** -------------------------------------------------------------------------------------* Time line */
		if (!viewData.charts.timeLineGraph) {
			// Create the dimension for the time line chart
			viewData.charts.timeLineGraph={};
			viewData.charts.timeLineGraph.dimension = viewData.facts.dimension(d => {
				return d.hour;
			});

			// Create the group for the time line chart
			const metricType = viewConfig.timeLineGraph.type || viewConfig.measureLookup[viewConfig.timeLineGraph.valueAccessor].type;
			if (metricTypeIsSummable(metricType)) {
				viewData.charts.timeLineGraph.dimensionGroup = viewData.charts.timeLineGraph.dimension.group().reduceSum(d => {
					return d[viewConfig.timeLineGraph.valueAccessor];
				});
			} else {
				viewData.charts.timeLineGraph.dimensionGroup = viewData.charts.timeLineGraph.dimension.group().reduceCount();
			}
		}

		// Create and configure the actual chart
		viewData.charts.timeLineGraph.chart = dc.barChart('#dc-timeLine-chart');
		viewData.charts.timeLineGraph.chart.width(1110)
			.height(190)
			.margins({top: 5, right: 0, bottom: 20, left: 50})
			.dimension(viewData.charts.timeLineGraph.dimension)
			.filterAll()
			.group(viewData.charts.timeLineGraph.dimensionGroup)
			.transitionDuration(300)
			.centerBar(false)
			.colors('#1fb35f')
			.gap(-5)
			.renderHorizontalGridLines(true);

		// Configure the x-axis
		const from=moment.tz(viewData.queryParams.range.from, viewData.timezone);
		const to=moment.tz(viewData.queryParams.range.to, viewData.timezone);
		viewData.charts.timeLineGraph.chart
			.x(d3.time.scale().domain([
				from.valueOf(),
				to.valueOf()
			]))
			.xUnits(d3.time.hour)
			.xAxis()
			.tickValues(() => {
				// [ TL ] Important: When calculating ticks, "from" must be in local timezone
				// Otherwise daylight-saving-time changes will not be correct
				const maxNoOfTicks=10;
				let ticks=[];
				let tickTime=from.clone();

				while (to.diff(tickTime)>0) {
					ticks.push(tickTime.valueOf());
					tickTime.add(1, 'day'); // We must add "1 day" (not "24 hours"), otherwise daylight-saving-time changes will not be correct
				}
				while (ticks.length>maxNoOfTicks) { // Too many ticks: Remove every second tick until <=maxNoOfTicks
					let tickCounter=-1;
					ticks=_filter(ticks, () => {
						tickCounter++;
						return (tickCounter % 2 == 0);
					});
				}
				return ticks;
			})
			.tickFormat(d => {
				const daysDiff = to.diff(from, 'days');
				if (daysDiff>7) {
					return moment(d).tz(viewData.timezone).format('MMM DD ddd');
				}
				return moment(d).tz(viewData.timezone).format('MMM DD ddd HH:mm');
			})
			.ticks(7);

		// Configure the y-axis
		viewData.charts.timeLineGraph.chart
			.y(d3.scale.linear().domain([0, 500])) // [ TL ] Why 0-500?
			.elasticY(true)
			.yAxis()
			.ticks(5);


		/** -------------------------------------------------------------------------------------* Data table */
			  // Define the dataTable's columns
		let columns=[];
		// Always start with a time column...
		columns.push(d => {
			return MetricFormatter.format('tableTime', d.dtg);
		});
		viewConfig.table.measures.forEach(m => {
			// Loop through all config.viewConfig.table.measures and generate a new column for each
			columns.push(d => {
				if (m.calculation){
					return MetricFormatter.calculate(m.calculation, d);
				}
				const val = d[viewConfig.measureLookup[m.measure].key];
				const metricType = m.type || viewConfig.measureLookup[m.measure].type;
				switch (metricType) {
					case 'count':
						if (!isFinite(val)){
							return '--';
						}
						return val;
					case 'number':
						if (!isFinite(val)){
							return '--';
						}
						if (val > 0) {
							return val;
						}
						return '';
					case 'duration':
						return MetricFormatter.format('duration', val);
					default: // E.g. 'date', 'string'
						return val;
				}
			});
		});

		// Create and configure the actual data table
		if (!viewData.charts.dataTable) {
			viewData.charts.dataTable={};
		}
		viewData.charts.dataTable.chart = dc.dataTable('#dc-table-graph');
		viewData.charts.dataTable.chart.width(960)
			.height(800)
			.dimension(viewData.charts.timeLineGraph.dimension)
			.filterAll()
			.size(30000)
			.group(d => {
				return d[viewConfig.table.groupBy];
			})
			.columns(columns)
			.sortBy(d => {
				return d.dtg;
			})
			.on('postRender', viewData.tableUpdatedCallback)
			.on('postRedraw', viewData.tableUpdatedCallback)
			.order(d3.ascending);


		/** -------------------------------------------------------------------------------------* Totals */
		const all = viewData.facts.groupAll();
		const totalsGroup = all.reduce(
			/* callback for when data is added to the current filter results */
			(p, v) => {
				viewConfig.totals.forEach(m => {
					const metricType = m.type || viewConfig.measureLookup[m.measure].type;
					if (metricTypeIsSummable(metricType)) {
						p[m.measure] += v[m.measure];
					} else {
						p[m.measure]++;
					}
				});
				return p;
			},
			/* callback for when data is removed from the current filter results */
			(p, v) => {
				viewConfig.totals.forEach(m => {
					const metricType = m.type || viewConfig.measureLookup[m.measure].type;
					if (metricTypeIsSummable(metricType)) {
						p[m.measure] -= v[m.measure];
					} else {
						p[m.measure]--;
					}
				});
				return p;
			},
			/* initialize p */
			() => {
				let totalsBase = {};
				viewConfig.totals.forEach(m => {
					totalsBase[m.measure] = 0;
				});
				return totalsBase;
			}
		);

		viewData.charts.totals = {};
		viewConfig.totals.forEach(m => {
			if (!m.hidden) {
				const metricType = m.type || viewConfig.measureLookup[m.measure].type,
					   decimals   = m.decimals || viewConfig.measureLookup[m.measure].decimals || 0;

				viewData.charts.totals[m.measure] = dc.numberDisplay('#dc-totals-' + m.measure);
				viewData.charts.totals[m.measure].valueAccessor(d => {
					if (m.calculation) {
						return MetricFormatter.calculate(m.calculation, d);
					}
					return d[m.measure];
				})
					.formatNumber(v => {
						if (!isFinite(v)) {
							return '--';
						}
						switch (metricType) {
							case 'number':
							case 'count':
								if (m.displayPercentOf) {
									return MetricFormatter.format('totalWithPercentage', v);
								}
								return v.toFixed(decimals);
							case 'duration':
								return MetricFormatter.format('duration', v);
						}

					})
					.group(totalsGroup);

				if (m.displayPercentOf) {
					// If 'displayPercentOf' exists for this metric, also display it's percentage relative to the metric 'displayPercentOf'
					viewData.charts.totals[m.measure + 'Percent'] = dc.numberDisplay('#dc-totals-' + m.measure + '-percent')
						.valueAccessor(d => {
							return d[m.measure] / d[m.displayPercentOf];
						})
						.formatNumber(v => {
							if (!isFinite(v)) {
								return '';
							}
							return '(' + MetricFormatter.getFormatter('percentage')(v) + ')';
						})
						.group(totalsGroup);
				}
			}
		});

		/** -------------------------------------------------------------------------------------* Finally render all */
		dc.renderAll();

		/** After render, loop through all barFilters and find the longest text (label). Stretch the "#dc-bar-chart-wrapper"-div if the longest text doesn't fit */
		setTimeout(() => {
			var width = 225;
			viewConfig.barFilters.forEach(m => {
				d3.selectAll('#dc-' + m.dimension + '-chart svg text').each(function() {
					width = Math.max(width, getTextNodelength(this));
				});
			});
			d3.selectAll('#dc-bar-chart-wrapper').style({width: (width+25) + 'px'});
		}, 0);
	}

	function getTextNodelength(textNode) {
		return d3.select(textNode).node().getComputedTextLength();
	}

	function reorderBarChart(viewData, chartName) {
		if (viewData.charts && viewData.charts[chartName] && viewData.charts[chartName].chart) {
			viewData.charts[chartName].barChartsValueOrder = !viewData.charts[chartName].barChartsValueOrder;
			viewData.charts[chartName].chart.redraw();
		}
	}

	function resetFilters(viewConfig, viewData) {
		viewData.suppressFilterUpdates = true;
		viewConfig.barFilters.forEach(m => {
			// Loop through all barFilters and clear them
			if (viewData.charts[m.dimension] && viewData.charts[m.dimension].chart) {
				viewData.charts[m.dimension].chart.filterAll();
			}
		});
		dc.redrawAll();
		viewData.suppressFilterUpdates = false;
		viewData.filtersUpdated();
	}

	function hasFilters(viewConfig, viewData) {
		return viewConfig.barFilters.some(m => {
			if (viewData.charts[m.dimension] && viewData.charts[m.dimension].chart) {
				const filters=viewData.charts[m.dimension].chart.filters();
				return (filters && filters.length>0);
			}
		});
	}

	function disposeView(viewData) {
		dc.deregisterAllCharts();
		for (let key in viewData.charts) {
			if (viewData.charts[key].dimensionGroup) {
				viewData.charts[key].dimensionGroup.dispose();
				viewData.charts[key].dimensionGroup=null;
			}
			if (viewData.charts[key].dimension) {
				viewData.charts[key].dimension.dispose();
				viewData.charts[key].dimension=null;
			}
			if (viewData.charts[key].chart) {
				viewData.charts[key].chart.selectAll('rect').on('click', null);
				viewData.charts[key].chart.selectAll('text').on('click', null);
				viewData.charts[key].chart.expireCache();
				viewData.charts[key].chart=null;
			}
			viewData.charts[key]=null;
		}
		viewData.charts={};

		if (viewData.facts) {
			viewData.facts.remove();
			viewData.facts=null;
		}

		d3.selectAll('svg').remove();

		viewData.disposed=true;
	}

	const service = {
		renderView: renderView,
		reorderBarChart: reorderBarChart,
		resetFilters: resetFilters,
		hasFilters: hasFilters,
		disposeView: disposeView
	};
	return service;
}
