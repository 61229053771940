import AccountConfiguration from '../utils/AccountConfigurationWrapper';
import WebAPIUtils from '../utils/WebAPIUtils';
import StateHistory from '../utils/StateHistory';
import LocalStorage from '../stores/LocalStorageWrapper';
import DebugInfo from '../utils/DebugInfo';

const {setShouldPoll} = WebAPIUtils;

const setReactModalHidden = (hidden) => {
	const bodyEl = document.getElementsByTagName('body')[0];
	if (bodyEl.classList.contains('modal-open')) {
		const modalElements = document.getElementsByClassName('modal-dialog');
		if (modalElements.length > 0) {
			for (let i = 0; i < modalElements.length; i++) {
				const modalElement = modalElements[i].parentNode;
				if (hidden) {
					if (!modalElement.classList.contains('hide-modal-debug')) {
						modalElement.classList.add('hide-modal-debug');
					}
				} else {
					modalElement.classList.remove('hide-modal-debug');
				}
			}
		}
	}
};

export default function ($uibModalInstance, $translate, modalState, vngageConfig, SessionService) {
	'ngInject';

	let vm = this;
	vm.startModalState = modalState;
	vm.debugInfoState = {
		modalState: modalState,
		debugData: {
			debugInfo: DebugInfo.getAll(),
			accountConfig: AccountConfiguration.configuration,
			session: SessionService.getSession(),
			stateData: StateHistory.getEveryThing()
			// stateData: Array(5000).fill(StateHistory.getEveryThing()) 	// For debugging large data downloads...
		},
		downloadData: {
			jsonData: null,
			fileName: null
		}
	};

	vm.profilePicture = (vm.debugInfoState.debugData.accountConfig.user.profile.profilePicture || vngageConfig.staticWorkspaceUrl + 'img/gravatar.png');
	vm.descriptionPlaceholder = $translate.instant('debugInfo.DESCRIPTION_PLACEHOLDER');

	vm.generateLink = generateLink;
	vm.generateLinkDone = generateLinkDone;

	// pause poll
	setShouldPoll.call(WebAPIUtils, false);
	setReactModalHidden(true);

	vm.close = () => {
		// restore poll
		const localValue = LocalStorage.getItem('shouldPoll');
		if (localValue) {
			const trueSet = localValue === 'true';
			setShouldPoll.call(WebAPIUtils, trueSet);
		} else {
			// no value in localStorage, assume that poll should be started
			setShouldPoll.call(WebAPIUtils, true);
		}
		setReactModalHidden(false);
		$uibModalInstance.close();
	};
	vm.reload = () => location.reload();


	function generateLink() {
		vm.debugInfoState.modalState = 'generatingLink';
		vm.debugInfoState.downloadData.jsonData = vm.debugInfoState.debugData;
		vm.debugInfoState.downloadData.fileName = 'vergic_debugData_' + vm.debugInfoState.debugData.debugInfo.debugTime.replace(/:/g, '_') + '.json.zip';
	}

	function generateLinkDone() {
		vm.debugInfoState.modalState = 'displayLink';
	}
}
