export default {
	'en-GB': {
		uiElement: {
			SAVE: 'Save',
			REMOVE: 'Delete',
			CANCEL: 'Cancel',
			CLOSE: 'Close',
			FILTER: 'Filter',
			ACTIVE: 'Active',
			INACTIVE: 'Inactive',
			UPDATE: 'Update',
			EXPORT_CSV: 'Export',
			IMPORT_CSV: 'Import',
			FILE_NAME: 'File name',
		},
		validate: {
			REQUIRED: 'Required field',
			NUMBER: 'The field is not a valid number',
			EMAIL: 'Please enter a valid e-mail address',
			MIN: 'Too low number',
			MAX: 'Too high number',
			URL: 'Invalid URL',
			MAXLENGTH: 'Too long',
			MINLENGTH: 'Too short',
			SUBDOMAIN: 'Subdomain (without suffix, minimum chars 2)',
			END_AFTER_START: 'End time need to be after start time',
			HOURS_MINUTES: 'Time format must be HH:mm (24H)'
		},
		notification: {
			SUCCESS: 'Success',
			SUCCESS_PUBLISH: 'Successfully published configuration',
			SUCCESS_CONFIG_SAVE: 'Successfully updated configuration',
			SUCCESS_CONFIG_LOAD: 'Successfully loaded configuration',

			ERROR: 'Error',
			ERROR_STATUS: 'Error: ',
			ERROR_NOT_FOUND: 'Error: Not Found',
			ERROR_SERVER_RESPONDED_WITH: 'Server responded with: ',
			ERROR_NOT_AUTHORIZED: 'Not authorized for request'
		},
		languageCodes: {
			'en-GB': 'English',
			'sv-SE': 'Swedish',
			'da-DK': 'Danish',
			'nb-NO': 'Norwegian',
			'fi-FI': 'Finnish',
			'de-DE': 'German',
			'es-ES': 'Spanish',
			'fr-FR': 'French',
			'it-IT': 'Italian',
			'nl-NL': 'Dutch',
			'pt-BR': 'Brazilian Portuguese',
			'zh-Hans-CN': 'Simplified Chinese'
		},
		markdown: {
			PREVIEW: 'Preview',
			TEXT: 'Text',
			MARKDOWN: 'Markdown',
			LINK: 'Link',
			LINK_DESCRIPTION: 'A link is composed of brackets [] containing the name of the link, immediately followed by paranthesis () containing the address.',
			BOLD: 'Bold',
			ITALIC: 'Italic',
			STRIKE: 'Strike-trough',
			SUPERSCRIPT: 'Superscript',
			SUBSCRIPT: 'Subscript',
			CODE: 'Code',
			LINE_BREAK: 'Line break',
			LINE_BREAK_DESCRIPTION: 'Single line break renders as &lt;br&gt;, two consecutive line breaks exits a block or list',
			BULLET_LIST: 'Bullet list',
			BULLET_LIST_DESCRIPTION: 'You can use * or - followed by a space. End the list with double line breaks',
			ORDERED_LIST: 'Ordered list',
			ORDERED_LIST_DESCRIPTION: 'Requires one digit from 1-9 and a dot and a space. For lists longer than 9 items, use same number again. End the list with double line breaks',
			CODE_BLOCK: 'Code block',
			CODE_BLOCK_DESCRIPTION: 'Each block starts with ``` (3) back ticks and a space. End the block with double line breaks',
			BLOCKQUOTE: 'Blockquote',
			BLOCKQUOTE_DESCRIPTION: 'Each block starts with a \'>\' and a space. End the block with double line breaks'
		},

		set: {
			// Configuration Sections (These are matched to APIConfigurationSectionService and of course real API)
			accountSettings: 'Site settings',
			action: 'Action',
			actionList: 'Actions',
			actionPanel: 'Action panel',
			actionPanelList: 'Action panels',
			banner: 'Banner',
			bannerList: 'Banners',
			bannerTheme: 'Banner theme',
			closure: 'Closure',
			closureList: 'Closures',
			configurationSections: 'configurationSection',
			customerCase: 'Customer case',
			customerCaseList: 'Customer cases',
			forms: 'Form',
			formList: 'Forms',
			opportunity: 'Opportunity',
			opportunityList: 'Opportunities',
			preDefinedText: 'Canned response',
			preDefinedTextList: 'Canned responses',
			panelSettings: 'Panel settings',
			renderTemplate: 'Template',
			solution: 'Solution',
			solutionList: 'Solutions',
			theme: 'Theme',
			themeList: 'Themes',
			urlCategory: 'Page category',
			urlCategoryList: 'URL categories',
			visitor: 'Visitor',
			unknown: 'N/A',

			// Remaining Configuration Set related items
			group: 'Group',
			groups: 'Group',
			groupList: 'Groups',
			pointOfInterest: 'Point Of Interest',
			pointOfInterests: 'Point Of Interest',
			pointOfInterestList: 'Points Of Interest',
			script: 'Script',
			scripts: 'Script',
			site: 'Site',
			sites: 'Site',
			translation: 'Translation',
			translations: 'Translation',
			user: 'User',
			userList: 'Users',
			identityProvider: 'Identity Provider',
			role: 'Role',
			roleList: 'Roles',
			systemSetting: 'System Setting',
			workspace: 'Workspace'
		},
		itemRemoval: {
			HEADER: 'Remove {{ itemType }} "{{ itemName }}"',
			NOTE: 'NOTE',
			REFERENCES_EXIST_MSG: 'This {{ itemType }} is connected to other items in the system, are you sure you want to delete it?',
			REFERENCE_ITEMS_MSG: 'The following {{ itemTypeWithRef }} are connected to this item:'
		},
		weekPicker: {
			WEEK: 'Week:',
			SELECTED_PERIOD: 'Selected period:'
		}
	},
	'sv-SE': {
		uiElement: {
			SAVE: 'Save',
			REMOVE: 'Delete',
			CANCEL: 'Cancel',
			CLOSE: 'Close',
			FILTER: 'Filter',
			ACTIVE: 'Active',
			INACTIVE: 'Inactive',
			UPDATE: 'Update',
			EXPORT_CSV: 'Export',
			IMPORT_CSV: 'Import',
			FILE_NAME: 'File name',
		},
		validate: {
			REQUIRED: 'Required field',
			NUMBER: 'The field is not a valid number',
			EMAIL: 'Please enter a valid e-mail address',
			MIN: 'Too low number',
			MAX: 'Too high number',
			URL: 'Invalid URL',
			MAXLENGTH: 'Too long',
			MINLENGTH: 'Too short',
			SUBDOMAIN: 'Subdomain (without suffix, minimum chars 2)',
			END_AFTER_START: 'End time need to be after start time',
			HOURS_MINUTES: 'Time format must be HH:mm (24H)'
		},
		notification: {
			SUCCESS: 'Success',
			SUCCESS_PUBLISH: 'Successfully published configuration',
			SUCCESS_CONFIG_SAVE: 'Successfully updated configuration',
			SUCCESS_CONFIG_LOAD: 'Successfully loaded configuration',

			ERROR: 'Error',
			ERROR_STATUS: 'Error: ',
			ERROR_NOT_FOUND: 'Error: Not Found',
			ERROR_SERVER_RESPONDED_WITH: 'Server responded with: ',
			ERROR_NOT_AUTHORIZED: 'Not authorized for request'
		},
		languageCodes: {
			'en-GB': 'English',
			'sv-SE': 'Swedish',
			'da-DK': 'Danish',
			'nb-NO': 'Norwegian',
			'fi-FI': 'Finnish',
			'de-DE': 'German',
			'es-ES': 'Spanish',
			'fr-FR': 'French',
			'it-IT': 'Italian',
			'nl-NL': 'Dutch',
			'pt-BR': 'Brazilian Portuguese',
			'zh-Hans-CN': 'Simplified Chinese'
		},
		markdown: {
			PREVIEW: 'Preview',
			TEXT: 'Text',
			MARKDOWN: 'Markdown',
			LINK: 'Link',
			LINK_DESCRIPTION: 'A link is composed of brackets [] containing the name of the link, immediately followed by paranthesis () containing the address.',
			BOLD: 'Bold',
			ITALIC: 'Italic',
			STRIKE: 'Strike-trough',
			SUPERSCRIPT: 'Superscript',
			SUBSCRIPT: 'Subscript',
			CODE: 'Code',
			LINE_BREAK: 'Line break',
			LINE_BREAK_DESCRIPTION: 'Single line break renders as &lt;br&gt;, two consecutive line breaks exits a block or list',
			BULLET_LIST: 'Bullet list',
			BULLET_LIST_DESCRIPTION: 'You can use * or - followed by a space. End the list with double line breaks',
			ORDERED_LIST: 'Ordered list',
			ORDERED_LIST_DESCRIPTION: 'Requires one digit from 1-9 and a dot and a space. For lists longer than 9 items, use same number again. End the list with double line breaks',
			CODE_BLOCK: 'Code block',
			CODE_BLOCK_DESCRIPTION: 'Each block starts with ``` (3) back ticks and a space. End the block with double line breaks',
			BLOCKQUOTE: 'Blockquote',
			BLOCKQUOTE_DESCRIPTION: 'Each block starts with a \'>\' and a space. End the block with double line breaks'
		},

		set: {
			// Configuration Sections (These are matched to APIConfigurationSectionService and of course real API)
			accountSettings: 'Site settings',
			action: 'Action',
			actionList: 'Actions',
			actionPanel: 'Action panel',
			actionPanelList: 'Action panels',
			banner: 'Banner',
			bannerList: 'Banners',
			bannerTheme: 'Banner theme',
			closure: 'Closure',
			closureList: 'Closures',
			configurationSections: 'configurationSection',
			customerCase: 'Customer case',
			customerCaseList: 'Customer cases',
			forms: 'Form',
			formList: 'Forms',
			opportunity: 'Opportunity',
			opportunityList: 'Opportunities',
			preDefinedText: 'Canned response',
			preDefinedTextList: 'Canned responses',
			panelSettings: 'Panel settings',
			renderTemplate: 'Template',
			solution: 'Solution',
			solutionList: 'Solutions',
			theme: 'Theme',
			themeList: 'Themes',
			urlCategory: 'Page category',
			urlCategoryList: 'URL categories',
			visitor: 'Visitor',
			unknown: 'N/A',

			// Remaining Configuration Set related items
			group: 'Group',
			groups: 'Group',
			groupList: 'Groups',
			pointOfInterest: 'Point Of Interest',
			pointOfInterests: 'Point Of Interest',
			pointOfInterestList: 'Points Of Interest',
			script: 'Script',
			scripts: 'Script',
			site: 'Site',
			sites: 'Site',
			translation: 'Translation',
			translations: 'Translation',
			user: 'User',
			userList: 'Users',
			identityProvider: 'Identity Provider',
			role: 'Role',
			roleList: 'Roles',
			systemSetting: 'System Setting',
			workspace: 'Workspace'
		},
		itemRemoval: {
			HEADER: 'Remove {{ itemType }} "{{ itemName }}"',
			NOTE: 'NOTE',
			REFERENCES_EXIST_MSG: 'This {{ itemType }} is connected to other items in the system, are you sure you want to delete it?',
			REFERENCE_ITEMS_MSG: 'The following {{ itemTypeWithRef }} are connected to this item:'
		},
		weekPicker: {
			WEEK: 'Vecka:',
			SELECTED_PERIOD: 'Vald period:'
		}
	},
	'fr-FR': {
		uiElement: {
			SAVE: 'Sauvegarder',
			REMOVE: 'Supprimer',
			CANCEL: 'Annuler',
			CLOSE: 'Fermer',
			FILTER: 'Filtrer',
			ACTIVE: 'Actif',
			INACTIVE: 'Inactif',
			UPDATE: 'Mettre à jour',
			EXPORT_CSV: 'Export',
			IMPORT_CSV: 'Import',
			FILE_NAME: 'Nom de fichier',
		},
		validate: {
			REQUIRED: 'Champs requis',
			NUMBER: 'Le champ est pas un nombre valide',
			EMAIL: 'Veuillez indiquer une adresse e-mail valide',
			MIN: 'Nombre trop faible',
			MAX: 'Nombre trop élevé',
			URL: 'URL invalide',
			MAXLENGTH: 'Trop long',
			MINLENGTH: 'Trop court',
			SUBDOMAIN: 'Sous-domaine (sans suffixe, 2 caractères minimum)',
			END_AFTER_START: 'L\'heure de fin doit être postérieure à l\'heure de début',
			HOURS_MINUTES: 'Le format de l\'heure doit être HH : mn (24H)'
		},
		notification: {
			SUCCESS: 'Succès',
			SUCCESS_PUBLISH: 'Configuration publiée avec succès',
			SUCCESS_CONFIG_SAVE: 'Configuration mise à jour avec succès',
			SUCCESS_CONFIG_LOAD: 'Configuration chargée avec succès',

			ERROR: 'Erreur',
			ERROR_STATUS: 'Erreur : ',
			ERROR_NOT_FOUND: 'Erreur : Introuvable',
			ERROR_SERVER_RESPONDED_WITH: 'Le serveur a répondu :',
			ERROR_NOT_AUTHORIZED: 'Demande non autorisée'
		},
		languageCodes: {
			'en-GB': 'Anglais',
			'sv-SE': 'Suédois',
			'da-DK': 'Danois',
			'nb-NO': 'Norvégien',
			'fi-FI': 'Finlandais',
			'de-DE': 'Allemand',
			'es-ES': 'Espagnol',
			'fr-FR': 'Français',
			'it-IT': 'Italien',
			'nl-NL': 'Néerlandais',
			'pt-BR': 'Portugais Brésilien',
			'zh-Hans-CN': 'Chinois simplifié'
		},
		markdown: {
			PREVIEW: 'Aperçu',
			TEXT: 'Texte',
			MARKDOWN: 'Markdown',
			LINK: 'Lien',
			LINK_DESCRIPTION: 'Un lien est composé de crochets [] contenant le nom du lien, immédiatement suivi de parenthèses () contenant l\'adresse.',
			BOLD: 'Gras',
			ITALIC: 'Italique',
			STRIKE: 'Barré',
			SUPERSCRIPT: 'en exposant',
			SUBSCRIPT: 'sous-script',
			CODE: 'Code',
			LINE_BREAK: 'Saut de ligne',
			LINE_BREAK_DESCRIPTION: 'Un saut de ligne simple donnera &lt;br&gt ;, deux sauts de ligne consécutifs permettent de sortir d\'un bloc ou d\'une liste',
			BULLET_LIST: 'Liste à puces',
			BULLET_LIST_DESCRIPTION: 'Vous pouvez utiliser * ou - suivi d\'un espace. Terminez la liste par un double saut de ligne',
			ORDERED_LIST: 'Liste ordonnée',
			ORDERED_LIST_DESCRIPTION: 'Nécessite un chiffre compris entre 1 et 9, un point et un espace. Pour les listes de plus de 9 éléments, utilisez à nouveau le même numéro. Terminez la liste par un double saut de ligne',
			CODE_BLOCK: 'Bloc de code',
			CODE_BLOCK_DESCRIPTION: 'Chaque bloc commence par ```(3) accents graves et un espace. Terminez le bloc par un double saut de ligne',
			BLOCKQUOTE: 'blockquote',
			BLOCKQUOTE_DESCRIPTION: 'Chaque bloc commence par un « > » et un espace. Terminez le bloc par un double saut de ligne'
		},

		set: {
			// Configuration Sections (These are matched to APIConfigurationSectionService and of course real API)
			accountSettings: 'Paramètres du site',
			action: 'Action',
			actionList: 'Actions',
			actionPanel: 'Panneau d\'action',
			actionPanelList: 'Panneaux d\'action',
			banner: 'Bannière',
			bannerList: 'Bannières',
			bannerTheme: 'Thème de la bannière',
			closure: 'Clôture',
			closureList: 'Clôtures',
			configurationSections: 'configurationSection',
			customerCase: 'Dossier client',
			customerCaseList: 'Dossiers clients',
			forms: 'Forme',
			formList: 'Formes',
			opportunity: 'Opportunité',
			opportunityList: 'Opportunités',
			preDefinedText: 'Réponse prédéterminée',
			preDefinedTextList: 'Réponses prédéterminées',
			panelSettings: 'Réglages du panneau',
			renderTemplate: 'Modèle',
			solution: 'Solution',
			solutionList: 'Solutions',
			theme: 'Thème',
			themeList: 'Thèmes',
			urlCategory: 'Catégorie de page',
			urlCategoryList: 'Catégories d\'URL ',
			visitor: 'Visiteur',
			unknown: 'N/A',

			// Remaining Configuration Set related items
			group: 'Groupe',
			groups: 'Groupe',
			groupList: 'Groupes',
			pointOfInterest: 'Point d\'intérêt',
			pointOfInterests: 'Point d\'intérêt',
			pointOfInterestList: 'Points d\'intérêt',
			script: 'Script',
			scripts: 'Script',
			site: 'Site',
			sites: 'Site',
			translation: 'Traduction',
			translations: 'Traduction',
			user: 'Utilisateur',
			userList: 'Utilisateurs',
			identityProvider: 'Fournisseur d\'identité',
			role: 'Rôle',
			roleList: 'Rôles',
			systemSetting: 'Réglage du système',
			workspace: 'Workspace'
		},
		itemRemoval: {
			HEADER: 'Retirer {{itemType}} "{{itemName}}"',
			NOTE: 'REMARQUE',
			REFERENCES_EXIST_MSG: 'Ce {{ itemType}} est connecté à d\'autres éléments du système, êtes-vous sûr(e) de vouloir le supprimer ?',
			REFERENCE_ITEMS_MSG: 'Les {{ itemTypeWithRef}} suivants sont connectés à cet élément :'
		},
		weekPicker: {
			WEEK: 'Semaine :',
			SELECTED_PERIOD: 'Période sélectionnée :'
		}
	}
};
