export default
{
	CONVERSATION_CREATED: 'conversationCreated',
	VISITOR_REQUEST_CONVERSATION: 'visitorRequestConversation',
	ASSOCIATED_WITH_GROUP: 'associatedWithGroup',
	CONVERSATION_KEEP_ALIVE: 'conversationKeepAlive',
	ASSOCIATED_WITH_CASE: 'associatedWithCase',
	PARTICIPANT_INVITED: 'participantInvited',
	CONVERSATION_RESERVATION: 'conversationReservation',
	PARTICIPANT_CONNECTION_STATUS: 'participantConnectionStatus',
	PARTICIPANT_LEFT: 'participantLeft',
	RELEASE_TO_GROUP: 'releaseToGroup'
}