import React from 'react';
import {connect} from 'react-redux';
import NavigationActions from './../../actions/NavigationActions';
import Video from './../../actionCreators/Video';
import ModalType from './../../constants/ModalType'
import VideoModal from './VideoModal.react';
import VideoFullscreen from './VideoFullscreen.react';

const OverlayView = (props) => {
	let modal = null;
	switch (props.modal) {

		case ModalType.VIDEO_MODAL:
			modal = <VideoModal {...props} />;
			break;

		case ModalType.VIDEO_FULLSCREEN:
			modal = <VideoFullscreen {...props} />;
			break;

		default:
			break;
	}
	return (
		<div className="overlay-wrapper">
			{modal}
		</div>
	);
};

function mapStateToProps(state) {
	return {
		modal: state.get('modal')
	}
}

function mapDispatchToProps(dispatch) {
	return {
		closeVideo: () => dispatch(Video.showInChatPanel()),
		showVideoSection: () => dispatch(Video.showVideoSection()),
		hideModal: () => dispatch(NavigationActions.hideModal())
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayView);

