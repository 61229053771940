import ContentUploadTools from '../utils/ContentUploadTools';
import DateWrapper from '../utils/DateWrapper';
import {Store} from '../stores/Store';
import LogActions from '../actions/LogActions';

let http = {get: () => 0, post: () => 0};
let restUrl = '';
let httpIsMocked = false;
let origHttp;

const setHttpMock = mock => {
	origHttp = http;
	http = mock;
	httpIsMocked = true;
};
const resetHttpMock = () => {
	if (httpIsMocked) {
		http = origHttp;
		httpIsMocked = false;
	}
};

const setHttp = httpFunc => {
	http = httpFunc;
	httpIsMocked = false;
};
const setRestUrl = url => restUrl = url;

const logRequest = (t0, name, requestParams, status = 'ok') => {
	const t1 = DateWrapper.now();
	Store.dispatch(LogActions.logRequest({name, status, responseTime: t1 - t0, requestParams}));
};

const requestWrapper = async (name, requestFunc, requestParams) => {
	const t0 = DateWrapper.now();
	let result;
	try {
		result = await requestFunc(...requestParams);
		logRequest(t0, name, requestParams);
		return Promise.resolve(result);
	} catch (error) {
		logRequest(t0, name, requestParams, error);
		return Promise.reject(error);
	}
};

const postUrlWrapper = (name, url, payload, config = {}) => {
	return requestWrapper(name, postUrl, [url, payload, config]);
};

const getUrlWrapper = (name, url, config = {}) => {
	return requestWrapper(name, getUrl, [url, config]);
};

const postUrl = (url, payload, config = {}) => http.post(url, payload, config)
	.then(response => {
		return Promise.resolve(response);
	}, err => {
		return Promise.reject(err);
	});

const getUrl = (url, config = {}) => http.get(url, config)
	.then(response => {
		return Promise.resolve(response.data);
	}, err => {
		return Promise.reject(err.data);
	});

const skipAngularErrors = {
	skipErrorHandling: true
};

// post
const startConversation = conversationId => postUrlWrapper('startConversation', `${restUrl}Conversation/${conversationId}/Start`, '', skipAngularErrors);
const transferToGroup = (conversationId, groupId) => postUrlWrapper('transferToGroup', `${restUrl}Conversation/${conversationId}/Transfer?toGroup=${groupId}`, '');
const sendMessage = (conversationId, message) => postUrlWrapper('sendMessage', `${restUrl}Conversation/${conversationId}/Message`, JSON.stringify(message), skipAngularErrors);
const sendGroupAvailability = groupIdStr => postUrlWrapper('sendGroupAvailability', `${restUrl}Group/MyAvailability?${groupIdStr}`);
const banParticipant = (conversationId, participantId) => postUrlWrapper('banParticipant', `${restUrl}Conversation/${conversationId}/Ban?participantId=${participantId}`, '');
const closeConversation = conversationId => postUrlWrapper('closeConversation', `${restUrl}Conversation/${conversationId}/Close`, '');

const leaveConversation = conversationId => postUrlWrapper('leaveConversation', `${restUrl}Conversation/${conversationId}/Leave`, '');
const closeCase = (caseId, closureObj) => postUrlWrapper('closeCase', `${restUrl}Case/${caseId}/Close`, JSON.stringify(closureObj));
const changeCase = (conversationId) => postUrlWrapper('changeCase', `${restUrl}Conversation/${conversationId}/ChangeCase`, '');
const updateCaseType = (caseId, update) => postUrlWrapper('updateCaseType', `${restUrl}Case/${caseId}`, JSON.stringify(update));
const emailConversationTranscript = (conversationId, email) => postUrlWrapper('emailConversationTranscript', `${restUrl}Conversation/${conversationId}/Transcript/SendEmail`, JSON.stringify({email}));
const updateVisitorClaims = (visitId, claims) => postUrlWrapper('updateVisitorClaims', `${restUrl}Visit/${visitId}/Claims`, JSON.stringify(claims));
const getStartCode = () => postUrlWrapper('getStartCode', `${restUrl}Conversation/StartCode`, '');
const getStartCodeWithParams = (params) => postUrlWrapper('getStartCode', `${restUrl}Conversation/StartCode`, JSON.stringify(params));
const putAwayConversation = (caseId, reasonName) => postUrlWrapper('putAwayConversation', `${restUrl}Case/${caseId}/PutAway?reasonName=${reasonName}`, '');
const postGeneric = (url, data, config) => postUrlWrapper('postGeneric', url, JSON.stringify(data), config);

// get
const getConversation = conversationId => getUrlWrapper('getConversation', `${restUrl}Conversation/${conversationId}`, skipAngularErrors);
const getVisitorProfile = visitId => getUrlWrapper('getVisitorProfile', `${restUrl}Visit/${visitId}`);
const getVisitorNavigation = visitId => getUrlWrapper('getVisitorNavigation', `${restUrl}Tracking/Navigation?visitId=${visitId}`);
const getGeneric = (url, config) => getUrlWrapper('getGeneric', url, config);
const getCaseHistory = (customerId, take = 100, skip = 1) => getUrlWrapper('getCaseHistory', `${restUrl}Case/?caseState=2&includeConversations=false&customerId=${customerId}&sortBy=createdAt&sortOrder=desc&take=${take}&skip=${skip}`);
const getCase = (caseId) => getUrlWrapper('getCase', `${restUrl}Case/${caseId}`);


// Content handling
const downloadContent = (key) => {
	const t0 = DateWrapper.now();
	const url = `${restUrl}ContentV2/${key}`;
	return ContentUploadTools.getAsArrayBuffer(url)
		.then(response => {
			logRequest(t0, 'downloadContent', {url});
			return Promise.resolve(response);
		}, err => {
			logRequest(t0, 'downloadContent', {url}, err);
			return Promise.reject(err);
		});

};

const uploadContent = (conversationId, data, uploadInfo) => {
	const t0 = DateWrapper.now();
	const url = `${restUrl}Conversation/${conversationId}/ContentUpload`;
	return ContentUploadTools.postArrayBuffer(url, data, {uploadInfo}, uploadInfo.mimeType)
		.then(response => {
			logRequest(t0, 'uploadContent', {url, uploadInfo});
			return Promise.resolve(response);
		}, err => {
			logRequest(t0, 'uploadContent', {url, uploadInfo}, err);
			return Promise.reject(err);
		});
};


export default {
	// HELPERS
	setHttp,
	setHttpMock,
	resetHttpMock,
	setRestUrl,
	// POST
	startConversation,
	transferToGroup,
	sendMessage,
	sendGroupAvailability,
	banParticipant,
	closeConversation,
	leaveConversation,
	closeCase,
	changeCase,
	updateCaseType,
	emailConversationTranscript,
	updateVisitorClaims,
	getStartCode,
	getStartCodeWithParams,
	putAwayConversation,
	postGeneric,
	uploadContent,
	// GET
	getConversation,
	getVisitorProfile,
	getVisitorNavigation,
	downloadContent,
	getGeneric,
	getCaseHistory,
	getCase
};

