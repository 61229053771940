__webpack_public_path__ = window.engage ? window.engage.config.staticWorkspaceUrl : '';

import vngageConfig from './vngageConfig';
import Globals from './globals';
import '../common/uiModalFreezeModule';
import languagesConfig from './languagesConfig';
import commonTranslations from '../common/commonTranslations';
import appTranslations from './AppTranslations.js';
import AuthorizationService from '../common/auth/AuthorizationService';
import IdentityService from '../common/auth/IdentityService';
import APIEndpointService from '../common/API/APIEndpointService';
import AuthenticationService from '../common/auth/AuthenticationService';
import APIMethodsService from '../common/API/APIMethodsService';
import NotificationService from '../common/NotificationService';
import APIStatusCodeService from '../common/API/APIStatusCodeService';
import AccountSitesService from '../common/AccountSitesService';
import SessionService from '../common/auth/SessionService';
import Session from '../common/auth/Session';
import AuthKeys from '../common/auth/AuthKeys';
import TimeZoneService from '../common/TimeZoneService';
import AppController from './AppController';
import SessionKeepAliveService from '../common/auth/SessionKeepAliveService';
import vngagePatterns from '../common/vngageFormDirectives/vngagePatterns';
import AppRouteStateInterceptors from './AppRouteStateInterceptors';
import AppHttpInterceptors from './AppHttpInterceptors';
import AppStartingController from './AppStartingController';
import vngageZeroIsNumberFilter from '../common/vngageCommonFilters/vngageZeroIsNumberFilter';
import APIStatisticsService from '../common/API/APIStatisticsService';
import APIBatchRequestService from '../common/API/APIBatchRequestService';
import TimeRangeService from './statistics/legacy/TimeRangeService';
import APIRealtimeService from '../common/API/APIRealtimeService';
import StatisticsFilterService from './statistics/legacy/StatisticsFilterService';
import TimeIntervalService from './statistics/legacy/TimeIntervalService';
import StatisticsDefaultsService from './statistics/legacy/StatisticsDefaultsService';
import APICaseService from '../common/API/APICaseService';
import APIConfigurationSectionService from '../common/API/APIConfigurationSectionService';
import APIConfigurationSetService from '../common/API/APIConfigurationSetService';
import vngageOrderObjectBy from '../common/vngageCommonFilters/vngageOrderObjectByFilter';
import removalService from '../common/vngageRemovalService/removalService';
import vngageYesNoModalController from '../common/vngageYesNoModalService/vngageYesNoModalController';
import vngageYesNoModalService from '../common/vngageYesNoModalService/vngageYesNoModalService';
import vngageRemoveModalController from '../common/vngageRemovalService/vngageRemoveModalController';
import vngageRemoveModalService from '../common/vngageRemovalService/vngageRemoveModalService';
import vngageCodemirrorCss from '../common/vngageCodeMirrorCss/vngageCodemirrorCssDirective';

import vngageBackNavigationDirective from '../common/vngageBackNavigation/vngageBackNavigationDirective';
import vngageEditNavigationDirective from '../common/vngageEditNavigation/vngageEditNavigationDirective';

import vngageCodemirrorHtml from '../common/vngageCodeMirrorHtml/vngageCodemirrorHtmlDirective';
import vngageCodemirrorJs from '../common/vngageCodemirrorJs/vngageCodemirrorJs';
import vngageCodemirrorLess from '../common/vngageCodemirrorLess/vngageCodemirrorLess';
import vngageCodemirrorXml from '../common/vngageCodemirrorXml/vngageCodemirrorXml';
import vngageCodemirrorMerge from '../common/vngageCodemirrorMerge/vngageCodemirrorMerge';
import vngageColumnTableController from '../common/vngageColumnTable/vngageColumnTableController';
import vngageColumnTable from '../common/vngageColumnTable/vngageColumnTableDirective';
import vngageColumnTablePaginationDirective from '../common/vngageColumnTable/vngageColumnTablePaginationDirective';
import vngageColumnTableResourceService from '../common/vngageColumnTable/vngageColumnTableResourceService';
import vngageAutofocus from '../common/vngageFormDirectives/vngageAutofocusDirective';
import vngageBasicValidation from '../common/vngageFormDirectives/vngageBasicValidationDirective';
import vngageIgnoredInput from '../common/vngageFormDirectives/vngageIgnoredInputDirective';
import vngageIndicateError from '../common/vngageFormDirectives/vngageIndicateErrorDirective';
import vngageIndicateSaving from '../common/vngageFormDirectives/vngageIndicateSavingDirective';
import vngageSubmitButtons from '../common/vngageFormDirectives/vngageSubmitButtonsDirective';
import vngageValidateEndAfterStart from '../common/vngageFormDirectives/vngageValidateEndAfterStartDirective';
import vngageValidateHoursMinutes from '../common/vngageFormDirectives/vngageValidateHoursMinutes';
import vngageValidateSubDomain from '../common/vngageFormDirectives/vngageValidateSubDomainDirective';
import vngageGuid from '../common/vngageGuid/vngageGuidService';
import vngageMarkdownInput from '../common/vngageMarkdown/vngageMarkdownInputDirective';
import vngageMarkdownLegend from '../common/vngageMarkdown/vngageMarkdownLegendDirective';
import vngageMarkdownPreview from '../common/vngageMarkdown/vngageMarkdownPreviewDirective';
import importModalFileSelected from '../common/vngageImportModal/ImportModalFileSelectedDirective';
//now using mmd module directly instead of vngageMarkdown service
import vngageMarkdown from '../common/vngageMarkdown/mmd';
import vngageSelectableItems from '../common/vngageSelectableItems/vngageSelectableItemsDirective';
import vngageSiteSelector from '../common/vngageSiteSelector/vngageSiteSelectorDirective';
import vngageSortableList from '../common/vngageSortableList/vngageSortableListDirective';
import vngageSpinner from '../common/vngageSpinner/vngageSpinner';
import vngageTagList from '../common/vngageTagList/vngageTagListDirective';
import vngageTag from '../common/vngageTags/vngageTagDirective';
import vngageTags from '../common/vngageTags/vngageTagsDirective';
import itemGroupMembership from '../common/itemGroupMemebershipService';
import StateReloadService from '../common/StateReloadService';
import transitionService from '../common/transitionService';
import VngageUtil from '../common/VngageUtilService';
import vngageUser from './publish/configurationSet/VngageUserFilter';
import './dashboard/DashboardModule';
import './configure/ConfigureModule';
import './analyze/AnalyzeModule';
import './account/AccountModule';
import './caseBrowser/CaseBrowserModule';
import './calendar/CalendarModule';
import './publish/PublishModule';
import './system/SystemModule';
import './desktopPlugins/DesktopPluginsModule';
import './monitor/MonitorModule';
import './engage/EngageModule';
import './statistics/StatisticsModule';
import './devTools/DevToolsModule';
import './desktopDeux/desktopDeuxModule';
import './dialogguest/DialogGuestModule';
import './coBroDesktop/CoBroDesktopModule';
import vngageLessWrapper from '../common/vngageLess/vngageLessWrapperService';
import vngageThemeConfigDefaults from '../common/vngageLess/vngageThemeConfigDefaults';
import vngageTheme from '../common/vngageLess/vngageThemeService';

import breadcrumbsTemplate from '../common/breadcrumbsTemplate';
import AppTemplate from './AppTemplate';
import App404Template from './App404Template';
import AppLoggedOutLandingPageTemplate from './AppLoggedOutLandingPageTemplate';
import '../common/vngageFormDirectives/validationMessagesTemplate';

import DesktopService from './desktopDeux/DesktopService';

import '../common/VngageWorkspaceTranslationModule';
import WorkspaceSettingsService from '../common/WorkspaceSettingsService';
import WhiteLabelService from '../common/vngageWhiteLabel/WhiteLabelService';
import vngageLogo from '../common/vngageLogoDirective';
import vngageSecurityWarning from '../common/vngageSecurityWarning/vngageSecurityWarningDirective';	// In use by legacy desktop and dialogguest
import vngageEscapeApostrophes from '../common/vngageCommonFilters/vngageEscapeApostrophes';
import ConfigValidationService from '../common/ConfigValidationService';

// Legacy desktop
import './desktop.OLD/DesktopModule';

export default angular.module('vngageApp', [

	// Angular modules
	'ngResource',
	'ngMessages',
	'ngSanitize',
	'ngAnimate',

	// vngageApp
	'vngageApp.dashboard',
	'vngageApp.analyze',
	'vngageApp.calendar',
	'vngageApp.engage',
	'vngageApp.account',
	'vngageApp.configure',
	'vngageApp.publish',
	'vngageApp.system',
	'vngageApp.desktopPlugins',
	'vngageApp.statistics',
	'vngageApp.desktopDeux',
	'vngageApp.caseBrowser',
	'vngageApp.devTools',
	'vngageApp.dialogguest',
	'vngageApp.monitor',
	'vngageApp.workspaceTranslation',
    'vngageApp.coBroDesktop',

	// Legacy desktop
	'vngageApp.desktop',

	// Third party modules
	'ui.router',
	'ui.bootstrap',
	'ui.modalFreeze',
	'pascalprecht.translate',
	'ui.codemirror',
	'ncy-angular-breadcrumb',
	'toastr',
	'unsavedChanges',
	'angularSpinner',
	'ui.calendar',
	'ui.grid',
	'ui.grid.cellNav',
	'ui.grid.exporter',
	'ui.grid.selection'
])

	.constant('languagesConfig', languagesConfig)
	.constant('commonTranslations', commonTranslations)
	.constant('appTranslations', appTranslations)
	.factory('AuthorizationService', AuthorizationService)
	.factory('IdentityService', IdentityService)
	.factory('AuthenticationService', AuthenticationService)
	.factory('APIEndpointService', APIEndpointService)
	.factory('APIMethodsService', APIMethodsService)
	.factory('NotificationService', NotificationService)
	.factory('APIStatusCodeService', APIStatusCodeService)
	.factory('AccountSitesService', AccountSitesService)
	.factory('SessionService', SessionService)
	.value('Session', Session)
	.value('AuthKeys', AuthKeys)
	.factory('TimeZoneService', TimeZoneService)
	.controller('AppController', AppController)
	.factory('SessionKeepAliveService', SessionKeepAliveService)
	.value('vngagePatterns', vngagePatterns)
	.controller('AppStartingController', AppStartingController)
	.filter('vngageZeroIsNumberFilter', vngageZeroIsNumberFilter)
	.factory('APIStatisticsService', APIStatisticsService)
	.factory('APIBatchRequestService', APIBatchRequestService)
	.factory('TimeRangeService', TimeRangeService)
	.factory('APIRealtimeService', APIRealtimeService)
	.factory('StatisticsFilterService', StatisticsFilterService)
	.factory('TimeIntervalService', TimeIntervalService)
	.factory('StatisticsDefaultsService', StatisticsDefaultsService)
	.factory('APICaseService', APICaseService)
	.factory('APIConfigurationSectionService', APIConfigurationSectionService)
	.factory('APIConfigurationSetService', APIConfigurationSetService)
	.filter('vngageOrderObjectBy', vngageOrderObjectBy)
	.factory('removalService', removalService)
	.controller('vngageYesNoModalController', vngageYesNoModalController)
	.factory('vngageYesNoModalService', vngageYesNoModalService)
	.controller('vngageRemoveModalController', vngageRemoveModalController)
	.factory('vngageRemoveModalService', vngageRemoveModalService)
	.directive('vngageBackNavigation', vngageBackNavigationDirective)
	.directive('vngageEditNavigation', vngageEditNavigationDirective)
	.directive('vngageCodemirrorCss', vngageCodemirrorCss)
	.directive('vngageCodemirrorHtml', vngageCodemirrorHtml)
	.directive('vngageCodemirrorJs', vngageCodemirrorJs)
	.directive('vngageCodemirrorLess', vngageCodemirrorLess)
	.directive('vngageCodemirrorXml', vngageCodemirrorXml)
	.directive('vngageCodemirrorMerge', vngageCodemirrorMerge)
	.controller('vngageColumnTableController', vngageColumnTableController)
	.directive('vngageColumnTable', vngageColumnTable)
	.directive('vngageColumnTablePaginationDirective', vngageColumnTablePaginationDirective)
	.factory('vngageColumnTableResourceService', vngageColumnTableResourceService)
	.directive('vngageAutofocus', vngageAutofocus)
	.directive('vngageBasicValidation', vngageBasicValidation)
	.directive('vngageIgnoredInput', vngageIgnoredInput)
	.directive('vngageIndicateError', vngageIndicateError)
	.directive('vngageIndicateSaving', vngageIndicateSaving)
	.directive('vngageSubmitButtons', vngageSubmitButtons)
	.directive('vngageValidateEndAfterStart', vngageValidateEndAfterStart)
	.directive('vngageValidateHoursMinutes', vngageValidateHoursMinutes)
	.directive('vngageValidateSubDomain', vngageValidateSubDomain)
	.factory('vngageGuid', vngageGuid)
	.directive('vngageMarkdownInput', vngageMarkdownInput)
	.directive('vngageMarkdownLegend', vngageMarkdownLegend)
	.directive('vngageMarkdownPreview', vngageMarkdownPreview)
	.directive('fileSelected', importModalFileSelected)
	.directive('vngageSelectableItems', vngageSelectableItems)
	.directive('vngageSortableList', vngageSortableList)
	.directive('vngageSpinner', vngageSpinner)
	.directive('vngageTagList', vngageTagList)
	.directive('vngageTag', vngageTag)
	.directive('vngageTags', vngageTags)
	.directive('vngageSiteSelector', vngageSiteSelector)
	.factory('itemGroupMembership', itemGroupMembership)
	.factory('StateReloadService', StateReloadService)
	.factory('transitionService', transitionService)
	.factory('VngageUtil', VngageUtil)
	.filter('vngageUser', vngageUser)

	.value('vngageMarkdown', vngageMarkdown)
	.value('vngageConfig', vngageConfig)
	.value('less', window.less)
	.value('jshint', window.JSHINT)
	.value('d3', window.d3)
	.value('moment', window.moment)
	.value('crossfilter', window.crossfilter)
	.value('dc', window.dc)
	.value('doT', window.doT)
	.value('filtrex', window.filtrex)
	.factory('vngageLessWrapper', vngageLessWrapper)
	.factory('vngageThemeConfigDefaults', vngageThemeConfigDefaults)
	.factory('vngageTheme', vngageTheme)

	.factory('DesktopService', DesktopService)
	.factory('WorkspaceSettingsService', WorkspaceSettingsService)
	.factory('WhiteLabelService', WhiteLabelService)
	.directive('vngageLogo', vngageLogo)
	.directive('vngageSecurityWarning', vngageSecurityWarning)	// In use by legacy desktop and dialogguest
	.filter('vngageEscapeApostrophes', vngageEscapeApostrophes)
	.factory('ConfigValidationService', ConfigValidationService)

	.config(function($compileProvider, $httpProvider, $stateProvider, $urlRouterProvider, $translateProvider, commonTranslations, appTranslations, unsavedWarningsConfigProvider, toastrConfig, $breadcrumbProvider, VngageWorkspaceTranslationServiceProvider, $locationProvider, $qProvider) {
		'ngInject';

		// Set location hash-prefix to old Angular 1.5.x style
		$locationProvider.hashPrefix('');

		// [TL] Production settings/optimizations for Angular
		if (!window.vngageDevMode) {
		// The $compileProvider-settings below should be set in production (https://docs.angularjs.org/guide/production)
		// Decreases Angular bootstrap-time quite noticeable
			$compileProvider.debugInfoEnabled(false);
			$compileProvider.commentDirectivesEnabled(false);
			$compileProvider.cssClassDirectivesEnabled(false);

			// Throttle apply-calls in $httpProvider (better performance when handling many requests/sec)
			$httpProvider.useApplyAsync(true);

			// Hide warnings about unhandled Angular promise-rejections (introduced in Angular 1.6)
			// We want to see these warnings and fix when developing (by adding catch()-blocks to all promises), but don't emit console-messages in production...
			$qProvider.errorOnUnhandledRejections(false);
		}

		// Configure plugins
		// -----------------

		// TODO, there is a bug with the lib and new translate version, cannot use together at this moment
		unsavedWarningsConfigProvider.useTranslateService = false;

		// Add common and main-menu translations
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(commonTranslations);
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(appTranslations);

		angular.extend(toastrConfig, {
			positionClass: 'toast-bottom-right',
			maxOpened: 2
		});

		$breadcrumbProvider.setOptions({templateUrl: breadcrumbsTemplate});

		// General Application Routes
		// --------------------------

		$urlRouterProvider.otherwise('/404');

		$urlRouterProvider.when('/', '/dashboard');

		$stateProvider.state('root', {
			url: '',
			templateUrl: AppTemplate,
			controller: 'AppController as appVm',
			data: {
				// Never display this state in breadcrumb.
				ncyBreadcrumbSkip: true
			}
		});

		$stateProvider.state('loggedOut', {
			url: '/loggedOut/',
			templateUrl: AppLoggedOutLandingPageTemplate
		});

		$stateProvider.state('404', {
			url: '/404/',
			templateUrl: App404Template
		});

		$stateProvider.state('starting', {
			url: '/starting/',
			controller: 'AppStartingController as vm',
			template: [
				'<span',
				'us-spinner="vm.spinnerOpts"',
				'spinner-key="spinner-1"',
				'spinner-start-active="1">',
				'</span> '
			].join(' ')
		});

		// Make a trailing slash optional for all routes
		// ---------------------------------------------
		// https://github.com/angular-ui/ui-router/wiki/Frequently-Asked-Questions#how-to-make-a-trailing-slash-optional-for-all-routes
		$urlRouterProvider.rule(function ($injector, $location) { // This is not DI
			let path = $location.url();

			// check to see if the path already has a slash where it should be
			if (path[path.length - 1] === '/' || path.indexOf('/?') > -1) {
				return;
			}

			if (path.indexOf('?') > -1) {
				return path.replace('?', '/?');
			}

			return path + '/';
		});
	})

	.config(AppHttpInterceptors)

	.run(function ($rootScope, AuthorizationService) {
		'ngInject';
		$rootScope.authorize = function (authorization) {
			return AuthorizationService.authorize(authorization);
		};
	})

	.run(AppRouteStateInterceptors);
