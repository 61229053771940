import vngageRemoveModalTemplate from './vngageRemoveModalTemplate';

export default function ($uibModal) {
	'ngInject';

	return {
		open: function (options) {
			return $uibModal.open({
				backdrop: 'static',
				templateUrl: vngageRemoveModalTemplate,
				controller: 'vngageRemoveModalController as vm',
				resolve: {
					options: () => {
						return options;
					},
					texts: (removalService, $q) => {
						'ngInject';
						return $q(function (resolve) {
							removalService.getModalTexts(options.itemToRemove, options.itemType).then(res => {
								resolve(res);
							}).catch(() => {
								resolve(['[translations missing]']);
							});
						});
					}
				}
			}).result;
		}
	};
};
