import _find from 'lodash/find';

export default function ($state, IdentityService, NotificationService, AuthorizationService) {
	'ngInject';

	const dashboardStateName = 'root.dashboard';

	function navigate(transitions) {
		IdentityService.authorizationDataLoadedPromise().then(() => {
			const found = _find(transitions, transition => AuthorizationService.authorize(transition.access));
			if (found) {
				$state.transitionTo(found.state);
			} else {
				NotificationService.error({ header: 'Unauthorized' });
				$state.transitionTo(dashboardStateName);
			}
		});
	}

	const service = {
		navigate: navigate
	};
	return service;
};
