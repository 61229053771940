export default function($uibModalInstance, $state, APIEndpointService, options, $timeout) {
    'ngInject';

    var vm = this;
    vm.options = options;
	vm.newBaseDomain = (vm.options.currentBaseDomain ? 'true' : '');
	vm.cancel = $uibModalInstance.dismiss;
	vm.close = () => {
		$uibModalInstance.close(vm.newBaseDomain);
	}
	$timeout(() => {
	    // Set form initially dirty to enable the save button
		vm.baseDomainMigrationModalForm.$setDirty();
	},0);
};
