import moment from 'moment-timezone';

export default function ($scope, $state, $rootScope, $timeout, SessionService, NotificationService, DynamicStatisticsModel, DynamicStatisticsConfig, DynamicStatisticsQueryService, DynamicStatisticsRenderViewService) {
	'ngInject';

	let vm = this;
	let model = DynamicStatisticsModel.get();

	vm.loading = false;
	vm.hideCharts = true;
	vm.weekStart = model.weekStart;

	// Figure out which dynamic view to render from $state.params.id
	vm.viewId = null;
	if (!$state.params || !$state.params.id) {
		// No state parameter id (i.e. the base route '#/statistics/' is used): Try to navigate to a default view (pick the first authorized view)
		const authorizedViews = DynamicStatisticsConfig.availableViews().filter(view => {
			return $rootScope.authorize(view.access);
		});
		if (authorizedViews[0]) {
			// A default view found: transition to it
			$state.go($state.current, {id: authorizedViews[0].id});
			return;
		}
	} else {
		const nonLetterMatch = $state.params.id.match(/([\W])/i);
		if (nonLetterMatch) {
			// The vm.viewId contains non-alphanumeric characters: truncate at first occurrence (typically a trailing slash in the url)
			vm.viewId = $state.params.id.substring(0, nonLetterMatch.index);
		} else {
			vm.viewId = $state.params.id;
		}
	}
	const view = DynamicStatisticsConfig.getView(vm.viewId);
	if (!view) {
		NotificationService.error({
			header: 'Error',
			body: 'Unknown statistics view'
		});
		$state.transitionTo('root.dashboard');
		return;
	} else if (!$rootScope.authorize(view.access)) {
		NotificationService.error({
			header: 'Unauthorized'
		});
		$state.transitionTo('root.dashboard');
		return;
	}
	vm.viewConfig = view.viewConfig;

	// Define an object to hold this views's state and runtime data
	vm.viewData = {
		rawData: [],
		facts: null,
		charts: {},
		timezone: SessionService.getUserTimezone(),
		queryParams: {
			period: 'hour',
			range: {
				from: null,
				to: null
			},
			siteId: ''
		},
		tableUpdatedCallback: dataTableUpdated,
		filtersUpdated: () => {
			if (!vm.viewData.suppressFilterUpdates) {
				$timeout(() => {
					vm.viewData.hasFilters = DynamicStatisticsRenderViewService.hasFilters(vm.viewConfig, vm.viewData);
				});
			}
			
		},
		hasFilters: false,
		suppressFilterUpdates: false,
		disposed: false
	};


	$scope.$on('$destroy', () => {
		DynamicStatisticsRenderViewService.disposeView(vm.viewData);
	});

	$scope.$watch('vm.weekStart', function (newWeekStart) {
		if (!newWeekStart) {
			return;
		}
		vm.viewData.queryParams.range = getWeekRangeFromDate(newWeekStart);
		model.weekStart = newWeekStart;
		DynamicStatisticsModel.update(model);

		vm.loading = true;
		vm.hideCharts = true;
		DynamicStatisticsQueryService.query(vm.viewConfig, vm.viewData).then((data) => {
			vm.hideCharts = false;
			vm.viewData.rawData = data;
			DynamicStatisticsRenderViewService.renderView(vm.viewConfig, vm.viewData);
			$timeout(() => {
				vm.loading = false;
				dataTableUpdated();
			});
		}, (err) => {
			vm.loading = false;
			if (err) {
				if (err === 'empty') {
					displayEmptyResult();
				} else {
					console.log('Error fetching data:', err);
				}
			}
		});
	});

	vm.reorderBarChart = (chartName) => {
		DynamicStatisticsRenderViewService.reorderBarChart(vm.viewData, chartName);
	};

	vm.resetFilters = () => {
		DynamicStatisticsRenderViewService.resetFilters(vm.viewConfig, vm.viewData);
	};

	let dataTableUpdatedCallbacks = [];
	vm.registerTableUpdatedCallback = (callback) => {
		if (!dataTableUpdatedCallbacks[callback]) {
			dataTableUpdatedCallbacks.push(callback);
		}
	};

	function dataTableUpdated() {
		dataTableUpdatedCallbacks.forEach(callback => {
			callback();
		});
	}

	function displayEmptyResult() {
		NotificationService.error({
			header: 'Error',
			body: 'No data available for the requested time period. Please select another period.'
		});
		vm.hideCharts = true;
	}

	function getWeekRangeFromDate(d) {
		var m = moment(d);
		return {
			from: m.startOf('isoWeek').format(),
			to: m.endOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
		};
	}
}
