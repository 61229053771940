import monitorConversationsTemplate from './monitorConversationsTemplate';

export default function (monitorConversationsService, VngageUtil, APIRealtimeService) {
	'ngInject';

	return {
		restrict: 'E',
		scope: {
			groupList: '=',
			userList: '='
		},
		templateUrl: monitorConversationsTemplate,
		link: function (scope) {
			scope.vm = {
				getItemName: VngageUtil.getItemName,
				convertMsToMinutesSeconds: VngageUtil.convertMsToMinutesSeconds,
				userIdInSelectedGroups: monitorConversationsService.userIdInSelectedGroups,
				conversationStateMap: monitorConversationsService.conversationStateMap,
				conversationStateIcon: monitorConversationsService.conversationStateIcon,
				conversationWrap: monitorConversationsService.conversationWrap,
				userList: scope.userList,
				groupList: scope.groupList,
				filterByUser: filterByUser,
				filterOwnerUser: monitorConversationsService.filterOwnerUser,
				filterGroup: monitorConversationsService.filterGroup
			};

			function filterByUser(id) {
				monitorConversationsService.displayByOwnerUser(id);
			}
		}
	}
};
