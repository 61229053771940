import React from 'react';
import {Button} from 'react-bootstrap';
import {useGetTranslation} from './../../LangContext';

const DoNotDisturbOverlay = (props) => {
	const getTranslation = useGetTranslation();
	return (
		<span>
			{props.infoTipDoNotDisturb && !props.restricted && !props.paused ?
				<div className="view-only-overlay">
					<div className="container">
						<h1>{getTranslation('overlayDoNotDisturbHeader')}</h1>
						<p>{getTranslation('overlayDoNotDisturbBody1')} <span
							className="co-status view-only"><i
							className="vngage-icon-eye-1"/><span>DO NOT DISTURB</span></span> {getTranslation('overlayDoNotDisturbBody2')}
						</p>
						<p>{getTranslation('overlayDoNotDisturbBody3')}</p>
						<div className="checkbox">
							<label>
								<input onChange={() => props.toggleActiveInfoTip('doNotDisturb')}
									   type="checkbox"/> {getTranslation('overlayDoNotDisturbRemember')}
							</label>
						</div>
						<Button onClick={() => props.hideInfoTip('doNotDisturb')}
								className="default-line-btn-contrast">{getTranslation('overlayBtnDoNotDisturbDismiss')}</Button>
					</div>
				</div>
				: null}
			</span>
	)
};
export default DoNotDisturbOverlay;

