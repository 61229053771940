import AppConstants from '../../../components/constants/AppConstants';

const Ui = AppConstants.Ui;

export default {
	activatePanel(conversationId) {
		return {
			type: Ui.ACTIVATE_PANEL,
			conversationId
		};
	},

	setPanelPosition(conversationId, panelPosition) {
		return {
			type: Ui.SET_PANEL_POSITION,
			conversationId,
			panelPosition
		};
	},
	showModal(modalType) {
		return {
			type: Ui.SHOW_MODAL,
			modalType
		}
	},
	hideModal() {
		return {
			type: Ui.HIDE_MODAL
		}
	}

}
