import _filter from '../../../../node_modules/lodash/filter';
import _find from '../../../../node_modules/lodash/find';
import _groupBy from '../../../../node_modules/lodash/groupBy';
import moment from 'moment-timezone';

export default function () {
	'ngInject';

	var service = {
		execute: execute,
		assignMissingDayValues: assignMissingDayValues
	};

	var rangeMapper = {
		'day': function () {
			return {
				add: function (from) {
					from.add(1, 'days');
					return from;
				}
			};
		},
		'hour': function () {
			return {
				add: function (from) {
					from.add(1, 'hours');
					return from;
				}
			};
		}
	};

	return service;

	function execute(data, filter, keyAccessor, factory) {

		var from = moment(filter.range.from).utc();
		var to = moment(filter.range.to).utc();

		var result = [];
		var rangeStrategy = rangeMapper[filter.period]();
		var groups = _groupBy(data, keyAccessor);

		while (from.diff(to) < 0) {

			for (var key in groups) {

				var found = _filter(groups[key], function (v) {
					return from.diff(moment.utc(v.calculatedAt)) === 0;
				});

				if (found.length) {
					result.push.apply(result, found);
				} else {
					var created = factory(key, groups[key]);
					created.calculatedAt = from.format();
					result.push(created);
				}
			}

			rangeStrategy.add(from);
		}

		return result;
	}

	function assignMissingDayValues(data, factory) {

		var daysInMonth = moment().daysInMonth();
		var currentMonth = moment().format('M');
		var currentYear = moment().format('YYYY');

		var result = [];
		for (var day = 1; day <= daysInMonth; day++) {

			var found = find(data, day.toString(), currentMonth);

			if (found) {
				result.push(found);
			} else {

				var missingDate = moment(currentYear + '-' + currentMonth + '-' + day.toString(), 'YYYY-M-D').utc().format();
				var created = factory();

				created.calculatedAt = missingDate;
				result.push(created);
			}
		}

		return result;
	}

	function find(data, day, month) {
		return _find(data, function (s) {
			var d = moment(s.calculatedAt).format('D');
			var m = moment(s.calculatedAt).format('M');
			return d === day && m === month;
		});
	}
};
