import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import immutable from 'immutable';
import Draggable from 'react-draggable';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import UploadModal from '../../desktopDeux/components/dialogPanel/UploadModal.react';
import VideoView from '../../desktopDeux/components/video/VideoView.react';
import WebCallView from '../../desktopDeux/components/video/WebCallView.react';
import Conversation from '../../desktopDeux/actionCreators/Conversation';
import ToolBarSectionType from '../../desktopDeux/constants/ToolBarSectionType';
import PluginButton from '../../desktopDeux/components/dialogPanel/PluginButton.react';
import {useGetTranslation} from '../../desktopDeux/components/LangContext';
import ConfirmModal from '../../desktopDeux/components/common/ConfirmModal.react';
import ServerActions from '../../desktopDeux/actionCreators/ServerActions';

const topPadding = 100;
const rightPadding = 350;

export const WidgetPanel = (props) => {
	const getTranslation = useGetTranslation();
	const [isDragging, setIsDragging] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [showUploadModal, setShowUploadModal] = useState(false);
	const {
			  toggleSection,
			  closeCase,
			  conversationId,
			  videoActive,
			  webCallActive,
			  plugins,
			  pluginButtons,
			  groupId,
			  connectionStatus,
			  videoEnabled,
			  webCallEnabled,
			  roomId,
			  comType,
			  modal,
			  uploadEnabled,
			  windowDim = {},
		  } = props;
	const {windowWidth = 1000, windowHeight = 600} = windowDim;

	const [position, setPosition] = useState({
		x: windowWidth - rightPadding,
		y: topPadding
	});

	const onStart = () => setIsDragging(true);
	const onStop = () => setIsDragging(false);

	const dragHandlers = {
		onStart,
		onStop,
	};

	const confirmAction = () => {
		setShowConfirm(false);
		const closureObj = {
			reason: {
				id: '',
				revision: 0
			},
			name: 'Dismiss',
			outcome: 'neutral',
			textToVisitor: ''
		};
		closeCase(conversationId, closureObj);
	};
	const removeButtonTooltip = (
		<Tooltip id="toolbar-tooltip">
			Close and remove the conversation
		</Tooltip>);


	const rtcReady = !roomId;
	const currentIsActive = roomId === conversationId;

	const inCoBrowsing = !!conversationId;

	const videoButtonEnabled = connectionStatus !== 'terminate'
		&& (rtcReady || (currentIsActive && comType === 'video' && !modal))
		&& inCoBrowsing;

	const webCallButtonEnabled = connectionStatus !== 'terminate'
		&& (rtcReady || (currentIsActive && comType === 'webCall'))
		&& inCoBrowsing;

	const uploadButtonEnabled = inCoBrowsing;

	const dragOverlay = (isDragging ? <div className="drag-overlay"/>: null);


	const pluginElements = plugins
		.filter(plugin => {
			return pluginButtons.has(plugin.get('id'))
				&& plugin.get('enabled')
				&& plugin.get('enabledGroups').includes(groupId);
		})
		.map(plugin => {
			const pluginId = plugin.get('id');
			const buttonState = pluginButtons.get(pluginId) || 'disabled';
			return (
				<PluginButton key={pluginId}
							  conversationId={conversationId}
							  buttonState={buttonState}
							  plugin={plugin}
				/>
			);
		});

	const noTools = !uploadEnabled && !videoEnabled && !webCallEnabled && pluginElements.size === 0;

	return (
		<Draggable
			handle=".widget-panel-handle"
			{...dragHandlers}
			defaultPosition={position}
			bounds={{top: 0}}>
			<div className="widget-panel">
				<div className="widget-panel-handle">
					<div className="widget-panel-header" id="white-label-cobrowser-header">
						{getTranslation('collaborationToolsHeader')}
					</div>
				</div>
				{dragOverlay}
				<div className="widget-panel-body toolbar">
					{noTools && <div>{getTranslation('collaborationToolsNone')}</div>}
					{uploadEnabled && <Button
						className="toolbar-btn"
						disabled={!uploadButtonEnabled}
						onClick={() => setShowUploadModal(true)}>
						<i className="vngage-icon-line-note"/>
					</Button>}
					{videoEnabled && <Button
						className="toolbar-btn"
						disabled={!videoButtonEnabled}
						onClick={() => toggleSection(conversationId, ToolBarSectionType.VIDEO)}>
						<i className="vngage-icon-videocam-1"/>
					</Button>}
					{webCallEnabled && <Button
						className="toolbar-btn"
						disabled={!webCallButtonEnabled}
						onClick={() => toggleSection(conversationId, ToolBarSectionType.WEB_CALL)}>
						<i className="vngage-icon-phone mirrored-x"/>
					</Button>}
					<OverlayTrigger placement="bottom" overlay={removeButtonTooltip} delay={400}>
						<Button
							className="toolbar-btn"
							disabled={!inCoBrowsing}
							onClick={() => setShowConfirm(true)}>
							<i className="vngage-icon-stop"/>
						</Button>
					</OverlayTrigger>
					{pluginElements}
				</div>

				{videoActive && <div className="section-view">
					<div className="video-chat-section">
						<VideoView/>
					</div>
				</div>}
				{webCallActive && <div className="section-view">
					<div className="web-call-section">
						<WebCallView/>
					</div>
				</div>}
				{showConfirm && <ConfirmModal
					title="Close the conversation"
					text="Are you sure you want to close the conversation?"
					confirm={confirmAction}
					confirmText="close conversation"
					cancel={() => setShowConfirm(false)}

				/>}
				{showUploadModal && <UploadModal
					hideModal={() => setShowUploadModal(false)}
					conversationId={conversationId}
				/>}
			</div>
		</Draggable>

	);
};

const mapStateToProps = (state, ownProps) => {
	const conversationId = ownProps.conversationId;
	const conversation = state.getIn(['conversations', conversationId]) || immutable.Map();
	const videoActive = !!conversation.getIn(['UI', 'activeSections', ToolBarSectionType.VIDEO]);
	const webCallActive = !!conversation.getIn(['UI', 'activeSections', ToolBarSectionType.WEB_CALL]);
	const plugins = state.get('plugins');
	const pluginButtons = conversation.getIn(['UI', 'pluginButtons']) || immutable.Map();
	const groupId = conversation.get('groupId');

	const connectionStatus = conversation.get('connectionStatus');
	const enabledCapabilities = conversation.getIn(['capabilities', 'enabled']) || immutable.Map();

	const videoEnabled = !!enabledCapabilities.get('video');
	const webCallEnabled = !!enabledCapabilities.get('webCall');

	const video = state.get('video');
	const roomId = video.get('roomId');
	const comType = video.get('comType');

	const modal = state.get('modal');
	const uploadEnabled = !!conversation.getIn(['capabilities', 'groupFeatures', 'fileSharing', 'enabled']);

	return {
		videoActive,
		webCallActive,
		plugins,
		pluginButtons,
		groupId,
		connectionStatus,
		videoEnabled,
		webCallEnabled,
		roomId,
		comType,
		modal,
		uploadEnabled,
	};

};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleSection: (conversationId, sectionType) => dispatch(Conversation.toggleSection(conversationId, sectionType)),
		closeCase: (conversationId, closureObj) => dispatch(ServerActions.closeCase(conversationId, closureObj)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetPanel);

