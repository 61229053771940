import immutable from 'immutable';
import VideoActivity from './../../constants/VideoActivity';
import ToolBarSectionType from './../../constants/ToolBarSectionType';
import {filters} from './filters';

const isActiveSectionVideo = (state, conversationId) => {
	const isVideo = !!state.getIn(['conversations', conversationId, 'UI', 'activeSections', ToolBarSectionType.VIDEO]);
	const isWebCall = !!state.getIn(['conversations', conversationId, 'UI', 'activeSections', ToolBarSectionType.WEB_CALL]);
	return isVideo || isWebCall;
};

const setStartTime = (state, time) => {
	state = state.setIn(['video', 'startTime'], time);
	return state;
};

const getStartTime = state => {
	return state.getIn(['video', 'startTime']);
};

const isVideoStarting = (state, conversationId) => {
	const messages = state.getIn(['conversations', conversationId, 'messages']);
	if (!messages) {
		return false;
	}

	const lastVideoMessage = filters.getLastFoundInArray(filters.identifier.videoMessage, messages.toJS());
	if (!lastVideoMessage) {
		return false;
	}

	return lastVideoMessage.activity === VideoActivity.START;
};

const getMicrophoneEnabled = state => {
	return state.getIn(['video', 'microphoneEnabled']);
};

const setMicrophoneEnabled = (state, enabled) => {
	state = state.setIn(['video', 'microphoneEnabled'], enabled);
	return state;
};

const getCameraEnabled = state => {
	return state.getIn(['video', 'cameraEnabled']);
};

const setCameraEnabled = (state, enabled) => {
	state = state.setIn(['video', 'cameraEnabled'], enabled);
	return state;
};

const getError = state => {
	return state.getIn(['video', 'error']);
};

const setError = (state, error) => {
	state = state.setIn(['video', 'error'], error);
	return state;
};

const getState = state => {
	return state.getIn(['video', 'state']);
};

const setState = (state, videoState) => {
	state = state.setIn(['video', 'state'], videoState);
	return state;
};

const getRoomId = state => {
	return state.getIn(['video', 'roomId']);
};

const setRoomId = (state, roomId) => {
	state = state.setIn(['video', 'roomId'], roomId);
	return state;
};

const getComType = state => {
	return state.getIn(['video', 'comType']);
};

const setComType = (state, comType) => {
	state = state.setIn(['video', 'comType'], comType);
	return state;
};

const isCurrentRoomId = (state, roomId) => {
	const currentRoomId = getRoomId(state);
	return roomId === currentRoomId;
};

const getPeerId = state => {
	return state.getIn(['video', 'peerId']);
};

const setPeerId = (state, peerId) => {
	state = state.setIn(['video', 'peerId'], peerId);
	return state;
};

const isPeerId = (state, easyrtcid) => {
	const peerId = getPeerId(state);
	return easyrtcid === peerId;
};

const getParsedRoomId = state => {
	const roomId = getRoomId(state);
	return parseRoomId(roomId);
};

const parseRoomId = roomId => {
	return roomId.toString().replace(/-/g, '');
};

const getTurnServerAuthenticationHash = (state, conversationId) => {
	const visitId = state.getIn(['conversations', conversationId, 'visitId']);
	return state.getIn(['visitorProfiles', visitId, 'data', 'claims', 'turnServerAuthenticationHash', 0]) || '';
};

const isVideoActive = state => {
	const videoState = getState(state);
	return videoState !== '';
};

const dispose = state => {
	state = setStartTime(state, 0);
	state = setState(state, '');
	state = setError(state, '');
	state = setRoomId(state, '');
	state = setPeerId(state, '');
	state = setMicrophoneEnabled(state, true);
	state = setCameraEnabled(state, true);
	state = setComType(state, '');
	return state;
};

const getLastVideoMessage = (state, conversationId) => {
	const messages = state.getIn(['conversations', conversationId, 'messages']) || immutable.List();
	return filters.getLastFoundInArray(filters.identifier.videoMessage, messages.toJS());
};

const getActiveSection = (state, conversationId) => {
	const isVideo = !!state.getIn(['conversations', conversationId, 'UI', 'activeSections', ToolBarSectionType.VIDEO]);
	const isWebCall = !!state.getIn(['conversations', conversationId, 'UI', 'activeSections', ToolBarSectionType.WEB_CALL]);
	if ((isVideo && isWebCall) || (!isVideo && !isWebCall)) {
		return '';
	} else {
		return isVideo ? ToolBarSectionType.VIDEO: ToolBarSectionType.WEB_CALL;
	}
};

const isConversation = (state, conversationId) => {
	return state.hasIn(['conversations', conversationId]);
};

const VideoLib = {
	isActiveSectionVideo,
	setStartTime,
	getStartTime,
	isVideoStarting,
	getMicrophoneEnabled,
	setMicrophoneEnabled,
	getCameraEnabled,
	setCameraEnabled,
	getError,
	setError,
	getState,
	setState,
	getRoomId,
	setRoomId,
	getComType,
	setComType,
	isCurrentRoomId,
	getPeerId,
	setPeerId,
	isPeerId,
	parseRoomId,
	getParsedRoomId,
	getTurnServerAuthenticationHash,
	isVideoActive,
	dispose,
	getLastVideoMessage,
	getActiveSection,
	isConversation,
};

export default VideoLib;

