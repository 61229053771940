import React from 'react';
import {Button} from 'react-bootstrap';

const DebugDevTab = ({handleDeveloperActions}) => {

	return (
		<div style={{width: '100%'}}>
			<Button variant="default" className="dark" block onClick={() => handleDeveloperActions('addPanel')}>Add
				panel</Button>
			<Button variant="default" className="dark" block onClick={() => handleDeveloperActions('addConversation')}>Add
				conversation</Button>
			<Button variant="default" className="dark" block onClick={() => handleDeveloperActions('addVisitor')}>Add
				visitor</Button>
			<Button variant="default" className="dark" block
					onClick={() => handleDeveloperActions('message')}>Message</Button>
			<Button variant="default" className="dark" block onClick={() => handleDeveloperActions('systemMessage')}>System
				message</Button>
			<Button variant="default" className="dark" block onClick={() => handleDeveloperActions('typing')}>Toggle
				typing</Button>
			<Button variant="default" className="dark" block onClick={() => handleDeveloperActions('removePanels')}>Remove
				panels</Button>
			<Button variant="default" className="dark" block onClick={() => handleDeveloperActions('removeFocus')}>Remove
				focus</Button>
			<Button variant="danger" className="dark" block
					onClick={() => handleDeveloperActions('crash')}>Crash</Button>
			<Button variant="default" className="dark" block onClick={() => handleDeveloperActions('id')}>Visitor
				identity</Button>
			<Button variant="default" className="dark" block
					onClick={() => handleDeveloperActions('profile')}>Profile</Button>
			<Button variant="default" className="dark" block onClick={() => handleDeveloperActions('profile_id')}>Profile
				+
				Id</Button>
			<Button variant="default" className="dark" block onClick={() => handleDeveloperActions('profile_id_url')}>Profile
				+
				Id + Url</Button>
			<Button variant="default" className="dark" block
					onClick={() => handleDeveloperActions('reservation')}>Add</Button>
			<Button variant="default" className="dark" block onClick={() => handleDeveloperActions('videoModal')}>Video
				modal</Button>
			<Button variant="default" className="dark" block onClick={() => handleDeveloperActions('videoFullscreen')}>Video
				fullscreen</Button>
			<Button variant="default" className="dark" block onClick={() => handleDeveloperActions('hideModal')}>Hide
				modal</Button>
		</div>
	);
};
export default DebugDevTab

