import StatisticsExportController from './StatisticsExportController';
import StatisticsExportTemplate from './StatisticsExportTemplate';
import StatisticsExportService from './StatisticsExportService';

export default angular.module('vngageApp.statistics.export', [
	'ui.router'
])

	.controller('StatisticsExportController', StatisticsExportController)
	.factory('StatisticsExportService', StatisticsExportService)

	.constant('StatisticsExportModuleConfig', {
		state: 'root.statistics.export',
		access: 'Statistics/Export'
	})

	.config(function ($stateProvider, StatisticsExportModuleConfig) {
		'ngInject';
		$stateProvider.state(StatisticsExportModuleConfig.state, {
			url: 'export/',
			controller: 'StatisticsExportController as vm',
			templateUrl: StatisticsExportTemplate,
			data: {
				access: StatisticsExportModuleConfig.access,
				ncyBreadcrumbLabel: 'Export'
			}
		});
	});
