import monitorTranslations from './MonitorTranslations';
import MonitorController from './MonitorController';
import MonitorUserLogoutModalController from './usersOnline/MonitorUserLogoutModalController'
import monitorService from './monitorService';
import monitorConversations from './monitorConversations/monitorConversations';
import monitorConversationsFilterGroups from './monitorConversations/monitorConversationsFilterGroups';
import monitorConversationsFilterUsers from './monitorConversations/monitorConversationsFilterUsers';
import monitorConversationsMock from './monitorConversations/monitorConversationsMocks';
import monitorConversationsService from './monitorConversations/monitorConversationsService';
import vngageQueueOverview from './queueOverview/vngageQueueOverview';
import monitorUsersOnline from './usersOnline/monitorUsersOnline';
import monitorTemplate from './monitorTemplate';
import '../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.monitor', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.constant('monitorTranslations', monitorTranslations)
	.controller('MonitorController', MonitorController)
	.factory('monitorService', monitorService)
	.directive('monitorConversations', monitorConversations)
	.filter('monitorConversationsFilterGroups', monitorConversationsFilterGroups)
	.filter('monitorConversationsFilterUsers', monitorConversationsFilterUsers)
	.constant('monitorConversationsMock', monitorConversationsMock)
	.factory('monitorConversationsService', monitorConversationsService)
	.directive('vngageQueueOverview', vngageQueueOverview)
	.directive('monitorUsersOnline', monitorUsersOnline)
	.controller('MonitorUserLogoutModalController', MonitorUserLogoutModalController)

	.constant('monitorModuleConfig', {
		state: 'root.monitor',
		access: 'Monitor'
	})

	.config(function ($stateProvider, $translateProvider, monitorTranslations, monitorModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';

		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(monitorTranslations);

		$stateProvider.state('root.monitor', {
			url: '/monitor/',
			views: {
				content: {
					controller: 'MonitorController as vm',
					templateUrl: monitorTemplate,

					resolve: {
						userList: function (APIEndpointService) {
							'ngInject';
							return APIEndpointService.user.query({limit: 10000}).$promise;
						},
						groupList: function (APIEndpointService) {
							'ngInject';
							return APIEndpointService.group.query().$promise;
						}
					}
				}
			},
			data: {
				access: monitorModuleConfig.access
			}
		});

	});
