let currentNetId = 0;
export default {
	getNewNetId() {
		currentNetId++;
		return currentNetId;
	},

	getNetId() {
		return currentNetId;
	}
};
