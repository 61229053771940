import React from 'react';
import createReactClass from 'create-react-class';
import ParticipantStore from '../stores/ParticipantStore';
import ParticipantsFloatList from '../../../components/ParticipantsFloatList.react';
import Participant from '../../../components/Participant.react';
import ViewActions from '../actions/ViewActions';

import {ButtonToolbar,Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter, Button} from 'react-bootstrap';
import ConversationsStore from '../stores/ConversationsStore';
import vngageConfig from '../../vngageConfig';

export default createReactClass({

	getInitialState () {
		return getStateFromStores(this.props.conversationId);
	},

	componentDidMount () {
		ParticipantStore.addChangeListener(this._onChange);
		ConversationsStore.addChangeListener(this._onChange);
	},

	componentWillUnmount () {
		ParticipantStore.removeChangeListener(this._onChange);
		ConversationsStore.removeChangeListener(this._onChange);
	},

	_onChange () {
		this.setState(getStateFromStores(this.props.conversationId));
	},

	onChangeAuthorization (participantId, authorization) {
		ViewActions.changeAuthorization(this.props.conversationId, participantId, authorization);
	},

	onRemoveParticipant (participantId) {
		ViewActions.kickParticipant(this.props.conversationId, participantId);
	},

	handleInvite () {
		ViewActions.inviteParticipant(this.props.conversationId);
	},

	closeInviteModal () {
		ViewActions.dismissInviteParticipant(this.props.conversationId);
	},

	selectInviteCodeUrl(e) {
		e.target.select();
	},

	cancelInputChange(e) {
		e.target.value = e.target.defaultValue;
		this.selectInviteCodeUrl(e);
	},

	render () {
		var workspaceUrl = vngageConfig.workspaceUrl;
		var lang = psPlugin.application.writeOutLanguage;
		var langs = {
			vgInviteError : lang('vgInviteError'),
			vgInviteErrorMessage : lang('vgInviteErrorMessage'),
			vgInviteTitle: lang('vgInviteTitle'),
			vgInviteDescription: lang('vgInviteDescription'),
			vgInviteClose: lang('vgInviteClose')
		};
		let showInviteModal=!!(this.state.inviteOptions && (this.state.inviteOptions.inviteCode || this.state.inviteOptions.error));
		let inviteError=!!(this.state.inviteOptions && this.state.inviteOptions.error);
		let inviteUrl=(showInviteModal ? workspaceUrl + '#/dialog/' + this.state.inviteOptions.inviteCode : '');

		let inviteDescription = 'Invite a guest to participate in this conversation';

		//At the moment we have no knowledge of conversation state in here
		let btnInvite = (this.state.participants.length < 2) ? '' :
			<button disabled={this.state.participants.length > 2} className="btn btn-xs btn-info btn-invite-participant" style={{float:'right'}} onClick={this.handleInvite} title={inviteDescription}>
				<i className="vngage-icon-torso"></i><span className="vngage-plus">+</span>
			</button>;

		return (
			<span>
				<Modal show={showInviteModal} onHide={this.closeInviteModal}>
					<Modal.Header>
						<Modal.Title>
							{inviteError ? <span>{langs.vgInviteError}</span> : <span>{langs.vgInviteTitle}</span>}
							<i onClick={this.closeInviteModal} className="vngage-icon-cancel pull-right"></i>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{inviteError ?
							<div className="modal-body alert alert-danger" style={{marginBottom: '0'}}>{langs.vgInviteErrorMessage}</div> :
							<div>
								<p>{langs.vgInviteDescription}</p>
								<input className="form-control invite" onFocus={this.selectInviteCodeUrl} onChange={this.cancelInputChange} autoFocus defaultValue={inviteUrl} />
							</div>
						}
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={this.closeInviteModal}>{langs.vgInviteClose}</Button>
					</Modal.Footer>
				</Modal>

				<ButtonToolbar style={{margin:'2px 5px 2px 0'}} className="participants-wrapper">

					{btnInvite}

					{ this.state.participants.map( participant => {
						return (
							<Participant
								participantId={participant.id}
								key={participant.id || participant.createdAt}
								name={participant.info.name || ''}
								titleId={participant.info.titleId}
								connectionState={participant.connectionState}
								participationState={participant.state}
								isCurrentUser={participant.info.isCurrentUser}
								showAuthMenu={participant.info.isCurrentUser ? false : true}
								changeAuthorization={this.onChangeAuthorization}
								removeParticipant={this.onRemoveParticipant}
								abilityCoBrowsing={participant.abilities.coBrowsing}
								abilityVideo={participant.abilities.video}
								/>
						);
					})}
				</ButtonToolbar>
			</span>
		);
	}
});

function getStateFromStores(conversationId) {
	return {
		participants: ParticipantStore.getParticipantsOfConversation(conversationId),
		inviteOptions: ConversationsStore.getInviteOptionsForConversation(conversationId)
	};
}
