import {fromJS} from 'immutable'

export default {
	pluginTemplate: {
		'id': '',
		'name': 'test',
		'apiVersion': '',
		'enabled': false,
		'enabledGroups': [],
		'enabledSubscriptions': [],
		'enabledActions': []
	},

	availableSubscriptions: [
		'testSubscription',
	],

	availableActions: [
		'unitTest',
	],

	internalActions: [
		'pluginReady',
	],

	subscriptionPriority: {
		testSubscription: 10
	},
}

