var _vgSettings = {};

function setSettings(settings) {
	_vgSettings = settings;
};

function getSettings() {
	return _vgSettings;
};

export default {
	getSettings: getSettings,
	setSettings: setSettings
}
