export default {
    'en-GB': {
        customScript: {
            ADD_HEADER: 'New custom script',
			EDIT_HEADER: 'Edit custom script',
			LIST_HEADER: 'Custom scripts',
			LIST_TITLE: 'List of custom scripts for this account',
			LIST_DESCRIPTION: 'Small snippets of custom code for doing custom things.',
			NAME: 'Name',
			COMMENTS: 'Comments',
			CODE: 'Custom Javascript'
        }
    },
	'sv-SE': {
		customScript: {
			ADD_HEADER: 'New custom script',
			EDIT_HEADER: 'Edit custom script',
			LIST_HEADER: 'Custom scripts',
			LIST_TITLE: 'List of custom scripts for this account',
			LIST_DESCRIPTION: 'Small snippets of custom code for doing custom things.',
			NAME: 'Name',
			COMMENTS: 'Comments',
			CODE: 'Custom Javascript'
		}
	}
};
