import moment from 'moment-timezone';

export default function($http, vngageConfig, SessionService, uiGridConstants) {
    'ngInject';

    var paramKeyMap = {
        skip: 'skip',
        take: 'take',
        ownedByUserId: 'ownedByUserId',
        minDate: 'minDate',
        maxDate: 'maxDate',
        sortBy: 'sortBy',
        sortOrder: 'sortOrder',
        caseState: 'caseState'
    };

    var paramValMap = {
        sortBy: {
            owner: 'owner',
            createdAt: 'createdAt',
            closedBy: 'closedBy',
            visitId: 'visitId'
        },
        sortOrder: {
            ascending: uiGridConstants.ASC,
            descending: uiGridConstants.DESC
        }
    };

    var service = {
        getCases: getCases,
        cleanCase: cleanCase,
        paramValMap: paramValMap,
        paramKeyMap: paramKeyMap
    };

    function getCases(options) {
        return $http.get(vngageConfig.restUrl + 'Case/', { params: getParams(options) });
    }

	function cleanCase(caseId) {
		return $http.post(vngageConfig.restUrl + 'Case/'+caseId+'/Clean');
    }

    function getParams(options) {

        var params = {};

        if (options.skip) {
            params.skip = options.skip;
        }
        if (options.take) {
            params.take = options.take;
        } else {
            params.take = 100;
        }
        if (options.customerId) {
            params.customerId = options.customerId;
        }
        if (options.ownedByUserId) {
            params.ownedByUserId = options.ownedByUserId;
        }
        if (options.minDate) {
            params.minDate = toISOStringWithUserOffset(options.minDate, options.minTime, 'from');
        }
        if (options.maxDate) {
            params.maxDate = toISOStringWithUserOffset(options.maxDate, options.maxTime, 'to');
        }
        if (options.sortBy) {
            params.sortBy = options.sortBy;
        } else {
            params.sortBy = paramValMap.sortBy.createdAt;
        }
        if (options.sortOrder) {
            params.sortOrder = options.sortOrder;
        } else {
            params.sortOrder = paramValMap.sortOrder.descending;
        }
        if (options.caseState) {
            params.caseState = options.caseState;
        } else {
            params.caseState = 2; // New / Ongoing / Closed, show closed by default
        }

        params.includeConversations = false;

        return params;
    }

    function toISOStringWithUserOffset(dateObj, timeStr, fromOrTo) {

		//function getDSTOffset(date) {
		//
		//	function stdTimezoneOffset (date) {
		//		var jan = new Date(date.getFullYear(), 0, 1);
		//		var jul = new Date(date.getFullYear(), 6, 1);
		//		return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
		//	}
		//
		//	function dst (date) {
		//		return date.getTimezoneOffset() < stdTimezoneOffset(date);
		//	}
		//
		//	return dst(date) ? 1 : 0;
		//}

        // specify the timezone by the User timezone, important to specify as moment.utc
        //var momentUtcObj = moment.utc(dateObj).utcOffset(SessionService.getUserTimezoneOffset());
        var momentUtcObj = moment.utc(dateObj).tz(SessionService.getUserTimezone()); //.utcOffset(SessionService.getUserTimezoneOffset());
        // add Daylight savings time, if any:
		//var dst = getDSTOffset(new Date(dateObj));
		//if (dst !== 0){
		//	momentUtcObj.add(dst, 'h');
		//}

        if (timeStr) {
            momentUtcObj.add(timeStr.slice(0, 2), 'h').add(timeStr.slice(3, 5), 'm');
            if (fromOrTo === 'from') {
                momentUtcObj.startOf('minute');
            } else {
                momentUtcObj.endOf('minute');
            }
        } else {
            momentUtcObj.add('23', 'h').add('59', 'm'); // move past timezones but not to the next day
            if (fromOrTo === 'from') {
                momentUtcObj.startOf('day'); // go to start of day
            } else {
                momentUtcObj.endOf('day'); // go to end of day
            }
        }

        // sending the ISO string to the API endpoint for the cases
        return momentUtcObj.toISOString();
    }

    return service;
};
