import vngageColumnTablePaginationTemplate from './vngageColumnTablePaginationTemplate';

export default function () {
	'ngInject';

	return {
		restrict: 'E',
		replace: true,
		templateUrl: vngageColumnTablePaginationTemplate
	};
};
