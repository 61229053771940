export default function ($resource, $http, vngageConfig, APIMethodsService, $window) {
	'ngInject';

    return {
        // API resource endpoints
        // ----------------------
        user:                       $resource(vngageConfig.restUrl + 'User/:id', { id: '@data.id' }, APIMethodsService),
        userSendIdActivationEmail:  $resource(vngageConfig.restUrl + 'User/:id/SendIdentityActivationEmail', { id: '@id' }),
        userMe:                     $resource(vngageConfig.restUrl + 'User/Me', { id: '@data.id' }, APIMethodsService),
        roleClaim:                  $resource(vngageConfig.restUrl + 'Account/Current/RoleClaim/:id', { id: '@data.id' }, APIMethodsService),
        group:                      $resource(vngageConfig.restUrl + 'Group/:id', { id: '@data.id' }, APIMethodsService),
        site:                       $resource(vngageConfig.restUrl + 'Site/:id', { id: '@data.id' }, APIMethodsService),
        translation:                $resource(vngageConfig.restUrl + 'Translation/:id', { id: '@data.id' }, APIMethodsService),
        systemConfiguration:        $resource(vngageConfig.restUrl + 'SystemConfiguration/', {}, APIMethodsService),
        systemConfRoleClaim:        $resource(vngageConfig.restUrl + 'SystemConfiguration/RoleClaim/:id', { id: '@data.id' }, APIMethodsService),
        systemConfRoleClaimAttach:  $resource(vngageConfig.restUrl + 'SystemConfiguration/RoleClaim/:id/Attach', { id: '@data.id' }),
        systemConfRoleClaimDetach:  $resource(vngageConfig.restUrl + 'SystemConfiguration/RoleClaim/:id/Detach', { id: '@data.id' }),
        account:                    $resource(vngageConfig.restUrl + 'Account/:id', { id: '@data.id' }, APIMethodsService),
        accountConfiguration:       $resource(vngageConfig.restUrl + 'AccountConfiguration/:id', { id: '@data.id' }, APIMethodsService),
        accountCurrentConfiguration:$resource(vngageConfig.restUrl + 'AccountConfiguration/Current'),
        setting:                    $resource(vngageConfig.restUrl + 'Setting/:id', { id: '@data.id' }, APIMethodsService),
        identityProvider:           $resource(vngageConfig.restUrl + 'Account/Current/IdentityProvider/:id', { id: '@data.id' }, APIMethodsService),
        trustRelationship:          $resource(vngageConfig.restUrl + 'Account/:accountId/TrustRelationship/:id', { id: '@data.id' }, APIMethodsService),
        accountRoleClaim:           $resource(vngageConfig.restUrl + 'Account/:id/RoleClaim', { id: '@data.id' }, APIMethodsService),
        sessionTrusts:              $resource(vngageConfig.path + 'Session/Trusts'),
        sessionAuthorizations:      $resource(vngageConfig.path + 'Session/Authorizations', {}, APIMethodsService),
        userInfo:                   $resource(vngageConfig.restUrl + 'User/Info'),
        accountCurrent:             $resource(vngageConfig.restUrl + 'Account/Current', {}, APIMethodsService),
        configurationSet:           $resource(vngageConfig.restUrl + 'ConfigurationSet/:id', { id: '@data.id' }, APIMethodsService),
        configurationSetHistory:    $resource(vngageConfig.restUrl + 'ConfigurationSet/:id/History', { id: '@data.id' }, APIMethodsService), // Unused for the moment - retrieves max 1000
        configurationSetResolved:   $resource(vngageConfig.restUrl + 'ConfigurationSet/Resolved/:id', { id: '@data.id' }, APIMethodsService),
        configurationSection:       $resource(vngageConfig.restUrl + 'ConfigurationSection/:id', { id: '@data.id' }, APIMethodsService),
        configurationSectionHistory:$resource(vngageConfig.restUrl + 'ConfigurationSection/:id/History', { id: '@data.id' }, APIMethodsService),
        pointOfInterest:            $resource(vngageConfig.restUrl + 'PointOfInterest/:id', { id: '@data.id' }, APIMethodsService),
        script:                     $resource(vngageConfig.restUrl + 'Script/:id', { id: '@data.id' }, APIMethodsService),
        scriptHistory:              $resource(vngageConfig.restUrl + 'Script/:id/History', { id: '@data.id' }, APIMethodsService),
        systemConfAuthorization:    $resource(vngageConfig.restUrl + 'SystemConfiguration/Authorization', {
            resource:   '@data.resource',
            action:     '@data.action'
        }, APIMethodsService),
        timeZone:                   $resource(vngageConfig.restUrl + 'TimeZone', {}, APIMethodsService),
        sandbox:                	$resource(vngageConfig.restUrl + 'Account/Current/Sandbox/Make/:name', { name: '@data.name' }, APIMethodsService),
        workspaceSettings:          $resource(vngageConfig.restUrl + 'Account/:accountId/Bucket/workspaceSettings', { accountId: '@accountId' }, APIMethodsService),
        interactionOverview:        $resource(vngageConfig.restUrl + 'Statistics/InteractionOverview', {}, APIMethodsService),
        case:                       $resource(vngageConfig.restUrl + 'Case/:id', { caseId: '@id' }, APIMethodsService),
        caseReport:                 $resource(vngageConfig.restUrl + 'GenerateConversationReport/:id', {
            caseId:     '@id',
            skip:       '@skip',
            take:       '@take'
        }, APIMethodsService),
		visitorProfile:				$resource(vngageConfig.restUrl + 'Visit/:id', {id: '@id'} , APIMethodsService),
		visitorProfileClaims:		$resource(vngageConfig.restUrl + 'Visit/:id/Claims', {id: '@id'} , APIMethodsService),
		visitorCases:				$resource(vngageConfig.restUrl + 'Case/:id', { visitId: '@id'} , APIMethodsService),
		visitorNavigation:			$resource(vngageConfig.restUrl + 'Tracking/Navigation/:id', {visitId: '@id'} , APIMethodsService),
		conversationCurrent:        $resource(vngageConfig.restUrl + 'Conversation/Current', {}, APIMethodsService),
        reservations:	            $resource(vngageConfig.restUrl + 'Reservations/Reservation/:id', {groupId: '@groupId', start:'@start', end:'@end' } , APIMethodsService),
        reservationsHolidays:	    $resource(vngageConfig.restUrl + 'Reservations/Holidays/:siteId/:id', {siteId: '@siteId'} , APIMethodsService),
        reservationsOpeningHourConfigs:	        $resource(vngageConfig.restUrl + 'Reservations/OpeningHourConfigs/:groupId', {groupId: '@groupId'} , APIMethodsService),
        reservationsOpeningHours:	$resource(vngageConfig.restUrl + 'Reservations/OpeningHours/:groupId', {groupId: '@groupId'} , APIMethodsService),
        reservationsOpeningHourOverrides:	$resource(vngageConfig.restUrl + 'Reservations/OpeningHourOverrides/:groupId/:id', {groupId: '@groupId'} , APIMethodsService),
        reservationsTestOpeningHours:	$resource(vngageConfig.restUrl + 'Reservations/OpeningHourConfigs/:groupId/Test', {groupId: '@groupId', start:'@start', end:'@end' } , APIMethodsService),
        reservationsTestOpeningHourOverrides:	$resource(vngageConfig.restUrl + 'Reservations/OpeningHourOverrides/:groupId/Test', {groupId: '@groupId', start:'@start', end:'@end'} , APIMethodsService),
        // API action endpoints
        // --------------------
        configurationSetActivate: function publish(setId, setRevision) {
            var relativeUrl = 'ConfigurationSet/:id/Activate'.replace(':id', $window.encodeURIComponent(setId));
            relativeUrl = relativeUrl + '?revision=' + setRevision;
            return $http.post(vngageConfig.restUrl + relativeUrl);
        },
        // session bucket endpoint
	    sessionBucket:              $resource(vngageConfig.restUrl + 'Session/Bucket/:key', {key:'@key'}, APIMethodsService),
        logout:                     $resource(vngageConfig.restUrl + 'admin/logout/:key', {key:'@key'}, APIMethodsService),

    };
};
