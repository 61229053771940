export default function() {
	return {
		initialized: false,
		idle: true,
		since: null,
		abort: null,
		participants: [],
		events: [],
		writings: [],
		id: null,
		state: null,
		showMeta: null,
		visualGuidance: null,
		streaming: {
			video: false,
			audio: false
		},
		inviteOptions: null
	};
};
