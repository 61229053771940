export default function() {
    'ngInject';

    var vm = this;
    vm.spinnerOpts = {
        className: 'us-spinner',
        lines: 11, length: 8, width: 4, radius: 8,
        top: '200px', left: '50%'
    };
};
