import immutable from 'immutable';
import AccountConfiguration from '../utils/AccountConfigurationWrapper';
import Notification from '../utils/Notification';
import Lang from '../utils/Lang';
import {filters} from '../reducers/libs/filters';
import AppConstants from '../../../components/constants/AppConstants';

const AC = AppConstants.ActionTypes;

const iconPath = '//content.psplugin.com/images/default/';
const chatIconGif = 'notification-chat.gif';
const queueIconGif = 'notification-queue.gif';

export default function ({getState}) {
	return (next) => (action) => {

		const prevState = getState();
		const returnValue = next(action);
		const nextState = getState();

		let title;
		let body;
		let icon;
		let sound;
		let shouldShow = false;

		const notificationOnChatMessages = AccountConfiguration.configuration.account.notificationOnChatMessages;

		const prevSince = prevState.getIn(['conversations', action.conversationId, 'since']);
		const nextSince = nextState.getIn(['conversations', action.conversationId, 'since']);
		const sinceDiff = prevSince !== nextSince;
		// new messages
		if (action.type === AC.RECEIVE_SINCE && notificationOnChatMessages && sinceDiff) {
			const newChatMsgs = filters.getFilteredArray(filters.identifier.chatMessages, action.rawMessages);
			const me = nextState.getIn(['conversations', action.conversationId, 'me']);
			const onlyFromOthers = newChatMsgs.filter(msg => msg.speaker.visitId || msg.speaker.userId !== me);
			if (onlyFromOthers.length > 0) {
				sound = AccountConfiguration.configuration.account.muteDesktopAudio ? '': 'message';
				icon = iconPath + chatIconGif;
				title = Lang.getTranslation('notificationChatTitle');
				if (onlyFromOthers.length === 1) {
					// 1 message
					body = Lang.getTranslation('notificationChatBody');
				} else {
					// more than one message
					body = Lang.getTranslation('notificationChatBodyPlural');
				}
				shouldShow = true;
			}

			// new visitors
		} else if (action.type === AC.RECEIVE_VISITORS && nextState.get('newVisitors').size > 0) {
			const nrOfNewVisitors = nextState.get('newVisitors').size;
			if (nrOfNewVisitors > 0) {
				sound = AccountConfiguration.configuration.account.muteDesktopAudio ? '': 'queue';
				icon = iconPath + queueIconGif;
				title = Lang.getTranslation('notificationQueueTitle');
				if (nrOfNewVisitors === 1) {
					// 1 in queue
					body = Lang.getTranslation('notificationQueueBody');
				} else {
					// more than one in queue
					body = Lang.getTranslation('notificationQueueBodyPlural');
				}
				shouldShow = true;
			}
			// auto routed conversation
		} else if (action.type === AC.INIT_ROUTED_CONVERSATION) {
			sound = AccountConfiguration.configuration.account.muteDesktopAudio ? '': 'queue';
			icon = iconPath + queueIconGif;
			title = Lang.getTranslation('notificationQueueTitle');
			body = Lang.getTranslation('notificationQueueBody');
			shouldShow = true;
		}
		if (shouldShow) {
			const notificationDuration = AccountConfiguration.configuration.account.notificationDuration;
			Notification.show(title, body, icon, notificationDuration, sound);
		}

		return returnValue;
	};
}

