import _isString from 'lodash/isString';
import _each from 'lodash/each';

export default function ($parse) {
	'ngInject';

	function getValue(item, prop) {
		var val = $parse(prop)(item);
		if (_isString(val)) {
			val = val.toLowerCase();
		}
		return val;
	}

	return function (items, field, reverse) {
		var result = [];

		_each(items, function (item) {
			result.push(item);
		});

		result.sort(function (a, b) {
			return (getValue(a, field) > getValue(b, field) ? 1: -1);
		});

		if (reverse) {
			result.reverse();
		}

		return result;
	};
};
