import _difference from 'lodash/difference';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';

import assign from 'object-assign';

import AppDispatcher from '../dispatcher/AppDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import StoreFactory from '../../../components/utils/StoreFactory';
import MessageUtils from '../../../components/utils/MessageUtils';
import VisualGuidance from '../utils/VisualGuidance';
import conversationRecord from '../../../components/records/ConversationRecord';
import ProfileStore from './ProfileStore';

const ActionTypes = AppConstants.ActionTypes;
const AuthConstants = AppConstants.Authorization;

let state = {
	CONVERSATIONS: new Map()
};

var ConversationsStore = StoreFactory.create({

	/**
	 * find conversation
	 * @param conversationId
	 */
	find (conversationId) {
		return state.CONVERSATIONS.get(conversationId);
	},

	/**
	 * find by ref id
	 * @param conversationRef
	 */
	findByRef (conversationRef) {
		let conversationId = null;
		state.CONVERSATIONS.forEach((value, key) => {
			if (value.conversationRef === conversationRef) {
				conversationId = key;
			}
		});
		if (conversationId) {
			return this.find(conversationId);
		} else {
			return false;
		}
	},

	/**
	 * returns all conversations
	 */
	get all() {
		return state.CONVERSATIONS;
	},

	isConversationStreaming (conversationId) {
		let conversation = this.find(conversationId);
		return conversation && (conversation.streaming.video || conversation.streaming.audio);
	}
});

const registeredCallback = function (payload) {
	const action = payload.action;
	const { conversationState, rawMessages, conversationId, conversations, propsToSet } = action;
	const MY_ID = ProfileStore.getProfileId();

	let conversation = ConversationsStore.find(conversationId),
		mappedMessages,
		since;

	switch (action.type) {

		case ActionTypes.RECEIVE_AS_STATE :

			since = conversationState.stateTime;
			mappedMessages = MessageUtils.mapConversationMessages(conversationState.messages, conversationId);

			if (!conversation || conversation && !conversation.visualGuidance) {
				conversation = assign({}, conversationRecord(), {
					id: conversationState.id,
					conversationRef: action.conversationRef,
					state: conversationState,
					showMeta: true,
					visualGuidance: new VisualGuidance(conversationState.id)
				});
				//conversation = mapVideoState(conversation, mappedMessages);
				addConversation(conversation.id);
				setConversationProps(conversation.id, conversation);
			} else {
				setConversationProps(conversation.id, {state: conversationState});
			}

			updateConversationSince(conversationId, since);

			conversation = mapVideoState(conversation, mappedMessages);
			setConversationProps(conversation.id, conversation);

			addNavigations(conversation, mappedMessages);
			setBrowserInfo(conversation, conversationState.messages);

			// Handle 'metadataExchange' on RECEIVE_AS_STATE (both directions, i.e. include all messages)
			handleMetadataExchange(conversation, mappedMessages, 'state', MY_ID);

			break;

		case ActionTypes.RECEIVE_SINCE :
			since = rawMessages[rawMessages.length - 1].createdAt;

			mappedMessages = MessageUtils.mapConversationMessages(rawMessages, conversationId);
			addNavigations(conversation, mappedMessages);

			// Handle 'metadataExchange' on RECEIVE_SINCE (only incoming, i.e. "MessagesWithoutMe")
			handleMetadataExchange(conversation, MessageUtils.getMessagesWithoutMe(mappedMessages, MY_ID), 'since', MY_ID);

			if (!conversation.isInitialized && !conversation.abort) {

				addConversation(conversationId);
				updateConversationSince(conversationId, since);

			} else {

				let cProps = {abort: false};

				//let vmsg = _find(mappedMessages.slice().reverse(), {messageType: 'video'});
				//if (vmsg && vmsg.message === 'start') {
				//	conversation.visualGuidance.setState({streaming: {audio:true, video:true}});
				//}
				mapVideoState(conversation, mappedMessages);

				setConversationProps(conversationId, conversation);
				setConversationProps(conversationId, cProps);

			}
			break;

		case ActionTypes.REQUEST_ERROR :
			let statusCode = action.statusCode,
				errorCode  = action.errorCode;

			if ([401, 403, 403].indexOf(errorCode) > -1) {
				removeConversation(conversationId);
			}
			break;

		case ActionTypes.PROCESS_CONVERSATIONS :
			if (!filterOutInactive(conversations)) {
				return false;
			}
			break;

		case AuthConstants.NOT_AUTHORIZED :
		case AuthConstants.BLOCKED :
		case ActionTypes.CLEAR_CONVERSATIONS :
		case ActionTypes.CONVERSATION_CLOSED :
		case ActionTypes.CONVERSATION_LEAVE :
			clearConversations();
			break;

		case ActionTypes.ADD_CONVERSATION :
			addConversation(conversationId);
			break;

		case ActionTypes.REMOVE_CONVERSATION :
			removeConversation(conversationId);
			break;

		case ActionTypes.UPDATE_CONVERSATION :
			setConversationProps(conversationId, propsToSet);
			break;

		case ActionTypes.VIDEO_STREAM :
		case ActionTypes.CREATE_VIDEO_INVITATION :
			setConversationProps(conversationId, {streaming: {
				video: action.isStreaming,
				audio: action.isStreaming
			}});
			break;

		//DEPRECATED?
		case ActionTypes.VIDEO_CONFERENCE :
			setConversationProps(conversationId, {streaming: {video: action.startStream, audio: action.startStream}});
			break;

		default :
			return true;
	}

	// if we made down here... emit change event
	ConversationsStore.emitChange();
};

/**
 * add converssation
 * @param conversationId
 */
function addConversation(conversationId) {
	if (!conversationId || ConversationsStore.find(conversationId)) {
		return;
	}
	state.CONVERSATIONS.set(conversationId, assign({}, conversationRecord()));
}

/**
 * remove conversation
 * @param conversationId
 */
function removeConversation(conversationId = '') {
	if (Array.isArray(conversationId)) {
		conversationId.forEach(c => {
			state.CONVERSATIONS.delete(c);
		});
	} else {
		state.CONVERSATIONS.delete(conversationId);
	}
}

/**
 * set properties in conversation
 * @param conversationId
 * @param properties
 */
function setConversationProps(conversationId, properties) {
	let conversation = ConversationsStore.find(conversationId);
	if (conversation) {
		let convo = assign(conversation, properties);
		state.CONVERSATIONS.set(conversationId, convo);
	}
}

/**
 * update timestamp on last since-request of conversation
 * @param conversationId
 * @param since
 */
function updateConversationSince(conversationId, since) {
	let conversation = ConversationsStore.find(conversationId);
	if (conversation && !conversation.isInitialized) {
		setConversationProps(conversationId, {
			initialized: true,
			since: since
		});
	}
}

/**
 * filter out inactive conversations
 * @returns {boolean}
 * @param activeConversations
 */
function filterOutInactive(activeConversations) {
	let storeDiff;

	if (activeConversations.length === 0) {
		clearConversations();
		return true;
	} else {
		var inStore = [];
		ConversationsStore.all.forEach(function (value, key) {
			inStore.push(key);
		});

		activeConversations.map(addConversation);

		// get the diff in order to clear no longer locked visitors
		storeDiff = _difference(inStore, activeConversations);

		if (storeDiff.length > 0) {
			storeDiff.map(removeConversation);
			return true;
		}

		return false;
	}
}

/**
 * clear conversation
 */
function clearConversations() {
	state.CONVERSATIONS.clear();
}

/**
 * add navigations to conversation
 * @param conversation
 * @param msgs
 */
function addNavigations(conversation, msgs) {
	// update vg
	let navigations = msgs.filter(msg => {
		return msg.messageType === 'navigation' || msg.messageType === 'domUpload';
	});

	if (navigations.length > 0) {
		conversation.visualGuidance.addNavigations(navigations, true);
	}
}

/**
 * set browserinfo of visitor in conversation
 * @param conversation
 * @param messages
 */
function setBrowserInfo(conversation, messages) {
	let browserInfoOfState = _filter(messages, {messageType: 'browserInfo'});
	if (browserInfoOfState.length > 0) {
		let lastBrowserInfo = browserInfoOfState[browserInfoOfState.length - 1];
		let browserInfo = {
			width: lastBrowserInfo.width,
			height: lastBrowserInfo.height,
			hasFlash: lastBrowserInfo.hasFlash
		};
		conversation.visualGuidance.setState({browserAttributes: browserInfo})
	}
}

/**
 * map video state upon reload in state of conversation
 * @param conversation
 * @param messages
 * @returns {*}
 */
function mapVideoState(conversation, messages) {
	let videoOfState = _sortBy(_filter(messages, { messageType: 'video'}), 'createdAt');
	if (videoOfState.length > 0) {
		let lastVideoState = videoOfState[videoOfState.length - 1];
		let isStreaming = lastVideoState.activity === 'start';

		conversation.streaming = {
			video: isStreaming,
			audio: isStreaming
		};
	}

	return conversation;
}

/**
 * handle metadataExchange messages in conversation
 * @param conversation
 * @param msgs
 */
function handleMetadataExchange(conversation, msgs, receiveType, myVisitId) {
	// Handle 'metadataExchange' for vg
	let metadataExchangeMessages = msgs.filter( msg => {
		return msg.messageType === 'metadataExchange';
	});
	if (metadataExchangeMessages.length > 0) {
		conversation.visualGuidance.handleReceivedMetadataExchangeMessages(metadataExchangeMessages, receiveType, myVisitId);
	}
}

ConversationsStore.dispatchToken = AppDispatcher.register(registeredCallback);

export default ConversationsStore;
