export default function ($q, $http, vngageConfig) {
	'ngInject';

	function execute(requests) {
		return $q(function (resolve, reject) {
			if (requests.length === 0) {
				resolve();
				return;
			}

			const batchRequest = createBatchRequest(requests);
			$http.post(vngageConfig.restUrl + 'batch', batchRequest).then(function (res) {
				resolve(res.data);
			}, function (res) {
				reject(res);
			});
		});

	}

	function createBatchRequest(requests) {
		const batchRequest = {
			items: []
		};

		requests.forEach(function (request) {
			batchRequest.items.push({
				headers: {},
				contentHeaders: {
					'Content-Type': 'application/json'
				},
				method: request.method,
				uri: vngageConfig.batchRestUrl + request.url,
				body: request.data ? JSON.stringify(request.data) : null
			});
		});

		return batchRequest;
	}

	return {
		execute: execute
	};
}
