import moment from 'moment-timezone';

export default (SessionService) => {
	return {
		options: {
			idProperty: 'data.id',
			stateLink: {
				state: 'root.account.user.edit',
				property: 'data.id'
			},
			createItem: {langKey: 'user.ADD_HEADER'},
			exportButton: {
				langKey: 'uiElement.EXPORT_CSV',
				csvFileName: 'users.csv'
			},
			importCSV: {
				langKey: 'uiElement.IMPORT_CSV',
				id: 'import-btn'
			},
			itemsPerPage: 50
		},

		columns: [
			{
				property: 'data.profile.profilePicture',
				type: 'avatar'
			}, {
				header: 'Name', // 'user.NAME'
				property: 'data.profile.displayName',
				sortDefault: true,
				useInFilter: true
			}, {
				header: 'Email', // 'user.EMAIL
				property: 'data.profile.preferredEmail',
				useInFilter: true
			}, {
				header: 'Guid',
				property: 'data.id',
				sortDefault: true,
				useInFilter: true
			}, {
				header: 'user.ACTIVATED',
				property: 'data.identities.length',
				transform: (v) => v > 0,
				exportRaw: false,
				type: 'tag',
				sortReverse: true
			}, {
				header: 'user.STATUS',
				property: 'data.status',
				transform: (v) => v > 0,
				exportRaw: false,
				type: 'tag',
				settings: {
					stateOptions: {
						0: {
							type: 'success',
							text: 'user.STATE_ACTIVE'
						},
						1: {
							type: 'warning',
							text: 'user.STATE_SUSPENDED'
						},
						2: {
							type: 'danger',
							text: 'user.STATE_BLOCKED'
						}
					}
				}
			}, {
				header: 'user.LAST_SIGNED_IN',
				property: 'data.lastSignIn',
				transform: (v) => v ? moment.utc(v).tz(SessionService.getUserTimezone()).format('L LT'): v,
				sortOnRawData: true,
				sortDefault: true,
				useInFilter: true,
				exportRaw: false,
			}, {
				header: 'Groups',
				property: 'data.groupIds',
				transform: (ids) => ids.join(' '),
				exportRaw: false,
				hidden: true
			}, {
				header: 'Roles',
				property: 'data.roleClaims',
				transform: (claims) => claims.map(claim => claim.split('/').pop().split('#').pop()).join(' '),
				exportRaw: false,
				hidden: true
			}, {
				header: 'Language',
				property: 'data.profile.language',
				hidden: true
			}, {
				header: 'Time zone',
				property: 'data.profile.timeZoneId',
				hidden: true
			}, {
				header: 'External ids',
				property: 'data.externalIds',
				transform: (ids) => Array.isArray(ids) ? ids.join(' '): '',
				exportRaw: false,
				hidden: true
			}
		]
	};
};
