import vngageSortableListTemplate from './vngageSortableListTemplate';

export default function ($parse, $state) {
	'ngInject';

	return {
		restrict: 'E',
		templateUrl: vngageSortableListTemplate,
		require: '?^form',
		scope: {
			sortableItems: '=',
			options: '='
		},
		link: function (scope, elem, attrs, formCtrl) {
			scope.moveUp = function () {
				if (formCtrl) {
					formCtrl.$setDirty();
				}
				arrayElMove(scope.sortableItems, this.$index, this.$index - 1);
			};

			scope.moveDown = function () {
				if (formCtrl) {
					formCtrl.$setDirty();
				}
				arrayElMove(scope.sortableItems, this.$index, this.$index + 1);
			};

			function arrayElMove(arrayInst, oldIndex, newIndex) {
				while (oldIndex < 0) {
					oldIndex += arrayInst.length;
				}
				while (newIndex < 0) {
					newIndex += arrayInst.length;
				}
				if (newIndex >= arrayInst.length) {
					var k = newIndex - arrayInst.length;
					while ((k--) + 1) {
						arrayInst.push(undefined);
					}
				}
				arrayInst.splice(newIndex, 0, arrayInst.splice(oldIndex, 1)[0]);
				return arrayInst;
			}

			scope.goToState = function (item) {
				if (scope.options && scope.options.stateLink) {
					var state = scope.options.stateLink.state;
					var stateId = $parse('refValue.' + scope.options.stateLink.property)(item);

					$state.go(state, {id: stateId});
				}
			};
		}
	};
};
