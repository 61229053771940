import immutable from 'immutable'
import NavigationActions from '../actions/NavigationActions'
import Helpers from './Helpers'

const panelAnimationTime = 240;

function activatePanelDebounced(conversationId) {

	const thunk = (dispatch, getState) => {
		dispatch(NavigationActions.activatePanel(conversationId));

		const panelPosition = getState().getIn(['conversations', conversationId, 'UI', 'panelPosition']);
		Helpers.setFocus(conversationId, panelPosition);
	};

	thunk.meta = {
		debounce: {
			time: panelAnimationTime,
			key: 'tester1'// + conversationId
		}
	};
	return thunk;
}

const activatePanel = conversationId => (dispatch, getState) => {
	const activePanel = getState().get('activePanel');
	if (conversationId !== activePanel) {
		const panelAnimating = getState().get('panelAnimating');
		// debounce if panel is animating
		if (panelAnimating) {
			dispatch(activatePanelDebounced(conversationId));
		} else {
			// not animating set immediately
			dispatch(NavigationActions.activatePanel(conversationId));
			const panelPosition = getState().getIn(['conversations', conversationId, 'UI', 'panelPosition']);
			Helpers.setFocus(conversationId, panelPosition);
		}
	}
};

const repositionPanel = (conversationId, nextPanelPosition) => (dispatch, getState) => {
	const prevPanelPosition = getState().getIn(['conversations', conversationId, 'UI', 'panelPosition']);
	const activePanel = getState().get('activePanel');
	if (nextPanelPosition !== prevPanelPosition) {
		dispatch(NavigationActions.setPanelPosition(conversationId, nextPanelPosition));

		if (conversationId !== activePanel) {
			// different panel is active
			if (nextPanelPosition === 'collapsed') {
				// activate already active panel if this panel is going to collapsed state
				dispatch(activatePanelDebounced(activePanel));
			} else {
				// panel entering coBro or normal, activate it
				dispatch(activatePanelDebounced(conversationId));
			}
		} else {
			dispatch(activatePanelDebounced(conversationId));
		}

	}
};


// helper, move to lib later
function getPanelOrder(state) {

	let panelOrder = state.get('panelOrder');
	const coBroConversationId = state.getIn(['coBro', 'conversationId']);
	const panelIndex = panelOrder.indexOf(coBroConversationId);
	panelOrder = coBroConversationId
		? panelOrder.delete(panelIndex).unshift(coBroConversationId)
		: panelOrder;
	return panelOrder;

}

const move = direction => (dispatch, getState) => {

	const activePanel = getState().get('activePanel');

	if (direction === 'right' || direction === 'left') {
		const panelOrder = getPanelOrder(getState())
		const dirAdd = direction === 'right' ? 1: (panelOrder.size - 1);
		const panelIndex = panelOrder.indexOf(activePanel);
		const nextPanel = panelOrder.get((panelIndex + dirAdd) % panelOrder.size);
		dispatch(activatePanel(nextPanel));
	} else {
		const activePanelPosition = getState().getIn(['conversations', activePanel, 'UI', 'panelPosition']);
		let nextPanelPosition;
		if (direction === 'down') {
			nextPanelPosition = activePanelPosition === 'normal'
				? 'collapsed'
				: activePanelPosition === 'coBro'
					? 'normal'
					: 'collapsed';
		} else {
			nextPanelPosition = activePanelPosition === 'collapsed'
				? 'normal'
				: activePanelPosition === 'normal'
					? 'coBro'
					: 'coBro';

		}
		dispatch(repositionPanel(activePanel, nextPanelPosition));

	}

};


const NavigationActionCreators = {
	getPanelOrder,
	activatePanel,
	repositionPanel,
	move,
};

export default NavigationActionCreators;

