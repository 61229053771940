export default function ($q, APIEndpointService, vngageConfig) {
	'ngInject';

        var defaultProfilePicture = vngageConfig.staticWorkspaceUrl + 'img/gravatar.png';
        var cache = {};
        var initialized = false;

        var service = {
            activate: activate,
            get: get
        };

        return service;

        function activate(userList) {

            if (initialized) {

                return $q.when();

            } else {

                return $q(function (resolve) {

					if (userList){

						setProfilePictures(userList);
						initialized = true;
						resolve();

					} else {

						APIEndpointService.user.query({limit: 10000}).$promise.then(function (res) {
							setProfilePictures(res);
							initialized = true;
							resolve();
						});

					}
                });

            }
        }

		function setProfilePictures(users){
			users.forEach(function (user) {
				cache[user.data.id] = user.data.profile.profilePicture || defaultProfilePicture;
			});
		}

        function get(userId) {
            return cache.hasOwnProperty(userId) ? cache[userId] : defaultProfilePicture;
        }

    };
