import vngageAddModalTemplate from '../../../common/vngageAddModalTemplate';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

export default function ($state, $uibModal, dataLists) {
	'ngInject';

	const vm = this;
	vm.actionPanelList = dataLists.actionPanel;
	const customerCase = dataLists.customerCase;

	vm.tableColumns = [{
		header: 'actionPanel.NAME',
		property: 'data.name',
		sortDefault: true,
		useInFilter: true,
		width: '33%'
	}, {
		header: 'GUID',
		property: 'data.id',
		hidden: true,
		useInFilter: true
	}, {
		header: 'Actions',
		property: 'data.section.actions',
		transform: getActionName,
		type: 'label-list'
	}, {
		header: 'CaseTypes',
		property: 'data.id',
		transform: getCaseTypeName,
		type: 'label-list'
	}];
	vm.tableOptions = {
		stateLink: {
			state: 'root.configure.actionPanel.edit',
			property: 'data.id'
		},
		createItem: {
			langKey: 'actionPanel.ADD_HEADER',
			method: addNew
		}
	};

	function openAddModal() {
		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: vngageAddModalTemplate,
			controller: 'ActionPanelAddModalController as vm',
			resolve: {
				options: () => {
					return {
						header: 'actionPanel.ADD_HEADER',
						label: 'actionPanel.NAME'
					};
				}
			}
		});
		modal.result.then(newActionPanel => {
			vm.actionPanelList.push(newActionPanel);
			$state.go('.edit', {id: newActionPanel.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		// If the state transition to the parent state is ok, then open modal
		$state.go('root.configure.actionPanel').then(openAddModal);
	}

	function getActionName(actionList) {
		const list = actionList || [];
		const trimmedList = list.length > 6 ? list.slice(0, 5) : list.slice(0, 6);
		const resource = _map(_filter(dataLists.actions, resource => {
			return _find(trimmedList, action => {
				return action === resource.data.id;
			});
		}), res => {
			return res.data.name;
		});
		if (trimmedList.length < list.length) {
			resource.push('+' + (list.length - trimmedList.length) + ' more');
		}
		return resource;
	}

	function getCaseTypeName(guid) {
		const resource = _map(_filter(customerCase, resource => {
			return _find(resource.data.section.actionPanels, actionPanel => {
				return actionPanel === guid;
			});
		}), res => {
			return res.data.name;
		});
		const list = resource || [];
		const trimmedList = list.length > 6 ? list.slice(0, 5) : list.slice(0, 6);

		if (trimmedList.length < list.length) {
			trimmedList.push('+' + (list.length - trimmedList.length) + ' more');
		}
		return trimmedList;
	}
};
