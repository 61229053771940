import SystemAccountAddModalTemplate from './SystemAccountAddModalTemplate';

export default function ($state, $uibModal, accountList, systemAccountListTableConfig) {
	'ngInject';

	var vm = this;
	vm.accountList = accountList;
	vm.selectedTemplateAccount = null;
	vm.table = systemAccountListTableConfig;
	vm.table.options.createItem.method = addNew;

	function openAddModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: SystemAccountAddModalTemplate,
			controller: 'SystemAccountAddModalController as modal',
			resolve: {
				accountList: () => {
					return vm.accountList;
				}
			}
		});

		modal.result.then(newAccount => {
			vm.accountList.push(newAccount);
			$state.go('.edit', {id: newAccount.data.id, account: newAccount});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		$state.go('root.system.account').then(openAddModal);
	}
};
