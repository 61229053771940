import DesktopLegacyTranslations from '../DesktopLegacyTranslations';

const orgTitle = document.title;
const interval = 800;
var intervalId;

function start(){
	if (intervalId) {
		return;
	}
	intervalId = setInterval(function(){
		document.title = (document.title === orgTitle) ? DesktopLegacyTranslations.translate('notificationChatTitle') : orgTitle;
	}, interval);
}
function stop(){
	if (intervalId){
		intervalId = clearInterval(intervalId);
		document.title = orgTitle;
	}
}
export default {
	start: start,
	stop: stop
}
