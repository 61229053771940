import vngageSecurityWarningTemplate from './vngageSecurityWarningTemplate';

export default function ($sce, $uibModal, $timeout, DesktopService, vngageConfig) {
	'ngInject';

	return {
		restrict: 'A',
		template: '<iframe style="position:absolute; width:0; height:0; visibility: hidden;" ng-src="{{ url }}" />',
		link: function (scope, element) {
			var url                   = httpifyUrl(vngageConfig.assetPath + 'vngage/ping.html'),
				waitFor               = 5000,
				timer                 = $timeout(tryLoadInsecure, waitFor),
				enableHTTPSCoBrowsing = DesktopService.configuration.visitor.enableHTTPSCoBrowsing;

			// If enableHTTPSCoBrowsing is set in config, skip the ping and bypass the security warning
			if (!enableHTTPSCoBrowsing) { scope.url = $sce.trustAsResourceUrl(url); }

			function tryLoadInsecure() {
				var domLoader = angular.element(element.find('iframe'))[0],
					couldLoad = false;

				try {
					couldLoad = (domLoader.contentDocument.location.href.indexOf('http') === 0);
				} catch (e) {
					couldLoad = true;
				}

				if (couldLoad || enableHTTPSCoBrowsing) {
					success();
				} else {
					showSecurityWarning();
				}
			}

			function httpifyUrl(url) {
				//make sure we have a valid ping page, starting with http:
				url = url.replace(/^(https\:\/\/|\/\/)/gi, 'http://');
				return url;
			}

			function success() {
				cleanup();
				scope.$apply();
			}

			function cleanup() {
				$timeout.cancel(timer);
				if (DesktopService.configuration) {
					DesktopService.configuration.coBrowserSecurity.check = false;
				}
			}

			function showSecurityWarning() {
				var modal = $uibModal.open({
					scope: scope,
					windowClass: 'modal-security',
					templateUrl: vngageSecurityWarningTemplate,
					backdrop: 'static',
					backdropClass: 'modal-backdrop-invisible'
				});

				modal.result.catch(() => {
					// Modal dismissed...
				}).finally(cleanup);
			}
		}
	};
}
