import moment from 'moment-timezone';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import GuidGenerator from '../../../../../components/utils/GuidGenerator';

export default function ($scope, $uibModalInstance, $translate, options) {
	'ngInject';

	const vm = this;

	const openingHoursEventsToBlocks = (openingHours) => {
		// This function parses "openingHours" and creates valid "blocks" from them
		// Some intelligence is needed, as "openingHours" currently may contain single "open"-events that open on both workdays and holidays, matched by separate/different "close"-events
		// And the other way around: Two separate "open"-events for workdays and holidays, matched by a single "close"-event valid for for both
		// We need to match each "open"-event with a corresponding "close"-event to create valid blocks
		// This means that we sometimes need to create and insert extra "open" or "close"-events.
		// openingHours.event.validOnHolidays === 0: Workdays only
		// openingHours.event.validOnHolidays === 1: Workdays + holidays
		// openingHours.event.validOnHolidays === 2: Holidays only

		const blocks = [];

		// Start by finding/analyzing/adding all "open"-events with "validOnHolidays" === 0  (Workdays only)
		// Find the corresponding "close"-event, and split it in two if its "validOnHolidays" === 1
		let openEvent = null;
		openingHours.forEach(event => {
			if (!openEvent && event.event === 'open' && event.validOnHolidays === 0) {
				// Start new block for workdays only
				openEvent = event;
			}
			if (openEvent && event.event === 'close' && (event.validOnHolidays === 0 || event.validOnHolidays === 1)) {
				// Close the currentBlock (workdays only)
				blocks.push({
					id: GuidGenerator.create(),
					openTime: openEvent.time,
					closeTime: event.time,
					validOnHolidays: 0,
					reservationDuration: openEvent.reservationDuration,
					slots: openEvent.slots
				});
				openEvent = null;
			}
		});

		// Find/analyze/add all "open"-events with "validOnHolidays" === 2  (Holidays only)
		// Find the corresponding "close"-event, and split it in two if its "validOnHolidays" === 1
		openEvent = null;
		openingHours.forEach(event => {
			if (!openEvent && event.event === 'open' && event.validOnHolidays === 2) {
				// Start new block for workdays only
				openEvent = event;
			}
			if (openEvent && event.event === 'close' && (event.validOnHolidays === 2 || event.validOnHolidays === 1)) {
				// Close the currentBlock (workdays only)
				blocks.push({
					id: GuidGenerator.create(),
					openTime: openEvent.time,
					closeTime: event.time,
					validOnHolidays: 2,
					reservationDuration: openEvent.reservationDuration,
					slots: openEvent.slots
				});
				openEvent = null;
			}
		});

		// Find/analyze/add all "open"-events with "validOnHolidays" === 1  (Workdays + Holidays)
		// Find the corresponding "close"-event, and split it in two if its "validOnHolidays" === 1
		openEvent = null;
		let workdaysClosed, holidaysClosed;
		openingHours.forEach(event => {
			if (!openEvent && event.event === 'open' && event.validOnHolidays === 1) {
				// Start new block for workdays only
				openEvent = event;
				workdaysClosed = false;
				holidaysClosed = false;
			}
			if (openEvent && event.event === 'close') {
				if (event.validOnHolidays === 1) {
					// Close the currentBlock (workdays + holidays)
					blocks.push({
						id: GuidGenerator.create(),
						openTime: openEvent.time,
						closeTime: event.time,
						validOnHolidays: 1,
						reservationDuration: openEvent.reservationDuration,
						slots: openEvent.slots
					});
					workdaysClosed = true;
					holidaysClosed = true;
				} else if (event.validOnHolidays === 0) {
					// This is a "close"-event for workdays only.
					// Add a block for workdays only (0)
					blocks.push({
						id: GuidGenerator.create(),
						openTime: openEvent.time,
						closeTime: event.time,
						validOnHolidays: 0,
						reservationDuration: openEvent.reservationDuration,
						slots: openEvent.slots
					});
					workdaysClosed = true;
				} else if (event.validOnHolidays === 2) {
					// This is a "close"-event for holidays only.
					// Add a block for holidays only (2)
					blocks.push({
						id: GuidGenerator.create(),
						openTime: openEvent.time,
						closeTime: event.time,
						validOnHolidays: 2,
						reservationDuration: openEvent.reservationDuration,
						slots: openEvent.slots
					});
					holidaysClosed = true;
				}
				if (workdaysClosed && holidaysClosed) {
					// Only null the "openEvent" when both a "workdays"- and a "holidays"-block has been added
					openEvent = null;
				}
			}
		});
		return blocks.sort(getSortFunction(['openTime', 'validOnHolidays']));
	};

	const optimizeBlocks = (blocks) => {
		// This function merges blocks that are exactly the same, but validOnHolidays = 0, 2

		const blocksAreEqual = (b1, b2) => {
			return _isEqual(_omit(b1, ['id', 'validOnHolidays']), _omit(b2, ['id', 'validOnHolidays']));
		};

		let prevBlock = null;
		blocks = _cloneDeep(blocks).sort(getSortFunction(['openTime', 'validOnHolidays'])).filter(block => {
			if (prevBlock && blocksAreEqual(prevBlock, block)) {
				// - There is a "prevBlock" and it's equal to "block" => they should be merged!
				// Change prevBlock and return false to NOT include 'block' in the resulting array
				if (prevBlock.validOnHolidays !== block.validOnHolidays) {
					// The merged block should be valid both workdays and holidays
					prevBlock.validOnHolidays = 1;
				}
				return false;
			}
			prevBlock = block;
			return true;
		});
		return blocks;
	};

	const blocksToOpeningHoursEvents = (blocks) => {
		const events = [];
		blocks.forEach(block => {
			// Push one open-event, and one close-event for each block...
			events.push({
				enableReservation: true,
				event: 'open',
				reservationDuration: block.reservationDuration,
				slots: block.slots,
				time: block.openTime,
				validOnHolidays: block.validOnHolidays
			});
			events.push({
				enableReservation: false,
				event: 'close',
				reservationDuration: 0,
				slots: 0,
				time: block.closeTime,
				validOnHolidays: block.validOnHolidays
			});
		});
		return events.sort(getSortFunction(['time']));
	};

	const init = () => {
		vm.options = options;
		vm.openingHours = options.openingHours;
		vm.errorMessage = '';
		vm.possibleValues = {
			times: generateTimes(15),
			validAtValues: [0, 1, 2],
			durations: [15, 30, 45, 60, 90, 120, 150, 180, 210, 240],
			slots: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
		};
		vm.editDateRange = options.editDateRange;
		vm.blockBeingEdited = null;
		vm.conflictingBlocks = [];

		vm.model = {
			blocks: [],
			applyToDays: [false, false, false, false, false, false, false],
			enabledDays: [false, false, false, false, false, false, false]
		};

		let openingHours = vm.openingHours[vm.options.dayOfWeekNumber - 1] && vm.openingHours[vm.options.dayOfWeekNumber - 1].openHourEvents || [];
		// console.log('openingHours:', openingHours);

		if (openingHours) {
			openingHours = openingHours.sort(getSortFunction(['time']));
			vm.model.blocks = optimizeBlocks(openingHoursEventsToBlocks(openingHours));
		}

		if (vm.editDateRange) {
			vm.titleStr = (vm.editDateRange.new ? $translate.instant('openingHours.NEW_OVERRIDE_TITLE') : $translate.instant('openingHours.EDIT_OVERRIDE_TITLE')) + ' ' + vm.editDateRange.start.format('YYYY-MM-DD') + ' - ' + vm.editDateRange.end.format('YYYY-MM-DD');
			let checkDay = moment(vm.editDateRange.start);
			while (checkDay.isBefore(vm.editDateRange.end) || checkDay.isSame(vm.editDateRange.end)) {
				let dayOfWeek = +checkDay.format('E');
				vm.model.enabledDays[dayOfWeek - 1] = true;
				checkDay.add(1, 'day');
			}
		} else {
			vm.titleStr = $translate.instant('openingHours.EDIT_RECURRING_TITLE');
			vm.model.enabledDays = [true, true, true, true, true, true, true];
		}

		vm.model.applyToDays[vm.options.dayOfWeekNumber - 1] = vm.model.enabledDays[vm.options.dayOfWeekNumber - 1];

		vm.cancel = $uibModalInstance.dismiss;

		vm.saveModel = function () {
			if (validateAll()) {
				const openingHoursEvents = blocksToOpeningHoursEvents(vm.model.blocks);
				// console.log('saveModel: ',openingHoursEvents);
				vm.model.applyToDays.forEach((apply, i) => {
					if (apply) {
						vm.openingHours[i] = {
							openHourEvents: openingHoursEvents
						};
					}
				});
				$uibModalInstance.close(vm.openingHours);
			}
		};

		$scope.$watch('vm.model.applyToDays', () => {
			validateAll();
		}, true);

		$scope.$watch('vm.blockBeingEdited', () => {
			validateAll();
		}, true);
	};

	const validateBlock = block => {
		vm.conflictingBlocks = [];
		vm.errorMessage = '';
		if (block) {
			// Check openTime and closeTime exists...
			// Fail validation, but don't display an error message...
			if (!block.openTime || !block.closeTime) {
				return false;
			}

			// Check that closeTime > openTime...
			if (moment.duration(block.closeTime) - moment.duration(block.openTime) <= 0) {
				vm.errorMessage = $translate.instant('openingHours.ERROR_START_END_TIME');
				return false;
			}

			// Check that block is not in conflict with any existing blocks...
			vm.conflictingBlocks = vm.model.blocks.filter(b => blocksConflict(b, block));
			if (vm.conflictingBlocks.length) {
				vm.errorMessage = $translate.instant('openingHours.ERROR_EDITED_BLOCK_CONFLICT');
				return false;
			}
		}
		return true;
	};

	const validateDays = () => {
		vm.errorMessage = '';
		if (!vm.model.applyToDays.some(daySelected => daySelected)) {
			vm.errorMessage = $translate.instant('openingHours.ERROR_NO_DAYS_SELECTED');
			return false;
		}
		return true;
	};

	const validateExistingBlocks = () => {
		const errorBlocks = [];
		const conflictingBlocks = [];
		vm.model.blocks.forEach(block => {
			if (!validateBlock(block)) {
				errorBlocks.push(block);
				conflictingBlocks.push(...vm.conflictingBlocks);
			}
		});
		if (conflictingBlocks.length) {
			vm.conflictingBlocks = conflictingBlocks;
			vm.errorMessage = $translate.instant('openingHours.ERROR_EXISTING_BLOCKS_CONFLICT');
			return false;
		} else if (errorBlocks.length) {
			vm.conflictingBlocks = errorBlocks;
			vm.errorMessage = $translate.instant('openingHours.ERROR_INVALID_CONFIG');
			return false;
		}
		return true;
	};

	const validateAll = () => {
		vm.allowSaveEditedBlock = true;
		if (vm.blockBeingEdited && !validateBlock(vm.blockBeingEdited)) {
			vm.allowSaveEditedBlock = false;
			return false;
		}
		if (!validateExistingBlocks()) {
			return false;
		}
		if (!validateDays()) {
			return false;
		}
		return true;
	};

	vm.getBlockValidityClass = validOnHolidays => {
		switch (validOnHolidays) {
			case 0:
				return {'valid-on-workdays-only': true};
			case 1:
				return {'valid-on-workdays-and-holidays': true};
			case 2:
				return {'valid-on-holidays-only': true};
			default:
				return {};
		}
	};
	vm.getBlockValidityText = validOnHolidays => {
		switch (validOnHolidays) {
			case 0:
				return $translate.instant('openingHours.ON') + $translate.instant('openingHours.WORKDAYS');
			case 1:
				return $translate.instant('openingHours.ON') + $translate.instant('openingHours.WORKDAYS_AND_HOLIDAYS');
			case 2:
				return $translate.instant('openingHours.ON') + $translate.instant('openingHours.HOLIDAYS');
			default:
				return '';
		}
	};

	vm.blockIsInArray = (block, blockArray) => {
		return block && blockArray && blockArray.length && !!blockArray.find(b => b.id === block.id);
	};

	vm.newOrEditBlock = block => {
		if (block) {
			// Edit exiting block
			vm.blockBeingEdited = _cloneDeep(block);
		} else {
			// Create new block
			vm.blockBeingEdited = {
				id: null,
				openTime: (vm.model.blocks.length ? null : '08:00'),
				closeTime: (vm.model.blocks.length ? null : '16:00'),
				validOnHolidays: 0,
				reservationDuration: 60,
				slots: 2
			};
		}
	};

	const blocksConflict = (block1, block2) => {
		if (!block1 || !block2 || block1.id === block2.id) {
			// Don't count conflicts will null, undefined or self
			return false;
		}
		const sortedBlocks = [block1, block2].sort(getSortFunction(['openTime']));
		const blocksOverlap = (moment.duration(sortedBlocks[0].closeTime) - moment.duration(sortedBlocks[1].openTime) > 0);
		if (!blocksOverlap) {
			// Blocks don't overlap in time => No conflict
			return false;
		} else {
			// Blocks overlap in time => Conflict if valid on same days, ok if not
			return (block1.validOnHolidays === 1 || block2.validOnHolidays === 1 || block1.validOnHolidays === block2.validOnHolidays);
		}
	};

	vm.addOrUpdateBlock = (block) => {
		if (validateBlock(block)) {
			// Block ok! Insert or replace it...
			block.id = block.id || GuidGenerator.create();
			vm.model.blocks = vm.model.blocks.filter(b => b.id !== block.id);
			vm.model.blocks.push(_cloneDeep(block));
			vm.model.blocks = optimizeBlocks(vm.model.blocks);
			vm.closeAddOrEditBlock();
		}
	};

	vm.deleteBlock = (block) => {
		vm.model.blocks = optimizeBlocks(vm.model.blocks.filter(b => b.id !== block.id));
		vm.closeAddOrEditBlock();
	};

	vm.closeAddOrEditBlock = () => {
		vm.errorMessage = '';
		vm.blockBeingEdited = null;
		vm.conflictingBlocks = [];
		validateAll();
	};

	function generateTimes(intervalMinutes) {
		var arr = [];
		var start = moment().startOf('day');
		var end = moment().endOf('day');
		while (start < end) {
			arr.push(start.format('HH:mm'));
			start.add(intervalMinutes, 'minutes');
		}
		return arr;
	}

	function getSortFunction(keys) {
		return function (t1, t2) {
			for (let i=0; i < keys.length; i++) {
				if (keys[i] && t1[keys[i]] !== t2[keys[i]]) {
					if (typeof t1[keys[i]] === 'string' && typeof t2[keys[i]] === 'string') {
						return (moment.duration(t1[keys[i]]) - moment.duration(t2[keys[i]]));
					}
					return t1[keys[i]] - t2[keys[i]];
				}
			}
			return 0;
		};
	}

	init();
}
