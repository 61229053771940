import _keys from 'lodash/keys';
import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function ($scope, $state, $stateParams, translation, translations, sysTranslations, VngageUtil, vngageRemoveModalService) {
	'ngInject';

	var vm = this;
	vm.resourceId = $stateParams.id;
	vm.translation = translation;
	vm.translationValues = null;
	vm.languageCodes = _keys(sysTranslations.data);
	vm.currentList = $stateParams.currentList || [];

	vm.submit = save;
	vm.remove = remove;
	vm.cancel = cancel;

	$scope.$on('resetResettables', () => {
		vm.translationEditForm.$setPristine();
		translation.$get().catch(() => {
		});
	});

	activate();

	function activate() {
		vm.translationValues = sysTranslations.data[vm.translation.data.languageCode];
	}

	function save() {
		vm.isSaving = true;
		for (var value in vm.translation.data.values) {
			if (!vm.translation.data.values[value]) {
				delete vm.translation.data.values[value];
			}
		}

		vm.translation.$save().finally(function () {
			vm.isSaving = false;
		});
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: translation,
			itemType: 'translation'
		}).then(function () {
			vm.translationEditForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(translation, 'root.account.translation.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(function () {
			vm.isSaving = false;
		});
	}

	function cancel() {
		$state.go('^');
	}
}
