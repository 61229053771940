import _find from 'lodash/find';

export default function ($translate) {
	'ngInject';

	return function (participant, participants) {
		if (!participant) {
			return false;
		}
		if (participant.userId || participant.visitId) {
			const mappedParticipant = _find(participants, {userId: (participant.userId || participant.visitId)});
			return (mappedParticipant && mappedParticipant.userName || participant.userId || participant.visitId);
		}

		return $translate.instant('caseBrowserModal.TRANSCRIPT_UNKNOWN_USER');
	};
}
