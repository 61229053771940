import React from 'react';
import mmd from '../../../../../common/vngageMarkdown/mmd';
import Timestamp from './Timestamp.react';

const MessageLink = (props) => {
	const rawMarkup = mmd(props.title);
	const {linkType, speaker} = props;

	return (
		<div className="message lineClampEllipsis">
			<strong>{speaker}: </strong>
			<i className={linkType}>
				<a href={props.txt} target="_blank"><span dangerouslySetInnerHTML={{__html: rawMarkup}}/></a>
			</i>
			<Timestamp
				timestamp={props.timestamp}
				isMostRecentMessage={props.isMostRecentMessage}
				displayRelativeDialogTime={props.displayRelativeDialogTime}
			/>
		</div>
	)
};
export default MessageLink

