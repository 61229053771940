import React from 'react';
import {connect} from 'react-redux';
import {Button, ButtonGroup, Dropdown, DropdownButton} from 'react-bootstrap';
import VideoActions from './../../actions/VideoActions';
import VideoError from './../../constants/VideoError';
import VideoState from './../../constants/VideoState';

const DebugVideoTab = (props) => {

	const handleVideoAction = (key, option) => {
		switch (key) {
			case 'setVideoError':
				props.setVideoError(option);
				break;
			case 'setVideoState':
				props.setVideoState(option);
				break;
			case 'resetVideoError':
				props.setVideoError(null);
				break;
			case 'resetVideoState':
				props.setVideoState(null);
				break;
		}
	};

	const videoErrorMenuItems = Object.keys(VideoError).map(key => {
		return <Dropdown.Item
			key={key}
			onClick={(e) => {
				e.stopPropagation();
				handleVideoAction('setVideoError', VideoError[key]);
			}}>
			{key}
		</Dropdown.Item>;
	});
	const videoStateMenuItems = Object.keys(VideoState).map(key => {
		return <Dropdown.Item
			key={key}
			onClick={(e) => {
				e.stopPropagation();
				handleVideoAction('setVideoState', VideoState[key]);
			}}>
			{key}
		</Dropdown.Item>;
	});

	return (
		<div
			style={{
				width: '100%',
				color: 'white',
				display: 'flex',
				flexDirection: 'column'
			}}>

			<ButtonGroup vertical>

				<DropdownButton
					variant="default"
					style={{marginBottom: '10px', width: '100%'}}
					className="dark"
					id='videorErrors'
					title="Set error">
					{videoErrorMenuItems}
				</DropdownButton>

				<DropdownButton
					variant="default"
					style={{marginBottom: '10px', width: '100%'}}
					className="dark"
					id='videorStates'
					title="Set state">
					{videoStateMenuItems}
				</DropdownButton>

				<Button
					variant="default"
					style={{marginBottom: '10px'}}
					className="dark"
					onClick={() => handleVideoAction('resetVideoError')}>
					Reset error
				</Button>

				<Button
					variant="default"
					style={{marginBottom: '10px'}}
					className="dark"
					onClick={() => handleVideoAction('resetVideoState')}>
					Reset state
				</Button>

			</ButtonGroup>

			<br/>
			<span>State: {props.videoState}</span>
			<span>Error: {props.videoError}</span>

		</div>
	);
};

function mapStateToProps(state) {
	return {
		videoState: state.getIn(['video', 'state']),
		videoError: state.getIn(['video', 'error'])
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setVideoError: (error) => dispatch(VideoActions.setError(error, 'debug')),
		setVideoState: (state) => dispatch(VideoActions.setState(state))
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DebugVideoTab);
