import {
	newStateDebug,
	removeAllFakePanels,
	removeFakePanel,
	addFakePanel,
	addFakeMessage,
	addFakeSystemMessage,
	toggleFakeTyping,
	addFakeVisitorProfile,
	addFakeVisitor,
	addFakeReservation,
	setFakeConversationStatus,
	addFakeConversation,
} from './stateModifiers/fake';

import AppConstants from '../../../components/constants/AppConstants';

const Fakes = AppConstants.Fakes;

export default function FakeReducer(state, action) {
	switch (action.type) {
		case Fakes.NEW_STATE_DEBUG:
			return newStateDebug(state, action);

		case Fakes.REMOVE_ALL_FAKE_PANELS:
			return removeAllFakePanels(state, action);

		case Fakes.REMOVE_FAKE_PANEL:
			return removeFakePanel(state, action);

		case Fakes.ADD_FAKE_PANEL:
			return addFakePanel(state, action);

		case Fakes.ADD_FAKE_MESSAGE:
			return addFakeMessage(state, action);

		case Fakes.ADD_FAKE_SYSTEM_MESSAGE:
			return addFakeSystemMessage(state, action);

		case Fakes.TOGGLE_FAKE_TYPING:
			return toggleFakeTyping(state, action);

		case Fakes.ADD_FAKE_VISITOR_PROFILE:
			return addFakeVisitorProfile(state, action);

		case Fakes.ADD_FAKE_VISITOR:
			return addFakeVisitor(state, action);

		case Fakes.ADD_FAKE_RESERVATION:
			return addFakeReservation(state, action);

		case Fakes.SET_FAKE_CONVERSATION_STATUS:
			return setFakeConversationStatus(state, action);

		case Fakes.ADD_FAKE_CONVERSATION:
			return addFakeConversation(state, action);

		default:
			return state;
	}
}
