// *.psplugin.com -> https://fag-se-kibana.int.vergic.com/
// us-*.vergic.com -> https://fag-us-kibana.int.vergic.com/
// st-*.vergic.com -> https://fag-st-kibana.int.vergic.com/
// af-*.vergic.com -> https://fag-af-kibana.int.vergic.com/
// drift-af.vergic.com -> https://fag-af-kibana.int.vergic.com/
// test-af.vergic.com -> https://fag-af-kibana.int.vergic.com/
// *.test47.int.vergic.com -> https://mma-test47-kibana.int.vergic.com/
// *.testpub.vergic.com -> https://mma-testpub-kibana.int.vergic.com/

export const kibanaInfo = [
	{
		base: 'https://fag-se-kibana.int.vergic.com',
		re: /\.psplugin\.com/g,
		index: '7dca4720-14eb-11ea-9c22-4b97b211c4ca'
	},
	{
		base: 'https://fag-us-kibana.int.vergic.com',
		re: /us-.+\.vergic\.com/g,
		index: 'ce36dce0-4688-11ea-98c3-730944b2476d'
	},
	{
		base: 'https://fag-st-kibana.int.vergic.com',
		re: /st-.+\.vergic\.com/g,
		index: '424d6830-1bf8-11ea-9886-557457cf6c12'
	},
	{
		base: 'https://fag-af-kibana.int.vergic.com',
		re: /af-.+\.vergic\.com/g,
		index: '725900e0-41d0-11ea-910e-25923b7f47b4'
	},
	{
		base: 'https://fag-af-kibana.int.vergic.com',
		re: /\.drift-af\.vergic\.com/g,
		index: '725900e0-41d0-11ea-910e-25923b7f47b4'
	},
	{
		base: 'https://mma-test47-kibana.int.vergic.com',
		re: /test47\.int\.vergic\.com/g,
		index: '486770e0-7a5c-11ea-84cb-f15f60ed271a'
	},
	{
		base: 'https://mma-testpub-kibana.int.vergic.com',
		re: /testpub\.vergic\.com/g,
		index: ''
	},
];

export const getUrl = (base, index, from, to, conversationId) => {
	return `${base}/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${from}',to:'${to}'))&_a=(columns:!(_source),filters:!(),index:'${index}',interval:auto,query:(language:kuery,query:"${conversationId}"),sort:!())`;
};