import _find from 'lodash/find';

export default function () {
	'ngInject';

	return {
		create: function (trustUrl, accountId, trustRelationships, accountIds) {
			var trusts = [];

			accountIds.forEach(function (a) {
				var existing = _find(trustRelationships, function (t) {
					return t.data.trustedAccountId === a;
				});

				if (!existing) {
					trusts.push({
						method: 'POST',
						data: {trustedAccountId: a},
						url: trustUrl.replace(':accountId', accountId)
					});
				}
			});

			return trusts;
		}
	};
};
