import vngageUsersOnlineTemplate from './vngageUsersOnlineTemplate';

export default function (crossfilter, dc, UsersOnlineDimensionService, d3) {
	'ngInject';

    return {
        restrict: 'A',
        require: '^ngModel',
        templateUrl: vngageUsersOnlineTemplate,
        link: function ($scope, element, attrs) {

            var table;

            $scope.$watch(attrs.ngModel, function (v) {
                if (!v) {
                    return;
                }

                draw(v);
            }, true);

            function draw(data) {

                var dimension = UsersOnlineDimensionService.calculate(data);

                if (!table) {

                    table = createTable(dc.dataTable('#usersOnlineTable'), dimension);
                    table.render();

                } else {

                    table.redraw();

                }
            }

            function createTable(dataTable, dimension) {

                return dataTable
                    .dimension(dimension.by.userId)
                    .group(function() { return ''; })
                    .sortBy(function(d) { return d.name; })
                    .order(d3.descending)
                    .columns([
                        function(d) {
                            var user = d.name || d.userId,
                                className = d.availableInGroups.length > 0 ? '' : 'presence-away',
                                html = '';
                            html += '<img class="'+ className +'" title="' + user + '" src="' + d.profilePicture + '"/>';
                            html += '<span class="username" title="' + user + '">' + trim(user) + '</span>';
                            return html;
                        },
                        function(d) {
							var html = '<span class="active chat">' + d.numberOfOngoingDialogs + '</span>';
							return html;
						}
                    ]);
            }

            function trim(value) {
                if (value.length > 20) {
                    return value.substring(0, 21) + '...';
                }
                return value;
            }
        }
    };
}
