export default function() {
	'ngInject';
	return function(groups, showOnlyQueueReservationGroups) {
		if (showOnlyQueueReservationGroups) {
			return groups.filter(function (group) {
				return group.data.reservationBehaviour !== 0;
			});
		} else {
			return groups;
		}
	};
};
