import _cloneDeep from 'lodash/cloneDeep';
import defaultTranslations from './BannerEditTranslationDefaults';

export default function($scope, $uibModalInstance, translations, NotificationService) {
	'ngInject';

	const vm = this;
	vm.mergeMode = 0;
	vm.existingTranslation = _cloneDeep(translations);
	vm.importedTranslation = {};
	vm.translationsText = '';

	vm.cancel = $uibModalInstance.dismiss;
	vm.import = () => {
		try {
			if (vm.translationsText) {
				vm.importedTranslation = JSON.parse(vm.translationsText);
				let newTranslation = {
					translations: {},
					custom: {},
					errorTranslations: {}
				};

				///////////////////////////////////////////////////////////////////////
				// Regular translations
				let importKeys = (vm.importedTranslation.translations ? Object.keys(vm.importedTranslation.translations) : []);
				let invalidKeys = [];

				// Check that all keys in import exists in the defaultTranslation
				let defaultTranslationsKeys = Object.keys(defaultTranslations.defaultTranslation_en.translations);
				importKeys.forEach(key => {
					if (defaultTranslationsKeys.indexOf(key) === -1) {
						invalidKeys.push(key);
					}
				});
				if (invalidKeys.length) {
					throw {
						error: 'invalidKeys',
						invalidKeys: invalidKeys
					};
				}

				// Check that all keys in import is of type "string"
				invalidKeys = [];
				importKeys.forEach(key => {
					if (typeof vm.importedTranslation.translations[key] !== 'string') {
						invalidKeys.push(key);
					}
				});
				if (invalidKeys.length) {
					throw {
						error: 'keysTypeError',
						invalidKeys: invalidKeys
					};
				}

				// Import regular translation keys
				importKeys.forEach(key => {
					if (typeof vm.existingTranslation.translations[key] === 'undefined' || vm.mergeMode === 0) {
						newTranslation.translations[key] = vm.importedTranslation.translations[key];
					}
				});



				///////////////////////////////////////////////////////////////////////
				// Custom translations

				// Check that all custom keys in import is of type "string"
				importKeys = (vm.importedTranslation.custom ? Object.keys(vm.importedTranslation.custom) : []);
				invalidKeys = [];
				importKeys.forEach(key => {
					if (typeof vm.importedTranslation.custom[key] !== 'string') {
						invalidKeys.push(key);
					}
				});
				if (invalidKeys.length) {
					throw {
						error: 'customKeysTypeError',
						invalidKeys: invalidKeys
					};
				}

				// Import custom keys
				importKeys.forEach(key => {
					if (typeof vm.existingTranslation.custom[key] === 'undefined' || vm.mergeMode === 0) {
						newTranslation.custom[key] = vm.importedTranslation.custom[key];
					}
				});



				///////////////////////////////////////////////////////////////////////
				// errorTranslations

				importKeys = (vm.importedTranslation.errorTranslations ? Object.keys(vm.importedTranslation.errorTranslations) : []);

				// Check that all error keys in import exists in the defaultTranslation
				invalidKeys = [];
				const defaultErrorTranslationsKeys = Object.keys(defaultTranslations.defaultTranslation_en.errorTranslations);
				importKeys.forEach(key => {
					if (defaultErrorTranslationsKeys.indexOf(key) === -1) {
						invalidKeys.push(key);
					}
				});
				if (invalidKeys.length) {
					throw {
						error: 'invalidErrorKeys',
						invalidKeys: invalidKeys
					};
				}

				// Check that all error keys in import is objects with correct sub-keys
				invalidKeys = [];
				importKeys.forEach(key => {
					if (typeof vm.importedTranslation.errorTranslations[key] !== 'object') {
						invalidKeys.push(key);
					} else if (typeof vm.importedTranslation.errorTranslations[key].description !== 'string') {
						invalidKeys.push(key);
					} else if (typeof vm.importedTranslation.errorTranslations[key].translation !== 'string') {
						invalidKeys.push(key);
					}
				});
				if (invalidKeys.length) {
					throw {
						error: 'errorKeysTypeError',
						invalidKeys: invalidKeys
					};
				}

				// Import errorTranslations keys
				importKeys.forEach(key => {
					if (typeof vm.existingTranslation.errorTranslations[key] === 'undefined' || vm.mergeMode === 0) {
						newTranslation.errorTranslations[key] = {};
						newTranslation.errorTranslations[key].description = vm.importedTranslation.errorTranslations[key].description;
						newTranslation.errorTranslations[key].translation = vm.importedTranslation.errorTranslations[key].translation;
					}
				});

				$uibModalInstance.close(newTranslation);
			} else {
				$uibModalInstance.close();
			}
		} catch (e) {
			console.error('Exception importing JSON:', e);
			let bodyText;
			if (!e) {
				bodyText = 'Unknown error parsing JSON';
			} else if (!e.error) {
				bodyText = 'Error JSON: '+ e.toString();
			} else {
				switch (e.error) {
					case 'missingSection':
						bodyText = 'Section(s) missing in JSON data. Must include "translations", "custom" and "errorTranslations"';
						break;
					case 'invalidKeys':
						bodyText = 'JSON contains unknown key(s): ' + e.invalidKeys.join(', ');
						break;
					case 'keysTypeError':
						bodyText = 'Translation key(s) not of type string: ' + e.invalidKeys.join(', ');
						break;
					case 'customKeysTypeError':
						bodyText = 'Custom key(s) not of type string: ' + e.invalidKeys.join(', ');
						break;
					case 'invalidErrorKeys':
						bodyText = 'JSON contains unknown errorTranslations key(s): ' + e.invalidKeys.join(', ');
						break;
					case 'errorKeysTypeError':
						bodyText = 'JSON contains invalid errorTranslations key(s): ' + e.invalidKeys.join(', ');
						break;
					default:
						bodyText = 'Unknown error: '+ e.error;
				}
			}
			NotificationService.error({header: 'Error importing translations', body: bodyText});
		}
	};
}
