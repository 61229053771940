import { Record, Map, List, fromJS } from 'immutable';

export const CustomFormRecord = Record({

	name: '',
	label: '',
	type: '',
	value: '',
	validateAs: '',
	titlePosition: 0

}, 'CustomForm');

export const ReservationMetadataRecord = Record({

	customForms: new List()

}, 'ReservationMetadata');

export const ReservationRecord = Record({

	id: '',
	groupId: '',
	siteId: '',
	solutionId: '',
	accountId: '',
	opportunityId: '',
	correlationId: '',
	conversationId: '',
	caseId: '',
	caseType: '',
	title: '',
	name: '',
	lastname: '',
	email: '',
	start: '',
	created: '',
	duration: '',
	phone: '',
	comment: '',
	cancelationMessage: '',
	hasReplied: false,
	pendingReplied: false,
	cancelled: false,
	metadata: new ReservationMetadataRecord()

}, 'ReservationRecord')