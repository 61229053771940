export default {
    'en-GB': {
        role: {
            ADD_HEADER: 'New role',
            EDIT_HEADER: 'Edit role',
            LIST_HEADER: 'Roles',
            LIST_TITLE: 'List of roles for this account',
            LIST_DESCRIPTION: 'Roles define what types of actions a user can take in the workspace. A user can be assigned multiple roles, where each role adds additional permissions.',
            CLAIM_TYPE: 'Claim type',
            LEVEL: 'Level',
            APPLY_TO_USERS: 'Apply to users',
            DESCRIPTION: 'Description',
            DISPLAY_NAME: 'Display Name'
        }
    },
    'sv-SE': {
        role: {
            ADD_HEADER: 'New role',
            EDIT_HEADER: 'Edit role',
            LIST_HEADER: 'Roles',
            LIST_TITLE: 'List of roles for this account',
            LIST_DESCRIPTION: 'Roles define what types of actions a user can take in the workspace. A user can be assigned multiple roles, where each role adds additional permissions.',
            CLAIM_TYPE: 'Claim type',
            LEVEL: 'Level',
            APPLY_TO_USERS: 'Apply to users',
            DESCRIPTION: 'Description',
            DISPLAY_NAME: 'Display Name'
        }
    }
};
