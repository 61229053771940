import {Store} from '../stores/Store';
import immutable from 'immutable';

export default {
	getMySubscribedGroups: () => {
		const state = Store.getState();
		if(state.get('presence')==='online'){
			const groupIds = state.get('myGroupAvailability').filter(groupStatus => groupStatus === true).toJS();
			return Object.keys(groupIds);
		} else {
			return [];
		}
	},

	getPresence(){
		const state = Store.getState();
		return state.get('presence')+'';
	},

	getForcedAway: () => {
		const state = Store.getState();
		return state.get('presenceForcedAway');
	},

	isConversationClosed: (conversationId) => {
		const state = Store.getState();
		if (!state.hasIn(['conversations',conversationId,'events'])) {
			return true;
		}
		return state.getIn(['conversations',conversationId,'events']).some( msg => msg.get('type')=='conversationClosed');
	},

	getMySubscribedGroupsFull: () => {
		const state = Store.getState();
		const groupIds = state.get('myGroupAvailability').filter(groupStatus => groupStatus === true);
		const groups = state.get('groups');
		return groups.filter( (v,k)=>groupIds.has(k));
	},

	getConversationGroupId: (conversationId) => {
		const state = Store.getState();
		if (!state.hasIn(['conversations',conversationId,'events'])) {
			return '';
		}
		return state.getIn(['conversations',conversationId,'events']).find( msg => msg.get('type')=='associatedWithGroup').get('groupId');
	},

	isDesktopActive(){
		const state = Store.getState();
		return !!state.get('desktopActive');
	}

}
