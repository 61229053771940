export default function(filtrex, OpportunityFiltrexHelperService) {
    'ngInject';

    var service = {
        getFiltrexArgs: getFiltrexArgs,
        compileFiltrex: compileFiltrex,
        getFiltrexAndFuncsStr: getFiltrexAndFuncsStr
    };

    var helper = OpportunityFiltrexHelperService;

    // returns the arguments for the filtrex.compileExpression function
    function getFiltrexArgs(condition, rawObject) {
        var filtrexStrAndFuncsObj = helper.generateFiltrexForAllConditions(condition);
        if (!rawObject) {
            return [ filtrexStrAndFuncsObj.filtrexString, filtrexStrAndFuncsObj.additionalFunctions ];
        } else {
            return filtrexStrAndFuncsObj;
        }
    }

    function compileFiltrex (condition) {
        var compiledFiltrex = false;
        try {
            var filtrexArgs = getFiltrexArgs(condition);
            compiledFiltrex = filtrex.compileExpression.apply(this, filtrexArgs);
        } catch (error) {
            console.log(error);
        }
        return compiledFiltrex;
    }

    // returns a stringified version of the arguments to compile (Is this useful for something)
    function getFiltrexAndFuncsStr(condition) {
        var filtrexArgs = getFiltrexArgs(condition, true);
        var addFuncs = '';

        // make string out of additional functions
        if (Object.keys(filtrexArgs.additionalFunctions).length > 0) {
            addFuncs += '{';
            Object.keys(filtrexArgs.additionalFunctions).forEach(function(functionKey) {
                addFuncs += ' ' + functionKey + ': ' + filtrexArgs.additionalFunctions[functionKey].toString() + ',';
            });

            addFuncs = helper.textTrimming.removeLastChar(addFuncs);
            addFuncs = helper.textTrimming.removeNewlines(addFuncs);
            addFuncs = helper.textTrimming.removeMultipleWhitespaces(addFuncs);
        }

        /*eslint-disable */
        return {
            filtrexString: "'" + filtrexArgs.filtrexString + "'",
            functionsString: addFuncs
        };
        /*eslint-enable */
    }

    return service;
};
