export default function (d3, dc, DialogUsageDimensionService, $window, $translate) {
	'ngInject';

		return {
			restrict: 'A',
			require: '^ngModel',
			link: function ($scope, element, attrs) {

				var chart;

				$scope.$watch(attrs.ngModel, function (v) {
					if (!v) {
						return;
					}

					draw(v);

				});

				function draw(data) {

					var dimensions = DialogUsageDimensionService.calculate(data);

					if (!chart) {

						chart = bar(dc.barChart(element[0]), dimensions, data);
						chart.render();
						$window.addEventListener('resize', onResize, false);

					} else {

						chart.redraw();
					}
				}

				function onResize () {
					chart.width(element[0].clientWidth);
					chart.select('g.legend').attr('transform', 'translate(' + (element[0].clientWidth - 80) + ',3)');
					chart.rescale();
					chart.redraw();
				}

				function bar(barChart, dimension, data) {

					var domain = [
						d3.time.hour.offset(new Date(data[0].calculatedAt), -12),
						d3.time.hour.offset(new Date(data[data.length - 1].calculatedAt), 12)
					];

					var x = d3.time.scale()
						.domain(domain)
						.rangeRound([0, 300 - 40 - 10]);

					var width = element[0].clientWidth;

					barChart
						.width(width)
						.height(200)
						.margins({top: 10, right: 30, bottom: 30, left: 30})
						.dimension(dimension.by.when)
						.group(dimension.groupBy.numberOfDialogs)
						.gap(1)
						.x(x)
						.colors('#1fb35f')
						.elasticY(true)
						.centerBar(true)
						.round(d3.time.week.round)
						.xUnits(d3.time.days)
						.renderHorizontalGridLines(true)
						.xAxisLabel($translate.instant('dashboard.dialogUsage.DATE'))
						.brushOn(false)
						.title(function (d) {
							return d.value;
						});

					barChart.on('postRedraw', function () {

						barChart.svg()
							.attr('class', 'svg-content')
							.select('text.total-dialogusage')
							.text(function () {
								return dimension.groupBy.totalNumberOfDialogs.value();
							});
					});

					barChart.on('postRender', function () {

						var g = barChart.svg()
							.attr('class', 'svg-content')
							.append('g');

						g.attr('class', 'legend')
							.attr('transform', 'translate(' + (width - 90) + ',3)')
							.style('font-size', '12px')
							.style('pointer-events', 'none');

						g.append('rect').attr('height', 45)
							.attr('width', 78)
							.attr('fill', 'rgba(255,255,255,.5)')
							.attr({'stroke': '#e3e3e3', 'stroke-width': '1px', 'shape-rendering': 'crispEdges'})
							.attr('opacity', 1)
							.attr('class', 'legend-box');

						g.append('text')
							.attr('x', 10)
							.attr('y', 12)
							.attr('dy', '.35em')
							.style({'font-family': 'vngage-semibold', 'font-size': '10px'})
							.text(function () {
								return $translate.instant('dashboard.dialogUsage.TOTAL');
							});

						g.append('text')
							.attr('class', 'total-dialogusage')
							.attr('x', 10)
							.attr('y', 37)
							.style('font-size', '22px')
							.style('font-family', 'vngage-semibold')
							.text(function () {
								return dimension.groupBy.totalNumberOfDialogs.value();
							});
					});

					barChart.xAxis()
						.ticks(d3.time.days, 2)
						.tickFormat(d3.time.format('%_d'));

					barChart.yAxis()
						.tickFormat(d3.format('d'));

					return barChart;
				}
				$scope.$on('$destroy', function(){
					$window.removeEventListener('resize', onResize, false);
				});
			}
		};
	}
