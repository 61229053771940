export default {
	'defaultTranslation_en': {
		displayName: 'System default (English)',
		textDirection: 'LTR',
		translations: {
			LOADING_TEXT: '...wait!',
			TITLE: 'Make a reservation for a personal web meeting',
			INGRESS: '<p class="ingress">The meeting can take place through video on your computer or a simple phone call.</p><p>1. Choose a time you prefer from the calendar.<br/>2. Fill out your contact information and click "Make reservation"</p>',
			CHOOSE_GROUP: 'Subject',
			CAL_NEXT: '',
			CAL_TODAY: 'Today',
			CAL_PREVIOUS: '',
			CAL_FIRST_AVAILABLE: 'First available time',
			CAL_FOOTER_CONTENT: '',

			SUNDAY: 'Sunday',
			MONDAY: 'Monday',
			TUESDAY: 'Tuesday',
			WEDNESDAY: 'Wednesday',
			THURSDAY: 'Thursday',
			FRIDAY: 'Friday',
			SATURDAY: 'Saturday',

			JAN: 'January',
			FEB: 'February',
			MAR: 'March',
			APR: 'April',
			MAY: 'May',
			JUN: 'June',
			JUL: 'July',
			AUG: 'August',
			SEP: 'September',
			OCT: 'October',
			NOV: 'November',
			DEC: 'December',

			AVAILABLE: 'Available',
			ONE_LEFT: 'One left',
			UNAVAILABLE: 'Full',

			FIRST_AVAILABLE_TIME_TEXT: 'First available time',
			NO_FIRST_AVAILABLE_TIME: 'No available times',
			NO_AVAILABLE_THIS_WEEK: 'No available times this week',
			NO_AVAILABLE_THIS_DAY: 'Nothing available today',
			NO_AVAILABLE_THIS_PAST_DAY: '',
			ICAL_DOWNLOAD_TEXT: '',
			ICAL_DOWNLOAD_LINK_TEXT: 'Download iCalendar-file',
			ICAL_SUMMARY_TEXT: 'Booked web meeting',
			ICAL_DESCRIPTION_HEADER: '',
			ICAL_DESCRIPTION_JOIN_TEXT: 'Join web meeting:',
			ICAL_DESCRIPTION_CANCEL_TEXT: 'Cancel web meeting:',
			ICAL_DESCRIPTION_FOOTER: '',

			CHOSEN_RESERVATION_HEADER: 'Your contact information',
			CHOSEN_RESERVATION_TIME: 'You have selected',
			CHOSEN_RESERVATION_TIME2: 'at',
			CHOSEN_RESERVATION_TIME3: 'Fill in your first and last name, email address and phone number.',
			CHANGE_RESERVATION_TIME: 'Change selected time',
			SELECTED_TIME_BUSY: 'The time you selected is no longer available. Please select a new time from calendar.',

			CONFIRMATION_TITLE: 'Thank you! We have received your reservation.<span class="ok-tic"></span>',
			CONFIRMATION_INGRESS: 'You will receive an email with information and a link to the meeting.',
			CONFIRMATION_INGRESS_NO_EMAIL: 'Please note the date and time for the meeting. You will be called when the meeting starts.',
			CLOSE_BUTTON: 'Back',
			SUBMIT_BUTTON: 'Make reservation',
			CANCEL_BUTTON: 'Cancel',
			LABEL_TIME: 'Time',
			LABEL_DATE: 'Date',

			FORM_LABEL_FIRST_NAME: 'First name',
			FORM_VALIDATION_FIRST_NAME: 'You must fill out your first name. Please use letters only.',
			FORM_LABEL_LAST_NAME: 'Last name',
			FORM_VALIDATION_LAST_NAME: 'You must fill out your last name. Please use letters only.',
			FORM_LABEL_EMAIL: 'E-mail',
			FORM_VALIDATION_EMAIL: 'E-mail address contains errors. Please try again',
			FORM_LABEL_PHONE: 'Phone number',
			FORM_VALIDATION_PHONE: 'Phone number contains errors. Please try again.',
			FORM_LABEL_GROUP_ID: 'Group id',
			FORM_VALIDATION_GROUP_ID: '*You need to select a group in order to make a reservation',
			FORM_LABEL_GROUP_NAME: 'Group',
			FORM_LABEL_MEETING_START: 'Meeting start',
			FORM_VALIDATION_MEETING_START: '*You need to select a valid time for your meeting',

			// CANCEL VIEW
			CANCEL_TITLE: 'Cancel your web meeting',
			CANCEL_TITLE_CANCELLED: 'Your web meeting is cancelled!',
			CANCEL_TITLE_ERROR: 'Your web meeting is already cancelled',
			CANCEL_INGRESS: '<p>You can cancel you web meeting here.</p><p>If you wan\'t to change the appointment, cancel this one and then make a new reservation.</p>',
			CANCEL_RESERVE_NEW: 'Make reservation',
			CANCEL_CONFIRMATION_MESSAGE: 'If you want, you can make a new reservation.',
			CANCEL_ERROR_MESSAGE: '',
			CANCEL_ALREADY_CANCELLED: 'This meeting is already cancelled',

			CANCEL_CANCELLATION_FORM_HEADING: '',
			CANCEL_CANCELLATION_FORM_INGRESS: '',
			CANCEL_FORM_RESERVATION_ID_LABEL: 'Meeting id',
			CANCEL_FORM_VALIDATION_RESERVATION_ID: 'You need to provide a correct meeting id',
			CANCEL_FORM_SUBMIT_BUTTON: 'Cancel reservation',

			// ENTER VIEW
			ENTER_TITLE: 'Welcome to your web meeting',
			ENTER_INGRESS: 'Your meeting is about to start',
			ENTER_CHECKING: 'Please wait. We are preparing to start the meeting.',
			ENTER_TO_EARLY_TITLE: 'Welcome to your web meeting',
			ENTER_TO_EARLY: '<p class="ingress">You are a little early.</p><p>You can find the time for when the meeting starts in the confirmation email.</p><p>Reload this page a few minutes before the meeting starts to be able to enter the queue. You can reload the page by pressing F5 on you keyboard.</p>',
			ENTER_TO_LATE_TITLE: 'Welcome to your web meeting!',
			ENTER_TO_LATE: '<p class="ingress">Unfortunately, the start time of your web meeting expired and your meeting has been cancelled.</p><p>You are welcome to schedule a new meeting time.</p>',
			ENTER_WAITING: '<p class="ingress">You are a little early.</p>Your meeting starts in ',
			ENTER_STARTING: '',
			ENTER_CANCELLED: 'Meeting is cancelled',
			ENTER_START: 'Start meeting',
			ENTER_RESERVE_NEW: 'Make reservation',
			ENTER_MINUTE: 'minute',
			ENTER_MINUTES: 'minutes',
			ENTER_HOUR: 'hour',
			ENTER_HOURS: 'hours',
			ENTER_DAY: 'day',
			ENTER_DAYS: 'days',
			ENTER_AND: 'and',
			ENTER_JOIN_TITLE_PHONE: 'Phone',
			ENTER_JOIN_TITLE_EMAIL: 'Email',

			ENTER_CANCELLATION_FORM_HEADING: '',
			ENTER_CANCELLATION_FORM_INGRESS: '',
			ENTER_FORM_RESERVATION_ID_LABEL: 'Meeting id',
			ENTER_FORM_VALIDATION_RESERVATION_ID: '*You need to provide a correct meeting id',
			ENTER_FORM_SUBMIT_BUTTON: 'Enter meeting',

			WCAG_SELECTED_WEEK_LABEL: 'Selected week',
			WCAG_TO: 'to',
			WCAG_LABEL_PREV_WEEK: 'Go one week backward',
			WCAG_LABEL_TODAY: 'Go to current week',
			WCAG_LABEL_NEXT_WEEK: 'Go one week forward',
			WCAG_LABEL_SELECT: 'Select subject',
			WCAG_LABEL_FIRST_AVAILABLE: 'Find first available time',
			WCAG_AVAILABLE_SLOT: 'Available slot',
			WCAG_UNAVAILABLE_SLOT: 'Unavailable slot',
			WCAG_YOUR_ARE_EARLY: 'You are a little early',
			WCAG_MEETING_STARTS_IN: 'Your meeting starts in',
		},
		custom: {},
		errorTranslations: {
			'0': {
				description: 'UnknownError',
				translation: 'An unknown server error has occurred.',
			},
			'19': {
				description: 'TimeSlotNotAvailable',
				translation: 'The time you selected is no longer available. Please select a another time.'
			},
			'20': {
				description: 'OutsideBookableHours',
				translation: 'The meeting time you tried to reserve is outside our opening hours. Please select another time.'
			},
			'21': {
				description: 'RequiredFieldMissing',
				translation: 'One or more required fields was missing. Please fill all fields before making a reservation.'
			},
			'22': {
				description: 'LimitReached',
				translation: 'You have already made a reservation this day. Please select another day or cancel your current reservation.'
			}

		}
	}
};
