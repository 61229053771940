import React, {useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import AccountConfig from '../../utils/AccountConfig';
import {useGetTranslation} from './../LangContext';
import ScrollText from './../common/ScrollText.react';

const TransferMenu = (props) => {
	const getTranslation = useGetTranslation();
	const [groupIds, setGroupIds] = useState([...AccountConfig.getGroupIds()].sort((a, b) => {
		const name1 = props.groups.getIn([a, 'name']);
		const name2 = props.groups.getIn([b, 'name']);
		if (name1 < name2) {
			return -1;
		} else if (name1 > name2) {
			return 1;
		} else {
			return 0;
		}
	}));

	const onSelectTransfer = (eventKey) => {
		props.transferToGroup(props.conversationId, eventKey);
	};

	const groupItems = groupIds.map((groupId, i) => {
		const disabled = props.groups.getIn([groupId, 'numberOfOnlineUsers']) === 0;
		const name = props.groups.getIn([groupId, 'name']) || 'no name';
		return (
			<Dropdown.Item key={i} className="transfer-group" disabled={disabled} eventKey={groupId}>
				<ScrollText text={name} cls="scroll-wrapper line-clamp line-clamp-1"/>
			</Dropdown.Item>
		);
	});

	const backToQueue = <Dropdown.Item className="transfer-group" key={props.groupId + 1}
									  disabled={props.groups.getIn([props.groupId, 'numberOfOnlineUsers']) === 0}
									  eventKey={props.groupId}>
		{getTranslation('actionTransferToQueue')}
	</Dropdown.Item>;

	return (
		<Dropdown onSelect={onSelectTransfer} alignRight className="transfer-dropdown">
			<Dropdown.Toggle id="dropdown-transfer-id" className="transfer-dropdown-button" variant="outline-secondary">
				{getTranslation('actionTransfer')}
			</Dropdown.Toggle>
			<Dropdown.Menu className="transfer-menu">
				{backToQueue}
				<Dropdown.Divider/>
				{groupItems}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default TransferMenu;

