import immutable from 'immutable';

export function setDesktopStatus(state, action) {
	state = state.set('desktopStatus', action.status);
	return state;
}

export function setInContactData(state, action) {
	state = state.update('inContactData', prev => prev.merge(immutable.fromJS(action.data)));
	return state;

}


