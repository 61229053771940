export default function(filterXSS) {
	'ngInject';

	var htmlValidator = new filterXSS.FilterXSS();

	return {
		require : 'ngModel',
		link: function (scope, element, attrs, ngModel) {
			ngModel.$validators.validateHtmlDirective = function (modelValue, viewValue) {
				var value = modelValue || viewValue || '',
					result = htmlValidator.validate(value);

				return !result.errors;
			};
		}
	};
}
