export const fileImportHeaders = [
	{name: 'profile.displayName', required: true},
	{name: 'profile.publicDisplayName', required: true},
	{
		name: 'profile.preferredEmail',
		required: true,
		format: 'email',
		unique: true,
	},
	{
		name: 'profile.language',
		format: 'enum',
		default: '', // needs to be filled
		values: []// needs to be filled
	},
	{
		name: 'profile.timeZoneId',
		format: 'enum',
		default: '', // needs to be filled
		values: [] // needs to be filled
	},
	{
		name: 'profile.profilePicture',
		required: false,
		format: 'https'
	},
	{
		name: 'roleClaims',
		required: false,
		format: 'list',
		prefix: 'http://schemas.vergic.com/api/identity/claims/role#',
		values: []// needs to be filled
	},
	{
		name: 'status',
		format: 'enum',
		default: 'Active',
		values: ['Active', 'Suspended', 'Blocked'],
		valueMap: {
			'Active': 0,
			'Suspended': 1,
			'Blocked': 2,
		}
	},
	{
		name: 'groupIds',
		required: false,
		format: 'list',
		values: []// needs to be filled
	}
];
