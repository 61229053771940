import React from 'react';
import createReactClass from 'create-react-class';
import EventStore from './../stores/EventStore';
import ConversationsStore from './../stores/ConversationsStore';
import MessageList from './MessageList.react';
import ReservationInfo from './ReservationInfo.react';
//import _debounce from 'lodash/debounce';

const resizeDelay = 500;

function getStateFromStores(conversationId) {
	return {
		conversationChatMessages : EventStore.getMappedMessagesOfConversation(conversationId),
		conversationReservation : EventStore.getReservationOfConversation(conversationId),
		isIdle: ConversationsStore.isConversationIdle(conversationId)
	};
}

export default createReactClass({

	getInitialState () {
		return getStateFromStores(this.props.conversationId);
	},

	componentDidMount () {
		this.mounted = true;
		EventStore.addChangeListener(this._onChange);
	},

	componentWillUnmount () {
		this.mounted = false;
		EventStore.removeChangeListener(this._onChange);
		//this.props.viewArea = null;
	},

	shouldComponentUpdate (nextProps, nextState) {
		return nextState.isIdle !== this.state.isIdle ||
			nextState.conversationChatMessages.length !== this.state.conversationChatMessages.length ||
			nextState.conversationReservation !== this.state.conversationReservation ||
			this.didUpdateMessages(nextState.conversationChatMessages, this.state.conversationChatMessages);
	},

	// checks if order of messages changed
	didUpdateMessages (prevMessages, messages) {
		return prevMessages.filter( (msg, i) => {
			return msg.id !== messages[i].id;
		}).length > 0;
	},

	render () {
		if (this.props.viewArea){
			 //hack to re-render ExpandableViewArea upon render of messages of conversation
			setTimeout( () => {
				if (this.mounted) {
					this.props.viewArea.render('nowhere');
				}
			}, 100);
		}

		return (
			<div>
				{ this.state.conversationReservation.reservation ? <ReservationInfo reservation={this.state.conversationReservation.reservation} groupList={this.props.groupList} /> : null }
				<MessageList ref="messagelist"
					isIdle={this.state.isIdle}
					messages={this.state.conversationChatMessages}
					/>
			</div>
		)
	},

	_onChange () {
		this.setState(getStateFromStores(this.props.conversationId));
	}
});
