import {
	setDebugFlag,
	setAccountId,
	setUserId,
	setVisitorSettings,
	setAccount,
	cleanUpBeforeLeaving,
	receiveAsState,
	receiveSince,
	updateCaseType,
	closeCaseAndConversation,
	addHistoryMarkers,
	expandHistoryMarker,
	addLocalMessage,
	setCurrentMessage,
	setCurrentMessageAndShow,
	setCurrentNote,
	changeCase,
	participantTyping,
	indicateMessageCopied,
	toggleNewCaseAfterClose,
	toggleEmailConversationTranscript,
	setActiveSection,
	removeActiveSection,
	setPendingRemoval,
	setSelectedClosure,
	setForms,
	processConversationDetails,
	textSize,
	toggleSystemMessages,
	toggleActiveInfoTip,
	hideInfoTip,
	toggleNoteMode,
	setCurrentInput,
	updateCobroUrl,
	setCobroHighlightMode,
	toggleCobroScale,
	toggleCobroPause,
	setCobroDomuploadStatus,
	setFlowState,
	startConversationFromQueue,
	initRoutedConversation,
	startRoutedConversation,
	initAndStartRoutedConversation,
	resumeConversation,
	conversationReady,
	removeConversation,
	setConversationCapabilities,
	updateLocalMessageStatus,
	setConversationError,
} from './stateModifiers/conversation';

import AppConstants from '../../../components/constants/AppConstants';

const ActionTypes = AppConstants.ActionTypes;
const Ui = AppConstants.Ui;


export default function ConversationReducer(state, action) {
	switch (action.type) {
		case ActionTypes.SET_DEBUG_FLAG :
			return setDebugFlag(state, action);

		case ActionTypes.SET_ACCOUNT_ID :
			return setAccountId(state, action);

		case ActionTypes.SET_USER_ID :
			return setUserId(state, action);

		case ActionTypes.SET_VISITOR_SETTINGS :
			return setVisitorSettings(state, action);

		case ActionTypes.SET_ACCOUNT :
			return setAccount(state, action);

		case ActionTypes.CLEAN_UP_BEFORE_LEAVING:
			return cleanUpBeforeLeaving(state, action);

		case ActionTypes.RECEIVE_AS_STATE:
			return receiveAsState(state, action);

		case ActionTypes.RECEIVE_SINCE :
			return receiveSince(state, action);

		case ActionTypes.UPDATE_CASE_TYPE:
			return updateCaseType(state, action);

		case ActionTypes.CLOSE_CASE_AND_CONVERSATION:
			return closeCaseAndConversation(state, action);

		case ActionTypes.ADD_HISTORY_MARKERS:
			return addHistoryMarkers(state, action);

		case ActionTypes.EXPAND_HISTORY_MARKER:
			return expandHistoryMarker(state, action);

		case ActionTypes.ADD_LOCAL_MESSAGE :
			return addLocalMessage(state, action);

		case Ui.SET_CURRENT_MESSAGE :
			return setCurrentMessage(state, action);

		case Ui.SET_CURRENT_MESSAGE_AND_SHOW :
			return setCurrentMessageAndShow(state, action);

		case Ui.SET_CURRENT_NOTE :
			return setCurrentNote(state, action);

		case ActionTypes.CHANGE_CASE :
			return changeCase(state, action);

		case ActionTypes.PARTICIPANT_TYPING :
			return participantTyping(state, action);

		case Ui.INDICATE_MESSAGE_COPIED:
			return indicateMessageCopied(state, action);

		case Ui.TOGGLE_NEW_CASE_AFTER_CLOSE:
			return toggleNewCaseAfterClose(state, action);

		case Ui.TOGGLE_EMAIL_CONVERSATION_TRANSCRIPT:
			return toggleEmailConversationTranscript(state, action);

		case Ui.SET_ACTIVE_SECTION:
			return setActiveSection(state, action);

		case Ui.REMOVE_ACTIVE_SECTION:
			return removeActiveSection(state, action);

		case Ui.SET_PENDING_REMOVAL:
			return setPendingRemoval(state, action);

		case Ui.SET_SELECTED_CLOSURE:
			return setSelectedClosure(state, action);

		case ActionTypes.SET_FORMS:
			return setForms(state, action);

		case ActionTypes.PROCESS_CONVERSATION_DETAILS:
			return processConversationDetails(state, action);

		case Ui.TEXT_SIZE:
			return textSize(state, action);

		case Ui.TOGGLE_SYSTEM_MESSAGES:
			return toggleSystemMessages(state, action);

		case Ui.TOGGLE_ACTIVE_INFO_TIP:
			return toggleActiveInfoTip(state, action);

		case Ui.HIDE_INFO_TIP:
			return hideInfoTip(state, action);

		case Ui.TOGGLE_NOTE_MODE:
			return toggleNoteMode(state, action);

		case Ui.SET_CURRENT_INPUT:
			return setCurrentInput(state, action);

		case ActionTypes.UPDATE_COBRO_URL:
			return updateCobroUrl(state, action);

		case ActionTypes.SET_COBRO_HIGHLIGHT_MODE:
			return setCobroHighlightMode(state, action);

		case ActionTypes.TOGGLE_COBRO_SCALE:
			return toggleCobroScale(state, action);

		case ActionTypes.TOGGLE_COBRO_PAUSE:
			return toggleCobroPause(state, action);

		case ActionTypes.SET_COBRO_DOMUPLOAD_STATUS:
			return setCobroDomuploadStatus(state, action);

		case ActionTypes.SET_FLOW_STATE:
			return setFlowState(state, action);

		case ActionTypes.START_CONVERSATION_FROM_QUEUE:
			return startConversationFromQueue(state, action);

		case ActionTypes.INIT_ROUTED_CONVERSATION:
			return initRoutedConversation(state, action);

		case ActionTypes.START_ROUTED_CONVERSATION:
			return startRoutedConversation(state, action);

		case ActionTypes.INIT_AND_START_ROUTED_CONVERSATION:
			return initAndStartRoutedConversation(state, action);

		case ActionTypes.RESUME_CONVERSATION:
			return resumeConversation(state, action);

		case ActionTypes.CONVERSATION_READY:
			return conversationReady(state, action);

		case ActionTypes.REMOVE_CONVERSATION:
			return removeConversation(state, action);

		case ActionTypes.SET_CONVERSATION_CAPABILITIES:
			return setConversationCapabilities(state, action);

		case ActionTypes.UPDATE_LOCAL_MESSAGE_STATUS:
			return updateLocalMessageStatus(state, action);

		case ActionTypes.SET_CONVERSATION_ERROR:
			return setConversationError(state, action);

		default:
			return state;
	}
}

