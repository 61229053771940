import immutable from 'immutable';
import {sharedReducerLib} from '../libs/sharedReducerLib';

export function visitorProfileAdd(state, action) {
	state = sharedReducerLib.setVisitorProfile(state, action.profile);
	return state;
}

export function setVisitorProfileNavigation(state, action) {
	state = sharedReducerLib.setVisitorProfileNavigation(state, action.visitId, action.navigation);
	return state;
}

export function setClaimsPatch(state, action) {
	state = state.setIn(['visitorProfiles', action.visitId, 'claimsPatch'], immutable.fromJS(action.fields));
	return state;
}