let langData = null;
let mockedLangData = null;

export default {
	set(data) {
		langData = data;
	},

	set mock(mock) {
		mockedLangData = mock;
	},

	get mock() {
		return mockedLangData;
	},

	get messages() {
		return langData.messages;
	},

	get locale() {
		return langData.locale;
	},

	getTranslation(id) {
		if (mockedLangData) {
			return mockedLangData.messages[id];
		} else {
			// if path is incorrect, do a console.log
			if (!langData) {
				console.error('Missing langData', id);
				return id;
			} else if (!langData.messages) {
				console.error('Missing langData.messages', langData.locales, langData.locale, id);
				return id;
			} else if (!langData.messages[id]) {
				console.error('Missing langData.messages[id]', langData.locales, langData.locale, id);
				return id;
			}
			return langData.messages[id];
		}
		// OWO-16973 CSP-29049
		// revert to previous code when issue is solved:
		// return !!mockedLangData
		// 	? mockedLangData.messages[id]
		// 	: langData.messages[id];
	},

	getMockedTranslation(id) {
		return mockedLangData.messages[id];
	},

};
