export default function () {
	'ngInject';

	const timeUnitsInSeconds = {
		seconds: 1,
		minutes: 60,
		hours: 60 * 60,
		days: 24 * 60 * 60
	};
	const secondsOnADay = 24 * 60 * 60;

	return {
		formatForSelectableList: (resources) => {
			var formattedList = [];
			resources.forEach(function (resource) {
				formattedList.push({displayName: resource.data.name, refValue: resource.data.id});
			});
			return formattedList;
		},
		timeSpanToTimeUnits: (timeSpanStr) => {
			// This function converts a .NET TimeSpan string to time units according to spec:
			// https://msdn.microsoft.com/en-us/library/se73z7b9(v=vs.110).aspx
			// Input format is: [ws][-]{ d | [d.]hh:mm[:ss[.ff]] }[ws]
			//
			// Special features of this function (deviations from spec):
			// Fractions seconds (ff) are *removed* by this function
			// Negative TimeSpans are not supported (converted to positive)
			// "Overflow" values in sections *are* permitted by this function, i.e. 70 seconds will be interpreted/converted to: +1 min, 10 sec
			//
			// Examples:
			// 0.23:59:59		= 0.23:59:59
			// 0.00:15:00		= 0.00:15:00
			// 13:10			= 0.13:10:00
			// 0.03:27:00.13	= 0.03:27:00
			// 0.00:00:10		= 0.00:00:10
			// 5.12:34			= 5.12:34:00
			// 0.12:95:10		= 0.13:35:10
			// -5.12:34			= 5.12:34:00
			// 8				= 8.00:00:00
			//
			// Output format is an object: {amount: X, unit: Y}
			// Where Y is a number, and Y is a string: 'days', 'hours', 'minutes', 'seconds'
			// The result is converted to the largest unit, preserving full precision
			// Examples:
			// 1.00:00:00 = {amount: 1, unit: 'days'}
			// 1.01:00:00 = {amount: 25, unit: 'hours'}
			// 0.24:00:00 = {amount: 1, unit: 'days'}

			let timeString = '',
				days       = 0,
				hours      = 0,
				minutes    = 0,
				seconds    = 0;

			// Remove whitespace
			timeSpanStr = timeSpanStr.trim();

			// Remove negative TimeSpans (remove leading '-')
			if (timeSpanStr.indexOf('-') === 0) {
				timeSpanStr = timeSpanStr.substring(1);
			}

			// Parse days and fractions (split by dots)
			const dotSplit = timeSpanStr.split('.');
			if (dotSplit.length > 3) {
				// Format error - too many dots
				return null;
			} else if (dotSplit.length === 3) {
				// Two dots found => We have both days and fractional seconds, keep days but throw away fractions
				days = parseInt(dotSplit[0], 10);
				timeString = dotSplit[1];
				if (timeString.indexOf(':') === -1) {
					// Format error - no ':' in timeString
					return null;
				}
			} else if (dotSplit.length === 2) {
				if (dotSplit[0].indexOf(':') !== -1) {
					// ':' in first dotSplit => We have fractions but no days, throw them away
					timeString = dotSplit[0];
				} else if (dotSplit[1].indexOf(':') !== -1) {
					// ':' in second dotSplit => We have days but no fractions
					days = parseInt(dotSplit[0], 10);
					timeString = dotSplit[1];
				} else {
					// No ':' in any part => Error!
					return null;
				}
			} else {
				// No dots => We only have a time string
				timeString = dotSplit[0];
			}

			// Parse time string (split by colons)
			const colonSplit = timeString.split(':');
			if (colonSplit.length > 3) {
				// [d.]hh:mm:ss:xx
				// timeString has too many parts => Error!
				return null;
			} else if (colonSplit.length === 3) {
				// [d.]hh:mm:ss
				// timeString has exactly three parts
				hours = parseInt(colonSplit[0], 10);
				minutes = parseInt(colonSplit[1], 10);
				seconds = parseInt(colonSplit[2], 10);
			} else {
				// timeString has one or two parts
				if (colonSplit.length === 2) {
					// [d.]hh.mm
					hours = parseInt(colonSplit[0], 10);
					minutes = parseInt(colonSplit[1], 10);
				} else {
					// TimeString is a single number
					if (dotSplit.length > 1) {
						// d.xx => Error!
						// But we also have dots!
						return null;
					} else {
						// d
						// Single number and no dots: Should be interpreted as days according to spec.
						days = parseInt(colonSplit[0], 10);
					}
				}
			}

			// Handle overflows...
			if (seconds >= 60) {
				minutes += Math.floor(seconds / 60);
				seconds = seconds % 60;
			}
			if (minutes >= 60) {
				hours += Math.floor(minutes / 60);
				minutes = minutes % 60;
			}
			if (hours >= 24) {
				days += Math.floor(hours / 24);
				hours = hours % 24;
			}

			// Select the largest possible unit and return result
			if (seconds > 0) {
				// Seconds exists: We have to output in unit 'seconds' to preserve precision
				return {
					amount: (days * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60 + seconds),
					unit: 'seconds'
				}
			} else if (minutes > 0) {
				// No seconds, but minutes exists: Output in unit 'minutes'
				return {
					amount: (days * 60 * 24 + hours * 60 + minutes),
					unit: 'minutes'
				}
			} else if (hours > 0) {
				// No seconds, no minutes, but hours exists: Output in 'hours'
				return {
					amount: (days * 24 + hours),
					unit: 'hours'
				}
			} else if (days > 0) {
				// Only days exists: We can output in 'days' with full precision
				return {
					amount: days,
					unit: 'days'
				}
			} else {
				// Zero time: Display as 0 days
				return {
					amount: 0,
					unit: 'days'
				}
			}
		},
		secondsToTimeSpan: (seconds) => {
			return Math.floor(seconds / secondsOnADay) + '.' + (new Date(seconds % secondsOnADay * 1000)).toUTCString().replace(/.*(\d{2}):(\d{2}):(\d{2}).*/, '$1:$2:$3');
		},
		timeUnitsToSeconds: (amount, unit) => {
			return timeUnitsInSeconds[unit] * amount;
		}
	}
};
