// Removes entities and cleans up dangling references for that entity
export default function($q, $translate, APIConfigurationSectionService, VngageUtil, APIEndpointService, OpportunityGeneratorService) {
	'ngInject';

    var service = {
        remove: remove,
        getModalTexts: getModalTexts
    };

    function getModalTexts(itemToRemove, itemType) {
        return remove(itemToRemove, itemType, true).then(function(res) {
            return getTranslation({
                itemToRemove: itemToRemove,
                itemType: itemType,
                itemsToSave: res.itemsToSave
            });
        });

        function getTranslation(params) {
            var translationPromises = [];

            translationPromises.push();

            var headerTextPromise = $translate('set.' + params.itemType).then(function(itemType) {
                var itemToRemoveName;
                switch (params.itemType) {
                    case 'user':
                        itemToRemoveName = params.itemToRemove.data.profile.displayName;
                        break;
                    case 'role':
                        itemToRemoveName = params.itemToRemove.data.displayName;
                        break;
                    case 'systemSetting':
                        itemToRemoveName = params.itemToRemove.data.key;
                        break;
                    default:
                        itemToRemoveName = params.itemToRemove.data.name;
                        break;
                }
                return $translate('itemRemoval.HEADER', {
                    itemName: itemToRemoveName,
                    itemType: itemType.toLowerCase()
                });
            });
            translationPromises.push(headerTextPromise);

            var refsFlag = false;
            if (params.itemsToSave) {
                params.itemsToSave.forEach(function(itemTypeToSave) {
                    if (itemTypeToSave.resources.length > 0) {
                        if (!refsFlag) {
                            refsFlag = true;
                            var itemTypeToRemovePromise = $translate('set.' + params.itemType).then(function(itemType) {
                                return $translate('itemRemoval.REFERENCES_EXIST_MSG', { itemType: itemType.toLowerCase() });
                            });
                            translationPromises.push(itemTypeToRemovePromise);
                        }

                        var itemTypeWithRefPromise = $translate('set.' + itemTypeToSave.type + 'List').then(function(itemTypePlural) {
                            return $translate('itemRemoval.REFERENCE_ITEMS_MSG', { itemTypeWithRef: itemTypePlural.toLowerCase() });
                        });

                        translationPromises.push(itemTypeWithRefPromise);

                        var dfd = $q.defer();
                        translationPromises.push(dfd.promise);
                        var itemNames = '';
                        itemTypeToSave.resources.forEach(function(item) {
                            if (itemTypeToSave.type === 'user') {
                                itemNames += '"' + item.data.profile.displayName + '", ';
                            } else {
                                itemNames += '"' + item.data.name + '", ';
                            }
                        });
                        itemNames = itemNames.slice(0, -2);
                        dfd.resolve(itemNames);
                    }
                });
            }

            return $q.all(translationPromises);
        }
    }

    var APICSS = APIConfigurationSectionService;

    function queryUser() {
        return APIEndpointService.user.query({limit: 10000}).$promise;
    }

    function queryGroup() {
        return APIEndpointService.group.query().$promise;
    }

    function performSavesAndRemove(params) {
        var savePromises = [];
        if (params.itemsToSave) {
            params.itemsToSave.forEach(function(itemType) {
                itemType.resources.forEach(function(itemToSave) {
                    savePromises.push(itemToSave.$save());
                });
            });
        }

        savePromises.push(params.itemToRemove.$remove());
        return $q.all(savePromises);
    }

    // Remove references and the entity
    function remove(itemToRemove, itemType, dryRun) {

        var dfdGetResources = $q.defer();

        // fetch entities to remove references from
        switch (itemType) {

            // ACTION
            // ------
            case APICSS.sectionMap.action:
                removeListRefs(itemToRemove, APICSS.get.bind({}, APICSS.sectionMap.actionPanel), 'data.section.actions')
                    .then(function(itemsToSave) {
                        dfdGetResources.resolve({
                            itemsToSave: [{
                                type: APICSS.sectionMap.actionPanel,
                                resources: itemsToSave
                            }],
                            itemToRemove: itemToRemove
                        });
                    });
                break;

            // ACTION PANEL
            // ------------
            case APICSS.sectionMap.actionPanel:
                $q.all([
                    removeListRefs(itemToRemove, APICSS.get.bind({}, APICSS.sectionMap.customerCase), 'data.section.actionPanels'),
                    removeListRefs(itemToRemove, queryGroup, 'data.actionPanels')
                ]).then(function(res) {
                    dfdGetResources.resolve({
                        itemsToSave: [
                            {
                                type: APICSS.sectionMap.customerCase,
                                resources: res[0]
                            },
                            {
                                type: 'group',
                                resources: res[1]
                            }
                        ],
                        itemToRemove: itemToRemove
                    });
                });
                break;

            // CANNED RESPONSE
            // ---------------
            case APICSS.sectionMap.cannedResponse:
                removeListRefs(itemToRemove, APICSS.get.bind({}, APICSS.sectionMap.customerCase), 'data.section.cannedResponses')
                    .then(function(itemsToSave) {
                        dfdGetResources.resolve({
                            itemsToSave: [{
                                type: APICSS.sectionMap.customerCase,
                                resources: itemsToSave
                            }],
                            itemToRemove: itemToRemove
                        });
                    });
                break;

            // CLOSURE
            // -------
            case APICSS.sectionMap.closure:
                removeListRefs(itemToRemove, APICSS.get.bind({}, APICSS.sectionMap.customerCase), 'data.section.closures')
                    .then(function(itemsToSave) {
                        dfdGetResources.resolve({
                            itemsToSave: [{
                                type: APICSS.sectionMap.customerCase,
                                resources: itemsToSave
                            }],
                            itemToRemove: itemToRemove
                        });
                    });
                break;

            // FORM
            // ----
            case APICSS.sectionMap.form:
                removeListRefs(itemToRemove, APICSS.get.bind({}, APICSS.sectionMap.customerCase), 'data.section.forms')
                    .then(function(itemsToSave) {
                        dfdGetResources.resolve({
                            itemsToSave: [{
                                type: APICSS.sectionMap.customerCase,
                                resources: itemsToSave
                            }],
                            itemToRemove: itemToRemove
                        });
                    });
                break;

            // CUSTOMER CASE
            // -------------
            case APICSS.sectionMap.customerCase:
                $q.all([
                    removePropertyRefs(itemToRemove, APICSS.get.bind({}, APICSS.sectionMap.solution) , 'data.section', 'caseTypeId'),
                    removeListRefs(itemToRemove, queryGroup, 'data.caseTypes')
                ]).then(function(res) {
                    dfdGetResources.resolve({
                        itemsToSave: [
                            {
                                type: APICSS.sectionMap.solution,
                                resources: res[0]
                            },
                            {
                                type: 'group',
                                resources: res[1]
                            }
                        ],
                        itemToRemove: itemToRemove
                    });
                });
                break;

            // GROUP
            // -----
            case 'group':
                $q.all([
                    removeListRefs(itemToRemove, queryUser, 'data.groupIds'),
                    removePropertyRefs(itemToRemove, APICSS.get.bind({}, APICSS.sectionMap.solution), 'data.section', 'groupId')
                ]).then(function(res) {
                    dfdGetResources.resolve({
                        itemsToSave: [
                            {
                                type: 'user',
                                resources: res[0]
                            },
                            {
                                type: APICSS.sectionMap.solution,
                                resources: res[1]
                            }
                        ],
                        itemToRemove: itemToRemove
                    });
                });
                break;

            // SOLUTION
            // --------
            case APICSS.sectionMap.solution:
                removeListRefs(itemToRemove, APICSS.get.bind({}, APICSS.sectionMap.opportunity), 'data.section.solutions')
                    .then(function(itemsToSave) {
                        dfdGetResources.resolve({
                            itemsToSave: [{
                                type: APICSS.sectionMap.opportunity,
                                resources: itemsToSave
                            }],
                            itemToRemove: itemToRemove
                        });
                    });
                break;

            // BANNER
            // ------
            case APICSS.sectionMap.banner:
                var bannerRefs = ['bannerId', 'bannerIdGroupBusy', 'bannerIdGroupClosed', 'bannerIdGroupOpen'];
                removePropertyRefs(itemToRemove, APICSS.get.bind({}, APICSS.sectionMap.solution), 'data.section', bannerRefs)
                    .then(function(itemsToSave) {
                        dfdGetResources.resolve({
                            itemsToSave: [{
                                type: APICSS.sectionMap.solution,
                                resources: itemsToSave
                            }],
                            itemToRemove: itemToRemove
                        });
                    });
                break;

            // POINT OF INTEREST
            // -----------------
            case 'pointOfInterest':
                removePoiFromOpportunity(itemToRemove)
                    .then(function(itemsToSave) {
                        dfdGetResources.resolve({
                            itemsToSave: [{
                                type: APICSS.sectionMap.opportunity,
                                resources: itemsToSave
                            }],
                            itemToRemove: itemToRemove
                        });
                    });
                break;

            // RENDERTEMPLATE
            // ------
            case APICSS.sectionMap.renderTemplate:
                removePropertyRefs(itemToRemove, APICSS.get.bind({}, APICSS.sectionMap.action), 'data.section', 'parameter')
                    .then(function(itemsToSave) {
                        dfdGetResources.resolve({
                            itemsToSave: [{
                                type: APICSS.sectionMap.action,
                                resources: itemsToSave
                            }],
                            itemToRemove: itemToRemove
                        });
                    });
                break;

            // ITEMS WITHOUT NEED FOR CLEANUP
            // ------------------------------
            default:
                dfdGetResources.resolve({
                    itemToRemove: itemToRemove
                });
                break;
        }

        if (dryRun) {
            return dfdGetResources.promise;
        } else {
            return $q(function(resolve) {
                dfdGetResources.promise.then(function(res) {
                    performSavesAndRemove(res).then(resolve);
                });
            });
        }
    }

    // removes references in lists (does not automatically save)
    function removeListRefs(itemToRemove, getListFunction, refListAccessor) {
        return $q(function(resolve) {
            getListFunction()
                .then(function(itemListWithRefs) {
                    var itemsWithRefsToSave = [];
                    itemListWithRefs.forEach(function(itemWithRefs) {
                        var listWithRefs = VngageUtil.valByStr(itemWithRefs, refListAccessor);
                        if (listWithRefs) {
                            var idIndex = listWithRefs.indexOf(itemToRemove.data.id);
                            if (idIndex !== -1) {
                                listWithRefs.splice(idIndex, 1);
                                itemsWithRefsToSave.push(itemWithRefs);
                            }
                        }
                    });
                    resolve(itemsWithRefsToSave);
                });
        });
    }

    // removes references in object properties (does not automatically save)
    function removePropertyRefs(itemToRemove, getListFunction, objWithRefAccessor, propertyAccessor) {
        return $q(function(resolve) {
            getListFunction()
                .then(function(itemListWithRefs) {
                    var itemsWithRefsToSave = [];
                    itemListWithRefs.forEach(function(itemWithRefs) {
                        var objWithRef = VngageUtil.valByStr(itemWithRefs, objWithRefAccessor);
                        // multiple properties on the same entity can have a reference
                        if (angular.isArray(propertyAccessor)) {
                            var toSave = false;
                            propertyAccessor.forEach(function(currentAccessor) {
                                if (objWithRef[currentAccessor] === itemToRemove.data.id) {
                                    objWithRef[currentAccessor] = '';
                                    toSave = true;
                                }
                            });
                            if (toSave) {
                                itemsWithRefsToSave.push(itemWithRefs);
                            }
                        } else {
                            if (objWithRef[propertyAccessor] === itemToRemove.data.id) {
                                objWithRef[propertyAccessor] = '';
                                itemsWithRefsToSave.push(itemWithRefs);
                            }
                        }

                    });
                    resolve(itemsWithRefsToSave);
                });
        });
    }

    // (does not automatically save)
    function removePoiFromOpportunity(itemToRemove) {
        return $q(function(resolve) {
            APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.opportunity)
                .then(function(opportunityList) {
                    var itemsToSave = [];
                    opportunityList.forEach(function(opportunity) {
                        var toSave = false;

                        var condition = opportunity.data.section.condition;
                        if (condition && condition.expression.field.type === itemToRemove.data.id) {
                            toSave = true;

                            // clear the first level of expression
                            condition.expression = OpportunityGeneratorService.getConditionEmptyTemplate().condition.expression;
                        }

                        if (condition && condition.conditions) {

                            // filter out conditions that are related to the poi in question
                            var filteredConditions = condition.conditions.filter(function(subCondition) {
                                return subCondition.expression.field.type !== itemToRemove.data.id;
                            });

                            if (filteredConditions.length !== condition.conditions.length) {
                                toSave = true;
                                condition.conditions = filteredConditions;
                            }
                        }

                        if (toSave) {
                            itemsToSave.push(opportunity);
                        }
                    });
                    resolve(itemsToSave);
                });
        });
    }

    return service;
};
