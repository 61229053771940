import caseBrowserModalTemplate from './caseBrowserModalTemplate';

export default function($uibModal) {
    'ngInject';

    return {
        open: function(options) {
             return $uibModal.open({
                 backdrop: 'static',
                 templateUrl: caseBrowserModalTemplate,
                 controller: 'CaseBrowserModalController as vm',
                 resolve: {
                     options: function() {
                         return options;
                     }
                 }
            }).result;
        }
    };
};
