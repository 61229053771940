import moment from 'moment-timezone';

export default function (
	$http,
	SessionService,
	APIEndpointService,
	$q,
	APIConfigurationSectionService,
	VngageUtil,
	Session,
	AccountSitesService
) {
	'ngInject';
	var accountId = Session.account.id;
	var siteId = AccountSitesService.getSelectedSiteId();

	var service = {
		queryOpeningHoursConfig: queryOpeningHoursConfig,
		queryOpeningHoursOverrideConfig: queryOpeningHoursOverrideConfig,
		saveOpeningHoursConfig: saveOpeningHoursConfig,
		saveOpeningHoursOverrideConfig: saveOpeningHoursOverrideConfig,
		removeOpeningHoursOverride: removeOpeningHoursOverride,
		queryOpeningHours: queryOpeningHours,
		queryTestOpeningHours: queryTestOpeningHours,
		queryTestOpeningHoursOverride: queryTestOpeningHoursOverride
	};

	// convert time to utc for the request to the server
	function toUtc(timeStr, toString) {
		var utcTime = moment.utc(timeStr);
		if (toString) {
			utcTime = utcTime.format();
		}
		return utcTime;
	}

	// convert time to group timezone for the view
	/*
			function toGroupTime(timeStr,  toString){
				var groupTime;
				if (typeof timeStr === 'string'){
					groupTime = moment.utc(timeStr).tz(group.data.timeZone);
				} else {
					groupTime = moment.utc(timeStr.format()).tz(group.data.timeZone);
				}
				if (toString){
					groupTime = groupTime.format();
				}
				return groupTime;
			}
		*/
	function queryOpeningHours(params) {
		return $q(function (resolve, reject) {
			var r = {
				accountId: accountId,
				siteId: siteId,
				groupId: params.group.id,
				start: toUtc(params.range.from, true),
				end: toUtc(params.range.to, true)
			};
			$q.all([
				APIEndpointService.reservationsOpeningHours.query(r).$promise
			]).then(function (res) {
				resolve(res[0]);
			}, function (err) {
				reject(err);
			});
		});
	}

	function queryTestOpeningHours(params) {
		var config = {
			accountId: accountId,
			siteId: siteId,
			groupId: params.group.id,
			start: toUtc(params.range.from, true),
			end: toUtc(params.range.to, true),
			openingHours: [
				params.previewModel.openingHours['0'] || {openHourEvents: []},
				params.previewModel.openingHours['1'] || {openHourEvents: []},
				params.previewModel.openingHours['2'] || {openHourEvents: []},
				params.previewModel.openingHours['3'] || {openHourEvents: []},
				params.previewModel.openingHours['4'] || {openHourEvents: []},
				params.previewModel.openingHours['5'] || {openHourEvents: []},
				params.previewModel.openingHours['6'] || {openHourEvents: []}
			]
		};
		return APIEndpointService.reservationsTestOpeningHours.post_arrayResult(config).$promise;
	}

	function queryTestOpeningHoursOverride(params) {
		// Convert "start" and "end" to groupTimezone
		var start = moment.tz(moment(params.previewModel.start).format('YYYY-MM-DD'), params.previewModel.group.timeZone).startOf('day');
		var end = moment.tz(moment(params.previewModel.end).format('YYYY-MM-DD'), params.previewModel.group.timeZone).endOf('day');
		var r = {
			accountId: accountId,
			siteId: siteId,
			id: (params.previewModel.id && ['new', 'default'].indexOf(params.previewModel.id) ===-1 ? params.previewModel.id : null),
			groupId: params.group.id,
			start: toUtc(params.range.from, true),
			end: toUtc(params.range.to, true),
			validFrom: toUtc(start, true),
			validTo: toUtc(end, true),
			openingHours: [
				params.previewModel.openingHours['0'] || {openHourEvents: []},
				params.previewModel.openingHours['1'] || {openHourEvents: []},
				params.previewModel.openingHours['2'] || {openHourEvents: []},
				params.previewModel.openingHours['3'] || {openHourEvents: []},
				params.previewModel.openingHours['4'] || {openHourEvents: []},
				params.previewModel.openingHours['5'] || {openHourEvents: []},
				params.previewModel.openingHours['6'] || {openHourEvents: []}
			]
		};
		return APIEndpointService.reservationsTestOpeningHourOverrides.post_arrayResult(r).$promise;
	}

	function queryOpeningHoursConfig(params) {
		var r = {
			groupId: params.group.id
		};
		return APIEndpointService.reservationsOpeningHourConfigs.get(r).$promise;
	}

	function queryOpeningHoursOverrideConfig(params) {
		var r = {
			groupId: params.group.id
		};
		return APIEndpointService.reservationsOpeningHourOverrides.query(r).$promise;
	}

	function saveOpeningHoursOverrideConfig(params) {
		// Convert "start" and "end" to groupTimezone
		var start = moment.tz(moment(params.start).format('YYYY-MM-DD'), params.group.timeZone).startOf('day');
		var end = moment.tz(moment(params.end).format('YYYY-MM-DD'), params.group.timeZone).endOf('day');
		var r = {
			accountId: accountId,
			siteId: siteId,
			groupId: params.group.id,
			validFrom: toUtc(start, true),
			validTo: toUtc(end, true),
			openingHours: [
				params.openingHours['0'] || {openHourEvents: []},
				params.openingHours['1'] || {openHourEvents: []},
				params.openingHours['2'] || {openHourEvents: []},
				params.openingHours['3'] || {openHourEvents: []},
				params.openingHours['4'] || {openHourEvents: []},
				params.openingHours['5'] || {openHourEvents: []},
				params.openingHours['6'] || {openHourEvents: []}
			]
		};
		if (params.id && ['new', 'default'].indexOf(params.id) === -1) {
			// if edit
			r.id = params.id;
			return APIEndpointService.reservationsOpeningHourOverrides.create({id: r.id}, r).$promise; // [TL] Ugly!! Should be POST/save, but use CREATE instead to avoid problems with isArray==true in the resource...
		} else {
			return APIEndpointService.reservationsOpeningHourOverrides.create(r).$promise;
		}
	}

	function removeOpeningHoursOverride(params) {
		var r = {
			id: params.id,
			groupId: params.group.id
		};
		return APIEndpointService.reservationsOpeningHourOverrides.remove(r).$promise;
	}

	function saveOpeningHoursConfig(params) {
		var r = {
			accountId: accountId,
			siteId: siteId,
			groupId: params.group.id,
			openingHours: [
				params.openingHours['0'] || {openHourEvents: []},
				params.openingHours['1'] || {openHourEvents: []},
				params.openingHours['2'] || {openHourEvents: []},
				params.openingHours['3'] || {openHourEvents: []},
				params.openingHours['4'] || {openHourEvents: []},
				params.openingHours['5'] || {openHourEvents: []},
				params.openingHours['6'] || {openHourEvents: []}
			]
		};
		return APIEndpointService.reservationsOpeningHourConfigs.create(r).$promise;
	}

	return service;
};

