import React from 'react';
import {useGetTranslation} from './../../LangContext';

const UserTyping = (props) => {
	const getTranslation = useGetTranslation();
	return (
		<div className="user-typing">
			{getTranslation('inputMsgTyping')}
		</div>
	)
};
export default UserTyping

