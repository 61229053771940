export default function(VngageUtil) {
    'ngInject';

    return {
        restrict: 'A',
        require: '^form',
        link: function(scope, elem, attr, formCtrl) {
            var inputField = VngageUtil.findFirstEl(elem, ['input', 'textarea', 'select']);

            var formInput = formCtrl[inputField.attr('name')];

            var updateClass = function(isInvalid) {
                if (isInvalid) {
                    elem.addClass('has-error');
                } else {
                    elem.removeClass('has-error');
                }
            };

            scope.$watch(function() {
                return formInput.$invalid && formInput.$dirty;
            }, updateClass);
        }
    };
};
