import immutable from 'immutable';
import Format from '../utils/Format';
import MessageType from './../constants/MessageType';
import ServerRequests from './ServerRequests';
import LogActions from '../actions/LogActions';
import ConversationActions from '../actions/ConversationActions';


const sendFormattedMessage = (conversationId, message, messageType, additionalData) => async dispatch => {
	const formattedMessage = Format.asType(conversationId, message, messageType, additionalData);
	await dispatch(sendMessage(conversationId, formattedMessage, messageType));
};

const sendFormattedMessageAsync = (conversationId, message, messageType, additionalData) => async dispatch => {
	const formattedMessage = Format.asType(conversationId, message, messageType, additionalData);
	await ServerRequests.sendMessage(conversationId, formattedMessage);
};


const sendMessage = (conversationId, formattedMessage, messageType) => async dispatch => {
	try {
		await ServerRequests.sendMessage(conversationId, formattedMessage);
	} catch (error) {
		dispatch(LogActions.netCallError(0, {
			name: 'sendMessage',
			error,
			conversationId,
			formattedMessage,
			messageType
		}));
	}
};

const timeout = (ms) => {
	return new Promise(resolve => setTimeout(resolve, ms));
};

const sendAndAddLocalFormattedMessage = (conversationId, message, messageType = MessageType.CHAT, additionalData) => async dispatch => {
	const formattedMessage = Format.asType(conversationId, message, messageType, additionalData);
	const messageId = formattedMessage.tags[0];
	dispatch(ConversationActions.addLocalMessage(conversationId, formattedMessage));
	try {
		// await timeout(2000);
		// await ServerRequests.sendMessage(conversationId + 'nope', formattedMessage);
		await ServerRequests.sendMessage(conversationId, formattedMessage);
	} catch (error) {
		// update message status and log error
		dispatch(ConversationActions.updateLocalMessageStatus(conversationId, messageId, 'error'));
		dispatch(LogActions.netCallError(0, {
			name: 'sendMessage',
			error,
			conversationId,
			formattedMessage,
			messageType
		}));
	}
};

const resendMessage = (conversationId, messageId) => async (dispatch, getState) => {
	// find message by messageId and resend it
	const message = getState().getIn(['conversations', conversationId, 'localMessages'])
		.find(message => message.getIn(['tags', 0]) === messageId);
	if (!message) {
		dispatch(LogActions.netCallError(0, {
			name: 'resendMessage',
			error: 'message not found',
			conversationId,
			messageId,
		}));
		return;
	}
	const messageToSend = Format.asCleanMessage(message).toJS();
	try {
		dispatch(ConversationActions.updateLocalMessageStatus(conversationId, messageId, 'pending'));
		// await timeout(2000);
		// await ServerRequests.sendMessage(conversationId + 'fel', messageToSend);
		await ServerRequests.sendMessage(conversationId, messageToSend);
		dispatch(ConversationActions.updateLocalMessageStatus(conversationId, messageId, 'ok'));
	} catch (error) {
		// log error
		dispatch(ConversationActions.updateLocalMessageStatus(conversationId, messageId, 'error'));
		dispatch(LogActions.netCallError(0, {
			name: 'resendMessage',
			error,
			conversationId,
			message: messageToSend,
		}));
	}

};

const sendChatMessage = (conversationId, message) => async dispatch => dispatch(sendAndAddLocalFormattedMessage(conversationId, message, MessageType.CHAT));
const sendNote = (conversationId, message) => async dispatch => dispatch(sendAndAddLocalFormattedMessage(conversationId, message, MessageType.NOTE));
const sendAction = (conversationId, actionObj, actionData) => async dispatch => dispatch(sendAndAddLocalFormattedMessage(conversationId, actionObj, MessageType.ACTION, actionData));
const sendDomUploadRequest = conversationId => async dispatch => dispatch(sendFormattedMessage(conversationId, '', MessageType.ASK_FOR_DOM_UPLOAD));
const sendDomHighlight = (conversationId, targetData) => async dispatch => dispatch(sendFormattedMessageAsync(conversationId, targetData, MessageType.DOM_HIGHLIGHT));
const sendMetadataExchange = (conversationId, params) => async dispatch => dispatch(sendFormattedMessage(conversationId, params, MessageType.METADATA_EXCHANGE));
const sendDomNavigation = (conversationId, url) => async dispatch => dispatch(sendFormattedMessage(conversationId, url, MessageType.NAVIGATION));
const sendVideoMessage = (roomId, videoType, videoActivity) => async dispatch => dispatch(sendFormattedMessage(roomId, videoType, MessageType.VIDEO, videoActivity));
const sendTypingStatus = (conversationId, typingStatus) => async dispatch => dispatch(sendFormattedMessage(conversationId, typingStatus, MessageType.TYPING_STATUS));


export default {
	resendMessage,
	sendChatMessage,
	sendNote,
	sendAction,
	sendDomUploadRequest,
	sendDomHighlight,
	sendMetadataExchange,
	sendDomNavigation,
	sendVideoMessage,
	sendTypingStatus,
};

