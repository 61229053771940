import _indexOf from 'lodash/indexOf';

export default function () {
	return function (userList, userFilter) {
		if (!userList) {
			return;
		}
		if (!userFilter || userFilter.length === 0) {
			return userList;
		}
		return userList.filter(function (user) {
			return _indexOf(userFilter, user.ownerUserId) !== -1;
		});
	};
}
