import _isObject from 'lodash/isObject';
import urlHelpers from '../../common/urlHelpers';

export default function ($http, $q, IdentityService, SessionService, $location, $rootScope, $state, NotificationService, AuthorizationService, $window, SessionKeepAliveService, vngageConfig) {
	'ngInject';

	const authenticationResponseUrlTrigger = 'authenticate';
	const bearerTokenResponseUrlTrigger = 'bearer';
	const sessionIdResponseUrlTrigger = 'session';
	const AuthService = {};
	let toDuringLoad;

	function setStateOptionsFromUrlParts(urlParts, options) {
		if (urlParts.length) {
			const stateParts = urlParts.shift().split(':');
			const stateName = stateParts.shift();
			if (stateName) {
				options.goToStateAfterLogin = 'root.' + stateName;
				options.toStateParams = {};
				while (stateParts.length) {
					// There are stateParams as well...
					const stateParamParts = stateParts.shift().split('=');
					if (stateParamParts.length === 2) {
						options.toStateParams[stateParamParts[0]] = stateParamParts[1];
					}
				}
			}
		}
	}

	// Keep the session alive
	SessionKeepAliveService.start();

	AuthService.login = function (forceLogin) {
		const session = SessionService.getSession();
		if (session && session.signInUrlOverride) {
			AuthService.signInUrlOverride = session.signInUrlOverride;
		}

		if (forceLogin) {
			SessionService.clearSessionId();
		} else if (IdentityService.isAuthenticated()) {
			return;
		}

		// create url for logging in to
		function createLoginUrl() {

			let toStateName = '';
			if (toDuringLoad && toDuringLoad.stateName) {
				const stateParts = toDuringLoad.stateName.split('.');
				if (stateParts[0] === 'root') {
					stateParts.shift(); // Remove the "root."-part of the name if exists
				}
				if (stateParts.length) {
					toStateName = '/' + stateParts.join('.');
					if (toDuringLoad.stateParams && _isObject(toDuringLoad.stateParams)) {
						for (const key of Object.keys(toDuringLoad.stateParams)) {
							toStateName += ':' + key + '=' + toDuringLoad.stateParams[key];
						}
					}
				}
			}

			if (AuthService.signInUrlOverride) {
				// console.log('createLoginUrl() - signInUrlOverride:', AuthService.signInUrlOverride);
				return AuthService.signInUrlOverride;
			} else {
				// console.log('createLoginUrl() - redirecting no standard signInUrl:', vngageConfig.signInUrl);
				return [
					vngageConfig.signInUrl,
					'?accountId=', vngageConfig.accountId,
					'&targeturl=', document.location.protocol,
					'//',
					document.location.host,
					document.location.pathname,
					$window.encodeURIComponent(document.location.search),
					// Tell the server to answer at #[authenticationResponseUrlTrigger]
					$window.encodeURIComponent('#' + authenticationResponseUrlTrigger),
					'/{0}',
					toStateName
				].join('');
			}
		}

		if (AuthService.signInUrlOverride === 'notifyParent') {
			// This is a special feature when Workspace is loaded in an iframe and/or session was received via SSO
			// If iframed, the usual IDP login flow will not work
			// Also, with in SSO, the user often does not even know his/her login-credentials
			// So instead, we often want a new SSO-flow to be initiated on "login".
			// This must be communicated to the parent frame (where the session is created/received somehow)

			// Loading Workspace with a URL-parameter ?signInUrl=notifyParent will do just this:
			//  Instead of navigating to default loginPage (vngageConfig.signInUrl)
			//  or to a custom URL specified by ?signInUrl=<URL>,
			//  it will try to send a message via window.postMessage() to parent, notifying parent that the session is invalid
			// 	(so parent needs to listen for this, and take appropriate action: Re-SSO, notify user, etc)
			if (window.parent && window.parent !== window.self) {
				window.parent.postMessage({
					issuer: 'Vergic',
					messageType: 'session_invalid'
				}, '*');
			} else {
				console.error('AuthService.login() error: signInUrl === \'notifyParent\' but no parent...');
			}
		} else {
			setTimeout(function () {
				$window.location = createLoginUrl();
			}, 0);
		}
	};

	AuthService.logout = function (logoutRouting, logoutLandingPageUrl) {
		$http.post(vngageConfig.path + 'Session/Logout?sessionId=' + SessionService.getSession().sessionId)
			// .then(AuthService.login.bind(this, true));
			.then(() => {
				if (logoutRouting) {
					SessionService.clearSessionId();
					if (logoutLandingPageUrl) {
						window.location.href = logoutLandingPageUrl;
					} else {
						$state.transitionTo('loggedOut');
					}
				} else {
					toDuringLoad = null;
					// toDuringLoad = { stateName: $state.current.name }; // Or do we want to preserve stateName on logout? (so re-logging in will take the user to the same/last state)
					AuthService.login(true);
				}
			});
	};

	// uses returned token and posts it to a URL to get a session ID
	AuthService.getSession = function (token) {
		if (!token) {
			return $q(function (resolve, reject) {
				reject();
			});
		}

		const url = vngageConfig.loginTokenUrl + token;

		return $http.post(url).then(function (res) {
			// Logged in
			SessionService.setSessionId(res.data.accessToken);
			SessionService.setSessionTimeToLive(res.data.sessionTimeoutSeconds);
		});
	};

	AuthService.requestDelegation = function (accountId, roleClaim) {

		const url = [
			vngageConfig.path, 'Session/RequestDelegation',
			'?sessionId=', SessionService.getSession().sessionId,
			'&accountId=', accountId,
			'&roleClaim=', window.encodeURIComponent(roleClaim)
		].join('');

		return $http.post(url).then(function (response) {

			// Save original sessionId as delegatedSessionId
			SessionService.setDelegatedSessionId(SessionService.getSession().sessionId);
			SessionService.setSessionId(response.data.accessToken);
			$window.location = $window.location.origin + $window.location.pathname;
		});
	};

	AuthService.logoutDelegation = function () {
		$http.post(vngageConfig.path + 'Session/Logout' + '?sessionId=' + SessionService.getSession().sessionId).then(function () {
			SessionService.setSessionId(SessionService.getDelegatedSessionId());
			$window.location = $window.location.origin + $window.location.pathname;
		});
	};

	AuthService.interceptRoutes = function (options) {

		// Evaluate the route on page load by analyzing the URL

		if ($location.url().indexOf('/' + authenticationResponseUrlTrigger + '/') === 0) {
			// This is a response from IDP server at #/[authenticationResponseUrlTrigger]
			// I.e. the IDP-server sent back authentication token -> use it to get session
			// if getSession() succeeded it means we (should) have a valid sessionId -> prime

			// Get token from URL, and also try to parse state if present
			const urlParts = $location.url().slice(authenticationResponseUrlTrigger.length + 2).split('/');
			const token = urlParts.shift();
			setStateOptionsFromUrlParts(urlParts, options);

			AuthService.getSession(token)
				.then(IdentityService.prime)
				.then(null, error => AuthService.login(true)); // if prime() failed -> login()

		} else if ($location.url().indexOf('/' + bearerTokenResponseUrlTrigger + '/') === 0) {
			// By using a URL-pattern like: "/#/<bearerTokenResponseUrlTrigger>/<token>/<route>",
			// we can inject a bearerToken directly into Workspace
			// I.e. an access bearerToken that an authProxy or commSrv can resolve into a vergic user

			// Get bearerToken from URL, and also try to parse state if present
			const urlParts = $location.url().slice(bearerTokenResponseUrlTrigger.length + 2).split('/');
			const bearerToken = urlParts.shift();
			setStateOptionsFromUrlParts(urlParts, options);

			if (bearerToken) {
				const signInUrlOverride = urlHelpers.getUrlParameter('signInUrl');
				// console.log('Logged in via bearerToken:', bearerToken, signInUrlOverride);
				SessionService.setBearerToken(bearerToken, signInUrlOverride);
			} else {
				// No bearerToken => Assume we already have a session (IdentityService.prime() below will fail and redirect to login if we don't...)
			}
			IdentityService.prime()
				.then(null, error => AuthService.login(true)); // if prime() failed -> login()

		} else if ($location.url().indexOf('/' + sessionIdResponseUrlTrigger + '/') === 0) {
			// By using a URL-pattern like: "/#/<sessionIdResponseUrlTrigger>/<sessionId>/<route>",
			// we can inject a vergic session directly into Workspace
			// I.e. a vergic sessionId or an access sessionId that commSrv can resolve into a vergic user

			// Get sessionId from URL, and also try to parse state if present
			const urlParts = $location.url().slice(sessionIdResponseUrlTrigger.length + 2).split('/');
			const sessionId = urlParts.shift();
			setStateOptionsFromUrlParts(urlParts, options);

			if (sessionId) {
				const signInUrlOverride = urlHelpers.getUrlParameter('signInUrl');
				// console.log('Logged in via sessionId:', sessionId, signInUrlOverride);
				SessionService.setSessionId(sessionId, signInUrlOverride);
			} else {
				// No sessionId => Assume we already have a session (IdentityService.prime() below will fail and redirect to login if we don't...)
			}
			IdentityService.prime()
				.then(null, error => AuthService.login(true)); // if prime() failed -> login()

		} else {
			// console.log('Normal route');
			IdentityService.prime()
				.then(null, error => AuthService.login(true)); // if prime() failed -> login()
		}

		// Route interceptor
		$rootScope.$on('$stateChangeStart', function (event, toState, toParams) {

			if (!IdentityService.authorizationDataLoaded() && toState.name !== options.loadingState) {
				// authorization data hasn't loaded yet, show loading screen

				event.preventDefault();

				// remember where we were going before going to loading state
				if (toState.name === options.rootState || toState.name === '404') {
					// going to root or 404 should default to options.goToStateAfterLogin
					toDuringLoad = {
						stateName: options.goToStateAfterLogin,
						stateParams: options.toStateParams
					};
				} else {
					toDuringLoad = {
						stateName: toState.name,
						stateParams: toParams
					};
				}

				// prime is not done, route to options.loadingState
				$state.go(options.loadingState);

			} else if (toState.data && toState.data.access && !AuthorizationService.authorize(toState.data.access)) {
				// unauthorized routing attempt

				event.preventDefault();

				NotificationService.error({header: 'Unauthorized'});

				$state.go(options.goToStateAfterLogin);

			} else if (toState.name === options.loadingState) {
				// loading, add pending routing after prime is done

				IdentityService.authorizationDataLoadedPromise().then(function () {
					event.preventDefault();

					if (toDuringLoad && toDuringLoad.stateName && toDuringLoad.stateName !== '404') {
						try {
							$state.go(toDuringLoad.stateName, toDuringLoad.stateParams || {});
						} catch (e) {
							// console.log('Invalid state:', toDuringLoad.stateName);
							$location.path('/');
						}
					} else {
						$location.path('/');
					}
				});
			}
		});
	};

	return AuthService;
}