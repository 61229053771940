import DialogGuestController from './DialogGuestController';
import '../../common/VngageWorkspaceTranslationModule';

/*eslint-disable */
export default angular.module('vngageApp.dialogguest', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])
	.controller('DialogGuestController', DialogGuestController)

	.config( ($stateProvider, $translateProvider, DesktopTranslations, $urlRouterProvider, VngageWorkspaceTranslationServiceProvider) => {
		'ngInject';

		let tpl = '<div id="vngage-guest-app" class="dialogguest"></div>';

		//TODO: add DOMCobrowsingOnly to check
		let checkShield = document.location.protocol === 'https:';

		if (checkShield){
			//$translateProvider.translations('en', DesktopTranslations['sv-SE']);
			VngageWorkspaceTranslationServiceProvider.addModuleTranslations(DesktopTranslations);
			tpl = '<div vngage-security-warning></div>' + tpl;
		}

		$stateProvider.state('root.dialogguest', {
			url: '/dialog/',
			views: {
				content: {
					template: '<div ui-view></div>'
				}
			},
			resolve: {
				loadConfiguration: function(DesktopService) {
					'ngInject';
					return DesktopService.getConfiguration();
				}
			}
		});

		$stateProvider.state('root.dialogguest.conversation', {
			url: ':id',
			template: tpl,
			controller: 'DialogGuestController',
			controllerAs:'vm',
			resolve: {
				conversationRef: $stateParams => { return $stateParams.id; },
				loadConfiguration: function(DesktopService) {
					'ngInject';
					return DesktopService.getConfiguration();
				}
			}
		});
	});
