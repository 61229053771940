import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';
import _every from 'lodash/every';

export default function($scope, $uibModalInstance, vngageGuid, mapping, banner, groupList, caseTypeList, translations) {
	'ngInject';
	const vm = this;
	vm.addNew = (!mapping);
	vm.banner = banner;
	vm.groupList = groupList;
	vm.caseTypeList = caseTypeList;
	vm.translations = translations;
	vm.save = save;
	vm.cancel = $uibModalInstance.dismiss;
	vm.remove = remove;

	vm.filterMappedGroups = (group) => {
		/*
		return _every(vm.banner.data.section.subjectMapping, (mapping) => {
			return (mapping.groupId !== group.data.id || mapping.id === vm.mapping.id)
		});
		*/
		// Show all groups (even if already mapped). Uncomment above to only allow one each group to exist in one mapping
		return true;
	};

	vm.currentDefaultMapping = _find(vm.banner.data.section.subjectMapping, (mapping) => {
		return (!!mapping.defaultMapping);
	});

	vm.mapping = (mapping ? _cloneDeep(mapping) : {
		id: vngageGuid.generate(),
		subjectMappingName: '',
		groupId: '',
		groupName: '',
		caseTypeId: '',
		caseTypeName: '',
		translationId: '',
		translationName: '',
		position: 0,
		defaultMapping: false
	});

	if (!vm.mapping.translationId) {
		// Migrate old mapping format
		vm.mapping.translationId=vm.mapping.translation;
	}

	$scope.$watch('vm.mapping.subjectMappingName', (subjectMappingName) => {
		if (subjectMappingName && _find(vm.banner.data.section.subjectMapping, (mapping) => {
			return (mapping.subjectMappingName && mapping.subjectMappingName.toUpperCase() === subjectMappingName.toUpperCase() && mapping.id !== vm.mapping.id);
		})) {
			$scope.mappingForm.mappingName.$setValidity('duplicate', false);
		} else {
			$scope.mappingForm.mappingName.$setValidity('duplicate', true);
		}
	});

	$scope.$watch('vm.mapping.defaultMapping', (defaultMapping) => {
		if (defaultMapping && _find(vm.banner.data.section.subjectMapping, (mapping) => {
			return (mapping.defaultMapping && mapping.id !== vm.mapping.id);
		})) {
			$scope.mappingForm.defaultMapping.$setValidity('collision', false);
		} else {
			$scope.mappingForm.defaultMapping.$setValidity('collision', true);
		}
	});

	function save() {
		if (vm.mapping.groupId) {
			vm.mapping.groupName = _find(vm.groupList, (group) => {
				return group.data.id === vm.mapping.groupId;
			}).data.name;
		}
		if (vm.mapping.caseTypeId) {
			vm.mapping.caseTypeName = _find(vm.caseTypeList, (caseType) => {
				return caseType.data.id === vm.mapping.caseTypeId;
			}).data.name;
		}
		if (vm.mapping.translationId) {
			vm.mapping.translationName = vm.translations[vm.mapping.translationId].displayName;
		}
		$uibModalInstance.close(vm.mapping);
	}

	function remove() {
		$uibModalInstance.close();
	}
}
