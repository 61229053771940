export default function ($timeout) {
	'ngInject';

	return {
		restrict: 'A',
		require: ['^form', 'ngModel'],
		scope: {
			model: '=ngModel',
			refresh: '=',
			matchBracketsDebounceSize: '=',	// If set and editor text is larger than this size (in bytes), codemirror bracket-matching will be debounced for performance (by 250ms or "matchBracketsDebounceTime" if set)
			matchBracketsDebounceTime: '='
		},
		replace: true,
		template: '<textarea data-ui-codemirror="{ onLoad: vm.setEditor }" data-ui-codemirror-opts="vm.options"></textarea>',
		controllerAs: 'vm',
		controller: function ($scope, $interval) {
			'ngInject';

			const vm = this;
			vm.editor = null;
			vm.matchBracketsDebounceTime = ($scope.matchBracketsDebounceTime || 250);
			vm.matchBracketsDebounceSize = ($scope.matchBracketsDebounceSize || 0);
			$scope.matchBracketDebounce = false;

			vm.options = {
				mode: 'text/css',
				lineNumbers: true,
				lineWrapping: true,
				matchBrackets: true,
				autoMatchParens: true,
				autoClearEmptyLines: true
			};

			$scope.$watch('matchBracketDebounce', function (enableDebounce) {
				if (vm.editor) {
					vm.editor.setOption('matchBrackets', {
						matchBracketsDebounceTime: (enableDebounce ? vm.matchBracketsDebounceTime : 0)
					});
				}
			});
			function adjustCodeMirrorMatchBracketDebounce() {
				// If codemirror editor-text is larger than <matchBracketsDebounceSize> bytes: Set matchBracketsDebounce-time to <vm.matchBracketsDebounceTime> ms for performance
				$scope.matchBracketDebounce = (vm.matchBracketsDebounceSize && vm.editor && vm.editor.getValue().length > vm.matchBracketsDebounceSize);
			}

			vm.setEditor = (editor) => {
				vm.editor = editor;
				$timeout(() => {
					vm.editor.doc.setValue($scope.model || '');
					adjustCodeMirrorMatchBracketDebounce();
				}, 0);
				$interval(adjustCodeMirrorMatchBracketDebounce, 5000);
			};

			$scope.$watch('refresh', (update) => {
				if (update && vm.editor) {
					$timeout(() => {
						vm.editor.refresh()
					}, 0);
				}
			}, true); // <--- Important: We need to watch incoming scope-variables with deep-checking in directives!!!!

		}
	};
};
