export default {
	'en-GB': {
		caseBrowser: {
			HEADER: 'Case browser',
			INGRESS: 'The Case browser lets you search for specific cases associated with an agent. From the list of results you can get the dialog transcript and details about the dialog.',
			SEARCH_BY_AGENT: 'Search by agent',
			SEARCH_BY_CASE_ID: 'Search by case ID',
			SEARCH_BY_SATISFACTION_SCORE: 'Search by customer satisfaction',
			SEARCH_BY_CUSTOMER_ID: 'Search by customer ID',
			BY_AGENT: 'By agent',
			BY_CUSTOMER_ID: 'By customer id',
			CUSTOMER_ID_TYPE: 'Customer id type',
			BY_AGENT_HELP_TOOLTIP: 'If the agent has been removed from the system it will not be available in the agent dropdown. You can search for agent by name or agent id.',
			FROM: 'From',
			FROM_HELP_TOOLTIP: 'Filter by opening time of a case. The time field will only be used in the search if a date is specified.',
			TO: 'To',
			TO_HELP_TOOLTIP: 'Filter by opening time of a case. The time field will only be used in the search if a date is specified.',
			SEARCH_PLACEHOLDER_TEXT: 'Type to search by name or id',
			SEARCH_CUSTOMER_ID_PLACEHOLDER_TEXT: 'Type to search by customer id',
			DATEPICKER_TODAY: 'Today',
			DATEPICKER_CLEAR: 'Clear',
			DATEPICKER_CLOSE: 'Close',
			SORT_ORDER: 'Get first',
			SORT_ORDER_HELP_TOOLTIP: 'In which order to fetch results from database. Selecting \'newest\' will fetch most recent cases first. Selecting \'oldest\' will fetch oldest logged cases first.',
			SORT_ORDER_NEWEST: 'Newest',
			SORT_ORDER_OLDEST: 'Oldest',
			NBR_OF_CASES: 'Number of cases to retrieve',
			NBR_OF_CASES_HELP_TOOLTIP: 'Number of cases to retrieve from the database. If more exist and you want to load more, click the \'Load more\' button at the bottom of the page',
			CLEAR_FILTER: 'Clear filter',
			GET_CASES: 'Get cases',
			BY_CASE_ID: 'By case ID',
			BY_SATISFACTION_SCORE: 'By customer satisfaction',
			INCLUDE_ONGOING_CASES: 'Include ongoing cases',

			RESULT: 'Result',
			TOTAL_NBR_RETRIEVED: 'Total number of retrieved cases:',
			LOAD_X_MORE: 'Load {{nbr}} more',
			RESULT_COL_NBR: '#',
			RESULT_COL_DATE: 'Date',
			RESULT_COL_OPENED: 'Opened',
			RESULT_COL_CLOSED: 'Closed',
			RESULT_COL_AGENT: 'Agent',
			RESULT_COL_CASE_TYPE: 'Case type',
			RESULT_COL_GROUP: 'Group',
			RESULT_COL_CLOSURE: 'Closure',
			RESULT_COL_OUTCOME: 'Outcome',
			RESULT_COL_CASE_ID: 'Case ID',
			RESULT_COL_SATISFACTION_SCORE: 'Score',

			CASE_STATE_NEW: 'New',
			CASE_STATE_ONGOING: 'Ongoing',
			CASE_STATE_CLOSED: 'Closed',
			CASE_OUTCOME_POSITIVE: 'Positive',
			CASE_OUTCOME_NEUTRAL: 'Neutral',
			CASE_OUTCOME_NEGATIVE: 'Negative',

			NOTIFICATION_NO_CASES_RETRIEVED_HEADER: 'No Cases Retrieved',
			NOTIFICATION_NO_CASES_RETRIEVED_BODY: 'No cases found matching the search options',
			NOTIFICATION_NO_MORE_CASES_RETRIEVED_HEADER: 'No More Cases Retrieved',
			NOTIFICATION_NO_MORE_CASES_RETRIEVED_BODY: 'No additional cases found matching the search options',
			NOTIFICATION_X_CASES_RETRIEVED: '{{nbr}} Cases Retrieved',
			NOTIFICATION_X_MORE_CASES_RETRIEVED: '{{nbr}} More Cases Retrieved',
			NOTIFICATION_INVALID_CASE_ID: 'Invalid Case ID',
			NOTIFICATION_ERROR_CLEANING_CASE: 'Error cleaning Case',
			NOTIFICATION_CASE_CLEANED_OK: 'Case cleaned'
		},
		caseBrowserModal: {
			HEADER: 'Case Details',
			CLOSE: 'Close',
			CLEAN: 'Delete Case data',
			CASE_ID: 'Case ID: ',
			SATISFACTION_SCORE: 'Customer Satisfaction',
			TAB_TRANSCRIPT: 'Transcript',
			TAB_OVERVIEW: 'Overview',
			ADVANCED: 'Show visitor navigation',
			SHOW_EVENTS: 'Show conversation events',
			BOOKED_MEETING_ADVANCED_INFO: 'Booked meeting. Click "Advanced" to view the reservation info',
			DATE: 'Date',
			OPENED: 'Opened',
			CLOSED: 'Closed',
			VISITOR_PLATFORM: 'Visitor platform',
			CURRENT_OWNER: 'Current owner',
			OPPORTUNITY: 'Opportunity',
			SOLUTION: 'Solution',
			GROUP: 'Group',
			CASE_TYPE: 'Case type',
			STATE: 'State',
			CLOSURE: 'Closure',
			OUTCOME: 'Outcome',
			PARTICIPANTS: 'Participants',
			CONVERSATIONS: 'Conversations',
			REF_NUMBER: 'refNumber',
			AUTHENTICATION: 'Authentication',

			CASE_NOT_CLOSED: '[Case not closed]',
			TRANSCRIPT_UNKNOWN_USER: '[unknown user]',
			VISITOR_INFORMATION: 'Visitor information',
			VISITOR_IDENTITY_KNOWN: 'Visitor is identified',
			VISITOR_IDENTITY_UNKNOWN: 'Visitor is no longer identified',
			NAVIGATED_TO: 'Navigated to:',
			CONVERSATION_EVENT: 'Conversation event:',

			RESERVATION_INFORMATION: 'Reservation information',
			RESERVATION_YES: 'yes',
			RESERVATION_NO: 'no',
			RESERVATION_GROUP: 'Group',
			RESERVATION_START_TIME: 'Start time',
			RESERVATION_FIRST_NAME: 'First name',
			RESERVATION_LAST_NAME: 'Last name',
			RESERVATION_PHONE: 'Phone',
			RESERVATION_EMAIL: 'Email',

			CLEAN_CASE_TITLE: 'Clean Case',
			CLEAN_CASE_HEADING: 'Do you really want to clean this Case?',
			CLEAN_CASE_DESCRIPTION: 'This operation can not be undone, and the Case\'s transcript and properties will be permanently deleted!',
			FILE_SENT: 'File'
		}
	},
	'x-sv-SE': {
		caseBrowser: {
			HEADER: 'Case browser',
			INGRESS: 'The Case browser lets you search for specific cases associated with an agent. From the list of results you can get the dialog transcript and details about the dialog.',
			SEARCH_BY_AGENT: 'Search by agent',
			SEARCH_BY_CASE_ID: 'Search by case ID',
			SEARCH_BY_SATISFACTION_SCORE: 'Search by customer satisfaction',
			SEARCH_BY_CUSTOMER_ID: 'Search by customer ID',
			BY_AGENT: 'By agent',
			BY_CUSTOMER_ID: 'By customer id',
			CUSTOMER_ID_TYPE: 'Customer id type',
			BY_AGENT_HELP_TOOLTIP: 'If the agent has been removed from the system it will not be available in the agent dropdown. You can search for agent by name or agent id.',
			FROM: 'From',
			FROM_HELP_TOOLTIP: 'Filter by opening time of a case. The time field will only be used in the search if a date is specified.',
			TO: 'To',
			TO_HELP_TOOLTIP: 'Filter by opening time of a case. The time field will only be used in the search if a date is specified.',
			SEARCH_PLACEHOLDER_TEXT: 'Type to search by name or id',
			SEARCH_CUSTOMER_ID_PLACEHOLDER_TEXT: 'Type to search by customer id',
			DATEPICKER_TODAY: 'Today',
			DATEPICKER_CLEAR: 'Clear',
			DATEPICKER_CLOSE: 'Close',
			SORT_ORDER: 'Get first',
			SORT_ORDER_HELP_TOOLTIP: 'In which order to fetch results from database. Selecting \'newest\' will fetch most recent cases first. Selecting \'oldest\' will fetch oldest logged cases first.',
			SORT_ORDER_NEWEST: 'Newest',
			SORT_ORDER_OLDEST: 'Oldest',
			NBR_OF_CASES: 'Number of cases to retrieve',
			NBR_OF_CASES_HELP_TOOLTIP: 'Number of cases to retrieve from the database. If more exist and you want to load more, click the \'Load more\' button at the bottom of the page',
			CLEAR_FILTER: 'Clear filter',
			GET_CASES: 'Get cases',
			BY_CASE_ID: 'By case ID',
			BY_SATISFACTION_SCORE: 'By customer satisfaction',
			INCLUDE_ONGOING_CASES: 'Include ongoing cases',

			RESULT: 'Result',
			TOTAL_NBR_RETRIEVED: 'Total number of retrieved cases:',
			LOAD_X_MORE: 'Load {{nbr}} more',
			RESULT_COL_NBR: '#',
			RESULT_COL_DATE: 'Date',
			RESULT_COL_OPENED: 'Opened',
			RESULT_COL_CLOSED: 'Closed',
			RESULT_COL_AGENT: 'Agent',
			RESULT_COL_CASE_TYPE: 'Case type',
			RESULT_COL_GROUP: 'Group',
			RESULT_COL_CLOSURE: 'Closure',
			RESULT_COL_OUTCOME: 'Outcome',
			RESULT_COL_CASE_ID: 'Case ID',
			RESULT_COL_SATISFACTION_SCORE: 'Customer Satisfaction',

			CASE_STATE_NEW: 'New',
			CASE_STATE_ONGOING: 'Ongoing',
			CASE_STATE_CLOSED: 'Closed',
			CASE_OUTCOME_POSITIVE: 'Positive',
			CASE_OUTCOME_NEUTRAL: 'Neutral',
			CASE_OUTCOME_NEGATIVE: 'Negative',

			NOTIFICATION_NO_CASES_RETRIEVED_HEADER: 'No Cases Retrieved',
			NOTIFICATION_NO_CASES_RETRIEVED_BODY: 'No cases found matching the search options',
			NOTIFICATION_NO_MORE_CASES_RETRIEVED_HEADER: 'No More Cases Retrieved',
			NOTIFICATION_NO_MORE_CASES_RETRIEVED_BODY: 'No additional cases found matching the search options',
			NOTIFICATION_X_CASES_RETRIEVED: '{{nbr}} Cases Retrieved',
			NOTIFICATION_X_MORE_CASES_RETRIEVED: '{{nbr}} More Cases Retrieved',
			NOTIFICATION_INVALID_CASE_ID: 'Invalid Case ID',
			NOTIFICATION_ERROR_CLEANING_CASE: 'Error cleaning Case',
			NOTIFICATION_CASE_CLEANED_OK: 'Case cleaned'
		},
		caseBrowserModal: {
			HEADER: 'Case Details',
			CLOSE: 'Close',
			CLEAN: 'Radera Case-data',
			CASE_ID: 'Case ID: ',
			SATISFACTION_SCORE: 'Customer Satisfaction',
			TAB_TRANSCRIPT: 'Transcript',
			TAB_OVERVIEW: 'Overview',
			ADVANCED: 'Show visitor navigation',
			SHOW_EVENTS: 'Show conversation events',
			BOOKED_MEETING_ADVANCED_INFO: 'Booked meeting. Click "Advanced" to view the reservation info',
			DATE: 'Date',
			OPENED: 'Opened',
			CLOSED: 'Closed',
			VISITOR_PLATFORM: 'Visitor platform',
			CURRENT_OWNER: 'Current owner',
			OPPORTUNITY: 'Opportunity',
			SOLUTION: 'Solution',
			GROUP: 'Group',
			CASE_TYPE: 'Case type',
			STATE: 'State',
			CLOSURE: 'Closure',
			OUTCOME: 'Outcome',
			PARTICIPANTS: 'Participants',
			CONVERSATIONS: 'Conversations',
			REF_NUMBER: 'refNumber',
			AUTHENTICATION: 'Authentication',

			CASE_NOT_CLOSED: '[Case not closed]',
			TRANSCRIPT_UNKNOWN_USER: '[okänd användare]',
			VISITOR_INFORMATION: 'Besökarinformation',
			VISITOR_IDENTITY_KNOWN: 'Besökaren är identifierad',
			VISITOR_IDENTITY_UNKNOWN: 'Besökaren är inte längre identifierad',
			NAVIGATED_TO: 'Navigerade till:',
			CONVERSATION_EVENT: 'Conversation event:',

			RESERVATION_INFORMATION: 'Reservationsinformation',
			RESERVATION_YES: 'ja',
			RESERVATION_NO: 'nej',
			RESERVATION_GROUP: 'Grupp',
			RESERVATION_START_TIME: 'Starttid',
			RESERVATION_FIRST_NAME: 'Förnamn',
			RESERVATION_LAST_NAME: 'Efternamn',
			RESERVATION_PHONE: 'Telefon',
			RESERVATION_EMAIL: 'E-post',

			CLEAN_CASE_TITLE: 'Radera Case-data',
			CLEAN_CASE_HEADING: 'Vill du verkligen radera Case-data?',
			CLEAN_CASE_DESCRIPTION: 'Denna operation är oåterkallelig, och meddelanden och information tillhörande detta Case kommer att raderas permanent!',
			FILE_SENT: 'Fil'
		}
	},
	'fr-FR': {
		caseBrowser: {
			HEADER: 'Parcourir les dossiers',
			INGRESS: 'Parcourir les dossiers vous permet de rechercher des dossiers spécifiques associés à un agent. Dans la liste des résultats, vous pouvez obtenir la transcription du dialogue et des informations sur la boîte de dialogue.',
			SEARCH_BY_AGENT: 'Recherche par agent',
			SEARCH_BY_CASE_ID: 'Recherche par n° de dossier',
			SEARCH_BY_CUSTOMER_ID: 'Recherche par identifiant client',
			SEARCH_BY_SATISFACTION_SCORE: 'Recherche par satisfaction client',
			BY_AGENT: 'Par l\'agent',
			BY_CUSTOMER_ID: 'Par identifiant client',
			CUSTOMER_ID_TYPE: 'Type d\'identifiant client',
			BY_AGENT_HELP_TOOLTIP: 'Si l\'agent a été retiré du système, il ne sera pas disponible dans le menu déroulant des agents. Vous pouvez rechercher un agent par nom ou par identifiant.',
			FROM: 'Depuis',
			FROM_HELP_TOOLTIP: 'Filtre par heure d\'ouverture des dossiers. L\'heure ne sera utilisée dans la recherche si une date est spécifiée.',
			TO: 'À',
			TO_HELP_TOOLTIP: 'Filtrer par heure d\'ouverture des dossiers. L\'heure ne sera utilisée dans la recherche que si une date est spécifiée.',
			SEARCH_PLACEHOLDER_TEXT: 'Écrivez pour rechercher par nom ou identifiant',
			SEARCH_CUSTOMER_ID_PLACEHOLDER_TEXT: 'Écrivez pour rechercher par identifiant',
			DATEPICKER_TODAY: 'Aujourd\'hui',
			DATEPICKER_CLEAR: 'Effacer',
			DATEPICKER_CLOSE: 'Fermer',
			SORT_ORDER: 'Obtenir le premier',
			SORT_ORDER_HELP_TOOLTIP: 'Dans quel ordre récupérer les résultats de la base de données. « Plus récents » récupérera la plupart des dossiers récents. « Plus anciens » récupérera les dossiers enregistrés en premier.',
			SORT_ORDER_NEWEST: 'Plus récent',
			SORT_ORDER_OLDEST: 'Plus ancien',
			NBR_OF_CASES: 'Nombre de dossiers à récupérer',
			NBR_OF_CASES_HELP_TOOLTIP: 'Nombre de dossiers à récupérer dans la base de données. S\'il en existe plus et que vous souhaitez en charger plus, cliquez sur le bouton « Charger plus de dossiers » au bas de la page',
			CLEAR_FILTER: 'Effacer les filtres',
			GET_CASES: 'Obtenir des dossiers',
			BY_CASE_ID: 'En cas ID',
			BY_SATISFACTION_SCORE: 'Par la satisfaction client',
			INCLUDE_ONGOING_CASES: 'Inclure les cas en cours',

			RESULT: 'Résultat',
			TOTAL_NBR_RETRIEVED: 'Nombre total de cas récupérés:',
			LOAD_X_MORE: 'Charger {{nbr}} dossiers en plus',
			RESULT_COL_NBR: '#',
			RESULT_COL_DATE: 'Date',
			RESULT_COL_OPENED: 'Ouvert',
			RESULT_COL_CLOSED: 'Fermé',
			RESULT_COL_AGENT: 'Agent',
			RESULT_COL_CASE_TYPE: 'Type de dossier',
			RESULT_COL_GROUP: 'Groupe',
			RESULT_COL_CLOSURE: 'Fermeture',
			RESULT_COL_OUTCOME: 'Résultat',
			RESULT_COL_CASE_ID: 'N° de dossier',
			RESULT_COL_SATISFACTION_SCORE: 'But',

			CASE_STATE_NEW: 'Nouveau',
			CASE_STATE_ONGOING: 'En cours',
			CASE_STATE_CLOSED: 'Fermé',
			CASE_OUTCOME_POSITIVE: 'Positif',
			CASE_OUTCOME_NEUTRAL: 'Neutre',
			CASE_OUTCOME_NEGATIVE: 'Négatif',

			NOTIFICATION_NO_CASES_RETRIEVED_HEADER: 'Aucun dossier récupéré',
			NOTIFICATION_NO_CASES_RETRIEVED_BODY: 'Aucun dossier ne correspond aux options de recherche',
			NOTIFICATION_NO_MORE_CASES_RETRIEVED_HEADER: 'Aucun dossier supplémentaire récupéré',
			NOTIFICATION_NO_MORE_CASES_RETRIEVED_BODY: 'Aucun autre dossier ne correspond aux options de recherche',
			NOTIFICATION_X_CASES_RETRIEVED: '{{nbr}} dossier(s) récupéré(s)',
			NOTIFICATION_X_MORE_CASES_RETRIEVED: '{{nbr}} dossier(s) supplémentaire (s) récupéré(s)',
			NOTIFICATION_INVALID_CASE_ID: 'Numéro de dossier non valide',
			NOTIFICATION_ERROR_CLEANING_CASE: 'Erreur de nettoyage dossier', // '***' Key translated by Vergic (google translate)!
			NOTIFICATION_CASE_CLEANED_OK: 'Dossier nettoyé' // '***' Key translated by Vergic (google translate)!
		},
		caseBrowserModal: {
			HEADER: 'Informations sur le dossier',
			CLOSE: 'Fermer',
			CLEAN: 'Nettoyer', // '***' Key translated by Vergic (google translate)!
			CASE_ID: 'N° de dossier: ',
			TAB_TRANSCRIPT: 'Transcription',
			TAB_OVERVIEW: 'Vue d\'ensemble',
			ADVANCED: 'Afficher la navigation des visiteurs',
			SHOW_EVENTS: 'Show conversation events',
			BOOKED_MEETING_ADVANCED_INFO: 'Réunion réservée. Cliquez sur « Avancé » pour afficher les informations de réservation',
			DATE: 'Date',
			OPENED: 'Ouvert',
			CLOSED: 'Fermé',
			VISITOR_PLATFORM: 'plateforme visiteurs',
			CURRENT_OWNER: 'Propriétaire actuel',
			OPPORTUNITY: 'Opportunité',
			SOLUTION: 'Solution',
			GROUP: 'Groupe',
			CASE_TYPE: 'Type de dossier',
			STATE: 'Etat',
			CLOSURE: 'Fermeture',
			OUTCOME: 'Résultat',
			SATISFACTION_SCORE: 'Customer Satisfaction',
			PARTICIPANTS: 'Participants',
			CONVERSATIONS: 'Conversations',
			REF_NUMBER: 'Numéro de réf',
			AUTHENTICATION: 'Authentification',

			CASE_NOT_CLOSED: '[Dossier non clos]',
			TRANSCRIPT_UNKNOWN_USER: '[utilisateur inconnu]',
			VISITOR_INFORMATION: 'Informations visiteurs',
			VISITOR_IDENTITY_KNOWN: 'le visiteur est identifié',
			VISITOR_IDENTITY_UNKNOWN: 'Le visiteur n\'est plus identifié',
			NAVIGATED_TO: 'Navigation jusqu\'à :',
			CONVERSATION_EVENT: 'Conversation event:',

			RESERVATION_INFORMATION: 'Informations sur la réservation',
			RESERVATION_YES: 'oui',
			RESERVATION_NO: 'non',
			RESERVATION_GROUP: 'Groupe',
			RESERVATION_START_TIME: 'Heure de début',
			RESERVATION_FIRST_NAME: 'Prénom',
			RESERVATION_LAST_NAME: 'Nom de famille',
			RESERVATION_PHONE: 'N° de téléphone',
			RESERVATION_EMAIL: 'Adresse e-mail',

			// '***' Key below translated by Vergic (google translate)!
			CLEAN_CASE_TITLE: 'Supprimer les données de dossier',
			CLEAN_CASE_HEADING: 'Voulez-vous vraiment nettoyer cette dossier ?',
			CLEAN_CASE_DESCRIPTION: 'Cette opération ne peut pas être annulée, et la transcription et les propriétés du dossier seront supprimées définitivement !',
			FILE_SENT: 'Fichier'
		}
	}
};
