import VisitorHandlerCmds from './VisitorHandlerCmds';

export default {
	visitors: {},
	quarantineClosed: {},
	update: function (visitors) {

		this.checkifVisitorIsRemoved(visitors);

		for (var v in visitors) {
			if (visitors.hasOwnProperty(v)) {

				if (typeof visitors[v].identification === 'object') {
					visitors[v].namedIdentity = visitors[v].identification.namedIdentity;
					visitors[v].identityClaims = visitors[v].identification.claims;
					// delete the identification object, since it would trigger change events on every heartbeat otherwise.
					// desktop/visitors/visitor.js > updateData()
					delete visitors[v].identification;
				}

				if (typeof this.quarantineClosed[v] !== 'undefined') {
					delete visitors[v];
					continue;
				}

				if (typeof this.visitors[v] === 'undefined') {
					try {
						this.visitors[v] = new psPlugin.application.visitor(visitors[v]);
						this.visitors[v].commands.getNavigationHistory.execute(this.visitors[v].properties.vId);
						psPlugin.application.events.onVisitorLoaded.trigger(this.visitors[v]);

					} catch (err) {
						console.error(err);
					}
				} else {
					this.visitors[v].updateData(visitors[v]);

				}
			}
		}
		this.quarantineClosed = {};
		this.listsUpdater();
	},
	selectedfromQueue: {},
	listsUpdater: function () {
		var visitor,
			visitorsInQueues = [],
			proactives = [],
			lockedVisitors = [];

		for (var v in this.visitors) {
			if (this.visitors.hasOwnProperty(v)) {
				visitor = this.visitors[v].properties;

				//console.log(JSON.stringify(visitor));
				//if (visitor.isTransferred === 'true') {
				//
				//	if (visitor.previousGroupId !== visitor.toGroup) {
				//		var lang = psPlugin.application.configuration.desktop.languages[visitor.langId.toLowerCase()],
				//			fromGroupId = visitor.previousGroupId.toLowerCase(),
				//			toGroupId = visitor.toGroup.toLowerCase(),
				//			fromGroup = lang.groups[fromGroupId],
				//			toGroup = lang.groups[toGroupId];
				//
				//		if (fromGroupId !== toGroupId) {
				//			console.log('visitor was transferred from ', fromGroup.name, 'to', toGroup.name);
				//		} else {
				//			console.log('visitor was transferred back to ', toGroup.name);
				//		}
				//	}
				//
				//}

				switch (visitor.memberOf) {
					case 'visitorsInQueues':
						if (typeof this.selectedfromQueue[v] === 'undefined') {
							visitorsInQueues.push(v);
						}
						break;
					case 'proactives':
						if (typeof this.selectedfromQueue[v] === 'undefined') {
							//TODO: check if we should use bool here
							if (visitor.lostDialog === true) {
								visitorsInQueues.push(v);
							} else {
								proactives.push(v);
							}
						}
						break;
					case 'lockedVisitors':
						lockedVisitors.push(v);
						break;
				}
			}
		}
		this.selectedfromQueue = {};
		psPlugin.application.events.onQueueList.trigger(visitorsInQueues);
		psPlugin.application.events.onProactiveList.trigger(proactives);
		psPlugin.application.events.onLockedList.trigger(lockedVisitors);
	},

	checkifVisitorIsRemoved: function (visitors) {
		for (var d in this.visitors) {
			if (typeof visitors[d] === 'undefined') {

				//console.log('visitor', d, 'is removed...', this.visitors[d])

				if (this.visitors[d].properties.memberOf === 'visitorsInQueues' ||
					this.visitors[d].properties.memberOf === 'proactives'
				) {

					if (this.visitors[d].coWorker) {
						try {
							//this.visitors[d].close();
							this.visitors[d].onClose();

						} catch (err) {
							console.error('cannot close coWorker.panel:', err);
						}
					} else {

					}
					//console.log('Visitor: ' + d + ' has left the queue, or lost connection');
					this.visitors[d].close();
					//this.visitors[d].coWorker.panel.close();


				} else {


					if (this.visitors[d].stateMachine.currentState === 'Locked') {

						if (this.visitors[d].properties.initialState === 'Queued') {
							//console.log('Visitor: ' + d + ' (locked) has left the queue.', this.visitors[d])
							this.visitors[d].close();
							return;
						}
						try {
							this.visitors[d].coWorker.onRejected();
						} catch (err) {
							console.error('onRejected() failed for visitor:', d, '...', this.visitors);
						}
					} else {
						//console.log('Chat-routing timeout ... close panel.');
						if (this.visitors[d]) {
							if(this.visitors[d].coWorker && this.visitors[d].coWorker.panel){
								this.visitors[d].coWorker.panel.close();
							}
						}

						if (this.visitors[d] && typeof this.visitors[d].close === 'function'){
							this.visitors[d].close();
						}

					}
				}
			}
		}
	},
	remove: function () {
		console.log('remove called');
	},
	commands: VisitorHandlerCmds
};
