import _find from 'lodash/find';
import SystemSettingAddModalTemplate from './SystemSettingAddModalTemplate';

export default function ($uibModal, APIEndpointService, $state, settingList, accountList) {
	'ngInject';

	var vm = this;
	vm.settingList = settingList;

	vm.table = {
		columns: [
			{
				header: 'Key',
				property: 'data.key',
				sortDefault: true,
				useInFilter: true
			},
			{
				header: 'GUID',
				property: 'data.id',
				hidden: true,
				useInFilter: true
			},
			{
				header: 'Account',
				property: 'data.accountId',
				useInFilter: true
			},
			{
				header: 'Account name',
				property: 'data.accountId',
				transform: function (accountId) {
					var foundAccount = _find(accountList, function (account) {
						return account.data.id === accountId;
					});
					return foundAccount ? foundAccount.data.displayName: '';
				}
			},
			{
				header: 'Value',
				property: 'data.value',
				useInFilter: true
			}
		],
		options: {
			stateLink: {
				state: 'root.system.setting.edit',
				property: 'data.id'
			},
			createItem: {
				langKey: 'systemSetting.ADD_HEADER',
				method: addNew
			}
		}
	};

	function openAddModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: SystemSettingAddModalTemplate,
			controller: 'SystemSettingAddModalController as vm'
		});
		modal.result.then(newSetting => {
			vm.settingList.push(newSetting);
			$state.go('.edit', {id: newSetting.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		$state.go('root.system.setting').then(openAddModal);
	}
}
