export default {
	name: 'Default settings',
	section: {
		defaultSiteId: null,
		blockVisitorDays: 1,
		dialogQuarantine: 15,
		doNotAskAgainQuarantine: 30,
		domCoBrowsing: false,
		enableHTTPSCoBrowsing: false,
		enablePassiveCoBrowsing: false,
		handleHttpMethod: 'ignore',
		iframeVisitorDuringChat: false,
		excludePagesFromCoBrowsingStart: [],
		shortQuarantine: 2,
		useProtocolRelativeUrls: false,
		useResponsiveBanners: false,
		videoStreamingServer: '',
		transportDomains: null,
		visitorProblemLoggingEnabled: false,
		visitorProblemLoggingTypes: '',
		streamingConversations: false,
		pollConversationsWhileStreaming: false,
		wcag: false,
		httpsOnly: true,
		reEvaluateAfterChat: true
	}
};
