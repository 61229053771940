import React from 'react';
import {useGetTranslation} from './../../LangContext';

const AddressBarItem = (props) => {
	const getTranslation = useGetTranslation();

	const navigateToUrl = (url) => {
		if (url !== '') {
			props.updateCoBroUrl(url, 'agent');
		}
	};

	let owner = '';
	let url = props.url;
	let tag;

	switch (props.owner) {
		case 'agent':
			owner = getTranslation('roleAgent') + ':';
			break;

		case 'visitor':
			owner = getTranslation('roleVisitor') + ':';
			break;

		case 'domUpload':
			owner = <span><i className="vngage-icon-line-download"/> {getTranslation('domUpload')}</span>;
			url = '';
			break;

		default:
			break;
	}

	const pausedTag = (
		<span className="co-status deactivated">
			<i className="vngage-icon-pause"/>
			<span>{getTranslation('coStatusPaused')}</span>
		</span>
	);

	const doNotDisturbTag = (
		<span className="co-status view-only">
			<i className="vngage-icon-eye-1"/>
			<span>{getTranslation('coStatusDoNotDisturb')}</span>
		</span>
	);

	const restrictedTag = (
		<span className="co-status restricted">
			<i className="vngage-icon-block-1"/>
			<span>{getTranslation('coStatusRestricted')}</span>
		</span>
	);

	const isBlockedTag = (
		<span className="co-status blocked">
			<i className="vngage-icon-block-1"/>
			<span>{getTranslation('coStatusIsBlocked')}</span>
		</span>
	);

	if (props.paused) {
		tag = pausedTag;
	} else if (props.isBlocked) {
		tag = isBlockedTag;
	} else if (props.doNotDisturb) {
		tag = doNotDisturbTag;
	} else if (props.restricted) {
		tag = restrictedTag;
	}

	const disabled = props.connectionStatus !== 'ok' || props.isBlocked ? 'disabled': '';
	const cls = `item lineClampEllipsis1LineExtraLong ${disabled}`;

	return (
		<div
			id="white-label-cobrowser-menu-item"
			className={cls}
			onClick={() => navigateToUrl(url)}
			ub-track="cobro-addressbar:url-selected">
			<span ub-track="cobrowsing:address-menu-url-selected">
				{tag}
				<strong
					style={{fontSize: '13px', marginLeft: '2px'}}
					ub-track="cobro-addressbar:url-selected">{owner}</strong> {url}
			</span>
		</div>
	);
};
export default AddressBarItem;

