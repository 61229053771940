import immutable from 'immutable';
import {filters} from './filters';
import FlowStates from '../../constants/FlowStates';
import Routing from '../../utils/Routing';
import Format from '../../utils/Format';
import ConversationSelectors from '../../selectors/ConversationSelectors';
import RoutingTypes from '../../constants/RoutingTypes';

const panelHeaderColors = ['#AB3192', '#64BD6B', '#00B7C9', '#E0BC72', '#575757', '#F04E5E', '#FFCB05', '#D2612A'];

function updateUserTyping(state, conversationId) {
	let activities = state.getIn(['conversations', conversationId, 'activities']).reverse();
	if (activities.size === 0) {
		return state.setIn(['conversations', conversationId, 'UI', 'userIsTyping'], false);
	}
	const activity = activities.find((v, k) => v.get('activity') === 'writing' && v.has('change') && v.hasIn(['speaker', 'visitId']));
	if (!activity) {
		return state.setIn(['conversations', conversationId, 'UI', 'userIsTyping'], false);
	} else {
		return state.setIn(['conversations', conversationId, 'UI', 'userIsTyping'], activity.get('change') === 'set');
	}

}

function markMessagesAsRead(state, conversationId) {
	if (state.hasIn(['conversations', conversationId, 'messages'])) {
		const readMessages = state.getIn(['conversations', conversationId, 'messages']).map(msg => msg.set('isRead', true));
		state = state.setIn(['conversations', conversationId, 'messages'], readMessages);
		state = state.setIn(['conversations', conversationId, 'UI', 'unreadMessages'], 0);
		return state;
	} else {
		return state;
	}
}

function updateNrOfUnreadMessages(state, conversationId) {
	const me = state.getIn(['conversations', conversationId, 'me']) || 'none';
	const nrOfRead = state.getIn(['conversations', conversationId, 'messages'])
		.filter(msg => {
			return msg.get('messageType') === 'chat'
				&& msg.getIn(['speaker', 'userId']) !== me
				&& msg.get('isRead') !== true;
		}).size;
	return state.setIn(['conversations', conversationId, 'UI', 'unreadMessages'], nrOfRead);
}

function setOldestMessageRanking(state) {
	let entries = state.get('conversations').map((conversation, conversationId) => {
		let createdRaw = 0;
		let returnObject = immutable.fromJS({
			conversationId,
			createdRaw
		});

		if (!conversation.has('messages')) {
			// not ready, skip
			return returnObject;
		}

		const messages = conversation.get('messages').reverse();
		let message = returnObject;
		messages.find((msg, key) => {
			if (msg.get('isRead') && msg.get('messageType') === 'chat') {
				return true;
			} else {
				message = msg;
				return false;
			}
		});
		returnObject = returnObject.set('createdRaw', message.get('createdRaw'));
		return returnObject;
	});
	entries = entries.sort((v0, v1) => v0.get('createdRaw') - v1.get('createdRaw'));
	let rankCounter = 0;
	entries.forEach(entry => {
		const rank = entry.get('createdRaw') === 0 ? 8: rankCounter++;
		state = state.setIn(['conversations', entry.get('conversationId'), 'UI', 'oldestMessageRanking'], rank);
	});
	return state;
}

function addPropsToMessage(message, conversation, extraProps = {}) {
	const messageProps = filters.identifier.messages(message)
		? {
			speaker: {
				...message.speaker,
				...ConversationSelectors.getSpeakerProps(conversation, message),
			}
		}
		: {};
	return {
		...message,
		createdRaw: getTime(message.createdAt),
		...messageProps,
		...extraProps
	};
}

function addRequiredPropsToMessages(rawMessages, conversation, extraProps = {}) {
	return rawMessages.map(message => addPropsToMessage(message, conversation, extraProps));
}

function addRequiredPropsToConversationState(conversationState, extraProps = {}) {
	return {
		...conversationState,
		events: addCreatedRaw(conversationState.events || []),
		messages: addRequiredPropsToMessages(conversationState.messages, conversationState, extraProps),
		participants: addCreatedRaw(conversationState.participants),
	};
}

function updateLocalMessages(state, action) {
	const {conversationId, rawMessages} = action;
	const idsToRemove = rawMessages
		.filter(msg => (msg.uploadInfo && msg.uploadInfo.tag) || (msg.tags && msg.tags.length > 0))
		.map(msg => {
			return msg.uploadInfo && msg.uploadInfo.tag
				? msg.uploadInfo.tag
				: msg.tags[0];
		});

	const localMessages = (state.getIn(['conversations', conversationId, 'localMessages']) || immutable.List())
		.filter(message => idsToRemove.indexOf(message.get('id')) === -1);
	state = state.setIn(['conversations', conversationId, 'localMessages'], localMessages);
	return state;
}

function addHistoryMarkers(state, conversationId, caseHistory) {
	const historyItems = caseHistory.map(caseItem => Format.asHistoryMarker(caseItem.data.id, caseItem.data.createdAt, getTime(caseItem.data.createdAt)));
	state = insertLocalMessages(state, conversationId, historyItems, true, true);
	return state;
}

function expandHistoryMarker(state, conversationId, markerId, caseData) {
	// remove marker
	state = removeLocalMessage(state, conversationId, markerId);
	// join all messages in all conversations and add needed props
	const mappedConversations = caseData.data.conversations.map(conversation => addRequiredPropsToConversationState(conversation, {status: 'received'}));
	const messages = mappedConversations.reduce((messages, conversation) => messages.concat(conversation.messages), []);

	// change timestamp of visitorProfile message if necessary
	const updatedTimestamp = messages[messages.length - 1].createdRaw + 1;
	const messageIndex = state.getIn(['conversations', conversationId, 'localMessages']).findIndex(msg => msg.get('messageType') === 'visitorProfile');
	if (messageIndex >= 0) {
		let msg = state.getIn(['conversations', conversationId, 'localMessages', messageIndex]);
		msg = msg.get('createdRaw') < updatedTimestamp
			? msg.set('createdRaw', updatedTimestamp).setIn(['tags', 0], updatedTimestamp)
			: msg;
		state = state.setIn(['conversations', conversationId, 'localMessages', messageIndex], msg);
	}

	// set divider
	const startTime = messages[0].createdRaw - 1;
	const dividerStart = Format.asDivider(startTime, '');
	// insert messages and divider
	state = insertLocalMessages(state, conversationId, [dividerStart, ...messages], true, true);
	return state;
}

function insertLocalMessages(state, conversationId, messages, keepTimeStamp = false, keepSpeaker = false) {
	const lastTimeStamp = ConversationSelectors.getLastMessageTimeStamp(state, conversationId);
	const lastLocalTimeStamp = ConversationSelectors.getLastLocalMessageTimeStamp(state, conversationId);
	const lastCreatedRaw = Math.max(lastLocalTimeStamp, lastTimeStamp);
	const me = state.getIn(['conversations', conversationId, 'me']);
	const conversation = state.getIn(['conversations', conversationId]);
	const agentSpeaker = ConversationSelectors.getSpeakerProps(conversation, {speaker: {userId: me}});

	// set properties for messages
	const mappedMessages = messages.map((message, index) => {
		const createdRaw = keepTimeStamp
			? message.createdRaw
			: lastCreatedRaw + index + 1;
		const speaker = keepSpeaker
			? message.speaker
			: agentSpeaker;
		return {
			...message,
			id: message.tags.length > 0 ? message.tags[0]: createdRaw,
			createdRaw,
			speaker,
			isRead: true,
			// status?
		};
	});

	// merge new messages with current localMessages
	let list = immutable.List();
	let localMessages = state.getIn(['conversations', conversationId, 'localMessages']) || immutable.List();
	const totalSize = mappedMessages.length + localMessages.size;
	for (let i = 0; i < totalSize; i++) {
		const firstMappedMessage = mappedMessages[0] || {createdRaw: Infinity};
		const firstLocalMessage = localMessages.first() || immutable.Map({createdRaw: Infinity});
		if (firstMappedMessage.createdRaw < firstLocalMessage.get('createdRaw')) {
			// add from mappedMessages
			list = list.push(immutable.fromJS(firstMappedMessage));
			mappedMessages.shift();
		} else {
			// add from local
			list = list.push(firstLocalMessage);
			localMessages = localMessages.shift();
		}
	}
	state = state.setIn(['conversations', conversationId, 'localMessages'], list);
	return state;
}

function removeLocalMessage(state, conversationId, id) {
	const index = state.getIn(['conversations', conversationId, 'localMessages']).findIndex(msg => msg.get('id') === id);
	state = state.deleteIn(['conversations', conversationId, 'localMessages', index]);
	return state;
}

function addNewConversation(state, conversationId, jsConversation) {
	const conversation = immutable.fromJS({
		conversationStatus: 'none',
		groupId: 'none',
		flowState: FlowStates.NEW,
		UI: {panelPosition: 'normal'},
		events: [],
		messages: [],
		activities: [],
		localMessages: [],
		...jsConversation
	});
	state = state.setIn(['conversations', conversationId], conversation);
	state = updatePanelOrder(state);
	return state;
}

function manageActiveConversations(state, details) {
	const conversationIds = details.map(detail => detail.id);
	// pending conversations that have been removed, timed out. will set agent to away
	const pendingConversationRemoved = state.get('conversations').some((conversation, conversationId) =>
		!conversationIds.includes(conversationId)
		&& conversation.get('routingType') !== RoutingTypes.EXTERNAL_STARTED
		&& (conversation.get('conversationStatus') === 'pendingStart' || conversation.get('conversationStatus') === 'suspended')
		&& conversation.get('flowState') === FlowStates.WAITING_FOR_USER_START);

	// filter out conversations that should be removed
	let filteredConversations = state.get('conversations').filter((conversation, id) => {
		const conversationExists = conversationIds.indexOf(id) !== -1;
		const flowState = conversation.get('flowState');
		const keep = flowState !== FlowStates.REMOVING && flowState !== FlowStates.WAITING_FOR_USER_START;
		return conversationExists || keep;
	});

	// update conversationStatus for existing conversations
	details.filter(detail => filteredConversations.has(detail.id))
		.forEach(detail => {
			filteredConversations = filteredConversations.setIn([detail.id, 'conversationStatus'], detail.status);
		});


	// subtract active conversations from maxConversationsPerGroup
	let groupIdMaxValues = filteredConversations
		.reduce((maxValues, conversation) => maxValues.update(conversation.get('groupId'), n => n - 1)
			, state.get('maxConversationsPerGroup'));

	const newConversations = details.filter(detail => !filteredConversations.has(detail.id));
	const excessConversations = [];

	newConversations.forEach(newConv => {
		const groupId = newConv.groupId;
		const conversationStatus = newConv.status;
		const routingType = state.getIn(['groupRouting', groupId]) || 0;
		// only count non pending, non external, non manual conversations

		// [TL] Also allow conversations with status 'pendingStart' if the group is manually routed!
		// This can *NORMALLY* not happen for a manually routed group, but it *MAY* still happen
		// for dialogs routed from a start-code (Phone2Web).
		// These dialog will appear as auto-routed (status = 'pendingStart'), even for a manually routed group.
		// And since the default value of the group setting "maxInteractionsPerUser" is 0 (and the setting is hidden in the UI when configuring a manual group),
		// all P2W-dialogs would bounce back to queue (as "excessConversations") by default if the group is manually routed and "maxInteractionsPerUser" is not changed...
		// See https://support.psplugin.com/issue/VEP-3991 for details
		if (groupIdMaxValues.get(groupId) > 0 || newConv.status !== 'pendingStart' || Routing.isExternal(routingType) || Routing.isManual(routingType)) {
			// ok to add, not maxed out
			filteredConversations = filteredConversations.set(newConv.id, immutable.fromJS({
				groupId,
				routingType,
				conversationStatus,
				flowState: FlowStates.NEW,
				events: [],
				messages: [],
				activities: [],
				localMessages: [],
			}));
			groupIdMaxValues = groupIdMaxValues.update(groupId, n => n - 1);
		} else {
			// maxed out, remove this one
			excessConversations.push(newConv.id);
		}
	});

	state = state.set('excessConversations', immutable.fromJS(excessConversations));
	state = state.set('conversations', filteredConversations);
	state = updatePanelOrder(state);

	// prepare to presence to be set to away if pending conversation was removed from agent
	state = state.set('presenceForcedAway', pendingConversationRemoved);

	return state;
}

function updatePanelOrder(state) {
	const conversations = state.get('conversations');
	let filteredPanels = state.get('panelOrder').filter((conversationId) => conversations.has(conversationId));
	const filteredPanelsSize = filteredPanels.size;
	const conversationsRemoved = state.get('panelOrder').size - filteredPanelsSize;
	conversations.forEach((conversation, conversationId) => {
		const flowState = conversation.get('flowState');
		if (!filteredPanels.includes(conversationId) && flowState) {
			filteredPanels = filteredPanels.unshift(conversationId);
		}
	});
	const conversationsAdded = filteredPanels.size - filteredPanelsSize;
	return (conversationsRemoved > 0 || conversationsAdded > 0)
		? state.set('panelOrder', filteredPanels)
		: state;
}

function updateParticipants(state, conversationId, rawMessages) {
	rawMessages.forEach(msg => {
		const joined = msg.type === 'participantJoined';
		const left = msg.type === 'participantLeft';
		if (joined || left) {
			const id = msg.participant.visitId || msg.participant.userId;
			const newState = joined
				? 'joined'
				: 'left';

			const updateWith = {
				state: newState,
				info: msg.info,
				...msg.participant
			};
			state = state.setIn(['conversations', conversationId, 'participants', id], immutable.fromJS(updateWith));
		}
	});
	return state;
}

function isAgentAloneInConversation(state, conversationId) {
	const agentHaJoined = hasAgentJoined(state, conversationId);
	// if agent has not joined start count from 1
	const startValue = !agentHaJoined ? 1: 0;
	const participants = state.getIn(['conversations', conversationId, 'participants']);
	return participants && participants.size > 0
		? participants.reduce((count, participant) => count + (participant.get('state') === 'joined' ? 1: 0), startValue) === 1
		: false;
}

function hasVisitorEverJoined(state, conversationId) {
	// Use same logic to detect if any visitor has ever joined as when calculating visitorProfilePayload.visitorJoined in Panel.react.js
	return !!(state.getIn(['conversations', conversationId, 'visitId']));
}

// tested in setOldestMessageRanking
function addCreatedRaw(items) {
	return items.map(item => ({...item, createdRaw: getTime(item.createdAt)}));
}

// tested in setOldestMessageRanking
function addCreatedRawToConversationState(conversationState) {
	return {
		...conversationState,
		events: addCreatedRaw(conversationState.events),
		messages: addCreatedRaw(conversationState.messages),
		participants: addCreatedRaw(conversationState.participants),
	};
}

function getTime(dateStr) {
	return new Date(dateStr).getTime();
}

// tested in RECEIVE_AS_STATE
function getNewPanelHeaderColor(state) {
	const usedColors = state.get('conversations').map(entry => entry.getIn(['UI', 'panelHeaderColor']));
	let filtered = panelHeaderColors.filter(color => !usedColors.includes(color));
	let offset = state.get('nbrOfDialogsSoFar') % filtered.length;
	if (filtered.length > 0) {
		return filtered[offset];
	} else {
		// 9th color and up
		const pos = state.get('panelOrder').size - 1;
		const conversationToGetFrom = state.getIn(['panelOrder', pos]);
		const excludeColor = state.getIn(['conversations', conversationToGetFrom, 'UI', 'panelHeaderColor']);

		filtered = panelHeaderColors.filter(color => color !== excludeColor);
		offset = state.get('nbrOfDialogsSoFar') % filtered.length;
		return filtered[offset];

	}
}

function getCurrentVisitId(participants) {
	const visitor = filters.getLastFoundInArray(filters.identifier.visitor, participants);
	return visitor ? visitor.visitId: null;
}

function getConnectionStatus(events) {
	return filters.getLastValid(filters.identifier.connectionStatus, events, '');
}

function updateConnectionStatus(state, conversationId) {
	const agentIsAlone = isAgentAloneInConversation(state, conversationId);
	const visitorHasJoined = hasVisitorEverJoined(state, conversationId);
	return state.updateIn(['conversations', conversationId, 'connectionStatus'], status => {
		return agentIsAlone
			? visitorHasJoined
				? 'terminate' // agent is alone after visitor has left
				: 'ok'	// agent is alone before any visitor has joined
			: status === 'terminate'
				? 'ok' // agent is not alone anymore
				: status; // nothing has changed, keep previous status
	});
}

function getConversation(state, conversationId) {
	return state.getIn(['conversations', conversationId]);
}

function getMessages(state, conversationId) {
	return state.getIn(['conversations', conversationId, 'messages']);
}

function getAgentParticipantState(state, conversationId) {
	const id = state.getIn(['conversations', conversationId, 'me']);
	return state.getIn(['conversations', conversationId, 'participants', id, 'state']) || 'none';
}

function hasAgentJoined(state, conversationId) {
	return getAgentParticipantState(state, conversationId) === 'joined';
}

function getActiveSection(conversation) {
	return conversation.getIn(['UI', 'activeSection']);
}

function updateCase(state, conversationId, rawMessages) {
	const currentCaseId = state.getIn(['conversations', conversationId, 'caseId']);
	const caseMsg = filters.getLastFoundInArray(filters.identifier.caseId, rawMessages);
	return caseMsg && caseMsg.caseId && caseMsg.caseId !== currentCaseId
		? state = state.setIn(['conversations', conversationId, 'caseId'], caseMsg.caseId)
		: state;
}

// tested in UPDATE_LOCAL_MESSAGE_STATUS-spec
function updateLocalMessageStatus(state, conversationId, messageId, status) {
	const messageIndex = state.getIn(['conversations', conversationId, 'localMessages'])
		.findIndex(message => message.getIn(['uploadInfo', 'tag']) === messageId || message.getIn(['tags', 0]) === messageId);
	return messageIndex > -1
		? state = state.setIn(['conversations', conversationId, 'localMessages', messageIndex, 'status'], status)
		: state;
}

export const conversationReducerLib = {
	updateUserTyping,
	markMessagesAsRead,
	updateNrOfUnreadMessages,
	setOldestMessageRanking,
	addPropsToMessage,
	addRequiredPropsToMessages,
	addRequiredPropsToConversationState,
	updateLocalMessages,
	addHistoryMarkers,
	expandHistoryMarker,
	insertLocalMessages,
	removeLocalMessage,
	addNewConversation,
	manageActiveConversations,
	updatePanelOrder,
	updateParticipants,
	isAgentAloneInConversation,
	hasVisitorEverJoined,
	addCreatedRaw,
	addCreatedRawToConversationState,
	getTime,
	getNewPanelHeaderColor,
	getCurrentVisitId,
	getConnectionStatus,
	updateConnectionStatus,
	getConversation,
	getMessages,
	getAgentParticipantState,
	hasAgentJoined,
	getActiveSection,
	updateCase,
	updateLocalMessageStatus,
};
