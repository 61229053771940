export default function (account, accountList, $state, VngageUtil, $stateParams) {
	'ngInject';
	var vm = this;
	vm.account = angular.copy(account); // Use a copy of the data for the form fields, otherwise the item in accountList will be changed on edit + cancel
	vm.accountList = accountList;
	vm.currentList = $stateParams.currentList || [];

	vm.submit = function () {
		vm.isSaving = true;
		vm.account.$save().then(updatedAccount => {
			vm.isSaving = false;

			// Update corresponding element in "accountList" to ensure the list reflects any updated changes on this account (i.e. name and changed "tags" that is visible in the list)
			VngageUtil.replaceResource(vm.accountList, updatedAccount).finally(() => {
				// transition to the same state, but with updated state params (account) to transfer the updated properties
				$state.transitionTo($state.current, {
					id: updatedAccount.data.id,
					account: updatedAccount
				});
			});
		}, err => {
			console.log('Error saving details, err:', err);
			vm.isSaving = false;
		});
	};
}
