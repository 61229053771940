import immutable from 'immutable';
import AccountConfig from '../../utils/AccountConfig';
import helpers from '../../utils/plugin/PluginSubscriptionHelpers';

const subscriptions = {

	// oneTimeSubscriptions
	// Maybe
	desktopInfo(plugin, prevState, state, reset) {
		if (!reset) {
			return immutable.List();
		}
	},

	// Maybe
	agentInfo(plugin, prevState, state, reset) {
		if (!reset) {
			return immutable.List();
		}

	},

	visitorClaims(plugin, prevState, state, reset) {
		const prevLastUpdatedProfile = prevState.getIn(['pluginData', 'lastUpdatedProfile']);
		const lastUpdatedProfile = state.getIn(['pluginData', 'lastUpdatedProfile']);
		if (reset) {
			// get all profiles
			return helpers.getConversationIds(plugin, state)
				.filter(conversationId => {
					const conversation = state.getIn(['conversations', conversationId]);
					return state.hasIn(['visitorProfiles', conversation.get('visitId')]);
				})
				.map(conversationId => {
					const conversation = state.getIn(['conversations', conversationId]);
					const visitId = conversation.get('visitId');
					const profile = state.getIn(['visitorProfiles', visitId]);
					const claims = helpers.getVisitorProfileClaims(profile);
					return immutable.fromJS({
						type: 'visitorClaims',
						conversationId,
						visitId,
						claims: claims
					})
				});

		} else if (immutable.is(prevLastUpdatedProfile, lastUpdatedProfile)) {
			// no change no subscriptions
			return immutable.List();
		} else {
			const conversationIds = helpers.getConversationIds(plugin, state);
			const conversationId = lastUpdatedProfile.get('conversationId');
			const visitId = lastUpdatedProfile.get('visitId');
			const rightType = lastUpdatedProfile.get('type') === 'add';
			const okConversationId = conversationIds.contains(conversationId);
			const okVisitId = state.hasIn(['visitorProfiles', visitId]);
			if (rightType && okConversationId && okVisitId) {
				const profile = state.getIn(['visitorProfiles', visitId]);
				const claims = helpers.getVisitorProfileClaims(profile);
				return immutable.fromJS([{
					type: 'visitorClaims',
					conversationId,
					visitId,
					claims: claims
				}]);

			} else {
				return immutable.List();
			}
		}
	},

	agentGroups(plugin, prevState, state, reset) {
		if (!reset) {
			return immutable.List();
		}
		return immutable.fromJS([{
			type: 'agentGroups',
			groups: AccountConfig.getAgentGroups()
		}]);


	},

	agentActiveGroups(plugin, prevState, state, reset) {
		if (immutable.is(prevState.get('myGroupAvailability'), state.get('myGroupAvailability')) && !reset) {
			return immutable.List();
		}
		return immutable.fromJS([{
			type: 'agentActiveGroups',
			groups: state.get('myGroupAvailability')
		}]);

	},

	caseInfo(plugin, prevState, state) {
		const prevConversationIds = helpers.getOpenConversationIds(plugin, prevState);
		const conversationIds = helpers.getOpenConversationIds(plugin, state);
		return conversationIds.filter(conversationId => {
			if (prevConversationIds.contains(conversationId)) {
				// already existing conversation, check case diff
				const conversation = state.getIn(['conversations', conversationId]);
				const oldConversation = prevState.getIn(['conversations', conversationId]);
				return conversation.get('caseId') !== oldConversation.get('caseId') || conversation.get('currentCaseType') !== oldConversation.get('currentCaseType');
			} else {
				// new conversation
				return true;
			}
		}).map(conversationId => {
			const conversation = state.getIn(['conversations', conversationId]);
			return immutable.fromJS({
				type: 'caseInfo',
				conversationId,
				caseId: conversation.get('caseId'),
				caseTypeId: conversation.get('currentCaseType'),
				solutionId: conversation.get('solutionId'),
				opportunityId: conversation.get('opportunityId'),
			})
		});
	},

	conversationOpened(plugin, prevState, state) {
		const prevConversationIds = helpers.getOpenConversationIds(plugin, prevState);
		const conversationIds = helpers.getOpenConversationIds(plugin, state);
		if (immutable.is(prevConversationIds, conversationIds)) {
			return immutable.List();
		}
		const newConversationIds = conversationIds.filter(conversationId => !prevConversationIds.contains(conversationId));
		if (newConversationIds.size === 0) {
			return immutable.List();
		}
		return newConversationIds.map(conversationId => {
			const conversation = state.getIn(['conversations', conversationId]);
			return immutable.Map({
				type: 'conversationOpened',
				conversationId,
				panelColor: conversation.getIn(['UI', 'panelHeaderColor']) || '#C0C4C1',
				groupId: conversation.get('groupId'),
				connectionStatus: conversation.get('connectionStatus'),
				conversationStatus: conversation.get('conversationStatus'),
				visitId: conversation.get('visitId')
			});
		});
	},

	conversationClosed(plugin, prevState, state) {
		const prevConversationIds = helpers.getOpenConversationIds(plugin, prevState);
		const conversationIds = helpers.getOpenConversationIds(plugin, state);
		if (immutable.is(prevConversationIds, conversationIds)) {
			return immutable.List();
		}
		const newConversationIds = prevConversationIds.filter(conversationId => !conversationIds.contains(conversationId));
		if (newConversationIds.size === 0) {
			return immutable.List();
		}
		return newConversationIds.map(conversationId => {
			const conversation = prevState.getIn(['conversations', conversationId]);
			return immutable.Map({
				type: 'conversationClosed',
				conversationId,
				panelColor: conversation.getIn(['UI', 'panelHeaderColor']) || '#C0C4C1',
				groupId: conversation.get('groupId'),

			});
		});

	},

	agentPresence(plugin, prevState, state) {
		return prevState.get('presence') !== state.get('presence')
			? immutable.fromJS([{
				type: 'agentPresence',
				presence: state.get('presence')
			}])
			: immutable.List()
	},

	conversationActive(plugin, prevState, state) {
		const oldConversationId = prevState.get('activePanel');
		const conversationId = state.get('activePanel');
		const updated = oldConversationId !== conversationId;
		return updated
			? immutable.fromJS([{
				type: 'conversationActive',
				conversationId
			}])
			: immutable.List();

	},

	conversationStatus(plugin, prevState, state) {
		const prevConversationIds = helpers.getOpenConversationIds(plugin, prevState);
		let conversationIds = helpers.getOpenConversationIds(plugin, state);
		return conversationIds.filter(conversationId => {
			if (prevConversationIds.contains(conversationId)) {
				// already existing conversation, check state diff
				const conversation = state.getIn(['conversations', conversationId]);
				const oldConversation = prevState.getIn(['conversations', conversationId]);
				return conversation.get('conversationStatus') !== oldConversation.get('conversationStatus');
			} else {
				// new conversation
				return true;
			}
		}).map(conversationId => {
			const conversation = state.getIn(['conversations', conversationId]);
			return immutable.fromJS({
				type: 'conversationStatus',
				conversationId,
				status: conversation.get('conversationStatus')
			})
		});
	},

	connectionStatus(plugin, prevState, state) {
		const prevConversationIds = helpers.getOpenConversationIds(plugin, prevState);
		let conversationIds = helpers.getOpenConversationIds(plugin, state);
		const status = conversationIds.filter(conversationId => {
			if (prevConversationIds.contains(conversationId)) {
				// already existing conversation, check state diff
				const conversation = state.getIn(['conversations', conversationId]);
				const oldConversation = prevState.getIn(['conversations', conversationId]);
				return conversation.get('connectionStatus') !== oldConversation.get('connectionStatus');
			} else {
				// new conversation
				return true;
			}
		}).map(conversationId => {
			const conversation = state.getIn(['conversations', conversationId]);
			return immutable.fromJS({
				type: 'connectionStatus',
				conversationId,
				status: conversation.get('connectionStatus')
			})
		});

		return status;
	},

	chatMessages(plugin, prevState, state, reset) {
		const prevData = prevState.get('pluginData');
		const currentData = state.get('pluginData');
		if (reset) {
			// get all messages for all conversations
			return currentData.get('messagesById').reduce((subList, list, key) => {
				if (!helpers.isConversationIdValid(plugin, state, key)) {
					return subList;
				}
				return subList
					.concat(helpers.getMessageSub(key,
						immutable.List(),
						currentData.getIn(['messagesById', key]), 'chat', 'chatMessages'));

			}, immutable.List());

		}
		const conversationId = currentData.get('conversationId');
		const prevValid = helpers.isConversationIdValid(plugin, prevState, conversationId);
		const currentValid = helpers.isConversationIdValid(plugin, state, conversationId);
		const becameValid = !prevValid && currentValid;

		if (becameValid) {
			// conversation became valid, get all messages
			return helpers.getMessageSub(conversationId,
				immutable.List(),
				currentData.getIn(['messagesById', conversationId]), 'chat', 'chatMessages');
		}

		if (immutable.is(prevData, currentData) || !currentValid) {
			// no new messages or conversation not valid, return empty
			return immutable.List();
		}

		// get diff messages
		return helpers.getMessageSub(conversationId,
			prevData.getIn(['messagesById', conversationId]),
			currentData.getIn(['messagesById', conversationId]), 'chat', 'chatMessages');
	},

	webRTCInfo(plugin, prevState, state) {
		const prevConversationIds = helpers.getOpenConversationIds(plugin, prevState);
		const conversationIds = helpers.getOpenConversationIds(plugin, state);
		if (immutable.is(prevConversationIds, conversationIds)) {
			return immutable.List();
		}
		const newConversationIds = conversationIds.filter(conversationId => !prevConversationIds.contains(conversationId));
		if (newConversationIds.size === 0) {
			return immutable.List();
		}
		return newConversationIds.map(conversationId => {
			const visitId = state.getIn(['conversations', conversationId, 'visitId']);
			const streamingServerAuthenticationHash = state.getIn(['visitorProfiles', visitId, 'data', 'claims', 'turnServerAuthenticationHash', 0]) || '';

			return immutable.fromJS({
				type: 'webRTCInfo',
				conversationId,
				streamingServerURL: AccountConfig.getStreamingServerUrl(),
				streamingServerAuthenticationHash: streamingServerAuthenticationHash
			});
		});
	},

	sectionButtonClicked(plugin, prevState, state) {
		const prevData = prevState.getIn(['pluginData', 'pluginButtonData']);
		const currentData = state.getIn(['pluginData', 'pluginButtonData']);

		if (immutable.is(prevData, currentData)) {
			// No change in pluginButtonData: No click occurred
			return immutable.List();
		}

		if (currentData.get('pluginId') !== plugin.get('id')) {
			// Click is not for this plugin
			return immutable.List();
		}

		if (prevData && currentData.get('clickCounter') === prevData.get('clickCounter')) {
			// Click-counter not increased: No click occurred
			return immutable.List();
		}

		return immutable.fromJS([{
			type: 'sectionButtonClicked',
			conversationId: currentData.get('conversationId')
		}]);
	}

};

export default {
	subscriptions
};
