export default function(group, userList, VngageUtil, $q, itemGroupMembership) {
    'ngInject';

    var vm = this;
    vm.submit = submit;
    vm.selectableUsers = itemGroupMembership.formatSelectable(userList, 'data.profile.displayName');
    vm.selectedUsers = itemGroupMembership.formatSelected(userList, group.data.id, 'data.groupIds');
    var selectedUsersRefCopy = angular.copy(vm.selectedUsers);

    function submit() {

        // make sure all selected users have the groupId array
        vm.selectedUsers.forEach(function(selectedUser) {
            selectedUser.data.groupIds = selectedUser.data.groupIds || [];
        });

        itemGroupMembership.saveResources(userList, vm.selectedUsers, selectedUsersRefCopy, 'data.groupIds', 'data.id', group.data.id).then(function() {
            // the users have been updated, renew the ref copy
            selectedUsersRefCopy = angular.copy(vm.selectedUsers);
        });
    }
};
