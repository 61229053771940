import moment from 'moment-timezone';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import GroupQueueReservationOpeningHoursConfigModalTemplate from './GroupQueueReservationOpeningHoursConfigModal/GroupQueueReservationOpeningHoursConfigModalTemplate';

export default function ($scope, $timeout, $q, uiCalendarConfig, holidaysService, groupList, group, GroupQueueReservationOpeningHoursService, $uibModal, $state, configOpeningHours, configOverrides, Session, NotificationService) {
	'ngInject';

	var vm = this;
	vm.nowWeekStart = moment().startOf('isoWeek');
	vm.visibleWeekStart = null;
	vm.visibleConfigOverrides = [];
	vm.workspaceLang = _get(Session, 'user.profile.language', 'en').split('-')[0];
	vm.dateFromOpened = false;
	vm.dateToOpened = false;
	vm.initiallyMoveToNextWeek = true;
	vm.calendarStartHour = moment.duration('08:00:00');
	vm.calendarEndHour = moment.duration('20:00:00');
	vm.group = group.data;
	vm.selectedConfigSet = 'default';
	vm.configOverrides = configOverrides;
	vm.previewConfig = false;
	vm.overrideIntervalErrorLevel = 0;
	vm.overrideIntervalErrorMessage = '';

	const _getModels = (openingHours, overrides, group) => {
		const models = {
			'default': {
				group: group,
				start: null,
				end: null,
				openingHours: openingHours
			},
			'new': {
				group: group,
				start: null,
				end: null,
				openingHours: []
			}
		};
		overrides.forEach(override => {
			override.group = group;
			override.start = moment(override.validFrom).tz(group.timeZone);
			override.end = moment(override.validTo).tz(group.timeZone);
			override.startDate = override.start.toDate();
			override.endDate = override.end.toDate();
			override.startDateStr = override.start.format('YYYY-MM-DD');
			override.endDateStr = override.end.format('YYYY-MM-DD');
			models[override.id] = override;
		});
		return models;
	};

	let models = _getModels(configOpeningHours.openingHours, configOverrides, vm.group);
	vm.model = _cloneDeep(models['default']);

	const _updateMinMaxCalendarTimes = () => {
		$timeout(() => {
			// This may be called during render, so put it in $timeout...
			if (uiCalendarConfig.calendars.openingHoursCalendar.fullCalendar) {
				uiCalendarConfig.calendars.openingHoursCalendar.fullCalendar('option', {
					minTime: vm.calendarStartHour.hours() + ':' + vm.calendarStartHour.minutes() + ':' + vm.calendarStartHour.seconds(),
					maxTime: vm.calendarEndHour.hours() + ':' + vm.calendarEndHour.minutes() + ':' + vm.calendarEndHour.seconds(),
				});
			}
		});
	};

	const _isOverlapping = (currentModel, overrides) => {
		// This function check if start and end in "currentModel" overlaps a period in the "overrides"-array
		// It returns:
		//  false if "currentModel" does not overlap
		// 	A copy of the first conflicting override-object on overlap

		// Careful not to mutate any models...
		currentModel = _cloneDeep(currentModel);
		let sortedOtherOverrides = _cloneDeep(overrides);

		if (currentModel.id) {
			// The currently selected override may have altered start- and end-dates: Replace it in "overrides"...
			sortedOtherOverrides = sortedOtherOverrides.filter(o => o.id !== currentModel.id);
		} else {
			// Current override period is "new": Push it into "overrides" as well...
			currentModel.id = 'new';
		}
		sortedOtherOverrides.push(currentModel);

		// "overrides" need to be sorted on "start"...
		sortedOtherOverrides = sortedOtherOverrides.sort((o1, o2) => o1.start.diff(o2.start));

		// Loop through "overrides" and look for overlaps
		for (let i = 0; i < sortedOtherOverrides.length - 1; i++) {
			const currentEnd = sortedOtherOverrides[i].end;
			const nextStart = sortedOtherOverrides[i+1].start;

			if (nextStart.isBefore(currentEnd)) {
				if (sortedOtherOverrides[i].id === currentModel.id) {
					return sortedOtherOverrides[i + 1];
				} else if (sortedOtherOverrides[i + 1].id === currentModel.id) {
					return sortedOtherOverrides[i];
				} else {
					// "currentModel" is not involved: Do nothing
					// We only want to detect overlaps where "currentModel" is involved...
				}
			}
		}

		// No overlaps
		return false;
	};

	const _checkOverrideInterval = () => {
		let errorLevel = 0;
		let errorMessage = '';

		if (vm.model.startDate && vm.model.endDate) {
			if (vm.model.end.isBefore(vm.model.start)) {
				errorLevel = 2;
				errorMessage = '"To" is after "From"';
			}

			const overlappingOverride = _isOverlapping(vm.model, vm.visibleConfigOverrides);
			if (overlappingOverride) {
				errorLevel = 1;
				errorMessage = 'The currently selected override period conflicts with another override period';
				if (overlappingOverride.startDateStr && overlappingOverride.endDateStr) {
					errorMessage += ': '+ overlappingOverride.startDateStr + ' - ' + overlappingOverride.endDateStr;
				}
			}
		}

		vm.overrideIntervalErrorLevel = errorLevel;
		vm.overrideIntervalErrorMessage = errorMessage;
		return errorLevel;
	};

	const _timeIsInOverrideModel = (time, overrideModel) => {
		return (time.isBetween(overrideModel.start, overrideModel.end, null, '[]'));
	};

	const _findOverrideModel = (time) => {
		const overrideId = Object.keys(models).filter(key => ['default', 'new'].indexOf(key) === -1).find(key => {
			return _timeIsInOverrideModel(time, models[key]);
		});
		return models[overrideId];
	};

	function init() {
		/**
		 * listen for config set to edit or create
		 */
		$scope.$watch('vm.selectedConfigSet', function () {
			if (!vm.selectedConfigSet) {
				// Old override config sets are hidden, so the selected one may not exist if navigation forward in time.
				// Switch to "default" if missing
				vm.selectedConfigSet = 'default';
			}
			if (vm.selectedConfigSet === 'default') {
				// Clear any errors when switching to "recurring"
				vm.overrideIntervalErrorLevel = 0;
				vm.overrideIntervalErrorMessage = '';
			}
			vm.model = _cloneDeep(models[vm.selectedConfigSet]);
		});
		/**
		 * listen for changes to start date in time range configuration
		 */
		$scope.$watch('vm.model.startDate', function () {
			if (vm.model.startDate && vm.model.startDate != '') {
				vm.model.start = moment(vm.model.startDate).tz(vm.group.timeZone);
				uiCalendarConfig.calendars.openingHoursCalendar.fullCalendar('gotoDate', moment(vm.model.start).format('YYYY-MM-DD'));
			} else {
				vm.model.start = null;
			}
			_checkOverrideInterval();
		});
		$scope.$watch('vm.model.endDate', function () {
			if (vm.model.endDate && vm.model.endDate != '') {
				vm.model.end = moment(vm.model.endDate).tz(vm.group.timeZone);
			} else {
				vm.model.end = null;
			}
			_checkOverrideInterval();
		});

		/**
		 * setting up the requests for calendar events
		 */
		const retrieveCalendarEventsForOpeningHours = (start, end, timezone, callback) => {
			vm.visibleWeekStart = moment(start).startOf('isoWeek');
			vm.visibleConfigOverrides = vm.configOverrides.filter(vm.filterOverridesFn);

			// [ TL ] The values of "start" and "end" will be the current week in UTC
			// However, we want the full week in the group's timezone, so re-calculate!
			start = moment.tz(start.time(0).format(), vm.group.timeZone).startOf('isoWeek');
			end = moment(start).endOf('isoWeek');
			if (!vm.previewConfig) {
				fetchOpeningHours(start, end, timezone, callback);
			} else {
				testOpeningHours(start, end, timezone, callback);
			}
		};

		vm.calendarEventSources = {
			openingHours: {
				stick: true,
				events: retrieveCalendarEventsForOpeningHours,
			},
			holidays: {
				stick: true,
				color: '#F2DADA',
				textColor: '#5E1919',
				displayEventTime: false,
				events: function (start, end, timezone, callback) {
					holidaysService.queryHolidays({
						range: {
							from: start,
							to: end
						}
					}).then(res => {
						res.forEach(e => {
							const start = e.start;
							e.start = moment.utc(start).tz(vm.group.timeZone).startOf('day');
							e.end = moment.utc(start).tz(vm.group.timeZone).endOf('day');
							e.allDay = true;
							//e.rendering = 'background';
						});
						callback(res);
					});
				}
			}
		};


		/**
		 * Configurator
		 */
		const openConfiguratorModal = (event) => {
			const eventClicked = !!event.start;
			const start = eventClicked ? moment(event.start) : moment.utc(event).startOf('day');

			// Check what the user clicked and
			// auto-switch to the correct entity (unless creating a "new" override)
			if (vm.selectedConfigSet === 'default') {
				// "Recurring" is selected...
				const override = _findOverrideModel(start);
				if (override) {
					// ...but an existing override period was clicked: Switch!
					vm.selectedConfigSet = override.id;
					vm.model = _cloneDeep(override);
				}
			} else if (vm.selectedConfigSet !== 'new' && !_timeIsInOverrideModel(start, vm.model)) {
				// An existing override is selected, but something else was clicked:
				// Check if it's another override or "recurring"
				const override = _findOverrideModel(start);
				if (override) {
					// Clicked another override: Switch to it!
					vm.selectedConfigSet = override.id;
					vm.model = _cloneDeep(override);
				} else {
					// Switch to "recurring"
					vm.selectedConfigSet = 'default';
					vm.model = _cloneDeep(models['default']);
				}
			}

			if (vm.selectedConfigSet !== 'default' && (!vm.model.start || !vm.model.end)) {
				// An override is being created or edited, but "from" or "to" is unset:
				// Set "from"/"to" to the date clicked for convenience...
				vm.model.start = vm.model.start || start.startOf('day');
				vm.model.end = vm.model.end || start.startOf('day');
				vm.model.startDate = vm.model.start.toDate();
				vm.model.endDate = vm.model.end.toDate();

				if (_checkOverrideInterval() === 1) {
					// After auto-setting "To"/From": Abort modal opening on warnings (ovelaps) as well!
					// The override *WILL* be editable by clicking it again,
					// but at least this gives the user a chance to see the warning...
					NotificationService.warning({
						header: 'Warning',
						body: 'Invalid override period'
					});
					return;
				}
			}

			if (_checkOverrideInterval() > 1) {
				// The current override contains a hard error: Abort
				NotificationService.error({
					header: 'Error',
					body: 'Invalid override period'
				});
				return;
			}

			// We're ready to open the modal...
			const modal = $uibModal.open({
				backdrop: 'static',
				templateUrl: GroupQueueReservationOpeningHoursConfigModalTemplate,
				controller: 'GroupQueueReservationOpeningHoursConfigModalController as vm',
				resolve: {
					options: () => {
						const editDateRange = (vm.selectedConfigSet !== 'default' ? {
							new: (vm.selectedConfigSet === 'new'),
							start: vm.model.start,
							end: vm.model.end,
						} : null);
						return {
							dayOfWeekNumber: +start.format('E'),
							dayOfWeekName: start.format('dddd'),
							openingHours: vm.model.openingHours,
							editDateRange: editDateRange
						};
					}
				}
			});
			modal.result.then(openingHours => {
				vm.model.openingHours = openingHours;
				vm.previewConfig = true;
				refetchEvents();
			}).catch(() => {
				// Modal dismissed...
			});
			//usSpinnerService.stop('spinner-view-case-loading');
		};

		vm.cancelPreview = resetView;
		vm.saveConfiguration = () => {
			if (vm.selectedConfigSet !== 'default') {
				GroupQueueReservationOpeningHoursService.saveOpeningHoursOverrideConfig(vm.model).then(() => {
					resetView();
				});
			} else {
				GroupQueueReservationOpeningHoursService.saveOpeningHoursConfig(vm.model).then(() => {
					resetView();
				});
			}
		};
		/**
		 * setting up the calendar
		 */
		vm.uiConfig = {
			// todo why is the calendar not responding to height?
			calendar: {
				lang: vm.workspaceLang,
				locale: vm.workspaceLang,
				height: 'auto',
				firstDay: 1,
				minTime: vm.calendarStartHour.hours() + ':' + vm.calendarStartHour.minutes() + ':' + vm.calendarStartHour.seconds(),
				maxTime: vm.calendarEndHour.hours() + ':' + vm.calendarEndHour.minutes() + ':' + vm.calendarEndHour.seconds(),
				defaultView: 'agendaWeek',
				header: {
					left: 'title',
					right: 'today prev,next'
				},
				dayClick: openConfiguratorModal,
				eventClick: openConfiguratorModal,
				displayEventTime: false,
				slotEventOverlap: false,
				allDaySlot: true,
				timezone: false,
				slotLabelFormat: 'H:mm',
				views: {
					day: {
						timeFormat: 'H:mm'
					},
					week: {
						timeFormat: 'H:mm',
						columnFormat: 'ddd Do'
					}
				}
			}
		};
		vm.calendarEvents = [vm.calendarEventSources.openingHours, vm.calendarEventSources.holidays];
	}

	vm.filterOverridesFn = (item) => {
		// Hide override periods from the past, except if:
		// Item is selected or if watching a past week
		const overrideEndWeekStart = moment(item.end).startOf('isoWeek');
		return (vm.model.id === item.id || overrideEndWeekStart.diff(vm.nowWeekStart, 'week') >= 0 || vm.visibleWeekStart && overrideEndWeekStart.diff(vm.visibleWeekStart, 'week') >= 0);
	};
	vm.deleteOverride = () => {
		GroupQueueReservationOpeningHoursService.removeOpeningHoursOverride(vm.model).then(() => {
			resetView();
		});
	};
	vm.openDatepicker = ($event, type) => {
		$event.preventDefault();
		$event.stopPropagation();

		if (type === 'from') {
			vm.dateFromOpened = true;
		} else if (type === 'to') {
			vm.dateToOpened = true;
		}
	};
	function testOpeningHours(start, end, timezone, callback) {
		if (vm.model.start) {
			GroupQueueReservationOpeningHoursService.queryTestOpeningHoursOverride({
				group: vm.group,
				previewModel: vm.model,
				range: {
					from: start,
					to: end
				}
			}).then(res => {
				callback(processEvents(res, '#86898A'));
			});
		} else {
			GroupQueueReservationOpeningHoursService.queryTestOpeningHours({
				group: vm.group,
				previewModel: vm.model,
				range: {
					from: start,
					to: end
				}
			}).then(res => {
				callback(processEvents(res, '#86898A'));
			});
		}
	}

	function fetchOpeningHours(start, end, timezone, callback) {
		if (vm.initiallyMoveToNextWeek) {
			vm.initiallyMoveToNextWeek = false;
			$timeout(() => uiCalendarConfig.calendars.openingHoursCalendar.fullCalendar('next'));
			return;
		}
		GroupQueueReservationOpeningHoursService.queryOpeningHours({
			group: vm.group,
			range: {
				from: start,
				to: end
			}
		}).then(res => {
			callback(processEvents(res, '#008996', '#D49090'));
		});
	}

	function _checkEarliestAndLatestCalendarTimes(event) {
		if (moment.duration(event.start.format('HH:mm:ss')) < vm.calendarStartHour) {
			vm.calendarStartHour = moment.duration(event.start.format('HH') + ':00:00');
		}
		if (moment.duration(event.end.format('HH:mm:ss')) > vm.calendarEndHour) {
			if (event.end.format('mm:ss') === '00:00') {
				vm.calendarEndHour = moment.duration(event.end.format('HH:mm:ss'));
			} else {
				if (event.end.format('HH') === '23') {
					vm.calendarEndHour = moment.duration('23:59:59');
				} else {
					vm.calendarEndHour = moment.duration(moment(event.end).add(1, 'hours').format('HH') + ':00:00');
				}
			}
		}
	}

	function processEvents(events, color, overrideColor) {
		// Reset min/max times before re-analyzing
		vm.calendarStartHour = moment.duration('08:00:00');
		vm.calendarEndHour = moment.duration('20:00:00');

		events.forEach(event => {
			event.start = moment.tz(event.start, vm.group.timeZone); // Convert time to group's timezone;
			event.end = moment.tz(event.end, vm.group.timeZone); // Convert time to group's timezone
			event.title = event.slots + ' available'; //todo change back to this title
			event.displayEventTime = false;
			event.color = (event.isOverride ? overrideColor : color);
			event.textColor = '#fff';
			_checkEarliestAndLatestCalendarTimes(event);
		});

		_updateMinMaxCalendarTimes();
		return events;
	}

	function resetView() {
		vm.model.openingHours = [];
		vm.model.start = null;
		vm.model.end = null;
		vm.previewConfig = false;
		vm.selectedConfigSet = 'default';
		$q.all({
			configOpeningHours: GroupQueueReservationOpeningHoursService.queryOpeningHoursConfig({group: vm.group}),
			configOverrides: GroupQueueReservationOpeningHoursService.queryOpeningHoursOverrideConfig({group: vm.group})
		}).then(results => {
			vm.configOverrides = results.configOverrides;
			models = _getModels(results.configOpeningHours.openingHours, vm.configOverrides, vm.group);
			vm.model = _cloneDeep(models[vm.selectedConfigSet]);
			refetchEvents();
		});
	}

	function refetchEvents() {
		$timeout(() => uiCalendarConfig.calendars.openingHoursCalendar.fullCalendar('refetchEvents'));
	}

	init();
}
