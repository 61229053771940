import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function ($scope, $state, $stateParams, $timeout, identityProvider, VngageUtil, vngageRemoveModalService) {
	'ngInject';

	var vm = this;
	vm.resourceId = $stateParams.id;
	vm.idp = identityProvider;
	vm.addAttributeCondition = addAttributeCondition;
	vm.removeAttributeCondition = removeAttributeCondition;
	vm.cancel = cancel;
	vm.remove = remove;
	vm.currentList = $stateParams.currentList || [];

	vm.availableIdpTypes = [
		{value: 0, name: 'Vergic Saml2'},
		{value: 1, name: 'Saml2'},
		{value: 2, name: 'JSON Web Token (JWT)'}
	];

	// when accepting to reset unsaved changes, reload the entity
	$scope.$on('resetResettables', () => {
		vm.idpEditForm.$setPristine();
		identityProvider.$get().catch(() => {
		});
	});

	function addAttributeCondition() {
		vm.idpEditForm.$setDirty();
		vm.idp.data.attributeConditions.push({
			name: '',
			value: ''
		});
	}

	function removeAttributeCondition($index) {
		vm.idpEditForm.$setDirty();
		vm.idp.data.attributeConditions.splice($index, 1);
	}

	vm.submit = function () {
		vm.isSaving = true;
		vm.idp.$save().finally(function () {
			vm.isSaving = false;
		});
	};

	function cancel() {
		$state.go('^');
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: identityProvider,
			itemType: 'identityProvider'
		}).then(function () {
			vm.idpEditForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(identityProvider, 'root.account.identityProvider.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(function () {
			vm.isSaving = false;
		});
	}
}
