import assign from 'object-assign';
import Authorizations from './AbilityAuthorizationsRecord';

let defaults = {
	id: '',
	connectionState: '',
	userId: null,
	visitId: null,
	state: '',
	info: {
		owner: false,
		titleId: '',
		role: ''
	},
	addedAuthorizations: [],
	removedAuthorizations: [],
	abilities: {
		coBrowsing: true,
		video: true
	}
};

export default function factory(role, props) {
	let result;
	switch (role) {
		case 'visitor' :
			result = assign({}, defaults, props, { abilities: { video: true, coBrowsing: true }});
			break;
		case 'user' :
			result = assign({}, defaults, props, { abilities: { video: true, coBrowsing: true }});
			break;
		case 'internalExpert' :
			result = assign({}, defaults, props, { abilities: { video: false, coBrowsing: false }});
			break;
		default:
			throw new Error(`participant role: ${role} was not found`);
	}

	return result;
}
