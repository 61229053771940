export default {
	sessionId: null,
	sessionType: null,
	signInUrlOverride: null,
	sessionTimeToLive: null,
	delegatedSessionId: null,

	user: null,
	account: null,
	authorizations: null
};
