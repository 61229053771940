import React, {useState, useEffect, useRef} from 'react';
import Clipboard from 'react-copy-to-clipboard';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useGetTranslation} from './../../LangContext';

const CopyMessage = (props) => {
	const getTranslation = useGetTranslation();
	const [isCopied, setIsCopied] = useState(false);
	const timer = useRef();

	useEffect(() => {
		return () => {
			if (timer.current) {
				clearInterval(timer.current);
			}
		};
	}, []);

	const onCopy = () => {
		setIsCopied(true);
		timer.current = setTimeout(() => setIsCopied(false), 2000);
	};
	const cls = `copy-msg ${isCopied ? 'copied': ''} vngage-icon-doc-add`;
	return (
		<span className="msg-tools">
			<OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">
				{getTranslation('clipboardTip')}
			</Tooltip>}>
				<Clipboard onCopy={onCopy} text={props.txt}><i className={cls}/></Clipboard>
			</OverlayTrigger>
		</span>
	);
};
export default CopyMessage;


