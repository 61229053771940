import _forEach from 'lodash/forEach';

export default function (VngageUtil, usSpinnerService) {
	'ngInject';

	var spinnerOptions = {
		className: 'us-spinner',
		lines: 9,
		length: 2,
		width: 2,
		radius: 2,
		left: '-9px',
		top: '10px',
		color: '#fff',
		position: 'relative'
	};

	return {
		restrict: 'A',
		require: '^form',
		compile: function (tElem, tAttrs) {

			var inputElements = tElem[0].querySelectorAll('button, input, select, textarea');
			var submitButtons = angular.element(tElem[0].querySelector('input[type="submit"], button[type="submit"]'));
			var spinnerKey = 'save-indication-spinner-' + submitButtons.attr('name');

			_forEach(inputElements, function (element) {
				VngageUtil.addOrConditionToAttribute(element, 'ng-disabled', tAttrs.vngageIndicateSaving);
			});

			submitButtons.prepend([
				'<span data-us-spinner="spinnerOptions" ',
				'spinner-key="' + spinnerKey + '">',
				'</span>'
			].join(''));

			return function (scope, elem, attrs) {

				scope.spinnerOptions = spinnerOptions;

				scope.$watch(attrs.vngageIndicateSaving, function (saving) {

					if (saving === undefined) {
						return;
					}

					if (saving) {
						usSpinnerService.spin(spinnerKey);
					} else {
						usSpinnerService.stop(spinnerKey);
					}
				});
			};
		}
	};
};
