import React from 'react';
import createReactClass from 'create-react-class';

import SpeakerTitle from './SpeakerTitle.react';
import { injectIntl} from 'react-intl';

export default injectIntl(createReactClass({

	render () {
		return (
			<span className="participant-writing">
				<strong>
					<SpeakerTitle name={this.props.name} title={this.props.title} isMe={this.props.isCurrentUser}/>
				</strong> {this.props.intl.formatMessage({id:'participantTyping'})}
			</span>
		)
	}
}));
