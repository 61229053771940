import {
	receiveAsState,
	receiveSince,
	conversationReady,
	visitorProfileAdd,
	togglePluginsEnabled,
	togglePlugin,
	pluginReady,
	addPlugins,
	pluginButtonClicked,
	setPluginButtonState,
	setMeta,
	updatePluginMeta,
	setSelectedPlugin,
	notify,
	processConversationDetails,
} from './stateModifiers/plugin';
import AppConstants from '../../../components/constants/AppConstants';

const ActionTypes = AppConstants.ActionTypes;
const Plugin = AppConstants.Plugin;

export default function PluginReducer(state, action) {
	switch (action.type) {
		case Plugin.RECEIVE_AS_STATE:
			return receiveAsState(state, action);

		case Plugin.RECEIVE_SINCE :
			return receiveSince(state, action);

		case Plugin.CONVERSATION_READY:
			return conversationReady(state, action);

		case ActionTypes.VISITOR_PROFILE_ADD:
			return visitorProfileAdd(state, action);

		case Plugin.TOGGLE_PLUGINS_ENABLED:
			return togglePluginsEnabled(state, action);

		case Plugin.TOGGLE_PLUGIN:
			return togglePlugin(state, action);

		case Plugin.PLUGIN_READY:
			return pluginReady(state, action);

		case Plugin.ADD_PLUGINS:
			return addPlugins(state, action);

		case Plugin.PLUGIN_BUTTON_CLICKED:
			return pluginButtonClicked(state, action);

		case Plugin.SET_PLUGIN_BUTTON_STATE:
			return setPluginButtonState(state, action);

		case Plugin.SET_META:
			return setMeta(state, action);

		case Plugin.UPDATE_PLUGIN_META:
			return updatePluginMeta(state, action);

		case Plugin.SET_SELECTED_PLUGIN:
			return setSelectedPlugin(state, action);

		case Plugin.NOTIFY:
			return notify(state, action);

		case Plugin.PROCESS_CONVERSATION_DETAILS:
			return processConversationDetails(state, action);

		default:
			break;
	}
	return state;

}

