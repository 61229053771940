export default function (crossfilter, d3) {
	'ngInject';

		var service = {
			calculate: calculate
		};

		var ndx = null,
			dimensions = null;

		return service;

		function create() {
			var dim = {
				by: {
					when: null
				},
				groupBy: {
					nbrOfDialogs: null
				}
			};

			dim.by.when = ndx.dimension(function (d) { return d3.time.day(new Date(d.calculatedAt)); });
			dim.by.numberOfDialogs = ndx.dimension(function (d) { return d.numberOfDialogs; });
			dim.groupBy.numberOfDialogs = dim.by.when.group().reduceSum(function (d) { return d.numberOfDialogs; });
			dim.groupBy.totalNumberOfDialogs = dim.by.numberOfDialogs.groupAll().reduceSum(function (d) { return d.numberOfDialogs; });

			return dim;
		}

		function calculate(data) {

			if (!dimensions) {

				ndx = crossfilter(data);
				dimensions = create();

			} else {

				ndx.remove();
				ndx.add(data);
			}

			return dimensions;
		}

	}
