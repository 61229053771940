import Video from './Video';
import LocalStorage from '../stores/LocalStorageWrapper';
import Message from '../actionCreators/Message';
import Group from '../actionCreators/Group';
import CoBrowsing from '../actionCreators/CoBrowsing';
import LogActions from '../actions/LogActions';
import ConversationActions from '../actions/ConversationActions';
import FlowStates from '../constants/FlowStates';
import ToolBarSectionType from '../constants/ToolBarSectionType';
import Helpers from './Helpers';
import Format from '../utils/Format';
import VisitorProfileSelectors from '../selectors/VisitorProfileSelectors';
import ConversationSelectors from '../selectors/ConversationSelectors';
import immutable from 'immutable';
import AccountConfig from '../utils/AccountConfig';
import ServerActions from './ServerActions';

// time before typed message is saved to local storage
const saveCurrentMessageDelay = 1000;
// idle time until stopped typing is sent
const typingDelay = 5000;

const toggleSection = (conversationId, sectionName) => (dispatch, getState) => {

	const activeSection = getState().getIn(['conversations', conversationId, 'UI', 'activeSections', sectionName]);

	activeSection ?
		dispatch(ConversationActions.removeActiveSection(conversationId, sectionName)):
		dispatch(ConversationActions.setActiveSection(conversationId, sectionName));

	if (sectionName === ToolBarSectionType.VIDEO || sectionName === ToolBarSectionType.WEB_CALL) {

		activeSection ?
			dispatch(Video.exit()):
			dispatch(Video.start(conversationId, sectionName));
	}
};

const disposeVideo = conversationId => dispatch => {

	dispatch(Video.disposeConversationVideo(conversationId));
};

const receiveSince = (rawMessages, conversationId) => (dispatch, getState) => {
	dispatch(ConversationActions.receiveSince(rawMessages, conversationId));
	const connectionStatus = getState().getIn(['conversations', conversationId, 'connectionStatus']);
	if (connectionStatus === 'terminate') {
		// if conversation is terminated, clean up web call and video
		const videoActive = !!getState().getIn(['conversations', conversationId, 'UI', 'activeSections', ToolBarSectionType.VIDEO]);
		const webCallActive = !!getState().getIn(['conversations', conversationId, 'UI', 'activeSections', ToolBarSectionType.WEB_CALL]);

		if (videoActive) {
			dispatch(toggleSection(conversationId, ToolBarSectionType.VIDEO));
		} else if (webCallActive) {
			dispatch(toggleSection(conversationId, ToolBarSectionType.WEB_CALL));
		}
	}
};

const updateCaseType = (conversationId) => (dispatch, getState) => {
	const groupId = getState().getIn(['conversations', conversationId, 'groupId']);
	const currentCaseType = getState().getIn(['conversations', conversationId, 'currentCaseType']);
	const caseTypes = Format.asSearchFilteredList(AccountConfig.getCaseTypesByGroupId(groupId), '', 'name', 'name');
	const hasCaseType = caseTypes.find(({id}) => id === currentCaseType);
	const caseObj = caseTypes.find(({id}) => id);

	if (!hasCaseType && caseObj) {
		dispatch(ServerActions.updateCaseType(conversationId, caseObj));
	}
};


const receiveAsState = (conversationId, conversationState) => dispatch => {

	dispatch(ConversationActions.receiveAsState(conversationId, conversationState));

	// get saved input text if any
	const msg = LocalStorage.getItem(conversationId + '.currentMessage');
	if (msg) {
		dispatch(ConversationActions.setCurrentMessageAndShow(conversationId, msg));
	}

	// get saved note text if any
	const noteText = LocalStorage.getItem(conversationId + '.currentNote');
	if (noteText) {
		dispatch(ConversationActions.setCurrentNote(conversationId, noteText));
	}
	dispatch(updateCaseType(conversationId));

	dispatch(Video.resumeConversationVideo(conversationId));
};


function saveTypedMessageDebounced(conversationId, msg, fieldName) {

	const thunk = (dispatch, getState) => {
		// save text to local storage
		LocalStorage.setItem(conversationId + '.' + fieldName, msg);

		// save text to state
		// this could be changed to dispatch(ConversationActions.setTypedMessage(conversationId, msg, fieldName));
		// to support many fields.
		switch (fieldName) {
			case 'currentMessage':
				dispatch(ConversationActions.setCurrentMessage(conversationId, msg));
				break;
			case 'currentNote':
				dispatch(ConversationActions.setCurrentNote(conversationId, msg));
				break;
			default:
				break;

		}
	};

	thunk.meta = {
		debounce: {
			time: saveCurrentMessageDelay,
			key: 'setTypedMessage' + fieldName + conversationId
		}
	};
	return thunk;
}

const setTypingState = (conversationId, isTyping) => (dispatch, getState) => {
	const flowState = getState().getIn(['conversations', conversationId, 'flowState']);
	if (flowState && flowState !== FlowStates.REMOVING) {
		const typingStatus = isTyping ? 'set': 'reset';
		dispatch(Message.sendTypingStatus(conversationId, typingStatus));
		dispatch(ConversationActions.participantTyping(conversationId, isTyping));
	}
};

function stoppedTypingDebounced(conversationId) {
	const thunk = (dispatch, getState) => {
		dispatch(setTypingState(conversationId, false));
	};

	thunk.meta = {
		debounce: {
			time: typingDelay,
			key: 'stoppedTyping' + conversationId
		}
	};
	return thunk;
}

const typingChatText = (conversationId, msg) => (dispatch, getState) => {
	const wasTyping = getState().getIn(['conversations', conversationId, 'isTyping']);
	if (msg) {
		// [TL] Only set typing state if msg is not empty (if msg=='', the user hit "enter" and the message is sent)
		if (wasTyping === false) {
			// started typing
			dispatch(setTypingState(conversationId, true));
		}
		dispatch(stoppedTypingDebounced(conversationId));
	}
	dispatch(saveTypedMessageDebounced(conversationId, msg, 'currentMessage'));
};

const typingNoteText = (conversationId, msg) => dispatch => {
	dispatch(saveTypedMessageDebounced(conversationId, msg, 'currentNote'));
};

const toggleNoteModeWithFocus = (conversationId) => (dispatch, getState) => {
	dispatch(ConversationActions.toggleNoteMode(conversationId));
	const panelPosition = getState().getIn(['conversations', conversationId, 'UI', 'panelPosition']);
	Helpers.setFocus(conversationId, panelPosition);
};

const processConversationDetails = (details) => (dispatch, getState) => {
	dispatch(ConversationActions.processConversationDetails(details));
	const forcedAway = getState().get('presenceForcedAway');
	if (forcedAway) {
		dispatch(Group.setPresence('away'));
	}
};

const addClaimsMessage = (conversationId) => (dispatch, getState) => {
	// display claims if account.displayVisitorProfile is set and there are claims
	const displayVisitorProfile = !!getState().getIn(['account', 'displayVisitorProfile']);
	const visitId = getState().getIn(['conversations', conversationId, 'visitId']);
	const visitorProfile = getState().getIn(['visitorProfiles', visitId]) || immutable.Map();
	const claims = VisitorProfileSelectors.getClaimsForMessage(visitorProfile);
	if (displayVisitorProfile && claims) {
		const createdRaw = ConversationSelectors.getLastLocalMessageTimeStamp(getState(), conversationId) + 1;
		const msg = Format.asVisitorProfileMessage(conversationId, createdRaw, claims.toJS());
		dispatch(ConversationActions.addLocalMessage(conversationId, msg, true));
	}
};

const afterConversationReady = (conversationId) => (dispatch, getState) => {
	const conversationReady = getState().getIn(['conversations', conversationId, 'flowState']) === FlowStates.READY;
	const coBrowsingEnabled = !!getState().getIn(['conversations', conversationId, 'capabilities', 'enabled', 'coBro']);
	const startInCoBrowsingMode = coBrowsingEnabled && !!getState().getIn(['conversations', conversationId, 'capabilities', 'groupFeatures', 'startInCoBrowsingMode', 'enabled']);
	if (conversationReady && startInCoBrowsingMode) {
		dispatch(LogActions.logAc('startInCoBrowsingMode', {conversationId}));
		dispatch(CoBrowsing.toggleCoBro(conversationId));
	}
};

export default {
	toggleSection,
	disposeVideo,
	receiveSince,
	updateCaseType,
	receiveAsState,
	typingChatText,
	typingNoteText,
	toggleNoteModeWithFocus,
	processConversationDetails,
	addClaimsMessage,
	afterConversationReady
};
