import React from 'react';
import StartOverlay from './StartOverlay.react';
import VisitorProfile from './VisitorProfile.react.js';
import {useGetTranslation} from './../LangContext';

const PanelPendingStart = (props) => {
	const getTranslation = useGetTranslation();
	const {conversationId} = props;

	const conversationStatusText = getTranslation('conversationStatusPendingStart');
	return (
		<div className="dialog-panel ">
			<span className="dialog-container">
				<div className="dialog-menu">
					<div className="header">
						<div className="header-text" tabIndex="-1">
							<i className="vngage-icon-clock"/>
							<div className="status-text">
								{conversationStatusText.substr(0, 20)}
							</div>
						</div>
					</div>

					<VisitorProfile conversationId={conversationId}/>
				</div>
				<div className="dialog-action-area">
					<div className="pending-auto-routing">
						<StartOverlay conversationId={conversationId}/>
					</div>
				</div>
			</span>
		</div>
	);
};

PanelPendingStart.displayName = 'PanelPendingStart';
export default PanelPendingStart;

