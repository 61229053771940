/*
 Vergic: This is a wrapper around 'easyrtc-factory' (which contains a modified version of the original easyrtc-1.1.1-rc2 from 2018-11-26)
 Modules importing this module ('easyrtc') will get one common easytrc-object like the original implementation (backwards compatibility)
 If multiple instances are wanted, use 'easyrtc-factory' instead
*/

/* global define, module, require */

// Vergic: Re-implemented the 'easyrtc' as a wrapper around 'easyrtc_factory' (to enable backwards compatibility with original easyrtc)
(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        //RequireJS (AMD) build system
        define('easyrtc',['./easyrtc_factory.js'], factory);
    } else if (typeof module === 'object' && module.exports) {
        //CommonJS build system
        module.exports = factory(require('./easyrtc_factory.js'));
    } else {
        //Vanilla JS, ensure dependencies are loaded correctly
        if (typeof window.io === 'undefined' || !window.io) {
            throw new Error("easyrtc requires socket.io");
        }
        root.easyrtc = factory(window.easyrtc_factory);
    }
}(this, function (easyrtc_factory) {
    return easyrtc_factory.newEasyRTC();
}));
