import SystemSettingAddModalController from './SystemSettingAddModalController';
import SystemSettingEditController from './SystemSettingEditController';
import SystemSettingListController from './SystemSettingListController';
import systemSettingTranslations from './SystemSettingTranslations';
import SystemSettingListTemplate from './SystemSettingListTemplate';
import SystemSettingEditTemplate from './SystemSettingEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.system.setting', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('SystemSettingAddModalController', SystemSettingAddModalController)
	.controller('SystemSettingEditController', SystemSettingEditController)
	.controller('SystemSettingListController', SystemSettingListController)
	.constant('systemSettingTranslations', systemSettingTranslations)

	.constant('systemSettingModuleConfig', {
		state: 'root.system.setting',
		access: 'System/Settings'
	})

	.config(function ($stateProvider, $translateProvider, systemSettingTranslations, systemSettingModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(systemSettingTranslations);

		$stateProvider.state(systemSettingModuleConfig.state, {
			url: 'setting/',
			controller: 'SystemSettingListController as vm',
			templateUrl: SystemSettingListTemplate,
			data: {
				ncyBreadcrumbLabel: 'Settings',
				access: systemSettingModuleConfig.access
			},
			resolve: {
				settingList: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.setting.query().$promise;
				},
				accountList: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.account.query().$promise;
				}
			}
		});

		$stateProvider.state('root.system.setting.edit', {
			url: ':id',
			controller: 'SystemSettingEditController as vm',
			templateUrl: SystemSettingEditTemplate,
			data: {
				ncyBreadcrumbLabel: 'Edit',
				access: {action: 'GET', resource: 'Setting'}
			},
			params: {currentList: []},
			resolve: {
				setting: function (settingList, VngageUtil, $stateParams) {
					'ngInject';
					return VngageUtil.findResource(settingList, $stateParams.id);
				}
			}
		});
	});
