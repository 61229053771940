import ConfigurationSetEditController from './ConfigurationSetEditController';
import ConfigurationSetItemHistoryModalController from './ConfigurationSetItemHistoryModalController';
import ConfigurationSetSaveModalController from './ConfigurationSetSaveModalController';
import configurationSetTranslations from './ConfigurationSetTranslations';
import ConfigurationSetEditTemplate from './ConfigurationSetEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.publish.configurationSet', ['ui.router', 'vngageApp.workspaceTranslation'])

	.controller('ConfigurationSetEditController', ConfigurationSetEditController)
	.controller('ConfigurationSetItemHistoryModalController', ConfigurationSetItemHistoryModalController)
	.controller('ConfigurationSetSaveModalController', ConfigurationSetSaveModalController)
	.constant('configurationSetTranslations', configurationSetTranslations)

	.constant('configurationSetModuleConfig', {
		state: 'root.publish.configuration',
		access: 'Engage/ConfigurationSet'
	})

	.config(function ($stateProvider, $translateProvider, configurationSetTranslations, configurationSetModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(configurationSetTranslations);

		$stateProvider.state(configurationSetModuleConfig.state, {
			url: 'configuration',
			controller: 'ConfigurationSetEditController as vm',
			templateUrl: ConfigurationSetEditTemplate,
			data: {
				ncyBreadcrumbLabel: 'Edit',
				access: configurationSetModuleConfig.access
			},
			resolve: {
				context: function (APIConfigurationSetService) {
					'ngInject';
					return APIConfigurationSetService.getConfigurationSetContext();
				},
				activateContext: function (APIConfigurationSetService, context) {
					'ngInject';
					return APIConfigurationSetService.getActivateContext(context.dependencies);
				}
			}
		});
	});
