import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';
import SiteSettingsMigrationModalTemplate from './SiteSettingsMigrationModalTemplate';

export default function (site, $state, VngageUtil, $scope, availableLanguages, vngageRemoveModalService, $uibModal, $stateParams) {
	'ngInject';

	var vm = this;
	vm.site = site;
	vm.submit = submit;
	vm.remove = remove;
	vm.cancel = cancel;
	vm.availableLanguages = availableLanguages;
	vm.currentList = $stateParams.currentList || [];


	if (vm.site.data.baseDomain && vm.site.data.baseDomain !== 'true') {
		// The setting "baseDomain" needs to be migrated.
		// Open a modal to inform and force new setting.
		var modal = $uibModal.open({
			templateUrl: SiteSettingsMigrationModalTemplate,
			controller: 'SiteSettingsMigrationModalController as vm',
			backdrop: 'static',
			backdropClass: 'modal-backdrop',
			resolve: {
				options: function () {
					return {
						info: 'site.BASE_DOMAIN_MIGRATION_INFO',
						current: 'site.BASE_DOMAIN_MIGRATION_CURRENTVALUE',
						recommendation: 'site.BASE_DOMAIN_MIGRATION_RECOMMENDATION',
						currentBaseDomain: vm.site.data.baseDomain
					};
				}
			}
		});
		modal.result.then(value => {
			vm.site.data.baseDomain = value;
			vm.siteEditForm.$setDirty();
		}, () => {
			$state.go('^');
		});
	}

	function submit() {
		vm.isSaving = true;
		vm.site.$save().finally(() => {
			vm.isSaving = false;
		});
	}

	// when accepting to reset unsaved changes, reload the entity
	$scope.$on('resetResettables', () => {
		vm.siteEditForm.$setPristine();
		site.$get().catch(() => {
		});
	});

	function cancel() {
		$state.go('^');
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: site,
			itemType: 'site'
		}).then(function () {
			vm.siteEditForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(site, 'root.account.site.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(function () {
			vm.isSaving = false;
		});
	}
}
