export default function($uibModalInstance, APIConfigurationSectionService) {
	'ngInject';
    var vm = this;
    vm.cancelNew = $uibModalInstance.dismiss;
    vm.saveNew = saveNew;

    vm.newData = {
        name: '',
        section: {}
    };

    function saveNew() {
        const type = APIConfigurationSectionService.sectionMap.solution;
        vm.isSaving = true;
		$uibModalInstance.freeze(true);

        if (!vm.newData.section.messageToCustomerCase) {
            vm.newData.section.messageToCustomerCase = '-';
        }

        APIConfigurationSectionService.create(vm.newData, type, true)
            .then(function(res) {
				$uibModalInstance.close(res);
            })
            .catch(function() {
				$uibModalInstance.freeze(false);
                vm.isSaving = false;
            });
    }
}
