import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import VideoState from '../../constants/VideoState';
import VideoLib from '../../reducers/libs/videoLib';
import ErrorView from './ErrorView.react';
import ToolbarView from './ToolbarView.react';
import LoadingView from './LoadingView.react';

const OverlayView = (props) => {
	const [isLoading, setIsLoading] = useState(true);
	const timer = useRef();
	const {videoState} = props;

	useEffect(() => {
		if (videoState === VideoState.STREAMING) {
			setIsLoading(false);
		}

		if (videoState === VideoState.LEAVING) {
			setIsLoading(true);
		}
	}, [videoState]);

	// clean up timer on leave
	useEffect(() => {
		return () => {
			clearTimeout(timer.current);
		};

	}, []);

	//componentWillReceiveProps
	const savedProps = useRef(props);
	useEffect(() => {
		const prevProps = savedProps.current;
		const nextProps = props;

		const currentVideoState = prevProps.videoState;
		const nextVideoState = nextProps.videoState;

		if (currentVideoState !== nextVideoState) {
			if (nextVideoState === VideoState.STREAMING) {
				if (currentVideoState === VideoState.CALLING_PEER) {
					timer.current = setTimeout(() => {
						setIsLoading(false);

					}, 1000);
					return;
				}
				setIsLoading(false);
			}

			if (nextVideoState === VideoState.LEAVING) {
				setIsLoading(true);
			}
		}
		savedProps.current = props;
	}, [props]);

	const {
			  error,
			  init,
			  isModal,
			  isFullscreen
		  } = props;


	let toolbarView = null;
	let errorView = null;
	let loadingView = null;

	if (isLoading) {
		loadingView = <LoadingView/>;
	} else {
		const payload = {
			isModal,
			isFullscreen
		};
		toolbarView = <ToolbarView {...payload} />;
	}

	if (error) {
		const errorViewPayload = {init};
		errorView = <ErrorView {...errorViewPayload} />;
	}

	return (
		<div className='video-overlay'>
			{toolbarView}
			{loadingView}
			{errorView}
		</div>
	);
};

function mapStateToProps(state) {

	return {
		error: VideoLib.getError(state),
		videoState: VideoLib.getState(state)
	};
}

export default connect(mapStateToProps)(OverlayView);
