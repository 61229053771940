import React, {useState} from 'react';
import {Form, FormGroup, FormControl, ControlLabel, Radio, Button} from 'react-bootstrap';
import InContactValidationRules from './InContactValidationRules'
import FormHelpers from '../../utils/FormHelpers'
import {useGetTranslation} from './../LangContext';

const InContactLoginForm = (props) => {
	const getTranslation = useGetTranslation();
	const [loginType, setLoginType] = useState('stationPhoneNumber');
	const [loginData, setLoginData] = useState({
		loginId: {
			value: '',
			errorMsg: '',
			inputStyle: ''
		},
		username: {
			value: '',
			errorMsg: '',
			inputStyle: ''
		},
		password: {
			value: '',
			errorMsg: '',
			inputStyle: ''
		}
	});
	const isFormValid = () => {
		return ['loginId', 'username', 'password'].every(name => loginData[name].errorMsg === 'ok');
	};

	const getUpdatedField = (name, value, loginType) => {
		let fieldType = name;
		if (name === 'loginId') {
			// if loginId use loginType to choose validation rule
			fieldType = loginType;
		}
		const validationRule = InContactValidationRules[fieldType];
		const errorMsgStatus = FormHelpers.validate(value, validationRule);
		const errorMsg = errorMsgStatus !== '' && errorMsgStatus !== 'ok'
			? getTranslation(errorMsgStatus)
			: errorMsgStatus;
		const inputStyle = errorMsg === ''
			? ''
			: errorMsg === 'ok'
				? 'success'
				: 'danger';

		return {value, errorMsg, inputStyle};
	};

	const updateAllFields = (loginType) => {
		const updatedFields = ['loginId', 'username', 'password'].reduce((obj, name) => {
			const value = loginData[name].value;
			obj[name] = getUpdatedField(name, value, loginType);
			return obj;
		}, {});
		setLoginData({...updatedFields})
	};

	const onSubmit = (event) => {
		event.preventDefault();
		const {username, password, loginId, loginType} = loginData;
		props.inContactLogin(username.value, password.value, loginId.value, loginType);
	};

	const onChangeField = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		const updated = getUpdatedField(name, value, loginType);
		setLoginData({...loginData, [name]: updated});
	};

	const onChangeRadio = (event) => {
		const loginT = event.target.value;
		updateAllFields(loginT);
		setLoginType(loginT)
	};

	const isValid = isFormValid();
	const placeHolder = loginType === 'stationId'
		? getTranslation('inContactPlaceholderStationId')
		: getTranslation('inContactPlaceholderPhone');
	const pending = props.inContactData.get('pending');
	const storedErrorMsg = props.inContactData.get('errorMsg');
	const errorMsg = storedErrorMsg.indexOf('KEY:') === 0
		? getTranslation(storedErrorMsg.substring(4))
		: storedErrorMsg;
	const cls = pending ? 'incontact-login pending': 'incontact-login';

	return (
		<div className={cls}>
			{pending
				? <div className="content-spinner"/>
				: null}
			<Form onSubmit={onSubmit}>
				<div className="modal-content">
					<div className="modal-header">
						<h3>{getTranslation('inContactTitle')}</h3>
					</div>
					<div className="modal-body">
						<Form.Group className="credentials">
							<Form.Control
								required
								disabled={pending}
								name="username"
								type="text"
								className={loginData.username.inputStyle}
								value={loginData.username.value}
								placeholder={getTranslation('inContactPlaceholderUsername')}
								onChange={onChangeField}/>
							{loginData.username.inputStyle === 'danger'
								? <span
									className="alert alert-danger">{loginData.username.errorMsg}</span>
								: null
							}
							<FormControl
								required
								disabled={pending}
								name="password"
								type="password"
								className={loginData.password.inputStyle}
								value={loginData.password.value}
								placeholder={getTranslation('inContactPlaceholderPassword')}
								onChange={onChangeField}/>
							{loginData.password.inputStyle === 'danger'
								? <span
									className="alert alert-danger">{loginData.password.errorMsg}</span>
								: null
							}
						</Form.Group>
						<Form.Group className="incontact-login-type">
							<Form.Check name="phone"
										type="radio"
										disabled={pending}
										label={getTranslation('inContactRadioPhone')}
										id="phone"
										onChange={onChangeRadio}
										value="stationPhoneNumber"
										checked={loginType === 'stationPhoneNumber'}/>
							<Form.Check name="stationId"
										type="radio"
										label={getTranslation('inContactRadioStationId')}
										id="stationId"
										onChange={onChangeRadio}
										value="stationId"
										checked={loginType === 'stationId'}/>
						</Form.Group>
						<Form.Group className="incontact-login-value">
							<Form.Control
								disabled={pending}
								name="loginId"
								type="text"
								className={loginData.loginId.inputStyle}
								value={loginData.loginId.value}
								placeholder={placeHolder}
								onChange={onChangeField}/>

							{loginData.loginId.inputStyle === 'danger'
								? <span
									className="alert alert-danger">{loginData.loginId.errorMsg}</span>
								: null
							}
						</Form.Group>
						{!!errorMsg
							? (
								<div className="error-msg">
									<i className="vngage-icon-attention"/>
									{errorMsg}
								</div>)
							: null}
					</div>
					<div className="modal-footer">
						<Button type="submit"
								onClick={onSubmit}
								disabled={!isValid || pending}
								variant="primary">
							{getTranslation('inContactContinue')}
						</Button>
					</div>
				</div>
			</Form>
		</div>
	);
};
export default InContactLoginForm;

