import ConversationActions from '../../actions/ConversationActions';
import Message from '../../actionCreators/Message'
import PluginActions from '../../actions/PluginActions';
import PluginAC from '../../actionCreators/PluginAC';

const actionFormats = {

	setChatInputText: {
		conversationId: 'conversationId',
		msg: 'string'
	},

	sendChatMessage: {
		conversationId: 'conversationId',
		msg: 'string'
	},

	sendActionMessage: {
		conversationId: 'conversationId',
		actionMessageName: 'string',
		actionData: 'object'
	},

	setSectionButtonState: {
		conversationId: 'conversationId',
		buttonState: 'string'
	},

	pluginReady: {},

};

const actionMap = {

	setChatInputText: ({conversationId, msg}) => dispatch => dispatch(ConversationActions.setCurrentMessageAndShow(conversationId, msg)),
	sendChatMessage: ({conversationId, msg}) => dispatch => dispatch(Message.sendChatMessage(conversationId, msg)),
	sendActionMessage: ({pluginId, conversationId, actionMessageName, actionData}) => dispatch => dispatch(PluginAC.sendActionMessage(conversationId, pluginId, actionMessageName, actionData)),
	setSectionButtonState: ({conversationId, pluginId, buttonState}) => dispatch => dispatch(PluginActions.setSectionButtonState(conversationId, pluginId, buttonState)),
	pluginReady: ({pluginId}) => (dispatch) => dispatch(PluginActions.pluginReady(pluginId)),
};

export default {
	actionFormats,
	actionMap
}
