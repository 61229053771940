import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function ($state, $scope, VngageUtil, setting, settingList, vngageRemoveModalService, vngagePatterns, $stateParams) {
	'ngInject';

	var vm = this;
	vm.setting = angular.copy(setting);
	vm.settingList = settingList;
	vm.submit = submit;
	vm.remove = remove;
	vm.cancel = cancel;
	vm.patterns = vngagePatterns;
	vm.currentList = $stateParams.currentList || [];

	function submit() {
		vm.isSaving = true;
		vm.setting.$save().then(updatedSetting => {
			// Update corresponding element in "settingList" to ensure the list reflects any updated changes on this setting
			VngageUtil.replaceResource(vm.settingList, updatedSetting).finally(() => {
				vm.isSaving = false;
				// transition to the same state, but with updated state params (account) transfer the updated properties
				$state.transitionTo($state.current, {id: updatedSetting.data.id, currentList: vm.currentList});
			});
		}, err => {
			vm.isSaving = false;
			console.log('Error saving system settings, err:', err);
		});
	}

	function cancel() {
		$state.go('^');
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: setting,
			itemType: 'systemSetting'
		}).then(() => {
			vm.systemSettingEditForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(setting, 'root.system.setting.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(() => {
			vm.isSaving = false;
		});
	}

	$scope.$on('resetResettables', () => {
		vm.systemSettingEditForm.$setPristine();
	});
}
