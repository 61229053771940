import ConfigurationSetHistoryController from './ConfigurationSetHistoryController';
import configurationSetHistoryTranslations from './ConfigurationSetHistoryTranslations';
import ConfigurationSetHistoryTemplate from './ConfigurationSetHistoryTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.publish.history', ['ui.router', 'vngageApp.workspaceTranslation'])

	.controller('ConfigurationSetHistoryController', ConfigurationSetHistoryController)
	.constant('configurationSetHistoryTranslations', configurationSetHistoryTranslations)

	.constant('configurationSetHistoryModuleConfig', {
		state: 'root.publish.history',
		access: 'Engage/ConfigurationSet'
	})

	.config(function ($stateProvider, configurationSetHistoryModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(configurationSetHistoryTranslations);

		$stateProvider.state(configurationSetHistoryModuleConfig.state, {
			url: 'history',
			controller: 'ConfigurationSetHistoryController as vm',
			templateUrl: ConfigurationSetHistoryTemplate,
			data: {
				ncyBreadcrumbLabel: 'History',
				access: configurationSetHistoryModuleConfig.access
			},
			resolve: {
				context: function (APIConfigurationSetService) {
					'ngInject';
					return APIConfigurationSetService.getConfigurationSetContext();
				},
				activateContext: function (APIConfigurationSetService, context) {
					'ngInject';
					return APIConfigurationSetService.getActivateContext(context.dependencies);
				},
				userList: function (APIEndpointService, IdentityService, context) {
					'ngInject';
					if (IdentityService.currentlyDelegated()) {
						return context.dependencies.users;
					} else {
						// Only return non-delegated users
						return context.dependencies.users.filter(user => user.data.type === 0);
					}
				}
			}
		});
	});
