import _find from 'lodash/find';

import AccountSettingsController from './AccountSettingsController';
import accountSettingsDefaults from './AccountSettingsDefaults';
import AccountSettingsEditTemplate from './AccountSettingsEditTemplate';

export default angular.module('vngageApp.account.accountSettings', [
	'ui.router'
])

	.controller('AccountSettingsController', AccountSettingsController)
	.value('accountSettingsDefaults', accountSettingsDefaults)

	.constant('accountSettingsModuleConfig', {
		state: 'root.account.accountSettings',
		access: 'Engage/AccountSettings'
	})

	.config(($stateProvider, accountSettingsModuleConfig) => {
		'ngInject';

		$stateProvider.state(accountSettingsModuleConfig.state, {
			url: 'settings/',
			controller: 'AccountSettingsController as vm',
			templateUrl: AccountSettingsEditTemplate,
			data: {
				access: accountSettingsModuleConfig.access,
				ncyBreadcrumbLabel: 'Settings'
			},
			resolve: {
				accountSettingsConfigurationSections: APIConfigurationSectionService => {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.accountSettings);
				},
				accountSettingsConfigurationSectionsValidation: (APIConfigurationSectionService, ConfigValidationService, accountSettingsConfigurationSections) => {
					'ngInject';
					return ConfigValidationService.validateSectionType(accountSettingsConfigurationSections, APIConfigurationSectionService.sectionMap.accountSettings);
				},
				accountSettings: (APIConfigurationSectionService, accountSettingsConfigurationSections, accountSettingsDefaults) => {
					'ngInject';

					var accountSettings = _find(accountSettingsConfigurationSections, r => r.data.siteId === '00000000-0000-0000-0000-000000000000');
					if (accountSettings) {
						return accountSettings;
					}
					return APIConfigurationSectionService.create(accountSettingsDefaults, APIConfigurationSectionService.sectionMap.accountSettings);
				}
			}
		});
	});
