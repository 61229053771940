export default {
    'en-GB': {
        systemAuthorization: {
            ADD_HEADER: 'New authorization',
            EDIT_HEADER: 'Edit authorization',
            LIST_HEADER: 'Authorizations',
            LIST_TITLE: 'List of authorizations for this account',
            LIST_DESCRIPTION: 'Authorizations exists for all requests that are possible to do in Vergic Engage. Roles can then be set up with the Authorizations that should be available to that role.',
            RESOURCE: 'Resource',
            ACTION: 'Action',
            DESCRIPTION: 'Description'
        }
    },
    'sv-SE': {
        systemAuthorization: {
            ADD_HEADER: 'New authorization',
            EDIT_HEADER: 'Edit authorization',
            LIST_HEADER: 'Authorizations',
            LIST_TITLE: 'List of authorizations for this account',
            LIST_DESCRIPTION: 'Authorizations exists for all requests that are possible to do in Vergic Engage. Roles can then be set up with the Authorizations that should be available to that role.',
            RESOURCE: 'Resource',
            ACTION: 'Action',
            DESCRIPTION: 'Description'
        }
    }
};
