import vngageTagListTemplate from './vngageTagListTemplate';

export default function () {
	'ngInject';

	return {
		restrict: 'AE',
		require: '^form',
		scope: {
			tagList: '=vngageTagList',
			tagValidateFunction: '=vngageTagValidateFunction'
		},
		templateUrl: vngageTagListTemplate,
		bindToController: true,
		controllerAs: 'vm',
		controller: function ($scope) {
			'ngInject';

			const vm = this;
			vm.$onInit = function () {
				vm.tagList = vm.tagList || [];
			}

			vm.addTag = function () {
				var tagExists = vm.tagList.indexOf(vm.newTag) >= 0;
				var tagIsValid = (typeof vm.tagValidateFunction) === 'function'
					? vm.tagValidateFunction(vm.newTag)
					: true;

				$scope.tagListForm.$setDirty();

				if (vm.newTag && !tagExists && tagIsValid) {
					vm.tagList.push(vm.newTag);
					tagExists = true;
				}

				if (tagExists) {
					vm.newTag = '';
				}
			};

			vm.removeTag = function (index) {
				$scope.tagListForm.$setDirty();
				vm.tagList.splice(index, 1);
			};

			vm.keyPressAddTag = function (event) {
				// 32 = space, 13 = enter
				if (event.keyCode === 32 || event.keyCode === 13) {
					event.preventDefault();
					vm.addTag();
				}
			};

		},
		compile: function (tElement, tAttrs) {

			var inputEl    = tElement.find('input'),
				inputGroup = tElement.children().eq(0);

			if (tAttrs.name) {
				inputGroup
					.attr('vngage-basic-validation', 'form:tagListForm;parent:true')
					.attr('vngage-indicate-error', '');
			}

			// Move attributes from wrapper to inputEl/inputGroup
			inputEl.attr('type', tAttrs.type || 'text');
			addAttribute(inputEl, 'name', true);
			addAttribute(inputEl, 'ngPattern');
			addAttribute(inputGroup, 'patternTranslation');

			function addAttribute(el, key, useKeyAsAttr) {
				var attrName;
				if (tAttrs[key]) {
					attrName = useKeyAsAttr ? key: tAttrs.$attr[key];
					el.attr(attrName, tAttrs[key]);
				}
			}

		}
	};
};
