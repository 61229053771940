import UrlCategoryAddModalController from './UrlCategoryAddModalController';
import UrlCategoryEditController from './UrlCategoryEditController';
import UrlCategoryListController from './UrlCategoryListController';
import urlCategoryTranslations from './UrlCategoryTranslations';
import UrlCategoryListTemplate from './UrlCategoryListTemplate';
import UrlCategoryEditTemplate from './UrlCategoryEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.configure.urlCategory', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('UrlCategoryAddModalController', UrlCategoryAddModalController)
	.controller('UrlCategoryEditController', UrlCategoryEditController)
	.controller('UrlCategoryListController', UrlCategoryListController)
	.constant('urlCategoryTranslations', urlCategoryTranslations)

	.constant('urlCategoryModuleConfig', {
		state: 'root.configure.urlCategory',
		access: 'Engage/PageCategories'
	})

	.config(function ($stateProvider, $translateProvider, urlCategoryTranslations, urlCategoryModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(urlCategoryTranslations);

		$stateProvider.state(urlCategoryModuleConfig.state, {
			url: 'urlcategory/',
			controller: 'UrlCategoryListController as vm',
			templateUrl: UrlCategoryListTemplate,
			data: {
				access: urlCategoryModuleConfig.access,
				ncyBreadcrumbLabel: 'Page Categories'
			},
			resolve: {
				urlCategoryList: function (APIConfigurationSectionService) {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.urlCategory, true);
				}
			}
		});

		$stateProvider.state('root.configure.urlCategory.edit', {
			url: ':id',
			controller: 'UrlCategoryEditController as vm',
			templateUrl: UrlCategoryEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				urlCategory: function ($stateParams, urlCategoryList, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(urlCategoryList, $stateParams.id);
				}
			}
		});
	});
