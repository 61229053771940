import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _reject from 'lodash/reject';
import _find from 'lodash/find';
import _omit from 'lodash/omit';

import assign from 'object-assign';
import AppDispatcher from '../dispatcher/AppDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import WebAPIUtils from '../utils/WebAPIUtils';
import MessageUtils from '../../../components/utils/MessageUtils';
import ParticipantUtils from '../../../components/utils/ParticipantUtils';
import VisualGuidance from '../utils/VisualGuidance';

import ConversationsStore from './ConversationsStore';
import ParticipantStore from './ParticipantStore';
import ProfileStore from './ProfileStore';
import StoreFactory from '../../../components/utils/StoreFactory';

//FIXME: AccountConfiguration is only setup in Desktop at the moment...
//import AccountConfiguration from './../../../common/AccountConfiguration';

const ActionTypes = AppConstants.ActionTypes;

var state = {
	messages: [],
	events: {}
};

var EventStore = StoreFactory.create({

	/**
	 * return conversation by ref
	 * @param conversationRef
	 */
	getConversationByRef (conversationRef) {
		let c = ConversationsStore.findByRef(conversationRef);
		return c || {};
	},

	/**
	 * returns messages mapped with speaker of conversation
	 * @param {String} conversationId
	 * @returns {Array} messages filtered array
	 */
	getMappedMessagesOfConversation (conversationId) {
		let conversation = ConversationsStore.find(conversationId);
		if (!conversation) {
			return [];
		}

		let conversationMsgs = _filter(state.messages, msg => {
			return msg.conversationId === conversationId;
		});

		let messageTypes = ['chat', 'link', 'meta'];
		let metaTypes = ['note', 'navigation'];

		//const USE_WEBRTC = AccountConfiguration.configuration.account.useWebRTC;
		const USE_WEBRTC = true;
		if (USE_WEBRTC) {
			messageTypes.push('video');
		}

		conversationMsgs = _filter(conversationMsgs, function (msg) {
			return messageTypes.indexOf(msg.messageType) > -1;
		});

		conversationMsgs.map( msg => {
			if (msg.speaker && !msg.speaker.info) {
				const speakerId = msg.speaker.userId || msg.speaker.visitId;
				msg.speaker = ParticipantStore.getParticipantOfConversationById(conversationId, speakerId);
			}
		});

		return MessageUtils.sortByDate(conversationMsgs).map((msg, index) => {
			msg.isMostRecentMessage = index === conversationMsgs.length - 1;
			return msg;
		});
	}

});

const registeredCallback = function (payload) {
	const action = payload.action;
	const { conversationId, participant } = action;
	const conversation = ConversationsStore.find(conversationId);
	const MY_ID = ProfileStore.getProfileId();
	const ME = ParticipantStore.getParticipantMe();

	let conversationMsgs = [],
		msgInConversation = [],
		message = action.message,
		mappedMessages,
		mappedProfile;

	switch (action.type) {

		case ActionTypes.CREATE_VIDEO_INVITATION :
		case ActionTypes.CREATE_NOTE :
		case ActionTypes.CREATE_MESSAGE :
			message = MessageUtils.addProfileToMsg(message, ME);
			message = MessageUtils.addConversationIdToMsg(message, conversationId);
			state.messages.push(message);
			break;

		case ActionTypes.RECEIVE_AS_STATE :
			AppDispatcher.waitFor([ParticipantStore.dispatchToken]);

			mappedMessages = MessageUtils.mapConversationMessages(action.conversationState.messages, conversationId);
			_addMessages(mappedMessages, false);
			break;

		case ActionTypes.RECEIVE_SINCE :
			AppDispatcher.waitFor([ParticipantStore.dispatchToken]);
			let didUpdateOptimisticMsg = false;

			msgInConversation = MessageUtils.addConversationIdToMsg(action.rawMessages, conversationId);
			conversationMsgs = MessageUtils.getConversationMessages(msgInConversation);

			let msgsWithoutMe;
			if (!MessageUtils.conversationHasChatMessages(conversationId, state.messages)) {
				msgsWithoutMe = conversationMsgs;
			} else {
				msgsWithoutMe = MessageUtils.getChatMessagesWithoutMe(conversationMsgs, MY_ID);
			}

			// update vg
			let navigations = _filter(msgsWithoutMe, msg => {
				return msg.messageType === 'navigation' || msg.messageType === 'domUpload';
			});

			if (navigations.length > 0) {
				conversation.visualGuidance.addNavigations(navigations, true);
			}

			_addMessages(msgsWithoutMe, true);

			// map over incoming messages and check if a message has a conversationTag
			// (aka response of optimistic insert by agent)
			conversationMsgs.map(message => {
				if (MessageUtils.hasTagOfConversation(conversationId, message, MY_ID)) {
					state.messages = state.messages.map(msg => {
						// TODO: this assumes it's the only tag... needs refactoring
						if (msg.tags && msg.tags[0] === message.tags[0]) {
							didUpdateOptimisticMsg = true;
							return assign({}, msg, MessageUtils.convertRawMessage(message));
						} else {
							return msg;
						}
					});
				}
				return message;
			});

			if (!msgsWithoutMe.length > 0 && !didUpdateOptimisticMsg) {
				return false;
			}

			break;

		case ActionTypes.PARTICIPANT_JOINED :
			AppDispatcher.waitFor([ParticipantStore.dispatchToken]);

			let joinedFiltered = participant.filter(p => {
				var conversationEvents = state.events[conversationId];
				let processed = conversationEvents && conversationEvents.indexOf(p.eventId) > -1;
				if (!processed) {
					state.events[conversationId] = state.events[conversationId] || [];
					state.events[conversationId].push(p.eventId);
				}
				return !processed;
			});

			let joinedParticipants = joinedFiltered.map(ParticipantUtils.getParticipantEntityId);

			joinedParticipants.map((participantId, index) => {
				mappedProfile = ParticipantStore.getParticipantOfConversationById(conversationId, participantId);
				message = MessageUtils.getMetaMessageData({action: 'participantJoined'}, mappedProfile);
				message = MessageUtils.addConversationIdToMsg(message, conversationId);

				if (participantId === MY_ID) {
					ConversationsStore.setProperties(conversationId, {abort: true, initialized: false, since: 0});
				} else {
					state.messages.push(message); // addMessage(message);
				}

			});
			break;

		case ActionTypes.PARTICIPANT_LEFT :
			AppDispatcher.waitFor([ParticipantStore.dispatchToken]);

			let leftFiltered = participant.filter(p => {
				var conversationEvents = state.events[conversationId];
				let processed = conversationEvents && conversationEvents.indexOf(p.eventId) > -1;
				if (!processed) {
					state.events[conversationId] = state.events[conversationId] || [];
					state.events[conversationId].push(p.eventId);
				}
				return !processed;
			});

			leftFiltered.map(participant => {
				let participantLeftId = ParticipantUtils.getParticipantEntityId(participant);
				mappedProfile = ParticipantStore.getParticipantOfConversationById(conversationId, participantLeftId);
				message = MessageUtils.getMetaMessageData({action: 'participantLeft'}, mappedProfile);
				message = MessageUtils.addConversationIdToMsg(message, conversationId);

				if (participantLeftId === MY_ID) {
					ConversationsStore.setProperties(conversationId, {abort: true, initialized: false, since: 0});
				} else {
					state.messages.push(message); // addMessage(message);
				}
			});
			break;

		case ActionTypes.TOGGLE_VISUAL_GUIDANCE_SCALE :
			conversation.visualGuidance.setScaled(action.isScaled);
			break;

		case ActionTypes.TOGGLE_META_MESSAGES :
			conversation.showMeta = !conversation.showMeta;
			break;

		case ActionTypes.VISUAL_GUIDANCE :
			switch (action.action) {
				case 'sendHighlight':
					conversation.visualGuidance.sendHighlight(action.data);
					break;

				case 'toggleHighlight':
					conversation.visualGuidance.toggleHighlight(action.isActive);
					break;

				case 'vgStatus' :
					conversation.visualGuidance.changedStatus(action.status);
					break;

				default :
					return false;
			}
			break;

		case ActionTypes.VISITOR_RESIZE :
			if (conversation && conversation.visualGuidance) {
				conversation.visualGuidance.setState(assign({browserAttributes: action.browserInfo}));
			} else {
				return false;
			}
			break;

		case ActionTypes.VISUAL_GUIDANCE_HIGHLIGHT :
			conversation.visualGuidance.showHighlight(action.payload);
			return false;
			break;

		case ActionTypes.CONVERSATION_CLOSED :
			state.messages = _reject(state.messages, p => p.conversationId === conversationId);
			state.events = _reject(state.events, p => p.conversationId === conversationId);
			return false;
			break;

		default :
			return true;
	}


	// if we made down here... emit change event
	EventStore.emitChange();
};

EventStore.dispatchToken = AppDispatcher.register(registeredCallback);


// adds message to state.messages
function addMessage(message, fromSince) {
	if (MessageUtils.getById(state.messages, message.id)) {
		return false;
	}

	state.messages.push(MessageUtils.convertRawMessage(message));
}

// loops through rawMessages and calls method for each message
function _addMessages(rawMessages, fromSince) {
	if (Array.isArray(rawMessages)) {
		rawMessages.map(function(msg){
			addMessage(msg, fromSince);
		});
	} else {
		addMessage(rawMessages, fromSince);
	}
}

export default EventStore;
