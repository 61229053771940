import BannerService from './BannerService';
import BannerThemeService from './BannerThemeService';
import bannerTranslations from './BannerTranslations';
import './BannerEdit/BannerEditModule';
import BannerEditController from './BannerEdit/BannerEditController';
import validateHtml from './BannerEdit/ValidateHtmlDirective';
import validateHtmlOnlyWarn from './BannerEdit/ValidateHtmlOnlyWarnDirective';
import BannerListController from './BannerList/BannerListController';
import bannerListTableConfig from './BannerList/BannerListTableConfig';
import BannerPreviewController from './BannerPreview/BannerPreviewController';
import bannerPreviewDirective from './BannerPreview/BannerPreviewDirective';
import bannerPreviewDisplay from './BannerPreview/BannerPreviewDisplayDirective';
import bannerPreviewResize from './BannerPreview/BannerPreviewResizeDirective';
import BookedMeetingsMarkupService from './BannerPreview/BookedMeetingsMarkupService';
import BannerPreview from './BannerPreview/BannerPreviewService';
import BannerPreviewUrlModalController from './BannerPreview/BannerPreviewUrlModalController';
import BannerListTemplate from './BannerList/BannerListTemplate';
import BannerAddModalController from './BannerList/BannerAddModalController';

import './BannerEdit/guides/BannerEditCssGuideTemplate';
import './BannerEdit/guides/BannerEditGeneralGuideTemplate';
import './BannerEdit/guides/BannerEditHtmlGuideTemplate';
import './BannerEdit/guides/BannerEditIntroGuideTemplate';
import './BannerEdit/guides/BannerEditSettingsGuideTemplate';
import './BannerEdit/guides/BannerEditMappingGuideTemplate';
import './BannerEdit/guides/BannerEditTranslationsGuideTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.engage.banner', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.engage.banner.edit',
	'vngageApp.workspaceTranslation'
])

	.factory('BannerService', BannerService)
	.factory('BannerThemeService', BannerThemeService)
	.constant('bannerTranslations', bannerTranslations)
	.controller('BannerEditController', BannerEditController)
	.directive('validateHtml', validateHtml)
	.directive('validateHtmlOnlyWarn', validateHtmlOnlyWarn)
	.controller('BannerListController', BannerListController)
	.factory('bannerListTableConfig', bannerListTableConfig)
	.controller('BannerPreviewController', BannerPreviewController)
	.directive('bannerPreviewDirective', bannerPreviewDirective)
	.directive('bannerPreviewDisplay', bannerPreviewDisplay)
	.directive('bannerPreviewResize', bannerPreviewResize)
	.factory('BannerPreview', BannerPreview)
	.factory('BookedMeetingsMarkupService', BookedMeetingsMarkupService)
	.controller('BannerPreviewUrlModalController', BannerPreviewUrlModalController)
	.controller('BannerAddModalController', BannerAddModalController)

	.constant('bannerModuleConfig', {
		state: 'root.engage.banner',
		access: 'Engage/Banner'
	})
	.value('filterXSS', window.filterXSS)
	.config(function($stateProvider, $translateProvider, bannerTranslations, bannerModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(bannerTranslations);

		$stateProvider
			.state(bannerModuleConfig.state, {
				url: 'banner/',
				controller: 'BannerListController',
				controllerAs: 'vm',
				templateUrl: BannerListTemplate,
				data: {
					access: bannerModuleConfig.access,
					ncyBreadcrumbLabel: 'Banner'
				},
				resolve: {
					bannerList: function(APIConfigurationSectionService) {
						'ngInject';
						return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.banner, true);
					}
				}
			});

	});
