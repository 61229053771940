export default {
	handler (event, sendFunction, subscriptionIsArray) {

		var send = function (type, data) {
			console.log('actionEvents<> send:', type, data);
			sendFunction(type, data);
		};
		this.send = send;

		var subscriptions = {};
		this.subscriptions = subscriptions;

		var subscribe = function (id, type, func) {
			if (typeof subscriptions[type] == 'undefined') subscriptions[type] = {};
			if (typeof subscriptions[type][id] == 'undefined') subscriptions[type][id] = [];
			subscriptions[type][id].push(func);
		};
		this.subscribe = subscribe;

		var unsubscribe = function (id, type) {
			if (typeof subscriptions[type] == 'undefined') return;
			if (!id) {
				delete subscriptions[type];
				return;
			}
			if (typeof subscriptions[type][id] == 'undefined') return;
			delete subscriptions[type][id];
		};
		this.unsubscribe = unsubscribe;

		var trigger = function (type, data) {
			if (typeof subscriptions[type] == 'undefined') return;
			for (var id in subscriptions[type]) {
				for (var f = 0; f < subscriptions[type][id].length; f++) {
					subscriptions[type][id][f](data);
				}
			}
		};
		event.subscribe('actionEvents', (function (data) {
			//console.log(JSON.parse(data));
			if (typeof subscriptionIsArray != 'undefined' && subscriptionIsArray) {
				//console.log('data length : ' + data.length);
				for (var a in data) {
					trigger(data[a].type, data[a].data);
				}
			} else {
				trigger(data.type, data.data);
			}
		}));
	},
	load () {
		//this.visitors = psPlugin.application.visitorHandler.visitors;
		//psPlugin.application.events.onCoWorkerLoaded.subscribe('actionEvents', (function (visitor) {
		//	try {
		//		console.log('subscribing to actionEvents', visitor);
		//		visitor.actionEvents = new psPlugin.application.modules.actionEvents.handler(visitor.events.onactions, (function (type, data) {
		//			//console.log(visitor.properties.sessionId,data);
		//			visitor.commands.sendAction.execute('runClientFunction', type, data);
		//		}), true);
		//	} catch (err) {
		//		console.log(err);
		//	}
		//}));
	},
	sendFunction (visitor) {
		console.log('sendFunction (deprecated)', visitor);
	}
};
