export default {
	Notification: window.Notification,
	newNotification(...args) {
		return new Notification(...args)
	},
	focus() {
		window.focus()
	},
	hasFocus() {
		return document.hasFocus();
	},
	isVisible() {
		return document.visibilityState === 'visible';
	},
	querySelector(q) {
		return document.querySelector(q);
	},
	goToHashLocation(hash) {
		window.location.hash = hash;
	}
}