import React from 'react';
import Timestamp from './Timestamp.react';
import CopyMessage from './CopyMessage.react';
import mmd from '../../../../../common/vngageMarkdown/mmd';

const MessageChat = (props) => {
	const formatVisitorChatMsg = (txt) => {
		return txt.split('\n')
			.map((row, index) => <span key={index}>{row}<br/></span>);
	};

	return (
		<span>
			{props.role === 'visitor'
				? <span className="message">
				<strong>{props.speaker}: </strong>
					{formatVisitorChatMsg(props.txt)}
				</span>
				: <span className="message"
						dangerouslySetInnerHTML={{__html: `<strong>${props.speaker}: </strong>` + mmd(props.txt)}}/>
			}
			<Timestamp
				timestamp={props.timestamp}
				isMostRecentMessage={props.isMostRecentMessage}
				displayRelativeDialogTime={props.displayRelativeDialogTime}
			/>
			<CopyMessage txt={props.txt}/>
		</span>
	)
};
export default MessageChat

