import accountTranslations from './AccountTranslations';
import './accountSettings/AccountSettingsModule';
import './customScript/CustomScriptModule';
import './delegation/DelegationModule';
import './identityProvider/IdentityProviderModule';
import './overview/OverviewModule';
import './panelSettings/PanelSettingsModule';
import './role/RoleModule';
import './site/SiteModule';
import './theme/ThemeModule';
import './translation/TranslationModule';
import './user/UserModule';
import './visitorSettings/VisitorSettingsModule';
import './workspaceSettings/WorkspaceSettingsModule';
import AccountContentTemplate from './AccountContentTemplate';
import AccountMenuTemplate from './AccountMenuTemplate';
import '../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.account', [
    'ui.router',
    'vngageApp.account.delegation',
    'vngageApp.account.identityProvider',
    'vngageApp.account.overview',
    'vngageApp.account.role',
    'vngageApp.account.site',
    'vngageApp.account.user',
    'vngageApp.account.accountSettings',
    'vngageApp.account.visitorSettings',
    'vngageApp.account.panelSettings',
    'vngageApp.account.theme',
    'vngageApp.account.translation',
    'vngageApp.account.customScript',
    'vngageApp.account.workspaceSettings',
	'vngageApp.workspaceTranslation'
])

.constant('accountTranslations', accountTranslations)

.constant('accountModuleConfig', {
    state: 'root.account',
    access: 'Account'
})

.config(function($stateProvider, $urlRouterProvider, $translateProvider, accountTranslations, accountModuleConfig, VngageWorkspaceTranslationServiceProvider) {
	'ngInject';
	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(accountTranslations);

    $urlRouterProvider.when('/account/', function(transitionService,
        overviewModuleConfig,
        siteModuleConfig,
        identityProviderModuleConfig,
        visitorSettingsModuleConfig,
        panelSettingsModuleConfig,
        workspaceSettingsModuleConfig,
        userModuleConfig,
        delegationModuleConfig,
        roleModuleConfig,
        themeModuleConfig,
        translationModuleConfig,
        customScriptModuleConfig,
		renderTemplateConfig) {
		'ngInject';

        transitionService.navigate([
            overviewModuleConfig,
            siteModuleConfig,
            identityProviderModuleConfig,
            visitorSettingsModuleConfig,
            panelSettingsModuleConfig,
            workspaceSettingsModuleConfig,
            userModuleConfig,
            delegationModuleConfig,
            roleModuleConfig,
            themeModuleConfig,
            translationModuleConfig,
            customScriptModuleConfig,
			renderTemplateConfig
        ]);
    });

    $stateProvider.state(accountModuleConfig.state, {
        url: '/account/',
        views: {
            content: {
                templateUrl: AccountContentTemplate
            },
            menu: {
                templateUrl: AccountMenuTemplate
            }
        },
        data: {
            access: accountModuleConfig.access,
            ncyBreadcrumbLabel: 'Account'
        }
    });
});
