import React from 'react';
import {Rnd} from 'react-rnd';

const ModalDraggable = (props) => {
	let title = null;
	if (props.title) {
		title = <div className="overlay-header">
			<h5>
				{props.title}
			</h5>
		</div>;
	}

	return (
		<Rnd
			default={{
				x: 20,
				y: 20,
				width: 320,
				height: 240
			}}
			className="pointer-events-auto"
			minWidth={320}
			minHeight={240}
			lockAspectRatio={true}
			bounds="parent">
			<div className="box"
				 style={{margin: 0, height: '100%'}}>
				<div className="overlay-content">
					<div className="overlay-top-bar">
						{title}
						<div className="overlay-filler"/>
						<div className="overlay-close">
							<i className="vngage-icon-cancel"
							   onClick={props.close}/>
						</div>
					</div>
					<div className="overlay-body">
						{props.children}
					</div>
				</div>
			</div>
		</Rnd>
	);
};
export default ModalDraggable;

