import React from 'react';
import createReactClass from 'create-react-class';
import EmbedObject from './utils/EmbedObject';
import vngageConfig from './../app/vngageConfig';
import { injectIntl } from 'react-intl';
import easyrtc from './../../vendor/easyrtc_v1.1.1-rc2/easyrtc';

const assetPath = vngageConfig.assetPath || '//content.psplugin.com/';
const SWF = 'video-ws-42.swf';

export default injectIntl(createReactClass({

	getInitialState () {
		return {
			layoutMode: ''
		}
	},

	toggleCamera (event) {
		easyrtc.enableCamera(event.target.checked);
	},

	toggleMicrophone (event) {
		easyrtc.enableMicrophone(event.target.checked);
	},

	changeLayout (target) {
		const lm = this.state.layoutMode;
		const layout = (target === 'v') ? 'v-zoom-v' : 'v-zoom-g';

		this.setState({
			layoutMode: (lm === '' || lm !== layout) ? layout : ''
		});
	},

	render () {
		let isStreaming = this.props.streaming.video || this.props.streaming.audio ,
			player = <span/>,
			btnStartStop = '';


		let layoutMode = 'videowrap ' + this.state.layoutMode;

		if (isStreaming && this.props.useFlash) {
			player = <div dangerouslySetInnerHTML={{__html: EmbedObject.create({
				meetingId: 'is8xi1z',
				outStreamId: '1' + this.props.streamId,
				inStreamId: this.props.streamId,
				playerUrl: assetPath + 'views/shared/video/' + SWF,
				baseUrl: assetPath + 'views/shared/img/webcam/',
				width: 270,
				autostart: true
			}, this.props.streamingServer)}}></div>


		} else if (isStreaming) {

			player = <div id="videowrap" className={layoutMode} ref="container">
				<div ref="videoCanvas" className="video-canvas">
					<video className="v v-me" id="selfie" autoPlay="autoPlay" muted="muted" volume="0"></video>
					<video className="v v-v" onClick={this.changeLayout.bind(this, 'v')} id="visitorVideo" autoPlay="autoPlay"></video>
					<video className="v v-g" onClick={this.changeLayout.bind(this, 'g')} id="otherVideo" autoPlay="autoPlay"></video>
				</div>
				<div className="toolbar video-toolbar">
					<label><input defaultChecked={true} type="checkbox" onChange={this.toggleCamera} /><i className="vngage-icon-videocam"></i></label>
					<label><input defaultChecked={true} type="checkbox" onChange={this.toggleMicrophone} /><i className="vngage-icon-volume"></i></label>
				</div>
			</div>;

		}
		if (this.props.roomOwner) {
			btnStartStop = (this.props.streaming.video) ?
				<div className="legacy-tool-bar"><button className="btn btn-xs btn-primary btn-video-startstop" onClick={this.props.onStopStream} type="button">{this.props.intl.formatMessage({id:'videoChatButtonStop'})}</button></div>
				:
				<div className="legacy-tool-bar"><button className="btn btn-xs btn-default btn-video-startstop" onClick={this.props.onCreateStream} type="button">{this.props.intl.formatMessage({id:'videoChatButtonStart'})}</button></div>;
		}
		return (
			<div className="chatWrapper">
				<div>
					{btnStartStop}
					<div className="psPlugin_videChat transitionAll" id="videoPlayer">
						{player}
					</div>
				</div>
			</div>
		)
	}

}));
