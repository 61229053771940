export default {
'en-GB': {
        calendar: {

        }
    },
    'sv-SE': {
        calendar: {

        }
    }
};
