export default function (access) {
	return {
		id: 'agentsbygroup',
		access: (access || 'Statistics'),
		viewConfig: {
			metricName: 'UserCaseUsagePerHourPerSitePerGroup',

			/** ----------------------------------------------* Measures
			 * Add measures to use in graphs and tables
			 */
			/*
			measures: [{
				key: 'loggedInTimeMs', 						// (REQUIRED) the key that will be created (if calculate) or the matching key in the original data object
				type: 'duration',							// (REQUIRED)
				calculation: {type: x, parameters: [...]}	// (OPTIONAL) Perform a pre-defined calculation defined in './common/MetricFormatterService.js' to achieve this value
			}]
*/
			measures: [
				{
					key: 'siteName',
					type: 'string'
				}, {
					key: 'groupName',
					type: 'string'
				}, {
					key: 'dimensionName',
					type: 'string',
					calculation: {
						type: 'formatUserNameInGroupSite',
						parameters: [
							'userName',
							'groupName',
							'siteName'
						]
					}
				}, {
					key: 'userName',
					type: 'string'
				}, {
					key: 'loggedInTimeMs',
					type: 'duration'
				}, {
					key: 'availableTimeMs',
					type: 'duration'
				}, {
					key: 'engagedTimeMs',
					type: 'duration'
				}, {
					key: 'dialogTimeMs',
					type: 'duration'
				}, {
					key: 'numberOfDialogs',
					type: 'number'
				}, {
					key: 'avg_sim_dialogs',
					type: 'number',
					calculation: {
						type: 'division',
						parameters: [
							'dialogTimeMs',
							'engagedTimeMs'
						]
					},
					decimals: 1
				}, {
					key: 'avg_dialog_time',
					type: 'duration',
					calculation: {
						type: 'division',
						parameters: [
							'dialogTimeMs',
							'numberOfDialogs'
						]
					}
				}, {
					key: 'numberOfClosedCases',
					type: 'number'
				}, {
					key: 'numberOfRelatedAssistedPurchasePois_sameVisit',
					type: 'number'
				}, {
					key: 'numberOfRelatedAssistedPurchasePois_6Hours',
					type: 'number'
				}, {
					key: 'satisfactionScore',
					type: 'number'
				}
			],

			/** ----------------------------------------------* Bar filters
			 * Add bar filters to present the dimensions in the data
			 */
			/*
			barFilters: [{
				dimension: 'siteName', 				// (REQUIRED) The dimension to create and display labels from
				valueAccessor: 'numberOfDialogs' 	// (REQUIRED) The value accessor to show graph value from
			}]
*/
			barFilters: [
				{
					dimension: 'siteName',
					valueAccessor: 'numberOfDialogs'
				}, {
					dimension: 'groupName',
					valueAccessor: 'numberOfDialogs'
				}, {
					dimension: 'userName',
					valueAccessor: 'numberOfDialogs',
					sortBy: 'numberOfDialogs'
				}
			],


			/** ----------------------------------------------* Time line graph
			 * Add bar filters to present the dimensions in the data
			 */
			/*
			timeLineGraph: [{
				valueAccessor: 'numberOfDialogs'	// (REQUIRED) The value accessor to show graph value from
				type: 'number'						// (OPTIONAL) Override the valueAccessor-metric's type - controls if the values should be summed or counted in the graph
			}]
*/
			timeLineGraph: {
				valueAccessor: 'numberOfDialogs'
			},


			/** ----------------------------------------------* Totals
			 * Add totals summaries above the data table
			 */
			/*
			totals: [{
				measure: 'numberOfActivatedSolutions',		// (REQUIRED) The measure to summarize or count in this 'total'
				type: 'number',								// (OPTIONAL) Override the valueAccessor-metric's type. Controls if the values should be summed or counted in the totals
				calculation: {type: x, parameters: [...]}	// (OPTIONAL) Perform a pre-defined calculation defined in './common/MetricFormatterService.js' to achieve this 'total'
				decimals: 0									// (OPTIONAL) Override the valueAccessor-metric's decimals.
				displayPercentOf: 'numberOfSolutions',		// (OPTIONAL) Also display percentage of another metric in parenthesis after this absolute value
				hidden: true								// (OPTIONAL) Makes this measure hidden (but available for calculations and 'percentageOf')
			}]
*/
			totals: [
				{
					measure: 'availableTimeMs'
				}, {
					measure: 'engagedTimeMs'
				}, {
					measure: 'numberOfDialogs'
				}, {
					measure: 'dialogTimeMs'
				}, {
					measure: 'avg_sim_dialogs',
					calculation: {
						type: 'division',
						parameters: [
							'dialogTimeMs',
							'engagedTimeMs'
						]
					},
					decimals: 1
				}, {
					measure: 'avg_dialog_time',
					calculation: {
						type: 'division',
						parameters: [
							'dialogTimeMs',
							'numberOfDialogs'
						]
					},
					decimals: 1
				}, {
					measure: 'numberOfClosedCases'
				}, {
					measure: 'numberOfRelatedAssistedPurchasePois_sameVisit',
					displayPercentOf: 'numberOfClosedCases'
				}, {
					measure: 'numberOfRelatedAssistedPurchasePois_6Hours',
					displayPercentOf: 'numberOfClosedCases'
				}, {
					measure: 'satisfactionScore',
					displayPercentOf: 'satisfactionScore'
				}
			],


			/** ----------------------------------------------* Table
			 * Add totals summaries above the data table
			 */
			/*
			table: [{
				groupBy: 'groupName' 		// (REQUIRED) Group rows by
				measures: [] 				// (REQUIRED) Measures to use in the table columns (Documentation for each measure is same as for totals)
			}]
*/
			table: {
				groupBy: 'dimensionName',
				measures: [
					{
						measure: 'availableTimeMs',
						calculation: {
							type: 'formatDuration',
							parameters: [
								'availableTimeMs',
								3600000	// Display progress-bar, relative to one hour (3600000 ms)
							]
						},
					}, {
						measure: 'engagedTimeMs',
						calculation: {
							type: 'formatDuration',
							parameters: [
								'engagedTimeMs',
								3600000 // Display progress-bar, relative to one hour (3600000 ms)
							]
						},
					}, {
						measure: 'numberOfDialogs'
					}, {
						measure: 'dialogTimeMs'
					}, {
						measure: 'numberOfClosedCases'
					}, {
						measure: 'numberOfRelatedAssistedPurchasePois_sameVisit',
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'numberOfRelatedAssistedPurchasePois_sameVisit',
								'numberOfClosedCases'
							]
						},
					}, {
						measure: 'numberOfRelatedAssistedPurchasePois_6Hours',
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'numberOfRelatedAssistedPurchasePois_6Hours',
								'numberOfClosedCases'
							]
						}
					},  {
						measure: 'satisfactionScore',
						calculation: {
							type: 'formatPercentageWithColors',
							parameters: [
								'satisfactionScore',
								[{
									from: 0,
									color: '#f04e5e'
								}, {
									from: 33,
									color: '#f0ad4e'
								}, {
									from: 66,
									color: '#71ce61'
								}]
							]
						}
					}
				]
			}
		}
	};
}
