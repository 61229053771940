import vngageMarkdownLegendTemplate from './vngageMarkdownLegendTemplate';

export default function () {
	'ngInject';

	return {
		restrict: 'AE',
		replace: true,
		templateUrl: vngageMarkdownLegendTemplate
	};
};
