import _defaults from 'lodash/defaults';
import _zipObject from 'lodash/zipObject';
import _map from 'lodash/map';

export default function (APIEndpointService, AccountSitesService) {
	'ngInject';

	let service  = {},
		noSiteId = '00000000-0000-0000-0000-000000000000';

	service.sectionMap = {
		accountSettings: 'accountSettings',
		action: 'action',
		actionPanel: 'actionPanel',
		banner: 'banner',
		bannerTheme: 'bannerTheme',
		closure: 'closure',
		customerCase: 'customerCase',
		form: 'forms',
		opportunity: 'opportunity',
		cannedResponse: 'preDefinedText',
		panelSettings: 'panelSettings',
		solution: 'solution',
		theme: 'theme',
		urlCategory: 'urlCategory',
		visitor: 'visitor',
		renderTemplate: 'renderTemplate',
		group: 'group',
		workspace: 'workspace'
	};

	service.getAll = function (siteSpecific) {
		// Returns a promise to get all ConfigurationSections
		// Can be used to fetch the config *once* in a view, and then manually filter the sections of interest later from the same result (to avoid multiple lookups)
		var params = {
			siteId: (siteSpecific ? getSelectedSiteId() : null),
			limit: 10000
		};
		return APIEndpointService.configurationSection.query(params).$promise;
	};

	service.filterConfigurationSections = function (configurationSections, types) {
		// Filters "configurationSections"-array and returns only the specified "types"
		// "types" can be a string or an array (of strings)
		// Can be used to fetch the config *once* in a view, and then manually filter the sections of interest later from the same result (to avoid multiple lookups)
		const getMultipleTypes = Array.isArray(types);
		types = (getMultipleTypes ? types : [types]);
		let filteredConfSections = _zipObject(types, _map(types, () => []));

		configurationSections.forEach(function (confSection) {
			if (types.indexOf(confSection.data.type) !== -1) {
				filteredConfSections[confSection.data.type].push(confSection);
			}
		});
		return (getMultipleTypes ? filteredConfSections : filteredConfSections[types[0]]);
	};

	service.get = function (types, siteSpecific) {
		return service.getAll(siteSpecific).then(function (configurationSections) {
			return service.filterConfigurationSections(configurationSections, types);
		});
	};

	service.create = function (data, type, siteSpecific, silent) {
		const defaults = {
			type: type,
			siteId: siteSpecific ? getSelectedSiteId() : noSiteId,
			section: {}
		};

		_defaults(data, defaults);

		if (silent) {
			return APIEndpointService.configurationSection.silentCreate(data).$promise;
		} else {
			return APIEndpointService.configurationSection.create(data).$promise;
		}
	};


	function getSelectedSiteId() {
		return AccountSitesService.getSelectedSiteId();
	}

	return service;
}
