import _find from 'lodash/find';
import _uniq from 'lodash/uniq';
import _pull from 'lodash/pull';
import moment from 'moment-timezone';

export default function (SessionService, APICaseService, APIEndpointService, $q, uiGridConstants, APIConfigurationSectionService, VngageUtil, $translate) {
	'ngInject';

	var service = {
		initializeGrid: initializeGrid,
		getRelatedData: getRelatedData,
		formatCase: formatCase,
		formatSatisfactionScore: formatSatisfactionScore,
		getParticipants: getParticipants,
		getConversations: getConversations,
		queryParams: {},
		initQueryParams: initQueryParams,
		queryGridData: queryGridData,
		updateCleanedCase: updateCleanedCase,

		gridOptions: null,
		gridContext: null,
		gridApi: null,
		isFetching: false
	};

	initQueryParams();

	var takeDefault = 100;

	function initQueryParams() {
		service.queryParams.skip = null;
		service.queryParams.take = takeDefault;
		service.queryParams.ownedByUserId = null;
		service.queryParams.ownedByUserName = null;
		service.queryParams.customerId = null;
		service.queryParams.minDate = null;
		service.queryParams.minTime = null;
		service.queryParams.maxDate = null;
		service.queryParams.maxTime = null;
		service.queryParams.sortBy = APICaseService.paramValMap.sortBy.createdAt;
		service.queryParams.sortOrder = uiGridConstants.DESC;
		service.queryParams.caseState = 2;
	}

	// options.textCenter
	// options.customContent
	function getCellTemplate(options) {
		options = options || {};
		return [
			'<div class="ui-grid-cell-contents {{grid.appScope.getCellCls(row.entity.caseId)}}' + (options.textCenter ? ' text-center': '') + '">',
			'<span ng-click="grid.appScope.viewCase(row.entity.caseId)" style="cursor:pointer;">',
			options.customContent || '{{COL_FIELD CUSTOM_FILTERS}}',
			'</span>',
			'</div>'
		].join(' ');
	}
	function formatSatisfactionScore (score){
		return score
			? `${Math.round(score * 100)}%`
			: '-';
	}

	function getScoreCellTemplate() {
		return `
			<div class="ui-grid-cell-contents {{grid.appScope.getCellCls(row.entity.caseId)}}">
			  <div ng-click="grid.appScope.viewCase(row.entity.caseId)" style="cursor:pointer;" ng-if="row.entity.satisfactionScore === 0">
			    <span>{{grid.appScope.formatSatisfactionScore(row.entity.satisfactionScore)}}</span>
			  </div>
			  <div ng-click="grid.appScope.viewCase(row.entity.caseId)" style="cursor:pointer;" ng-if="row.entity.satisfactionScore >= 0.5">
			    <span class="bg-success text-white rounded col-8px satisfaction-score">{{grid.appScope.formatSatisfactionScore(row.entity.satisfactionScore)}}</span>
			  </div>
			  <div ng-click="grid.appScope.viewCase(row.entity.caseId)" style="cursor:pointer;" ng-if="row.entity.satisfactionScore > 0 && row.entity.satisfactionScore < 0.5">
				<span class="bg-danger text-white rounded col-8px satisfaction-score">{{grid.appScope.formatSatisfactionScore(row.entity.satisfactionScore)}}</span>
			  </div>
			</div> `;
	}

	function getRowTemplate() {
		return [
			'<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name"',
			'class="ui-grid-cell"',
			'ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader }"',
			'ui-grid-cell>',
			'</div>'
		].join(' ');
	}

	// Need the $scope for the controller where the grid lives since listeners are added by grid
	function initializeGrid($scope) {
		service.gridContext = $scope;
		service.gridOptions = {
			enableFiltering: true,
			enableGridMenu: true,
			exporterCsvFilename: 'case_browser.csv',
			gridMenuShowHideColumns: false,
			exporterMenuPdf: false,
			exporterMenuExcel: false,
			minRowsToShow: 20,
			data: [],
			rowTemplate: getRowTemplate(),
			columnDefs: [
				{
					name: '#',
					displayName: $translate.instant('caseBrowser.RESULT_COL_NBR'),
					enableSorting: false,
					enableColumnMenu: false,
					enableHiding: false,
					enableFiltering: false,
					cellTemplate: getCellTemplate({
						customContent: '{{ row.grid.options.data.indexOf(row.entity) + 1 }}',
						textCenter: true
					}),
					width: 50,
					allowCellFocus: false,
					headerCellClass: ''
				},
				{
					name: 'openedDate',
					displayName: $translate.instant('caseBrowser.RESULT_COL_DATE'),
					enableSorting: true,
					enableHiding: false,
					enableColumnMenu: true,
					cellTemplate: getCellTemplate(),
					width: 110,
					allowCellFocus: false
				},
				{
					name: 'openedTime',
					displayName: $translate.instant('caseBrowser.RESULT_COL_OPENED'),
					width: 100,
					enableSorting: true,
					enableHiding: false,
					enableColumnMenu: true,
					cellTemplate: getCellTemplate(),
					allowCellFocus: false
				},
				{
					name: 'closedTime',
					displayName: $translate.instant('caseBrowser.RESULT_COL_CLOSED'),
					width: 100,
					enableSorting: true,
					enableHiding: false,
					enableColumnMenu: true,
					cellTemplate: getCellTemplate(),
					allowCellFocus: false
				},
				{
					name: 'currentOwnerName',
					displayName: $translate.instant('caseBrowser.RESULT_COL_AGENT'),
					enableSorting: true,
					enableHiding: false,
					enableColumnMenu: true,
					cellTemplate: getCellTemplate(),
					width: 220,
					allowCellFocus: false
				},
				{
					name: 'caseType',
					displayName: $translate.instant('caseBrowser.RESULT_COL_CASE_TYPE'),
					enableSorting: true,
					enableHiding: false,
					enableColumnMenu: true,
					cellTemplate: getCellTemplate(),
					allowCellFocus: false,
					width: 140
				},
				{
					name: 'group',
					displayName: $translate.instant('caseBrowser.RESULT_COL_GROUP'),
					enableSorting: true,
					enableHiding: false,
					enableColumnMenu: true,
					cellTemplate: getCellTemplate(),
					allowCellFocus: false,
					width: 140
				},
				{
					name: 'closure',
					displayName: $translate.instant('caseBrowser.RESULT_COL_CLOSURE'),
					enableSorting: true,
					enableHiding: false,
					enableColumnMenu: true,
					cellTemplate: getCellTemplate(),
					allowCellFocus: false,
					width: 100
				},
				{
					name: 'outcome',
					displayName: $translate.instant('caseBrowser.RESULT_COL_OUTCOME'),
					enableSorting: false,
					enableHiding: false,
					enableColumnMenu: false,
					cellTemplate: getCellTemplate(),
					width: 110,
					allowCellFocus: false
				},
				{
					name: 'caseId',
					displayName: $translate.instant('caseBrowser.RESULT_COL_CASE_ID'),
					enableSorting: false,
					enableHiding: false,
					enableColumnMenu: false,
					cellTemplate: getCellTemplate(),
					allowCellFocus: false,
					visible: false
				},
				{
					name: 'satisfactionScore',
					displayName: $translate.instant('caseBrowser.RESULT_COL_SATISFACTION_SCORE'),
					enableSorting: true,
					enableHiding: false,
					enableColumnMenu: true,
					allowCellFocus: false,
					width: 100,
					cellTemplate: getScoreCellTemplate()
				}
			],
			onRegisterApi: function (gridApi) {
				service.gridApi = gridApi;
			}
		};
		return service.gridOptions;
	}

	var lists = {
		userList: [],
		groupList: [],
		caseList: []
	};


	// query other required entities if it's not done already. Is used when searching by caseId directly, when case list has not been queried
	function getRelatedData() {
		return $q(function (resolve, reject) {
			if (lists.userList.length === 0) {
				$q.all([
					APIEndpointService.user.query({limit: 10000}).$promise,
					APIEndpointService.group.query().$promise,
					APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.customerCase),
				]).then(function (res) {
					lists.userList = res[0];
					lists.groupList = res[1];
					lists.caseTypeList = res[2];
					resolve();
				}, function (err) {
					reject(err);
				});
			} else {
				resolve();
			}
		});
	}


	// query case as well as other required entities to make a complete case structure for grid
	function queryCase(caseParams) {
		return $q(function (resolve, reject) {
			if (lists.userList.length === 0) {
				$q.all([
					APIEndpointService.user.query({limit: 10000}).$promise,
					APIEndpointService.group.query().$promise,
					APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.customerCase),
					APICaseService.getCases(caseParams)
				]).then(function (res) {
					lists.userList = res[0];
					lists.groupList = res[1];
					lists.caseTypeList = res[2];
					resolve({
						userList: res[0],
						groupList: res[1],
						caseTypeList: res[2],
						caseList: res[3].data
					});
				}, function (err) {
					reject(err);
				});
			} else {
				APICaseService.getCases(caseParams).then(function (res) {
					resolve({
						userList: lists.userList,
						groupList: lists.groupList,
						caseTypeList: lists.caseTypeList,
						caseList: res.data
					});
				}, function (err) {
					reject(err);
				});
			}
		});
	}

	// remember query params so 'fetch more' button don't use new query params if they have been modified
	// by user since pressing 'get cases'
	var queryParamMemory = {};

	// options.gridData
	// options.next
	// options.take
	function queryGridData(options) {
		return $q(function (resolve, reject) {
			var take = options.take ? parseInt(options.take, 10): takeDefault;
			var height;
			service.queryParams.take = take;
			var queryParamsToUse;
			if (!options.next) {
				options.gridOptions.data.length = 0;
				options.gridOptions.data = [];
				service.queryParams.skip = 0;
				queryParamMemory.next = false; // first fetch with search options
				queryParamMemory = angular.copy(service.queryParams); // clear memory
				queryParamMemory.taken = service.queryParams.take; // remember how many to skip next time
				queryParamsToUse = service.queryParams;
			} else {
				// update memory and use those params
				queryParamMemory.take = take;
				queryParamMemory.next = true; // this is a "fetch more"
				queryParamMemory.skip = queryParamMemory.taken; // skip already fetched
				queryParamMemory.taken = queryParamMemory.taken + queryParamMemory.take; // add the new to fetch to skip next time
				queryParamsToUse = queryParamMemory;
			}

			service.isFetching = true;
			queryCase(queryParamsToUse).then(function (lists) {
				var newFetchedCases = formatCaseList(lists);
				height = options.gridOptions.data.length;
				options.gridOptions.data = options.gridOptions.data.concat(newFetchedCases);

				resolve({
					cases: newFetchedCases,
					paramsUsed: angular.copy(queryParamMemory)
				});
			}).finally(function () {
				service.isFetching = false;
			}).catch(reject);
		});
	}

	function updateCleanedCase(caseId) {
		const caseObj = service.gridOptions.data.find((c) => {
			return (c.caseId === caseId);
		});
		if (caseObj) {
			caseObj.currentOwnerName = '-';
		}
	}

	function formatCaseList(lists) {
		return lists.caseList.map(function (caseItem) {
			return formatCase(caseItem);
		});
	}

	// NOTE: Would be a lot better with a nice list of participants generated by server
	function getParticipants(users, caseItem) {
		var participantsRaw = [];
		var conversations = caseItem.data.conversations;
		var visitorId;
		conversations.forEach(function (conversation) {

			// This logic extracts all speakers and recipients from the conversation messages in the conversation.
			// But maybe it would be better to extract that data from the "participantJoined"-events in conversation.events?

			//occasionally we get null instead an array of messages
			_pull(conversation.messages, null);
			// _pull(conversation.events, null);

			conversation.messages.forEach(function (message) {
				// conversation.events.filter(event => event.type === 'conversationMessage').forEach(function (message) {
				message.recipients.forEach(function (recipient) {
					participantsRaw.push(recipient.userId);
				});
				if (message.speaker.visitId) {
					visitorId = message.speaker.visitId;
				} else if (message.speaker.userId) {
					participantsRaw.push(message.speaker.userId);
				}
			});
		});
		var participantsIds = _uniq(participantsRaw);
		var participantsParsed = [];
		participantsIds.forEach(function (participantId) {
			var user = _find(users, {data: {id: participantId}});
			var userName = user ? user.data.profile.displayName: '';
			if (participantId) {
				participantsParsed.push({
					userId: participantId,
					userName: userName
				});
			}
		});
		if (visitorId) {
			participantsParsed.push({
				userId: visitorId,
				userName: 'Visitor',
				userType: 'visitor'
			});
		}

		return participantsParsed;
	}

	function getConversations(caseItem) {
		return (caseItem && caseItem.data && caseItem.data.conversations && caseItem.data.conversations.length ? caseItem.data.conversations: []);
	}

	// Formats raw case item to be shown in grid. Original case item is available
	// in originalCaseItem
	function formatCase(caseItem) {
		var stateMap = [
			$translate.instant('caseBrowser.CASE_STATE_NEW'),
			$translate.instant('caseBrowser.CASE_STATE_ONGOING'),
			$translate.instant('caseBrowser.CASE_STATE_CLOSED')
		];
		var outcomeMap = [
			$translate.instant('caseBrowser.CASE_OUTCOME_POSITIVE'),
			$translate.instant('caseBrowser.CASE_OUTCOME_NEUTRAL'),
			$translate.instant('caseBrowser.CASE_OUTCOME_NEGATIVE'),
		];

		// Parse date from utc to user's time zone
		var openedTimeAdjusted = moment.utc(caseItem.data.createdAt).tz(SessionService.getUserTimezone()), //.utcOffset(SessionService.getUserTimezoneOffset()),
			closedTimeAdjusted = moment.utc(caseItem.data.closedAt).tz(SessionService.getUserTimezone()); //.utcOffset(SessionService.getUserTimezoneOffset());

		const hasClosure = caseItem.data.closure && caseItem.data.closure.name !== '' && caseItem.data.closure.name !== 'noReason';
		const userId = caseItem.data.ownedBy && caseItem.data.ownedBy.userId || '';
		return {
			opened: openedTimeAdjusted.format('L LT'),
			openedTime: openedTimeAdjusted.format('LT'),
			closedTime: caseItem.data.closedAt ? closedTimeAdjusted.format('LT'): '',
			openedDate: openedTimeAdjusted.format('L'),
			closedDate: caseItem.data.closedAt ? closedTimeAdjusted.format('L'): '',
			state: stateMap[caseItem.data.state],
			outcome: hasClosure ? outcomeMap[caseItem.data.closure.outcome]: '',
			satisfactionScore: caseItem.data.satisfactionScore,
			currentOwnerId: userId,
			currentOwnerName: VngageUtil.getItemName('user', lists.userList, userId),
			caseId: caseItem.data.id,
			group: VngageUtil.getItemName('group', lists.groupList, caseItem.data.groupId),
			closure: hasClosure ? caseItem.data.closure.name: '',
			caseType: VngageUtil.getItemName('case', lists.caseTypeList, (caseItem.data.type ? caseItem.data.type.id: '')),
			originalCaseItem: caseItem
		};
	}

	return service;
};
