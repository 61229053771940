export default function($timeout) {
    'ngInject';

    return {
        restrict: 'A',
        link: function (scope, element) {
            $timeout(function() {
                element[0].focus();
            }, 30);
        }
    };

};
