import CannedResponseAddModalController from './CannedResponseAddModalController';
import ImportModalController from '../../../common/vngageImportModal/ImportModalController';
import CannedResponseEditController from './CannedResponseEditController';
import CannedResponseListController from './CannedResponseListController';
import cannedResponseTranslations from './CannedResponseTranslations';
import CannedResponseListTemplate from './CannedResponseListTemplate';
import CannedResponseEditTemplate from './CannedResponseEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.configure.cannedResponse', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('CannedResponseAddModalController', CannedResponseAddModalController)
	.controller('ImportModalController', ImportModalController)
	.controller('CannedResponseEditController', CannedResponseEditController)
	.controller('CannedResponseListController', CannedResponseListController)
	.constant('cannedResponseTranslations', cannedResponseTranslations)

	.constant('cannedResponseModuleConfig', {
		state: 'root.configure.cannedResponse',
		access: 'Engage/CannedResponses'
	})

	.config(function ($stateProvider, $translateProvider, cannedResponseTranslations, cannedResponseModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(cannedResponseTranslations);

		$stateProvider.state(cannedResponseModuleConfig.state, {
			url: 'cannedresponse/',
			controller: 'CannedResponseListController as vm',
			templateUrl: CannedResponseListTemplate,
			data: {
				access: cannedResponseModuleConfig.access,
				ncyBreadcrumbLabel: 'Canned Response'
			},
			resolve: {
				dataLists: function (APIConfigurationSectionService, $q) {
					'ngInject';
					return $q((resolve, reject) => {
						$q.all({
							configSections: APIConfigurationSectionService.get([
								APIConfigurationSectionService.sectionMap.cannedResponse,
								APIConfigurationSectionService.sectionMap.customerCase
							], true)
						}).then((result) => {
							resolve({
								cannedResponse: result.configSections[APIConfigurationSectionService.sectionMap.cannedResponse],
								customerCase: result.configSections[APIConfigurationSectionService.sectionMap.customerCase]
							});
						}, (error) => {
							console.log('Error resolving lists: ', error);
							reject(error);
						});
					});
				}
			}
		});

		$stateProvider.state('root.configure.cannedResponse.edit', {
			url: ':id',
			controller: 'CannedResponseEditController as vm',
			templateUrl: CannedResponseEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				cannedResponse: function ($stateParams, dataLists, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(dataLists.cannedResponse, $stateParams.id);
				}
			}
		});
	});
