import React from 'react';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {hasError: false};
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		console.log('error', error);
		return {hasError: true};
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		this.props.logCrash(error, errorInfo);

	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			const style = {margin: 'auto'};
			return <h1 style={style}>Something went wrong.</h1>;
		}

		return this.props.children;
	}
}

ErrorBoundary.displayName = 'ErrorBoundary';
export default ErrorBoundary;
