import _find from 'lodash/find';

export default function () {
	'ngInject';

	return {
		remove: function (trustUrl, accountId, trustRelationships, trustedAccountIds, allAccountIds) {
			var trusts = [];

			allAccountIds.forEach(function (a) {
				var existing = _find(trustRelationships, function (t) {
					return t.data.trustedAccountId === a;
				});

				if (existing) {

					var stillTrusted = _find(trustedAccountIds, function (t) {
						return t === existing.data.trustedAccountId;
					});

					if (!stillTrusted) {
						trusts.push({
							method: 'DELETE',
							url: trustUrl.replace(':accountId', accountId) + existing.data.trustedAccountId
						});
					}
				}
			});

			return trusts;
		}
	};
};
