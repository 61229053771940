import CoBroDesktopTemplate from './CoBroDesktopTemplate';
import CoBroDesktopController from './CoBroDesktopController';
import DesktopTranslations from '../desktopDeux/DesktopTranslations';
import '../../common/VngageWorkspaceTranslationModule';

/*eslint-disable */
export default angular.module('vngageApp.coBroDesktop', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.constant('coBroDesktopModuleConfig', {
		state: 'root.cobrodesktop',
		// access: 'CoBroDesktop'	// Do we want to restrict access?
	})

	.controller('CoBroDesktopController', CoBroDesktopController)
	.constant('DesktopTranslations', DesktopTranslations)

	.config(($stateProvider, $translateProvider, coBroDesktopModuleConfig, DesktopTranslations, $urlRouterProvider, VngageWorkspaceTranslationServiceProvider) => {
		'ngInject';

		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(DesktopTranslations);

		$stateProvider.state(coBroDesktopModuleConfig.state, {
			url: '/cobrodesktop/',
			views: {
				content: {
					templateUrl: CoBroDesktopTemplate,
					controller: 'CoBroDesktopController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				loadConfiguration: function (DesktopService) {
					'ngInject';
					return DesktopService.getConfiguration();
				},
				desktopPlugins: function (WorkspaceSettingsService) {
					'ngInject';
					return WorkspaceSettingsService.getSections('desktopPlugins').catch((error) => {
						// Catch reject to avoid red error notification
						if (error && error.status !== 404) {
							// Only log errors != 404 (which is normal when workspaceSetting is missing)
							console.error('Could not get workspaceSettings:', error);
						}
					});
				}
			}
		});
	});
