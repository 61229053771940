import React from 'react';
import SpeakerTitle from '../../../components/SpeakerTitle.react';
import { injectIntl } from 'react-intl';
import createReactClass  from 'create-react-class';

export default injectIntl(createReactClass({

	render () {
		return (
			<span className="participant-writing">
				<strong>
					<SpeakerTitle name={this.props.name} title={this.props.title} isMe={this.props.isCurrentUser}/>
				</strong> {this.props.intl.formatMessage({id: 'participantTyping'})}
			</span>
		)
	}
}));
