import moment from 'moment';
import _each from 'lodash/each';
import languagesConfig from "../app/languagesConfig";

export default angular.module('vngageApp.workspaceTranslation', [
	'tmh.dynamicLocale'
])
	.constant('languagesConfig', languagesConfig)
	.provider('VngageWorkspaceTranslationService', function ($translateProvider, tmhDynamicLocaleProvider) {
		'ngInject';

		// Initial Angular i18n setup
		$translateProvider.useSanitizeValueStrategy('sanitizeParameters');
		$translateProvider.preferredLanguage('en');

		// Initial Angular l10n setup
		// "__webpack_public_path__" should have been set first thing in AppModule.js
		// (we can't use vngageConfig.staticWorkspaceUrl as normally done, as it's not yet set when initializing the provider, and we need to specify tmhDynamicLocaleProvider.localeLocationPattern() already here)
		const staticWorkspaceUrl = (typeof __webpack_public_path__ !== 'undefined' ? __webpack_public_path__ : (window.engage && window.engage.config && window.engage.config.staticWorkspaceUrl ? window.engage.config.staticWorkspaceUrl : ''));
		tmhDynamicLocaleProvider.localeLocationPattern(staticWorkspaceUrl + 'l10n/angular-locale-{{locale}}.js');
		//tmhDynamicLocaleProvider.defaultLocale('en');	// Default to english should not be needed, as it's already default and bundled with Angular

		const provider = {
			$get: instantiateService,
			addModuleTranslations: addTranslations // Makes it possible to also add translations in config
		};
		return provider;

		function instantiateService($translate, $q, tmhDynamicLocale, $locale, i18nService) {
			'ngInject';

			// Set initial default and fallback language...
			$translate.use('en-GB');
			$translate.fallbackLanguage('en-GB');

			function setWorkspaceLanguage(langCode) {
				const language = languagesConfig.lookupByLangCode(langCode);
				langCode = (language ? language.langCode: 'en-GB');
				if (languagesConfig.supportedLanguages.indexOf(langCode) === -1) {
					langCode = 'en-GB';
				}
				const langCodeShort = langCode.split('-')[0];

				// Set language in "angular-translate"
				$translate.use(langCode);

				// Set language in Angular's i18nService (used by e.g. ui-grid)
				i18nService.setCurrentLang(langCodeShort);

				// Set locale in moment
				try {
					moment.locale(langCodeShort);
				} catch (err) {
					moment.locale('en');
				}
				if (moment.locale() !== langCodeShort) {
					console.error('Error setting moment.locale() to "' + langCodeShort + '". The locale probably does not exist in the moment module bundle. Please check/update the webpack configuration and rebuild')
				}

				// There also may be an instance of moment exposed in global scope (not the same instance as the one imported through webpack)
				// Not clear if any component uses it, but switch locale on that as well, just to be sure...
				if (window && window.moment) {
					try {
						window.moment.locale(langCodeShort);
					} catch (err) {
						window.moment.locale('en');
					}
				}

				// Set angular locale (used by e.g. ui-bootstrap's datepicker-popup)
				// Using "angular-dynamic-locale", which is asynchronously lazy-loaded, so return a promise!
				// TODO (maybe): Bundle all languages' locales and overwrite the $locale-object instead to avoid an extra call, alt. cache the user's $locale in localStorage.
				// Or do we want the lazy-loading (like desktop-i18n)?
				// In that case, we may want to lazy-load all angular module translations ($translate) as well - those are a lot bigger...
				return $q(function (resolve, reject) {
					const currentCountryCode = ($locale && (typeof $locale.id === 'string') && $locale.id.split('-')[0]) || '';
					if (langCodeShort !== currentCountryCode) {
						tmhDynamicLocale.set(langCodeShort).then(() => {
							resolve();
						}, () => {
							// Failed to load locale [langCodeShort], fallback to 'en-us'
							tmhDynamicLocale.set('en-us').then(() => {
								resolve();
							}, () => {
								// Failed to set locale to fallback default 'en-us' as well
								// Should not happen as it should be already bundled in Angular!
								// But resolve anyway to avoid Workspace freezing...
								resolve();
							})
						});
					} else {
						resolve();
					}
				});
			}

			const service = {
				setWorkspaceLanguage: setWorkspaceLanguage
			};

			return service;
		}

		function addTranslations(moduleTranslations) {
			_each(moduleTranslations, function (value, key) {
				// console.log('addTranslations key, value:',key,value);
				$translateProvider.translations(key, value);
			});
		}

	});
