import DesktopPluginsAddModalTemplate from './DesktopPluginsAddModalTemplate.html';
import DesktopPluginsHandler from './DesktopPluginsHandler';
import _defaultsDeep from 'lodash/defaults';

export default function (desktopPluginsSettings, $scope, $state, $uibModal, Session, APIEndpointService, WorkspaceSettingsService, NotificationService) {
	'ngInject';

	const desktopPluginsSectionDefaults = {
		pluginsList: []
	};

	const desktopPluginsSection = desktopPluginsSettings || {};
	const vm = this;
	vm.desktopPluginsSection = _defaultsDeep(desktopPluginsSection, desktopPluginsSectionDefaults);

	// For testing...
/*
	vm._clearAllConfigSections = function () {
		WorkspaceSettingsService._clearAllConfigSections();
	};
*/

	vm.tableOptions = {
		stateLink: {
			state: 'root.desktopPlugins.edit',
			property: 'id'
		},
		createItem: {
			langKey: 'plugin.ADD_HEADER',
			method: addNew
		}
	};
	vm.tableColumns = [{
		header: 'Name',
		property: 'name',
		sortDefault: true,
		useInFilter: true
	}, {
		header: 'GUID',
		property: 'id',
		hidden: true,
		useInFilter: true
	}, {
		header: 'Enabled',
		property: 'enabled',
		type: 'tag',
		settings: {
			on: 'enabled',
			off: 'disabled',
			onType: 'success',
			offType: 'warning'
		},
		sortDefault: true,
		useInFilter: true
	}];

	function openAddModal() {
		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: DesktopPluginsAddModalTemplate,
			controller: 'DesktopPluginsAddModalController as vm',
			resolve: {
				desktopPluginsSection: () => vm.desktopPluginsSection
			}
		});
		modal.result.then(pluginsList => {
			vm.desktopPluginsSection.pluginsList = pluginsList;
		}).catch(error => {
			// Error or modal dismissed...
			if (error) {
				$state.reload();
			}
		});
	}

	function addNew() {
		$state.go('root.desktopPlugins').then(openAddModal);
	}
};

