import React from 'react';
import {connect} from 'react-redux';
import immutable from 'immutable'
import ServerActions from '../../actionCreators/ServerActions'
import ConversationActions from '../../actions/ConversationActions'
import AccountConfig from './../../utils/AccountConfig';
import ClosureMenu from './Case/ClosureMenu.react';
import ClosureButton from './Case/ClosureButton.react';
import CaseTypeMenu from './Case/CaseTypeMenu.react';

export const Footer = (props) => {
	const showCaseModal = () => {
		props.setActiveSection(props.conversationId, 'caseManager');
	};

	const okToClose = (AccountConfig.caseTypeHasForm(props.currentCaseType)
		&& props.forms.size > 0
		&& props.forms.every(form => form.get('isValid')))
		|| !AccountConfig.caseTypeHasForm(props.currentCaseType);

	const emailTranscript = AccountConfig.getEmailTranscriptConfig();
	const payload = {
		...props,
		alwaysEmailTranscript: emailTranscript.alwaysEmail,
	};

	const closureComponent = okToClose
		? <ClosureMenu {...payload}/>
		: <ClosureButton showCaseModal={showCaseModal}/>;

	return <div className="footer">
		<CaseTypeMenu {...payload} />
		{closureComponent}
	</div>
};


function mapStateToProps(state, ownProps) {
	const conversationId = ownProps.conversationId;
	const conversation = state.getIn(['conversations', conversationId]) || immutable.Map();

	return {
		forms: conversation.get('forms') || immutable.List(),
		currentCaseType: conversation.get('currentCaseType') || '',
		groupId: conversation.get('groupId'),
		newCaseAfterClose: conversation.getIn(['UI', 'newCaseAfterClose']) || false,
		emailConversationTranscript: conversation.getIn(['UI', 'emailConversationTranscript']) || false,
		caseId: conversation.get('caseId'),
		enabledCapabilities: conversation.getIn(['capabilities', 'enabled']) || immutable.Map(),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setActiveSection: (conversationId, section) => dispatch(ConversationActions.setActiveSection(conversationId, section)),
		toggleNewCaseAfterClose: (conversationId) => dispatch(ConversationActions.toggleNewCaseAfterClose(conversationId)),
		toggleEmailConversationTranscript: (conversationId) => dispatch(ConversationActions.toggleEmailConversationTranscript(conversationId)),

		updateCaseType: (conversationId, caseObj) => dispatch(ServerActions.updateCaseType(conversationId, caseObj)),
		closeCase: (conversationId, closureObj) => dispatch(ServerActions.closeCase(conversationId, closureObj)),
		putAwayConversation: (conversationId, caseId, reasonName) => dispatch(ServerActions.putAwayConversation(conversationId, caseId, reasonName)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
