export default {
    'en-GB': {
        form: {
            ADD_HEADER: 'New form',
            EDIT_HEADER: 'Edit form',
            LIST_HEADER: 'Forms',
            LIST_TITLE: 'List of forms for working site',
            LIST_DESCRIPTION: '',
            TEMPLATE: 'Template',
            TEMPLATE_DESC: 'Requires JSON syntax',
            NAME: 'Name',
            DESCRIPTION: 'Description',
            TEMPLATE_VALIDATION_ERROR: 'The JSON format is invalid.',
            EXAMPLE_TITLE: 'Valid JSON example',

            REMOVE_HEADER: 'Remove form "{{ name }}"',
            REMOVE_MESSAGE: 'Note that any existing references to this form from case types also will be removed.'
        }
    },
    'sv-SE': {
        form: {
            ADD_HEADER: 'New form',
            EDIT_HEADER: 'Edit form',
            LIST_HEADER: 'Forms',
            LIST_TITLE: 'List of forms for working site',
            LIST_DESCRIPTION: '',
            TEMPLATE: 'Template',
            TEMPLATE_DESC: 'Requires JSON syntax',
            NAME: 'Name',
            DESCRIPTION: 'Description',
            TEMPLATE_VALIDATION_ERROR: 'The JSON format is invalid.',
            EXAMPLE_TITLE: 'Valid JSON example',

            REMOVE_HEADER: 'Remove form "{{ name }}"',
            REMOVE_MESSAGE: 'Note that any existing references to this form from case types also will be removed.'
        }
    }
};
