import React from 'react';
import PluginToast from './PluginToast.react';

const PluginToastContainer = ({plugins, setSelectedPlugin, selectedPlugin}) => {

	return (
		<div className="plugin-toast-container">
			{plugins
				.filter(plugin => plugin.getIn(['notification', 'showToast']))
				.map((plugin, index) =>
					<PluginToast
						key={plugin.get('id') + index}
						pluginId={plugin.get('id')}
						name={plugin.get('name') + index}
						notification={plugin.get('notification')}
						setSelectedPlugin={setSelectedPlugin}
						selectedPlugin={selectedPlugin}
					/>)
			}
		</div>
	);

};
export default PluginToastContainer;