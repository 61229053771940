export default function ($uibModalInstance, APIEndpointService, accountConfiguration, NotificationService) {
    'ngInject';

    var vm = this;
    vm.newData = {
        profile: {
            timeZoneId: accountConfiguration.data.timeZoneId,
            language: accountConfiguration.data.language
        }
    };

    vm.cancelNew = $uibModalInstance.dismiss;
    vm.saveNew = saveNew;
    vm.saveNewAndSendEmail = saveNewAndSendEmail;

    function saveNew() {
        vm.isSaving = true;
		$uibModalInstance.freeze(true);
        APIEndpointService.user.create(vm.newData).$promise
            .then(function(res) {
				$uibModalInstance.close(res);
            })
            .catch(function() {
				$uibModalInstance.freeze(false);
            })
            .finally(function() {
                vm.isSaving = false;
            });
    }

    function saveNewAndSendEmail() {
        vm.isSaving = true;
		$uibModalInstance.freeze(true);
        APIEndpointService.user.create(vm.newData).$promise
            .then(function(res) {
                APIEndpointService.userSendIdActivationEmail.save({ id: res.data.id }).$promise.then(function () {
                    NotificationService.success({header: 'Success', body: 'Activation email sent'});
					$uibModalInstance.close(res);
                });
            })
            .catch(function() {
				$uibModalInstance.freeze(false);
            })
            .finally(function() {
                vm.isSaving = false;
            });
    }
};
