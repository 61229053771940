import React from 'react';
import {
	Button,
	Modal,
	Form,
} from 'react-bootstrap';
import {useGetTranslation} from './../../LangContext';

const SendPageModal = ({modalIsOpen, hideModal, pageTitle, setPageTitle, restricted, menuActions, ubTracking: {sendPage}}) => {
	const getTranslation = useGetTranslation();

	return (
		<Modal className="co-sendPageModal" show={modalIsOpen} onHide={() => hideModal()}>
			<Modal.Header closeButton>
				<Modal.Title>{getTranslation('coPageLinkModalTitle')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>{getTranslation('coPageLinkModalText')}</p>
				<br/>
				<Form.Group>
					<Form.Control
						name="link"
						type="text"
						value={pageTitle}
						onChange={(e) => setPageTitle(e.target.value)}
						disabled={restricted}
					/>
				</Form.Group>
				{restricted ?
					<span
						style={{color: 'red'}}>{getTranslation('coPageLinkDisabled')}</span>: null}
			</Modal.Body>
			<Modal.Footer>
				<Button disabled={restricted}
						onClick={(e) => menuActions('sendAction')}
						ub-track={'cobrowsing:' + sendPage}>
					{getTranslation('coPageLinkSendBtn')}
				</Button>

				<Button onClick={() => hideModal()}>{getTranslation('btnCancel')}</Button>
			</Modal.Footer>
		</Modal>

	);
};
export default SendPageModal

