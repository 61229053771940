import React from 'react';

const PanelPlaceholder = () => {
	return (
		<div>
			<div className="loader-visitor-meta">
				<div className=" loader-placeholder inverse medium"/>
				<div className=" loader-placeholder inverse short"/>
			</div>
			<div className="loader-toolbar">
				<div className="loader-tool"/>
				<div className="loader-tool"/>
				<div className="loader-tool"/>
				<div className="loader-tool"/>
			</div>
			<div className="loader-content inverse">
				<div className=" loader-placeholder inverse short"/>
				<div className=" loader-placeholder inverse long"/>
			</div>
			<div className="loader-content">
				<div className=" loader-placeholder medium"/>
				<div className=" loader-placeholder long"/>
			</div>
			<div className="loader-content inverse">
				<div className=" loader-placeholder inverse medium"/>
				<div className=" loader-placeholder inverse short"/>
			</div>
			<div className="loader-content">
				<div className=" loader-placeholder medium"/>
				<div className=" loader-placeholder long"/>
			</div>
			<div className="loader-content inverse">
				<div className=" loader-placeholder inverse medium"/>
				<div className=" loader-placeholder inverse short"/>
			</div>
			<div className="loader-footer-container">
				<div className="loader-input"/>
				<div className="loader-options"/>
				<div className="loader-action"/>
			</div>
		</div>
	);
};

export default PanelPlaceholder

