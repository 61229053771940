export default {
	'en-GB': {
		statistics: {
			views: {
				sites: {
					MENU_LABEL: 'Sites',
					TITLE: 'Sites',
					TABLE_HEADING: 'Hour report',
					TIME_LINE_GRAPH_HEADING: 'Visits by hour',
					measures: {
						siteName: {
							NAME: 'Site',
							TITLE: 'The name of the site presenting the chat option',
							LONG_DESCRIPTION: ''
						},
						numberOfUniqueVisits: {
							NAME: 'Visits',
							TITLE: 'Number of visits to the site',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the unique visitor session ids'
						},
						numberOfUniqueVisitors: {
							NAME: 'Visitors',
							TITLE: 'Number of unique visitors (Unique per hour)',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of unique visitor ids'
						},
						numberOfOpportunityEvaluations: {
							NAME: 'Navigations',
							TITLE: 'Number of navigations for the visitors',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the tracked navigation events where opportunities are evaluated'
						},
						numberOfOpportunities: {
							NAME: 'Opportunity hits',
							TITLE: 'Hits on opportunities',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of opportunities found where it\'s possible to act and/or present options to the visitors'
						},
						numberOfQueueAdded: {
							NAME: 'Requests',
							TITLE: 'Number of queued assistance requests',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of visitors added to a queue waiting for assistance'
						},
						numberOfQueueDialogs: {
							NAME: 'Dialogs',
							TITLE: 'Number of dialogs with customer service',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of dialogs picked from the queues'
						},
						numberOfCasesClosed: {
							NAME: 'Cases closed',
							TITLE: 'Number of cases closed',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of cases closed'
						},
						numberOfPurchaseConfirmations: {
							NAME: 'Purchases',
							TITLE: 'Number of purchases - Percent of visitors who purchased',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of registered purchase confirmations. PS! There must be a configured POI of type Purchase confirmation!'
						},
						numberOfRelatedAssistedPurchasePois_sameVisit: {
							NAME: 'Ass. Pur. (same visit)',
							TITLE: 'Number of purchases where the visitor received assistance during the same visit - Percent of cases related to purchases',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of registered purchase confirmations where assistance was provided during the same visit.(A case is related to the same visit) PS! There must be a configured POI of type Purchase confirmation!'
						},
						numberOfRelatedAssistedPurchasePois_6Hours: {
							NAME: 'Ass. Pur. (6hours)',
							TITLE: 'Number of purchases where the visitor received assistance within 6hours - Percent of cases related to purchases',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of registered purchase confirmations where assistance was provided to the visitor within 6hours. (A case that has been engaged in or closed is related to the same visitor) PS! There must be a configured POI of type Purchase confirmation!'
						}
					},
					barFilters: {
						siteName: {
							HEADING: 'Sites'
						}
					}
				}
			}
		}
	},
	'fr-FR': {
		statistics: {
			views: {
				sites: {
					MENU_LABEL: 'Sites',
					TITLE: 'Sites',
					TABLE_HEADING: 'Rapport horaire',
					TIME_LINE_GRAPH_HEADING: 'Visites par heure',
					measures: {
						siteName: {
							NAME: 'Site',
							TITLE: 'Nom du site proposant l\'option de chat',
							LONG_DESCRIPTION: ''
						},
						numberOfUniqueVisits: {
							NAME: 'Visites',
							TITLE: 'Nombre de visites sur le site',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente les identifiants  de session uniques des visiteurs'
						},
						numberOfUniqueVisitors: {
							NAME: 'Visiteurs',
							TITLE: 'Nombre de visiteurs uniques (unique par heure)',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre d\'identifiants de visiteurs uniques'
						},
						numberOfOpportunityEvaluations: {
							NAME: 'Navigations',
							TITLE: 'Nombre de navigations des visiteurs',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente les événements de navigation suivis où des opportunités sont évaluées'
						},
						numberOfOpportunities: {
							NAME: 'Opportunités saisies',
							TITLE: 'Opportunités saisies',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre d\'occasions lors desquelles il est possible d\'agir et / ou de présenter des options aux visiteurs'
						},
						numberOfQueueAdded: {
							NAME: 'Demandes',
							TITLE: 'Nombre de demandes d\'assistance en attente',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de visiteurs ajoutés à une file d\'attente '
						},
						numberOfQueueDialogs: {
							NAME: 'Dialogues',
							TITLE: 'Nombre de dialogues avec le service clients',
							LONG_DESCRIPTION: 'Ce nombre est compté par heure et représente le nombre de dialogues sélectionnés dans les files d\'attente'
						},
						numberOfCasesClosed: {
							NAME: 'Dossiers clôturés',
							TITLE: 'Nombre de dossiers clôturés',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de dossiers clôturés'
						},
						numberOfPurchaseConfirmations: {
							NAME: 'Achats',
							TITLE: 'Nombre d\'achats - Pourcentage de visiteurs ayant effectué un achat',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de confirmations d\'achat enregistrées où une assistance a été fournie au visiteur dans un intervalle de 6 heures. (Un dossier qui a été ouvert ou fermé est lié au même visiteur) PS ! Il doit y avoir un POI configuré de type Confirmation d\'achat !'
						},
						numberOfRelatedAssistedPurchasePois_sameVisit: {
							NAME: 'Ach. Aid. (Même visite)',
							TITLE: 'Nombre d\'achats pour lesquels le visiteur a reçu de l\'aide au cours de la même visite - Pourcentage de dossiers liés aux achats',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de confirmations d\'achat enregistrées pour lesquelles une assistance a été fournie au cours de la même visite. (Un dossier est lié à la même visite) PS ! Il doit y avoir un POI configuré de type Confirmation d\'achat !'
						},
						numberOfRelatedAssistedPurchasePois_6Hours: {
							NAME: 'Ach. Aid. (6 heures)',
							TITLE: 'Nombre d\'achats pour lesquels le visiteur a reçu de l\'aide dans les 6 heures - Pourcentage de dossiers liés à des achats',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de confirmations d\'achat enregistrées pour lesquelles une assistance a été fournie au visiteur dans les 6 heures. (Un dossier ayant été ouvert ou clôturé est lié au même visiteur) PS ! Il doit y avoir un POI configuré de type Confirmation d\'achat !'
						}
					},
					barFilters: {
						siteName: {
							HEADING: 'Sites'
						}
					}
				}
			}
		}
	}
};
