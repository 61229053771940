export default function (accountList, SystemRolesService, APIEndpointService, NotificationService, roleClaim) {
	'ngInject';

	var vm = this;

	vm.formattedSelectableAccounts = SystemRolesService.formatSelectableAccounts(accountList);
	vm.pushToAccounts = pushToAccounts;
	vm.removeFromAccounts = removeFromAccounts;
	vm.selectedAccounts = [];
	vm.roleClaim = roleClaim;

	function pushToAccounts() {
		APIEndpointService.systemConfRoleClaimAttach.save({id: vm.roleClaim.id}, vm.selectedAccounts)
			.$promise
			.then(pushSuccess, pushFail);
	}

	function pushSuccess() {
		NotificationService.success({
			header: 'Success',
			body: 'Added the role "' + vm.roleClaim.displayName + '" to account(s)'
		});
	}

	function pushFail() {
		NotificationService.error({
			header: 'Error',
			body: 'There was an error when adding the role "' + vm.roleClaim.displayName + '"'
		});
	}

	function removeFromAccounts() {
		APIEndpointService.systemConfRoleClaimDetach.save({id: vm.roleClaim.id}, vm.selectedAccounts)
			.$promise
			.then(function () {
				NotificationService.success({
					header: 'Success',
					body: 'Removed the role "' + vm.roleClaim.displayName + '" from account(s)'
				});
			}, function () {
				NotificationService.error({
					header: 'Error',
					body: 'There was an error when removing the role"' + vm.roleClaim.displayName + '"'
				});
			});
	}
};
