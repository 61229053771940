import {createSelector} from 'reselect';
import immutable from 'immutable';
import Format from '../utils/Format';

const excludedVisitorClaimSources = ['commServer', 'turnServerAuthenticationHash', 'User', 'VisitorIdentification'];
const excludedPluginClaimSources = ['turnServerAuthenticationHash', 'User', 'VisitorIdentification'];

//
// CLAIMS
//

const extractSource = (claim) => (claim.get('source') || '').split('/').pop();

const removeHiddenClaimsFilter = (claim) => claim.get('type').indexOf('__') !== 0;	// [TL] Used for filtering out hidden claims (type starting with '__')

const getClaimByType = (claims, type) => {
	return claims.find(claim => claim.get('type').toLowerCase() === type.toLowerCase());
};

const getClaim = (claims, type, source = 'Visitor') => {
	return claims.find(claim => claim.get('type') === type && extractSource(claim) === source);
};

const getCustomerId = (visitorProfile) => {
	const claims = visitorProfile.getIn(['data', 'claims']) || immutable.List();
	const claim = getClaimByType(claims, 'CustomerId') || immutable.Map();
	return claim.getIn(['values', 0]) || '';
};

//
// Claims for visitorProfile Form
//
const getFormattedClaimsByStatus = (status, claims) => {
	return claims
		.filter(claim => claim.status === status)
		.map(({type, updatedValue, source}) => {
			return {
				type,
				values: [updatedValue],
				source
			};
		});
};

const getUpdatedClaims = claims => getFormattedClaimsByStatus('updated', claims);
const getNewClaims = claims => getFormattedClaimsByStatus('new', claims);
//
// visitorProfile Form END
//

const getFilteredClaims = (visitorProfile, exclusions) => {
	return (visitorProfile.getIn(['data', 'claims']) || immutable.List())
		.filter((claim) => !exclusions.includes(extractSource(claim)));
};

const createPrettyClaim = (claim) => {
	return immutable.Map({
		type: Format.getClaimNameBySource(claim.get('type'), claim.get('source')),
		value: Format.getClaimValueByType(claim.get('type'), claim.getIn(['values', 0])),
	});
};

const getClaimFieldsForQueue = (visitorProfile) => {
	return getFilteredClaims(visitorProfile, excludedVisitorClaimSources)
		.filter(removeHiddenClaimsFilter)    // [TL] Remove hidden claims from queue
		.map(claim => createPrettyClaim(claim)).filter(claim => !!claim.get('type'));
};

const getClaimFieldsForPlugin = (visitorProfile) => {
	return getFilteredClaims(visitorProfile, excludedPluginClaimSources)
		.map(claim => claim.set('source', extractSource(claim)))
		.filter(claim => {
			const source = claim.get('source');
			return (source === 'commServer' && (claim.get('type') === 'ipaddress' || claim.get('type') === 'CustomerId'))
				|| source !== 'commServer';
		});
	// .filter(removeHiddenClaimsFilter)	// [TL] Should hidden claims be exposed to plugins? I think so for now...
};

const getClaimsForMessage = (visitorProfile) => getFilteredClaims(visitorProfile, excludedVisitorClaimSources)
	.filter(removeHiddenClaimsFilter);	// [TL] Remove hidden claims from message

// has claims that are valid to show
const hasClaims = (visitorProfile) => {
	if (!immutable.isImmutable(visitorProfile)) {
		return false;
	}
	return getFilteredClaims(visitorProfile, excludedVisitorClaimSources)
		.filter(removeHiddenClaimsFilter)	// [TL] Remove hidden claims when deciding if a profile has any claims to show
		.size > 0;
};

const getVisitorIdentificationClaims = (visitorProfile) => {
	const claims = visitorProfile.getIn(['data', 'claims']) || immutable.List();
	return (claims.filter(claim => extractSource(claim) === 'VisitorIdentification') || immutable.List())
		.filter(removeHiddenClaimsFilter);	// [TL] Remove hidden "VisitorIdentification"-claims as well
};

const getOtherClaimsByCategory = (visitorProfile) => {
	const otherClaims = getFilteredClaims(visitorProfile, [...excludedVisitorClaimSources, 'Visitor'])
		.filter(removeHiddenClaimsFilter);	// [TL] Remove hidden claims "otherClaims". Not sure what these are, but as they may be displayed in the UI, hide them just in case...

	return otherClaims.reduce((claimsObj, claim) => {
		const category = extractSource(claim);
		const prettyClaim = createPrettyClaim(claim);
		if (prettyClaim.get('type')) {
			// only add field if the type is not empty
			return claimsObj.update(category, categoryContent => (categoryContent || immutable.List()).push(createPrettyClaim(claim)));
		} else {
			return claimsObj;
		}
	}, immutable.Map());
};

//
// VisitorProfile
//
const getVisitorProfile = (state, conversationId) => {
	const visitId = state.getIn(['conversations', conversationId, 'visitId']) || '';
	return state.getIn(['visitorProfiles', visitId]) || immutable.Map();
};

const getVisitorTitle = (visitorProfile) => {
	const channel = getVisitorChannel(visitorProfile);
	let title;
	let field;
	let emailFields;
	let emailAddressField;

	const claims = visitorProfile.getIn(['data', 'claims']) || immutable.List();
	switch (channel) {
		case 'facebook-messenger':
			field = claims.find(claim => extractSource(claim) === 'facebook-messenger' && claim.get('type') === 'facebook_name') || immutable.Map();
			title = field.getIn(['values', 0]) || channel;
			break;
		case 'sms':
			field = claims.find(claim => extractSource(claim) === 'sms' && claim.get('type') === 'ChannelId') || immutable.Map();
			title = field.getIn(['values', 0]) || channel;
			break;
		case 'Whatsapp':
			title = channel;
			break;
		case 'Telephone':
			title = channel;
			break;
		case 'email':
			emailFields = visitorProfile.getIn(['data', 'claims', 'email', 'fields']) || immutable.List();
			emailAddressField = emailFields.find(field => field.get('type') === 'email_email') || immutable.Map();
			title = emailAddressField.getIn(['values', 0]) || channel;
			break;

		default:
			title = '';
			break;
	}
	return title;
};

const getVisitorChannel = (visitorProfile) => {
	const channel = visitorProfile.getIn(['meta', 'os', 'channel']) || '';
	if (channel) {
		return channel;
	} else {
		// if no channel info available use first claim
		const firstClaim = getFilteredClaims(visitorProfile, excludedVisitorClaimSources).first() || immutable.Map();
		return extractSource(firstClaim);
	}
};


const getVisitorIdentificationString = (visitorProfile) => {
	const claims = (visitorProfile.getIn(['data', 'claims']) || immutable.List())
		.filter(removeHiddenClaimsFilter)    // [TL] Should visitorIdentification-claims starting with '__' be hidden? May be visible in UI, so hide for now...
		.filter(claim => extractSource(claim) === 'VisitorIdentification') || immutable.List();
	const visitorIdentificationClaim = claims.find(claim => claim.get('type').toLowerCase() === 'pnr') || claims.get(0) || immutable.Map();
	return visitorIdentificationClaim.getIn(['values', 0]) || '';
};


const getVisitorProfileForm = (visitorProfile) => {
	const claims = visitorProfile.getIn(['data', 'claims']) || immutable.Map();
	const claimsPatch = visitorProfile.get('claimsPatch') || immutable.List();
	const visitorClaims = (claims.filter(claim => extractSource(claim) === 'Visitor') || immutable.List())
		.filter(removeHiddenClaimsFilter);	// [TL] Remove hidden claims from profileForm
	const getPatchedClaim = claim => {
		return claimsPatch.find(patch => patch.get('type') === claim.get('type')) || immutable.Map();
	};

	return visitorClaims.map(claim => {
		const patchedClaim = getPatchedClaim(claim);
		// add updatedValue from patch or use original value
		claim = claim.set('updatedValue', patchedClaim.get('updatedValue') || claim.getIn(['values', 0]));
		// add status from patch or set to original
		claim = claim.set('status', patchedClaim.get('status') || 'original');
		return claim;
	})
		// add new claims from patch
		.concat(claimsPatch.filter(claim => claim.get('status') === 'new'));
};

const getQueuedVisitorClaims = createSelector([getClaimFieldsForQueue],
	(claims) => claims);

const getQueuedVisitorTitle = createSelector([getVisitorTitle],
	(title) => title);

const getQueuedVisitorChannel = createSelector([getVisitorChannel],
	(channel) => channel);


export default {
	// helpers
	excludedVisitorClaimSources,
	extractSource,

	getClaim,
	getCustomerId,
	getFormattedClaimsByStatus,
	getUpdatedClaims,
	getNewClaims,

	getFilteredClaims,
	createPrettyClaim,
	getClaimFieldsForQueue,
	getClaimFieldsForPlugin,
	getVisitorChannel,
	// non memoized selectors
	getVisitorProfile,
	getVisitorTitle,
	getClaimsForMessage,
	hasClaims,
	getVisitorIdentificationString,
	getVisitorProfileForm,
	getVisitorIdentificationClaims,
	getOtherClaimsByCategory,
	// Selectors
	getQueuedVisitorClaims,
	getQueuedVisitorTitle,
	getQueuedVisitorChannel,

};

