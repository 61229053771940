import AppDispatcher from '../dispatcher/AppDispatcher';
import AppConstants from '../../../components/constants/AppConstants';

const ProfileActions = AppConstants.Profile;

export default {

	profileIdentified (profile) {
		AppDispatcher.handleServerAction({
			type: ProfileActions.REQUEST_PROFILE_SUCCESS,
			profile: profile
		});
	}
};

