export default function (IdentityService, SessionService, vngageConfig, $http, $interval) {
	'ngInject';

	return {
		start: function () {
			IdentityService.authorizationDataLoadedPromise().then(() => {
				const ttl = SessionService.getSessionTimeToLive() || 300;
				let isNooping = false;

				$interval(() => {
					if (isNooping) {
						return;
					}
					isNooping = true;

					$http.get(vngageConfig.restUrl + 'command/noop')
						.catch(() => {})
						.finally(() => {
							isNooping = false;
						});
				}, (ttl * 1000) * 0.75);
			});
		}
	};
}
