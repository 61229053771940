export default {
	'en-GB': {
        configurationSet: {
            ADD_HEADER: 'Add new configuration Set',
            SAVE_HEADER: 'Save new configuration set',
            PUBLISH_HEADER: 'Publish configuration set',
            EDIT_HEADER: 'Create new configuration set',
            LIST_HEADER: 'Configuration Sets',
            NAME: 'Name',
            COMMENT: 'Comments',
            SET: 'Set',
            SAVE_PUBLISH: 'Save and publish',
            PUBLISH: 'Publish',
            UPDATE: 'Update',
            SAVE: 'Create'
        }
    },
    'sv-SE': {
        configurationSet: {
            ADD_HEADER: 'Add new configuration Set',
            SAVE_HEADER: 'Save new configuration set',
            PUBLISH_HEADER: 'Publish configuration set',
            EDIT_HEADER: 'Create new configuration set',
            LIST_HEADER: 'Configuration Sets',
            NAME: 'Name',
            COMMENT: 'Comments',
            SET: 'Set',
            SAVE_PUBLISH: 'Save and publish',
            PUBLISH: 'Publish',
            UPDATE: 'Update',
            SAVE: 'Create'
        }
    }
};
