var Timer = (function () {

	function Timer(interval, failTime, func, failFunc) {

		this.delayTimer = null;
		this.failTimer = null;

		//set default options
		this.interval = interval || 2800;
		this.failTime = failTime || 20000;
		this.func = func || function () {
			};
		this.failFunc = failFunc || function () {
			};

		//initialise it!
		this.start();
	}

	Timer.prototype.setTimer = function () {
		this.delayTimer = setTimeout(this.func, this.interval);
	};

	Timer.prototype.setFailTimer = function () {
		var timer = this;

		timer.failTimer = setTimeout(function () {
			clearTimeout(timer.delayTimer);
			timer.failFunc();
		}, timer.failTime);
	};

	Timer.prototype.restart = function () {
		if (psPlugin.legacyHasStopped === true) { return; }
		this.delayTimer = clearTimeout(this.delayTimer);
		this.failTimer = clearTimeout(this.failTimer);
		this.setTimer();
		this.setFailTimer();
	};

	Timer.prototype.stop = function stop() {
		this.delayTimer = clearTimeout(this.delayTimer);
		this.failTimer = clearTimeout(this.failTimer);
	};

	Timer.prototype.start = function start() {
		if (psPlugin.legacyHasStopped === true) { return; }
		this.setTimer();
		this.setFailTimer();
	};

	return Timer;

}());

export default Timer;
