export default {
    columns: [
        {
            header: 'Name',
            property: 'data.displayName',
            sortDefault: true,
            useInFilter: true,
            width: '36%'
        },
        {
            header: 'Account id',
            property: 'data.id',
            useInFilter: true,
            width: '32%'
        },
        {
            header: 'Active',
            property: 'data.enabled',
            type: 'tag',
            sortReverse: true,
            settings: {
                on: 'systemAccount.ACCOUNT_ACTIVE',
                off: 'systemAccount.ACCOUNT_INACTIVE',
                onType: 'success',
                offType: 'warning'
            },
            width: '5%'
        },
        {
            header: 'Tags',
            property: 'data.tags',
            useInFilter: true,
            type: 'tags',
            width: '26%'
        }
    ],
    options: {
        stateLink: {
            state: 'root.system.account.edit',
            property: 'data.id'
        },
        createItem: {
            langKey: 'systemAccount.ADD_HEADER'
        },
        itemsPerPage: 50,
        hideRemove: true
    }
};
