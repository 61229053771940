import moment from 'moment-timezone';
export default function ($scope, StatisticsExportService, NotificationService) {
	'ngInject';

	var vm = $scope.vm = this;

	vm.dateToday = moment().format('YYYY-MM-DD');

	vm.params = {
		from: vm.dateToday,
		to: vm.dateToday,
		type: 'metrics',
		entityType: 'ConversionFunnel',
		extension: 'csv',
		granularity: 'hour'
	};

	vm.downloadFiles = function () {
		var links = document.querySelectorAll('a.download-link');

		[...links].forEach(function(link, index){
			setTimeout(function(){
				link.click();
			}, 100 + 500 * index);
		});
	};

	vm.types = [
		'metrics',
		'tracking',
		'trackingDto'
	];

	vm.entityTypes = StatisticsExportService.getEntityTypes();

	var firstTime = true;

	$scope.$watch('vm.params', function(){
		vm.files = StatisticsExportService.getFilenames(vm.params);

		if (!firstTime){
			NotificationService.success({
				body: 'The links have been updated with the selected date range.'
			});
		}
		firstTime = false;
	}, true);


	vm.openDatepicker = function($event, type) {
		$event.preventDefault();
		$event.stopPropagation();

		if (type === 'from') {
			vm.dateFromOpened = true;
		} else if (type === 'to') {
			vm.dateToOpened = true;
		}
	};


}
