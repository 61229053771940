export default {
    'en-GB': {
        solution: {
            ADD_HEADER: 'New solution',
            EDIT_HEADER: 'Edit solution',
            LIST_HEADER: 'Solutions',
			LIST_TITLE: 'List of solutions for working site',
			LIST_DESCRIPTION: 'Solutions are actions taken upon opportunities for customer service, sales, conversion and lead generation. It could be to display a banner, create a lead in a CRM system or e.g. show customized content.',
            NAME: 'Name',
            NAME_DESC: 'The display name for this solution',
            NAME_PLACEHOLDER: 'Name',
            GROUP: 'Group relation',
            CASE_TYPE: 'Case type relation',
            BANNER_NAME: 'Active banner',
            OPPORTUNITY_NAME: 'Opportunity relation',
            SOLUTION_MESSAGE: 'Solution message',
			SOLUTION_MESSAGE_DESC: 'Message displayed in queue',
			SOLUTION_MESSAGE_PLACEHOLDER: 'Message',

            REMOVE_HEADER: 'Remove solution "{{ name }}"',
            REMOVE_MESSAGE: 'Note that any existing references to this solution from opportunities also will be removed.',

			MESSAGE: 'Message',
			QUEUE_MESSAGE: 'Message displayed in queue',
			ADD_EXPLANATION: '<p><strong>You are creating a new solution</strong></p><p>To make use of the solution, please add one or more banners and provide a <strong>default group</strong> and <strong>case type.</strong></p><p>The solution can then be used by opportunities.</p>'

        }
	},
	'sv-SE': {
		solution: {
			ADD_HEADER: 'New solution',
			EDIT_HEADER: 'Edit solution',
			LIST_HEADER: 'Solutions',
			LIST_TITLE: 'List of solutions for working site',
			LIST_DESCRIPTION: 'Solutions are actions taken upon opportunities for customer service, sales, conversion and lead generation. It could be to display a banner, create a lead in a CRM system or e.g. show customized content.',
			NAME: 'Name',
			NAME_DESC: 'The display name for this solution',
			NAME_PLACEHOLDER: 'Name',
			GROUP: 'Group relation',
			CASE_TYPE: 'Case type relation',
			BANNER_NAME: 'Active banner',
			OPPORTUNITY_NAME: 'Opportunity relation',
			SOLUTION_MESSAGE: 'Solution message',
			SOLUTION_MESSAGE_DESC: 'Message displayed in queue',
			SOLUTION_MESSAGE_PLACEHOLDER: 'Message',

			REMOVE_HEADER: 'Remove solution "{{ name }}"',
			REMOVE_MESSAGE: 'Note that any existing references to this solution from opportunities also will be removed.',

			MESSAGE: 'Message',
			QUEUE_MESSAGE: 'Message displayed in queue',
			ADD_EXPLANATION: '<p><strong>You are creating a new solution</strong></p><p>To make use of the solution, please add one or more banners and provide a <strong>default group</strong> and <strong>case type.</strong></p><p>The solution can then be used by opportunities.</p>'
		}
	}
};
