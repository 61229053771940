import LogActions from '../actions/LogActions';
import ConversationActions from '../actions/ConversationActions';
import Message from './Message';
import Localized from './Localized';
import Navigation from './Navigation';


const coBrowseFromVisitorProfile = (url) => (dispatch, getState) => {
	const coBro = getState().get('coBro');

	if (!coBro.get('paused')) {
		// if not paused, pause
		dispatch(toggleCoBroPause());
	}
	// treat the navigation as it was from the history menu
	dispatch(pageLoaded(url, 'menu'));

};


const sendDomUploadRequest = () => (dispatch, getState) => {

	const coBro = getState().get('coBro');

	if (!coBro.get('paused') && coBro.get('domUploadStatus') === 'ready') {
		const conversationId = coBro.get('conversationId');
		dispatch(LogActions.logAc('sendDomUploadRequest', {conversationId}));
		// show pending spinner
		dispatch(ConversationActions.setCoBroDomUploadStatus('pending'));

		// send domUpload message
		dispatch(Message.sendDomUploadRequest(conversationId));
	}
};

const setCoBro = (conversationId, status) => (dispatch, getState) => {
	const coBroConversationId = getState().getIn(['coBro', 'conversationId']);
	const doChange = status === 'enter' && conversationId !== coBroConversationId
		|| status === 'exit' && conversationId === coBroConversationId;

	if (doChange) {
		dispatch(toggleCoBro(conversationId, true));
	}

};

const sendCoBroStatusMessage = (conversationId, enabled) => (dispatch) => {
	// Hacky solution: Notify visitor of changed coBro status using MetadataExchange-messages
	dispatch(Message.sendMetadataExchange(conversationId, {
		type: 'SYSTEM:coBroStatusChanged',
		enabled
	}));
};

const toggleCoBro = (conversationId, silent = false) => (dispatch, getState) => {
	const coBroConversationId = getState().getIn(['coBro', 'conversationId']);
	const enterCoBro = (conversationId !== coBroConversationId);

	dispatch(LogActions.logAc('toggleCoBro', {conversationId, coBroConversationId: coBroConversationId}));

	// position panel
	const nextPanelPosition = (enterCoBro ? 'coBro' : 'normal');
	dispatch(Navigation.repositionPanel(conversationId, nextPanelPosition));

	if (!silent) {
		const message = (enterCoBro ? 'sysMsgEnteredCoBro' : 'sysMsgLeftCoBro');
		dispatch(Localized.sendLocalizedNote(conversationId, message));
	}

	// Notify visitor that coBro status changed...
	dispatch(sendCoBroStatusMessage(conversationId, enterCoBro));

	if (enterCoBro && coBroConversationId !== '') {
		// When active coBro is switching from another conversation: Update that one as well...
		if (!silent) {
			// switch cobrowsing panels, send exit note to the one closing
			dispatch(Localized.sendLocalizedNote(coBroConversationId, 'sysMsgLeftCoBro'));
		}
		dispatch(sendCoBroStatusMessage(coBroConversationId, false));
	}

	const conversation = getState().getIn(['conversations', conversationId]);
	if (enterCoBro && conversation.get('lastNavigationIsDomUpload')) {
		// entering coBro, req domUpload if last nav url was domUpload
		// domUpload url might be dead so better req it again
		dispatch(sendDomUploadRequest());
	}
};

const toggleCoBroPause = () => (dispatch, getState) => {
	dispatch(ConversationActions.toggleCoBroPause());

	const domCoBrowsing = getState().getIn(['visitorSettings', 'domCoBrowsing']);
	const isPaused = getState().getIn(['coBro', 'paused']);
	const restricted = getState().getIn(['coBro', 'restricted']);
	const isBlocked = getState().getIn(['coBro', 'isBlocked']);
	const coBroConversationId = getState().getIn(['coBro', 'conversationId']);
	const conversation = getState().getIn(['conversations', coBroConversationId]);

	if (domCoBrowsing && !isPaused && !restricted && !isBlocked && conversation.get('lastNavigationIsDomUpload')) {
		// Unpausing when domCoBrowsing === true
		// Should we always request a DOM if conversation.get('lastNavigationIsDomUpload')?
		dispatch(sendDomUploadRequest());
	}
};


const pageLoaded = (url, updateType = 'navigation') => (dispatch, getState) => {
	let coBro = getState().get('coBro');
	const coBroConversationId = coBro.get('conversationId');

	// if no navigations or new navigation then update cobro url
	if (coBro.get('navigation').size === 0 || url !== coBro.get('navigation').last().get('url')) {
		dispatch(ConversationActions.updateCoBroUrl(url, 'agent', updateType));
		// get coBro again
		coBro = getState().get('coBro');
		// send navigation if ok to do so
		if (!coBro.get('doNotDisturb') && !coBro.get('paused') && !coBro.get('isBlocked')) {
			dispatch(Message.sendDomNavigation(coBroConversationId, url));
		}
	}
};

const timeout = (ms) => {
	return new Promise(resolve => setTimeout(resolve, ms));
};

const updateDomHighlight = (conversationId, targetData) => async (dispatch) => {
	try {
		dispatch(ConversationActions.setCoBroHighlightMode(false));
		await dispatch(Message.sendDomHighlight(conversationId, targetData));
	} catch (error) {
		dispatch(LogActions.netCallError(0, {
			name: 'handleHighlight',
			error,
			conversationId,
			targetData
		}));
		// toggle highlightMode with a delay to reset highlight
		await timeout(1000);
		dispatch(ConversationActions.setCoBroHighlightMode(true));
		dispatch(ConversationActions.setCoBroHighlightMode(false));
	}
};


export default {
	coBrowseFromVisitorProfile,
	sendDomUploadRequest,
	setCoBro,
	toggleCoBro,
	toggleCoBroPause,
	pageLoaded,
	updateDomHighlight
};

