export default function ($scope, $state, $stateParams, group, groupType) {
	'ngInject';

	$state.transitionTo('root.configure.group.edit.details', $stateParams);

	$scope.$on('resetResettables', () => {
		vm.formWrapper.$setPristine();
		group.$get().catch(() => {
		});
	});

	var vm = this;
	vm.group = group;
	vm.groupType = groupType;
	vm.backRoute = '^.^';
	vm.currentList = $stateParams.currentList || [];
}
