import React from 'react';
import {
	Row,
	Col,
	Button,
} from 'react-bootstrap';
import ScrollText from '../../common/ScrollText.react';

export const VisitorPreChatJourney = ({navigation, coBrowseFromVisitorProfile, inCoBro, getTranslation}) => {
	return (
		<div className="pre-chat-journey">
			{navigation.map((navObj, index) => {
				const description = !navObj.get('description') || navObj.get('description') === 'none'
					? ''
					: navObj.get('description');
				const color = navObj.get('color');
				const url = navObj.get('url');
				return (
					<Row className="item" key={index}>
						<Col className="index" md={1}>
							<div className={`marker ${color}`}/>
							<span>
								&nbsp;
								{index + 1}
							</span>
						</Col>
						<Col md={7}>
							<a className="open-link" href={url} target="_blank" rel="noopener noreferrer">
								<i className="vngage-icon-export"/>
								&nbsp;
								<ScrollText
									text={`${description} ${url}`}
									cls="scroll-wrapper line-clamp line-clamp-1"
								/>
							</a>
						</Col>
						<Col md={4}>
							<Button
								disabled={!inCoBro}
								size="xs"
								variant="success"
								onClick={() => coBrowseFromVisitorProfile(url)
								}
							>
								{getTranslation('viewInCoBrowser')}
							</Button>
						</Col>

					</Row>
				);
			})}
		</div>
	);
};
