export default {
    'en-GB': {
        group: {
            ADD_HEADER: 'New group',
            EDIT_HEADER: 'Edit group',
            LIST_HEADER: 'Groups',
			LIST_TITLE: 'List of groups for working site',
			LIST_DESCRIPTION: 'Groups are a collection of users with the same area of compentence. A group could be “Sales team” or “Technical support”. Depending on the site visitors need, the visitor will be assigned to a group. A visitor that requests a contact regarding a technical issue will be assigned to the group “Technical support” and served when a group member is available.',
            GENERAL_OPTIONS: 'General options',
	        DIALOG_START_MSG: 'Dialogue: Start message',
	        HELP_STARTMESSAGE: 'This message will be shown when you start the dialogue with the visitor. You can use the parameter [[name]] to get the agent\'s name. Sample: Hello, my name is [[name]], how can I be of assistance?',
	        HELP_SIGNATURE: 'This message will be shown when you ends the dialogue with the visitor. You can use the parameter [[name]] to get the agent\'s name. Sample: Thank you for talking to us. We wish you a pleasant evening. Best regards [[name]].',
	        DIALOG_SIGNATURE: 'Dialogue: Signature',
            MAX_VISITORS_PER_USER: 'Max visitors per agent online',
			HELP_MAX_VISITORS_PER_USER: 'The maximum number of visitors able to request help from each available agent',
	        TIMEZONE: 'Time zone',
	        DIALOG_OPTIONS: 'Dialogue options',
            START_MESSAGE: 'Start message',
            SIGNATURE: 'Signature',
            DETAILS: 'General',
            OPENING_HOURS: 'Opening hours',
			DESCRIPTION_OPENING_HOURS: 'Set the time when the group is available for dialogues. Start by setting the opening time (green). Closed (red) is for exceptions like holidays, and overrides Open.',
			EDIT_MULTISELECT_TITLE_USERS: 'Select users to be members of this group',
			CASES: 'Cases',
			EDIT_MULTISELECT_TITLE_CASES: 'Select cases to make available in this group',
            ALLOCATION_TYPE: 'Availability',
            DAY_OF_WEEK: 'Day of the week',
            START_TIME: 'Start time',
            END_TIME: 'End time',
            DURATION: 'Duration',
            ADD_OPENING_HOURS: '+ Add opening hours',
            REMOVE_OPENING_HOURS: 'Remove these opening hours',
            NAME: 'Name',
			HELP_NAME: 'Visible in the entire application - e.g desktop, statistics, solutions.',
            DESCRIPTION: 'Description',
            ROUTINGTYPE: 'Routing type',
			HELP_ROUTINGTYPE: 'Determines how requests for interactions are served',
            MAX_INTERACTIONS_PER_USER: 'Max interactions per agent',
			HELP_MAX_INTERACTIONS_PER_USER: 'The maximum number of interactions an agent can receive',
            MAX_LOCK_TIME: 'Max lock time',
            HELP_MAX_LOCK_TIME: 'Seconds before the agent is automatically set to “away” and the visitor is re-routed to an available agent',
            USERS: 'Users',
            TIME_ZONE: 'Time Zone',

            REMOVE_HEADER: 'Remove group “{{ name }}”',
            REMOVE_MESSAGE: 'Note that any existing references to this group from users and solutions also will be removed.',

			GROUP_TYPE: 'Group type',
			GROUP_TYPE_HELP: 'Type of group',

			RESERVATION_BEHAVIOUR: 'Queue reservation behaviour',
			HELP_RESERVATION_BEHAVIOUR: 'Select if booked meetings to this group should be auto-started',

			QUEUE_RESERVATION_SETTINGS: 'Queue Reservation Settings',
			QUEUE_RESERVATION_HOURS: 'Bookable hours',

            PRIO: 'Group prio factor',
            HELP_PRIO: 'A factor setting group prio towards other groups. a factor of 2 will double the prio',
            FEATURES: 'Features',
			FILE_SHARING_HEADER:'File sharing',
			FILE_SHARING_ENABLED: 'Enabled',
			FILE_SHARING_DESCRIPTION: 'Enable the agent to share files with the visitor.',
			FILE_SHARING_ACCEPTED_FILE_TYPES: 'Add mime types for file sharing.',
			FILE_SHARING_HELP: '<h4>File sharing</h4>' +
			'File sharing is enabled for this group and will be available in the dialog.<br>' +
			'Add mime types to the list to enable them. The agent can only share files of the types in the list. Only properly formatted mime types will work' +
			'<h5>Image mime types</h5>' +
			'image/jpeg<br>' +
			'image/png<br>' +
			'image/gif<br>' +
			'image/* - all images<br>' +
			'<h5>Other common mime types</h5>' +
			'application/pdf - pdf<br>' +
			'application/msword - word<br>' +
			'application/vnd.ms-excel - excel<br>' +
			'video/mpeg - MPEG video<br><br>',
			START_IN_COBRO_HEADER:'Start conversations in Co-browsing mode'
        }
    },
	'sv-SE': {
		group: {
			ADD_HEADER: 'New group',
			EDIT_HEADER: 'Edit group',
			LIST_HEADER: 'Groups',
			LIST_TITLE: 'List of groups for working site',
			LIST_DESCRIPTION: 'Groups are a collection of users with the same area of compentence. A group could be “Sales team” or “Technical support”. Depending on the site visitors need, the visitor will be assigned to a group. A visitor that requests a contact regarding a technical issue will be assigned to the group “Technical support” and served when a group member is available.',
			GENERAL_OPTIONS: 'General options',
			DIALOG_START_MSG: 'Dialogue: Start message',
			HELP_STARTMESSAGE: 'This message will be shown when you start the dialogue with the visitor. You can use the parameter [[name]] to get the agent\'s name. Sample: Hello, my name is [[name]], how can I be of assistance?',
			HELP_SIGNATURE: 'This message will be shown when you ends the dialogue with the visitor. You can use the parameter [[name]] to get the agent\'s name. Sample: Thank you for talking to us. We wish you a pleasant evening. Best regards [[name]].',
			DIALOG_SIGNATURE: 'Dialogue: Signature',
			MAX_VISITORS_PER_USER: 'Max visitors per agent online',
			HELP_MAX_VISITORS_PER_USER: 'The maximum number of visitors able to request help from each available agent',
			TIMEZONE: 'Time zone',
			DIALOG_OPTIONS: 'Dialogue options',
			START_MESSAGE: 'Start message',
			SIGNATURE: 'Signature',
			DETAILS: 'General',
			OPENING_HOURS: 'Opening hours',
			DESCRIPTION_OPENING_HOURS: 'Set the time when the group is available for dialogues. Start by setting the opening time (green). Closed (red) is for exceptions like holidays, and overrides Open.',
			EDIT_MULTISELECT_TITLE_USERS: 'Select users to be members of this group',
			CASES: 'Cases',
			EDIT_MULTISELECT_TITLE_CASES: 'Select cases to make available in this group',
			ALLOCATION_TYPE: 'Availability',
			DAY_OF_WEEK: 'Day of the week',
			START_TIME: 'Start time',
			END_TIME: 'End time',
			DURATION: 'Duration',
			ADD_OPENING_HOURS: '+ Add opening hours',
			REMOVE_OPENING_HOURS: 'Remove these opening hours',
			NAME: 'Name',
			HELP_NAME: 'Visible in the entire application - e.g desktop, statistics, solutions.',
			DESCRIPTION: 'Description',
			ROUTINGTYPE: 'Routing type',
			HELP_ROUTINGTYPE: 'Determines how requests for interactions are served',
			MAX_INTERACTIONS_PER_USER: 'Max interactions per agent',
			HELP_MAX_INTERACTIONS_PER_USER: 'The maximum number of interactions an agent can receive',
			MAX_LOCK_TIME: 'Max lock time',
			HELP_MAX_LOCK_TIME: 'Seconds before the agent is automatically set to “away” and the visitor is re-routed to an available agent',
			USERS: 'Users',
			TIME_ZONE: 'Time Zone',

			REMOVE_HEADER: 'Remove group “{{ name }}”',
			REMOVE_MESSAGE: 'Note that any existing references to this group from users and solutions also will be removed.',

			GROUP_TYPE: 'Group type',
			GROUP_TYPE_HELP: 'Type of group',

			RESERVATION_BEHAVIOUR: 'Queue reservation behaviour',
			HELP_RESERVATION_BEHAVIOUR: 'Select if booked meetings to this group should be auto-started',

			QUEUE_RESERVATION_SETTINGS: 'Queue Reservation Settings',
			QUEUE_RESERVATION_HOURS: 'Bookable hours',

			PRIO: 'Group prio factor',
			HELP_PRIO: 'A factor setting group prio towards other groups. a factor of 2 will double the prio',
			FEATURES: 'Features',
			FILE_SHARING_HEADER:'File sharing',
			FILE_SHARING_ENABLED: 'Enabled',
			FILE_SHARING_DESCRIPTION: 'Enable the agent to share files with the visitor.',
			FILE_SHARING_ACCEPTED_FILE_TYPES: 'Add mime types for file sharing.',
			FILE_SHARING_HELP: '<h4>File sharing</h4>' +
			'File sharing is enabled for this group and will be available in the dialog.<br>' +
			'Add mime types to the list to enable them. The agent can only share files of the types in the list. Only properly formatted mime types will work' +
			'<h5>Image mime types</h5>' +
			'image/jpeg<br>' +
			'image/png<br>' +
			'image/gif<br>' +
			'image/* - all images<br>' +
			'<h5>Other common mime types</h5>' +
			'application/pdf - pdf<br>' +
			'application/msword - word<br>' +
			'application/vnd.ms-excel - excel<br>' +
			'video/mpeg - MPEG video<br><br>',
			START_IN_COBRO_HEADER:'Start conversations in Co-browsing mode'
		}
	}
};
