import moment from 'moment-timezone';
export default function (vngagePatterns) {
    'ngInject';

    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            startTime: '='
        },
        link: function(scope, iElem, iAttrs, ctrl) {
            scope.$watch('startTime', function() {
                compare();
            }, true);

            scope.$watch(function() { return ctrl.$modelValue; }, function() {
                compare();
            });

            function compare() {
                if (vngagePatterns.hoursMinutes.test(scope.startTime) && vngagePatterns.hoursMinutes.test(ctrl.$modelValue)) {
                    ctrl.$setValidity('endafterstart', moment(scope.startTime, 'HH:mm').isBefore(moment(ctrl.$modelValue, 'HH:mm')));
                } else {
                    ctrl.$setValidity('endafterstart', true);
                }
            }
        }
    };
};
