import React, {useState, useRef, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import immutable from 'immutable';
import Item from './Item.react.js';
import NewMessageIndicator from './NewMessageIndicator.react';
import HistoryIndicator from './HistoryIndicator.react';
import Format from '../../../utils/Format';
import ConversationSelectors from '../../../selectors/ConversationSelectors';
import {useGetTranslation} from './../../LangContext';
import Message from '../../../actionCreators/Message';

const scrollToBottom = (listElement) => {
	if (listElement && listElement.scrollHeight) {
		listElement.scrollTop = listElement.scrollHeight;
	}
};

const scrollToHistoryMarker = (listElement, lastHistoryMarkerIndex) => {
	if (listElement && lastHistoryMarkerIndex > -1) {
		const lastHistoryMarker = listElement.firstChild.childNodes[lastHistoryMarkerIndex];
		// scroll last history item into view
		// add 20 pixels extra to show more items if any
		listElement.scrollTop = Math.max(lastHistoryMarker.offsetTop - 20, 0);
	}
};

const shouldShowHistoryIndicator = (listElement, lastHistoryMarkerIndex) => {
	if (lastHistoryMarkerIndex > -1) {
		const lastHistoryMarker = listElement.firstChild.childNodes[lastHistoryMarkerIndex];
		return listElement.scrollTop > lastHistoryMarker.offsetTop;
	} else {
		return false;
	}
};

const withinSnapDistance = (listElement) => {
	// check if still mounted
	if (!listElement || !listElement.scrollHeight) {
		return false;
	}
	const scrollHeight = listElement.scrollHeight;
	const scrollTop = listElement.scrollTop;
	const clientHeight = listElement.clientHeight;

	// limit for bottom snap when new message
	const offset = listElement.firstChild.lastChild && listElement.firstChild.lastChild.offsetHeight
		? listElement.firstChild.lastChild.offsetHeight
		: 0;
	const snapLimit = 100 + offset;

	return (scrollHeight - scrollTop - snapLimit) < clientHeight;
};

const focusChanged = (listElement) => {
	if (withinSnapDistance(listElement)) {
		scrollToBottom(listElement);
	}
};

const List = (props) => {
	const getTranslation = useGetTranslation();
	const messageList = useRef();
	const [nbrOfNewMessages, setNbrOfNewMessages] = useState(0);
	const [historyIndicatorStatus, setHistoryIndicatorStatus] = useState('pending');

	const timer = useRef();
	const focusTimer = useRef();
	const scrollHeight = useRef();

	const {lastHistoryMarkerIndex} = props;

	const updateIndicator = () => {
		// only update indicator if the new message is from the visitor
		const isVisitor = props.chatMessages.last().hasIn(['speaker', 'visitId']);
		if (isVisitor && timer.current) {
			setNbrOfNewMessages(nbrOfNewMessages + 1);
		}
	};

	const indicatorClick = () => {
		setNbrOfNewMessages(0);
		scrollToBottom(messageList.current);
	};

	const newMessageReceived = () => {
		if (withinSnapDistance(messageList.current)) {
			scrollToBottom(messageList.current);
		} else {
			updateIndicator();
		}
	};

	const updateScrollHeight = () => {
		const listElement = messageList.current;
		if (listElement) {
			scrollHeight.current = listElement.scrollHeight;
		}
	};

	const historyIndicatorClick = () => {
		setHistoryIndicatorStatus('hidden');
		scrollToHistoryMarker(messageList.current, lastHistoryMarkerIndex);
	};

	// onMount
	useEffect(() => {
		scrollToBottom(messageList.current);
	}, []);

	useEffect(() => {
		const checkIfScrolledDown = () => {
			if (withinSnapDistance(messageList.current)) {
				// if within snap distance, reset nbrOfNewMessages
				setNbrOfNewMessages(0);
			}
			const historyIndicatorValid = shouldShowHistoryIndicator(messageList.current, lastHistoryMarkerIndex);
			setHistoryIndicatorStatus(prev => prev === 'hidden' ? 'hidden': historyIndicatorValid ? 'visible': 'hidden');
		};

		timer.current = setInterval(checkIfScrolledDown, 1000);

		return () => {
			if (timer.current) {
				clearInterval(timer.current);
				timer.current = null;
			}
			if (focusTimer.current) {
				clearInterval(focusTimer.current);
				focusTimer.current = null;
			}
		};
	}, [lastHistoryMarkerIndex]);

	//componentWillReceiveProps / componentDidUpdate
	const savedProps = useRef(props);
	useEffect(() => {
		const prevProps = savedProps.current;
		const nextProps = props;
		const hasNewMessages = nextProps.chatMessages.size !== prevProps.chatMessages.size;
		const prevLastMessage = prevProps.chatMessages.last() || immutable.Map({createdRaw: 0});
		const nextLastMessage = nextProps.chatMessages.last() || immutable.Map({createdRaw: 0});

		if (hasNewMessages && (nextLastMessage.get('createdRaw') === prevLastMessage.get('createdRaw'))) {
			// history expanded
			// do nothing
		} else if (hasNewMessages && (nextLastMessage.get('createdRaw') > prevLastMessage.get('createdRaw'))) {
			// new message received
			newMessageReceived();
		}
		if (nextProps.hasFocus && !prevProps.hasFocus) {
			// delay scroll to bottom until input field is done animating
			focusTimer.current = setTimeout(() => focusChanged(messageList.current), 400);
		}
		if (hasNewMessages || !scrollHeight.current) {
			updateScrollHeight();
		}
		savedProps.current = props;
	},);


	const {chatMessages, me} = props;
	const nrOfChatMessages = chatMessages.size;

	const getSpeaker = (msg) => {
		return msg.getIn(['speaker', 'id']) === me
			? getTranslation('roleYou')
			: msg.getIn(['speaker', 'role']) === 'agent'
				? msg.getIn(['speaker', 'name']) || 'UnknownAgent'
				: getTranslation('roleVisitor');
	};

	const items = chatMessages.map((msg, index) => {
		const messageData = Format.messageAsTextData(msg);
		const key = msg.get('id');
		const timestamp = msg.get('createdRaw');
		const role = msg.getIn(['speaker', 'role']);
		const speaker = getSpeaker(msg);

		return (
			<Item
				timestamp={timestamp}
				displayRelativeDialogTime={props.displayRelativeDialogTime}
				txt={messageData.text}
				type={messageData.type}
				linkType={messageData.linkType}
				title={messageData.title}
				speaker={speaker}
				role={role}
				key={key}
				id={key}
				isRead={msg.get('isRead')}
				isMostRecentMessage={index === nrOfChatMessages - 1}
				displaySystemMessages={props.displaySystemMessages}
				oldestMessageRanking={props.oldestMessageRanking}
				messageData={messageData}
				status={msg.get('status')}
				conversationId={props.conversationId}
				resendMessage={props.resendMessage}
			/>
		);
	});

	const newMessagesIndicator = nbrOfNewMessages > 0
		? <NewMessageIndicator indicatorClick={indicatorClick}/>
		: null;

	return (
		<div className="message-list-container">
			<HistoryIndicator indicatorClick={historyIndicatorClick} historyIndicatorStatus={historyIndicatorStatus}/>
			<div className="message-list" ref={messageList}>
				<ul>
					{items}
				</ul>
			</div>
			{newMessagesIndicator}
		</div>
	);
};

const makeMapStateToProps = () => {
	const getChatMessages = ConversationSelectors.makeGetBraidedChatMessages();
	// returns mapStateToProps
	return (state, props) => {
		// incoming
		// ownProps.conversationId
		const conversationId = props.conversationId;
		const conversation = state.getIn(['conversations', conversationId]) || immutable.Map();
		const chatMessages = getChatMessages(state, props);
		const lastHistoryMarkerIndex = chatMessages.findLastIndex(msg => msg.get('messageType') === 'historyMarker');
		return {
			localMessages: conversation.get('localMessages'),
			title: conversation.get('title'),
			chatMessages,
			lastHistoryMarkerIndex,
			me: conversation.get('me'),
			oldestMessageRanking: conversation.getIn(['UI', 'oldestMessageRanking']),
			displayRelativeDialogTime: state.getIn(['account', 'displayRelativeDialogTime']) === true,
			hasFocus: state.get('activePanel') === conversationId,
		};
	};
};

function mapDispatchToProps(dispatch) {
	return {
		resendMessage: (conversationId, messageId) => dispatch(Message.resendMessage(conversationId, messageId)),
	};
}

const areEqual = (prevProps, nextProps) => {
	return !!prevProps.chatMessages && !!nextProps.chatMessages
		&& prevProps.chatMessages.size === nextProps.chatMessages.size
		&& prevProps.hasFocus === nextProps.hasFocus
		&& immutable.is(prevProps.localMessages, nextProps.localMessages);
};

export default connect(makeMapStateToProps,
	mapDispatchToProps)(React.memo(List, areEqual));

