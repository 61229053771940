import React from 'react';
import ReactDOM from 'react-dom';
import CoBroDesktopLandingPage from './components/CoBroDesktopLandingPage.react';
import i18nLoader from '../../i18n';
import {Provider} from 'react-redux';
import {Store} from '../desktopDeux/stores/Store';
import AccountConfiguration from '../desktopDeux/utils/AccountConfigurationWrapper';
import Lang from '../desktopDeux/utils/Lang';
import {endDesktop, initCrashDetector, initServerRequests, startDesktop} from '../desktopDeux/Starters';

export default class CoBroDesktopController {

	constructor($rootScope, $scope, $http, vngageConfig, $uibModal, IdentityService, desktopPlugins) {
		'ngInject';

		const vm = this;
		initCrashDetector(vm, $uibModal);
		initServerRequests(vngageConfig, $http, IdentityService);

		const language = AccountConfiguration.configuration.user.profile.language || 'en';

		if (AccountConfiguration.isMockEnabled()) {
			console.log('language mocked to', language);
		}

		i18nLoader(language, this.renderWrapper.bind(this, desktopPlugins));

		// upon destroy scope, clean up
		$scope.$on('$destroy', function () {
			endDesktop();
			ReactDOM.unmountComponentAtNode(document.getElementById('vngage-cobro-desktop-app'));
		});
	}


	renderWrapper(desktopPlugins, i18n) {
		Lang.set(i18n);
		startDesktop(desktopPlugins);
		this.renderApp({locale: Lang.locale, messages: Lang.messages});
	}

	renderApp(i18n) {
		ReactDOM.render(<Provider store={Store} {...i18n}> <CoBroDesktopLandingPage/> </Provider>,
			document.getElementById('vngage-cobro-desktop-app'));
	}

}

