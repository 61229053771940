import React from 'react';
import {Button} from 'react-bootstrap';

export default function DebugPluginButton({plugin, index, togglePlugin, notify}) {
	const name = plugin.get('name') || 'no name';
	const style = {
		display: 'flex'
	};

	const onClickNotify = () => {
		notify(plugin.get('id'), 'test', false);
	};

	const onClickNotifyWithToast = () => {
		notify(plugin.get('id'), 'ok then, lets do this and open plugin tab', true);
	};

	return (
		<div style={style}>
			<span>
				<label className="flipswitch">
					<span className="badge">{name}</span>
					<input
						type="checkbox"
						className="switch"
						defaultChecked={plugin.get('enabled')}
						onChange={() => togglePlugin(index)}
					/>
					<span className="switch pull-right"/>
				</label>
			</span>

			<Button variant="default" className="dark" size="xs" onClick={onClickNotify}>notify</Button>
			<Button variant="default" className="dark" size="xs" onClick={onClickNotifyWithToast}>toast</Button>
		</div>
	);
}
