import Session from './Session';
import AuthKeys from './AuthKeys';

export default function () {

	function getSession() {
		if (window.crashSession) {
			return {
				sessionId: '00000000-0000-0000-0000-000000000000'
			};
		}
		Session.sessionId = Session.sessionId || localStorage.getItem(AuthKeys.sessionId);	// Make sure sessionId is properly cached in Session.sessionId
		Session.sessionType = Session.sessionType || localStorage.getItem(AuthKeys.sessionType) || 'session';
		return Session;
	}

	function _storeSession(session) {
		localStorage.setItem(AuthKeys.sessionId, session.sessionId);
		localStorage.setItem(AuthKeys.sessionType, session.sessionType);
	}

	function setSessionId(sessionId, signInUrlOverride) {
		Session.sessionId = sessionId;
		Session.sessionType = 'session';
		Session.signInUrlOverride = signInUrlOverride || null;	// Should "signInUrl" be stored in localStorage as well?
		_storeSession(Session);
	}

	function setBearerToken(token, signInUrlOverride) {
		Session.sessionId = token;
		Session.sessionType = 'bearer';
		Session.signInUrlOverride = signInUrlOverride || null;	// Should "signInUrl" be stored in localStorage as well?
		_storeSession(Session);
	}

	function clearSessionId() {
		Session.sessionId = null;
		Session.sessionType = null;
		Session.signInUrlOverride = null;
		localStorage.removeItem(AuthKeys.sessionId);
		localStorage.removeItem(AuthKeys.sessionType);

		// remove inContact integration items used in desktopDeux
		sessionStorage.removeItem('inContactSessionId');
		sessionStorage.removeItem('inContactTokenData');
	}

	// DelegatedSessionId
	// ------------------
	function setDelegatedSessionId(delegatedSessionId) {
		Session.delegatedSessionId = delegatedSessionId;
		localStorage.setItem(AuthKeys.delegatedSessionId, delegatedSessionId);
	}

	function getDelegatedSessionId() {
		Session.delegatedSessionId = Session.delegatedSessionId || localStorage.getItem(AuthKeys.delegatedSessionId);
		return Session.delegatedSessionId;
	}

	// SessionTimeToLive
	// -----------------
	function getSessionTimeToLive() {
		Session.sessionTimeToLive = Session.sessionTimeToLive || localStorage.getItem(AuthKeys.sessionTimeToLive);
		return Session.sessionTimeToLive;
	}

	function setSessionTimeToLive(ttl) {
		Session.sessionTimeToLive = ttl;
		localStorage.setItem(AuthKeys.sessionTimeToLive, ttl);
	}

	function setUser(user) {
		if (Session.user && Session.user.profile) {
			user.profile = angular.extend({}, Session.user.profile, user.profile);
		}
		Session.user = user;
	}

	function setAccount(account) {
		Session.account = account;
	}

	function setAuthorizations(authorizations) {
		Session.authorizations = authorizations;
	}

	function isUserDelegated() {
		return Session.user.type === 1;
	}

	function loaded() {
		return !!(Session.user && Session.account && Session.authorizations);
	}

	function userHasTimezone() {
		return !!(Session.user && Session.user.profile.timeZoneId);
	}

	//function setUserTimezoneOffset(timezones, defaultTimeZoneId) {
	//	var timeZoneId = Session.user.profile.timeZoneId || defaultTimeZoneId;
	//	var found = _.find(timezones, function (t) {
	//		return t.id === timeZoneId;
	//	});
	//	if (found) {
	//		Session.user.profile.timeZoneUtcOffset = found.baseUtcOffset;
	//	} else {
	//		Session.user.profile.timeZoneUtcOffset = '';
	//	}
	//}

	function getUserTimezone() {
		return Session.user.profile.timeZoneId || 'Etc/UTC'; //'Europe/Stockholm'; //moment().tz('America/Detroit');
	}

	//function getDSTOffset() {
	//	function stdTimezoneOffset (date) {
	//		var jan = new Date(date.getFullYear(), 0, 1);
	//		var jul = new Date(date.getFullYear(), 6, 1);
	//		return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
	//	}
	//
	//	function dst (date) {
	//		return date.getTimezoneOffset() < stdTimezoneOffset(date);
	//	}
	//
	//	var today = new Date();
	//	if (dst(today)) {
	//		return 1;
	//	} else {
	//		return 0;
	//	}
	//}

	function getAuthHeader() {
		const session = getSession();
		if (session && session.sessionId) {
			return (session.sessionType && session.sessionType === 'bearer' ? 'Bearer ' : 'SessionId ') + session.sessionId;
		}
		return '';
	}

	function isAuthenticated() {
		// Function moved from IdentityService
		// Was previously implemented as:
		// return !!localStorage.getItem(AuthKeys.sessionId);
		// ...but we should not rely on a working localStorage...
		const session = getSession();
		return !!(session && session.sessionId);
	}

	return {
		loaded: loaded,
		setUser: setUser,
		setAccount: setAccount,
		setAuthorizations: setAuthorizations,
		setSessionTimeToLive: setSessionTimeToLive,
		userHasTimezone: userHasTimezone,
		getSessionTimeToLive: getSessionTimeToLive,
		isUserDelegated: isUserDelegated,
		getDelegatedSessionId: getDelegatedSessionId,
		setDelegatedSessionId: setDelegatedSessionId,
		clearSessionId: clearSessionId,
		setSessionId: setSessionId,
		getSession: getSession,
		getUserTimezone: getUserTimezone,
		setBearerToken,
		getAuthHeader,
		isAuthenticated
	};
}