import React from 'react';
import ViewActions from './../actions/ViewActions';
import EasyRTCApp from './../../../components/VideoEasyRTC';
import ConversationsStore from  './../stores/ConversationsStore';
import Video from './../../../components/Video.react';
import createReactClass  from 'create-react-class';

export default createReactClass({

	getInitialState () {
		return getStateFromStores(this.props.conversationId);
	},

	componentDidMount () {
		this.mounted = true;
		ConversationsStore.addChangeListener(this._onChange);
		if (this.props.useFlash) { return; }
		if (this.state.streaming.video) {
			EasyRTCApp.start(this.props.conversationId, 'guest', this.props.streamingServer);
		}
	},

	componentWillUnmount () {
		ConversationsStore.removeChangeListener(this._onChange);
		if (this.props.useFlash) { return; }
		EasyRTCApp.leave(this.props.conversationId);
		this.mounted = false;
	},

	componentDidUpdate (prevProps, prevState) {
		if (this.props.useFlash) { return; }
		if (!this.state.streaming.video && prevState.streaming.video) {
			EasyRTCApp.leave(this.props.conversationId);
			return;
		}
		if (this.state.streaming.video && !prevState.streaming.video){
			EasyRTCApp.start(this.props.conversationId, 'guest', this.props.streamingServer);
		}
	},

	_onChange () {
		if (!this.mounted) {
			return false;
		}
		this.setState(getStateFromStores(this.props.conversationId));
	},

	onCreateStream () {
		ViewActions.setupVideoChat(this.props.conversationId, {activity: 'start', streamId: this.props.streamId});
	},

	onStopStream () {
		ViewActions.setupVideoChat(this.props.conversationId, {activity: 'stop', streamId: this.props.streamId});
	},

	render () {
		console.log('render video component', this.state.streaming);
		return (
			<Video
				onCreateStream={this.onCreateStream}
				onStopStream={this.onStopStream}
				conversationId={this.props.conversationId}
				streamingServer={this.props.videoStreamingServer}
				streamId={this.props.conversationId}
				visitorHasFlash={this.props.visitorHasFlash}
				streaming={this.state.streaming}
				streamingVideo={this.state.streaming.video}
				useFlash={false}
				roomOwner={this.props.roomOwner}
			/>
		);
	}

});

function getStateFromStores(conversationId) {
	let conversation = ConversationsStore.find(conversationId);
	conversation = conversation || {streaming: {audio: false, video: false}};
	return {
		streaming: conversation.streaming
	};
}
