export default {
    'en-GB': {
        systemRole: {
            ADD_HEADER: 'New role',
            EDIT_HEADER: 'Edit role',
            LIST_HEADER: 'System roles',
            LIST_TITLE: 'List of system roles for this account',
            LIST_DESCRIPTION: 'System roles defines all the roles that should be available for admins to assign to users.',
            CLAIM_TYPE: 'Claim type',
            LEVEL: 'Level',
            ADD_TO_SELECTED_ACCOUNTS: 'Add to selected accounts',
            REMOVE_FROM_SELECTED_ACCOUNTS: 'Remove from selected accounts',
            DESCRIPTION: 'Description',
            NAME: 'Name',
            DISPLAY_NAME: 'Display name',
			TAB_ROLE: 'Role',
			TAB_AUTHORIZATIONS: 'Authorizations',
			TAB_DEPENDS: 'Depends on',
			TAB_PUSH: 'Push to account'
        }
	},
	'sv-SE': {
		systemRole: {
			ADD_HEADER: 'New role',
			EDIT_HEADER: 'Edit role',
			LIST_HEADER: 'System roles',
			LIST_TITLE: 'List of system roles for this account',
			LIST_DESCRIPTION: 'System roles defines all the roles that should be available for admins to assign to users.',
			CLAIM_TYPE: 'Claim type',
			LEVEL: 'Level',
			ADD_TO_SELECTED_ACCOUNTS: 'Add to selected accounts',
			REMOVE_FROM_SELECTED_ACCOUNTS: 'Remove from selected accounts',
			DESCRIPTION: 'Description',
			NAME: 'Name',
			DISPLAY_NAME: 'Display name',
			TAB_ROLE: 'Role',
			TAB_AUTHORIZATIONS: 'Authorizations',
			TAB_DEPENDS: 'Depends on',
			TAB_PUSH: 'Push to account'
		}
    }
};
