import customScriptTranslations from './CustomScriptTranslations';
import CustomScriptEditController from './CustomScriptEditController';
import CustomScriptEditTemplate from './CustomScriptEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.account.customScript', ['ui.router', 'ui.codemirror', 'vngageApp.workspaceTranslation'])

	.constant('customScriptTranslations', customScriptTranslations)
	.controller('CustomScriptEditController', CustomScriptEditController)

	.constant('customScriptModuleConfig', {
		state: 'root.account.customScript',
		access: 'Engage/CustomScript'
	})

	.config(($stateProvider, $translateProvider, customScriptTranslations, customScriptModuleConfig, VngageWorkspaceTranslationServiceProvider) => {
		'ngInject';

		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(customScriptTranslations);

		$stateProvider.state(customScriptModuleConfig.state, {
			url: 'customscript/',
			controller: 'CustomScriptEditController as vm',
			templateUrl: CustomScriptEditTemplate,
			data: {
				access: customScriptModuleConfig.access,
				ncyBreadcrumbLabel: 'Custom Script'
			},
			resolve: {
				allCustomScripts: ($q, APIEndpointService) => {
					'ngInject';
					return APIEndpointService.script.query().$promise;
				},
				customScriptsValidationError: (allCustomScripts, ConfigValidationService) => {
					'ngInject';
					return ConfigValidationService.validateScrips(allCustomScripts).length > 0;
				},
				customScript: ($q, APIEndpointService, allCustomScripts) => {
					'ngInject';
					return $q(resolve => {
						if (allCustomScripts.length > 0) {
							resolve(allCustomScripts[0]);
						} else {
							const newData = {
								name: 'Custom Script',
								script: '(function(){\n})();',
								comments: 'Script'
							};
							APIEndpointService.script.create(newData).$promise.then(newScript => {
								resolve(newScript);
							});
						}
					});
				},
				userList: (APIEndpointService, IdentityService) => {
					'ngInject';
					return APIEndpointService.user.query({limit: 10000}).$promise.then(userList => {

						if (IdentityService.currentlyDelegated()) {
							return userList;
						} else {
							// Only return non-delegated users
							return userList.filter(user => user.data.type === 0);
						}
					});
				},
				liveRevision: ($q, APIEndpointService) => {
					'ngInject';
					return $q(resolve => {
						$q.all([
							APIEndpointService.accountCurrentConfiguration.get().$promise
						]).then(res => {

							var resolved = {};

							resolved.accountConfiguration = res[0];

							var id = resolved.accountConfiguration.data.liveConfigurationSetId;
							var revision = resolved.accountConfiguration.data.liveConfigurationSetIdRevision;

							return $q.all([
								APIEndpointService.configurationSetResolved.get({
									id: id,
									revision: revision
								}).$promise
							]);

						}).then(res => {
							var configurationSetResolved = res[0];
							resolve(configurationSetResolved.scripts[0]);
						}, err => {

						});
					});
				}
			}
		});
	});
