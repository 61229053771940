import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import immutable from 'immutable';
import {Modal, ModalHeader, Button} from 'react-bootstrap';
import ContentUploadTools from '../../utils/ContentUploadTools';
import ContentPreview from './ContentPreview.react';
import UploadDropzone from './UploadDropzone.react';
import ServerActions from '../../actionCreators/ServerActions';
import LogActions from '../../actions/LogActions';
import {useGetTranslation} from './../LangContext';

export const UploadModal = (props) => {
	const getTranslation = useGetTranslation();
	const [showModal, setShowModal] = useState(false);
	const [fileObject, setFileObject] = useState(null);
	const [loading, setLoading] = useState(false);


	const {contentErrorLog, contentLog} = props;
	const onDrop = useCallback((files) => {
		const file = files[0];
		if (!file) {
			contentErrorLog('invalid file', {});
			return;
		}
		const filename = file.name;
		const fileType = file.type;
		const fileSize = file.size;
		contentLog('reading file', {
			filename, fileType, fileSize
		});

		const reader = new FileReader();
		reader.onloadend = () => {
			setFileObject(ContentUploadTools.getFileObject(reader.result, file));
			setLoading(false);
			contentLog('reading file done', {
				filename, fileType, fileSize
			});
		};
		setLoading(true);
		reader.readAsArrayBuffer(file);
	}, [contentErrorLog, contentLog]);

	const upload = () => {
		props.uploadContent(props.conversationId, fileObject.data, fileObject.uploadInfo);
		props.hideModal();
	};

	useEffect(() => {
		return () => {
			if (fileObject) {
				// clean up to avoid memory leaks
				ContentUploadTools.revokeBlobUrl(fileObject.blobUrl);
				setFileObject(null);
			}
		};
	}, [fileObject]);

	const uploadReady = !!fileObject;

	return (
		<Modal show={true} onHide={props.hideModal}>
			<Modal.Header closeButton>
				<Modal.Title>{uploadReady ? fileObject.uploadInfo.filename: '\u00A0'}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				{loading
					? <div className="content-spinner"/>
					: uploadReady
						? <ContentPreview {...fileObject}/>
						: <UploadDropzone onDrop={onDrop} acceptedMimeTypes={props.acceptedMimeTypes}/>
				}

			</Modal.Body>

			<Modal.Footer className="content-upload-footer">
				<Button onClick={upload} disabled={!uploadReady} ub-track="file-upload-send">
					{getTranslation('btnSend')}
				</Button>
				<Button variant="default" onClick={props.hideModal}>{getTranslation('btnCancel')}</Button>
			</Modal.Footer>

		</Modal>
	);
};

function mapStateToProps(state, ownProps) {
	const conversationId = ownProps.conversationId;
	const conversation = state.getIn(['conversations', conversationId]) || immutable.Map();
	return {acceptedMimeTypes: conversation.getIn(['capabilities', 'groupFeatures', 'fileSharing', 'mimeTypes']) || immutable.List()};
}

function mapDispatchToProps(dispatch) {
	return {
		uploadContent: (conversationId, arrayBuffer, uploadInfo) => dispatch(ServerActions.uploadContent(conversationId, arrayBuffer, uploadInfo)),
		contentLog: (name, params) => dispatch(LogActions.contentLog(name, params)),
		contentErrorLog: (error, params) => dispatch(LogActions.contentErrorLog(error, params)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal);

