import BannerAddModalTemplate from './BannerAddModalTemplate';

export default function ($state, $uibModal, bannerList, bannerListTableConfig) {
	'ngInject';

	var vm = this;
	vm.banners = bannerList;
	vm.toJson = toJson;

	vm.options = bannerListTableConfig.options;
	vm.columns = bannerListTableConfig.columns;

	vm.options.createItem.method = addNew;


	function addNew() {
		$state.go('root.engage.banner').then(openModal);
	}

	function openModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: BannerAddModalTemplate,
			controller: 'BannerAddModalController as vm'
		});

		modal.result.then(newBanner => {
			bannerList.push(newBanner);
			$state.go('.edit.general', {id: newBanner.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function toJson(value) {
		return JSON.stringify(value, null, 2);
	}

}
