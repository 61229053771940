export default function($q, $translate) {
    'ngInject';

    var service = {
        getLanguageSelectOptions: getLanguageSelectOptions
    };

    function getLanguageSelectOptions(translationList) {
        return $q(function(resolve) {
            var promises = [];
            var selectOptions = [];
            translationList.forEach(function(translation) {
                var dfd = $q.defer();
                promises.push(dfd.promise);
                $translate('languageCodes.' + translation.data.languageCode).then(function(codeName) {
                    selectOptions.push({
                        code: translation.data.languageCode,
                        name: codeName + ' (' + translation.data.languageCode + ')'
                    });
                    dfd.resolve();
                });
            });
            $q.all(promises).then(function() {
                resolve(selectOptions);
            });
        });
    }

    return service;
};
