export default function ($sce, $parse, $timeout, $translate, doT) {
	'ngInject';

	const dotSettings = angular.extend({}, doT.templateSettings, {
		// Visitor uses slightly modified regex for interpolate and encode! ({{= conf.XX}} will be encoded, but that's not doT's default setting)
		interpolate: /\{\{\!\=([\s\S]+?)\}\}/g,
		encode:      /\{\{\=([\s\S]+?)\}\}/g,
		varname: 'conf, _'
	});

	var preview = {
		isOpen: true,
		isBusy: false,
		isClosed: false,
		active: false,
		expanded: false,
		content: '',
		style: '',
		size: {
			mobile: false,
			small: false,
			medium: true,
			normal: false,
			scale: true
		},
		address: '',
		dynamicSizeBanner: false,
		bookedMeetingsView: 'calendar1',
		bookedMeetingsShowForm: true
	};

	preview.setSize = setSize;
	preview.state = setState;
	preview.display = setDisplay;
	preview.compileHtml = compileHtml;
	preview.setDynamicSizeBanner = setDynamicSizeBanner;
	preview.setBookedMeetingsView = setBookedMeetingsView;

	function setSize(newSize) {
		angular.forEach(['mobile', 'tablet', 'medium', 'desktop'], function (val) {
			preview.size[val] = (newSize === val);
		});
	}

	function setState(state) {
		state = state && state.toLowerCase() || 'open';

		preview.isOpen = false;
		preview.isBusy = false;
		preview.isClosed = false;

		preview['is' + capitalize(state)] = true;
	}

	function setDisplay(display) {
		if (display === 'expand') {
			preview.active = true;
			preview.expanded = true;
		} else if (display === 'show') {
			preview.active = true;
			preview.expanded = false;
		} else if (display === 'hide') {
			preview.active = false;
			preview.expanded = false;
		}
	}

	function compileHtml(html, variables, globalVars, customDoTSettings) {
		var vars = angular.extend({}, variables),
			globals = angular.extend({}, globalVars),
			settings  = angular.extend({}, dotSettings, (customDoTSettings ? customDoTSettings : {}));

		vars.isOpen = preview.isOpen;
		vars.isBusy = preview.isBusy;
		vars.isClosed = preview.isClosed;

		try {
			return doT.template(html, settings)(vars, globals);
		} catch (error) {}
		return '';
	}

	function setDynamicSizeBanner(val) {
		preview.dynamicSizeBanner = val;
	}

	function setBookedMeetingsView(view) {
		preview.bookedMeetingsView = view;
	}

	function setContent() {
		var result = '';

		result += '<!doctype html>\n<html class="vngage-csstransitions">\n<head>\n<meta charset="utf-8">\n';
		result += '<style>\n';
		// result += 'body { box-sizing: border-box; height: 100vh; margin: 0; padding: 5%; overflow: hidden; }\n';
		result += 'body { box-sizing: border-box; margin: 0; padding: 5%; }\n';
		result += '.no-transition, .no-transition * { transition: none !important; }\n';
		result += '</style>\n';
		result += '</head>\n';
		result += '<div id="banner"></div>\n';
		result += '<div id="overlay" class="vngage-overlay" style="transition: none;"></div>\n';
		result += '<style id="css"></style>\n';
		result += '</body>\n</html>';

		preview.content = $sce.trustAsHtml(result);
	}

	function capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	setContent();

	return preview;
}
