import AccountConfiguration from './AccountConfigurationWrapper';

export default {

	getGroupIds() {
		const conf = AccountConfiguration.configuration;
		return conf.groupIds;
	},

	getUserGroupIds() {
		const conf = AccountConfiguration.configuration;
		return conf.user.groupIds;
	},

	getGroupName(groupId) {
		const conf = AccountConfiguration.configuration;
		const group = conf.groups[groupId] || {name: ''};
		return group.name;
	},

	/**
	 *
	 * @param groupId
	 * @returns {{fileSharing:{enabled: boolean, mimeTypes: Array}}}
	 */
	getGroupFeatures(groupId) {
		const conf = AccountConfiguration.configuration;
		const group = conf.groups[groupId];
		return group && group.features && group.features.section
			? group.features.section
			: {};
	},

	getCaseTypesByGroupId(groupId) {
		const conf = AccountConfiguration.configuration;
		let caseTypes = (conf.groups[groupId] ? conf.groups[groupId].caseTypes || []: []);
		return caseTypes.map(caseType => {
			const caseData = conf.customerCases[caseType];
			if (caseData) {
				return {
					id: caseType,
					name: caseData.name
				};
			} else {
				return {
					id: '',
					name: ''
				};
			}
		});
	},

	getCaseTypes() {
		const conf = AccountConfiguration.configuration;
		let usedCaseTypes = [];
		return conf.groupIds.reduce((allCases, groupId) => {
			let caseTypes = (conf.groups[groupId] ? conf.groups[groupId].caseTypes || []: []);
			caseTypes = caseTypes.filter(caseType => usedCaseTypes.indexOf(caseType) === -1);
			const cases = caseTypes.map(caseType => {
				const caseData = conf.customerCases[caseType];
				if (caseData) {
					usedCaseTypes.push(caseType);
					return {
						id: caseType,
						name: caseData.name
					};
				} else {
					return {
						id: '',
						name: ''
					};
				}
			});
			return allCases.concat(cases);
		}, []);
	},

	getOneCaseType(caseId) {
		const conf = AccountConfiguration.configuration;
		const customerCase = conf.customerCases[caseId] || {id: '', name: ''};
		return {
			id: customerCase.id,
			name: customerCase.name
		};
	},

	getCannedResponses(caseId) {
		const conf = AccountConfiguration.configuration;
		const customerCase = conf.customerCases[caseId] || {section: {cannedResponses: []}};
		const cannedResponses = customerCase.section.cannedResponses || [];

		return cannedResponses.map(cannedId => {
			const cannedResponse = conf.cannedResponses[cannedId] || {id: '', name: '', section: {message: ''}};
			const section = cannedResponse.section;
			return {
				id: cannedResponse.id,
				message: section.message,
				name: cannedResponse.name
			};
		});
	},

	getActions(caseId) {
		const badPanel = {panelName: '', actions: []};
		const badAction = {name: '', icon: '', parameter: '', itemType: ''};
		const conf = AccountConfiguration.configuration;
		const customerCase = conf.customerCases[caseId] || {section: {actionPanels: []}};
		const actionPanels = customerCase.section.actionPanels || [];

		return actionPanels.map(panelId => {
			const panel = conf.actionPanels[panelId];
			if (!panel) {
				return badPanel;
			}
			const sectionActions = panel.section.actions || [];
			let actionObject = {panelName: panel.name};
			actionObject.actions = sectionActions.map(actionId => {
				const action = conf.actions[actionId];
				if (!action) {
					return badAction;
				}
				return {
					name: action.name || '',
					icon: action.section.icon || '',
					parameter: action.section.parameter || '',
					itemType: action.section.itemType || ''
				};
			});
			return actionObject;

		});
	},

	getAllActions() {
		const conf = AccountConfiguration.configuration;
		const {actions} = conf;
		const mappedActions = {};

		for (let actionId in actions) {

			const action = actions[actionId];
			const actionObj = {
				name: action.name || '',
				icon: action.section.icon || '',
				parameter: action.section.parameter || '',
				itemType: action.section.itemType || ''
			};

			mappedActions[actionId] = actionObj;
		}

		return mappedActions;
	},

	getAction(id) {
		const actions = this.getAllActions();
		return actions[id] || null;
	},

	getClosures(caseId) {
		const dismissClosure = {
			reason: {
				id: '5D111DBD-665A-4DAD-9F24-AE93AF7C4A71',
				revision: 0
			},
			name: 'Dismiss',
			outcome: 'neutral',
			textToVisitor: ''
		};
		const conf = AccountConfiguration.configuration;
		const customerCase = conf.customerCases[caseId] || {section: {actionPanels: []}};
		const badClosure = {
			reason: {
				id: '',
				revision: 0
			},
			name: '',
			outcome: '',
			textToVisitor: ''
		};
		if (!customerCase.section.closures || customerCase.section.closures.length === 0) {
			return [dismissClosure];
		}
		return customerCase.section.closures.map(closureId => {
			const closure = conf.closures[closureId];
			if (!closure) {
				return badClosure;
			}
			const section = closure.section;
			const textToVisitor = section.textToVisitor || '';
			return {
				reason: {
					id: closure.id,
					revision: 0
				},
				name: closure.name,
				outcome: section.outcome,
				textToVisitor: textToVisitor
			};
		});
	},

	getOneClosure(closureId) {
		const conf = AccountConfiguration.configuration;
		const closure = conf.closures[closureId];
		if (closure) {
			const section = closure.section;
			const textToVisitor = section.textToVisitor || '';
			return {
				reason: {
					id: closure.id,
					revision: 0
				},
				name: closure.name,
				outcome: section.outcome,
				textToVisitor: textToVisitor
			};
		} else {
			return {
				reason: {
					id: '5D111DBD-665A-4DAD-9F24-AE93AF7C4A71',
					revision: 0
				},
				name: 'Dismiss',
				outcome: 'neutral',
				textToVisitor: ''
			};

		}
	},

	getForms(caseId) {
		const conf = AccountConfiguration.configuration;
		const customerCase = conf.customerCases[caseId] || {section: {forms: []}};
		const forms = customerCase.section.forms || [];
		return forms.map(formId => {
			const form = conf.forms[formId] || {name: '', id: ''};
			const section = form.section || {description: ''};
			const template = section.template || '[]';
			const formFields = !Array.isArray(JSON.parse(template)) ? []: JSON.parse(template);
			const formattedFields = formFields.length === 0
				? formFields
				: formFields[0].childElements.map(field => {
					return {value: '', isValid: !!field.required, ...field};
				});

			return {
				id: form.id,
				isValid: formattedFields.length === 0,
				name: form.name,
				description: section.description,
				formFields: formattedFields
			};
		});


	},

	getSolution(solutionId) {
		const conf = AccountConfiguration.configuration;
		const solution = conf.solutions[solutionId] || {section: {messageToCustomerCase: ''}};
		return {
			id: solutionId,
			name: solution.section.messageToCustomerCase || ''
		};
	},

	caseTypeHasForm(caseTypeId) {
		const conf = AccountConfiguration.configuration;
		const customerCase = conf.customerCases[caseTypeId];
		return customerCase !== undefined && customerCase.section.forms !== undefined && customerCase.section.forms.length > 0;
	},

	getEmailTranscriptConfig() {

		const accountEmail = AccountConfiguration.configuration.account.alwaysMailToThisEmailOnDialogClose;
		const agentEmail = AccountConfiguration.configuration.user.profile.preferredEmail;

		const alwaysEmail = !!accountEmail;
		const email = alwaysEmail ? accountEmail: agentEmail;

		return {
			email,
			alwaysEmail
		};
	},

	getURLInfo(url, siteId) {
		const defaultInfo = {
			url,
			'urlFragment': '',
			'icon': '',
			'color': 'gray',
			'description': 'none'
		};

		const urlCategories = AccountConfiguration.configuration.urlCategories;

		const keys = Object.keys(urlCategories);
		const match = keys.find(key => urlCategories[key].siteId === siteId && url.indexOf(urlCategories[key].section.urlFragment) > -1);


		if (match) {
			return {...urlCategories[match].section, url};
		} else {
			return defaultInfo;
		}
	},

	getURLInfoArray(urlDataArray) {
		return urlDataArray.map(urlData => this.getURLInfo(urlData.url, urlData.siteId));
	},

	getUserName() {
		const conf = AccountConfiguration.configuration;
		if (conf && conf.user && conf.user.profile && conf.user.profile.displayName) {
			return conf.user.profile.displayName;
		} else {
			return 'agent';
		}
	},

	getUserEmail() {
		const conf = AccountConfiguration.configuration;
		if (conf && conf.user && conf.user.profile && conf.user.profile.preferredEmail) {
			return conf.user.profile.preferredEmail;
		} else {
			return '';
		}
	},

	getUserExternalIds() {
		const conf = AccountConfiguration.configuration;
		if (conf && conf.user && conf.user.externalIds) {
			return {...conf.user.externalIds};
		} else {
			return {};
		}
	},

	getUserId() {
		const conf = AccountConfiguration.configuration;
		if (conf && conf.user && conf.user.id) {
			return conf.user.id;
		} else {
			return false;
		}
	},

	getGroupRouting() {
		const conf = AccountConfiguration.configuration;
		const groups = conf.groups;
		const groupKeys = Object.keys(groups);
		return groupKeys.reduce((obj, groupKey) => {
			obj[groupKey] = groups[groupKey].routingType
				? groups[groupKey].routingType
				: 0;
			return obj;
		}, {});
	},

	getMaxConversationsPerGroup() {
		const conf = AccountConfiguration.configuration;
		const groups = conf.groups;
		const groupKeys = Object.keys(groups);
		return groupKeys.reduce((obj, groupKey) => {
			obj[groupKey] = groups[groupKey].maxInteractionsPerUser || 0;
			return obj;
		}, {});
	},

	// PLUGIN ACCESS functions

	getAgentGroups() {
		const conf = AccountConfiguration.configuration;
		const userGroups = conf.user.groupIds;
		return userGroups.reduce((obj, groupId) => {
			obj[groupId] = {
				name: (conf.groups[groupId] || {name: ''}).name,
			};
			return obj;
		}, {});
	},

	getStreamingServerUrl() {
		const DEFAULT_SOCKET_URL = 'https://rtc.vergic.com';	// [ TL ] Default rtc-server (if no server specified in "Account/Visitor Behavior/Override default video streaming server")
		const conf = AccountConfiguration.configuration;
		return conf.visitor.videoStreamingServer || DEFAULT_SOCKET_URL;
	},

	getEnableVideoChat() {
		return AccountConfiguration.configuration.account.enableVideoChat;
	},

	getEnableWebCall() {
		return !!AccountConfiguration.configuration.account.enableWebCall;
	},

	getIntegrationType() {
		const conf = AccountConfiguration.configuration;
		const integrations = conf && conf.workspace && conf.workspace.integration
			? conf.workspace.integration
			: {};
		return Object.keys(integrations).find(key => integrations[key] && integrations[key].enabled) || 'none';
	},

	getInInContactData() {
		const conf = AccountConfiguration.configuration;
		const hasData = conf && conf.workspace && conf.workspace.integration && conf.workspace.integration.inContact;
		if (hasData) {
			const {enabled, ...data} = conf.workspace.integration.inContact;
			return data;
		} else {
			return {
				vendorName: '',
				applicationName: '',
				businessUnit: '',
				authUrl: ''
			};
		}
	},

};
