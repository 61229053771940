export default function($uibModalInstance, configurationSet, analyzedSet, ConfigurationSetService, StateReloadService) {
    'ngInject';

    var vm = this;
    vm.saveChanges = saveChanges;
    vm.cancelSave = $uibModalInstance.dismiss;

    function saveChanges() {
        vm.isSaving = true;
		$uibModalInstance.freeze(true);

        ConfigurationSetService.saveSet(configurationSet, analyzedSet, vm.newData.comments)
            .then(function(res) {
                StateReloadService.reload().then($uibModalInstance.close);
            })
            .catch(function() {
                vm.isSaving = false;
				$uibModalInstance.freeze(false);
            });
    }
};
