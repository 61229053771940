//import vngageAddModalTemplate from '../../../common/vngageAddModalTemplate';
import moment from 'moment-timezone';
import _find from 'lodash/find';
import _get from 'lodash/get';

export default function (uiCalendarConfig, groupList, userList, reservationsService, $uibModal, $state, $scope, $timeout, reservationModalService, APIEndpointService, usSpinnerService, Session) {
	'ngInject';

	var vm = this;
	vm.workspaceLang = _get(Session, 'user.profile.language', 'en').split('-')[0];
	vm.groupList = groupList;
	vm.userList = userList;
	vm.selectedGroupId = null;
	vm.selectedGroup = null;
	vm.calendarStartHour = '08:00';
	vm.calendarEndHour = '20:00';

	var updateMinMaxCalendarTimes = function () {
		$timeout(function () {
			// Need to be put in $timeout because fullcalendar will crash if 'option' is changed during render/init(?)
			if (uiCalendarConfig.calendars.reservationsCalendar.fullCalendar) {
				uiCalendarConfig.calendars.reservationsCalendar.fullCalendar('option', {
					minTime: vm.calendarStartHour,
					maxTime: vm.calendarEndHour
				});
			}
		});
	};

	var eventClickHandler = function (event) {
		viewReservation(event);
		return;
	}
	var dayClickHandler = function (event) {
		return;
	}

	function viewReservation(reservation) {
		//vm.loadingCase = true;
		//usSpinnerService.spin('spinner-view-case-loading');
		reservationModalService.open({
			reservation: reservation,
			groupTimeZone: vm.selectedGroup.data.timeZone,
			userList: userList
		}).catch(() => {
			// Modal dismissed
		}).finally(() => {
			$timeout(function () {
				uiCalendarConfig.calendars.reservationsCalendar.fullCalendar('refetchEvents');
			}, 300);
		});
		//usSpinnerService.stop('spinner-view-case-loading');
	}

	$scope.$watch('vm.selectedGroupId', function () {
		vm.selectedGroup = _find(vm.groupList, (group) => {
			return (group.data.id === vm.selectedGroupId)
		});
		if (uiCalendarConfig.calendars.reservationsCalendar && typeof uiCalendarConfig.calendars.reservationsCalendar.fullCalendar === 'function') {
			uiCalendarConfig.calendars.reservationsCalendar.fullCalendar('refetchEvents');
		}
	});

	vm.uiConfig = {
		// todo why is the calendar not responding to height?
		calendar: {
			lang: vm.workspaceLang,
			locale: vm.workspaceLang,

			height: 300,
			firstDay: 1,
			minTime: vm.calendarStartHour,
			maxTime: vm.calendarEndHour,
			defaultView: 'agendaWeek',
			header: {
				left: 'title',
				center: 'agendaWeek,agendaDay',
				right: 'today prev,next'
			},
			dayClick: dayClickHandler,
			eventClick: eventClickHandler,
			displayEventTime: false,
			slotEventOverlap: false,
			allDaySlot: false,
			slotLabelFormat: 'H:mm',
			views: {
				day: {
					timeFormat: 'H:mm',
					columnFormat: 'ddd Do'
				},
				week: {
					timeFormat: 'H:mm',
					columnFormat: 'ddd Do'
				}
			}
		}
	};
	vm.calendarEventSources = {
		reservations: {
			stick: true,
			events: function (start, end, timezone, callback) {
				if (!vm.selectedGroupId) {
					// No group selected: Just clear all events
					callback([]);
					return;
				}

				start = moment.tz(start.time(0).format(), vm.selectedGroup.timeZone).startOf('isoWeek');
				end = moment(start).endOf('isoWeek');
				reservationsService.queryReservations({
					groupId: vm.selectedGroupId,
					range: {
						from: start,
						to: end
					}
				}).then(function (res) {
					// console.log('FIXED RESPONSE: ',res);
					var events = res;
					var minMaxChanged = false;

					events.forEach(function (e) {
						var color = '#008996';
						if (e.pendingReply) {
							color = '#8A8787';
						}
						if (e.hasReplied) {
							color = '#228000';
						}
						if (e.cancelled) {
							color = '#860D00';
						}

						e.start = moment.tz(e.start, vm.selectedGroup.data.timeZone);
						e.end = moment(e.start).add(60, 'minute');  // FK wants all slots to be rendered as 1 hour long in the calendar (even though the actual meeting-time is different!)
						//e.end = moment(e.start).add(moment.duration(e.duration));

						e.title = e.comment || e.name + ' ' + e.lastName;
						e.displayEventTime = false;
						e.color = color;
						e.textColor = '#fff';

						if (setEarliestAndLatestCalendarTimes(e)) {
							minMaxChanged = true;
						}
					});
					if (minMaxChanged) {
						updateMinMaxCalendarTimes();
					}
					callback(events);
				});
			}
		}
	}
	vm.calendarEvents = [vm.calendarEventSources.reservations];

	function setEarliestAndLatestCalendarTimes(event) {
		var start = moment(event.start);
		var end = moment(event.end);
		var changed = false;
		if (moment.duration(start.format('HH:mm:ss')) < moment.duration(vm.calendarStartHour)) {
			vm.calendarStartHour = start.format('HH') + ':00';
			changed = true;
		}
		if (moment.duration(end.format('HH:mm:ss')) > moment.duration(vm.calendarEndHour)) {
			if (end.format('mm:ss') === '00:00') {
				vm.calendarEndHour = end.format('HH') + ':00';
			} else {
				if (end.format('HH') === '23') {
					vm.calendarEndHour = '23:59:59';
				} else {
					vm.calendarEndHour = end.add(1, 'hours').format('HH') + ':00';
				}
			}
			changed = true;
		}
		return changed;
	}
};
