import SoundFx from '../SoundFx';
import WebNotificationService from '../WebNotificationService';
import _debounce from 'lodash/debounce';


let state = {
	messages: []
};

function markAsRead() {
	state.messages = [];
}

function doNotify() {
	WebNotificationService.notify('chatMessage', state.messages);
	if (!psPlugin.application.configuration.account.muteDesktopAudio) {
		SoundFx.play('message');
	}
	markAsRead();

}

var notify = _debounce(doNotify, 200);

function incomingMessage(message){
	state.messages.push(message);
	notify();
}

export default {
	incomingMessage: incomingMessage
};
