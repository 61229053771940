/*eslint-disable */
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';
import _each from 'lodash/each';
import _keys from 'lodash/keys';
import _map from 'lodash/map';

import Session from '../../common/auth/Session';
import {parse} from '../../common/configurationParser';
import AccountConfiguration from '../../common/AccountConfiguration';
import urlHelpers from '../../common/urlHelpers';

export default function ($q, $uibModal, StateReloadService, APIEndpointService, $state, $timeout, vngageConfig) {
	'ngInject';
	'use strict';

	var service = {
		configurationValue: null,

		get configuration() {
			return AccountConfiguration.configuration;
		},
		set configuration(value) {
			AccountConfiguration.configuration = value;
		}
	};

	service.connection = {
		status: 2
	};

	service.connectionStatus = function connectionStatus(status) {
		return $q(function (resolve, reject) {
			if (typeof status === 'number') {
				if (service.connection.status !== status && status === 1) {
					service.connection.status = status;
					resolve(1);
				} else if (service.connection.status !== status && status === 2) {
					service.connection.status = status;
					resolve(2);
				} else if (service.connection.status !== status && status === 0) {
					service.connection.status = 0;
					reject(0);
				}
			} else {
				reject();
			}
		});

	};

	service.getConfiguration = function () {
		return $q(function (resolve, reject) {

			//if we have fetched the configuration once, just return that one instead.
			if (service.configuration) {
				return resolve(service.configuration);
			}

			APIEndpointService.accountCurrentConfiguration.get().$promise.then(function (res) {

				const config = res.data;
				const params = {
					id: config.liveConfigurationSetId,
					revision: config.liveConfigurationSetIdRevision
				};

				APIEndpointService.configurationSetResolved.get(params).$promise.then(function (res) {

					service.configuration = parse(res);
					//TODO: deprecate in favor of using Session.user in legacy code
					service.configuration.user = Session.user;

					//optional: map the resolved sites objects
					service.configuration.desktop = {
						languages: mapSites(pickData(res.sites), service.configuration)
					};


					//Necessary?
					service.configuration.siteIds = _keys(service.configuration.sites);
					service.configuration.groupIds = _keys(service.configuration.groups);

					service.configuration.coBrowserSecurity = {
						check: (document.location.protocol === 'https:' && service.configuration.visitor.domCoBrowsing !== true)
					};

					// Get the coBrowsingWhiteListHostNames from config and add it to service.configuration.account (to be able to check CobBo-urls against the whitelist)
					service.configuration.account.coBrowsingWhiteListHostNames = config.coBrowsingWhiteListHostNames || [];

					// Also get "restUrl" for this account and add its origin to coBrowsingWhiteListHostNames (i.e. always allow DOM-uploaded pages).
					// We'd actually want to add the System setting "CommunicationsServerUrl", but a normal "User" can't access that, so use vngageConfig.restUrl instead
					// (should be the same, as BE uses "CommunicationsServerUrl" to generate "restUrl" in index.html)
					const commServerUrl = urlHelpers.getOriginFromURL(vngageConfig.restUrl);
					if (commServerUrl) {
						const commServerUrlParts = commServerUrl.split('://');
						if (commServerUrlParts.length === 2) {
							service.configuration.account.coBrowsingWhiteListHostNames.push(commServerUrlParts[1]); // Push the domain name only (, )without protocol)
						}
					}

					resolve(service.configuration);

				}, function (res) {

					console.error(res);
					reject(res);

				});

			}, function (res) {
				console.error(res);
				reject(res);
			});
		});
	};


	function pickData(entities) {
		return _map(entities, function (entity) {
			return entity.data;
		});
	}


	function mapSites(obj, conf) {
		var collection = _map(obj, function (s) {
			var out = {
				id: s.id,
				name: s.name,
				languageCode: s.languageCode,
				groups: _keyBy(_filter(conf.groups, {siteId: s.id}), 'id'),
				actionPanels: _filter(conf.actionPanels, {siteId: s.id}),
				actions: _filter(conf.actions, {siteId: s.id}),
				cases: {}
			};

			_each(conf.customerCases, function (item) {
				if (item.siteId === s.id) {
					out.cases[item.id] = item;
				}
			});
			return out;
		});

		collection = _keyBy(collection, 'id');

		return collection || {};
	}


	return service;
}
