export default function (access) {
	return {
		id: 'agents',
		access: (access || 'Statistics'),
		viewConfig: {
			metricName: 'UserCaseUsagePerHour',

			/** ----------------------------------------------* Measures */
			measures: [
				{
					key: 'userName',
					type: 'string'
				}, {
					key: 'loggedInTimeMs',
					type: 'duration'
				}, {
					key: 'availableTimeMs',
					type: 'duration'
				}, {
					key: 'engagedTimeMs',
					type: 'duration'
				}, {
					key: 'dialogTimeMs',
					type: 'duration'
				}, {
					key: 'numberOfDialogs',
					type: 'number'
				}, {
					key: 'avg_sim_dialogs',
					calculation: {
						type: 'division',
						parameters: [
							'dialogTimeMs',
							'engagedTimeMs'
						]
					},
					type: 'number'
				}, {
					key: 'avg_dialog_time',
					calculation: {
						type: 'division',
						parameters: [
							'dialogTimeMs',
							'numberOfDialogs'
						]
					},
					type: 'duration'
				}, {
					key: 'numberOfClosedCases',
					type: 'number'
				}, {
					key: 'numberOfTransfers',
					type: 'number'
				}, {
					key: 'numberOfRelatedAssistedPurchasePois_sameVisit',
					type: 'number'
				}, {
					key: 'numberOfRelatedAssistedPurchasePois_6Hours',
					type: 'number'
				}
			],


			/** ----------------------------------------------* Bar filters */
			barFilters: [
				{
					dimension: 'userName',
					valueAccessor: 'numberOfDialogs'
				}
			],


			/** ----------------------------------------------* Time line graph */
			timeLineGraph: {
				valueAccessor: 'numberOfDialogs'
			},


			/** ----------------------------------------------* Totals */
			totals: [
				{
					measure: 'loggedInTimeMs'
				}, {
					measure: 'availableTimeMs'
				}, {
					measure: 'engagedTimeMs'
				}, {
					measure: 'numberOfDialogs'
				}, {
					measure: 'dialogTimeMs'
				}, {
					measure: 'avg_sim_dialogs',
					calculation: {
						type: 'division',
						parameters: [
							'dialogTimeMs',
							'engagedTimeMs'
						]
					},
					decimals: 1
				}, {
					measure: 'avg_dialog_time',
					calculation: {
						type: 'division',
						parameters: [
							'dialogTimeMs',
							'numberOfDialogs'
						]
					},
					decimals: 1
				}, {
					measure: 'numberOfClosedCases'
				}, {
					measure: 'numberOfTransfers',
					displayPercentOf: 'numberOfDialogs'
				}, {
					measure: 'numberOfRelatedAssistedPurchasePois_sameVisit',
					displayPercentOf: 'numberOfClosedCases'
				}, {
					measure: 'numberOfRelatedAssistedPurchasePois_6Hours',
					displayPercentOf: 'numberOfClosedCases'
				}
			],


			/** ----------------------------------------------* Table */
			table: {
				groupBy: 'userName',
				measures: [
					{
						measure: 'loggedInTimeMs',
						calculation: {
							type: 'formatDuration',
							parameters: [
								'loggedInTimeMs',
								3600000	// Display progress-bar, relative to one hour (3600000 ms)
							]
						}
					}, {
						measure: 'availableTimeMs',
						calculation: {
							type: 'formatDuration',
							parameters: [
								'availableTimeMs',
								3600000	// Display progress-bar, relative to one hour (3600000 ms)
							]
						}
					}, {
						measure: 'engagedTimeMs',
						calculation: {
							type: 'formatDuration',
							parameters: [
								'engagedTimeMs',
								3600000 // Display progress-bar, relative to one hour (3600000 ms)
							]
						}
					}, {
						measure: 'numberOfDialogs'
					}, {
						measure: 'dialogTimeMs'
					}, {
						measure: 'numberOfClosedCases'
					}, {
						measure: 'numberOfTransfers',
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'numberOfTransfers',
								'numberOfDialogs'
							]
						}
					}, {
						measure: 'numberOfRelatedAssistedPurchasePois_sameVisit',
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'numberOfRelatedAssistedPurchasePois_sameVisit',
								'numberOfClosedCases'
							]
						}
					}, {
						measure: 'numberOfRelatedAssistedPurchasePois_6Hours'
					}
				]
			}
		}
	};
}
