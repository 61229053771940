import {getItemNavigationDataAfterRemove} from '../../common/UiHelpers';
import DesktopPluginAPI from '../desktopDeux/pluginAPI/DesktopPluginAPI';
import DesktopPluginsHandler from './DesktopPluginsHandler';
import DesktopPluginsAddActionMessageMappingTemplate from './DesktopPluginsAddActionMessageMappingTemplate.html';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';

export default function (desktopPluginsSettings, plugin, groupList, actionMessagesList, $scope, $state, $uibModal, Session, APIEndpointService, VngageUtil, $timeout, $translate, WorkspaceSettingsService, NotificationService, AccountActionService, $stateParams) {
	'ngInject';

	let vm = this;
	vm.isNew = !desktopPluginsSettings;
	vm.desktopPluginsSection = desktopPluginsSettings || {};

	vm.actualVersion = DesktopPluginAPI.getActualVersion(plugin.apiVersion);
	vm.plugin = {
		...DesktopPluginAPI.getPluginTemplate(vm.actualVersion),
		...plugin
	};

	vm.displayModes = [{
		translationKey: 'plugin.DISPLAY_MODE_VALUE_FLOATING',
		value: 'floating'
	}, {
		translationKey: 'plugin.DISPLAY_MODE_VALUE_HIDDEN',
		value: 'hidden'
	}, {
		translationKey: 'plugin.DISPLAY_MODE_VALUE_EXTERNAL',
		value: 'external'
	}, {
		translationKey: 'plugin.DISPLAY_MODE_VALUE_PINNED',
		value: 'pinned'
	}
	];

	vm.sectionButtonTypes = [{
		translationKey: 'plugin.SECTION_BUTTON_VALUE_NONE',
		value: 'none'
	}, {
		translationKey: 'plugin.SECTION_BUTTON_VALUE_BUTTON',
		value: 'button'
	}];

	vm.groupsList = [];
	vm.subscriptionsList = [];
	vm.actionsList = [];
	vm.currentList = $stateParams.currentList || [];

	vm.plugin.sectionButtonType = vm.plugin.sectionButtonType || 'none';

	vm.availableGroups = [];
	vm.availableSubscriptions = [];
	vm.availableActions = [];
	vm.apiVersionError = '';
	vm.apiVersionSupported = DesktopPluginAPI.apiVersionSupported(plugin.apiVersion);
	if (!vm.apiVersionSupported) {
		// Exit if this plugin's API-version is not supported in this Workspace build
		vm.apiVersionError = 'Warning: This API-version is not supported in this Workspace build! Editing disabled.';
		return;
	}

	vm.availableGroups = DesktopPluginsHandler.formatSelectableItems(groupList);
	vm.enabledGroups = DesktopPluginsHandler.formatSelectedGroupItems(plugin.enabledGroups, groupList);

	vm.availableSubscriptions = DesktopPluginsHandler.formatSelectableItems(DesktopPluginAPI.getAvailableSubscriptions(plugin.apiVersion));
	vm.enabledSubscriptions = DesktopPluginsHandler.formatSelectedItems(plugin.enabledSubscriptions);

	vm.availableActions = DesktopPluginsHandler.formatSelectableItems(DesktopPluginAPI.getAvailableActions(plugin.apiVersion));
	vm.enabledActions = DesktopPluginsHandler.formatSelectedItems(plugin.enabledActions);

	vm.actionMessagesList = actionMessagesList.map(action => {
		// Lookup and add itemTypeName to the actions (to easier display it in lists)
		action = _cloneDeep(action);
		if (_get(action, 'data.section.itemType')) {
			action.data.section.itemTypeName = $translate.instant(AccountActionService.getActionTypes()[action.data.section.itemType]);
		}
		return action;
	});

	vm.mappedActionMessages = DesktopPluginsHandler.formatActionMessageMappings(plugin.mappedActionMessages, vm.actionMessagesList);

	vm.save = save;
	vm.cancel = cancel;
	vm.deletePlugin = deletePlugin;
	vm.addOrUpdateMapping = addOrUpdateMapping;

	$timeout(() => {
		// Display error-messages for any invalid, required fields on init (should not happen for new plugins, as URL is now required already in the add-modal)
		angular.forEach(vm.pluginForm.$error.required, function (field) {
			field.$setDirty();
		});
	});

	function addOrUpdateMapping(editMappedAction) {

		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: DesktopPluginsAddActionMessageMappingTemplate,
			controller: 'DesktopPluginsAddActionMessageMappingController as vm',
			resolve: {
				editMappedAction: () => editMappedAction,
				actionMessagesList: () => vm.actionMessagesList,
				mappedActionMessages: () => vm.mappedActionMessages
			}
		});

		modal.result.then(editedActionMessageItem => {
			if (!editedActionMessageItem.actionId && editedActionMessageItem.id) { // actionId set to null indicates delete
				// Delete mapping...
				vm.mappedActionMessages = vm.mappedActionMessages.filter(item => item.id !== editedActionMessageItem.id);
				vm.pluginForm.$setDirty();

			} else {
				// Add or update mapping...

				const action = vm.actionMessagesList.find(action => action.data.id === editedActionMessageItem.actionId);
				if (action) {
					const actionMessageMapping = {
						id: editedActionMessageItem.id,
						actionMessageName: editedActionMessageItem.actionMessageName,
						actionId: action.data.id,
						actionName: action.data.name,
						itemTypeName: action.data.section.itemTypeName
					};

					const insertIndex = vm.mappedActionMessages.findIndex(item => item.id === editedActionMessageItem.id);
					if (insertIndex !== -1) {
						// Update edited mapping
						vm.mappedActionMessages[insertIndex] = actionMessageMapping;
					} else {
						// Add new mapping
						vm.mappedActionMessages.push(actionMessageMapping);
					}

					vm.pluginForm.$setDirty();
				} else {
					NotificationService.error({
						header: 'Add Action Message',
						body: 'Unknown actionId: ' + editedActionMessageItem.actionId
					});
				}
			}
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function save() {
		vm.isSaving = true;
		let newPlugin = vm.plugin;
		newPlugin = DesktopPluginsHandler.updateSubscriptions(newPlugin, vm.enabledSubscriptions, vm.availableSubscriptions);
		newPlugin = DesktopPluginsHandler.updateActions(newPlugin, vm.enabledActions, vm.availableActions);
		newPlugin = DesktopPluginsHandler.updateGroups(newPlugin, vm.enabledGroups);
		newPlugin = DesktopPluginsHandler.updateActionMessageMappings(newPlugin, vm.mappedActionMessages);

		vm.subscriptionsList = [];
		vm.actionsList = [];

		vm.desktopPluginsSection.pluginsList = DesktopPluginsHandler.addOrUpdateDesktopPluginsList(vm.desktopPluginsSection.pluginsList, newPlugin);
		WorkspaceSettingsService.saveWorkspaceSettingsSections({desktopPlugins: vm.desktopPluginsSection}).catch((error) => {
			NotificationService.error({
				header: 'Edit plugin settings',
				body: 'Could not save plugin: ' + (error ? (error.data ? error.data.message: error.statusText): 'unknown')
			});
			vm.pluginForm.$setDirty();
		}).finally(() => {
			vm.isSaving = false;
		});
	}

	function cancel() {
		$state.go('^');
	}

	function deletePlugin() {
		if (VngageUtil.confirmSimple('Are you sure you want to delete?')) {
			vm.isSaving = true;
			vm.pluginForm.$setPristine();
			vm.desktopPluginsSection.pluginsList = DesktopPluginsHandler.deleteFromDesktopPluginsList(vm.desktopPluginsSection.pluginsList, vm.plugin.id);
			WorkspaceSettingsService.saveWorkspaceSettingsSections({desktopPlugins: vm.desktopPluginsSection}).then(() => {
				const itemNavigationData = getItemNavigationDataAfterRemove(vm.plugin, 'root.desktopPlugins.edit', vm.currentList);
				vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
				$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
				// $state.go('^', null, {reload: true});
			}, (error) => {
				NotificationService.error({
					header: 'Delete plugin',
					body: 'Could not delete plugin: ' + (error ? (error.data ? error.data.message: error.statusText): 'unknown')
				});
			}).finally(() => {
				vm.isSaving = false;
			});
		}
	}

}
