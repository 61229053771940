import immutable from 'immutable';
import {filters} from './filters';
import {coBroLib} from './coBroLib';

/**
 *
 * @param profile(JS object)
 * @returns {string}
 */
function getMetaInfo(profile) {
	const meta = profile.meta || {os: {}, browser: {}};
	const channel = meta.os.channel || '';
	const osName = meta.os.osName || '';
	const deviceName = meta.os.deviceName || '';
	const browserName = meta.browser.name || '';
	const browserVersion = parseInt(meta.browser.version || 0, 10) || '';

	return `${channel} ${osName} ${deviceName} ${browserName} ${browserVersion}`.trim();
}

function setVisitorProfile(state, profile) {
	state = state.mergeIn(['visitorProfiles', profile.data.id], immutable.fromJS(profile));
	state = state.setIn(['visitorProfiles', profile.data.id, 'meta', 'metaInfo'], immutable.fromJS(getMetaInfo(profile)));
	return state;
}

function updateVisitorProfile(state, messages) {
	const profileMessages = filters.getFilteredArray(filters.identifier.visitProfile, messages);
	state = profileMessages.reduce((state, msg) => {
		const conversationId = msg.conversationId;
		const visitId = state.getIn(['conversations', conversationId, 'visitId']);
		let profile = state.getIn(['visitorProfiles', visitId, 'data']);
		if (profile) {
			profile = profile.set('namedIdentity', msg.namedIdentity || '');
			profile = profile.set('authenticationLevel', msg.authenticationLevel || '');
			profile = profile.set('claims', immutable.fromJS(msg.claims));
			state = state.mergeIn(['visitorProfiles', visitId, 'data'], profile);
		}
		return state;

	}, state);
	return state;
}

function removeUnusedData(state) {
	// get visitIds and conversationsIds from visitors and conversations,
	// match against 'visitorProfiles' and 'cases' and remove obsolete data

	const visitIdsFromVisitors = state.get('visitors').map(visitor => visitor.get('visitId'));
	const visitIdsFromConversations = state.get('conversations').map(conversation => conversation.get('visitId') || '');
	const activeProfiles = state.get('visitorProfiles').filter((value, key) => visitIdsFromVisitors.contains(key) || visitIdsFromConversations.contains(key));

	state = state.set('visitorProfiles', activeProfiles);
	return state;
}

function removeVisitorProfile(state, visitId) {
	state = state.deleteIn(['visitorProfiles', visitId]);
	return state;
}

function setVisitorProfileNavigation(state, visitId, navigation) {
	state = state.setIn(['visitorProfiles', visitId, 'navigation'], immutable.fromJS(navigation));
	return state;
}

function getCurrentRandomValue(state, min, max) {
	const seed = state.get('seed');
	const rnd = seed / 233280;
	return (min + rnd * (max - min)) | 0;
}

function stepRandomValue(state) {
	const seed = state.get('seed');
	state = state.set('seed', (seed * 9301 + 49297) % 233280);
	return state;
}

function setInvitationCode(state) {
	const code = getCurrentRandomValue(state, 10000, 99999);
	state = state.set('invitationCode', code);
	state = stepRandomValue(state);
	return state;
}

function addSelectAnimation(state, conversationId, startY) {
	if (!immutable.OrderedMap.isOrderedMap(state.get('selectedItemPositions'))) {
		state = state.set('selectedItemPositions', immutable.OrderedMap());
	}
	state = state.setIn(['selectedItemPositions', conversationId], immutable.fromJS({position: startY, time: 0}));
	return state;
}

function removeSelectAnimation(state, conversationId) {
	if (conversationId) {
		state = state.deleteIn(['selectedItemPositions', conversationId]);
	}
	return state;
}

function incSelectAnimationTime(state) {
	const increasedTimes = state.get('selectedItemPositions')
		.map(selectedItem => selectedItem.set('time', selectedItem.get('time') + 1));
	state = state.set('selectedItemPositions', increasedTimes);
	return state;
}

function removeDeadSelectAnimations(state, maxAge) {
	const filtered = state.get('selectedItemPositions').filter(selectedItem => selectedItem.get('time') <= maxAge);
	state = state.set('selectedItemPositions', filtered);
	return state;
}

function setDoNotDisturbInfoTip(state, prevDoNotDisturb, nextDoNotDisturb) {
	const userPreferenceActiveInfoTip = state.getIn(['userPreferences', 'activeInfoTip', 'doNotDisturb']);

	if (!prevDoNotDisturb && nextDoNotDisturb && userPreferenceActiveInfoTip) {
		state = state.setIn(['infoTip', 'doNotDisturb'], true);
	} else {
		state = state.setIn(['infoTip', 'doNotDisturb'], false);
	}

	return state;
}

function forceCloseInfoTip(state, type) {
	state = state.setIn(['infoTip', type], false);
	return state;
}

function setPanelPositionOnAllPanels(state, panelPosition) {
	state = state.update('conversations', conversations =>
		conversations.map(conversation => conversation.setIn(['UI', 'panelPosition'], panelPosition)));
	return state;
}

function setPanelPosition(state, conversationId, panelPosition) {
	// panelPositions normal, collapsed, coBro
	const prevPanelPosition = state.getIn(['conversations', conversationId, 'UI', 'panelPosition']);
	switch (panelPosition) {
		case 'collapsed':
			// set to collapsed
			break;
		case 'normal':
			// go to normal
			if (prevPanelPosition === 'coBro') {
				// coming from cobro
				state = setPanelPositionOnAllPanels(state, 'normal');
				state = coBroLib.endCoBro(state);
			}
			break;
		case 'coBro':
			state = setPanelPositionOnAllPanels(state, 'collapsed');
			state = coBroLib.startCoBro(state, conversationId);

			break;
	}
	state = panelPosition !== prevPanelPosition
		? state.set('panelAnimating', true)
		: state;
	state = state.setIn(['conversations', conversationId, 'UI', 'panelPosition'], panelPosition);

	return state;
}

export const sharedReducerLib = {
	getMetaInfo,
	setVisitorProfile,
	updateVisitorProfile,
	removeUnusedData,
	removeVisitorProfile,
	setVisitorProfileNavigation,
	getCurrentRandomValue,
	stepRandomValue,
	setInvitationCode,
	addSelectAnimation,
	removeSelectAnimation,
	incSelectAnimationTime,
	removeDeadSelectAnimations,
	setDoNotDisturbInfoTip,
	forceCloseInfoTip,
	setPanelPositionOnAllPanels,
	setPanelPosition,
};