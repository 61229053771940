import React from 'react';
import {
	Tooltip,
	OverlayTrigger,
} from 'react-bootstrap';

const PluginPanelButton = ({plugin, handleClick, selectedPlugin}) => {
	const isSelected = plugin.get('id') === selectedPlugin;
	const hasNotification = !!plugin.get('notification') && !isSelected;
	const iconStyle = {
		width: '40px',
		height: '30px',
		backgroundImage: `url(${plugin.get('pluginIconUrl')}`,
		backgroundSize: '20px 20px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center'
	};
	const toolTipStyle = isSelected
		? {display: 'none'}
		: {};

	const tooltip = (
		<Tooltip style={toolTipStyle} id="toast-tooltip">
			{plugin.get('name')}
		</Tooltip>);


	const cls = `plugin-toggle ${isSelected ? 'active': ''} ${hasNotification ? 'show-notification': ''}`;
	return (
		<OverlayTrigger disabled placement="left" overlay={tooltip} delay={400}>
			<div className={cls} onClick={() => handleClick(plugin.get('id'))}>
				{hasNotification && <div className="button-notification"/>}
				{plugin.get('pluginIconUrl')
					? <div style={iconStyle}/>
					: <i className="vngage-icon-lightbulb"/>
				}
			</div>

		</OverlayTrigger>
	);
};

export default PluginPanelButton;
