import moment from 'moment-timezone';

export default function ($state, SessionService, transitionService, VngageUtil, APIEndpointService, $q, usSpinnerService, ConfigurationSetService, context, activateContext, userList, vngageUserFilter) {
	'ngInject';

	var vm = this;
	vm.configurationSetId = activateContext.newestRevision.data.id;
	vm.selectedRevision = activateContext.newestRevision;
	vm.selectedRevisionNumber = vm.selectedRevision.revision;
	vm.selectedRevisionComment = vm.selectedRevision.data.comments;
	vm.selectedRevisionUpdatedAt = formatWithTZ(vm.selectedRevision.updatedAt);
	vm.revisionArr = Array.apply(null, new Array(vm.selectedRevision.revision + 1).map(Number.prototype.valueOf, 0)).map((n, i) => i).reverse();
	vm.analyzedSet = null;
	vm.selectedItem = null;
	vm.byUserSelected = vngageUserFilter(vm.selectedRevision.userId, userList);
	vm.formatWithTZ = formatWithTZ;
	vm.update = update;
	vm.updatedItemFilter = updatedItemFilter;

	function formatWithTZ(date) {
		return moment.utc(date).tz(SessionService.getUserTimezone()).format('dddd MMM D HH:mm - YYYY');
	}

	update();

	function update() {

		usSpinnerService.spin('spinner-revision');

		if (vm.selectedRevisionNumber === 0) {

			$q.all([
				APIEndpointService.configurationSet.get({id: vm.configurationSetId, revision: vm.selectedRevisionNumber}).$promise,
				APIEndpointService.configurationSetResolved.get({id: vm.configurationSetId, revision: vm.selectedRevisionNumber}).$promise
			]).then(function (res) {

				var result = ConfigurationSetService.combine(res[0], res[1]);

				vm.selectedRevision = res[0];

				vm.analyzedSet = ConfigurationSetService.analyzeSet(activateContext.liveRevisionSet, activateContext.liveRevisionSetResolved, result);
				vm.selectedRevisionComment = vm.selectedRevision.data.comments;
				vm.selectedRevisionUpdatedAt = formatWithTZ(vm.selectedRevision.updatedAt);
				vm.byUserSelected = vngageUserFilter(vm.selectedRevision.userId, userList);

				usSpinnerService.stop('spinner-revision');
			});

			return;
		}

		$q.all([
			APIEndpointService.configurationSet.get({id: vm.configurationSetId, revision: vm.selectedRevisionNumber}).$promise,
			APIEndpointService.configurationSetResolved.get({id: vm.configurationSetId, revision: vm.selectedRevisionNumber}).$promise,
			APIEndpointService.configurationSet.get({id: vm.configurationSetId, revision: vm.selectedRevisionNumber - 1}).$promise,
			APIEndpointService.configurationSetResolved.get({id: vm.configurationSetId, revision: vm.selectedRevisionNumber - 1}).$promise
		]).then(function (res) {

			var result = ConfigurationSetService.combine(res[0], res[1]);

			vm.selectedRevision = res[0];

			var previous = {
				configurationSet: res[2],
				configurationSetResolved: res[3]
			}

			vm.analyzedSet = ConfigurationSetService.analyzeSet(previous.configurationSet, previous.configurationSetResolved, result);
			vm.selectedRevisionComment = vm.selectedRevision.data.comments;
			vm.selectedRevisionUpdatedAt = formatWithTZ(vm.selectedRevision.updatedAt);
			vm.byUserSelected = vngageUserFilter(vm.selectedRevision.userId, userList);

			usSpinnerService.stop('spinner-revision');
		});
	}

	function updatedItemFilter(item) {
		return ConfigurationSetService.updatedItem(item);
	}
};
