import _omit from 'lodash/omit';
import AppDispatcher from '../dispatcher/AppDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import WebAPIUtils from '../utils/WebAPIUtils';
import MessageUtils from '../../../components/utils/MessageUtils';
import ConversationsStore from '../stores/ConversationsStore';

const ActionTypes = AppConstants.ActionTypes;
const PayloadSources = AppConstants.PayloadSources;

export default {

	/**
	 * create chat message vo
	 * dispatches for optimistic insert
	 * post
	 * @param text
	 * @param conversationId
	 * @param profile
	 */
	createMessage (text, conversationId, profile) {
		let createdMessageData = MessageUtils.getCreatedMessageData(text, conversationId);
		let message = _omit(createdMessageData, 'speaker');
		let serverMsg = _omit(message, 'id');

		WebAPIUtils.createMessage(serverMsg, conversationId);

		AppDispatcher.handleViewAction({
			type: ActionTypes.CREATE_MESSAGE,
			message: message,
			conversationId: conversationId,
			profile: profile
		});
	},

	/**
	 * create navigation message vo
	 * post
	 * @param payloadData
	 * @param conversationId
	 */
	createNavigationMessage(payloadData, conversationId) {
		let message = MessageUtils.getNavigationData(payloadData, conversationId);
		WebAPIUtils.createMessage(message, conversationId);
	},

	toggleMetaData (conversationId) {
		AppDispatcher.handleViewAction({
			type: ActionTypes.TOGGLE_META_MESSAGES,
			conversationId: conversationId
		})
	},

	/**
	 * create activity message vo - participantWriting
	 * post
	 * @param conversationId
	 * @param isTyping
	 */
	participantWriting (conversationId, isTyping) {
		WebAPIUtils.createActivityMessage(conversationId, {
			type: 'participantWriting',
			change: isTyping ? 'set' : 'reset',
			activity: 'writing'
		});
	},

	/**
	 * toggle visualguidance view scale
	 * dispatch internal status
	 * @param conversationId
	 * @param isScaled
	 */
	toggleVisualGuidanceScale (conversationId, isScaled) {
		AppDispatcher.handleViewAction({
			type: ActionTypes.TOGGLE_VISUAL_GUIDANCE_SCALE,
			conversationId: conversationId,
			isScaled: isScaled
		});
	},

	/**
	 * activate visualguidance jsChannel
	 * dispatch internal status
	 * @param conversationId
	 */
	activateVisualGuidanceChannel (conversationId) {
		let conversation = ConversationsStore.find(conversationId);
		if (conversation) {
			if (conversation.visualGuidance) {
				conversation.visualGuidance.getChannel();
			}
		}
	},

	/**
	 * deactivate visualguidance jsChannel
	 * dispatch internal status
	 * @param conversationId
	 */
	deactivateVisualGuidanceChannel (conversationId) {
		let conversation = ConversationsStore.find(conversationId);
		if (conversation && conversation.visualGuidance.channel) {
			conversation.visualGuidance.destroyChannel();
		}
	},

	/**
	 * visualguidance has loaded its iframe
	 * dispatch internal status
	 * @param conversationId
	 */
	visualGuidanceiFrameLoaded (conversationId) {
		AppDispatcher.handleViewAction({
			type: ActionTypes.VISUAL_GUIDANCE,
			conversationId: conversationId
		});
	},

	sendHighlight (conversationId, data) {
		// [TL] This was (in dialogguest) so it's just copied from 'desktop'
		// Untested and unknown if it works
		// (should a dialogguest even be able to highlight?)
		AppDispatcher.handleViewAction({
			type: ActionTypes.VISUAL_GUIDANCE,
			conversationId: conversationId,
			action: 'sendHighlight',
			data: data
		});

		// notify the others
		let message = MessageUtils.getHighlightMessageData(data);
		WebAPIUtils.createMessage(message, conversationId);
	},

	sendMetadataExchange(conversationId, data) {
		// Send metadata to visitor
		let message = MessageUtils.getMetadataExchangeMessageData(data);
		// console.log('ViewActions (dialogguest): sendMetadataExchange(), message:',message);
		WebAPIUtils.createMessage(message, conversationId);
	}
};
