var view = {
	createNodes: function (nodeObj) {
		var res = {};
		var createNode = function (node, returnElem) {
			var elem = document.createElement(node.tag);
			for (var att in node) {
				switch (att) {
					case 'onclick':
					case 'onmouseover':
					case 'onmouseout':
					case 'className':
						elem[att] = node[att];
						break;
					case 'children':
						for (var c in node[att]) {
							elem.appendChild(createNode(node[att][c], true));
						}
						break;
					case 'innerHTML':
						elem.innerHTML = node[att];
						break;
					case 'text':
						elem.innerText = elem.textContent = node[att];
						break;
					case 'tag':
					case 'reference':
						break;
					default:
						if (typeof node[att] === 'function' || att.indexOf('$') !== -1) continue;
						elem.setAttribute(att, node[att]);
						break;
				}
			}
			if (typeof node.reference !== 'undefined' && node.reference !== '') res[node.reference] = elem;
			if (returnElem) return elem;
		};
		createNode(nodeObj, false);
		return res;
	}
};

export default view;
