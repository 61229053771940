import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _isNumber from 'lodash/isNumber';

export default function($q) {
    'ngInject';

    var service = {
        formatSelectedItems: formatSelectedItems,
        formatSelectableItems: formatSelectableItems,
        prioritySetter: prioritySetter
    };

    function formatSelectedItems(opportunity, solutionList) {
        var selectedItems = [];
        opportunity.data.section.solutions.forEach(function(selectedSolutionId) {
            var displayName = _find(solutionList, function(solution){
                return solution.data.id === selectedSolutionId;
            }).data.name;
            selectedItems.push({
                refValue: selectedSolutionId,
                displayName: displayName
            });
        });
        return selectedItems;
    }

    function formatSelectableItems(solutionList) {
        var formattedList = [];
        solutionList.forEach(function(solution) {
            formattedList.push({
                refValue: {
                    refValue: solution.data.id,
                    displayName: solution.data.name
                },
                displayName: solution.data.name
            });
        });
        return formattedList;
    }

    function prioritySetter(opportunityList) {
        var prioList = [];

        opportunityList.forEach(function(resource) {
            addResource(resource);
        });

        prioList.sort(function(a, b) {
            return sectionPosition(a.refItem) - sectionPosition(b.refItem);
        });

        function addResource(resource) {
            prioList.push({
                refItem: resource,
                originalIndex: sectionPosition(resource)
            });
        }

        function sectionPosition(resource, value) {
            if (_isNumber(value)) {
                resource.data.section.position = value;
            }

            value = Number(resource.data.section.position);
            return isNaN(value) ? Infinity : value;
        }

        function swapListPosition(resource, relativeIndex) {
            var index = getIndex(resource);
            prioList[index] = prioList.splice(index + relativeIndex, 1, prioList[index])[0];
        }

        function getIndex(resource) {
            return _findIndex(prioList, function(item) {
                return resource === item.refItem;
            });
        }

        return {
            isChanged: false,
            canRaise: function(resource) {
                return getIndex(resource) > 0;
            },
            canLower: function(resource) {
                return getIndex(resource) < (prioList.length - 1);
            },
            raise: function(resource) {
                swapListPosition(resource, -1);
                this.isChanged = true;
            },
            lower: function(resource) {
                swapListPosition(resource, 1);
                this.isChanged = true;
            },
            position: function(resource) {
                return getIndex(resource) + 1;
            },
            add: function(newResource) {
                addResource(newResource);
            },
            save: function() {
                var promises = [];

                this.isChanged = false;

                prioList.forEach(function(item, index) {
                    var resource = item.refItem,
                        position = index + 1;

                    if (position !== item.originalIndex) {
                        sectionPosition(resource, position);
                        promises.push(resource.$save());
                        item.originalIndex = position;
                    }
                });

                return $q.all(promises);
            },
            newItemPriority: function() {
                var lastItem = prioList[prioList.length - 1];
                return (lastItem ? sectionPosition(lastItem.refItem) : 0) + 1;
            }
        };
    }

    return service;
};
