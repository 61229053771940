import React from 'react';
import AddressBar from './AddressBar.react.js';
import Toolbar from './Toolbar.react';

const Header = (props) => {
	const addressBarPayload = {
		navigation: props.navigation,
		paused: props.paused,
		restricted: props.restricted,
		addressbarNavigation: props.addressbarNavigation,
		connectionStatus: props.connectionStatus,
		updateCoBroUrl: props.updateCoBroUrl,
		doNotDisturb: props.doNotDisturb
	};

	return (
		<div className="co-header" id="white-label-cobrowser-header">
			{props.enabledFeatures.addressBar &&
			<AddressBar conversationId={props.conversationId} {...addressBarPayload}/>
			}
			<Toolbar conversationId={props.conversationId} {...props}/>
		</div>
	);
};
export default Header;

