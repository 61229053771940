export default {
	get defaultSettings() {
		return {
			name: 'Default settings',
			section: {
				groupId: '',
				fileSharing: {
					enabled: false,
					mimeTypes: []
				},
				startInCoBrowsingMode: {
					enabled: false,
				}
			}
		};
	},
	get newSettings() {
		return {
			name: 'New settings',
			section: {
				groupId: '',
				fileSharing: {
					enabled: false,
					mimeTypes: [
						'image/*',
						'application/pdf',
						'application/word',
						'application/vnd.ms-excel',
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						'application/vnd.ms-powerpoint',
						'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
					]
				},
				startInCoBrowsingMode: {
					enabled: true,
				}
			}
		};
	},


};

