import immutable from 'immutable';

export function setMaxConversationsPerGroup(state, action) {
	state = state.set('maxConversationsPerGroup', immutable.fromJS(action.maxConversationsPerGroup));
	return state;
}

export function initGroupAvailability(state, action) {
	const savedGroupIds = action.savedGroupIds || {};
	let initObj = action.groupIds.reduce((obj, id) => {
		obj[id] = savedGroupIds[id] !== undefined
			? savedGroupIds[id]
			: true;
		return obj;
	}, {});
	state = state.set('myGroupAvailability', immutable.fromJS(initObj));
	return state;
}

export function setGroupAvailability(state, action) {
	state = state.mergeIn(['myGroupAvailability'], immutable.fromJS(action.groupChanges));
	return state;
}

export function receiveGroupStatus(state, action) {
	let {groups, users} = action.data;

	// calculate counters
	const uniqueUsersInGroups = groups.reduce((usersObj, group) => {
		if (state.getIn(['myGroupAvailability', group.groupId])) {
			return group.users.reduce((obj, name) => {
				obj[name] = 0;
				return obj;
			}, usersObj);
		} else {
			return usersObj;
		}
	}, {});
	const counters = {
		numberOfOnlineUsers: Object.keys(uniqueUsersInGroups).length,
		numberOfVisitorsInQueue: groups.reduce((sum, group) => sum + group.numberOfVisitorsInQueue, 0),
		numberOfVisitorsInDialog: groups.reduce((sum, group) => sum + group.numberOfVisitorsInDialog, 0),
		numberOfProactiveVisitors: groups.reduce((sum, group) => sum + group.numberOfProactiveVisitors, 0)
	};

	groups = groups.reduce((obj, group) => {
		let {groupId, avgWaitTimeMs, estWaitTimeMs, maxWaitTimeMs, ...rest} = group;
		obj[groupId] = rest;
		return obj;
	}, {});

	state = state.get('counters').some((value, key) => counters[key] !== value)
		? state.set('counters', immutable.fromJS(counters))
		: state;

	state = state.set('groups', immutable.fromJS(groups));

	return state;
}

export function setPresence(state, action) {
	const presenceStr = (action.presence + '') !== 'away' ? 'online': 'away';
	state = state.set('presence', presenceStr);
	return state;
}

export function resetForceAway(state, action) {
	state = state.set('presenceForcedAway', false);
	return state;
}

export function initGroupRouting(state, action) {
	state = state.set('groupRouting', immutable.fromJS(action.groupRouting));
	return state;
}
