import React, {useState, useRef, useEffect, useMemo} from 'react';
import Format from '../../../utils/Format';
import AccountConfig from './../../../utils/AccountConfig';
import {Dropdown} from 'react-bootstrap';
import CannedResponse from './CannedResponse.react';
import {useGetTranslation} from '../../LangContext';


export const ActionItems = (props) => {
	const {actionPanel, searchStr, sendAction, conversationId, topIndex} = props;
	const types = {
		'0': 'url',
		'1': 'video',
		'2': 'pdf',
		'3': 'image',
	};

	const actionElements = Format.asSearchFilteredList(actionPanel.actions, searchStr, 'name').map((action, index) => {
		const type = types[action.itemType] || types[0];
		return (
			<Dropdown.Item
				key={action.name + (topIndex * 1000 + index)}
			>
				<div className="item line-clamp line-clamp-1"
					 onClick={() => sendAction(conversationId, action)}>
					<i className={'vngage-icon-' + action.icon} ub-track="canned-action"/>
					<span title={type + ': ' + action.parameter}
						  ub-track="canned-action">{action.name}</span>
				</div>
			</Dropdown.Item>
		);
	});
	return (
		<React.Fragment>
			{actionElements}
		</React.Fragment>
	);
};

export const ActionPanelItems = (props) => {
	const {currentCaseType, searchStr, sendAction, conversationId} = props;
	const actionContent = useMemo(() => {
		return AccountConfig.getActions(currentCaseType).map((actionPanel, topIndex) => {
			// header
			const header = (<Dropdown.Header className="item-header" key={topIndex}>
				<div className="action">
					<strong>{actionPanel.panelName}</strong>
				</div>
			</Dropdown.Header>);
			// actions
			return (
				<React.Fragment key={actionPanel.panelName}>
					{header}
					<ActionItems {...{actionPanel, searchStr, sendAction, conversationId, topIndex}}/>
				</React.Fragment>
			);
		});
	}, [currentCaseType, searchStr, sendAction, conversationId]);

	return (
		<React.Fragment>
			{actionContent}
		</React.Fragment>
	);
};

export const CannedResponseItems = (props) => {
	const {currentCaseType, searchStr, typingChatText} = props;
	const cannedResponses = useMemo(() => {
		const cannedResponsesWithSearchStr = AccountConfig.getCannedResponses(currentCaseType)
			.map(cannedResponse => ({
				...cannedResponse,
				searchStr: `${cannedResponse.message} ${cannedResponse.name}` // use message and name for search
			}));
		return Format.asSearchFilteredList(cannedResponsesWithSearchStr, searchStr, 'searchStr', 'name')
			.map(canned => {
				return (
					<Dropdown.Item
						key={canned.id}
						onSelect={() => typingChatText(canned.message)}
					>
						<CannedResponse
							role="Dropdown.Item"
							canned={canned}
						/>
					</Dropdown.Item>
				);
			});

	}, [currentCaseType, searchStr, typingChatText]);

	return (
		<React.Fragment>
			{cannedResponses}
		</React.Fragment>
	);
};


const InputMenu = (props) => {
	const getTranslation = useGetTranslation();
	const searchField = useRef();
	const timeOut = useRef();

	const [searchStr, setSearchStr] = useState('');
	const [activeCategory, setActiveCategory] = useState('cannedResponses');
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const {conversationId, typingChatText, currentCaseType, sendAction} = props;

	const categories = [
		{
			category: 'cannedResponses',
			titleLangKey: 'imCannedResponsesTitle',
			icon: 'line-text'
		},
		{
			category: 'actions',
			titleLangKey: 'imActionsTitle',
			icon: 'line-lightning'
		}
	];

	useEffect(() => {
		if (!dropdownOpen && searchField.current) {
			// closing menu clear search
			searchField.current.value = '';
			setSearchStr('');
			// set focus in chatTextArea
			const chatInput = document.getElementById('input' + conversationId);
			if (chatInput) {
				chatInput.focus();
			}
		} else {
			// about to open menu, wait and set focus

			timeOut.current = setTimeout(() => {
				if (searchField.current) {
					searchField.current.focus();
				}
			}, 30);
		}
		return () => {
			if (timeOut.current) {
				clearInterval(timeOut.current);
				timeOut.current = null;
			}
		};

	}, [dropdownOpen, conversationId]);

	const categoryObj = categories.find(category => category.category === activeCategory);
	const titleKey = categoryObj.titleLangKey;
	const categoryButtons = categories.map(category => {
		const cls = `nav-item ${category.category === activeCategory ? 'active': ''}`;
		const icon = `vngage-icon-${category.icon}`;
		return <button type="button" key={category.category} className={cls}
					   onClick={() => setActiveCategory(category.category)}><i className={icon}/></button>;

	});
	const listData = categoryObj.category === 'cannedResponses'
		? <CannedResponseItems {...{currentCaseType, searchStr, typingChatText}}/>
		: <ActionPanelItems {...{currentCaseType, searchStr, sendAction, conversationId}}/>;

	const menuHeader = (
		<Dropdown.Header className="fixed">
			<div className="tab-header">
				<div className="tab-title">{getTranslation(titleKey)}</div>
				<div className="tab-nav">
					{categoryButtons}
				</div>
			</div>
			<div className="filter-wrapper">
				<input
					type="text"
					className="filter"
					ref={searchField}
					onChange={(event) => setSearchStr(event.target.value)}
				/>
			</div>
		</Dropdown.Header>
	);

	return (
		<Dropdown
			drop="up"
			disabled={!props.inputEnabled}
			id="input-dropdown"
			className="input-menu"
			tabIndex="-1"
			onToggle={setDropdownOpen}>
			<Dropdown.Toggle variant="outline-success" size="sm" id={`dropdown-input-${conversationId}`}
							 className="menu-trigger">
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{menuHeader}
				{listData}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default InputMenu;
