import UserAddModalTemplate from './UserAddModalTemplate';
import vngageImportModalTemplate from '../../../common/vngageImportModal/vngageImportModalTemplate';
import {fileImportHeaders} from './FileImportHeaders';

export default function (SessionService, $uibModal, $state, userList, userListTableConfig, roleClaimList, timeZones, accountConfiguration, languagesConfig, groupList) {
	'ngInject';

	var vm = this;
	vm.userList = userList;

	const tableConfig = userListTableConfig(SessionService);

	vm.options = tableConfig.options;
	vm.columns = tableConfig.columns;

	vm.options.createItem.method = addNew;
	vm.options.importCSV.method = importNew;

	// map data to headers
	vm.headers = fileImportHeaders.map(header => {
		return header.name === 'profile.timeZoneId'
			? {
				...header,
				values: timeZones.map(zone => zone.id),
				default: accountConfiguration.data.timeZoneId
			}
			: header;
	}).map(header => {
		return header.name === 'profile.language'
			? {
				...header,
				values: languagesConfig.supportedLanguages,
				default: accountConfiguration.data.language
			}
			: header;

	}).map(header => {
		return header.name === 'roleClaims'
			? {...header, values: roleClaimList.map(claim => claim.data.claimType.split('#').pop())}
			: header;

	}).map(header => {
		return header.name === 'groupIds'
			? {
				...header,
				values: groupList.map(group => group.key),
			}
			: header;
	});

	function importNew() {
		$state.go('root.account.user').then(openImportModal);
	}

	function addNew() {
		// If the state transition to the parent state is ok, then open modal
		$state.go('root.account.user').then(openAddModal);
	}

	function openAddModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: UserAddModalTemplate,
			controller: 'UserAddModalController as vm',
			resolve: {
				accountConfiguration: (APIEndpointService) => {
					'ngInject';
					return APIEndpointService.accountCurrentConfiguration.get().$promise;
				}
			}
		});
		modal.result.then(newUser => {
			vm.userList.push(newUser);
			$state.go('.edit', {id: newUser.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function openImportModal() {
		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: vngageImportModalTemplate,
			controller: 'ImportModalController as vm',
			size: 'lg',
			resolve: {
				options: () => {
					return {header: 'user.IMPORT_HEADER'};
				},
				itemsList: () => {
					return vm.userList;
				},
				itemType: () => 'user',
				isSection: () => false,
				headers: () => vm.headers,
				extraData: () => {
					return {'profile.preferredEmail': {uniqueValues: vm.userList.map(user => user.data.profile.preferredEmail)}};
				}
			}
		});
		modal.result.then(newUsers => {
		}).catch(() => {
			// Modal dismissed...
		});

	}

}
