export default function (access) {
	return {
		id: 'queues',
		access: (access || 'Statistics'),
		viewConfig: {
			metricName: 'ConversionFunnelSitePerGroup',

			/** ----------------------------------------------* Measures */
			measures: [
				{
					key: 'siteName',
					type: 'string'
				}, {
					key: 'groupName',
					type: 'string'
				}, {
					key: 'dimensionName',
					type: 'string',
					calculation: {
						type: 'formatMeasureWithParenthesis',
						parameters: [
							'groupName',
							'siteName'
						]
					}
				}, {
					key: 'numberOfQueueAdded',
					type: 'number'
				}, {
					key: 'numberOfQueueSelectedWhoWaitedBetween_0-30000Ms',
					type: 'number'
				}, {
					key: 'numberOfQueueSelectedWhoWaitedBetween_30000-60000Ms',
					type: 'number'
				}, {
					key: 'sla_1min',
					type: 'number',
					calculation: {
						type: 'addition',
						parameters: [
							'numberOfQueueSelectedWhoWaitedBetween_0-30000Ms',
							'numberOfQueueSelectedWhoWaitedBetween_30000-60000Ms'
						]
					}
				}, {
					key: 'numberOfQueueSelectedWhoWaitedBetween_60000-120000Ms',
					type: 'number'
				}, {
					key: 'sla_2min',
					type: 'number',
					calculation: {
						type: 'addition',
						parameters: [
							'numberOfQueueSelectedWhoWaitedBetween_0-30000Ms',
							'numberOfQueueSelectedWhoWaitedBetween_30000-60000Ms',
							'numberOfQueueSelectedWhoWaitedBetween_60000-120000Ms'
						]
					}
				}, {
					key: 'numberOfQueueAddedDropped',
					type: 'number'
				}, {
					key: 'numberOfQueueAddedSelected',
					type: 'number'
				}, {
					key: 'totalQueueCompletedWaitTimeMs',
					type: 'duration'
				}, {
					key: 'numberOfQueueExits',
					type: 'number',
					calculation: {
						type: 'addition',
						parameters: [
							'numberOfQueueAddedDropped',
							'numberOfQueueAddedSelected'
						]
					}
				}, {
					key: 'avgQueueWaitTimeMs',
					type: 'duration'
				}, {
					key: 'maxQueueWaitTimeMs',
					type: 'duration'
				}, {
					key: 'numberOfQueueTransfers',
					type: 'number'
				}, {
					key: 'numberOfQueueTransferDropped',
					type: 'number'
				}, {
					key: 'numberOfQueueTransferSelected',
					type: 'number'
				}
			],


			/** ----------------------------------------------* Bar filters */
			barFilters: [
				{
					dimension: 'siteName',
					valueAccessor: 'numberOfQueueAdded'
				}, {
					dimension: 'groupName',
					valueAccessor: 'numberOfQueueAdded'
				}
			],


			/** ----------------------------------------------* Time line graph */
			timeLineGraph: {
				valueAccessor: 'numberOfQueueAdded'
			},


			/** ----------------------------------------------* Totals */
			totals: [
				{
					measure: 'numberOfQueueAdded'
				}, {
					measure: 'numberOfQueueExits',
					hidden: true
				}, {
					measure: 'totalQueueCompletedWaitTimeMs',
					hidden: true
				}, {
					measure: 'numberOfQueueAddedSelected',
					displayPercentOf: 'numberOfQueueExits'
				}, {
					measure: 'numberOfQueueSelectedWhoWaitedBetween_0-30000Ms',
					displayPercentOf: 'numberOfQueueAddedSelected'
				}, {
					measure: 'sla_1min',
					displayPercentOf: 'numberOfQueueAddedSelected'
				}, {
					measure: 'sla_2min',
					displayPercentOf: 'numberOfQueueAddedSelected'
				}, {
					measure: 'avgQueueWaitTimeMs',
					calculation: {
						type: 'division',
						parameters: [
							'totalQueueCompletedWaitTimeMs',
							'numberOfQueueAddedSelected'
						]
					}
				}, {
					measure: 'numberOfQueueTransfers',
					displayPercentOf: 'numberOfQueueAddedSelected'
/*
				}, {
					measure: 'numberOfQueueTransferSelected',
					displayPercentOf: 'numberOfQueueTransfers'
*/
				}
			],


			/** ----------------------------------------------* Table */
			table: {
				groupBy: 'dimensionName',
				measures: [
					{
						measure: 'numberOfQueueAdded'
					}, {
						measure: 'numberOfQueueAddedSelected',
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'numberOfQueueAddedSelected',
								'numberOfQueueAdded'
								//'numberOfQueueExits'
							]
						}
					}, {
						measure: 'numberOfQueueSelectedWhoWaitedBetween_0-30000Ms',
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'numberOfQueueSelectedWhoWaitedBetween_0-30000Ms',
								'numberOfQueueAddedSelected'
							]
						}
					}, {
						measure: 'sla_1min',
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'sla_1min',
								'numberOfQueueAddedSelected'
							]
						}
					}, {
						measure: 'sla_2min',
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'sla_2min',
								'numberOfQueueAddedSelected'
							]
						}
/*
					}, {
						measure: 'numberOfQueueAddedDropped'
*/
					}, {
						measure: 'avgQueueWaitTimeMs'
					}, {
						measure: 'maxQueueWaitTimeMs'
					}, {
						measure: 'numberOfQueueTransfers'
/*
					}, {
						measure: 'numberOfQueueTransferSelected',
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'numberOfQueueTransferSelected',
								'numberOfQueueTransfers'
							]
						}
*/
					}
				]
			}
		}
	}
};

