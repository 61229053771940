import DesktopDeuxTemplate from './DesktopDeuxTemplate';
import DesktopDeuxController from './DesktopDeuxController';
import DesktopTranslations from './DesktopTranslations';
import vngageDebugInfoJsonDataDirective from './debugInfoModal/vngageDebugInfoJsonDataDirective';

export default angular.module('vngageApp.desktopDeux', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])
	.controller('DesktopDeuxController', DesktopDeuxController)
	.constant('DesktopTranslations', DesktopTranslations)
	.directive('vngageDebugInfoJsonData', vngageDebugInfoJsonDataDirective)

	.config(($stateProvider, $translateProvider, DesktopTranslations, $urlRouterProvider, VngageWorkspaceTranslationServiceProvider) => {
		'ngInject';

		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(DesktopTranslations);

		$stateProvider.state('root.desktopDeux', {
			url: '/desktopDeux/',
			views: {
				content: {
					templateUrl: DesktopDeuxTemplate,
					controller: 'DesktopDeuxController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				loadConfiguration: function (DesktopService) {
					'ngInject';
					return DesktopService.getConfiguration();
				},
				desktopPlugins: function (WorkspaceSettingsService) {
					'ngInject';
					return WorkspaceSettingsService.getSections('desktopPlugins').catch((error) => {
						// Catch reject to avoid red error notification
						if (error && error.status !== 404) {
							// Only log errors != 404 (which is normal when workspaceSetting is missing)
							console.error('Could not get workspaceSettings:', error);
						}
					});
				}
			}
		});

	});
