import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';
import _difference from 'lodash/difference';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _pull from 'lodash/pull';

export default function ($scope, $q, APIEndpointService, action, actionPanelList, templateList, AccountActionService, $state, VngageUtil, vngageRemoveModalService, APIConfigurationSectionService, $stateParams) {
	'ngInject';

	const vm = this;
	vm.responseData = action.data;
	vm.selectablePanels = AccountActionService.formatSelectableActionPanels(actionPanelList);
	vm.selectedPanels = AccountActionService.getSelectedPanelsForAction(actionPanelList, action.data.id);
	vm.selectedTemplate = getTemplateResource(action.data.section.parameter);
	vm.templateList = templateList;
	vm.submit = submit;
	vm.remove = remove;
	vm.cancel = cancel;
	vm.currentList = $stateParams.currentList || [];
	vm.action = action;

	const disabledActionTypes = ['1', '5']; // Disable/hide "Javascript" and "Form" action types
	vm.actionItemTypes = _map(AccountActionService.getActionTypes(), (name, index) => {
		const value = '' + index;
		return {
			value,
			name,
			display: ((disabledActionTypes.indexOf(value) === -1) || (vm.responseData.section.itemType === value))
		};
	});

	$scope.$on('resetResettables', () => {
		vm.actionEditForm.$setPristine();
		action.$get().catch(() => {
		});
	});

	//keep for comparison when saving affected actionPanels
	let currentlySavedPanels = angular.copy(vm.selectedPanels);

	function cancel() {
		$state.go('^');
	}

	function saveDone() {
		// Update local state to saved data
		vm.responseData = action.data;
		vm.selectedPanels = AccountActionService.getSelectedPanelsForAction(actionPanelList, action.data.id);
		currentlySavedPanels = angular.copy(vm.selectedPanels);
		vm.selectedTemplate = getTemplateResource(action.data.section.parameter);
		vm.isSaving = false;
	}

	function getPanelResource(panelId) {
		return _find(actionPanelList, function (panel) {
			return panel.data.id === panelId;
		});
	}

	function saveChangedActionPanels() {
		const promises      = [],
			  panelsRemoved = _map(_difference(currentlySavedPanels, vm.selectedPanels), getPanelResource),
			  panelsAdded   = _map(_difference(vm.selectedPanels, currentlySavedPanels), getPanelResource);

		panelsAdded.forEach(function (panel) {
			panel.data.section.actions.push(action.data.id);
			promises.push(panel.$save());
		});

		panelsRemoved.forEach(function (panel) {
			_pull(panel.data.section.actions, action.data.id);
			promises.push(panel.$save());
		});

		return $q.all(promises);
	}

	function getTemplateResource(templateId) {
		if (!templateId) {
			return '';
		}

		return _find(templateList, function (template) {
			return template.data.id === templateId;
		});
	}

	function submit() {
		if (vm.responseData.section.itemType == 6 && vm.selectedTemplate && vm.selectedTemplate.data) {
			action.data.section.parameter = vm.selectedTemplate.data.id;
		}
		vm.isSaving = true;
		action.$save().then(saveChangedActionPanels).finally(saveDone);
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: action,
			itemType: APIConfigurationSectionService.sectionMap.action
		}).then(function () {
			vm.actionEditForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(action, 'root.configure.action.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(saveDone);
	}
}
