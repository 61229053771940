/**
 * Formats duration in milliseconds as "0m 0s"
 * @param {number} ms - duration in milliseconds
 * @returns {string} duration
 */
function formatDuration(ms) {
	if (!ms || ms <= 0) {
		return '0m 0s';
	}

	var s = Math.round(ms / 1000);
	var minutes = Math.floor((s / 60));
	var seconds = (s % 60).toFixed(0);

	return minutes + 'm ' + seconds + 's';
}

export default formatDuration;
