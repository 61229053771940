import moment from 'moment-timezone';

export default function ($uibModalInstance, configurationSet, selectedRevision, analyzedSet, NotificationService, SessionService, APIEndpointService, ConfigurationSetService, StateReloadService) {
	'ngInject';

	var vm = this;
	vm.submit = submit;
	vm.saveChanges = saveChanges;
	vm.cancelSave = $uibModalInstance.dismiss;
	vm.configuration = configurationSet;
	vm.selectedRevision = selectedRevision;
	vm.comments = selectedRevision.data.comments;
	vm.configurationUpdatedAt = configurationSet.updatedAt;
	vm.formatWithTZ = formatWithTZ;
	vm.isNewConfigurationSet = configurationSet.revision === selectedRevision.revision;

	function submit() {
		if (vm.isNewConfigurationSet) {
			publish(configurationSet);
		} else {
			saveChanges();
		}
	}

	function formatWithTZ(date) {
		return moment.utc(date).tz(SessionService.getUserTimezone()).format('dddd MMM D HH:mm - YYYY');
	}

	function saveChanges() {
		vm.isSaving = true;
		$uibModalInstance.freeze(true);

		ConfigurationSetService.saveSet(configurationSet, analyzedSet, vm.comments)
			.then(function (res) {
				publish(res);
			})
			.catch(function () {
				vm.isSaving = false;
				$uibModalInstance.freeze(false);
			});
	}

	function publish(configuration) {
		APIEndpointService.configurationSetActivate(configuration.data.id, configuration.revision)
			.then(function (res) {
				NotificationService.success({
					body: 'Version "' + configuration.revision + '" is now live',
					header: 'Success'
				});
				// StateReloadService.reload().then($uibModalInstance.close);
				$uibModalInstance.close(configuration); // Never immediately reload the state on save (=problem on FSA). Instead resolve with the updated/saved configurationSet and let the calling controller update the state without reloading
			}).catch(function (res) {
				vm.isSaving = false;
				$uibModalInstance.freeze(false);
			});
	}
};
