export default function ($scope, $state, $translate, SessionService, transitionService, VngageUtil, APIEndpointService, $q, usSpinnerService, ConfigurationSetService, APIConfigurationSectionService, context, vngageYesNoModalService, NotificationService, ConfigValidationService) {
	'ngInject';

	const vm = this;
	vm.containsErrors = false;
	vm.validationErrors = [];

	$scope.$watch('vm.validationErrors', () => {
		vm.containsErrors = (vm.validationErrors.length > 0);
	});

	vm.repair = configurationError => {
		const yesNoModalPromise = vngageYesNoModalService.open({
			title: 'Repair',
			heading: 'Are you sure you want to repair this configuration error?',
			bodyHtml: '&nbsp;<br>' + vm.getRepairInfo(configurationError)
		}, () => {
			const actionsPromises = [];
			configurationError.repairActions.forEach(repairAction => {
				console.log('Repairing:', configurationError, repairAction);
				if (repairAction.action === 'delete') {
					// Delete...
					if (repairAction.resource && repairAction.resource.$remove) {
						actionsPromises.push(repairAction.resource.$remove());
					} else {
						console.error('Error removing resource:', repairAction);
						actionsPromises.push($q.reject({
							error: 'Error removing resource',
							repairAction: repairAction
						}));
					}
				}
			});
			return $q((resolve, reject) => {
				$q.all(actionsPromises).then(res => {
					resolve(res);
				}, error => {
					console.error('Error repairing configuration:', error, configurationError);
					reject(error);
				});
			});
		}).result;

		yesNoModalPromise.then(() => {
			// Repair ok
			$state.reload();
		}).catch((error) => {
			// Abort/modal dismissed or repair error
			if (error) {
				NotificationService.error({
					header: error
				});
			}
		});
	};

	const _translationIfExists = key => {
		const translation = $translate.instant(key);
		return translation !== key && translation;
	};

	vm.errorTypeTranslated = error => {
		return _translationIfExists('configurationSetRepair.errorTypes.' + error.errorType) ||
			error.errorType;
	};
	vm.entityTypeTranslated = error => {
		return _translationIfExists('configurationSetRepair.entityTypes.' + error.dataType) ||
			_translationIfExists('set.' + error.dataType) ||
			error.dataType;
	};
	vm.getRepairInfo = configurationError => {
		let info = '<h4>Repair will perform the following actions</h4>';

		if (configurationError.keepId) {
			info += 'Keep the first occurring entity of type "' + vm.entityTypeTranslated(configurationError) + '" found in the latest Configuration Set: '+configurationError.keepId+'<br>' +
				'This is the object that visitor uses, and other objects should be removed<br><br>';
		}
		configurationError.repairActions.forEach(repairAction => {
			if (repairAction.action === 'delete') {
				info += 'Delete "'+ vm.entityTypeTranslated(configurationError) + '": ' + repairAction.resource.data.id + '<br>';
			}
		});
		return info;
	};

	vm.validationErrors = ConfigValidationService.validateAll(context);
}
