import vngageBackNavigationTemplate from './vngageBackNavigationTemplate';

export default function($state) {
	'ngInject';

	return {
		restrict: 'AE',
		replace: true,
		scope: {
			route: '=',
			forceReload: '='
		},
		templateUrl: vngageBackNavigationTemplate,
		link: function(scope, element, attrs) {
			scope.goBack = function() {
				if ('forceReload' in attrs) {
					$state.go(attrs.route, {}, { reload: true, inherit: false, notify: true });
				} else {
					$state.go(attrs.route);
				}
			}
		}
	};
};
