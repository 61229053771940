import React, {useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import mmd from '../../../../../common/vngageMarkdown/mmd';

const CannedResponse = (props) => {
	const [markDown, setMarkDown] = useState(props.canned.message);

	const {canned} = props;
	const tooltipStyle = {
		width: '300px',
		pointerEvents: 'none'
	};

	const tooltip = (
		<Tooltip id="tooltip" style={tooltipStyle}>
			<p className="line-clamp line-clamp-5"
			   dangerouslySetInnerHTML={{__html: markDown}}/>
		</Tooltip>
	);

	return (
		<OverlayTrigger
			overlay={tooltip} placement="top"
			onEnter={() => setMarkDown(mmd(canned.message))}
			delay={{show: 1000, hide: 50}}
		>

			<div className="response">
				<strong className="line-clamp line-clamp-1" ub-track="canned-response">{canned.name}</strong>
				<p className="line-clamp line-clamp-2" ub-track="canned-response">
					{canned.message}
				</p>
			</div>
		</OverlayTrigger>
	);

};
export default CannedResponse;


