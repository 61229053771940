import React, {useState, useEffect} from 'react';
import immutable from 'immutable';
import {connect} from 'react-redux';
import ConversationActions from '../../../actions/ConversationActions';
import Message from '../../../actionCreators/Message';
import ServerActions from '../../../actionCreators/ServerActions';
import AccountConfig from '../../../utils/AccountConfig';
import Format from '../../../utils/Format';
import CaseForm from './CaseForm.react';
import FormHelpers from '../../../utils/FormHelpers';
import {
	Form,
	Row,
	Button,
	Dropdown,
	Modal,
	OverlayTrigger,
	Tooltip
} from 'react-bootstrap';
import ToolBarSectionType from '../../../constants/ToolBarSectionType';
import {useGetTranslation} from '../../LangContext';

export const CaseManagerSection = (props) => {
	const getTranslation = useGetTranslation();
	const [forms, setForms] = useState([]);
	const [caseTypes, setCaseTypes] = useState([]);
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const {currentCaseType, groupId, caseId} = props;
	const incomingForms = props.forms;

	const {alwaysEmail} = AccountConfig.getEmailTranscriptConfig();

	const isCaseValid = () => {
		return props.selectedClosure !== '' && forms.every(form => form.isValid);
	};
	const onFormChange = (text, formatObj, formId) => {
		const formIndex = forms.findIndex(form => form.id === formId);
		const form = forms.find(form => form.id === formId);
		const fieldIndex = form.formFields.findIndex(f => f.name === formatObj.name);
		const field = form.formFields.find(f => f.name === formatObj.name);

		const updatedFields = [
			...form.formFields.slice(0, fieldIndex),
			{
				...field,
				isValid: FormHelpers.validateText(text, formatObj),
				value: text,
			},
			...form.formFields.slice(fieldIndex + 1)
		];
		const formIsValid = updatedFields.every(f => f.isValid);

		setForms([
			...forms.slice(0, formIndex),
			{
				...form,
				formFields: updatedFields,
				isValid: formIsValid
			},
			...forms.slice(formIndex + 1),
		]);
	};

	const hideModal = () => {
		props.setForms(props.conversationId, forms);
		props.removeActiveSection(props.conversationId, ToolBarSectionType.CASE_MANAGER);
	};
	const onSelectCaseType = (eventKey) => {
		const caseObj = caseTypes.find(c => c.id === eventKey) || {problem: 'missingCase', eventKey, caseTypes};
		props.updateCaseType(props.conversationId, caseObj);
	};
	const finish = () => {
		props.closeCase(props.conversationId, AccountConfig.getOneClosure(props.selectedClosure), false);
		props.removeActiveSection(props.conversationId, ToolBarSectionType.CASE_MANAGER);
	};

	useEffect(() => {
		setCaseTypes(Format.asSearchFilteredList(AccountConfig.getCaseTypesByGroupId(groupId), '', 'name', 'name'));
	}, [groupId]);

	useEffect(() => {
		const prevForms = incomingForms.size > 0 ? incomingForms.toJS(): AccountConfig.getForms(currentCaseType);
		const preparedForms = FormHelpers.validateFormFields(FormHelpers.replaceWithCaseId(prevForms, caseId));
		setForms(preparedForms);
	}, [incomingForms, currentCaseType, caseId]);

	const validCase = isCaseValid();

	const caseTypesElements = caseTypes.map((caseObj, index) => {
		return (
			<Dropdown.Item key={index} title={caseObj.name} eventKey={caseObj.id}
						   className="lineClampEllipsis1Line">{caseObj.name}</Dropdown.Item>
		);
	});

	const closures = (
		<Form.Group>
			{Format.asSearchFilteredList(AccountConfig.getClosures(currentCaseType), '', 'name', 'name')
				.map((closureObj, index) => {
					return <Form.Check name={closureObj.name}
									   type="radio"
									   key={index}
									   label={closureObj.name === 'Dismiss' ? getTranslation('defaultClosureName'): closureObj.name}
									   id={closureObj.reason.id}
									   onChange={(event) => props.setSelectedClosure(props.conversationId, closureObj.reason.id)}
									   checked={props.selectedClosure === closureObj.reason.id}/>;
				})}
		</Form.Group>);

	const currentCase = AccountConfig.getOneCaseType(currentCaseType);


	const continueWithNewCase = (
		<Form.Group className="case-options" key="continueWithNewCaseToggleCheckbox">
			<Form.Check
				name="continueWithNewCase"
				id="continueWithNewCase"
				label={getTranslation('cmSaveNew')}
				checked={props.newCaseAfterClose}
				onChange={() => props.toggleNewCaseAfterClose(props.conversationId)}
			/>
		</Form.Group>
	);

	const emailTranscriptToggle = alwaysEmail ? null:
		<Form.Group className="case-options" key="emailTranscriptToggleCheckbox">
			<Form.Check
				name="emailTranscript"
				id="emailTranscript"
				label={getTranslation('cmSendAsEmail')}
				checked={props.emailConversationTranscript}
				onChange={() => props.toggleEmailConversationTranscript(props.conversationId)}
			/>
		</Form.Group>;

	const formList = forms.map((form) => {
		return (
			<CaseForm onFormChange={onFormChange}
					  conversationId={props.conversationId}
					  key={form.id}
					  sendNote={props.sendNote}
					  form={form}
					  hideModal={hideModal}/>
		);
	});

	const currentCaseId = caseId
		? <div className="subtitle">Case ID: {caseId}</div>
		: null;


	const toolTipStyle = currentCase.name.length < 20 || dropdownOpen
		? {display: 'none'}
		: {};
	const toolCaseCurrent = <Tooltip style={toolTipStyle} id="tooltip">{currentCase.name}</Tooltip>;

	return (
		<Modal className="case-manager-section-modal" show={true} onHide={() => hideModal()}>
			<Modal.Header closeButton>
				<Modal.Title>{getTranslation('cmTitle')} {currentCaseId}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="case-type-area">
					<div className="area-head">
						<h5 className="no-wrap">1. {getTranslation('cmCaseTypeTitle')}</h5>
						<div className="inline-divider"/>
					</div>
					<OverlayTrigger placement="top" overlay={toolCaseCurrent}>
						<Dropdown
							tabIndex="-1"
							className="case-menu-modal"
							drop="down"
							onSelect={onSelectCaseType}
							onToggle={setDropdownOpen}
							size="sm"
							id={`split-button-basic-${0}`}>
							<Dropdown.Toggle id="dropdown-case" size="sm" variant="outline-secondary"
											 className="case-dropdown-button">
								{currentCase.name.length > 35 ? currentCase.name.slice(0, 32) + '...': currentCase.name}
							</Dropdown.Toggle>
							<Dropdown.Menu className="case-menu-container">
								{caseTypesElements}
							</Dropdown.Menu>
						</Dropdown>
					</OverlayTrigger>
					{formList}
				</div>
				<div className="closure-area">
					<div className="area-head">
						<h5 className="no-wrap">2. {getTranslation('cmClosureTitle')}</h5>
						<div className="inline-divider"/>
					</div>
					<div className="form-wrapper">
						<div className="col-md-7">
							{closures}
						</div>
						<div className="col-md-5">
						</div>
					</div>
				</div>
				<div className="case-close-area">
					<div className="area-head">
						<h5 className="no-wrap">3. {getTranslation('cmCloseCaseTitle')}</h5>
						<div className="inline-divider"/>
					</div>
					<Row className="area-content">
						<div>
							{continueWithNewCase}
							{emailTranscriptToggle}
						</div>
						<div className="finish-area">
							<Button
								variant="default" className="btn-finish" onClick={finish}
								disabled={!validCase}>{getTranslation('cmBtnFinish')}</Button>
							<label className="save-label">{getTranslation('cmSaveClose')}</label>
						</div>
					</Row>
				</div>
			</Modal.Body>
		</Modal>
	);
};

function mapStateToProps(state, ownProps) {
	// INCOMING
	// conversationId

	const conversationId = ownProps.conversationId;
	const conversation = state.getIn(['conversations', conversationId]) || immutable.Map();
	const groupId = conversation.get('groupId');
	const selectedClosure = conversation.getIn(['UI', 'selectedClosure']);
	const currentCaseType = conversation.get('currentCaseType');
	const caseId = conversation.get('caseId');
	const emailConversationTranscript = conversation.getIn(['UI', 'emailConversationTranscript']);
	const forms = conversation.get('forms');
	const newCaseAfterClose = conversation.getIn(['UI', 'newCaseAfterClose']);

	return {
		conversationId,
		groupId,
		selectedClosure,
		currentCaseType,
		caseId,
		emailConversationTranscript,
		forms,
		newCaseAfterClose
	};
}

function mapDispatchToProps(dispatch) {
	return {
		setSelectedClosure: (conversationId, selectedClosure) => dispatch(ConversationActions.setSelectedClosure(conversationId, selectedClosure)),
		removeActiveSection: (conversationId, section) => dispatch(ConversationActions.removeActiveSection(conversationId, section)),
		sendNote: (conversationId, message) => dispatch(Message.sendNote(conversationId, message)),
		updateCaseType: (conversationId, caseObj) => dispatch(ServerActions.updateCaseType(conversationId, caseObj)),
		closeCase: (conversationId, closureObj) => dispatch(ServerActions.closeCase(conversationId, closureObj)),
		setForms: (conversationId, forms) => dispatch(ConversationActions.setForms(conversationId, forms)),
		toggleNewCaseAfterClose: (conversationId) => dispatch(ConversationActions.toggleNewCaseAfterClose(conversationId)),
		toggleEmailConversationTranscript: (conversationId) => dispatch(ConversationActions.toggleEmailConversationTranscript(conversationId)),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CaseManagerSection);


