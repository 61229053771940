import {
	setMaxConversationsPerGroup,
	initGroupAvailability,
	setGroupAvailability,
	receiveGroupStatus,
	setPresence,
	resetForceAway,
	initGroupRouting,
} from './stateModifiers/group';
import AppConstants from '../../../components/constants/AppConstants';

const ActionTypes = AppConstants.ActionTypes;

export default function GroupReducer(state, action) {

	switch (action.type) {
		case ActionTypes.SET_MAX_CONVERSATIONS_PER_GROUP:
			return setMaxConversationsPerGroup(state, action);

		case ActionTypes.INIT_GROUP_AVAILABILITY:
			return initGroupAvailability(state, action);

		case ActionTypes.SET_GROUP_AVAILABILITY:
			return setGroupAvailability(state, action);

		case ActionTypes.RECEIVE_GROUP_STATUS :
			return receiveGroupStatus(state, action);

		case ActionTypes.SET_PRESENCE:
			return setPresence(state, action);

		case ActionTypes.RESET_FORCE_AWAY:
			return resetForceAway(state, action);

		case ActionTypes.INIT_GROUP_ROUTING:
			return initGroupRouting(state, action);
		default:
			return state;
	}
}
