import uiView from './ui/view';
import vngageConfig from '../../vngageConfig';
import ViewActions from '../actions/ViewActions';
import ConversationsStore from '../stores/ConversationsStore';
import AccountConfiguration from './../../../common/AccountConfiguration';
import React from 'react';
import ReactDOM from 'react-dom';
import Session from './../../../common/auth/Session';
import i18nLoader from './../../../i18n';
import VideoContainer from './../components/VideoContainer.react';
import {IntlProvider} from 'react-intl';

var videoChat = {

	load: function () {
		if (psPlugin.application.configuration.account.enableVideoChat === true) {
			//optionally, we could just kill it off here
			//user haven't got flash installed or it is disabled
			//if (psPlugin.shell.browser.hasFlash) return false;
			psPlugin.application.events.onCoWorkerLoaded.subscribe('videoChat', (function (visitor) {
				try {
					visitor.coWorker.videoChatModule = new psPlugin.application.modules.videoChat.panel(visitor);
				} catch (err) {
					console.log(err);
				}
			}));
		}
	},
	panel: function (visitor) {
		// parent element
		var parent = visitor.coWorker.visitorDetailsArea;
		this.el = document.createElement('div');
		var el = this.el;
		parent.insertBefore(this.el, parent.childNodes[1]);

		// hackish
		setTimeout( () => {
			renderComponent(visitor);
		}, 400);

		var isActive;

		//var maximized = false;
		//var minimizedView = function () {
		//	maximized = false;
		//	if (el) {ReactDOM.unmountComponentAtNode(el);}
		//};

		//var maximizedView = function () {
		//	maximized = true;
			//renderComponent(visitor);
		//};
		//minimizedView();

		var onApproved = function () {
			//if (maximized) {
			setTimeout(() => {renderComponent(visitor);}, 400);
			//}
		};

		var onNotApproved = function () {
			if (el) {
				ReactDOM.unmountComponentAtNode(el);
			}
		};

		if (visitor.properties.approvalStatus === 2) { // 2: InDialog
			onApproved();
		} else {
			onNotApproved();
		}

		/*
		* --------------------------------------------------
		* Event listeners
		* --------------------------------------------------
		*/
		var eventSubscriptions = [];

		//// on coworker panel maximize
		//visitor.events.onCoWorkerMaximize.subscribe('videoChat', (function () {
		//	maximizedView();
		//}));
		//eventSubscriptions.push('onCoWorkerMaximize');
		//
		//// on coworker panel minimize
		//visitor.events.onCoWorkerMinimize.subscribe('videoChat', (function () {
		//	//console.info('videoChat onCoWorkerMinimize for: '+visitor.properties.sessionId);
		//	let streamId = visitor.properties.sessionId;
		//	if (ConversationsStore.isConversationStreaming(streamId)) {
		//		ViewActions.setupVideoChat(streamId, {activity:'stop', streamId: streamId});
		//	}
		//	minimizedView();
		//}));
		//eventSubscriptions.push('onCoWorkerMinimize');

		// onUnlockToQueue
		visitor.onClose.push((function () {
			let streamId = visitor.properties.sessionId;
			if (ConversationsStore.isConversationStreaming(streamId)) {
				ViewActions.setupVideoChat(streamId, {activity:'stop', streamId:streamId});
			}
		}));

		// on approve dialog
		visitor.events.onApprove.subscribe('videoChat', (function () {
			onApproved();
		}));
		eventSubscriptions.push('onApprove');

		// on End dialog event
		visitor.events.onEndDialog.subscribe('videoChat', (function () {
			onNotApproved();
		}));
		eventSubscriptions.push('onEndDialog');
	}
};

function renderComponent (visitor) {

	if (visitor.properties.approvalStatus !== 2) { // 2: InDialog
		return;
	}

	const USE_WEBRTC = AccountConfiguration.configuration.account.useWebRTC;
	const CONVERSATION_ID = visitor.properties.sessionId;
	var el = visitor.coWorker.videoChatModule.el;

	// check for flash
	var userHasFlash = psPlugin.shell.browser.hasFlash;
	var visitorHasFlash = visitor.properties.hasFlash;
	//if we don't know yet, assume visitor has flash installed
	if (typeof visitorHasFlash === 'undefined') {
		visitorHasFlash = visitor.properties.hasFlash = true;
	}

	i18nLoader(Session.user.profile.language, function(i18n) {
		const locale = (Array.isArray(i18n.locales) ? i18n.locales[0] : i18n.locales);
		ReactDOM.render(<IntlProvider locale={locale} messages={i18n.messages}><VideoContainer
			key={'claim_'+CONVERSATION_ID}
			conversationId={CONVERSATION_ID}
			streamingServer={AccountConfiguration.configuration.visitor.videoStreamingServer}
			streamId={CONVERSATION_ID}
			visitorHasFlash={visitorHasFlash}
			useFlash={!USE_WEBRTC}
			roomOwner={true}
			{...i18n}/></IntlProvider>, el);
		visitor.reactComponents = visitor.reactComponents || [];
		visitor.reactComponents.push(el);
	});
}

export default videoChat;
