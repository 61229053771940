export default function (vngageConfig) {
	'ngInject';

	return {
		selectedTheme: null,
		themes: [],
		defaultVariables: 'variables',
		library: ['lesshat/lesshat'],
		basePath: vngageConfig.staticWorkspaceUrl + 'visitor/ui/less/',
		custom: '',
		variables: {},
		dynamicVars: []
	};
}
