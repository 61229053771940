import React, {useState, useMemo} from 'react';
import DebugPollChart from './DebugPollChart.react';
import DebugAnalysis from '../../../utils/DebugAnalysis';
import {
	Button,
} from 'react-bootstrap';

const PollDurations = (props) => {
	const [selectedItem, setSelectedItem] = useState({netId: 0, index: 0});
	const {data, goToPos} = props;

	const chartData = useMemo(() => {
		let itemIndex = data.findIndex(item => item.netId === selectedItem.netId);
		itemIndex === -1
			? 0
			: itemIndex;
		return DebugAnalysis.getChartData(data.slice(itemIndex, itemIndex + 10));
	}, [selectedItem, data]);

	const onClickItem = (item) => {
		console.log(item);
		setSelectedItem(item);
	};

	const itemsWithoutSkip = data.filter(item => item.status !== 'skip');
	const pollTimes = itemsWithoutSkip.map(item => item.duration);
	const {mean, min, max, median, std} = DebugAnalysis.getMetrics(pollTimes);
	const colors = [
		'#000000',
		'#a00000',
		'#c00000',
		'#ff0000',
	];
	const pollList = data
		.filter(item => item.duration > 0 || item.status === 'skip')
		.map((item, index) => {
			const t = item.duration;
			const stdNr = Math.floor(Math.max(t - mean, 0) / std);
			const colorIndex = Math.min(colors.length - 1, stdNr);

			const color = item.status === 'error' || item.status === 'warning'
				? colors[colors.length - 1]
				: item.status === 'skip'
					? '#ff00ff'
					: colors[colorIndex];

			const style = {
				color,
				background: item.netId === selectedItem.netId
					? 'aquamarine'
					: 'none'
			};


			return <a
				href="#"
				key={item.index}
				style={style}
				onClick={() => onClickItem(item)}>
				{item.status === 'ok'
					? t + ', '
					: <b>{t + ', '}</b>
				}
			</a>;
		});


	const pollTimesStyle = {
		display: 'inline',
		maxHeight: '200px',
		overflow: 'scroll'
	};

	return (
		<div className="case-type-area">
			<div style={pollTimesStyle}>
				<DebugPollChart data={chartData}/>
			</div>
			<h3>Poll times</h3>
			<div style={pollTimesStyle}>
				{pollList}
			</div>
			<h3 style={{paddingTop: '10px'}}>Min: {min}</h3>
			<h3>Max: {max}</h3>
			<h3>Mean: {mean}</h3>
			<h3>Median: {median}</h3>
			<h3>Standard deviation: {Math.round(std)}</h3>
			<Button
				onClick={() => goToPos(selectedItem.index)}>
				goto pos
			</Button>
		</div>
	);
};

export default PollDurations;



