import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';
import createDebounce from 'redux-debounced';
import {combineReducers as reducers} from '../utils/CombineReducers';
import Immutable from 'immutable';
import {fromJS} from 'immutable';
import INITIAL_STATE from '../INITIAL_STATE';
import AppConstants from '../../../components/constants/AppConstants';
import NotificationMiddleware from './NotificationMiddleware';
import {createAnalyticsMiddleware} from './AnalyticsMiddleware';
import LocalStorage from './LocalStorageWrapper';

const ActionTypes = AppConstants.ActionTypes;

// See https://github.com/fcomb/redux-logger for configuration options

const pluginActionsWhiteList = [
	ActionTypes.PLUGIN_ACTION,
	ActionTypes.PLUGIN_ERROR,
	ActionTypes.PLUGIN_SUBSCRIPTIONS,
];

// what to log for different logTypes
const loggingPredicates = {
	verbose: actionType => true,
	errors: actionType => false,
	plugins: actionType => pluginActionsWhiteList.indexOf(actionType) >= 0,
	requests: actionType => actionType === ActionTypes.LOG_REQUEST,
	none: actionType => false,
};

const debugLogType = LocalStorage.hasStorage() && LocalStorage.getItem('vngageLogType') || 'none';
const desktopDeuxDebug = LocalStorage.hasStorage() && LocalStorage.getItem('desktopDeuxDebug') || '';
const loggingEnabled = desktopDeuxDebug === 'true' || debugLogType !== 'none';

const logger = createLogger({
	timestamp: true,
	duration: true,
	collapsed: true,
	predicate: (getState, action) => !getState().getIn(['debugFlags', 'pauseDebugLogging'])
		&& !isErrorAction(action.type)
		&& action.type !== AppConstants.Fakes.NEW_STATE_DEBUG
		&& loggingPredicates[getState().getIn(['debugFlags', 'logType'])](action.type),
	stateTransformer: (state) => state.toJS && state.toJS() || state
});

// const logger = createLogger({
// 	timestamp: true,
// 	duration: true,
// 	collapsed: true,
// 	predicate: (getState, action) => {
//
// 		console.log('logtyoe',getState().getIn(['debugFlags', 'logType']));
// 		console.log('asdfasd',!getState().getIn(['debugFlags', 'pauseDebugLogging'])
// 		, !isErrorAction(action.type)
// 		, action.type !== AppConstants.Fakes.NEW_STATE_DEBUG
// 		, loggingPredicates[getState().getIn(['debugFlags', 'logType'])](action.type))
//
// 		return !getState().getIn(['debugFlags', 'pauseDebugLogging'])
// 		&& !isErrorAction(action.type)
// 		&& action.type !== AppConstants.Fakes.NEW_STATE_DEBUG
// 		&& loggingPredicates[getState().getIn(['debugFlags', 'logType'])](action.type);
//
// 	},
// 	stateTransformer: (state) => state.toJS && state.toJS() || state
// });

const isErrorAction = (actionType) => {
	// This function returns true if parameter actionType ends with '_ERROR'
	const suffix = '_ERROR';
	return (actionType.indexOf(suffix, actionType.length - suffix.length) !== -1);
};

const errorLogger = createLogger({
	timestamp: true,
	duration: true,
	collapsed: false,
	level: 'error',
	colors: {
		title: () => '#CC0000',
		prevState: () => '#9E9E9E',
		action: () => '#03A9F4',
		nextState: () => '#4CAF50',
		error: () => '#F20404'
	},
	predicate: (getState, action) => isErrorAction(action.type),
	stateTransformer: (state) => state.toJS && state.toJS() || state
});


const debounce = createDebounce();
const loggers = loggingEnabled ? [logger, errorLogger]: [];

// analytics middleware for redux, not active
// const dataLayer = window.dataLayer || [];
// const analyticsObject = {
// 	type: 'TOGGLE_NOTE_MODE',//'NET_CALL',//'PROCESS_CONVERSATION_DETAILS',
// 	path: [],
// 	metric: 'hit'
// };
// const analyticsMiddleware = createAnalyticsMiddleware(dataLayer, analyticsObject)

const middlewares = [debounce, thunk].concat(loggers).concat([NotificationMiddleware]);

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
export const Store = createStoreWithMiddleware(reducers, INITIAL_STATE);

export const getCustomStore = (customState) => createStore(reducers,
	customState,
	applyMiddleware(thunk, debounce, logger));

// Enable Webpack hot module replacement for reducers
if (module.hot) {
	module.hot.accept('../utils/CombineReducers', () => {
		// console.log('Feeling hot in Store');
		const nextReducer = require('../utils/CombineReducers').combineReducers;
		Store.replaceReducer(nextReducer);
	});
}
