import TranslationAddModalController from './TranslationAddModalController';
import TranslationEditController from './TranslationEditController';
import TranslationListController from './TranslationListController';
import TranslationService from './TranslationService';
import translationTranslations from './TranslationTranslations';
import TranslationListTemplate from './TranslationListTemplate';
import TranslationEditTemplate from './TranslationEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.account.translation', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('TranslationAddModalController', TranslationAddModalController)
	.controller('TranslationEditController', TranslationEditController)
	.controller('TranslationListController', TranslationListController)
	.factory('TranslationService', TranslationService)
	.constant('translationTranslations', translationTranslations)

	.constant('translationModuleConfig', {
		state: 'root.account.translation',
		access: 'Engage/Translations'
	})

	.config(function ($stateProvider, $translateProvider, translationTranslations, translationModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(translationTranslations);

		$stateProvider.state(translationModuleConfig.state, {
			url: 'translation/',
			controller: 'TranslationListController as vm',
			templateUrl: TranslationListTemplate,
			data: {
				access: translationModuleConfig.access,
				ncyBreadcrumbLabel: 'Translations'
			},
			resolve: {
				translationList: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.translation.query().$promise;
				},
				sysTranslations: function (TranslationService) {
					'ngInject';
					return TranslationService.querySystemTranslations();
				}
			}
		});
		$stateProvider.state('root.account.translation.edit', {
			url: ':id',
			controller: 'TranslationEditController as vm',
			templateUrl: TranslationEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				translation: function ($stateParams, translationList, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(translationList, $stateParams.id);
				},
				translations: function (APIEndpointService, $q, $stateParams) {
					'ngInject';
					return APIEndpointService.translation.query().$promise.then(function (res) {
						return res.filter(function (r) {
							return r.data.id !== $stateParams.id;
						});
					});
				}
			}
		});
	});
