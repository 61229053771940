import React from 'react';
import {connect} from 'react-redux';
import immutable from 'immutable';
import ConversationActions from '../../actions/ConversationActions';
import Panel from './Panel.react.js';
import PanelPendingStart from './PanelPendingStart.react';
import PanelPendingRemoval from './PanelPendingRemoval.react';
import PanelLoading from './PanelLoading.react';
import FlowStates from './../../constants/FlowStates';
import {useWhyDidYouUpdate} from './../../ReactHooks';

export const PanelWrapper = (props) => {

	let panel;
	switch (props.flowState) {
		case FlowStates.NEW:
			panel = <PanelLoading {...props}/>;
			break;
		case FlowStates.LOADING:
			panel = <PanelLoading {...props}/>;
			break;
		case FlowStates.ERROR:
			panel = <PanelLoading {...props}/>;
			break;
		case FlowStates.READY:
			panel = <Panel {...props}/>;
			break;
		case FlowStates.WAITING_FOR_USER_START:
			panel = <PanelPendingStart {...props}/>;
			break;
		case FlowStates.REMOVING:
			panel = <PanelPendingRemoval {...props}/>;
			break;
	}
	return panel;
};

function mapStateToProps(state, ownProps) {
	// INCOMING
	// conversationId
	// panelContainerHeight
	// panelContainerWidth
	const conversationId = ownProps.conversationId;
	const conversation = state.getIn(['conversations', conversationId]) || immutable.Map();

	const flowState = conversation.get('flowState') || FlowStates.NEW;
	const isCollapsed = conversation.getIn(['UI', 'panelPosition']) === 'collapsed';
	const isMaximized = conversation.getIn(['UI', 'panelPosition']) === 'coBro';
	const activePanel = state.get('activePanel');
	const panelIsActive = activePanel === conversationId;
	const activeSections = conversation.getIn(['UI', 'activeSections']);
	const refCode = conversation.get('refNumber');

	return {
		conversationId,
		hasError: conversation.get('flowState') === FlowStates.ERROR,
		latestError: conversation.get('latestError') || '',
		flowState,
		isMaximized,
		isCollapsed,
		panelIsActive,
		activeSections,
		panelContainerHeight: ownProps.panelContainerHeight,
		panelContainerWidth: ownProps.panelContainerWidth,
		refCode,
	};
}

function mapDispatchToProps(dispatch) {
	return {removeConversation: (conversationId) => dispatch(ConversationActions.removeConversation(conversationId)), };
}

PanelWrapper.displayName = 'PanelWrapper';
export default connect(mapStateToProps,
	mapDispatchToProps)(PanelWrapper);


