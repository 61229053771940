import DesktopPluginAPI from '../../pluginAPI/DesktopPluginAPI'
import FlowStates from '../../constants/FlowStates'


const isConversationIdValid = (conversationId, plugin, state) => {
	// check if action has conversationId and if it is valid
	// conversationId is valid if:
	// 1. conversationId is not undefined
	// 2. conversationId is a string
	// 3. the conversation exists in conversations
	// 4. the conversation is initialised
	// 5. the conversation groupId is in plugin.enabledGroups

	// conversationId needs to be defined
	// check type of conversationId, only strings allowed

	if (typeof conversationId !== 'string') {
		return `ConversationId is not a string.`;
	}

	// conversation with conversationId must exist
	if (!state.hasIn(['conversations', conversationId])) {
		return `Conversation with conversationId ${conversationId} does not exist.`;
	}

	// conversation with conversationId must be ready
	const flowState = state.getIn(['conversations', conversationId, 'flowState']);
	if (flowState === FlowStates.REMOVING || flowState === FlowStates.NEW) {
		return `Conversation with conversationId ${conversationId} is not ready.`;
	}

	// conversation with conversationId must be of enabled group
	if (!plugin.get('enabledGroups').contains(state.getIn(['conversations', conversationId, 'groupId']))) {
		return `Conversation with conversationId ${conversationId}, group is unsupported.`;
	}

	return 'ok';
};


const isActionFormatValid = (action, data, actualApiVersion) => {

	// check that action has a format, which means it is supported
	const actionFormat = DesktopPluginAPI.getActionFormat(actualApiVersion, action);
	if (!actionFormat) {
		return `Action ${action} does not exist.`;
	}

	const formatKeys = Object.keys(actionFormat);
	// check that that action has all arguments that format has
	if (!formatKeys.every(key => data[key] !== undefined)) {
		return `Action ${action} is missing ${formatKeys.filter(key => data[key] === undefined)}.`;
	}

	const actionKeys = Object.keys(data);
	// check that that action has same nbr of arguments as format
	if (formatKeys.length !== actionKeys.length) {
		return `Action ${action} has to many parameters.`;
	}

	return 'ok';

};


/**
 *
 * @param {origin} = trans
 * @param {action} = incomingData
 * @param {data} = incomingData
 * @param plugin immutable
 * @param state immutable
 * @returns {boolean}
 */
const isActionValid = ({origin}, {action, ...data}, {plugin, state}) => {
	const actualApiVersion = plugin.get('actualApiVersion');

	// check origin
	if (plugin.get('url').indexOf(origin) !== 0) {
		return `Bad url origin. Expected \'${plugin.get('url')}\' got \'${origin}\'`;
	}

	// check that action is defined
	if (!action) {
		return 'Property action is not defined';
	}

	// check that action is enabled or an internal action
	if (!DesktopPluginAPI.isInternalAction(actualApiVersion, action) && !plugin.get('enabledActions').contains(action)) {
		return `Action ${action} is not enabled`;
	}

	if (isActionFormatValid(action, data, actualApiVersion) !== 'ok') {
		return isActionFormatValid(action, data, actualApiVersion);
	}

	if (data.conversationId === '') {
		return `ConversationId is empty.`;
	}

	if (data.conversationId && isConversationIdValid(data.conversationId, plugin, state) !== 'ok') {
		return isConversationIdValid(data.conversationId, plugin, state);
	}

	return 'ok';
};

export {
	isActionValid,
	isActionFormatValid,
	isConversationIdValid
}

