import React from 'react';
import createReactClass from 'create-react-class';
import {Button} from 'react-bootstrap';
import ViewActions from '../actions/ViewActions';
import { injectIntl } from 'react-intl';

export default injectIntl(createReactClass({

	handleClick (event) {
		ViewActions.startSuspendedDialog(this.props.conversationId);
		event.target.disabled = 'disabled';
	},

	render () {
		return (
			<Button
				data-tip={this.props.intl.formatMessage({id:'dialogButtonStartTitle'})}
				bsSize="xsmall"
				bsStyle="warning"
				className="tool-tip tool-tip-top-left pull-left"
				onClick={this.handleClick}
			>{this.props.intl.formatMessage({id:'dialogButtonStart'})}</Button>
		)
	}
}));
