var injector;


function initialize () {
	var el = document.getElementById('wrapper');
	if (!el) {
		injector = { get:function(){ console.error('injector failed to initialize.'); } };
		return;
	}
	//inject the angular NotificationService instance:
	injector = angular.element(el).injector();
}



export default function() {
	if (!injector) {
		initialize();
	}

	return injector;
}
