import Conversation from '../actionCreators/Conversation';
import PollRequest from './PollRequest';

export default class ConversationMyDetailsRequest extends PollRequest {
	constructor(reqGuid, restUrl) {
		super(reqGuid, restUrl);
	}

	get asRequest() {
		return {
			body: {},
			method: 'get',
			tag: this.guid,
			uri: `${this.restUrl}Conversation/Current/MyDetails`
		}
	}

	resolve(response, statusCode) {
		return new Promise((resolve, reject) => {
			if (statusCode > 399) {
				console.log('<ConversationMyDetails ERROR> ', response);
				reject(response);
			} else {
				resolve(Conversation.processConversationDetails(response));
			}
		});
	}
}

