var service = {
	configurationValue: null,

	get configuration () {
		return this.configurationValue;
	},
	set configuration (value) {
		this.configurationValue = value;
	}
};

export default service;
