import React, {useState, useEffect} from 'react';
import {
	Modal,
	ListGroup,
} from 'react-bootstrap';

const DebugAnalysisModal = (props) => {
	const [localData, setLocalData] = useState([]);
	const {data} = props;


	useEffect(() => {
		const keys = Object.keys(data);
		const newData = keys.reduce((obj, key) => {
			const item = data[key];
			if (!obj[item.type]) {
				obj[item.type] = [];
			}
			obj[item.type].push({...item, key});
			return obj;
		}, {});
		setLocalData(newData);
	}, [data, setLocalData]);

	const itemStyle = {
		width: '100%',
		textAlign: 'left'
	};

	const errStyle = {display: 'block'};

	const baseUrl = window.location.href.split('#').shift();
	const elements = Object.keys(localData).map(key => {
		const list = localData[key];
		const categoryElements = list.map(item => {
			const errors = item.errors.map(errObj => {
				const {errorMsg, severity} = errObj;
				const color = severity === 'crash' ? 'red': 'black';
				return (
					<span key={errorMsg} style={{...errStyle, color}}>{errorMsg}
						<a
							rel="noreferrer noopener"
							target="_blank"
							href={baseUrl + item.url}
						> &gt;&gt;</a>
					</span>
				);
			});
			return (
				<ListGroup.Item style={itemStyle} header={item.name} key={item.key}>{errors}</ListGroup.Item>
			);
		});
		return (
			<div key={key}>
				<h4>{key}</h4>
				<ListGroup>
					{categoryElements}
				</ListGroup>
			</div>);
	});

	return (
		<Modal className="case-manager-section-modal" show={true}
			   onHide={() => props.hideModal()}>
			<Modal.Header closeButton>
				<Modal.Title>Account analysis</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="case-type-area">
					{elements.length > 0 ? elements: <h4>No issues found</h4>}
				</div>

			</Modal.Body>
		</Modal>
	);
};

export default DebugAnalysisModal;
