const languages = {
	'da-DK': {
		langCode: 'da-DK',
		displayName: 'Danish',
		aliases: []
	},
	'en-GB': {
		langCode: 'en-GB',
		displayName: 'English',
		aliases: ['en', 'en-US']
	},
	'fr-FR': {
		langCode: 'fr-FR',
		displayName: 'French',
		aliases: []
	},
	'de-DE': {
		langCode: 'de-DE',
		displayName: 'German',
		aliases: []
	},
	'sv-SE': {
		langCode: 'sv-SE',
		displayName: 'Swedish',
		aliases: []
	}
};
// Update: Support same languages in whole Workspace as in Desktop. Saves a lot of trouble,
// and now we set a fallback-language ('en-GB') for Workspace-language, so if the translation is missing, the user gets English...
const supportedLanguages = ['da-DK','en-GB','fr-FR','de-DE','sv-SE'];

// NOTE: When adding new languages, don't forget to add the locale in moment
// by adding the language code in "webpack.config.development.js" and "webpack.config.production.js":
// In the plugins-section configuring the webpack.ContextReplacementPlugin()
// E.g:
// new webpack.ContextReplacementPlugin(/moment[\/\\]locale$/, /da|en|fr|de|sv/)

export default {
	languages,
	supportedLanguages,
	lookupByLangCode: langCode => {
		if (languages[langCode]) {
			return languages[langCode];
		}

		// Look for aliases matching "code"
		let foundLanguage = null;
		for (let lang of Object.keys(languages)) {
			if (languages[lang].aliases.indexOf(langCode) !==-1 ) {
				foundLanguage = languages[lang];
				break;
			}
		}
		return foundLanguage;
	}
};
