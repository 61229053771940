export default function (access) {
	return {
		id: 'solutions',
		access: (access || 'Statistics'),
		viewConfig: {
			metricName: 'ConversionFunnelSitePerOpportunityPerSolution',

			/** ----------------------------------------------* Measures */
			measures: [
				{
					key: 'siteName',
					type: 'string'
				}, {
					key: 'opportunityName',
					type: 'string'
				}, {
					key: 'solutionName',
					type: 'string'
				}, {
					key: 'dimensionName',
					type: 'string',
					calculation: {
						type: 'formatSolutionFromOpportunitySite',
						parameters: [
							'solutionName',
							'opportunityName',
							'siteName'
						]
					}
				}, {
					key: 'numberOfSolutions',
					type: 'number'
				}, {
					key: 'numberOfSolutionsSuppressed',
					calculation: {
						type: 'addition',
						parameters: [
							'numberOfSolutions',
							'-numberOfActivatedSolutions'
						]
					},
					type: 'number'
				}, {
					key: 'numberOfActivatedSolutions',
					type: 'number'
				}, {
					key: 'numberOfQueueAdded',
					type: 'number'
				}, {
					key: 'numberOfQueueDialogs',
					type: 'number'
				}, {
					key: 'numberOfEvaluatedSolutionsForUniqueVisitors',
					type: 'number'
				}, {
					key: 'numberOfActivatedSolutionsForUniqueVisitor',
					type: 'number'
				}, {
					key: 'numberOfSolutionsSuppressedForUniqueVisitors',
					calculation: {
						type: 'addition',
						parameters: [
							'numberOfEvaluatedSolutionsForUniqueVisitors',
							'-numberOfActivatedSolutionsForUniqueVisitor'
						]
					},
					type: 'number'
				}
			],


			/** ----------------------------------------------* Bar filters */
			barFilters: [
				{
					dimension: 'siteName',
					valueAccessor: 'numberOfActivatedSolutions'
				}, {
					dimension: 'opportunityName',
					valueAccessor: 'numberOfActivatedSolutions'
				}, {
					dimension: 'solutionName',
					valueAccessor: 'numberOfActivatedSolutions'
				}
			],


			/** ----------------------------------------------* Time line graph */
			timeLineGraph: {
				valueAccessor: 'numberOfActivatedSolutions'
			},


			/** ----------------------------------------------* Totals */
			totals: [
				{
					measure: 'numberOfActivatedSolutionsForUniqueVisitor',
					displayPercentOf: 'numberOfEvaluatedSolutionsForUniqueVisitors'
				}, {
					measure: 'numberOfQueueAdded'
				}, {
					measure: 'numberOfQueueDialogs',
				}, {
					measure: 'numberOfSolutionsSuppressedForUniqueVisitors',
					displayPercentOf: 'numberOfEvaluatedSolutionsForUniqueVisitors'
				}, {
					measure: 'numberOfEvaluatedSolutionsForUniqueVisitors',
					hidden: true
				}
			],


			/** ----------------------------------------------* Table */
			table: {
				groupBy: 'dimensionName',
				measures: [
					{
						measure: 'numberOfActivatedSolutionsForUniqueVisitor',
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'numberOfActivatedSolutionsForUniqueVisitor',
								'numberOfEvaluatedSolutionsForUniqueVisitors'
							]
						}
					}, {
						measure: 'numberOfQueueAdded'
					}, {
						measure: 'numberOfQueueDialogs',
					}, {
						measure: 'numberOfSolutionsSuppressedForUniqueVisitors',
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'numberOfSolutionsSuppressedForUniqueVisitors',
								'numberOfEvaluatedSolutionsForUniqueVisitors'
							]
						}
					}
				]
			}
		}
	}
};
