import {
	visitorProfileAdd,
	setVisitorProfileNavigation,
	setClaimsPatch,
} from './stateModifiers/visitorProfile';

import AppConstants from '../../../components/constants/AppConstants';

const ActionTypes = AppConstants.ActionTypes;

export default function VisitorProfileReducer(state, action) {

	switch (action.type) {
		case ActionTypes.VISITOR_PROFILE_ADD:
			return visitorProfileAdd(state, action);

		case ActionTypes.SET_VISITOR_PROFILE_NAVIGATION:
			return setVisitorProfileNavigation(state, action);

		case ActionTypes.SET_CLAIMS_PATCH:
			return setClaimsPatch(state, action);

		default:
			return state;

	}
}

