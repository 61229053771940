import moment from 'moment-timezone';
export default function(group, $uibModalInstance, options, VngageUtil, GroupOpeningHoursService) {
    'ngInject';

    var vm = this;
    vm.cancel = $uibModalInstance.dismiss;
    vm.save = save;
    vm.remove = remove;
    vm.options = options;
    vm.group = group;

    vm.groupAllocationTypes = {
        0: 'Open',
        2: 'Closed'
    };
	vm.daysOfWeek = {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday'
	};

	if (options.newAllocation) {
        vm.timeAllocation = {
            groupAllocationType: 0,
            dayOfWeek: +options.momentObj.format('d'),
            startTime: options.momentObj.format('HH:mm'),
            duration: '00:30',
            includeHolidays: false,
            enableReservation: false,
            slots:0,
            reservationDuration:60,
            name: ''
        };
    } else {
        vm.timeAllocation = group.data.timeAllocations[options.allocationIndex];
    }

    vm.endTime = GroupOpeningHoursService.getEndTimeHHmm(vm.timeAllocation.startTime, vm.timeAllocation.duration);

    function save() {
        vm.isSaving = true;

        // TODO get timeAllocation using HHmm strings instead of the date objs
        var startTimeDateObj = moment(vm.timeAllocation.startTime, 'HH:mm');
        var endTimeDateObj = moment(vm.endTime, 'HH:mm');
        vm.timeAllocation.duration = GroupOpeningHoursService.getDurationHHmm(startTimeDateObj, endTimeDateObj);

        if (vm.options.newAllocation) {
            group.data.timeAllocations.push(vm.timeAllocation);
        }
        saveAndClose();
    }

    function remove() {
        vm.isSaving = true;
        if (VngageUtil.confirmSimple('Sure you want to delete?')) {
            group.data.timeAllocations.splice(options.allocationIndex, 1);
            saveAndClose();
        } else {
            vm.isSaving = false;
			$uibModalInstance.dismiss();
        }
    }

    function saveAndClose() {
        group.$save().then(function() {
			$uibModalInstance.close();
        }).finally(function() {
            vm.isSaving = false;
        });
    }
};
