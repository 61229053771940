/**
 * Controllers
 */
import holidaysController from './holidaysController';
/**
 * Services
 */
import holidaysService from './holidaysService';
/**
 * Templates
 */
import holidaysTemplate from './holidaysTemplate.html';
/**
 * Models
 */
import holidaysTranslations from './holidaysTranslations';

/**
 * Case modal
 */
import holidaysConfigModalController from './holidaysConfigModal/holidaysConfigModalController';
import holidaysConfigModalService from './holidaysConfigModal/holidaysConfigModalService';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.calendar.holidays', [
	'ui.router',
	'ui.calendar',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('holidaysController', holidaysController)
	.factory('holidaysService', holidaysService)
	.constant('holidaysTranslations', holidaysTranslations)

	.controller('holidaysConfigModalController', holidaysConfigModalController)
	.factory('holidaysConfigModalService', holidaysConfigModalService)

	.constant('holidaysModuleConfig', {
		state: 'root.calendar.holidays'
	})

	.config(function ($stateProvider, $translateProvider, holidaysTranslations, holidaysModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(holidaysTranslations);

		$stateProvider.state(holidaysModuleConfig.state, {
			url: 'holidays/',
			controller: 'holidaysController as vm',
			templateUrl: holidaysTemplate,
			data: {
				ncyBreadcrumbLabel: 'holidays'
			}
		});
	});
