export default function (roleClaim, systemConfiguration, SystemRolesService, APIEndpointService, $state) {
	'ngInject';

	var vm = this;
	vm.roleClaim = angular.copy(roleClaim);
	vm.submit = submit;
	vm.selectableAuthorizations = SystemRolesService.formatAuthorizations(systemConfiguration.allowedAuthorizations);

	// Authorizations don't have id:s!!
	// So let SystemRolesService.formatAuthorizations() generate refValues for them (for use as "data-selected-values" in <vngage-selectable-items>)
	vm.selectedAuthorizationRefs = SystemRolesService.formatAuthorizations(vm.roleClaim.authorizations).map(formattedAuthorization => formattedAuthorization.refValue);

	function submit() {
		vm.isSaving = true;

		// Update vm.roleClaim.authorizations with selected authorizations (lookup by refValue in systemConfiguration.allowedAuthorizations)
		vm.roleClaim.authorizations = vm.selectedAuthorizationRefs.map(refValue => {
			return SystemRolesService.findAuthorizationByRefValue(systemConfiguration.allowedAuthorizations, refValue);
		}).filter(authorization => !!authorization);

		return APIEndpointService.systemConfRoleClaim.save({id: vm.roleClaim.id}, {data: vm.roleClaim}).$promise.then(function (updatedRoleClaim) {
			SystemRolesService.replaceRoleClaim(systemConfiguration.roleClaims, updatedRoleClaim).finally(() => {
				vm.isSaving = false;
				$state.transitionTo($state.current, {
					id: vm.roleClaim.id,
					roleClaim: updatedRoleClaim
				});
			});
		}, err => {
			vm.isSaving = false;
			console.log('Error saving roleClaim (SystemRoleAuthorizationsController)');
		});
	}
};
