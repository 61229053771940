import React from 'react';
import {connect} from 'react-redux';
import immutable from 'immutable';
import Navigation from '../../actionCreators/Navigation';
import ServerActions from '../../actionCreators/ServerActions';
import HeaderMenu from './HeaderMenu.react';
import {useGetTranslation} from '../LangContext';

const panelGrey = '#C0C4C1';

export const Header = (props) => {
	const getTranslation = useGetTranslation();

	const togglePanelCollapse = (event) => {
		if (!props.isMaximized) {
			const position = props.isCollapsed ? 'normal': 'collapsed';
			props.repositionPanel(props.conversationId, position);
		}
		event.stopPropagation();
	};

	let conversationStatusText = '';
	let icon = <i className="vngage-icon-user-line"/>;

	if (props.conversationStatus === 'closed') {
		icon = <i className="vngage-icon-user-line"/>;
		conversationStatusText = getTranslation('conversationStatusDisconnected');
	} else if (props.conversationStatus === 'pendingStart' || props.conversationStatus === 'suspended') {
		icon = <i className="vngage-icon-clock"/>;
		conversationStatusText = getTranslation('conversationStatusPendingStart');
	} else {
		// not closed, base it on connectionStatus instead
		switch (props.connectionStatus) {
			case 'ok':
				conversationStatusText = getTranslation('conversationStatusOnline');
				break;
			case 'lost':
				conversationStatusText = getTranslation('conversationStatusOnline');
				break;
			case 'dead':
				conversationStatusText = getTranslation('conversationStatusOnline');
				break;
			case 'terminate':
				icon = <i className="vngage-icon-ico_networkerror"/>;
				conversationStatusText = getTranslation('inputMsgVisitorLeft');
				break;
			default:
				icon = null;
				conversationStatusText = '';
		}
	}

	const style = {
		background: props.headerColor
	};

	let notifier = '';
	if (props.unreadMessages > 0) {
		const nr = props.unreadMessages % 2;
		const cls = ' notifier bounce' + nr;
		notifier = <div className={cls}>{props.unreadMessages}</div>;
	}
	return (
		<div className="header" style={style}>
			<div className="header-text" id={'header' + props.conversationId} tabIndex="-1"
				 onClick={togglePanelCollapse}>
				{icon}
				<div className="status-text">
					{conversationStatusText.substr(0, 20)}
				</div>
			</div>
			{notifier}
			<HeaderMenu {...props} />
		</div>
	);
};

function mapStateToProps(state, ownProps) {
	// INCOMING
	// conversationId
	const conversationId = ownProps.conversationId;
	const conversation = state.getIn(['conversations', conversationId]) || immutable.Map();

	const isCollapsed = conversation.getIn(['UI', 'panelPosition']) === 'collapsed';
	const isMaximized = conversation.getIn(['UI', 'panelPosition']) === 'coBro';
	const conversationStatus = conversation.get('conversationStatus');
	const connectionStatus = conversation.get('connectionStatus');
	const headerColor = conversationStatus === 'open' ? conversation.getIn(['UI', 'panelHeaderColor']): panelGrey;
	const unreadMessages = conversation.getIn(['UI', 'unreadMessages']);
	const caseId = conversation.get('caseId');
	const enabledCapabilities = conversation.getIn(['capabilities', 'enabled']) || immutable.Map();
	const groupId = conversation.get('groupId');
	const groups = state.get('groups');

	const participant = conversation.get('participants').find(participant => !participant.getIn(['info', 'owner']));
	const participantId = (participant ? participant.get('id'): null);

	return {
		conversationId,
		isMaximized,
		isCollapsed,
		conversationStatus,
		connectionStatus,
		headerColor,
		unreadMessages,

		caseId,
		enabledCapabilities,
		groupId,
		groups,
		participantId,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		repositionPanel: (conversationId, nextPanelPosition) => dispatch(Navigation.repositionPanel(conversationId, nextPanelPosition)),
		transferToGroup: (conversationId, groupId) => dispatch(ServerActions.transferToGroup(conversationId, groupId)),
		disconnect: (conversationId) => dispatch(ServerActions.disconnect(conversationId)),
		banParticipant: (conversationId, participantId) => dispatch(ServerActions.banParticipant(conversationId, participantId)),
		putAwayConversation: (conversationId, caseId, reasonName) => dispatch(ServerActions.putAwayConversation(conversationId, caseId, reasonName)),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Header);

