export default {
	'en-GB': {
		statistics: {
			views: {
				solutions: {
					MENU_LABEL: 'Solutions',
					TITLE: 'Solutions',
					TABLE_HEADING: 'Hour report',
					TIME_LINE_GRAPH_HEADING: 'Activated per hour',
					measures: {
						siteName: {
							NAME: 'Site',
							TITLE: 'The name of the site presenting the chat option',
							LONG_DESCRIPTION: ''
						},
						opportunityName: {
							NAME: 'Opportunity',
							TITLE: 'The name of the opportunity that triggered the chat option',
							LONG_DESCRIPTION: ''
						},
						solutionName: {
							NAME: 'Solution',
							TITLE: 'The name of the solution presented to the visitor. This is where the visitor can choose to engage in a chat',
							LONG_DESCRIPTION: ''
						},
						dimensionName: {
							NAME: 'Solution',
							TITLE: '',
							LONG_DESCRIPTION: '*This measure is actually a generated text to group the dimensions visually in the data table. It is generated by "Soution name" + From: "Opportunity name + "Site name")'
						},
						numberOfSolutions: {
							NAME: 'Solutions',
							TITLE: 'The number possible solutions to present where opportunities are located',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of solutions triggered from an opportunity. If enough Agents are handling the requests the next step is to display the solution to the visitor'
						},
						numberOfSolutionsSuppressed: {
							NAME: 'Suppressed',
							TITLE: 'These solutions has been suppressed because of limited opening hours or to few agents are handling the queue (Percent of possible solution to present)',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of solutions triggered from an opportunity. If enough Agents are handling the requests the next step is to display the solution to the visitor'
						},
						numberOfActivatedSolutions: {
							NAME: 'Presented',
							TITLE: 'Presented options for assistance (% Percent of possible solution to present)',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of solutions displayed to the visitors qualified by the parent opportunity. The % represents the percentage of the total possible solutions that could have been presented to the visitors'
						},
						numberOfQueueAdded: {
							NAME: 'Requests',
							TITLE: 'Number of queued assistance requests',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of visitors added to a queue waiting for assistance'
						},
						numberOfQueueDialogs: {
							NAME: 'Dialogs',
							TITLE: 'Number of dialogs with customer service',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of dialogs picked from the queues'
						},
						numberOfEvaluatedSolutionsForUniqueVisitors: {
							NAME: 'Solutions per unique visitors',
							TITLE: 'Solutions ready to be presented to the visitor if enough agents are logged in',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of solutions for unique visitors. If enough Agents are handling the requests the next step is to display the solution to the visitor'
						},
						numberOfActivatedSolutionsForUniqueVisitor: {
							NAME: 'Presentations to unique visitors',
							TITLE: 'Solutions presented to unique visitors (Percent of visitors who qualified for the opportunity)',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of solutions displayed to unique visitors . The % represents the percentage of the total possible solutions that could have been presented to these visitors'
						},
						numberOfSolutionsSuppressedForUniqueVisitors: {
							NAME: 'Suppressed visitors',
							TITLE: 'Unique visitors where solutions has been suppressed (missed opportunities) due to limited opening hours or that to few agents are handling the queue (Percent of visitors who qualified for the opportunity)',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of solutions triggered from an opportunity. If enough Agents are handling the requests the next step is to display the solution to the visitor'
						},
						numberOfCompletedSolutionsForUniqueVisitors: {
							NAME: 'Visitors who engaged',
							TITLE: 'Solutions engaged in by unique visitors and how many percent visitors engaged after being presented a solution',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of visitors engaged/requests assistance either a call-back or a queued request from the solution'
						}
					},
					barFilters: {
						siteName: {
							HEADING: 'Sites',
							LONG_DESCRIPTION: 'The number of activated solutions per site in the selected time period'
						},
						opportunityName: {
							HEADING: 'Opportunities',
							LONG_DESCRIPTION: 'The number of activated solutions per Opportunity'
						},
						solutionName: {
							HEADING: 'Solutions',
							LONG_DESCRIPTION: 'The number of activated solutions (displayed banners)'
						}
					}
				}
			}
		}
	},
	'fr-FR': {
		statistics: {
			views: {
				solutions: {
					MENU_LABEL: 'Solutions',
					TITLE: 'Solutions',
					TABLE_HEADING: 'Rapport horaire',
					TIME_LINE_GRAPH_HEADING: 'Activation par heure',
					measures: {
						siteName: {
							NAME: 'Site',
							TITLE: 'Nom du site proposant l\'option de chat',
							LONG_DESCRIPTION: ''
						},
						opportunityName: {
							NAME: 'Opportunité',
							TITLE: 'Nom de l\'opportunité qui a déclenché l\'option de chat',
							LONG_DESCRIPTION: ''
						},
						solutionName: {
							NAME: 'Solution',
							TITLE: 'Nom de la solution présentée au visiteur. C\'est ici que le visiteur peut choisir de se lancer dans un chat',
							LONG_DESCRIPTION: ''
						},
						dimensionName: {
							NAME: 'Solution',
							TITLE: '',
							LONG_DESCRIPTION: '*Cette mesure est en fait un texte généré pour regrouper visuellement les dimensions dans le tableau de données. Elle est générée par « Solution name » + à partir de : « Nom de l\'opportunité + nom du site »)',
						},
						numberOfSolutions: {
							NAME: 'Solutions',
							TITLE: 'Nombre de solutions possibles à présenter là où se situent les opportunités',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de solutions mises en œuvre à partir d\'une opportunité. Si suffisamment d\'agents traitent les demandes, l\'étape suivante consiste à présenter la solution au visiteur'
						},
						numberOfSolutionsSuppressed: {
							NAME: 'Supprimée',
							TITLE: 'Ces solutions ont été supprimées à cause des heures d\'ouverture limitées ou de la faible présence d\'agents dans la file d\'attente (Pourcentage de solutions possibles à présenter)',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de solutions mises en œuvre à partir d\'une opportunité. Si suffisamment d\'agents traitent les demandes, l\'étape suivante consiste à présenter la solution au visiteur'
						},
						numberOfActivatedSolutions: {
							NAME: 'Présentation',
							TITLE: 'Options d\'assistance présentées (% Pourcentage de solutions possibles à présenter)',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de solutions présentées aux visiteurs qualifiés par l\'opportunité mère. % représente le pourcentage du total des solutions envisageables qui auraient pu être présentées aux visiteurs'
						},
						numberOfQueueAdded: {
							NAME: 'Demandes',
							TITLE: 'Nombre de demandes d\'assistance en attente',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de visiteurs ajoutés à une file d\'attente '
						},
						numberOfQueueDialogs: {
							NAME: 'Dialogues',
							TITLE: 'Nombre de dialogues avec le service clients',
							LONG_DESCRIPTION: 'Ce nombre est compté par heure et représente le nombre de dialogues sélectionnés dans les files d\'attente'
						},
						numberOfEvaluatedSolutionsForUniqueVisitors: {
							NAME: 'Solutions par visiteurs uniques',
							TITLE: 'Solutions prêtes à être présentées au visiteur si suffisamment d\'agents sont connectés',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de solutions pour les visiteurs uniques. Si suffisamment d\'agents traitent les demandes, l\'étape suivante consiste à présenter la solution au visiteur'
						},
						numberOfActivatedSolutionsForUniqueVisitor: {
							NAME: 'Présentations aux visiteurs uniques',
							TITLE: 'Solutions présentées aux visiteurs uniques (Pourcentage de visiteurs correspondant à l\'opportunité)',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de solutions présentées aux visiteurs uniques. % représente le pourcentage du total des solutions envisageables qui auraient pu être présentées à ces visiteurs'
						},
						numberOfSolutionsSuppressedForUniqueVisitors: {
							NAME: 'Visiteurs supprimés',
							TITLE: 'Visiteurs uniques pour lesquels les solutions ont été supprimées (opportunités manquées) en raison d\'heures d\'ouverture limitées ou d\'un nombre réduit d\'agents gérant la file d\'attente (Pourcentage de visiteurs correspondant à l\'opportunité)',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de solutions déclenchées d\'une opportunité. Si un nombre suffisant d\'agents gèrent les demandes l\'étape suivante consiste à afficher la solution au visiteur'
						},
						numberOfCompletedSolutionsForUniqueVisitors: {
							NAME: 'Visiteurs s\'étant engagés',
							TITLE: 'Solutions adoptées par les visiteurs uniques et  pourcentage de visiteurs engagés après qu\'une solution leur a été présentée',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de visiteurs engagés / demande d\'assistance soit par rappel ou par demande en file d\'attente'
						}
					},
					barFilters: {
						siteName: {
							HEADING: 'Sites',
							LONG_DESCRIPTION: 'Nombre de solutions activées par site au cours de la période sélectionnée'
						},
						opportunityName: {
							HEADING: 'Opportunités',
							LONG_DESCRIPTION: 'Nombre de solutions activées par opportunité'
						},
						solutionName: {
							HEADING: 'Solutions',
							LONG_DESCRIPTION: 'Nombre de solutions activées (bannières affichées)'
						}
					}
				}
			}
		}
	}
};
