export default function ($q, less) {
	'ngInject';

	const lessOptions = {
		paths: [''],
		relativeUrls: false,
		compress: true,	// TODO: Don't use less' built-in compressor/minifier (deprecated: http://lesscss.org/usage/#less-options-compress-deprecated-)
		javascriptEnabled: true,	// javascript is disabled by default from less 3.x, and needs to be explicitly enabled (because we use a lot of lesshat-functions)
		// sourceMap: { sourceMapFileInline: true }
	};
	let isWorking = false;

	function parse(lessContent, conf, preview) {
		return $q((resolve, reject) => {

			if (preview && isWorking) {
				// Don't start another preview-render if the previous is not yet finished...
				reject('Busy rendering');
				return;
			}

			lessContent = lessContent || '';
			isWorking = true;
			less.render(lessContent, lessOptions).then(result => {
				isWorking = false;
				resolve(result.css);
			}).catch(error => {
				// console.error('Less render error:', error);
				isWorking = false;
				reject({
					error: error,
					result: null
				});
			});
		});
	}

	return {
		parse: parse
	};
}
