import GuidGenerator from '../../../components/utils/GuidGenerator';
import VisitorProfileRequest from '../requests/VisitorProfileRequest';
import VisitorNavigationRequest from '../requests/VisitorNavigationRequest';
import ConversationSinceRequest from '../requests/ConversationSinceRequest';
import ConversationUnassignedRequest from '../requests/ConversationUnassignedRequest';
import ConversationMyDetailsRequest from '../requests/ConversationMyDetailsRequest';
import InteractionOverviewRequest from '../requests/InteractionOverviewRequest';
import BatchRequest from '../requests/BatchRequest';
import {Store} from '../stores/Store';
import ServerActions from '../actionCreators/ServerActions';
import LogActions from '../actions/LogActions';
import GroupActions from '../actions/GroupActions';
import Group from '../actionCreators/Group';
import AccountConfig from '../utils/AccountConfig';
import NetId from './NetId';
import PollManager from './PollManager';
import LocalStorage from '../stores/LocalStorageWrapper';
import StateFilters from '../filters/StateFilters';
import AuthKeys from '../../../common/auth/AuthKeys';
import timerTools from './TimerTools';
const {getPresence, getForcedAway} = StateFilters;

let $http, $restUrl, $batchRestUrl, shouldPoll = false;
let pollTimeStamp = 0;
let presenceTimeStamp = 0;
const setGroupTime = 20;
const pollTime = 3;
const REQUESTS = new Map();

let pollSupervisor = null;

const postRequest = (localNetId, http, {reqUrl, payload}) => {
	const hasPayload = !!payload;
	const payloadStr = hasPayload ? JSON.stringify(payload): '';
	Store.dispatch(LogActions.netCall(localNetId, reqUrl, hasPayload ? payload: ''));
	return http.post(reqUrl, payloadStr)
		.then(function (result) {
		}, function (err) {
			Store.dispatch(LogActions.netCallError(localNetId, {reqUrl, payload, err}));
		});
};

const postRequestArray = async (postRequests, inSequence = false) => {
	for (let postReq of postRequests) {
		const localNetId = NetId.getNewNetId();
		if (inSequence) {
			await postRequest(localNetId, $http, postReq);
		} else {
			postRequest(localNetId, $http, postReq);
		}
	}
};

const getDesktopGuid = (debug) => {
	const desktopId = Store.getState().get('desktopId') || '0';
	const windowId = !debug
		? window.vngage && window.vngage.info && window.vngage.info.wID || '0'
		: 'wID';
	const guid = GuidGenerator.create();
	return `${windowId}-${desktopId}-${guid}`;
};

let WebApiUtils = {

	// used for test of poll
	initVars(http, vngageConfig) {
		$http = http;
		$restUrl = vngageConfig.restUrl;
		$batchRestUrl = vngageConfig.batchRestUrl;
	},

	// used in DesktopDeux
	init(http, vngageConfig, identityService) {
		$http = http;
		$restUrl = vngageConfig.restUrl;
		$batchRestUrl = vngageConfig.batchRestUrl;
		shouldPoll = false;

		if (!pollSupervisor) {
			pollSupervisor = timerTools.setInterval(() => {
				const currentTime = new Date().getTime();
				this.pollPresence(identityService, currentTime);

				const secondsDiff = Math.round((currentTime - pollTimeStamp) / 1000);
				if (secondsDiff >= pollTime) {
					pollTimeStamp = currentTime;
					this.poll();
				}
			}, 1000);
		}
	},

	// presence poll every 20 seconds and immediate when presence changes
	pollPresence(identityService, currentTime) {
		if (shouldPoll && Store.getState().get('desktopStatus') === 'desktop') {
			const secondsDiff = Math.round((currentTime - presenceTimeStamp) / 1000);
			const currentPresence = LocalStorage.getItem(AuthKeys.userPresence) || 'online';
			if (getPresence() !== currentPresence) {
				// presence changed from outside desktop and not by forced away
				presenceTimeStamp = currentTime;
				Store.dispatch(Group.setPresence(currentPresence));
			} else {
				// presence same in localStorage and state, notify identityService
				identityService.setPresence(getPresence());
				if (secondsDiff >= setGroupTime) {
					presenceTimeStamp = currentTime;
					Store.dispatch(Group.sendCurrentGroupAvailability());
				}
				if (getPresence() === 'away' && getForcedAway()) {
					Store.dispatch(GroupActions.resetForceAway());
					identityService.setForcedAwayVisibility('visible');
					presenceTimeStamp = currentTime;
					Store.dispatch(Group.sendCurrentGroupAvailability());
				}
			}
		}
	},

	/**
	 * poll method
	 * starts a poll where it uses data from the state to make requests.
	 * It sends batches of requests every 3 seconds
	 */
	// used in DesktopDeux
	poll(debug = false) {
		if (!shouldPoll && !debug || Store.getState().get('desktopStatus') !== 'desktop') {
			return false;
		}
		const netId = NetId.getNewNetId();
		if (!debug) {
			if (PollManager.okToPoll(netId)) {
				Store.dispatch(LogActions.netPoll(netId, PollManager.getPollStats(netId)));
			} else {
				Store.dispatch(LogActions.netPollSkip(netId, PollManager.getPollStats(netId)));
				return false;
			}
		}
		const postRequests = [];
		REQUESTS.clear();

		const myGroupAvailability = Store.getState().get('myGroupAvailability');
		const isOnline = Store.getState().get('presence') === 'online';
		(function (guid) {
			const groupIds = AccountConfig.getUserGroupIds().filter(groupId => myGroupAvailability.get(groupId) === true);
			if (groupIds.length > 0) {
				REQUESTS.set(guid, new ConversationUnassignedRequest(guid, $batchRestUrl, groupIds));
			}
		}(getDesktopGuid(debug)));

		(function (guid) {
			REQUESTS.set(guid, new ConversationMyDetailsRequest(guid, $batchRestUrl));
		}(getDesktopGuid(debug)));

		(function (guid) {
			REQUESTS.set(guid, new InteractionOverviewRequest(guid, $batchRestUrl));
		}(getDesktopGuid(debug)));

		const conversations = Store.getState().get('conversations');

		// handle since requests
		conversations.filter((conversation, conversationId) => {
			const conversationStatus = conversation.get('conversationStatus');
			const flowState = conversation.get('flowState');
			return flowState === 'ready' && (conversationStatus === 'open' || conversationStatus === 'closed');
		}).forEach((conversation, conversationId) => {
			const since = conversation.get('since');
			const reqGuid = getDesktopGuid(debug);
			REQUESTS.set(reqGuid, new ConversationSinceRequest(reqGuid, $batchRestUrl, conversationId, since));
		});

		if (!debug) {
			// handle new conversations
			Store.dispatch(ServerActions.handleNewConversations());

			// look for any missing visitorProfiles
			Store.dispatch(ServerActions.checkVisitorProfiles());
		}

		const excessConversations = Store.getState().get('excessConversations');
		excessConversations.forEach(conversationId => {
			postRequests.push({reqUrl: `${$restUrl}Conversation/${conversationId}/Leave`});
		});

		const visitors = Store.getState().get('visitors');
		const visitorProfiles = Store.getState().get('visitorProfiles');
		visitors.forEach(visitor => {
			const visitId = visitor.get('visitId');
			if (visitId && visitId !== '00000000-0000-0000-0000-000000000000' && !visitorProfiles.has(visitId)) {
				let reqGuid = getDesktopGuid(debug);
				REQUESTS.set(reqGuid, new VisitorProfileRequest(reqGuid, $batchRestUrl, visitId));
				reqGuid = getDesktopGuid(debug);
				REQUESTS.set(reqGuid, new VisitorNavigationRequest(reqGuid, $batchRestUrl, visitId));
			}
		});

		if (debug) {
			const pollData = {
				getRequests: [],
				postRequests,
			};
			for (let item of REQUESTS.values()) {
				pollData.getRequests.push(item.asRequest);
			}
			return pollData;

		}

		postRequestArray(postRequests);

		if (REQUESTS.size > 0) {
			const myNetId = netId;
			PollManager.add(myNetId);
			let batches = BatchRequest($http.post, $restUrl, REQUESTS, myNetId);
			batches.then(response => {
				Store.dispatch(LogActions.netPollDone(netId, PollManager.getPollStats(netId)));
				const reqResponses = response.reqResponses || [];
				reqResponses
					// remove bad actions, empty ConversationSinceRequest will be null
					.filter(response => !!response)
					// dispatch actions
					.map(Store.dispatch);
				PollManager.remove(response.netId);
			}).catch(response => {
				response.errors.forEach(error => {
					if (error.type === 'warning') {
						Store.dispatch(LogActions.netPollWarning(response.netId, error.err, PollManager.getPollStats(NetId.getNetId())));
					} else {
						Store.dispatch(LogActions.netPollError(response.netId, error.err, PollManager.getPollStats(NetId.getNetId())));
					}
				});
				PollManager.remove(response.netId);
			});
		}

	},

	// used in DesktopDeux
	getShouldPoll() {
		return shouldPoll;
	},
	// used in DesktopDeux
	setShouldPoll(s) {
		if (s && !shouldPoll) {
			// polling is turned on after being off
			// restore times
			pollTimeStamp = 0;
			presenceTimeStamp = 0;
		}
		shouldPoll = s;
	},

	/**
	 * dispose polling, actually only disabling it if it is running
	 */
	// used in DesktopDeux
	dispose() {
		// TODO this should also abort ongoing requests
		shouldPoll = false;
	},

};

export default WebApiUtils;
