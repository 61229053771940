import React from 'react';
import TimeAgo from '../../../components/TimeAgo.react'
import SpeakerTitle from '../../../components/SpeakerTitle.react';
import mmd from 'mmd';
import createReactClass from 'create-react-class';

export default createReactClass({

	render () {
		let message = this.props.message;
		let speaker = this.props.speaker;
		if (!message.message) {
			message.message = '';
		}
		let rawMarkup = mmd(message.message.toString());

		return (
			<div className="flex-container">
				<h5 className="message-author-name">
					<SpeakerTitle name={speaker.name} title={speaker.titleId} isMe={speaker.isCurrentUser} />
				</h5>
				<span className="message-createdAt">
					<TimeAgo startTime={message.createdAt} relative={this.props.isMostRecentMessage} isMostRecentMessage={this.props.isMostRecentMessage}/>
				</span>
				<div className="message-text">
					<span dangerouslySetInnerHTML={{__html: rawMarkup}} />
				</div>
			</div>
		);
	}
});
