import IdentityProviderAddModalController from './IdentityProviderAddModalController';
import IdentityProviderEditController from './IdentityProviderEditController';
import IdentityProviderListController from './IdentityProviderListController';
import identityProviderTranslations from './IdentityProviderTranslations';
import IdentityProviderListTemplate from './IdentityProviderListTemplate';
import IdentityProviderEditTemplate from './IdentityProviderEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.account.identityProvider', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('IdentityProviderAddModalController', IdentityProviderAddModalController)
	.controller('IdentityProviderEditController', IdentityProviderEditController)
	.controller('IdentityProviderListController', IdentityProviderListController)
	.constant('identityProviderTranslations', identityProviderTranslations)

	.constant('identityProviderModuleConfig', {
		state: 'root.account.identityProvider',
		access: 'Account/IDP'
	})

	.config(function ($stateProvider, $translateProvider, identityProviderTranslations, identityProviderModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';

		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(identityProviderTranslations);

		$stateProvider.state(identityProviderModuleConfig.state, {
			url: 'identityprovider/',
			controller: 'IdentityProviderListController as vm',
			templateUrl: IdentityProviderListTemplate,
			data: {
				access: identityProviderModuleConfig.access,
				ncyBreadcrumbLabel: 'IDP'
			},
			resolve: {
				identityProviderList: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.identityProvider.query().$promise;
				}
			}
		});

		$stateProvider.state('root.account.identityProvider.edit', {
			url: ':id',
			controller: 'IdentityProviderEditController as vm',
			templateUrl: IdentityProviderEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				identityProvider: function (identityProviderList, $stateParams, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(identityProviderList, $stateParams.id);
				}
			}
		});
	});
