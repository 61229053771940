import weekPickerTemplate from './weekPickerTemplate';
import moment from 'moment-timezone';
import assign from 'object-assign';

export default function (SessionService) {
	'ngInject';

	var noOfVisibleWeeks=5;
	var today,timezone;

	function getWeekRangeFromDate(d) {
		var m = moment(d).tz(timezone);
		return {
			from: m.startOf('isoWeek').format(),
			to: m.endOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
		}
	}
	function getDateObj(weekStartDate) {
		return assign({week:moment(weekStartDate).tz(timezone).format('W')}, getWeekRangeFromDate(weekStartDate));
	}

	function setVisibleWeeks(selectedWeekStart) {
		var i,w,visibleWeeks=[getDateObj(selectedWeekStart)];
		for (i=1;i<=noOfVisibleWeeks;i++) {
			// Add previous weeks
			w = moment(selectedWeekStart).startOf('isoWeek').subtract(i,'week');
			visibleWeeks.unshift(getDateObj(w));
		}
		for (i=1;i<=noOfVisibleWeeks;i++) {
			// Add next weeks
			w=moment(selectedWeekStart).startOf('isoWeek').add(i,'week');
			if (w.diff(today)<=0) {
				visibleWeeks.push(getDateObj(w));
			} else {
				// ...but don't add future weeks
				break;
			}
		}
		return visibleWeeks;
	}

	return {
		restrict: 'E',
		require: '^ngModel',
		scope: {
			weekStart: '=ngModel',
			timezone: '='
		},
		templateUrl: weekPickerTemplate,
		link: function ($scope, element, attrs) {

			if (!$scope.timezone) {
				$scope.timezone=SessionService.getUserTimezone();
			}
			timezone=$scope.timezone;
			today=moment().tz(timezone);

			if (!$scope.weekStart) {
				$scope.weekStart = today.startOf('isoWeek');
			}
			$scope.selectedWeekObj = getDateObj($scope.weekStart);
			$scope.visibleWeeks = setVisibleWeeks($scope.weekStart);

			$scope.setStyle = function(weekObj) {
				if (weekObj.from===$scope.selectedWeekObj.from) {
					return {
						fontWeight: 'bold',
						background: '#00b7c9',
						color: '#ffffff',
						border: 'none'
					}
				} else {
					return {}
				}
			}
			$scope.setWeek = function(weekObj) {
				$scope.weekStart = moment(weekObj.from).tz(timezone);
				$scope.selectedWeekObj = weekObj;
				$scope.visibleWeeks = setVisibleWeeks($scope.weekStart);
			}
		}
	};
}
