import React from 'react';
import createReactClass from 'create-react-class';
import ViewActions from '../actions/ViewActions';
import {SplitButton, Dropdown} from 'react-bootstrap';
import { injectIntl } from 'react-intl';

export default injectIntl(createReactClass({

	handleClick (event) {
		event.preventDefault();
		ViewActions.releaseToGroup(this.props.conversationId, this.props.currentGroupId);
	},

	handleSelect (event, groupId) {
		event.preventDefault();
		ViewActions.releaseToGroup(this.props.conversationId, groupId);
	},

	render (){
		var currentGroupId = this.props.currentGroupId;
		var isDisabled = this.props.conversationState !== 'open';

		return (
			<SplitButton id="transferSplitButton" bsSize="xsmall" title={this.props.intl.formatMessage({id:'dialogButtonRelease'})} disabled={isDisabled} onClick={this.handleClick} onSelect={this.handleSelect}>
			{this.props.groups.map(group => {

				let metaUser = group.numberOfUsers,
					metaVisitor = group.numberOfVisitors;

				return (
					<Dropdown.Item
						key={group.id}
						eventKey={group.id}
						disabled={group.numberOfUsers < 1}
						active={group.id === currentGroupId}
					>
						{group.name}
						<span className="item-meta">
							Users online: {metaUser}<br/>
							Visitors online: {metaVisitor}
						</span>
					</Dropdown.Item>
				)
			})}
			</SplitButton>
		)
	}
}));
