export default function(
	$http,
	SessionService,
	APIEndpointService,
	$q,
	APIConfigurationSectionService,
	VngageUtil,
	vngageConfig,
	calendarModuleConfig,
	Session,
	AccountSitesService,
	$window
) {
	'ngInject';
	var accountId = Session.account.id;
	var siteId = AccountSitesService.getSelectedSiteId();
	var service = {
		queryReservations: queryReservations,
		sendEmail: sendEmail,
		cancelReservation: cancelReservation,
		getConversation:getConversation
	};

	function queryReservations(params) {
		var r = {
			siteId: siteId,
			accountId: accountId,
			groupId: params.groupId,
			start: params.range.from.format(),
			end: params.range.to.format(),
			includeCancelled: false
		};
		return APIEndpointService.reservations.query(r).$promise;
	}

	function sendEmail(data) {
		var r = {
			siteId:siteId,
			accountId:accountId,
			groupId: data.groupId,
			hash:calendarModuleConfig.hash,
			reservationId: data.reservationId,
			userId: data.userId,
			userName: data.userName,
			ingress: data.ingress,
			message: data.message,
			allowReply: data.allowReply
		};
		return $http.post(calendarModuleConfig.host+'/api/sendEmail/',r);
	}

	function cancelReservation(data) {
		return $http.post(vngageConfig.restUrl + 'Reservations/Reservation/'+data.id+'/Cancel?message=' + $window.encodeURIComponent(data.message), JSON.stringify(data.message));
	}

	function getConversation(data, callBack) {
		/*
		var r = {
			siteId:siteId,
			accountId:accountId,
			groupId: calendarModuleConfig.defaultGroupId,
			hash:calendarModuleConfig.hash,
			reservationId: data.reservationId
		};
		var res = $http.post(calendarModuleConfig.host+'/api/getConversation/',data);
		//console.log(res);
		//callBack(res);*/
		var res = [];
		return $q(function(resolve, reject) {
			resolve(res);
			return res;
		});
	}
	return service;
};
