import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import ViewActions from  '../actions/ViewActions';
import { injectIntl } from 'react-intl';

export default injectIntl(createReactClass({

	getDefaultProps () {
		return {
			highlightMode : false,
			isScaled: true
		};
	},

	componentDidMount () {
		this.domScaleSwitch = ReactDOM.findDOMNode(this.refs.scaleSwitch);
	},

	onToggleHighlight () {
		ViewActions.toggleHighlight(this.props.conversationId, !this.props.highlightMode);
	},

	onToggleScaling () {
		ViewActions.toggleVisualGuidanceScale(this.props.conversationId, this.domScaleSwitch.checked);
	},

	onAskForDomUpload (){
		ViewActions.requestDomUpload(this.props.conversationId);
	},

	onTogglePause (){
		ViewActions.toggleVisualGuidancePause(this.props.conversationId, this.props.isPaused)
	},

	onSendLink () {
		ViewActions.visualGuidanceSendPageLink(this.props.conversationId);
	},

	render: function() {
		const pauseBtnLabel = this.props.intl.formatMessage({id: this.props.paused ? 'vgResumeBtnLabel' : 'vgPauseBtnLabel'});
		const pauseBtnTitle = this.props.intl.formatMessage({id: this.props.paused ? 'vgResumeBtnTitle' : 'vgPauseBtnTitle'});

		return (
			<div className="vg-toolbar">
				<span className="pull-right"><label className="flipswitch">
					<span className="flipswitch-label">{this.props.intl.formatMessage({id:'vgCoBrowseScale'})}</span>
					<input
						onChange={this.onToggleScaling}
						type="checkbox"
						ref="scaleSwitch"
						className="switch"
						defaultChecked={this.props.isScaled}
						/>
					<span className="switch"></span>
				</label></span>
				<button
					onClick={this.onTogglePause}
					className="coBrowserToggleBtn"
					title={pauseBtnTitle}
					style={ this.props.useDomCoBrowsing ? {display: 'none'} : {minWidth:'5em'}}
					>{pauseBtnLabel}</button>
				<button
					style={this.props.paused ? {display: ''} : {display: 'none'}}
					onClick={this.onSendLink}
					>{this.props.intl.formatMessage({id:'vgSendBtn'})}</button>
				<button
					style={{display:'none'}}
					>{this.props.intl.formatMessage({id:'vgHomeBtn'})}</button>
				<button
					onClick={this.onAskForDomUpload}
					disabled={this.props.paused}
					>{this.props.intl.formatMessage({id:'vgAskForDom'})}</button>
				<button
					onClick={this.onToggleHighlight}
					disabled={this.props.paused}
					className={this.props.highlightMode ? 'selected' : ''}
					>{this.props.intl.formatMessage({id:'vgHighlight'})}</button>
			</div>
		);
	}
}));
