import AppConstants from '../../../components/constants/AppConstants';

const Ui = AppConstants.Ui;
const ActionTypes = AppConstants.ActionTypes;

export default {

	setActiveSection(conversationId, section) {
		return {
			type: Ui.SET_ACTIVE_SECTION,
			conversationId,
			section
		};
	},

	removeActiveSection(conversationId, section) {
		return {
			type: Ui.REMOVE_ACTIVE_SECTION,
			conversationId,
			section
		};
	},

	setDebugFlag(flagName, value) {
		return {
			type: ActionTypes.SET_DEBUG_FLAG,
			flagName,
			value
		};
	},

	setAccountId(accountId) {
		return {
			type: ActionTypes.SET_ACCOUNT_ID,
			accountId
		};
	},

	setAccount(account) {
		return {
			type: ActionTypes.SET_ACCOUNT,
			account
		};
	},

	setVisitorSettings(visitorSettings) {
		return {
			type: ActionTypes.SET_VISITOR_SETTINGS,
			visitorSettings
		};
	},

	setUserId(userId) {
		return {
			type: ActionTypes.SET_USER_ID,
			userId
		};
	},

	cleanUpBeforeLeaving(netId) {
		return {
			type: ActionTypes.CLEAN_UP_BEFORE_LEAVING,
			netId
		};
	},

	processConversationDetails(details) {
		return {
			type: ActionTypes.PROCESS_CONVERSATION_DETAILS,
			details
		};
	},

	receiveAsState(conversationId, conversationState) {
		return {
			type: ActionTypes.RECEIVE_AS_STATE,
			conversationId,
			conversationState
		};
	},

	receiveSince(rawMessages, conversationId) {
		return {
			type: ActionTypes.RECEIVE_SINCE,
			rawMessages,
			conversationId
		};
	},

	closeCaseAndConversation(conversationId) {
		return {
			type: ActionTypes.CLOSE_CASE_AND_CONVERSATION,
			conversationId
		};
	},

	updateCaseType(conversationId, caseType) {
		return {
			type: ActionTypes.UPDATE_CASE_TYPE,
			conversationId,
			caseType
		};
	},

	addHistoryMarkers(conversationId, caseHistory) {
		return {
			type: ActionTypes.ADD_HISTORY_MARKERS,
			conversationId,
			caseHistory,
		};
	},

	expandHistoryMarker(conversationId, markerId, caseData) {
		return {
			type: ActionTypes.EXPAND_HISTORY_MARKER,
			conversationId,
			markerId,
			caseData,
		};
	},

	addLocalMessage(conversationId, msg, keepTimeStamp = false) {
		return {
			type: ActionTypes.ADD_LOCAL_MESSAGE,
			conversationId,
			msg,
			keepTimeStamp
		};
	},

	setCurrentMessage(conversationId, msg) {
		return {
			type: Ui.SET_CURRENT_MESSAGE,
			conversationId,
			msg
		};
	},

	setCurrentMessageAndShow(conversationId, msg) {
		return {
			type: Ui.SET_CURRENT_MESSAGE_AND_SHOW,
			conversationId,
			msg
		}
	},

	setCurrentNote(conversationId, noteText) {
		return {
			type: Ui.SET_CURRENT_NOTE,
			conversationId,
			noteText
		};
	},

	changeCase(conversationId, caseObj) {
		return {
			type: ActionTypes.CHANGE_CASE,
			conversationId,
			caseObj
		};
	},

	participantTyping(conversationId, isTyping) {
		return {
			type: ActionTypes.PARTICIPANT_TYPING,
			conversationId,
			isTyping
		}
	},

	setTextSize(textSize) {
		return {
			type: Ui.TEXT_SIZE,
			textSize
		};
	},

	toggleSystemMessages(visible) {
		return {
			type: Ui.TOGGLE_SYSTEM_MESSAGES,
			visible
		};
	},

	indicateMessageIsCopied(conversationId) {
		return {
			type: Ui.INDICATE_MESSAGE_COPIED,
			conversationId
		};
	},

	toggleNewCaseAfterClose(conversationId) {
		return {
			type: Ui.TOGGLE_NEW_CASE_AFTER_CLOSE,
			conversationId
		};
	},

	toggleEmailConversationTranscript(conversationId) {
		return {
			type: Ui.TOGGLE_EMAIL_CONVERSATION_TRANSCRIPT,
			conversationId
		};
	},

	setPendingRemoval(conversationId) {
		return {
			type: Ui.SET_PENDING_REMOVAL,
			conversationId
		};
	},

	setSelectedClosure(conversationId, selectedClosure) {
		return {
			type: Ui.SET_SELECTED_CLOSURE,
			conversationId,
			selectedClosure
		};
	},

	setForms(conversationId, forms) {
		return {
			type: ActionTypes.SET_FORMS,
			conversationId,
			forms
		};
	},

	toggleNoteMode(conversationId) {
		return {
			type: Ui.TOGGLE_NOTE_MODE,
			conversationId
		};
	},

	setCurrentInput(conversationId, currentInput) {
		return {
			type: Ui.SET_CURRENT_INPUT,
			conversationId,
			currentInput
		};
	},

	updateCoBroUrl(url, owner, updateType = 'navigation') {
		return {
			type: ActionTypes.UPDATE_COBRO_URL,
			url,
			owner,
			updateType
		};
	},

	setCoBroHighlightMode(isActive) {
		return {
			type: ActionTypes.SET_COBRO_HIGHLIGHT_MODE,
			isActive
		};
	},

	toggleCoBroScale() {
		return {
			type: ActionTypes.TOGGLE_COBRO_SCALE
		};
	},

	toggleCoBroPause() {
		return {
			type: ActionTypes.TOGGLE_COBRO_PAUSE
		};
	},

	setCoBroDomUploadStatus(status) {
		return {
			type: ActionTypes.SET_COBRO_DOMUPLOAD_STATUS,
			status
		};
	},

	toggleActiveInfoTip(infoType) {
		return {
			type: Ui.TOGGLE_ACTIVE_INFO_TIP,
			infoType
		}
	},

	hideInfoTip(infoType) {
		return {
			type: Ui.HIDE_INFO_TIP,
			infoType
		}
	},

	setFlowState(conversationId, flowState) {
		return {
			type: ActionTypes.SET_FLOW_STATE,
			conversationId,
			flowState
		}
	},

	startConversationFromQueue(conversationId, groupId, startY) {
		return {
			type: ActionTypes.START_CONVERSATION_FROM_QUEUE,
			conversationId,
			groupId,
			startY
		}
	},

	initRoutedConversation(conversationId) {
		return {
			type: ActionTypes.INIT_ROUTED_CONVERSATION,
			conversationId,
		}
	},

	startRoutedConversation(conversationId) {
		return {
			type: ActionTypes.START_ROUTED_CONVERSATION,
			conversationId,
		}
	},

	resumeConversation(conversationId) {
		return {
			type: ActionTypes.RESUME_CONVERSATION,
			conversationId,
		}
	},

	initAndStartRoutedConversation(conversationId) {
		return {
			type: ActionTypes.INIT_AND_START_ROUTED_CONVERSATION,
			conversationId,
		}
	},

	conversationReady(conversationId) {
		return {
			type: ActionTypes.CONVERSATION_READY,
			conversationId,
		}
	},

	removeConversation(conversationId) {
		return {
			type: ActionTypes.REMOVE_CONVERSATION,
			conversationId
		};
	},

	setConversationCapabilities(conversationId, capabilities) {
		return {
			type: ActionTypes.SET_CONVERSATION_CAPABILITIES,
			conversationId,
			capabilities
		};
	},

	updateLocalMessageStatus(conversationId, messageId, status) {
		return {
			type: ActionTypes.UPDATE_LOCAL_MESSAGE_STATUS,
			conversationId,
			messageId,
			status
		};
	},

	setConversationError(conversationId, latestError) {
		return {
			type: ActionTypes.SET_CONVERSATION_ERROR,
			conversationId,
			latestError,
		};
	},

}
