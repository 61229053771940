export default function(systemConfiguration, roleClaim, SystemRolesService, APIEndpointService, $state) {
	'ngInject';

	var vm = this;
	vm.roleClaim = angular.copy(roleClaim);
	vm.submit = submit;
	vm.roleClaim.dependsOn = vm.roleClaim.dependsOn || [];

	vm.formattedSelectableDependsOn = SystemRolesService.formatSelectableDependsOn(systemConfiguration.roleClaims, vm.roleClaim);

	function submit() {
		vm.isSaving = true;
		return APIEndpointService.systemConfRoleClaim.save({ id: vm.roleClaim.id }, { data: vm.roleClaim }).$promise.then(function(updatedRoleClaim) {
			SystemRolesService.replaceRoleClaim(systemConfiguration.roleClaims,updatedRoleClaim).finally(() => {
				vm.isSaving = false;
				$state.transitionTo($state.current, {
					id: vm.roleClaim.id,
					roleClaim: updatedRoleClaim
				});
			});
		}, err => {
			vm.isSaving = false;
			console.log('Error saving roleClaim (SystemRoleDependsController)');
		});
	}
};
