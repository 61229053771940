import AppDispatcher from '../dispatcher/CSDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import StoreFactory from '../../../components/utils/StoreFactory';

const ProfileActions = AppConstants.Profile;

var _profile = {};

var ProfileStore = StoreFactory.create({

	/**
	 * return profile
	 * @returns {Object} profile
	 */
	getProfile () {
		return _profile;
	},

	/**
	 * return id of profile
	 * @returns {String} id
	 */
	getProfileId () {
		return _profile.id;
	}

});

const registeredCallback = function(payload) {
	const action = payload.action;
	const profile = action.profile;

	switch (action.type) {
		case ProfileActions.REQUEST_PROFILE:
			_profile = {};
			ProfileStore.emitChange();
			break;

		case ProfileActions.REQUEST_PROFILE_SUCCESS:
			_profile = profile;
			ProfileStore.emitChange();
			break;

		case ProfileActions.REQUEST_PROFILE_ERROR:
			_profile = {};
			ProfileStore.emitChange();
			break;
	}
};

ProfileStore.dispatchToken = AppDispatcher.register( registeredCallback );

export default ProfileStore;
