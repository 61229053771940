var DOM = {
	//**psDOM** is a helper library for common DOM operations.
	//##createElement
	// helps instantiate an element
	//
	//     var el=psDOM.createElement('div','hello world',
	//         {id:'greeting'},
	//		   {click:function(){alert('hi!');}}
	//     );
	createElement: function (tag, innerHTML, attributes, eventListeners) {
		var el = document.createElement(tag);
		for (var attributeName in attributes) {
			el.setAttribute(attributeName, attributes[attributeName]);
		}
		for (var eventName in eventListeners) {
			el.addEventListener(eventName, eventListeners[eventName], false);
		}
		if (typeof innerHTML == 'string') el.innerHTML = innerHTML;
		return el;
	},
	//##createElementWrapping
	createElementWrapping: function (tag, attributes, events, children) {
		var el = document.createElement(tag);
		if (events) {
			for (var key in events) {
				el.addEventListener(key, events[key], false);
			}
		}
		;
		if (attributes) {
			for (var key in attributes) {
				el.setAttribute(key, attributes[key]);
			}
		}
		if (children && children.length) {
			children.forEach(function (child) {
				el.appendChild(child);
			});
		}
		return el;
	},
	//##_createComponentFragment
	//prerolled elements we use regularly
	_createComponentFragment: function (componentName, innerHTML, attributes, eventListeners) {
		var extend = psPlugin.core.jsExt.extendObj;
		var componentPrototypes = {
			button: {tag: 'input', attributes: {'type': 'button'}},
			checkbox: {tag: 'input', attributes: {'type': 'checkbox'}},
			fieldset: {tag: 'fieldset'},
			menu: {tag: 'nav'},
			panel: {tag: 'section'},
			password: {tag: 'input', attributes: {type: 'password'}},
			radio: {tag: 'input', attributes: {'type': 'radio'}},
			text: {tag: 'input', attributes: {'type': 'text'}}
		};
		var component = componentPrototypes[componentName];
		var tag = componentName;
		if (component) {
			attributes = extend(attributes || {}, component.attributes || {});
			attributes['data-ui'] = componentName;
			eventListeners = extend(eventListeners || {}, component.eventListeners || {});
			tag = component.tag;
		} else {
			/*console.info('not preset');*/
		}
		return DOM.createElement(tag, innerHTML, attributes, eventListeners);
	}
};

export default DOM;
