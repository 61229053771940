import React from 'react';
import SpeakerTitle from '../../../components/SpeakerTitle.react';
import { injectIntl } from 'react-intl';
import createReactClass from 'create-react-class';

export default injectIntl(createReactClass({

	render () {
		let message = this.props.message;
		let speaker = this.props.speaker;
		if (!message.message) {
			message.message = '';
		}

		return (
			<div className="flex-container">
				<div className="message-note">
					<div>
						<SpeakerTitle name={speaker.name} title={speaker.titleId} isMe={speaker.isCurrentUser}/>
					</div>
					<span> {this.props.intl.formatMessage({id: 'msgNoted'})} {message.message}</span>
				</div>
			</div>
		);
	}
}));
