import TranslationAddModalTemplate from './TranslationAddModalTemplate';

export default function (translationList, sysTranslations, APIEndpointService, $uibModal, $state) {
	'ngInject';

	var vm = this;
	vm.translationList = translationList;

	vm.tableOptions = {
		stateLink: {
			state: 'root.account.translation.edit',
			property: 'data.id'
		},
		createItem: {
			langKey: 'translation.ADD_HEADER',
			method: addNew
		}
	};
	vm.tableColumns = [{
		header: 'Name',
		property: 'data.name',
		sortDefault: true,
		useInFilter: true
	}, {
		header: 'GUID',
		property: 'data.id',
		hidden: true,
		useInFilter: true
	}];

	function openAddModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: TranslationAddModalTemplate,
			controller: 'TranslationAddModalController as vm',
			resolve: {
				translations: () => {
					return translationList;
				},
				systemTranslations: () => {
					return sysTranslations;
				}
			}
		});
		modal.result.then(newTranslation => {
			translationList.push(newTranslation);
			$state.go('.edit', {id: newTranslation.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		$state.go('root.account.translation').then(openAddModal);
	}
};
