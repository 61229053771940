export default function($uibModalInstance, APIConfigurationSectionService, options) {
    'ngInject';

    var vm = this;
    vm.options = options;
    vm.saveNew = saveNew;
    vm.cancelNew = $uibModalInstance.dismiss;

    function saveNew() {
        var type = APIConfigurationSectionService.sectionMap.actionPanel;
        vm.isSaving = true;
		$uibModalInstance.freeze(true);

        APIConfigurationSectionService.create(vm.newData, type, true)
            .then(function(res) {
				$uibModalInstance.close(res);
            })
            .catch(function() {
				$uibModalInstance.freeze(false);
                vm.isSaving = false;
            });
    }
};
