import _cloneDeep from 'lodash/cloneDeep';

export default function (APIEndpointService, $q) {
	'ngInject';

	const service = {
		getConfigurationSetContext: getConfigurationSetContext,
		getActivateContext: getActivateContext
	};

	function getConfigurationSetContext() {

		return $q((resolve, reject) => {
			let resolved = {
				configurationDependencies: null,
				dependencies: {}
			};

			$q.all({
				configurationSections: APIEndpointService.configurationSection.query({limit: 10000}).$promise,
				groups: APIEndpointService.group.query().$promise,
				pointOfInterests: APIEndpointService.pointOfInterest.query().$promise,
				scripts: APIEndpointService.script.query().$promise,
				sites: APIEndpointService.site.query().$promise,
				translations: APIEndpointService.translation.query().$promise,

				accountConfiguration: APIEndpointService.accountCurrentConfiguration.get().$promise,
				users: APIEndpointService.user.query({limit: 10000}).$promise
			}).then(res => {
				resolved = {
					configurationDependencies: {
						configurationSections: res.configurationSections,
						groups: res.groups,
						pointOfInterests: res.pointOfInterests,
						scripts: res.scripts,
						sites: res.sites,
						translations: res.translations
					},
					dependencies: {
						accountConfiguration: res.accountConfiguration,
						users: res.users
					}
				};

				let id = resolved.dependencies.accountConfiguration.data.liveConfigurationSetId;
				const defaultLiveId = 'master'; // TODO using a magic string is not very good, if API changes this will break

				return $q(resolve => {
					// If no configurationSet exists in the system, create "master", assign it as the
					// liveConfigurationSet in the accountConfiguration data, and then proceed
					// to the publish page. This applies to newly created accounts not based on a template
					if (!id) {
						// New account
						resolved.dependencies.accountConfiguration.data.liveConfigurationSetId = defaultLiveId;
						$q.all([
							APIEndpointService.configurationSet.create({id: defaultLiveId}),
							APIEndpointService.accountCurrentConfiguration.save(null, resolved.dependencies.accountConfiguration.data).$promise
						]).then(() => {
							APIEndpointService.configurationSet.get({id: defaultLiveId}).$promise.then(res => {
								resolved.dependencies.configurationSet = res;
								resolve('new');
							});
						});
					} else {
						APIEndpointService.configurationSet.tryGet({id: id}).$promise
							.then(res => {
								// Existing account, configurationSet exists
								resolved.dependencies.configurationSet = res;
								resolve();
							}, () => {
								// New account created from a template account. Since configurationSets aren't copied
								// We need to create a new one first and set the liveConfigurationSetIdRevision to 0
								resolved.dependencies.accountConfiguration.data.liveConfigurationSetIdRevision = 0;
								$q.all({
									accountConfiguration: APIEndpointService.accountCurrentConfiguration.save(null, resolved.dependencies.accountConfiguration.data).$promise,
									configurationSet: APIEndpointService.configurationSet.create({id: defaultLiveId})
								}).then(res => {
									resolved.dependencies.configurationSet = res.configurationSet;
									resolve();
								});
							});
					}
				}).then(res => {
					if (res === 'new') {
						id = defaultLiveId;
					}
					return APIEndpointService.configurationSetResolved.get({id: id}).$promise;
				});
			}).then(res => {
				resolved.dependencies.configurationSetResolved = res;
				resolve(resolved);
			}, res => {
				reject(res);
			});
		});
	}

	function getActivateContext(dependencies = {}) {
		return $q((resolve, reject) => {
			// Only fetch accountCurrentConfiguration if not already present in dependencies.accountConfiguration...
			const accountConfigPromise = (
				dependencies.accountConfiguration && dependencies.accountConfiguration.$promise ?
					dependencies.accountConfiguration.$promise
					:
					APIEndpointService.accountCurrentConfiguration.get().$promise
			);

			accountConfigPromise.then(res => {
				const accountConfiguration = res;
				const id = accountConfiguration.data.liveConfigurationSetId;
				const revision = accountConfiguration.data.liveConfigurationSetIdRevision;

				return $q.all({
					accountConfiguration: accountConfiguration,
					liveRevisionSet: APIEndpointService.configurationSet.get({ id: id, revision: revision }).$promise,
					liveRevisionSetResolved: APIEndpointService.configurationSetResolved.get({ id: id, revision: revision }).$promise,
					revisionSet: dependencies.configurationSet || APIEndpointService.configurationSet.get({id: id}).$promise,
					revisionSetResolved: dependencies.configurationSetResolved || APIEndpointService.configurationSetResolved.get({id: id}).$promise
				});
			}).then(res => {
				resolve({
					accountConfiguration: res.accountConfiguration,
					liveRevisionSet: res.liveRevisionSet,
					liveRevisionSetResolved: res.liveRevisionSetResolved,
					revisionSet: res.revisionSet,
					revisionSetResolved: res.revisionSetResolved,
					newestRevision: _cloneDeep(res.revisionSet) // This used to be fetched in a separate, identical query. Not sure if object(s) are mutated somewhere in the Publish-view mess, so clone it to be safe...
				});
			}, res => {
				reject(res);
			});
		});
	}

	return service;
}
