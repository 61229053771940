import _isObject from 'lodash/isObject';
import _isUndefined from 'lodash/isUndefined';

export default function () {
	'ngInject';

	return {
		restrict: 'E',
		scope: {
			state: '=',
			stateOptions: '='
		},
		replace: true,
		template: '<span class="badge badge-{{type}}">{{text | translate}}</span>',
		link: function (scope, element, attrs) {
			scope.$watch('state', function () {
				var item;

				if (_isObject(scope.stateOptions)) {
					item = scope.stateOptions[scope.state];
					scope.type = item.type;
					scope.text = item.text;
				} else if (_isUndefined(scope.state)) {
					scope.type = attrs.type || 'info';
					scope.text = attrs.text || '';
				} else if (scope.state) {
					scope.type = attrs.onType || 'success';
					scope.text = attrs.on || 'uiElement.ACTIVE';
				} else {
					scope.type = attrs.offType || 'warning';
					scope.text = attrs.off || 'uiElement.INACTIVE';
				}
			});
		}
	};

};
