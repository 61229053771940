export default {
	name: 'Default settings',
	section: {
		displayVisitorProfile: false,
		displayCaseHistory: true,
		deactivatePhoneToPs: true,
		notificationOnProactive: false,
		PDFcapture: true,
		alwaysMailToThisEmailOnDialogClose: '',
		chatMessagePendingTimeoutForVisitor: 10,
		coBrowserRestricted: [],
		coBrowserDoNotDisturb: [],
		enableVideoChat: true,
		enableWebCall: false,
		hideQueue: false,
		notificationDuration: 0,
		notificationOnChatMessages: true,
		oldSoundPlayer: false,
		muteDesktopAudio: false,
		displayRelativeDialogTime: true,
		enableConversationHistory: false,
		coBrowsingStartUrl: '',
	}
};
