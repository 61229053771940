import immutable from 'immutable';
import INITIAL_STATE from '../../INITIAL_STATE';
import FlowStates from '../../constants/FlowStates'
import VisitorProfileSelectors from '../../selectors/VisitorProfileSelectors'

export default {

	getConversationIds(plugin, state) {
		return state.get('panelOrder')
			.filter(conversationId => {
				const groupId = state.getIn(['conversations', conversationId, 'groupId']) || 'wrongGroup';
				return plugin.get('enabledGroups').contains(groupId)
			});
	},

	getOpenConversationIds(plugin, state) {
		const conversations = state.get('conversations');
		return state.get('panelOrder')
			.filter(conversationId => {
				const conversation = conversations.get(conversationId);

				const ready = conversation.get('flowState') === FlowStates.READY;
				const isOpen = conversation.get('conversationStatus') === 'open';

				const groupId = conversation.get('groupId') || 'wrongGroup';
				const groupEnabled = plugin.get('enabledGroups').contains(groupId);

				return ready && groupEnabled && isOpen;
			});
	},

	isConversationIdValid(plugin, state, conversationId) {
		const conversation = state.getIn(['conversations', conversationId]);
		if (!conversation) {
			return false;
		}
		const ready = conversation.get('flowState') === FlowStates.READY;
		const isOpen = conversation.get('conversationStatus') === 'open';
		const isInPanelOrder = state.get('panelOrder').contains(conversationId);

		const groupId = conversation.get('groupId') || 'wrongGroup';
		const groupEnabled = plugin.get('enabledGroups').contains(groupId);
		return ready && isOpen && isInPanelOrder && groupEnabled;
	},

	// tested in chatMessages-spec.js
	getMessageSub(conversationId, prevMessages, currentMessages, messageType = 'chat', type = 'chatMessages') {

		const oldLength = (prevMessages || immutable.List()).size;
		const newLength = currentMessages.size;
		if (newLength <= oldLength) {
			return immutable.List();
		}
		const sub = immutable.Map({
			type,
			conversationId,
			state: oldLength === 0 ? 'new': 'update',
			messages: currentMessages
				.slice(oldLength, newLength)
				.filter(message => message.get('messageType') === messageType)
				.map(message => immutable.fromJS({
					message: message.get('message'),
					speaker: message.getIn(['speaker', 'visitId']) ? 'visitor': 'agent',
					createdAt: message.get('createdAt')
				}))
		});
		if (sub.get('messages').size === 0) {
			return immutable.List();
		}
		return immutable.List().push(sub);

	},

	getVisitorProfileClaims(visitorProfile) {
		return VisitorProfileSelectors.getClaimFieldsForPlugin(visitorProfile);
	}

}

