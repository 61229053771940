export default {
	name: 'Default settings',
	section: {
		initialState: 'undocked',
		nonResponsiveMobile: false,
		shouldAutoHide: false,
		stateBlacklist: '',
		offsetTop: '',
		resizeObject: '',
		point: '',
		supportImage: '',
		defaultAgentPicture: '',
		poweredByLabel: 'POWERED BY PUZZEL',
		poweredByURL: ''
	}
};
