export default {
    'en-GB': {
        user: {
            ADD_HEADER: 'New user',
            LIST_PAGE_HEADER: 'Users',
            EDIT_HEADER: 'Edit user',
            LIST_HEADER: 'List of users for this account',
			LIST_DESCRIPTION: 'All users are global (on account level) and are available for sites and groups. A new user account must first be activated to be valid.',
			LIST_DESCRIPTION_IMPORTANT: 'Important: After creating a user, click the "Send activation link"-tab to send an email to the user with instructions to activate the user account',
            EMAIL: 'E-mail',
            PROFILE_PICTURE: 'Profile picture',
            TIME_ZONE: 'Time zone',
            STATUS: 'Account in VEP',
            LANGUAGE: 'Workspace language',
            NAME: 'Name',
            DISPLAY_NAME: 'Public display name',
            SEND_ACTIVATION_EMAIL: 'Send activation e-mail',
			SEND_ACTIVATION_EMAIL_TITLE: 'IDP identity for this user account',
			SEND_ACTIVATION_EMAIL_DESCRIPTION: 'Before the new user account is valid you need to send an activation e-mail to the user. The e-mail contains a link to the IDP where the User is able to activate the account',
            ACTIVATED: 'Account in IDP',

			ROLES_MULTISELECT_TITLE:'Select roles to this user account',
			GROUPS_MULTISELECT_TITLE:'Choose what groups this member should be assigned to',

            STATE_ACTIVE: 'active',
            STATE_SUSPENDED: 'suspended',
            STATE_BLOCKED: 'blocked',

            ACTIVATED_TRUE: 'true',
            ACTIVATED_FALSE: 'false',
			LAST_SIGNED_IN: 'Last signed in',
			IMPORT_HEADER: 'Import users from a file',
			EXTERNAL_IDS_TITLE: 'External Ids',
			EXTERNAL_IDS_DESC: 'Requires JSON syntax',
		}
	},
	'sv-SE': {
		user: {
			ADD_HEADER: 'New user',
			LIST_PAGE_HEADER: 'Users',
			EDIT_HEADER: 'Edit user',
			LIST_HEADER: 'List of users for this account',
			LIST_DESCRIPTION: 'All users are global (on account level) and are available for sites and groups. A new user account must first be activated to be valid.',
			LIST_DESCRIPTION_IMPORTANT: 'Important: After creating a user, click the "Send activation link"-tab to send an email to the user with instructions on how to activate the user account',
			EMAIL: 'E-mail',
			PROFILE_PICTURE: 'Profile picture',
			TIME_ZONE: 'Time zone',
			STATUS: 'Account in VEP',
			LANGUAGE: 'Workspace language',
			NAME: 'Name',
			DISPLAY_NAME: 'Public display name',
			SEND_ACTIVATION_EMAIL: 'Send activation e-mail',
			SEND_ACTIVATION_EMAIL_TITLE: 'IDP identity for this user account',
			SEND_ACTIVATION_EMAIL_DESCRIPTION: 'Before the new user account is valid you need to send an activation e-mail to the user. The e-mail contains a link to the IDP where the User is able to activate the account',
			ACTIVATED: 'Account in IDP',

			ROLES_MULTISELECT_TITLE:'Select roles to this user account',
			GROUPS_MULTISELECT_TITLE:'Choose what groups this member should be assigned to',

			STATE_ACTIVE: 'active',
			STATE_SUSPENDED: 'suspended',
			STATE_BLOCKED: 'blocked',

			ACTIVATED_TRUE: 'true',
			ACTIVATED_FALSE: 'false',
			LAST_SIGNED_IN: 'Senast inloggad',
			IMPORT_HEADER: 'Import users from a file',

			EXTERNAL_IDS_TITLE: 'External Ids',
			EXTERNAL_IDS_DESC: 'Requires JSON syntax',
		}
	}
};
