import StatisticsMenuTemplate from './StatisticsMenuTemplate';
import StatisticsContentTemplate from './StatisticsContentTemplate';
import StatisticsTranslations from './StatisticsTranslations';

import './export/StatisticsExportModule';
import './dynamic/DynamicStatisticsModule';

export default angular.module('vngageApp.statistics', [
	'ui.router',
	'vngageApp.statistics.export',
	'vngageApp.statistics.dynamic'
])

	.constant('StatisticsModuleConfig', {
		state: 'root.statistics',
		access: 'Statistics'
	})

	.config(function ($stateProvider, $urlRouterProvider, StatisticsModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';

		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(StatisticsTranslations);

		$urlRouterProvider.when('/statistics/', function (transitionService, DynamicStatisticsModuleConfig, StatisticsExportModuleConfig) {
			'ngInject';
			// [ TL ] Use "transitionService.navigate" to pick a default view when navigating to "/statistics/"
			transitionService.navigate([DynamicStatisticsModuleConfig, StatisticsExportModuleConfig]);
		});

		$stateProvider.state(StatisticsModuleConfig.state, {
			url: '/statistics/',
			views: {
				content: {
					templateUrl: StatisticsContentTemplate
				},
				menu: {
					controller: function ($scope, $rootScope, DynamicStatisticsConfig) {
						'ngInject';
						// Add a simple controller to dynamically generate the menu items for the authorized statistics v2 views
						$scope.authorizedViews = DynamicStatisticsConfig.availableViews().filter(view => {
							return $rootScope.authorize(view.access);
						})
					},
					templateUrl: StatisticsMenuTemplate
				}
			},
			data: {
				access: StatisticsModuleConfig.access,
				ncyBreadcrumbLabel: 'Statistics2'
			}
		});
	})
