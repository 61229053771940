import AppConstants from './../../../components/constants/AppConstants';
import { Dispatcher } from 'flux';
import assign from 'object-assign';

const PayloadSources = AppConstants.PayloadSources;

var AppDispatcher = assign(new Dispatcher(), {

	/**
	 * @param {object} action The details of the action, including the action's
	 * type and additional data coming from the server.
	 */
	handleServerAction (action) {
		let payload = {
			source: PayloadSources.SERVER_ACTION,
			action: action
		};
		this.dispatch(payload);
	},

	/**
	 * @param {object} action The details of the action, including the action's
	 * type and additional data coming from the server.
	 */
	handleConnectionAction (action) {
		let payload = {
			source: PayloadSources.CONNECTION_ACTION,
			action: action
		};
		this.dispatch(payload);
	},

	/**
	 * @param {object} action The details of the action, including the action's
	 * type and additional data coming from the view.
	 */
	handleViewAction (action) {
		let payload = {
			source: PayloadSources.VIEW_ACTION,
			action: action
		};
		this.dispatch(payload);
	}

});

export default AppDispatcher;
