export default function (account, APIEndpointService, NotificationService) {
	'ngInject';

	var vm = this;
	vm.account = account;
	vm.submit = submit;
	vm.createSandbox = createSandbox;
	vm.sandbox = {};

	function createSandbox() {
		vm.updatingSandbox = true;
		APIEndpointService.sandbox.tryGet().$promise
			.then(function (res) {
				vm.sandbox = res;
			})
			.finally(function () {
				vm.updatingSandbox = false;
			})
			.catch(function (error) {
				NotificationService.error({
					body: error.statusText,
					header: 'Updating Sandbox failed'
				});
			});
	}

	function submit() {
		vm.account.$save();
	}
};
