import React, {useState} from 'react';
import {
	Modal,
	Button,
} from 'react-bootstrap';

const DebugRequestsModal = (props) => {
	const [categories, setCategories] = useState(props.data.reduce((obj, action) => ({
		...obj,
		[action.name]: true
	}), {}));

	const onClickItem = (action) => {
		const str = `${action.name} pos:${action.index} responseTime:${action.responseTime} time:${new Date(action.timestamp).toISOString()}`;
		console.log('%c' + str, 'font-weight: bold;');
		console.log('params:', action.requestParams);
		if (action.status !== 'ok') {
			console.log('error:', action.status);
		}
	};

	const selectAllOrNone = (state) => setCategories(Object.keys(categories).reduce((obj, cat) => ({
		...obj,
		[cat]: state
	}), {}));

	const filteredList = props.data.filter(action => categories[action.name]);

	const times = filteredList.map(action => action.responseTime);
	const n = times.length;
	const mean = Math.round(times.reduce((sum, value) => sum + value, 0) / n);
	const min = Math.min(...times);
	const max = Math.max(...times);
	const median = [...times].sort((a, b) => a - b)[Math.floor(n / 2)];
	const std = Math.sqrt(times.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / n) || Infinity;
	const colors = [
		'#000000',
		'#a00000',
		'#c00000',
		'#ff0000',
	];


	const requests = filteredList.map(action => {
		const t = action.responseTime;
		const stdNr = Math.floor(Math.abs((t - mean) / std));
		const colorIndex = Math.min(colors.length - 1, stdNr);
		const hasError = action.status !== 'ok';
		const color = hasError
			? colors[colors.length - 1]
			: colors[colorIndex];

		return <a
			href="#"
			key={action.index}
			style={{color}}
			onClick={() => onClickItem(action)}>
			{action.status === 'ok'
				? t + ', '
				: <b>{t + ', '}</b>
			}
		</a>;
	});

	const catergoryButtons = Object.keys(categories).map(key => {
		const variant = categories[key] ? 'primary': 'outline-secondary';
		return (
			<Button
				key={key}
				className="btn"
				variant={variant}
				size="xs"
				onClick={() => setCategories(cats => ({...cats, [key]: !cats[key]}))}>
				{key}
			</Button>
		);
	});


	return (
		<Modal className="case-manager-section-modal" show={true}
			   onHide={() => props.hideModal()}>
			<Modal.Header closeButton>
				<Modal.Title>Request stats</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Button
					className="btn"
					variant="success"
					size="xs"
					onClick={() => selectAllOrNone(true)}>
					All
				</Button>
				<Button
					className="btn"
					variant="success"
					size="xs"
					onClick={() => selectAllOrNone(false)}>
					None
				</Button>
				{catergoryButtons}
				<h3>Request times</h3>
				<div style={{marginBottom: '10px'}}>
					<div style={{display: 'inline'}}>
						{requests}
					</div>
				</div>
				<h3>Min: {min}</h3>
				<h3>Max: {max}</h3>
				<h3>Mean: {mean}</h3>
				<h3>Median: {median}</h3>
				<h3>Standard deviation: {Math.round(std)}</h3>

			</Modal.Body>
			<Modal.Footer>
				<Button
					onClick={() => props.setPois(filteredList.map(action => action.index))}>save to pois</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DebugRequestsModal;



