import _filter from 'lodash/filter';
import uiView from './ui/view';
import ViewActions from '../actions/ViewActions';
import moment from 'moment-timezone';

//TODO: add legacy event for presence.
var IdentityService, DesktopService;


function formatDuration(seconds) {
	function pad(n) {
		return (n > 9) ? n: '0' + n;
	}

	var h = Math.floor(seconds / 3600),
		m = Math.floor(seconds / 60) % 60,
		s = Math.floor(seconds) % 60;

	return (h > 0 ? pad(h) + ':': '') + pad(m) + ':' + pad(s);
}

function parseMessage(message) {
	const re = /<%(.+?)%>/g;
	let cursor = 0,
		title = '',
		meetingStartTime = '',
		match;

	const extractTitle = function(line) {
		title += line != '' ? line.replace(/"/g, '\\"') : '';
		title = (title).replace(/[\r\t\n]/g, ' ').trim();
	};

	while (match = re.exec(message)) {
		meetingStartTime = match[1];
		cursor = match.index + match[0].length;
	}
	extractTitle(message ? message.substr(cursor, message.length - cursor) : '');

	return {
		title: title,
		meetingStartTime: meetingStartTime
	};
};

var queuePanel = {
	queue: {},
	proactive: {},
	viewElem: null,
	proactiveElem: null,

	init: function(_IdentityService, _DesktopService){
		IdentityService = _IdentityService;
		DesktopService = _DesktopService;
	},

	load: function () {
		var layout;

		if (!psPlugin.shell.pageLayout.onlineList){
			return;
		}

		if (!this.viewElem){
			this.viewElem = this.listView();
		}

		layout = this.viewElem;

		psPlugin.shell.pageLayout.onlineList.elem.appendChild(layout.wrapper);
		psPlugin.application.events.onQueueStatusUpdated.subscribe('queueStatus', function(status){
			updateQueueStatus(status, layout);
		});

		psPlugin.application.events.onInterval.subscribe('queueStatus',function () {
			psPlugin.application.commands.getQueueStatus.execute(function (status) {
				if (typeof status === 'object') {
					psPlugin.application.events.onQueueStatusUpdated.trigger(status);
				}
			});
		});

		// Listen to queue
		psPlugin.application.events.onQueueList.subscribe('loadModules', (function (list) {
			queuePanel.handleVisitors(list, 'queue');
		}));

		// Listen to proactive list
		psPlugin.application.events.onProactiveList.subscribe('loadModules', (function (list) {
			queuePanel.handleVisitors(list, 'proactive');
			queuePanel.render.sort();
		}));


	},

	render: {
		paused: false,
		sort: function () {
			if (!this.paused) {
				this.proactive();
				this.queue();
			}
		},

		queue: function () {
			var sortableArr = [];
			for (var n in queuePanel.queue) {
				var visitor = psPlugin.application.visitorHandler.visitors[n];
				if (visitor && visitor.properties.memberOf === 'lockedVisitors') {
					queuePanel.removeLockedVisitor(n);
				} else if (visitor) {
					var node = queuePanel.queue[n];
					if (typeof node != 'function') {
						if (typeof node.elem != 'undefined') {
							var visitorTransferred = visitor.properties.isTransferred === true,
								visitorLostDialog = visitor.properties.lostDialog === true;
							if (visitor && (visitorTransferred || visitorLostDialog)) {
								sortableArr.unshift(node);
							} else {
								sortableArr.push(node);
							}
						}
					}
				}
			}
			var i = 0;
			while (i < sortableArr.length) {
				var elem = sortableArr[i].elem;
				queuePanel.placements[i](elem);
				i++;
			}
		},
		proactive: function () {
			var sortableArr = [];
			for (var n in queuePanel.proactive) {
				var node = queuePanel.proactive[n];
				if (typeof node != 'function') {
					if (typeof node.elem != 'undefined') {
						sortableArr.push(node);
					}
				}
			}
			try {
				sortableArr = sortableArr.sort(this.comparePoints);
				var i = 0;
				while (i < sortableArr.length) {
					var elem = sortableArr[i].elem;
					//console.log(elem);
					queuePanel.placements[i](elem);
					i++;
				}
			} catch (err) {
				console.error(err);
			}
		},
		comparePoints: function (a, b) {
			return b.getScore() - a.getScore();
		}
	},

	/*
	 * --------------------------------------------------
	 * On event trigger
	 * --------------------------------------------------
	 */
	handleVisitors (list, listType) {
		// loop visitor list and assign view to visitor
		var doQueueListUpdate = false,
			doProactiveListUpdate = false;

		for (var i in list) {
			if (typeof this[listType][list[i]] === 'undefined') {
				this[listType][list[i]] = new this.item({
					id: list[i],
					parent: listType
				});
				if (listType !== 'proactive') {
					doQueueListUpdate = true;
				} else {
					doProactiveListUpdate = true;
				}
			}
		}

		if (doQueueListUpdate) {
			psPlugin.application.events.onQueueListUpdated.trigger(list);
		}
		if (doProactiveListUpdate) {
			psPlugin.application.events.onProactiveQueueListUpdated.trigger(list);
		}
	},


	removeLockedVisitor (id) {
		if (this.queue[id]) {
			this.queue[id].suspend();
		}
	},

	listView () {
		// parent element
		var parent = psPlugin.shell.pageLayout.onlineList.elem;
		// visitor reference
		var layout = uiView.createNodes({
			reference: 'wrapper',
			tag: 'div',
			className: 'onlineListWrapper',
			children: [{
				reference: 'queueWrapper',
				tag: 'div',
				className: 'onlineListQueueWrapper',
				children: [{
					reference: 'queueStatusHeader',
					tag: 'div',
					className: 'queueStatusHeader',
					innerHTML: ''
				}, {
					reference: 'queueStatusPopup',
					tag: 'div',
					className: 'queueStatusPopup',
					innerHTML: ''
				}, {
					reference: 'queueHeader',
					tag: 'div',
					className: 'onlineListHeader',
					children: [{
						reference: 'queueSettings',
						tag: 'button',
						className: 'btn btn-groupstatus legacy-tip legacy-tip-right',
						tip: psPlugin.application.writeOutLanguage('QueuePanelGroupStatusOverview'),
						innerHTML: '<i class="vngage-icon vngage-icon-settings"></i>'
					}, {
						reference: 'btnMeetingCode',
						tag: 'button',
						className: 'btn btn-meeting-code legacy-tip legacy-tip-right',
						innerHTML: '<i class="icon-meeting-code"></i>',
						tip: psPlugin.application.writeOutLanguage('meetingCodeGenerate')

					}, {
						reference: 'queueStatus',
						tag: 'span',
						className: '',
						innerHTML: ''
					}]
				}, {
					reference: 'queue',
					tag: 'div',
					className: 'onlineListQueueList'
				}]
			}, {
				reference: 'proactiveWrapper',
				tag: 'div',
				className: 'onlineListProactiveWrapper',
				children: [{
					reference: 'proactiveHeader',
					tag: 'div',
					className: 'onlineListHeader',
					innerHTML: psPlugin.application.writeOutLanguage('queuePanelProactive')
				}, {
					reference: 'proactive',
					tag: 'div',
					className: 'onlineListProactiveList'
				}]
			}]
		});

		layout.queueHeader.onclick = function () {
			layout.queueStatusPopup.style.display = (layout.queueStatusPopup.style.display === 'block') ? 'none': 'block';
		};


		layout.queueStatus.innerHTML = '<span class="header-visitors"><strong class="visitors-count">&nbsp;</strong>' + psPlugin.application.writeOutLanguage('queueStatusVisitorsInQueue') + '</span>' +
		'<span class="header-agents"><strong class="agents-count">&nbsp;</strong>' + psPlugin.application.writeOutLanguage('queueStatusAgentsOnline') + '</span>';

		var visitorsCountEl,
			agentsCountEl;

		psPlugin.application.configuration.user.availableForGroup = {};


		//var hasRun = false;

		parent.appendChild(layout.wrapper);

		layout.btnMeetingCode.setAttribute('data-tip', 'Generate meeting code');// data-placement="bottom" title="Tooltip on bottom")

		//hide groupStatus menu when onlineList is collapsed
		psPlugin.shell.pageLayout.onlineList.conf.onCollapse = function () {
			layout.queueStatusPopup.style.display = 'none';
		};


		layout.btnMeetingCode.addEventListener('click', function (e) {
			e.preventDefault();
			e.stopPropagation();
			ViewActions.askForStartCode();
		}, false);

		return layout;
	},

	placements: {
		0 (elem) {
			elem.style.zIndex = '6';
			elem.style.top = '0px';
		},
		1 (elem) {
			elem.style.zIndex = '5';
			elem.style.top = '66px';
		},
		2 (elem) {
			elem.style.zIndex = '4';
			elem.style.top = '132px';

		},
		3 (elem) {
			elem.style.zIndex = '3';
			elem.style.top = '198px';
		},
		4 (elem) {
			elem.style.zIndex = '2';
			elem.style.top = '264px';
		},
		5 (elem) {
			elem.style.zIndex = '1';
			elem.style.top = '330px';
		}
	},

	item (params) {
		// parent element
		//var parent = queuePanel.viewElem.queue;
		var parent = queuePanel.viewElem[params.parent];
		// visitor reference
		var visitor = psPlugin.application.visitorHandler.visitors[params.id];
		// console.log('ITEM!',params, visitor)

		// actions: null
		// approvalStatus: 0
		// chatMessages: null
		// connectionState: 2
		// initialState: "Proactive"
		// isIdentified: "true"
		// joinedQueue: 1354777963150
		// langID: null
		// langId: "FC2F75CA-4086-444C-B6D4-0A2FEBCB468C"
		// lostDialog: "false"
		// memberOf: "proactives"
		// pageVisits: Array[1]
		// psVisitorId: null
		// queueData: Object
		// score: 0
		// sessionId: "32D47E0E-86F7-4181-84CE-8F081866A784"
		// siteEntranceTime: null
		// siteId: "4EEC4CF0-8929-4ECF-8B0C-7938044CAD94"
		// DEPRECATED: timeStampSessionStart: 1354777963150
		// toGroup: "B305306F-5E26-4AE2-8F9A-E2709C7EBE00"
		// vId: "C36BE770-36EA-4DCB-A7AA-AE35F51400DB"
		// visitingPageNow: null
		// timeSinceQueueEntry
		// timeSinceTransfer

		let message = visitor.properties.title || psPlugin.application.writeOutLanguage('queueItemMessageUndefined');
		let meetingStartTime = '';
		let parsedTitle = parseMessage(message);

		if (parsedTitle.meetingStartTime) {
			if (parsedTitle.meetingStartTime.length>5) {
				// Assume we have a full time string if parsedTitle.meetingStartTime.length>5
				// In that case, try to format it as HH:mm
				// And at the same time convert it to the browser's timezone (maybe should be group's timezone in the future?)
				try {
					parsedTitle.meetingStartTime = moment(parsedTitle.meetingStartTime).format('HH:mm');
				} catch (err) {}
			}
			meetingStartTime = psPlugin.application.writeOutLanguage('meetingStart') + parsedTitle.meetingStartTime;
		}
		message = parsedTitle.title;

		//TODO: remove check with string true?
		var isTransferred = visitor.properties.isTransferred === true;
		var secondsInQueue = isTransferred ? visitor.properties.timeSinceTransfer: visitor.properties.timeSinceQueueEntry || 0;
		var lostDialog = visitor.properties.lostDialog === true;
		var shortMessage;
		if (message.length > 50) {
			shortMessage = message.substring(0, 47) + '...';
		} else {
			shortMessage = message;
		}


		/*
		 * --------------------------------------------------
		 * item layout
		 * --------------------------------------------------
		 */


		var cls = 'onlineListItem';
		if (visitor.properties.initialState === 'Proactive') {
			cls = cls + ' isProactive';
		}
		if (isTransferred) {
			cls += ' isTransferred';
		}
		if (lostDialog) {
			cls += ' isLostDialog';
		}

		var bottomRowChildren = [{
			reference: 'waitTimeDisplay',
			className: 'queueListItemWaitTime',
			tag: 'span',
			innerHTML: formatDuration(secondsInQueue)
		}, {
			reference: 'score',
			className: 'queueListItemScore',
			tag: 'span'
		}];

		var queueListItemTitle = '';

		if (visitor.properties.isTransferred === true) {
			queueListItemTitle = 'Urgent: Visitor was transferred and is waiting';
		} else if (visitor.properties.lostDialog === true) {
			queueListItemTitle = 'Urgent: Visitor lost agent and is waiting';
		} else if (visitor.properties.initialState === 'Proactive') {
			queueListItemTitle = 'Proactive';
		}

		var layout = uiView.createNodes({
			reference: 'onlineListItem',
			tag: 'div',
			className: cls,


			children: [{
				reference: 'topRow',
				tag: 'div',
				className: 'queueListItemTopRow',
				children: [{
					reference: 'icon',
					tag: 'i',
					title: queueListItemTitle,
					className: 'vngage-icon-torso',
					innerHTML: ''
				}, {
					reference: 'message',
					tag: 'div',
					title: message,
					className: 'queueListItemMessage',
					innerHTML: shortMessage

				}, {
					reference: 'meetingStartTime',
					tag: 'div',
					title: meetingStartTime,
					className: 'meeting-start-time',
					innerHTML: meetingStartTime

				}, {
					reference: 'buttonArea',
					tag: 'div',
					className: 'queueListItemButtonArea',
					children: [{
						reference: 'selectButton',
						tag: 'button',
						innerHTML: psPlugin.application.writeOutLanguage('queueItemButtonSelect'),
						onclick: function () {
							ViewActions.conversationStart(visitor.properties.sessionId);
							suspend();
						}
					}, {
						reference: 'lockButton',
						tag: 'button',
						innerHTML: psPlugin.application.writeOutLanguage('queueItemButtonLock'),
						onclick: function () {
							//visitor.commands.Lock.execute();
							ViewActions.conversationStart(visitor.properties.sessionId);
							suspend();
						}
					}]
				}]
			}, {
				reference: 'middleRow',
				tag: 'div',
				className: 'queueListItemMiddleRow',
				children: [{
					reference: 'navigationHistory',
					tag: 'div',
					className: 'queueListItemNavigationHistory'
				}]
			}, {
				reference: 'bottomRow',
				tag: 'div',
				className: 'queueListItemBottomRow',
				children: bottomRowChildren
			}]

		});
		// container
		var elem = layout.onlineListItem;
		this.elem = elem;
		parent.appendChild(elem);

		/*
		 * --------------------------------------------------
		 * Elements of interest saved in variable
		 * --------------------------------------------------
		 */
		var navigationHistory = layout.navigationHistory;
		var scoreElem = layout.score;

		var getScore = function () {
			return visitor.properties.score;
		};
		this.getScore = getScore;

		/*
		 * --------------------------------------------------
		 * Fill with data on first run
		 * --------------------------------------------------
		 */
		// add onLoad data to layout
		//helpers.addToNavigation(navigationHistory, visitor.properties.navigationHistory);
		visitor.events.onGetNavigationHistory.subscribe('queueListItem', (function (data) {
			//if (typeof data.data == 'object') {
			if (data.length) {
				if (visitor.properties.visitingPageNow === null) {
					visitor.properties.visitingPageNow = data[0].url;
				}
				addToNavigation(navigationHistory, data.reverse(), visitor.properties.langId);
			}

		}));
		scoreElem.innerHTML = visitor.properties.score;

		/*
		 * --------------------------------------------------
		 * Event listeners
		 * --------------------------------------------------
		 */
		// save for unsubscription array;
		var eventSubscriptions = [];

		// on page navigation
		visitor.events.onpageVisits.subscribe('queueListItem', (function (navigations) {
			addToNavigation(navigationHistory, navigations, visitor.properties.langId);
		}));
		eventSubscriptions.push('onpageVisits');

		// on score change
		visitor.events.onscore.subscribe('queueListItem', (function (points) {
			scoreElem.innerHTML = points;
		}));
		eventSubscriptions.push('onscore');

		function updateIdenficationInQueue(indentifcationState) {
			if (indentifcationState === 'identified') {
				layout.icon.classList.add('vngage-icon-vcard');
			} else {
				layout.icon.classList.remove('vngage-icon-vcard');
			}
		}

		updateIdenficationInQueue(visitor.properties.visitAuthenticationState);

		visitor.events.onvisitAuthenticationState.subscribe('visitIdentification', function (val) {
			updateIdenficationInQueue(val);
		});
		eventSubscriptions.push('onvisitAuthenticationState');


		var timeSinceEvent = isTransferred ? 'ontimeSinceTransfer': 'ontimeSinceQueueEntry';

		visitor.events[timeSinceEvent].subscribe('queueListItem', (function (value) {
			layout.waitTimeDisplay.innerHTML = formatDuration(value);
		}));
		eventSubscriptions.push(timeSinceEvent);


		var suspend = function () {
			psPlugin.application.visitorHandler.selectedfromQueue[params.id] = true;
			close();
		};

		var close = function () {
			try {
				// remove event listeners
				for (var i in eventSubscriptions) {
					visitor.events[eventSubscriptions[i]].unsubscribe('queueListItem');
				}
				// remove dom node
				parent.removeChild(elem);

				// delete javascript reference
				delete queuePanel[params.parent][params.id];
			} catch (err) {
				//console.log('queuepanel close:',err);
			}

		};
		visitor.onClose.push(close);

		this.close = close;
		this.suspend = suspend;



	}
};



var visitorsCountEl, agentsCountEl;


function updateQueueStatus (status, layout) {
	var queueStatusIntervalCounter = 0,
		queueStatusIntervalLimit = 0;
		//,
		//queueStatusHeader = layout.queueStatusHeader,
		//queueStatusPopup = layout.queueStatusPopup;

	//if (hasRun) return; hasRun = true;
	var switchMode = (IdentityService.getPresence() === 'online') ? 'flipswitch flipswitch-colorful': 'flipswitch';

	visitorsCountEl = visitorsCountEl || layout.queueStatus.querySelector('.visitors-count');
	agentsCountEl = agentsCountEl || layout.queueStatus.querySelector('.agents-count');

	agentsCountEl.textContent = status.totalUsers;
	visitorsCountEl.textContent = status.totalVisitors;

	var queueStatusPopupContent = '<table class="queue-data">' +
		'<thead><tr>' +
			//'<th>' + psPlugin.application.writeOutLanguage('queueStatusLanguage') + '</th>'+
			//'<th>' + psPlugin.application.writeOutLanguage('queueStatusGroup') + '</th>'+
		'<th>' + psPlugin.application.writeOutLanguage('queuePanelQueue') + '</th>' +
		'<th class="col-visitors">' + psPlugin.application.writeOutLanguage('queueStatusVisitors') + '</th>' +
		'<th class="col-agents">' + psPlugin.application.writeOutLanguage('queueStatusAgents') + '</th>' +
		'<th class="col-available">' + psPlugin.application.writeOutLanguage('queueStatusMyAvailability') + '</th>' +
		'</tr></thead>';
	for (var l in status.languages) {
		var lang = status.languages[l];
		if (typeof lang === 'function') {
			continue;
		}

		queueStatusPopupContent += '<tr class="row-site">' +
			'<td class="col-name">' + lang.name + '</td>' +
			'<td class="col-visitors">' + lang.totalVisitors + '</td>' +
			'<td class="col-agents">' + lang.totalLangUsers + '</td>' +
			'<td></td>' +
			'</tr>';


		for (var g in lang.groups) {
			var group = lang.groups[g];
			if (typeof group === 'function') {
				continue;
			}

			if (typeof psPlugin.application.configuration.user.availableForGroup[g] === 'undefined') {
				psPlugin.application.configuration.user.availableForGroup[g] = true;
			}

			queueStatusPopupContent += '<tr>' +
				'<td class="col-name">' + group.name + '</td>' +
				'<td class="col-visitors">' + group.numberOfVisitors + '</td>' +
				'<td class="col-agents">' + group.availableUsers + '</td>' +
				'<td class="col-available">' + (
					(psPlugin.application.configuration.user.groupIds.indexOf(g) === -1) ? '':
					'<label class="' + switchMode + '"><input type="checkbox" class="switch" onchange="psPlugin.application.configuration.user.availableForGroup[\'' + g + '\'] = this.checked;" ' + ((!!psPlugin.application.configuration.user.availableForGroup[g]) ? 'checked ': '') + 'id="presence_' + g + '" name="presence_' + g + '" /><span class="switch"></span></label>'
					//'<div style="text-align:left; position:relative;"><input onchange="psPlugin.application.configuration.user.availableForGroup[\'' +g+ '\'] = this.checked;" type="checkbox" '+ ((!!psPlugin.application.configuration.user.availableForGroup[g]) ? 'checked ' : '') +'id="presence_'+g+'" name="presence_'+g+'" class="switch" /><label for="presence_'+g+'">&nbsp;</label></div>'
				) + '</td>' +
				'</tr>';
		}
	}
	queueStatusPopupContent += '</table>';
	layout.queueStatusPopup.innerHTML = queueStatusPopupContent;
}

function matchURL (language, url) {
	var categories = _filter(DesktopService.configuration.urlCategories, {siteId: language});
	var res = {
		icon: 'webpage',
		color: 'blue',
		title: url
	};
	categories.forEach(function (urlMatch) {
		if (typeof url === 'string' && url.indexOf(urlMatch.section.urlFragment) > -1) {
			res = {
				icon: urlMatch.section.icon,
				color: urlMatch.section.color,
				title: urlMatch.section.description + ' \n ' + url
			};
			return res;
		}
	});
	return res;
}


function addToNavigation (parent, navigations, language) {
	var item, el;

	for (var p in navigations) {
		if (typeof navigations[p].url !== 'undefined') {
			item = matchURL(language, navigations[p].url);
			el = psPlugin.shell.jsExt.createIcon('small', false, item.icon, item.color, true);

			el.title = item.title;

			if (parent.children.length > 13) {
				parent.removeChild(parent.lastChild);
			}

			parent.insertBefore(el, parent.firstChild);
		}
	}
}

export default queuePanel;
