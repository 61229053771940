import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function ($scope, roleClaimList, timeZones, accountConfiguration, userList, user, $state, UserService, vngageConfig, APIEndpointService, NotificationService, AuthorizationService, vngageRemoveModalService, VngageUtil, VngageWorkspaceTranslationService, languagesConfig, Session, SessionService, $stateParams) {
	'ngInject';

	var vm = this;
	vm.roleClaimList = roleClaimList;
	vm.timeZones = angular.copy(timeZones);
	vm.timeZones.push({id: '', displayName: '(None)'}); // It is possible to not have a timezone as well
	vm.selectableRoles = UserService.formatSelectableRoles(vm.roleClaimList);
	vm.user = user;
	vm.submit = save;
	vm.remove = remove;
	vm.cancel = cancel;
	vm.removeIdentity = removeIdentity;
	vm.sendIdentityActivationEmail = sendIdentityActivationEmail;
	vm.desktopLanguages = languagesConfig.supportedLanguages.map(langCode => {
		return languagesConfig.lookupByLangCode(langCode);
	});
	vm.currentList = $stateParams.currentList || [];
	vm.externalIdsText = JSON.stringify(vm.user.data.externalIds, null, 2);

	vm.availableStatusTypes = [
		{value: 0, name: 'Active'},
		{value: 1, name: 'Suspended'},
		{value: 2, name: 'Blocked'}
	];

	// when accepting to reset unsaved changes, reload the entity
	$scope.$on('resetResettables', () => {
		vm.userDetailsForm.$setPristine();
		user.$get().catch(() => {
		});
	});

	activate();

	function activate() {

		// If the user has the right for Groups, also load the data and show the groups tab
		if (AuthorizationService.authorize({action: 'GET', resource: 'Group'})) {
			APIEndpointService.group.query().$promise.then(function (res) {
				vm.selectableGroups = UserService.formatSelectableGroups(res);
			});
		}

		vm.user.data.roleClaims = vm.user.data.roleClaims || [];
		vm.user.data.groupIds = vm.user.data.groupIds || [];

		if (angular.isUndefined(vm.user.data.profile.timeZoneId) && !!accountConfiguration.data.timeZoneId) {

			var defaultTimeZone = _find(vm.timeZones, function (t) {
				return t.id === accountConfiguration.data.timeZoneId;
			});

			if (defaultTimeZone) {
				vm.user.data.profile.timeZoneId = defaultTimeZone.id;
			}
		}

		if (!vm.user.data.profile.language && !!accountConfiguration.data.language) {
			vm.user.data.profile.language = accountConfiguration.data.language;
		}
		vm.defaultProfilePicture = vngageConfig.staticWorkspaceUrl + 'img/gravatar.png';

	}

	function save() {
		const isValid = isValidJSON(vm.externalIdsText);
		vm.user.data.externalIds = isValid
			? JSON.parse(vm.externalIdsText)
			: vm.user.data.externalIds;

		vm.externalIdsText = JSON.stringify(vm.user.data.externalIds, null, 2) || '';

		vm.isSaving = true;
		vm.user.$save()
			.catch(vm.userDetailsForm.$setDirty)
			.finally(function () {
				if (Session.user.id === vm.user.data.id) {
					// If updated self, also update Session
					SessionService.setUser(vm.user.data);
					VngageWorkspaceTranslationService.setWorkspaceLanguage(vm.user.data.profile.language);
				}
				VngageUtil.replaceResource(userList, vm.user).finally(() => {
					vm.isSaving = false;
				});
			});
	}

	function cancel() {
		$state.go('^');
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: user,
			itemType: 'user'
		}).then(function () {
			vm.userDetailsForm.$setPristine(); // mute unsavedChanges warning
			const itemNavigationData = getItemNavigationDataAfterRemove(user, 'root.account.user.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(function () {
			vm.isSaving = false; // unlock if error
		});
	}

	function removeIdentity(identity) {
		vm.userDetailsForm.$setDirty();
		var identityIndex = _findIndex(vm.user.data.identities, identity);
		vm.user.data.identities.splice(identityIndex, 1);
	}

	function sendIdentityActivationEmail() {
		APIEndpointService.userSendIdActivationEmail.save({id: vm.user.data.id}).$promise.then(function () {
			NotificationService.success({header: 'Success', body: 'Activation email sent'});
		});
	}


	function isValidJSON(str) {
		let valid;
		try {
			JSON.parse(str);
			valid = true;
		} catch (err) {
			valid = false;
		}
		return valid;
	}

}
