import React from 'react';
import createReactClass from 'create-react-class';
import AccountConfiguration from '../common/AccountConfiguration';
import { injectIntl, FormattedTime, FormattedMessage } from 'react-intl';

// var FormattedDate = ReactIntl.FormattedDate;
// var FormattedRelative = ReactIntl.FormattedRelative;

export default injectIntl(createReactClass({

	getInitialState () {
		return {
			timeAgo: 0,
			showRelative: typeof AccountConfiguration.configuration.account.displayRelativeDialogTime === 'undefined' ? true : AccountConfiguration.configuration.account.displayRelativeDialogTime
		}
	},

	componentDidMount () {
		this.tick();
		this.interval = setInterval(this.tick, 1000);
	},

	componentWillUnmount () {
		clearInterval(this.interval);
	},

	tick () {
		let time = (Date.now() - new Date(this.props.startTime).getTime()) / 1000;
		this.setState({
			timeAgo: this.props.startTime,
			time: (time < 1) ? 0 : time
		});
	},

	toggleDate () {
		this.setState({showRelative: !this.state.showRelative});
	},

	render () {
		let display;
		if (this.state.showRelative) {
			if (this.state.time < 45) {
				if (this.props.isMostRecentMessage) {
					display = <FormattedMessage
						id = {'timeInSeconds'}
						values = {{time:Math.floor(this.state.time) || 1}}/>;
				} else {
					display = <span>{this.props.intl.formatMessage({id:'lessThanMinute'})}</span>;
				}
			} else {
				display = <FormattedMessage
					id = {'timeInMinutes'}
					values = {{time:Math.floor(this.state.time/60) || 1}}/>;
			}
		} else {
			display = <FormattedTime value={this.state.timeAgo}
									 day = "numeric"
									 month = "long"
									 year = "numeric"
									 hour = "numeric"
									 minute = "numeric"/>;
		}
		return (
			<div className="timeago" onClick={this.toggleDate}>
			{display}
			</div>
		)
	}

}));
