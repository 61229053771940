import Message from './Message';
import AccountConfig from '../utils/AccountConfig';
import LogActions from '../actions/LogActions';
import ServerRequests from './ServerRequests';
import PluginActions from '../actions/PluginActions';

const pluginActionError = (plugin, errorMsg, actionData) => (dispatch, getState) => {
	dispatch(LogActions.pluginActionError(plugin ? plugin.get('pluginName'): '', plugin ? plugin.get('id'): '', errorMsg, actionData));
};


const sendActionMessage = (conversationId, pluginId, actionMessageName, actionData) => async (dispatch, getState) => {

	let plugin;

	try {
		plugin = getState().get('plugins').find(plugin => plugin.get('id') === pluginId);
		const mappedAction = plugin.get('mappedActionMessages').find(mappedAction => mappedAction.get('actionMessageName') === actionMessageName);
		if (!mappedAction) {
			throw new Error('Unknown actionMessageName: ' + actionMessageName);
		}

		const actionId = mappedAction.get('actionId');
		const actionObj = AccountConfig.getAction(actionId);

		if (!actionObj) {
			throw new Error('Unknown (or unpublished) actionId. actionMessageName: "' + actionMessageName + '" is mapped to a missing actionId: ' + actionId);
		}

		await dispatch(Message.sendAction(conversationId, actionObj, actionData));

	} catch (error) {
		dispatch(pluginActionError(plugin, 'sendActionMessage', {error, conversationId}));
	}
};

const getStartCodeUsingId = (customerId) => async (dispatch, getState) => {
	dispatch(PluginActions.updatePluginMeta({startCode: ''}));
	try {
		// param format:  { "customerIds": ["customer://customerId"] }
		const params = {
			customerIds: [
				customerId,
			]
		};
		const response = await ServerRequests.getStartCodeWithParams(params);
		const startCode = response.data || '';
		dispatch(PluginActions.updatePluginMeta({startCode}));
	} catch (error) {
		dispatch(LogActions.netError(0, 'updateStartCode', {customerId, error}));
	}
};

export default {
	sendActionMessage,
	getStartCodeUsingId
};
