import WorkspaceSettingsController from './WorkspaceSettingsController';
import WorkspaceSettingsEditTemplate from './WorkspaceSettingsEditTemplate';

export default angular.module('vngageApp.account.workspaceSettings', [
	'ui.router'
])

	.controller('WorkspaceSettingsController', WorkspaceSettingsController)

	.constant('workspaceSettingsModuleConfig', {
		state: 'root.account.workspaceSettings',
		access: 'Engage/WorkspaceSettings'
	})

	.config(($stateProvider, workspaceSettingsModuleConfig) => {
		'ngInject';

		$stateProvider.state(workspaceSettingsModuleConfig.state, {
			url: 'workspacesettings/',
			controller: 'WorkspaceSettingsController as vm',
			templateUrl: WorkspaceSettingsEditTemplate,
			data: {
				access: workspaceSettingsModuleConfig.access,
				ncyBreadcrumbLabel: 'Workspace settings'
			},
			resolve: {
				generalWorkspaceSettings: WorkspaceSettingsService => {
					'ngInject';
					return WorkspaceSettingsService.forceGetSections('general').catch((error) => {
						// Catch reject to avoid red error notification
						if (error && error.status !== 404) {
							// Only log errors != 404 (which is normal when workspaceSetting is missing)
							console.error('Could not get workspaceSettings:', error);
						}
					});
				},
				workspaceSectionsValidation: WorkspaceSettingsService => {
					'ngInject';
					// Note: Order of execution matters since WorkspaceSettingsService caches the result.
					// Calling WorkspaceSettingsService.workspaceSectionsValidation() without parameter "force" == true will use cached result if exists.
					// So if WorkspaceSettingsService.forceGetSections() above is called before workspaceSectionsValidation(), an extra BE call will be avoided (data in cache will be validated)...
					return WorkspaceSettingsService.workspaceSectionsValidation();
				}
			}
		});
	});
