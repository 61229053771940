export default function (accountSettings, accountSettingsConfigurationSectionsValidation, $state, $scope) {
	'ngInject';

	var vm = this;
	vm.accountSettings = accountSettings;
	vm.accountSettings.data.section.coBrowserRestricted = vm.accountSettings.data.section.coBrowserRestricted || [];
	vm.accountSettings.data.section.coBrowserDoNotDisturb = vm.accountSettings.data.section.coBrowserDoNotDisturb || [];
	vm.accountSettings.data.section.coBrowsingStartUrl = vm.accountSettings.data.section.coBrowsingStartUrl || '';
	vm.accountSettings.data.section.notificationDuration = vm.accountSettings.data.section.notificationDuration + '';
	vm.accountSettingsConfigurationSectionsValidation = accountSettingsConfigurationSectionsValidation;

	vm.save = save;
	vm.cancel = cancel;

	function save() {
		vm.accountSettings.data.section.notificationDuration = +vm.accountSettings.data.section.notificationDuration;
		vm.accountSettings.$save();
	}

	function cancel() {
		$state.go('^');
	}

	$scope.$on('resetResettables', () => {
		vm.accountSettingsForm.$setPristine();
	});
}
