import JSChannel from '../../../../vendor/JSChannel';
import assign from 'object-assign';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _extend from 'lodash/extend';
import ViewActions from '../actions/ViewActions';
import ParticipantStore from '../stores/ParticipantStore';
import Session from '../../../common/auth/Session';

function currentAccountId() {
	return (Session && Session.account && Session.account.id ? Session.account.id : '');
}

export default class VisualGuidance {

	constructor (conversationId, options = {}) {
		this.usePassiveCoBrowsing = options.enablePassiveCoBrowsing || false;
		this.useDomCoBrowsing = options.domCoBrowsing || false;
		this.useProtocolRelativeUrls = options.useProtocolRelativeUrls || false;
		this.coBrowserNoFollow = options.coBrowserNoFollow || [];

		this.conversationId = conversationId;

		this.navigations = [];

		this._state = {
			url: '',
			lastFollowUrl : '', // last url followed by Agent
			urlCouldFollow: true, // url not in dont-follow
			highlightMode: false, // are we highlighting
			paused: this.usePassiveCoBrowsing || false, // have agent deactivated ?
			bidirectional: false, // is it bidirectional ?
			isScaled: true, // scaled ui ?
			browserAttributes: { // visitor browser props
				width:980,
				height:500,
				hasFlash:true
			},
			status: 'passive',
			displayStatusBar: false,
			guestAuthorized: false,
			domUploadStatus: 'pending',
			askingForDOMUpload: false,
			sendPageUrl: '',
			sendPageTitle: ''
		};

		this.metadataExchangeQueuedMessages = [];
	}

	/**
	 * verify url has an 'ok' classification
	 * @param {string} classification
	 * @returns {boolean}
	 */
	static verifyUrlClassification(classification) {
		return classification === 'ok';
	}

	/**
	 * strip protocol of url
	 * @param {string} url
	 * @returns {*}
	 */
	static stripProtocol (url) {
		return url.replace(/^(https?|ftp):\/\//, '//');
	}

	/**
	 * checks if navigation is bidirectional
	 * @param {object} role
	 * @returns {boolean}
	 */
	static isNavigationBidirectional (role) {
		return !(role && role.hasOwnProperty('bidirectional') && !role.bidirectional);
	}

	getState () {
		return this._state;
	}

	setState (props) {
		this._state = assign(this._state, props);
	}

	setScaled (isScaled) {
		this._state.isScaled = isScaled;
	}

	toggleHighlight (isActive) {
		this._state.highlightMode = !!isActive;

		this.getChannel().call({
			method: 'highlight',
			params: (isActive) ? 'start': 'stop',
			success: function (resp) {
				console.log('highlight =', resp);
			}
		});
	}

	toggleGuestAuthorized (authorized) {
		this._state.guestAuthorized = !!authorized;
	}

	togglePause (isPaused) {
		if (!isPaused && isPaused !== false){
			isPaused = !this._state.paused;
		}
		this._state.paused = !!isPaused;

		let visitorIsAt = _get(ParticipantStore.getVisitorParticipantOfConversation(this.conversationId), 'currentNavigation', {})
		this._state.bidirectional = VisualGuidance.isNavigationBidirectional(visitorIsAt);

		this.evaluateStatus();
		this.mapParticipantNavigation();

		if (!this._state.paused && this.verifyNavigation(visitorIsAt)) {
			this.setState({url:visitorIsAt.url});
		}

	}

	sendHighlight (data){
		this.toggleHighlight(false);
	}

	askingForDOMUpload (status) {
		this.toggleStatusBar(true);
		this._state.askingForDOMUpload = status === 'Pending';
		this._state.domUploadStatus = status;
	}

	addNavigations (navigations, reverse) {
		let urlToSet;
		let latestUrl;

		if (!Array.isArray(navigations)) {
			navigations = [navigations];
		}

		this.navigations.push(...navigations);

		const reversedNavigations = reverse ? navigations.reverse() : navigations;
		const latestVisitorNavigation = _find(reversedNavigations, nav => {
			return nav.speaker.visitId;
		});

		// Todo: remove when BE fixed first navigation message via proxy25
		if (latestVisitorNavigation && latestVisitorNavigation.data) {
			latestVisitorNavigation.url = latestVisitorNavigation.data.url;
			latestVisitorNavigation.classification = latestVisitorNavigation.data.classification;
			latestVisitorNavigation.bidirectional = latestVisitorNavigation.data.bidirectional;
		}

		if (latestVisitorNavigation && latestVisitorNavigation.url) {
			if (latestVisitorNavigation.messageType === 'domUpload') {
				this.askingForDOMUpload('Done');
			}

			latestUrl = latestVisitorNavigation.url;
			urlToSet = this.useProtocolRelativeUrls ? VisualGuidance.stripProtocol(latestUrl) : latestUrl;

			if (!this._state.paused && (this.verifyNavigation(latestVisitorNavigation) || latestVisitorNavigation.messageType === 'domUpload')) {
				this.setState({ url: urlToSet });
			}
		} else {
			this.evaluateStatus();
		}

		this.mapParticipantNavigation();
	}

	sendMetadataExchangeMessagesToVGFrame(metadataMessages) {
		// console.log('VisualGuidance: sendMetadataExchangeMessagesToVGFrame(): ',metadataMessages);
		this.getChannel().notify({
			method: 'metadataExchange',
			params: metadataMessages
		});
	};

	handleReceivedMetadataExchangeMessages(metadataExchangeMessages, receiveType, myVisitId) {
		// Handle 'metadataExchange' from other part (from conversationMessage). Relay to vg frame
		if (metadataExchangeMessages && metadataExchangeMessages.length > 0) {
			var metadataMessages = _map(metadataExchangeMessages, function(metadataExchangeMsg) {
				// console.log('myVisitId, metadataExchangeMsg.speaker.visitId:',myVisitId, metadataExchangeMsg.speaker.visitId);
				return _extend(metadataExchangeMsg.metadataBag, {
					_vngage_id: metadataExchangeMsg.id,
					_vngage_createdAt: metadataExchangeMsg.createdAt,
					_vngage_recieveType: receiveType, // 'state' or 'since' to let the visitor script decide what to do with it
					_vngage_direction: ((!metadataExchangeMsg.speaker.visitId || metadataExchangeMsg.speaker.visitId === myVisitId ? 'out' : 'in'))
				});
			});
			if (this.channel) {
				// console.log('VG Posting metadataMessages directly:',metadataMessages);
				this.sendMetadataExchangeMessagesToVGFrame(metadataMessages);
			} else {
				// We do not have a VG channel yet: Queue messages
				// They will be sent as soon as the channel is set up
				// console.log('VG Queuing metadataMessages :',metadataMessages);
				this.metadataExchangeQueuedMessages = this.metadataExchangeQueuedMessages.concat(metadataMessages);
			}
		}
	};

	getLastNavigationOfRole (role) {
		switch (role) {
			case 'visitor' :
				return _get(ParticipantStore.getVisitorParticipantOfConversation(this.conversationId), 'currentNavigation.url', '');
			break;

			case 'agent' :
				return _get(ParticipantStore.getAgentParticipantOfConversation(this.conversationId), 'currentNavigation.url', '');
				break;

			case 'guest' :
				return _get(ParticipantStore.getGuestParticipantOfConversation(this.conversationId), 'currentNavigation.url', '');
				break;
		}
	}

	// loop through participants of conversation
	// look through navigations and map the navigation to participant
	mapParticipantNavigation () {
		let participants = ParticipantStore.getParticipantsOfConversation(this.conversationId),
			revNav = this.navigations;

		participants.map( p => {
			let participantSpeakerId = p.userId || p.visitId;
			let navigations = _map(_filter(revNav.reverse(), navParticipantId.bind(participantSpeakerId)), navMap);
			p.currentNavigation = navigations ? navigations[0] : {};
		});

		let visitorNavigation = _get(ParticipantStore.getVisitorParticipantOfConversation(this.conversationId), 'currentNavigation', {});

		this._state.bidirectional = VisualGuidance.isNavigationBidirectional(visitorNavigation);

		this.evaluateStatus();

		this._state.displayStatusBar = true;

		setTimeout( () => {
			ViewActions.visualGuidanceToggleStatusBar(this.conversationId, false);
		}, 4000)
	}

	toggleStatusBar (show) {
		this._state.displayStatusBar = show;
	}

	evaluateStatus () {

		this._state.status = 'active';

		if (this.useDomCoBrowsing) {
			return;
		}
		if (this._state.paused) {
			return this._state.status = 'paused';
		}

		if (!this._state.urlClassified) {
			this._state.status = 'passive';
		}

		if (!this._state.urlCouldFollow) {
			this._state.status = 'passive';
		}

		if (!this._state.bidirectional) {
			this._state.status = 'passive';
		}
	}

	// verify navigation with account cobrowser config
	verifyNavigation (navigation) {
		this._state.urlClassified = !!VisualGuidance.verifyUrlClassification(navigation.classification);
		this._state.urlCouldFollow = !!this.allowCoBrowserFollowNavigation(navigation.url);

		return !(!this._state.urlClassified || !this._state.urlCouldFollow);
	}

	// loop through account nofollow fragments to verify valid navigation follow
	allowCoBrowserFollowNavigation(followUrl) {
		const fragments = this.coBrowserNoFollow;

		if (!fragments.length || !_isArray(fragments)) {
			return true;
		}

		return fragments.every( fragment => {
			return followUrl.indexOf(fragment) === -1;
		});
	}

	pageLoaded (url, title) {
		if (this.useDomCoBrowsing) {
			// no need to take action here right?
			return;
		}

		// when user navigates ensure neither User-page nor Visitor-page is a NoFollowUrl
		let anyUrl = url.concat(this.getLastNavigationOfRole('visitor'));

		this.verifyNavigation({
			classification: 'ok',
			url:anyUrl
		});


		//console.log('<VG PAGELOADED>', 'surl:', this._state.url, 'url:', url, 'furl:', this._state.lastFollowUrl, 'state:', this._state);
		if (url !== this._state.url && url != this._state.lastFollowUrl && this._state.lastFollowUrl !== '' && this._state.bidirectional && !this.useDomCoBrowsing) {
			this.setState({url:url});
			if (!this._state.paused) {
				ViewActions.createNavigationMessage({
					url: url,
					bidirectional: true //!lockednpm
				}, this.conversationId);
			}
		}

		if (this._state.paused) {
			//Feed new url and info to sendPage()
			this.setState({url:url, sendPageUrl:url, sendPageTitle:title});
		}

		this.evaluateStatus();

		this._state.lastFollowUrl = url;
	}

	getSendPageLinkData () {
		return {
			url: this._state.sendPageUrl || this._state.url,
			title: this._state.sendPageTitle
		}
	}

	getChannel () {
		if (!this.legacyChannel) {
			this.setupLegacyChannel();
		}
		if (!this.accountChannel) {
			this.setupAccountChannel();
		}
		return this.channel;
	}

	destroyChannel () {
		if (this.accountChannel) {
			this.accountChannel.destroy();
			this.accountChannel = null;
		}
		if (this.legacyChannel) {
			this.legacyChannel.destroy();
			this.legacyChannel = null;
		}
		this.channel = null;
	}

	setupLegacyChannel() {
		// Set up an open (legacy) channel for communication with old visitor versions
		this.legacyChannel = JSChannel.build({
			debugOutput: false,
			window: document.querySelector('.vg-legacy-iframe').contentWindow,
			origin: '*',
			scope: 'cobrowser',
			reconnect: true,
			onReady: function (ch) {
				console.log('<VG> Legacy jsChannel ready');
			}
		});
		this.legacyChannel.bind('whoAreYou', this.onWhoAreYou.bind(this));
		this.legacyChannel.bind('onPageLoaded', this.onPageLoaded.bind(this));
		this.legacyChannel.bind('onCapturedNavigation', this.onCapturedNavigation.bind(this));
		this.legacyChannel.bind('onElementHighlighted', this.onElementHighlighted.bind(this));

		// Set channel to "legacyChannel" initially/by default. Will be switched to "accountChannel" on first connect/onReady on that channel
		// Note: Once the channel has been switched to "accountChannel" it can never switch back.
		// That means that if a "smart" (newer-version) visitor is loaded in the sub-frame and connects it's account jschannel, the highlight-calls will always be sent to "accountChannel" from then on...
		// If an "old-version" visitor is loaded after that (e.g. by getPageWithFormValues, etc), highlighting will stop working.
		// Not a big deal, and eventually there will never be any "old-version" visitors connecting
		this.channel = this.legacyChannel;
	}

	setupAccountChannel () {
		this.accountChannel = JSChannel.build({
			debugOutput: false,
			window: document.querySelector('.vg-legacy-iframe').contentWindow,
			origin: '*', //TODO: make this more restrictive
			scope: 'cobrowser_'+currentAccountId().toLowerCase(), // [TL] Set up the channel with embedded accountId to prevent cross-account-cobrowsing! (NOTE: Case sensitive!)
			reconnect: true, //allow the child window to reconnect
			onReady: function (ch) {
				console.log('<VG> Account jsChannel ready');
				this.channel = ch; // A smart/new visitor version has connected: Switch active channel to 'accountChannel'

				// Send any queued metadataExchange-messages when the channel is ready
				if (this.metadataExchangeQueuedMessages) {
					this.sendMetadataExchangeMessagesToVGFrame(this.metadataExchangeQueuedMessages);
					this.metadataExchangeQueuedMessages = [];
				}
			}.bind(this)
		});
		// this.accountChannel.bind('whoAreYou', this.onWhoAreYou.bind(this));
		this.accountChannel.bind('onPageLoaded', this.onPageLoaded.bind(this));
		this.accountChannel.bind('onCapturedNavigation', this.onCapturedNavigation.bind(this));
		this.accountChannel.bind('onElementHighlighted', this.onElementHighlighted.bind(this));
		this.accountChannel.bind('metadataExchange', this.onMetadataExchange.bind(this));
	}

	onWhoAreYou(trans, params) {
		// New visitor versions in iframed child frames are supposed to immediately send a 'whoAreYou' to parent (this script) to complete the handshake
		var response = {
			identity: 'desktop',
			//version: vngage.info, // Is it possible to get build version in Workspace?
			accountId: currentAccountId()
		};
		if (!params || !params.accountId || params.accountId.toLowerCase() !== response.accountId.toLowerCase()) {
			// AccountId mismatch
			response.identity = 'invalid_desktop';
		}
		return response;
	}

	onPageLoaded(trans, params) {
		//console.log('<VG> onPageLoaded', trans, params);
		this.pageLoaded(params.url, params.title);
		ViewActions.visualGuidanceiFrameLoaded(this.conversationId);
	}

	onCapturedNavigation(trans, params) {
		// console.log('<VG> onCapturedNavigation', trans, params);

		//do not send url to visitor if in paused mode.
		if (!this._state.paused || this.useDomCoBrowsing) {
			//statusBar.notify(lang('coBrowserNavigatingTo') + url);
			ViewActions.createNavigationMessage({
				url: params.url,
				bidirectional: true //!locked
			}, this.conversationId);
		}
	}

	onElementHighlighted(trans, params) {
		ViewActions.sendHighlight(this.conversationId, params);
	}

	onMetadataExchange(trans, params) {
		// console.log('VisualGuidance (desktop): onMetadataExchange:',this.conversationId, params);
		ViewActions.sendMetadataExchange(this.conversationId, params);
	}
}


function navParticipantId (nav) {
	let speakerId = nav.speaker.userId || nav.speaker.visitId;
	return this === speakerId;
}

function navMap (nav) {
	let mappedNav = {
		classification: nav.classification,
		url: nav.url,
		bidirectional : nav.bidirectional
	};

	if (nav.originalSource) {
		assign(mappedNav, {
			originalSource : nav.originalSource
		});
	}

	return mappedNav;
}
