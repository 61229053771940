import _find from 'lodash/find';
import moment from 'moment-timezone';

export default function ($q, $http, vngageConfig, APIBatchRequestService) {
    'ngInject';

    var endpoints = {
        queryMe:                             { url: 'statistics/me',                                verb: 'post' },
        queryUserClosedCases:                { url: 'statistics/getuserclosedcases',                verb: 'post' },
        queryUsers:                          { url: 'statistics/users',                             verb: 'post' },
        queryUserOnlineActivity:             { url: 'statistics/getuseractivityduration',           verb: 'post' },
        queryUserEngagedActivity:            { url: 'statistics/userengagedduration',               verb: 'post' },
        queryUserResponseActivity:           { url: 'statistics/userresponseduration',              verb: 'post' },
        queryUsersOnline:                    { url: 'statistics/usersonline',                       verb: 'get' },
        queryOverall:                        { url: 'statistics/overall',                           verb: 'get' },
        queryLatency:                        { url: 'statistics/ingestionLatency',                  verb: 'get' },
        queryDialogUsage:                    { url: 'statistics/getdialogusage',                    verb: 'post' },
        queryUserUsage:                      { url: 'statistics/getuserusage',                      verb: 'post' },
        queryGroupOverview:                  { url: 'statistics/groupoverview',                     verb: 'get' },
        queryGroupInfo:                      { url: 'statistics/groupinfo',                         verb: 'post' },
        queryOpportunities:                  { url: 'statistics/opportunities',                     verb: 'post' },
        querySolutionsByOpportunities:       { url: 'statistics/solutionsbyopportunities',          verb: 'post' },
        queryQueueWait:                      { url: 'statistics/queuewaittime',                     verb: 'post' },
        queryQueues:                         { url: 'statistics/getqueueinfo',                      verb: 'post' },
        queryCases:                          { url: 'statistics/cases',                             verb: 'post' },
        queryPurchaseConversion:             { url: 'statistics/uniquevisitspurchaseconversion',    verb: 'post' },
        queryPurchaseWithCase:               { url: 'statistics/purchasewithcase',                  verb: 'post' },
        queryTotalNumberOfCasesPerDay:       { url: 'statistics/totalnumberofcasesperday',          verb: 'get' },
        queryTotalNumberOfVisitsPerDay:      { url: 'statistics/totalnumberofvisitsperday',         verb: 'get' },
        queryMetricData:                     { url: 'statistics/MetricData',                        verb: 'get' },
        queryMetricDataMinimized:            { url: 'statistics/MetricDataMinimized',               verb: 'get' }
        //queryAggregate:                      { url: 'statistics/aggregate',                         verb: 'get' }
    };

    return {
        defaultPollingTimeout: 31 * 1000,
        endpoints: endpoints,
        queryOverall: function(q) {
            return $http[endpoints.queryOverall.verb](vngageConfig.restUrl + endpoints.queryOverall.url, { params: q});
        },
        queryLatency: function() {
            return $http[endpoints.queryLatency.verb](vngageConfig.restUrl + endpoints.queryLatency.url);
        },
        queryDialogUsage: function(q) {
            return $http[endpoints.queryDialogUsage.verb](vngageConfig.restUrl + endpoints.queryDialogUsage.url, q);
        },
        queryGroupOverview: function(q) {
            return $http[endpoints.queryGroupOverview.verb](vngageConfig.restUrl + endpoints.queryGroupOverview.url, { params: q});
        },
        queryGroupInfo: function(q) {
            return $http[endpoints.queryGroupInfo.verb](vngageConfig.restUrl + endpoints.queryGroupInfo.url, q);
        },
        queryOpportunities: function(q) {
            return $http[endpoints.queryOpportunities.verb](vngageConfig.restUrl + endpoints.queryOpportunities.url, q);
        },
        querySolutionsByOpportunities: function(q) {
            return $http[endpoints.querySolutionsByOpportunities.verb](vngageConfig.restUrl + endpoints.querySolutionsByOpportunities.url, q);
        },
        queryQueueWait: function(q) {
            return $http[endpoints.queryQueueWait.verb](vngageConfig.restUrl + endpoints.queryQueueWait.url, q);
        },
        queryQueues: function(q) {
            return $http[endpoints.queryQueues.verb](vngageConfig.restUrl + endpoints.queryQueues.url, q);
        },
        queryCases: function(q) {
            return $http[endpoints.queryCases.verb](vngageConfig.restUrl + endpoints.queryCases.url, q);
        },
        queryPurchaseConversion: function(q) {
            return $http[endpoints.queryPurchaseConversion.verb](vngageConfig.restUrl + endpoints.queryPurchaseConversion.url, q);
        },
        queryPurchaseWithCase: function(q) {
            return $http[endpoints.queryPurchaseWithCase.verb](vngageConfig.restUrl + endpoints.queryPurchaseWithCase.url, q);
        },
        queryMyStats: function(q) {
            return $http[endpoints.queryMe.verb](vngageConfig.restUrl + endpoints.queryMe.url, q);
        },
        queryUserClosedCases: function(q) {
            return $http[endpoints.queryUserClosedCases.verb](vngageConfig.restUrl + endpoints.queryUserClosedCases.url, q);
        },
        queryUserOnlineActivity: function(q) {
            return $http[endpoints.queryUserOnlineActivity.verb](vngageConfig.restUrl + endpoints.queryUserOnlineActivity.url, q);
        },
        queryUserEngagedActivity: function(q) {
            return $http[endpoints.queryUserEngagedActivity.verb](vngageConfig.restUrl + endpoints.queryUserEngagedActivity.url, q);
        },
        queryUserResponseActivity: function(q) {
            return $http[endpoints.queryUserResponseActivity.verb](vngageConfig.restUrl + endpoints.queryUserResponseActivity.url, q);
        },
        queryUsers: function(q) {
            return $http[endpoints.queryUsers.verb](vngageConfig.restUrl + endpoints.queryUsers.url, q);
        },
        queryUserUsage: function(q) {
            q.userType = 0;
            return $http[endpoints.queryUserUsage.verb](vngageConfig.restUrl + endpoints.queryUserUsage.url, q);
        },
        queryUsersOnline: function(q) {
            return $http[endpoints.queryUsersOnline.verb](vngageConfig.restUrl + endpoints.queryUsersOnline.url, {params: q});
        },
        queryTotalNumberOfCasesPerDay: function(q) {
            return $http[endpoints.queryTotalNumberOfCasesPerDay.verb](vngageConfig.restUrl + endpoints.queryTotalNumberOfCasesPerDay.url, {params: q});
        },
        queryTotalNumberOfVisitsPerDay: function(q) {
            return $http[endpoints.queryTotalNumberOfVisitsPerDay.verb](vngageConfig.restUrl + endpoints.queryTotalNumberOfVisitsPerDay.url, {params: q});
        },
        queryMetricData: function(metricName,q) {

            var utcTimeZone = 'Etc/UTC';
            var start=q.range.from;
            var end=q.range.to;

            if (metricName.indexOf('UserCaseUsage') === -1) {
                if (q.period === 'day'){
                    metricName += 'PerDay';
                    // [ TL ] If period===day, adjust start, end to UTC-time before fetching (only UTC is allowed by the API when fetching days)
                    start=moment.tz(moment.parseZone(q.range.from).format('YYYY-MM-DD'),utcTimeZone).format();
                    end=moment.tz(moment.parseZone(q.range.to).format('YYYY-MM-DD'),utcTimeZone).endOf('day').format();
                } else {
                    metricName += 'PerHour';
                }
            }
            var r = {
                metricName:metricName,
                granularity:q.period,
                start: start,
                end: end,
                project: q.projection,
                filter: q.siteId ? [ 'siteId='+q.siteId ] : null
            };
            return $http[endpoints.queryMetricData.verb](vngageConfig.restUrl + endpoints.queryMetricData.url, {params: r});
        },

        queryMetricDataMinimized: function(metricName,q) {

            var utcTimeZone = 'Etc/UTC';
            var start=q.range.from;
            var end=q.range.to;

            if (metricName.indexOf('UserCaseUsage') === -1) {
                if (q.period === 'day'){
                    metricName += 'PerDay';
                    // [ TL ] If period===day, adjust start, end to UTC-time before fetching (only UTC is allowed by the API when fetching days)
                    start=moment.tz(moment.parseZone(q.range.from).format('YYYY-MM-DD'),utcTimeZone).format();
                    end=moment.tz(moment.parseZone(q.range.to).format('YYYY-MM-DD'),utcTimeZone).endOf('day').format();
                } else {
                    metricName += 'PerHour';
                }
            }
            var r = {
                metricName:metricName,
                granularity:q.period,
                start: start,
                end: end,
                project: q.projection,
                filter: q.siteId ? [ 'siteId='+q.siteId ] : null
            };
            return $http[endpoints.queryMetricDataMinimized.verb](vngageConfig.restUrl + endpoints.queryMetricDataMinimized.url, {params: r});
        },

        /*
        queryAggregate: function(q) {
            // [ FL ] For now this is only used as an example
            if (!q) {
                q = {
                    metricName:'ConversionFunnelSitePerDay',
                    aggregate:['numberOfOpportunityEvaluations','numberOfOpportunities'],
                    dimension:['siteId','siteName'],
                    granularity:'Day',
                    start:'2015-09-01',
                    end: '2015-11-01'
                };
            }
            return $http[endpoints.queryAggregate.verb](vngageConfig.restUrl + endpoints.queryAggregate.url, {params: q});
        },
*/
        query: function(actions, q) {
            var requests = [];
            actions.forEach(function (action) {

                var endpoint = _find(endpoints, action);

                if (endpoint) {
                    requests.push({url: action.url, method: action.verb, data: q});
                }
            });
            return APIBatchRequestService.execute(requests);
        }
    };
};
