import React from 'react';
import {connect} from 'react-redux';
import immutable from 'immutable';
import mmd from '../../../../common/vngageMarkdown/mmd';
import MetaInfo from './MetaInfo.react';
import MetaDevice from './MetaDevice.react';
import VisitorIdentification from './VisitorIdentification.react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import DialogDurationTimer from './DialogDurationTimer.react';
import TimeFormat from '../../utils/TimeFormat';
import Format from '../../utils/Format';
import AccountConfig from './../../utils/AccountConfig';
import VisitorProfileSelectors from '../../selectors/VisitorProfileSelectors';
import {useGetTranslation} from '../LangContext';

export const VisitorProfile = (props) => {
	const getTranslation = useGetTranslation();

	const {
			  visitorProfile,
			  visitorJoined,
			  conversationStatus,
			  reservation,
			  meetingStartTime,
			  groupName,
			  visitorIdentified,
			  title
		  } = props;
	const channel = VisitorProfileSelectors.getVisitorChannel(visitorProfile);
	const device = visitorProfile.getIn(['meta', 'os', 'deviceName']) || channel;

	// if there is no title then hide the tooltip
	const tooltipStyle = title ? {}: {display: 'none'};
	const tooltipLink = (<Tooltip style={tooltipStyle} id="tooltip">
		<p dangerouslySetInnerHTML={{__html: mmd(title)}}/>
	</Tooltip>);

	const infoPayload = {
		visitorJoined,
		conversationStatus,
		visitorProfile,
	};

	let groupNameString = null;
	let meetingStartTimeString = null;
	if (reservation) {
		groupNameString = groupName ? getTranslation('activeGroupName') + groupName: '';
		meetingStartTimeString = meetingStartTime ? getTranslation('meetingStart') + TimeFormat.asReservationTime(meetingStartTime): '';
	}

	return (
		<div className="visitor-meta">
			{reservation ?
				<span className="meta-data">
					<p>{groupNameString}</p>
					<p>{meetingStartTimeString}</p>
					<p><MetaInfo {...infoPayload}/></p>
					<VisitorIdentification
						visitorProfile={visitorProfile}
						visitorIdentified={visitorIdentified}
					/>
				</span>
				:
				<span className="meta-data">
					<OverlayTrigger placement="top" overlay={tooltipLink}>
						<span className="meta-data">
							<p>
								<b className="lineClampEllipsis1Line">{Format.stripMarkdown(props.messageToCustomerCase)}</b>
								<MetaInfo {...infoPayload} />
							</p>
						</span>
					</OverlayTrigger>
					<VisitorIdentification
						visitorProfile={visitorProfile}
						visitorIdentified={visitorIdentified}
					/>
				</span>
			}
			<div>
				<MetaDevice device={device}/>
				<DialogDurationTimer isClosed={props.isClosed} startTime={props.startTime}/>
			</div>
		</div>
	);
};


const mapStateToProps = (state, ownProps) => {
	// INCOMING
	// conversationId
	const conversationId = ownProps.conversationId;
	const conversation = state.getIn(['conversations', conversationId]) || immutable.fromJS({events: []});
	const visitId = conversation.get('visitId');

	const visitorProfile = state.getIn(['visitorProfiles', visitId]) || immutable.Map();
	const visitorJoined = !!visitId;
	const conversationStatus = conversation.get('conversationStatus');

	const events = conversation.get('events') || immutable.List();
	const event = events.findLast(event => event.get('type') === 'conversationReservation');
	const reservation = event
		? event.get('reservation')
		: null;

	const meetingStartTime = reservation ? reservation.get('start'): null;
	const groupId = conversation.get('groupId');
	const groupName = AccountConfig.getGroupName(groupId);

	const authenticationLevel = visitorProfile.getIn(['data', 'authenticationLevel']) || null;
	const visitorIdentified = authenticationLevel === 'Identified';

	const title = conversation.getIn(['title', 'message']);
	const messageToCustomerCase = AccountConfig.getSolution(conversation.get('solutionId')).name;
	const startTime = conversation.get('startTime');
	return {
		visitorProfile,
		visitorJoined,
		conversationStatus,
		reservation,
		meetingStartTime,
		groupName,
		visitorIdentified,
		title,
		messageToCustomerCase,
		startTime,
	};
};

function mapDispatchToProps(dispatch) {
	return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitorProfile);

