export default {

	RELOADED: 'videoStateReloaded',
	RESUMING: 'videoStateResuming',
	INIT_MEDIA: 'videoStateInitMedia',
	CONNECTING: 'videoStateConnecting',
	JOIN_ROOM: 'videoStateJoinRoom',
	CALLING_PEER: 'videoStateCallingPeer',
	STREAMING: 'videoStateStreaming',
	LEAVING: 'videoStateLeaving'
};
