import React from 'react';
import ReactDOM from 'react-dom';
import {IntlProvider} from 'react-intl';
import Container from './Container.react';
import ServerActions from './actions/ServerActions';
import ProfileActions from './actions/ProfileActions';
import WebAPIUtils from './utils/WebAPIUtils';
import i18nLoader from '../../i18n';

export default class DialogGuestController {

	constructor($scope, $http, Session, vngageConfig, conversationRef, loadConfiguration) {
		'ngInject';

		if (!conversationRef) {
			return;
		}

		this.conversationRef = conversationRef;
		this.groupList = loadConfiguration.groups;

		// we've got an identified user profile
		ProfileActions.profileIdentified(Session.user);

		// init connections
		WebAPIUtils.init($http, this.conversationRef, vngageConfig);

		// load language then render
		i18nLoader(Session.user.profile.language, this.renderApp.bind(this));

		// upon destroy scope, clean up
		$scope.$on('$destroy', function() {
			ServerActions.clearConversations();
			WebAPIUtils.dispose();
			ReactDOM.unmountComponentAtNode(document.getElementById('vngage-guest-app'));
		});
	}

	renderApp (i18n) {
		const locale = (Array.isArray(i18n.locales) ? i18n.locales[0] : i18n.locales);
		ReactDOM.render(
			<IntlProvider locale={locale} messages={i18n.messages}>
				<Container conversationRef={this.conversationRef} groupList={this.groupList} {...i18n} />
			</IntlProvider>,
			document.getElementById('vngage-guest-app')
		);
	}
}
