import IdentityProviderAddModalTemplate from './IdentityProviderAddModalTemplate';

export default function (APIEndpointService, $uibModal, $state, identityProviderList) {
	'ngInject';

	var vm = this;
	vm.identityProviderList = identityProviderList;

	vm.tableOptions = {
		stateLink: {
			state: 'root.account.identityProvider.edit',
			property: 'data.id'
		},
		createItem: {
			langKey: 'identityProvider.ADD_HEADER',
			method: addNew
		}
	};
	vm.tableColumns = [{
		header: 'Name',
		property: 'data.name',
		sortDefault: true,
		useInFilter: true
	}, {
		header: 'GUID',
		property: 'data.id',
		hidden: true,
		useInFilter: true
	}];

	function openAddModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: IdentityProviderAddModalTemplate,
			controller: 'IdentityProviderAddModalController as vm'
		});
		modal.result.then(newIdp => {
			vm.identityProviderList.push(newIdp);
			$state.go('.edit', {id: newIdp.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		$state.go('root.account.identityProvider').then(openAddModal);
	}
};
