import monitorUsersOnlineTemplate from './monitorUsersOnlineTemplate';

export default function (crossfilter, dc, UsersOnlineDimensionService, d3, monitorConversationsService) {
	'ngInject';

	return {
		restrict: 'A',
		scope: {
			users: '=',
			userlogout: '=',
		},
		templateUrl: monitorUsersOnlineTemplate,
		link: function (scope) {
			//TODO: implement trim on username and tidy up
			scope.monitorConversationsService = monitorConversationsService;

			// fix for data reload closing popup
			scope.isopen = false;
			scope.togglepopup = () => scope.isopen = !scope.isopen;

			function trim(value) {
				if (value.length > 20) {
					return value.substring(0, 21) + '...';
				}
				return value;
			}
		}
	};
}
