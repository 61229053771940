export default function ($uibModalInstance, APIConfigurationSectionService, options, defaultTemplates, $scope) {
	'ngInject';

	var vm = this;
	vm.options = options;
	vm.cancelNew = $uibModalInstance.dismiss;
	vm.saveNew = saveNew;
	vm.setScript = setScript;

	vm.newData = {
		name: '',
		section: {
			script: ''
		}
	};

	vm.templateCodeUpdatedTimestamp = 0;

	$scope.$watch('vm.defaultTemplateObj', function (defaultTemplateObj) {
		vm.newData.section.script = defaultTemplateObj
			? defaultTemplateObj.script
			: '';
		vm.templateCodeUpdatedTimestamp = Date.now(); // This will trigger a codemirror-refresh
	});

	vm.defaultTemplates = defaultTemplates;

	function setScript({script}) {
		vm.newData.section.script = script;
	}

	function saveNew() {
		const type = APIConfigurationSectionService.sectionMap.renderTemplate;
		vm.isSaving = true;
		$uibModalInstance.freeze(true);

		APIConfigurationSectionService.create(vm.newData, type, false)
			.then(function (res) {
				$uibModalInstance.close(res);
			})
			.catch(function () {
				$uibModalInstance.freeze(false);
				vm.isSaving = false;
			});
	}
};
