import AppConstants from '../../../components/constants/AppConstants';

const ActionTypes = AppConstants.ActionTypes;
import immutable from 'immutable';

export default {
	resultArray: [],
	resultObj: {},

	// account analysis
	analyzeAccount(account) {
		this.resultObj = {};
		this.analyzeObject(account, [], this.checkMissingValue, this.collectMissingValues);
		return this.resultObj;

	},

	addErrorsToObj(list, errorMsg, type, url, errors, severity = 'benign') {
		return list.reduce((obj, id) => {
			if (!obj[id]) {
				obj[id] = {name: '', type, url: url + id, errors: []};
			}
			obj[id].errors.push({errorMsg, severity});
			return obj;
		}, errors);
	},

	deepAnalysis(account) {
		let errors = {};
		const userUrl = '#/account/user/';
		const groupUrl = '#/configure/group/';
		const solutionUrl = '#/engage/solution/';
		const caseTypeUrl = '#/configure/casetype/';
		let userGroupIds = [...account.user.groupIds];

		// user
		const userHasUndefinedGroups = userGroupIds.filter(id => !account.groups[id]);
		errors = this.addErrorsToObj(userHasUndefinedGroups, `user has undefined groups [${userHasUndefinedGroups.join(' ')}]`, 'user', userUrl, errors, 'crash');
		// use empty userGroupIds if there is a bad group to prevent analysis from crashing
		userGroupIds = userHasUndefinedGroups.length > 0
			? []
			: userGroupIds;

		// groups
		const groupsWithBadCase = userGroupIds.filter(id => !account.groups[id].caseTypes || account.groups[id].caseTypes.length === 0);
		errors = this.addErrorsToObj(groupsWithBadCase, 'has no caseTypes', 'group', groupUrl, errors);

		const groupsWithBadVars1 = userGroupIds.filter(id => account.groups[id].maxInteractionsPerUser && account.groups[id].maxInteractionsPerUser === 0);
		errors = this.addErrorsToObj(groupsWithBadVars1, 'bad maxInteractionsPerUser', 'group', groupUrl, errors);

		const groupsWithBadVars2 = userGroupIds.filter(id => account.groups[id].maxVisitorsPerUserOnline && account.groups[id].maxVisitorsPerUserOnline === 0);
		errors = this.addErrorsToObj(groupsWithBadVars2, 'bad maxVisitorsPerUserOnline', 'group', groupUrl, errors);

		const groupsWithBadVars3 = userGroupIds.filter(id => account.groups[id].caseTypes && account.groups[id].caseTypes.some(c => !account.customerCases[c]));
		errors = this.addErrorsToObj(groupsWithBadVars3, 'has a deleted caseType', 'group', groupUrl, errors, 'crash');

		const solutionGroups = Object.keys(account.solutions).map(id => account.solutions[id].section && account.solutions[id].section.groupId ? account.solutions[id].section.groupId: '');
		const missingSolutions = userGroupIds.filter(id => solutionGroups.indexOf(id) === -1);
		errors = this.addErrorsToObj(missingSolutions, 'group has no solution', 'group', groupUrl, errors);


		// find solutions that are not part of any opportunity
		const userSolutions = Object.keys(account.solutions).filter(id => account.solutions[id].section && account.solutions[id].section.groupId && userGroupIds.indexOf(account.solutions[id].section.groupId) > -1);
		const opportunitySolutions = Object.keys(account.opportunities).reduce((list, id) => {
			const opportunity = account.opportunities[id];
			const hasSolutions = opportunity.section && opportunity.section.solutions && opportunity.section.solutions.length > 0;
			return hasSolutions
				? list.concat(opportunity.section.solutions.filter(c => list.indexOf(c) === -1 && userSolutions.indexOf(c) > -1))
				: list;

		}, []);
		const solutionsMissingOpportunity = userSolutions.filter(id => opportunitySolutions.indexOf(id) === -1);
		errors = this.addErrorsToObj(solutionsMissingOpportunity, 'solution has no opportunity', 'solution', solutionUrl, errors);

		// cases
		const userGroupsCases = userGroupIds.reduce((list, id) => account.groups[id].caseTypes && account.groups[id].caseTypes.length > 0
			? list.concat(account.groups[id].caseTypes.filter(c => list.indexOf(c) === -1))
			: list, []);

		const badCases = userGroupsCases.filter(id => !account.customerCases[id]);
		errors = this.addErrorsToObj(badCases, 'deleted case type', 'caseType', caseTypeUrl, errors, 'crash');

		const okCases = userGroupsCases.filter(id => !!account.customerCases[id]);


		// duplicate cannedResponses in case
		const duplicateCannedResponses = okCases
			.filter(id => account.customerCases[id].section && account.customerCases[id].section.cannedResponses && account.customerCases[id].section.cannedResponses
				.some((cannedResponse, index) => account.customerCases[id].section.cannedResponses.slice(index + 1).includes(cannedResponse)));
		errors = this.addErrorsToObj(duplicateCannedResponses, 'duplicate canned responses', 'caseType', caseTypeUrl, errors, 'crash');

		const missingClosures = okCases.filter(id => !account.customerCases[id].section.closures || account.customerCases[id].section.closures.length === 0);
		errors = this.addErrorsToObj(missingClosures, 'caseType has no closures', 'caseType', caseTypeUrl, errors);

		// set names
		Object.keys(errors).forEach(key => {
			const err = errors[key];
			switch (err.type) {
				case 'group':
					err.name = account.groups[key].name;
					break;
				case 'caseType':
					err.name = account.customerCases[key] && account.customerCases[key].name
						? account.customerCases[key].name
						: key;
					break;
				case 'solution':
					err.name = account.solutions[key].name;
					break;
				case 'user':
					err.name = account.user.id;
					break;
			}
		});
		return errors;


		// check user group maxVisitors and maxInteractions if applicable
		// find user solutions associated to user groups
		// find opportunities associated to user solutions
		// check that all groups are covered

		// check that user group has caseType
		// get caseTypes for user groups
		// check that caseType exists and that it has closure

	},

	collectMissingValues(prop, value, path, resultObj) {
		if (!resultObj[prop]) {
			resultObj[prop] = [];
		}
		resultObj[prop].push(path.join(':') + ':' + prop + '=' + value);

	},

	checkMissingValue(prop, value) {
		return (typeof value !== 'object') && (value === undefined || value === null || value === '' || value === '-');
	},

	findEmptyGuids(actions) {
		return actions.map((action, index) => action.set('index', index))
			.filter(action => action.getIn(['action', 'type']) !== ActionTypes.RECEIVE_VISITORS && JSON.stringify(action.get('action')).indexOf('00000000') !== -1)
			.map(action => action.get('index'));
	},

	findErrorActions(actions) {
		return this.findActionsByName(actions, 'error');
	},

	findFirstActionByType(actions, type) {
		return actions.find(action => action.getIn(['action', 'type']) === type);
	},

	findRequestUrl(actions) {
		const action = this.findFirstActionByType(actions, 'LOG_REQUEST');
		return action.getIn(['action', 'data', 'requestParams', 0]) || '';
	},


	findActionsByName(actions, searchString) {
		return actions.map((action, index) => action.set('index', index))
			.filter(action => action.getIn(['action', 'type']).toLowerCase().indexOf(searchString) !== -1)
			.map(action => action.get('index'));
	},

	findBadConnection(actions) {
		const indexedActions = actions.map((action, index) => action.set('index', index));
		return indexedActions
			.filter(action => action.getIn(['action', 'type']) === ActionTypes.RECEIVE_SINCE)
			.filter(action => action.getIn(['action', 'rawMessages'])
				.some(msg => msg.has('connectionState') && msg.get('connectionState') !== 'ok'))
			.map(action => action.get('index'))
			.concat(indexedActions
				.filter(action => action.getIn(['action', 'type']) === ActionTypes.RECEIVE_AS_STATE)
				.filter(action => action.getIn(['action', 'conversationState', 'events'])
					.some(msg => msg.has('connectionState') && msg.get('connectionState') !== 'ok'))
				.map(action => action.get('index')))
			.sort((a, b) => a - b);
	},

	findBlockedUrls(actions) {
		const indexedActions = actions.map((action, index) => action.set('index', index));
		return indexedActions
			.filter(action => action.getIn(['action', 'type']) === ActionTypes.RECEIVE_SINCE)
			.filter(action => action.getIn(['action', 'rawMessages'])
				.some(msg => msg.has('classification') && msg.get('classification') !== 'ok'))
			.map(action => action.get('index'))
			.concat(indexedActions
				.filter(action => action.getIn(['action', 'type']) === ActionTypes.RECEIVE_AS_STATE)
				.filter(action => action.getIn(['action', 'conversationState', 'messages'])
					.some(msg => msg.has('classification') && msg.get('classification') !== 'ok'))
				.map(action => action.get('index')))
			.sort((a, b) => a - b);
	},
	// main
	analyzeObject(obj, path, checkFunction, collectFunction) {
		if (typeof obj === 'object') {
			for (let i in obj) {
				if (checkFunction(i, obj[i])) {
					collectFunction(i, obj[i], path, this.resultObj);
				}
				const next = this.analyzeObject(obj[i], path.concat([i]), checkFunction, collectFunction);
				if (next !== null) {
					return next;
				}
			}
		}
		return null;
	},

	//
	// Poll analysis
	//

	getPollActions(actions) {
		return actions
			.map((action, index) => action.set('index', index))
			.filter(action => action.getIn(['action', 'type']).includes('POLL'))
			.map((action) => action.toJS())
			.map(action => {
				return {
					timestamp: action.timestamp,
					type: action.action.type,
					netId: action.action.netId,
					index: action.index,
					pollStats: action.action.pollStats || {},
					error: action.action.error || {}
				};
			});
	},

	getPollData(pollActions) {
		const lookupObj = pollActions.reduce((lookup, action) => {
			const {type, netId, timestamp, index, pollStats, error} = action;
			const obj = lookup[netId] || {
				netId,
				start: 0,
				end: 0,
				duration: 0,
				index: 0,
				status: 'ok',
				pollStats,
				pollStatsAfter: {},
			};
			switch (type) {
				case ActionTypes.NET_POLL:
					obj.start = timestamp;
					obj.index = index;
					obj.pollStats = pollStats;
					break;
				case ActionTypes.NET_POLL_DONE:
					obj.end = timestamp;
					obj.pollStatsAfter = pollStats;
					break;
				case ActionTypes.NET_POLL_WARNING:
					obj.end = timestamp;
					obj.error = error;
					obj.status = 'warning';
					obj.pollStatsAfter = pollStats;
					break;
				case ActionTypes.NET_POLL_ERROR:
					obj.end = timestamp;
					obj.error = error;
					obj.status = 'error';
					obj.pollStatsAfter = pollStats;
					break;
				case ActionTypes.NET_POLL_SKIP:
					obj.start = timestamp;
					obj.end = obj.start;
					obj.status = 'skip';
					break;
				default:
					break;
			}
			if (obj.start > 0 && obj.end > 0) {
				obj.duration = obj.end - obj.start;
			}
			return {
				...lookup,
				[netId]: obj
			};
		}, {});

		// convert object to array
		const sortedKeys = Object.keys(lookupObj)
			.map(key => parseInt(key))
			.sort((a, b) => a - b);
		return sortedKeys.map(key => lookupObj[key]);
	},

	getChartData(pollData) {
		const okY = 1;
		const skipY = 2;
		const errorY = 3;

		const okData = pollData
			.filter(item => item.status === 'ok')
			.reduce((list, item) => {
				const {start, end} = item;
				const coords = [
					{x: start, y: okY, item},
					{x: end, y: okY, item},
					{x: end, y: null},
				];
				return list.concat(coords);
			}, []);

		const skipData = pollData
			.filter(item => item.status === 'skip')
			.reduce((list, item) => {
				const {start, end} = item;
				const coords = [
					{x: start, y: skipY, item},
					{x: end, y: skipY, item},
					{x: end, y: null},
				];
				return list.concat(coords);
			}, []);

		const errorData = pollData
			.filter(item => item.status === 'error' || item.status === 'warning')
			.map(item => {
				const {start, end} = item;
				return {
					x0: start,
					x1: end,
					y: errorY,
					item
				};
			})
			.reduce((result, item, index) => {
				const y = result.length > 0
					? item.x0 > result[index - 1].x1
						? errorY - 1
						: result[index - 1].y
					: errorY - 1;

				return result.concat({
					...item,
					y: y + 1
				});
			}, [])
			.map(newItem => {
				const {x0, x1, y, item} = newItem;
				return [
					{x: x0, y, item},
					{x: x1, y, item},
					{x: x1, y: null},
				];
			})
			.reduce((list, item) => list.concat(item), []);

		return [
			{
				id: 'ok',
				color: 'hsl(246, 70%, 50%)',
				data: okData
			}, {
				id: 'skip',
				color: 'hsl(333, 70%, 50%)',
				data: skipData
			}, {
				id: 'error',
				color: 'hsl(211, 70%, 50%)',
				data: errorData
			},


		];
	},

	getMetrics(data) {
		const n = data.length;
		const mean = Math.round(data.reduce((sum, value) => sum + value, 0) / n);
		const min = Math.min(...data);
		const max = Math.max(...data);
		const median = [...data].sort((a, b) => a - b)[Math.floor(n / 2)];
		const std = Math.sqrt(data.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / n) || Infinity;
		return {mean, min, max, median, std};
	},

	//
	// Poll analysis END
	//

	getRequestsData(actions) {
		return actions
			.map((action, index) => action.set('index', index))
			.filter(action => action.getIn(['action', 'type']) === 'LOG_REQUEST')
			.map((action) => action.setIn(['action', 'data', 'index'], action.get('index')))
			.map((action) => action.setIn(['action', 'data', 'timestamp'], action.get('timestamp')))
			.map((action) => action.getIn(['action', 'data']).toJS());
	},

	// search in actions
	findInActions(actions, actionString = '', searchString = '') {
		return actions.map((action, index) => action.set('index', index))
			.filter(action => action.getIn(['action', 'type']).toLowerCase().indexOf(actionString) !== -1)
			.filter(action => JSON.stringify(action.get('action')).toLowerCase().indexOf(searchString) !== -1)
			.map(action => action.get('index'));
	},

	// search in states
	findInStates(actions, actionString = '', searchString = '', getStateAt) {
		return actions.map((action, index) => action.set('index', index))
			.filter(action => action.getIn(['action', 'type']).toLowerCase().indexOf(actionString) !== -1)
			.filter(action => (JSON.stringify(getStateAt(action.get('index')) || {})).toLowerCase().indexOf(searchString) !== -1)
			.map(action => action.get('index'));
	},

	//
	// MyDetails scan
	//
	findRemovalPoints(actions) {
		const filteredActions = actions.map((action, index) => action.set('index', index))
			.filter(action => action.getIn(['action', 'type']).indexOf('PROCESS_CONVERSATION_DETAILS') !== -1);

		return filteredActions
			.map((action, index) => {
				const currentNumberOfConversations = action.getIn(['action', 'details']).size;
				const previousIndex = index === 0
					? 0
					: index - 1;
				const previousNumberOfConversations = filteredActions[previousIndex].getIn(['action', 'details']).size;
				return action.set('conversationDiff', currentNumberOfConversations - previousNumberOfConversations);
			})
			.filter(action => action.get('conversationDiff') < 0);
	},

}
;

