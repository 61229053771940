import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function ($scope, APIEndpointService, $stateParams, actionPanel, $state, VngageUtil, actionList, ActionPanelsService, APIConfigurationSectionService, vngageRemoveModalService) {
	'ngInject';

	var vm = this;

	actionPanel.data.section.actions = actionPanel.data.section.actions || [];

	vm.actionPanelId = $stateParams.id;
	vm.actionPanel = actionPanel;
	vm.actionList = actionList;
	vm.selectableActions = ActionPanelsService.formatSelectableActions(actionList);
	vm.submit = submit;
	vm.remove = remove;
	vm.cancel = cancel;
	vm.currentList = $stateParams.currentList || [];

	$scope.$on('resetResettables', () => {
		vm.apForm.$setPristine();
		actionPanel.$get().catch(() => {
		});
	});

	function cancel() {
		$state.go('^');
	}

	function submit() {
		vm.isSaving = true;
		actionPanel.$save().finally(function () {
			vm.isSaving = false;
		});
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: actionPanel,
			itemType: APIConfigurationSectionService.sectionMap.actionPanel
		}).then(function () {
			vm.apForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(actionPanel, 'root.configure.actionPanel.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(function () {
			vm.isSaving = false;
		});
	}
}
