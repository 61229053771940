export default function () {
	'ngInject';

	var DISMISSED_REASON = '5D111DBD-665A-4DAD-9F24-AE93AF7C4A71',
		EMPTY_GUID       = '00000000-0000-0000-0000-000000000000';

	var ActiveCase = function (props, cases) {

		this.props = props || {};
		this.props.forms = {};
		this.cases = cases;
		this.defaultCaseType = cases[0];
	};

	ActiveCase.prototype.assign = function (props) {
		angular.extend(this.props, props);
		this.verifyCaseType();
	};

	ActiveCase.prototype.close = function (outcome, closureOption) {
		this.props.caseTypeRevision = 0;
		this.props.closureReason = outcome !== 'dismissed' ? closureOption.id : DISMISSED_REASON;
		this.props.closureReasonRevision = 0;
		this.props.closureReasonOutcome = outcome;
		this.props.closureReasonName = closureOption.name;
	};

	ActiveCase.prototype.decline = function () {
		this.props.caseTypeRevision = 0;
		this.props.closureReason = DISMISSED_REASON;
		this.props.closureReasonRevision = 0;
		this.props.closureReasonOutcome = 'negative';
		this.props.closureReasonName = 'declined';
	};

	ActiveCase.prototype.verifyCaseType = function () {
		let aCase = this.props.caseType;
		if (
			typeof aCase === 'undefined' ||
			aCase === EMPTY_GUID ||
			(this.cases.length && this.cases.indexOf(aCase) === -1)
		) {
			this.props.caseType = this.defaultCaseType;
		}

	};

	return ActiveCase;
}
