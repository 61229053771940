import DesktopLegacyTranslations from './DesktopLegacyTranslations';
import vngageConfig from '../vngageConfig';
import TabFlashService from './legacy/TabFlashService';

var translate = DesktopLegacyTranslations.translate,
	N = window.Notification,

	// Settings
	duration = 8,
	notificationOnChatMessages = true,
	notificationOnProactive = false,

	display = false,
	onFocusDuration = 1500,
	activeNotifications = {},
	iconPath = vngageConfig.assetPath + 'images/default/'; //OK with relative protocol?


var buttonClickHandler;

let isInitialized = false;

function init (options){

	if (isInitialized || typeof N === 'undefined') {
		return;
	}

	duration = setDuration(options.duration, duration);
	notificationOnChatMessages = options.notificationOnChatMessages !== false;
	notificationOnProactive = options.notificationOnProactive === true;

	permission();
	visibilityUpdater();
	isInitialized = true;
}



/**
 *    HELPERS
 */

function setDuration(value, defaultValue){
	value = parseInt(value, 0);
	return isNaN(value) ? defaultValue : value;
}

function getIconPath(name) {
	return iconPath + 'notification-' + name + '.gif';
}

function getFormattedTime() {
	var now = new Date();
	function f(time) { return time < 10 ? '0' + time : time; }
	return f(now.getHours()) + ':' + f(now.getMinutes()) + ':' + f(now.getSeconds());
}

function delay(fun, duration) {
	return window.setTimeout(fun, duration);
}


function permission() {

	if (N.permission) {
		if (N.permission === 'granted') {
			return;
		}
		if (N.permission === 'denied') {
			console.log('Web Notifications is blocked by your browser');
			return;
		}
	}

	var container = document.getElementById('notify');

	if (container) {
		console.error('no container for notifications!');
		return;
	}

	var contentArea = document.querySelector('#content'),
		button = document.createElement('button'),

		containerTxt = document.createTextNode(translate('notificationNotPermitted')),
		buttonTxt = document.createTextNode(translate('notificationNotPermittedButton'));

	button.appendChild(buttonTxt);

	buttonClickHandler = function(){
		N.requestPermission( function (choice) {
			N.permission = choice;
		});
		if (container){
			container.classList.add('hidden');
			button.removeEventListener('click', buttonClickHandler, false);
		}
	};
	button.addEventListener('click', buttonClickHandler, false);

	container = document.createElement('div');
	container.id = 'notify';
	container.className = 'notify stick-right intro hidden no-transition';
	container.appendChild(containerTxt);
	container.appendChild(button);

	contentArea.appendChild(container);

	delay(function () {
		container.classList.remove('no-transition');
		container.classList.remove('hidden');

		delay(function () {
			container.classList.remove('intro');
		}, 1200);
	}, 2000);
}

function visibilityUpdater() {
	var hidden,
		visibilityChange,
		isHidden = false,
		hasFocus = false;

	if (typeof document.hidden !== 'undefined') {
		hidden = 'hidden';
		visibilityChange = 'visibilitychange';
	} else if (typeof document.mozHidden !== 'undefined') {
		hidden = 'mozHidden';
		visibilityChange = 'mozvisibilitychange';
	} else if (typeof document.msHidden !== 'undefined') {
		hidden = 'msHidden';
		visibilityChange = 'msvisibilitychange';
	} else if (typeof document.webkitHidden !== 'undefined') {
		hidden = 'webkitHidden';
		visibilityChange = 'webkitvisibilitychange';
	}

	function closeNoteFactory(note) {
		return function () {
			note.close();
		};
	}

	function update() {
		display = !hasFocus || isHidden;

		if (!display) {

			TabFlashService.stop();

			// Window has gained focus
			chatNotification.unreadMessages = 0;

			// Hide all notes
			for (var tag in activeNotifications) {
				delay(closeNoteFactory(activeNotifications[tag]), onFocusDuration);
			}
		}
	}

	// Add some listeners
	window.addEventListener('blur', function () {
		hasFocus = false;
		update();
	}, false);

	window.addEventListener('focus', function () {
		hasFocus = true;
		update();
	}, false);

	document.addEventListener(visibilityChange, function () {
		isHidden = document[hidden];
		update();
	}, false);
}

/**
 *    NOTIFICATION METHODS
 */
function displayNotification() {
	return display && N && N.permission === 'granted';
}


function chatNotification(messages) {
	if (!displayNotification() || !messages.length) { return; }

	var latestMsg = messages[messages.length - 1].message.replace(/&#39;/g, '\'');

	// Keep track of unread count
	chatNotification.unreadMessages = chatNotification.unreadMessages || 0;
	chatNotification.unreadMessages += messages.length;

	var srcText = 'notificationChatBody' + (chatNotification.unreadMessages > 1 ? 'Plural' : ''),
		body = chatNotification.unreadMessages + ' ' + translate(srcText) + '\n"' + latestMsg + '"\n';

	createNotification('chat', translate('notificationChatTitle'), body);
	TabFlashService.start();
}

function queueNotification(queueList) {
	var srcText = 'notificationQueueBody' + (queueList.length > 1 ? 'Plural' : ''),
		body = queueList.length + ' ' + translate(srcText);
	createNotification('queue', translate('notificationQueueTitle'), body);
}

function proactiveNotification(queueList) {
	var srcText = 'notificationQueueBody' + (queueList.length > 1 ? 'Plural' : ''),
		body = queueList.length + ' ' + 'proactive: ' + translate(srcText);
	createNotification('proactive', 'proactive:' + translate('notificationQueueTitle'), body);
}

function lockedVisitorNotification(){
	var title = translate('notificationVisitorTitle'),
		body = '';
	createNotification('locked', title, body);
}

function createNotification(type, title, body) {
	// Debounce, when called in fast succession
	window.clearTimeout(createNotification.debounce);
	createNotification.debounce = delay(function () {

		var note = new N(title, {
			body: getFormattedTime() + ' - ' + body + '\nVergic engage',
			icon: getIconPath(type),
			requireInteraction: true,
			tag: type
		});

		// Add note to list of active notifications
		activeNotifications[type] = note;

		// Add listeners to newly created note
		note.addEventListener('click', function () {
			this.close();
			window.focus();
		}, false);

		note.addEventListener('show', function () {
			if (duration > 0) {
				delay(function () {
					note.close();
				}, duration * 1000);
			}
		}, false);

		// Remove note from active notification list on close
		note.addEventListener('close', function () {
			delete activeNotifications[type];
		}, false);
	}, 100);

}

function notify (type, data) {
	if (typeof N === 'undefined') {
		console.log('Web Notifications API:', type, data);
		return;
	}

	if (!isInitialized){
		init();
	}

	if (!displayNotification()) {
		return;
	}

	if (type === 'lockedVisitor'){
		lockedVisitorNotification(data);
	}

	if (type === 'queueUpdate') {
		queueNotification(data);
	}
	if (type === 'proactiveUpdate' && notificationOnProactive) {
		proactiveNotification(data);
	}

	if (type === 'chatMessage' && notificationOnChatMessages){
		chatNotification(data);
	}
}

function dispose () {
	var container = document.getElementById('notify');
	if (container) {
		while (container.firstChild) {
			let child = container.firstChild;
			if (child.removeEventListener && buttonClickHandler) {
				child.removeEventListener('click', buttonClickHandler, false);
			}
			container.removeChild(container.firstChild);
		}

		container.parentNode.removeChild(container);
	}

	isInitialized = false;
}

export default {
	init: init,
	notify: notify,
	dispose: dispose
};
