import vngageAddModalTemplate from '../../../common/vngageAddModalTemplate';
import vngageImportModalTemplate from '../../../common/vngageImportModal/vngageImportModalTemplate';
import {fileImportHeaders} from './FileImportHeaders';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

export default function ($state, $uibModal, dataLists, APIConfigurationSectionService) {
	'ngInject';
	const vm = this;
	const caseType = dataLists.customerCase;
	vm.cannedResponseList = dataLists.cannedResponse;

	vm.tableColumns = [{
		header: 'Name',
		property: 'data.name',
		sortDefault: true,
		useInFilter: true,
		width: '33%'
	}, {
		header: 'GUID',
		property: 'data.id',
		hidden: true,
		useInFilter: true
	}, {
		header: 'Case Types',
		property: 'data.id',
		useInFilter: true,
		type: 'label-list',
		transform: getCaseTypeName
	}];
	vm.tableOptions = {
		stateLink: {
			state: 'root.configure.cannedResponse.edit',
			property: 'data.id'
		},
		createItem: {
			langKey: 'cannedResponse.ADD_HEADER',
			method: addNew
		},
		importCSV: {
			langKey: 'uiElement.IMPORT_CSV',
			method: importNew,
			id: 'import-btn'
		}
	};

	function openAddModal() {
		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: vngageAddModalTemplate,
			controller: 'CannedResponseAddModalController as vm',
			resolve: {
				options: () => {
					return {
						header: 'cannedResponse.ADD_HEADER',
						label: 'cannedResponse.NAME'
					};
				},
				cannedResponseList: () => {
					return vm.cannedResponseList;
				},
			}
		});
		modal.result.then(newCannedResponse => {
			vm.cannedResponseList.push(newCannedResponse);
			$state.go('.edit', {id: newCannedResponse.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function openImportModal() {
		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: vngageImportModalTemplate,
			controller: 'ImportModalController as vm',
			size: 'lg',
			resolve: {
				options: () => {
					return {header: 'cannedResponse.IMPORT_HEADER'};
				},
				itemsList: () => {
					return vm.cannedResponseList;
				},
				itemType: () => APIConfigurationSectionService.sectionMap.cannedResponse,
				isSection: () => true,
				headers: () => fileImportHeaders,
				extraData: () => {
					return null;
				}

			}
		});
		modal.result.then(newCannedResponses => {
		}).catch(() => {
			// Modal dismissed...
		});

	}

	function addNew() {
		$state.go('root.configure.cannedResponse').then(openAddModal);
	}

	function importNew() {
		$state.go('root.configure.cannedResponse').then(openImportModal);
	}

	function getCaseTypeName(cannedResponseGuid) {
		var resource = _map(_filter(caseType, function (caseType) {
			return _find(caseType.data.section.cannedResponses, function (id) {
				return cannedResponseGuid === id;
			});
		}), function (res) {
			return res.data.name;
		});
		const list = resource || [];
		const trimmedList = list.length > 8 ? list.slice(0, 7): list.slice(0, 8);

		if (trimmedList.length < list.length) {
			trimmedList.push('+' + (list.length - trimmedList.length) + ' more');
		}
		return trimmedList;
	}
}
