import React from 'react';
import {connect} from 'react-redux';
import {Button, Modal} from 'react-bootstrap';
import * as C from '../../../constants';
import ConversationActions from '../../../actions/ConversationActions';
import TimeFormat from '../../../utils/TimeFormat';
import AccountConfig from '../../../utils/AccountConfig';
import _sortBy from 'lodash/sortBy';
import ToolBarSectionType from '../../../constants/ToolBarSectionType';
import {useGetTranslation} from './../../LangContext';


export const BookedMeetingInfoSection = (props) => {
	const getTranslation = useGetTranslation();
	const reservation = props.reservation.toJS();
	const hideModal = () => {
		props.removeActiveSection(props.conversationId, ToolBarSectionType.RESERVATION);
	};

	const analyzeCustomFormData = (reservation) => {
		// Convert to array, sorted on 'postition'...
		reservation.metadata.customForms = _sortBy(reservation.metadata.customForms, ['position']);

		// Remove customForm elements that are 'hiddenForAgent'
		reservation.metadata.customForms = reservation.metadata.customForms.filter(item => !item.hiddenForAgent);

		/*
				// Uncomment to concatenate present fullName instead (firstName + ' ' + lastName)
				const firstNameItem=_find(reservation.metadata.customForms, data  => (['name','firstName'].indexOf(data.name) > -1));
				const lastNameItem=_find(reservation.metadata.customForms, { 'name': 'lastName' });
				const fullName = (firstNameItem ? firstNameItem.value : '?') + ' ' + (lastNameItem ? lastNameItem.value : '?');
				reservation.metadata.customForms.unshift({
					'name': 'fullName',
					'label': getTranslation('reservationFullName'),
					'type': 'text',
					'validateAs': 'letters',
					'value': fullName
				});
				reservation.metadata.customForms = reservation.metadata.customForms.filter(form => {
					return (['name','firstName','lastName'].indexOf(form.name) === -1);
				});
		*/

		// Also add group name and start time first
		reservation.metadata.customForms.unshift({
			'name': '__groupName',
			'label': getTranslation('reservationGroup'),
			'type': 'text',
			'validateAs': 'text',
			'value': AccountConfig.getGroupName(reservation.groupId)
		});
		reservation.metadata.customForms.unshift({
			'name': '__meetingStartTime',
			'label': getTranslation('reservationStartTime'),
			'type': 'time',
			'validateAs': 'time',
			'value': reservation.start
		});

		reservation.metadata.customForms_analyzed = true;
	};
	const getFormElement = (form, n) => {
		let content = null;

		switch (form.validateAs) {
			case C.ReservationFormType.TIME:
				content = TimeFormat.asReservationTime(form.value, true);
				break;
			default:
				content = form.value;
				break;
		}

		const divStyle = {};
		if (form.name === '__groupName') {
			divStyle.marginBottom = '10px';
		}

		return (
			<div className="row" key={n} style={divStyle}>
				<span className="form-label col-4 break-word">{form.label}</span>
				<span className="form-content col-8 break-word">{content}</span>
			</div>
		);
	};

	if (!reservation) {
		return null;
	}
	if (!reservation.metadata.customForms) {
		reservation.metadata.customForms = [];
	}
	if (!reservation.metadata.customForms_analyzed) {
		analyzeCustomFormData(reservation);
	}

	return (
		<Modal className="booked-meeting-info-section-modal" show={true} onHide={hideModal}>
			<Modal.Header closeButton>
				<Modal.Title>{getTranslation('bookedMeetingInformationTitle')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{reservation.metadata.customForms.map((form, n) => getFormElement(form, n))}
			</Modal.Body>
			<Modal.Footer>
				<Button className="btn btn-primary" onClick={hideModal}>{getTranslation('bookedMeetingInformationCloseBtn')}</Button>
			</Modal.Footer>
		</Modal>
	);

};

const mapStateToProps = (state, ownProps) => {
	// INCOMING
	// conversationId
	const conversationId = ownProps.conversationId;
	const conversation = state.getIn(['conversations', conversationId]) || immutable.fromJS({events: []});

	const event = conversation.get('events').findLast(event => event.get('type') === 'conversationReservation');
	const reservation = !!event
		? event.get('reservation')
		: null;

	return {
		reservation,
	};
};

function mapDispatchToProps(dispatch) {
	return {
		removeActiveSection: (conversationId, section) => dispatch(ConversationActions.removeActiveSection(conversationId, section)),

	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BookedMeetingInfoSection);

