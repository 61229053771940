import moment from 'moment-timezone';
import _get from 'lodash/get';

export default function (uiCalendarConfig, groupList, userList, $uibModal, $state, myHistoryService, caseBrowserModalService, APIEndpointService, usSpinnerService, Session) {
	'ngInject';

	var vm = this;
	vm.workspaceLang = _get(Session, 'user.profile.language', 'en').split('-')[0];
	// console.log('[ FL ] myHistoryController initiate!');
	vm.groupList = groupList;
	vm.userList = userList;
	console.log(vm.groupList);
	var eventClickHandler = function (event) {
		console.log(event);
		viewCase(event.id);
		return;
	}
	var dayClickHandler = function (event) {
		return;
	}

	function viewCase(caseId) {
		vm.loadingCase = true;
		usSpinnerService.spin('spinner-view-case-loading');
		APIEndpointService.case.get({id: caseId}).$promise.then(function (res) {
			caseBrowserModalService.open({
				caseItem: res,
				userList: vm.userList
			}).catch(() => {
				// Modal dismissed...
			});
		}).finally(function () {
			vm.loadingCase = false;
			usSpinnerService.stop('spinner-view-case-loading');
		});
	}

	vm.uiConfig = {
		// todo why is the calendar not responding to height?
		calendar: {
			lang: vm.workspaceLang,
			locale: vm.workspaceLang,
			height: 300,
			firstDay: 1,
			minTime: moment.duration('08:00:00'),
			defaultView: 'agendaWeek',
			header: {
				left: 'title',
				center: 'agendaWeek,agendaDay',
				right: 'today prev,next'
			},
			dayClick: dayClickHandler,
			eventClick: eventClickHandler,
			slotEventOverlap: false,
			allDaySlot: false,
			slotLabelFormat: 'H:mm',
			views: {
				day: {
					timeFormat: 'H:mm'
				},
				week: {
					timeFormat: 'H:mm',
					columnFormat: 'dddd'
				}
			}
		}
	};

	vm.calendarEventSources = {
		cases: {
			stick: true,
			events: function (start, end, timezone, callback) {
				myHistoryService.queryCases({
					range: {
						from: start,
						to: end
					}
				}).then(function (res) {
					callback(res);
				});

			}
		}
	}
	vm.calendarEvents = [vm.calendarEventSources.cases];
};
