import BannerEditMappingAddEditModalTemplate from './BannerEditMappingAddEditModalTemplate';
import _cloneDeep from 'lodash/cloneDeep';
import _isArray from 'lodash/isArray';
import _forEach from 'lodash/forEach';
import _isEqual from 'lodash/isEqual';
import _remove from 'lodash/remove';
import _findIndex from 'lodash/findIndex';

export default function ($state, $scope, banner, NotificationService, groupList, caseTypeList, BannerEditMappingService, vngageGuid, $uibModal, $timeout) {
	'ngInject';

	var vm = this;
	vm.bannerConfigurationForm = $scope.$parent.vm.bannerConfigurationForm; // This is a child controller to BannerEditController: Get the $parent's vm.bannerConfigurationForm
	vm.banner = banner;
	vm.groupList = groupList;
	vm.caseTypeList = caseTypeList;

	var originalSubjectMappingObject = _cloneDeep(vm.banner.data.section.subjectMapping);
	if (!_isArray(vm.banner.data.section.subjectMapping)) {
		// No existing subjectMapping in this banner...

		if (vm.banner.data.section.pairing) {
			// But pairing exists: Migrate data from it
			console.log('Migrating data from pairing -> subjectMapping');
			vm.banner.data.section.subjectMapping = Object.keys(vm.banner.data.section.pairing).map((key, index) => {
				var mapping = vm.banner.data.section.pairing[key];
				mapping.id = vngageGuid.generate();
				mapping.groupId = key;
				mapping.position = index;
				mapping.translationId = mapping.translation;
				mapping.translationName = mapping.displayName;
				mapping.subjectMappingName = mapping.displayName;
				delete mapping.translation;
				delete mapping.displayName;
				return mapping;
			});
		} else {
			vm.banner.data.section.subjectMapping = [];
		}
	}
	_forEach(vm.banner.data.section.subjectMapping, (mapping, index) => {
		// Make sure data in subjectMapping is in new format
		if (!mapping.translationId && mapping.translation) {
			mapping.translationId = mapping.translation;
		}
		if (!mapping.translationName && mapping.displayName) {
			mapping.translationName = mapping.displayName;
		}
		if (!mapping.subjectMappingName && mapping.displayName) {
			mapping.subjectMappingName = mapping.displayName;
		}
		delete mapping.translation;
		delete mapping.displayName;
	});

	if (!_isEqual(originalSubjectMappingObject, vm.banner.data.section.subjectMapping)) {
		// console.log('dirty after migration!',vm.banner.data.section.subjectMapping);
		$timeout(() => {
			vm.bannerConfigurationForm.$setDirty();
		});
	}

	vm.mappingListOrder = BannerEditMappingService.positionSetter(vm.banner.data.section.subjectMapping);
	vm.mappingTableColumns = [{
		header: '',
		property: '',
		transform: vm.mappingListOrder.position,
		sortDefault: true
	}, {
		header: 'banner.MAPPING_LIST_COL_NAME',
		property: 'subjectMappingName',
		useInFilter: true,
		addBadges: (item) => {
			return (item.defaultMapping ? ['default'] : []);
		},
		nowrap: true
	}, {
		header: 'GUID',
		property: 'id',
		hidden: true,
		useInFilter: true
	}, {
		header: 'banner.MAPPING_LIST_COL_GROUP',
		property: 'groupName',
		width: '28%',
		translate: true,
		useInFilter: true
	}, {
		header: 'banner.MAPPING_LIST_COL_CASETYPE',
		property: 'caseTypeName',
		translate: true
	}, {
		header: 'banner.MAPPING_LIST_COL_TRANSLATION',
		property: 'translationName',
		translate: true,
		useInFilter: true
	}];
	vm.mappingTableOptions = {
		priority: vm.mappingListOrder,
		hideIndex: true,
		stateLink: {
			method: (mapping) => {
				openEditModal(mapping);
			}
		},
		createItem: {
			langKey: 'banner.MAPPING_ADD_BTN',
			method: () => {
				openEditModal();
			}
		}
	};

	$scope.$watch('vm.banner.data.section.subjectMapping', function () {
		_forEach(vm.banner.data.section.subjectMapping, (mapping, index) => {
			if (typeof mapping.position !== 'undefined' && mapping.position != index) {
				vm.bannerConfigurationForm.$setDirty();
				return false;
			}
		});
	}, true);

	$scope.$watch('vm.banner.data.section.subjectMapping', function () {
		// Shallow watch for cloned/replaced vm.banner.data.section.subjectMapping: Update the reference in the ordered table if needed
		vm.mappingListOrder.setList(vm.banner.data.section.subjectMapping);
	});

	function openEditModal(existingMapping) {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: BannerEditMappingAddEditModalTemplate,
			controller: 'BannerEditMappingAddEditModalController as vm',
			resolve: {
				mapping: () => {
					return existingMapping;
				},
				banner: () => {
					return vm.banner;
				},
				groupList: () => {
					return vm.groupList;
				},
				caseTypeList: () => {
					return vm.caseTypeList;
				},
				translations: () => {
					return vm.banner.data.section.translations;
				}
			}
		});
		modal.result.then(savedMapping => {
			if (!savedMapping) {
				// Mapping removed
				_remove(vm.banner.data.section.subjectMapping, (mapping) => {
					return mapping.id === existingMapping.id;
				});
			} else {
				// Mapping added or edited
				var existingMappingId = _findIndex(vm.banner.data.section.subjectMapping, (mapping) => {
					return mapping.id === savedMapping.id;
				})
				if (existingMappingId === -1) {
					// Mapping added
					vm.banner.data.section.subjectMapping.push(savedMapping);
				} else {
					// Mapping edited
					vm.banner.data.section.subjectMapping[existingMappingId] = savedMapping;
				}
			}
			vm.banner.data.section.subjectMapping = _cloneDeep(vm.banner.data.section.subjectMapping);
			vm.bannerConfigurationForm.$setDirty();
		}).catch(() => {
			// Modal dismissed...
		});
	}
}
