import React from 'react';
import createReactClass from 'create-react-class';
import ProfileStore from '../stores/ProfileStore';
import MessageUtils from '../../../components/utils/MessageUtils';
import MessageList from './MessageList.react';
import _filter from 'lodash/filter';
import _find from 'lodash/find';

const ME_COLOR = 'white';
const VISIT_COLOR = '#e5ebf9';
const GUEST_COLOR = 'rgb(238, 238, 236)';

export default createReactClass({

	getInitialState () {
		return {
			messages: getMappedMessages(this.props.chatMessages, this.props.participants)
		};
	},

	render () {
		return (
			<div>
				<MessageList
					messages={this.state.messages}
					/>
			</div>
		)
	}
});

function getParticipant(participants, speakerId) {
	return _find(participants, p => {
		let id = p.participant.userId || p.participant.visitId;
		return id === speakerId
	});
}

function mapParticipants(participants) {
	return participants.map( participant => {
		let participantColor, speakerId;

		if (participant.participant.userId && participant.info.owner) {
			participantColor = ME_COLOR;
		} else if (participant.participant.userId && participant.info.role === 'internalExpert') {
			participantColor = GUEST_COLOR;
		} else {
			participantColor = VISIT_COLOR;
		}
		participant.colorId = participantColor;

		// mark participant if same as current logged in user
		speakerId = participant.participant.userId || participant.participant.visitId;
		if (speakerId === ProfileStore.getProfileId()) {
			participant.info.isCurrentUser = true;
		}

		return participant;
	})
}

function getMappedMessages(messages, participants) {

	let mappedParts = mapParticipants(participants);

	let messageTypes = ['chat', 'link', 'note', 'navigation'];
	let convertedMessages = [];

	messages.forEach( msg => {
		convertedMessages.push(MessageUtils.convertRawMessage(msg));
	});

	let conversationMsgs = _filter(convertedMessages, msg => {
		return messageTypes.indexOf(msg.messageType) > -1;
	});

	conversationMsgs.map( (msg) => {
		if (msg.speaker) {
			var speakerId = msg.speaker.userId || msg.speaker.visitId,
				participant = getParticipant(mappedParts, speakerId);

			msg.speaker = participant;
		}
	});

	return MessageUtils.sortByDate(conversationMsgs);
}
