import _find from 'lodash/find';

export default function(VngageUtil, $q) {
    'ngInject';

    var service = {
        formatSelectable: formatSelectable,
        formatSelected: formatSelected,
        getPendingUpdates: getPendingUpdates,
        saveResources: saveResources
    };

    function formatSelectable(resourceList, displayNameAccessString) {
        return resourceList.map(function(resource) {
            return {
                refValue: resource,
                displayName: VngageUtil.valByStr(resource, displayNameAccessString)
            };
        });
    }

    function formatSelected(resourceList, refId, listAccessString) {
        return resourceList.filter(function(resource) {
            if (!VngageUtil.valByStr(resource, listAccessString)) { return false; }
            return VngageUtil.valByStr(resource, listAccessString).filter(function(id) {
                return id === refId;
            }).length > 0;
        });
    }

    function getPendingUpdates(selectableList, selectedList, referenceList, listAccessString, refIdAccessString, refId) {
        var addRefIdTo = VngageUtil.arrayDeepPropDiff(referenceList, selectedList, refIdAccessString);

        // the referenceList is a deep copy so it doesn't point to the same resources
        // need to get the corresponding resources from the selectableList
        var removeRefIdFrom = VngageUtil.arrayDeepPropDiff(selectedList, referenceList, refIdAccessString);

        var removeRefIdFromOriginals = selectableList.filter(function(originalItem) {
            return !!_find(removeRefIdFrom, function(referenceItem) {
                return referenceItem.data.id === originalItem.data.id;
            });
        });
        var updatedResources = addRefIdToResources(addRefIdTo, refId, listAccessString);
        updatedResources = updatedResources.concat(removeRefIdFromResources(removeRefIdFromOriginals, refId, listAccessString));
        return updatedResources;
    }

    function addRefIdToResources(resourceList, refId, listAccessString) {
        return resourceList.map(function(resource) {
            var list = VngageUtil.valByStr(resource, listAccessString);
            list.push(refId);
            return resource;
        });
    }

    function removeRefIdFromResources(resourceList, refId, listAccessString) {
        return resourceList.map(function(resource) {
            var list = VngageUtil.valByStr(resource, listAccessString);
            list.splice(list.indexOf(refId), 1);
            return resource;
        });
    }

    function saveResources(selectableList, selectedList, referenceList, listAccessString, refIdAccessString, refId) {
        return $q.all(getPendingUpdates(selectableList, selectedList, referenceList, listAccessString, refIdAccessString, refId).map(function(resourceToSave) {
            return resourceToSave.$save();
        }));
    }

    return service;
};
