import immutable from 'immutable';
import AccountConfig from '../../utils/AccountConfig';

const subscriptions = {

	agentInfo(plugin, prevState, state, reset) {
		if (!reset) {
			return immutable.List();
		}
		return immutable.fromJS([{
			type: 'agentInfo',
			displayName: AccountConfig.getUserName(),
			preferredEmail: AccountConfig.getUserEmail(),
			externalIds: AccountConfig.getUserExternalIds()
		}]);

	},
};

export default {
	subscriptions
}

