export default {
	'en-GB': {
		banner: {
			LIST_HEADER: 'Banners',
			LIST_TITLE: 'List of banners for working site',
			LIST_DESCRIPTION: 'Banners are used to display offers of support to visitors in various ways. A banner is always tied to a solution (which contains info about how to help the visitor).',
			ADD_HEADER: 'New banner',

			EDIT_HEADER: 'Edit banner',

			DISPLAY_STATE_TRUE: 'active',
			DISPLAY_STATE_FALSE: 'inactive',

			TAB_GENERAL: 'General',
			TAB_SETTINGS: 'Settings',
			TAB_INTRO: 'Intro',
			TAB_HTML: 'HTML',
			TAB_CSS: 'CSS',
			TAB_SUBJECT_MAPPING: 'Subject mapping',
			TAB_TRANSLATIONS: 'Translations',

			ID: 'Id',
			NAME: 'Name',
			NAME_PLACEHOLDER: 'My new banner',
			DESCRIPTION: 'Description',
			DESCRIPTION_PLACEHOLDER: 'A short description of this banner',
			DISPLAY_STATE: 'Display banner when',
			OPEN: 'Open',
			BUSY: 'Busy',
			CLOSED: 'Closed',
			HTML: 'HTML',
			HTML_TAB: 'Tab HTML',
			HTML_TAB_PLACEHOLDER: 'Chat with us',
			TAG: 'Wrapper HTML tag',
			TAG_PLACEHOLDER: 'div',
			TARGET: 'Injection selector',
			TARGET_PLACEHOLDER: 'body',
			TARGET_METHOD: 'Injection method',
			TARGET_METHOD_APPEND: 'Append inside target',
			TARGET_METHOD_PREPEND: 'Prepend inside target',
			TARGET_METHOD_BEFORE: 'Insert before target',
			TARGET_METHOD_AFTER: 'Insert after target',
			TARGET_METHOD_REPLACE: 'Replace target element',
			INTRO_DURATION: 'Intro duration (seconds)',
			INTRO_DURATION_PLACEHOLDER: '0',
			INTRO_DELAY: 'Intro delay (seconds)',
			INTRO_DELAY_PLACEHOLDER: '0',
			INTRO_ONCE: 'Do intro once',
			INTRO_ONCE_TEMPLATE: 'Once per template',
			INTRO_QUARANTINE: 'Do once timeout until next intro (minutes)',
			INTRO_QUARANTINE_PLACEHOLDER: '60',
			EXTRA_CLASSES: 'Wrapper additional class names',
			EXTRA_CLASSES_PLACEHOLDER: 'example-class extra-wide',
			MOUSE_EXPANSION: 'Mouse expand',
			MOUSE_EXPAND: 'Expand when cursor enters banner',
			MOUSE_COLLAPSE: 'Collapse when cursor leaves banner',

			VISITOR_UI_MODE: 'Visitor UI mode',
			VISITOR_UI_MODE_DESCRIPTION: 'Select Visitor UI mode to use in dialogs started from this banner.',
			VISITOR_UI_MODE_HELP_TOOLTIP: 'Select Visitor application UI mode to use in dialogs started from this banner.<br><br>This should almost always be set to "Normal".<br>"Minimal UI" mode is only used in integration setups with other chat products, and that mode will not include UI elements for the visitor to chat with the agent.',
			VISITOR_UI_MODE_NORMAL: 'Normal',
			VISITOR_UI_MODE_MINIMAL: 'Minimal UI',

			TEMPLATE: 'Template',
			TEMPLATE_PLACEHOLDER: 'Choose a template',
			TEMPLATE_DOOREYE: 'Dooreye',
			TEMPLATE_INJECTED: 'Injected',
			TEMPLATE_TABBANNER: 'Tabbanner',
			TEMPLATE_TAKEOVER: 'Takeover',
			TEMPLATE_QUEUERESERVATION: 'Queue reservation',

			ADD_TEXT_KEY_HEADER: 'Add banner text key',
			ADD_TEXT_KEY: 'Text key name',
			ADD_TEXT_KEY_EXISTS: 'Text key already exists!',
			ADD_TEXT_KEY_PLACEHOLDER: 'title',
			ADD_TEXT_KEY_VALUE: 'Text key value',
			ADD_TEXT_KEY_VALUE_PLACEHOLDER: 'Text value for this key name',

			PREVIEW: 'Banner preview',
			PREVIEW_OPEN: 'Open',
			PREVIEW_BUSY: 'Busy',
			PREVIEW_CLOSED: 'Closed',
			PREVIEW_HIDE: 'Hide',
			PREVIEW_SHOW: 'Show',
			PREVIEW_EXPAND: 'Expand',

			CSS: 'Account CSS',
			CSS_DESCRIPTION: 'This CSS is shared for all banners across the account.',
			CSS_RESPONSIVE: 'Responsive banners',
			CSS_PRIMARY: 'Primary color',
			CSS_BACKGROUND: 'Banner background',

			REMOVE_HEADER: 'Remove banner "{{ name }}"',
			REMOVE_MESSAGE: 'Note that any existing references to this banner from solutions also will be removed.',

			MAPPING_HEADING: 'Access multiple groups with custom translation and case type',
			MAPPING_DESCRIPTION: 'Create translations and map them to different groups. This enables visitors to start conversations with any of these groups, in the provided translation, all from the same banner.',
			MAPPING_LIST_TITLE: 'List of subject mappings',
			MAPPING_ADD_BTN: 'New mapping',
			MAPPING_LIST_COL_NAME: 'Name',
			MAPPING_LIST_COL_GROUP: 'Group',
			MAPPING_LIST_COL_CASETYPE: 'Case type',
			MAPPING_LIST_COL_TRANSLATION: 'Translation',

			MAPPING_ADD_HEADER: 'New subject mapping of group and translation',
			MAPPING_ADD_HELP_SUBJECT: 'You are creating a new subject mapping',
			MAPPING_EDIT_HEADER: 'Edit subject mapping',
			MAPPING_EDIT_HELP_SUBJECT: 'You are editing a subject mapping',
			MAPPING_EDIT_HELP_TEXT: 'Please select a group, a case type and a translation to map into a new subject mapping. The name of the subject mapping is displayed to the visitor',
			MAPPING_EDIT_NAME: 'Name',
			MAPPING_EDIT_DEFAULT_MAPPING: 'Use as default mapping',
			MAPPING_EDIT_SELECT_GROUP: 'Select group',
			MAPPING_EDIT_SELECT_CASETYPE: 'Select case type',
			MAPPING_EDIT_SELECT_TRANSLATION: 'Select translation',
			MAPPING_EDIT_SELECT_GROUP_PLACEHOLDER: '- Select group -',
			MAPPING_EDIT_SELECT_CASETYPE_PLACEHOLDER: '- Select case type -',
			MAPPING_EDIT_SELECT_TRANSLATION_PLACEHOLDER: '- Select translation -',
			MAPPING_EDIT_ERROR_DUPLICATE_NAME: 'The name is already in use',
			MAPPING_EDIT_ERROR_DEFAULT_MAPPING: 'Another mapping is already set as default. Please uncheck that before setting this as default.',

			TRANSLATIONS_HEADING: 'Create translations or custom content for banners',
			TRANSLATIONS_DESCRIPTION: 'Translate or customize existing content in banners. You can also add new custom text in the section below. These will be added to all existing translations in the <a>#custom translation list</a>.',
			TRANSLATIONS_LIST_TITLE: 'List of translations',
			TRANSLATIONS_ADD_BTN: 'New translation',
			TRANSLATIONS_KEYS: 'Translation keys',

			TRANSLATION_ADD_HEADER: 'New translation',
			TRANSLATION_EDIT: 'Edit',
			TRANSLATION_EXPORT: 'Export',
			TRANSLATION_IMPORT: 'Import',
			TRANSLATION_REMOVE: 'Remove',
			TRANSLATION_EDIT_HEADER: 'Edit translation',
			TRANSLATION_EDIT_NAME: 'Name',
			TRANSLATION_EDIT_ERROR_DUPLICATE_NAME: 'The name is already in use',
			TRANSLATION_ADD_SELECT_BASE_TRANSLATION: 'Select base translation',
			TRANSLATION_ADD_HELP_SUBJECT: 'You are creating a new translation',
			TRANSLATION_EDIT_HELP_SUBJECT: 'You are editing a translation',
			TRANSLATION_EDIT_HELP_TEXT: 'Choose a display name for the translation, and select a base translation as a starting point and edit as you like.<br><br>The optional test direction (RTL) will be indicated by an icon in the list of your translations.',
			TRANSLATION_EXPORT_HEADER: 'Export translation',
			TRANSLATION_EXPORT_INGRESS: 'Translation JSON for export:',
			TRANSLATION_EXPORT_COPY_BTN: 'Copy to clipboard',
			TRANSLATION_IMPORT_HEADER: 'Import translation',
			TRANSLATION_IMPORT_INGRESS: 'Paste translation JSON to import:',
			TRANSLATION_IMPORT_MERGE_MODE_INGRESS: 'Select how to handle values for existing keys:',
			TRANSLATION_IMPORT_OVERWRITE: 'Overwrite existing values with imported',
			TRANSLATION_IMPORT_KEEP: 'Keep existing values',
			TRANSLATION_IMPORT_BTN: 'Import',

			TRANSLATIONS_CUSTOM_HEADING: 'Custom translation list',
			TRANSLATIONS_CUSTOM_DESCRIPTION: 'This is used in the HTML-tab e.g. &#123;&#123;= conf.Name &#125;&#125;',
			TRANSLATIONS_CUSTOM_REMOVE: 'Remove',
			TRANSLATIONS_CUSTOM_ADD_BTN: 'New custom key',
			TRANSLATIONS_CUSTOM_ADD_KEY_PLACEHOLDER: 'Name',
			TRANSLATIONS_CUSTOM_ADD_VALUE_PLACEHOLDER: 'Text',
			TRANSLATIONS_SET_AS_DEFAULT: 'Set as default',
			TRANSLATIONS_DEFAULT_TRANSLATION: 'Default translation',
			TRANSLATIONS_DEFAULT: 'Default',

			TRANSLATIONS_ERROR_MESSAGES_HEADING: 'Error code translations',
			TRANSLATIONS_ERROR_MESSAGES_DESCRIPTION: 'This is used to display/translate error codes from backend',
			TRANSLATIONS_ERROR_MESSAGES_REMOVE: 'Remove',
			TRANSLATIONS_ERROR_MESSAGES_ADD_BTN: 'New error code',
			TRANSLATIONS_ERROR_MESSAGES_ADD_KEY_PLACEHOLDER: 'Code',
			TRANSLATIONS_ERROR_MESSAGES_ADD_VALUE_PLACEHOLDER: 'Error text'
		},

		lessVars: {
			noDesc: 'No description available',
			transforms: '<i>Internal</i>',
			noTransforms: '<i>Internal: </i>Used to disable transforms',
			transitions: '<i>Internal: </i>Wrapper class for transitions',
			noTransitions: '<i>Internal: </i>Used do disable transitions',
			bannerDesktop: 'Media query values for desktop',
			bannerTablet: 'Media query values for tablet',
			bannerPhone: 'Media query values for phone',
			bannerNoPhone: 'Media query values for device smaller than phone',
			bannerImagePath: 'The base path to file storage i.e images for agent avatars. <i>If not default, make sure the domain is white-listed</i>',
			bannerResponsive: 'Boolean for making use of responsive banners. Set <i>false</i> to disable responsive banners. Applies to all banner types except injected.',
			bannerResponsiveFontSize: 'Font size for content on tablet and below. Applies to all banner types except injected.',
			bannerResponsiveBtnFontSize: 'Font size for buttons (<code>.vngage-butn</code>) on tablet and below. Applies to all banner types except injected.',
			bannerResponsiveColor: 'Content font color for tablet and below. Applies to all banner types except injected.',
			bannerResponsiveBgColor: 'Content background color for tablet and below. Applies to all banner types except injected.',
			bannerResponsiveHeaderColor: 'Main header color for tablet and below. Applies to all banner types except injected.',
			bannerShadow: 'Main shadow values. Applies to all banner types except injected.',
			bannerTextAlign: 'Main text alignment. Applies to all banner types except injected.',
			bannerTransitionDuration: 'Main transition time for banners. Applies to all banner types except injected.',
			bannerBtnTransitionDuration: 'Main transition time for buttons. Applies to all banner types except injected.',
			bannerZindex: 'Main z-index. Applies to all banner types except injected.',
			bannerPrimaryColor: 'Main primary color. Used for background on button and tab. Applies to Tab- and Dooreye banner.',
			bannerPrimaryTextColor: 'Main primary text color. Used in conjunction with the Primary Color on button and tab. Applies to Tab- and Dooreye banner.',
			bannerTextColor: 'Main text color. Applies to all banner types except injected.',
			bannerBgColor: 'Main banner background color. Applies to all banner types except injected.',
			bannerBgImage: 'Injects a background image on tab- and takeover banners. Takes an url or base-64. <i>If using url, make sure the domain is white-listed</i>',
			bannerFontSize: 'Applies to all banner types except injected.',
			bannerFontFamily: 'Applies to all banner types except injected.',
			bannerLineHeight: 'Applies to all banner types except injected.',
			bannerHeaderStart: '<i>Internal</i>',
			bannerHeaderEnd: '<i>Internal</i>',
			bannerH1Color: 'Applies to all banner types except injected.',
			bannerH1Size: 'Applies to all banner types except injected.',
			bannerH1Weight: 'Applies to all banner types except injected.',
			bannerH1Family: 'Applies to all banner types except injected.',
			bannerH1Margin: 'Applies to all banner types except injected.',
			bannerH1Padding: 'Applies to all banner types except injected.',
			bannerH2Color: 'Applies to all banner types except injected.',
			bannerH2Size: 'Applies to all banner types except injected.',
			bannerH2Weight: 'Applies to all banner types except injected.',
			bannerH2Family: 'Applies to all banner types except injected.',
			bannerH2Margin: 'Applies to all banner types except injected.',
			bannerH2Padding: 'Applies to all banner types except injected.',
			bannerH3Color: 'Applies to all banner types except injected.',
			bannerH3Size: 'Applies to all banner types except injected.',
			bannerH3Weight: 'Applies to all banner types except injected.',
			bannerH3Family: 'Applies to all banner types except injected.',
			bannerH3Margin: 'Applies to all banner types except injected.',
			bannerH3Padding: 'Applies to all banner types except injected.',
			bannerH4Color: 'Applies to all banner types except injected.',
			bannerH4Size: 'Applies to all banner types except injected.',
			bannerH4Weight: 'Applies to all banner types except injected.',
			bannerH4Family: 'Applies to all banner types except injected.',
			bannerH4Margin: 'Applies to all banner types except injected.',
			bannerH4Padding: 'Applies to all banner types except injected.',
			bannerParagraphSize: 'Applies to all banner types except injected.',
			bannerParagraphMargin: 'Applies to all banner types except injected.',
			bannerParagraphPadding: 'Applies to all banner types except injected.',
			bannerParagraphLineHeight: 'Applies to all banner types except injected.',
			overlayBackground: 'Main overlay background color. Applies to takeover banner on desktop and to all banner types except injected when responsive.',
			overlayOpacity: 'Main opacity on overlay background color. Applies to takeover banner on desktop and to all banner types except injected when responsive.',
			overlayZindex: 'Main z-index value for overlay. Applies to takeover banner on desktop and to all banner types except injected when responsive.',
			bannerLinkColor: 'Applies to all banner types except injected.',
			bannerLinkFontSize: 'Applies to all banner types except injected.',
			bannerLinkFontWeight: 'Applies to all banner types except injected.',
			bannerLinkTextDecoration: 'Applies to all banner types except injected.',
			bannerLinkColorHover: 'Applies to all banner types except injected.',
			bannerLinkTextDecorationHover: 'Applies to all banner types except injected.',
			bannerBtnColorNegative: 'Inverted button text color. Applies to all banner types except injected.',
			bannerBtnBgColorNegative: 'Inverted button background color. Applies to all banner types except injected.',
			bannerBtnBgColorNegativeStart: 'Inverted button background gradient <b>start</b> color. Applies to all banner types except injected.',
			bannerBtnBgColorNegativeEnd: 'Inverted button background gradient <b>end</b> color. Applies to all banner types except injected.',
			bannerBtnBorderColorNegative: 'Inverted button border color. Applies to all banner types except injected.',
			bannerBtnColor: 'Main text color for button. Applies to all banner types except injected.',
			bannerBtnBgColor: 'Main background color for button. Applies to all banner types except injected.',
			bannerBtnBgColorStart: 'Main background gradient <b>start</b> color for button. Applies to all banner types except injected.',
			bannerBtnBgColorEnd: 'Main background gradient <b>end</b> color for button. Applies to all banner types except injected.',
			bannerBtnBgColorStartPos: 'Main gradient <b>start</b> position for button. Applies to all banner types except injected.',
			bannerBtnBgColorEndPos: 'Main gradient <b>end</b> position for button. Applies to all banner types except injected.',
			bannerBtnBgGradient: 'Gradient is disabled when <code>false</code>. Applies to all banner types except injected.',
			bannerBtnBorder: 'Main border properties for button. Applies to all banner types except injected.',
			bannerBtnBorderColor: 'Applies to all banner types except injected.',
			bannerBtnBorderRadius: 'Applies to all banner types except injected.',
			bannerBtnFontSize: 'Applies to all banner types except injected.',
			bannerBtnFontWeight: 'Applies to all banner types except injected.',
			bannerBtnFontFamily: 'Applies to all banner types except injected.',
			bannerBtnLineHeight: 'Applies to all banner types except injected.',
			bannerBtnMargin: 'Applies to all banner types except injected.',
			bannerBtnMarginSides: 'Applies to all banner types except injected.',
			bannerBtnPadding: 'Applies to all banner types except injected.',
			bannerBtnPaddingSides: 'Applies to all banner types except injected.',
			bannerBtnTextTransform: 'Applies to all banner types except injected.',
			bannerButtonsMargin: 'Applies to all banner types except injected.',
			bannerButtonsMarginTop: 'Applies to all banner types except injected.',
			bannerBtnColorNegativeHover: 'Inverted button text color on hover state. Applies to all banner types except injected.',
			bannerBtnBgColorNegativeHover: 'Inverted button background color on hover state. Applies to all banner types except injected.',
			bannerBtnBorderColorNegativeHover: 'Inverted button border color on hover state. Applies to all banner types except injected.',
			bannerBtnColorHover: 'Main text color for button on hover state. Applies to all banner types except injected.',
			bannerBtnBgColorHover: 'Main background color for button on hover state. Applies to all banner types except injected.',
			bannerBtnBorderColorHover: 'Main border color for button on hover state. Applies to all banner types except injected.',
			tabbanner: '<i>Internal</i>',
			tabbannerColor: 'Override main banner value and apply only to Tabbanner.',
			tabbannerBgColor: 'Override main banner value and apply only to Tabbanner.',
			tabbannerBgImage: 'Override main banner value and apply only to Tabbanner.',
			tabbannerBorderColor: 'Override main banner value and apply only to Tabbanner.',
			tabbannerBorderWidth: 'Override main banner value and apply only to Tabbanner.',
			tabbannerBorderRadius: 'Override main banner value and apply only to Tabbanner.',
			tabbannerShadow: 'Override main banner value and apply only to Tabbanner.',
			tabbannerTextAlign: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH1Color: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH1Size: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH1Weight: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH1Family: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH1Margin: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH1Padding: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH2Color: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH2Size: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH2Weight: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH2Family: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH2Margin: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH2Padding: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH3Color: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH3Size: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH3Weight: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH3Family: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH3Margin: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH3Padding: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH4Color: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH4Size: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH4Weight: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH4Family: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH4Margin: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH4Padding: 'Override main banner value and apply only to Tabbanner.',
			tabbannerPosX: 'The horizontal placement of the Tabbanner.',
			tabbannerPosXOffset: 'The horizontal offset from <code>@tabbannerPosX</code>.',
			tabbannerPosY: 'The vertical placement of the Tabbanner.',
			tabbannerPosYOffset: 'The vertical offset from <code>@tabbannerPosY</code>.',
			tabbannerWidth: 'Applies only to Tabbanner.',
			tabbannerHeight: 'Applies only to Tabbanner.',
			tabbannerMargin: 'Applies only to Tabbanner.',
			tabbannerMarginTop: 'Applies only to Tabbanner.',
			tabbannerPadding: 'Applies only to Tabbanner.',
			tabbannerPaddingRight: 'Applies only to Tabbanner.',
			tabbannerInactiveTransform: 'Transform values for when the banner is inactivated. Applies only to Tabbanner.',
			tabbannerActiveTransform: 'Transform values for when the banner is activated. Applies only to Tabbanner.',
			tabbannerExpandTransform: 'Transform values for when the banner is expanding. Applies only to Tabbanner.',
			tabbannerAgent: 'Displays the banner image. Set <code>false</code> to disable. Applies only to Tabbanner.',
			tabbannerAgentImage: 'Filename of the image. Is used together with @bannerImagePath. Applies only to Tabbanner.',
			tabbannerAgentBgPos: 'The X/Y image position. Applies only to Tabbanner.',
			tabbannerAgentWidth: 'Image width. Applies only to Tabbanner.',
			tabbannerAgentHeight: 'Image height. Applies only to Tabbanner.',
			tabbannerAgentPosX: 'The horizontal placement of the image. Applies only to Tabbanner.',
			tabbannerAgentPosXOffset: 'The horizontal offset from <code>@tabbannerAgentPosX</code>. Applies only to Tabbanner.',
			tabbannerAgentPosY: 'The vertical placement of the image. Applies only to Tabbanner.',
			tabbannerAgentPosYOffset: 'The vertical offset from <code>@tabbannerAgentPosY</code>. Applies only to Tabbanner.',
			tabbannerTabStyle: 'Choose a tab style within the range of 0-3. Applies only to Tabbanner.',
			tabbannerTabColor: 'Tab text color. Applies only to Tabbanner.',
			tabbannerTabBgColor: 'Tab background color. Applies only to Tabbanner.',
			tabbannerTabBgColorHover: 'Tab background color on hover. Applies only to Tabbanner.',
			tabbannerTabShadow: 'Shadow properties on tab. Applies only to Tabbanner.',
			tabbannerTabBorderRadius: 'Tab border radius. Applies only to Tabbanner.',
			tabbannerTabFontSize: 'Tab font size. Applies only to Tabbanner.',
			tabbannerTabFontWeight: 'Tab font weight. Applies only to Tabbanner.',
			tabbannerTabLineHeight: 'Tab line height. Applies only to Tabbanner.',
			tabbannerTabIconUrl: 'The url for the icon in tab. Applies only to Tabbanner.',
			tabbannerTabIconSpace: 'Tab icon margins. Applies only to Tabbanner.',
			tabbannerTabWidth: 'Tab width. Applies only to Tabbanner.',
			tabbannerTabPadding: 'Tab padding. Applies only to Tabbanner.',
			tabbannerTabTextTransform: 'Tab text transform. Applies only to Tabbanner.',
			tabbannerTabIconOne: 'Tab icon url. Applies only to Tabbanner.',
			tabbannerTabIconTwo: 'Tab icon url. Applies only to Tabbanner.',
			tabbannerTabIconThree: 'Tab icon url. Applies only to Tabbanner.',
			tabbannerTabIconFour: 'Tab icon url. Applies only to Tabbanner.',
			tabbannerTabRotatedShadow: 'Rotated tab shadow. Applies only to Tabbanner.',
			tabbannerTabRotatedBorderRadius: 'Rotated tab border radius. Applies only to Tabbanner.',
			tabbannerTabRotatedFontSize: 'Rotated tab font-size. Applies only to Tabbanner.',
			tabbannerTabRotatedFontWeight: 'Rotated tab font-weight. Applies only to Tabbanner.',
			tabbannerTabRotatedHeight: 'Rotated tab height. Applies only to Tabbanner.',
			tabbannerTabRotatedPadding: 'Rotated tab padding. Applies only to Tabbanner.',
			tabbannerTabRotatedIconUrl: 'The url for the icon in tab. Applies only to Tabbanner.',
			tabbannerResponsiveColor: 'Content font color for tablet and below. Overrides <code>@bannerResponsiveColor</code>. Applies only to Tabbanner.',
			tabbannerResponsiveBgColor: 'Content background color for tablet and below. Overrides <code>@bannerResponsiveBgColor</code>. Applies only to Tabbanner.',
			tabbannerResponsiveHeaderColor: 'Header color for tablet and below. Overrides <code>@bannerResponsiveHeaderColor</code>. Applies only to Tabbanner.',
			tabbannerResponsivePosYOffset: 'The vertical offset from <code>@tabbannerPosY</code> on tablet and below. Overrides <code>@tabbannerPosYOffset</code>. Applies only to Tabbanner.',
			tabbannerResponsiveMarginTop: 'Margin top on tablet and below. Applies only to Tabbanner.',
			tabbannerResponsiveHeight: 'Height on tablet and below. Applies only to Tabbanner.',
			tabbannerResponsivePadding: 'Padding on tablet and below. Applies only to Tabbanner.',
			tabbannerResponsiveSmallPadding: 'Padding on phone and below. Applies only to Tabbanner.',
			takeoverColor: 'Override main banner value and apply only to Takeover banner.',
			takeoverBgColor: 'Override main banner value and apply only to Takeover banner.',
			takeoverBgImage: 'Override main banner value and apply only to Takeover banner.',
			takeoverBorderColor: 'Override main banner value and apply only to Takeover banner.',
			takeoverBorderWidth: 'Override main banner value and apply only to Takeover banner.',
			takeoverBorderRadius: 'Override main banner value and apply only to Takeover banner.',
			takeoverShadow: 'Override main banner value and apply only to Takeover banner.',
			takeoverTextAlign: 'Override main banner value and apply only to Takeover banner.',
			takeoverH1Color: 'Override main banner value and apply only to Takeover banner.',
			takeoverH1Size: 'Override main banner value and apply only to Takeover banner.',
			takeoverH1Weight: 'Override main banner value and apply only to Takeover banner.',
			takeoverH1Family: 'Override main banner value and apply only to Takeover banner.',
			takeoverH1Margin: 'Override main banner value and apply only to Takeover banner.',
			takeoverH1Padding: 'Override main banner value and apply only to Takeover banner.',
			takeoverH2Color: 'Override main banner value and apply only to Takeover banner.',
			takeoverH2Size: 'Override main banner value and apply only to Takeover banner.',
			takeoverH2Weight: 'Override main banner value and apply only to Takeover banner.',
			takeoverH2Family: 'Override main banner value and apply only to Takeover banner.',
			takeoverH2Margin: 'Override main banner value and apply only to Takeover banner.',
			takeoverH2Padding: 'Override main banner value and apply only to Takeover banner.',
			takeoverH3Color: 'Override main banner value and apply only to Takeover banner.',
			takeoverH3Size: 'Override main banner value and apply only to Takeover banner.',
			takeoverH3Weight: 'Override main banner value and apply only to Takeover banner.',
			takeoverH3Family: 'Override main banner value and apply only to Takeover banner.',
			takeoverH3Margin: 'Override main banner value and apply only to Takeover banner.',
			takeoverH3Padding: 'Override main banner value and apply only to Takeover banner.',
			takeoverH4Color: 'Override main banner value and apply only to Takeover banner.',
			takeoverH4Size: 'Override main banner value and apply only to Takeover banner.',
			takeoverH4Weight: 'Override main banner value and apply only to Takeover banner.',
			takeoverH4Family: 'Override main banner value and apply only to Takeover banner.',
			takeoverH4Margin: 'Override main banner value and apply only to Takeover banner.',
			takeoverH4Padding: 'Override main banner value and apply only to Takeover banner.',
			takeoverButtonsMarginTop: 'Override main banner value and apply only to Takeover banner.',
			takeoverBtnDiscreetFontSize: 'Override main banner value and apply only to Takeover banner.',
			takeoverButtonsDiscreetBottom: 'Override main banner value and apply only to Takeover banner.',
			takeoverTop: 'Offset from top. Applies only to Takeover banner.',
			takeoverWidth: 'Width of the Takeover banner. Applies only to Takeover banner.',
			takeoverHeight: 'Height of the Takeover banner. Applies only to Takeover banner.',
			takeoverPadding: 'Content padding of the Takeover banner. Applies only to Takeover banner.',
			takeoverPaddingTop: 'Top padding of of content for the Takeover banner. Applies only to Takeover banner.',
			takeoverInactiveTransform: 'Transform properties when Takeover banner is inactivated. Applies only to Takeover banner.',
			takeoverAgent: 'Displays the banner image. Set <code>false</code> to disable. Applies only to Takeover banner.',
			takeoverAgentImage: 'Filename of the image. Is used together with @bannerImagePath. Applies only to Takeover banner.',
			takeoverAgentBgPos: 'Position of the background image. Applies only to Takeover banner.',
			takeoverAgentWidth: 'Width of the image. Applies only to Takeover banner.',
			takeoverAgentHeight: 'Height of the image. Applies only to Takeover banner.',
			takeoverAgentPosX: 'The horizontal placement of the image. Applies only to Takeover banner.',
			takeoverAgentPosXOffset: 'The horizontal offset from <code>@takeoverAgentPosX</code>. Applies only to Takeover banner.',
			takeoverAgentPosY: 'The vertical placement of the image. Applies only to Takeover banner.',
			takeoverAgentPosYOffset: 'The vertical offset from <code>@takeoverAgentPosY</code>. Applies only to Takeover banner.',
			takeoverResponsiveColor: 'Content font color for tablet and below. Overrides <code>@bannerResponsiveColor</code>. Applies only to Takeover banner.',
			takeoverResponsiveBgColor: 'Content background color for tablet and below. Overrides <code>@bannerResponsiveBgColor</code>. Applies only to Takeover banner.',
			takeoverResponsiveHeaderColor: 'Header color for tablet and below. Overrides <code>@bannerResponsiveHeaderColor</code>. Applies only to Takeover banner.',
			takeoverResponsivePadding: 'Padding on tablet and below. Applies only to Takeover banner.',
			dooreye: '<i>Internal</i>',
			dooreyeColor: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeBgColor: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeBgImage: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeBorderRadius: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeTextAlign: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH1Color: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH1Size: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH1Weight: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH1Family: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH1Margin: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH1Padding: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH2Color: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH2Size: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH2Weight: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH2Family: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH2Margin: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH2Padding: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH3Color: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH3Size: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH3Weight: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH3Family: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH3Margin: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH3Padding: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH4Color: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH4Size: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH4Weight: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH4Family: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH4Margin: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH4Padding: 'Override main banner value and apply only to Dooreye banner.',
			dooreyePosY: 'The vertical placement of the Dooreye banner.',
			dooreyePosYOffset: 'The vertical offset from <code>@dooreyePosY</code>. Applies only to Dooreye banner.',
			dooreyePosX: 'The horizontal placement of the Dooreye banner.',
			dooreyePosXOffset: 'The horizontal offset from <code>@dooreyePosX</code>. Applies only to Dooreye banner.',
			dooreyeShadow: 'Shadow properties for content container. Applies only to Dooreye banner.',
			dooreyeWidth: 'Content width. Applies only to Dooreye banner.',
			dooreyePadding: 'Content padding. Applies only to Dooreye banner.',
			dooreyePaddingTop: 'Content padding top. Applies only to Dooreye banner.',
			dooreyeAgent: 'Displays the banner image. Set <code>false</code> to disable. Applies only to Dooreye banner.',
			dooreyeAgentImage: 'Filename of the image. Is used together with @bannerImagePath. Applies only to Dooreye banner.',
			dooreyeAgentBgPos: 'Position of the background image. Applies only to Dooreye banner.',
			dooreyeAgentWidth: 'Width of the image. Applies only to Dooreye banner.',
			dooreyeAgentHeight: 'Height of the image. Applies only to Dooreye banner.',
			dooreyeAgentPosX: 'The horizontal placement of the image. Applies only to Dooreye banner.',
			dooreyeAgentPosXOffset: 'The horizontal offset from <code>@dooreyeAgentPosX</code>. Applies only to Dooreye banner.',
			dooreyeAgentPosY: 'The vertical placement of the image. Applies only to Dooreye banner.',
			dooreyeAgentPosYOffset: 'The vertical offset from <code>@dooreyeAgentPosY</code>. Applies only to Dooreye banner.',
			dooreyeDooreyeBgColor: 'Background color for the fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeBgPos: 'Background url position properties for fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeBgImage: 'Filename of the image in the fixed door eye circle. Is used together with @bannerImagePath. Applies only to Dooreye banner.',
			dooreyeDooreyeBorder: 'Border properties for fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeBorderRadius: 'Border radius for fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeLineHeight: 'Line height for fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeShadow: 'Shadow properties for fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeWidth: 'Width of fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeHeight: 'Height of fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeMessageOffsetX: 'Horizontally position the content. Applies only to Dooreye banner.',
			dooreyeMessageOffsetY: 'Vertically position the content. Applies only to Dooreye banner.',
			dooreyeMessageArrow: 'Size of the arrow on content container. Applies only to Dooreye banner.',
			dooreyeResponsiveColor: 'Content font color for tablet and below. Overrides <code>@bannerResponsiveColor</code>. Applies only to Dooreye.',
			dooreyeResponsiveBgColor: 'Content background color for tablet and below. Overrides <code>@bannerResponsiveColor</code>. Applies only to Dooreye.',
			dooreyeResponsiveHeaderColor: 'Header color for tablet and below. Overrides <code>@bannerResponsiveColor</code>. Applies only to Dooreye.',
			dooreyeResponsiveHeight: 'Height on tablet and below. Applies only to Dooreye banner.',
			dooreyeResponsivePadding: 'Padding on tablet and below. Applies only to Dooreye banner.',
			dooreyeResponsiveSmallPadding: 'Padding on Phone and below. Applies only to Dooreye banner.'
		}
	},
	'sv-SE': {
		banner: {
			LIST_HEADER: 'Banners',
			LIST_TITLE: 'List of banners for working site',
			LIST_DESCRIPTION: 'Banners are used to display offers of support to visitors in various ways. A banner is always tied to a solution (which contains info about how to help the visitor).',
			ADD_HEADER: 'New banner',

			EDIT_HEADER: 'Edit banner',

			DISPLAY_STATE_TRUE: 'active',
			DISPLAY_STATE_FALSE: 'inactive',

			TAB_GENERAL: 'General',
			TAB_SETTINGS: 'Settings',
			TAB_INTRO: 'Intro',
			TAB_HTML: 'HTML',
			TAB_CSS: 'CSS',
			TAB_SUBJECT_MAPPING: 'Subject mapping',
			TAB_TRANSLATIONS: 'Translations',

			ID: 'Id',
			NAME: 'Name',
			NAME_PLACEHOLDER: 'My new banner',
			DESCRIPTION: 'Description',
			DESCRIPTION_PLACEHOLDER: 'A short description of this banner',
			DISPLAY_STATE: 'Display banner when',
			OPEN: 'Open',
			BUSY: 'Busy',
			CLOSED: 'Closed',
			HTML: 'HTML',
			HTML_TAB: 'Tab HTML',
			HTML_TAB_PLACEHOLDER: 'Chat with us',
			TAG: 'Wrapper HTML tag',
			TAG_PLACEHOLDER: 'div',
			TARGET: 'Injection selector',
			TARGET_PLACEHOLDER: 'body',
			TARGET_METHOD: 'Injection method',
			TARGET_METHOD_APPEND: 'Append inside target',
			TARGET_METHOD_PREPEND: 'Prepend inside target',
			TARGET_METHOD_BEFORE: 'Insert before target',
			TARGET_METHOD_AFTER: 'Insert after target',
			TARGET_METHOD_REPLACE: 'Replace target element',
			INTRO_DURATION: 'Intro duration (seconds)',
			INTRO_DURATION_PLACEHOLDER: '0',
			INTRO_DELAY: 'Intro delay (seconds)',
			INTRO_DELAY_PLACEHOLDER: '0',
			INTRO_ONCE: 'Do intro once',
			INTRO_ONCE_TEMPLATE: 'Once per template',
			INTRO_QUARANTINE: 'Do once timeout until next intro (minutes)',
			INTRO_QUARANTINE_PLACEHOLDER: '60',
			EXTRA_CLASSES: 'Wrapper additional class names',
			EXTRA_CLASSES_PLACEHOLDER: 'example-class extra-wide',
			MOUSE_EXPANSION: 'Mouse expand',
			MOUSE_EXPAND: 'Expand when cursor enters banner',
			MOUSE_COLLAPSE: 'Collapse when cursor leaves banner',

			VISITOR_UI_MODE: 'Visitor UI mode',
			VISITOR_UI_MODE_DESCRIPTION: 'Select Visitor UI mode to use in dialogs started from this banner.',
			VISITOR_UI_MODE_HELP_TOOLTIP: 'Select Visitor application UI mode to use in dialogs started from this banner.<br><br>This should almost always be set to "Normal".<br>"Minimal UI" mode is only used in integration setups with other chat products, and that mode will not include UI elements for the visitor to chat with the agent.',
			VISITOR_UI_MODE_NORMAL: 'Normal',
			VISITOR_UI_MODE_MINIMAL: 'Minimal UI',

			TEMPLATE: 'Template',
			TEMPLATE_PLACEHOLDER: 'Choose a template',
			TEMPLATE_DOOREYE: 'Dooreye',
			TEMPLATE_INJECTED: 'Injected',
			TEMPLATE_TABBANNER: 'Tabbanner',
			TEMPLATE_TAKEOVER: 'Takeover',
			TEMPLATE_QUEUERESERVATION: 'Queue reservation',

			ADD_TEXT_KEY_HEADER: 'Add banner text key',
			ADD_TEXT_KEY: 'Text key name',
			ADD_TEXT_KEY_EXISTS: 'Text key already exists!',
			ADD_TEXT_KEY_PLACEHOLDER: 'title',
			ADD_TEXT_KEY_VALUE: 'Text key value',
			ADD_TEXT_KEY_VALUE_PLACEHOLDER: 'Text value for this key name',

			PREVIEW: 'Banner preview',
			PREVIEW_OPEN: 'Open',
			PREVIEW_BUSY: 'Busy',
			PREVIEW_CLOSED: 'Closed',
			PREVIEW_HIDE: 'Hide',
			PREVIEW_SHOW: 'Show',
			PREVIEW_EXPAND: 'Expand',

			CSS: 'Account CSS',
			CSS_DESCRIPTION: 'This CSS is shared for all banners across the account.',
			CSS_RESPONSIVE: 'Responsive banners',
			CSS_PRIMARY: 'Primary color',
			CSS_BACKGROUND: 'Banner background',

			REMOVE_HEADER: 'Remove banner "{{ name }}"',
			REMOVE_MESSAGE: 'Note that any existing references to this banner from solutions also will be removed.',

			MAPPING_HEADING: 'Access multiple groups with custom translation and case type',
			MAPPING_DESCRIPTION: 'Create translations and map them to different groups. This enables visitors to start conversations with any of these groups, in the provided translation, all from the same banner.',
			MAPPING_LIST_TITLE: 'List of subject mappings',
			MAPPING_ADD_BTN: 'New mapping',
			MAPPING_LIST_COL_NAME: 'Name',
			MAPPING_LIST_COL_GROUP: 'Group',
			MAPPING_LIST_COL_CASETYPE: 'Case type',
			MAPPING_LIST_COL_TRANSLATION: 'Translation',

			MAPPING_ADD_HEADER: 'New subject mapping of group and translation',
			MAPPING_ADD_HELP_SUBJECT: 'You are creating a new subject mapping',
			MAPPING_EDIT_HEADER: 'Edit subject mapping',
			MAPPING_EDIT_HELP_SUBJECT: 'You are editing a subject mapping',
			MAPPING_EDIT_HELP_TEXT: 'Please select a group, a case type and a translation to map into a new subject mapping. The name of the subject mapping is displayed to the visitor',
			MAPPING_EDIT_NAME: 'Name',
			MAPPING_EDIT_DEFAULT_MAPPING: 'Use as default mapping',
			MAPPING_EDIT_SELECT_GROUP: 'Select group',
			MAPPING_EDIT_SELECT_CASETYPE: 'Select case type',
			MAPPING_EDIT_SELECT_TRANSLATION: 'Select translation',
			MAPPING_EDIT_SELECT_GROUP_PLACEHOLDER: '- Select group -',
			MAPPING_EDIT_SELECT_CASETYPE_PLACEHOLDER: '- Select case type -',
			MAPPING_EDIT_SELECT_TRANSLATION_PLACEHOLDER: '- Select translation -',
			MAPPING_EDIT_ERROR_DUPLICATE_NAME: 'The name is already in use',
			MAPPING_EDIT_ERROR_DEFAULT_MAPPING: 'Another mapping is already set as default. Please uncheck that before setting this as default.',

			TRANSLATIONS_HEADING: 'Create translations or custom content for banners',
			TRANSLATIONS_DESCRIPTION: 'Translate or customize existing content in banners. You can also add new custom text in the section below. These will be added to all existing translations in the <a>#custom translation list</a>.',
			TRANSLATIONS_LIST_TITLE: 'List of translations',
			TRANSLATIONS_ADD_BTN: 'New translation',
			TRANSLATIONS_KEYS: 'Translation keys',

			TRANSLATION_ADD_HEADER: 'New translation',
			TRANSLATION_EDIT: 'Edit',
			TRANSLATION_EXPORT: 'Export',
			TRANSLATION_IMPORT: 'Import',
			TRANSLATION_REMOVE: 'Remove',
			TRANSLATION_EDIT_HEADER: 'Edit translation',
			TRANSLATION_EDIT_NAME: 'Name',
			TRANSLATION_EDIT_ERROR_DUPLICATE_NAME: 'The name is already in use',
			TRANSLATION_ADD_SELECT_BASE_TRANSLATION: 'Select base translation',
			TRANSLATION_ADD_HELP_SUBJECT: 'You are creating a new translation',
			TRANSLATION_EDIT_HELP_SUBJECT: 'You are editing a translation',
			TRANSLATION_EDIT_HELP_TEXT: 'Choose a display name for the translation, and select a base translation as a starting point and edit as you like.<br><br>The optional test direction (RTL) will be indicated by an icon in the list of your translations.',
			TRANSLATION_EXPORT_HEADER: 'Export translation',
			TRANSLATION_EXPORT_INGRESS: 'Translation JSON for export:',
			TRANSLATION_EXPORT_COPY_BTN: 'Copy to clipboard',
			TRANSLATION_IMPORT_HEADER: 'Import translation',
			TRANSLATION_IMPORT_INGRESS: 'Paste translation JSON to import:',
			TRANSLATION_IMPORT_MERGE_MODE_INGRESS: 'Select how to handle values for existing keys:',
			TRANSLATION_IMPORT_OVERWRITE: 'Overwrite existing values with imported',
			TRANSLATION_IMPORT_KEEP: 'Keep existing values',
			TRANSLATION_IMPORT_BTN: 'Import',

			TRANSLATIONS_CUSTOM_HEADING: 'Custom translation list',
			TRANSLATIONS_CUSTOM_DESCRIPTION: 'This is used in the HTML-tab e.g. &#123;&#123;= conf.Name &#125;&#125;',
			TRANSLATIONS_CUSTOM_REMOVE: 'Remove',
			TRANSLATIONS_CUSTOM_ADD_BTN: 'New custom key',
			TRANSLATIONS_CUSTOM_ADD_KEY_PLACEHOLDER: 'Name',
			TRANSLATIONS_CUSTOM_ADD_VALUE_PLACEHOLDER: 'Text',
			TRANSLATIONS_SET_AS_DEFAULT: 'Set as default',
			TRANSLATIONS_DEFAULT_TRANSLATION: 'Default translation',
			TRANSLATIONS_DEFAULT: 'Default',

			TRANSLATIONS_ERROR_MESSAGES_HEADING: 'Error code translations',
			TRANSLATIONS_ERROR_MESSAGES_DESCRIPTION: 'This is used to display/translate error codes from backend',
			TRANSLATIONS_ERROR_MESSAGES_REMOVE: 'Remove',
			TRANSLATIONS_ERROR_MESSAGES_ADD_BTN: 'New error code',
			TRANSLATIONS_ERROR_MESSAGES_ADD_KEY_PLACEHOLDER: 'Code',
			TRANSLATIONS_ERROR_MESSAGES_ADD_VALUE_PLACEHOLDER: 'Error text'
		},

		lessVars: {
			noDesc: 'No description available',
			transforms: '<i>Internal</i>',
			noTransforms: '<i>Internal: </i>Used to disable transforms',
			transitions: '<i>Internal: </i>Wrapper class for transitions',
			noTransitions: '<i>Internal: </i>Used do disable transitions',
			bannerDesktop: 'Media query values for desktop',
			bannerTablet: 'Media query values for tablet',
			bannerPhone: 'Media query values for phone',
			bannerNoPhone: 'Media query values for device smaller than phone',
			bannerImagePath: 'The base path to file storage i.e images for agent avatars. <i>If not default, make sure the domain is white-listed</i>',
			bannerResponsive: 'Boolean for making use of responsive banners. Set <i>false</i> to disable responsive banners. Applies to all banner types except injected.',
			bannerResponsiveFontSize: 'Font size for content on tablet and below. Applies to all banner types except injected.',
			bannerResponsiveBtnFontSize: 'Font size for buttons (<code>.vngage-butn</code>) on tablet and below. Applies to all banner types except injected.',
			bannerResponsiveColor: 'Content font color for tablet and below. Applies to all banner types except injected.',
			bannerResponsiveBgColor: 'Content background color for tablet and below. Applies to all banner types except injected.',
			bannerResponsiveHeaderColor: 'Main header color for tablet and below. Applies to all banner types except injected.',
			bannerShadow: 'Main shadow values. Applies to all banner types except injected.',
			bannerTextAlign: 'Main text alignment. Applies to all banner types except injected.',
			bannerTransitionDuration: 'Main transition time for banners. Applies to all banner types except injected.',
			bannerBtnTransitionDuration: 'Main transition time for buttons. Applies to all banner types except injected.',
			bannerZindex: 'Main z-index. Applies to all banner types except injected.',
			bannerPrimaryColor: 'Main primary color. Used for background on button and tab. Applies to Tab- and Dooreye banner.',
			bannerPrimaryTextColor: 'Main primary text color. Used in conjunction with the Primary Color on button and tab. Applies to Tab- and Dooreye banner.',
			bannerTextColor: 'Main text color. Applies to all banner types except injected.',
			bannerBgColor: 'Main banner background color. Applies to all banner types except injected.',
			bannerBgImage: 'Injects a background image on tab- and takeover banners. Takes an url or base-64. <i>If using url, make sure the domain is white-listed</i>',
			bannerFontSize: 'Applies to all banner types except injected.',
			bannerFontFamily: 'Applies to all banner types except injected.',
			bannerLineHeight: 'Applies to all banner types except injected.',
			bannerHeaderStart: '<i>Internal</i>',
			bannerHeaderEnd: '<i>Internal</i>',
			bannerH1Color: 'Applies to all banner types except injected.',
			bannerH1Size: 'Applies to all banner types except injected.',
			bannerH1Weight: 'Applies to all banner types except injected.',
			bannerH1Family: 'Applies to all banner types except injected.',
			bannerH1Margin: 'Applies to all banner types except injected.',
			bannerH1Padding: 'Applies to all banner types except injected.',
			bannerH2Color: 'Applies to all banner types except injected.',
			bannerH2Size: 'Applies to all banner types except injected.',
			bannerH2Weight: 'Applies to all banner types except injected.',
			bannerH2Family: 'Applies to all banner types except injected.',
			bannerH2Margin: 'Applies to all banner types except injected.',
			bannerH2Padding: 'Applies to all banner types except injected.',
			bannerH3Color: 'Applies to all banner types except injected.',
			bannerH3Size: 'Applies to all banner types except injected.',
			bannerH3Weight: 'Applies to all banner types except injected.',
			bannerH3Family: 'Applies to all banner types except injected.',
			bannerH3Margin: 'Applies to all banner types except injected.',
			bannerH3Padding: 'Applies to all banner types except injected.',
			bannerH4Color: 'Applies to all banner types except injected.',
			bannerH4Size: 'Applies to all banner types except injected.',
			bannerH4Weight: 'Applies to all banner types except injected.',
			bannerH4Family: 'Applies to all banner types except injected.',
			bannerH4Margin: 'Applies to all banner types except injected.',
			bannerH4Padding: 'Applies to all banner types except injected.',
			bannerParagraphSize: 'Applies to all banner types except injected.',
			bannerParagraphMargin: 'Applies to all banner types except injected.',
			bannerParagraphPadding: 'Applies to all banner types except injected.',
			bannerParagraphLineHeight: 'Applies to all banner types except injected.',
			overlayBackground: 'Main overlay background color. Applies to takeover banner on desktop and to all banner types except injected when responsive.',
			overlayOpacity: 'Main opacity on overlay background color. Applies to takeover banner on desktop and to all banner types except injected when responsive.',
			overlayZindex: 'Main z-index value for overlay. Applies to takeover banner on desktop and to all banner types except injected when responsive.',
			bannerLinkColor: 'Applies to all banner types except injected.',
			bannerLinkFontSize: 'Applies to all banner types except injected.',
			bannerLinkFontWeight: 'Applies to all banner types except injected.',
			bannerLinkTextDecoration: 'Applies to all banner types except injected.',
			bannerLinkColorHover: 'Applies to all banner types except injected.',
			bannerLinkTextDecorationHover: 'Applies to all banner types except injected.',
			bannerBtnColorNegative: 'Inverted button text color. Applies to all banner types except injected.',
			bannerBtnBgColorNegative: 'Inverted button background color. Applies to all banner types except injected.',
			bannerBtnBgColorNegativeStart: 'Inverted button background gradient <b>start</b> color. Applies to all banner types except injected.',
			bannerBtnBgColorNegativeEnd: 'Inverted button background gradient <b>end</b> color. Applies to all banner types except injected.',
			bannerBtnBorderColorNegative: 'Inverted button border color. Applies to all banner types except injected.',
			bannerBtnColor: 'Main text color for button. Applies to all banner types except injected.',
			bannerBtnBgColor: 'Main background color for button. Applies to all banner types except injected.',
			bannerBtnBgColorStart: 'Main background gradient <b>start</b> color for button. Applies to all banner types except injected.',
			bannerBtnBgColorEnd: 'Main background gradient <b>end</b> color for button. Applies to all banner types except injected.',
			bannerBtnBgColorStartPos: 'Main gradient <b>start</b> position for button. Applies to all banner types except injected.',
			bannerBtnBgColorEndPos: 'Main gradient <b>end</b> position for button. Applies to all banner types except injected.',
			bannerBtnBgGradient: 'Gradient is disabled when <code>false</code>. Applies to all banner types except injected.',
			bannerBtnBorder: 'Main border properties for button. Applies to all banner types except injected.',
			bannerBtnBorderColor: 'Applies to all banner types except injected.',
			bannerBtnBorderRadius: 'Applies to all banner types except injected.',
			bannerBtnFontSize: 'Applies to all banner types except injected.',
			bannerBtnFontWeight: 'Applies to all banner types except injected.',
			bannerBtnFontFamily: 'Applies to all banner types except injected.',
			bannerBtnLineHeight: 'Applies to all banner types except injected.',
			bannerBtnMargin: 'Applies to all banner types except injected.',
			bannerBtnMarginSides: 'Applies to all banner types except injected.',
			bannerBtnPadding: 'Applies to all banner types except injected.',
			bannerBtnPaddingSides: 'Applies to all banner types except injected.',
			bannerBtnTextTransform: 'Applies to all banner types except injected.',
			bannerButtonsMargin: 'Applies to all banner types except injected.',
			bannerButtonsMarginTop: 'Applies to all banner types except injected.',
			bannerBtnColorNegativeHover: 'Inverted button text color on hover state. Applies to all banner types except injected.',
			bannerBtnBgColorNegativeHover: 'Inverted button background color on hover state. Applies to all banner types except injected.',
			bannerBtnBorderColorNegativeHover: 'Inverted button border color on hover state. Applies to all banner types except injected.',
			bannerBtnColorHover: 'Main text color for button on hover state. Applies to all banner types except injected.',
			bannerBtnBgColorHover: 'Main background color for button on hover state. Applies to all banner types except injected.',
			bannerBtnBorderColorHover: 'Main border color for button on hover state. Applies to all banner types except injected.',
			tabbanner: '<i>Internal</i>',
			tabbannerColor: 'Override main banner value and apply only to Tabbanner.',
			tabbannerBgColor: 'Override main banner value and apply only to Tabbanner.',
			tabbannerBgImage: 'Override main banner value and apply only to Tabbanner.',
			tabbannerBorderColor: 'Override main banner value and apply only to Tabbanner.',
			tabbannerBorderWidth: 'Override main banner value and apply only to Tabbanner.',
			tabbannerBorderRadius: 'Override main banner value and apply only to Tabbanner.',
			tabbannerShadow: 'Override main banner value and apply only to Tabbanner.',
			tabbannerTextAlign: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH1Color: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH1Size: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH1Weight: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH1Family: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH1Margin: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH1Padding: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH2Color: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH2Size: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH2Weight: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH2Family: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH2Margin: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH2Padding: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH3Color: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH3Size: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH3Weight: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH3Family: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH3Margin: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH3Padding: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH4Color: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH4Size: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH4Weight: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH4Family: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH4Margin: 'Override main banner value and apply only to Tabbanner.',
			tabbannerH4Padding: 'Override main banner value and apply only to Tabbanner.',
			tabbannerPosX: 'The horizontal placement of the Tabbanner.',
			tabbannerPosXOffset: 'The horizontal offset from <code>@tabbannerPosX</code>.',
			tabbannerPosY: 'The vertical placement of the Tabbanner.',
			tabbannerPosYOffset: 'The vertical offset from <code>@tabbannerPosY</code>.',
			tabbannerWidth: 'Applies only to Tabbanner.',
			tabbannerHeight: 'Applies only to Tabbanner.',
			tabbannerMargin: 'Applies only to Tabbanner.',
			tabbannerMarginTop: 'Applies only to Tabbanner.',
			tabbannerPadding: 'Applies only to Tabbanner.',
			tabbannerPaddingRight: 'Applies only to Tabbanner.',
			tabbannerInactiveTransform: 'Transform values for when the banner is inactivated. Applies only to Tabbanner.',
			tabbannerActiveTransform: 'Transform values for when the banner is activated. Applies only to Tabbanner.',
			tabbannerExpandTransform: 'Transform values for when the banner is expanding. Applies only to Tabbanner.',
			tabbannerAgent: 'Displays the banner image. Set <code>false</code> to disable. Applies only to Tabbanner.',
			tabbannerAgentImage: 'Filename of the image. Is used together with @bannerImagePath. Applies only to Tabbanner.',
			tabbannerAgentBgPos: 'The X/Y image position. Applies only to Tabbanner.',
			tabbannerAgentWidth: 'Image width. Applies only to Tabbanner.',
			tabbannerAgentHeight: 'Image height. Applies only to Tabbanner.',
			tabbannerAgentPosX: 'The horizontal placement of the image. Applies only to Tabbanner.',
			tabbannerAgentPosXOffset: 'The horizontal offset from <code>@tabbannerAgentPosX</code>. Applies only to Tabbanner.',
			tabbannerAgentPosY: 'The vertical placement of the image. Applies only to Tabbanner.',
			tabbannerAgentPosYOffset: 'The vertical offset from <code>@tabbannerAgentPosY</code>. Applies only to Tabbanner.',
			tabbannerTabStyle: 'Choose a tab style within the range of 0-3. Applies only to Tabbanner.',
			tabbannerTabColor: 'Tab text color. Applies only to Tabbanner.',
			tabbannerTabBgColor: 'Tab background color. Applies only to Tabbanner.',
			tabbannerTabBgColorHover: 'Tab background color on hover. Applies only to Tabbanner.',
			tabbannerTabShadow: 'Shadow properties on tab. Applies only to Tabbanner.',
			tabbannerTabBorderRadius: 'Tab border radius. Applies only to Tabbanner.',
			tabbannerTabFontSize: 'Tab font size. Applies only to Tabbanner.',
			tabbannerTabFontWeight: 'Tab font weight. Applies only to Tabbanner.',
			tabbannerTabLineHeight: 'Tab line height. Applies only to Tabbanner.',
			tabbannerTabIconUrl: 'The url for the icon in tab. Applies only to Tabbanner.',
			tabbannerTabIconSpace: 'Tab icon margins. Applies only to Tabbanner.',
			tabbannerTabWidth: 'Tab width. Applies only to Tabbanner.',
			tabbannerTabPadding: 'Tab padding. Applies only to Tabbanner.',
			tabbannerTabTextTransform: 'Tab text transform. Applies only to Tabbanner.',
			tabbannerTabIconOne: 'Tab icon url. Applies only to Tabbanner.',
			tabbannerTabIconTwo: 'Tab icon url. Applies only to Tabbanner.',
			tabbannerTabIconThree: 'Tab icon url. Applies only to Tabbanner.',
			tabbannerTabIconFour: 'Tab icon url. Applies only to Tabbanner.',
			tabbannerTabRotatedShadow: 'Rotated tab shadow. Applies only to Tabbanner.',
			tabbannerTabRotatedBorderRadius: 'Rotated tab border radius. Applies only to Tabbanner.',
			tabbannerTabRotatedFontSize: 'Rotated tab font-size. Applies only to Tabbanner.',
			tabbannerTabRotatedFontWeight: 'Rotated tab font-weight. Applies only to Tabbanner.',
			tabbannerTabRotatedHeight: 'Rotated tab height. Applies only to Tabbanner.',
			tabbannerTabRotatedPadding: 'Rotated tab padding. Applies only to Tabbanner.',
			tabbannerTabRotatedIconUrl: 'The url for the icon in tab. Applies only to Tabbanner.',
			tabbannerResponsiveColor: 'Content font color for tablet and below. Overrides <code>@bannerResponsiveColor</code>. Applies only to Tabbanner.',
			tabbannerResponsiveBgColor: 'Content background color for tablet and below. Overrides <code>@bannerResponsiveBgColor</code>. Applies only to Tabbanner.',
			tabbannerResponsiveHeaderColor: 'Header color for tablet and below. Overrides <code>@bannerResponsiveHeaderColor</code>. Applies only to Tabbanner.',
			tabbannerResponsivePosYOffset: 'The vertical offset from <code>@tabbannerPosY</code> on tablet and below. Overrides <code>@tabbannerPosYOffset</code>. Applies only to Tabbanner.',
			tabbannerResponsiveMarginTop: 'Margin top on tablet and below. Applies only to Tabbanner.',
			tabbannerResponsiveHeight: 'Height on tablet and below. Applies only to Tabbanner.',
			tabbannerResponsivePadding: 'Padding on tablet and below. Applies only to Tabbanner.',
			tabbannerResponsiveSmallPadding: 'Padding on phone and below. Applies only to Tabbanner.',
			takeoverColor: 'Override main banner value and apply only to Takeover banner.',
			takeoverBgColor: 'Override main banner value and apply only to Takeover banner.',
			takeoverBgImage: 'Override main banner value and apply only to Takeover banner.',
			takeoverBorderColor: 'Override main banner value and apply only to Takeover banner.',
			takeoverBorderWidth: 'Override main banner value and apply only to Takeover banner.',
			takeoverBorderRadius: 'Override main banner value and apply only to Takeover banner.',
			takeoverShadow: 'Override main banner value and apply only to Takeover banner.',
			takeoverTextAlign: 'Override main banner value and apply only to Takeover banner.',
			takeoverH1Color: 'Override main banner value and apply only to Takeover banner.',
			takeoverH1Size: 'Override main banner value and apply only to Takeover banner.',
			takeoverH1Weight: 'Override main banner value and apply only to Takeover banner.',
			takeoverH1Family: 'Override main banner value and apply only to Takeover banner.',
			takeoverH1Margin: 'Override main banner value and apply only to Takeover banner.',
			takeoverH1Padding: 'Override main banner value and apply only to Takeover banner.',
			takeoverH2Color: 'Override main banner value and apply only to Takeover banner.',
			takeoverH2Size: 'Override main banner value and apply only to Takeover banner.',
			takeoverH2Weight: 'Override main banner value and apply only to Takeover banner.',
			takeoverH2Family: 'Override main banner value and apply only to Takeover banner.',
			takeoverH2Margin: 'Override main banner value and apply only to Takeover banner.',
			takeoverH2Padding: 'Override main banner value and apply only to Takeover banner.',
			takeoverH3Color: 'Override main banner value and apply only to Takeover banner.',
			takeoverH3Size: 'Override main banner value and apply only to Takeover banner.',
			takeoverH3Weight: 'Override main banner value and apply only to Takeover banner.',
			takeoverH3Family: 'Override main banner value and apply only to Takeover banner.',
			takeoverH3Margin: 'Override main banner value and apply only to Takeover banner.',
			takeoverH3Padding: 'Override main banner value and apply only to Takeover banner.',
			takeoverH4Color: 'Override main banner value and apply only to Takeover banner.',
			takeoverH4Size: 'Override main banner value and apply only to Takeover banner.',
			takeoverH4Weight: 'Override main banner value and apply only to Takeover banner.',
			takeoverH4Family: 'Override main banner value and apply only to Takeover banner.',
			takeoverH4Margin: 'Override main banner value and apply only to Takeover banner.',
			takeoverH4Padding: 'Override main banner value and apply only to Takeover banner.',
			takeoverButtonsMarginTop: 'Override main banner value and apply only to Takeover banner.',
			takeoverBtnDiscreetFontSize: 'Override main banner value and apply only to Takeover banner.',
			takeoverButtonsDiscreetBottom: 'Override main banner value and apply only to Takeover banner.',
			takeoverTop: 'Offset from top. Applies only to Takeover banner.',
			takeoverWidth: 'Width of the Takeover banner. Applies only to Takeover banner.',
			takeoverHeight: 'Height of the Takeover banner. Applies only to Takeover banner.',
			takeoverPadding: 'Content padding of the Takeover banner. Applies only to Takeover banner.',
			takeoverPaddingTop: 'Top padding of of content for the Takeover banner. Applies only to Takeover banner.',
			takeoverInactiveTransform: 'Transform properties when Takeover banner is inactivated. Applies only to Takeover banner.',
			takeoverAgent: 'Displays the banner image. Set <code>false</code> to disable. Applies only to Takeover banner.',
			takeoverAgentImage: 'Filename of the image. Is used together with @bannerImagePath. Applies only to Takeover banner.',
			takeoverAgentBgPos: 'Position of the background image. Applies only to Takeover banner.',
			takeoverAgentWidth: 'Width of the image. Applies only to Takeover banner.',
			takeoverAgentHeight: 'Height of the image. Applies only to Takeover banner.',
			takeoverAgentPosX: 'The horizontal placement of the image. Applies only to Takeover banner.',
			takeoverAgentPosXOffset: 'The horizontal offset from <code>@takeoverAgentPosX</code>. Applies only to Takeover banner.',
			takeoverAgentPosY: 'The vertical placement of the image. Applies only to Takeover banner.',
			takeoverAgentPosYOffset: 'The vertical offset from <code>@takeoverAgentPosY</code>. Applies only to Takeover banner.',
			takeoverResponsiveColor: 'Content font color for tablet and below. Overrides <code>@bannerResponsiveColor</code>. Applies only to Takeover banner.',
			takeoverResponsiveBgColor: 'Content background color for tablet and below. Overrides <code>@bannerResponsiveBgColor</code>. Applies only to Takeover banner.',
			takeoverResponsiveHeaderColor: 'Header color for tablet and below. Overrides <code>@bannerResponsiveHeaderColor</code>. Applies only to Takeover banner.',
			takeoverResponsivePadding: 'Padding on tablet and below. Applies only to Takeover banner.',
			dooreye: '<i>Internal</i>',
			dooreyeColor: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeBgColor: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeBgImage: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeBorderRadius: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeTextAlign: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH1Color: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH1Size: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH1Weight: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH1Family: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH1Margin: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH1Padding: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH2Color: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH2Size: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH2Weight: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH2Family: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH2Margin: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH2Padding: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH3Color: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH3Size: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH3Weight: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH3Family: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH3Margin: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH3Padding: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH4Color: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH4Size: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH4Weight: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH4Family: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH4Margin: 'Override main banner value and apply only to Dooreye banner.',
			dooreyeH4Padding: 'Override main banner value and apply only to Dooreye banner.',
			dooreyePosY: 'The vertical placement of the Dooreye banner.',
			dooreyePosYOffset: 'The vertical offset from <code>@dooreyePosY</code>. Applies only to Dooreye banner.',
			dooreyePosX: 'The horizontal placement of the Dooreye banner.',
			dooreyePosXOffset: 'The horizontal offset from <code>@dooreyePosX</code>. Applies only to Dooreye banner.',
			dooreyeShadow: 'Shadow properties for content container. Applies only to Dooreye banner.',
			dooreyeWidth: 'Content width. Applies only to Dooreye banner.',
			dooreyePadding: 'Content padding. Applies only to Dooreye banner.',
			dooreyePaddingTop: 'Content padding top. Applies only to Dooreye banner.',
			dooreyeAgent: 'Displays the banner image. Set <code>false</code> to disable. Applies only to Dooreye banner.',
			dooreyeAgentImage: 'Filename of the image. Is used together with @bannerImagePath. Applies only to Dooreye banner.',
			dooreyeAgentBgPos: 'Position of the background image. Applies only to Dooreye banner.',
			dooreyeAgentWidth: 'Width of the image. Applies only to Dooreye banner.',
			dooreyeAgentHeight: 'Height of the image. Applies only to Dooreye banner.',
			dooreyeAgentPosX: 'The horizontal placement of the image. Applies only to Dooreye banner.',
			dooreyeAgentPosXOffset: 'The horizontal offset from <code>@dooreyeAgentPosX</code>. Applies only to Dooreye banner.',
			dooreyeAgentPosY: 'The vertical placement of the image. Applies only to Dooreye banner.',
			dooreyeAgentPosYOffset: 'The vertical offset from <code>@dooreyeAgentPosY</code>. Applies only to Dooreye banner.',
			dooreyeDooreyeBgColor: 'Background color for the fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeBgPos: 'Background url position properties for fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeBgImage: 'Filename of the image in the fixed door eye circle. Is used together with @bannerImagePath. Applies only to Dooreye banner.',
			dooreyeDooreyeBorder: 'Border properties for fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeBorderRadius: 'Border radius for fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeLineHeight: 'Line height for fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeShadow: 'Shadow properties for fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeWidth: 'Width of fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeDooreyeHeight: 'Height of fixed door eye circle. Applies only to Dooreye banner.',
			dooreyeMessageOffsetX: 'Horizontally position the content. Applies only to Dooreye banner.',
			dooreyeMessageOffsetY: 'Vertically position the content. Applies only to Dooreye banner.',
			dooreyeMessageArrow: 'Size of the arrow on content container. Applies only to Dooreye banner.',
			dooreyeResponsiveColor: 'Content font color for tablet and below. Overrides <code>@bannerResponsiveColor</code>. Applies only to Dooreye.',
			dooreyeResponsiveBgColor: 'Content background color for tablet and below. Overrides <code>@bannerResponsiveColor</code>. Applies only to Dooreye.',
			dooreyeResponsiveHeaderColor: 'Header color for tablet and below. Overrides <code>@bannerResponsiveColor</code>. Applies only to Dooreye.',
			dooreyeResponsiveHeight: 'Height on tablet and below. Applies only to Dooreye banner.',
			dooreyeResponsivePadding: 'Padding on tablet and below. Applies only to Dooreye banner.',
			dooreyeResponsiveSmallPadding: 'Padding on Phone and below. Applies only to Dooreye banner.'
		}
	}
};
