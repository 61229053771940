import React from 'react';
import createReactClass from 'create-react-class';
import TimeAgo from '../../../components/TimeAgo.react'
import SpeakerTitle from '../../../components/SpeakerTitle.react';

export default createReactClass({

	render () {
		let message = this.props.message;
		let speaker = this.props.speaker;
		if(!message.message) message.message = '';

		let title = message.title;
		let linkType = message.linkType;
		let url = message.url;
		let isIframe = url && url.indexOf('<iframe') === 0;

		return (
			<div className="flex-container">
				<h5 className="message-author-name">
					<SpeakerTitle name={speaker.name} title={speaker.titleId} isMe={speaker.isCurrentUser} />
				</h5>
				<span className="message-createdAt">
					<TimeAgo startTime={message.createdAt} relative={this.props.isMostRecentMessage} isMostRecentMessage={this.props.isMostRecentMessage}/>
				</span>
				<div className="message-text">
					<i className={linkType}>
						{isIframe ? <span dangerouslySetInnerHTML={{ __html: url }}/> : <a href={url} target="_blank">{title}</a>}
					</i>
				</div>
			</div>
		);
	}
});
