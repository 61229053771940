import Session from '../../../common/auth/Session';
import immutable from 'immutable';

let localInfo;

export default {
	setAllLocal(info) {
		localInfo = info;
	},

	getAllLocal() {
		return localInfo;
	},

	getAll() {
		const subDomainName = Session && Session.account && Session.account.subDomainName ? Session.account.subDomainName: '';
		console.log('acc', Session.account)
		let info = {};
		if (window.vngage && window.vngage.info && (typeof window.vngage.info) === 'object') {
			info = window.vngage.info;
		}
		return {
			debugTime: new Date().toISOString(),
			userAgent: navigator.userAgent,
			screen_width: screen.width,
			screen_height: screen.height,
			window_innerWidth: window.innerWidth,
			window_innerHeight: window.innerHeight,
			description: null,
			subDomainName,
			isDesktopIFramed: window !== window.top,
			...info
		};
	}
}

