export default function(filterXSS) {
    'ngInject';

    var htmlValidator = new filterXSS.FilterXSS();

    return {
        require : 'ngModel',
        scope: {
            validateHtmlOnlyWarn: '='
        },
        link: function (scope, element, attrs, ngModel) {
            scope.$watch(function () {
                return ngModel.$modelValue;
            }, function(newValue) {
                var result = htmlValidator.validate(newValue || '');
                if (result.errors) {
                    element.addClass('vngage-invalid-warning');
                    if (scope.validateHtmlOnlyWarn) {
                        scope.validateHtmlOnlyWarn = 'invalid';
                    }
                } else {
                    element.removeClass('vngage-invalid-warning');
                    if (scope.validateHtmlOnlyWarn) {
                        scope.validateHtmlOnlyWarn = 'valid';
                    }
                }
            });
        }
    };
}
