import ActionAddModalController from './ActionAddModalController';
import ActionEditController from './ActionEditController';
import ActionListController from './ActionListController';
import AccountActionService from './ActionService';
import actionTranslations from './ActionTranslations';
import ActionListTemplate from './ActionListTemplate';
import ActionEditTemplate from './ActionEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.configure.action', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('ActionAddModalController', ActionAddModalController)
	.controller('ActionEditController', ActionEditController)
	.controller('ActionListController', ActionListController)
	.factory('AccountActionService', AccountActionService)
	.constant('actionTranslations', actionTranslations)

	.constant('actionModuleConfig', {
		state: 'root.configure.action',
		access: 'Engage/Actions'
	})

	.config(function ($stateProvider, $translateProvider, actionTranslations, actionModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(actionTranslations);

		$stateProvider.state(actionModuleConfig.state, {
			url: 'action/',
			controller: 'ActionListController as vm',
			templateUrl: ActionListTemplate,
			data: {
				access: actionModuleConfig.access,
				ncyBreadcrumbLabel: 'Actions'
			},
			resolve: {
				dataLists: function (APIConfigurationSectionService, $q) {
					'ngInject';
					return $q((resolve, reject) => {
						$q.all({
							configSections: APIConfigurationSectionService.get([
								APIConfigurationSectionService.sectionMap.actionPanel,
								APIConfigurationSectionService.sectionMap.action
							], true)
						}).then((result) => {
							resolve({
								actionPanel: result.configSections[APIConfigurationSectionService.sectionMap.actionPanel],
								actions: result.configSections[APIConfigurationSectionService.sectionMap.action]
							});
						}, (error) => {
							console.log('Error resolving lists: ', error);
							reject(error);
						});
					});
				}
			}
		});

		$stateProvider.state('root.configure.action.edit', {
			url: ':id',
			controller: 'ActionEditController as vm',
			templateUrl: ActionEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				action: function ($stateParams, dataLists, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(dataLists.actions, $stateParams.id);
				},
				actionPanelList: function (dataLists) {
					'ngInject';
					return dataLists.actionPanel;
				},
				templateList: function (APIConfigurationSectionService) {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.renderTemplate);
				}
			}
		});
	});
