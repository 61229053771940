import React, {useState, useEffect, useRef} from 'react';
import TimeFormat from '../../../utils/TimeFormat';
import DateWrapper from '../../../utils/DateWrapper';

const Timestamp = (props) => {
	const [time, setTime] = useState(0);
	const [currentInterval, setCurrentInterval] = useState(0);
	const [displayRelativeDialogTime, setDisplayRelativeDialogTime] = useState(props.displayRelativeDialogTime);
	const timer = useRef(null);

	const toggleRelativeTimestamp = () => {
		setDisplayRelativeDialogTime(!displayRelativeDialogTime);
	};

	const changeInterval = (nextInterval) => {
		// remove old interval
		clearInterval(timer.current);

		// set new interval
		setCurrentInterval(nextInterval);
		timer.current = setInterval(() => tick(), nextInterval * 1000);
	};

	const tick = () => {
		const delta = Math.floor((DateWrapper.now() - props.timestamp) / 1000);
		setTime((delta < 1) ? 0: delta);

		const nextInterval = TimeFormat.getNextInterval(delta, props.isMostRecentMessage);
		if (nextInterval !== currentInterval) {
			changeInterval(nextInterval);
		}
	};

	useEffect(() => {
		tick();
		return () => {
			clearInterval(timer.current);

		};
	}, []);

	let timeStr = '';
	if (displayRelativeDialogTime) {
		if (props.isMostRecentMessage) {
			timeStr = TimeFormat.asRelative(time);
		} else {
			timeStr = TimeFormat.asRelativeWithoutSeconds(time);
		}
	} else {
		timeStr = TimeFormat.asNormal(props.timestamp);
	}
	// prepend space to avoid copying timestamp when double clicking last word
	timeStr = ' ' + timeStr;

	return (
		<span className="date-time noselect" onClick={() => toggleRelativeTimestamp()}>
			{timeStr}
		</span>
	)
};
export default Timestamp

