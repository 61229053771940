import moment from 'moment-timezone';

export default function(Session, APIStatisticsService, StatisticsFilterService) {
	'ngInject';

    var myStats = this;
    myStats.loading = false;
    myStats.stats = {
        numberOfDialogs: 0,
        successfullCases: '',
        numberOfClosedCases: 0,
        dialogDuration: ''
    };
    myStats.profile = {
        name: Session.user.profile.displayName,
        picture: Session.user.profile.profilePicture
    };
    myStats.currentRange = '';
    myStats.query = query;

    activate();

    function activate() {
        query('today');
    }

    function query(range) {
        myStats.loading = true;

        myStats.currentRange = range;

        var filter = StatisticsFilterService.create({ period: 'day', range: range });

        APIStatisticsService.queryMyStats(filter).then(update);
    }

    function update(res) {
        var data = res.data;

        myStats.stats.numberOfDialogs = data.numberOfDialogs;
        myStats.stats.numberOfClosedCases = data.numberOfClosedCases;

        if (data.dialogDurationMs && data.numberOfDialogs) {
            var dialogDuration = moment.utc(Math.round(data.dialogDurationMs / data.numberOfDialogs)).format('m ss').split(' ');
            myStats.stats.dialogDuration = dialogDuration[0] + 'min ' + dialogDuration[1] + 's';
        } else {
            myStats.stats.dialogDuration = '0min 0s';
        }

        if (data.numberOfClosedCases) {
            myStats.stats.successfullCases = Math.round(data.numberOfPositiveOutcome / data.numberOfClosedCases * 100);
        } else {
            myStats.stats.successfullCases = 0;
        }

        myStats.loading = false;
    }
};
