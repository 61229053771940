export default function (NotificationService, $translate) {
	'ngInject';

	var service = {
		notify,
		error
	};

	function error(header,body) {
		NotificationService.error({
			header: header,
			body: body
		});
	}

	function notify(res) {
		if (res.cases.length === 0 && !res.paramsUsed.next) {

			// found none at all
			NotificationService.warning({
				header: $translate.instant('caseBrowser.NOTIFICATION_NO_CASES_RETRIEVED_HEADER'),
				body: $translate.instant('caseBrowser.NOTIFICATION_NO_CASES_RETRIEVED_BODY')
			});
		} else if (res.cases.length === 0) {

			// found none and no additional cases to fetch with the current query options
			NotificationService.warning({
				header: $translate.instant('caseBrowser.NOTIFICATION_NO_MORE_CASES_RETRIEVED_HEADER'),
				body: $translate.instant('caseBrowser.NOTIFICATION_NO_MORE_CASES_RETRIEVED_BODY'),
			});
		} else if (res.cases.length < res.paramsUsed.take && !res.paramsUsed.next) {

			// found some but no additional cases to fetch with the current query options
			NotificationService.info({
				header: $translate.instant('caseBrowser.NOTIFICATION_X_CASES_RETRIEVED', {nbr: res.cases.length})
			});
		} else if (res.cases.length < res.paramsUsed.take) {

			// found some but no additional cases to fetch with the current query options
			NotificationService.info({
				header: $translate.instant('caseBrowser.NOTIFICATION_X_MORE_CASES_RETRIEVED', {nbr: res.cases.length})
			});

		} else if (!res.paramsUsed.next) {

			// first fetch and ok, can probably get more
			NotificationService.info({
				header: $translate.instant('caseBrowser.NOTIFICATION_X_CASES_RETRIEVED', {nbr: res.cases.length})
			});
		} else {

			// not first fetch, can probably get more
			NotificationService.info({
				header: $translate.instant('caseBrowser.NOTIFICATION_X_MORE_CASES_RETRIEVED', {nbr: res.cases.length})
			});
		}
	}

	return service;
};
