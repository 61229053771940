import React, {useState, useRef, useEffect} from 'react';

const ScrollText = ({text, cls, title = '', ubTrack = '', speed = 50, delay = 1000}) => {
	const [hover, setHover] = useState(false);
	const [xMax, setXMax] = useState(0);
	const [scrollTime, setScrollTime] = useState(0);
	const [doScroll, setDoScroll] = useState(false);
	const element = useRef();

	useEffect(() => {
		// caclulate scroll length and transition speed
		const len = element.current.offsetWidth - element.current.scrollWidth;
		setXMax(len);
		setScrollTime(Math.abs(len / speed));
	}, [text, speed]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setDoScroll(hover);
		}, delay);
		return () => {
			clearTimeout(timer);
			setDoScroll(false);
		};
	}, [hover, delay]);


	const style = xMax === 0
		? {
			whiteSpace: 'nowrap',
			display: 'inline-block',
		}
		: doScroll
			? {
				whiteSpace: 'nowrap',
				display: 'inline-block',
				transform: `translateX(${xMax}px)`,
				transition: `transform ${scrollTime}s linear`
			}
			: {};

	return (
		<div
			className={cls}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			title={title}
			ref={element}
			ub-track={ubTrack}
		>
			<span style={style} ub-track={ubTrack}>
				{text}
			</span>
		</div>
	);
};
export default ScrollText;

