import _indexOf from 'lodash/indexOf';

export default function () {
	'ngInject';

	return function (groupList, groupFilter) {
		if (!groupList) {
			return groupList;
		}
		if (!groupFilter || groupFilter.length === 0) {
			return groupList;
		}
		return groupList.filter(function (group) {
			return _indexOf(groupFilter, group.groupId) !== -1;
		});
	};
}
