import React from 'react';
import {useGetTranslation} from '../LangContext';
import mmd from 'mmd';
import TimeFormat from '../../utils/TimeFormat';

const VisitorTooltip = ({title, claims, isBookedMeeting, bookedMeetingTime}) => {
	const getTranslation = useGetTranslation();
	return (
		<div>
			<div>
				<p dangerouslySetInnerHTML={{__html: mmd(title)}}/>
				{isBookedMeeting
					? <p>{getTranslation('meetingStart') + ': ' + TimeFormat.asReservationTime(bookedMeetingTime)}</p>
					: null
				}
			</div>
			{claims.map((claim, i) => <p key={i}>
				<b>{claim.get('type')}: </b>{claim.get('value')}
			</p>)}
		</div>
	)
};

export default VisitorTooltip;
