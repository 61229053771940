import vngageAddModalTemplate from '../../../common/vngageAddModalTemplate';
import _map from 'lodash/map';

export default function ($scope, $state, $uibModal, opportunityList, OpportunityService, solutionList) {
	'ngInject';

	var vm = this;
	vm.opportunityList = _map(opportunityList, item => {
		if (item.data.section.solutions) {
			item.data.solutionsByName = _map(OpportunityService.formatSelectedItems(item, solutionList), 'displayName');
		} else {
			item.data.solutionsByName = [];
		}
		return item;
	});

	vm.listOrder = OpportunityService.prioritySetter(opportunityList);

	vm.save = save;

	vm.options = {
		stateLink: {
			state: 'root.analyze.opportunity.edit',
			property: 'data.id'
		},
		createItem: {
			langKey: 'opportunity.ADD_HEADER',
			method: addNew
		},
		priority: vm.listOrder,
		hideIndex: true
	};
	vm.columns = [{
		header: 'opportunity.POSITION',
		property: '',
		transform: vm.listOrder.position,
		sortDefault: true,
		width: '100px'
	}, {
		header: 'opportunity.SAVED_POSITION',
		property: 'data.section.position',
		width: '140px'
	}, {
		header: 'GUID',
		property: 'data.id',
		hidden: true,
		useInFilter: true
	}, {
		header: 'opportunity.NAME',
		property: 'data.name',
		width: '30%',
		useInFilter: true
	}, {
		header: 'Solutions in use',
		property: 'data.solutionsByName',
		type: 'label-list'
	}];


	$scope.$watch('vm.listOrder.isChanged', () => {
		if (vm.listOrder.isChanged) {
			vm.opportunityPriorityForm.$setDirty();
		}
	});


	function openAddModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: vngageAddModalTemplate,
			controller: 'OpportunityAddModalController as vm',
			resolve: {
				options: () => {
					return {
						header: 'opportunity.ADD_HEADER',
						label: 'opportunity.NAME'
					};
				},
				newItemPriority: () => {
					return vm.listOrder.newItemPriority();
				}
			}
		});
		modal.result.then(newOpportunity => {
			opportunityList.push(newOpportunity);
			vm.listOrder.add(newOpportunity);
			$state.go('.edit', {id: newOpportunity.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		$state.go('root.analyze.opportunity').then(openAddModal);
	}

	function save() {
		vm.listOrder.save().then(() => {
			vm.opportunityPriorityForm.$setPristine();
		});
	}
};
