import React, {useEffect} from 'react';
import keyboardJS from 'keyboardjs';

const bindKeysWithContext = (c, props) => {
	keyboardJS.withContext(c, () => {
		keyboardJS.bind('right', props.moveForward1);
		keyboardJS.bind('left', props.moveBackward1);
		keyboardJS.bind('shift+right', props.moveForward10);
		keyboardJS.bind('shift+left', props.moveBackward10);
		keyboardJS.bind('alt+right', props.moveForward100);
		keyboardJS.bind('alt+left', props.moveBackward100);
		keyboardJS.bind('shift+alt+right', props.moveForward1000);
		keyboardJS.bind('shift+alt+left', props.moveBackward1000);
		keyboardJS.bind('down', () => props.logAll());
		keyboardJS.bind('up', () => props.logCls());
	});
};

const unbindKeysWithContext = (c) => {
	keyboardJS.withContext(c, () => {
		keyboardJS.unbind('right');
		keyboardJS.unbind('left');
		keyboardJS.unbind('shift+right');
		keyboardJS.unbind('shift+left');
		keyboardJS.unbind('alt+right');
		keyboardJS.unbind('alt+left');
		keyboardJS.unbind('shift+alt+right');
		keyboardJS.unbind('shift+alt+left');
		keyboardJS.unbind('down');
		keyboardJS.unbind('up');
	});
};

const bindKeys = (props) => {
	bindKeysWithContext('', props);
	// bindKeysWithContext('dialog', props);
	bindKeysWithContext('debug', props);
};

const unbindKeys = () => {
	unbindKeysWithContext('');
	// unbindKeysWithContext('dialog');
	unbindKeysWithContext('debug');
};

const DebugKeyHandler = (props) => {

	const {useDebugKeys, ...keyProps} = props;

	useEffect(() => {
		bindKeys(props);
		if (props.useDebugKeys) {
			keyboardJS.setContext('debug');
		} else {
			keyboardJS.setContext('dialog');
		}
		return () => {
			unbindKeys();
		};
	}, [props]);


	return <div/>;
};

export default DebugKeyHandler;

