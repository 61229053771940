import _find from 'lodash/find';
import _remove from 'lodash/remove';

export default function (APIBatchRequestService, TrustRelationshipRemoveService, TrustRelationshipCreateService) {
	'ngInject';

	return {
		getSelectableTrusts: getSelectableTrusts,
		getSelectedTrusts: getSelectedTrusts,
		populateTrustRelationships: populateTrustRelationships,
		updateTrustedClaimTypes: updateTrustedClaimTypes,
		getTrustedRoleClaims: getTrustedRoleClaims,
		getMatchedRoleClaims: getMatchedRoleClaims,
		mapSelectableRoleClaims: mapSelectableRoleClaims,
		updateTrustRelationships: updateTrustRelationships
	};

	/**
	 * get selectable trust
	 * @param accounts
	 * @param accountId
	 * @returns {Array}
	 */
	function getSelectableTrusts(accounts, accountId) {
		return accounts
			.filter(function (a) {
				return a.data.id !== accountId;
			}).map(function (a) {
				return {
					refValue: a.data.id,
					displayName: a.data.displayName
				};
			});
	}

	/**
	 * map trustedAccountId of each relation
	 * @param relations
	 * @returns {Array}
	 */
	function getSelectedTrusts(relations) {
		return relations.map(function (t) {
			return t.data.trustedAccountId;
		});
	}

	/**
	 * set trustedAccountName for each relation
	 * @param accounts
	 * @param relations
	 */
	function populateTrustRelationships(accounts, relations) {
		return relations.forEach(function (t) {
			var found = _find(accounts, function (a) {
				return a.data.id === t.data.trustedAccountId;
			});

			if (found) {
				t.data.trustedAccountName = found.data.displayName;
			}
		});
	}

	/**
	 * get trusted role claims
	 * @param role
	 * @param claimTypes
	 * @returns {Array}
	 */
	function getTrustedRoleClaims(role, claimTypes) {
		var trustedRoleClaims = _find(claimTypes, function (t) {
			return t.claimType === role.data.claimType;
		});

		return trustedRoleClaims ? trustedRoleClaims.trustedRoleClaimTypes || [] : [];
	}

	/**
	 * get matched role claims
	 * @param claims
	 * @param types
	 * @returns {Array}
	 */
	function getMatchedRoleClaims(claims, types) {
		var selectedRoleClaims = [];

		// Map every roleclaim type to the current trusted account roles
		types.forEach(function (t) {
			var found = _find(claims, function (r) {
				return r.data.claimType === t;
			});

			if (found) {
				selectedRoleClaims.push(found);
			}
		});

		return selectedRoleClaims;
	}

	/**
	 * maps selectable role claims
	 * @param claims
	 * @returns {Object}
	 */
	function mapSelectableRoleClaims(claims) {
		return claims.map(function (r) {
			return {
				refValue: r.data.claimType,
				displayName: r.data.displayName
			};
		});
	}

	/**
	 *
	 * @param accountId
	 * @param trustRelationships
	 * @param trustedAccountIds
	 * @param allTrustedAccountIds
	 * @returns {*}
	 */
	function updateTrustRelationships(accountId, trustRelationships, trustedAccountIds, allTrustedAccountIds) {
		var trustUrl = 'account/:accountId/trustrelationship/',
			trustsToRemove,
			trustsToCreate,
			requests;

		trustsToCreate = TrustRelationshipCreateService.create(trustUrl, accountId, trustRelationships, trustedAccountIds);
		trustsToRemove = TrustRelationshipRemoveService.remove(trustUrl, accountId, trustRelationships, trustedAccountIds, allTrustedAccountIds);

		requests = trustsToCreate.concat(trustsToRemove);

		return APIBatchRequestService.execute(requests);
	}

	/**
	 *
	 * @param trustedClaimType
	 * @param trustRelationship
	 */
	function updateTrustedClaimTypes(trustedClaimType, trustRelationship) {
		trustRelationship.trustedClaimTypes = trustRelationship.trustedClaimTypes || [];

		var existingTrustedClaimType = _find(trustRelationship.trustedClaimTypes, function (tr) {
			return tr.claimType === trustedClaimType.claimType;
		});

		if (!existingTrustedClaimType) {
			trustRelationship.trustedClaimTypes.push(trustedClaimType);
		} else if (trustedClaimType.trustedRoleClaimTypes.length === 0) {
			_remove(trustRelationship.trustedClaimTypes, function (tr) {
				return tr.claimType === trustedClaimType.claimType;
			});
		} else {
			existingTrustedClaimType.trustedRoleClaimTypes = trustedClaimType.trustedRoleClaimTypes;
		}
	}
};
