import React, {useState} from 'react';
import {connect} from 'react-redux';
import Header from './Header.react';
import RestrictedOverlay from './RestrictedOverlay.react';
import BlockedOverlay from './BlockedOverlay.react';
import DomUploadSpinner from './DomUploadSpinner.react';
import {CoChannel} from './CoChannel.react';
import DisconnectedOverlay from './DisconnectedOverlay.react';
import ConversationActions from '../../../actions/ConversationActions';
import LogActions from '../../../actions/LogActions';
import CoBrowsing from '../../../actionCreators/CoBrowsing';
import Message from '../../../actionCreators/Message';
import UserPreferencesAC from '../../../actionCreators/UserPreferencesAC';
import immutable from 'immutable';

export const CoBrowser = (props) => {
	const [pageTitle, setPageTitle] = useState('');

	const elementHighLighted = (targetData) => {
		props.updateDomHighlight(props.conversationId, targetData);
	};

	const updateCoBroUrlFromMenu = (url) => {
		props.pageLoaded(url, 'menu');
	};

	const lastUrl = props.coBro.get('navigation').last() || immutable.fromJS({url: ''});
	const coChannelPayload = {
		highlightMode: props.coBro.get('highlightMode'),
		isScaled: props.coBro.get('isScaled'),
		paused: props.coBro.get('paused'),
		restricted: props.coBro.get('restricted'),
		isBlocked: props.coBro.get('isBlocked'),
		url: props.coBro.get('url'),
		refreshUrlCounter: props.coBro.get('refreshUrlCounter'),
		lastUrl,
		metadataExchangeMessages: props.coBro.get('metadataExchangeMessages'),
		toggleCoBroPause: props.toggleCoBroPause,
		sendMetadataExchange: props.sendMetadataExchange,

		conversationId: props.conversationId,
		accountId: props.accountId,
		panelContainerWidth: props.panelContainerWidth,
		visitorWidth: props.browserInfo.get('width'),
		infoTipDoNotDisturb: props.infoTipDoNotDisturb,
		toggleActiveInfoTip: props.toggleActiveInfoTip,
		hideInfoTip: props.hideInfoTip,
		cobroError: props.cobroError,
		cobroLog: props.cobroLog,

		elementHighLighted,
		pageLoaded: props.pageLoaded,
		setPageTitle,
	};

	const headerPayload = {
		highlightMode: props.coBro.get('highlightMode'),
		isScaled: props.coBro.get('isScaled'),
		doNotDisturb: props.coBro.get('doNotDisturb'),
		navigation: props.coBro.get('navigation'),
		paused: props.coBro.get('paused'),
		restricted: props.coBro.get('restricted'),
		addressbarNavigation: props.coBro.get('addressbarNavigation'),
		url: props.coBro.get('url'),

		conversationId: props.conversationId,
		connectionStatus: props.connectionStatus,
		currentCaseType: props.currentCaseType,
		setCoBroHighlightMode: props.setCoBroHighlightMode,
		toggleCoBroScale: props.toggleCoBroScale,
		toggleCoBro: props.toggleCoBro,
		sendAction: props.sendAction,
		toggleCoBroPause: props.toggleCoBroPause,
		sendDomUploadRequest: props.sendDomUploadRequest,

		updateCoBroUrl: updateCoBroUrlFromMenu,
		enabledFeatures: props.enabledFeatures,

		pageTitle,
	};

	const isDisconnected = props.conversationStatus === 'closed' || props.connectionStatus !== 'ok';
	const isBlocked = props.coBro.get('isBlocked') && !props.coBro.get('paused');
	const isRestricted = props.coBro.get('restricted') && !props.coBro.get('paused');
	const isWaitingForDomUpload = props.coBro.get('domUploadStatus') !== 'ready';
	const overlay = isDisconnected
		? <DisconnectedOverlay
			toggleCoBro={props.toggleCoBro}
			conversationId={props.conversationId}
			enabledFeatures={props.enabledFeatures}
		/>
		: isBlocked
			? <BlockedOverlay
				url={lastUrl.get('blockedUrl')}
				toggleCoBro={props.toggleCoBro}
				conversationId={props.conversationId}
				enabledFeatures={props.enabledFeatures}
			/>
			: isRestricted
				? <RestrictedOverlay
					toggleCoBroPause={props.toggleCoBroPause}/>
				: isWaitingForDomUpload
					? <DomUploadSpinner
						setCoBroDomUploadStatus={props.setCoBroDomUploadStatus}
					/>
					: null;//'no overlay'

	const browserWrapperCls = `browser-wrapper ${isWaitingForDomUpload ? 'pending-content-upload': ''}`;
	return <div className={browserWrapperCls}>
		{overlay}
		<Header {...headerPayload}/>
		<CoChannel {...coChannelPayload}/>
	</div>;
};

const enabledFeaturesDefault = {
	pause: true,
	scale: true,
	highlight: true,
	domUpload: true,
	exit: true,
	extrasMenu: true,
	actionsMenu: true,
	addressBar: true
};

function mapStateToProps(state, ownProps) {
	// incoming
	// ownProps.panelContainerWidth
	// ownProps.conversationId
	// ownProps.enabledFeatures
	const enabledFeatures = ownProps.enabledFeatures
		? {...enabledFeaturesDefault, ...ownProps.enabledFeatures}
		: enabledFeaturesDefault;

	const conversationId = state.getIn(['coBro', 'conversationId']);
	const conversation = state.getIn(['conversations', conversationId]) || immutable.Map();
	return {
		enabledFeatures,
		// this
		coBro: state.get('coBro'),
		conversationStatus: conversation.get('conversationStatus') || '',

		// coChannel
		browserInfo: state.getIn(['conversations', conversationId, 'browserInfo']) || immutable.Map({width: 0}),
		infoTipDoNotDisturb: state.getIn(['infoTip', 'doNotDisturb']),
		accountId: state.get('accountId') || '',

		// header
		connectionStatus: conversation.get('connectionStatus') || '',
		currentCaseType: conversation.get('currentCaseType') || '',
	};
}

function mapDispatchToProps(dispatch) {
	return {
		// this
		updateDomHighlight: (conversationId, targetData) => dispatch(CoBrowsing.updateDomHighlight(conversationId, targetData)),

		// header this
		setCoBroHighlightMode: (isActive) => dispatch(ConversationActions.setCoBroHighlightMode(isActive)),

		// header
		toggleCoBroScale: () => dispatch(ConversationActions.toggleCoBroScale()),
		sendDomUploadRequest: () => dispatch(CoBrowsing.sendDomUploadRequest()),
		sendAction: (conversationId, actionObj) => dispatch(Message.sendAction(conversationId, actionObj)),

		// header DisconnectedOverlay
		toggleCoBro: (conversationId) => dispatch(CoBrowsing.toggleCoBro(conversationId)),

		// header coChannel RestrictedOverlay
		toggleCoBroPause: () => dispatch(CoBrowsing.toggleCoBroPause()),

		// DomUploadSpinner
		setCoBroDomUploadStatus: (status) => dispatch(ConversationActions.setCoBroDomUploadStatus(status)),

		// coChannel
		toggleActiveInfoTip: (infoType) => dispatch(UserPreferencesAC.toggleActiveInfoTip(infoType)),
		hideInfoTip: (infoType) => dispatch(ConversationActions.hideInfoTip(infoType)),
		cobroLog: (description, data) => dispatch(LogActions.cobroLog(description, data)),
		cobroError: (error, data) => dispatch(LogActions.cobroError(error, data)),
		sendMetadataExchange: (conversationId, params) => dispatch(Message.sendMetadataExchange(conversationId, params)),

		// coChannel this
		pageLoaded: (url, updateType) => dispatch(CoBrowsing.pageLoaded(url, updateType)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CoBrowser);
