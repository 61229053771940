import _each from 'lodash/each';
import moment from 'moment-timezone';
import vngageGroupOverviewTemplate from './vngageGroupOverviewTemplate';
import formatDuration from '../../../../common/formatDuration.js';

export default function (d3, crossfilter, dc, GroupOverviewDimensionService, $translate) {
	'ngInject';

	return {
		restrict: 'A',
		require: '^ngModel',
		templateUrl: vngageGroupOverviewTemplate,
		link: function (scope, element, attrs) {

			const charts = [];
			scope.formatDuration = formatDuration;

			scope.$watch(attrs.ngModel, function (v) {
				if (!v) { return; }
				if (v.summary) {
					scope.summary = v.summary;
				}
				scope.groups = v.groups;
				if (v.groups) {
					GroupOverviewDimensionService.calculate(v.groups).then(draw);
				}
			}, true);

			function draw(dimension) {

				if (charts.length === 0) {
					charts.push(table(dc.dataTable('#groupTable'), dimension));
					//charts.push(pie(dc.pieChart('#groupChart'), dimension));

					charts.forEach(function (c) {
						return c.render();
					});
				} else {
					charts.forEach(function (c) {
						return c.redraw();
					});
				}
			}

			function table(dataTable, dimension) {

				return dataTable
					.dimension(dimension.groupBy.groupId)
					.group(function () {
						return '';
					})
					.sortBy(function (d) {
						return d.value.usersOnline;
					})
					.order(d3.descending)
					.columns([

						function (d) {
							// Group name
							const kpis = d.value.kpis;
							let groupName = '';
							if (kpis && (kpis.maxWaitingTimeReached || kpis.maxNumberOfVisitorsWaitingReached)) {
								groupName += '<span class="vngage-icon-attention kpi-reached-text" title="' + $translate.instant('dashboard.groupOverview.THRESHOLD_EXCEEDED') + '"></span>&nbsp;';
							}

							groupName += '<span class="bold-text">';
							groupName += d.value.groupName ? d.value.groupName : '';
							groupName += '</span>';

							return groupName;
						},
						function (d) {
							// Agents in desktop
							return d.value.usersOnline;
						},
						function (d) {
							// Visitors in queue
							const value = d.value.visitorsWaitingForDialog;
							if (d.value.kpis && d.value.kpis.maxNumberOfVisitorsWaitingReached) {
								return [
									'<span class="kpi" title="',
									$translate.instant('dashboard.groupOverview.MAX_ALLOWED_USERS_EXCEEDED', {amount: d.value.kpis.maxNumberOfVisitorsWaitingForDialog}),
									'">',
									value,
									'</span>'
								].join('');
							}
							return value;
						},
						function (d) {
							// Active dialogs
							return '<span>' + d.value.visitorsInDialog + '</span>';
						},
						function (d) {
							// Avg waiting time
							return '<span class="aggregated-data">' + formatDuration(d.value.averageWaitingTime) + '</span>';
						},
						function (d) {
							// Max waiting time
							const value = formatDuration(d.value.maxWaitingDurationMs);

							if (d.value.kpis && d.value.kpis.maxWaitingTimeReached) {
								return [
									'<span class="kpi" title="',
									$translate.instant('dashboard.groupOverview.MAX_ALLOWED_WAITING_TIME_EXCEEDED', {maxTime: d.value.kpis.maxWaitingTimeMinutesSeconds}),
									'">',
									value,
									'</span>'
								].join('');
							}

							return '<span class="aggregated-data">' + value + '</span>';
						},
/*
							function (d) {
								// Avg dialog time
								return '<span class="aggregated-data">' + formatDuration(d.value.averageDialogTimeCalculated) + '</span>';
							},
							function (d) {
								// Max dialog time
								return '<span class="aggregated-data">' + formatDuration(d.value.maxDialogDurationCalculatedMs) + '</span>';
							}
*/
					]).renderlet(function () {
						dataTable.selectAll('.dc-table-row')
							.forEach(function (r) {
								r.forEach(function (e) {
									if (e.__data__) {
										const color = dimension.colors[e.__data__.key];
										e.style.borderLeft = '4px solid ' + color;
									}
									const columns = e.querySelectorAll('td');
									_each(columns, function (c) {
										const kpi = c.querySelector('span.kpi');
										if (kpi) {
											angular.element(c).addClass('kpi-reached');
										}
									});
								});
							});
					});
			}

			function pie(pieChart, dimension) {

				return pieChart.width(140)
					.height(140)
					.transitionDuration(0)
					.dimension(dimension.by.groupId)
					.renderLabel(false)
					.title(function (d) {
						if (!d.value.averageWaitingTime) {
							return null;
						}

						const averageWaitingTime = moment.utc(d.value.averageWaitingTime).format('m s').split(' ');
						return d.value.groupName + ' / ' + averageWaitingTime[0] + 'm ' + averageWaitingTime[1] + 's';
					})
					.group(dimension.groupBy.groupId)
					.colors(function (d) {
						return dimension.colors[d];
					})
					.valueAccessor(function (d) {
						return d.value.averageWaitingTime;
					});
			}
		}
	};
}
