function getBrowserPlugins() {
	var i = 0,
		len = navigator.plugins.length,
		plugins = [],
		plugin;

	for (i = 0; i < len; i++) {
		plugin = navigator.plugins[i];
		plugins.push(
			plugin.name + ' (' + plugin.filename + ') - ' + plugin.description.substring(0, 80)
		);
	}
	return plugins;
}

function getVisitors() {
	var data = [];
	if (psPlugin.application.visitorHandler && psPlugin.application.visitorHandler.visitors) {
		var visitors = psPlugin.application.visitorHandler.visitors;
		for (var v in visitors) {
			data.push({
				currentState: visitors[v].stateMachine.currentState,
				properties: visitors[v].properties,
				_guid: v
			});
		}
		return data;
	}
	return false;
}

function getUserInfo() {
	var user = psPlugin.shell.session.user;
	if (!user) return false;
	return {
		name: user.name,
		email: user.email,
		id: user.id
	};
}

function getAccountInfo() {
	var acc = psPlugin.shell.session.account;
	if (!acc) return false;
	return {
		name: acc.name,
		id: acc.id,
		created: acc.created
	};
}

class ExceptionHandler {

	// ExceptionHandler:
	// generates an object containing the most important data from the psPlugin object
	// then sends it off to Youtrack
	constructor($http, Session, vngageConfig, conversationRef) {
		'ngInject';
	}

	generate (prettyPrint) {

		var data = {
			timestamp: new Date(),
			account: getAccountInfo(),
			user: getUserInfo(),
			__sessionId: psPlugin.__sessionId || '',
			currentApplication: psPlugin.application.name || '',
			visitors: getVisitors(),
			browser: {
				info: psPlugin.shell.browser,
				userAgent: navigator.userAgent,
				plugins: getBrowserPlugins()
			}
		};

		if (prettyPrint) {
			return JSON.stringify(data, null, '  ');
		}
		return data;
	}

	send (errorCode, msg, commands, responseData) {

		var p = new connection.promise.Promise();

		var url = composeUri(vngageConfig.restUrl, 'Log'),
			headers = {'Content-Type': 'application/json'},
			data = generate();

		if (responseData && responseData !== '') {
			responseData = JSON.stringify(responseData);
			data.responseData = responseData;
		}
		if (errorCode) data.errorCode = errorCode;
		if (msg) data.msg = msg;
		if (commands) data.commands = commands;

		//add from which hostName the data comes from:
		data.fromHost = document.location.hostname;


		//Don't report issues if we're on Localhost:
		// if (document.location.hostname === 'localhost') {
		//	console.error('ExceptionHandler Log:');
		//	console.log(data);
		//	p.done(null, data);
		//	return p;
		//}
		//console.log('Exception about to be reported! Here is the Log:\n', data);

		console.log(data);
		connection.promise.post(url, JSON.stringify(data), headers).then(function (error, response) {
			if (error) {
				p.done(error, response);
				return p;
			}
			p.done(null, data);
		});

		return p;
	}
}


export default ExceptionHandler;


