import vngageColumnTableTemplate from './vngageColumnTableTemplate';

export default function () {
	'ngInject';

	return {
		restrict: 'E',
		scope: {
			columns: '=',
			options: '=',
			resourceSource: '=resource'
		},
		replace: true,
		transclude: true,
		bindToController: true,
		controller: 'vngageColumnTableController as vm',
		templateUrl: vngageColumnTableTemplate
	};
};
