import _omit from 'lodash/omit';
import _findLast from 'lodash/findLast';
import CSDispatcher from '../dispatcher/CSDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import MessageUtils from '../../../components/utils/MessageUtils';
import WebAPIUtils from '../utils/WebAPIUtils';
import ConversationsStore from '../stores/ConversationsStore';
import ParticipantStore from '../stores/ParticipantStore';
import VisitorProfileStore from '../stores/VisitorProfileStore';
import AbilityAuthorizations from '../../../components/records/AbilityAuthorizationsRecord';

import angularInjector from '../../../common/AngularInjector';

const ActionTypes = AppConstants.ActionTypes;


export default {

	/**
	 * create chat message vo
	 * dispatches for optimistic insert
	 * post
	 * @param {string} text
	 * @param {string} conversationId
 	 * @param {Object} profile
	 */
	createMessage (text, conversationId, profile) {
		let createdMessageData = MessageUtils.getCreatedMessageData(text, conversationId);
		let message = _omit(createdMessageData, 'speaker');
		let serverMsg = _omit(message, 'id');

		WebAPIUtils.createMessage(serverMsg, conversationId);

		CSDispatcher.handleViewAction({
			type: ActionTypes.CREATE_MESSAGE,
			message: message,
			conversationId: conversationId,
			profile: profile
		});
	},

	/**
	 * create note message vo
	 * dispatches for optimistic insert
	 * post
	 * @param {string} text
	 * @param {string} id
	 * @param {Object} profile
	 */
	createNoteMessage (text, id, profile) {
		let message = _omit(MessageUtils.getNoteData(text, id), ['id', 'speaker', 'isMostRecentMessage']);
		WebAPIUtils.createMessage(message, id);
		CSDispatcher.handleViewAction({
			type: ActionTypes.CREATE_NOTE,
			message: message,
			conversationId: id,
			profile: profile
		});
	},

	/**
	 * create link message vo
	 * dispatches for optimistic insert
	 * post
	 * @param {Object} payloadData
	 * @param {string} conversationId
	 * @param {Object} profile
	 */
	createLinkMessage (payloadData, conversationId, profile) {
		CSDispatcher.handleViewAction({
			type: ActionTypes.CREATE_LINK_MESSAGE,
			data: payloadData,
			conversationId: conversationId,
			profile: profile
		});
		let message = _omit(MessageUtils.getLinkData(payloadData, conversationId), 'id', 'speaker', 'isMostRecentMessage');
		WebAPIUtils.createMessage(message, conversationId);
	},

	/**
	 * create navigation message vo
	 * post
	 * @param {Object} payloadData
	 * @param {string} conversationId
	 */
	createNavigationMessage(payloadData, conversationId) {
		let message = MessageUtils.getNavigationData(payloadData, conversationId);
		WebAPIUtils.createMessage(message, conversationId);
	},

	/**
	 * send page link of visual guidance url
	 * @param {string} conversationId
	 */
	visualGuidanceSendPageLink (conversationId) {
		let conversation = ConversationsStore.find(conversationId);
		if (conversation && conversation.visualGuidance) {
			//Call legacy modal
			let link = conversation.visualGuidance.getSendPageLinkData();
			psPlugin.showSendPageModal(link, conversationId);
		}
	},

	/**
	 * send link action message
	 * @param {Object} payloadData
	 * @param {string} conversationId
	 */
	createSendPageLink (payloadData, conversationId) {
		//WebAPIUtils.createMessage(message, conversationId);
		CSDispatcher.handleViewAction({
			type: ActionTypes.CREATE_LINK_MESSAGE,
			data: payloadData,
			conversationId: conversationId
		});
		payloadData.linkType = 'link';
		this.createLinkMessage(payloadData, conversationId);
	},

	/**
	 * change authorization of participant
	 * @param {string} conversationId
	 * @param {string} participantId
	 * @param {string} authorization
	 */
	changeAuthorization (conversationId, participantId, authorization) {
		const { key, value } = authorization;

		let authorizations = [];
		switch (key) {
			case 'coBrowsing' :
				authorizations = AbilityAuthorizations.coBrowsing;
				break;

			case 'video' :
				authorizations = AbilityAuthorizations.video;
				break;
		}

		let toAdd = (value) ? authorizations : [];
		let toRemove = (value) ? [] : authorizations;
		let data = {
			participationId: participantId,
			add : toAdd,
			remove: toRemove
		};

		WebAPIUtils.authorizeParticipant(conversationId, data)
			.then( response => {
				//console.log('.:Authorize:.', response);
				CSDispatcher.handleViewAction({
					type: ActionTypes.PARTICIPANT_AUTHORIZATION,
					conversationId: conversationId,
					participantId: participantId,
					authorization: key,
					value: value
				});
			}, err => {
				//console.log('.:Authorize:. error', err);
			});
	},

	/**
	 * create activity message vo - participantWriting
	 * post
	 * @param {string} conversationId
	 * @param {boolean} isTyping
	 */
	participantWriting (conversationId, isTyping) {
		WebAPIUtils.createActivityMessage(conversationId, {
			type: 'participantWriting',
			change: isTyping ? 'set' : 'reset',
			activity: 'writing'
		});
	},

	/**
	 * Send transcript of conversation by email
	 * @param {string} conversationId
	 * @param {string} email
	 */
	emailConversationTranscript(conversationId, email){
		WebAPIUtils.emailTranscript(conversationId, email);
	},

	/**
	 * create highlight message vo
	 * dispatch internal
	 * post
	 * @param {string} conversationId
	 * @param {Object} data
	 */
	sendHighlight (conversationId, data) {
		CSDispatcher.handleViewAction({
			type: ActionTypes.VISUAL_GUIDANCE,
			conversationId: conversationId,
			action: 'sendHighlight',
			data: data
		});

		// notify the others
		let message = MessageUtils.getHighlightMessageData(data);
		WebAPIUtils.createMessage(message, conversationId);
	},

	/**
	 * toggle highlight status
	 * dispatch internal
	 * @param {string} conversationId
	 * @param {boolean} active
	 */
	toggleHighlight (conversationId, active) {
		CSDispatcher.handleViewAction({
			type: ActionTypes.VISUAL_GUIDANCE,
			conversationId: conversationId,
			action:'toggleHighlight',
			isActive: active
		});
	},

	/**
	 * create askForDomUpload message vo
	 * dispatch internal status
	 * post
	 * @param {string} conversationId
	 */
	requestDomUpload (conversationId) {
		CSDispatcher.handleViewAction({
			type: ActionTypes.COBROWSER_DOMUPLOAD,
			conversationId: conversationId,
			reqState: 'Pending'
		});

		let message = MessageUtils.getAskForDomUploadMessageData();
		WebAPIUtils.createMessage(message, conversationId);
	},

	/**
	 * toggle visualguidance activity
	 * dispatch internal status
	 * @param {string} conversationId
	 * @param {boolean} isPaused
	 */
	toggleVisualGuidancePause (conversationId, isPaused) {
		CSDispatcher.handleViewAction({
			type: ActionTypes.VISUAL_GUIDANCE,
			conversationId: conversationId,
			action:'togglePause',
			isPaused: isPaused
		});
	},

	/**
	 * toggle visualguidance view scale
	 * dispatch internal status
	 * @param {string} conversationId
	 * @param {boolean} isScaled
	 */
	toggleVisualGuidanceScale (conversationId, isScaled) {
		CSDispatcher.handleViewAction({
			type: ActionTypes.TOGGLE_VISUAL_GUIDANCE_SCALE,
			conversationId: conversationId,
			isScaled: isScaled

		});
	},

	/**
	 * toggle visualguidance statusbar status
	 * dispatch internal status
	 * @param {string} conversationId
	 * @param {boolean} show
	 */
	visualGuidanceToggleStatusBar (conversationId, show) {
		CSDispatcher.handleViewAction({
			type: ActionTypes.VISUAL_GUIDANCE,
			conversationId: conversationId,
			action:'displayStatusBar',
			show: show
		});
	},

	/**
	 * activate visualguidance jsChannel
	 * dispatch internal status
	 * @param {string} conversationId
	 */
	activateVisualGuidanceChannel (conversationId) {
		let conversation = ConversationsStore.find(conversationId);
		if (conversation) {
			if (conversation.visualGuidance) {
				conversation.visualGuidance.getChannel();
			}
		}
	},

	/**
	 * deactivate visualguidance jsChannel
	 * dispatch internal status
	 * @param {string} conversationId
	 */
	deactivateVisualGuidanceChannel (conversationId) {
		let conversation = ConversationsStore.find(conversationId);
		if (conversation && conversation.visualGuidance.channel) {
			conversation.visualGuidance.destroyChannel();
		}
	},

	/**
	 * visualguidance has loaded its iframe
	 * dispatch internal status
	 * @param {string} conversationId
	 */
	visualGuidanceiFrameLoaded (conversationId) {
		CSDispatcher.handleViewAction({
			type: ActionTypes.VISUAL_GUIDANCE,
			conversationId: conversationId
		});
	},

	/**
	 * create video message vo
	 * post
	 * @param {string} conversationId
 	 * @param {Object} data
	 */
	setupVideoChat (conversationId, data) {
		let message = MessageUtils.getVideoMessageData({activity:'start', streamId:conversationId});
		CSDispatcher.handleViewAction({
			type: ActionTypes.CREATE_VIDEO_INVITATION,
			message: message,
			conversationId: conversationId,
			isStreaming: true
		});
	},

	/**
	 * send message to participants that we're starting up a videochat
	 * @param {string} conversationId
	 */
	sendVideoChatInvitation (conversationId) {
		let message = MessageUtils.getVideoMessageData({activity:'start', streamId:conversationId});
		WebAPIUtils.createMessage(message, conversationId);
	},

	/**
	 * send message to participants that we're closing down videostream
	 * @param {string} conversationId
	 */
	sendVideoChatClosure (conversationId) {
		let message = MessageUtils.getVideoMessageData({activity:'stop', streamId:conversationId});
		WebAPIUtils.createMessage(message, conversationId);

		//just stop the video stream
		CSDispatcher.handleViewAction({
			type: ActionTypes.VIDEO_STREAM,
			conversationId: conversationId,
			isStreaming: false
		});
	},

	/**
	 * set title (state) of visitor
	 * @param {string} id
	 * @param {string} title
	 */
	visitorSetTitle (id, title) {
		CSDispatcher.handleViewAction({
			type: ActionTypes.VISITOR_HEADER_TITLE,
			visitId: id,
			title: title
		});
	},

	/**
	 * fetch visitor profile data claims
	 * @param {string} id
	 */
	visitorGetProfile (id) {
		WebAPIUtils.getProfile(id);
	},

	/**
	 * update visitor profile data claims
	 * @param {string} id
	 * @param {Array} claims
	 */
	visitorUpdateProfile (id, claims) {
		let profile = VisitorProfileStore.getProfileById(id);
		if (profile) {
			WebAPIUtils.updateProfile(id, profile.claims);
		}
	},

	/**
	 * update visitor profile claims locally
	 * @param {string} visitId
	 * @param {string} claim
	 * @param {string} value
	 */
	visitorUpdateProfileClaims (visitId, claim, value) {
		CSDispatcher.handleViewAction({
			type: ActionTypes.VISITOR_PROFILE_UDPATECLAIMS,
			visitId: visitId,
			claim : claim,
			claimValue : value
		});
	},

	/**
	 * kick guest participant from conversation
	 * @param {string} conversationId
	 * @param {string} participantId
	 */
	kickParticipant (conversationId, participantId) {
		WebAPIUtils.kickParticipant(conversationId, participantId);
	},

	/**
	 * invite guest to participate in conversation
	 * @param {string} conversationId
	 */
	inviteParticipant (conversationId) {
		var inviteCode = (Math.random().toString(16) + '000000000').substr(2,5);
		WebAPIUtils.inviteGuest(conversationId, {
			inviteCode : inviteCode,
			maxUsageCount : 1,
			participation : {
				owner: 'false',
				titleId : 'guest',
				role : 'internalExpert'
			}
		}).then(function(response) {
			CSDispatcher.handleViewAction({
				type: ActionTypes.INVITE_PARTICIPANT_SUCCESS,
				conversationId: conversationId,
				inviteCode: inviteCode
			});
		}, function(error) {
			CSDispatcher.handleViewAction({
				type: ActionTypes.INVITE_PARTICIPANT_ERROR,
				conversationId: conversationId
			});
		});
	},

	/**
	 * dismiss guest invite in conversation
	 * @param {String} conversationId
	 */
	dismissInviteParticipant (conversationId) {
		CSDispatcher.handleViewAction({
			type: ActionTypes.INVITE_PARTICIPANT_DISMISSED,
			conversationId: conversationId
		});
	},

	/**
	 * Release visitor to group
	 * @param {string} conversationId
	 * @param {string} groupId
	 */
	releaseToGroup (conversationId, groupId) {
		var visitor = psPlugin.application.visitorHandler.visitors[conversationId];
		visitor.commands.saveCase.execute(visitor.coWorker.psCase.activeCase);
		visitor.commands.Transfer.execute(groupId);
		//CSDispatcher.handleViewAction({
		//	type: ActionTypes.TRANSFER_TO_GROUP,
		//	conversationId: conversationId,
		//	groupId: groupId
		//});
	},

	/**
	 * Start conversation
	 * @param {string} conversationId
	 */
	conversationStart (conversationId) {
		WebAPIUtils.conversationStart(conversationId);
		//.then(function(){
		//	console.log('started!');
		//	//psPlugin.application.visitorHandler.visitors[conversationId].updateData(data);
		//});
	},
	/**
	 * Close conversation
	 * @param {string} conversationId
	 */
	conversationClose (conversationId) {
		WebAPIUtils.conversationClose(conversationId);
		//.then(function(){
		//	console.log('closed!');
		//	//psPlugin.application.visitorHandler.visitors[conversationId].updateData(data);
		//});
	},

	/**
	 * Block participant
	 * @param {string} conversationId
	 */
	blockParticipant (conversationId) {
		var visitor = psPlugin.application.visitorHandler.visitors[conversationId];
		var noteText = psPlugin.application.writeOutLanguage('coWorkerBlockBlocked');
		this.createNoteMessage(noteText, conversationId);
		//visitor.commands.block.execute();
		//psPlugin.application.listener.sendAction('runClientFunction', 'block', {}, conversationId);

		//TODO: finalize Block functionality
		var visitorParticipant = ParticipantStore.getVisitorParticipantOfConversation(conversationId);
		if (visitorParticipant) {
			WebAPIUtils.banParticipant(conversationId,visitorParticipant.id).then(function(resolve, reject){
				setTimeout(function(){
					visitor.commands.EndDialog.execute();
					visitor.coWorker.setState('Completed');
				}, 1000);
			});
		}

	},

	/**
	 * Start a dialog that's has been suspended or in pendingStart
	 * @param {string} conversationId
	 */
	startSuspendedDialog (conversationId) {
		WebAPIUtils.conversationStart(conversationId);
	},

	/**
	 * Closes the case and optionally ends dialogObject
	 * @param {string} conversationId the id
	 * @param {boolean} inDialog Not in use?
	 */
	endDialog (conversationId, inDialog) {
		var visitor;
		try {

			visitor = psPlugin.application.visitorHandler.visitors[conversationId];
			visitor.coWorker.psCase.closeCase(inDialog);

		} catch (err) {

			const conversation = ConversationsStore.find(conversationId);
			if (conversation) {
				const caseEvent = _findLast(conversation.events, function (ev) { return ev.caseId; });
				const caseId = caseEvent && caseEvent.caseId;
				const caseClosure = {
					name: 'Exception',
					outcome: 'Neutral' // Positive | Neutral | Negative
				};

				if (caseId) {
					WebAPIUtils.closeCase(caseId, caseClosure).then(function resolve(data){
						console.log('closeCase', data);
					},function reject(error){
						console.log('closeCase:error', error);
					});
				}

				if (conversation.state !== 'closed') {
					WebAPIUtils.conversationClose(conversationId);
				}
			}
			if (visitor) {
				visitor.coWorker.panel.close();
			}

		}



	},

	setConversationIdleState (conversationId, isIdle) {
		CSDispatcher.handleViewAction({
			type: ActionTypes.CONVERSATION_IDLE,
			conversationId: conversationId,
			idle: isIdle
		});
	},

	askForStartCode () {
		WebAPIUtils.getStartCode().then(code => {
			var NotificationService = angularInjector().get('NotificationService');

			NotificationService.info({
				body: '' + code
			}, {
				messageClass: 'meeting-code',
				timeOut: 10000,
				extendedTimeOut: 10000,
				tapToDismiss: true
			});

		}, err => {
			console.log('.:getStartCode:. error', err);
		});
	},

	sendMetadataExchange(conversationId, data) {
		// Send metadata to visitor
		let message = MessageUtils.getMetadataExchangeMessageData(data);
		// console.log('ViewActions: sendMetadataExchange(), message:',message);
		WebAPIUtils.createMessage(message, conversationId);
	}

};
