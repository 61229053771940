export default {
	draggedNode: null,

	createDraggable: function (elem) {
		elem.style.cursor = 'move';
		elem.draggable = 'true';
		elem.ondragstart = function(e) {
			psPlugin.core.dragDrop.onDragStart(elem, e);
		};
		elem.ondragend = function () {
			return false;
		};
	},

	onDragStart: function (elem, e) {
		e.dataTransfer.setData('Text', elem.id);
		psPlugin.core.dragDrop.draggedNode = elem;
		//console.log(dataTransfer.dropEffect);
	},

	createDropZone: function (elem, callback) {
		//dragableObj.droppedCount[elem.id] = 0;
		//elem.ondrop = this.onDropEvent;
		elem.data = {
			callback: callback
		};
		elem.ondrop = function (e) {
			e.preventDefault();
			//console.log(e);
			var elemId = e.dataTransfer.getData('Text');
			e.stopPropagation();
			//console.log(elemId);
			var elem = document.getElementById(elemId);
			//console.log(elem.data);
			//console.log(this.data);
			if (typeof elem.data.params !== 'undefined' && typeof elem.data.params.dropCopy !== 'undefined') {
				if (elem.data.params.dropCopy) this.appendChild(elem.cloneNode(true));
			} else {
				this.appendChild(elem);
			}

			this.data.callback(elem.data);
		};

		elem.ondragenter = function (e) {
			e.preventDefault();
			return true;
		};

		//elem.ondragenter = function(){return false;};
		elem.ondragover = function () {
			return false;
		};
	},

	//Drag drop - drag and drop panels
	dragDropConfiguration: function (params) {
		// sort to container
		var inTarget = [];
		var inLibrary = [];
		for (var uId in params.data) {
			if (typeof params.dataTarget[uId] != 'undefined') {
				inTarget.push(uId);
			} else {
				inLibrary.push(uId);
			}
		}
		// icons TEMP !!!
		var createIcon = function (uId) {

			var item = params.data[uId];
			var e = document.createElement('div');
			e.id = item.id;
			e.data = item;
			e.style.display = 'inline-block';
			e.style.border = '1px solid #888';
			e.style.backgroundColor = '#fff';
			e.style.padding = '3px';
			e.style.marginBottom = '2px';
			e.style.marginRight = '3px';

			//Are you a banner? You are if you have a template.
			if (item.template) {
				e.innerHTML = psPlugin.core.jsExt.prefixBannerNamesWithTemplate(item);
			} else {
				e.innerHTML = item.name;
			}
			psPlugin.core.dragDrop.createDraggable(e);
			return e;
		};
		// containers
		var targetElem = document.createElement('div');
		targetElem.className = 'dragDropTargetContent';
		targetElem.style.minHeight = '200px';

		var libraryElem = document.createElement('div');
		libraryElem.className = 'dragDropLibraryContent';
		libraryElem.style.minHeight = '200px';

		// loop and add to containers
		inTarget.forEach(function (uId) {
			targetElem.appendChild(createIcon(uId));
		});
		this.createDropZone(targetElem, function (uId) {
			params.dataTarget[uId.id] = true;
			params.callback(uId.id, 'add');
			//psPlugin.user.save(uId);
		});

		inLibrary.forEach(function (uId) {
			libraryElem.appendChild(createIcon(uId));
		});
		this.createDropZone(libraryElem, function (uId) {
			if (typeof params.dataTarget[uId.id] != 'undefined') delete params.dataTarget[uId.id];
			params.callback(uId.id, 'remove');
			//psPlugin.user.save(uId);
		});

		params.targetPanel.setContent(targetElem);


		// library
		params.targetPanel.children.Library.setContent(libraryElem);
	}
}
