import React, {useState, useEffect, useRef} from 'react';
import {Form, Row, Col, ControlLabel, Button, ButtonToolbar} from 'react-bootstrap';
import Format from '../../../utils/Format';
import {useGetTranslation} from './../../LangContext';

const CaseForm = (props) => {
	const getTranslation = useGetTranslation();
	const [indicateSent, setIndicateSent] = useState(false);
	const pendingRemovalTimer = useRef();

	const sendNote = () => {
		props.sendNote(props.conversationId, '*' + getTranslation('sysMsgNote') + '* ' + Format.asFormValuesString(props.form));
		setIndicateSent(true);
	};

	useEffect(() => {
		if (indicateSent) {
			pendingRemovalTimer.current = setTimeout(() => {
				setIndicateSent(false);
			}, 2000);
		}
		return () => {
			clearInterval(pendingRemovalTimer.current);
		};
	}, [indicateSent]);

	const form = props.form;
	const submitBtn = <Button disabled={!form.isValid} variant="default"
							  onClick={sendNote}>{getTranslation('cmSubmitBtn')}</Button>;
	const isSentBtn = <Button className="indicate-sent" variant="success"><i
		className="vngage-icon-ok"/> {getTranslation('cmSubmitSuccessBtn')}</Button>;

	const formFields = form.formFields.map(field => {
		const validationState = field.required ? (field.isValid ? 'success': 'error'): 'success';
		return (
			<Form.Group as={Row} key={field.name}>
				<Form.Label sm={4} column={true}>
					{field.required ? (field.label + '*'): field.label}
				</Form.Label>
				<Col sm={8}>
					<Form.Control
						type={field.type}
						required={field.required ? 'required': ''}
						name={field.name}
						value={field.value}
						onChange={(event) => props.onFormChange(event.target.value, field, form.id)}/>
				</Col>
			</Form.Group>
		);
	});

	return (
		<div key={form.id}>
			<div className="area-subhead">
				<h5 className="no-wrap">{getTranslation('cmFormTitle')}: {form.name}</h5>
				<div className="inline-divider"/>
			</div>
			<span className="form-description">{form.description}</span>
			<Form className="form-horizontal">
				{formFields}
				<ButtonToolbar>
					<Button onClick={props.hideModal} variant="link">
						{getTranslation('cmReturnBtn')}
					</Button>
					{indicateSent ? isSentBtn: submitBtn}
				</ButtonToolbar>
			</Form>
		</div>
	);

};
export default CaseForm;

