import React from 'react';
import createReactClass from 'create-react-class';
import ViewActions from  '../actions/ViewActions';
import { injectIntl } from 'react-intl';

export default injectIntl(createReactClass({

	getDefaultProps () {
		return {
			status : 'active', //paused, passive, progress
			show: true,
			visitorIsAt: '',
			askingForDOMUpload: false,
			domUploadStatus: 'Done'
		};
	},

	render: function() {
		let cls = 'vg-status vg-status-' + this.props.status;
		let url = this.props.visitorIsAt;
		let message = 'vgVisitorIsAt';

		if (this.props.askingForDOMUpload) {
			cls = 'vg-status vg-status-progress';
			message = 'vgAskingDOMUpload' + this.props.domUploadStatus;
			url = '';
		}

		if (this.props.show) {
			cls = cls + ' show';
		}

		return (
			<div className={cls}>
				<div className="vg-status-icon">
					<span className="icon-bg"></span>
				</div>
				<div className="vg-status-msg">{this.props.intl.formatMessage({id:message})} {url}</div>
			</div>
		);
	}
}));
