export default {
	CHAT: 'chat',
	NOTE: 'note',
	ACTION: 'action',
	NAVIGATION: 'navigation',
	VIDEO: 'video',
	DOM_HIGHLIGHT: 'domHighlight',
	ASK_FOR_DOM_UPLOAD: 'askForDomUpload',
	METADATA_EXCHANGE: 'metadataExchange',
	CONTENT: 'content',
	TYPING_STATUS: 'typingStatus',

	actionItemTypes: {
		LINK: 'link',
		JS: 'js',
		VIDEO_LINK: 'videoLink',
		PDF: 'pdf',
		PHOTO: 'photo',
		FORM: 'form',
		RENDER: 'render'
	}
};
