export default function (panelSettings, panelSettingsConfigurationSectionsValidation, $state, $scope) {
	'ngInject';

	var vm = this;
	vm.panelSettings = panelSettings;
	vm.panelSettingsConfigurationSectionsValidation = panelSettingsConfigurationSectionsValidation;
	vm.save = save;
	vm.cancel = cancel;

	function save() {
		vm.panelSettings.$save();
	}

	function cancel() {
		$state.go('^');
	}

	$scope.$on('resetResettables', () => {
		vm.panelSettingsForm.$setPristine();
	});
};
