import React from 'react';
import WebCallView from '../../video/WebCallView.react';

const WebCallSection = () => {
	return (
		<div className="web-call-section">
			<WebCallView/>
		</div>
	)
};
export default WebCallSection;

