import React from 'react';
import mmd from '../../../../../common/vngageMarkdown/mmd';
import Timestamp from './Timestamp.react';

const MessagePdf = (props) => {
	const rawMarkup = mmd(props.title);
	const linkType = props.linkType;
	return (
		<div className="message lineClampEllipsis">
			<strong className="role">{props.speaker}: </strong>
			<i className={linkType}>
				<a href={props.txt} target="_blank"><span dangerouslySetInnerHTML={{__html: rawMarkup}}/></a>
			</i>
			<Timestamp
				timestamp={props.timestamp}
				isMostRecentMessage={props.isMostRecentMessage}
				displayRelativeDialogTime={props.displayRelativeDialogTime}
			/>
		</div>
	)
};
export default MessagePdf

