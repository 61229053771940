import React from 'react';
import ViewActions from './../actions/ViewActions';
import LocalSync from './../../../components/utils/LocalSync';
import { injectIntl } from 'react-intl';
import createReactClass  from 'create-react-class';

const ENTER_KEY_CODE = 13;
const MAX_LENGTH = 1000;

// preview
//<div
//	className="content"
//	dangerouslySetInnerHTML={{
//		__html: converter.makeHtml(this.state.text)
//	}}/>

export default injectIntl(createReactClass({

	getInitialState () {
		return {
			text: LocalSync.getConversationInput(this.props.id),
			isPreviewing: false,
			isTyping: false
		};
	},

	componentWillUnmount () {
		LocalSync.clearConversationInput(this.props.id);
	},

	render () {
		return (
			<div className='dialog-compose'>
				<textarea
					className="message-composer form-control"
					rows="3"
					name="message"
					placeholder={this.props.intl.formatMessage({id: 'messagePlaceholder'})}
					value={this.state.text}
					onChange={this._onChange}
					onKeyDown={this._onKeyDown}
					maxLength={MAX_LENGTH}
				/>
			</div>
		);
	},

	_onChange (event) {
		const val = event.target.value;
		this.setState({text: val});
		LocalSync.setConversationInput(this.props.id, val);

		if (val.length > 0) {
			//if (val.length >= this.state.maxLength) {
			//	console.error('exceeded maxLength of ' + this.state.maxLength);
			//}
			if (!this.state.isTyping){
				this.setState({isTyping: true});
				ViewActions.participantWriting(this.props.id, true);
			}
		} else {
			if (this.state.isTyping){
				this.setState({isTyping: false});
				ViewActions.participantWriting(this.props.id, false);
			}
		}
	},

	_onKeyDown (event) {
		if (event.keyCode === ENTER_KEY_CODE) {
			if ( !event.shiftKey ) {
				event.preventDefault();
				let text = this.state.text || '';

				//trim and cap the text at maxLength to prevent server issues
				text = text.trim().slice(0, MAX_LENGTH);

				if (text) {
					ViewActions.participantWriting(this.props.id, false);
					ViewActions.createMessage(text, this.props.id, this.props.profile);
					LocalSync.clearConversationInput(this.props.id);
				}
				this.setState({
					text: '',
					isTyping: false,
					isPreviewing: false
				});
			}
		}
	}

}));
