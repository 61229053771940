import AppConstants from '../../../components/constants/AppConstants';
const ActionTypes = AppConstants.ActionTypes;
const Ui = AppConstants.Ui;

export default {

	receiveVisitors (visitors) {
		return {
			type: ActionTypes.RECEIVE_VISITORS,
			visitors
		};
	},

	setQueuePanelPosition () {
		return {
			type: Ui.QUEUE_PANEL_PINNED
		};
	},

	toggleUserPreferences () {
		return {
			type: Ui.TOGGLE_USER_PREFERENCES
		};
	},

	toggleGroupStatus () {
		return {
			type: Ui.TOGGLE_GROUP_STATUS
		};
	},

	setUserPreferences (userPreferences) {
		return {
			type: Ui.SET_USER_PREFERENCES,
			userPreferences
		};
	},

	setStartCode (code) {
		return {
			type: ActionTypes.SET_START_CODE,
			code
		};
	},

	toggleStartCodeView () {
		return {
			type: Ui.TOGGLE_START_CODE_VIEW
		};
	},

	addSelectAnimation (conversationId, startY) {
		return {
			type: Ui.ADD_SELECT_ANIMATION,
			conversationId,
			startY
		};
	},

	removeSelectAnimation (conversationId) {
		return {
			type: Ui.REMOVE_SELECT_ANIMATION,
			conversationId,
		};
	}

}
