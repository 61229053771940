export default function ($timeout, $parse, $window) {
	'ngInject';
	return {
		restrict: 'A',
		link: function (scope, element) {
			var timer;

			$timeout(function () {
				resize();

				// Enable transition
				$timeout(function () {
					element.addClass('active');
				}, 100);
			});

			scope.$watch('vm.preview.size', resize, true);

			angular.element($window).bind('resize', function () {
				$timeout.cancel(timer);
				timer = $timeout(resize, 200);
			});

			function resize() {
				var height, width, parentWidth, scale;
				var dynamicSizeBanner = !!($parse('vm.preview.dynamicSizeBanner')(scope));
				var setMin = false;

				if ($parse('vm.preview.size.mobile')(scope)) {
					height = 480;
					width = 320;
				} else if ($parse('vm.preview.size.tablet')(scope)) {
					height = 500;
					width = 550;
				} else if ($parse('vm.preview.size.medium')(scope)) {
					height = 550;
					width = 800;
					setMin = true;
				} else {
					height = 750;
					width = 1000;
					setMin = true;
				}

				parentWidth = element.parent()[0].clientWidth;
				scale = parentWidth / width;

				element.css('width', width + 'px');
				element.css('min-width', setMin ? '100%' : '0');

				if ($parse('vm.preview.size.scale')(scope) && scale < 1) {
					if (!dynamicSizeBanner) {
						element.css('height', (height * (1 / scale)) + 'px');
					}
					element.css('transform', 'scale(' + scale + ')');
				} else {
					if (!dynamicSizeBanner) {
						element.css('height', height + 'px');
					}
					element.css('transform', '');
				}

				if (dynamicSizeBanner) {
					// If the currently previewed banner has dynamicSizeBanner === true, dispatch a custom 'resize'-event to all children of this element
					for (let child = element[0].firstChild; child !== null; child = child.nextSibling) {
						child.dispatchEvent(new CustomEvent('resized'));	// Use CustomEvent instead of Event (CustomEvent is polyfilled to work with IE 11 also)
					}
				}
			}
		}
	};
}
