import PublishConfigurationModalTemplate from './PublishConfigurationModalTemplate';
import moment from 'moment-timezone';

export default function (SessionService, APIEndpointService, $q, ConfigurationSetService, context, activateContext, $uibModal, usSpinnerService, userList, vngageUserFilter) {
	'ngInject';

	var vm = this;
	vm.context = context;
	vm.selectedRevision = activateContext.newestRevision;
	vm.selectedRevisionComment = vm.selectedRevision.data.comments;
	vm.selectedRevisionUpdatedAt = vm.selectedRevision.updatedAt;
	vm.liveRevisionSet = activateContext.liveRevisionSet;
	vm.liveRevisionComment = activateContext.liveRevisionSet.data.comments;
	vm.liveRevisionUpdatedAt = activateContext.liveRevisionSet.updatedAt;
	vm.selectedRevisionNumber = vm.selectedRevision.revision;
	vm.newestRevision = activateContext.newestRevision;
	vm.revisionArr = Array.apply(null, new Array(vm.newestRevision.revision + 1).map(Number.prototype.valueOf, 0)).map((n, i) => i).reverse();
	vm.revisionComment = activateContext.revisionSetResolved.comments;
	vm.revisionUpdatedAt = activateContext.revisionSet.updatedAt;
	vm.analyzedSet = null;
	vm.onlyShowUpdated = true;
	vm.updatedItemFilter = updatedItemFilter;
	vm.openPublishModal = openPublishModal;
	vm.compare = compare;
	vm.byUserNewest = vngageUserFilter(activateContext.revisionSet.userId, userList);
	vm.byUserSelected = vngageUserFilter(vm.selectedRevision.userId, userList);
	vm.byUserLive = vngageUserFilter(activateContext.liveRevisionSet.userId, userList);
	vm.formatWithTZ = formatWithTZ;

	activate();

	function activate() {

		var result = ConfigurationSetService.combine(activateContext.revisionSet, activateContext.revisionSetResolved);

		vm.analyzedSet = ConfigurationSetService.analyzeSet(activateContext.liveRevisionSet, activateContext.liveRevisionSetResolved, result);
	}

	function formatWithTZ(date) {
		return moment.utc(date).tz(SessionService.getUserTimezone()).format('dddd MMM D HH:mm - YYYY');
	}

	function compare() {

		var id = vm.liveRevisionSet.data.id;

		usSpinnerService.spin('spinner-revision');

		$q.all([
			APIEndpointService.configurationSet.get({
				id: id,
				revision: vm.selectedRevisionNumber
			}).$promise,
			APIEndpointService.configurationSetResolved.get({
				id: id,
				revision: vm.selectedRevisionNumber
			}).$promise
		]).then(function (res) {

			var result = ConfigurationSetService.combine(res[0], res[1]);

			vm.selectedRevision = res[0];
			vm.analyzedSet = ConfigurationSetService.revertSet(activateContext.liveRevisionSet, activateContext.liveRevisionSetResolved, result);
			vm.selectedRevisionComment = vm.selectedRevision.data.comments;
			vm.selectedRevisionUpdatedAt = vm.selectedRevision.updatedAt;
			vm.byUserSelected = vngageUserFilter(vm.selectedRevision.userId, userList);

			usSpinnerService.stop('spinner-revision');
		});
	}

	function openPublishModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: PublishConfigurationModalTemplate,
			controller: 'PublishConfigurationModalController as vm',
			resolve: {
				configurationSet: () => {
					return vm.context.dependencies.configurationSet;
				},
				selectedRevision: () => {
					return vm.selectedRevision;
				},
				analyzedSet: () => {
					return vm.analyzedSet;
				}
			}
		});
		modal.result.then(configurationSet => {
			vm.liveRevisionSet = context.liveRevisionSet = configurationSet;
			vm.liveRevisionComment = context.liveRevisionSet.data.comments = configurationSet.data.comments;
			activate();
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function updatedItemFilter(item) {
		if (vm.onlyShowUpdated) {
			return ConfigurationSetService.updatedItem(item);
		} else {
			return true;
		}
	}
};
