import React from 'react';
import {connect} from 'react-redux';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import PluginActions from '../../actions/PluginActions';

export const PluginButton = (props) => {
	const {
			  plugin,
			  buttonState,
			  pluginButtonClicked,
			  conversationId,
		  } = props;

	const onClick = () => {
		const buttonClickable = (['active', 'enabled'].indexOf(buttonState) !== -1);
		if (buttonClickable) {
			pluginButtonClicked(conversationId, plugin.get('id'));
		}
	};

	const cls = 'plugin-toolbar-btn toolbar-btn plugin-button-' + buttonState;
	const style = {
		width: '30px',
		height: '30px',
		backgroundImage: 'url("' + plugin.get('sectionButtonIconUrl') + '")',
		backgroundSize: '20px 20px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center'
	};
	const tooltip = (
		<Tooltip id="toolbar-tooltip">
			{plugin.get('sectionButtonTitle')}
		</Tooltip>);

	return (
		<OverlayTrigger placement="bottom" overlay={tooltip} delay={400}>
			<div className="toolbar-btn-container" onClick={onClick}>
				<Button size="sm" tabIndex="-1" className={cls} style={style}/>
			</div>
		</OverlayTrigger>
	);
};

function mapStateToProps(state, ownProps) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		pluginButtonClicked: (conversationId, pluginId) => dispatch(PluginActions.pluginButtonClicked(conversationId, pluginId))
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PluginButton);
