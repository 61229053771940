import moment from 'moment-timezone';

export default function ($controller, $scope, APIStatisticsService, APIBatchRequestService, dc, SessionService, AuthorizationService, TimeRangeService, APIRealtimeService) {
	'ngInject';

        var dashboard = this;
        dashboard.statisticsOverall = null;
        dashboard.formatMetric = formatMetric;
        dashboard.overallAuthorization = {action: 'GET', resource: 'Statistics/Overall'};
        dashboard.realtimeData = APIRealtimeService.dataWrap;

        activate();

        function activate() {

            // This is from preventing dc from saving too many charts in memory :/
            dc.chartRegistry.clear();

            if (!AuthorizationService.authorize(dashboard.overallAuthorization)) {
                return;
            }

            $controller('BaseDashboardController', {query: queryOverall, $scope: $scope});
            APIRealtimeService.start({ conversationCurrent: false, intervalMs: 5000 });
            $scope.$on('$destroy', function() {
                APIRealtimeService.stop();
            });

            dashboard.showAlert = !sessionStorage.getItem('vngage.dismissedAlert');
            dashboard.dismissAlert = function () {
                dashboard.showAlert = false;
                sessionStorage.setItem('vngage.dismissedAlert', Date.now());
            };
        }

        function queryOverall() {

            var today = TimeRangeService.create('today');
            var range = {
                from: today.from(),
                to: today.to()
            };

            return APIStatisticsService.queryOverall(range).then(function (res) {
                var data = res.data;
                dashboard.statisticsOverall = data ? angular.fromJson(data) : {};
                dashboard.statisticsOverall.lastUpdated = moment().tz(SessionService.getUserTimezone()).format('LTS'); //.utcOffset(SessionService.getUserTimezoneOffset()).format('HH:mm:ss');
            });
        }

        function formatMetric(metric) {
            if (metric === undefined || metric === -1) {
                return '--';
            }

            return metric;
        }
    };
