import React from 'react';
import CopyMessage from './CopyMessage.react';
import Timestamp from './Timestamp.react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const MessageVideo = (props) => {

	const tooltipLink = <Tooltip id="tooltip">{props.txt}</Tooltip>;
	const url = props.txt;
	const linkType = props.linkType;
	const isIframe = props.txt.indexOf('<iframe') === 0;

	const videoObj = <span className="title">{props.title}
		<Timestamp
			timestamp={props.timestamp}
			isMostRecentMessage={props.isMostRecentMessage}
			displayRelativeDialogTime={props.displayRelativeDialogTime}
		/>
			<span dangerouslySetInnerHTML={{__html: url}}/>
			</span>;
	const videoLink = <span>
			<a href={url} target="_blank">{props.title}</a>
			<Timestamp
				timestamp={props.timestamp}
				isMostRecentMessage={props.isMostRecentMessage}
				displayRelativeDialogTime={props.displayRelativeDialogTime}
			/>
			</span>;

	return (
		<div>
			<OverlayTrigger placement="top" overlay={tooltipLink}>
				<div className="message lineClampEllipsis">
					<i className={linkType}/>
					{isIframe ? videoObj: videoLink}
				</div>
			</OverlayTrigger>
			<CopyMessage txt={props.txt}/>
		</div>
	)
};
export default MessageVideo

