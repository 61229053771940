import React from 'react';
import {connect} from 'react-redux';
import VideoError from '../../constants/VideoError';
import VideoLib from '../../reducers/libs/videoLib';
import Video from './../../actionCreators/Video';
import SmallButton from './../common/SmallButton.react';
import {useGetTranslation} from '../LangContext';

const ErrorView = (props) => {
	const getTranslation = useGetTranslation();

	const {
			  error,
			  init,
			  exit,
		  } = props;
	const retryText = getTranslation('videoButtonRetry');
	const exitText = getTranslation('btnExit');
	let title = '';

	switch (error) {

		case VideoError.INIT_MEDIA_FAILED:
			title = getTranslation('videoMediaSourceError');
			return <div className="video-error">
				{title}
				<SmallButton
					title={retryText}
					callback={() => {
						init();
					}}/>
			</div>;

		case VideoError.CONNECT_FAILED:
			title = getTranslation('videoConnectFailed');
			return <div className="video-error">
				{title}
				<SmallButton
					title={retryText}
					callback={() => {
						init();
					}}/>
			</div>;

		case VideoError.JOIN_ROOM_FAILED:
			title = getTranslation('videoJoinRoomFailed');
			return <div className="video-error">
				{title}
				<SmallButton
					title={retryText}
					callback={() => {
						init();
					}}/>
			</div>;

		case VideoError.STREAM_ERROR:
			title = getTranslation('videoStreamError');
			return <div className="video-error">
				{title}
				<SmallButton
					title={retryText}
					callback={() => {
						init();
					}}/>
			</div>;

		case VideoError.VISITOR_DISCONNECTED:
			title = getTranslation('videoVisitorDisconnected');
			return (
				<div className="video-error">
					{title}
					<span>
						<SmallButton
							title={getTranslation('videoButtonRestart')}
							callback={() => {
								init();
							}}/>
						<SmallButton
							title={exitText}
							callback={() => {
								exit();
							}}/>
					</span>
				</div>);

		default:
			return <div className="video-error"/>;
	}
};

function mapStateToProps(state) {

	return {
		error: VideoLib.getError(state)
	};
}

function mapDispatchToProps(dispatch) {

	return {
		exit: () => dispatch(Video.exit())
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ErrorView);
