import React from 'react';
import { injectIntl } from 'react-intl';
import createReactClass  from 'create-react-class';

var Ended = injectIntl(createReactClass({
	render () {
		return (
			<div>
				<h3>{this.props.intl.formatMessage({id: 'conversationEnded'})}</h3>
				<p>{this.props.intl.formatMessage({id: 'conversationEndedDescription'})}</p>
			</div>
		)
	}
}));

var Left = injectIntl(createReactClass({
	render () {
		return (
			<div>
				<h3>{this.props.intl.formatMessage({id: 'conversationLeft'})}</h3>
				<p>{this.props.intl.formatMessage({id: 'conversationLeftDescription'})}</p>
			</div>
		)
	}
}));

var Blocked = injectIntl(createReactClass({
	render () {
		return (
			<div>
				<h3>{this.props.intl.formatMessage({id: 'conversationBanned'})}</h3>
				<p>{this.props.intl.formatMessage({id: 'conversationBannedDescription'})}</p>
				<small>{this.props.intl.formatMessage({id: 'conversationBannedStatusTitle'})}</small>
				<p className="status-message-error">{this.props.intl.formatMessage({id: 'conversationBannedStatusMessage'})}</p>
			</div>
		)
	}
}));

var Empty = injectIntl(createReactClass({
	render () {
		return (
			<div>
				<h3>{this.props.intl.formatMessage({id: 'conversationEmpty'})}</h3>
				<p>{this.props.intl.formatMessage({id: 'conversationEmptyDescription'})}</p>
				<small>{this.props.intl.formatMessage({id: 'conversationEmptyStatusTitle'})}</small>
				<p className="status-message-error">{this.props.intl.formatMessage({id: 'conversationEmptyStatusMessage'})}</p>
			</div>
		)
	}
}));

export default createReactClass({

	render () {
		let view;
		switch (this.props.authError.errorCode) {
			case 'ended' :
				view = <Ended/>;
				break;
			case 'left' :
				view = <Left/>;
				break;
			case 'blocked' :
				view = <Blocked subErrorCode={this.props.authError.subErrorCode}/>;
				break;
			case 'empty' :
				view = <Empty subErrorCode={this.props.authError.subErrorCode}/>;
				break;
		}

		return (
			<div className="lobby-container">
				<div className="left-area">
					<i className="vngage-icon-group"></i>
				</div>
				<div className="content-area">{view}</div>
			</div>
		)
	}
});
