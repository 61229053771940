let availableIds = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
let usedIds = [];

export default {
	getNew(){
		const conversationId = availableIds.shift();
		usedIds.push(conversationId);
		return conversationId;
	},
	peek(nr){
		return availableIds[nr];
	},
	reset(){
		availableIds = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
		usedIds = [];
	},
	remove(conversationId){
		availableIds.unshift(conversationId);
		usedIds.splice(usedIds.indexOf(conversationId),1);
	}

}
