import MonitorUserLogoutModalTemplate from './usersOnline/MonitorUserLogoutModalTemplate.html'
export default function ($scope, $uibModal, $state, TimeRangeService, APIRealtimeService, APIStatisticsService, monitorService, $interval, UserProfilePictureCacheService, groupList, userList, monitorConversationsService) {
	'ngInject';

	var vm = this,
		realtimeRefreshRate = 5000,
		slowRefreshRate = 10000,
		crazySlowRefreshRate = APIStatisticsService.defaultPollingTimeout;

	vm.groupOverview = monitorService.groupOverview;
	vm.statisticsOverall = null;
	vm.formatMetric = formatMetric;
	vm.realtimeData = APIRealtimeService.dataWrap;
	vm.userList = userList;
	vm.groupList = groupList;
	vm.toggleRealtimeUpdate = toggleRealtimeUpdate;
	vm.realtimeOn = true;

	vm.openLogoutModal = openLogoutModal;

	function queryOverall() {

		var today = TimeRangeService.create('today');
		var range = {
			from: today.from(),
			to: today.to()
		};

		return APIStatisticsService.queryOverall(range).then(function (res) {
			var data = res.data;
			vm.statisticsOverall = data ? angular.fromJson(data) : {};
		});
	}
	activate();

	function activate() {
		var queryGroupInterval = $interval(monitorService.queryAndMapGroupOverview, slowRefreshRate),
			queryOverallInterval = $interval(queryOverall, crazySlowRefreshRate);

		//prepare for fetching user profile pictures, and prepopulate with existing userList
		UserProfilePictureCacheService.activate(userList);

		startRealtime();

		queryOverall();
		monitorService.queryAndMapGroupOverview();

		// Watch realtime data
		$scope.$watch(function() { return vm.realtimeData; }, function(newData) {
			monitorService.setRealTimeData(newData);
			monitorConversationsService.setRealtimeData(newData);
		}, true);

		$scope.$on('$destroy', function() {
			APIRealtimeService.stop();
			$interval.cancel(queryOverallInterval);
			$interval.cancel(queryGroupInterval);
		});
	}

	function startRealtime() {
		APIRealtimeService.start({ intervalMs: realtimeRefreshRate });
	}

	function toggleRealtimeUpdate() {
		if (!vm.realtimeOn) {
			startRealtime();
			vm.realtimeOn = true;
		} else {
			APIRealtimeService.stop();
			vm.realtimeOn = false;
		}
	}

	function formatMetric(metric) {
		if (metric === undefined || metric === -1) {
			return '--';
		}

		return metric;
	}

	function openLogoutModal(user) {
		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: MonitorUserLogoutModalTemplate,
			controller: 'MonitorUserLogoutModalController as vm',
			resolve: {
				user: () => user,
			}
		});
		modal.result.then(data => {
			console.log('agent logged out',data);
		}).catch(error => {
			if (error) {
				$state.reload();
			}
		});
	}

	vm.monitorConversationsService = monitorConversationsService;
}
