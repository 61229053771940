import React from 'react';
import {Button} from 'react-bootstrap';
import {useGetTranslation} from './../../LangContext';

const NewMessageIndicator = ({indicatorClick}) => {
	const getTranslation = useGetTranslation();
	return (
		<div className="new-messages-indicator">
			<h2 className="line">
			<span>
				<Button className="btn" variant="primary" size="xs" onClick={indicatorClick}>
				<i className="vngage-icon-down-dir"/>
					{getTranslation('newMsgIndicator')}
					<i className="vngage-icon-down-dir"/>
				</Button>
				</span>
			</h2>
		</div>

	)
}

export default NewMessageIndicator;
