import React from 'react';
import { injectIntl } from 'react-intl';
import ReservationInfoFormFieldItem from './ReservationInfoFormFieldItem.react';
import EventStore from '../stores/EventStore';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';

import createReactClass  from 'create-react-class';

function getGroupName(groupList, groupId) {
	let group = _find(groupList, g => {
		return g.id === groupId
	});
	return group ? group.name : null;
}

export default injectIntl(createReactClass({

	analyzeCustomFormData(reservation) {
		// Convert to array, sorted on 'position'...
		reservation.metadata.customForms = _sortBy(reservation.metadata.customForms, ['position']);

		// Remove customForm elements that are 'hiddenForAgent'
		reservation.metadata.customForms = reservation.metadata.customForms.filter(item => !item.hiddenForAgent);

		// If customForms is incomplete, fill it with values from reservation.name, reservation.lastName, reservation.phone, reservation.email
		if (reservation.name && !_find(reservation.metadata.customForms, { 'name': 'firstName' }) && !_find(reservation.metadata.customForms, { 'name': 'name' })) {
			// For backwards compatibility: Add firstName from reservation base object if missing in metadata.customForms
			reservation.metadata.customForms.push({
				'name': 'firstName',
				'label': this.props.intl.formatMessage({id: 'reservationFirstName'}),
				'type': 'text',
				'validateAs': 'letters',
				'value': reservation.name
			});
		}
		if (reservation.lastName && !_find(reservation.metadata.customForms, { 'name': 'lastName' })) {
			// For backwards compatibility: Add lastName from reservation base object if missing in metadata.customForms
			reservation.metadata.customForms.push({
				'name': 'lastName',
				'label': this.props.intl.formatMessage({id: 'reservationLastName'}),
				'type': 'text',
				'validateAs': 'letters',
				'value': reservation.lastName
			});
		}
		if (reservation.email && !_find(reservation.metadata.customForms, { 'name': 'email' })) {
			// For backwards compatibility: Add email from reservation base object if missing in metadata.customForms
			reservation.metadata.customForms.push({
				'name': 'email',
				'label': this.props.intl.formatMessage({id: 'reservationEmail'}),
				'type': 'text',
				'validateAs': 'email',
				'value': reservation.email
			});
		}
		if (reservation.phone && !_find(reservation.metadata.customForms, { 'name': 'phone' })) {
			// For backwards compatibility: Add phone from reservation base object if missing in metadata.customForms
			reservation.metadata.customForms.push({
				'name': 'phone',
				'label': this.props.intl.formatMessage({id: 'reservationPhone'}),
				'type': 'text',
				'validateAs': 'phone',
				'value': reservation.phone
			});
		}

		// Also add group name and start time first
		reservation.metadata.customForms.unshift({
			'name': '__groupName',
			'label': this.props.intl.formatMessage({id: 'reservationGroup'}),
			'type': 'text',
			'validateAs': 'text',
			'value': getGroupName(this.props.groupList, reservation.groupId)
		});
		reservation.metadata.customForms.unshift({
			'name': '__meetingStartTime',
			'label': this.props.intl.formatMessage({id: 'reservationStartTime'}),
			'type': 'time',
			'validateAs': 'time',
			'value': reservation.start
		});

		reservation.metadata.customForms_analyzed = true;
	},

	render () {
		let reservation = this.props.reservation;
		if (!reservation) {
			return null;
		}
		if (!reservation.metadata.customForms) {
			reservation.metadata.customForms = [];
		}
		if (!reservation.metadata.customForms_analyzed) {
			this.analyzeCustomFormData(reservation)
		}

		return (
			<div className="flex-container">
				<ul className="reservation-info" style={{
					listStyleType: 'none',
					width: '100%',
					padding: '2px',
					margin: '8px',
					border: '1px solid black',
					background: '#FFFFDD',
					borderRadius: '5px'
				}}>
					<li>
						<div className="reservation-info-header" style={{
							fontWeight: 'bold',
							fontSize: '12px',
							color: '#AA0000'
						}}>
							{this.props.intl.formatMessage({id: 'reservationInfoHeader'})}
						</div>
					</li>
					{reservation.metadata.customForms.map(formFieldItem => {
						return (
							<ReservationInfoFormFieldItem key={formFieldItem.name} formFieldItem={formFieldItem} />
						)
					})}
				</ul>
			</div>
		);
	}
}));
