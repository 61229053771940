'use strict';

function replaceAll(txt, find, replace) {
	let i, j;

	if (typeof find === 'string') {
		return txt.split(find).join(replace);
	}

	while (typeof (i = find.shift()) === 'string' && typeof (j = replace.shift()) === 'string') {
		txt = replaceAll(txt, i || '', j || '');
	}

	return txt;
}

function html(src) {
	// return replaceAll(src.toString(), ['&#39;', '&', '<', '"', '>'], ['\'', '&amp;', '&lt;', '&quot;', '&gt;']);
	return replaceAll(src.toString(), ['&', '\'', '<', '"', '>'], ['&amp;', '&#39;', '&lt;', '&quot;', '&gt;']); // Changed Björn's weird "reverse encoding" of apostrophes above.  That can't be right, right?
}

function targetBlank(str) {
	return str.replace(/<a /i, '<a target="_blank" ');
}

const inlineManager = {
	saved: [],
	init: function () {
		this.saved = [];
	},

	save: function (value) {
		this.saved.push(value);
		return '${' + (this.saved.length - 1) + '}';
	},

	retrieve: function (match, index) {
		return this.saved[index] ? this.saved[index]: '';
	},
	image: function (match, p1, p2) {
		return this.save('<img alt="' + p1 + '" src="' + p2 + '"/>');
	},

	linkP1P2: function (match, p1, p2) {
		return this.save(targetBlank(p1.link(p2)));
	},

	linkP2P1: function (match, p1, p2) {
		return this.save(targetBlank(p2.link(p1)));
	},

	linkP1P3P2: function (match, p1, p2, p3) {
		return this.save(p1 + targetBlank(p3.link(p2)));
	},

	bold: function (match, p1, p2) {
		return this.save('<strong>' + p2 + '</strong>');
	},

	code: function (match, p1, p2) {
		return this.save('<code>' + p2 + '</code>');
	},

	emphasis: function (match, p1) {
		return this.save('<em>' + p1 + '</em>');
	},

	strikeThrough: function (match, p1) {
		return this.save('<s>' + p1 + '</s>');
	},

	superScript: function (match, p1, p2) {
		return this.save('<sup>' + p2 + '</sup>');
	},

	subScript: function (match, p1, p2) {
		return this.save('<sub>' + p2 + '</sub>');
	},

	bracket: function (match, p1) {
		return this.save(match);
	},

};


function inlineEscape(s) {

	inlineManager.init();


	let firstPass = html(s)
	// handle preexisting ${x}
		.replace(/\${(\d+)}/g, inlineManager.bracket.bind(inlineManager))

		// images
		.replace(/!\[([^\]]+)]\(([^\s("&]+\.[^\s("&]+)\)/g, inlineManager.image.bind(inlineManager))//'<img alt="$1" src="$2" />')
		// .replace(/!\[([^\]]+)]\(([^\s("&]+\.[^\s("&]+)\)/g, '<img alt="$1" src="$2" />')

		// links
		.replace(/\[([^\]]+)]\(([^\s"&]+\.[^\s"]+)\)/g, inlineManager.linkP1P2.bind(inlineManager))//targetBlank('$1'.link('$2'))
		// .replace(/\[([^\]]+)]\(([^\s"&]+\.[^\s"]+)\)/g, targetBlank('$1'.link('$2')))

		.replace(/^(https?:\/\/([^\s"&]+\.[^\s"]+)[^\s]*)/g, inlineManager.linkP2P1.bind(inlineManager))//targetBlank('$2'.link('$1'))
		// .replace(/^(https?:\/\/([^\s"&]+\.[^\s"]+)[^\s]*)/g, targetBlank('$2'.link('$1')))

		.replace(/([^;["])(https?:\/\/([^\s"&]+\.[^\s".:]+))/g, inlineManager.linkP1P3P2.bind(inlineManager))//targetBlank('$3'.link('$2'))
		// .replace(/([^;["])(https?:\/\/([^\s"&]+\.[^\s".:]+))/g, '$1' + targetBlank('$3'.link('$2')))

		// *bold*
		.replace(/\B(\*{1})([\s\S]+?)\s*\1(?!\*)\B/g, inlineManager.bold.bind(inlineManager))//'<strong>$2</strong>')
		// .replace(/\B(\*{1})([\s\S]+?)\s*\1(?!\*)\B/g, '<strong>$2</strong>')

		// `code`
		.replace(/\B(\`{1})([\s\S]+?)\s*\1(?!\`)\B/g, inlineManager.code.bind(inlineManager))//'<code>$2</code>')
		// .replace(/\B(\`{1})([\s\S]+?)\s*\1(?!\`)\B/g, '<code>$2</code>')

		// __emphasis__
		.replace(/\b_{1,2}((?:__|[\s\S])+?)_{1,2}\b/g, inlineManager.emphasis.bind(inlineManager))//'<em>$1</em>')
		// .replace(/\b_{1,2}((?:__|[\s\S])+?)_{1,2}\b/g, '<em>$1</em>')

		// ---strike-through---
		.replace(/\B-{3}(.*?)-{3}\B/g, inlineManager.strikeThrough.bind(inlineManager))//'<s>$1</s>')
		// .replace(/\B-{3}(.*?)-{3}\B/g, '<s>$1</s>')

		// ^superscript^
		.replace(/\B(\^{1})([\s\S]+?)\s*\1(?!\^)\B/g, inlineManager.superScript.bind(inlineManager))//'<sup>$2</sup>')
		// .replace(/\B(\^{1})([\s\S]+?)\s*\1(?!\^)\B/g, '<sup>$2</sup>')

		// ^subscript^
		.replace(/\B(~{1})([\s\S]+?)\s*\1(?!~)\B/g, inlineManager.subScript.bind(inlineManager));//'<sub>$2</sub>')
	// .replace(/\B(~{1})([\s\S]+?)\s*\1(?!~)\B/g, '<sub>$2</sub>')

	let finalStr = firstPass.replace(/\${(\d+)}/g, inlineManager.retrieve.bind(inlineManager));

	// console.log('*******');
	// console.log('firstPass', firstPass);
	// console.log('saved', inlineManager.saved);
	// console.log('finalStr', finalStr);
	return finalStr;
}

const tagData = {
	ul: {type: 'list', tags: ['<ul>', '</ul>']},
	ol: {type: 'list', tags: ['<ol>', '</ol>']},
	code: {type: 'multi', tags: ['<pre><code>', '</code></pre>']},
	quote: {type: 'multi', tags: ['<blockquote>', '</blockquote>']},
	h1: {type: 'single', tags: ['<h1>', '</h1>']},
	h2: {type: 'single', tags: ['<h2>', '</h2>']},
	h3: {type: 'single', tags: ['<h3>', '</h3>']},
	h4: {type: 'single', tags: ['<h4>', '</h4>']},
	h5: {type: 'single', tags: ['<h5>', '</h5>']},
	h6: {type: 'single', tags: ['<h6>', '</h6>']},
	br: {type: 'pop', tags: ['<br>', '']},
	none: {type: 'continuation', tags: ['', '']}
};

const markDownTagNames = {
	'* ': 'ul',
	'- ': 'ul',
	'``` ': 'code',
	'> ': 'quote',
	'# ': 'h1',
	'## ': 'h2',
	'### ': 'h3',
	'#### ': 'h4',
	'##### ': 'h5',
	'###### ': 'h6',
	'1. ': 'ol',
	'2. ': 'ol',
	'3. ': 'ol',
	'4. ': 'ol',
	'5. ': 'ol',
	'6. ': 'ol',
	'7. ': 'ol',
	'8. ': 'ol',
	'9. ': 'ol',
	'': 'br'
};
const maxDepth = 3; // max tag depth

function removeLineBreaks(str) {
	return str.replace(/\n/g, '');
}

function mmd(src) {
	if (!src) {
		return src;
	}

	let parentTags = [];
	let outList = [];
	src
		.replace(/\r|\s+$/g, '')
		.split(/\n/)
		.forEach((row) => {
			// get length of markdown
			const markDownLength = row.indexOf(' ') + 1;
			// get markdown and handle if markDownLength is 0. that is not having an end
			const markDown = row.substr(0, markDownLength === 0 ? 1: markDownLength);

			const hasMarkDownTag = markDownTagNames[markDown] !== undefined;

			// use markdown if available, otherwise continue with previous tag
			const markDownTagName = hasMarkDownTag
				? markDownTagNames[markDown]
				: 'none';

			// get tag data
			const tag = tagData[markDownTagName];
			const tags = tag.tags;

			// if max depth reached and multi or list then pop one level first
			if (parentTags.length === maxDepth && (tag.type === 'multi' || tag.type === 'list')) {
				outList.push(tagData[parentTags.pop()].tags[1]);
			}

			// if new tag is same as old then set to continuation
			const tagType = markDownTagName !== parentTags[parentTags.length - 1]
				? tag.type
				: 'continuation';

			// trim away markdown from text if there is any
			const text = hasMarkDownTag
				? inlineEscape((row.substr(markDown.length)))
				: inlineEscape((row));

			// console.log(0, hasMarkDownTag, text, markDown, tag);
			// console.log(1, row, parentTags, markDownTagName, ':', tagType);

			switch (tagType) {
				case 'pop':
					// empty line
					if (parentTags.length === 0) {
						// if not inside a tag then add br
						outList.push(tagData.br.tags[0]);
					} else {
						// if inside tag then pop and add end tag
						outList.push(tagData[parentTags.pop()].tags[1]);
					}
					break;
				case 'single':
					// single line markdown
					outList.push(tags[0] + text + tags[1]);
					break;
				case 'multi':
					// multiple line markdown
					parentTags.push(markDownTagName);
					outList.push(tags[0]);
					outList.push(text);
					break;
				case 'list':
					// lists
					parentTags.push(markDownTagName);
					outList.push(tags[0]);
					outList.push('<li>' + text + '</li>');
					break;
				case 'continuation':
					const parentTagName = parentTags[parentTags.length - 1];
					if (parentTagName === 'ol' || parentTagName === 'ul') {
						// if ol or ul then use li tags
						outList.push('<li>' + text + '</li>');
					} else if (parentTagName === 'code' || parentTagName === 'quote') {
						// if quote or code then insert br first
						outList.push(tagData.br.tags[0]);
						outList.push(text);
					} else {
						// just text add br after
						outList.push(text);
						outList.push(tagData.br.tags[0]);
					}
					break;
			}
			// console.log(2, row, parentTags, removeLineBreaks(outList.join('')));

		});

	// add end-tags for remaining parents
	while (parentTags.length > 0) {
		outList.push(tagData[parentTags.pop()].tags[1]);
	}

	// remove last br if any
	if (outList[outList.length - 1] === tagData.br.tags[0]) {
		outList.pop();
	}

	// trim away line breaks before returning
	return removeLineBreaks(outList.join(''));
}

export default mmd;
