export default {
	'en-GB': {
		holidays: {
			PAGE_HEADER: 'Holidays',
			PAGE_DESCRIPTION: 'Add remove national holidays for the opening hours configuration.',
			MODAL_ADD_HOLIDAY: 'Add new holiday',
			MODAL_EDIT_HOLIDAY: 'Edit holiday',
			MODAL_HELP: 'Enter a name for the holiday',
			MODAL_TITLE: 'Title',
			BTN_SAVE: 'Save',
			BTN_CLOSE: 'Close',
			BTN_DELETE: 'Delete',
			NOTIFICATION_UNKNOWN_ERROR: 'Unknown error',
			NOTIFICATION_ERROR_SAVING_HOLIDAY: 'Could not save holiday',
			NOTIFICATION_ERROR_DELETING_HOLIDAY: 'Could not delete holiday'
		}
	},
	'sv-SE': {
		holidays: {
			PAGE_HEADER: 'Helgdagar',
			PAGE_DESCRIPTION: 'Lägg till och ta bort helgdagar för öppettiderna.',
			MODAL_ADD_HOLIDAY: 'Lägg till helgdag',
			MODAL_EDIT_HOLIDAY: 'Ändra helgdag',
			MODAL_HELP: 'Välj ett namn för helgdagen',
			MODAL_TITLE: 'Titel',
			BTN_SAVE: 'Spara',
			BTN_CLOSE: 'Stäng',
			BTN_DELETE: 'Radera',
			NOTIFICATION_UNKNOWN_ERROR: 'Okänt fel',
			NOTIFICATION_ERROR_SAVING_HOLIDAY: 'Helgdag kunde inte sparas',
			NOTIFICATION_ERROR_DELETING_HOLIDAY: 'Helgdag kunde inte raderas'
		}
	},
	'fr-FR': {
		holidays: {
			PAGE_HEADER: 'Vacances',
			PAGE_DESCRIPTION: 'Ajouter Enlever les jours fériés pour la configuration des heures d\'ouverture.',
			MODAL_ADD_HOLIDAY: 'Ajouter une nouvelle période de vacances',
			MODAL_EDIT_HOLIDAY: 'Modifier vacances',
			MODAL_HELP: 'Donnez un nom aux vacances',
			MODAL_TITLE: 'Titre',
			BTN_SAVE: 'sauvegarder',
			BTN_CLOSE: 'Fermer',
			BTN_DELETE: 'Effacer',
			NOTIFICATION_UNKNOWN_ERROR: 'Erreur inconnue',
			NOTIFICATION_ERROR_SAVING_HOLIDAY: 'Impossible d\'enregistrer des vacances',
			NOTIFICATION_ERROR_DELETING_HOLIDAY: 'Impossible de supprimer les vacances'
		}
	}
};
