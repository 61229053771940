import React from 'react';
import createReactClass from 'create-react-class';
import TimeAgo from '../../../components/TimeAgo.react'
import SpeakerTitle from '../../../components/SpeakerTitle.react';
import { injectIntl } from 'react-intl';

export default injectIntl(createReactClass({

	render () {
		let message = this.props.message;
		let speaker = this.props.speaker;

		if(!message.message) message.message = '';

		return (
			<div className="flex-container">
				<div className="message-note">
					<div className="note-meta">
						<SpeakerTitle name={speaker.name} title={speaker.titleId} isMe={speaker.isCurrentUser} />
						<span>{this.props.intl.formatMessage({id:'msgNoted'})}</span>
					</div>
					<span>{message.message}</span>
				</div>
			</div>
		);
	}
}));
