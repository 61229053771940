import React from 'react';
import {connect} from 'react-redux';
import PluginChannel from './PluginChannel.react';
import PluginActions from '../../actions/PluginActions';
import {getPinnedPlugins} from '../../selectors/PluginSelectors';
import PluginPanelButton from './PluginPanelButton';
import PluginToastContainer from './PluginToastContainer.react';


const PluginPanel = (props) => {
	const {
			  plugins,
			  pluginsEnabled,
			  setSelectedPlugin,
			  selectedPlugin,
			  ...rest
		  } = props;

	const pluginPayload = {
		...rest,
		selectedPlugin
	};

	const handleClick = (id) => {
		setSelectedPlugin(id !== selectedPlugin ? id: '');
	};

	const panelCls = `plugin-panel ${selectedPlugin ? 'active': ''}`;

	return plugins.size > 0 && pluginsEnabled
		? (
			<div className={panelCls}>
				<div className="plugin-content">
					{plugins
						.map((plugin) => <PluginChannel key={plugin.get('id')} plugin={plugin} {...pluginPayload}/>)
					}
				</div>
				<div className="plugin-toggle-buttons">
					{plugins
						.map((plugin) =>
							<div key={plugin.get('name')}>
								<PluginPanelButton
									plugin={plugin}
									handleClick={handleClick}
									selectedPlugin={selectedPlugin}
								/>
							</div>)
					}
				</div>
				<PluginToastContainer
					plugins={plugins}
					selectedPlugin={selectedPlugin}
					setSelectedPlugin={setSelectedPlugin}
				/>
			</div>
		)
		: null;
};

function mapStateToProps(state) {
	return {
		pluginsEnabled: state.get('pluginsEnabled'),
		selectedPlugin: state.get('selectedPlugin'),
		plugins: getPinnedPlugins(state),

	};
}

function mapDispatchToProps(dispatch) {
	return {
		setSelectedPlugin: (pluginId) => dispatch(PluginActions.setSelectedPlugin(pluginId)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PluginPanel);
PluginPanel.displayName = 'PluginPanel';

