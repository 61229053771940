export default function($uibModalInstance, APIConfigurationSectionService, options) {
	'ngInject';

    var vm = this;
    vm.options = options;
    vm.saveNew = saveNew;
    vm.cancelNew = $uibModalInstance.dismiss;

    // GDPR: Default case TTL is 14 days
	vm.newData = {
		section: {
			caseTimeToLive: '14.00:00:00'
		}
	};

    function saveNew() {
        var type = APIConfigurationSectionService.sectionMap.customerCase;
        vm.isSaving = true;
		$uibModalInstance.freeze(true);

        APIConfigurationSectionService.create(vm.newData, type, true)
            .then(function(res) {
				$uibModalInstance.close(res);
            })
            .catch(function() {
				$uibModalInstance.freeze(false);
                vm.isSaving = false;
            });
    }
};
