export default function (access) {
	return {
		id: 'sites',
		access: (access || 'Statistics'),
		viewConfig: {
			metricName: 'ConversionFunnelSite',

			/** ----------------------------------------------* Measures */
			measures: [
				{
					key: 'siteName',
					type: 'string'
				}, {
					key: 'numberOfUniqueVisits',
					type: 'number'
				}, {
					key: 'numberOfUniqueVisitors',
					type: 'number'
				}, {
					key: 'numberOfOpportunityEvaluations',
					type: 'number'
				}, {
					key: 'numberOfOpportunities',
					type: 'number'
				}, {
					key: 'numberOfQueueAdded',
					type: 'number'
				}, {
					key: 'numberOfQueueDialogs',
					type: 'number'
				}, {
					key: 'numberOfCasesClosed',
					type: 'number'
				}, {
					key: 'numberOfPurchaseConfirmations',
					type: 'number'
				}, {
					key: 'numberOfRelatedAssistedPurchasePois_sameVisit',
					type: 'number'
				}, {
					key: 'numberOfRelatedAssistedPurchasePois_6Hours',
					type: 'number'
				}
			],


			/** ----------------------------------------------* Bar filters */
			barFilters: [
				{
					dimension: 'siteName',
					valueAccessor: 'numberOfUniqueVisits'
				}
			],


			/** ----------------------------------------------* Time line graph */
			timeLineGraph: {
				valueAccessor: 'numberOfUniqueVisits'
			},


			/** ----------------------------------------------* Totals */
			totals: [
				{
					measure: 'numberOfUniqueVisits'
				}, {
					measure: 'numberOfOpportunityEvaluations'
				}, {
					measure: 'numberOfOpportunities',
					displayPercentOf: 'numberOfUniqueVisits'
				}, {
					measure: 'numberOfQueueAdded'
				}, {
					measure: 'numberOfQueueDialogs'
				}, {
					measure: 'numberOfCasesClosed'
				}, {
					measure: 'numberOfPurchaseConfirmations',
					displayPercentOf: 'numberOfUniqueVisits'
				}, {
					measure: 'numberOfRelatedAssistedPurchasePois_sameVisit',
					displayPercentOf: 'numberOfCasesClosed'
				}, {
					measure: 'numberOfRelatedAssistedPurchasePois_6Hours',
					displayPercentOf: 'numberOfCasesClosed'
				}
			],


			/** ----------------------------------------------* Table */
			table: {
				groupBy: 'siteName',
				measures: [
					{
						measure: 'numberOfUniqueVisits'
					}, {
						measure: 'numberOfOpportunityEvaluations'
					}, {
						measure: 'numberOfOpportunities'
/*
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'numberOfOpportunities',
								'numberOfOpportunityEvaluations'
							]
						}
*/
						//displayPercentOf: 'numberOfOpportunityEvaluations'
					}, {
						measure: 'numberOfQueueAdded'
					}, {
						measure: 'numberOfQueueDialogs'
					}, {
						measure: 'numberOfCasesClosed'
					}, {
						measure: 'numberOfPurchaseConfirmations',
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'numberOfPurchaseConfirmations',
								'numberOfUniqueVisits'
							]
						}
					}, {
						measure: 'numberOfRelatedAssistedPurchasePois_sameVisit',
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'numberOfRelatedAssistedPurchasePois_sameVisit',
								'numberOfCasesClosed'
							]
						}
					}, {
						measure: 'numberOfRelatedAssistedPurchasePois_6Hours'
/*
						calculation: {
							type: 'formatNumberWithPercentage',
							parameters: [
								'numberOfRelatedAssistedPurchasePois_6Hours',
								'numberOfCasesClosed'
							]
						}
*/
					}
				]
			}
		}
	}
};
