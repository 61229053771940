import {createSelector} from 'reselect'
import immutable from 'immutable'

const getAllMessages = (state, {conversationId}) => state.getIn(['conversations', conversationId, 'messages']);
const getLocalMessages = (state, {conversationId}) => state.getIn(['conversations', conversationId, 'localMessages']) || immutable.List();
const getDisplaySystemMessages = (state) => state.getIn(['userPreferences', 'displaySystemMessages']);

const getFilterFunction = (displaySystemMessages) => {
	const messageFilters = {
		chat: ['bannerInput', 'chat', 'link', 'note', 'content'],
		system: ['bannerInput', 'chat', 'link', 'note', 'content', 'navigation', 'domUpload']
	};
	const currentFilter = displaySystemMessages ? messageFilters['system']: messageFilters['chat'];
	return (msg) => {
		const msgType = msg.get('messageType');
		const found = currentFilter.indexOf(msgType) !== -1;
		const isDomUpload = msgType === 'domUpload' && !msg.get('isDomNavigation');
		return found && !isDomUpload;
	};
};

const getChatMessages = (messages, displaySystemMessages) => {
	const filterFunction = getFilterFunction(displaySystemMessages);
	return messages && messages.filter
		? messages.filter(filterFunction)
		: immutable.List();
};

const getBraidedChatMessages = (messages, localMessages, displaySystemMessages) => {
	let chatMessages = getChatMessages(messages, displaySystemMessages);
	let list = immutable.List();
	const totalSize = chatMessages.size + localMessages.size;
	for (let i = 0; i < totalSize; i++) {
		const firstChatMessage = chatMessages.first() || immutable.Map({createdRaw: Infinity});
		const firstLocalMessage = localMessages.first() || immutable.Map({createdRaw: Infinity});
		if (firstChatMessage.get('createdRaw') < firstLocalMessage.get('createdRaw')) {
			// add from chat
			list = list.push(firstChatMessage.set('status', 'received'));
			chatMessages = chatMessages.shift();
		} else {
			// add from local
			list = list.push(firstLocalMessage.update('status', prevStatus => prevStatus ? prevStatus: 'pending'));
			localMessages = localMessages.shift();
		}
	}
	return list;
};

const makeGetBraidedChatMessages = () => {
	return createSelector(
		[getAllMessages, getLocalMessages, getDisplaySystemMessages],
		getBraidedChatMessages
	);
};

const getLastMessageTimeStamp = (state, conversationId) => {
	const messages = (state.getIn(['conversations', conversationId, 'messages']) || immutable.List());
	return (messages.last() || immutable.Map({createdRaw: 0})).get('createdRaw') || 0;
};

const getLastLocalMessageTimeStamp = (state, conversationId) => {
	const localMessages = (state.getIn(['conversations', conversationId, 'localMessages']) || immutable.List());
	return (localMessages.last() || immutable.Map({createdRaw: 0})).get('createdRaw') || 0;
};


const getParticipantNameFromConversation = (id, conversation) => {
	const participants = conversation.participants || [];
	const participant = participants.find(participant => participant.userId === id || participant.visitId === id);
	return participant && participant.info && participant.info.name ? participant.info.name: '';
};

const getSpeakerProps = (conversation, msg) => {
	const id = msg.speaker.userId || msg.speaker.visitId || '';
	const name = !!conversation.toJS
		? conversation.getIn(['participants', id, 'info', 'name']) || ''
		: getParticipantNameFromConversation(id, conversation);

	const role = msg.speaker.visitId
		? 'visitor'
		: 'agent';
	return {
		...msg.speaker,
		id,
		name,
		role
	};
};

export default {
	getAllMessages,
	getDisplaySystemMessages,
	getChatMessages,
	getBraidedChatMessages,
	makeGetBraidedChatMessages,
	getLastMessageTimeStamp,
	getLastLocalMessageTimeStamp,
	getSpeakerProps,
};

