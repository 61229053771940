// Override config with temporary environment set using EnvTool
try {
	const tempVngageConfig = sessionStorage.getItem('tempVngageConfig');
	if (tempVngageConfig) {
		if (window.engage) {
			window.engage.config = JSON.parse(tempVngageConfig);
		} else {
			window.config = JSON.parse(tempVngageConfig);
		}
	}
} catch (e) {
	// Accessing sessionStorage may cause security exceptions - ignore...
}

const config = window.engage ? window.engage.config : window.config || {};
// config.batchRestUrl = (typeof config.batchRestUrl === 'string' ? config.batchRestUrl : 'api/v1/');
config.batchRestUrl = 'api/v1/';	// URI-prefix that will be used for all request items in "Batch"-calls (instead of the full "restUrl")

export default config;
