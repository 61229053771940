export default {
	'en-GB': {
		statistics: {
			views: {
				queues: {
					MENU_LABEL: 'Queues',
					TITLE: 'Queues',
					TABLE_HEADING: 'Hour report',
					TIME_LINE_GRAPH_HEADING: 'Requests by hour',
					measures: {
						siteName: {
							NAME: 'Site',
							TITLE: 'The name of the site presenting the chat option',
							LONG_DESCRIPTION: ''
						},
						groupName: {
							NAME: 'Group (Queue)',
							TITLE: 'The name of the queue / group that handled the chat request',
							LONG_DESCRIPTION: ''
						},
						dimensionName: {
							NAME: 'Queue (Site)',
							TITLE: '',
							LONG_DESCRIPTION: '*This measure is actually a generated text to group the dimensions visually in the data table. It is generated by "Group/Queue name" + "(Site name)"'
						},
						numberOfQueueAdded: {
							NAME: 'Requests',
							TITLE: 'Number of queued assistance requests',
							LONG_DESCRIPTION: 'This number is counted per hour and represents visitors added to queue. Transfers are not included!'
						},
						'numberOfQueueSelectedWhoWaitedBetween_0-30000Ms': {
							NAME: 'SLA 30sec',
							TITLE: 'Visitors picked from the queue within 30 seconds',
							LONG_DESCRIPTION: '*This number represents the amount of visitors (numberOfQueueAddedSelected) who received assistance within 30 sec. It is the same as the metric "numberOfQueueSelectedWhoWaitedBetween_0-30000Ms"'
						},
						'numberOfQueueSelectedWhoWaitedBetween_30000-60000Ms': {
							NAME: 'SLA 30-60 sec'
						},
						sla_1min: {
							NAME: 'SLA 1min',
							TITLE: 'Visitors picked from the queue within 1 minute',
							LONG_DESCRIPTION: '*This number represents the amount of visitors (numberOfQueueAddedSelected) who received assistance within 1min. It is calculated by adding "numberOfQueueSelectedWhoWaitedBetween_0-30000Ms" and "numberOfQueueSelectedWhoWaitedBetween_30000-60000Ms"'
						},
						'numberOfQueueSelectedWhoWaitedBetween_60000-120000Ms': {
							NAME: 'SLA 1-2 min'
						},
						sla_2min: {
							NAME: 'SLA 2min',
							TITLE: 'Visitors picked from the queue within 2 minutes',
							LONG_DESCRIPTION: '*This number represents the amount of visitors (numberOfQueueAddedSelected) who received assistance within 2min. It is calculated by adding "sla_1min" and "numberOfQueueSelectedWhoWaitedBetween_60000-120000Ms"'
						},
						numberOfQueueAddedDropped: {
							NAME: 'Abandoned',
							TITLE: 'Visitor who has left the queue before receiving assistance',
							LONG_DESCRIPTION: 'This number is counted per hour and represents visitors who has left the queue before receiving assistance. Transfers are not included!. A visitor is counted as dropped if they actively requests to be dropped or if they are inactive / has left the web site for more than 1min'
						},
						numberOfQueueAddedSelected: {
							NAME: 'Dialogs (% of requests)',
							TITLE: 'The number of visitors / dialogs who has been picked from the queue. The percent visitors who was helped ',
							LONG_DESCRIPTION: 'This number is counted per hour and represents visitors picked from the queue. Transfers are not included.'
						},
						totalQueueCompletedWaitTimeMs: {
							NAME: 'Total wait time',
							TITLE: 'The total time visitor has waited in queue',
							LONG_DESCRIPTION: 'The number represents the total time visitors who has been selected from the queue has waited. It is used for calculating average wait time when summed up over several hours'
						},
						numberOfQueueExits: {
							NAME: 'Queue exits',
							TITLE: 'The total number of visitors who has exited (Left or received assistance)',
							LONG_DESCRIPTION: '*This number represents the amount of visitors who has left the queue or received assistance. It is used for calculating the percent of visitors who received assistance'
						},
						avgQueueWaitTimeMs: {
							NAME: 'Avg wait time',
							TITLE: 'The average wait time before receiving assistance',
							LONG_DESCRIPTION: 'The number represents the average time visitors who has been selected from the queue has waited'
						},
						maxQueueWaitTimeMs: {
							NAME: 'Max wait time',
							TITLE: 'The maximum wait time before receiving assistance',
							LONG_DESCRIPTION: 'The number represents the maximum time visitors who has been selected from the queue has waited'
						},
						numberOfQueueTransfers: {
							NAME: 'Trans.',
							TITLE: 'Visitors who has been in a dialog but are transferred to another group/agent',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of visitors who has been transferred to another group/agent'
						},
						numberOfQueueTransferDropped: {
							NAME: 'Trans. abandoned',
							TITLE: 'Transferred visitors who left before receiving assistance from their new group/agent',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of transferred visitors who left the site or actively chose to cancel the assistance request while waiting'
						},
						numberOfQueueTransferSelected: {
							NAME: 'Trans. picked (% of transfers)',
							TITLE: 'Transferred visitors picked from the queue',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of transferred visitors who was picked from the queue'
						}
					},
					barFilters: {
						siteName: {
							HEADING: 'Sites'
						},
						groupName: {
							HEADING: 'Groups'
						}
					}
				}
			}
		}
	},
	'fr-FR': {
		statistics: {
			views: {
				queues: {
					MENU_LABEL: 'Files d\'attente',
					TITLE: 'Files d\'attente',
					TABLE_HEADING: 'Rapport horaire',
					TIME_LINE_GRAPH_HEADING: 'Demandes par heure',
					measures: {
						siteName: {
							NAME: 'Site',
							TITLE: 'Nom du site proposant l\'option de chat',
							LONG_DESCRIPTION: ''
						},
						groupName: {
							NAME: 'Groupe (File d\'attente)',
							TITLE: 'Nom de la file d\'attente / du groupe qui a traité la demande de chat',
							LONG_DESCRIPTION: ''
						},
						dimensionName: {
							NAME: 'File d\'attente (site)',
							TITLE: '',
							LONG_DESCRIPTION: '*Cette mesure est en fait un texte généré pour regrouper visuellement les dimensions dans le tableau des données. Elle est générée à partir de « Nom du groupe /de la file d\'attente + » « (Nom du site) »'
						},
						numberOfQueueAdded: {
							NAME: 'Demandes',
							TITLE: 'Nombre de demandes d\'assistance en attente',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente les visiteurs ajoutés à la file d\'attente. Les transferts ne sont pas inclus !'
						},
						'numberOfQueueSelectedWhoWaitedBetween_0-30000Ms': {
							NAME: 'SLA 30 sec',
							TITLE: 'Visiteurs sélectionnés dans la file d\'attente dans les 30 secondes',
							LONG_DESCRIPTION: '*Ce nombre représente le nombre de visiteurs (numberOfQueueAddedSelected) qui ont reçu de l\'aide dans un délai de 30 sec. Il s\'agit du même indicateur que « numberOfQueueSelectedWhoWaitedBetween_0-30000Ms »'
						},
						'numberOfQueueSelectedWhoWaitedBetween_30000-60000Ms': {
							NAME: 'SLA 30-60 sec'
						},
						sla_1min: {
							NAME: 'SLA 1 min',
							TITLE: 'Visiteurs sélectionnés dans la file d\'attente en moins d\'une minute',
							LONG_DESCRIPTION: '*Ce nombre représente le nombre de visiteurs (numberOfQueueAddedSelected) qui ont reçu de l\'aide dans un délai d\'une minute. Il est calculé en additionnant « numberOfQueueSelectedWhoWaitedBetween_0-30000Ms » et « numberOfQueueSelectedWhoWaitedBetween_30000-60000Ms »'
						},
						'numberOfQueueSelectedWhoWaitedBetween_60000-120000Ms': {
							NAME: 'SLA 1-2 min'
						},
						sla_2min: {
							NAME: 'SLA 2min',
							TITLE: 'Visiteurs prélevées à partir de la file d\'attente dans les 2 minutes',
							LONG_DESCRIPTION: '*Ce nombre représente le nombre de visiteurs (numberOfQueueAddedSelected) qui ont reçu de l\'aide dans les 2 min. Il est calculé en additionnant « sla_1min » et « numberOfQueueSelectedWhoWaitedBetween_600000000-120000Ms »'
						},
						numberOfQueueAddedDropped: {
							NAME: 'Abandon',
							TITLE: 'Visiteur qui a quitté la file d\'attente avant de recevoir de l\'aide',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et correspond au nombre de visiteurs qui ont quitté la file d\'attente avant de recevoir de l\'aide. Les transferts ne sont pas inclus ! Il est considéré qu\'un visiteur a abandonné s\'il l\'a demandé expressément ou s\'il est inactif / a quitté le site internet pendant plus d\'une minute'
						},
						numberOfQueueAddedSelected: {
							NAME: 'Dialogues (% des demandes)',
							TITLE: 'Nombre de visiteurs / dialogues qui ont été retirés de la file d\'attente. Pourcentage de visiteurs ayant reçu de l\'aide ',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente les visiteurs sélectionnés dans la file d\'attente. Les transferts ne sont pas inclus.'
						},
						totalQueueCompletedWaitTimeMs: {
							NAME: 'Temps total d\'attente',
							TITLE: 'Durée totale de l\'attente du visiteur dans la file d\'attente',
							LONG_DESCRIPTION: 'Ce nombre représente le temps d\'attente total des visiteurs qui ont été sélectionnés dans la file d\'attente. Il est utilisé pour calculer le temps d\'attente moyen lorsqu\'il est additionné sur plusieurs heures'
						},
						numberOfQueueExits: {
							NAME: 'sorties de la file d\'attente',
							TITLE: 'Nombre total de visiteurs qui sont sortis (partis ou ayant reçu de l\'aide)',
							LONG_DESCRIPTION: '*Ce nombre représente le nombre de visiteurs qui ont quitté la file d\'attente ou qui ont reçu de l\'aide. Il sert à calculer le pourcentage de visiteurs qui ont reçu de l\'aide'
						},
						avgQueueWaitTimeMs: {
							NAME: 'Temps d\'attente moyen',
							TITLE: 'Temps d\'attente moyen avant d\'obtenir de l\'aide',
							LONG_DESCRIPTION: 'Ce nombre représente le temps d\'attente moyen des visiteurs qui ont été sélectionnés dans la file d\'attente'
						},
						maxQueueWaitTimeMs: {
							NAME: 'Temps d\'attente max',
							TITLE: 'Temps d\'attente maximum avant de recevoir l\'aide',
							LONG_DESCRIPTION: 'Ce nombre représente le temps d\'attente maximum des visiteurs qui ont été sélectionnés dans la file d\'attente'
						},
						numberOfQueueTransfers: {
							NAME: 'Trans.',
							TITLE: 'Visiteurs ayant participé à un dialogue mais qui ont été transférés dans un autre groupe / agent',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de visiteurs qui ont été transférés à un autre groupe / agent'
						},
						numberOfQueueTransferDropped: {
							NAME: 'Trans. abandonné',
							TITLE: 'Visiteurs transférés qui sont partis avant de recevoir de l\'aide de leur nouveau groupe / agent',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de visiteurs transférés qui ont quitté le site ou ont délibérément choisi d\'annuler la demande d\'assistance pendant l\'attente'
						},
						numberOfQueueTransferSelected: {
							NAME: 'Trans. sélectionnés (% des transferts)',
							TITLE: 'Visiteurs transférés sélectionnés dans la file d\'attente',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de visiteurs transférés qui ont été retirés de la file d\'attente'
						}
					},
					barFilters: {
						siteName: {
							HEADING: 'Sites'
						},
						groupName: {
							HEADING: 'Groupes'
						}
					}
				}
			}
		}
	}
};
