import _find from 'lodash/find';
import _map from 'lodash/map';
import _reject from 'lodash/reject';
import Authorizations from '../records/AbilityAuthorizationsRecord'
import ParticipantColors from './ParticipantColors';
import ParticipantRecord from '../records/ParticipantRecord';

export default {

	/**
	 * returns userId or visitId of participant
	 * @param {Object} participant
	 * @returns {String}
	 */
	getParticipantEntityId (participant) {
		let mirrorParticipant = participant.participant || participant;
		return mirrorParticipant.userId || mirrorParticipant.visitId;
	},

	/**
	 * returns userId or visitId of participant
	 * @param {Object} participant
	 * @returns {Object}
	 */
	updateExistingParticipantStatus (participant) {
		participant.connectionState = 'ok';
		participant.state = 'joined';
		participant.id = participant.participationId;

		return participant;
	},

	/**
	 * returns userId or visitId of participant
	 * @param {String} conversationId
	 * @param {Object} participant
	 * @param {String} joinedId
	 * @returns {Object}
	 */
	mapJoinedParticipant (conversationId, participant, joinedId) {
		let participantToBe = {
			conversationId: conversationId,
			connectionState: 'ok',
			info: participant.info,
			id : participant.participationId,
			state: 'joined'
		};

		if (participant.participant.visitId) {
			participantToBe.visitId = joinedId;
		} else {
			participantToBe.userId = joinedId;
		}

		return participantToBe;
	},

	/**
	 * returns userId or visitId of participant
	 * @param {String} conversationId
	 * @param {Array} participants
	 * @param {Object} participant
	 * @returns {boolean}
	 */
	mapParticipantLeft (conversationId, participants, participant) {
		let didLeaveFromConversation = false;
		_map(participants, p => {
			if (p.conversationId === conversationId) {
				let pid   = this.getParticipantEntityId(p),
					parId = this.getParticipantEntityId(participant);
				if (pid === parId) {
					p.state = 'left';
					p.connectionState = 'terminate';
					didLeaveFromConversation = true;
				}
			}
		});

		return didLeaveFromConversation;
	},

	/**
	 * does participant have specific ability
	 * @param {Object} participant
	 * @param {String} ability
	 * @returns {boolean}
	 */
	hasAbility (participant, ability) {
		const hasUpdatedAuth = this.mappedAddedAuth(participant, ability);
		const hasRemovedAuth = this.mappedRemovedAuth(participant, ability);
		return (participant.abilities[ability] && !hasRemovedAuth) || hasUpdatedAuth;
	},

	/**
	 * update ability of participant
	 * @param {Object} participant
	 * @param {String} ability
	 * @param {Boolean} value
	 * @returns {participant}
	 */
	setAbility (participant, ability, value) {
		participant.abilities[ability] = value;
		return participant;
	},

	/**
	 * did participant recieve added authorizations
	 * @param {Object} participant
	 * @param {String} auth
	 * @returns {boolean}
	 */
	mappedAddedAuth (participant, auth) {
		if (!Authorizations[auth]) return false;

		const result = Authorizations[auth].filter(a => {
			return participant.addedAuthorizations.indexOf(a) > -1;
		});

		return result.length === Authorizations[auth].length;
	},

	/**
	 * did participant loose authorizations
	 * @param {Object} participant
	 * @param {String} auth
	 * @returns {boolean}
	 */
	mappedRemovedAuth (participant, auth) {
		if (!Authorizations[auth]) return false;

		const result = Authorizations[auth].filter(a => {
			return participant.removedAuthorizations.indexOf(a) > -1;
		});

		return result.length === Authorizations[auth].length;
	},

	/**
	 * check if passed in authorizations matches defined authorizations
	 * @param {Array} authorizations
	 * @returns {Object}
	 */
	participantAuthorizationChanged (authorizations) {
		const addedCoBrowsing = Authorizations.coBrowsing.filter( a => {
			return authorizations.indexOf(a) > -1;
		});

		const addedVideo = Authorizations.video.filter( a => {
			return authorizations.indexOf(a) > -1;
		});

		return {
			coBrowsing: addedCoBrowsing.length === Authorizations.coBrowsing.length,
			video: addedVideo.length === Authorizations.video.length
		}
	},

	/**
	 * rejects matched participant speakerId and conversationId
	 * @param {Array} participants
	 * @param {Object} participant
	 * @param {String} speakerId
	 * @returns {array} filtered array
	 */
	rejectMatchingParticipant (participants, participant, speakerId) {
		return _reject(participants, p => {
			let pid = p.userId || p.visitId;
			return pid === speakerId && p.conversationId === participant.conversationId;
		});
	},

	/**
	 * returns a color based on participant type and role
	 * @param {Object} participant
	 * @param {String} role
	 * @returns {String} color
	 */
	mapParticipantColor (participant, role = 'user') {
		if (participant.userId && participant.info.role === role) {
			return ParticipantColors.USER_COLOR;
		} else if (participant.userId && participant.info.role !== role) {
			return ParticipantColors.GUEST_COLOR;
		} else {
			return ParticipantColors.VISIT_COLOR;
		}
	},

	/**
	 * returns a participant-record based on participant role
	 * @param {Object} participant
	 * @returns {Object} participantRecord
	 */
	createParticipant (participant) {
		switch (participant.info.role) {
			case 'agent' :
				return ParticipantRecord('user', participant);
				break;
			case 'visitor' :
				return ParticipantRecord('visitor', participant);
				break;
			case 'internalExpert' :
				return ParticipantRecord('internalExpert', participant);
				break;
			default :
				throw new Error('participant role not found');
		}
	}
};
