

/**
 * Environment Object
 * Same detection as zepto.js uses
 * with a few additions like browser rendering engine
 */
function detect(ua) {
	var os = this.os = {}, browser = this.browser = {},
		webkit = ua.match(/Web[kK]it[\/]{0,1}([\d.]+)/),
		android = ua.match(/(Android);?[\s\/]+([\d.]+)?/),
		osx = !!ua.match(/\(Macintosh\; Intel /),
		ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
		ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/),
		iphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
		webos = ua.match(/(webOS|hpwOS)[\s\/]([\d.]+)/),
		//win = /Win\d{2}|Windows/.test(platform),
		win = /Windows/.test(ua),
		wp = ua.match(/Windows Phone ([\d.]+)/),
		touchpad = webos && ua.match(/TouchPad/),
		kindle = ua.match(/Kindle\/([\d.]+)/),
		silk = ua.match(/Silk\/([\d._]+)/),
		blackberry = ua.match(/(BlackBerry).*Version\/([\d.]+)/),
		bb10 = ua.match(/(BB10).*Version\/([\d.]+)/),
		rimtabletos = ua.match(/(RIM\sTablet\sOS)\s([\d.]+)/),
		playbook = ua.match(/PlayBook/),
		chrome = ua.match(/Chrome\/([\d.]+)/) || ua.match(/CriOS\/([\d.]+)/),
		firefox = ua.match(/Firefox\/([\d.]+)/),
		firefoxos = ua.match(/\((?:Mobile|Tablet); rv:([\d.]+)\).*Firefox\/[\d.]+/),
		ie = ua.match(/MSIE\s([\d.]+)/) || ua.match(/Trident\/[\d](?=[^\?]+).*rv:([0-9.].)/),
		webview = !chrome && ua.match(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/),
		safari = webview || ua.match(/Version\/([\d.]+)([^S](Safari)|[^M]*(Mobile)[^S]*(Safari))/),
		edge = ua.match(/ Edge\/([\d.]+)/) || ua.match(/ Edg\/([\d.]+)/)

	// Todo: clean this up with a better OS/browser seperation:
	// - discern (more) between multiple browsers on android
	// - decide if kindle fire in silk mode is android or not
	// - Firefox on Android doesn't specify the Android version
	// - developer.mozilla.org/en-US/docs/Web/HTTP/Gecko_user_agent_string_reference
	// - possibly devide in os, device and browser hashes

	if (browser.webkit = !!webkit) browser.version = webkit[1]

	if (android) os.android = true, os.version = android[2], os.osName = 'Android'
	if (iphone && !ipod) os.ios = os.iphone = true, os.version = iphone[2].replace(/_/g, '.'), os.osName = 'iOS'
	if (ipad) os.ios = os.ipad = true, os.version = ipad[2].replace(/_/g, '.'), os.osName = 'iOS'
	if (ipod) os.ios = os.ipod = true, os.version = ipod[3] ? ipod[3].replace(/_/g, '.') : null, os.osName = 'iOS'
	if (wp) os.wp = true, os.version = wp[1]
	if (webos) os.webos = true, os.version = webos[2], os.osName = 'WebOS'
	if (touchpad) os.touchpad = true
	if (blackberry) os.blackberry = true, os.version = blackberry[2], os.osName = 'Blackberry'
	if (bb10) os.bb10 = true, os.version = bb10[2]
	if (rimtabletos) os.rimtabletos = true, os.version = rimtabletos[2]
	if (playbook) browser.playbook = true
	if (kindle) os.kindle = true, os.version = kindle[1], os.osName = 'Kindle'
	if (silk) browser.silk = true, browser.version = silk[1]
	if (!silk && os.android && ua.match(/Kindle Fire/)) browser.silk = true
	if (chrome) browser.chrome = true, browser.version = chrome[1], browser.name = 'Chrome'
	if (firefox) browser.firefox = true, browser.version = firefox[1], browser.name = 'Firefox'
	if (firefoxos) os.firefoxos = true, os.version = firefoxos[1]
	if (ie) browser.ie = true, browser.version = ie[1], browser.name = 'IE'
	if (safari && (osx || os.ios || win)) {
		browser.safari = true, browser.name = 'Safari'
		if (!os.ios) browser.version = safari[1]
	}
	if (edge) browser.edge = true, browser.version = edge[1], browser.name = 'Edge';
	if (webview) browser.webview = true;
	if (win) os.osName = 'Windows';
	if (osx) os.osName = 'Mac'

	os.osx = osx
	os.win = win
	os.tablet = !!(ipad || playbook || (android && !ua.match(/Mobile/)) ||
		(firefox && ua.match(/Tablet/)) || (ie && !ua.match(/Phone/) && ua.match(/Touch/)))
	os.phone  = !!(!os.tablet && !os.ipod && (android || iphone || webos || blackberry || bb10 ||
		(chrome && ua.match(/Android/)) || (chrome && ua.match(/CriOS\/([\d.]+)/)) ||
		(firefox && ua.match(/Mobile/)) || (ie && ua.match(/Touch/))))

	//a bit naïve, but hey...
	os.touch = !!(os.tablet || os.phone);

	if (os.phone) {
		os.deviceName = 'Phone';
	} else if (os.tablet) {
		os.deviceName = 'Tablet';
	} else {
		os.deviceName = 'Desktop';
	}

	//Only go after things we can read from UA string:
	//os.legacy = !('opacity' in document.documentElement.style);
	//browser.engine = getRenderEngine();

	return {
		os: this.os,
		browser: this.browser
	};
}


export default {
	detect: detect
};
