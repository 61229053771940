export default {
    'en-GB': {
        analyze: {

        }
    },
    'sv-SE': {
        analyze: {

        }
    }
};
