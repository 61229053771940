import {toJS} from 'immutable';
import v000 from './0.0.0/index';
import v101 from './1.0.0/index';
import v111 from './1.1.0/index';
import v121 from './1.2.0/index';
import v131 from './1.3.0/index';

export default {
	api: {
		'0.0.0': {...v000},
		'1.0.0': {...v101},
		'1.1.0': {...v111},
		'1.2.0': {...v121},
		'1.3.0': {...v131},
	},

	getAction(actualApiVersion = '1.0.0', action) {
		return this.api[actualApiVersion].actionMap[action];
	},

	getActionFormat(actualApiVersion = '1.0.0', action) {
		return this.api[actualApiVersion].actionFormats[action];
	},

	// used for testing
	getInternalActions(apiVersion = '1.0.0') {
		const actualApiVersion = this.getActualVersion(apiVersion);
		return this.api[actualApiVersion].internalActions;
	},

	isInternalAction(actualApiVersion = '1.0.0', action) {
		return this.api[actualApiVersion].internalActions.indexOf(action) !== -1;
	},

	getSubscription(actualApiVersion = '1.0.0', subscription) {
		return this.api[actualApiVersion].subscriptions[subscription];
	},

	// get template for new plugin, used in workspace
	getPluginTemplate(apiVersion = '1.0.0') {
		const actualApiVersion = this.getActualVersion(apiVersion);
		return {
			...this.api[actualApiVersion].pluginTemplate,
			apiVersion
		};
	},

	// get list of selectable subscriptions for new plugin, used in workspace
	getAvailableSubscriptions(apiVersion = '1.0.0') {
		const actualApiVersion = this.getActualVersion(apiVersion);
		return this.api[actualApiVersion].availableSubscriptions;
	},

	// get list of selectable actions for new plugin, used in workspace
	getAvailableActions(apiVersion = '1.0.0') {
		const actualApiVersion = this.getActualVersion(apiVersion);
		return this.api[actualApiVersion].availableActions;
	},

	// object used to sort subscriptions so that they occur in the right order
	// must use the actualAPIVersion
	getSubscriptionPriority(actualApiVersion = '1.0.0') {
		return this.api[actualApiVersion].subscriptionPriority;
	},

	// version numbers as arrays, used internally
	getVersionsAsArrays() {
		return this.getAvailableAPIVersions()
			.map(version => version
				.split('.')
				.map(nrStr => parseInt(nrStr)));
	},

	getAvailableAPIVersions() {
		const versions = Object.keys(this.api)
			.sort((a, b) => {
			// version sorting
				const regExStrip0 = /(\.0+)+$/;
				const segmentsA = a.replace(regExStrip0, '').split('.');
				const segmentsB = b.replace(regExStrip0, '').split('.');
				const l = Math.min(segmentsA.length, segmentsB.length);

				for (let i = 0; i < l; i++) {
					const diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
					if (diff) {
						return diff;
					}
				}
				return segmentsA.length - segmentsB.length;
			});
		// remove test version 0.0
		versions.shift();
		return versions;
	},


	// get all versions using minor and patch in: major.minor.patch
	getMajorSet(versions = null) {
		versions = versions || this.getVersionsAsArrays();
		return versions.reduce((list, nrList) => {
			const value = nrList[0];
			return (list.indexOf(value) === -1
				? list.concat([value])
				: list);
		}, [])
			.map(nr => nr + '.x');
	},

	// get all versions using patch in: major.minor.patch
	getMinorSet(versions = null) {
		versions = versions || this.getVersionsAsArrays();
		return versions.reduce((list, nrList) => {
			const value = nrList[0] + '.' + nrList[1];
			return (list.indexOf(value) === -1
				? list.concat([value])
				: list);
		}, [])
			.map(nr => nr + '.x');

	},

	getLatestVersion() {
		const majors = this.getMajorSet();
		return majors[majors.length - 1];
	},

	getActualVersion(apiVersion) {
		const apiVersionList = apiVersion.split('.');
		if (apiVersionList.length < 3) {
			apiVersionList.push('x');
		}
		if (apiVersionList[1] !== 'x' && apiVersionList[2] !== 'x') {
			// no x, use incoming number if it exists
			return (this.api[apiVersion]
				? apiVersion
				: this.getLatestVersion());
		}
		if (apiVersionList[0] === 'x') {
			// major cannot be x, return latest
			return this.getLatestVersion();
		}

		const versions = this.getVersionsAsArrays();

		if (apiVersionList[1] === 'x') {
			// major
			const majorNr = parseInt(apiVersionList[0]);
			const minorNr = parseInt(apiVersionList[1]);

			const maxMajor = versions.reduce((max, verList) => {
				return (verList[0] === majorNr && verList[1] > max
					? verList[1]
					: max);
			}, 0);
			// minor
			const maxMinor = versions.reduce((max, verList) => {
				return (verList[0] === majorNr && verList[1] === maxMajor && verList[2] > max
					? verList[2]
					: max);
			}, 0);
			return apiVersionList[0] + '.' + maxMajor + '.' + maxMinor;

		} else if (apiVersionList[2] === 'x') {
			// minor
			const majorNr = parseInt(apiVersionList[0]);
			const minorNr = parseInt(apiVersionList[1]);
			const maxMinor = versions.reduce((max, verList) => {
				return (verList[0] === majorNr && verList[1] === minorNr && verList[2] > max
					? verList[2]
					: max);
			}, 0);
			return apiVersionList[0] + '.' + apiVersionList[1] + '.' + maxMinor;

		}

	},

	apiVersionSupported(apiVersion) {
		const actualApiVersion = this.getActualVersion(apiVersion);
		return (actualApiVersion.indexOf('x') === -1);
	},

};
