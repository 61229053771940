import SessionService from '../../../common/auth/SessionService';

const _mimeTypeMapping = {
	image: {
		langKey: 'imageUploaded',
		iconClass: 'content-image-icon'
	},
	video: {
		langKey: 'videoUploaded',
		iconClass: 'content-video-icon'
	},
	file: {
		langKey: 'fileUploaded',
		iconClass: 'content-file-icon'
	}
};
const _getFileType = (mimeType) => {
	return (mimeType && mimeType.replace ? mimeType.replace(/\/.+/, '') : '');
};

const generateBlobUrl = (data, type = 'octet/stream') => {
	const blob = new Blob([data], {type});
	return window.URL.createObjectURL(blob);
};

const revokeBlobUrl = (url) => {
	window.URL.revokeObjectURL(url);
};

const getFileObject = (data, file) => {
	// Transform dangerous chars in filename to harmless on upload
	const filename = file.name
		.replace(/ /g, '_')
		.replace(/[/\\]/g, '-')
		.replace(/[^a-zA-Z0-9_\-.À-Ýà-ý]/g, '_');
	const fileSize = file.size;
	const mimeType = file.type;
	return {
		data,
		blobUrl: generateBlobUrl(data),
		uploadInfo: {
			filename,
			fileSize,
			mimeType,
		}
	};
};
const saveFile = (fileObject) => {
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.style = 'display: none';
	a.href = fileObject.blobUrl;
	a.download = fileObject.uploadInfo.filename;
	a.click();
};

const postArrayBuffer = (url, payload, customHeaders = {}, mimeType = 'application/octet-stream') => {
	return new Promise(function (resolve, reject) {
		const xhr = new XMLHttpRequest();
		xhr.open('POST', url, true);

		const authHeader = SessionService().getAuthHeader();
		if (authHeader) {
			xhr.setRequestHeader('Authorization', authHeader);
		}

		Object.keys(customHeaders).forEach(function (headerName) {
			const customHeader = customHeaders[headerName] || {};
			// encode filename to handle special characters in filename, like åäö
			const encoded = customHeader.filename
				? {	...customHeader, filename: encodeURI(customHeader.filename) }
				: customHeader;
			xhr.setRequestHeader(headerName, JSON.stringify(encoded));
		});
		xhr.setRequestHeader('Content-Type', mimeType);

		xhr.onload = function () {
			if (xhr.status === 200) {
				resolve(xhr.response);
			} else {
				reject({
					name: 'postArrayBuffer onload',
					status: xhr.status,
					statusText: xhr.statusText,
					response: xhr.response
				});
			}
		};

		xhr.onerror = function () {
			reject({
				name: 'postArrayBuffer onerror',
				status: xhr.status,
				statusText: xhr.statusText,
				response: xhr.response
			});
		};

		xhr.send(new Blob([payload]));
	});
};

const getAsArrayBuffer = (url) => {
	return new Promise(function (resolve, reject) {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);

		const authHeader = SessionService().getAuthHeader();
		if (authHeader) {
			xhr.setRequestHeader('Authorization', authHeader);
		}
		xhr.responseType = 'arraybuffer';

		xhr.onload = function () {
			if (xhr.status === 200) {
				resolve(xhr.response);
			} else {
				reject({
					name: 'getAsArrayBuffer onload',
					status: xhr.status,
					statusText: xhr.statusText,
					response: xhr.response
				});
			}
		};

		xhr.onerror = function () {
			reject({
				name: 'getAsArrayBuffer onerror',
				status: xhr.status,
				statusText: xhr.statusText,
			});

		};

		xhr.send();
	});
};

const isAcceptedMimeType = (mimeType, acceptedMimeTypes) => {
	const mimeTypeFound = acceptedMimeTypes.indexOf(mimeType) >= 0;
	const wildcard = mimeType.replace(/\/.+/, '/*');
	const wildcardMimeTypeFound = acceptedMimeTypes.indexOf(wildcard) >= 0;
	return mimeTypeFound || wildcardMimeTypeFound;
};

const getMessageLangKey = (mimeType) => {
	const fileType = _getFileType(mimeType);
	return (_mimeTypeMapping[fileType] ? _mimeTypeMapping[fileType].langKey : _mimeTypeMapping['file'].langKey);
};
const getIconClass = (mimeType) => {
	const fileType = _getFileType(mimeType);
	return (_mimeTypeMapping[fileType] ? _mimeTypeMapping[fileType].iconClass : _mimeTypeMapping['file'].iconClass);
};
const truncateFilename = (filename, len) => {
	if (filename.length <= len || !len) {
		// filename smaller than len or no len specified, do not truncate
		return filename;
	}
	const ext = filename.substring(filename.lastIndexOf('.'), filename.length);
	const dotString = '... ';
	const firstLen = len - ext.length - dotString.length;
	if (firstLen < 1) {
		// exclude extension, no valid truncate can be done
		return filename.substring(0, len);
	}
	return filename.substring(0, firstLen) + dotString + ext;
};

export default {
	// for tests
	_mimeTypeMapping,
	_getFileType,
	//
	generateBlobUrl,
	revokeBlobUrl,
	getFileObject,
	saveFile,
	postArrayBuffer,
	getAsArrayBuffer,
	isAcceptedMimeType,
	getMessageLangKey,
	getIconClass,
	truncateFilename,
};
