import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {hot} from 'react-hot-loader/root';
import KeyRoutes from '../utils/KeyRoutes';
import DebugPanel from '../components/debugPanel/DebugPanel.react';
import LocalStorage from '../stores/LocalStorageWrapper';
import DesktopAC from '../actionCreators/DesktopAC';
import LogActions from '../actions/LogActions';
import DesktopDeuxView from '../components/DesktopDeuxView.react.js';
import DesktopDeuxFailed from './DesktopDeuxFailedView.react';
import InContactLoginForm from './inContact/InContactLoginForm.react';
import ErrorBoundary from './ErrorBoundary.react';
import Lang from './../utils/Lang';
import {LangContext} from './LangContext';

const debug = LocalStorage.getItem('desktopDeuxDebug') === 'true';

const DesktopLandingPage = () => {
	useEffect(() => {
		KeyRoutes.resume();
		return () => {
			KeyRoutes.pause();
		};
	}, []);
	const dispatch = useDispatch();
	const inContactLogin = (username, password, loginId, loginType) => dispatch(DesktopAC.inContactLogin(username, password, loginId, loginType));
	const logCrash = (error, errorInfo) => dispatch(LogActions.logCrash(error, errorInfo));

	const mockedLocale = useSelector(state => state.getIn(['debugFlags', 'mockedLocale']));
	const desktopStatus = useSelector(state => state.get('desktopStatus'));
	const inContactData = useSelector(state => state.get('inContactData'));
	const scope = 'dialog';

	KeyRoutes.setContext(scope);

	const lang = mockedLocale
		? {
			getTranslation(id) {
				return Lang.getTranslation(id);
			}
		}
		: Lang;

	let page;
	switch (desktopStatus) {
		case 'new':
			// pre everything state
			page = null;
			break;
		case 'inContactLoginForm':
			page = <InContactLoginForm inContactData={inContactData} inContactLogin={inContactLogin}/>;
			break;
		case 'desktopFailed':
			page = <DesktopDeuxFailed/>;
			break;
		case 'desktop':
			page = <DesktopDeuxView/>;
			break;
		default:
			page = <DesktopDeuxView/>;
			break;

	}

	return (
		<ErrorBoundary logCrash={logCrash}>
			<LangContext.Provider value={lang}>
				{debug ? <DebugPanel/>: null}
				{page}
			</LangContext.Provider>
		</ErrorBoundary>
	);
};


export default hot(DesktopLandingPage);


