import AppDispatcher from '../dispatcher/AppDispatcher';
import AppConstants from '../../../components/constants/AppConstants';

const ActionTypes = AppConstants.ActionTypes;

export default {

	receiveAsState (conversationState, conversationId, conversationRef) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.RECEIVE_AS_STATE,
			conversationState: conversationState,
			conversationId: conversationId,
			conversationRef: conversationRef
		});
	},

	receiveSince (conversationEvents, conversationId) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.RECEIVE_SINCE,
			rawMessages: conversationEvents,
			conversationId: conversationId
		});
	},

	processConversations (conversations) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.PROCESS_CONVERSATIONS,
			conversations: conversations
		});
	},

	clearConversations () {
		AppDispatcher.handleServerAction({
			type: ActionTypes.CLEAR_CONVERSATIONS
		});
	},

	addConversation (conversationId) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.ADD_CONVERSATION,
			conversationId: conversationId
		});
	},

	removeConversation (conversationId) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.REMOVE_CONVERSATION,
			conversationId: conversationId
		});
	},

	updateConversation (conversationId, propsToSet) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.UPDATE_CONVERSATION,
			conversationId: conversationId,
			propsToSet : propsToSet
		});
	},

	receiveBrowserInfo (conversationId, browserInfo) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.VISITOR_RESIZE,
			conversationId: conversationId,
			browserInfo: {
				width: browserInfo.width,
				height: browserInfo.height,
				hasFlash: browserInfo.hasFlash
			}
		});
	},

	receiveVideoAction (conversationId, config, startStream) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.VIDEO_CONFERENCE,
			conversationId: conversationId,
			config: config,
			startStream: startStream
		});
	},

	conversationClosed (conversationId, errorCode) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.CONVERSATION_CLOSED,
			conversationId: conversationId,
			errorCode: errorCode
		});
	},

	conversationLeave (conversationId, errorCode) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.CONVERSATION_LEAVE,
			conversationId: conversationId,
			errorCode: errorCode
		});
	},

	visualGuidanceHighlight (conversationId, payload) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.VISUAL_GUIDANCE_HIGHLIGHT,
			conversationId: conversationId,
			payload: payload
		})
	},

	visualGuidanceStatus (conversationId, status) {
		AppDispatcher.handleViewAction({
			type: ActionTypes.VISUAL_GUIDANCE,
			conversationId: conversationId,
			status: status,
			action: 'vgStatus'
		});
	},

	rewindConversation (conversationId) {
		this.updateConversation(conversationId, {abort: true, initialized: false, since: 0});
	},

	requestError (conversationId, statusCode, errorCode) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.REQUEST_ERROR,
			conversationId: conversationId,
			statusCode: statusCode,
			errorCode: errorCode
		})
	}
};
