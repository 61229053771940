import {
	unitTest,
	unitTestInc,
	receiveVisitors,
	queuePanelPinned,
	toggleUserPreferences,
	toggleGroupStatus,
	setUserPreferences,
	setStartCode,
	toggleStartCodeView,
	addSelectAnimation,
	removeSelectAnimation,
} from './stateModifiers/queue';
import AppConstants from '../../../components/constants/AppConstants';

const ActionTypes = AppConstants.ActionTypes;
const Ui = AppConstants.Ui;

export default function queueReducer(state, action) {

	switch (action.type) {
		case 'UNIT_TEST':
			return unitTest(state, action);
		case 'UNIT_TEST_INC':
			return unitTestInc(state, action);
		case ActionTypes.RECEIVE_VISITORS :
			return receiveVisitors(state, action);

		case Ui.QUEUE_PANEL_PINNED:
			return queuePanelPinned(state, action);

		case Ui.TOGGLE_USER_PREFERENCES:
			return toggleUserPreferences(state, action);

		case Ui.TOGGLE_GROUP_STATUS:
			return toggleGroupStatus(state, action);

		case Ui.SET_USER_PREFERENCES:
			return setUserPreferences(state, action);

		case ActionTypes.SET_START_CODE:
			return setStartCode(state, action);

		case Ui.TOGGLE_START_CODE_VIEW:
			return toggleStartCodeView(state, action);

		case Ui.ADD_SELECT_ANIMATION:
			return addSelectAnimation(state, action);

		case Ui.REMOVE_SELECT_ANIMATION:
			return removeSelectAnimation(state, action);


		default:
			return state;

	}
}
