export default function (query, $scope, $timeout, APIStatisticsService) {
	'ngInject';

	var disposed = false;
    var q;

    activate();

    function activate() {

        $scope.$on('$destroy', function () {
            disposed = true;
        });

        executeQuery();
    }

    function executeQuery() {
        if (q) {
            $timeout.cancel(q);
        }

        if (disposed) {
            return;
        }

        query().then(function() {
            q = $timeout(executeQuery, APIStatisticsService.defaultPollingTimeout);
        },function() {
            // We should re-schedule a new poll also on fail
            // Otherwise the dashboard widgets will stop updating after temporary network failures, etc...
            q = $timeout(executeQuery, APIStatisticsService.defaultPollingTimeout);
        });
    }
};
