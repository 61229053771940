import React from 'react';
import { injectIntl, FormattedRelative } from 'react-intl';
import createReactClass from 'create-react-class';

export default injectIntl(createReactClass({

	getInitialState () {
		return { timeAgo: 0 }
	},

	componentDidMount () {
		this.interval = setInterval(this.tick, 1000);
	},

	componentWillUnmount () {
		clearInterval(this.interval);
	},

	tick () {
		this.setState({
			timeAgo: this.props.startTime
		});
	},

	render () {
		return (
			<div className="duration-display">
				<i className="vngage-icon-clock"></i>
				<FormattedRelative value={this.state.timeAgo} />
			</div>
		)
	}

}));
