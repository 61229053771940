import React from 'react';
import Modal from './Modal.react';

const ModalFullscreen = (props) => {
	return (
		<div className="overlay-fullscreen">
			<Modal close={props.close}>
				{props.children}
			</Modal>
		</div>
	);
};
export default ModalFullscreen;

