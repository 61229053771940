import vngageCodemirrorLessTemplate from './vngageCodemirrorLessTemplate';

export default function ($q, $window, $timeout, $interval) {
	'ngInject';

	var notification;

	return {
		replace: true,
		restrict: 'A',
		require: ['^form', '^ngModel'],
		scope: {
			model: '=ngModel',
			onStyleUpdated: '&',
			themeModel: '=',
			matchBracketsDebounceSize: '=',	// If set and editor text is larger than this size (in bytes), codemirror bracket-matching will be debounced for performance (by 250ms or "matchBracketsDebounceTime" if set)
			matchBracketsDebounceTime: '='
		},
		templateUrl: vngageCodemirrorLessTemplate,
		link: function (scope, element, attrs, ctrls) {

			scope.form = ctrls[0];
			var ngModel = ctrls[1];

			ngModel.$asyncValidators.validateLess = validate;

			scope.$watch('model', function (newValue) {
				if (scope.form.$pristine) {
					editor.doc.setValue(newValue || '');
				}
			});

			var editor = element.next()[0].CodeMirror;
			editor.setOption('extraKeys', {'Ctrl-Enter': preview});

			scope.matchBracketDebounce = false;
			scope.$watch('matchBracketDebounce', function (enableDebounce) {
				if (editor) {
					editor.setOption('matchBrackets', {
						matchBracketsDebounceTime: (enableDebounce ? scope.matchBracketsDebounceTime || 250 : 0)
					});
				}
			});
			function adjustCodeMirrorMatchBracketDebounce() {
				// If codemirror editor-text is larger than <matchBracketsDebounceSize> bytes: Set matchBracketsDebounce-time to <matchBracketsDebounceTime> ms for performance
				scope.matchBracketDebounce = (scope.matchBracketsDebounceSize && editor && editor.getValue().length > scope.matchBracketsDebounceSize);
			}
			$interval(adjustCodeMirrorMatchBracketDebounce, 5000);

			$timeout(function () {
				editor.doc.setValue(scope.model || '');
				adjustCodeMirrorMatchBracketDebounce();
			}, 0);

			function preview() {
				scope.model = editor.getValue() || '';
				ngModel.$modeValue = scope.model;

				validate().then(function () {
					scope.onStyleUpdated().catch(err => {
						// Preview update failed (because of invalid less)
						// Ignore here (errors will be marked in the codemirror editor)
						// console.error('onStyleUpdated error', err);
					});
				});
			}

			function validate(modelValue, viewValue) {
				return scope.themeModel.validate(modelValue).then(function () { // Call validate() with the updated "modelValue" as parameter (otherwise it will validate the old value)
					tryClearNotification();
					valid();
				}, function (e) {
					if (e && e.error) {
						tryClearNotification();
						invalid(e.error);
					}
				});
			}

			function tryClearNotification() {
				if (notification) {
					notification.clear();
				}
			}

			function valid() {
				ngModel.$setValidity('style', true);
			}

			function invalid(error) {
				ngModel.$setValidity('style', false);

				editor.operation(function () {
					var msg = $window.document.createElement('div');
					var icon = msg.appendChild($window.document.createElement('span'));
					icon.innerHTML = '!!';
					icon.className = 'CodeMirror-lint-error-icon';
					msg.appendChild($window.document.createTextNode(error.message || 'Unrecognised input'));
					msg.className = 'CodeMirror-lint-error';
					notification = editor.doc.setBookmark({line: error.line - 1}, {widget: msg});
				});

				setScrollPosition();
			}

			function setScrollPosition() {
				var info = editor.getScrollInfo();
				var after = editor.charCoords({
					line: editor.getCursor().line + 1,
					ch: 0
				}, 'local').top;
				if (info.top + info.clientHeight < after) {
					editor.scrollTo(null, after - info.clientHeight + 3);
				}
			}
		}
	};
};
