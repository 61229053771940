import engageTranslations from './EngageTranslations';
import './banner/BannerModule';
import './solution/SolutionModule';
import EngageContentTemplate from './EngageContentTemplate';
import EngageMenuTemplate from './EngageMenuTemplate';
import '../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.engage', [
    'ui.router',
	'vngageApp.workspaceTranslation',
    'vngageApp.engage.solution',
    'vngageApp.engage.banner'
])

.constant('engageTranslations', engageTranslations)

.constant('engageModuleConfig', {
    state: 'root.engage',
    access: 'Account'
})

.config(function($stateProvider, $urlRouterProvider, $translateProvider, engageTranslations, engageModuleConfig, VngageWorkspaceTranslationServiceProvider) {
	'ngInject';
	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(engageTranslations);

    $urlRouterProvider.when('/engage/', function (transitionService,
        solutionModuleConfig,
        bannerModuleConfig) {
		'ngInject';

        transitionService.navigate([
            solutionModuleConfig,
            bannerModuleConfig
        ]);
    });

    $stateProvider.state(engageModuleConfig.state, {
        url: '/engage/',
        views: {
            content: {
                templateUrl: EngageContentTemplate
            },
            menu: {
                templateUrl: EngageMenuTemplate
            }
        },
        data: {
            access: engageModuleConfig.access,
            ncyBreadcrumbLabel: 'Engage'
        }
    });
});
