import React, {useState, useRef} from 'react';
import {connect} from 'react-redux';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import ServerActions from '../../actionCreators/ServerActions';
import VisitorProfileSelectors from '../../selectors/VisitorProfileSelectors';
import immutable from 'immutable';
import VisitorBalloon from './VisitorBalloon.react.js';
import VisitorMeta from './VisitorMeta.react.js';
import VisitorTooltip from './VisitorTooltip.react.js';
import Format from '../../utils/Format';
import TimeFormat from '../../utils/TimeFormat';
import {useGetTranslation} from './../LangContext';

const Visitor = (props) => {
	const getTranslation = useGetTranslation();
	const [selectedVisitor, setSelectedVisitor] = useState(false);
	const [displayNavigationHistory, setDisplayNavigationHistory] = useState(false);
	const posRef = useRef();

	const selectVisitor = () => {
		const top = posRef.current.offsetTop;
		props.startConversationFromQueue(props.conversationId, props.groupId, top);
		setSelectedVisitor(true);
	};

	const toggleNavigationHistory = () => {
		setDisplayNavigationHistory(!displayNavigationHistory);
	};

	const inTransferCls = props.inTransfer ? 'in-transfer': '';
	const lostByUserCls = props.lostByUser ? 'is-lost': '';
	const processing = selectedVisitor ? 'processing': '';
	const navigationHistory = displayNavigationHistory ? 'expanded-navigation-history': '';
	const cls = `queued-item ${inTransferCls} ${lostByUserCls} ${processing} ${navigationHistory}`;
	const title = props.title;

	const statusLabel = props.inTransfer && props.lostByUser
		? getTranslation('isLostLabel')
		: props.inTransfer
			? getTranslation('inTransferLabel')
			: '';

	const visitorTooltip = (
		<Tooltip id="visitor-title-tooltip">
			<VisitorTooltip
				title={title}
				claims={props.claims}
				isBookedMeeting={props.isBookedMeeting}
				bookedMeetingTime={props.bookedMeetingTime}
			/>
		</Tooltip>);

	const identified = props.visitorIdentified
		? <i style={{color: 'green', paddingRight: '2px'}} className="vngage-icon-vcard"/>
		: null;

	return (
		<div className={cls} ref={posRef}>
			{displayNavigationHistory
				? <div onClick={toggleNavigationHistory} className="co-overlay"/>
				: null
			}
			<VisitorBalloon
				selectVisitor={selectVisitor}
				inTransfer={props.inTransfer}
				durationSinceTransfer={props.durationSinceTransfer}
				durationSinceBegin={props.durationSinceBegin}
			/>
			<div className="details">
				<span className="tag">{statusLabel}</span>
				<OverlayTrigger placement="left" overlay={visitorTooltip}>
					<div className="bookedMeetingTitle lineClampEllipsis1Line">
						{identified}
						{title.length > 33
							? title.substring(0, 33) + ' ...'
							: title
						}
						{props.isBookedMeeting
							?
							<div>{getTranslation('meetingStart') + TimeFormat.asReservationTime(props.bookedMeetingTime)}</div>
							: null
						}
					</div>
				</OverlayTrigger>
				<VisitorMeta
					visitorJoined={props.visitorJoined}
					meta={props.meta}
					channel={props.channel}
					navigation={props.navigation}
					displayNavigationHistory={displayNavigationHistory}
					toggleNavigationHistory={toggleNavigationHistory}
				/>
			</div>
		</div>
	);
};

// ownProps = visitor
const mapStateToProps = (state, ownProps) => {
	const visitor = ownProps.visitor;
	const visitId = visitor.get('visitId') || '';
	const conversationId = visitor.get('id') || '';
	const visitorProfile = state.getIn(['visitorProfiles', visitId]) || immutable.Map();
	const title = visitor.get('title') || '';
	const isBookedMeeting = Format.isBookedMeeting(title);
	return {
		navigation: visitorProfile.get('navigation') || immutable.List(),
		channel: VisitorProfileSelectors.getQueuedVisitorChannel(visitorProfile),
		conversationId,
		groupId: visitor.get('groupId'),
		inTransfer: visitor.get('inTransfer'),
		lostByUser: visitor.get('lostByUser'),
		durationSinceBegin: visitor.get('durationSinceRequestConversationMs'),
		durationSinceTransfer: visitor.get('durationSinceTransferMs'),
		meta: visitorProfile.get('meta') || immutable.Map(),
		claims: VisitorProfileSelectors.getQueuedVisitorClaims(visitorProfile),
		visitorJoined: visitId && visitId !== '00000000-0000-0000-0000-000000000000',
		visitorIdentified: visitor.get('visitAuthenticationLevel') === 'Identified',
		displayVisitorProfile: !!state.getIn(['account', 'displayVisitorProfile']),
		title: isBookedMeeting ? Format.getBookedMeetingTitle(title): VisitorProfileSelectors.getQueuedVisitorTitle(visitorProfile) || title,
		isBookedMeeting,
		bookedMeetingTime: Format.getBookedMeetingTime(title),

	};
};

function mapDispatchToProps(dispatch) {
	return {startConversationFromQueue: (conversationId, groupId, startY) => dispatch(ServerActions.startConversationFromQueue(conversationId, groupId, startY))};
}

Visitor.displayName = 'Visitor';
export default connect(mapStateToProps,
	mapDispatchToProps)(Visitor);
