import AppDispatcher from '../dispatcher/AppDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import StoreFactory from '../../../components/utils/StoreFactory';

const ProfileActions = AppConstants.Profile;

var state = {
	profile: {}
};

var ProfileStore = StoreFactory.create({

	getProfile () {
		return state.profile;
	},

	getProfileId () {
		return state.profile.id;
	}

});

const registeredCallback = function(payload) {
	let action = payload.action;
	let profile = action.profile;

	switch (action.type) {

		case ProfileActions.REQUEST_PROFILE:
			state.profile = {};
			break;

		case ProfileActions.REQUEST_PROFILE_SUCCESS:
			state.profile = profile;
			break;

		case ProfileActions.REQUEST_PROFILE_ERROR:
			state.profile = {};
			break;

		default :
			return true;
	}

	ProfileStore.emitChange();

};

ProfileStore.dispatchToken = AppDispatcher.register( registeredCallback );

export default ProfileStore;
