import moment from 'moment-timezone';

export default function (APIEndpointService, $q) {
	'ngInject';

	var cache = null;

	var service = {
		query: query
	};

	return service;

	function query() {

		if (cache) {
			return $q(function (resolve) {
				resolve(cache);
			});
		}

		return $q(function (resolve, reject) {
			cache = angular.copy(moment.tz.names()).map(n => {
				return {
					id: n,
					displayName: n
				};
			});//res;
			resolve(cache);
			//APIEndpointService.timeZone.query().$promise.then(function (res) {
			//	cache = res;
			//	resolve(cache);
			//}, function (res) {
			//	reject(res);
			//});
		});
	}
};
