export default function () {
	// store all events in an array
	var eventsArr = [];
	var root = this;
	// add event to scope
	var addEvent = function (root, name, subscriptions) {
		eventsArr.push(name);
		root[name] = {
			// array of subscribed funcitons
			subscriptions: subscriptions,
			// trigger event, and run all subscribed functions with data as argument
			trigger: function (data) {


				//if ('onInterval onQueueStatusUpdated onQueueList onQueueListUpdated onProactiveList onLockedList'.indexOf(name) === -1) {
				//	if (!(name === 'onListenerResponse' && data.visitorsInQueues)) {
				//		console.log('<E> application.events.' + name + '.trigger(data)', data);
				//	}
				//}

				//for (var s in this.subscriptions) {
				for (var s = 0; s < this.subscriptions.length; s++) {
					//console.log('-->	' + name, this.subscriptions[s]);
					if (typeof this.subscriptions[s].func(data) === 'function') {
						try {
							this.subscriptions[s].func(data);
						} catch (err) {
							//console.log(err);
							//console.log({
							//    info: 'Event ' + name + ': function failed, see below:',
							//    error: JSON.stringify(err)
							//});
						}
					}
				}
			},
			// subscribe to event - push an id'd function in subscribe('myfunction',(function(){}));
			subscribe: function (id, func) {
				//console.log(id+' subscribed to '+name);
				this.subscriptions.push({id: id, func: func});
			},
			// unsubscribe to event - unsubscribe('myfunction');
			unsubscribe: function (id) {
				for (var s in this.subscriptions) {
					if (this.subscriptions[s].id == id) this.subscriptions.splice(s, 1);
					//logger({
					//    info: 'Event ' + name + ': Unsubscribed function with id:' + id
					//});
					return true;
				}
			}
		};
	};
	this.addEvents = function (events) {
		for (var c in events) {
			if (typeof c !== 'function') { addEvent(root, c, events[c]); }
		}
	};

};
