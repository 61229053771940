import React from 'react';
import createReactClass from 'create-react-class';
import {Button} from 'react-bootstrap';
import ViewActions from '../actions/ViewActions';
import { injectIntl } from 'react-intl';

export default injectIntl(createReactClass({

	handleClick () {
		ViewActions.endDialog(this.props.conversationId);
	},

	render () {
		return (
			<Button
				data-tip={this.props.intl.formatMessage({id:'dialogButtonEndTitle'})}
				bsStyle="success"
				bsSize="xsmall"
				className="tool-tip tool-tip-top-right pull-right coworker-btn-end"
				onClick={this.handleClick}
			>{this.props.intl.formatMessage({id:'dialogButtonEnd'})}</Button>
		)
	}

}));
