export default function ($http, $q, APIEndpointService, calendarModuleConfig, AccountSitesService, Session) {
	'ngInject';

	var accountId = Session.account.id;
	var siteId = AccountSitesService.getSelectedSiteId();
	var service = {
		queryHolidays: queryHolidays,
		querySaveHoliday: querySaveHoliday,
		queryRemoveHoliday: queryRemoveHoliday
	};

	function querySaveHoliday(params) {
		return $q((resolve, reject) => {
			var r = {
				id: params.id || null,
				accountId: accountId,
				siteId: siteId,
				start: params.start.format(),
				end: params.end.format(),
				title: params.title
			};
			if (r.id) {
				$q.all([
					APIEndpointService.reservationsHolidays.create({id: r.id}, r).$promise
				]).then(res => {
					resolve(res[0]);
				}, err => {
					reject(err);
				});
			} else {
				$q.all([
					APIEndpointService.reservationsHolidays.create(r).$promise
				]).then(res => {
					resolve(res[0]);
				}, err => {
					reject(err);
				});
			}
		});
	}

	function queryRemoveHoliday(params) {
		return $q((resolve, reject) => {
			var r = {
				id: params.id,
				accountId: accountId,
				siteId: siteId,
				hash: calendarModuleConfig.hash,
				start: params.start.format(),
				end: params.end.format()
			};
			$q.all([
				APIEndpointService.reservationsHolidays.delete(r).$promise
			]).then(res => {
				resolve(res[0]);
			}, err => {
				reject(err);
			});
		});
	}

	function queryHolidays(params) {
		return $q((resolve, reject) => {
			var r = {
				accountId: accountId,
				siteId: siteId,
				start: params.range.from.format(),
				end: params.range.to.format()
			};
			$q.all([
				APIEndpointService.reservationsHolidays.query(r).$promise
			]).then(res => {
				resolve(res[0]);
			}, err => {
				reject(err);
			});
		});
	}

	return service;
};

