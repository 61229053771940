import AppConstants from '../../../components/constants/AppConstants';
import VideoLib from './libs/videoLib';

const VideoActionTypes = AppConstants.Video;

export default function VideoReducer(state, action) {

	switch (action.type) {
		case VideoActionTypes.SET_START_TIME:
			return VideoLib.setStartTime(state, action.time);

		case VideoActionTypes.SET_MICROPHONE_ENABLED:
			return VideoLib.setMicrophoneEnabled(state, action.enabled);

		case VideoActionTypes.SET_CAMERA_ENABLED:
			return VideoLib.setCameraEnabled(state, action.enabled);

		case VideoActionTypes.SET_VIDEO_ERROR:
			return VideoLib.setError(state, action.error);

		case VideoActionTypes.SET_VIDEO_STATE:
			return VideoLib.setState(state, action.state);

		case VideoActionTypes.SET_ROOM_ID:
			return VideoLib.setRoomId(state, action.roomId);

		case VideoActionTypes.SET_PEER_ID:
			return VideoLib.setPeerId(state, action.peerId);

		case VideoActionTypes.DISPOSE:
			return VideoLib.dispose(state);

		case VideoActionTypes.SET_COM_TYPE:
			return VideoLib.setComType(state, action.comType);

		default:
			return state;
	}
}
