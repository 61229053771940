import React from 'react';
import createReactClass from 'create-react-class';
import {Button} from 'react-bootstrap';
import ViewActions from '../actions/ViewActions';
import { injectIntl } from 'react-intl';

export default injectIntl(createReactClass({

	onClickHandler () {
		ViewActions.blockParticipant(this.props.conversationId);
	},

	render () {
		var isDisabled = this.props.conversationState !== 'open';
		return (
			<Button data-tip={this.props.intl.formatMessage({id: 'dialogButtonBlockTitle'})} className="tool-tip tool-tip-top-left pull-left" bsSize="xsmall" onClick={this.onClickHandler} disabled={isDisabled}>{this.props.intl.formatMessage({id: 'dialogButtonBlock'})}</Button>
		)

	}

}));
