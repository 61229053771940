import immutable from 'immutable';

const seed = Math.floor(Math.random() * 99999);
const desktopId = Math.floor(Math.random() * 99999);

export default immutable.fromJS({
	accountId: '',
	account: {
		coBrowserDoNotDisturb: [],
		coBrowserRestricted: [],
		coBrowsingWhiteListHostNames: []
	},
	visitorSettings: {},
	desktopStatus: 'new',
	inContactData: {
		pending: false,
		errorMsg: '',
	},
	user: {
		id: ''
	},
	desktopActive: true,
	newVisitors: [],
	visitors: [],
	visitorProfiles: {},
	conversations: {},
	excessConversations: [],
	groups: {},
	maxConversationsPerGroup: {},
	groupRouting: {},
	counters: {
		numberOfOnlineUsers: 0,
		numberOfProactiveVisitors: 0,
		numberOfVisitorsInDialog: 0,
		numberOfVisitorsInQueue: 0

	},
	displayStartCode: false,
	displayUserPreferences: false,
	displayGroupStatus: false,
	panelOrder: [],
	nbrOfDialogsSoFar: 0,
	myGroupAvailability: {},
	infoTip: {
		doNotDisturb: false
	},
	userPreferences: {
		textSize: 'default',
		queuePanelPinned: true,
		displaySystemMessages: false,
		activeInfoTip: {
			doNotDisturb: true
		}

	},
	seed,
	desktopId,
	windowId: 0,
	invitationCode: 0,
	startCode: '00000',
	selectedItemPositions: {},
	selectedItemWarnTime: 3,
	selectedItemRemoveTime: 6,
	netId: 0,
	presence: '',
	presenceForcedAway: false,
	displayForceAwayMessage: false,
	panelAnimating: false,
	activePanel: '',
	coBro: {
		conversationId: '',
		url: '',
		lastDomUploadUrl: 'dummyUrl',
		isScaled: true,
		paused: false,
		highlightMode: false,
		navigation: [],
		blocked: [],
		refreshUrlCounter: 0,
		domUploadStatus: 'ready',
		doNotDisturb: false,
		restricted: false,
		isBlocked: false,
		addressbarNavigation: {},
		metadataExchangeMessages: []
	},
	debugFlags: {
		pauseDebugLogging: false,
		logType: 'none'
	},
	pluginsEnabled: true,
	selectedPlugin: '',
	plugins: [],
	pluginData: {
		conversationId: '',
		messagesById: {},
		lastUpdatedProfile: {
			conversationId: '',
			visitId: '',
			type: ''
		},
		pluginButtonData: {},
		meta: {},
		myDetailsCounter: 0,
	},
	video: {
		startTime: 0,
		microphoneEnabled: true,
		cameraEnabled: true,
		comType: '',
		state: '',
		error: '',
		roomId: '',
		peerId: ''
	},
	modal: ''
});
