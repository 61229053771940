
import GroupQueueReservationOpeningHoursController from './GroupQueueReservationOpeningHoursController';
import GroupQueueReservationOpeningHoursService from './GroupQueueReservationOpeningHoursService';
import GroupQueueReservationOpeningHoursTemplate from './GroupQueueReservationOpeningHoursTemplate';
import GroupQueueReservationOpeningHoursTranslations from './GroupQueueReservationOpeningHoursTranslations';
import GroupQueueReservationOpeningHoursCalendarTranslations from './GroupQueueReservationOpeningHoursCalendarTranslations';
import GroupQueueReservationOpeningHoursConfigModalController from './GroupQueueReservationOpeningHoursConfigModal/GroupQueueReservationOpeningHoursConfigModalController';
import GroupQueueReservationOpeningHoursShowBlockDirective from './GroupQueueReservationOpeningHoursConfigModal/GroupQueueReservationOpeningHoursShowBlockDirective';
import GroupQueueReservationOpeningHoursEditBlockDirective from './GroupQueueReservationOpeningHoursConfigModal/GroupQueueReservationOpeningHoursEditBlockDirective';
import '../../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.configure.group.groupQueueReservationsOpeningHours', [
	'ui.router',
	'ui.calendar',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.constant('GroupQueueReservationOpeningHoursCalendarTranslations', GroupQueueReservationOpeningHoursCalendarTranslations)

	.constant('GroupQueueReservationOpeningHoursModuleConfig', {
		state: 'root.configure.group.edit.queueReservationOpeningHours',
	})

	.controller('GroupQueueReservationOpeningHoursController', GroupQueueReservationOpeningHoursController)
	.factory('GroupQueueReservationOpeningHoursService', GroupQueueReservationOpeningHoursService)
	.constant('GroupQueueReservationOpeningHoursTranslations', GroupQueueReservationOpeningHoursTranslations)

	.controller('GroupQueueReservationOpeningHoursConfigModalController', GroupQueueReservationOpeningHoursConfigModalController)

	.constant('GroupQueueReservationOpeningHoursModuleConfig', {
		state: 'root.configure.group.edit.queueReservationOpeningHours'
	})

	.directive('showOpeningHoursBlock', GroupQueueReservationOpeningHoursShowBlockDirective)
	.directive('editOpeningHoursBlock', GroupQueueReservationOpeningHoursEditBlockDirective)

	.config(function($stateProvider, $translateProvider, GroupQueueReservationOpeningHoursTranslations, GroupQueueReservationOpeningHoursModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(GroupQueueReservationOpeningHoursTranslations);

		$stateProvider.state(GroupQueueReservationOpeningHoursModuleConfig.state, {
			// url: 'groupQueueReservationOpeningHours/',
			// url: ':id',
			url: '/groupQueueReservationOpeningHours/',
			controller: 'GroupQueueReservationOpeningHoursController as vm',
			templateUrl: GroupQueueReservationOpeningHoursTemplate,
			data: {
				ncyBreadcrumbLabel: 'groupQueueReservationOpeningHours'
			},
			resolve: {
				groupList: function(APIEndpointService, AccountSitesService) {
					'ngInject';
					var siteId = AccountSitesService.getSelectedSiteId();
					return APIEndpointService.group.query({siteId:siteId}).$promise;
				},
				userList: function(APIEndpointService, IdentityService) {
					'ngInject';
					return APIEndpointService.user.query({limit: 10000}).$promise.then(function(userList) {

						if (IdentityService.currentlyDelegated()) {
							return userList;
						} else {
							// Only return non-delegated users
							return userList.filter(function(user) {
								return user.data.type === 0;
							});
						}
					});
				},
				configOpeningHours: function(APIEndpointService, group) {
					'ngInject';
					var r = {
						groupId: group.data.id
					};
					return APIEndpointService.reservationsOpeningHourConfigs.get(r).$promise;
				},
				configOverrides: function(APIEndpointService, AccountSitesService, Session, group) {
					'ngInject';
					var r = {
						groupId: group.data.id
					};
					return APIEndpointService.reservationsOpeningHourOverrides.query(r).$promise;
				}
			}
		});
	});
