import React from 'react';
import {connect} from 'react-redux';
import immutable from 'immutable';
import Conversation from '../../../actionCreators/Conversation';
import Message from '../../../actionCreators/Message';
import ConversationActions from '../../../actions/ConversationActions';
import InputMenu from '../InputMenu/InputMenu.react.js';
import ChatTextarea from './ChatTextarea.react';
import NoteTextarea from './NoteTextarea.react';

export const InputArea = (props) => {
	return props.noteMode
		? <form className="compose-note-wrapper">
			<NoteTextarea {...props}/>
		</form>
		: <form className="compose-message-wrapper" onSubmit={(e) => e.preventDefault()}>
			<InputMenu {...props}/>
			<ChatTextarea {...props}/>
		</form>;
};

function mapStateToProps(state, ownProps) {
	// incoming
	// conversationId
	// chatSelection,
	// setChatSelection,
	// typingChatText,
	// chatText,
	//
	// noteSelection,
	// setNoteSelection,
	// typingNoteText,
	// noteText,


	const conversationId = ownProps.conversationId;
	const conversation = state.getIn(['conversations', conversationId]) || immutable.Map();
	return {
		// chat
		inputEnabled: conversation.get('conversationStatus') !== 'closed' && conversation.get('connectionStatus') !== 'terminate',
		panelPosition: conversation.getIn(['UI', 'panelPosition']),
		panelAnimating: state.get('panelAnimating'),
		// note
		noteMode: conversation.getIn(['UI', 'currentInput']) === 'note',
		// input menu
		currentCaseType: conversation.get('currentCaseType'),
		conversationStatus: conversation.get('conversationStatus'),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		// chat
		sendChatMessage: (conversationId, msg) => dispatch(Message.sendChatMessage(conversationId, msg)),
		// note
		sendNote: (conversationId, msg) => dispatch(Message.sendNote(conversationId, msg)),
		toggleNoteMode: (conversationId) => dispatch(Conversation.toggleNoteModeWithFocus(conversationId)),
		// input menu
		sendAction: (conversationId, actionObj) => dispatch(Message.sendAction(conversationId, actionObj)),
	};
}

InputArea.displayName = 'InputArea';
export default connect(mapStateToProps, mapDispatchToProps)(InputArea);
