export default {
	'en-GB': {
        site: {
            ADD_HEADER: 'New site',
            EDIT_HEADER: 'Edit site',
            LIST_HEADER: 'Sites',
            LIST_TITLE: 'List of sites for this account',
            LIST_DESCRIPTION: 'A site can set up settings such as language, themes. It\'s most commonly used to separate multi-language sites, but can also be used for many different sites under the same account.',
            SCRIPT: 'Script',
            LANGUAGE: 'Translation',
            NAME: 'Name',
			DOMAIN: 'Domain',

			USE_BASE_DOMAIN_COOKIES: 'Use base-domain cookies',
			USE_BASE_DOMAIN_COOKIES_DESCR: 'This enables visitor\'s session-cookies to be set to the topmost valid base-domain of the currently visited URL. ' +
				'The effect is that all sub-domains will share the same session, and no transfer-tokens will be required when navigating between them.<br>' +
				'If disabled, session-cooikes will be set to the full domain/hostname, and transport-tokens will be required to keep the session on cross-sub-domain-navigations.',

			MIGRATION_HEADER: 'Migrate settings',
			MIGRATION_TITLE: 'Site setting needs migration',
			MIGRATION_SAVE: 'Set',

			BASE_DOMAIN_MIGRATION_INFO: 'The setting "Domain" has been replaced by "Use base-domain cookies".<br>' +
				'The old setting would force the visitor\'s session cookies to be set to a specific base-domain, however that would only allow one single base-domain to be used. ' +
				'The new setting "Use base-domain cookies" is more general and will automatically find and use the topmost base-domain of any hostname.',
			BASE_DOMAIN_MIGRATION_CURRENTVALUE: 'The currently saved value for "Domain" is: ',
			BASE_DOMAIN_MIGRATION_RECOMMENDATION: 'The recommended new setting for "Use base-domain cookies" is: <strong>enabled</strong>'
        }
    },
	'sv-SE': {
		site: {
			ADD_HEADER: 'New site',
			EDIT_HEADER: 'Edit site',
			LIST_HEADER: 'Sites',
			LIST_TITLE: 'List of sites for this account',
			LIST_DESCRIPTION: 'A site can set up settings such as language, themes. It\'s most commonly used to separate multi-language sites, but can also be used for many different sites under the same account.',
			SCRIPT: 'Script',
			LANGUAGE: 'Translation',
			NAME: 'Name',
			DOMAIN: 'Domain',

			USE_BASE_DOMAIN_COOKIES: 'Use base-domain cookies',
			USE_BASE_DOMAIN_COOKIES_DESCR: 'This enables visitor\'s session-cookies to be set to the topmost valid base-domain of the currently visited URL. ' +
			'The effect is that all sub-domains will share the same session, and no transfer-tokens will be required when navigating between them.<br>' +
			'If disabled, session-cooikes will be set to the full domain/hostname, and transport-tokens will be required to keep the session on cross-sub-domain-navigations.',

			MIGRATION_HEADER: 'Migrate settings',
			MIGRATION_TITLE: 'Site setting needs migration',
			MIGRATION_SAVE: 'Set',

			BASE_DOMAIN_MIGRATION_INFO: 'The setting "Domain" has been replaced by "Use base-domain cookies".<br>' +
			'The old setting would force the visitor\'s session cookies to be set to a specific base-domain, however that would only allow one single base-domain to be used. ' +
			'The new setting "Use base-domain cookies" is more general and will automatically find and use the topmost base-domain of any hostname.',
			BASE_DOMAIN_MIGRATION_CURRENTVALUE: 'The currently saved value for "Domain" is: ',
			BASE_DOMAIN_MIGRATION_RECOMMENDATION: 'The recommended new setting for "Use base-domain cookies" is: <strong>enabled</strong>'
		}
	}
};
