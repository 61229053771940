import React from 'react';
import MessageList from './MessageList.react';
import createReactClass  from 'create-react-class';

export default createReactClass({

	componentDidMount () {
		this.shadeRef = document.querySelector('.indicate-overflow-top');
	},

	onScrollChange (pos) {
		// Apply a shadow when content in message list is scrolled
		if (pos < 100) {
			this.shadeRef.style.opacity = (pos/10 + 4/10);
		}
	},

	render () {
		return  (
			<MessageList messages={this.props.messages} onScrollChange={this.onScrollChange}/>
		)
	}
})
