import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function ($scope, APIEndpointService, $stateParams, $state, closure, VngageUtil, vngageRemoveModalService, APIConfigurationSectionService) {
	'ngInject';

	var vm = this;
	vm.closure = closure;
	vm.submit = submit;
	vm.remove = remove;
	vm.cancel = cancel;
	vm.currentList = $stateParams.currentList || [];

	$scope.$on('resetResettables', () => {
		vm.closureEditForm.$setPristine();
		closure.$get().catch(() => {
		});
	});

	function submit() {
		vm.isSaving = true;
		closure.$save().finally(function () {
			vm.isSaving = false;
		});
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: closure,
			itemType: APIConfigurationSectionService.sectionMap.closure
		}).then(function () {
			vm.closureEditForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(closure, 'root.configure.closure.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(function () {
			vm.isSaving = false;
		});
	}

	function cancel() {
		$state.go('^');
	}
}
