import dashboardTranslations from './DashboardTranslations';
import DashboardController from './DashboardController';
import BaseDashboardController from './BaseDashboardController';
import MyStatsController from './mystats/MyStatsController';
import DialogUsageController from './dialogUsage/DialogUsageController';
import UserUsageController from './userUsage/UserUsageController';
import GroupOverviewController from './groupOverview/GroupOverviewController';
import UsersOnlineController from './usersOnline/UsersOnlineController';
import UserProfilePictureCacheService from './usersOnline/service/UserProfilePictureCacheService';
import vngageDialogUsage from './dialogUsage/directive/vngageDialogUsage';
import DialogUsageDimensionService from './dialogUsage/service/DialogUsageDimensionService';
import vngageGroupOverview from './groupOverview/directive/vngageGroupOverview';
import GroupOverviewDimensionService from './groupOverview/service/GroupOverviewDimensionService';
import GroupOverviewKPIService from './groupOverview/service/GroupOverviewKPIService';
import vngageUsersOnline from './usersOnline/directive/vngageUsersOnline';
import UsersOnlineDimensionService from './usersOnline/service/UsersOnlineDimensionService';
import vngageUserUsage from './userUsage/directive/vngageUserUsage';
import UserUsageDimensionService from './userUsage/service/UserUsageDimensionService';
import DashboardTemplate from './DashboardTemplate';
import CasesAndVisitsController from './casesAndVisits/CasesAndVisitsController';
import casesAndVisitsService from './casesAndVisits/casesAndVisitsService';

// templates used by ng-include
import './dialogUsage/DialogUsageTemplate';
import './groupOverview/GroupOverviewTemplate';
import './mystats/MyStatsTemplate';
import './usersOnline/UsersOnlineTemplate';
import './userUsage/UserUsageTemplate';
import './casesAndVisits/CasesAndVisitsTemplate';
import '../../common/VngageWorkspaceTranslationModule';
import '../account/customScript/guides/CustomScriptGuideTemplate.html';

export default angular.module('vngageApp.dashboard', [
    'ui.router',
    'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

.constant('dashboardTranslations', dashboardTranslations)
.controller('DashboardController', DashboardController)
.controller('BaseDashboardController', BaseDashboardController)
.controller('MyStatsController', MyStatsController)
.controller('DialogUsageController', DialogUsageController)
.controller('UserUsageController', UserUsageController)
.controller('GroupOverviewController', GroupOverviewController)
.controller('UsersOnlineController', UsersOnlineController)
.factory('UserProfilePictureCacheService', UserProfilePictureCacheService)
.directive('vngageDialogUsage', vngageDialogUsage)
.factory('DialogUsageDimensionService', DialogUsageDimensionService)
.directive('vngageGroupOverview', vngageGroupOverview)
.factory('GroupOverviewDimensionService', GroupOverviewDimensionService)
.factory('GroupOverviewKPIService', GroupOverviewKPIService)
.directive('vngageUsersOnline', vngageUsersOnline)
.factory('UsersOnlineDimensionService', UsersOnlineDimensionService)
.directive('vngageUserUsage', vngageUserUsage)
.factory('UserUsageDimensionService', UserUsageDimensionService)
.controller('CasesAndVisitsController', CasesAndVisitsController)
.factory('casesAndVisitsService', casesAndVisitsService)

.constant('dashboardModuleConfig', {
    state: 'root.dashboard'
})

.config(function($stateProvider, $translateProvider, dashboardTranslations, dashboardModuleConfig, VngageWorkspaceTranslationServiceProvider) {
	'ngInject';

	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(dashboardTranslations);

    $stateProvider.state(dashboardModuleConfig.state, {
        url: '/dashboard/',
        views: {
            content: {
                controller: 'DashboardController as dashboard',
                templateUrl: DashboardTemplate
            }
        }
    });
});
