import DesktopPluginAPI from '../desktopDeux/pluginAPI/DesktopPluginAPI';
import DesktopPluginsHandler from './DesktopPluginsHandler';

export default function ($uibModalInstance, APIConfigurationSectionService, Session, vngageGuid, WorkspaceSettingsService, NotificationService, desktopPluginsSection) {
	'ngInject';

	const vm = this;
	const apiMajorSet = DesktopPluginAPI.getMajorSet();
	const apiMinorSet = DesktopPluginAPI.getMinorSet();
	const apiVersions = [...apiMajorSet, ...apiMinorSet, ...DesktopPluginAPI.getAvailableAPIVersions()];
	const apiVersion = DesktopPluginAPI.getLatestVersion();

	vm.newData = {
		name: '',
		apiVersion,
		apiVersions
	};

	vm.saveNew = saveNew;
	vm.cancelNew = $uibModalInstance.dismiss;

	function saveNew() {
		vm.isSaving = true;
		$uibModalInstance.freeze(true);

		const newPlugin = {
			...DesktopPluginAPI.getPluginTemplate(vm.newData.apiVersion),
			name: vm.newData.name,
			url: vm.newData.url,
			apiVersion: vm.newData.apiVersion,
			id: vngageGuid.generate().toUpperCase()
		};

		desktopPluginsSection.pluginsList = DesktopPluginsHandler.addOrUpdateDesktopPluginsList(desktopPluginsSection.pluginsList, newPlugin);
		WorkspaceSettingsService.saveWorkspaceSettingsSections({desktopPlugins: desktopPluginsSection}).then(() => {
			vm.isSaving = false;
			$uibModalInstance.freeze(false);
			$uibModalInstance.close(desktopPluginsSection.pluginsList);
		}).catch(error => {
			vm.isSaving = false;
			$uibModalInstance.freeze(false);
			NotificationService.error({
				header: 'Add plugin',
				body: 'Could not add plugin: ' + (error ? (error.data ? error.data.message : error.statusText) : 'unknown')
			});
			$uibModalInstance.dismiss(error);
		});
	}

};

