import keyMirror from 'keymirror';

export default {

	ActionTypes: keyMirror({
		RECEIVE_VISITORS: null,
		VISITOR_SELECTED: null,
		VISITOR_LOCKED: null,
		RECEIVE_AS_STATE: null,
		RECEIVE_SINCE: null,
		UPDATE_CASE_TYPE: null,
		CLOSE_CASE_AND_CONVERSATION: null,
		PROCESS_CONVERSATION_DETAILS: null,
		CLEAR_CONVERSATIONS: null,
		REMOVE_CONVERSATION: null,
		ADD_CONVERSATION: null,
		UPDATE_CONVERSATION: null,
		TRANSFER_TO_GROUP: null,
		GROUP_STATUS_UPDATED: null,
		HANG_UP: null,
		SET_CONVERSATION_ERROR: null,

		RECEIVE_GROUP_STATUS: null,
		CHANGE_GROUP_AVAILABILITY: null,

		CONVERSATION_IDLE: null,

		CREATE_MESSAGE: null,
		CREATE_NOTE: null,
		CREATE_LINK_MESSAGE: null,
		CREATE_NAVIGATION_MESSAGE: null,
		CREATE_VIDEO_INVITATION: null,

		VISITOR_RESIZE: null,
		COBROWSER_HIGHLIGHT: null,
		COBROWSER_TOGGLE_HIGHLIGHT: null,
		COBROWSER_DOMUPLOAD: null,
		TOGGLE_VISUAL_GUIDANCE_SCALE: null,
		TOGGLE_META_MESSAGES: null,
		VIDEO_STREAM: null,
		VISUAL_GUIDANCE: null,
		VISUAL_GUIDANCE_HIGHLIGHT: null,

		VIDEO_CONFERENCE: null,

		CASE_CLOSED: null,
		CONVERSATION_CLOSED: null,
		CONVERSATION_LEAVE: null,

		PARTICIPANT_JOINED: null,
		PARTICIPANT_LEFT: null,
		PARTICIPANT_LEAVE: null,
		PARTICIPANT_AUTHORIZED: null,
		PARTICIPANT_CONNECTIONSTATUS: null,
		PARTICIPANT_TYPING: null,
		PARTICIPANT_AUTHORIZATION: null,

		REQUEST_ERROR: null,

		VISITOR_PROFILE: null,
		VISITOR_PROFILE_ADD: null,
		SET_VISITOR_PROFILE_NAVIGATION: null,
		VISITOR_PROFILE_UDPATECLAIMS: null,
		VISITOR_PROFILE_CLEAR_IDENTIFIED: null,
		VISITOR_HEADER_TITLE: null,

		CONNECTION_UP: null,
		CONNECTION_DOWN: null,

		INVITE_PARTICIPANT_SUCCESS: null,
		INVITE_PARTICIPANT_ERROR: null,
		INVITE_PARTICIPANT_DISMISSED: null,

		SET_START_CODE: null,
		INIT_GROUP_AVAILABILITY: null,
		SET_GROUP_AVAILABILITY: null,
		CHANGE_CASE: null,
		ADD_LOCAL_MESSAGE: null,
		SET_FORMS: null,
		SET_CLAIMS_PATCH: null,
		CLEAN_UP_BEFORE_LEAVING: null,
		SET_PRESENCE: null,
		RESET_FORCE_AWAY: null,
		INIT_GROUP_ROUTING: null,
		NET_POLL: null,
		NET_POLL_DONE: null,
		NET_POLL_SKIP: null,
		NET_POLL_WARNING: null,
		NET_POLL_ERROR: null,
		NET_CALL: null,
		NET_CALL_ERROR: null,
		SET_DEBUG_FLAG: null,
		UPDATE_COBRO_URL: null,
		SET_COBRO_HIGHLIGHT_MODE: null,
		TOGGLE_COBRO_SCALE: null,
		TOGGLE_COBRO_PAUSE: null,
		SET_COBRO_DOMUPLOAD_STATUS: null,
		SET_ACCOUNT_ID: null,
		SET_ACCOUNT: null,
		SET_VISITOR_SETTINGS: null,
		SET_USER_ID: null,
		SET_MAX_CONVERSATIONS_PER_GROUP: null,
		CONTENT_LOG: null,
		CONTENT_ERROR_LOG: null,
		LOG_AC: null,
		NET_ERROR: null,
		COBRO_LOG: null,
		COBRO_ERROR: null,
		LOG_CRASH: null,
		LOG_MISC: null,
		LOG_REQUEST: null,
		PLUGIN_ACTION: null,
		PLUGIN_ACTION_ERROR: null,
		PLUGIN_ERROR: null,
		PLUGIN_SUBSCRIPTIONS: null,
		SET_FLOW_STATE: null,
		START_CONVERSATION_FROM_QUEUE: null,
		INIT_ROUTED_CONVERSATION: null,
		START_ROUTED_CONVERSATION: null,
		RESUME_CONVERSATION: null,
		INIT_AND_START_ROUTED_CONVERSATION: null,
		CONVERSATION_READY: null,
		SET_CONVERSATION_CAPABILITIES: null,
		UPDATE_LOCAL_MESSAGE_STATUS: null,
		SET_DESKTOP_STATUS: null,
		SET_INCONTACT_DATA: null,
		ADD_HISTORY_MARKERS: null,
		EXPAND_HISTORY_MARKER: null,
	}),

	Plugin: keyMirror({
		RECEIVE_AS_STATE: null,
		RECEIVE_SINCE: null,
		CONVERSATION_READY: null,
		TOGGLE_PLUGINS_ENABLED: null,
		TOGGLE_PLUGIN: null,
		REFRESH_PLUGIN: null,
		PLUGIN_READY: null,
		ADD_PLUGINS: null,
		PLUGIN_BUTTON_CLICKED: null,
		SET_PLUGIN_BUTTON_STATE: null,
		SET_META: null,
		UPDATE_PLUGIN_META: null,
		SET_SELECTED_PLUGIN: null,
		NOTIFY: null,
		PROCESS_CONVERSATION_DETAILS: null,
	}),

	Ui: keyMirror({
		SET_CURRENT_MESSAGE: null,
		SET_CURRENT_MESSAGE_AND_SHOW: null,
		RESET_UPDATE_INPUT_TEXT: null,
		SET_CURRENT_NOTE: null,
		QUEUE_PANEL_PINNED: null,
		TEXT_SIZE: null,
		TOGGLE_USER_PREFERENCES: null,
		TOGGLE_ACTIVE_INFO_TIP: null,
		HIDE_INFO_TIP: null,
		TOGGLE_GROUP_STATUS: null,
		SET_ACTIVE_SECTION: null,
		REMOVE_ACTIVE_SECTION: null,
		SET_USER_PREFERENCES: null,
		TOGGLE_SYSTEM_MESSAGES: null,
		TOGGLE_NEW_CASE_AFTER_CLOSE: null,
		TOGGLE_EMAIL_CONVERSATION_TRANSCRIPT: null,
		SET_PENDING_REMOVAL: null,
		INDICATE_MESSAGE_COPIED: null,
		SET_SELECTED_CLOSURE: null,
		TOGGLE_START_CODE_VIEW: null,
		ADD_SELECT_ANIMATION: null,
		REMOVE_SELECT_ANIMATION: null,
		TOGGLE_NOTE_MODE: null,
		ACTIVATE_PANEL: null,
		SET_PANEL_POSITION: null,
		SHOW_MODAL: null,
		HIDE_MODAL: null
	}),

	Fakes: keyMirror({
		ADD_FAKE_PANEL: null,
		REMOVE_FAKE_PANEL: null,
		REMOVE_ALL_FAKE_PANELS: null,
		ADD_FAKE_MESSAGE: null,
		ADD_FAKE_SYSTEM_MESSAGE: null,
		TOGGLE_FAKE_TYPING: null,
		SET_FAKE_CURRENT_CASE_TYPE: null,
		ADD_FAKE_VISITOR_PROFILE: null,
		ADD_FAKE_VISITOR: null,
		NEW_STATE_DEBUG: null,
		ADD_FAKE_RESERVATION: null,
		SET_FAKE_CONVERSATION_STATUS: null,
		ADD_FAKE_CONVERSATION: null
	}),

	PayloadSources: keyMirror({
		SERVER_ACTION: null,
		VIEW_ACTION: null,
		CONNECTION_ACTION: null
	}),

	Authorization: keyMirror({
		PENDING: null,
		AUTHORIZED: null,
		NOT_AUTHORIZED: null,
		BLOCKED: null
	}),

	Profile: keyMirror({
		REQUEST_PROFILE: null,
		REQUEST_PROFILE_SUCCESS: null,
		REQUEST_PROFILE_ERROR: null
	}),

	Video: keyMirror({
		SET_START_TIME: null,
		SET_ROOM_ID: null,
		SET_VIDEO_ERROR: null,
		SET_VIDEO_STATE: null,
		SET_MICROPHONE_ENABLED: null,
		SET_CAMERA_ENABLED: null,
		SET_PEER_ID: null,
		SET_COM_TYPE: null,
		DISPOSE: null
	})
};
