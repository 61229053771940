import React from 'react';
import {connect} from 'react-redux';
import Video from '../../actionCreators/Video';
import VideoLib from '../../reducers/libs/videoLib';

const ToolbarView = (props) => {
	const {
			  isModal,
			  isFullscreen,
			  toggleMicrophone,
			  toggleCamera,
			  switchToFullscreen,
			  switchToModal,
			  microphoneEnabled,
			  cameraEnabled
		  } = props;

	const microphoneButtonStyle = microphoneEnabled ?
		'vngage-icon-mic active':
		'vngage-icon-mute';

	const cameraButtonStyle = cameraEnabled ?
		'vngage-icon-videocam-1 active':
		'vngage-icon-videocam-1';

	const modalButton = isModal ?
		null:
		<i className="vngage-icon-export"
		   onClick={() => switchToModal()}/>;

	const fullscreenButton = isFullscreen ?
		null:
		<i className="vngage-icon-line-fullscreen"
		   onClick={() => switchToFullscreen()}/>;

	return <div className="video-toolbar-bottom">
		{fullscreenButton}
		{modalButton}
		<i className={microphoneButtonStyle}
		   onClick={() => toggleMicrophone()}/>
		<i className={cameraButtonStyle}
		   onClick={() => toggleCamera()}/>
	</div>;
};

function mapStateToProps(state) {

	return {
		microphoneEnabled: VideoLib.getMicrophoneEnabled(state),
		cameraEnabled: VideoLib.getCameraEnabled(state)
	};
}

function mapDispatchToProps(dispatch) {

	return {
		toggleMicrophone: () => dispatch(Video.toggleMicrophone()),
		toggleCamera: () => dispatch(Video.toggleCamera()),
		switchToFullscreen: () => dispatch(Video.showVideoFullscreen()),
		switchToModal: () => dispatch(Video.showVideoModal())
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ToolbarView);
