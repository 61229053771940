export default function (group, caseTypeList) {
	'ngInject';

	var vm = this;
	vm.submit = submit;
	vm.group = group;
	vm.selectableCases = formatSelectableCaseTypes(caseTypeList);

	function submit() {
		vm.group.$save();
	}

	function formatSelectableCaseTypes(caseTypeList) {

		return caseTypeList.map(function (caseType) {
			return {
				refValue: caseType.data.id,
				displayName: caseType.data.name
			};
		});
	}
};
