const USER = new WeakMap();

export default {

	set props ({sessionProps:sessionProps, sessionId:sessionId}) {
		var user = sessionProps.user;

		USER.set(this, {
			token: sessionId,
			id: user.id,
			accountId: sessionProps.account.id,
			name: user.profile.displayName,
			email: user.profile.preferredEmail,
			configuration: {
				profilePicture: user.profile.profilePicture,
				displayName: user.profile.publicDisplayName || user.profile.displayName,
				desktopLanguage: user.profile.language
			},
			roles: user.roleClaims,
			languages: user.languages || [],
			identities: user.identities || [],
			allowConcurrentSessions: true,
			authorizations: sessionProps.authorizations
		});
	},

	get props () {
		return USER.get(this);
	}
};
