export default {
	'en-GB': {
        configurationSetHistory: {
            HISTORY_HEADER: 'Configuration Set history'
        }
    },
    'sv-SE': {
        configurationSetHistory: {
            HISTORY_HEADER: 'Configuration Set history'
        }
    }
};
