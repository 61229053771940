import UserAgentDetection from '../../../common/UserAgentDetection';

const isAsync = (userAgentRaw) => {
	const asyncParams = userAgentRaw.split('|').map(str => str.trim());
	return asyncParams.length > 0 && asyncParams[0] === 'Async';
};

const getChannel = (userAgentRaw) => {
	const asyncParams = userAgentRaw.split('|').map(str => str.trim());
	const channel = asyncParams.length > 1 ? asyncParams[1]: '';

	return {
		facebookmessenger: 'facebook-messenger',
		sms: 'sms',
		email: 'email'
	}[channel.toLowerCase()] || channel;

};

export default {
	getChangeCaseData(conversationId, data) {
		const state = data.data.items[1].body;
		const conversation = state.conversations.find(conv => conv.id === conversationId);
		const caseEvents = conversation.events.filter(event => event.type === 'associatedWithCase');
		const newCase = caseEvents.pop();
		const prevCase = caseEvents.pop();
		return {
			conversationId,
			from: {
				caseId: prevCase.caseId
			},
			to: {
				caseId: newCase.caseId

			}
		};
	},

	getAsVisitorProfile(visitorProfile) {
		const defaultOs = {
			osName: '',
			osx: false,
			win: false,
			tablet: false,
			phone: false,
			touch: false,
			deviceName: '',
			channel: ''
		};
		const defaultBrowser = {
			webkit: false,
			version: '',
			chrome: false,
			name: ''
		};

		// Only detect on strings longer than 12
		// if string is shorter it's probably a messaging channel
		const userAgentRaw = visitorProfile.data.userAgentRaw || '';
		const detectedMeta = isAsync(userAgentRaw)
			? {os: {channel: getChannel(userAgentRaw)}, browser: {}}
			: UserAgentDetection.detect(userAgentRaw);

		visitorProfile.meta = {
			os: {
				...defaultOs,
				...detectedMeta.os
			},
			browser: {
				...defaultBrowser,
				...detectedMeta.browser
			}
		};
		return visitorProfile;
	},

	getTrimmedGroupStatus({users, ...rest}) {
		return {...rest};
	}

};
