import React from 'react';
import {useSpring, animated} from 'react-spring'

const QueueSelectedItem = (props) => {
	const animProps = useSpring({
		from: {transform: `translate3d(${props.startX}px,${props.startY}px,0)`},
		to: {transform: `translate3d(${props.endX}px,${props.endY}px,0)`},
	});
	return (
		<animated.div className="selected-item" style={animProps}>
				<div className="loader-indication"/>
		</animated.div>
	);
};

export default QueueSelectedItem

