export default function (TimeRangeService, TimeIntervalService) {
	'ngInject';

	var service = {
		create: create
	};

	return service;

	function create(val) {
		var range = TimeRangeService.create(TimeIntervalService.range[val.range]);

		var filter = {
			range: {
				to: range.to()
			}
		};

		if (!val.period) {
			val.period = TimeIntervalService.period.day;
		}

		filter.period = TimeIntervalService.period[val.period];
		filter.range.from = range.from();
		filter.rangeAsText = TimeIntervalService.range[val.range];
		filter.siteId = val.siteId;
		return filter;
	}
};
