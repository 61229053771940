import React, {useState, useEffect} from 'react';
import AccountConfig from '../../../utils/AccountConfig';
import Format from '../../../utils/Format';
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useGetTranslation} from './../../LangContext';

const CaseTypeMenu = (props) => {
	const getTranslation = useGetTranslation();
	const [caseTypes, setCaseTypes] = useState([]);
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const onSelectCaseType = (eventKey) => {
		if (eventKey === 'options') {
			props.setActiveSection(props.conversationId, 'caseManager');
		} else {
			const caseObj = caseTypes.find(c => c.id === eventKey) || {problem: 'missingCase', eventKey, caseTypes};
			props.updateCaseType(props.conversationId, caseObj);
		}
	};

	const {groupId} = props;

	useEffect(() => {
		setCaseTypes(Format.asSearchFilteredList(AccountConfig.getCaseTypesByGroupId(groupId), '', 'name', 'name'));
	}, [groupId]);

	const caseTypesItems = caseTypes.map((caseObj, index) => {
		return (
			<Dropdown.Item key={index} title={caseObj.name} eventKey={caseObj.id}
						   className="lineClampEllipsis1Line">{caseObj.name}</Dropdown.Item>
		);
	});

	const currentCase = AccountConfig.getOneCaseType(props.currentCaseType);
	const currentCaseLoaded = currentCase.name.length > 0;

	const toolTipStyle = currentCase.name.length < 20 || dropdownOpen
		? {display: 'none'}
		: {};
	const toolCaseCurrent = <Tooltip style={toolTipStyle} id="tooltip">{currentCase.name}</Tooltip>;

	return (
		<OverlayTrigger overlay={toolCaseCurrent} placement="top-end" delay={{show: 250, hide: 400}}>
			<Dropdown
				tabIndex="-1"
				className="case-menu"
				drop="up"
				onSelect={onSelectCaseType}
				onToggle={setDropdownOpen}
				size="sm"
				id={`split-button-basic-${0}`}
			>
				<Dropdown.Toggle id="dropdown-case" size="sm" variant="outline-secondary"
								 className="case-dropdown-button">
					{currentCaseLoaded
						? currentCase.name.length > 20 ? currentCase.name.slice(0, 20) + '...': currentCase.name
						: getTranslation('loadingCurrentCase')
					}
				</Dropdown.Toggle>
				<Dropdown.Menu className="case-list">
					{caseTypesItems}
					<Dropdown.Divider/>
					<Dropdown.Item key="options"
								   eventKey="options">{getTranslation('moreCaseOptions')}</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</OverlayTrigger>
	);
};
export default CaseTypeMenu;

