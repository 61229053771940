export default function(vngagePatterns) {
    'ngInject';

    return {
        require: 'ngModel',
        link: function($scope, element, attrs, ngModel) {
            ngModel.$validators.hoursminutes = function(value) {
                if (value) {
                    return vngagePatterns.hoursMinutes.test(value);
                } else {
                    return true;
                }
            };
        }
    };
};
