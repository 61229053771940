export default {
    'en-GB': {
        action: {
            ADD_HEADER: 'New action',
            EDIT_HEADER: 'Edit action',
            LIST_HEADER: 'Actions',
			LIST_TITLE: 'List of actions for working site',
			LIST_DESCRIPTION: 'Actions are used when having a dialog with a visitor. In co-browsing mode you can send links, files, movies, images or documents such as PDF files. Actions can also be small scripts to interact with the visitor.',
            ICON: 'Icon',
            ITEM_TYPE: 'Action type',
			PARAMETER_URL: 'Action target URL',
			PARAMETER_EMBED_TEMPLATE: 'HTML template',
            NAME: 'Name',
            ACTION_TYPE: 'Action type',
            ACTION_TYPE_URL: 'URL',
            ACTION_TYPE_JAVSCRIPT: 'Javascript',
            ACTION_TYPE_VIDEO_PLAYER: 'Video',
            ACTION_TYPE_PDF: 'PDF',
            ACTION_TYPE_IMAGE_OVERLAY: 'Image',
            ACTION_TYPE_FORM: 'Form',
			ACTION_TYPE_EMBED: 'Embed',
			DESC_ICON: 'This icon will represent the Action when displayed in an Action panel',
			DESC_ITEM_TYPE: 'Actions can have different behaviors. It can be a link to a page, a file or a video',
			DESC_PARAMETER_URL: 'The action target can be a web page, link to a image or a document such as a PDF file',
			DESC_PARAMETER_EMBED_TEMPLATE: 'HTML-template to render for this action. The template can contain placeholders that will be substituted with dynamic data when the action is used from e.g. a Desktop plugin. Example: "{{key}}" will be replaced by "value" if the action is triggered with actionData={key: \'value\'}',

            REMOVE_HEADER: 'Remove action "{{ name }}"',
            REMOVE_MESSAGE: 'Note that any existing references to this action from action panels also will be removed.'
        }
    },
	'sv-SE': {
		action: {
			ADD_HEADER: 'New action',
			EDIT_HEADER: 'Edit action',
			LIST_HEADER: 'Actions',
			LIST_TITLE: 'List of actions for working site',
			LIST_DESCRIPTION: 'Actions are used when having a dialog with a visitor. In co-browsing mode you can send links, files, movies, images or documents such as PDF files. Actions can also be small scripts to interact with the visitor.',
			ICON: 'Icon',
			ITEM_TYPE: 'Action type',
			PARAMETER_URL: 'Action target URL',
			PARAMETER_EMBED_TEMPLATE: 'HTML template',
			NAME: 'Name',
			ACTION_TYPE: 'Action type',
			ACTION_TYPE_URL: 'URL',
			ACTION_TYPE_JAVSCRIPT: 'Javascript',
			ACTION_TYPE_VIDEO_PLAYER: 'Video',
			ACTION_TYPE_PDF: 'PDF',
			ACTION_TYPE_IMAGE_OVERLAY: 'Image',
			ACTION_TYPE_FORM: 'Form',
			ACTION_TYPE_EMBED: 'Embed',
			DESC_ICON: 'This icon will represent the Action when displayed in an Action panel',
			DESC_ITEM_TYPE: 'Actions can have different behaviors. It can be a link to a page, a file or a video',
			DESC_PARAMETER_URL: 'The action target can be a web page, link to a image or a document such as a PDF file',
			DESC_PARAMETER_EMBED_TEMPLATE: 'HTML-template to render for this action. The template can contain placeholders that will be substituted with dynamic data when the action is used from e.g. a Desktop plugin. Example: "{{key}}" will be replaced by "value" if the action is triggered with actionData={key: \'value\'}',

			REMOVE_HEADER: 'Remove action "{{ name }}"',
			REMOVE_MESSAGE: 'Note that any existing references to this action from action panels also will be removed.'
		}
	}
};
