import React from 'react';
import { injectIntl } from 'react-intl';
import createReactClass  from 'create-react-class';

export default injectIntl(createReactClass({
	render () {
		return (
			<div className="lobby-container">
				<div className="left-area">
					<i className="vngage-icon-group"></i>
				</div>
				<div className="content-area">
					<div>
						<h3>{this.props.intl.formatMessage({id: 'conversationConnecting'})}</h3>
						<p>{this.props.intl.formatMessage({id: 'conversationConnectingDescription'})}</p>
						<small>{this.props.intl.formatMessage({id: 'conversationConnectingStatusTitle'})}</small>
						<p className="status-message">{this.props.intl.formatMessage({id: 'conversationConnectingStatusMessage'})}</p>
					</div>
				</div>
			</div>
		)
	}
}));
