import React from 'react';
import {Button} from 'react-bootstrap';
import {useGetTranslation} from './../../LangContext';

const RestrictedOverlay = (props) => {
	const getTranslation = useGetTranslation();
	return (
		<div className="restricted-overlay">
			<div className="container">
				<h1>{getTranslation('overlayRestrictedHeader')}</h1>
				<p>{getTranslation('overlayRestrictedBody1')} <span className="co-status restricted"><i
					className="vngage-icon-block-1"/><span>RESTRICTED</span></span> {getTranslation('overlayRestrictedBody2')}
				</p>
				<p>{getTranslation('overlayDoNotDisturbBody3')}</p>
				<Button onClick={() => props.toggleCoBroPause()}
						className="default-line-btn-contrast">{getTranslation('overlayBtnRestrictedDismiss')}</Button>
			</div>
		</div>
	)
};
export default RestrictedOverlay

