export default {
	setItem(key, value) {
		sessionStorage.setItem(key, value);
	},

	removeItem(key, value) {
		sessionStorage.removeItem(key);
	},

	getItem(key) {
		return sessionStorage.getItem(key);
	},

	hasStorage() {
		let test = 'test9199394';
		try {
			sessionStorage.setItem(test, test);
			sessionStorage.removeItem(test);
			return true;
		} catch (e) {
			return false;
		}
	}
}

