const actionFormats = {

	unitTest: {
		conversationId: 'conversationId',
		msg: 'string'
	},

	pluginReady: {},

};

const actionMap = {
	unitTest: ({conversationId, msg}) => (dispatch) => () => {
		return {
			type: 'TEST'
		};
	},
	pluginReady: ({pluginId}) => (dispatch) => dispatch(PluginActions.pluginReady(pluginId)),
};

export default {
	actionFormats,
	actionMap
}

