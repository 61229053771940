import AppDispatcher from '../dispatcher/AppDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import MessageUtils from '../../../components/utils/MessageUtils';
import StoreFactory from '../../../components/utils/StoreFactory';

const ActionTypes = AppConstants.ActionTypes;

var state = {
	status: ActionTypes.CONNECTION_UP
};

var ConnectionStore = StoreFactory.create({

	/**
	 * return connection state status
	 */
	getStatus () {
		return state.status;
	}

});

const registeredCallback = function(payload) {
	const action = payload.action;

	switch (action.type) {

		case ActionTypes.CONNECTION_UP:
			state.status = ActionTypes.CONNECTION_UP;
			break;

		case ActionTypes.CONNECTION_DOWN:
			state.status = ActionTypes.CONNECTION_DOWN;
			break;
		default :
			return true;
	}

	// if we made it down here, emit change
	ConnectionStore.emitChange();
};

ConnectionStore.dispatchToken = AppDispatcher.register(registeredCallback);

export default ConnectionStore;
