import _find from 'lodash/find';
import WebAPIUtils from '../utils/WebAPIUtils';

export default class VngageVisitor {

	constructor(id) {
		this.id = id;
		this.profile = {claims:[]};
		this.cases = [];
	}

	getCases () {
		return new Promise( (resolve, reject) => {

			if (this.id === '00000000-0000-0000-0000-000000000000') {
				resolve([]);
				return;
			}

			WebAPIUtils.getCases(this.id).then((result) => {
				this.cases = result;
				resolve(this.cases);
			}, function (error) {
				reject(error);
			});
		});
	}

	getMessageOfCase (caseId) {
		return new Promise( (resolve, reject) => {
			var theCase = _find(this.cases, function(caze) {
				return caze.data.id === caseId;
			});

			if (theCase) {
				var caseInfo = {
					participants: theCase.data.conversations[0].events.filter( event => event && event.type === 'participantJoined' ),
					messages: theCase.data.conversations[0].messages
				};
				resolve(caseInfo);
			} else {
				reject('could not find case');
			}
		});
	}

	getNavigation (visitId) {
		return new Promise((resolve, reject) => {

			if (visitId === '00000000-0000-0000-0000-000000000000') {
				resolve([]);
				return;
			}

			WebAPIUtils.getNavigation(visitId).then((result) => {
				resolve(result);
			}, function(error) {
				reject(error);
			});
		});
	}
};
