import assign from 'object-assign';
import _without from 'lodash/without';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _omit from 'lodash/omit';

const sources = {
	USER: 'http://schemas.vergic.com/api/visitorClaimSource/User',
	VISITOR: 'http://schemas.vergic.com/api/visitorClaimSource/Visitor',
	VISITORIDENTIFICATION: 'http://schemas.vergic.com/api/visitorClaimSource/VisitorIdentification'
};

var baseList = [
	{
		type: 'email',
		label: 'Email',
		input: 'email'
	},
	{
		type: 'phone',
		label: 'Phone',
		input: 'text'
	},
	{
		type: 'customerNumber',
		label: 'Customer number',
		input: 'text'
	}
];

function filter(inClaims) {
	var claims = inClaims.slice(0);

	claims = _without(claims, _filter(claims, {source: sources.VISITORIDENTIFICATION}));
	claims = _filter(claims, function(claim) {
		return !!claim.values;
	});

	return _map(claims, function(claim) {
		claim = _omit(claim, 'label', 'input', 'locked');
		claim.source = sources.USER;
		if (claim.values && !Array.isArray(claim.values)) {
			claim.values = claim.values.split(',');
		}
		return claim;
	});
}

function getClaims(inClaims) {
	var list = baseList.slice(0);
	let clist = [];
	_map(list, function(claim) {
		var cIdentified = _find(inClaims, { type: claim.type, source: sources.VISITORIDENTIFICATION }),
			cUser = _find(inClaims, { type: claim.type, source: sources.USER } ),
			cVisitor = _find(inClaims, { type: claim.type, source: sources.VISITOR }),
			inClaim = cIdentified || cUser || cVisitor,
			nClaim = {};

		assign(nClaim, claim, inClaim);
		nClaim.locked = !!(inClaim && inClaim.source === sources.VISITORIDENTIFICATION);
		clist.push(nClaim);
	});
	return clist;
}

export default {
	base : baseList,
	filter: filter,
	getClaims: getClaims,
	sources: sources
};
