import React from 'react';
import {useDropzone} from 'react-dropzone'
import {useGetTranslation} from './../LangContext';

const UploadDropzone = (props) => {
	const getTranslation = useGetTranslation();
	const {getRootProps, getInputProps, isDragActive} = useDropzone({
		onDrop: props.onDrop,
		accept: props.acceptedMimeTypes.toJS(),
		multiple: false,
		disablePreview: true,
	});

	const cls = isDragActive
		? 'dropzone dropzone-active'
		: 'dropzone';


	return (
		<section>
			<div className={cls} {...getRootProps()}>
				<input {...getInputProps()}/>
				<p className="dropzone-content">{getTranslation('dropzoneText')}</p>
			</div>
		</section>
	);
};
export default UploadDropzone

