export default {
    'en-GB': {
        opportunity: {
            EDIT_HEADER: 'Edit opportunity',
            ADD_HEADER: 'New opportunity',
            LIST_HEADER: 'Opportunities',
            LIST_TITLE: 'List of opportunities for working site',
            LIST_DESCRIPTION: 'Opportunities can be set up to track or act on certain actions a visitor takes, i.e. navigating to contact page, loading contact form, having errors in checkout and so on.',
            NAME: 'Name',
            POSITION: 'Priority',
            SAVED_POSITION: 'Saved priority',
            DESCRIPTION: 'Description',
            SCORE: 'Score'
        }
    },
    'sv-SE': {
        opportunity: {
            EDIT_HEADER: 'Edit opportunity',
            ADD_HEADER: 'New opportunity',
            LIST_HEADER: 'Opportunities',
            LIST_TITLE: 'List of opportunities for working site',
            LIST_DESCRIPTION: 'Opportunities can be set up to track or act on certain actions a visitor takes, i.e. navigating to contact page, loading contact form, having errors in checkout and so on.',
            NAME: 'Name',
            POSITION: 'Priority',
            SAVED_POSITION: 'Saved priority',
            DESCRIPTION: 'Description',
            SCORE: 'Score'
        }
    }
};
