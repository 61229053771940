import immutable from 'immutable';

const isArray = (obj) => Object.prototype.toString.call(obj) === '[object Array]';
const getSortedObj = (obj) => Object.keys(obj).sort().reduce((o, key) => ({
	...o,
	[key]: obj[key]
}), {});

const traverse = (obj) => {
	if (isArray()) {
		// is array
		return obj.map(item => traverse(item));
	} else if ((typeof obj === 'object') && (obj !== null)) {
		// is object
		return Object.entries(getSortedObj(obj)).reduce((o, [key, value]) => ({...o, [key]: traverse(value)}), {});
	} else {
		// is something else
		return obj;
	}
};

export const getSortedState = state => traverse(state.toJS());

