import moment from 'moment-timezone';
import ConfigurationSetSaveModalTemplate from './ConfigurationSetSaveModalTemplate';
import ConfigurationSetItemHistoryModalTemplate from './ConfigurationSetItemHistoryModalTemplate';

export default function ($state, SessionService, transitionService, VngageUtil, $uibModal, StateReloadService, NotificationService, ConfigurationSetService, context, activateContext, vngageUserFilter) {
	'ngInject';

	var vm = this;
	vm.configurationSet = context.dependencies.configurationSet;
	vm.revisionComment = context.dependencies.configurationSetResolved.comments;
	vm.revisionUpdatedAt = context.dependencies.configurationSet.updatedAt;
	vm.users = context.dependencies.users;
	vm.byUserNewest = vngageUserFilter(context.dependencies.configurationSet.userId, vm.users);
	vm.byUserLive = vngageUserFilter(activateContext.liveRevisionSet.userId, vm.users);
	vm.openSaveModal = openSaveModal;
	vm.openItemHistoryModal = openItemHistoryModal;
	vm.updatedItem = ConfigurationSetService.updatedItem;
	vm.updatedItemFilter = updatedItemFilter;
	vm.analyzedSet = null;
	vm.liveConfigurationSetIdRevision = context.dependencies.accountConfiguration.data.liveConfigurationSetIdRevision;
	vm.liveRevisionComment = activateContext.liveRevisionSet.data.comments;
	vm.liveRevisionUpdatedAt = activateContext.liveRevisionSet.updatedAt;
	vm.pendingIncludes = pendingIncludes;
	vm.formatWithTZ = formatWithTZ;

	vm.includeUpdateToggleState = false;
	vm.toggleInclude = function toggleInclude(val) {
		var invert = val !== true && val !== false;
		vm.analyzedSet.configurationSetListified.filter(updatedItemFilter).forEach(function (item) {
			item.includeUpdate = invert ? !item.includeUpdate: val;
		});
	};

	function formatWithTZ(date) {
		return moment.utc(date).tz(SessionService.getUserTimezone()).format('dddd MMM D HH:mm - YYYY');
	}

	activate();

	function activate() {

		var references = context.dependencies.configurationSet;
		var resolved = context.dependencies.configurationSetResolved;

		vm.analyzedSet = ConfigurationSetService.analyzeSet(references, resolved, context.configurationDependencies);
	}

	function openSaveModal() {
		$uibModal.open({
			backdrop: 'static',
			templateUrl: ConfigurationSetSaveModalTemplate,
			controller: 'ConfigurationSetSaveModalController as vm',
			resolve: {
				configurationSet: () => {
					return vm.configurationSet;
				},
				analyzedSet: () => {
					return vm.analyzedSet;
				}
			}
		}).result.catch(() => {
			// Modal dismissed...
		});
	}

	function openItemHistoryModal(item) {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: ConfigurationSetItemHistoryModalTemplate,
			controller: 'ConfigurationSetItemHistoryModalController as vm',
			resolve: {
				itemHistory: function (APIEndpointService) {
					// TODO: This needs to request against the correct api (all types are not configurationSections)
					return APIEndpointService.configurationSectionHistory.query({id: item.id}).$promise;
				},
				users: () => {
					return vm.users;
				},
				selectedVersion: () => {
					return item.revisionToSave;
				}
			}
		});
		modal.result.then(selectedVersion => {
			item.revisionToSave = +selectedVersion;
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function updatedItemFilter(item) {
		return ConfigurationSetService.updatedItem(item);
	}

	function pendingIncludes() {
		return vm.analyzedSet.configurationSetListified.filter(item => {
			return (item.includeUpdate === true) && ConfigurationSetService.updatedItem(item);
		}).length === 0;
	}
};
