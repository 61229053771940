import React from 'react';
import {Dropdown} from 'react-bootstrap';

const DotsMenu = ({onSelect, disabled = false, drop = 'down', onToggle = () => 0, iconCls = '', children}) => {
	return (
		<Dropdown id="dropdown-1"
				  className="dots-dropdown"
				  onSelect={onSelect}
				  size="sm"
				  alignRight
				  tabIndex="-1"
				  onToggle={onToggle}
				  drop={drop}>
			<Dropdown.Toggle disabled={disabled} size="sm" id="toggle-1" className={`dots-dropdown-button ${iconCls}`}>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{children}
			</Dropdown.Menu>
		</Dropdown>
	)
};

export default DotsMenu;

