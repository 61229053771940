import React, {useState, useRef, useCallback} from 'react';
import {useGetTranslation} from '../LangContext';
/**
 * We use emoji-mart from https://github.com/h6ak/emoji-mart.git#support_react17
 * since emoji-mart ~2.11.1 used deprecated lifecycle methods
 * if the the original emoji-mart gets updated and fixed, we should use that version instead
 */
import {Picker} from 'emoji-mart';
import {useEventListener} from '../../ReactHooks.js';

// "excludedEmojis" below is a list of emojis to exclude from the emoji-picker.
// I.e. list all "emoji.unified"-codes here that would not render properly with a regular font.
// TODO: Implement a similar exclusion-list in visitor as well!
const excludedEmojis = [
	'263A-FE0F', // "White Smiling Face"/"relaxed": Does not exist in normal font sets, so exclude it from the picker (the emoji would not render properly in the chat)
];

export const EmojiPicker = (props) => {
	const getTranslation = useGetTranslation();
	const [i18n, setI18n] = useState({
		search: getTranslation('emojiSearch'),
		notfound: getTranslation('emojiNotFound'),
		skintext: getTranslation('emojiSkinText'),
		categories: {
			search: getTranslation('emojiSearchResults'),
			recent: getTranslation('emojiCatRecent'),
			people: getTranslation('emojiCatPeople'),
			nature: getTranslation('emojiCatNature'),
			foods: getTranslation('emojiCatFoods'),
			activity: getTranslation('emojiCatActivity'),
			places: getTranslation('emojiCatPlaces'),
			objects: getTranslation('emojiCatObjects'),
			symbols: getTranslation('emojiCatSymbols'),
			flags: getTranslation('emojiCatFlags'),
			custom: getTranslation('emojiCatCustom'),
		}
	});
	const picker = useRef();

	const insertEmoji = (emoji) => {
		props.insertEmoji(emoji.native);
	};

	const {togglePicker} = props;
	const handleClick = useCallback(({target}) => {
		if (picker.current && picker.current.contains(target)) {
			// click inside picker do nothing, Picker.onSelect handles it
		} else {
			togglePicker();
		}
	}, [togglePicker]);
	useEventListener('click', handleClick);
	return (
		<div className="emoji-picker" ref={picker}>
			<Picker
				emojiSize={19}
				perLine={7}
				showPreview={false}
				emojiTooltip={true}
				onSelect={insertEmoji}
				i18n={i18n}
				native={true}
				style={{'width': '269px'}}
				emojisToShowFilter={emoji => excludedEmojis.indexOf(emoji.unified) === -1}
			/>
		</div>
	);
};

export default EmojiPicker;
