import vngageYesNoModalTemplate from './vngageYesNoModalTemplate';

export default ($uibModal) => {
	'ngInject';

	return {
		open: (content, yesHandler) => {
			return $uibModal.open({
				backdrop: 'static',
				templateUrl: vngageYesNoModalTemplate,
				controller: 'vngageYesNoModalController as vm',
				resolve: {
					content: () => content,
					yesHandler: () => yesHandler
					// If a "yesHandler"-function (above) is specified, call it on "yes"-click, and close this modal with its resolved value
					// It should return a promise
					// This makes it possible to do something asynchronous on "yes" and close the modal *AFTER* the task is finished...
				}
			});
		}
	};
};
