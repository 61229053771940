import React from 'react';
import {connect} from 'react-redux';
import DebugPluginButton from './DebugPluginButton.react';
import PluginActions from '../../actions/PluginActions';

export const PluginsTab = (props) => {

	const plugins = props.plugins.entrySeq().map(([index, plugin]) => {
		const payload = {
			plugin,
			index,
			togglePlugin: props.togglePlugin,
			notify: props.notify
		};
		return (<DebugPluginButton key={index} {...payload}/>);
	});

	return (
		<div className="debugger-tool-container">
			<div className="flip-container">
				<label className="flipswitch">
					<span className="badge">plugins enabled</span>
					<input
						type="checkbox"
						className="switch"
						defaultChecked={props.pluginsEnabled}
						onChange={() => props.toggleEnablePlugins()}
					/>
					<span className='switch'/>
				</label>
			</div>

			{plugins}
		</div>
	);

};


function mapStateToProps(state) {
	return {
		plugins: state.get('plugins'),
		pluginsEnabled: state.get('pluginsEnabled')
	};
}

function mapDispatchToProps(dispatch) {
	return {
		togglePlugin: (pluginNr) => dispatch(PluginActions.togglePlugin(pluginNr)),
		toggleEnablePlugins: () => dispatch(PluginActions.toggleEnablePlugins()),
		togglePermission: (name, pluginNr) => dispatch(PluginActions.togglePermission(name, pluginNr)),
		setGeneralPluginData: (generalProps, pluginNr) => dispatch(PluginActions.setGeneralPluginData(generalProps, pluginNr)),
		notify: (pluginId, text, showToast) => dispatch(PluginActions.notify(pluginId, text, showToast))
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PluginsTab);
