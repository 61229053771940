// initialize desktop specific things
import LocalStorage from './stores/LocalStorageWrapper';
import {Store} from './stores/Store';
import ConversationActionCreators from './actions/ConversationActionCreators';
import Session from '../../common/auth/Session';
import DesktopAC from './actionCreators/DesktopAC';
import SessionStorage from './stores/SessionStorageWrapper';
import GroupActions from './actions/GroupActions';
import AccountConfig from './utils/AccountConfig';
import ConversationActions from './actions/ConversationActions';
import AccountConfiguration from './utils/AccountConfigurationWrapper';
import QueueActions from './actions/QueueActions';
import {getPlugins} from './utils/plugin/WorkspaceTools';
import PluginActions from './actions/PluginActions';
import KeyRoutes from './utils/KeyRoutes';
import {endCrashDetector, startCrashDetector} from './debugInfoModal/CrashDetector';
import vngageDebugInfoModalTemplate from './debugInfoModal/vngageDebugInfoModalTemplate.html';
import vngageDebugInfoModalController from './debugInfoModal/vngageDebugInfoModalController';
import ServerRequests from './actionCreators/ServerRequests';
import WebAPIUtils from './utils/WebAPIUtils';

export const initCrashDetector = (vm, $uibModal) => {
	vm.generateDebugInfo = function (startModalState = 'initial') {
		$uibModal.open({
			backdrop: 'static',
			templateUrl: vngageDebugInfoModalTemplate,
			controller: vngageDebugInfoModalController,
			controllerAs: 'vm',
			windowClass: 'crash-report-modal',
			resolve: {
				modalState: () => {
					return startModalState;
				}
			}
		}).result.catch(() => {
			// Modal dismissed...
		});
	};
	startCrashDetector(vm.generateDebugInfo);
};

export const initServerRequests = (vngageConfig, $http, IdentityService) => {
	// ServerRequests use angular $http and initialize poll, but do not start it
	ServerRequests.setRestUrl(vngageConfig.restUrl);
	ServerRequests.setHttp($http);
	WebAPIUtils.init($http, vngageConfig, IdentityService);
};


export const endDesktop=()=>{
	// Enough with the keyboard shortcuts
	KeyRoutes.stop();
	endCrashDetector();
	WebAPIUtils.dispose();
};

// initialize desktop specific things and start
export const startDesktop = (desktopPlugins) => {
	// Read 'vngageLogType' from local Storage -> dispatch action to set in state
	const debugLogType = LocalStorage.getItem('vngageLogType');
	if (debugLogType && debugLogType !== 'undefined') {
		Store.dispatch(ConversationActionCreators.setLogTypeFlag('' + debugLogType));
	}

	// Read 'pauseDebugLogging' from local Storage -> dispatch action to set in state
	const pauseDebugLogging = LocalStorage.getItem('pauseDebugLogging');
	if (pauseDebugLogging && pauseDebugLogging !== 'undefined') {
		Store.dispatch(ConversationActionCreators.setPauseDebugFlag(JSON.parse(pauseDebugLogging)));
	}

	const debugEnabled = LocalStorage.getItem('desktopDeuxDebug') === 'true';
	const pollIsOff = LocalStorage.getItem('shouldPoll') === 'false';
	const myGroupIds = Session.user.groupIds || [];
	const validDesktopUser = (myGroupIds.length > 0);

	if (pollIsOff && debugEnabled) {
		// poll is off and debug is on
		// do nothing
	} else if (!validDesktopUser) {
		Store.dispatch(DesktopAC.initDesktopFailed());
	} else {
		// check that saved data is valid
		let savedGroupIds = SessionStorage.getItem('groupAvailability') || '{}';
		try {
			if (typeof savedGroupIds !== 'string') {
				savedGroupIds = JSON.stringify(savedGroupIds);
			}
			savedGroupIds = JSON.parse(savedGroupIds);

		} catch (e) {
			window.console.error(e);
			savedGroupIds = {};
		}

		Store.dispatch(GroupActions.initGroupAvailability(myGroupIds, savedGroupIds));
		Store.dispatch(GroupActions.setMaxConversationsPerGroup(AccountConfig.getMaxConversationsPerGroup()));
		Store.dispatch(GroupActions.initGroupRouting(AccountConfig.getGroupRouting()));

		Store.dispatch(ConversationActions.setAccount(AccountConfiguration.configuration.account));
		Store.dispatch(ConversationActions.setVisitorSettings(AccountConfiguration.configuration.visitor));
		const accountId = Session && Session.account && Session.account.id ? Session.account.id: '';
		Store.dispatch(ConversationActions.setAccountId(accountId));
		Store.dispatch(ConversationActions.setUserId(AccountConfig.getUserId()));

		// Read from local Storage -> dispatch action to set them in the state
		const userPreferences = LocalStorage.getItem('userPreferences');
		if (userPreferences && userPreferences !== 'undefined') {
			Store.dispatch(QueueActions.setUserPreferences(JSON.parse(userPreferences)));
		}
		if (desktopPlugins) {
			const plugins = getPlugins(desktopPlugins);
			Store.dispatch(PluginActions.addPlugins(plugins));
		}
		Store.dispatch(DesktopAC.initDesktop());
	}

	// Start listening to keyboard shortcuts
	KeyRoutes.watch();
};