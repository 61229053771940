import RoutingTypes from '../constants/RoutingTypes';

const isExternal = (type) => [RoutingTypes.EXTERNAL, RoutingTypes.EXTERNAL_STARTED].includes(type);
const isManual = (type) => [RoutingTypes.MANUAL].includes(type);
const requiresStart = (type) => [RoutingTypes.EXTERNAL, RoutingTypes.AUTO, RoutingTypes.AUTO2].includes(type);

export default {
	isExternal,
	isManual,
	requiresStart
};
