export default function (IdentityService, Session) {
	'ngInject';

	var cache = {};
	var service = {
		authorize: authorize
	};

	return service;

	function tryGetCached(authorization) {

		var key = createCacheKey(authorization);

		if (!cache.hasOwnProperty(key)) {
			return null;
		}
		return cache[key];
	}

	function setCached(authorization, result) {

		var key = createCacheKey(authorization);

		cache[key] = result;
	}

	function createCacheKey(authorization) {
		return angular.isObject(authorization) ? authorization.resource + authorization.action : authorization;
	}

	function authorizeResourceAndAction(authorization) {
		// [TL] This function looks weird, stupid and ineffective...
		// Why not populate the cache while looping Session.authorizations?
		// E.g. by adding inside the forEach()-loop:
		//      setCached(currAuth, true);
		// Or at the very least exit the forEach()-loop early if the requested auth is found (by "return false;")?
		// Don't have time right now to analyze this thoroughly, but this cache looks quite stupid...

		var resourceAllowed = false;
		Session.authorizations.forEach(function (currAuth) {
			var resourceOk = authorization.resource.toLowerCase() === currAuth.resource.toLowerCase();
			var actionOk = currAuth.action.toLowerCase() === authorization.action.toLowerCase();
			if (resourceOk && actionOk) {
				resourceAllowed = true;
			}
		});

		setCached(authorization, resourceAllowed);

		return resourceAllowed;
	}

	function authorize(authorization) {

		if (angular.isUndefined(authorization)) {
			return true;
		}

		if (!IdentityService.authorizationDataLoaded()) {
			return false;
		}

		if (!angular.isObject(authorization)) {
			authorization = {
				resource: 'Workspace',
				action: authorization
			};
		}

		var cached = tryGetCached(authorization);
		if (cached !== null) {
			return cached;
		}

		return authorizeResourceAndAction(authorization);
	}
}
