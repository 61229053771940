import React from 'react';
import {
	Modal,
	Button,
} from 'react-bootstrap';

export const MyDetailsModal = (props) => {
	const {data, moveToAbsolutePosition, setPois} = props;

	const goToPos = (index) => moveToAbsolutePosition(index);

	const pois = data.map(action => {
		return <a
			href="#"
			key={action.get('index')}
			onClick={() => goToPos(action.get('index'))}>
			{action.get('index')}{' '}
		</a>;
	});

	return (
		<Modal className="case-manager-section-modal" show={true}
			   onHide={() => props.hideModal()}>
			<Modal.Header closeButton>
				<Modal.Title>MyDetails analysis</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="case-type-area">
					<div style={{marginBottom: '10px'}}>
						<div style={{display: 'inline'}}>
							{pois}
						</div>
					</div>

				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button
					onClick={() => setPois(data.map(action => action.get('index')))}>save to pois</Button>
			</Modal.Footer>

		</Modal>
	);
};

