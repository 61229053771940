export default function (APIEndpointService, NotificationService, $q) {
	'ngInject';

	function updateStorage(sitesResources, defaultSiteId) {
		const sites = sitesResources.map(s => s.data);
		sessionStorage.setItem('account_sites', JSON.stringify(sites));

		const currentlySelectedSiteId = JSON.parse(sessionStorage.getItem('account_site_selected_id'));
		if (!currentlySelectedSiteId || !sites.find(site => site.id === currentlySelectedSiteId)) {
			// Only set account_site_selected_id if not already set, or if it does not exist in sites
			if (sites.find(site => site.id === defaultSiteId)) {
				sessionStorage.setItem('account_site_selected_id', JSON.stringify(defaultSiteId));
			} else {
				// Fallback to first site in list if defaultSiteId does not exist
				sessionStorage.setItem('account_site_selected_id', JSON.stringify(sites[0].id));
			}
		}
	}

	function getSites() {
		return $q.all({
			sites: APIEndpointService.site.query().$promise,
			configurationSections: APIEndpointService.configurationSection.query({limit: 10000}).$promise
		}).then((res) => {
			if (res.sites.length === 0) {
				NotificationService.warning({
					header: 'No sites in account',
					body: 'You need to create a site for this account'
				}, {
					timeOut: 0
				});
			}

			const visitorSettings = res.configurationSections.find(section => section.data.type === 'visitor');
			let defaultSiteId = null;
			if (visitorSettings && visitorSettings.data && visitorSettings.data.section && res.sites.find(site => site.data.id === visitorSettings.data.section.defaultSiteId)) {
				defaultSiteId = visitorSettings.data.section.defaultSiteId
			}

			updateStorage(res.sites, defaultSiteId);

		}).catch((err) => {
			NotificationService.error({
				header: 'Error fetching site list',
				body: 'There was an error fetching sites'
			});
		});
	}

	function getSelectedSiteId() {
		return JSON.parse(sessionStorage.getItem('account_site_selected_id'));
	}

	return {
		getSites,
		getSelectedSiteId
	};
};
