import uiView from './ui/view';

var actionPanel = function (params) {
	var layout = uiView.createNodes({
		reference: 'wrapper',
		tag: 'div',
		className: 'actionPanelWrapper',
		children: [{
			reference: 'header',
			tag: 'div',
			className: 'actionPanelHeader',
			innerHTML: params.name,
			children: [{
				reference: 'closeButton',
				tag: 'a',
				className: 'actionPanelClose',
				innerHTML: '&times;'//'x',
			}]
		}, {
			reference: 'content',
			tag: 'div',
			className: 'actionPanelContent'
		}]
	});
	var wrapper = layout.wrapper;
	var content = layout.content;
	var closeButton = layout.closeButton;
	var onClose = [];
	var item;

	var close = function () {
		for (var f = 0, len = onClose.length; f < len; f++) {
			try {
				onClose[f]();
			} catch (err) {
				console.log(err);
			}
		}
		wrapper.parentElement.removeChild(wrapper);
	};

	this.onClose = onClose;
	this.close = close;
	this.elem = wrapper;
	closeButton.onclick = close;

	params.section.actions.forEach(function (actionId) {
		item = psPlugin.application.configuration.actions[actionId];
		if (item) {
			item = new ActionItem(item);

			content.appendChild(item.elem);
		}
	});

};
var ActionItem = function (item) {
	//var pItem = psPlugin.application.configuration.desktop.languages[parameters.language].items[parameters.id];

	var iStyle = psPlugin.shell.jsExt.iconMap(item.section.icon);
	var elem = psPlugin.shell.jsExt.createIcon('big', item.name, iStyle.className, iStyle.bgColor, true);
	elem.id = 'draggableItems_' + psPlugin.shell.jsExt.guid();

	//Add meaningful metadata as tooltip for the actionItem
	switch (item.section.itemType) {
		case '0':
			elem.title = 'URL: ' + item.section.parameter;
			break;
		case '3':
			elem.title = 'PDF: ' + item.section.parameter;
			break;
		case '4':
			elem.title = 'Image ' + item.section.parameter;
			break;
		case '2':
			elem.title = 'Video';
			break;
	}

	elem.data = {
		command: helpers.typeOfAction[item.section.itemType],
		params: {
			data: item.section.parameter,
			icon: {
				name: item.name,
				className: iStyle.className,
				bgColor: iStyle.bgColor
			},
			command: 'navigate',
			dropCopy: false
		}
	};

	this.elem = elem;

	psPlugin.core.dragDrop.createDraggable(elem);
};


var helpers = {
	typeOfAction: {
		'0': 'navigate',
		'1': 'executeScript',
		'2': 'showVideoPlayer',
		'3': 'showPDF',
		'4': 'showImage',
		'5': 'shareForm'
	}
};

export default actionPanel;
