import _includes from 'lodash/includes';

// https://gist.github.com/lucassus/9e7bbb89a630f3390b2f
// https://github.com/angular-ui/bootstrap/issues/967

// Adds the method freeze() to $uibModalInstance. Disables closing modal by clicking backdrop
// and by pressing ESC key

export default angular.module('ui.modalFreeze', []).config(function($provide) {
  'ngInject';

  $provide.decorator('$uibModal', function($delegate) {
    'ngInject';

    var open = $delegate.open;

    // decorate newly created modalInstance with some custom methods
    $delegate.open = function() {
      var modalInstance = open.apply(this, arguments);

      modalInstance.freeze = function(freeze) {
        modalInstance._freezed = freeze;
      };

      // return true when the modal instance is freezed and
      // dismiss reason is 'backdrop click' or 'escape key press'
      modalInstance.freezed = function(reason) {
        if (!modalInstance._freezed) { return false; }
        return _includes(['backdrop click', 'escape key press'], reason);
      };

      return modalInstance;
    };

    return $delegate;
  });

  $provide.decorator('$uibModalStack', function($delegate) {
    'ngInject';

    var dismiss = $delegate.dismiss;

    // do nothing when the modal is freezed
    // otherwise fallback to the old behaviour
    $delegate.dismiss = function(modalInstance, reason) {
      if (modalInstance.freezed(reason)) { return; }
      dismiss.apply(this, arguments);
    };

    return $delegate;
  });

});
