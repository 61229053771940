import moment from 'moment-timezone';
import _pull from 'lodash/pull';
import caseBrowserConversationTemplate from './caseBrowserConversationTemplate';
import Format from '../../desktopDeux/utils/Format';

export default function (SessionService, AuthorizationService, vngageMarkdown, $sce, $translate) {
	'ngInject';
	const getIconClass = (mimeType) => {
		const _mimeTypeMapping = {
			image: 'content-image',
			video: 'content-video',
			file: 'content-file'
		};
		const fileType = mimeType ? mimeType.replace(/\/.+/, ''): '';
		return (_mimeTypeMapping[fileType] ? _mimeTypeMapping[fileType]: _mimeTypeMapping['file']);
	};

	const formatWithTZ = (date) => {
		return moment.utc(date).tz(SessionService.getUserTimezone()).format('LTS');
	};

	const hasClaims = (message) => {
		return message.claims && message.claims.length > 0;
	};

	const hasOnlyCommServerClaims = (message) => {
		return hasClaims(message) && Format.getClaimsFromMessage(message).length === 0;
	};

	const isDuplicateClaimMessage = (prevMessage, message) => {
		if (!prevMessage.claims || !message.claims) {
			return false;
		} else {
			return JSON.stringify(prevMessage.claims) === JSON.stringify(message.claims);
		}
	};

	const getMessageClass = (message) => {
		let cls = 'message';
		const agent = message.speaker && !!message.speaker.userId;
		const visitor = message.speaker && !!message.speaker.visitId;

		switch (message.messageType) {
			case 'chat':
				if (agent) {
					cls += ' agent';
				} else if (visitor) {
					cls += ' visitor';
				}
				break;
			case 'form':
				if (visitor) {
					cls += ' visitor form';
				}
				break;
			case 'navigation':
				cls += ' visitor-navigation';
				break;
			case 'note':
				cls += ' note';
				break;
			case 'link':
				cls += ' agent link';
				break;
			case 'visitProfile':
				if (hasOnlyCommServerClaims(message)) {
					cls = ' no-show';
				} else {
					cls += ' visitor visitor-profile';

				}
				break;
			case 'content':
				cls += ' agent link';
				break;
		}
		return cls;
	};


	const parseMessages = (messages) => {
		let prevAuthenticationLevel = 'New';
		return messages
		// remove activity messages
			.reduce((list, message) => {
				if (list.length === 0) {
					return list.concat([message]);
				} else if (message.messageType === 'activity') {
					return list;
				} else {
					return list.concat([message]);
				}
			}, [])
			// remove duplicate claims messages
			.reduce((list, message) => {
				if (list.length === 0) {
					return list.concat([message]);
				} else if (isDuplicateClaimMessage(list[list.length - 1], message)) {
					return list;
				} else {
					return list.concat([message]);
				}
			}, [])
			.map((msg, i) => {

				// create a unique type when messageType is link
				const type = msg.type;
				const messageType = msg.messageType === 'link'
					? msg.messageType + '_' + msg.linkType
					: msg.messageType || 'event';	// If no msg.messageType, this is an event...

				const rawEvent = (messageType === 'event' ? JSON.stringify(msg, null, 2) : null);

				const authenticationLevel = msg.authenticationLevel || prevAuthenticationLevel;

				const speaker = msg.speaker;
				const isAgent = !!msg.speaker && !!msg.speaker.userId;
				const isVisitor = !!msg.speaker && !!msg.speaker.visitId;
				const hasSpeaker = isAgent || isVisitor;

				const title = msg.title || '';

				// map message text to mmd for agent and plain text for visitor
				const parsedMessage = isVisitor
					? msg.message // visitor
					: $sce.trustAsHtml(vngageMarkdown(msg.message)); // agent

				// For visitor messages:
				// Split the message into lines to be able to render it as it's done in Desktop chat.
				// (put each line in a <span> with an added <br> in the template, and DON'T bind the content of each line as html!)
				const parsedVisitorMessageLines = isVisitor && typeof parsedMessage === 'string' ? parsedMessage.split('\n') : null;

				const isContentUpload = msg.uploadInfo && msg.uploadInfo.mimeType;
				const filename = isContentUpload ? msg.uploadInfo.filename: '';

				// map icon classes
				const icon = isContentUpload
					? getIconClass(msg.uploadInfo.mimeType) // content upload specific icon
					: msg.linkType || '';

				const isIdentifiedStyle = authenticationLevel === 'Identified' ? ' identified': '';
				const cls = getMessageClass(msg) + isIdentifiedStyle;

				const createdAt = msg.createdAt;

				const timestamp = formatWithTZ(createdAt);
				const claims = Format.getClaimsFromMessage(msg);
				const claimsSource = Format.getMessageClaimsSource(msg);
				const url = msg.url || '';
				const form = msg.form || [];
				const photoIsMax = false;

				prevAuthenticationLevel = authenticationLevel;

				return {
					type,
					rawEvent,
					messageType,
					authenticationLevel,
					speaker,
					hasSpeaker,
					isAgent,
					title,
					parsedMessage,
					parsedVisitorMessageLines,
					icon,
					cls,
					createdAt,
					timestamp,
					claims,
					claimsSource,
					url,
					form,
					photoIsMax,
					filename,
				};

			})
			// insert extra messages when identification has changed
			.reduce((list, message) => {
				const prevMessage = list.length > 0 ? list[list.length - 1]: {...message, authenticationLevel: 'New'};
				const extraMsg = {
					messageType: '',
					authenticationLevel: message.authenticationLevel,
					speaker: {},
					hasSpeaker: false,
					isAgent: true,
					title: '',
					parsedMessage: '',
					icon: '',
					cls: 'message identified-status',
					createdAt: message.createdAt,
					timestamp: message.timestamp,
					claims: [],
					claimsSource: '',
					url: '',
					form: [],
					photoIsMax: false,
					filename: '',
				};

				if (prevMessage.authenticationLevel === 'Identified' && message.authenticationLevel !== 'Identified') {
					// not_identified
					extraMsg.messageType = 'not_identified';
					return list.concat([extraMsg, message]);
				} else if (prevMessage.authenticationLevel !== 'Identified' && message.authenticationLevel === 'Identified') {
					// identified
					extraMsg.messageType = 'identified';
					return list.concat([extraMsg, message]);
				} else {
					// no change in identification
					return list.concat([message]);
				}
			}, [])
			// insert day breaks
			.reduce((list, message) => {

				const currentDate = new Date(message.createdAt);
				const prevDate = list.length === 0
					? new Date(0)
					: new Date(list[list.length - 1].createdAt);
				const newWeekDay = currentDate.getDay() !== prevDate.getDay();
				const moreThanOneDay = (currentDate.getTime() - prevDate.getTime()) > 24 * 60 * 60 * 1000;

				if (newWeekDay || moreThanOneDay) {
					// day break
					const timestamp = moment.utc(message.createdAt).tz(SessionService.getUserTimezone()).format('MMMM D - YYYY'); // TODO: Localize more?
					const extraMsg = {
						messageType: 'dayBreak',
						authenticationLevel: message.authenticationLevel,
						speaker: {},
						hasSpeaker: false,
						isAgent: true,
						title: '',
						parsedMessage: '',
						icon: '',
						cls: 'message day-break',
						createdAt: message.createdAt,
						timestamp,
						claims: [],
						claimsSource: '',
						url: '',
						form: [],
						photoIsMax: false,
						filename: '',
					};
					return list.concat([extraMsg, message]);

				} else {
					return list.concat([message]);
				}
			}, []);
	};

	return {
		restrict: 'E',
		scope: {
			conversation: '=',
			participants: '=',
			advanced: '=',
			showEvents: '='
		},
		templateUrl: caseBrowserConversationTemplate,
		link: function (scope) {

			//occasionally we get null instead an array of messages
			_pull(scope.conversation.messages, null);

			scope.messages = scope.conversation.messages;
			if (AuthorizationService.authorize('CaseBrowser/Support')) {
				// Also include events if user is authorized to display them...
				_pull(scope.conversation.events, null);

				// conversationMessages are also present in the "events"-array since VEP-3901
				// ...but don't use them because of VEP-3965
				scope.messages = scope.messages.concat(scope.conversation.events.filter(event => event.type !== 'conversationMessage'));
			}
			scope.messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
			scope.messages = parseMessages(scope.messages);

			scope.$watch('advanced', function (advanced) {
				advancedTags.forEach(function (tag) {
					scope.allowedTags[tag] = advanced;
				});
			});

			scope.$watch('showEvents', function (showEvents) {
				eventsTags.forEach(function (tag) {
					scope.allowedTags[tag] = showEvents;
				});
			});

			// booleans since they can be disabled
			scope.allowedTags = {
				'event': false,
				'chat': true,
				'note': true,
				'form': true,
				'navigation': false,
				'activity': false,
				'askForDomUpload': false,
				'domUpload': false,
				'video': false,
				'visitProfile': true,
				'link': true,
				'content': true,
				link_video: true,
				link_pdf: true,
				link_link: true,
				link_photo: true,
				link_render: true,
				not_identified: true,
				identified: true,
				dayBreak: true,
			};

			var advancedTags = [
				'navigation'
			];

			var eventsTags = [
				'event'
			];

			scope.togglePhotoMax = function (message) {
				message.photoIsMax = !message.photoIsMax;
			};

			scope.getVideoHTML = function (str) {
				return $sce.trustAsHtml(str);
			};

			scope.toggleDisplayRawEvent = function (message) {
				message.displayRawEvent = !message.displayRawEvent;
			};
		}
	};
}
