import AppConstants from '../../../components/constants/AppConstants';

const ActionTypes = AppConstants.ActionTypes;

export default {

	setMaxConversationsPerGroup(maxConversationsPerGroup) {
		return {
			type: ActionTypes.SET_MAX_CONVERSATIONS_PER_GROUP,
			maxConversationsPerGroup
		};
	},

	setGroupAvailability(groupChanges) {
		return {
			type: ActionTypes.SET_GROUP_AVAILABILITY,
			groupChanges
		};
	},

	initGroupAvailability(groupIds, savedGroupIds) {
		return {
			type: ActionTypes.INIT_GROUP_AVAILABILITY,
			groupIds,
			savedGroupIds
		};
	},

	receiveGroupStatus(data) {
		return {
			type: ActionTypes.RECEIVE_GROUP_STATUS,
			data
		};
	},

	setPresence(presence) {
		return {
			type: ActionTypes.SET_PRESENCE,
			presence
		};
	},

	resetForceAway() {
		return {
			type: ActionTypes.RESET_FORCE_AWAY
		}
	},

	initGroupRouting(groupRouting) {
		return {
			type: ActionTypes.INIT_GROUP_ROUTING,
			groupRouting
		};
	},


};

