import React from 'react';
import createReactClass from 'create-react-class';

export default createReactClass({

	render () {
		return (
			<div className="dialog-container">
				{this.props.children}
			</div>
		);
	}

});
