import './configurationSet/ConfigurationSetModule';
import './history/ConfigurationSetHistoryModule';
import './publish/PublishConfigurationModule';
import './repair/ConfigurationSetRepairModule';
import ConfigurationSetService from './ConfigurationSetService';
import publishTranslations from './PublishTranslations';
import PublishContentTemplate from './PublishContentTemplate';
import PublishMenuTemplate from './PublishMenuTemplate';
import '../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.publish', [
	'ui.router',
	'vngageApp.publish.configurationSet',
	'vngageApp.publish.history',
	'vngageApp.publish.publish',
	'vngageApp.publish.repair',
	'vngageApp.workspaceTranslation'
])

	.factory('ConfigurationSetService', ConfigurationSetService)
	.constant('publishTranslations', publishTranslations)

	.constant('publishModuleConfig', {
		state: 'root.publish',
		access: 'Account'
	})

	.config(function ($stateProvider, $urlRouterProvider, $translateProvider, publishTranslations, publishModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(publishTranslations);

		$urlRouterProvider.when('/publish/', function (transitionService, configurationSetModuleConfig, publishConfigurationModuleConfig) {
			'ngInject';

			transitionService.navigate([
				configurationSetModuleConfig,
				publishConfigurationModuleConfig
			]);
		});

		$stateProvider.state(publishModuleConfig.state, {
			url: '/publish/',
			views: {
				content: {
					templateUrl: PublishContentTemplate
				},
				menu: {
					templateUrl: PublishMenuTemplate
				}
			},
			data: {
				access: publishModuleConfig.access,
				ncyBreadcrumbLabel: 'Publish'
			}
		});
	});
