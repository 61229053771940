export default {
	getBuiltChannel() {

		return {
			bind() {
			},
			unbind() {
			},
			call() {
			},
			notify() {
			},
			destroy() {
			},
		}

	}

}


