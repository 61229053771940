import Conversation from '../actionCreators/Conversation';
import PollRequest from './PollRequest';

export default class ConversationSinceRequest extends PollRequest {
	constructor(reqGuid, restUrl, conversationId, since = 0) {
		super(reqGuid, restUrl, conversationId);
		this.conversationId = conversationId;
		this.since = since;
	}

	get asRequest() {
		return {
			body: {},
			method: 'get',
			tag: this.guid,
			uri: `${this.restUrl}Conversation/${this.conversationId}/Since?timestamp=${this.since}`
		}
	}

	resolve(response, statusCode) {
		return new Promise((resolve, reject) => {
			if (statusCode >= 400 && statusCode < 600) {
				console.log('<Since ERROR> ', response, statusCode);
				reject(response);
			} else {
				if (response && response.length > 0) {
					resolve(Conversation.receiveSince(response, this.conversationId));
				} else {
					resolve(null);
				}

			}

		});

	}
}
