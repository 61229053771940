import immutable from 'immutable';

function hasPlugins(state) {
	return state.get('plugins').size > 0;
}

function addMessages(state, conversationId, messages) {
	state = state.updateIn(['pluginData', 'messagesById', conversationId], list => list
		? list.concat(immutable.fromJS(messages))
		: immutable.fromJS(messages));
	return state;
}

function removeOldMessages(state) {
	const panelOrder = state.get('panelOrder');

	state = state.updateIn(['pluginData', 'messagesById'],
		msgObj => msgObj
			.filter((value, key) => panelOrder.contains(key)));

	return state;
}

function receiveAsState(state, conversationId, messages) {
	if (state.get('plugins') && state.get('plugins').size === 0) {
		return state;
	}

	state = state.setIn(['pluginData', 'messagesById', conversationId], immutable.List());
	state = removeOldMessages(state);
	state = addMessages(state, conversationId, messages);
	return state;
}

function receiveSince(state, conversationId, messages) {
	if (state.get('plugins') && state.get('plugins').size === 0) {
		return state;
	}
	state = state.setIn(['pluginData', 'conversationId'], conversationId);
	state = removeOldMessages(state);
	state = addMessages(state, conversationId, messages);
	return state;
}

function visitorProfileAdd(state, profile) {
	const visitId = profile.data.id || '';
	const conversationId = state.get('conversations').findKey((conversation, key) => conversation.get('visitId') === visitId) || '';
	state = state.setIn(['pluginData', 'lastUpdatedProfile', 'conversationId'], conversationId);
	state = state.setIn(['pluginData', 'lastUpdatedProfile', 'visitId'], visitId);
	state = state.setIn(['pluginData', 'lastUpdatedProfile', 'type'], 'add');

	return state;
}

function pluginButtonClicked(state, conversationId, pluginId) {
	state = state.setIn(['pluginData', 'pluginButtonData', 'conversationId'], conversationId);
	state = state.setIn(['pluginData', 'pluginButtonData', 'pluginId'], pluginId);
	state = state.updateIn(['pluginData', 'pluginButtonData', 'clickCounter'], n => n ? n + 1: 1);
	return state;
}

function setSectionButtonState({conversationId, pluginId, buttonState}, state) {
	return state.get('conversations').has(conversationId)
		? state.setIn(['conversations', conversationId, 'UI', 'pluginButtons', pluginId], buttonState)
		: state;
}

function addPlugins(state, plugins) {
	// test plugins against available groups
	const validPlugins = plugins.filter(plugin => plugin.enabledGroups.some(groupId => state.get('myGroupAvailability').has(groupId)));
	state = state.set('plugins', immutable.fromJS(validPlugins));
	return state;
}

function pluginReady({pluginId}, state) {
	const pluginIndex = state.get('plugins').findIndex(plugin => plugin.get('id') === pluginId);
	return pluginIndex !== -1
		? state.updateIn(['plugins', pluginIndex, 'refreshCounter'], n => n + 1)
		: state;

}

function notify({pluginId, text, showToast}, state) {
	const pluginIndex = state.getIn(['plugins']).findIndex(plugin => plugin.get('id') === pluginId);
	return pluginIndex >= 0
		? state.setIn(['plugins', pluginIndex, 'notification'], immutable.Map({text, showToast}))
		: state;
}

function processConversationDetails(action, state) {
	return state.updateIn(['pluginData', 'myDetailsCounter'], n => n + 1);
}

function setSelectedPlugin({pluginId}, state) {
	// reset notification if plugin is selected
	const pluginIndex = state.getIn(['plugins']).findIndex(plugin => plugin.get('id') === pluginId);
	state = pluginIndex >= 0
		? state.deleteIn(['plugins', pluginIndex, 'notification'])
		: state;

	state = state.set('selectedPlugin', pluginId);
	return state;
}


export const pluginLib = {
	hasPlugins,
	addMessages,
	removeOldMessages,
	receiveAsState,
	receiveSince,
	visitorProfileAdd,
	pluginButtonClicked,
	setSectionButtonState,
	addPlugins,
	pluginReady,
	notify,
	processConversationDetails,
	setSelectedPlugin,
};