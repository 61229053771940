import React from 'react';
import MessageChat from './MessageChat.react';
import MessageNavigation from './MessageNavigation.react';
import MessageNote from './MessageNote.react';
import MessageLink from './MessageLink.react';
import MessageMeta from './MessageMeta.react';
import createReactClass  from 'create-react-class';

export default createReactClass({

	render () {
		let message = this.props.message;
		let speaker = message.speaker;

		if (!speaker) {
			// do nothing;
			return false;
		}

		let speakerType = speaker.info.titleId;
		let cls;
		let messageItem;

		switch (this.props.messageType) {
			case 'chat' :
				messageItem = <MessageChat
					relative={message.isMostRecentMessage}
					isMostRecentMessage={message.isMostRecentMessage}
					speaker={speaker.info}
					message={message}
					key={message.id}/>;
				cls = 'message-' + speakerType;
				break;
			case 'navigation' :
				messageItem = <MessageNavigation
					speaker={speaker.info}
					message={message}
					key={message.id}/>;
				cls = 'navigation-' + speakerType;
				break;
			case 'note' :
				messageItem = <MessageNote
					speaker={speaker.info}
					message={message}
					key={message.id || Date.now()}/>;
				cls = 'message-' + speakerType;
				break;
			case 'link' :
				messageItem = <MessageLink
					relative={message.isMostRecentMessage}
					isMostRecentMessage={message.isMostRecentMessage}
					speaker={speaker.info}
					message={message}
					key={message.id}/>;
				cls = 'message-' + speakerType;
				break;
			case 'meta' :
				messageItem = <MessageMeta
					speaker={speaker.info}
					message={message}
					key={message.id  || Date.now()}/>;
				cls = 'meta-' + speakerType;
				break;
		}



		return (
			<li className={cls}
				key={this.props.key}>
				{messageItem}
			</li>
		);
	}
});
