import ClosureAddModalController from './ClosureAddModalController';
import ImportModalController from '../../../common/vngageImportModal/ImportModalController';
import ClosureEditController from './ClosureEditController';
import ClosureListController from './ClosureListController';
import closureTranslations from './ClosureTranslations';
import ClosureListTemplate from './ClosureListTemplate';
import ClosureEditTemplate from './ClosureEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.configure.closure', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('ClosureAddModalController', ClosureAddModalController)
	.controller('ImportModalController', ImportModalController)
	.controller('ClosureEditController', ClosureEditController)
	.controller('ClosureListController', ClosureListController)
	.constant('closureTranslations', closureTranslations)

	.constant('closureModuleConfig', {
		state: 'root.configure.closure',
		access: 'Engage/Closures'
	})

	.config(function ($stateProvider, $translateProvider, closureTranslations, closureModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(closureTranslations);

		$stateProvider.state(closureModuleConfig.state, {
			url: 'closure/',
			controller: 'ClosureListController as vm',
			templateUrl: ClosureListTemplate,
			data: {
				access: closureModuleConfig.access,
				ncyBreadcrumbLabel: 'Closures'
			},
			resolve: {
				dataLists: function (APIConfigurationSectionService, $q) {
					'ngInject';
					return $q((resolve, reject) => {
						$q.all({
							configSections: APIConfigurationSectionService.get([
								APIConfigurationSectionService.sectionMap.closure,
								APIConfigurationSectionService.sectionMap.customerCase
							], true)
						}).then((result) => {
							resolve({
								closure: result.configSections[APIConfigurationSectionService.sectionMap.closure],
								customerCase: result.configSections[APIConfigurationSectionService.sectionMap.customerCase]
							});
						}, (error) => {
							console.log('Error resolving lists: ', error);
							reject(error);
						});
					});
				}
			}
		});

		$stateProvider.state('root.configure.closure.edit', {
			url: ':id',
			controller: 'ClosureEditController as vm',
			templateUrl: ClosureEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				closure: function ($stateParams, dataLists, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(dataLists.closure, $stateParams.id);
				}
			}
		});
	});
