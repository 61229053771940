export default function () {
	'ngInject';

	const service = {
		getConditionEmptyTemplate: getConditionEmptyTemplate,
		getConditionOperators: getConditionOperators,
		getConditionValueTemplates: getConditionValueTemplates,
		initializeCondition: initializeCondition
	};

	function getConditionEmptyTemplate(firstLevel) {
		const conditionDefault = {
			name: '',
			score: '',
			condition: {
				expression: {
					not: false,
					field: {
						type: 'navigation',   // POI Type, default = 'navigation'
						value: ''   // POI
					},
					operator: {
						type: '',
						value: ''   // Operator
					},
					value: {
						type: '',
						value: ''   // Value
					}
				}
			}
		};
		const template = angular.copy(conditionDefault);
		if (firstLevel) {
			angular.extend(template.condition, {conditions: []});
		}
		return template;
	}

	function getConditionOperators() {
		const conditionOperators = [
			{
				value: 'or',
				name: 'Or'
			}, {
				value: 'and',
				name: 'And'
			}
		];
		return angular.copy(conditionOperators);
	}

	function getConditionValueTemplates(poiList) {
		const templates = {
			// POI TYPE
			// --------
			pointOfInterestTypeValues: [
				// wrapped in data for easy concatenation with pointOfInterestList, uses id since it maps to poi model
				{
					data: {
						id: '',
						name: 'None'
					}
				}, {
					data: {
						id: 'navigation',
						name: 'Navigation'
					}
				}, {
					data: {
						id: 'visitor',
						name: 'Visitor'
					}
				}
			].concat(poiList),

			// POI: NAVIGATION
			// ---------------
			navigationPointOfInterestValues: [
				{
					value: 'currentPage',
					name: 'Current Page'
				}, {
					value: 'historyPage1',
					name: 'History Page 1'
				}, {
					value: 'historyPage2',
					name: 'History Page 2'
				}, {
					value: 'historyPage3',
					name: 'History Page 3'
				}, {
					value: 'historyPage4',
					name: 'History Page 4'
				}
			],

			navigationOperatorValues: [
				{
					operator: {
						type: 'string',
						value: 'equal'
					},
					name: 'Equal'
				}, {
					operator: {
						type: 'string',
						value: 'notEqual'
					},
					name: 'Not equal'
				}, {
					operator: {
						type: 'string',
						value: 'contains'
					},
					name: 'Contains'
				}, {
					operator: {
						type: 'string',
						value: 'notContain'
					},
					name: 'Does Not Contain'
				}
			],

			navigationOperatorValueValues: [
				{
					value: {
						type: 'static',
						value: ''
					}
				}
			],

			// POI: VISITOR
			// ------------
			visitorPointOfInterestValues: [
				{
					value: 'score',
					name: 'Score'
				}, {
					value: 'isProactive',
					name: 'Is Proactive'
				}
			],

			visitorScoreOperatorValues: [
				{
					operator: {
						type: 'number',
						value: 'equal'
					},
					name: 'Equal'
				}, {
					operator: {
						type: 'number',
						value: 'notEqual'
					},
					name: 'Not Equal'
				}, {
					operator: {
						type: 'number',
						value: 'greater'
					},
					name: 'Greater Than'
				}, {
					operator: {
						type: 'number',
						value: 'less'
					},
					name: 'Less Than'
				}
			],

			visitorScoreOperatorValueValues: [
				{
					value: {
						type: 'static',
						value: ''
					}
				}
			],

			visitorIsProactiveOperatorValues: [
				{
					operator: {
						type: 'boolean',
						value: 'equal'
					},
					name: 'Equal'
				}, {
					operator: {
						type: 'boolean',
						value: 'notEqual'
					},
					name: 'Not Equal'
				}
			],

			visitorIsProactiveOperatorValueValues: [
				{
					value: {
						type: 'static',
						value: 'true'
					},
					name: 'True'
				}, {
					value: {
						type: 'static',
						value: 'false'
					},
					name: 'False'
				}
			],

			// POI: CUSTOM
			// -----------
			customPointOfInterestValues: [
				{
					value: 'total',
					name: 'Total'
				}, {
					value: 'consecutive',
					name: 'Consecutive'
				}, {
					value: 'consecutiveAway',
					name: 'Consecutive Away'
				}, {
					value: 'matched',
					name: 'Matched'
				}
			],

			customDefaultOperatorValues: [
				{
					operator: {
						type: 'number',
						value: 'equal'
					},
					name: 'Equal'
				}, {
					operator: {
						type: 'number',
						value: 'notEqual'
					},
					name: 'Not Equal'
				}, {
					operator: {
						type: 'number',
						value: 'greater'
					},
					name: 'Greater Than'
				}, {
					operator: {
						type: 'number',
						value: 'less'
					},
					name: 'Less Than'
				}
			],

			customDefaultOperatorValueValues: [
				{
					value: {
						type: 'static',
						value: ''
					}
				}
			],

			customMatchedOperatorValues: [
				{
					operator: {
						type: 'boolean',
						value: 'equal'
					},
					name: 'Equal'
				}, {
					operator: {
						type: 'boolean',
						value: 'notEqual'
					},
					name: 'Not Equal'
				}
			],

			customMatchedOperatorValueValues: [
				{
					value: {
						type: 'static',
						value: 'true'
					},
					name: 'True'
				}, {
					value: {
						type: 'static',
						value: 'false'
					},
					name: 'False'
				}
			]
		};

		return angular.copy(templates);
	}


	// when loading a saved object for the value of the <select> options, the comparison by reference will not find it in the
	// <option> list, find the corresponding object in the options list and use that instead.
	// Feels like a hack...
	function modelInit(savedOptionValue, optionsList) {
		let foundOption;

		if (optionsList[0].operator) {
			// Init Operator
			foundOption = optionsList.filter(function (option) {
				return option.operator.value === savedOptionValue;
			});
			return foundOption[0] ? foundOption[0].operator : null;
		} else if (optionsList[0].value.value) {
			// Init Value
			foundOption = optionsList.filter(function (option) {
				return option.value.value === savedOptionValue;
			});
			return foundOption[0] ? foundOption[0].value : null;
		} else {
			// Init POI
			foundOption = optionsList.filter(function (option) {
				return option.value === savedOptionValue;
			});
			return foundOption[0];
		}
	}

	// Initializes all model values
	function initializeCondition(condition, tpl) {
		condition.expression.field.type = condition.expression.field.type || tpl.pointOfInterestTypeValues[0].data.id;
		let existing;
		if (condition.expression.field.type === 'navigation') {
			existing = modelInit(condition.expression.field.value, tpl.navigationPointOfInterestValues);
			condition.expression.field.value = (existing ? existing.value : null) || tpl.navigationPointOfInterestValues[0].value;
			existing = modelInit(condition.expression.operator.value, tpl.navigationOperatorValues);
			condition.expression.operator = existing || tpl.navigationOperatorValues[0].operator;
			condition.expression.value = condition.expression.value.type ? condition.expression.value : tpl.navigationOperatorValueValues[0].value;
		} else if (condition.expression.field.type === 'visitor') {
			existing = modelInit(condition.expression.field.value, tpl.visitorPointOfInterestValues);
			condition.expression.field.value = (existing ? existing.value : null) || tpl.visitorPointOfInterestValues[0].value;

			if (condition.expression.field.value === 'score') {
				existing = modelInit(condition.expression.operator.value, tpl.visitorScoreOperatorValues);
				condition.expression.operator = existing || tpl.visitorScoreOperatorValues[0].operator;
				condition.expression.value = condition.expression.value.type ? condition.expression.value : tpl.visitorScoreOperatorValueValues[0].value;
			} else if (condition.expression.field.value === 'isProactive') {
				existing = modelInit(condition.expression.operator.value, tpl.visitorIsProactiveOperatorValues);
				condition.expression.operator = existing || tpl.visitorIsProactiveOperatorValues[0].operator;
				existing = modelInit(condition.expression.value.value, tpl.visitorIsProactiveOperatorValueValues);
				condition.expression.value = existing || tpl.visitorIsProactiveOperatorValueValues[0].value;
			}
		} else {
			existing = modelInit(condition.expression.field.value, tpl.customPointOfInterestValues);
			condition.expression.field.value = (existing ? existing.value : null) || tpl.customPointOfInterestValues[0].value;
			if (['total', 'consecutive', 'consecutiveAway'].indexOf(condition.expression.field.value) > -1) {
				existing = modelInit(condition.expression.operator.value, tpl.customDefaultOperatorValues);
				condition.expression.operator = existing || tpl.customDefaultOperatorValues[0].operator;
				condition.expression.value = condition.expression.value.type ? condition.expression.value : tpl.customDefaultOperatorValueValues[0].value;
			} else if (condition.expression.field.value === 'matched') {
				existing = modelInit(condition.expression.operator.value, tpl.customMatchedOperatorValues);
				condition.expression.operator = existing || tpl.customMatchedOperatorValues[0].operator;
				existing = modelInit(condition.expression.value.value, tpl.customMatchedOperatorValueValues);
				condition.expression.value = existing || tpl.customMatchedOperatorValueValues[0].value;
			}
		}
		return condition;
	}

	return service;
};
