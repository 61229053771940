
var lang = {},
	defaultLanguage = 'en-GB',
	currentLanguage = defaultLanguage;

lang['da-DK'] = {
	/*
	 * -----------------------------------------------------------
	 * Menu
	 * -----------------------------------------------------------
	 */
	menuCodeStarter: 'Phone to Web',
	menuCodeStarterLinkVisitorByCode: 'Link til besøgere via kode',
	menuCodeStarterGernerateCode: 'Generere kode',

	meetingCodeGenerate: 'Generere mødeskode',
	/*
	 * -----------------------------------------------------------
	 * CoBrowser
	 * -----------------------------------------------------------
	 */
	authorizeGuestCoBrowsingBtn: 'Enable Guest Cobrowsing',
	authorizeGuestCoBrowsingBtnActive : 'Disable Guest Cobrowsing',
	authorizeGuestCoBrowsingBtnTitle: 'Toggle Guest Cobrowsing',
	coBrowserTopMenuButtonHome: 'Hjem',
	coBrowserTopMenuButtonHomeTitle: 'Vil omdirigere til udgangspunktet for visual præsentation',
	coBrowserTopMenuButtonDeActivate: 'Sluk for visual præsentation',
	coBrowserTopMenuButtonDeActivateTitle: 'Stop synkronisering af besøgende',
	coBrowserTopMenuButtonActivate: 'Start visual præsentation', //og send denne side',
	coBrowserTopMenuButtonActivateTitle: 'Start synkronisering af besøgende',
	coBrowserTopMenuButtonGetLatestPage: 'Hent besøgerens nuværende side',
	coBrowserTopMenuButtonGetLatestPageTitle: 'Gå til seneste kendte side for besøgende',

	coBrowserTopMenuButtonGetVisitorPageWithFormValues: 'Hent besøgerens formular værdier',
	coBrowserTopMenuButtonGetVisitorPageWithFormValuesTitle: 'Hent besøgendes nuværende side, inklusiv form felter',

	coBrowserRetrieveFormValues: 'Henter formular værdier...',
	coBrowserLockScreenNavigationMessage: 'Besøgeren navigerer til ',
	coBrowserHighLightButton: 'Fremhæv',
	coBrowserHighLightButtonTitle: 'Giver mulighed for, visuelt, at præsentere felter for besøgende',

	coBrowserHighLightTextLabel: 'Klikke for at fremhæve feltet for besøgeren',
	coBrowserScaleLabel: 'Scale',
	coBrowserScaleLabelTitle: 'Hvis aktiv, skaleres vinduet til besøgendes skærm størrelse',

	coBrowserNavigatingTo: 'Navigerer til: ',
	coBrowserTimeoutText: 'Det ser ud til at alt ikke er som det skal være, prøv at: ',
	coBrowserVisitorIsAt: 'Besøgeren er på ',
	coBrowserNoticeDeactivated: 'Visual præsentation er deaktiveret.',
	coBrowserLoading: 'Henter...',

	coBrowserStatusActiveShortDesc: 'Tovejs visuel præsentation er nu aktivt',
	coBrowserStatusPausedShortDesc: 'Du har stoppet visuel præsentation',
	coBrowserStatusPassiveShortDesc: 'Visuelt præsentation er i passiv tilstand. Du kan sende links som beskeder ved at trykke på "Deaktiver" og gå til den ønskede side hvor efter der kan trykkes på "send link"',
	coBrowserStatusProgressShortDesc: 'Vent venligst ...',

	/*
	 * -----------------------------------------------------------
	 * Invites
	 * -----------------------------------------------------------
	 */

	vgInviteError : 'Error',
	vgInviteErrorMessage : 'Failed to create invitation code',
	vgInviteTitle: 'Invite a guest to this dialog',
	vgInviteDescription: 'Copy and share the link with the quest shall join this dialog.',
	vgInviteClose: 'Close',

	/*
	 * -----------------------------------------------------------
	 * CoWorker
	 * -----------------------------------------------------------
	 */
	coWorkerTitleStateLocked: 'Låst',
	coWorkerTitleStatePendingApproval: 'Afventer accept',
	coWorkerTitleStateTimedOut: 'Ubesvaret',
	coWorkerTitleStateDeclined: 'Afvist',
	coWorkerTitleStateEnded: 'Afsluttet dialog',
	coWorkerTitleStateConnectionDead: 'Afsluttet dialog',
	coWorkerTitleStateConnectionLost: '',

	coWorkedBlockConfirmHeading: 'Er du sikker på at du vil blokere for denne person?',
	coWorkerBlockConfirmCancel: 'Fortryd',
	coWorkerBlockConfirm: 'Blokere',
	coWorkerBlockBlocked: '*Blokerede!!!*',

	coWorkerReleaseButton: 'Frigiv',
	coWorkerReleaseButtonConfirmed: '*Frigiv dialogen tilbage til køen*',

	coWorkerSendPageToVisitor: 'Send link',
	coWorkerSendPageToVisitorTitle: 'Send nuværende side som et link til besøgeren. Hvis tovejs visuel præsentation er aktivt vil besøgeren også blive navigeret til siden.',
	coWorkerSendPageToVisitorLinkLabel: 'Angiv navn på linket',
	coWorkerSendPageToVisitorLinkTitle: 'Klik her',
	coWorkerSendPageToVisitorButtonCancel: 'Fortryd',
	coWorkerSendPageToVisitorButtonSend: 'Send',



	coWorkerMessagesConnectionDeadHeading: 'Besøgeren har enten forladt websitet, lukket browseren, eller mistet forbindelsen til internet.<br>Dialogen er afsluttet',
	coWorkerMessagesConnectionDeadButtonContinue: 'Forsæt arbejdet',
	coWorkerMessagesConnectionDeadButtonClose: 'Afslut sag',

	coWorkerMessagesConnectionLostHeading: 'Besøgeren har enten forladt websitet, lukket browseren, eller mistet forbindelsen til internet.<br>Dialogen vil blive afsluttet hvis besøgeren ikke vender tilbage indenfor 30s',
	coWorkerMessagesConnectionLostButtonContinue: 'Fortsæt arbejdet',

	coWorkerMessagesTimedOutHeading: 'Besøgeren har ikke besvaret invitationen',
	coWorkerMessagesDeclinedHeading: 'Besøgeren har afvist dialogen',
	coWorkerMessagesDeclinedButtonClose: 'Afslut sag',

	//coWorkerMessagesEndedHeading: 'Besøgeren har afsluttet dialogen',
	coWorkerMessagesEndedHeading: 'Dialogen er afsluttet',
	coWorkerMessagesEndedButtonContinue: 'Fortsæt arbejdet',
	coWorkerMessagesEndedButtonClose: 'Afslut sag',

	coWorkerVisitorDetailsButtonInvite: 'Invite',
	coWorkerVisitorDetailsButtonInviteTitle: 'Invite Guest',
	coWorkerVisitorDetailsButtonKickInvitee: 'Remove guest',
	coWorkerVisitorDetailsButtonBlockIntiteeTitle: 'Remove guest',
	coWorkerVisitorDetailsButtonStart: 'Start',
	coWorkerVisitorDetailsButtonStartTitle: 'Start dialog med denne besøgende',
	coWorkerVisitorDetailsButtonBlock: 'Bloker',
	coWorkerVisitorDetailsButtonBlockTitle: 'Bloker denne besøger',
	coWorkerVisitorDetailsButtonRelease: 'Frigiv',
	coWorkerVisitorDetailsButtonReleaseTitle: 'Frigiv tilbage til køen',
	coWorkerVisitorDetailsButtonHangUp: 'Læg på',
	coWorkerVisitorDetailsButtonHangUpTitle: 'Afslut kommunikation med besøgeren',
	coWorkerVisitorDetailsButtonEnd: 'Afslut',
	coWorkerVisitorDetailsButtonEndTitle: 'Afslut sag',

	coWorkerDropZoneToolTip: 'Slip-zone: Slip aktioner her',
	coWorkerActionPanelAreaToolTip: 'Aktions område: Træk aktioner herfra og til slip-zonen for at eksekvere!',

	coWorkerTransferUsersOnline: 'Brugere online',
	coWorkerTransferVisitorsInQueue: 'Besøgende i gruppes kø',

	/*
	 * -----------------------------------------------------------
	 * Participants
	 * -----------------------------------------------------------
	 */
	chatparticipantsTitle: 'Deltagere',

	/*
	 * -----------------------------------------------------------
	 * Chat
	 * -----------------------------------------------------------
	 */
	chatPanelTitle: 'Chat dialog',
	chatAutoTextButton: 'Standardsvar',
	chatAutoTextButtonToolTip: 'Klik for at vælge standardsvar',

	chatNotesPanelTitle: 'Notater',

	chatPlaceholderText: 'Enter to send, Shift+Enter for new line',
	chatNotesPlaceholderText: 'Notes you write here will not be visible in the chat for the visitor',
	/*
	 * -----------------------------------------------------------
	 * Video Chat
	 * -----------------------------------------------------------
	 */
	videoChatButtonStart: 'Audio / Video',
	videoChatButtonStop: 'Stop Audio / Video',
	videoChatNoFlashMsgUser: 'Du måtte ha flash installert for at kunne bruke denne funktion',
	videoChatNoFlashMsgVisitor: 'Funktion ikke tilgængelig: besøgeren har ikke Flash installert.',

	/*
	 * -----------------------------------------------------------
	 * Visitor profile
	 * -----------------------------------------------------------
	 */
	chatvisitorProfilePanelTitle: 'Besøgende profil',

	/*
	 * -----------------------------------------------------------
	 * Case
	 * -----------------------------------------------------------
	 */
	casePanelTitle: 'Sag',
	caseCheckBoxEmailHistoryOnCaseClose: 'Email sags historikken når den lukkes',
	caseCheckBoxEmailHistoryOnCaseCloseToolTip: 'Email sags historikken til din login email når sagen lukkes',

	caseCommentChangedTypeTo: 'Sags type er ændret til',
	caseCommentOpened: 'Sag åbnet',
	caseCommentOpenedBy: 'af',
	caseCommentEmailSentTo: 'Sag sendt som email til',

	caseCommentFormValidated: 'Formular valideret',
	caseCommentClosedAs: 'Sag lukket som',

	caseCommentIdentificationUnknown: 'Besøgeren er logget af',
	caseCommentIdentificationKnown: 'Besøgeren er logget ind',

	caseMessageDialogStillActiveHeading: 'Dialogen er stadigvæk aktiv!',
	caseMessageDialogStillActiveButtonContinueWithNew: 'Forsæt med ny sag',
	caseMessageDialogStillActiveButtonHangUp: 'Læg på',

	caseFormValidateButton: 'Validere',

	caseTypeChooserTitle: 'Ændre sags type',

	caseCloseTitle: 'Vælg afslutnings option',
	caseClosureErrorHeader: 'Afslutnings option',
	caseClosureError: 'Du kan ikke afslutte for end en afslutnings option er valgt!',

	caseAutoTextPanelHeader: 'Standardsvar',
	caseAutoTextNotPresent: 'Der er ingen fordefineret tekster for denne sags type',

	caseDismiss: 'Afvis',
	/*
	 * -----------------------------------------------------------
	 * Case list
	 * -----------------------------------------------------------
	 */
	caseListHeading: 'Besøgs historie',
	caseListNotPresent: 'Ingen tidligere besøg fundet!',
	caseListDeactivated: 'Visitor case-history deactivated.',

	caseListItemType: 'Sags type',
	caseListItemGroup: 'Gruppe',
	caseListItemId: 'Sags Id',

	previousCasePanelNoData: 'Denne sag indeholder ingen data.',
	previousCasePanelLoading: 'Henter...',
	previousCasePanelHistory: 'historie',
	/*
	 * -----------------------------------------------------------
	 * Queue list
	 * -----------------------------------------------------------
	 */
	queuePanelQueue: 'Kø',
	queuePanelProactive: 'Proaktiv',
	QueuePanelGroupStatusOverview: 'Gruppestatus oversigt',
	/*
	 * -----------------------------------------------------------
	 * Queue list
	 * -----------------------------------------------------------
	 */
	queueItemMessageUndefined: 'Ukendt meddelelse',
	meetingStart: 'Meeting start: ',

	queueItemButtonSelect: 'Vælg',
	queueItemButtonLock: 'Lås',

	/*
	 * -----------------------------------------------------------
	 * General
	 * -----------------------------------------------------------
	 */
	monthsShort: ['Jan','Feb','Mar','Apr','Maj','Jun','Jul','Aug','Sep','Okt','Nov','Dec'],
	//monthsFull = ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'],
	//weekdaysShort = ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
	//weekdaysFull = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],

	/*
	 * -----------------------------------------------------------
	 * Visitors / Agents online
	 * -----------------------------------------------------------
	 */
	queueStatusAgentsOnline: 'Agenter tilgængelig',
	queueStatusMyAvailability: 'Min tilgænglighed',
	queueStatusAgents: 'Agenter',
	queueStatusVisitors: 'Besøgende',
	queueStatusVisitorsInQueue: 'Besøgende i kø',
	queueStatusLanguage: 'Sprog',
	queueStatusGroup: 'Gruppe',

	/*
	 * -----------------------------------------------------------
	 * Notifications
	 * -----------------------------------------------------------
	 */
	notificationNotPermitted: 'Notifikationer er ikke godkendt endnu.',
	notificationNotPermittedButton: 'Godkend',
	notificationChatTitle: 'Ny chat besked!',
	notificationChatBody: 'ny ulæst chat besked',
	notificationChatBodyPlural: 'nye ulæste chat beskeder',
	notificationQueueTitle: 'ny besøger i chat køen!',
	notificationQueueBody: 'besøger venter på hjælp',
	notificationQueueBodyPlural: 'besøgere venter på hjælp',
	notificationVisitorTitle: 'Du er blevet tildelt en ny besøgere',

	clipboardDefaultTip: 'Klik for at kopiere',
	clipboardConfirmTip: 'Kopieret til udklip',

	__dummy: null
};
lang['sv-SE'] = {
	/*
	 * -----------------------------------------------------------
	 * Menu
	 * -----------------------------------------------------------
	 */
	menuCodeStarter: 'Phone to Web',
	menuCodeStarterLinkVisitorByCode: 'Länka genom kod',
	menuCodeStarterGernerateCode: 'Skapa kod',

	meetingCodeGenerate: 'Skapa möteskod',
	/*
	 * -----------------------------------------------------------
	 * CoBrowser
	 * -----------------------------------------------------------
	 */
	authorizeGuestCoBrowsingBtn: 'Enable Guest Cobrowsing',
	authorizeGuestCoBrowsingBtnActive : 'Disable Guest Cobrowsing',
	authorizeGuestCoBrowsingBtnTitle: 'Toggle Guest Cobrowsing',

	coBrowserTopMenuButtonHome: 'Hem',
	coBrowserTopMenuButtonHomeTitle: 'Navigera till punkt där visuell guidning startades',
	coBrowserTopMenuButtonDeActivate: 'Stäng av visuell guidning',
	coBrowserTopMenuButtonDeActivateTitle: 'Stäng av synkronisering med besökare',
	coBrowserTopMenuButtonActivate: 'Starta visuell guidning',//och skicka denna sida',
	coBrowserTopMenuButtonActivateTitle: 'Aktivera synkronisering med besökare',
	coBrowserTopMenuButtonGetLatestPage: 'Hämta besökarens nuvarande sida',
	coBrowserTopMenuButtonGetLatestPageTitle: 'Gå till senast kända sida för besökare',

	coBrowserTopMenuButtonGetVisitorPageWithFormValues: 'Hämta formulärvärden',
	coBrowserTopMenuButtonGetVisitorPageWithFormValuesTitle: 'Hämta besökarens nuvarande sida och formulärvärden',

	coBrowserRetrieveFormValues: 'Hämtar formulärvärden...',
	coBrowserLockScreenNavigationMessage: 'Besökaren navigerar till ',
	coBrowserHighLightButton: 'Markera fält',
	coBrowserHighLightButtonTitle: 'Möjliggör att visuellt markera element att presentera för besökare',

	coBrowserHighLightTextLabel: 'Klicka för att markera fältet för besökaren',
	coBrowserScaleLabel: 'Skala',
	coBrowserScaleLabelTitle: 'Skalar vyn till matcha besökarens skärmstorlek',

	coBrowserNavigatingTo: 'Navigera till: ',
	coBrowserTimeoutText: 'Det verkar ha uppstått ett fel. Försök att: ',
	coBrowserVisitorIsAt: 'Besökaren är på ',
	coBrowserNoticeDeactivated: 'Visual guidance är deaktiverat.',
	coBrowserLoading: 'Laddar...',

	coBrowserStatusActiveShortDesc: 'Dubbelriktad visuell guidning är aktiverad',
	coBrowserStatusPausedShortDesc: 'Du har avaktiverat visuell guidning',
	coBrowserStatusPassiveShortDesc: 'Visuell guidning är i passivt läge. Du kan skicka länkar som meddelande genom att klicka på "Avaktivera", navigera till den önskade sidan och "skicka länk"',
	coBrowserStatusProgressShortDesc: 'Vänta ...',

	/*
	 * -----------------------------------------------------------
	 * Invites
	 * -----------------------------------------------------------
	 */

	vgInviteError : 'Fel',
	vgInviteErrorMessage : 'Misslyckades skapa inviteringskod',
	vgInviteTitle: 'Bjud in en gäst i dialogen',
	vgInviteDescription: 'Kopiera och dela länken med den gäst du vill bjuda in till dialogen.',
	vgInviteClose: 'Stäng',

	/*
	 * -----------------------------------------------------------
	 * CoWorker
	 * -----------------------------------------------------------
	 */
	coWorkerTitleStateLocked: 'Låst',
	coWorkerTitleStatePendingApproval: 'Väntar på godkännande',
	coWorkerTitleStateTimedOut: 'Ej svarat',
	coWorkerTitleStateDeclined: 'Tackat nej',
	coWorkerTitleStateEnded: 'Avslutad dialog',
	coWorkerTitleStateConnectionDead: 'Avslutad dialog',
	coWorkerTitleStateConnectionLost: '',

	coWorkedBlockConfirmHeading: 'Är du säker på att du vill blockera denna person?',
	coWorkerBlockConfirmCancel: 'Ångra',
	coWorkerBlockConfirm: 'Blockera',
	coWorkerBlockBlocked: '*Blockerad!*',

	coWorkerReleaseButton: 'Överför',
	coWorkerReleaseButtonConfirmed: '*Överför dialogen till en annan grupp*',

	coWorkerSendPageToVisitor: 'Skicka länk',
	coWorkerSendPageToVisitorTitle: 'Skicka den här sidan som en länk till besökaren. Om dubbelriktad visuell guidning är aktiverat så kommer besökaren automatiskt att navigeras till sidan.',
	coWorkerSendPageToVisitorLinkLabel: 'Skriv in ett namn för länken',
	coWorkerSendPageToVisitorLinkTitle: 'Klicka här',
	coWorkerSendPageToVisitorButtonCancel: 'Ångra',
	coWorkerSendPageToVisitorButtonSend: 'Skicka',


	coWorkerMessagesConnectionDeadHeading: 'Aktuell besökare har lämnat webbplatsen, stängt sin webbläsare eller tappat anslutningen till internet.<br>Dialogen är avslutad',
	coWorkerMessagesConnectionDeadButtonContinue: 'Fortsätt arbeta',
	coWorkerMessagesConnectionDeadButtonClose: 'Stäng',

	coWorkerMessagesConnectionLostHeading: 'Aktuell besökare har lämnat webbplatsen, stängt sin webbläsare eller tappat anslutningen till internet.<br>Dialogen kommer att avslutas om inte besökaren återvänder inom 30s',
	coWorkerMessagesConnectionLostButtonContinue: 'Fortsätt arbeta',

	coWorkerMessagesTimedOutHeading: 'Besökaren har inte svarat.',
	coWorkerMessagesDeclinedHeading: 'Aktuell besökare har tackat nej.',
	coWorkerMessagesDeclinedButtonClose: 'Stäng',

	//coWorkerMessagesEndedHeading: 'Aktuell besökare har avslutat dialogen',
	coWorkerMessagesEndedHeading: 'Dialogen är avslutad',
	coWorkerMessagesEndedButtonContinue: 'Fortsätt arbeta',
	coWorkerMessagesEndedButtonClose: 'Stäng',


	coWorkerVisitorDetailsButtonInvite: 'Bjud in',
	coWorkerVisitorDetailsButtonInviteTitle: 'Bjud in gäst',
	coWorkerVisitorDetailsButtonKickInvitee: 'Ta bort gäst',
	coWorkerVisitorDetailsButtonBlockIntiteeTitle: 'Ta bort gäst',
	coWorkerVisitorDetailsButtonStart: 'Start',
	coWorkerVisitorDetailsButtonStartTitle: 'Starta dialog med besökare',
	coWorkerVisitorDetailsButtonBlock: 'Blockera',
	coWorkerVisitorDetailsButtonBlockTitle: 'Blockera besökare',
	coWorkerVisitorDetailsButtonRelease: 'Överför',
	coWorkerVisitorDetailsButtonReleaseTitle: 'Överför dialogen till en annan grupp',
	coWorkerVisitorDetailsButtonHangUp: 'Lägg på',
	coWorkerVisitorDetailsButtonHangUpTitle: 'Avsluta dialog med besökare',
	coWorkerVisitorDetailsButtonEnd: 'Stäng',
	coWorkerVisitorDetailsButtonEndTitle: 'Stäng detta ärende',

	coWorkerDropZoneToolTip: 'Dropzon: Släpp länkar här',
	coWorkerActionPanelAreaToolTip: 'Dra länkar härifrån till Dropzon:en',

	coWorkerTransferUsersOnline: 'Medarbetare online',
	coWorkerTransferVisitorsInQueue: 'Besökare i gruppens kö',

	/*
	 * -----------------------------------------------------------
	 * Participants
	 * -----------------------------------------------------------
	 */
	chatparticipantsTitle: 'Deltagare',

	/*
	 * -----------------------------------------------------------
	 * Chat
	 * -----------------------------------------------------------
	 */
	chatPanelTitle: 'Chattdialog',
	chatAutoTextButton: 'Standardsvar',
	chatAutoTextButtonToolTip: 'Klicka för att välja bland standardsvar',

	chatNotesPanelTitle: 'Anteckningar',

	chatPlaceholderText: 'Enter för att skicka, Shift+Enter för ny rad',
	chatNotesPlaceholderText: 'Anteckningar som du skriver här kommer inte att synas i besökarens chatt',
	/*
	 * -----------------------------------------------------------
	 * Video Chat
	 * -----------------------------------------------------------
	 */
	videoChatButtonStart: 'Ljud / Bild',
	videoChatButtonStop: 'Stoppa Ljud / Bild',
	videoChatNoFlashMsgUser: 'Du måste ha flash installerat för att kunna använda denna funktion',
	videoChatNoFlashMsgVisitor: 'Ej tillgängligt: den här besökaren verkar inte ha flash installerat',
	/*
	 * -----------------------------------------------------------
	 * Visitor profile
	 * -----------------------------------------------------------
	 */
	chatvisitorProfilePanelTitle: 'Besökares profil',
	/*
	 * -----------------------------------------------------------
	 * Case
	 * -----------------------------------------------------------
	 */
	casePanelTitle: 'Ärenden',
	caseCheckBoxEmailHistoryOnCaseClose: 'Maila ärendehistorik vid avslut',
	caseCheckBoxEmailHistoryOnCaseCloseToolTip: 'Skicka ärendehistorik vid avslut till din email',

	caseCommentChangedTypeTo: 'Ärendetyp ändrad till',
	caseCommentOpened: 'Ärende öppnat',
	caseCommentOpenedBy: 'av',
	caseCommentEmailSentTo: 'Ärende skickat som e-mail till',

	caseCommentFormValidated: 'Giltigt formulär',
	caseCommentClosedAs: 'Ärende stängt som',

	caseCommentIdentificationUnknown: 'Besökaren är inte längre inloggad',
	caseCommentIdentificationKnown: 'Besökaren är inloggad',

	caseMessageDialogStillActiveHeading: 'Dialogen är fortfarande aktiv',
	caseMessageDialogStillActiveButtonContinueWithNew: 'Fortsätt med nytt ärende',
	caseMessageDialogStillActiveButtonHangUp: 'Lägg på',

	caseFormValidateButton: 'Bekräfta',

	caseTypeChooserTitle: 'Ändra ärendetyp',

	caseCloseTitle: 'Välj avslutningsalternativ',
	caseClosureErrorHeader: 'Välj avslutningsalternativ',
	caseClosureError: 'Du kan inte stänga utan att ha valt ett avslutningsalternativ',

	caseAutoTextPanelHeader: 'Standardsvar',
	caseAutoTextNotPresent: 'Det finns inga fördefinerade texter för denna ärendetyp',

	caseDismiss: 'Ärende avvisat',
	/*
	 * -----------------------------------------------------------
	 * Case list
	 * -----------------------------------------------------------
	 */
	caseListHeading: 'Besökarhistorik',
	caseListNotPresent: 'Inga tidigare besök tillgängliga',
	caseListDeactivated: 'Besökare case-historik ej aktiverat.',

	caseListItemType: 'Ärendetyp',
	caseListItemGroup: 'Grupp',
	caseListItemId: 'Ärende Id',

	previousCasePanelNoData: 'Detta ärende är tomt',
	previousCasePanelLoading: 'Laddar…',
	previousCasePanelHistory: 'Historik',
	/*
	 * -----------------------------------------------------------
	 * Queue list
	 * -----------------------------------------------------------
	 */
	queuePanelQueue: 'Kö',
	queuePanelProactive: 'Proaktiv',
	QueuePanelGroupStatusOverview: 'Gruppöversikt och tillgänglighet',
	/*
	 * -----------------------------------------------------------
	 * Queue list
	 * -----------------------------------------------------------
	 */
	queueItemMessageUndefined: 'Odefinerat meddelande',
	meetingStart: 'Mötetsstart: ',

	queueItemButtonSelect: 'Välj',
	queueItemButtonLock: 'Lås',

	/*
	 * -----------------------------------------------------------
	 * General
	 * -----------------------------------------------------------
	 */

	monthsShort: ['Jan','Feb','Mar','Apr','Maj','Jun','Jul','Aug','Sep','Okt','Nov','Dec'],
	//monthsFull = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
	//weekdaysShort = ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
	//weekdaysFull = ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
	/*
	 * -----------------------------------------------------------
	 * Visitors / Agents online
	 * -----------------------------------------------------------
	 */
	queueStatusAgentsOnline: 'Handläggare tillgängliga',
	queueStatusAgents: 'Handläggare',
	queueStatusMyAvailability: 'Min tillgänglighet',
	queueStatusVisitors: 'Besökare',
	queueStatusVisitorsInQueue: 'Besökare i kö',
	queueStatusLanguage: 'Språk',
	queueStatusGroup: 'Grupp',

	/*
	 * -----------------------------------------------------------
	 * Notifications
	 * -----------------------------------------------------------
	 */
	notificationNotPermitted: 'Notifieringar är inte tillåtna ännu.',
	notificationNotPermittedButton: 'Tillåt',
	notificationChatTitle: 'Nytt chatt meddelande!',
	notificationChatBody: 'nytt chatt meddelande',
	notificationChatBodyPlural: 'nya chatt meddelanden',
	notificationQueueTitle: 'Ny besökare i chattkön!',
	notificationQueueBody: 'besökare inväntar hjälp',
	notificationQueueBodyPlural: 'besökare inväntar hjälp',
	notificationVisitorTitle: 'En besökare har tilldelats dig',

	clipboardDefaultTip: 'Klicka för att kopiera rubrik',
	clipboardConfirmTip: 'Kopierat till urklipp',

	__dummy: null
};


lang['en-GB'] = {
	/*
	 * -----------------------------------------------------------
	 * Menu
	 * -----------------------------------------------------------
	 */
	menuCodeStarter: 'Code starter',
	menuCodeStarterLinkVisitorByCode: 'Link visitor by code',
	menuCodeStarterGernerateCode: 'Generate code',

	meetingCodeGenerate: 'Generate meeting code',
	/*
	 * -----------------------------------------------------------
	 * CoBrowser
	 * -----------------------------------------------------------
	 */
	authorizeGuestCoBrowsingBtn: 'Enable Guest Cobrowsing',
	authorizeGuestCoBrowsingBtnActive : 'Disable Guest Cobrowsing',
	authorizeGuestCoBrowsingBtnTitle: 'Toggle Guest Cobrowsing',

	coBrowserTopMenuButtonHome: 'Home',
	coBrowserTopMenuButtonHomeTitle: 'Will redirect to entrypoint of CoBrowsing',
	coBrowserTopMenuButtonDeActivate: 'Deactivate co-browsing',
	coBrowserTopMenuButtonDeActivateTitle: 'Deactivate synchronization with visitor',
	coBrowserTopMenuButtonActivate: 'Activate co-browsing',// and send this url',
	coBrowserTopMenuButtonActivateTitle: 'Activate synchronization with visitor',
	coBrowserTopMenuButtonGetLatestPage: 'Get visitors latest page',
	coBrowserTopMenuButtonGetLatestPageTitle: 'Go to the last known visited page by the visitor',

	coBrowserTopMenuButtonGetVisitorPageWithFormValues: 'Get visitors page with form values',
	coBrowserTopMenuButtonGetVisitorPageWithFormValuesTitle: 'Get the visitors current page including current state of forms',

	coBrowserRetrieveFormValues: 'Retrieving form data...',
	coBrowserLockScreenNavigationMessage: 'Visitor is navigating to ',
	coBrowserHighLightButton: 'Highlight',
	coBrowserHighLightButtonTitle: 'Enables you to visually highlight an input element to present to the visitor',

	coBrowserHighLightTextLabel: 'Click to highlight for visitor',

	coBrowserScaleLabel: 'Scale',
	coBrowserScaleLabelTitle: 'if enabled, the viewport scales to match the visitor screen size.',

	coBrowserNavigatingTo: 'Navigating to: ',
	coBrowserTimeoutText: 'There seems to be a problem, try to: ',
	coBrowserVisitorIsAt: 'Visitor is at ',
	coBrowserNoticeDeactivated: 'Visual guidance is deactivated.',
	coBrowserLoading: 'Loading...',

	coBrowserStatusActiveShortDesc: 'Bi-directional visual guidance is enabled.',
	coBrowserStatusPausedShortDesc: 'You have deactivated visual guidance.',
	coBrowserStatusPassiveShortDesc: 'Visual guidance is in passive mode. You can send links as messages by clicking "Deactivate", browse to the desired page, then "send link".',
	coBrowserStatusProgressShortDesc: 'Hold on ...',

	/*
	 * -----------------------------------------------------------
	 * Invites
	 * -----------------------------------------------------------
	 */

	vgInviteError : 'Error',
	vgInviteErrorMessage : 'Failed to create invitation code',
	vgInviteTitle: 'Invite a guest to this dialog',
	vgInviteDescription: 'Copy and share the link with the quest shall join this dialog.',
	vgInviteClose: 'Close',


	/*
	 * -----------------------------------------------------------
	 * CoWorker
	 * -----------------------------------------------------------
	 */
	coWorkerTitleStateLocked: 'Locked',
	coWorkerTitleStatePendingApproval: 'Pending Approval',
	coWorkerTitleStateTimedOut: 'No response',
	coWorkerTitleStateDeclined: 'Declined',
	coWorkerTitleStateEnded: 'Dialog ended',
	coWorkerTitleStateConnectionDead: 'Dialog ended',
	coWorkerTitleStateConnectionLost: '',

	coWorkedBlockConfirmHeading: 'Are you sure you want to block this person?',
	coWorkerBlockConfirmCancel: 'Cancel',
	coWorkerBlockConfirm: 'Block',
	coWorkerBlockBlocked: '*BLOCKED!!!*',

	coWorkerReleaseButton: 'Transfer',
	coWorkerReleaseButtonConfirmed: '*Dialog released to list*',

	coWorkerSendPageToVisitor: 'Send link',
	coWorkerSendPageToVisitorTitle: 'Send current page to visitor as a message. If in bi-directional CoBrowsing, the visitor will also be redirected to this page.',
	coWorkerSendPageToVisitorLinkLabel: 'Please provide a title for the link',
	coWorkerSendPageToVisitorLinkTitle: 'Click here',
	coWorkerSendPageToVisitorButtonCancel: 'Cancel',
	coWorkerSendPageToVisitorButtonSend: 'Send',

	coWorkerMessagesConnectionDeadHeading: 'Current visitor has left the web site, closed the web browser or lost connection to internet.<br>Dialog has ended.',
	coWorkerMessagesConnectionDeadButtonContinue: 'Continue working',
	coWorkerMessagesConnectionDeadButtonClose: 'Close panel',

	coWorkerMessagesConnectionLostHeading: 'Current visitor has left the web site, closed the web browser or lost connection to internet.<br>Dialog will end if visitor does not reconnect within 30s.',
	coWorkerMessagesConnectionLostButtonContinue: 'Continue working',

	coWorkerMessagesTimedOutHeading: 'Visitor has not responded to the invite.',
	coWorkerMessagesDeclinedHeading: 'Current visitor has declined.',
	coWorkerMessagesDeclinedButtonClose: 'Close panel',

	//coWorkerMessagesEndedHeading: 'Current visitor has ended the dialog.',
	coWorkerMessagesEndedHeading: 'The dialog has ended',
	coWorkerMessagesEndedButtonContinue: 'Continue working',
	coWorkerMessagesEndedButtonClose: 'Close panel',

	coWorkerVisitorDetailsButtonInvite: 'Invite',
	coWorkerVisitorDetailsButtonInviteTitle: 'Invite Guest',
	coWorkerVisitorDetailsButtonKickInvitee: 'Remove guest',
	coWorkerVisitorDetailsButtonBlockIntiteeTitle: 'Remove guest',
	coWorkerVisitorDetailsButtonStart: 'Start',
	coWorkerVisitorDetailsButtonStartTitle: 'Start dialog with this visitor',
	coWorkerVisitorDetailsButtonBlock: 'Block',
	coWorkerVisitorDetailsButtonBlockTitle: 'Block this visitor',
	coWorkerVisitorDetailsButtonRelease: 'Transfer',
	coWorkerVisitorDetailsButtonReleaseTitle: 'Transfer dialog to another group',
	coWorkerVisitorDetailsButtonHangUp: 'Hang up',
	coWorkerVisitorDetailsButtonHangUpTitle: 'End communication with the visitor',
	coWorkerVisitorDetailsButtonEnd: 'Close',
	coWorkerVisitorDetailsButtonEndTitle: 'Close the case',

	coWorkerDropZoneToolTip: 'Drop-zone: Drop actions here',
	coWorkerActionPanelAreaToolTip: 'Action area: Drag actions from here to Drop-zone above to execute!',

	coWorkerTransferUsersOnline: 'Users online',
	coWorkerTransferVisitorsInQueue: 'Visitors in group',

	/*
	 * -----------------------------------------------------------
	 * Participants
	 * -----------------------------------------------------------
	 */
	chatparticipantsTitle: 'Participants',

	/*
	 * -----------------------------------------------------------
	 * Chat
	 * -----------------------------------------------------------
	 */
	chatPanelTitle: 'Chat',
	chatAutoTextButton: 'Canned responses',
	chatAutoTextButtonToolTip: 'Click to show canned responses',

	chatNotesPanelTitle: 'Notes',

	chatPlaceholderText: 'Enter to send, Shift+Enter for new line',
	chatNotesPlaceholderText: 'Notes you write here will not be visible in the chat for the visitor',
	/*
	 * -----------------------------------------------------------
	 * Video Chat
	 * -----------------------------------------------------------
	 */
	videoChatButtonStart: 'Audio / Video',
	videoChatButtonStop: 'Stop Audio / Video',
	videoChatNoFlashMsgUser: 'You need to have flash installed and enabled in order to use this function',
	videoChatNoFlashMsgVisitor: 'Not available: this visitor does not seem to have flash installed or enabled.',
	/*
	 * -----------------------------------------------------------
	 * Visitor profile
	 * -----------------------------------------------------------
	 */
	chatvisitorProfilePanelTitle: 'Visitor profile',
	/*
	 * -----------------------------------------------------------
	 * Case
	 * -----------------------------------------------------------
	 */
	casePanelTitle: 'Case',
	caseCheckBoxEmailHistoryOnCaseClose: 'Email case history on close',
	caseCheckBoxEmailHistoryOnCaseCloseToolTip: 'Email case history on case close to your login email address',

	caseCommentChangedTypeTo: 'Case type changed to',
	caseCommentOpened: 'Case opened',
	caseCommentOpenedBy: 'by',
	caseCommentEmailSentTo: 'Case sent as email to',

	caseCommentFormValidated: 'Form validated',
	caseCommentClosedAs: 'Case closed as',

	caseCommentIdentificationUnknown: 'The visitor has logged out',
	caseCommentIdentificationKnown: 'The visitor is now logged in',

	caseMessageDialogStillActiveHeading: 'Dialog still active!',
	caseMessageDialogStillActiveButtonContinueWithNew: 'Continue with new case',
	caseMessageDialogStillActiveButtonHangUp: 'Hang up',

	caseFormValidateButton: 'Validate',

	caseTypeChooserTitle: 'Change case type',

	caseCloseTitle: 'Choose closure option',
	caseClosureErrorHeader: 'Choose closure option',
	caseClosureError: 'You can not close unless you choose a closure option!',

	caseAutoTextPanelHeader: 'Canned responses',
	caseAutoTextNotPresent: 'There are no predefined texts for this case type',

	caseDismiss: 'Dismiss',
	/*
	 * -----------------------------------------------------------
	 * Case list
	 * -----------------------------------------------------------
	 */
	caseListHeading: 'Visitor history',
	caseListNotPresent: 'No previous visits available!',
	caseListDeactivated: 'Visitor case-history deactivated.',

	caseListItemType: 'Case type',
	caseListItemGroup: 'Group',
	caseListItemId: 'Case Id',

	previousCasePanelNoData: 'This case contains no data.',
	previousCasePanelLoading: 'Loading...',
	previousCasePanelHistory: 'history',
	/*
	 * -----------------------------------------------------------
	 * Queue list
	 * -----------------------------------------------------------
	 */
	queuePanelQueue: 'Queue',
	queuePanelProactive: 'Proactive',
	QueuePanelGroupStatusOverview: 'Group status overview',
	/*
	 * -----------------------------------------------------------
	 * Queue list
	 * -----------------------------------------------------------
	 */
	queueItemMessageUndefined: 'Message undefined',
	meetingStart: 'Meeting start: ',

	queueItemButtonSelect: 'Select',
	queueItemButtonLock: 'Lock',

	/*
	 * -----------------------------------------------------------
	 * General
	 * -----------------------------------------------------------
	 */

	monthsShort: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
	//monthsFull = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
	//weekdaysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
	//weekdaysFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

	/*
	 * -----------------------------------------------------------
	 * Visitors / Agents online
	 * -----------------------------------------------------------
	 */
	queueStatusAgentsOnline: 'Agents available',
	queueStatusAgents: 'Agents',
	queueStatusMyAvailability: 'My availability',
	queueStatusVisitors: 'Visitors',
	queueStatusVisitorsInQueue: 'Visitors in queue',
	queueStatusLanguage: 'Language',
	queueStatusGroup: 'Group',

	/*
	 * -----------------------------------------------------------
	 * Notifications
	 * -----------------------------------------------------------
	 */
	notificationNotPermitted: 'Notifications are not permitted yet.',
	notificationNotPermittedButton: 'Allow',
	notificationChatTitle: 'New chat message!',
	notificationChatBody: 'new unread chat message',
	notificationChatBodyPlural: 'new unread chat messages',
	notificationQueueTitle: 'New visitor in chat queue!',
	notificationQueueBody: 'visitor awaiting help',
	notificationQueueBodyPlural: 'visitors awaiting help',
	notificationVisitorTitle: 'A new visitor has been assigned to you',

	clipboardTip: 'Click to copy headline',
	clipboardConfirmTip: 'Copied to clipboard',

	__dummy: null
};

//Aliases:
lang['en'] = lang['en-US'] = lang['en-GB'];


function setLanguage(language) {
	if (lang[language]){
		currentLanguage = language;
	}
}

function getLanguage(){
	return currentLanguage;
}

function translate(key){
	if (typeof lang[currentLanguage] === 'undefined' || typeof lang[currentLanguage][key] === 'undefined') {
		if (typeof lang[defaultLanguage][key] !== 'undefined') {
			return A.lang[defaultLanguage][key];
		}
		return '{' + key + '}';
	}
	return lang[currentLanguage][key];
}


export default {
	setLanguage: setLanguage,
	getLanguage: getLanguage,
	translate: translate
};
