import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';
export default function ($scope, APIEndpointService, $stateParams, $state, urlCategory, VngageUtil, APIConfigurationSectionService, vngageRemoveModalService) {
	'ngInject';

	var vm = this;
	vm.urlCategoryId = $stateParams.id;
	vm.urlCategory = urlCategory;
	vm.submit = submit;
	vm.remove = remove;
	vm.cancel = cancel;
	vm.currentList = $stateParams.currentList || [];

	$scope.$on('resetResettables', () => {
		vm.urlCategoryEditForm.$setPristine();
		urlCategory.$get().catch(() => {});
	});

	function submit() {
		vm.isSaving = true;
		urlCategory.$save().finally(function () {
			vm.isSaving = false;
		});
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: urlCategory,
			itemType: APIConfigurationSectionService.sectionMap.urlCategory
		}).then(function () {
			vm.urlCategoryEditForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(urlCategory, 'root.configure.urlCategory.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(function () {
			vm.isSaving = false;
		});
	}

	function cancel() {
		$state.go('^');
	}
};
