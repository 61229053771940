import React from 'react';
import ModalDraggable from './ModalDraggable.react'
import VideoView from '../video/VideoView.react';

const VideoModal = (props) => {
	const onClose = () => {
		props.hideModal();
		props.showVideoSection();
	};

	const payload = {
		isModal: true
	};

	return (
		<ModalDraggable close={onClose}>
			<VideoView {...payload} />
		</ModalDraggable>
	)
};
export default VideoModal

