export default function(BannerService) {
	'ngInject';

	return {
		options: {
			idProperty: 'data.id',
			stateLink: {
				state: 'root.engage.banner.edit.general',
				property: 'data.id'
			},
			groupHeaders: [
				{
					text: '',
					colspan: 4
				},{
					text: 'banner.DISPLAY_STATE',
					colspan: 3
				}
			],
			createItem: {
				langKey: 'banner.ADD_HEADER'
			}
		},
		columns: [
			{
				header: 'banner.NAME',
				property: 'data.name',
				sortDefault: true,
				useInFilter: true,
				width: '35%'
			},{
				header: 'GUID',
				property: 'data.id',
				hidden: true,
				useInFilter: true
			}, {
				header: 'banner.TEMPLATE',
				property: 'data.section.template',
				translate: true,
				width: '15%',
				transform: function(val) {
					return BannerService.templates()[val];
				}
			},{
				header: 'banner.DESCRIPTION',
				property: 'data.section.description',
				width: '30%',
				useInFilter: true
			},{
				header: 'banner.OPEN',
				property: 'data.section.displayWhenOpen',
				sortReverse: true,
				type: 'tag',
				width: '8%'
			},{
				header: 'banner.BUSY',
				property: 'data.section.displayWhenBusy',
				sortReverse: true,
				type: 'tag',
				width: '8%'
			},{
				header: 'banner.CLOSED',
				property: 'data.section.displayWhenClosed',
				sortReverse: true,
				type: 'tag',
				width: '8%'
			}
		]
	};
}
