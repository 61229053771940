export default {
    'en-GB': {
        translation: {
            ADD_HEADER: 'New translation',
            EDIT_HEADER: 'Edit translation',
            LIST_HEADER: 'Translations',
			LIST_TITLE: 'List of translations for this account',
			LIST_DESCRIPTION: 'Each site can have one translation, and each translation may be used on multiple sites.',
            CODE: 'Code',
            NAME: 'Name'
        }
    },
    'sv-SE': {
        translation: {
            ADD_HEADER: 'New translation',
            EDIT_HEADER: 'Edit translation',
            LIST_HEADER: 'Translations',
            LIST_TITLE: 'List of translations for this account',
            LIST_DESCRIPTION: 'Each site can have one translation, and each translation may be used on multiple sites.',
            CODE: 'Code',
            NAME: 'Name'
        }
    }
};
