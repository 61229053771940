import JSChannel from '../../../../../vendor/JSChannel';
import PluginMockChannel from './PluginMockChannel'
import LogActions from '../../actions/LogActions'
import {Store} from '../../stores/Store';

let debug = false;
export default {
	latestSubscriptions: {},

	build(iframeContentWindow, origin) {
		if (!debug) {
			return JSChannel.build({
				debugOutput: false,
				window: iframeContentWindow,
				origin: (origin ? origin: '*'),
				scope: 'plugin',
				reconnect: true,
				onReady: function (ch) {
					// console.log('DESKTOP channel ready');
					// self.handleSubscriptions(self.props, true);
				}
			});
		} else {
			return PluginMockChannel.getBuiltChannel();
		}
	},

	subscriptionsSent(pluginId, subscriptions) {
		if (!debug) {
			Store.dispatch(LogActions.pluginSubscriptions(pluginId, subscriptions));
		} else {
			this.latestSubscriptions[pluginId] = subscriptions;
		}
	},

	noSubscriptionsSent(pluginId) {
		if (debug) {
			this.latestSubscriptions[pluginId] = null;
		}
	},

	setDebug(on) {
		debug = on;
	}

}


