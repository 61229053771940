import OverviewController from './OverviewController';
import overviewTranslations from './OverviewTranslations';
import OverviewTemplate from './OverviewTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.account.overview', [
    'ui.router',
    'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

.controller('OverviewController', OverviewController)
.constant('overviewTranslations', overviewTranslations)

.constant('overviewModuleConfig', {
    state: 'root.account.overview',
    access: 'Account'
})

.config(function($stateProvider, $translateProvider, overviewTranslations, overviewModuleConfig, VngageWorkspaceTranslationServiceProvider) {
	'ngInject';
	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(overviewTranslations);

    $stateProvider.state(overviewModuleConfig.state, {
        url: 'overview/',
        templateUrl: OverviewTemplate,
        controller: 'OverviewController as vm',
        data: {
            ncyBreadcrumbLabel: 'Overview',
            access: overviewModuleConfig.access
        },
        resolve: {
            account: function (APIEndpointService) {
				'ngInject';
                return APIEndpointService.accountCurrent.get().$promise;
            }
        }
    });
});
