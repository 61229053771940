import Message from '../../actionCreators/Message'

const actionFormats = {
	sendNote: {
		conversationId: 'conversationId',
		msg: 'string'
	},
};

const actionMap = {
	sendNote: ({conversationId, msg}) => dispatch => dispatch(Message.sendNote(conversationId, msg)),
};

export default {
	actionFormats,
	actionMap
}

