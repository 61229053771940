export default {
	'en-GB': {
		plugin: {
			ADD_HEADER: 'New plugin',
			LIST_HEADER: 'Desktop plugins',
			LIST_TITLE: 'List of plugins for this account',
			LIST_DESCRIPTION: 'Define and edit desktop plugins',
			NAME: 'Plugin name',
			VERSION: 'API Version',
			PLUGIN_NAME: 'Name',
			PLUGIN_URL: 'Url',
			SECTION_BUTTON: 'Section Button',
			SECTION_BUTTON_VALUE_NONE: 'None',
			SECTION_BUTTON_VALUE_BUTTON: 'Button',
			SECTION_BUTTON_TITLE: 'Button title',
			SECTION_BUTTON_ICON_URL: 'Icon url',
			SECTION_PLUGIN_ICON_URL: 'Plugin icon url',
			DISPLAY_MODE: 'Display mode',
			DISPLAY_MODE_VALUE_FLOATING: 'Floating window',
			DISPLAY_MODE_VALUE_HIDDEN: 'Hidden',
			DISPLAY_MODE_VALUE_EXTERNAL: 'External',
			DISPLAY_MODE_VALUE_PINNED: 'Pinned',
			WIDTH: 'Width',
			HEIGHT: 'Height',
			X_POSITION: 'X Position',
			Y_POSITION: 'Y Position',
			MAP_ACTIONS_HEADER_NAME: 'Name',
			MAP_ACTIONS_HEADER_ACTION: 'Mapped Action (type)',
		}
	},
	'x-sv-SE': {
		plugin: {
			ADD_HEADER: 'Ny plugin',
			LIST_HEADER: 'Desktop plugins',
			LIST_TITLE: 'Plugins för detta konto',
			LIST_DESCRIPTION: 'Skapa and redigera desktop plugins',
			NAME: 'Plugin-namn',
			VERSION: 'API Version',
			PLUGIN_NAME: 'Name',
			PLUGIN_URL: 'Url',
			SECTION_BUTTON: 'Section Button',
			SECTION_BUTTON_VALUE_NONE: 'None',
			SECTION_BUTTON_VALUE_BUTTON: 'Button',
			SECTION_BUTTON_TITLE: 'Button title',
			SECTION_BUTTON_ICON_URL: 'Icon url',
			SECTION_PLUGIN_ICON_URL: 'Plugin icon url',
			DISPLAY_MODE: 'Display mode',
			DISPLAY_MODE_VALUE_FLOATING: 'Floating window',
			DISPLAY_MODE_VALUE_HIDDEN: 'Hidden',
			DISPLAY_MODE_VALUE_EXTERNAL: 'External',
			DISPLAY_MODE_VALUE_PINNED: 'Pinned',
			WIDTH: 'Bredd',
			HEIGHT: 'Höjd',
			X_POSITION: 'X Position',
			Y_POSITION: 'Y Position',
			MAP_ACTIONS_HEADER_NAME: 'Name',
			MAP_ACTIONS_HEADER_ACTION: 'Mapped Action (type)',
		}
	}
};

