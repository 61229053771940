export default function($uibModalInstance, $state, BannerService, APIConfigurationSectionService) {
    'ngInject';

    var vm = this;
    vm.saveNew = saveNew;
    vm.cancelNew = $uibModalInstance.dismiss;
    vm.templates = BannerService.templates();

    function saveNew() {
        var type = APIConfigurationSectionService.sectionMap.banner;
        vm.isSaving = true;
		$uibModalInstance.freeze(true);
        BannerService.defaults({ data: vm.newBanner });

        APIConfigurationSectionService.create(vm.newBanner, type, true)
            .then(function(res) {
				$uibModalInstance.close(res);
            })
            .catch(function() {
				$uibModalInstance.freeze(false);
                vm.isSaving = false;
            });
    }
}
