export default {
	CHAT: 'chat',
	VISITOR_PROFILE: 'visitorProfile',
	CASE_MANAGER: 'caseManager',
	VIDEO: 'video',
	RESERVATION: 'bookedMeetingInfo',
	COBRO: 'coBrowsing',
	PLUGIN_BUTTON: 'plugin_button',
	WEB_CALL: 'webCall'
}
