import _find from 'lodash/find';

import SystemRoleAuthorizationsController from './SystemRoleAuthorizations/SystemRoleAuthorizationsController';
import SystemRoleDependsController from './SystemRoleDepends/SystemRoleDependsController';
import SystemRoleDetailsController from './SystemRoleDetails/SystemRoleDetailsController';
import SystemRolePushController from './SystemRolePush/SystemRolePushController';
import SystemRoleAddModalController from './SystemRoleAddModalController';
import SystemRoleEditController from './SystemRoleEditController';
import SystemRoleListController from './SystemRoleListController';
import SystemRolesService from './SystemRoleService';
import systemRoleTranslations from './SystemRoleTranslations';

import SystemRoleListTemplate from './SystemRoleListTemplate';
import SystemRoleEditTemplate from './SystemRoleEditTemplate';
import SystemRoleDetailsTemplate from './SystemRoleDetails/SystemRoleDetailsTemplate';
import SystemRoleAuthorizationsTemplate from './SystemRoleAuthorizations/SystemRoleAuthorizationsTemplate';
import SystemRoleDependsTemplate from './SystemRoleDepends/SystemRoleDependsTemplate';
import SystemRolePushTemplate from './SystemRolePush/SystemRolePushTemplate';

import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.system.role', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('SystemRoleAuthorizationsController', SystemRoleAuthorizationsController)
	.controller('SystemRoleDependsController', SystemRoleDependsController)
	.controller('SystemRoleDetailsController', SystemRoleDetailsController)
	.controller('SystemRolePushController', SystemRolePushController)
	.controller('SystemRoleAddModalController', SystemRoleAddModalController)
	.controller('SystemRoleEditController', SystemRoleEditController)
	.controller('SystemRoleListController', SystemRoleListController)
	.factory('SystemRolesService', SystemRolesService)
	.constant('systemRoleTranslations', systemRoleTranslations)

	.constant('systemRoleModuleConfig', {
		state: 'root.system.role',
		access: 'System/Roles'
	})

	.config(function ($stateProvider, $translateProvider, systemRoleTranslations, systemRoleModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(systemRoleTranslations);

		var roleClaimResolver = function ($stateParams, systemConfiguration, VngageUtil, SystemRolesService, $q, APIEndpointService) {
			'ngInject';
			if ($stateParams.roleClaim) {
				// [ TL ] When a $stateParams.roleClaim exists, use that (this will happen after transition from "add" and "edit")
				return $stateParams.roleClaim;
			} else {
				return $q(function (resolve, reject) {
					// [ TL ] No $stateParams.roleClaim exists - get a fresh copy from REST
					// (we could have simply looked it up in the systemConfiguration.roleClaims-list, but the roleClaim may have been updated by another admin in BE, so fetch it again to be sure)
					APIEndpointService.systemConfiguration.get().$promise.then(fetchedSystemConfiguration => {
						var foundRoleClaim = _find(fetchedSystemConfiguration.roleClaims, roleClaim => {
							return roleClaim.id === $stateParams.id;
						});
						if (foundRoleClaim) {
							// [ TL ] Also replace the old item in the systemConfiguration.roleClaims-list to reflect any updates from BE
							SystemRolesService.replaceRoleClaim(systemConfiguration.roleClaims, foundRoleClaim).finally(() => {
								resolve(foundRoleClaim);
							});
						} else {
							reject(VngageUtil.resourceNotFoundMessage);
						}
					});
				});
			}
		};

		$translateProvider.translations('en', systemRoleTranslations.en);

		$stateProvider.state(systemRoleModuleConfig.state, {
			url: 'role/',
			controller: 'SystemRoleListController as vm',
			templateUrl: SystemRoleListTemplate,
			data: {
				ncyBreadcrumbLabel: 'Roles',
				access: systemRoleModuleConfig.access
			},
			resolve: {
				systemConfiguration: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.systemConfiguration.get().$promise;
				}
			}
		});
		$stateProvider.state('root.system.role.edit', {
			url: ':id',
			params: {
				roleClaim: null,
				currentList: []
			},
			controller: 'SystemRoleEditController as vm',
			templateUrl: SystemRoleEditTemplate,
			data: {
				ncyBreadcrumbLabel: 'Edit',
				access: {action: 'GET', resource: 'SystemConfiguration'}
			},
			resolve: {
				accountList: function (APIEndpointService) {
					'ngInject';
					// [ TL ] Do we really need to fetch the accountList every time the state root.system.role.edit is loaded?
					// This resolve could have been put in the parent state (root.system.role) to only fetch it once when the list is fetched
					return APIEndpointService.account.query().$promise;
				},
				roleClaim: roleClaimResolver
			}
		});

		// DETAILS
		// -------
		$stateProvider.state('root.system.role.edit.role', {
			controller: 'SystemRoleDetailsController as vm',
			templateUrl: SystemRoleDetailsTemplate,
			data: {
				ncyBreadcrumbLabel: 'Role',
				access: {action: 'GET', resource: 'SystemConfiguration'}
			}
		});

		// AUTHORIZATIONS
		// --------------
		$stateProvider.state('root.system.role.edit.authorizations', {
			controller: 'SystemRoleAuthorizationsController as vm',
			templateUrl: SystemRoleAuthorizationsTemplate,
			data: {
				ncyBreadcrumbLabel: 'Authorization',
				access: {action: 'GET', resource: 'SystemConfiguration'}
			}
		});

		// DEPENDS
		// -------
		$stateProvider.state('root.system.role.edit.depends', {
			controller: 'SystemRoleDependsController as vm',
			templateUrl: SystemRoleDependsTemplate,
			data: {
				ncyBreadcrumbLabel: 'Depends',
				access: {action: 'GET', resource: 'SystemConfiguration'}
			}
		});

		// PUSH TO ACCOUNT
		// ---------------
		$stateProvider.state('root.system.role.edit.push', {
			controller: 'SystemRolePushController as vm',
			templateUrl: SystemRolePushTemplate,
			data: {
				ncyBreadcrumbLabel: 'Push to account',
				access: {action: 'GET', resource: 'SystemConfiguration'}
			}
		});
	});
