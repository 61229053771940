import _each from 'lodash/each';

import errorHandler from './errorHandler';
import validateAndSetupConfiguration from './validateAndSetupConfiguration';
import queuePanel from './queuePanel';
import SoundFx from '../SoundFx';
import WebNotificationService from '../WebNotificationService';

import EventLoader from './eventLoader';
import CommandLoader from './commandLoader';
import VisitorHandler from './VisitorHandler';
import actionPanels from './actionPanels';
import ViewActions from '../actions/ViewActions';

import AngularInjector from '../../../common/AngularInjector';

var NotificationService;

export default function (DesktopService, IdentityService) {
	psPlugin.application = {
		name: 'desktop',
		modules: {},
		configuration: {},
		visitorFilter: {
			selectedGroups: [],
				selectedLanguages: [],
				availableForGroups: [],
				allGroups: [],
				proactiveLang: []
		},
		views: {},
		//stores
		openPanels: [],

		events: new EventLoader(),

		start: function () {

			psPlugin.legacyHasStopped = false;
			errorHandler.init();

			//Deprecate
			psPlugin.application.events.onOffline.subscribe('errorHandler', function (msg) {
				DesktopService.connectionStatus(1).then(onConnectionStatusChange, onConnectionStatusLost);
			});

			psPlugin.application.configuration = DesktopService.configuration;

			if ( validateAndSetupConfiguration() ) {

				//psPlugin.application.events.onConfigurationProcessed.trigger();
				psPlugin.application.setVisitorStateConditions();
				psPlugin.application.setLayoutAreas();

				for (var m in psPlugin.application.modules) {
					try {
						psPlugin.application.modules[m].load();
					} catch (err) {
						console.log({
							info: 'Module "' + m + '" load failed!',
							error: err
						});
					}
				}

				if (DesktopService.configuration.account.hideQueue !== true) {
					queuePanel.init(IdentityService, DesktopService);
					queuePanel.load();
				} else {
					let btn = document.createElement('BUTTON');
					btn.type = 'button';
					btn.className = 'btn btn-meeting-code legacy-tip legacy-tip-right';
					btn.innerHTML = '<i class="icon-meeting-code"></i>';
					btn.setAttribute('tip', psPlugin.application.writeOutLanguage('meetingCodeGenerate'));
					btn.addEventListener('click', function(e){
						e.preventDefault();
						ViewActions.askForStartCode();
					}, false);

					psPlugin.shell.pageLayout.onlineList.elem.appendChild(btn);
				}

				// Subscribe to events for Web Notifications API
				// TODO: don't subscribe unless specifically told that the user wants it
				psPlugin.application.events.onProactiveQueueListUpdated.subscribe('webNotification', function (list) {
					WebNotificationService.notify('proactiveUpdate', list);
				});

				//only notify if we have a visible queue
				if (DesktopService.configuration.account.hideQueue !== true) {
					psPlugin.application.events.onQueueListUpdated.subscribe('webNotification', function (list) {
						if (!DesktopService.configuration.account.muteDesktopAudio) {
							SoundFx.play('queue');
						}
						WebNotificationService.notify('queueUpdate', list);
					});
				}

				psPlugin.application.listener.start();
				psPlugin.shell.pageLayout.closeMessage();

			}

			return true;


		},

		setVisitorStateConditions: function () {
			var conditions = {
				Proactive: {
					Lock: function () {
						return true;
					},
					Begin: function () {
						return true;
					}
				},
				Queued: {
					Lock: function () {
						return true;
					},
					Begin: function () {
						return true;
					}
				},
				PendingApproval: {
					Approve: function () {
						return true;
					},
					Reject: function () {
						return true;
					},
					NotToday: function () {
						return true;
					}
				},
				InDialog: {
					EndDialog: function () {
						return true;
					}
				},
				Completed: {
					Close: function () {
						return true;
					}
				}
			};
			for (var state in conditions) {
				for (var transition in conditions[state]) {
					this.visitorStateDiagram.states[state].transitions[transition].conditions.push(conditions[state][transition]);
				}
			}
		},

		setLayoutAreas: function () {
			var contentAreas = [{
				workspace: {
					type: 'column',
					width: 'flexible',
					collapsable: false
				}
			}];

			//if (DesktopService.configuration.account.hideQueue !== true) {
			contentAreas.push({
				onlineList: {
					type: 'column',
					width: (DesktopService.configuration.account.hideQueue) ? 40 : 50,
					collapsable: (DesktopService.configuration.account.hideQueue) ? false : true,
					expandableOn: 'onmouseover',
					collapsableOn: 'onmouseout',
					expandWidth: 400
				}
			});
			//}

			psPlugin.shell.pageLayout.setContentAreas(contentAreas);
		},

		close: function () {
			try {
				this.listener.stop();
			} catch (err) {
				console.error('cannot close application', err);
			}
			this.openPanels.forEach(function (panel) {
				try {
					panel.close();
				} catch (err) {
					console.error('error closing panel', err);
				}
			});
		},

		restart: function () {
			this.close();
		}

	};

	psPlugin.application.events.addEvents({
		onConfigurationNotSufficient: [],
		onNewChatMessage: [],
		onQueueList: [],
		onQueueListUpdated: [],
		onProactiveList: [],
		onProactiveQueueListUpdated: [],
		onLockedList: [],
		onListenerResponse: [],
		onVisitorLoaded: [],
		onVisitorLocked: [],
		onCoWorkerLoaded: [],
		onInterval: [],
		onQueueStatusUpdated: [],
		onOffline: [] //Deprecate
	});

	psPlugin.application.commands = new CommandLoader();
	psPlugin.application.commands.addCommands({

		getQueueStatus: {
			canExecute: function () {
				return true;
			},
			execute: function (callback) {
				psPlugin.application.listener.checkGroupQueueStatuses(function (status) {
					try {
						var languages = {},
							langs = psPlugin.application.configuration.desktop.languages,
							lang,
							l,
							g,
							u,
							group,
							totalVisitors = 0,
							totalUsers = [],
							totalLangUsers,
							users = {},
							user;

						_each(langs, function (lang, langId) {
							languages[langId] = {
								name: lang.name,
								groups: {},
								totalVisitors: 0,
								totalLangUsers: 0
							};
							totalLangUsers = [];
							_each(lang.groups, function (group) {

								languages[langId].groups[group.id] = {
									name: group.name
								};
								if (typeof status[group.id] !== 'undefined') {

									_each(status[group.id].users, function (userId) {
										if (totalLangUsers.indexOf(userId) === -1) {
											totalLangUsers.push(userId);
										}
										if (totalUsers.indexOf(userId) === -1) {
											totalUsers.push(userId);
										}

										if (!users[userId]) {
											users[userId] = {
												//TODO: map name from usersGet
												name: userId,
												online: {}
											};
										}
										if (users[userId].online[languages[langId].name]) {
											users[userId].online[languages[langId].name].push(group.name);
										} else {
											users[userId].online[languages[langId].name] = [group.name]
										}
									});
									if (!lang.totalVisitors) lang.totalVisitors = 0;
									lang.totalVisitors += status[group.id].numberOfVisitors;
									totalVisitors += status[group.id].numberOfVisitors;
									var grp = languages[langId].groups[group.id];
									grp.numberOfVisitors = status[group.id].numberOfVisitors;
									grp.availableUserNames = status[group.id].users;
									grp.availableUsers = status[group.id].users.length;
								}
							});

							languages[langId].totalLangUsers = totalLangUsers.length;

						});


						status = {
							totalUsers: totalUsers.length,
							totalVisitors: totalVisitors,
							languages: languages,
							users: users
						};
						callback(status);
					} catch (err) {
						console.error(err);
						callback('ERROR');
					}
				});
			}
		}
	});



	psPlugin.application.visitorHandler = VisitorHandler;
	psPlugin.application.actionPanel = actionPanels;
};
