export default function ($uibModalInstance, options, removalService, texts) {
	'ngInject';

	var vm = this;
	vm.options = options;
	vm.texts = texts;
	vm.remove = remove;
	vm.cancelNew = $uibModalInstance.dismiss;

	function remove() {
		vm.isSaving = true;
		$uibModalInstance.freeze(true);
		removalService.remove(options.itemToRemove, options.itemType).then(function () {
			$uibModalInstance.close();
		}).catch(function () {
			vm.isSaving = false;
			$uibModalInstance.freeze(false);
		});
	}
}
