import React from 'react';
import CopyMessage from './CopyMessage.react';
import MessageLink from './MessageLink.react';
import MessagePdf from './MessagePdf.react';
import MessagePhoto from './MessagePhoto.react';
import MessageVideo from './MessageVideo.react';
import MessageRender from './MessageRender.react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const MessageLinkTypes = (props) => {
	const tooltipLink = <Tooltip id="tooltip">{props.txt}</Tooltip>;
	const linkType = props.linkType;

	let link;
	let copyMessageEnabled = true;

	switch (linkType) {
		case 'link':
			link = <MessageLink {...props} />;
			break;
		case 'pdf':
			link = <MessagePdf {...props} />;
			break;
		case 'photo':
			link = <MessagePhoto {...props} />;
			break;
		case 'video':
			link = <MessageVideo {...props} />;
			break;
		case 'render':
			link = <MessageRender {...props} />;
			copyMessageEnabled = false;
			break;
	}

	return (
		<div>
			<OverlayTrigger placement="top" overlay={tooltipLink}>
				{link}
			</OverlayTrigger>
			{copyMessageEnabled
				? <CopyMessage txt={props.txt}/>
				: null}
		</div>
	)
};
export default MessageLinkTypes

