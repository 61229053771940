import React from 'react';
import Timestamp from './Timestamp.react';

const MessagePhoto = (props) => {
	const linkType = props.linkType;

	return (
		<div className="message lineClampEllipsis">
			<strong className="role">{props.speaker}: </strong>
			<i className={linkType}/>
			<span className="title">{props.title}</span>
			<Timestamp
				timestamp={props.timestamp}
				isMostRecentMessage={props.isMostRecentMessage}
				displayRelativeDialogTime={props.displayRelativeDialogTime}
			/>
			<a href={props.txt} target="_blank"><img width="250px" src={props.txt}/></a>
		</div>
	)
};
export default MessagePhoto
