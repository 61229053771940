import immutable from 'immutable';
import INITIAL_STATE from '../../INITIAL_STATE';
import AccountConfig from '../../utils/AccountConfig';
import helpers from './PluginSubscriptionHelpers';
import DesktopPluginAPI from '../../pluginAPI/DesktopPluginAPI'

export default {
	getCurrentSubscriptions(plugin, prevState, state, reset = false) {
		const diffState = reset ? INITIAL_STATE: prevState;
		const actualApiVersion = plugin.get('actualApiVersion');
		return plugin.get('enabledSubscriptions')
			.reduce((subs, name) => {
				const subFunction = DesktopPluginAPI.getSubscription(actualApiVersion, name);
				const sub = subFunction(plugin, diffState, state, reset);
				// const sub = this.subscriptions[name](plugin, diffState, state, reset);
				return subs.concat(sub);
			}, immutable.List());
	},
}

