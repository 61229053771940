import React from 'react';
import {connect} from 'react-redux';
import ServerActions from '../../../actionCreators/ServerActions';
import {Button} from 'react-bootstrap';
import TimeFormat from '../../../utils/TimeFormat';

export const MessageHistoryMarker = (props) => {
	const onCLick = () => {
		props.expandHistoryMarker(props.conversationId, props.messageData.caseId);
	};
	const date = TimeFormat.asJustDate(props.timestamp);
	const time = TimeFormat.asJustTime(props.timestamp);
	return (
		<div>
			<Button className="btn" variant="primary" size="xs" onClick={onCLick}>
				<span><i className="vngage-icon-history"/>{date}</span>
				<span>{time}</span>
			</Button>
		</div>
	)
};


function mapStateToProps(state, ownProps) {
	return {};
}

function mapDispatchToProps(dispatch) {
	return {
		expandHistoryMarker: (conversationId, caseId) => dispatch(ServerActions.expandHistoryMarker(conversationId, caseId)),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MessageHistoryMarker)
