import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';

export default function($scope, $uibModalInstance, vngageGuid, translations) {
	'ngInject';

	const vm = this;
	vm.translations = _cloneDeep(_pick(translations, ['translations', 'custom', 'errorTranslations']));
	vm.translationsText = JSON.stringify(vm.translations, null, 2);

	vm.close = () => {
		$uibModalInstance.close();
	};
	vm.cancel = $uibModalInstance.dismiss;

	vm.copy = () => {
		try {
			document.getElementById('bannerTranslationsExportJSON').select();
			document.execCommand('copy');
			$uibModalInstance.close();
		} catch (e) {
			// Will fail for old browsers (IE)
		}
	};
}
