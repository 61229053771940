export default function(VngageUtil) {
    'ngInject';

    return {
        restrict: 'A',
        compile: function(tElem, tAttrs) {
            VngageUtil.getAllFormEls(tElem).forEach(function(currentEl) {
                var closestForm = VngageUtil.getClosestParentElem(tElem, 'form');

                if (closestForm && (currentEl.type === 'submit' || currentEl.name.indexOf('submit') !== -1)) {
                    var formName = closestForm.attr('name');
                    VngageUtil.addOrConditionToAttribute(currentEl, 'ng-disabled', formName + '.$invalid');
                    if (tAttrs.vngageSubmitButtons !== 'enabledPristine') {
                        VngageUtil.addOrConditionToAttribute(currentEl, 'ng-disabled', formName + '.$pristine');
                    }
                }
            });
        }
    };
};
