const MAX_LENGTH = 1000;


const validateText = (text, formatObj) => {
	const dummy = {
		'label': 'Name',
		'name': 'Name',
		'required': 'required',
		'errorMessage': {
			'header': 'Name',
			'content': 'In order to close this case you have to fill in Name!'
		},
		'type': 'text'
	};
	if (formatObj.required) {
		return text.length > 0;
	} else {
		return true;
	}
};
const replaceWithCaseId = (incomingForms, caseId) => {
	const re = /{(.+?)}/g;
	return incomingForms.map(form => {
		return {
			...form,
			formFields: form.formFields.map(f => {
				return {
					...f,
					value: f.value.replace(re, caseId)
				};
			})
		}
	});
};

const validateFormFields = (incomingForms) => {
	return incomingForms.map(form => {
		return {
			...form,
			isValid: form.formFields.every(f => f.isValid)
		}
	});
};

// form value validation functions
const ruleFunctions = {
	notEmpty: (str, rule) => str.length > 0 ? 'ok': rule.errorMsg,
	rightLength: (str, rule) => {
		return str.length >= rule.value[0] && str.length <= rule.value[1]
			? 'ok'
			: rule.errorMsg;
	},
	rightFormat: (str, rules) => {
		return rules.reduce((status, rule) => {
			return status !== 'ok'
				? status
				: rule.value.test(str)
					? 'ok'
					: rule.errorMsg;

		}, 'ok')
	}
};

// validate form value by rule, used for inContact
const validate = (fieldValue, rule) => {
	return Object.keys(rule).reduce((status, key) => {
		return status === 'ok'
			? ruleFunctions[key](fieldValue, rule[key])
			: status;
	}, 'ok');

};


//
// visitorProfileForm functions
//

const updateFieldValue = (type, newValue, fields) => {
	const claimIndex = fields.findIndex(claim => claim.type === type);
	const claimData = {...fields[claimIndex]};
	if (claimData.status !== 'new') {
		claimData.status = claimData.values[0] === newValue ? 'original': 'updated';
	}
	claimData.updatedValue = newValue;

	return [
		...fields.slice(0, claimIndex),
		claimData,
		...fields.slice(claimIndex + 1)
	];
};

const addField = (type, value, fields) => {
	return [...fields, {
		status: 'new',
		type: type.trim(),
		values: [value],
		updatedValue: value,
		source: 'http://schemas.vergic.com/api/visitorClaimSource/Visitor'
	}];
};

const removeField = (type, fields) => {
	const claimIndex = fields.findIndex(claim => claim.type === type);
	return [
		...fields.slice(0, claimIndex),
		...fields.slice(claimIndex + 1)
	];

};

const fieldsAreUpdated = (fields) => {
	return fields.some(field => field.status !== 'original');
};

const resetFieldValue = (type, fields) => {
	const claimIndex = fields.findIndex(claim => claim.type === type);
	const claimData = {...fields[claimIndex]};
	claimData.updatedValue = claimData.values[0];
	claimData.status = 'original';

	return [
		...fields.slice(0, claimIndex),
		claimData,
		...fields.slice(claimIndex + 1)
	];
};

const isFieldTypeValid = (type, fields) => {
	const trimmedType = type.trim();
	const okLength = trimmedType.length > 1;
	const alreadyInUse = fields.some(field => field.type === trimmedType);
	return okLength && !alreadyInUse;
};

const isFieldValueValid = (value) => {
	const trimmedValue = value.trim();
	return value.length > 1;
};

export default {
	validateText,
	replaceWithCaseId,
	validateFormFields,
	ruleFunctions,
	validate,
	updateFieldValue,
	addField,
	removeField,
	fieldsAreUpdated,
	resetFieldValue,
	isFieldTypeValid,
	isFieldValueValid
}
