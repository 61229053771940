import React from 'react';
import {connect} from 'react-redux';
import QueueActions from '../../actions/QueueActions';
import UserPreferencesAC from '../../actionCreators/UserPreferencesAC';
import ServerActions from '../../actionCreators/ServerActions';
import ConversationActions from '../../actions/ConversationActions';
import Visitor from './Visitor.react.js';
import MetaBar from './MetaBar.react.js';
import StartCodeModal from './StartCodeModal.react';
import DesktopSettings from './DesktopSettings.react';
import PluginPanel from '../plugin/PluginPanel.react';
import {getSelectedPluginWidth} from '../../selectors/PluginSelectors';

const pinnedWidth = 250;
const unPinnedWidth = 100;

export const QueuePanelView = (props) => {
	const {
			  visitors,
			  counters,
			  selectedPluginWidth,
			  windowDim,
			  ...availability
		  } = props;


	const showStartCodeView = () => {
		props.getStartCode();
		props.toggleStartCodeView();
	};

	const queuedVisitors = visitors.filter(visitor => !props.panelOrder.includes(visitor.get('id')))
		.map(visitor => {
			const conversationId = visitor.get('id');
			return (
				<Visitor
					key={conversationId}
					visitor={visitor}
				/>
			);
		});
	let currentModal = null;
	if (props.displayStartCode) {
		const startCodePayload = {
			toggleStartCodeView: props.toggleStartCodeView,
			startCode: props.startCode,
			getStartCode: props.getStartCode
		};
		currentModal = <StartCodeModal {...startCodePayload}/>;
	}
	if (props.displayUserPreferences) {
		const userPreferencesPayload = {
			setTextSize: props.setTextSize,
			toggleSystemMessages: props.toggleSystemMessages,
			toggleActiveInfoTip: props.toggleActiveInfoTip,
			textSize: props.textSize,
			displayUserPreferences: props.displayUserPreferences,
			displaySystemMessages: props.displaySystemMessages,
			userPreferences: props.userPreferences,
			toggleUserPreferences: props.toggleUserPreferences
		};
		currentModal = <DesktopSettings {...userPreferencesPayload}/>;
	}

	// ub-track
	const ubData = `queue-area:${props.queuePanelPinned ? 'pined': 'not-pined'}`;

	const pinAreaCls = `queue-pin-area ${props.queuePanelPinned ? 'pined': 'not-pined'}`;
	const pinArea = <div onClick={props.setQueuePanelPosition} className={pinAreaCls}
						 ub-track={ubData}/>;

	const containerWidth = props.queuePanelPinned ? pinnedWidth: unPinnedWidth;

	const containerStyle = {
		width: `${containerWidth + selectedPluginWidth}px`
	};

	return (
		<div style={containerStyle} className="queue-container">
			<PluginPanel {...windowDim}/>
			<div className="queue-panel">
				<MetaBar {...availability} counters={counters} showStartCodeView={showStartCodeView}/>
				{queuedVisitors}
				{currentModal}
				{pinArea}
			</div>
		</div>
	);

};

function mapStateToProps(state) {
	// incoming props
	// windowDim
	return {
		visitors: state.get('visitors'),
		counters: state.get('counters'),
		queuePanelPinned: state.getIn(['userPreferences', 'queuePanelPinned']),
		displayUserPreferences: state.get('displayUserPreferences'),
		displayGroupStatus: state.get('displayGroupStatus'),
		textSize: state.getIn(['userPreferences', 'textSize']),
		displaySystemMessages: state.getIn(['userPreferences', 'displaySystemMessages']),
		userPreferences: state.get('userPreferences'),
		displayStartCode: state.get('displayStartCode'),
		startCode: state.get('startCode'),
		panelOrder: state.get('panelOrder'),
		selectedPluginWidth: getSelectedPluginWidth(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		toggleUserPreferences: () => dispatch(QueueActions.toggleUserPreferences()),
		toggleGroupStatus: () => dispatch(QueueActions.toggleGroupStatus()),
		setMessageFilter: (conversationId, filter) => dispatch(ConversationActions.setMessageFilter(conversationId, filter)),
		toggleStartCodeView: () => dispatch(QueueActions.toggleStartCodeView()),

		// Action Creators
		getStartCode: () => dispatch(ServerActions.getStartCode()),
		setQueuePanelPosition: () => dispatch(UserPreferencesAC.setQueuePanelPosition()),
		setTextSize: (textSize) => dispatch(UserPreferencesAC.setTextSize(textSize)),
		toggleActiveInfoTip: (infoType) => dispatch(UserPreferencesAC.toggleActiveInfoTip(infoType)),
		toggleSystemMessages: (systemMessagesState) => dispatch(UserPreferencesAC.toggleSystemMessages(systemMessagesState)),
	};
}

QueuePanelView.displayName = 'QueuePanelView';
export default connect(mapStateToProps,
	mapDispatchToProps)(QueuePanelView);

