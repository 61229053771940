import GuidGenerator from '../../../components/utils/GuidGenerator';
import immutable from 'immutable';
import LogActions from '../actions/LogActions';
import ConversationActions from '../actions/ConversationActions';
import QueueActions from '../actions/QueueActions';
import VisitorProfileActions from '../actions/VisitorProfileActions';
import Conversation from '../actionCreators/Conversation';
import WebAPIUtilsFilters from '../utils/WebAPIUtilsFilters';
import AccountConfig from '../utils/AccountConfig';
import WindowWrapper from '../utils/WindowWrapper';
import Format from '../utils/Format';
import FlowStates from '../constants/FlowStates';
import ServerRequests from './ServerRequests';
import Localized from '../actionCreators/Localized';
import {conversationReducerLib} from '../reducers/libs/conversationReducerLib';
import Routing from '../utils/Routing';
import VideoTools from '../utils/VideoTools';
import VisitorProfileSelectors from '../selectors/VisitorProfileSelectors';

const animateOutTime = 500;

//
// ASYNC REQUESTS
//
const startConversation = (conversationId) => {
	return async (dispatch, getState) => {
		try {
			await ServerRequests.startConversation(conversationId);
		} catch (error) {
			dispatch(conversationError(conversationId, 'startConversation', error));
		}
	};
};

const closeConversation = (conversationId) => {
	return async (dispatch, getState) => {
		const conversationStatus = getState().getIn(['conversations', conversationId, 'conversationStatus']);
		if (conversationStatus === 'closed') {
			// if conversation is already closed, do nothing.
			return;
		}
		try {
			await ServerRequests.closeConversation(conversationId);
		} catch (error) {
			if (error && error.status !== 409) {
				// Only log error if other than 409 (which is "conflict", 'Conversation is not open, cannot close')
				// 409 happens if "/Close" was called more than once (maybe due to a "Disconnect" or "Ban")
				// I.e. not critical, and this didn't result in an error before
				dispatch(LogActions.netError(0, 'closeConversation', {
					error, conversationId
				}));
			}
		}
	};
};

const getConversation = (conversationId) => {
	return async (dispatch, getState) => {
		try {
			const conversationState = await ServerRequests.getConversation(conversationId);
			dispatch(Conversation.receiveAsState(conversationId, conversationState));
			const agentInvited = conversationReducerLib.getAgentParticipantState(getState(), conversationId) === 'invited';
			if (agentInvited) {
				await dispatch(startConversation(conversationId));
				dispatch(LogActions.netError(0, 'getConversation', {
					error: 'open conversation with invited agent',
					conversationId
				}));
			}
		} catch (error) {
			dispatch(conversationError(conversationId, 'getConversation', error));
		}
	};
};

const getRoutingParams = (conversationId) => {
	return async (dispatch, getState) => {
		try {
			const conversationState = await ServerRequests.getConversation(conversationId);
			dispatch(ConversationActions.receiveAsState(conversationId, conversationState));
		} catch (error) {
			dispatch(conversationError(conversationId, 'getRoutingParams', error));
		}
	};
};

// tested in conversationWrapUp-spec
const addHistoryMarkers = (conversationId) => {
	return async (dispatch, getState) => {
		const visitorProfile = VisitorProfileSelectors.getVisitorProfile(getState(), conversationId);
		const customerId = VisitorProfileSelectors.getCustomerId(visitorProfile); //|| '2421627944594778';
		const enableConversationHistory = !!getState().getIn(['account', 'enableConversationHistory']);
		if (enableConversationHistory && customerId) {
			try {
				const caseHistory = await ServerRequests.getCaseHistory(customerId);
				dispatch(ConversationActions.addHistoryMarkers(conversationId, caseHistory.reverse()));
			} catch (error) {
				dispatch(LogActions.netError(0, 'addHistoryMarkers', {
					error, conversationId, customerId
				}));
			}
		}
	};
};

const expandHistoryMarker = (conversationId, caseId) => {
	return async (dispatch, getState) => {
		try {
			const caseData = await ServerRequests.getCase(caseId);
			dispatch(ConversationActions.expandHistoryMarker(conversationId, caseId, caseData));
		} catch (error) {
			dispatch(LogActions.netError(0, 'expandHistoryMarker', {
				error, conversationId, caseId
			}));
		}
	};
};

const getVisitorProfile = (conversationId) => {
	return async (dispatch, getState) => {
		try {
			// get visitor profile
			const visitId = getState().getIn(['conversations', conversationId, 'visitId']);
			if (visitId && visitId !== '00000000-0000-0000-0000-000000000000') {
				// Only try to get visitor profile if a proper 'visitId' exists (may be missing in auto-started booked meetings, before the visitor joins)

				const visitorProfileRaw = await ServerRequests.getVisitorProfile(visitId);
				dispatch(VisitorProfileActions.addVisitorProfile(WebAPIUtilsFilters.getAsVisitorProfile(visitorProfileRaw)));

				// get visitor profile navigation
				const visitorNavigation = await ServerRequests.getVisitorNavigation(visitId);
				dispatch(VisitorProfileActions.setVisitorProfileNavigation(visitId, AccountConfig.getURLInfoArray(visitorNavigation)));
			}
		} catch (error) {
			dispatch(LogActions.netError(0, 'getVisitorProfile', {error, conversationId}));
		}
	};
};

const getConversationAndVisitorProfile = (conversationId) => {
	return async (dispatch, getState) => {
		const flowState = getState().getIn(['conversations', conversationId, 'flowState']);
		if (flowState !== FlowStates.ERROR) {
			await dispatch(getConversation(conversationId));
			await dispatch(getVisitorProfile(conversationId));
		}
	};
};

//
// HELPERS
//

const conversationError = (conversationId, callName, error) => (dispatch, getState) => {
	dispatch(ConversationActions.setConversationError(conversationId, Format.asErrorObject(callName, error)));
	dispatch(ConversationActions.setFlowState(conversationId, FlowStates.ERROR));
};

const routedConversationWrapUp = (conversationId) => (dispatch, getState) => {
	const flowState = getState().getIn(['conversations', conversationId, 'flowState']);
	if (flowState === FlowStates.ERROR) {
		// if error remove conversation
		dispatch(ConversationActions.removeConversation(conversationId));
	} else {
		dispatch(ConversationActions.setFlowState(conversationId, FlowStates.WAITING_FOR_USER_START));
	}
};

const isRtcSupported = (state, conversationId) => {
	const conversation = state.getIn(['conversations', conversationId]);
	const visitId = conversation.get('visitId');
	const visitorProfile = state.getIn(['visitorProfiles', visitId]) || immutable.Map();
	const visitorMeta = visitorProfile.get('meta') || immutable.Map();
	const visitorBrowserName = visitorMeta.getIn(['browser', 'name']);
	const visitorBrowserVer = visitorMeta.getIn(['browser', 'version']);
	return VideoTools.isVisitorBrowserSupported(visitorBrowserName, visitorBrowserVer) && VideoTools.isAgentBrowserSupported();
};

const isVideoEnabled = (state, conversationId) => {
	return AccountConfig.getEnableVideoChat() && isRtcSupported(state, conversationId);
};

const isWebCallEnabled = (state, conversationId) => {
	return isRtcSupported(state, conversationId) && AccountConfig.getEnableWebCall();
};

const isConversationAsync = (state, conversationId) => {
	const conversation = state.getIn(['conversations', conversationId]);
	const visitId = conversation.get('visitId');
	const visitorProfile = state.getIn(['visitorProfiles', visitId]) || immutable.Map();
	const visitorMeta = visitorProfile.get('meta') || immutable.Map();
	const channel = visitorMeta.getIn(['os', 'channel']) || '';
	return !!channel;
};

const setConversationCapabilities = (conversationId) => (dispatch, getState) => {
	// group features, file upload
	const groupId = getState().getIn(['conversations', conversationId, 'groupId']);
	const groupFeatures = AccountConfig.getGroupFeatures(groupId);
	const asyncConversation = isConversationAsync(getState(), conversationId);

	const enabled = {
		coBro: !asyncConversation,
		video: isVideoEnabled(getState(), conversationId) && !asyncConversation,
		webCall: isWebCallEnabled(getState(), conversationId) && !asyncConversation,
		putAway: asyncConversation
	};
	const capabilities = {
		groupFeatures,
		enabled
	};
	dispatch(ConversationActions.setConversationCapabilities(conversationId, capabilities));
};

const standardWrapUp = (conversationId) => {
	return async (dispatch, getState) => {
		const flowState = getState().getIn(['conversations', conversationId, 'flowState']);
		if (flowState !== FlowStates.ERROR) {
			// conversation is ready
			await dispatch(addHistoryMarkers(conversationId));
			dispatch(Conversation.addClaimsMessage(conversationId));
			dispatch(setConversationCapabilities(conversationId));
			dispatch(ConversationActions.conversationReady(conversationId));
		}
	};
};

// wrap up conversation and do post finnish actions
const conversationWrapUp = (conversationId) => {
	return async (dispatch, getState) => {
		await dispatch(standardWrapUp(conversationId));
		dispatch(Conversation.afterConversationReady(conversationId));
	};
};

const sendTransferToGroupDebounced = (conversationId, groupId) => {
	const thunk = async (dispatch, getState) => {
		try {
			await ServerRequests.transferToGroup(conversationId, groupId);
		} catch (error) {
			dispatch(LogActions.netError(0, 'sendTransferToGroupDebounced', {
				error,
				conversationId,
				groupId,
			}));
			// something went wrong, remove conversation
			dispatch(ConversationActions.removeConversation(conversationId));
		}
	};

	thunk.meta = {
		debounce: {
			time: animateOutTime,
			key: 'transferToGroup' + conversationId
		}
	};
	return thunk;
};

const sendCloseCaseAndConversationDebounced = (conversationId, caseId, closureObj) => {
	const thunk = async (dispatch, getState) => {
		try {
			await dispatch(closeConversation(conversationId));
			await ServerRequests.closeCase(caseId, closureObj);
			await ServerRequests.leaveConversation(conversationId);
		} catch (error) {
			dispatch(LogActions.netError(0, 'sendCloseCaseAndConversationDebounced', {
				error,
				conversationId,
				caseId,
				closureObj
			}));
			// something went wrong, remove conversation
			dispatch(ConversationActions.removeConversation(conversationId));
		}
	};

	thunk.meta = {
		debounce: {
			time: animateOutTime,
			key: 'closeCaseAndConversation' + conversationId
		}
	};
	return thunk;
};

//
// POLL ACTION HELPERS
//
// initialize auto routed conversation
const initRoutedConversation = (conversationId) => {
	return async (dispatch, getState) => {
		dispatch(ConversationActions.initRoutedConversation(conversationId));

		await dispatch(getRoutingParams(conversationId));

		dispatch(routedConversationWrapUp(conversationId));
	};
};


const resumeConversation = (conversationId) => {
	return async (dispatch, getState) => {
		dispatch(ConversationActions.resumeConversation(conversationId));

		await dispatch(getConversationAndVisitorProfile(conversationId));
		await dispatch(standardWrapUp(conversationId));
	};
};

const initAndStartRoutedConversation = (conversationId) => {
	return async (dispatch, getState) => {
		dispatch(ConversationActions.initAndStartRoutedConversation(conversationId));

		await dispatch(startConversation(conversationId));
		await dispatch(getConversationAndVisitorProfile(conversationId));

		const flowState = getState().getIn(['conversations', conversationId, 'flowState']);

		if (flowState === FlowStates.ERROR) {
			dispatch(ConversationActions.removeConversation(conversationId));
		} else {
			dispatch(conversationWrapUp(conversationId));
		}
	};
};

//
// ACTIONS
//
const startConversationFromQueue = (conversationId, groupId, startY) => {
	return async (dispatch, getState) => {
		dispatch(ConversationActions.startConversationFromQueue(conversationId, groupId, startY));

		await dispatch(startConversation(conversationId));
		await dispatch(getConversationAndVisitorProfile(conversationId));
		dispatch(conversationWrapUp(conversationId));
	};
};

const startRoutedConversation = (conversationId) => {
	return async (dispatch, getState) => {
		dispatch(ConversationActions.startRoutedConversation(conversationId));

		await dispatch(startConversation(conversationId));
		await dispatch(getConversationAndVisitorProfile(conversationId));
		dispatch(conversationWrapUp(conversationId));
	};
};

const transferToGroup = (conversationId, groupId) => {
	return async (dispatch, getState) => {
		dispatch(LogActions.logAc('transferToGroup', {conversationId, groupId}));
		await dispatch(Localized.sendTransferToGroupNote(conversationId, groupId));
		dispatch(ConversationActions.setPendingRemoval(conversationId));
		dispatch(Conversation.disposeVideo(conversationId));
		await dispatch(sendTransferToGroupDebounced(conversationId, groupId));
	};
};

const disconnect = (conversationId) => {
	return async (dispatch, getState) => {
		dispatch(LogActions.logAc('disconnect', {conversationId}));
		try {
			await dispatch(Localized.sendDisconnectNote(conversationId));
			dispatch(Conversation.disposeVideo(conversationId));
			await dispatch(closeConversation(conversationId));
		} catch (error) {
			dispatch(LogActions.netError(0, 'disconnect', {error, conversationId}));
		}
	};
};

const banParticipant = (conversationId, participantId) => {
	return async (dispatch, getState) => {
		dispatch(LogActions.logAc('banParticipant', {conversationId, participantId}));
		try {
			await dispatch(Localized.sendBlockedByAgentNote(conversationId));
			dispatch(Conversation.disposeVideo(conversationId));
			await ServerRequests.banParticipant(conversationId, participantId);
			await dispatch(closeConversation(conversationId));
		} catch (error) {
			dispatch(LogActions.netError(0, 'banParticipant', {
				error, conversationId, participantId
			}));
		}
	};
};

const getStartCode = () => {
	return async (dispatch, getState) => {
		dispatch(LogActions.logAc('getStartCode', {}));
		try {
			const response = await ServerRequests.getStartCode();
			// getStartCode uses post and ServerRequest post does not resolve .data
			// here we do it manually until this BE anomaly is solved
			const code = response.data || '';
			dispatch(QueueActions.setStartCode(code));
		} catch (error) {
			dispatch(LogActions.netError(0, 'getStartCode', {error}));
		}
	};
};

const closeCaseAndConversation = (conversationId, caseId, closureObj) => (dispatch, getState) => {
	dispatch(LogActions.logAc('closeCaseAndConversation', {
		conversationId, caseId, closureObj
	}));
	dispatch(ConversationActions.setPendingRemoval(conversationId));
	dispatch(Conversation.disposeVideo(conversationId));
	dispatch(sendCloseCaseAndConversationDebounced(conversationId, caseId, closureObj));
};

const changeCase = (conversationId, caseId, closureObj) => {
	return async (dispatch, getState) => {
		dispatch(LogActions.logAc('changeCase', {
			conversationId, caseId, closureObj
		}));
		try {
			await ServerRequests.closeCase(caseId, closureObj);

			// we don't use this response. Cases are updated by messages.
			const caseResponse = await ServerRequests.changeCase(conversationId);

			dispatch(Localized.sendCloseCaseNote(conversationId, closureObj));

		} catch (error) {
			dispatch(LogActions.netError(0, 'changeCase', {
				error, conversationId, caseId, closureObj
			}));
		}
	};
};

const emailConversationTranscript = (conversationId) => {
	return async (dispatch, getState) => {
		try {
			const conversation = getState().getIn(['conversations', conversationId]);
			const emailTranscriptConfig = AccountConfig.getEmailTranscriptConfig();
			const emailTranscriptSelected = conversation.getIn(['UI', 'emailConversationTranscript']);
			if (emailTranscriptConfig.alwaysEmail || emailTranscriptSelected) {
				dispatch(LogActions.logAc('emailConversationTranscript', {conversationId}));
				await ServerRequests.emailConversationTranscript(conversationId, emailTranscriptConfig.email);
			}
		} catch (error) {
			dispatch(LogActions.netError(0, 'emailConversationTranscript', {error, conversationId}));
		}
	};
};

const closeCase = (conversationId, closureObj) => {
	return async (dispatch, getState) => {
		dispatch(LogActions.logAc('closeCase', {conversationId, closureObj}));
		try {
			// email transcript
			await dispatch(emailConversationTranscript(conversationId));

			const conversation = getState().getIn(['conversations', conversationId]);
			const caseId = conversation.get('caseId');
			const newCaseAfterClose = conversation.getIn(['UI', 'newCaseAfterClose']);

			if (newCaseAfterClose) {
				// change case
				dispatch(changeCase(conversationId, caseId, closureObj));
			} else {
				// close case and conversation
				dispatch(closeCaseAndConversation(conversationId, caseId, closureObj));
			}

		} catch (error) {
			dispatch(LogActions.netError(0, 'closeCase', {
				error, conversationId, closureObj
			}));
		}
	};
};

const updateCaseType = (conversationId, caseObj) => {
	return async (dispatch, getState) => {
		dispatch(LogActions.logAc('updateCaseType', {conversationId, caseObj}));
		try {
			const conversation = getState().getIn(['conversations', conversationId]);
			const newCaseType = caseObj.id;
			const caseId = conversation.get('caseId');

			const caseName = caseObj.name || '';
			const update = Format.asCaseUpdate(newCaseType);

			await ServerRequests.updateCaseType(caseId, update);
			dispatch(ConversationActions.updateCaseType(conversationId, newCaseType));

			const forms = AccountConfig.getForms(newCaseType);
			dispatch(ConversationActions.setForms(conversationId, forms));
			dispatch(Localized.sendChangeCaseTypeNote(conversationId, caseName));

		} catch (error) {
			dispatch(LogActions.netError(0, 'updateCaseType', {
				error, conversationId, caseObj
			}));
		}
	};
};

const updateVisitorClaims = (visitId, claims) => {
	return async (dispatch, getState) => {
		const newClaims = VisitorProfileSelectors.getNewClaims(claims);
		const updatedClaims = VisitorProfileSelectors.getUpdatedClaims(claims);
		dispatch(LogActions.logAc('updateVisitorClaims', {
			visitId, newClaims, updatedClaims
		}));
		try {
			// updateVisitorClaims uses post and we need the response
			const profile = await ServerRequests.updateVisitorClaims(visitId, updatedClaims);
			dispatch(VisitorProfileActions.addVisitorProfile(WebAPIUtilsFilters.getAsVisitorProfile(profile.data)));

		} catch (error) {
			dispatch(LogActions.netError(0, 'updateVisitorClaims', {
				error, visitId, claims
			}));
		}
	};
};


const uploadContent = (conversationId, data, uploadInfo) => {
	return async (dispatch, getState) => {
		const {filename, mimeType, fileSize} = uploadInfo;
		dispatch(LogActions.logAc('uploadContent', {
			conversationId, filename, mimeType, fileSize
		}));

		// add local message
		const uploadInfoWithTag = {...uploadInfo, tag: GuidGenerator.create()};
		const formattedMessage = Format.asContentMessage(conversationId, uploadInfoWithTag);
		dispatch(ConversationActions.addLocalMessage(conversationId, formattedMessage));

		try {
			const result = await ServerRequests.uploadContent(conversationId, data, uploadInfoWithTag);
		} catch (error) {
			dispatch(LogActions.netError(0, 'uploadContent', {
				error, conversationId, filename, mimeType, fileSize
			}));
			const errorTag = Format.extractErrorType(error);
			dispatch(ConversationActions.updateLocalMessageStatus(conversationId, uploadInfoWithTag.tag, errorTag));
		}
	};
};

//
// POLL ACTIONS
//

// called from poll to handle new conversations
const handleNewConversations = () => {
	return async (dispatch, getState) => {
		const conversations = getState().get('conversations');
		// get new conversations
		const newConversationIds = conversations.filter(conversation => conversation.get('flowState') === FlowStates.NEW).keySeq().toArray();
		await Promise.all(newConversationIds.map(conversationId => {
			const conversation = getState().getIn(['conversations', conversationId]);
			const conversationStatus = conversation.get('conversationStatus');
			const requiresStart = Routing.requiresStart(conversation.get('routingType'));

			const compoundStatus = conversationStatus === 'open' || conversationStatus === 'closed'
				? conversationStatus
				: requiresStart
					? 'requiresStart'
					: 'autoStart';

			switch (compoundStatus) {
				case 'closed':
					// get conversation and visitor profile
					return dispatch(resumeConversation(conversationId));
				case 'requiresStart':
					// auto routed
					return dispatch(initRoutedConversation(conversationId));
				case 'autoStart':
					// external routing, startConversation like a normal one
					return dispatch(initAndStartRoutedConversation(conversationId));
				default:
					//case 'open':
					// get conversation and visitor profile
					return dispatch(resumeConversation(conversationId));
			}
		}));
	};
};

// called from poll to find new visitIds in conversations and get visitorProfiles & navigations for them
// (with booked meetings, a conversation may not have a visitId at the time the agent gets/takes them - it's added later when the visitor finally joins)
const checkVisitorProfiles = () => {
	return async (dispatch, getState) => {
		const visitorProfiles = getState().get('visitorProfiles');
		const conversations = getState().get('conversations');
		const conversationsWithMissingVisitorProfile = conversations.filter(conversation => {
			const visitId = conversation.get('visitId');
			return (visitId && visitId !== '00000000-0000-0000-0000-000000000000' && !visitorProfiles.has(visitId));
		}).keySeq().toArray();
		await Promise.all(conversationsWithMissingVisitorProfile.map(conversationId => {
			return dispatch(getVisitorProfile(conversationId));
		}));
		// set capabilities again to enable video and web call
		conversationsWithMissingVisitorProfile.forEach(conversationId => dispatch(setConversationCapabilities(conversationId)));
	};
};

//
// MISC
//
const cleanUpBeforeLeavingDesktop = (netId, hash) => {
	return async (dispatch, getState) => {
		dispatch(LogActions.logAc('cleanUpBeforeLeavingDesktop', {netId, hash}));
		const items = getState().get('panelOrder')
			.filter(conversationId => getState().getIn(['conversations', conversationId, 'flowState']) !== FlowStates.REMOVING)
			.map(conversationId => {
				return immutable.Map({
					conversationId,
					groupId: getState().getIn(['conversations', conversationId, 'groupId'])
				});
			}).toJS();

		await Promise.all(items.map(item => {
			return dispatch(transferToGroup(item.conversationId, item.groupId));
		}));

		dispatch(ConversationActions.cleanUpBeforeLeaving(netId));

		if (hash) {
			WindowWrapper.goToHashLocation(hash);
		}

	};
};

const putAwayConversationDebounced = (conversationId, caseId, reasonName) => {
	const thunk = async (dispatch, getState) => {
		try {
			await ServerRequests.putAwayConversation(caseId, reasonName);
		} catch (error) {
			dispatch(LogActions.netError(0, 'putAwayConversationDebounced', {
				error,
				conversationId,
				caseId,
				reasonName
			}));
			// something went wrong, remove conversation
			dispatch(ConversationActions.removeConversation(conversationId));
		}
	};

	thunk.meta = {
		debounce: {
			time: animateOutTime,
			key: 'closeCaseAndConversation' + conversationId
		}
	};
	return thunk;
};

const putAwayConversation = (conversationId, caseId, reasonName) => (dispatch, getState) => {
	dispatch(LogActions.logAc('putAwayConversation', {
		conversationId, caseId, reasonName
	}));
	dispatch(ConversationActions.setPendingRemoval(conversationId));
	dispatch(Conversation.disposeVideo(conversationId));
	dispatch(putAwayConversationDebounced(conversationId, caseId, reasonName));
};

export default {
	// ASYNC REQUESTS
	startConversation,
	closeConversation,
	getConversation,
	addHistoryMarkers,
	expandHistoryMarker,
	getVisitorProfile,
	getConversationAndVisitorProfile,

	// HELPERS
	conversationError,
	routedConversationWrapUp,
	setConversationCapabilities,
	standardWrapUp,
	conversationWrapUp,
	sendTransferToGroupDebounced,
	putAwayConversationDebounced,

	// POLL ACTION HELPERS
	initRoutedConversation,
	resumeConversation,
	initAndStartRoutedConversation,

	// ACTIONS
	startConversationFromQueue,
	startRoutedConversation,
	transferToGroup,
	disconnect,
	banParticipant,
	getStartCode,
	closeCaseAndConversation,
	changeCase,
	emailConversationTranscript,
	closeCase,
	updateCaseType,
	updateVisitorClaims,
	uploadContent,
	putAwayConversation,
	// POLL ACTIONS
	handleNewConversations,
	checkVisitorProfiles,

	// MISC
	cleanUpBeforeLeavingDesktop,
};

