export default {
    'en-GB': {
        actionPanel: {
            ADD_HEADER: 'New action panel',
            EDIT_HEADER: 'Edit action panel',
			EDIT_DESCRIPTION: 'An action panel groups actions for better overview and organization. Action panels are related to case types and are accessible in the desktop application',
            LIST_HEADER: 'Action panels',
			LIST_TITLE: 'List of action panels for working site',
			LIST_DESCRIPTION: 'Action panels are used to group actions for easier usage in the desktop application',
            NAME: 'Name',
            ACTIONS: 'Number of actions',
            DESCRIPTION: 'Description',
			EDIT_MULTISELECT_TITLE: 'Assign actions to this action panel',

            REMOVE_HEADER: 'Remove action panel "{{ name }}"',
            REMOVE_MESSAGE: 'Note that any existing references to this action panel from groups and case types also will be removed.'
        }
    },
	'sv-SE': {
		actionPanel: {
			ADD_HEADER: 'New action panel',
			EDIT_HEADER: 'Edit action panel',
			EDIT_DESCRIPTION: 'An action panel groups actions for better overview and organization. Action panels are related to case types and are accessible in the desktop application',
			LIST_HEADER: 'Action panels',
			LIST_TITLE: 'List of action panels for working site',
			LIST_DESCRIPTION: 'Action panels are used to group actions for easier usage in the desktop application',
			NAME: 'Name',
			ACTIONS: 'Number of actions',
			DESCRIPTION: 'Description',
			EDIT_MULTISELECT_TITLE: 'Assign actions to this action panel',

			REMOVE_HEADER: 'Remove action panel "{{ name }}"',
			REMOVE_MESSAGE: 'Note that any existing references to this action panel from groups and case types also will be removed.'
		}
	}
};
