import React, {useState, useEffect, useRef} from 'react';
import FormatDuration from '../../../../common/formatDuration.js';
import DateWrapper from '../../utils/DateWrapper';

const DialogDurationTimer = (props) => {
	const [duration, setDuration] = useState(0);
	const timer = useRef();
	const {startTime, isClosed} = props;

	useEffect(() => {
		const tick = () => {
			setDuration(Math.floor((DateWrapper.now() - startTime) / 1000));
		};

		if (!isClosed) {
			tick();
			timer.current = setInterval(tick, 1000);
		}
		return () => {
			clearInterval(timer.current);
		};
	}, [startTime, isClosed]);

	return <div className="duration fadeIn">{FormatDuration(duration * 1000)}</div>;

};
export default DialogDurationTimer;

