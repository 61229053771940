export default {
    'en-GB': {
        overview: {
            HEADER: 'Account overview',
            ACCOUNT_NAME: 'Account name',
            SUB_DOMAIN: 'Sub domain',
            STATS_USERS: 'Users',
            STATS_ROLES: 'Roles',
            STATS_IDP: 'ID Providers',
            STATS_TRUSTS: 'Trusts',
            DESCRIPTION: 'Description',
            VALIDATE_NAME: 'Minimum 2 characters for name',
            VALIDATE_SUBDOMAIN: 'subdomain (without suffix, minium chars 3)',
            UPDATE_SANDBOX: 'Development sandbox',
            SANDBOX_ID: 'Sandbox ID: ',
            SANDBOX_NAME: 'Sandbox name: ',
            UPDATE_SANDBOX_BUTTON: 'Update sandbox',
            ACCOUNT_ID: 'Account ID'
        }
    },
    'sv-SE': {
        overview: {
            HEADER: 'Account overview',
            ACCOUNT_NAME: 'Account name',
            SUB_DOMAIN: 'Sub domain',
            STATS_USERS: 'Users',
            STATS_ROLES: 'Roles',
            STATS_IDP: 'ID Providers',
            STATS_TRUSTS: 'Trusts',
            DESCRIPTION: 'Description',
            VALIDATE_NAME: 'Minimum 2 characters for name',
            VALIDATE_SUBDOMAIN: 'subdomain (without suffix, minium chars 3)',
            UPDATE_SANDBOX: 'Development sandbox',
            SANDBOX_ID: 'Sandbox ID: ',
            SANDBOX_NAME: 'Sandbox name: ',
            UPDATE_SANDBOX_BUTTON: 'Update sandbox',
            ACCOUNT_ID: 'Account ID'
        }
    }
};
