export default {
	'en-GB': {
		renderTemplate: {
			ADD_HEADER: 'New template',
			LIST_HEADER: 'Templates',
			EDIT_HEADER: 'Edit template',
			LIST_TITLE: 'List of templates for this account',
			LIST_DESCRIPTION: 'Templates that can be displayed by a visitor.',
			NAME: 'Name',
			CODE: 'Template code',
			DEFAULT_TEMPLATES_HEADER: 'Create from default template:',
			DEFAULT_TEMPLATE_NONE: '-- none --'
		}
	},
	'sv-SE': {
		renderTemplate: {
			ADD_HEADER: 'New template',
			LIST_HEADER: 'Templates',
			EDIT_HEADER: 'Edit template',
			LIST_TITLE: 'List of templates for this account',
			LIST_DESCRIPTION: 'Templates that can be displayed by a visitor.',
			NAME: 'Name',
			CODE: 'Template code',
			DEFAULT_TEMPLATES_HEADER: 'Create from default template',
			DEFAULT_TEMPLATE_NONE: '-- none --'
		}
	}
};
