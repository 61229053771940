import uiView from './view';

export default function (params, className) {
	var message = uiView.createNodes({
		reference: 'wrapper',
		tag: 'div',
		className: 'psPlugin_messageWrapper ' + (className || ''),
		children: [{
			reference: 'icon',
			tag: 'div',
			className: 'psPlugin_messageIcon' + params.icon
		}, {
			reference: 'text',
			tag: 'div',
			className: 'psPlugin_messageArea',
			children: [{
				reference: 'heading',
				tag: 'div',
				className: 'psPlugin_messageAreaHeading',
				innerHTML: params.heading
			}, {
				reference: 'content',
				tag: 'div',
				className: 'psPlugin_messageAreaContent',
				innerHTML: params.content
			}, {
				reference: 'buttons',
				tag: 'div',
				className: 'psPlugin_messageAreaButtons'
			}]
		}]
	});
	for (var b in params.buttons) {
		var button = document.createElement('button');
		button.innerHTML = params.buttons[b].text;
		button.onclick = params.buttons[b].onclick;
		message.buttons.appendChild(button);
	}
	return message.wrapper;
};
