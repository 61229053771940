
// Not a typo! [A-z] matches char code 65 to 122 (same as [A-Za-z])
var guidPattern = /[A-z0-9]{8}-([A-z0-9]{4}-){3}[A-z0-9]{12}/g;

function upperCaseIt(string, pattern) {
	return string.replace(pattern, function x(){
		return (arguments[0]).toUpperCase();
	});
}

function normalizeGuid(response) {
	return JSON.parse(upperCaseIt(JSON.stringify(response), guidPattern));
}

export default normalizeGuid;
