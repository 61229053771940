export default function($uibModalInstance, APIEndpointService) {
    'ngInject';

    var vm = this;
    vm.newData = {};
    vm.saveNew = saveNew;
    vm.cancelNew = $uibModalInstance.dismiss;

    function saveNew() {
        vm.isSaving = true;
		$uibModalInstance.freeze(true);

        APIEndpointService.systemConfRoleClaim.create(vm.newData).$promise
            .then(function(res) {
				$uibModalInstance.close(res);
            })
            .catch(function() {
				$uibModalInstance.freeze(false);
                vm.isSaving = false;
            });
    }
};
