import uiView from './view';

function notification (params) {
	//var test = new psPlugin.core.ui.notification({
	//	title: 'Some warning',
	//	content: 'You need to fill out the form field!!!',
	//	top: 300,
	//	left: 800
	//});
	var conf = {
		content: params.content || false,
		title: params.title || false,
		showAt: params.showAt || false,
		parent: params.parent || document.getElementsByTagName('body')[0],
		top: params.top || 0,
		left: params.left || 0,
		position: params.position || 'absolute'
	};

	var pos = [conf.left, conf.top];
	var layout = uiView.createNodes(helpers.view);
	var el = conf.showAt;

	layout.wrapper.style.position = conf.position;
	layout.wrapper.style.display = 'block';

	if (el) {
		pos = psPlugin.shell.jsExt.findPos(el);
		pos[1] = pos[1] + el.offsetHeight;
	}
	layout.wrapper.style.left = pos[0] + 'px';
	layout.wrapper.style.top = pos[1] + 'px';


	if (conf.title) layout.title.innerHTML = conf.title;
	if (conf.content) layout.content.innerHTML = conf.content;

	conf.parent.appendChild(layout.wrapper);

	var close = function (e) {
		conf.parent.removeChild(layout.wrapper);
		document.body.removeEventListener('click', close);
		document.body.removeEventListener('keydown', close);
	};

	this.close = close;
	this.elem = layout.wrapper;

	setTimeout(function(){
		document.body.addEventListener('click', close, false);
		document.body.addEventListener('keydown', close, false);
	}, 0);

}

var helpers = {
	view: {
		reference: 'wrapper',
		tag: 'div',
		className: 'psPlugin_warning transitionAll',
		children: [{
			reference: 'title',
			tag: 'h3',
			className: 'psPlugin_warning_title'
		}, {
			reference: 'content',
			tag: 'div',
			className: 'psPlugin_warning_content'
		}, {
			reference: 'arrow',
			tag: 'div',
			className: 'psPlugin_warning_arrow'
		}]
	}
};

export default notification;
