import _forOwn from 'lodash/forOwn';
import _forEach from 'lodash/forEach';
import _isArray from 'lodash/isArray';
import _find from 'lodash/find';

// Both handles formatting of data and display state for directive

export default function ($q, APIEndpointService, monitorConversationsMock, $translate) {
	'ngInject';

	var conversationStateMap = {
		0: $translate.instant('monitor.conversations.states.0_PENDING_ROUTING'),	//'Pending routing', //PendingRouting
		1: $translate.instant('monitor.conversations.states.1_QUEUE'),				//'In queue', //Queue
		2: $translate.instant('monitor.conversations.states.2_LOCKED'),				//'Locked', //Locked
		//pending approval is deprecated, change title to "In dialog" for now.
		3: $translate.instant('monitor.conversations.states.4_DIALOG'),	//$translate.instant('monitor.conversations.states.3_PENDING_VISITOR_APPROVAL'), //'Pending visitor approval', //PendingVisitorApproval,
		4: $translate.instant('monitor.conversations.states.4_DIALOG'),				//'In dialog', //Dialog
		5: $translate.instant('monitor.conversations.states.5_HUNG_UP_BY_VISITOR'),	//'Hung up', //HungUpByVisitor
		6: $translate.instant('monitor.conversations.states.6_HUNG_UP_BY_USER'),	//'Hung up', //HungUpByUser
		7: $translate.instant('monitor.conversations.states.7_DECLINED'),			//'Declined' //Declined
	};

	var conversationStateIcon = {
		0: 'vngage-icon-sort-alt-up',
		1: 'vngage-icon-users',
		2: 'vngage-icon-lock',
		3: 'vngage-icon-hourglass',
		4: 'vngage-icon-chat-1',
		5: 'vngage-icon-ok',
		6: 'vngage-icon-ok',
		7: 'vngage-icon-cancel'
	};

	var service = {
		conversationStateMap: conversationStateMap,
		conversationStateIcon: conversationStateIcon,
		getStateName: getStateName,
		conversationWrap: {
			displayType: 'byGroup',
			ownerUserFilter: [],
			groupFilter: [],
			groupStatus: {}
		},

		// Public functions
		availableInSelectedGroups: availableInSelectedGroups,
		userIdInSelectedGroups: userIdInSelectedGroups,
		setRealtimeData: setRealtimeData, // When new data arrives to APIRealtimeService, feed it here
		displayByOwnerUser: displayByOwnerUser,
		filterOwnerUser: filterOwnerUser,
		displayByGroup: displayByGroup,
		filterGroup: filterGroup,
		filterConversations: filterConversations,
		toggleFilterItem: toggleFilterItem,
		getStateCount: getStateCount,
		getNumberOfGroupAgents: getNumberOfGroupAgents,

		// Debug
		feedMocks: feedMocks
	};

	// 		returns the conversations wrapped like this:
	// 		{
	// 			byGroup: [
	// 				{
	// 					byState: [
	// 						{
	// 							state: 1,
	// 							conversations: [<conversations for this state>]
	//						}
	// 					],
	// 					conversations: [<conversations for this group>],
	// 					groupId: 1
	// 				}
	// 			],
	// 			byOwnerUser: [
	// 				{
	// 					byState: [
	//						{
	// 							state: 1,
	// 							conversations: [<conversations for this state>]
	//						}
	// 					],
	// 					conversations: [<conversations for this user>],
	// 					ownerUserId: 1
	// 				}
	// 			],
	// 			original: [<full list of conversations>]
	// 		}

	function getNumberOfGroupAgents() {
		if (service.conversationWrap.groupFilter.length === 0) {
			return service.conversationWrap.groupStatus.usersOnline;
		} else {
			var selectedGroups      = service.conversationWrap.groupFilter,
				allGroups           = service.conversationWrap.groupStatus.groups,
				foundGroups         = allGroups.filter(group => selectedGroups.indexOf(group.groupId) != -1),
				numberOfGroupAgents = foundGroups.reduce((prevSum, currentGroup) => prevSum + currentGroup.numberOfOnlineUsers, 0);

			return numberOfGroupAgents;
		}
	}

	function userIdInSelectedGroups(userId) {
		if (service.conversationWrap.groupFilter.length === 0) {
			return true;
		} else {
			var allUsers = service.conversationWrap.groupStatus.users;
			var foundUser = _find(allUsers, user => user.userId == userId);

			if (foundUser) {
				var availableInGroups = foundUser.availableInGroups;
				return service.conversationWrap.groupFilter.some(group => availableInGroups.indexOf(group) >= 0);
			} else {
				return false;
			}
		}
	}

	function availableInSelectedGroups(availableInGroups) {
		if (service.conversationWrap.groupFilter.length === 0) {
			return true;
		} else {
			return service.conversationWrap.groupFilter.some(group => availableInGroups.indexOf(group) >= 0);
		}
	}

	function setRealtimeData(newData) {
		service.conversationWrap.conversations = {
			original: newData.conversationCurrent,
			byGroup: groupByGroup(newData.conversationCurrent),
			byOwnerUser: groupByOwnerUser(newData.conversationCurrent)
		};

		var interactionOverview = newData.interactionOverview;
		if (interactionOverview) {
			service.conversationWrap.groupStatus = {
				usersOnline: newData.interactionOverview.numberOfOnlineUsers,
				groups: newData.interactionOverview.groups,
				users: newData.interactionOverview.users
			};
		}

	}

	function feedMocks() {
		setRealtimeData({conversationCurrent: monitorConversationsMock.conversations2});
	}

	function groupByOwnerUser(conversations) {
		if (!conversations) {
			return [];
		}

		// split up by user
		var byOwnerUser = {};
		conversations.forEach(function (conversation) {
			if (!byOwnerUser[conversation.ownerUserId]) {
				byOwnerUser[conversation.ownerUserId] = [];
			}
			byOwnerUser[conversation.ownerUserId].push(conversation);
		});
		var byOwnerUserArr = [];

		// transform into array
		_forOwn(byOwnerUser, function (conversations, ownerUserId) {
			byOwnerUserArr.push({
				ownerUserId: ownerUserId,
				conversations: conversations,
				byState: [] // TODO do this one
			});
		});

		// split up by state
		byOwnerUserArr.forEach(function (byOwnerUser) {
			groupByState(byOwnerUser);
		});

		return byOwnerUserArr;
	}

	function groupByGroup(conversations) {
		if (!conversations) {
			return [];
		}
		// split up by group
		var byGroupObj = {};
		conversations.forEach(function (conversation) {
			if (!byGroupObj[conversation.groupId]) {
				byGroupObj[conversation.groupId] = [];
			}
			byGroupObj[conversation.groupId].push(conversation);
		});

		// transform into array
		var byGroupArr = [];
		_forOwn(byGroupObj, function (conversations, groupId) {
			byGroupArr.push({
				groupId: groupId,
				conversations: conversations,
				byState: []
			});
		});

		// split up by state
		byGroupArr.forEach(function (byGroup) {
			groupByState(byGroup);
		});

		return byGroupArr;
	}

	// conversationWrap = { byState: [<empty>], conversations: [<all conversations>] }
	function groupByState(conversationWrap) {
		_forEach(conversationStateMap, function (stateName, stateKey) {
			stateKey = parseInt(stateKey, 10);
			var stateConversations = {
				state: stateKey,
				conversations: []
			};
			var conversationsInState = conversationWrap.conversations.filter(function (conversation) {
				// filter out conversations with no ownerUserId
				// most likely "abandoned", where visitor has closed the browser tab
				// and agent has closed the related case.
				return conversation.ownerUserId !== '00000000-0000-0000-0000-000000000000' &&
					conversation.state === stateKey;
			});
			stateConversations.conversations = conversationsInState;
			if (conversationsInState.length > 0) {
				conversationWrap.byState.push(stateConversations);
			}
		});

		return conversationWrap;
	}

	function getStateName(stateKey) {
		return $q(function (resolve) {
			resolve(conversationStateMap[stateKey]);
		});
	}

	// "add" means that the new filter values should be added to the already existing values
	function displayByOwnerUser(filter, add) {
		service.conversationWrap.displayType = 'byOwnerUser';
	}

	function displayByGroup(filter, add) {
		service.conversationWrap.displayType = 'byGroup';
	}

	function filterOwnerUser(filter, add) {
		filterConversations('ownerUserFilter', filter, add);
	}

	function filterGroup(filter, add) {
		filterConversations('groupFilter', filter, add);
	}

	// Clears filter per default, optional add
	function filterConversations(type, filter, add) {
		if (!type) {
			service.conversationWrap['ownerUserFilter'] = [];
			service.conversationWrap['groupFilter'] = [];
		}
		if (!add) {
			service.conversationWrap[type] = [];
		}
		if (typeof filter === 'string') {
			service.conversationWrap[type].push(filter);
		} else if (_isArray(filter)) {
			service.conversationWrap[type] = service.conversationWrap.concat(filter);
		}
		return;
	}

	function getStateCount(stateNr, userId) {
		var ownerUserWrap = _find(service.conversationWrap.conversations.byOwnerUser, {ownerUserId: userId});
		if (!ownerUserWrap) {
			return 0;
		}
		var stateNrArr = [].concat(stateNr);
		var stateNrs = 0;
		stateNrArr.forEach(function (stateNr) {
			var stateWrap = _find(ownerUserWrap.byState, {state: stateNr});
			if (stateWrap) {
				stateNrs += stateWrap.conversations.length;
			}
		});
		return stateNrs;
	}

	// itemType can be 'ownerUser' or 'group'
	function toggleFilterItem(itemType, itemId) {
		var filterList = service.conversationWrap[itemType + 'Filter'];
		var idx = filterList.indexOf(itemId);

		if (itemType === 'ownerUser') {
			service.conversationWrap.displayType = 'byOwnerUser';
		}

		// is currently selected, remove from list
		if (idx > -1) {
			filterList.splice(idx, 1);
		}

		// is newly selected, add to list
		else {
			filterList.push(itemId);
		}
	}

	return service;
}
