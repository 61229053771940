export default function (access) {
	return {
		id: 'devicecategories',
		access: (access || 'Statistics'),
		viewConfig: {
			metricName: 'ConversionFunnelSitePerDeviceCategory',

			/** ----------------------------------------------* Measures */
			measures: [
				{
					key: 'siteName',
					type: 'string'
				}, {
					key: 'deviceCategory',
					type: 'string'
				}, {
					key: 'dimensionName',
					type: 'string',
					calculation: {
						type: 'formatMeasureWithParenthesis',
						parameters: [
							'deviceCategory',
							'siteName'
						]
					}
				}, {
					key: 'numberOfUniqueVisitors',
					type: 'number'
				}, {
					key: 'numberOfUniqueVisits',
					type: 'number'
				}, {
					key: 'numberOfQueueAdded',
					type: 'number'
				}, {
					key: 'numberOfQueueDialogs',
					type: 'number'
				}, {
					key: 'numberOfCasesClosed',
					type: 'number'
				}, {
					key: 'numberOfPurchaseConfirmations',
					type: 'number'
				}, {
					key: 'numberOfRelatedAssistedPurchasePois_sameVisit',
					type: 'number'
				}, {
					key: 'numberOfRelatedAssistedPurchasePois_6Hours',
					type: 'number'
				}
			],


			/** ----------------------------------------------* Bar filters */
			barFilters: [
				{
					dimension: 'siteName',
					valueAccessor: 'numberOfUniqueVisits'
				}, {
					dimension: 'deviceCategory',
					valueAccessor: 'numberOfUniqueVisits'
				}
			],


			/** ----------------------------------------------* Time line graph */
			timeLineGraph: {
				valueAccessor: 'numberOfUniqueVisits'
			},


			/** ----------------------------------------------* Totals */
			totals: [
				{
					measure: 'numberOfUniqueVisits'
				}, {
					measure: 'numberOfQueueAdded'
				}, {
					measure: 'numberOfQueueDialogs'
				}, {
					measure: 'numberOfCasesClosed'
				}, {
					measure: 'numberOfPurchaseConfirmations',
					displayPercentOf: 'numberOfUniqueVisits'
				}, {
					measure: 'numberOfRelatedAssistedPurchasePois_sameVisit',
					displayPercentOf: 'numberOfCasesClosed'
				}, {
					measure: 'numberOfRelatedAssistedPurchasePois_6Hours',
					displayPercentOf: 'numberOfCasesClosed'
				}
			],


			/** ----------------------------------------------* Table */
			table: {
				groupBy: 'dimensionName',
				measures: [
					{
						measure: 'numberOfUniqueVisits'
					}, {
						measure: 'numberOfQueueAdded'
					}, {
						measure: 'numberOfQueueDialogs'
					}, {
						measure: 'numberOfCasesClosed'
					}, {
						measure: 'numberOfPurchaseConfirmations'
					}, {
						measure: 'numberOfRelatedAssistedPurchasePois_sameVisit'
					}, {
						measure: 'numberOfRelatedAssistedPurchasePois_6Hours'
					}
				]
			}
		}
	}
};
