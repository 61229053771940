export default {
	'en-GB': {
		reservations: {
			PAGE_HEADER: 'Reservations',
			PAGE_DESCRIPTION: 'Manage reservations to special queues. A group needs to be configured to be allowed to split it into reservable time spots! Click on a reservation to view the reservation details',
			GROUP: 'Queue Reservation Group',
			RESERVATION_MODAL_HEADER: 'Reservation: ',
			BTN_CLOSE: 'Close',
			BTN_CANCEL: 'Cancel',
			BTN_CANCEL_RESERVATION: 'Cancel reservation',
			BTN_SEND: 'Send',
			BRN_SEND_EMAIL: 'Send email',
			MODAL_CANCEL_RESERVATION_HEADER: 'Send email message to ',
			MODAL_CANCEL_RESERVATION_MESSAGE: 'The email template already contains a greeting and a signature. You only need to write your message to the visitor!',
			PHONE_LABEL: 'Phone',
			EMAIL_LABEL: 'Email',
			FIRST_NAME_LABEL: 'First name',
			LAST_NAME_LABEL: 'Last name',
			GROUP_LABEL: 'Group',
			START_TIME_LABEL: 'Start time',
			OPTIONS_SELECT_GROUP: '-- Select group --',
			TAB_RESERVATION_INFO: 'Reservation',
			TAB_RESERVATION_DETAILS: 'Details',
			DETAILS_HEADER: 'Details',
			DETAILS_RESERVATION_ID_NAME: 'Reservation ID',
			DETAILS_CASE_ID_NAME: 'Case ID',
			DETAILS_CONVERSATION_ID_NAME: 'Conversation ID'
		}
	},
	'sv-SE': {
		reservations: {
			PAGE_HEADER: 'Reservationer',
			PAGE_DESCRIPTION: 'Hantera reservationer i speciella grupper. En grupp måste vara konfigurerad för att kunna hantera olika tidsluckor. Välj en köreservationsgrupp och klicka på en reservation för att se dess detaljer.',
			GROUP: 'Köreservationsgrupp',
			RESERVATION_MODAL_HEADER: 'Reservation: ',
			BTN_CLOSE: 'Stäng',
			BTN_CANCEL: 'Ställ in',
			BTN_CANCEL_RESERVATION: 'Ställ in reservation',
			BTN_SEND: 'Skicka',
			BRN_SEND_EMAIL: 'Skicka email',
			MODAL_CANCEL_RESERVATION_HEADER: 'Skicka e-mail till  ',
			MODAL_CANCEL_RESERVATION_MESSAGE: 'E-mailet innehåller redan hälsningsfras och avslutningsmeddelande. Du behöver endast skriva ditt meddelande till besökaren!',
			PHONE_LABEL: 'Telefon',
			EMAIL_LABEL: 'E-post',
			FIRST_NAME_LABEL: 'Förnamn',
			LAST_NAME_LABEL: 'Efternamn',
			GROUP_LABEL: 'Grupp',
			START_TIME_LABEL: 'Starttid',
			OPTIONS_SELECT_GROUP: '-- Välj grupp --',
			TAB_RESERVATION_INFO: 'Reservation',
			TAB_RESERVATION_DETAILS: 'Detaljer',
			DETAILS_HEADER: 'Detaljer',
			DETAILS_RESERVATION_ID_NAME: 'Reservations-ID',
			DETAILS_CASE_ID_NAME: 'Case-ID',
			DETAILS_CONVERSATION_ID_NAME: 'Conversation-ID'
		}
	},
	'fr-FR': {
		reservations: {
			PAGE_HEADER: 'Réservations',
			PAGE_DESCRIPTION: 'Gérer les réservations aux files d\'attente spéciales. Un groupe doit être configuré pour pouvoir être divisé en créneaux horaires ! Cliquez sur une réservation pour afficher les informations de réservation',
			GROUP: 'Groupe de réservation de file d\'attente ',
			RESERVATION_MODAL_HEADER: 'Réservation : ',
			BTN_CLOSE: 'Fermer',
			BTN_CANCEL: 'Annuler',
			BTN_CANCEL_RESERVATION: 'Annuler la réservation',
			BTN_SEND: 'Envoyer',
			BRN_SEND_EMAIL: 'Envoyer l\'e-mail',
			MODAL_CANCEL_RESERVATION_HEADER: 'Envoyer un e-mail à ',
			MODAL_CANCEL_RESERVATION_MESSAGE: 'Le modèle de courrier électronique contient déjà un message d\'accueil et une signature. Il vous suffit d\'écrire votre message au visiteur !',
			PHONE_LABEL: 'N° de téléphone',
			EMAIL_LABEL: 'E-mail',
			FIRST_NAME_LABEL: 'Prénom',
			LAST_NAME_LABEL: 'Nom de famille',
			GROUP_LABEL: 'Groupe',
			START_TIME_LABEL: 'Heure de début',
			OPTIONS_SELECT_GROUP: '-- Choisir un groupe --',
			TAB_RESERVATION_INFO: 'Réservation',
			TAB_RESERVATION_DETAILS: 'Informations',
			DETAILS_HEADER: 'Informations',
			DETAILS_RESERVATION_ID_NAME: 'Numéro de réservation',
			DETAILS_CASE_ID_NAME: 'Numéro de dossier',
			DETAILS_CONVERSATION_ID_NAME: 'Numéro de la conversation'
		}
	}
};
