import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function ($stateParams, roleClaim, roleClaimListExceptThis, userList, $state, RoleService, VngageUtil, $scope, vngageRemoveModalService) {
	'ngInject';

	var vm = this;
	vm.roleClaim = roleClaim;
	vm.submit = submit;
	vm.remove = remove;
	vm.cancel = cancel;
	vm.roleClaim.data.dependsOn = vm.roleClaim.data.dependsOn || []; // TODO belongs in APIEndpointService
	vm.formattedSelectableDependsOn = RoleService.formatSelectableDependsOn(roleClaimListExceptThis);
	vm.formattedSelectableUsers = RoleService.formatSelectableUsers(userList);
	vm.formattedSelectedUsers = RoleService.formatSelectedUsers(userList, roleClaim.data.claimType);
	vm.applyRoleClaimsToUsers = applyRoleClaimsToUsers;
	vm.currentList = $stateParams.currentList || [];

	// when accepting to reset unsaved changes, reload the entity
	$scope.$on('resetResettables', () => {
		vm.roleEditForm.$setPristine();
		roleClaim.$get().catch(() => {
		});
	});

	var selectedUsersCurrentlySaved = angular.copy(vm.formattedSelectedUsers);

	function applyRoleClaimsToUsers() {
		var claimType = vm.roleClaim.data.claimType;
		var usersCTRemoved = VngageUtil.arrayDiff(selectedUsersCurrentlySaved, vm.formattedSelectedUsers);
		var usersCTAdded = VngageUtil.arrayDiff(vm.formattedSelectedUsers, selectedUsersCurrentlySaved);
		var usersToSave = [].concat(RoleService.removeRoleClaimFromUsers(userList, usersCTRemoved, claimType));
		usersToSave = usersToSave.concat(RoleService.addRoleClaimToUsers(userList, usersCTAdded, claimType));
		RoleService.saveMultipleUsers(usersToSave);
		selectedUsersCurrentlySaved = angular.copy(vm.formattedSelectedUsers);
	}

	function submit() {
		vm.isSaving = true;
		vm.roleClaim.$save().finally(function () {
			vm.isSaving = false;
		});
	}

	function cancel() {
		$state.go('^');
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: roleClaim,
			itemType: 'role'
		}).then(function () {
			vm.roleEditForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(roleClaim, 'root.account.role.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(function () {
			vm.isSaving = false;
		});
	}
}
