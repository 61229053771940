export default [
	// Case events
	'onGetActiveCase',
	'onSaveCase',
	'onCloseCase',
	'onGetPreviousCases',
	'onDroppedAction',
	'onTryCaseClose',
	'onOpenNewCase',
	'onGetNavigationHistory',

	// State events
	'enterStateQueued',
	'enterStateProactive',
	'enterStatePendingApproval',
	'enterStateInDialog',
	'enterStateCompleted',

	// Transition events
	'onQueueJoined',
	'onProactive',
	'onEnlist',
	'onLeave',
	'onRequest',
	'onRemove',
	'onLock',
	'onBegin',
	'onUnlockToProactive',
	'onUnlockToQueue',
	'onApprove',
	'onReject',
	'onNotToday',
	'onEndDialog',
	'onClose',

	//Identity
	'onIdentityChanged'
];
