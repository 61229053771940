export default function ($rootScope, $location, AuthenticationService, usSpinnerService, NotificationService, $state) {
	'ngInject';

	AuthenticationService.interceptRoutes({
		rootState: 'root',
		goToStateAfterLogin: 'root.dashboard',
		loadingState: 'starting'
	});

	$rootScope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
		// console.log(`$locationChangeStart\nold: ${oldUrl}\nnew: ${newUrl}`, event);
		// prevent links with href="#" to navigate to root. fixes issues with react-bootstrap
		// an alternative approach would be to check newUrl.indexOf('#'),
		// but checking $location.path() feels more like the 'angular way'
		if (oldUrl !== newUrl && $location.path() === '') {
			event.preventDefault();
		}
	});

	$rootScope.$on('$stateChangeStart', function () {
		usSpinnerService.spin('spinner-page-loading');
	});

	$rootScope.$on('stateChangeCancel', function () {
		usSpinnerService.stop('spinner-page-loading');
	});

	$rootScope.$on('$stateChangeSuccess', function (event, toState) {
		usSpinnerService.stop('spinner-page-loading');

		// Global param to control which states shoule have the main menu displayed (currently all but 'root.dialogguest' and 'root.cobrodesktop')
		$rootScope.viewWithMainMenu = toState.name.indexOf('root.dialogguest') === -1 &&
			toState.name.indexOf('root.cobrodesktop') === -1;

		// Global param used to make sidebar appear or disappear depending on which view is showing
		// ---------------------------------------------------------------------------------
		// TODO remove this and put proper classes instead of #sidebar
		$rootScope.viewWithSubMenu = toState.name.indexOf('root.account') !== -1 ||
			toState.name.indexOf('root.statistics') !== -1 ||
			toState.name.indexOf('root.calendar') !== -1 ||
			toState.name.indexOf('root.system') !== -1 ||
			toState.name.indexOf('root.analyze') !== -1 ||
			toState.name.indexOf('root.configure') !== -1 ||
			toState.name.indexOf('root.publish') !== -1 ||
			toState.name.indexOf('root.engage') !== -1;
	});

	var loopStopperMemory;

	$rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
		// if a failed routing attempt is done twice (by an automatic redirect) go to dashboard instead
		function safeGo(fromState, toState) {
			if (loopStopperMemory !== toState.name) {
				loopStopperMemory = toState.name;
				$state.go(toState.name);
			} else {
				NotificationService.warning({
					header: 'Navigation failed',
					body: 'Redirecting to Dashboard'
				});
				$state.go('root.dashboard');
				loopStopperMemory = null;
			}
		}

		usSpinnerService.stop('spinner-page-loading');
		if (error.body || error.header) {
			NotificationService.error(error);
		} else if (error.config && error.config.url) {
			NotificationService.error({
				header: 'Error: Request Failed',
				body: error.config.url
			});
		}

		if (fromState.name === 'starting') {
			// Go the parent state of the state that wasn't found (keep trying until a valid state is reached, or go to dashboard)
			let stateParts = toState.name.split('.');
			stateParts.pop();
			while (stateParts.length) {
				try {
					$state.go(stateParts.join('.'));
					break;
				} catch (e) {
					// Invalid state, try parent
					stateParts.pop();
				}
			}
			if (!stateParts.length) {
				// Still invalid state, go to dashboard
				$state.go('root.dashboard');
			}
		} else {
			safeGo(fromState, toState);
		}
	});
};
