import {getItemNavigationDataAfterRemove} from '../../../../common/UiHelpers';

export default function ($state, $scope, $parse, $timeout, banner, theme, BannerService, BannerThemeService, wcagEnabled, VngageUtil, filterXSS, NotificationService, vngageRemoveModalService, APIConfigurationSectionService, $stateParams) {
	'ngInject';

	const vm = this;
	const htmlValidator = new filterXSS.FilterXSS();

	vm.wcagEnabled = wcagEnabled;
	vm.banner = banner;
	const defaultTranslation = BannerService.getDefaultBannerTranslation(vm.banner);
	vm.theme = BannerThemeService.create(theme);
	vm.theme.config.activeTranslation = (defaultTranslation ? defaultTranslation.id: '');
	const savedThemeConfig = {
		custom: angular.copy(vm.theme.config.custom),
		variables: angular.copy(vm.theme.config.variables)
	};

	vm.submit = submit;
	vm.remove = remove;
	vm.submitTheme = submitTheme;

	vm.templates = BannerService.templates();
	vm.templateMatches = templateMatchesAny;
	vm.templateDefaults = templateDefaults;
	vm.addTextKey = addTextKey;
	vm.removeTextKey = removeTextKey;
	vm.translate = translate;
	vm.currentList = $stateParams.currentList || [];
	vm.backRoute = '^.^';

	vm.htmlValid = 'valid'; // flag for html validation
	vm.refreshCodemirror = false;
	vm.visitorUIMode = vm.banner.data.section.visitorUIMode || 'normal';

	$scope.$watch('vm.theme.config.custom', vm.theme.preview, true);
	$scope.$watch('vm.theme.config.variables', vm.theme.preview, true);

	$scope.$on('$stateChangeSuccess', function (event, toState) {
		const parts = toState.name.split('.');
		let name = parts[parts.length - 1].toLowerCase();

		name = name.charAt(0).toUpperCase() + name.slice(1);

		vm.isTheme = toState.name === 'root.engage.banner.edit.css';

		// [ TL ] Don't try to load a guideTemplate for the parent state (banner)
		vm.guideTemplate = (name !== 'Banner' ? 'app/engage/banner/BannerEdit/guides/BannerEdit' + name + 'GuideTemplate.html': '');
	});

	let bannerReFetchInProgress = false;
	let delayedStateChange = null;

	function reFetchBanner(banner) {
		bannerReFetchInProgress = true;
		banner.$get().then((res) => {
			// console.log('banner fetched');
			bannerReFetchInProgress = false;
			if (delayedStateChange) {
				// A stateChange was attempted during banner fetch: Resume it...
				$state.go(delayedStateChange.toStateName, delayedStateChange.toParams);
			}
			delayedStateChange = null;
		}, (err) => {
			bannerReFetchInProgress = false;
			delayedStateChange = null;
		});
	}

	$scope.$on('$stateChangeStart', function (event, toState, toParams) {

		if (bannerReFetchInProgress) {
			// The banner is currently being fetched (e.g. triggered by 'resetResettables' below when skipping navigating away from a dirty banner)
			// Save this attempted stateChange to 'delayedStateChange' and abort the current stateChange.
			// This stateChange will later be resumed when the banner has finished loading (in 'reFetchBanner()' above)
			delayedStateChange = {
				toStateName: toState.name,
				toParams: angular.copy(toParams)
			};
			event.preventDefault();
			return;
		}

		if (vm.bannerConfigurationForm && vm.bannerConfigurationForm.$invalid && toState.name.indexOf('root.engage.banner.edit.') >= 0) {
			event.preventDefault();
			NotificationService.warning({
				header: 'Form is invalid',
				body: 'Fix errors on this page before editing other banner properties'
			}, {timeOut: 10000});
		} else if (toState.name === 'root.engage.banner') {
			reFetchBanner(banner);
		}

		// Trigger a code mirror re-render on state changes
		vm.refreshCodemirror = true;
		$timeout(function () {
			vm.refreshCodemirror = false;
		}, 100);
	});

	$scope.$on('resetResettables', () => {
		if (vm.bannerConfigurationForm) {
			vm.bannerConfigurationForm.$setPristine();
		}
		reFetchBanner(banner);

		if (vm.bannerThemeForm) {
			vm.bannerThemeForm.$setPristine();
		}
		// Restore saved theme config that may have been altered locally in this view
		vm.theme.config.custom = angular.copy(savedThemeConfig.custom);
		vm.theme.config.variables = angular.copy(savedThemeConfig.variables);
	});

	vm.fixBannerHtml = function () {
		fixHtmlValue('bannerHTML', 'html');
	};

	vm.fixBannerTabHtml = function () {
		fixHtmlValue('bannerTabHTML', 'htmlTab');
	};

	function fixHtmlValue(formInputName, prop) {
		const formInput = $parse('htmlForm.htmlEditor.' + formInputName)(vm.bannerConfigurationForm);
		if (formInput) {
			const viewValue = formInput.$viewValue || '';
			vm.banner.data.section[prop] = htmlValidator.process(viewValue);
		}
	}

	vm.fixHtml = function () {
		vm.fixBannerHtml();
		vm.fixBannerTabHtml();
	};

	function submit() {

		//need to run this here too, in case user presses submit within debounce delay.
		//might be a bug in how the form submit flow is set up.
		// 			vm.fixHtml(); Removed since the fix removes for example href="tel:+6494461709" from a link

		if (vm.banner.data.section.subjectMapping) {
			// If this banner has subjectMapping: Update the positions to the current sort order
			vm.banner.data.section.subjectMapping.forEach(function (item, index) {
				item.position = index;
			});
		}
		vm.banner.data.section.visitorUIMode = vm.visitorUIMode || 'normal';

		BannerService.cleanup(vm.banner);

		vm.isSaving = true;
		vm.banner.$save().then(() => {
			vm.isSaving = false;
			vm.bannerConfigurationForm.$setPristine();
		}).catch(error => {
			// Error saving banner
			console.error('Error saving banner:', error);
			vm.isSaving = false;
			NotificationService.error({
				header: 'Error',
				body: 'Error saving banner'
			}, {timeOut: 10000});
			vm.bannerConfigurationForm.$setDirty();
		});
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: vm.banner,
			itemType: APIConfigurationSectionService.sectionMap.banner
		}).then(() => {
			vm.isSaving = false;
			const itemNavigationData = getItemNavigationDataAfterRemove(vm.banner, 'root.engage.banner.edit.general', vm.currentList, '^.^');
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(() => {
			vm.isSaving = false;
		});
	}

	function templateMatchesAny(list) {
		return list.indexOf(vm.banner.data.section.template) >= 0;
	}

	function templateDefaults() {
		BannerService.defaults(vm.banner);
	}


	function addTextKey() {
		if (!vm.textKey || vm.banner.data.section.texts[vm.textKey]) {
			return;
		}
		vm.banner.data.section.texts[vm.textKey] = vm.textKeyValue || '';
		vm.textKey = '';
		vm.textKeyValue = '';

		vm.bannerConfigurationForm.$setDirty();
	}

	function removeTextKey(key) {
		delete vm.banner.data.section.texts[key];
	}


	function submitTheme() {
		vm.isSaving = true;
		vm.theme.save().then(() => {
			vm.isSaving = false;
			vm.bannerThemeForm.$setPristine();
		}).catch(error => {
			// Error in less
			vm.isSaving = false;

			// Reverts changes in local object
			NotificationService.error({
				header: 'Invalid Account CSS/Less',
				body: 'Less code contains errors. Could not save bannerTheme css.'
			}, {timeOut: 10000});
			vm.bannerThemeForm.$setDirty();
		});
	}

	function translate(varKey) {
		return 'lessVars.' + varKey;
	}

}
