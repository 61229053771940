import React from 'react';
import Participant from '../../../components/Participant.react';
import DropDown from './common/DropDown.react';
import ParticipantsFloatList from '../../../components/ParticipantsFloatList.react';
import DurationDisplay from './DurationDisplay.react';
import ViewActions from './../actions/ViewActions';
import ParticipantActions from './../actions/ParticipantActions';
import { injectIntl } from 'react-intl';
import createReactClass from 'create-react-class';

export default injectIntl(createReactClass({

	toggleMetaData () {
		ViewActions.toggleMetaData(this.props.conversationId);

		if (this.props.showMeta) {
			this.refs.metaAction.classList.toggle('active');
		}
	},

	leaveConversation () {
		ParticipantActions.participantLeave(this.props.conversationId);
	},

	render () {
		let dropdownTitle = this.props.participants.length + ' ' + this.props.intl.formatMessage({id: 'participants'});
		let participantsView;
		let participants = this.props.participants.map( participant => {
			return (
				<Participant
					key={participant.id}
					name={participant.info.name || ''}
					titleId={participant.info.titleId}
					connectionState={participant.connectionState}
					participationState={participant.state}
					isCurrentUser={participant.info.isCurrentUser}
				/>
			)
		});
		if (this.props.participants.length < 4) {
			participantsView = <div>
				<ParticipantsFloatList className="meta-participants">{participants}</ParticipantsFloatList>

			</div>;
		} else {
			participantsView = <div>

				<DropDown title={dropdownTitle}>{participants}</DropDown>
			</div>;
		}

		return (
			<div className="dialog-meta">
				<div className="flex-container">
					<h2 className="icon-large">
						<i className="vngage-icon-group"></i>
					</h2>
				<div className="flex1">
					<h2 className="meta-title">{this.props.intl.formatMessage({id: 'groupDialog'})}</h2>
					{participantsView}
				</div>
				</div>
				<div className="meta-actions">
					<a ref="metaAction" className={this.props.showMeta ? '' : 'active'} onClick={this.toggleMetaData}>{this.props.showMeta ? this.props.intl.formatMessage({id: 'btnMetaInactive'}) : this.props.intl.formatMessage({id: 'btnMetaActive'})}</a>
					<a onClick={this.leaveConversation}>{this.props.intl.formatMessage({id: 'btnLeave'})}</a>
					<i className="vngage-icon-cog"></i>
				</div>
				<div className="indicate-overflow-top"></div>
			</div>
		)
	}
}));
