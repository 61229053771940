import _each from 'lodash/each';
import throttle from 'lodash/throttle';

import normalizeGuid from './normalizeGuid';

var $http, restUrl, batchRestUrl, serverUrl, SessionService,
	listening = false,
	// the timer that keeps track of the request bundler delay
	//timer = false,
	// delay commands for this long and add them to the bundle
	//delay = 300,
	bundleCommands = [],
	isWaiting = false,
	retries = 0,
	maxRetries = 3,
	previousRequest = '', // cache request for exceptionHandler reporting
	envId = '00000000-0000-0000-0000-000000000000';


var throttleBundle = throttle(_executeSendBundle, 300, {leading: false});


function composeUri(api, method) {
	if (api.lastIndexOf('/') !== api.length - 1) {
		api += '/';
	}
	if (method.charAt(0) === '/') {
		method = method.substr(1);
	}
	return api + method;
}


//request envelope
function envelope(commands) {
	if (typeof psPlugin.shell.session.user !== 'undefined') {
		envId = psPlugin.shell.session.user.token || envId;
	}
	return {
		id: envId,
		version: '1.0',
		commands: commands || []
	};
}




function _sendBundled(commands) {
	var obj = envelope(commands),
		data = JSON.stringify(obj);

	//console.log(commands)
	//bundleCounter++;
	//isWaiting = true;


	var items = [],
		item,
		req, reqData,
		proxy;

	_each(commands, function (cmd) {

		//configurationGet has no data payload
		if (cmd.payload.data) {

			reqData = {data: cmd.payload.data};
			proxy = 'Command/Proxy25/Desktop';

		} else {

			reqData = cmd;
			proxy = 'Command/Proxy30';
		}

		item = {
			'contentHeaders': {'Content-Type': 'application/json'},
			'method': 'post',
			'uri': batchRestUrl + proxy,
			'body': JSON.stringify(reqData),
			'tag': cmd.tag
		};

		//if (cmd.type !== 'configurationGet') {
		items.push(item);
		//}
	});

	req = {
		items: items
	};

	//console.log('REQ', req);

	//cache request so we can report it in case anything goes wrong.
	previousRequest = data;
	$http.post(restUrl + 'Batch', JSON.stringify(req)).then(function (res) {
		//console.log('$http.post::BATCH CALLBACK', res);

		var data, body;

		_each(res.data.items, function (item) {
			//console.log('RESPONSE!', item.statusCode, item)
			if (item.statusCode < 300) {

				body = (item.body && item.body !== '') ? JSON.parse(item.body): '';
				data = body.payload;

				if (!data) {
					data = body;
				}
				var responseHandler = item.tag;
				//console.log('responseHandler', responseHandler);

				for (var k in responseHandler) {
					try {
						data = normalizeGuid(data);
						psPlugin.application[k][responseHandler[k]](data);
					} catch (err) {
						console.error('_responseHandler exception:', err);
					}
				}

			} else {
				console.error('ERROR', item.statusCode)
			}
		});

		isWaiting = false;

	}, function(err) {
		// Catch error, and set isWaiting = false!
		// Otherwise the polling will die after a single failed network call
		// console.log('comServer3 _sendBundled error:',err);
		isWaiting = false;
	});

}

var heartBeatCmd = false,
	groupQueueStatusCmd = false;

function _makeBundle(commands) {
	var method;

	for (var com in commands) {
		//method=heartbeat
		//method=listVisitors
		//method=groupQueueStatuses *bubblare*
		if (typeof commands[com] === 'object') {

			var command = getReqObj(com, commands[com]);

			if (commands[com].tag !== 'undefined') {
				command.tag = commands[com].tag;
			}

			if (typeof commands[com].data === 'string') {

				method = commands[com].data.match(/(?:method=)([A-Z]*)/gi);

				if (method.length) {
					method = method[0];
					if (
						method === 'method=listVisitors' ||
						method === 'method=heartbeat'
					) {
						heartBeatCmd = command;
						continue;
					}

					if (method === 'method=groupQueueStatuses') {
						groupQueueStatusCmd = command;
						continue;
					}
				}
			}

			bundleCommands.push(command);
		}
	}
	//if (!timer) {
	//	console.warn('--- !timer create timeout');
	//	timer = setTimeout(_executeSendBundle, delay);
	//}
	throttleBundle();
}


//generic ErrorHandler
function handleError(errorCode, responseData) {

	var hasReallyTried = sessionStorage.getItem('vngage_sr');

	if (errorCode !== 401 && !hasReallyTried) {
		retries++;
		// first, try to restart the current application...
		if (retries < maxRetries && psPlugin.application && typeof psPlugin.application.restart === 'function') {
			psPlugin.application.restart();
			return;
		} else {
			// ...and if that doesn't work, reload the page once
			sessionStorage.setItem('vngage_sr', 'true');
			window.location.reload(true);
			return;
		}
	}
	if (hasReallyTried) {
		sessionStorage.removeItem('vngage_sr');
	}

	var msg = errorCode || 'Unknown error',
		genericErrorMsg = 'Unfortunately an error has occurred!\nA message has been sent to Vergic with details about this error.\n\nPlease click OK and log in again';

	function endSession(message) {
		message = message || genericErrorMsg;

		if (psPlugin.application.events && psPlugin.application.events.onOffline) {
			psPlugin.application.events.onOffline.trigger(message);
		} else {
			alert(message);
			try {
				if (errorCode === 419) {
					psPlugin.shell.logout(false);
				} else {
					psPlugin.shell.logout();
				}
			} catch (err) {
				console.error(err);
				SessionService.clearSessionId();
				window.location.reload(true);
			}
		}
	}


	if (errorCode === 419) {
		// 419 Authentication Timeout (not in RFC 2616)
		// Not a part of the HTTP standard, 419 Authentication Timeout denotes that previously valid authentication has expired.
		// It is used as an alternative to 401 Unauthorized in order to differentiate from otherwise authenticated clients being denied access to specific server resources.
		endSession('Your session seem to have timed out due to inactivity. You need to log in again.');
		return errorCode;
	}


	try {

		if (responseData &&
			responseData.payload &&
			responseData.payload.description
		) {
			msg = responseData.payload.description;
		}
		responseData = responseData || '';
		psPlugin.core.exceptionHandler.send(errorCode, msg, previousRequest, responseData)
			.then(function () {
				endSession();
			});

	} catch (err) {
		endSession();
	}
	return errorCode;
}


function _executeSendBundle() {
	if (!isWaiting) {
		isWaiting = true;
		//console.log(bundleCommands);
		//console.log('#' + (bundleCounter+1) + ' : ' + bundleCommands.length, bundleCommands);

		//if heartbeat && bundlecommands is more then 1 && data is defined
		if (heartBeatCmd &&
			(bundleCommands.length < 1 || bundleCommands.length > 0 &&
			typeof bundleCommands[0].payload.data !== 'undefined' &&
			bundleCommands[0].payload.data.indexOf('method=registerv2') === -1)
		) {
			bundleCommands.push(heartBeatCmd);
			heartBeatCmd = false;
		}

		if (groupQueueStatusCmd) {
			bundleCommands.push(groupQueueStatusCmd);
			groupQueueStatusCmd = false;
		}

		_sendBundled(bundleCommands);

		bundleCommands = [];

		//} else {
		//	//console.log('Still waiting for last bundle (#' + bundleCounter + ') Queue:', bundleCommands.length + ' commands');
	}
	//timer = false;
}

function sendLegacy(commands) {
	console.log('sendLegacy', commands);
}
/*
		 * -----------------------------------------------------------
		 * Clean out tag and return request object
		 * -----------------------------------------------------------
		 */
var getReqObj = function (k, params) {
	var vars = {};
	for (var p in params) {
		if (p !== 'tag') {
			vars[p] = params[p];
		}
	}
	//return this[k](vars);
	return {
		type: k,
		payload: vars
	};
};
/*
 * -----------------------------------------------------------
 * Response: handle response from com server
 * -----------------------------------------------------------
 */
var _responseHandler = function (data) {

	var responseHandler = data.tag;

	//TODO: do something here as well? Moar error handling?
	if (data.type === 'error') {

		console.error('error in _responseHandler. logging data:', data);
		handleError(data.payload.errorCode, data);
		//throw data.payload.errorCode; //10, InvalidSessionId
	}

	for (var k in responseHandler) {
		try {
			psPlugin.application[k][responseHandler[k]](data.payload);
		} catch (err) {
			console.error('_responseHandler exception:', err);
		}
	}
};



var ComServer3 = {
	init (_http, _vngageConfig, _SessionService) {
		$http = _http;
		serverUrl = _vngageConfig.path;
		restUrl = _vngageConfig.restUrl;
		batchRestUrl = _vngageConfig.batchRestUrl;
		SessionService = _SessionService;
	},

	send (commands) {
		var coms = JSON.parse(JSON.stringify(commands));

		//if commands is already in array, special occasions, like environment copy must be run in correct order
		if (psPlugin.shell.jsExt.isArray(coms)) {
			_sendBundled(coms);
			return;
		}

		if (!listening && (
			typeof coms.groupJoin !== 'undefined' ||
			typeof coms.routeSubscribe !== 'undefined' ||
			typeof coms.listen !== 'undefined'
		)) {
			listening = true;
		}

		_makeBundle(coms);
	},

	logout (cb) {
		console.info('about to log out...', cb);
		//var url = composeUri(serverUrl, 'Session/Logout?SessionId=' + getSessionId()),
		var url = composeUri(serverUrl, 'Session/Logout'),
			headers = {
				Authorization: SessionService.getAuthHeader(),
				'Content-Type': 'application/json'
			};

		// First log out from 2.5 ...
		this.send([{
			type: 'commServer23Proxy',
			payload: {
				serviceName: 'psDesktop.ashx',
				data: 'method=logout&psId=' + psPlugin.shell.session.account.id + '&psAdminId=' + psPlugin.shell.session.user.id + '&userToken=token'
			}
		}]);
	},

	post (request, callback) {

		var url = composeUri(restUrl, request.url),
			data = request.data,
			headers = request.headers || {};

		headers.Authorization = SessionService.getAuthHeader();
		$http.post(url, data)
			.success(function(data) {
				if (typeof callback === 'function'){
					callback(data);
				}

			})
			.error(function(data, status) {
				if (status === 404) {
					if (typeof callback === 'function'){
						callback(data);
					}
				} else {

					handleError(status, data);
				}
			});


	},

	get (request, callback) {

		var url = composeUri(restUrl, request.url);

		//headers.Authorization = 'SessionId ' + SessionService.getSessionId();
		$http.get(url)
			.success(function(data) {
				if (typeof callback === 'function'){
					callback(data);
				}

			})
			.error(function(data, status, headers, config) {
				if (status === 404) {
					if (typeof callback === 'function'){
						callback(data);
					}
				} else {
					handleError(error, data);
				}
			});

	}
};

export default ComServer3;
