import _find from 'lodash/find';
import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function ($translate, $state, solution, lists, VngageUtil, BannerService, $scope, APIConfigurationSectionService, vngageRemoveModalService, $stateParams) {
	'ngInject';

	var vm = this;
	vm.solution = solution;
	vm.groupList = lists.groups.sort(nameComparator);
	vm.bannerList = lists.banners.sort(nameComparator);
	vm.caseTypeList = lists.caseTypes.sort(nameComparator);
	vm.queueReservation = false;

	vm.submit = submit;
	vm.remove = remove;
	vm.cancel = cancel;
	vm.bannerName = bannerName;
	vm.defaultBannerTemplateMatches = defaultBannerTemplateMatchesAny;
	vm.currentList = $stateParams.currentList || [];

	$scope.$watch('vm.solution.data.section.bannerId', () => {
		vm.queueReservation = defaultBannerTemplateMatchesAny(['queueReservation']);
	});

	function nameComparator(o1, o2) {
		const nameA = (o1 && o1.data && o1.data.name ? o1.data.name.toUpperCase(): '');
		const nameB = (o2 && o2.data && o2.data.name ? o2.data.name.toUpperCase(): '');
		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}
		return 0;
	}

	function defaultBannerTemplateMatchesAny(templateKeyList) {
		const selectedDefaultBanner = _find(vm.bannerList, (b) => {
			return (b.data.id === vm.solution.data.section.bannerId);
		});
		return (selectedDefaultBanner && templateKeyList.indexOf(selectedDefaultBanner.data.section.template) >= 0);
	}

	function submit() {
		vm.isSaving = true;

		if (!vm.solution.data.section.messageToCustomerCase) {
			// Don't allow messageToCustomerCase to be blank. If so, set to '-'
			vm.solution.data.section.messageToCustomerCase = '-';
		}

		vm.solution.$save().finally(function () {
			vm.isSaving = false;
		});
	}

	function cancel() {
		$state.go('^');
	}

	// when accepting to reset unsaved changes, reload the entity
	$scope.$on('resetResettables', () => {
		vm.solutionEditForm.$setPristine();
		solution.$get().catch(() => {
		});
	});

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: solution,
			itemType: APIConfigurationSectionService.sectionMap.solution
		}).then(function () {
			vm.solutionEditForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(solution, 'root.engage.solution.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(function () {
			vm.isSaving = false;
		});
	}

	function bannerName(banner) {
		const tmplName = $translate.instant(BannerService.templates()[banner.data.section.template]);
		return (banner.data.name || '') + ' – ' + tmplName;
	}
}
