import React from 'react';
import {Button} from 'react-bootstrap';

const HistoryIndicator = ({indicatorClick, historyIndicatorStatus}) => {
	const showHistoryIndicator = historyIndicatorStatus === 'visible';

	const cls = `history-indicator${!showHistoryIndicator ? ' hidden': ''}`;

	return (
		<div className={cls}>
			<h2 className="line">
				<span>
					<Button className="btn" variant="primary" size="xs" onClick={indicatorClick}>
						<i className="vngage-icon-up-dir"/>
					history
						<i className="vngage-icon-up-dir"/>
					</Button>
				</span>
			</h2>
		</div>

	);
};

export default HistoryIndicator;

