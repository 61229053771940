import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import immutable from 'immutable';
import Clipboard from 'react-copy-to-clipboard';
import {Modal, Button} from 'react-bootstrap';
import PluginAC from '../../desktopDeux/actionCreators/PluginAC';
import urlHelpers from '../../../common/urlHelpers';

export const StartCode = (props) => {
	const [copied, setCopied] = useState('');
	const {
			  status,
			  customerId,
			  startCode,
			  getStartCodeUsingId,
			  coBroConversationId,
			  coBrowsingStartUrl
		  } = props;

	const show = status === 'showStartCodeModal' && !coBroConversationId;

	const url = coBrowsingStartUrl
		? urlHelpers.injectParamToURL(coBrowsingStartUrl, 'vngageStartCode=' + startCode)
		: startCode;

	const getNewCode = () => {
		setCopied('');
		getStartCodeUsingId(customerId);
	};

	return (
		<Modal show={show} onHide={() => 0}>
			<Modal.Header closeButton>
				<Modal.Title>Generate start code</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!startCode
					? <div className="content-spinner"/>
					: startCode === 'none'
						? <div className="start-code-row start-code-text">Press button to generate a code</div>
						: <React.Fragment>
							<div className="start-code-row">
								<div className="start-code-text">
									Start code: {startCode} {copied === 'code' &&
								<span className="copied">copied!</span>}
								</div>
								<Clipboard onCopy={() => setCopied('code')} text={startCode}>
									<Button size="xs" variant="success">copy code</Button>
								</Clipboard>
							</div>
							{
								coBrowsingStartUrl &&
								<div className="start-code-row">
									<div className="start-code-text">
										Start URL: {url} {copied === 'url' && <span className="copied">copied!</span>}
									</div>
									<Clipboard onCopy={() => setCopied('url')} text={url}>
										<Button size="xs" variant="success">copy url</Button>
									</Clipboard>
								</div>
							}
						</React.Fragment>
				}
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={getNewCode}>Get new code</Button>
			</Modal.Footer>
		</Modal>
	);
};

StartCode.displayName = 'StartCode';

function mapStateToProps(state, ownProps) {

	const pluginMeta = state.getIn(['pluginData', 'meta']) || immutable.Map();
	const customerId = pluginMeta.get('customerId') || `incontact://${pluginMeta.get('contactId')}`;
	return {
		coBroConversationId: state.getIn(['coBro', 'conversationId']) || '',
		coBrowsingStartUrl: state.getIn(['account', 'coBrowsingStartUrl']) || '',
		status: pluginMeta.get('status') || '',
		startCode: pluginMeta.get('startCode') || 'none',
		customerId,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		getStartCodeUsingId: (customerId) => dispatch(PluginAC.getStartCodeUsingId(customerId)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(StartCode);




