import React, {useState, useMemo} from 'react';
import Format from '../../../utils/Format';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import AccountConfig from './../../../utils/AccountConfig';
import {useGetTranslation} from './../../LangContext';
import ScrollText from './../../common/ScrollText.react';

const ActionPanel = (props) => {
	const getTranslation = useGetTranslation();
	const [open, setOpen] = useState(false);
	const [searchStr, setSearchStr] = useState('');

	const {currentCaseType, sendAction, conversationId} = props;

	const handleClick = () => {
		setOpen(!open);
		setSearchStr(open ? '': searchStr);
	};

	const ubTrackAction = 'cobrowsing-action-menu:action-selected';
	const listData = useMemo(() => {
		const types = {
			'0': 'url',
			'1': 'video',
			'2': 'pdf',
			'3': 'image',
		};
		return AccountConfig.getActions(currentCaseType).map((actionPanel, panelIndex) => {
			return (
				<div className="action" key={actionPanel.panelName}>
					<strong>{actionPanel.panelName}</strong>
					{Format.asSearchFilteredList(actionPanel.actions, searchStr, 'name').map((action, index) => {
						const type = types[action.itemType] || types[0];
						const itemCls = 'item';
						const key = panelIndex * 100 + index;
						return (
							<div
								onClick={() => sendAction(conversationId, action)}
								id="white-label-cobrowser-menu-item"
								className={itemCls} key={action.name + key}
								ub-track={ubTrackAction}>
								<i className={'vngage-icon-' + action.icon} ub-track={ubTrackAction}/>
								<ScrollText
									text={action.name} cls="title-wrapper line-clamp line-clamp-1"
									ubTrack={ubTrackAction}
									title={type + ': ' + action.parameter}/>
							</div>
						);
					})}
				</div>
			);
		});
	}, [currentCaseType, sendAction, conversationId, searchStr]);

	let isVisible = open ? 'active': '';
	let cls = `toolbar-item ${isVisible}`;

	const tooltip = (
		<Tooltip id="toolbar-tooltip">
			{getTranslation('coFuncResources')}
		</Tooltip>);

	const ubTrackMenu = `cobrowsing-action-menu:${open ? 'close': 'open'}`;
	return (
		<span className={cls}>
			<OverlayTrigger placement="bottom" overlay={tooltip} delay={400}>
				<div className="vngage-icon-line-lightning" onClick={() => handleClick()}
					 ub-track={ubTrackMenu}/>
			</OverlayTrigger>
			{open ?
				<div className="docked-right-actions-menu">
					<div className="header" onClick={() => handleClick()} ub-track={ubTrackMenu}>
						<i className="vngage-icon-cancel" ub-track={ubTrackMenu}/>
						<span className="title" ub-track={ubTrackMenu}>
							{getTranslation('vgActions')}
						</span>
					</div>
					<div className="list-container">
						<div className="filter-wrapper">
							<input
								className="filter"
								onChange={(event) => setSearchStr(event.target.value)}
								placeholder={getTranslation('vgActionsFilter')}
							/>
						</div>
						{listData}
					</div>
				</div>
				: ''}
		</span>
	);

};
export default ActionPanel;

