import immutable from 'immutable';
import {filters} from './filters';
import {sharedReducerLib} from './sharedReducerLib';
import INITIAL_STATE from '../../INITIAL_STATE';
import urlHelpers from '../../../../common/urlHelpers';


function getNewNavigation(jsObj = {}) {
	return immutable.fromJS({
		url: '',
		owner: 'visitor',
		paused: false,
		doNotDisturb: false,
		restricted: false,
		isBlocked: false,
		blockedUrl: '',
		...jsObj
	});
}

function containsFragment(url, coBrowserDoNotDisturb) {
	return coBrowserDoNotDisturb.some(s => url.indexOf(s) >= 0);
}

function getLastNavigationType(messages) {
	return (filters.getLastFoundInArray(filters.identifier.navigation, messages) || {messageType: 'none'}).messageType;
}

function checkClassification(classification) {
	return {
		ok: true,
		unknown: true,
		blockedByWhiteList: false,
		invalidUrl: false
	}[classification] || false;

}

function endCoBro(state) {
	state = reset(state);
	return state;
}

function startCoBro(state, conversationId) {
	const messagesAsJS = (state.getIn(['conversations', conversationId, 'messages']) || immutable.List()).toJS();
	state = reset(state);
	state = state.setIn(['coBro', 'conversationId'], conversationId);
	state = initializeNavigation(state, conversationId, messagesAsJS);
	state = setCoBroMetadataExchangeMessages(state, conversationId, messagesAsJS);
	return state;
}

function initializeNavigation(state, conversationId, messages) {
	state = state.setIn(['coBro', 'addressbarNavigation'], getNewNavigation());
	state = addNavigationFromMessages(state, conversationId, messages);

	// make sure url is valid
	const lastVisitorNavigation = (state.getIn(['coBro', 'navigation']).findLast(nav => nav.get('owner') === 'visitor') || getNewNavigation());
	const lastNavigation = (state.getIn(['coBro', 'navigation']).last() || getNewNavigation());
	const lastNavigationIsDomUpload = lastNavigation.get('owner') === 'domUpload' && state.getIn(['coBro', 'domUploadStatus']) === 'ready';

	// if last is domUpload then url needs to be empty
	state = lastNavigationIsDomUpload
		? addNavigation(state, '', 'agent', 'menu')
		// add new url if last !== visitor last and last is not domUpload
		: lastNavigation.get('url') === lastVisitorNavigation.get('url')
			? state
			: addNavigation(state, lastVisitorNavigation.get('url'), lastVisitorNavigation.get('owner'), 'menu');

	return state;
}

function addNavigationFromMessages(state, conversationId, messages) {
	if (state.getIn(['coBro', 'conversationId']) === conversationId) {
		const navigation = filters.getFilteredArray(filters.identifier.navigation, messages);
		navigation.forEach(nav => {
			const classification = nav.classification || 'ok';
			const owner = nav.messageType === 'domUpload'
				? (nav.isDomNavigation //|| true // to test domOnlyCoBrowsing
					? 'domNavigation'
					: 'domUpload')
				: (nav.speaker && nav.speaker.userId
					? 'agent'
					: 'visitor');
			const incomingDnd = nav.bidirectional !== undefined && nav.bidirectional === false;
			state = addNavigation(state, nav.url, owner, null, (nav.originalSource || ''), incomingDnd, classification);
		});
	}
	return state;
}

function setCoBroMetadataExchangeMessages(state, conversationId, messages) {
	let metadataExchangeMessages = filters.getFilteredArray(filters.identifier.metadataExchange, messages);
	if (metadataExchangeMessages.length) {
		const visitId = state.getIn(['conversations', conversationId, 'visitId']);
		metadataExchangeMessages = metadataExchangeMessages.map(metaMsg => {
			// Decorate each metaMsg.metadataBag with some additional vngage-specific-data...
			return {
				...metaMsg,
				metadataBag: {
					...metaMsg.metadataBag,
					_vngage_id: metaMsg.id,
					_vngage_createdAt: metaMsg.createdAt,
					_vngage_recieveType: 'state', // 'state' or 'since' to let the visitor script decide what to do with it
					_vngage_direction: ((metaMsg.speaker.visitId && metaMsg.speaker.visitId === visitId ? 'in': 'out'))
				}
			};
		});
		state = state.setIn(['coBro', 'metadataExchangeMessages'], immutable.fromJS(metadataExchangeMessages));
	}
	return state;
}

function addCoBroMetadataExchangeMessages(state, conversationId, messages) {
	let newMetadataExchangeMessages = filters.getFilteredArray(filters.identifier.metadataExchange, messages);
	if (newMetadataExchangeMessages.length) {
		const visitId = state.getIn(['conversations', conversationId, 'visitId']);
		newMetadataExchangeMessages = newMetadataExchangeMessages.map(metaMsg => {
			// Decorate each metaMsg.metadataBag with some additional vngage-specific-data...
			return {
				...metaMsg,
				metadataBag: {
					...metaMsg.metadataBag,
					_vngage_id: metaMsg.id,
					_vngage_createdAt: metaMsg.createdAt,
					_vngage_recieveType: 'since', // 'state' or 'since' to let the visitor script decide what to do with it
					_vngage_direction: ((metaMsg.speaker.visitId && metaMsg.speaker.visitId === visitId ? 'in': 'out'))
				}
			};
		});
		state = state.updateIn(['coBro', 'metadataExchangeMessages'], existingMetadataExchangeMessages => existingMetadataExchangeMessages.concat(immutable.fromJS(newMetadataExchangeMessages)));
	}
	return state;
}

function addNavigation(state, url, owner, updateType, originalSource = '', incomingDnd = false, classification = 'ok') {
	const domCoBrowsing = state.getIn(['visitorSettings', 'domCoBrowsing']);
	const coBrowsingWhitelist = (state.getIn(['account', 'coBrowsingWhiteListHostNames']) || immutable.List()).toJS();
	const emptyUrl = url === '';

	if (domCoBrowsing && owner === 'visitor') {
		// console.log('skipping visitor navigation on  domCoBrowsing==true')
		return state;
	}
	if (owner === 'domNavigation') {
		owner = 'visitor';
	}

	// is url blocked
	const blocked = state.getIn(['coBro', 'blocked']);
	const originalSourceIsBlocked = originalSource &&
		!urlHelpers.urlIsWhitelisted(originalSource, coBrowsingWhitelist) ||
		blocked.contains(originalSource);

	const urlIsBlocked = !emptyUrl &&
		(!checkClassification(classification) ||
			!urlHelpers.urlIsWhitelisted(url, coBrowsingWhitelist) ||
			blocked.contains(url));

	const blockedUrl = urlIsBlocked
		? url
		: originalSourceIsBlocked
			? originalSource
			: '';

	const isBlocked = urlIsBlocked || originalSourceIsBlocked;
	// console.log(!checkClassification(classification),!urlHelpers.urlIsWhitelisted(url, coBrowsingWhitelist.toJS()),blocked.contains(url))

	originalSource = originalSource === ''
		? url
		: originalSource;
	originalSource = urlHelpers.removeVngageParamsFromURL(originalSource);
	const currentDoNotDisturb = state.getIn(['coBro', 'doNotDisturb']);
	// force pause if agent navigates during doNotDisturb
	state = currentDoNotDisturb && owner === 'agent'
		? state.setIn(['coBro', 'paused'], true)
		: state;

	const paused = state.getIn(['coBro', 'paused']);
	const lastNavigation = (state.getIn(['coBro', 'navigation']).last() || getNewNavigation({paused}));

	const lastNavigationUrlIsDifferent = lastNavigation.get('url') !== originalSource;
	const lastOwner = lastNavigation.get('owner');
	const isValidOwner = lastOwner === 'domUpload' ? true: owner !== 'agent';

	let navigation = state.getIn(['coBro', 'navigation']);

	const restricted = containsFragment(originalSource, state.getIn(['account', 'coBrowserRestricted']));
	const doNotDisturb = incomingDnd || containsFragment(url, state.getIn(['account', 'coBrowserDoNotDisturb']));
	const isDomOnlyNavigation = originalSource !== url;

	navigation = lastOwner !== owner || lastNavigationUrlIsDifferent
		? navigation.push(
			getNewNavigation({
				url: originalSource,
				owner,
				paused,
				doNotDisturb,
				restricted,
				isBlocked,
				blockedUrl
			})
		)
		: navigation;

	// only a visitor can be restricted
	state = owner === 'visitor'
		? state.setIn(['coBro', 'restricted'], restricted)
		: state;

	// only a visitor can be doNotDisturb
	state = owner === 'visitor'
		? state.setIn(['coBro', 'doNotDisturb'], doNotDisturb)
		: state;

	state = state.setIn(['coBro', 'navigation'], navigation);

	state = state.setIn(['coBro', 'isBlocked'], isBlocked);
	// only a visitor can be blocked
	// state = owner === 'visitor'
	// 	? state.setIn(['coBro', 'isBlocked'], isBlocked)
	// 	: state;

	state = isBlocked
		? state.updateIn(['coBro', 'blocked'], blocked => blockedUrl && !blocked.contains(blockedUrl) ? blocked.push(blockedUrl): blocked)
		: state;

	// if domUpload then reset status
	state = owner === 'domUpload'
		? state.setIn(['coBro', 'domUploadStatus'], 'ready')
		: state;

	// if domUpload then save url in lastDomUploadUrl
	state = owner === 'domUpload' || isDomOnlyNavigation
		? state.setIn(['coBro', 'lastDomUploadUrl'], url)
		: state;

	// if agent navigagtes from domUpload to normal site in paused mode, always set url in iFrame
	const navigatedFromDomUpload = state.getIn(['coBro', 'url']) === state.getIn(['coBro', 'lastDomUploadUrl']) && url !== state.getIn(['coBro', 'lastDomUploadUrl']);

	// update iFrame url
	const shouldUrlUpdate = updateType === 'menu' ||
		(navigatedFromDomUpload && owner === 'agent') ||
		(isValidOwner && (lastNavigationUrlIsDifferent || isDomOnlyNavigation) && !paused && !restricted);

	const shouldResetUrl = isBlocked && ((!paused) || (paused && owner === 'agent'));
	state = shouldResetUrl
		? state.setIn(['coBro', 'url'], '')
		: shouldUrlUpdate
			? state.setIn(['coBro', 'url'], url)
			: state;

	// force update if url selected from menu
	state = updateType === 'menu'
		? state.updateIn(['coBro', 'refreshUrlCounter'], n => n + 1)
		: state;

	// set infoTip if visitor navigates to doNotDisturb page
	state = sharedReducerLib.setDoNotDisturbInfoTip(state, currentDoNotDisturb, state.getIn(['coBro', 'doNotDisturb']));

	// addressbar url
	// when paused only changes when the agent navigates
	state = paused && owner !== 'agent'
		? state
		: state.setIn(['coBro', 'addressbarNavigation'], navigation.last());

	return state;
}

function reset(state) {
	state = state.set('coBro', INITIAL_STATE.get('coBro'));
	return state;
}

function setHighlightMode(state, isActive) {
	const isPaused = state.getIn(['coBro', 'paused']);
	state = state.setIn(['coBro', 'highlightMode'], isPaused ? false: isActive);
	return state;
}

function toggleCoBroScale(state) {
	state = state.updateIn(['coBro', 'isScaled'], n => !n);
	return state;
}

function toggleCoBroPause(state) {
	state = state.updateIn(['coBro', 'paused'], n => !n);

	const domCoBrowsing = state.getIn(['visitorSettings', 'domCoBrowsing']);
	const isPaused = state.getIn(['coBro', 'paused']);

	const restricted = state.getIn(['coBro', 'restricted']);
	const isBlocked = state.getIn(['coBro', 'isBlocked']);

	const lastVisitorNavigation = state.getIn(['coBro', 'navigation']).findLast(nav => nav.get('owner') === 'visitor' && !nav.get('restricted') && !nav.get('isBlocked'));

	// if no unrestricted visitorNavigation exist or set url to empty
	const lastVisitorUrl = lastVisitorNavigation === undefined
		? ''
		: lastVisitorNavigation.get('url');

	state = sharedReducerLib.forceCloseInfoTip(state, 'doNotDisturb');

	state = state.updateIn(['coBro', 'highlightMode'], n => isPaused ? false: n);
	state = isPaused
		? state
		: lastVisitorUrl === '' || restricted || isBlocked
			? state
			: addNavigation(state, lastVisitorUrl, 'visitor');
	state = state.updateIn(['coBro', 'url'], prevUrl => isPaused ? prevUrl: restricted || isBlocked || domCoBrowsing ? '': lastVisitorUrl);

	return state;
}

function setCoBroDomUploadStatus(state, status) {
	return state.setIn(['coBro', 'domUploadStatus'], status);
}

export const coBroLib = {
	getNewNavigation,
	containsFragment,
	getLastNavigationType,
	checkClassification,
	endCoBro,
	startCoBro,
	initializeNavigation,
	addNavigationFromMessages,
	setCoBroMetadataExchangeMessages,
	addCoBroMetadataExchangeMessages,
	addNavigation,
	reset,
	setHighlightMode,
	toggleCoBroScale,
	toggleCoBroPause,
	setCoBroDomUploadStatus,
};