export default function($q, APIEndpointService) {
    'ngInject';

    var AccountRolesService = {};

    // Formatting of selectableList:s
    // ------------------------------
    AccountRolesService.formatSelectableUsers = function(users) {
        var formattedValues = [];
        users.forEach(function(user) {
            formattedValues.push({
                displayName: user.data.profile.displayName,
                refValue: user.data.id
            });
        });
        return formattedValues;
    };

    AccountRolesService.formatSelectedUsers = function(users, claimType) {
        var formattedValues = [];
        users.forEach(function(user) {
            if (user.data.roleClaims) {
                user.data.roleClaims.forEach(function(userRoleClaim) {
                    if (userRoleClaim === claimType) {
                        formattedValues.push(user.data.id);
                    }
                });
            }
        });
        return formattedValues;
    };

    AccountRolesService.formatSelectableDependsOn = function(roleClaims) {
        var formattedValues = []; // [{reference: 'refvalue', displayName: 'displayName'}]
        angular.forEach(roleClaims, function(roleClaim) {
            formattedValues.push({
                refValue: roleClaim.data.claimType,
                displayName: roleClaim.data.displayName
            });
        });
        return formattedValues;
    };

    // Roles for Users
    // ---------------

    // TODO maybe should get the users straight from server instead?
    AccountRolesService.getUserById = function(allUsers, userId) {
        for (var i = 0; i < allUsers.length; i++) {
            if (allUsers[i].data.id === userId) {
                return allUsers[i];
            }
        }
    };

    // saves multiple users to server, returns an aggregated promise for the requests
    AccountRolesService.saveMultipleUsers = function(usersToSave) {
        var requestPromises = [];
        usersToSave.forEach(function(userToSave) {
            requestPromises.push(APIEndpointService.user.save({ id: userToSave.data.id }, userToSave).$promise);
        });
        return $q.all(requestPromises);
    };

    // adds a claimType to an array of users, returns an array with the updated users
    AccountRolesService.addRoleClaimToUsers = function(allUsers, userIds, claimType) {
        var usersToUpdate = [];
        var user;
        userIds.forEach(function(userId) {
            user = AccountRolesService.getUserById(allUsers, userId);
            if (!user.data.roleClaims) {
                user.data.roleClaims = [];
            }
            user.data.roleClaims.push(claimType);
            usersToUpdate.push(user);
        });
        return usersToUpdate;
    };

    // removes a claimType from an array of users, returns an array with the updated users
    AccountRolesService.removeRoleClaimFromUsers = function(allUsers, userIds, claimType) {
        var usersToUpdate = [];
        userIds.forEach(function(userId) {
            var user = AccountRolesService.getUserById(allUsers, userId);
            for (var i = 0; i < user.data.roleClaims.length; i++) {
                if (user.data.roleClaims[i] === claimType) {
                    user.data.roleClaims.splice(i, 1);
                }
            }
            usersToUpdate.push(user);
        });
        return usersToUpdate;
    };

    return AccountRolesService;
};
