import immutable from 'immutable'
import {Store} from '../stores/Store';

let testInterval;
export const startCrashDetector = (showModal) => {
	testInterval = setInterval(() => {
		const inDesktopTab = !!document.getElementById('vngage-desktop-deux-app');
		const desktopWrapper = document.getElementsByClassName('desktop-wrapper');
		if (inDesktopTab && desktopWrapper.length === 0) {
			const storeOk = Store && Store.getState && Store.getState().get;
			if ((storeOk && Store.getState().get('desktopStatus') === 'desktop') || !storeOk) {
				// no desktop element but desktop is initialised
				// or Store is broken
				clearInterval(testInterval);
				showModal('crash');
			}
		}
	}, 2500);

};

export const endCrashDetector = () => {
	clearInterval(testInterval);
};


