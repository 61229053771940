import _defaults from 'lodash/defaults';
import _find from 'lodash/find';
import _each from 'lodash/each';
import _isString from 'lodash/isString';

export default function ($q, $http, $parse, vngageLessWrapper, vngageThemeConfigDefaults, usSpinnerService) {
	'ngInject';

	function setup(lessSource, baseTheme, themeConfig, lessConfig) {

		// Defaults
		lessSource = lessSource || '';
		themeConfig = _defaults(themeConfig || {}, vngageThemeConfigDefaults);
		lessConfig = _defaults(lessConfig || {}, {globalVars: {}});

		baseTheme = _find(themeConfig.themes, theme => {
			return baseTheme ? theme.name === baseTheme : theme.defaultTheme;
		});
		if (baseTheme) {
			themeConfig.selectedTheme = baseTheme;
		}

		themeConfigSetup(lessSource, themeConfig);

		return {
			preview: preview.bind(this, themeConfig, lessConfig),
			source: source.bind(this, themeConfig, lessConfig),
			validate: validate.bind(this, themeConfig, lessConfig),
			variables: variables.bind(this, themeConfig),
			config: () => {
				return themeConfig;
			}
		};
	}

	return setup;


	/**
	 *    THEME SERVICE METHODS
	 */
	function preview(themeConfig, lessConfig) {
		let less = '';

		// Inject visitor "static.less" to emulate the injected vngageStaticCss style on visitor
		less += '@import "' + themeConfig.basePath + 'static";\n';

		// We're creating CSS, so we need to add selectedTheme as import
		if (themeConfig.selectedTheme) {
			less += '@import "' + themeConfig.basePath + themeConfig.selectedTheme.path + '";\n';
		}

		less = formatLess(themeConfig, less);
		usSpinnerService.spin('themeSpinner');

		return $q((resolve, reject) => {
			vngageLessWrapper.parse(less, lessConfig, true).then(result => {
				usSpinnerService.stop('themeSpinner');
				resolve(result);
			}).catch(error => {
				usSpinnerService.stop('themeSpinner');
				reject(error);
			});
		});
	}

	function source(themeConfig, lessConfig) {
		return $q((resolve, reject) => {
			validate(themeConfig, lessConfig).then(() => {
				// No need for import when we're saving LESS (node will import on it's own)
				resolve(formatLess(themeConfig));
			}).catch(error => {
				// Reject! I.e. don't swallow errors!
				reject(error);
			});
		});
	}

	function validate(themeConfig, lessConfig, customLess) {	// Take a third parameter "customLess". If supplied, us instead of themeConfig.custom to be able to validate customLess before the model is updated
		return $q((resolve, reject) => {
			usSpinnerService.spin('themeSpinner');
			const custom = getAdditionalImports(themeConfig) + (customLess || themeConfig.custom || '');
			vngageLessWrapper.parse(custom, lessConfig).then(() => {
				usSpinnerService.stop('themeSpinner');
				resolve();
			}).catch(error => {
				usSpinnerService.stop('themeSpinner');
				reject(error);
			});
		});
	}

	function variables(themeConfig) {
		return $q((resolve, reject) => {
			if (!_isString(themeConfig.defaultVariables)) {
				reject();
			} else {
				$http({
					method: 'GET',
					url: themeConfig.basePath + themeConfig.defaultVariables + '.less',
					skipAuthorization: true,
					transformRequest: (data, headers) => {
						delete headers().Authorization;
					}
				}).then(response => {
					const result  = [];
					const content = response.data;
					content.split('\n').forEach(line => {
						const test = line.match(/\s*@(\w+): (.+);/);
						if (test) {
							result.push({
								key: test[1],
								value: test[2]
							});
						}
					});
					resolve(result);
				}).catch(error => {
					reject();
				});
			}
		});
	}


	/**
	 *    FORMAT INTO LESS
	 *    Creates less string from custom less + variables
	 */
	function formatLess(themeConfig, baseLess) {
		let theme = _isString(baseLess) ? baseLess : '';

		_each(themeConfig.variables, (value, key) => {
			if ((value || value === false)) {
				theme += '@' + key + ': ' + value + ';\n';
			}
		});

		themeConfig.dynamicVars.forEach(v => {
			const regex = new RegExp('(@' + v.key + ':) (.+); ?(\/\/(.+))', 'i');
			themeConfig.custom = themeConfig.custom.replace(regex, '$1 ' + v.value + ';' + ' $3');
		});

		theme += themeConfig.custom + '\n';

		theme = theme.replace(/(^\s|\s$)/g, '');

		return theme;
	}

	function getAdditionalImports(themeConfig) {
		let result = '';
		themeConfig.library.concat(themeConfig.defaultVariables).forEach(path => {
			result += '@import "' + themeConfig.basePath + path + '";\n';
		});
		return result;
	}


	/**
	 *    PARSE SOURCE
	 *    Following methods parses the less source into variables,
	 *    dynamic variables, and custom less field.
	 */
	function themeConfigSetup(lessSource, themeConfig) {
		const lines    = (lessSource || '').split('\n');
		const matchers = [importMatcher, dynamicVariableMatcher, defaultVariableMatcher];

		themeConfig.custom = '';
		themeConfig.dynamicVars = [];

		lines.forEach(line => {
			matchers.some(matcher => {
				return matcher(themeConfig, line);
			});
		});

		themeConfig.custom = themeConfig.custom.replace(/^\s|\s$/g, '');
	}


	/**
	 *   Matches imports
	 */
	function importMatcher(themeConfig, line) {
		const result = line.match(/@import (?:"|')(?:@{(\w+)}|(.+))(?:"|');/);
		if (!result) {
			return false;
		}

		const importName = result[2];
		const importVariable = result[1];
		const base = _find(themeConfig.themes, t => {
			return t.name === importVariable;
		});
		if (base) {
			themeConfig.selectedTheme = base;
			return true;
		}

		const defaultVars = themeConfig.basePath + themeConfig.defaultVariables === importName;
		const lib = _find(themeConfig.library, l => {
			return themeConfig.basePath + l === importName;
		});

		return !!(lib || defaultVars);
	}

	/**
	 *   Matches variables and custom lines
	 */
	function defaultVariableMatcher(themeConfig, line) {
		let key, value,
			result        = line.match(/@(\w+): (.+);/),
			confVariables = themeConfig.variables;

		if (result) {
			key = result[1];
			value = result[2];
		}

		if (confVariables.hasOwnProperty(key)) {
			confVariables[key] = convertStringBoolean(value);
		} else {
			themeConfig.custom += line + '\n';
		}

		return true;
	}

	/**
	 *   Matches dynamic variables
	 */
	function dynamicVariableMatcher(themeConfig, line) {
		const result = line.match(/@(\w+): (.+); ?\/\/(.+)/i);
		if (!result) {
			return false;
		}

		const key = result[1];
		const value = result[2];
		const meta = result[3];
		try {
			const parsed = $parse('{' + meta + '}')();
			themeConfig.dynamicVars.push({
				key: key,
				value: convertStringBoolean(value),
				type: parsed.type,
				label: parsed.label,
				desc: parsed.desc,
				colorType: parsed.colorType ? parsed.colorType : null,
				metadata: '//' + result[result.length - 1]
			});
			themeConfig.custom += line + '\n';
		} catch (e) {
			return false;
		}

		return true;
	}

	function convertStringBoolean(value) {
		if (value === 'true') {
			value = true;
		} else if (value === 'false') {
			value = false;
		}
		return value;
	}

}
