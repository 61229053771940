export default function (toastr) {
	'ngInject';

	function success (message, options) {
		toastr.success(message.body, message.header, options);
	}

	function info (message, options) {
		toastr.info(message.body, message.header, options);
	}

	function error (message, options) {
		options = options || {};
		options.timeOut = options.timeOut || 10000;
		return toastr.error(message.body, message.header, options);
	}

	function warning (message, options) {
		toastr.warning(message.body, message.header, options);
	}


	return {
		success: success,
		info: info,
		error: error,
		warning: warning
	};
}
