export default function ($sce, $parse, $compile, vngageMarkdown) {
	'ngInject';

	function findLabel(element) {
		var el = element[0].previousSibling;

		while (el && el.nodeType === 3) {
			el = el.previousSibling;
		}

		return el && /label/i.test(el.nodeName) ? el : null;
	}

	return {
		restrict: 'AE',
		require: 'ngModel',
		scope: {
			model: '=ngModel'
		},
		controller: function () {},
		controllerAs: 'vm',
		bindToController: true,
		link: function (scope, element) {
			var label = angular.element(findLabel(element));

			scope.$watch('vm.model', function () {
				scope.vm.trustedHtml = $sce.trustAsHtml(vngageMarkdown(scope.vm.model));
			});

			scope.vm.toggleLegend = function ($event) {
				$event.preventDefault();
				scope.vm.showLegend = !scope.vm.showLegend;
			};

			label.addClass('markdown-label');
			label.append($compile(angular.element('<vngage-markdown-legend/>'))(scope));

			element.addClass('markdown-input');
			element.after($compile(angular.element('<vngage-markdown-preview/>'))(scope));
		}
	};

};
