import _throttle from '../../../../../../node_modules/lodash/throttle';

export default function ($timeout, $window) {
	'ngInject';

	return {
		restrict: 'A',
		scope: {
			registerTableUpdatedCallback: '='
		},
		controller: function($scope) {
			'ngInject';

			let vm = this;
			let header, viewport, stickyHeader, headerPosY;
			let scrollLeft = 0;
			let stick = false;

			function renderStickyHeader() {
				const x=viewport.scrollLeft;
				const y=viewport.scrollTop;

				if (x !== scrollLeft) {
					scrollLeft = x;
					stickyHeader[0].style.transform = `translate3d(-${x}px, 0, 0)`;
				}
				if (
					(stick && y < headerPosY) ||
					(!stick && y > headerPosY)
				) {
					stick = !stick;
					header.parent().toggleClass('fixed-mode', stick);
				}
			}

			function adjustStickyHeaderStyle() {
				const cols = Array.from(header[0].children);
				cols.forEach((col,index) => {
					stickyHeader[0].children[index].style.width = col.offsetWidth + 'px';
				});
				stickyHeader[0].style.width = header[0].offsetWidth + 'px';
				headerPosY = findPos(header[0], viewport);
			};

			function findPos(obj, withinObj) {
				let top = 0;
				withinObj = withinObj || document.body;

				if (obj.offsetParent) {
					do {
						if (obj === withinObj) {
							break;
						}
						top += obj.offsetTop;
					} while (obj = obj.offsetParent);
				}
				return top;
			}

			const onScroll = _throttle(() => {
				renderStickyHeader();
			}, 100);

			const onWindowResize = _throttle(() => {
				adjustStickyHeaderStyle();
				renderStickyHeader()
			}, 100);

			function init() {
				header=$scope.header;
				viewport = document.getElementById('main-container');
				headerPosY = findPos(header[0], viewport);
				stickyHeader = header.clone();
				stickyHeader.addClass('header-sticky');
				stickyHeader.removeAttr('sticky-header');
				header.parent().append(stickyHeader);
				adjustStickyHeaderStyle();

				angular.element(viewport).on('scroll', onScroll);
				angular.element($window).on('resize', onWindowResize);

				$scope.$on('$destroy', () => {
					angular.element(viewport).off('scroll', onScroll);
					angular.element($window).off('resize', onWindowResize);
				});

				if ($scope.registerTableUpdatedCallback) {
					$scope.registerTableUpdatedCallback(adjustStickyHeaderStyle);
				}
			}

			vm.init = init;
		},
		link: ($scope, element, attributes, controller) => {
			$scope.header = $(element, this);
			$timeout(() => {
				// Init after after $timeout to make sure all column header has been rendered by angular before creating the sticky header
				controller.init();
			}, 0);
		}
	}
}
