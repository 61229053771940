import {
	ConversationRecord,
	UIRecord,
	StreamingRecord,
	ParticipantRecord,
	UserInfoRecord
} from '../records/Conversation';
import {
	ProfileRecord,
	ProfileDataRecord,
	ProfileMetaRecord,
	BrowserRecord,
	OSRecord,
	ClaimRecord
} from '../records/Profile';
import {ReservationRecord, ReservationMetadataRecord, CustomFormRecord} from '../records/Reservation';
import {VisitorRecord} from '../records/Visitor';
import * as Constants from '../constants';
import {fromJS} from 'immutable';

export const Conversation = {

	parse(props) {

		let conversation = new ConversationRecord();
		conversation = this.update(conversation, props);
		return conversation;
	},

	update(conversation, props) {

		const copy = {...props};

		if (copy.stateTime) {
			copy.since = copy.stateTime;
			delete copy.stateTime;
		}

		return conversation.merge(copy);
	},

	addParticipant(conversation, participant) {

		return conversation.setIn(['participants', participant.id], participant);
	},

	addEvent(conversation, props) {

		const event = fromJS(props);

		switch (props.type) {
			case Constants.ConversationEventTypes.PARTICIPANT_INVITED:

				conversation = this.updateUI(conversation, {eligibleUntil: props.eligableUntil});
				break;

			default:

				break;
		}

		return conversation.update('events', events => events.push(event));
	},

	addImmutableEvent(conversation, event) {

		return conversation.update('events', events => events.push(event));
	},

	addMessage(conversation, message) {

		return conversation.update('events', events => events.push(event));
	},

	updateUI(conversation, props) {

		return conversation.mergeIn(['UI'], props);
	},

	setStatus(conversation, status) {

		return conversation.set('conversationStatus', status);
	},

	getTitle(conversation) {

		return conversation.get('title');
	}
}

export const Participant = {

	parse(props) {

		let participant = new ParticipantRecord();
		participant = this.update(participant, props);
		return participant;
	},

	update(participant, props) {

		return participant.merge(props);
	},

	setInfo(participant, props) {

		const info = new UserInfoRecord(props);

		return participant.set('info', info);
	}
}

export const Profile = {

	parse(props) {

		let profile = new ProfileRecord();
		profile = this.update(profile, props);
		return profile;
	},

	update(profile, props) {

		return profile.merge(props);
	},

	addClaim(profile, props) {

		const claim = new ClaimRecord(props);

		return profile.updateIn(['data', 'claims'], claims => claims.push(claim));
	},

	getMetaInfo(profile) {

		const osName = profile.getIn(['meta', 'os', 'osName']);
		const deviceName = profile.getIn(['meta', 'os', 'deviceName']);
		const browserName = profile.getIn(['meta', 'browser', 'name']);
		const browserVersion = parseInt(profile.getIn(['meta', 'browser', 'version']), 10);

		return `${osName} ${deviceName} ${browserName} ${browserVersion}`;
	},

	getDevice(profile) {

		return profile.getIn(['meta', 'os', 'deviceName']);
	},

	getMeta(profile) {

		return profile.get('meta');
	}
}

export const Reservation = {

	parse(props) {

		let reservation = new ReservationRecord();
		reservation = this.update(reservation, props);
		return reservation;
	},

	update(reservation, props) {

		return reservation.merge(props);
	},

	addForm(reservation, props) {

		const form = new CustomFormRecord(props);

		return reservation.updateIn(['metadata', 'customForms'], customForms => customForms.push(form));
	},

	getForms(reservation) {

		return reservation.getIn(['metadata', 'customForms']);
	},

	getGroupId(reservation) {

		return reservation.get('groupId');
	},

	getStartTime(reservation) {

		return reservation.get('start');
	}
}

export const Visitor = {

	parse(props) {

		let visitor = new VisitorRecord();
		visitor = this.update(visitor, props);
		return visitor;
	},

	update(visitor, props) {

		return visitor.merge(props);
	}
}

export default fromJS({
	chat: {
		messageType: 'chat',
		type: 'conversationMessage',
		recipients: null,
		isRead: true,
		message: '',
		createdAt: '',
		createdRaw: 0,
		tags: ['']
	},
	note: {
		messageType: 'note',
		type: 'conversationMessage',
		recipients: null,
		isRead: true,
		message: '',
		createdAt: null,
		createdRaw: 0,
		tags: ['']

	},
	link: {
		messageType: 'link',
		type: 'conversationMessage',
		recipients: null,
		createdAt: null,
		createdRaw: 0,
		url: '',
		linkType: 'link',
		title: '',
		tags: []
	},

	video: {
		messageType: 'video',
		type: 'conversationMessage',
		activity: '',
		message: '',
		streamId: '',
		createdAt: null,
		createdRaw: 0,
		recipients: null,
		tags: []
	},

	videoLink: {
		messageType: 'link',
		type: 'conversationMessage',
		recipients: null,
		createdAt: null,
		createdRaw: 0,
		url: '',
		linkType: 'video',
		title: '',
		tags: []
	},

	pdf: {
		messageType: 'link',
		type: 'conversationMessage',
		recipients: null,
		createdAt: null,
		createdRaw: 0,
		url: '',
		linkType: 'pdf',
		title: '',
		tags: []
	},

	photo: {
		messageType: 'link',
		type: 'conversationMessage',
		recipients: null,
		createdAt: null,
		createdRaw: 0,
		url: '',
		linkType: 'photo',
		title: '',
		tags: []
	},

	domHighlight: {
		messageType: 'domHighlight',
		type: 'conversationMessage',
		recipients: null,
		activity: '',
		tags: []
	},

	domNavigation: {
		messageType: 'navigation',
		type: 'conversationMessage',
		url: '',
		bidirectional: true,
		recipients: null,
		createdAt: null,
		speaker: null
	},

	domUploadRequest: {
		messageType: 'askForDomUpload',
		type: 'conversationMessage',
		recipients: null,
		activity: '',
		tags: []
	},

	metadataExchange: {
		messageType: 'metadataExchange',
		type: 'conversationMessage',
		createdAt: '',
		tags: ['']
	},

	js: {},

	form: {},

	render: {
		type: 'conversationMessage',
		messageType: 'link',
		linkType: 'render',
		linkId: '',
		title: '',
		actionId: '',
		actionData: null,
		recipients: null,
		createdAt: null,
		createdRaw: 0,
		tags: []
	},

	content: {
		messageType: 'content',
		type: 'conversationMessage',
		recipients: null,
		isRead: true,
		createdAt: '',
		createdRaw: 0,
		key: '',
		uploadInfo: {
			filename: 'none',
			fileSize: 0,
			mimeType: 'none',
		},
		tags: ['']
	},

	visitorProfile: {
		messageType: 'visitorProfile',
		type: 'conversationMessage',
		recipients: null,
		isRead: true,
		message: '',
		createdAt: '',
		createdRaw: 0,
		claims: [],
		status: 'received',
		speaker: {
			role: 'agent',
			id: '',
			name: ''
		},
		tags: ['first']
	},

	divider: {
		messageType: 'divider',
		type: 'conversationMessage',
		recipients: null,
		isRead: true,
		message: '',
		createdAt: '',
		createdRaw: 0,
		status: 'received',
		speaker: {
			role: 'agent',
			id: '',
			name: ''
		},
		tags: ['']
	},

	historyMarker: {
		messageType: 'historyMarker',
		type: 'conversationMessage',
		caseId: '',
		recipients: null,
		isRead: true,
		message: '',
		createdAt: '',
		createdRaw: 0,
		status: 'received',
		speaker: {
			role: 'agent',
			id: '',
			name: ''
		},
		tags: ['']
	},

})

