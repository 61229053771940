import React from 'react';
import createReactClass from 'create-react-class';
import ViewActions from './../actions/ViewActions';
import EasyRTCApp from './../../../components/VideoEasyRTC';
import ConversationsStore from './../stores/ConversationsStore';
import Video from './../../../components/Video.react';
import { injectIntl } from 'react-intl';
import easyrtc from './../../../../vendor/easyrtc_v1.1.1-rc2/easyrtc';

import {Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter, Button} from 'react-bootstrap';

export default injectIntl(createReactClass({

	getInitialState () {
		return getStateFromStores(this.props.conversationId);
	},

	componentDidMount () {
		this.mounted = true;
		ConversationsStore.addChangeListener(this._onChange);
		if (this.props.useFlash) { return; }
		if (this.state.streaming.video){
			EasyRTCApp.start(this.props.conversationId, 'agent', this.props.streamingServer);
		}
	},

	componentWillUnmount () {
		ConversationsStore.removeChangeListener(this._onChange);
		if (this.props.useFlash) { return; }
		EasyRTCApp.leave(this.props.conversationId);
		this.mounted = false;
	},

	componentDidUpdate () {
		if (!this.state.streaming.video) {
			EasyRTCApp.leave(this.props.conversationId);
		}
		if (this.props.useFlash || !this.state.streaming.video) {
			return;
		}
		EasyRTCApp.start(this.props.conversationId, 'agent', this.props.streamingServer);
	},

	_onChange () {
		if (!this.mounted) {
			return false;
		}
		this.setState(getStateFromStores(this.props.conversationId));
	},

	onCreateStream () {
		if (!this.props.useFlash){
			if (easyrtc && !!easyrtc.getRoomsJoined()){
				if (Object.keys(easyrtc.getRoomsJoined()).length){
					this.openModal();
					return false;
				}
			}
		}

		ViewActions.setupVideoChat(this.props.conversationId, {activity:'start', streamId:this.props.streamId});

		if (this.props.useFlash){
			ViewActions.sendVideoChatInvitation(this.props.conversationId);
		}
	},

	onStopStream () {
		EasyRTCApp.leave(this.props.conversationId);
		ViewActions.sendVideoChatClosure(this.props.conversationId, {activity:'stop', streamId:this.props.streamId});
	},

	openModal () {
		this.setState({ showModal: true });
	},

	closeModal () {
		this.setState({ showModal: false });
	},

	render () {
		return (
			<span>
				<Modal show={this.state.showModal} onHide={this.closeModal}>
					<Modal.Header><Modal.Title><i className="vngage-icon-videocam"></i> {this.props.intl.formatMessage({id:'videoAlreadyConnectedTitle'})}<i onClick={this.closeModal} className="vngage-icon-cancel pull-right"></i></Modal.Title></Modal.Header>
					<Modal.Body><p>{this.props.intl.formatMessage({id:'videoAlreadyConnectedMessage'})}</p></Modal.Body>
					<Modal.Footer><Button onClick={this.closeModal}>{this.props.intl.formatMessage({id:'videoAlreadyConnectedCloseAction'})}</Button></Modal.Footer>
				</Modal>
				<Video
					onCreateStream={this.onCreateStream}
					onStopStream={this.onStopStream}
					conversationId={this.props.conversationId}
					streamingServer={this.props.videoStreamingServer}
					streamId={this.props.conversationId}
					visitorHasFlash={this.props.visitorHasFlash}
					streaming={this.state.streaming}
					useFlash={this.props.useFlash}
					roomOwner={this.props.roomOwner}
				/>
			</span>
		);
	}

}));

function getStateFromStores(conversationId) {
	let conversation = ConversationsStore.find(conversationId);
	conversation = conversation || {streaming: {audio: false, video: false}};

	return {
		streaming: conversation.streaming
	};
}
