import VideoActivity from '../constants/VideoActivity';
import VideoLib from '../reducers/libs/videoLib';
import Localized from '../actionCreators/Localized';
import Message from '../actionCreators/Message';
import ConversationActions from '../actions/ConversationActions';
import NavigationActions from '../actions/NavigationActions';
import VideoActions from '../actions/VideoActions';
import ToolBarSectionType from '../constants/ToolBarSectionType';
import ModalType from '../constants/ModalType';
import VideoError from '../constants/VideoError';
import VideoState from '../constants/VideoState';
import VideoTools from '../utils/VideoTools';
import LocalStorage from '../stores/LocalStorageWrapper';

const resumeConversationVideo = conversationId => (dispatch, getState) => {
	const lastVideoMessage = VideoLib.getLastVideoMessage(getState(), conversationId) || {};
	const isStarting = lastVideoMessage.activity === VideoActivity.START;
	if (isStarting) {
		dispatch(VideoActions.setRoomId(conversationId));
		dispatch(VideoActions.setState(VideoState.RELOADED));
		const isVideo = lastVideoMessage.message === 'video';
		if (isVideo) {
			dispatch(VideoActions.setComType(ToolBarSectionType.VIDEO));
			dispatch(ConversationActions.setActiveSection(conversationId, ToolBarSectionType.VIDEO));
		} else {
			dispatch(VideoActions.setComType(ToolBarSectionType.WEB_CALL));
			dispatch(ConversationActions.setActiveSection(conversationId, ToolBarSectionType.WEB_CALL));
		}
	}
};

const disposeConversationVideo = conversationId => (dispatch, getState) => {
	if (VideoLib.isCurrentRoomId(getState(), conversationId)) {
		dispatch(exit());
	}
};

const toggleCamera = () => (dispatch, getState) => {
	const enabled = VideoLib.getCameraEnabled(getState());

	VideoTools.enableCamera(!enabled);
	dispatch(VideoActions.setCameraEnabled(!enabled));
};

const toggleMicrophone = () => (dispatch, getState) => {
	const enabled = VideoLib.getMicrophoneEnabled(getState());

	VideoTools.enableMicrophone(!enabled);
	dispatch(VideoActions.setMicrophoneEnabled(!enabled));
};

const setRoomCredentials = roomId => (dispatch, getState) => {
	const parsedRoomId = VideoLib.getParsedRoomId(getState());
	const turnServerAuthenticationHash = VideoLib.getTurnServerAuthenticationHash(getState(), roomId);

	VideoTools.setCredential({
		'password': turnServerAuthenticationHash,
		'room': roomId
	});

	return new Promise((resolve, reject) => {
		// [TL] - Hmmm, the success- (or error-) callback for VideoTools.sendServerMessage() below never seems to be called!
		// So we can't resolve on that.
		// Is this even needed? (it seems to works without it).
		// What's the purpose of sending a message with "roomCredentials"?
		// Keep it for now, but resolve this function immediately, or we're stuck here forever! :/
		// Update: This call sets room credentials in the easyrtc-server, but it's not used
		// The easyrtc-server has no way of knowing which role the client has, so it CAN NOT let anyone set credentials by a call like this!
		VideoTools.sendServerMessage('roomCredentials', {
			room: parsedRoomId,
			roomKey: turnServerAuthenticationHash
		}, (type, data) => {
			//console.log('sendServerMessage roomCredentials success');
			//resolve();
		}, (code, message) => {
			//console.log('sendServerMessage roomCredentials error');
			//reject(VideoError.SET_ROOM_CREDENTIALS_FAILED);ver
		});
		/*
		VideoTools.sendServerMessage('webrtc signaling server version', {}, (type, data) => {
			console.log('easyrtc version:',type, data);
			//resolve();
		}, (code, message) => {
			//console.log('sendServerMessage roomCredentials error');
			//reject(VideoError.SET_ROOM_CREDENTIALS_FAILED);ver
		});
*/

		resolve();
	});
};

const leaveRoom = () => (dispatch, getState) => {
	const parsedRoomId = VideoLib.getParsedRoomId(getState());

	return new Promise((resolve, reject) => {
		VideoTools.leaveRoom(
			parsedRoomId,
			() => {
				resolve();
			},
			error => {
				reject(VideoError.LEAVE_ROOM_FAILED);
			}
		);
	});
};

const joinRoom = roomId => (dispatch, getState) => {
	const parsedRoomId = VideoLib.getParsedRoomId(getState());

	return new Promise((resolve, reject) => {
		VideoTools.joinRoom(
			parsedRoomId,
			{roomAutoCreateEnable: true},
			() => {
				resolve();
			},
			error => {
				reject(VideoError.JOIN_ROOM_FAILED);
			}
		);
	});
};

const connect = (roomId, visitorVideoElement) => (dispatch, getState) => {
	const appName = VideoTools.getAppName();

	// [TL] The security model for webrtc seems flawed...
	// Below, we connect to the easyrtc-server without setting any credentials.
	// I.e. the easyrtc-server must currently allow connection from anyone.
	//
	// After successful connect, we call setRoomCredentials(), which calls easyrtc.sendServerMessage() with msgType = "roomCredentials" to set credentials for the room on the server.
	// However, currently the easyrtc-server never validates the session, so *ANYONE* can send such a message and set/change credentials for any given room, and later connect to it.
	// If fact the current easyrtc-server also seems to contain a bug that makes *ANY* connection-attempt including credentials for a room to CHANGE the credentials!
	//
	// To fix this, I think we have two options:
	// Alt. 1)
	// Only commsrv is allowed to create rooms and set credentials for them in easyrtc (somehow authenticated)
	// We could implement a new endpoint, enabled only for agents, that "requests" BE to create a room with a new roomId (other than conversationId) and set credentials for it.
	// These credentials (roomId and password) *MUST* be used when connecting to the easyrtc-server (checked in onAuthenticate() in the easyrtc-server)
	// The agents includes these credentials in the "video-start" conversationMessage sent from agent to visitor so that visitor can connect.
	//
	// Alt. 2)
	// All clients are allowed to connect to the easyrtyc-server (no checking in onAuthenticate() in the easyrtc-server)
	// Creating a room and setting credentials for it is only allowed for agent-sessions.
	// So the easyrtc-server must be able to validate the sessions and determine if it's a valid agent-session or not (by calling commsrv)
	// Credentials are checked on "joinRoom" (only clients with valid credentials are allowed in the room)

	return new Promise((resolve, reject) => {
		VideoTools.connect(
			appName,
			() => {
				dispatch(setRoomCredentials(roomId)).then(resolve, reject);
			},
			error => {
				reject(VideoError.CONNECT_FAILED);
			}
		);
	});
};

const initMediaSource = (roomId, agentVideoElement) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		VideoTools.initMediaSource(
			stream => {
				VideoTools.setVideoObjectSrc(
					agentVideoElement,
					VideoTools.getLocalStream()
				);

				resolve(stream);
			},
			error => {
				reject(VideoError.INIT_MEDIA_FAILED);
			}
		);
	});
};

const resumeStream = (agentVideoElement, visitorVideoElement) => async (dispatch, getState) => {
	const roomId = VideoLib.getRoomId(getState());

	try {
		dispatch(VideoActions.setState(VideoState.RESUMING));
		VideoTools.setVideoObjectSrc(
			agentVideoElement,
			VideoTools.getLocalStream()
		);

		const peerId = VideoLib.getPeerId(getState());
		if (VideoTools.isPeerConnected(peerId)) {
			VideoTools.setStreamAcceptor((easyrtcid, stream) => {
				dispatch(initStream(easyrtcid, stream, visitorVideoElement));
			});
			VideoTools.connectStreamToElement(peerId, visitorVideoElement);
			dispatch(VideoActions.setState(VideoState.STREAMING));
		} else {
			dispatch(VideoActions.setState(VideoState.CALLING_PEER));
			await VideoTools.callPeer(peerId);
		}
	} catch (error) {
		dispatch(VideoActions.setError(error, 'resumeStream'));
	}
};

const initWhileCallingPeer = () => (dispatch, getState) => {
};

const initWhileResuming = agentVideoElement => async (dispatch, getState) => {
	try {
		VideoTools.setVideoObjectSrc(
			agentVideoElement,
			VideoTools.getLocalStream()
		);
	} catch (error) {
		dispatch(VideoActions.setError(error, 'initWhileResuming'));
	}
};

const setupStream = (agentVideoElement, visitorVideoElement) => async (dispatch, getState) => {
	const roomId = VideoLib.getRoomId(getState());

	try {
		dispatch(VideoActions.setState(VideoState.INIT_MEDIA));
		await dispatch(initMediaSource(roomId, agentVideoElement));

		if (!VideoLib.isCurrentRoomId(getState(), roomId)) {
			dispatch(VideoActions.setError(VideoError.ROOM_ID_CHANGED, 'setupStream1'));
			return;
		}
		dispatch(VideoActions.setState(VideoState.CONNECTING));
		await dispatch(connect(roomId, visitorVideoElement));

		if (!VideoLib.isCurrentRoomId(getState(), roomId)) {
			dispatch(VideoActions.setError(VideoError.ROOM_ID_CHANGED, 'setupStream2'));
			return;
		}

		dispatch(VideoActions.setState(VideoState.JOIN_ROOM));
		await dispatch(joinRoom(roomId));

		if (!VideoLib.isCurrentRoomId(getState(), roomId)) {
			dispatch(VideoActions.setError(VideoError.ROOM_ID_CHANGED, 'setupStream3'));
			return;
		}
		dispatch(VideoActions.setState(VideoState.CALLING_PEER));

		if (!VideoLib.isVideoStarting(getState(), roomId)) {
			dispatch(sendVideoStartMessage(roomId));
		}

	} catch (error) {
		dispatch(VideoActions.setError(error, 'setupStream4'));
	}
};

const peerConnected = (easyrtcid) => async (dispatch, getState) => {
	dispatch(VideoActions.setPeerId(easyrtcid));

	if (VideoTools.isIdVisitor(easyrtcid) && !VideoTools.isPeerConnected(easyrtcid)) {
		const videoState = VideoLib.getState(getState());
		if (videoState === VideoState.RELOADED) {
			await VideoTools.callPeer(easyrtcid);
		}
	}
};

const peerDisconnected = (easyrtcid, stream, visitorVideoElement) => (dispatch, getState) => {
};

const initStream = (easyrtcid, stream, visitorVideoElement) => dispatch => {
	const role = VideoTools.getRoleFromId(easyrtcid);

	if (VideoTools.isRoleVisitor(role)) {
		VideoTools.clearMediaStream(visitorVideoElement);
		VideoTools.setVideoObjectSrc(visitorVideoElement, stream);
		//VideoTools.peerInfo();

		dispatch(VideoActions.setState(VideoState.STREAMING));
	}
};

const disposeStream = (easyrtcid, stream, visitorVideoElement, getState) => dispatch => {
	if (VideoLib.isPeerId(getState(), easyrtcid)) {
		VideoTools.clearMediaStream(visitorVideoElement);

		dispatch(VideoActions.setError(VideoError.VISITOR_DISCONNECTED, 'disposeStream'));
		dispatch(VideoActions.setState(VideoState.RESUMING));
	}
};

const initListeners = (agentVideoElement, visitorVideoElement) => (dispatch, getState) => {
	VideoTools.setStreamAcceptor((easyrtcid, stream) => {
		dispatch(initStream(easyrtcid, stream, visitorVideoElement));
	});

	// [TL] Skip hooking up "onStreamClosed" below (i.e. don't call disposeStream() when visitor disconnects).
	// disposeStream() was never actually working before either, as it tried to call VideoTools.isPeerId() instead of the correct VideoLib.isPeerId(),
	// throwing an exception (silently), and that effectively aborted the whole logic (agent would see the last video frame from visitor frozen).
	// Now disposeStream() is fixed and no exception is thrown, but now it will set an error and display a "Restart"-button
	// that have to be manually clicked to resume visitor video...
	// Not very good UX, and if visitor is not iframed, we would get lot's of these errors and the agent would have to manually resume after every visitor page navigation.
	// So simply skip it for now...
	// (If we do nothing, the video frame is black while visitor is disconnected and visitor-video will automatically resume when/if visitor re-enters the room)
	/*
		VideoTools.setOnStreamClosed((easyrtcid, stream) => {
			dispatch(disposeStream(easyrtcid, stream, visitorVideoElement, getState));
		});
	*/

	VideoTools.setRoomOccupantListener((roomName, otherPeers, myInfo) => {
		dispatch(VideoActions.setStartTime(myInfo.roomJoinTime));

		Promise.all(Object.keys(otherPeers).map(easyrtcid => {
			return dispatch(peerConnected(easyrtcid));
		})).then(() => {
			// console.log('all peers connected ok');
		}, err => {
			// console.log('peerConnected error:', err);
		});
	});

	VideoTools.setOnError(event => error => {
		dispatch(VideoActions.setError(VideoError.STREAM_ERROR, 'initListeners1'));
	});

	VideoTools.setDisconnectListener(() => {
		const videoState = VideoLib.getState(getState());
		if (videoState === VideoState.STREAMING) {
			// Unexpected disconnection from server (while in state STREAMING)
			// Simply exit video for now (agent will have to manually restart)...
			dispatch(VideoActions.setError(VideoError.DISCONNECTED_FROM_SERVER, 'initListeners2'));
			dispatch(exit());
		}
	});

};

const initIceCandidateFilter = (getState) => dispatch => {
	/*
		Interpret localStorage-flags for debugging webRTC candidate filtering

		"vngage.skipIceCandidateTypes" controls both incoming and outgoing candidates
		"vngage.skipLocalIceCandidateTypes" controls outgoing candidates only
		"vngage.skipRemoteIceCandidateTypes" controls incoming candidates only

		The values should be string with a single type or a comma separated list of types  ("host", "turn"/"relay", "reflex"/"stun"/"srflx")
		Note that "prflx"-candidates (peer reflexive) can't be filtered/skipped by this filer, as these candidates are not "signalled" (https://groups.google.com/forum/#!msg/discuss-webrtc/ZzH3H-QxowQ/z42mhaO7KwAJ)

		Examples:
		vngage.skipIceCandidateTypes = "turn,stun"  	// Skip TURN and STUN candidates, both locals and remotes (and only send/accept HOST candidates)
		vngage.skipIceCandidateTypes = "relay,srflx" 	// Same as above
		vngage.skipIceCandidateTypes = "host, stun"  	// Accept only TURN candidates, both locals and remotes (Note: Remote "prflx" (peer reflexive) may still be used, as filtering them is not possible)

		vngage.skipLocalIceCandidateTypes = "host"		// Send only local STUN and TURN candidates to other peers

		vngage.skipRemoteIceCandidateTypes = "host,turn"	// Accept only remote STUN candidates from other peers (skip "host" and "turn")
	*/

	const skipIceCandidateTypesStr = LocalStorage.getItem('vngage.skipIceCandidateTypes');
	const skipLocalIceCandidateTypesStr = LocalStorage.getItem('vngage.skipLocalIceCandidateTypes');
	const skipRemoteIceCandidateTypesStr = LocalStorage.getItem('vngage.skipRemoteIceCandidateTypes');

	if (!skipIceCandidateTypesStr && !skipLocalIceCandidateTypesStr && !skipRemoteIceCandidateTypesStr) {
		// #nofilter
		return;
	}

	const candidateTypeTransformer = type => {
		type = type.trim().toLowerCase();
		switch (type) {
			case 'turn':
				type = 'relay';
				break;
			case 'stun':
			case 'reflex':
			case 'reflexive':
				type = 'srflx';
				break;
			default:
		}
		return type;
	};

	// Parse each localStorage-value into arrays of candidate types
	let skipIceCandidateTypes = (skipIceCandidateTypesStr ? skipIceCandidateTypesStr.split(',').map(candidateTypeTransformer): []);
	let skipLocalIceCandidateTypes = (skipLocalIceCandidateTypesStr ? skipLocalIceCandidateTypesStr.split(',').map(candidateTypeTransformer): []);
	let skipRemoteIceCandidateTypes = (skipRemoteIceCandidateTypesStr ? skipRemoteIceCandidateTypesStr.split(',').map(candidateTypeTransformer): []);

	// Push the "skipIceCandidateTypes" into the local- and remote ones...
	skipLocalIceCandidateTypes = skipLocalIceCandidateTypes.concat(skipIceCandidateTypes);
	skipRemoteIceCandidateTypes = skipRemoteIceCandidateTypes.concat(skipIceCandidateTypes);

	console.warn('ICE candidate filers are applied. skipLocalIceCandidateTypes:', skipLocalIceCandidateTypes, 'skipRemoteIceCandidateTypes:', skipRemoteIceCandidateTypes);

	const iceCandidateFilter = (iceCandidate, remote) => {
		const candidateFields = iceCandidate.candidate.split(' ');
		const type = candidateFields[candidateFields.findIndex(val => val === 'typ') + 1];
		if (remote) {
			if (skipRemoteIceCandidateTypes.indexOf(type) === -1) {
				return iceCandidate;
			} else {
				// console.log('Skipping '+(remote ? 'remote': 'local') + ' ' + type + ' candidate',iceCandidate);
				return null;
			}
		} else {
			if (skipLocalIceCandidateTypes.indexOf(type) === -1) {
				return iceCandidate;
			} else {
				// console.log('Skipping '+(remote ? 'remote': 'local') + ' ' + type + ' candidate',iceCandidate);
				return null;
			}
		}
	};
	VideoTools.setIceCandidateFilter(iceCandidateFilter);
};

const initWebCall = (agentVideoElement, visitorVideoElement) => async (dispatch, getState) => {
	const microphoneEnabled = VideoLib.getMicrophoneEnabled(getState());
	VideoTools.enableDebug(LocalStorage.getItem('debugEasyrtc') === 'true');
	VideoTools.enableCamera(false);
	VideoTools.enableVideoReceive(false);
	VideoTools.enableVideo(false);
	VideoTools.enableMicrophone(microphoneEnabled);
	dispatch(VideoActions.setComType(ToolBarSectionType.WEB_CALL));
	dispatch(init(agentVideoElement, visitorVideoElement));
};

const initVideo = (agentVideoElement, visitorVideoElement) => async (dispatch, getState) => {
	const cameraEnabled = VideoLib.getCameraEnabled(getState());
	const microphoneEnabled = VideoLib.getMicrophoneEnabled(getState());
	VideoTools.enableDebug(LocalStorage.getItem('debugEasyrtc') === 'true');
	VideoTools.enableCamera(cameraEnabled);
	VideoTools.enableVideoReceive(true);
	VideoTools.enableVideo(true);
	VideoTools.enableMicrophone(microphoneEnabled);
	dispatch(VideoActions.setComType(ToolBarSectionType.VIDEO));
	dispatch(init(agentVideoElement, visitorVideoElement));
};

const init = (agentVideoElement, visitorVideoElement) => async (dispatch, getState) => {
	const videoState = VideoLib.getState(getState());
	const error = VideoLib.getError(getState());
	const videoStreamingServerUrl = VideoTools.getVideoStreamingServerUrl();

	//VideoTools.enableDebug(true);

	if (error) {
		dispatch(VideoActions.setError(error, 'init1'));
	}

	try {
		switch (videoState) {
			case VideoState.STREAMING:
				dispatch(resumeStream(agentVideoElement, visitorVideoElement));
				break;

			case VideoState.RESUMING:
				dispatch(initWhileResuming(agentVideoElement));
				break;

			case VideoState.CALLING_PEER:
				dispatch(initWhileCallingPeer());
				break;

			case VideoState.LEAVING:
				break;

			default:
				VideoTools.setSocketUrl(videoStreamingServerUrl, VideoTools.getSocketConfig());
				VideoTools.setUserName('agent');

				// Setup IceCandidate filter (currently only used for debugging)
				dispatch(initIceCandidateFilter(getState));

				dispatch(initListeners(agentVideoElement, visitorVideoElement, getState));
				await dispatch(setupStream(agentVideoElement, visitorVideoElement));
		}
	} catch (error) {
		dispatch(VideoActions.setError(error, 'init2'));
	}
};

const sendVideoStartMessage = roomId => (dispatch, getState) => {
	dispatch(Message.sendVideoMessage(roomId, VideoLib.getComType(getState()), VideoActivity.START));
};

const sendVideoStopMessage = roomId => (dispatch, getState) => {
	dispatch(Message.sendVideoMessage(roomId, VideoLib.getComType(getState()), VideoActivity.STOP));
};

const sendVideoNote = (conversationId, event) => (dispatch, getState) => {
	const comType = VideoLib.getComType(getState());
	const locKey = {
		[ToolBarSectionType.VIDEO]: {
			start: 'videoStarted',
			stop: 'videoStopped',
		},
		[ToolBarSectionType.WEB_CALL]: {
			start: 'webCallStarted',
			stop: 'webCallStopped',
		},
		'': {
			start: 'videoMalfunction',
			stop: 'videoMalfunction',
		}
	}[comType][event];

	dispatch(Localized.sendLocalizedNote(conversationId, locKey));
};

const sendVideoStartedNote = conversationId => (dispatch, getState) => {
	dispatch(sendVideoNote(conversationId, 'start'));
};

const sendVideoStoppedNote = conversationId => (dispatch, getState) => {
	dispatch(sendVideoNote(conversationId, 'stop'));
};

const showVideoSection = () => (dispatch, getState) => {
	const roomId = VideoLib.getRoomId(getState());
	if (roomId) {
		dispatch(ConversationActions.setActiveSection(
			roomId,
			ToolBarSectionType.VIDEO
		));
	}
};

const hideVideoSection = () => (dispatch, getState) => {
	const roomId = VideoLib.getRoomId(getState());
	if (roomId) {
		dispatch(ConversationActions.removeActiveSection(
			roomId,
			ToolBarSectionType.VIDEO
		));
	}
};

const hideWebCallSection = () => (dispatch, getState) => {
	const roomId = VideoLib.getRoomId(getState());
	if (roomId) {
		dispatch(ConversationActions.removeActiveSection(
			roomId,
			ToolBarSectionType.WEB_CALL
		));
	}
};

const showInChatPanel = () => dispatch => {
	dispatch(NavigationActions.hideModal());
	dispatch(showVideoSection());
};

const showVideoModal = () => dispatch => {
	dispatch(hideVideoSection());
	dispatch(NavigationActions.showModal(ModalType.VIDEO_MODAL));
};

const showVideoFullscreen = () => dispatch => {
	dispatch(hideVideoSection());
	dispatch(NavigationActions.showModal(ModalType.VIDEO_FULLSCREEN));
};

const disposeVideoElements = (visitorElement, agentElement) => dispatch => {
	VideoTools.setVideoObjectSrc(visitorElement, '');
	VideoTools.setVideoObjectSrc(agentElement, '');
};

const start = (conversationId, comType) => (dispatch, getState) => {
	dispatch(VideoActions.setRoomId(conversationId));
	dispatch(VideoActions.setComType(comType));
	dispatch(sendVideoStartedNote(conversationId));
};

const exit = () => async (dispatch, getState) => {
	const roomId = VideoLib.getRoomId(getState());

	dispatch(sendVideoStoppedNote(roomId));
	dispatch(VideoActions.setState(VideoState.LEAVING));

	VideoTools.setOnError(null);
	VideoTools.setRoomOccupantListener(null);
	VideoTools.setStreamAcceptor(null);
	VideoTools.setOnStreamClosed(null);

	if (VideoTools.isInRoom(roomId)) {
		try {
			await dispatch(leaveRoom(roomId));
		} catch (error) {
			dispatch(VideoActions.setError(error, 'leaveRoom'));
		}
	}

	VideoTools.closeLocalStream();
	VideoTools.disconnect();

	dispatch(sendVideoStopMessage(roomId));

	const modal = getState().get('modal');
	if (modal === ModalType.VIDEO_MODAL || modal === ModalType.VIDEO_FULLSCREEN) {
		dispatch(NavigationActions.hideModal());
	}

	const isConversation = VideoLib.isConversation(getState(), roomId);
	if (isConversation && VideoLib.isActiveSectionVideo(getState(), roomId)) {
		const comType = VideoLib.getComType(getState());
		if (comType === ToolBarSectionType.VIDEO) {
			dispatch(hideVideoSection());
		} else {
			dispatch(hideWebCallSection());
		}
	}

	dispatch(VideoActions.dispose());
};

export default {
	sendVideoStoppedNote,
	sendVideoStartedNote,
	resumeConversationVideo,
	disposeConversationVideo,
	toggleCamera,
	toggleMicrophone,
	leaveRoom,
	joinRoom,
	connect,
	initMediaSource,
	initWebCall,
	initVideo,
	init,
	setupStream,
	initWhileResuming,
	resumeStream,
	disposeVideoElements,
	showVideoSection,
	hideVideoSection,
	hideWebCallSection,
	showInChatPanel,
	showVideoModal,
	showVideoFullscreen,
	start,
	exit
};

