export default function ($resource, NotificationService, $q, APIStatusCodeService) {
	'ngInject';

    return {
        post_arrayResult: {
            method: 'POST',
            isArray: true,
            interceptor: {
                response: function(config) {
                    return config.resource;
                }
            }
        },
        post: {
            method: 'POST',
            interceptor: {
                response: function(config) {
                    return config.resource;
                }
            }
        },
        query: {
            method: 'GET',
            isArray: true,
            interceptor: {
                response: function(config) {
					return config.resource;
                }
            }
        },
        tryGet: {
            method: 'GET',
            muteNotification: true,
            isArray: false,
            interceptor: {
                response: function(config) {
                    return config.resource;
                }
            }
        },
        remove: {
            method: 'DELETE',
            interceptor: {
                response: function (res) {
                    NotificationService.success({ header: 'Success', body: 'Successfully deleted' });
                    return res;
                },
                responseError: function (res) {
                    APIStatusCodeService.handle(res);
                    return $q.reject(res);
                }
            }
        },
        create: {
            method: 'POST',
            interceptor: {
                response: function(res) {
                    NotificationService.success({ header: 'Success', body: 'Successfully created' });
                    return res.resource;
                },
                responseError: function(res) {
                    APIStatusCodeService.handle(res);
                    return $q.reject(res);
                }
            }
        },
        save: {
            method: 'POST',
            params: {
                revision: '@revision'
            },
            interceptor: {
                response: function(res) {
                    NotificationService.success({ header: 'Success', body: 'Successfully saved' });
                    return res.resource;
                },
                responseError: function(res) {
                    APIStatusCodeService.handle(res);
                    return $q.reject(res);
                }
            },
            transformRequest: function(data) {
                // Vergic API nests all data one level down, send data on that level
                return angular.toJson(data.data);
            }
        },
        silentSave: {
            method: 'POST',
            muteNotification: true,
            params: {
                revision: '@revision'
            },
            interceptor: {
                responseError: function(res) {
                    APIStatusCodeService.handle(res);
                    return $q.reject(res);
                }
            },
            transformRequest: function(data) {
                // Vergic API nests all data one level down, send data on that level
                return angular.toJson(data.data);
            }
        },
        silentCreate: {
            method: 'POST',
            muteNotification: true,
            interceptor: {
                responseError: function(res) {
                    APIStatusCodeService.handle(res);
                    return $q.reject(res);
                }
            }
        }
    };
};
