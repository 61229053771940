export default function () {
    'ngInject';

    return {
        restrict: 'A',
        scope: {
          spin: '='
        },
        link: function (scope, element) {

            element.addClass('spinner hide');

            scope.$watch('spin', function(newValue) {
                if (newValue) {
                    element.removeClass('hide');
                    element.addClass('show');
                } else {
                    element.removeClass('show');
                    element.addClass('hide');
                }
            });
        }
    };
};
