import _find from 'lodash/find';
import _uniq from 'lodash/uniq';
import _pull from 'lodash/pull';
import moment from 'moment-timezone';

export default function(SessionService, APICaseService, APIEndpointService, $q, APIConfigurationSectionService, VngageUtil) {
	'ngInject';

	var service = {
		queryCases: queryCase,
		// formatCase: formatCase,
		// getParticipants: getParticipants
	};

	var lists = {
		siteList: [],
		siteLookup: {},
		userList: [],
		userLookup: {},
		groupList: [],
		groupLookup : {},
		caseTypeList :[],
		caseTypeLookup :{},
		caseList: []
	};
	function populateUserAndGroupLookupLists(){
		lists.siteList.forEach(function(s){
			lists.siteLookup[s.data.id] = s.data;
		});
		lists.userList.forEach(function(u){
			lists.userLookup[u.data.id] = u.data;
		});
		lists.groupList.forEach(function(g){
			lists.groupLookup[g.data.id] = g.data;
		});
		lists.caseTypeList.forEach(function(g){
			lists.caseTypeLookup[g.data.id] = g.data;
		});
	}
	function generateCaseList(data){
		var outcomeMap = [
			'Positive',
			'Neutral',
			'Negative'
		];
		//console.log('[ FL ] lists: ',lists);
		lists.caseList = [];
		data.forEach(function(c){
			var listItem = c.data;
			//console.log(listItem);
			// todo make a better lookup check
			try {
				listItem.groupName = lists.groupLookup[listItem.ownedBy.groupId.id].name;
			} catch (err) {
				listItem.groupName = '--';
				/*console.error(err,listItem)*/
			}
			try {
				listItem.userName = lists.userLookup[listItem.ownedBy.userId].profile.displayName;
			} catch (err) {
				listItem.userName = '--';
				/*console.error(err,listItem)*/
			}
			try {
				listItem.caseType = lists.caseTypeLookup[listItem.type.id].name;
			} catch (err) {
				listItem.caseType = '--';
				/*console.error(err,listItem)*/
			}
			try {
				listItem.siteName = lists.siteLookup[lists.groupLookup[listItem.ownedBy.groupId.id].siteId].name;
			} catch (err) {
				listItem.siteName = '--';
				/*console.error(err,listItem)*/
			}
			try {
				listItem.bucket = listItem.createdAt;
			} catch (err) {
				console.error(err,listItem);
			}
			try {
				listItem.outcome = outcomeMap[listItem.closure.outcome];
			} catch (err) {
				listItem.outcome = '--';
				/*console.error(err,listItem)*/
			}
			try {
				listItem.reason = listItem.closure.name;
			} catch (err) {
				listItem.reason = '--';
				/*console.error(err,listItem)*/
			}
			var start = moment(listItem.bucket);
			var end = moment(listItem.closedAt);

			listItem.title = listItem.caseType || 'Unknown';
			listItem.start = start;
			listItem.end = checkForMinimumDuration(start,end);
			lists.caseList.push(listItem);
		});
		console.log('[ FL ] caseListItem: ',lists.caseList[0]);
		return (lists.caseList);
	}
	function checkForMinimumDuration(start,end){
		var minDur = 20; //min

		var newEnd = moment(start.format()).add(minDur, 'minute');
		//console.log('[ FL ] newEnd',start.diff(newEnd));
		//console.log('[ FL ] end',start.diff(end));
		if(start.diff(end) > -1199770){
			end = newEnd;
		}
		return end;
	}
	function queryCase(params) {
		//console.log('[ FL ] queryCase - params: ',params);
		var caseParams = {
			caseState: 2,
			maxDate: params.range.to,
			maxTime: null,
			minDate: params.range.from,
			minTime: null,
			ownedByUserId: null,
			skip: 0,
			sortBy: 'createdAt',
			sortOrder: 'desc'
		};

		return $q(function(resolve, reject) {
			if (lists.userList.length === 0) {
				$q.all([
					APIEndpointService.site.query().$promise,
					APIEndpointService.user.query({limit: 10000}).$promise,
					APIEndpointService.group.query().$promise,
					APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.customerCase),
					APICaseService.getCases(caseParams)
				]).then(function(res) {
					lists.siteList = res[0];
					lists.userList = res[1];
					lists.groupList = res[2];
					lists.caseTypeList = res[3];
					populateUserAndGroupLookupLists();
					resolve(generateCaseList(res[4].data));
				}, function(err) {
					reject(err);
				});
			} else {
				APICaseService.getCases(caseParams).then(function(res) {
					resolve(generateCaseList(res.data));
				}, function(err) {
					reject(err);
				});
			}
		});
	}

/*	function formatCase(caseItem) {
		var stateMap = [
			'New',
			'Ongoing',
			'Closed'
		];
		var outcomeMap = [
			'Positive',
			'Neutral',
			'Negative'
		];

		// Parse date from utc to user's time zone
		var openedTimeAdjusted = moment.utc(caseItem.data.createdAt).tz(SessionService.getUserTimezone()), //.utcOffset(SessionService.getUserTimezoneOffset()),
			closedTimeAdjusted = moment.utc(caseItem.data.closedAt).tz(SessionService.getUserTimezone()); //.utcOffset(SessionService.getUserTimezoneOffset());

		return {
			opened:             openedTimeAdjusted.format('L LT'),
			openedTime:         openedTimeAdjusted.format('LT'),
			closedTime:         caseItem.data.closedAt ? closedTimeAdjusted.format('LT') : '-',
			openedDate:         openedTimeAdjusted.format('L'),
			state:              stateMap[caseItem.data.state],
			outcome:            caseItem.data.closure ? outcomeMap[caseItem.data.closure.outcome] : '-',
			currentOwnerId:     caseItem.data.ownedBy.userId || '-',
			currentOwnerName:   VngageUtil.getItemName('user', lists.userList, caseItem.data.ownedBy.userId),
			caseId:             caseItem.data.id,
			group:              VngageUtil.getItemName('group', lists.groupList, caseItem.data.groupId),
			closure:            caseItem.data.closure ? caseItem.data.closure.name : '-',
			caseType:           VngageUtil.getItemName('case', lists.caseTypeList, (caseItem.data.type ? caseItem.data.type.id : '-')),

			originalCaseItem: caseItem
		};
	}*/
/*	function getParticipants(users, caseItem) {
		//console.log('getParticipants',users);
		var participantsRaw = [];
		var conversations = caseItem.data.conversations;
		var visitorId;
		conversations.forEach(function(conversation) {

			//occasionally we get null instead an array of messages
			_pull(conversation.messages, null);

			conversation.messages.forEach(function(message) {
				message.recipients.forEach(function(recipient) {
					participantsRaw.push(recipient.userId);
				});
				if (message.speaker.visitId) {
					visitorId = message.speaker.visitId;
				} else if (message.speaker.userId) {
					participantsRaw.push(message.speaker.userId);
				}
			});
		});
		var participantsIds = _uniq(participantsRaw);
		var participantsParsed = [];
		participantsIds.forEach(function(participantId) {
			var user = _find(users, { data: { id: participantId }});
			var userName = user ? user.data.profile.displayName : '';
			if (participantId) {
				participantsParsed.push({
					userId: participantId,
					userName: userName
				});
			}
		});
		if (visitorId) {
			participantsParsed.push({
				userId: visitorId,
				userName: 'Visitor',
				userType: 'visitor'
			});
		}

		return participantsParsed;
	}*/
	return service;
};

