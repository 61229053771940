import React from 'react';
import createReactClass from 'create-react-class';
import SpeakerTitle from './SpeakerTitle.react';
import FlipSwitch from './FlipSwitch.react.js';
import { injectIntl } from 'react-intl';
import {DropdownButton, Dropdown} from 'react-bootstrap';

export default injectIntl(createReactClass({

	onContextMenu(eventKey) {
		const id = this.props.participantId;
		switch (eventKey){

			case 'kick' :
				this.props.removeParticipant(id);
				break;

			case 'coBrowsing' :
				this.props.changeAuthorization(id, { key: 'coBrowsing', value: !this.props.abilityCoBrowsing });
				break;

			case 'video' :
				this.props.changeAuthorization(id, { key: 'video', value: !this.props.abilityVideo });
				break;

		}
	},

	render () {
		let status = this.props.participationState === 'joined' ? this.props.connectionState: 'terminate',
			cls = 'participant-status vngage-icon-torso status-' + status,
			item =
				<span>
					<i className={cls}></i>
					<SpeakerTitle name={this.props.name} title={this.props.titleId} isMe={this.props.isCurrentUser}/>
				</span>
			;

		if (this.props.showAuthMenu && this.props.titleId === 'guest') {
			return (
				<DropdownButton className="participant" bsSize="xsmall" pullRight title={item}>
					<Dropdown.Item header>{this.props.name}</Dropdown.Item>

					<FlipSwitch
						displayedInList={true}
						label={this.props.intl.formatMessage({id:'actionFeatureCoBrowsing'})}
						isChecked={this.props.abilityCoBrowsing}
						onChangeHandler={this.onContextMenu}
						action='coBrowsing' />

					<FlipSwitch
						displayedInList={true}
						label={this.props.intl.formatMessage({id:'actionFeatureVideo'})}
						isChecked={!!this.props.abilityVideo}
						onChangeHandler={this.onContextMenu}
						action='video' />

					<Dropdown.Divider/>
					<Dropdown.Item onSelect={this.onContextMenu.bind(this, 'kick')}>{this.props.intl.formatMessage({id:'actionRemoveParticipant'})}</Dropdown.Item>
				</DropdownButton>
			);
		} else {
			return (
				<span className="participant">{item}</span>
			);
		}
	}
}));
