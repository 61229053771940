import _values from 'lodash/values';
import _each from 'lodash/each';

import PointOfInterestAddModalTemplate from './PointOfInterestAddModalTemplate';

export default function ($state, $uibModal, pointOfInterestList, pointOfInterestTypes) {
	'ngInject';

	var vm = this;
	vm.pointOfInterestList = pointOfInterestList;

	vm.tableColumns = [{
		header: 'pointOfInterest.NAME',
		property: 'data.name',
		sortDefault: true,
		useInFilter: true
	}, {
		header: 'GUID',
		property: 'data.id',
		hidden: true,
		useInFilter: true
	}, {
		header: 'pointOfInterest.TYPE',
		property: 'data.key',
		width: '35%',
		transform: function (val) {
			return pointOfInterestTypes[val] || val;
		},
		translate: true
	}];
	vm.tableOptions = {
		stateLink: {
			state: 'root.analyze.pointOfInterest.edit',
			property: 'data.id'
		},
		createItem: {
			langKey: 'pointOfInterest.ADD_HEADER',
			method: addNew
		}
	};

	function openAddModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: PointOfInterestAddModalTemplate,
			controller: 'PointOfInterestAddModalController as vm',
			resolve: {
				pointOfInterestTypeArray: ($translate) => {
					'ngInject';
					var translationKeys = _values(pointOfInterestTypes);
					return $translate(translationKeys).then(function (translations) {
						var poiTypes = [];
						_each(pointOfInterestTypes, function (langKey, key) {
							poiTypes.push({key: key, displayName: translations[langKey]});
						});
						return poiTypes;
					});
				}
			}
		});
		modal.result.then(newPointOfInterest => {
			pointOfInterestList.push(newPointOfInterest);
			$state.go('.edit', {id: newPointOfInterest.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		$state.go('root.analyze.pointOfInterest').then(openAddModal);
	}
};
