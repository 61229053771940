import React from 'react';
import ToolbarButton from './ToolbarButton.react';
import ActionsPanel from './ActionsPanel.react';
import ExtrasMenu from './ExtrasMenu.react';
import {useGetTranslation} from './../../LangContext';


const Toolbar = (props) => {
	const getTranslation = useGetTranslation();
	const {enabledFeatures} = props;

	const clsPause = `vngage-icon-pause toolbar-item ${props.paused ? 'active': ''}`;
	const clsScale = `${props.isScaled ? 'vngage-icon-line-zoom-in toolbar-item': 'vngage-icon-line-zoom-out toolbar-item active'}`;
	const clsHighlight = `vngage-icon-highlight toolbar-item ${props.highlightMode ? 'active': ''} ${props.paused ? 'disabled': ''}`;
	const clsDomUpload = `vngage-icon-line-download toolbar-item ${props.paused ? 'disabled': ''}`;

	const ubTracking = {
		pause: `pause-${props.paused ? 'off': 'on'}`,
		scale: `scale-${props.isScaled ? 'on': 'off'}`,
		highlight: `highlight-${props.highlightMode ? 'off': 'on'}`,
		domUpload: 'dom-upload',
		sendPage: 'send-page',
	};

	return (
		<div className="co-toolbar">
			{enabledFeatures.pause &&
			<ToolbarButton
				cls={clsPause}
				tooltipText={getTranslation('coFuncPause')}
				onClickAction={props.toggleCoBroPause}
				ubTrack={'cobrowsing:' + ubTracking.pause}
			/>
			}
			{enabledFeatures.scale &&
			<ToolbarButton
				cls={clsScale}
				tooltipText={getTranslation('coFuncScale')}
				onClickAction={props.toggleCoBroScale}
				ubTrack={'cobrowsing:' + ubTracking.scale}
			/>
			}

			{enabledFeatures.highlight &&
			<ToolbarButton
				cls={clsHighlight}
				tooltipText={getTranslation('coFuncHighlight')}
				onClickAction={() => props.setCoBroHighlightMode(!props.highlightMode)}
				ubTrack={'cobrowsing:' + ubTracking.highlight}
			/>
			}

			{enabledFeatures.domUpload &&
			<ToolbarButton
				cls={clsDomUpload}
				tooltipText={getTranslation('coFuncGetDOM')}
				onClickAction={props.sendDomUploadRequest}
				ubTrack={'cobrowsing:' + ubTracking.domUpload}
			/>
			}

			{enabledFeatures.extrasMenu &&
			<ExtrasMenu {...props} ubTracking={ubTracking}/>
			}
			{enabledFeatures.actionsMenu &&
			<ActionsPanel {...props}/>
			}

			{enabledFeatures.exit &&
			<ToolbarButton
				cls="vngage-icon-line-restore-screen toolbar-item"
				tooltipText={getTranslation('actionExitCoBrowse')}
				onClickAction={() => props.toggleCoBro(props.conversationId)}
				ubTrack="cobrowsing:exit"
			/>
			}
		</div>
	);
};
export default Toolbar;

