import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function ($q, $sce, $scope, $state, $parse, theme, vngageTheme, VngageUtil, vngageConfig, vngageRemoveModalService, NotificationService, $window, $stateParams, panelSettings) {
	'ngInject';

	const vm = this;
	vm.isSaving = false;
	vm.reset = reset;
	vm.preview = preview;
	vm.updatePreviewConf = updatePreviewConf;
	vm.save = save;
	vm.remove = remove;
	vm.applyBackgroundSite = applyBackgroundSite;
	vm.theme = theme;
	vm.setView = setView;
	vm.css;
	vm.prevBgSiteInput = '';
	vm.currentList = $stateParams.currentList || [];
	vm.style = {
		activeClass: 'desktop',
		docked: true,
		get isDesktop() {
			return this.activeClass === 'desktop';
		},
		get isMobile() {
			return this.activeClass === 'mobile';
		},
		get isInApp() {
			return this.activeClass === 'in-app';
		},
		get isDocked() {
			return this.docked;
		},
		desktop() {
			this.activeClass = 'desktop';
			vm.updatePreviewConf();
		},
		mobile() {
			this.activeClass = 'mobile';
			vm.updatePreviewConf();
		},
		inApp() {
			this.activeClass = 'in-app';
			vm.updatePreviewConf();
		},
		get iframeWidth() {
			if (this.isDesktop) {
				return 1100;
			}

			if (this.isMobile) {
				return 320;
			}

			if (this.isInApp) {
				return 320;
			}

			return 1100;
		}
	};
	vm.activeThemeView = 'queue';
	vm.themePreviewUrl = $sce.trustAsResourceUrl(vngageConfig.staticWorkspaceUrl + 'visitor/ui/views/queue.html');

	$scope.$on('resetResettables', () => {
		vm.themeEditForm.$setPristine();
		theme.$get().catch(() => {
		});
	});

	initTheme();
	stickyLogic();

	function showThemeError(header, e) {
		let errorMessage = 'Unknown error';
		if (typeof e === 'string') {
			errorMessage = e;
		} else if (typeof e === 'object') {
			if (typeof e.message === 'string') {
				errorMessage = e.message;
			} else if (typeof e.error === 'object' && typeof e.error.message === 'string') {
				errorMessage = 'LessError: ' + e.error.message;
			}
		}
		NotificationService.error({header, body: errorMessage});
	}

	function initTheme() {
		const section = theme.data.section;
		const config = {
			themes: [
				{
					name: 'themeDark', displayName: 'Dark theme', path: 'themeDark', defaultTheme: true
				},
				{
					name: 'themeLight', displayName: 'Light theme', path: 'themeLight'
				}
			],
			variables: {
				panelHasBoxShadow: true,
				hasGradient: true,
				btnHasIcons: true,
				colorPanelBackground: '',
				panelBgColor: '',
				headerBgColor: '',
				colorAccent: '',
				btnBgColor: '',
				btnNegativeBgColor: '',
				btnPositiveBgColor: '',
				fontFamily: '',
				panelBorderRadius: '',
				btnBorderRadius: '',

				focusIndicatorWidth: '',
				focusIndicatorColor: '',
				focusIndicatorStyle: ''
			}
		};

		vm.originalName = vm.theme.data.name;

		vm.themeMethods = vngageTheme(section.less, section.baseTheme, config);
		vm.themeConfig = vm.themeMethods.config();

		preview();
	}

	function applyBackgroundSite() {
		vm.prevBgSiteTrusted = $sce.trustAsResourceUrl(vm.prevBgSiteInput);
	}

	function getConfig() {
		return {
			css: vm.css,
			docked: vm.style.isDocked,
			mobile: vm.style.isMobile,
			inApp: vm.style.isInApp,
			panelProps: {
				poweredByURL: panelSettings.poweredByURL,
				poweredByLabel: (typeof panelSettings.poweredByLabel === 'string' ? panelSettings.poweredByLabel : 'POWERED BY PUZZEL')	// Allow empty string, default to same value as previously hardcoded in doT-file "draggablePanel.dot"
			}
		};
	}

	function preview(throwOnError) {
		if (vm.themeEditForm && vm.themeEditForm.$invalid) {
			return $q(function (resolve, reject) {
				reject();
			});
		}
		return vm.themeMethods.preview().then(function (css) {
			vm.css = css;
			const config = getConfig();
			$scope.$emit('renderTheme', config);
		}, function (e) {
			console.log('Theme preview error:', e);
			if (throwOnError === true) {
				throw e;
			} else {
				showThemeError('Theme preview error', e);
			}
		});
	}

	function updatePreviewConf() {
		const config = getConfig();
		$scope.$emit('updatePreview', config);
	}

	function save() {
		vm.isSaving = true;

		const promises = {
			preview: preview(true), // Call with throwOnError=true to make preview pass errors on...
			less: vm.themeMethods.source()
		};

		const validator = $parse('themeEditForm.customStyle.$asyncValidators.validateLess')(vm);

		if (validator) {
			promises.custom = validator();
		}

		$q.all(promises).then(function (results) {
			vm.theme.data.section = {
				less: results.less,
				baseTheme: vm.themeConfig.selectedTheme.name
			};
			return vm.theme.$save().then(function (response) {
				vm.originalName = response.data.name;
				vm.themeEditForm.$setPristine();
			});

		}).catch(function (e) {
			// Handle all kids of errors from less preview and validate! (DON'T save if invalid!)
			vm.themeEditForm.$setDirty();
			showThemeError('Error saving theme', e);
		}).finally(function () {
			vm.isSaving = false;
		});
	}

	function reset() {
		vm.theme.data.name = vm.originalName;
		initTheme();
		vm.themeEditForm.$setPristine();
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: theme,
			itemType: 'theme'
		}).then(function () {
			vm.themeEditForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(theme, 'root.account.theme.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(function () {
			vm.isSaving = false;
		});
	}

	function setView(view) {
		vm.themePreviewUrl = $sce.trustAsResourceUrl(vngageConfig.staticWorkspaceUrl + 'visitor/ui/views/' + view + '.html');
		vm.activeThemeView = view;
		updatePreviewConf();
	}

	function stickyLogic() {
		const stickyPreview            = document.querySelector('.sticky-preview'),
			  stickyPreviewClass       = ' position-fixed-preview',
			  stickyPreviewOriginClass = stickyPreview.className,

			  stickyElement            = document.querySelector('.sticky-preview-area'),
			  stickyClass              = ' position-fixed-area',
			  stickyElementOrginClass  = stickyElement.className,

			  main                     = document.getElementById('main-container');

		const onScroll = function () {
			const textArea = document.querySelector('.CodeMirror.cm-s-default.CodeMirror-wrap');
			if (stickyElement && textArea.offsetHeight > 180) {
				const scrollTop     = main.scrollTop,
					  fromElement   = document.querySelector('.CodeMirror.cm-s-default.CodeMirror-wrap'),
					  elementOffset = fromElement.offsetTop,
					  distance      = elementOffset - scrollTop;

				stickyPreview.className = stickyPreviewOriginClass + (distance < -70 ? stickyPreviewClass: '');
				stickyElement.className = stickyElementOrginClass + (distance < -70 ? stickyClass: '');
				responsive(stickyElement, $window);
			}
		};
		const onResize = function () {
			if (stickyElement) {
				responsive(stickyElement, $window);
			}
		};

		angular.element(main).bind('scroll', onScroll);
		angular.element($window).bind('resize', onResize);

		$scope.$on('$destroy', function handler() {
			angular.element(main).unbind('scroll', onScroll);
			angular.element($window).unbind('resize', onResize);
		});
	}

	function responsive(stickyElement, $window) {
		const fromElement = document.querySelector('.editor-control-panel').parentElement,
			  elStyle     = fromElement.currentStyle || $window.getComputedStyle(fromElement),
			  padding     = parseInt(elStyle.paddingLeft.substring(0, 2)) + parseInt(elStyle.paddingRight.substring(0, 2));
		stickyElement.style.width = (fromElement.offsetWidth - padding) + 'px';
	}
}
