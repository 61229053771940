export default function(group) {
    'ngInject';

    var vm = this;
    vm.group = group;
    vm.submit = submit;

    function submit() {
        vm.isSaving = true;
        vm.group.$save().finally(function() {
            vm.isSaving = false;
        });
    }
};
