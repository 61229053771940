import LocalStorage from '../stores/LocalStorageWrapper';

function setFocus(conversationId, panelPosition) {
	setTimeout(() => {
		let elem;
		if (panelPosition === 'collapsed') {
			// if collapsed focus on header
			elem = document.getElementById('header' + conversationId);
		} else {
			elem = document.getElementById('input' + conversationId);
		}
		if (elem) {
			elem.focus()
		}
	}, 20);
}

function notifyWorkspaceValueUpdated(key, value) {
	LocalStorage.setItem(key, value);
	window.dispatchEvent(new CustomEvent('vergicWorkspaceEvent', {
		detail: {
			key,
			value
		}
	}));
}

const Helpers = {
	setFocus,
	notifyWorkspaceValueUpdated,
};

export default Helpers;

