import AppConstants from '../../../components/constants/AppConstants';
const Fakes = AppConstants.Fakes;

export default {
	newStateDebug (newState) {
		return {
			type: Fakes.NEW_STATE_DEBUG,
			newState
		};
	},

	addFakePanel(conversationId, conversationState){
		return {
			type: Fakes.ADD_FAKE_PANEL,
			conversationId,
			conversationState
		};
	},

	addFakeConversation(){
		return {
			type: Fakes.ADD_FAKE_CONVERSATION
		};
	},

	removeAllFakePanels(){
		return {
			type: Fakes.REMOVE_ALL_FAKE_PANELS
		};
	},

	addFakeMessage(){
		return {
			type: Fakes.ADD_FAKE_MESSAGE
		};
	},

	addFakeSystemMessage(){
		return {
			type: Fakes.ADD_FAKE_SYSTEM_MESSAGE
		};
	},

	removeFakePanel(conversationId){
		return {
			type: Fakes.REMOVE_FAKE_PANEL,
			conversationId
		};
	},

	toggleFakeTyping(){
		return {
			type: Fakes.TOGGLE_FAKE_TYPING
		};
	},

	addFakeVisitorProfile(conversationId, whatToShow){
		return {
			type: Fakes.ADD_FAKE_VISITOR_PROFILE,
			conversationId,
			whatToShow
		};
	},

	addFakeVisitor(){
		return {
			type: Fakes.ADD_FAKE_VISITOR,
		};
	},

	addFakeReservation(){
		return {
			type: Fakes.ADD_FAKE_RESERVATION
		};
	},

	setFakeConversationStatus(conversationId, status) {
		return {
			type: Fakes.SET_FAKE_CONVERSATION_STATUS,
			conversationId,
			status
		}
	}

}
