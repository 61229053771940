export default function ($uibModalInstance, APIEndpointService, user) {
	'ngInject';

	const vm = this;
	vm.user = user;

	vm.logout = logout;
	vm.cancel = $uibModalInstance.dismiss;

	function logout() {
		const save = APIEndpointService.logout.get({ key: vm.user.userId }).$promise;
		$uibModalInstance.close(save);
	}
};

