import _find from 'lodash/find';
import React from 'react';
import createReactClass from 'create-react-class';
import vngageVisitor from '../visitor/vngageVisitor';
import ViewActions from '../actions/ViewActions';
import { injectIntl } from 'react-intl';

var Claim = createReactClass({

	onClaimChange(event) {
		this.props.onClaimChange(this.props.claim, event.target.value);
	},

	render () {
		return (
			<div>
				<label htmlFor={this.props.claim.type}>{this.props.claim.label}</label>
				<input
					key={this.props.claim.type}
					disabled={this.props.claim.locked}
					type={this.props.claim.input}
					name={this.props.claim.type}
					id={this.props.claim.type}
					onChange={this.onClaimChange}
					value={this.props.value}
				/>
			</div>
		);
	}
});

export default injectIntl(createReactClass({

	render () {
		if (!this.props.claims) {
			return false;
		}
		let browserName, browserVersion, device, os, icon;
		if (this.props.userAgent) {
			browserName = this.props.userAgent.browser.name;
			browserVersion = parseInt(this.props.userAgent.browser.version,10);
			device = this.props.userAgent.os;
		}
		let buttonWrapStyle = { margin: '10px 0', overflow: 'hidden' };

		if (device && device.osName) {
			os = device.osName;
		}
		if (device && device.phone) {
			icon = <i className="vngage-icon-mobile"></i>;
		} else if (device && device.tablet) {
			icon = <i className="vngage-icon-tablet"></i>;
		} else {
			icon = <i className="vngage-icon-desktop"></i>;
		}

		return (
			<div className="card card-bg rounded">
				<p>{icon} {(device && device.deviceName) ? device.deviceName : ''} {os} {browserName} {browserVersion}</p>
				<form className="form-horizontal" name="visitorProfile" onSubmit={this.props.onSave}>

					{this.props.claims.map( claim => {
						return (
							<Claim key={claim.type} claim={claim} type={claim.type} value={claim.values} onClaimChange={this.props.onUpdate} />
						)
					})}

					<div style={buttonWrapStyle}>
						<div className="pull-right">
							<div>
								<button type="submit" className="btn btn-xs btn-success">
									{this.props.intl.formatMessage({id:'btnSend'})}
								</button>
								<button type="button" className="btn btn-xs btn-default" onClick={this.props.onReset}>
									{this.props.intl.formatMessage({id:'btnReset'})}
								</button>
							</div>
						</div>
					</div>

				</form>
			</div>
		)
	}
}));
