import {addLocaleData} from 'react-intl';

const en = () => Promise.all([
	import(/* webpackChunkName: 'en' */ './en'),
	import(/* webpackChunkName: 'en' */ 'react-intl/locale-data/en.js')
]);

const sv = () => Promise.all([
	import(/* webpackChunkName: 'sv' */ './sv'),
	import(/* webpackChunkName: 'sv' */ 'react-intl/locale-data/sv.js')
]);

const de = () => Promise.all([
	import(/* webpackChunkName: 'de' */ './de'),
	import(/* webpackChunkName: 'de' */ 'react-intl/locale-data/de.js')
]);

const fr = () => Promise.all([
	import(/* webpackChunkName: 'fr' */ './fr'),
	import(/* webpackChunkName: 'fr' */ 'react-intl/locale-data/fr.js')
]);

const locales = {
	'en'	: en,
	'en-GB' : en,
	'en-US' : en,
	'sv'	: sv,
	'sv-SE' : sv,
	'de'    : de,
	'de-DE' : de,
	'fr'    : fr,
	'fr-FR' : fr
};

export default (locale, callbackFn, scope) => {
	const loader = locales[locale] || locales['en'];
	loader().then(([i18n, reactIntlLocaleData]) => {
		addLocaleData(reactIntlLocaleData);
		callbackFn.call(scope, i18n);
	});
};
