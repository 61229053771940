import { Record, Map, List, fromJS } from 'immutable';

export const BrowserRecord = Record({

	name: '',
	version: '',
	webkit: true,
	chrome: true

}, 'BrowserRecord');

export const OSRecord = Record({

	osName: '',
	deviceName: '',
	osx: true,
	win: false,
	tablet: false,
	phone: false,
	touch: false

}, 'OSRecord');

export const ClaimRecord = Record({

	type: '',
	source: '',
	values: new List()

}, 'ClaimRecord');

export const ProfileMetaRecord = Record({

	os: new OSRecord(),
	browser: new BrowserRecord()

}, 'ProfileMetaRecord');

export const ProfileDataRecord = Record({

	id: '',
	authenticationLevel: '',
	namedIdentity: '',
	userAgentRaw: '',
	claims: new List()

}, 'ProfileDataRecord');

export const ProfileRecord = Record({

	userId: '',
	revision: 0,
	updatedAt: '',
	data: new ProfileDataRecord(),
	meta: new ProfileMetaRecord()

}, 'ProfileRecord');