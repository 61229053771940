const loadDefaultTemplates = () => {
	// Bundle the templates in a separate webpack chunk (named "defaultRenderTemplates") to be able to exclude them from uglify/minification
	// All template file names need to be explicitly enumerated inline below, as webpack can't resolve them from an expression

	return Promise.all([
		import(/* webpackChunkName: 'defaultRenderTemplates' */ './defaultTemplates/fileSharing.html?raw').then(module => {
			return {
				name: 'fileSharing',
				script: module.default
			};
		}),
		import(/* webpackChunkName: 'defaultRenderTemplates' */ './defaultTemplates/screenSharing.html?raw').then(module => {
			return {
				name: 'screenSharing',
				script: module.default
			};
		})
	]);
};

export default {
	loadDefaultTemplates
};
