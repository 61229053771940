import defaultTranslations from './BannerEditTranslationDefaults';
import BannerEditTranslationAddEditModalTemplate from './BannerEditTranslationAddEditModalTemplate';
import BannerExportTranslationsModalTemplate from './BannerExportTranslationsModalTemplate';
import BannerImportTranslationsModalTemplate from './BannerImportTranslationsModalTemplate';

import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _pickBy from 'lodash/pickBy';

export default function ($state, $timeout, $scope, $parse, banner, BannerService, BannerThemeService, VngageUtil, filterXSS, NotificationService, vngageRemoveModalService, APIConfigurationSectionService, $uibModal, wcagEnabled) {
	'ngInject';

	var vm = this;
	vm.getKeys = function (o) {
		return (o ? Object.keys(o): []);
	}
	vm.bannerConfigurationForm = $scope.$parent.vm.bannerConfigurationForm; // As this is a child controller to BannerEditController: Get the $parent's vm.bannerConfigurationForm
	vm.theme = $scope.$parent.vm.theme; // As this is a child controller to BannerEditController: Get the $parent's vm.theme
	vm.banner = banner;

	vm.viewTranslation = viewTranslation;
	vm.addTranslation = addEditTranslation;
	vm.exportTranslation = exportTranslation;
	vm.importTranslation = importTranslation;
	vm.editTranslation = addEditTranslation;
	vm.removeTranslation = removeTranslation;

	vm.addCustomTranslationKey = addCustomTranslationKey;
	vm.removeCustomTranslationKey = removeCustomTranslationKey;
	vm.newCustomTranslationKey = '';
	vm.newCustomTranslationValue = '';
	vm.customTranslationError = {
		timer: null,
		visible: false,
		text: ''
	};

	vm.addErrorCodeTranslationKey = addErrorCodeTranslationKey;
	vm.removeErrorCodeTranslationKey = removeErrorCodeTranslationKey;
	vm.newErrorCodeTranslationKey = '';
	vm.newErrorCodeTranslationValue = '';
	vm.errorCodeTranslationError = {
		timer: null,
		visible: false,
		text: ''
	};

	let translationsDataMigrated = false;
	if (vm.banner.data.section.translations) {
		// Add new translation keys if added in default
		Object.keys(vm.banner.data.section.translations).map((key) => {
			const originalTranslationsLength = (vm.banner.data.section.translations[key].translations ? Object.keys(vm.banner.data.section.translations[key].translations).length: 0);
			const originalErrorTranslationsLength = (vm.banner.data.section.translations[key].errorTranslations ? Object.keys(vm.banner.data.section.translations[key].errorTranslations).length: 0);

			let translation = _cloneDeep(defaultTranslations.defaultTranslation_en);
			if (!wcagEnabled) {
				// If wcag is not enabled, don't add any "WCAG_"-keys from the default translation...
				translation.translations = _pickBy(translation.translations, (val, key) => {
					return (key.indexOf('WCAG_') !== 0);
				});
			}

			_merge(translation, vm.banner.data.section.translations[key]);
			vm.banner.data.section.translations[key] = translation;

			if (vm.banner.data.section.translations[key].translations && Object.keys(vm.banner.data.section.translations[key].translations).length !== originalTranslationsLength) {
				translationsDataMigrated = true;
			}
			if (vm.banner.data.section.translations[key].errorTranslations && Object.keys(vm.banner.data.section.translations[key].errorTranslations).length !== originalErrorTranslationsLength) {
				translationsDataMigrated = true;
			}
		});

		// Update existing custom error codes that has become "non-custom", or "non-custom" ones that has become custom.
		Object.keys(vm.banner.data.section.translations).map((key) => {
			if (vm.banner.data.section.translations[key].errorTranslations) {
				Object.keys(vm.banner.data.section.translations[key].errorTranslations).map((errorCode) => {
					if (vm.banner.data.section.translations[key].errorTranslations[errorCode].custom && defaultTranslations.defaultTranslation_en.errorTranslations[errorCode]) {
						// Custom error code has become "non-custom" - remove it's custom-parameter
						delete vm.banner.data.section.translations[key].errorTranslations[errorCode].custom;
						translationsDataMigrated = true;
					} else if (!vm.banner.data.section.translations[key].errorTranslations[errorCode].custom && !defaultTranslations.defaultTranslation_en.errorTranslations[errorCode]) {
						// "Non-custom" error code has become custom - set it's custom-parameter = true
						vm.banner.data.section.translations[key].errorTranslations[errorCode].custom = true;
						translationsDataMigrated = true;
					}
				});
			}
		});
	}

	// Make sure all translations have their key assigned to 'id'
	// Also check that they do not contain a defaultTranslation *object* (leftover from old version)
	_forEach(vm.banner.data.section.translations, (translation, key) => {
		if (translation.id !== key) {
			translation.id = key;
			translationsDataMigrated = true;
		}
		if (_isObject(translation.defaultTranslation)) {
			translation.defaultTranslation = false;
			translationsDataMigrated = true;
		}
	});

	// Make sure we have a default translation
	var defaultTranslation = BannerService.getDefaultBannerTranslation(vm.banner);
	if (defaultTranslation && !defaultTranslation.defaultTranslation) {
		defaultTranslation.defaultTranslation = true;
		translationsDataMigrated = true;
	}

	if (translationsDataMigrated) {
		$timeout(() => {
			// if (!_isEqual(originalTranslationsData, vm.banner.data.section.translations)) {
			// 	console.log('Dirty!');
			// }
			vm.bannerConfigurationForm.$setDirty();
		});
	}

	// Set selected translation
	vm.selectedTranslationId = vm.theme.config.activeTranslation || (defaultTranslation ? defaultTranslation.id: '');
	vm.theme.config.activeTranslation = vm.selectedTranslationId;

	$scope.$watch('vm.theme.config.activeTranslation', vm.theme.preview, true);

	function displayError(errorObj, errorMessage) {
		errorObj.text = errorMessage;
		errorObj.visible = true;
		$timeout.cancel(errorObj.timer);
		errorObj.timer = $timeout(() => {
			errorObj.timer = null;
			errorObj.visible = false;
		}, 3000);
	}


	// View, add, edit, remove translations
	function viewTranslation(translationId) {
		vm.selectedTranslationId = translationId;
		vm.theme.config.activeTranslation = translationId;
	}

	function exportTranslation(id, event) {
		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: BannerExportTranslationsModalTemplate,
			controller: 'BannerExportTranslationsModalController as vm',
			resolve: {
				translations: () => {
					return vm.banner.data.section.translations[id];
				}
			}
		});
		modal.result.then(() => {
		}).catch(() => {
			// Modal dismissed...
		});

		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	function importTranslation(id, event) {
		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: BannerImportTranslationsModalTemplate,
			controller: 'BannerImportTranslationsModalController as vm',
			resolve: {
				translations: () => {
					return vm.banner.data.section.translations[id];
				}
			}
		});
		modal.result.then((newTranslations) => {
			_merge(vm.banner.data.section.translations[id].translations, newTranslations.translations);
			_merge(vm.banner.data.section.translations[id].custom, newTranslations.custom);
			_merge(vm.banner.data.section.translations[id].errorTranslations, newTranslations.errorTranslations);
		}).catch(() => {
			// Modal dismissed...
		});

		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	function addEditTranslation(id, event) {
		openEditModal(id);
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	function removeTranslation(id, event) {
		// console.log('remove translation',vm.banner.data.section.translations,vm.selectedTranslationId);
		event.preventDefault();
		event.stopPropagation();
		delete vm.banner.data.section.translations[id];

		// Make sure we still have a defaultTranslation
		var defaultTranslation = BannerService.getDefaultBannerTranslation(vm.banner);
		if (defaultTranslation && !defaultTranslation.defaultTranslation) {
			defaultTranslation.defaultTranslation = true;
		}
		if (vm.selectedTranslationId === id) {
			// User removed the currently selected translation: Select the default
			vm.selectedTranslationId = (defaultTranslation ? defaultTranslation.id: '');
			vm.theme.config.activeTranslation = vm.selectedTranslationId;
		}
		vm.bannerConfigurationForm.$setDirty();
	}


	// Add, edit, remove custom translation keys
	function addCustomTranslationKey() {
		// First check for errors...
		var error = false;
		Object.keys(vm.banner.data.section.translations).map((translation, index) => {
			if (!vm.newCustomTranslationKey) {
				displayError(vm.customTranslationError, 'You must enter a key');
				error = true;
				return;
			}
			if (vm.banner.data.section.translations[translation].custom[vm.newCustomTranslationKey]) {
				displayError(vm.customTranslationError, 'Custom key \'' + vm.newCustomTranslationKey + '\' already defined');
				error = true;
				return;
			}
			if (!vm.newCustomTranslationValue) {
				displayError(vm.customTranslationError, 'You must enter a text');
				error = true;
				return;
			}
		});

		// If ok, add
		if (!error) {
			Object.keys(vm.banner.data.section.translations).map((translation, index) => {
				vm.banner.data.section.translations[translation].custom[vm.newCustomTranslationKey] = vm.newCustomTranslationValue || '';
			});
			vm.newCustomTranslationKey = '';
			vm.newCustomTranslationValue = '';
			vm.bannerConfigurationForm.$setDirty();
		}
	}

	function removeCustomTranslationKey(id) {
		Object.keys(vm.banner.data.section.translations).map((translation, index) => {
			delete vm.banner.data.section.translations[translation].custom[id];
		});
		vm.bannerConfigurationForm.$setDirty();
	}


	// Add, edit, remove error code translation keys
	function addErrorCodeTranslationKey() {
		// First check for errors...
		var error = false;
		Object.keys(vm.banner.data.section.translations).map((translation, index) => {
			if (!vm.newErrorCodeTranslationKey || !(/^[0-9]+$/.test(vm.newErrorCodeTranslationKey))) {
				displayError(vm.errorCodeTranslationError, 'Invalid error code (must be a positive integer)');
				error = true;
				return;
			}
			if (vm.banner.data.section.translations[translation].errorTranslations[vm.newErrorCodeTranslationKey]) {
				displayError(vm.errorCodeTranslationError, 'Error code \'' + vm.newErrorCodeTranslationKey + '\' already defined');
				error = true;
				return;
			}
			if (!vm.newErrorCodeTranslationValue) {
				displayError(vm.errorCodeTranslationError, 'You must enter an error message');
				error = true;
				return;
			}
		});

		// If ok, add
		if (!error) {
			Object.keys(vm.banner.data.section.translations).map((translation, index) => {
				vm.banner.data.section.translations[translation].errorTranslations[vm.newErrorCodeTranslationKey] = {
					custom: true,
					translation: vm.newErrorCodeTranslationValue
				};
			});
			vm.newErrorCodeTranslationKey = '';
			vm.newErrorCodeTranslationValue = '';
			vm.bannerConfigurationForm.$setDirty();
		}
	}

	function removeErrorCodeTranslationKey(id) {
		var error = false;
		Object.keys(vm.banner.data.section.translations).map((translation, index) => {
			if (!vm.banner.data.section.translations[translation].errorTranslations[id].custom) {
				displayError(vm.errorCodeTranslationError, 'Only custom error codes can be removed');
				error = true;
				return;
			}
		});
		if (!error) {
			Object.keys(vm.banner.data.section.translations).map((translation, index) => {
				delete vm.banner.data.section.translations[translation].errorTranslations[id];
			});
			vm.bannerConfigurationForm.$setDirty();
		}
	}


	function openEditModal(id) {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: BannerEditTranslationAddEditModalTemplate,
			controller: 'BannerEditTranslationAddEditModalController as vm',
			resolve: {
				translationId: () => {
					return id;
				},
				translations: () => {
					return vm.banner.data.section.translations;
				}
			}
		});
		modal.result.then(savedTranslation => {
			if (!savedTranslation) {
				// Translation removed
				delete vm.banner.data.section.translations[id];

				// Make sure we still have a defaultTranslation
				var defaultTranslation = BannerService.getDefaultBannerTranslation(vm.banner);
				if (defaultTranslation && !defaultTranslation.defaultTranslation) {
					defaultTranslation.defaultTranslation = true;
				}

				if (vm.selectedTranslationId === id) {
					// User removed the currently selected translation: Select the default
					vm.selectedTranslationId = (defaultTranslation ? defaultTranslation.id: '');
					vm.theme.config.activeTranslation = vm.selectedTranslationId;
				}
			} else {
				// Mapping added or edited

				if (savedTranslation.defaultTranslation || _isEmpty(vm.banner.data.section.translations)) {
					_forEach(vm.banner.data.section.translations, translation => {
						translation.defaultTranslation = false;
					});
					savedTranslation.defaultTranslation = true;
				}

				vm.banner.data.section.translations[savedTranslation.id] = savedTranslation;

				vm.selectedTranslationId = savedTranslation.id;
				vm.theme.config.activeTranslation = vm.selectedTranslationId;
			}
			vm.banner.data.section.subjectMapping = _cloneDeep(vm.banner.data.section.subjectMapping);
			vm.bannerConfigurationForm.$setDirty();
		}).catch(() => {
			// Modal dismissed...
		});
	}
}
