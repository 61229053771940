import AngularInjector from '../../../common/AngularInjector';

export default {
	init: function () {

		//var DesktopService = injector.get('DesktopServiceLegacy');

		psPlugin.application.events.onConfigurationNotSufficient.subscribe('errorHandler', function (msg) {
			var NotificationService = AngularInjector.get('NotificationService');
			var msg = (typeof msg === 'string') ? msg: '';

			NotificationService.error({
				header: 'Configuration error',
				body: msg + 'Please contact your account administrator.'
			}, {
				timeOut: 0,
				tapToDismiss: true
			});

		});


		//psPlugin.application.events.onConnectionRegisterFail.subscribe('errorHandler', function (data) {
		//	psPlugin.application.listener.stop();
		//	console.log('onConnectionRegisterFail: ', data);
		//	psPlugin.application.events.onOffline.trigger('onConnectionRegisterFail');
		//});
		//psPlugin.application.events.onConnectionStreamFail.subscribe('errorHandler', function (data) {
		//psPlugin.application.listener.stop();
		//console.log('onConnectionStreamFail: ', data);
		//psPlugin.application.events.onOffline.trigger('onConnectionStreamFail');
		//DesktopService.connectionStatus(1);
		//});

		//psPlugin.application.events.onOffline.subscribe('errorHandler', function (msg) {
		//	DesktopService.connectionStatus(1).then(onConnectionStatusChange, onConnectionStatusLost);
		//	//TODO: Make a nicer transition into login screen.
		//	msg = msg || 'Your session has timed out.\n\nYou might need to log in again, but first try reloading the page.';
		//	alert(msg);
		//});

		//psPlugin.application.events.onListenerTimeout.subscribe('errorHandler', function (data) {
		//	DesktopService.connectionStatus(1);
		//	console.error('onListenerTimeout', data, arguments);
		//	//Connection lost.
		//	//Connection restored. Reload now
		//	if (window.msg) return;
		//	window.msg = NotificationService.error({
		//		//header: 'Connection error',
		//		body: 'Connection lost. <a href="javascript:window.location.reload();">Reconnect</a>'
		//	},{
		//		allowHtml: true,
		//		positionClass: 'toast-top-right',
		//		timeOut: 0,
		//		extendedTimeOut:0,
		//		tapToDismiss: true
		//	});
		//
		//});
		//psPlugin.application.events.onTokenMismatch.subscribe('errorHandler', function (data) {
		//	console.error('onTokenMismatch', data);
		//});
	}
};
