import React from 'react';
import {useGetTranslation} from './../../LangContext';

const IndicateVisitorNavigation = (props) => {
	const getTranslation = useGetTranslation();
	const url = props.url;
	const text = getTranslation('roleVisitor');
	const nr = props.size % 2;
	const cls = 'indicate-visitor-url wiggle' + nr;
	const defaultCls = 'indicate-visitor-url';
	return (
		<div className={props.indicate ? cls: defaultCls}>
			<span className="visitor-url lineClampEllipsis1LineExtraLong"><strong>{text}:</strong> {url}</span>
		</div>
	)
};
export default IndicateVisitorNavigation

