import DesktopController from './DesktopController';
import DesktopLegacy from './DesktopLegacyController';
import DesktopService from './DesktopService';
import DesktopTranslations from './DesktopTranslations';
import ActiveCase from './case/ActiveCase';
import CaseService from './case/CaseService';
import DesktopTemplate from './DesktopTemplate';
import '../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.desktop', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('DesktopController', DesktopController)
	.controller('DesktopLegacy', DesktopLegacy)
	.constant('DesktopTranslations', DesktopTranslations)
	.factory('ActiveCase', ActiveCase)
	.factory('CaseService', CaseService)
	.factory('DesktopServiceLegacy', DesktopService)

	.constant('desktopModuleConfig', {
		state: 'root.desktop'
	})

	.config(function($stateProvider, $translateProvider, DesktopTranslations, desktopModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(DesktopTranslations);

		$stateProvider.state(desktopModuleConfig.state, {
			url: '/desktop/',
			views: {
				content: {
					templateUrl: DesktopTemplate,
					controller: 'DesktopController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				loadConfiguration: function(DesktopServiceLegacy) {
					'ngInject';
					return DesktopServiceLegacy.getConfiguration();
				}
			}
		});
	});
