import Data from './Data'
import Actions from './Actions'
import version110 from '../1.1.0/index'

// only actions are updated from v1.1.0
export default {
	actionFormats: {...version110.actionFormats, ...Actions.actionFormats},
	actionMap: {...version110.actionMap, ...Actions.actionMap},
	...Data,
	subscriptions: {...version110.subscriptions}
}

