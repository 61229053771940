export default {
	'en-GB': {
		app: {
			menu: {
				DASHBOARD: 'Dashboard',
				DASHBOARD_TITLE: 'Dashboard',
				DESKTOP: 'Desktop',
				DESKTOP_TITLE: 'Desktop',
				STATISTICS: 'Statistics',
				STATISTICS_TITLE: 'Statistics',
				CASE_BROWSER: 'Case browser',
				CASE_BROWSER_TITLE: 'Case browser',
				MONITOR: 'Monitor',
				MONITOR_TITLE: 'Workforce monitor',
				CALENDAR: 'Calendar',
				CALENDAR_TITLE: 'Calendar',
				ANALYZE: 'Analyze',
				ANALYZE_TITLE: 'Analyze',
				ENGAGE: 'Engage',
				ENGAGE_TITLE: 'Engage',
				CONFIGURE: 'Configure',
				CONFIGURE_TITLE: 'Configure',
				PUBLISH: 'Publish',
				PUBLISH_TITLE: 'Publish',
				ACCOUNT: 'Account',
				ACCOUNT_TITLE: 'Account',
				SYSTEM: 'System',
				SYSTEM_TITLE: 'System',
				DEV_TOOLS: 'Dev tools',
				DEV_TOOLS_TITLE: 'Dev tools',
				PLUGINS: 'Plugins',
				PLUGINS_TITLE: 'Plugins'
			},
			ONLINE: 'Online',
			AWAY: 'Away',
			DELEGATION_END: 'Home account',
			DELEGATE: 'Delegate',
			LOGOUT: 'Log out',
			FORCED_AWAY_1: 'Your status has been set to Away due to inactivity.',
			FORCED_AWAY_2: 'To get dialogs again you need to change your status to Online.',
			CHANGE_TO_ONLINE: 'Change my status to Online'
		}
	},
	'sv-SE': {
		app: {
			menu: {
				DASHBOARD: 'Dashboard',
				DASHBOARD_TITLE: 'Dashboard',
				DESKTOP: 'Desktop',
				DESKTOP_TITLE: 'Desktop',
				STATISTICS: 'Statistics',
				STATISTICS_TITLE: 'Statistics',
				CASE_BROWSER: 'Case browser',
				CASE_BROWSER_TITLE: 'Case browser',
				MONITOR: 'Monitor',
				MONITOR_TITLE: 'Workforce monitor',
				CALENDAR: 'Calendar',
				CALENDAR_TITLE: 'Calendar',
				ANALYZE: 'Analyze',
				ANALYZE_TITLE: 'Analyze',
				ENGAGE: 'Engage',
				ENGAGE_TITLE: 'Engage',
				CONFIGURE: 'Configure',
				CONFIGURE_TITLE: 'Configure',
				PUBLISH: 'Publish',
				PUBLISH_TITLE: 'Publish',
				ACCOUNT: 'Account',
				ACCOUNT_TITLE: 'Account',
				SYSTEM: 'System',
				SYSTEM_TITLE: 'System',
				DEV_TOOLS: 'Dev tools',
				DEV_TOOLS_TITLE: 'Dev tools',
				PLUGINS: 'Plugins',
				PLUGINS_TITLE: 'Plugins'
			},
			ONLINE: 'Online',
			AWAY: 'Away',
			DELEGATION_END: 'Home account',
			DELEGATE: 'Delegate',
			LOGOUT: 'Log out',
			FORCED_AWAY_1: 'Your status has been set to Away due to inactivity.',
			FORCED_AWAY_2: 'To get dialogs again you need to change your status to Online.',
			CHANGE_TO_ONLINE: 'Change my status to Online'
		}
	},
	'fr-FR': {
		app: {
			menu: {
				DASHBOARD: 'Tableau de bord',
				DASHBOARD_TITLE: 'Tableau de bord',
				DESKTOP: 'Bureau',
				DESKTOP_TITLE: 'Bureau',
				STATISTICS: 'Statistiques',
				STATISTICS_TITLE: 'Statistiques',
				CASE_BROWSER: 'Parcourir les dossiers',
				CASE_BROWSER_TITLE: 'Parcourir les dossiers',
				MONITOR: 'Moniteur',
				MONITOR_TITLE: 'Moniteur de main-d\'œuvre',
				CALENDAR: 'Calendrier',
				CALENDAR_TITLE: 'Calendrier',
				ANALYZE: 'Analyser',
				ANALYZE_TITLE: 'Analyser',
				ENGAGE: 'Lancer',
				ENGAGE_TITLE: 'Lancer',
				CONFIGURE: 'Configurer',
				CONFIGURE_TITLE: 'Configurer',
				PUBLISH: 'Publier',
				PUBLISH_TITLE: 'Publier',
				ACCOUNT: 'Compte',
				ACCOUNT_TITLE: 'Compte',
				SYSTEM: 'Système',
				SYSTEM_TITLE: 'Système',
				DEV_TOOLS: 'Outils de développement',
				DEV_TOOLS_TITLE: 'Outils de développement',
				PLUGINS: 'Plugins',
				PLUGINS_TITLE: 'Plugins'
			},
			ONLINE: 'En ligne',
			AWAY: 'Absent(e)',
			DELEGATION_END: 'Compte local',
			DELEGATE: 'Déléguer',
			LOGOUT: 'Déconnexion',
			FORCED_AWAY_1: 'Votre statut est passé à « Absent(e) » en raison de votre inactivité.',
			FORCED_AWAY_2: 'Pour pouvoir dialoguer à nouveau, définissez à nouveau votre statut sur « En ligne »',
			CHANGE_TO_ONLINE: 'Passer au statut « En ligne »'
		}
	}
};
