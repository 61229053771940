import AppDispatcher from '../dispatcher/AppDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import MessageUtils from '../../../components/utils/MessageUtils';
import StoreFactory from '../../../components/utils/StoreFactory';

const AuthorizationTypes = AppConstants.Authorization;
const ActionTypes = AppConstants.ActionTypes;

const ERROR_CODES = {
	failedConversationAuthorization: 'blocked'
};

const SUBERROR_CODES = {
	MaxInvitationCodeUsageExceed: 'maxInvitations'
};

var state = {
	authorized: AuthorizationTypes.PENDING,
	errorCode: null,
	subErrorCode: null
};

var AuthorizationStore = StoreFactory.create({

	/**
	 * return authorization state
	 */
	getStatus () {
		return state.authorized;
	},

	/**
	 * return error code
	 */
	getErrorCode () {
		return {
			errorCode: state.errorCode,
			subErrorCode: state.subErrorCode
		}
	}

});

const registeredCallback = function(payload) {
	const action = payload.action;
	const error = action.error;
	const errorCode = action.errorCode;
	const statusCode = action.statusCode;

	switch (action.type) {

		case AuthorizationTypes.PENDING:
			state.authorized = AuthorizationTypes.PENDING;
			break;

		case AuthorizationTypes.AUTHORIZED:
			state.authorized = AuthorizationTypes.AUTHORIZED;
			break;

		case AuthorizationTypes.NOT_AUTHORIZED:
			state.errorCode = 'empty'; //ERROR_CODES[error.errorCode];
			state.subErrorCode = SUBERROR_CODES[error.subErrorCode];
			state.authorized = AuthorizationTypes.NOT_AUTHORIZED;
			break;

		case AuthorizationTypes.BLOCKED:
			state.errorCode = 'blocked';
			state.authorized = AuthorizationTypes.NOT_AUTHORIZED;
			break;

		case ActionTypes.CONVERSATION_CLOSED:
			state.errorCode = 'ended';
			state.authorized = AuthorizationTypes.NOT_AUTHORIZED;
			break;

		case ActionTypes.CONVERSATION_LEAVE:
			state.errorCode = 'left';
			state.authorized = AuthorizationTypes.NOT_AUTHORIZED;
			break;

		case ActionTypes.REQUEST_ERROR:
			state.errorCode = ERROR_CODES[errorCode];
			if ([400, 500, 503].indexOf(statusCode) === -1){
				state.authorized = AuthorizationTypes.NOT_AUTHORIZED;
			}
			break;

		default :
			return true;
	}

	// if we made it down here, emit change event
	AuthorizationStore.emitChange();
};

AuthorizationStore.dispatchToken = AppDispatcher.register(registeredCallback);

export default AuthorizationStore;
