import AccountConfiguration from '../../../common/AccountConfiguration';

let mockEnabled = false;
let conf = {};
let mockAccount = {};
export default {
	get configuration(){
		if(mockEnabled){
			return mockAccount;
		} else {
			return AccountConfiguration.configuration;
		}
	},
	setMock(mock){
		mockAccount = mock;
	},
	enableMock(){
		mockEnabled = true;
	},
	disableMock(){
		mockEnabled = false;
	},
	isMockEnabled(){
		return mockEnabled;
	}

}


