export default function(trustRelationships, accountList, accountId, StateReloadService, TrustRelationshipService, NotificationService) {
    'ngInject';

    var vm = this;
    vm.trustRelationships = trustRelationships;
    vm.selectableTrusts = TrustRelationshipService.getSelectableTrusts(accountList, accountId);
    vm.selectedTrusts = TrustRelationshipService.getSelectedTrusts(vm.trustRelationships);

    TrustRelationshipService.populateTrustRelationships(accountList, vm.trustRelationships);

    vm.submit = function () {
        vm.isSaving = true;
        TrustRelationshipService.updateTrustRelationships(
            accountId,
            vm.trustRelationships,
            vm.selectedTrusts,
            vm.selectableTrusts.map(function(s) { return s.refValue; })
        ).then(success,fail);

        function success(res) {
            vm.isSaving = false;
            // TL: We should probably do some proper error handling here.
            // Right now the TrustRelationshipService.updateTrustRelationships() promise always resolves to "success",
            // but here we should loop though res.items to look for failed requests in the batch.
            NotificationService.success({ header: 'Success', body: 'Successfully updated trust relationships' });
        }
        function fail(err) {
            vm.isSaving = false;
            NotificationService.error({ header: 'Error', body: 'Error updating trust relationships' });
        }
    };
};
