import devToolsTranslations from './DevToolsTranslations';
import DevToolsContentTemplate from './DevToolsContentTemplate';
import DevToolsController from './DevToolsController';
import '../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.devTools', [
    'ui.router',
	'vngageApp.workspaceTranslation'
])

.constant('devToolsTranslations', devToolsTranslations)
.controller('DevToolsController', DevToolsController)

.constant('devToolsModuleConfig', {
    state: 'root.devTools',
    access: 'Account'
})

.config(function($stateProvider, $urlRouterProvider, $translateProvider, devToolsTranslations, devToolsModuleConfig, VngageWorkspaceTranslationServiceProvider) {
    'ngInject';

	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(devToolsTranslations);

    $stateProvider.state(devToolsModuleConfig.state, {
        url: '/devtools/',
        views: {
            content: {
                templateUrl: DevToolsContentTemplate,
                controllerAs: 'vm',
                controller: 'DevToolsController'
            }
        },
        data: {
            access: devToolsModuleConfig.access,
            ncyBreadcrumbLabel: 'Dev Tools'
        }
    });
});
