import LegacyTimer from './LegacyTimer';


var availableCommands = {};

var Listener = (function () {

	var Listener = function (heartbeatParams, commands, IdentityService, DesktopService, NotificationService) {
		var self = this,
			intervalTimer = false,
			heartbeatRetries = 3,
			heartbeatGiveUp = 5,
			heartbeatInterval = 2800,
			heartbeatTimeout = 17200, //20000,
			heartbeatAttempts = 0,
			listening = false;

		this.token = 'vngage'; //false;
		this.tokenMismatch = false;
		this.started = false;
		this.options = {};

		//make heartbeat public
		this.heartbeatParams = heartbeatParams;

		this.isListening = function () {
			return listening;
		};

		this.start = function () {

			if (!listening) {
				listening = true;
			}

			if (!intervalTimer) {
				intervalTimer = new LegacyTimer(heartbeatInterval, heartbeatTimeout, heartbeat, retryHeartbeat);
			} else {
				intervalTimer.restart();
			}

		};

		this.stop = function (tokenMismatch) {
			if (!!tokenMismatch) {
				self.tokenMismatch = true;
			}
			if (intervalTimer) {
				intervalTimer.stop();
			}
			listening = false;

			//console.log(tokenMismatch ? '{+TM+}' : '', 'tokenMismatch=', self.tokenMismatch, 'listening=', listening);
		};

		var distributeResponse = function (response, eventTrigger) {

			heartbeatAttempts = 0;
			if (!!self.tokenMismatch) {
				return;
			}

			//trigger the event that handles listenerResponse
			//vDeux uses this:
			//psPlugin.application.events.onListenerResponse.trigger(response);
			psPlugin.application.events.onListenerResponse.trigger(response.data);

			// start listener if not started
			if (!listening) listening = true;

			if (!intervalTimer) {
				intervalTimer = new LegacyTimer(heartbeatInterval, heartbeatTimeout, heartbeat, retryHeartbeat);
			} else {
				intervalTimer.restart();
			}

			// call event trigger if defined
			if (typeof eventTrigger !== 'undefined') eventTrigger();
		};

		function heartbeat() {
			var hbParams = self.heartbeatParams;
			if (typeof hbParams === 'function') hbParams = hbParams();

			psPlugin.application.events.onInterval.trigger();

			//Debug failed connection (heartbeat retries)
			if (window.breakheart === true) {
				console.log('heartbroken... ♥');
				return;
			}
			self.send(hbParams, (function (data) {
				if (!helpers.checkForErrorsInResponse(hbParams, data)) {
					console.log('♥-error');
					return false;
				}

				if (data.data.forcePause) {
					if (IdentityService.presence !== 'away') {
						IdentityService.setPresence('away');
						document.getElementById('forced-pause').classList.add('visible-message');
						/*NotificationService.warning({
							header: 'Please note...',
							body: 'Your status has been changed to "away", since you did not respond to an auto-assigned visitor within good time.'
						}, {
							timeOut: 0,
							tapToDismiss: true
						});*/
					}
				}

				DesktopService.connectionStatus(2).then(psPlugin.onConnectionStatusChange, psPlugin.onConnectionStatusLost);

				distributeResponse(data);
			}));
		}

		function retryHeartbeat() {

			heartbeatAttempts++;

			if (window.breakheart === true) {
				console.log('retry ♥', heartbeatAttempts + '/' + heartbeatRetries);
			}

			if (heartbeatAttempts <= heartbeatRetries) {
				DesktopService.connectionStatus(1).then(psPlugin.onConnectionStatusChange, psPlugin.onConnectionStatusLost);
				intervalTimer.restart();
			} else {
				DesktopService.connectionStatus(0).then(psPlugin.onConnectionStatusChange, psPlugin.onConnectionStatusLost)
				if (heartbeatAttempts < heartbeatGiveUp) {
					// Connection seems dead (show notification), but keep trying until "heartbeatGiveUp" is reached!
					intervalTimer.restart();
				} else {
					intervalTimer.stop();
				}
			}
		}

		//finally, add the commands to the listener instance
		this.addCommands(commands);
	};

	//Adds methods and properties to the Listener
	Listener.prototype.addCommands = function (props) {
		for (var p in props) {
			if (typeof props[p].runCommand !== 'undefined') {
				try {
					this[p] = this[props[p].runCommand];
				} catch (err) {
					//console.error(err);
				}
			} else {
				availableCommands[p] = new helpers.command(props[p], this.send);
				this[p] = availableCommands[p].send;
			}

		}
	};

	return Listener;
})();


var helpers = {

	command: function (props, send) {
		this.send = function () {
			var params = props.parameters.apply(this, arguments);

			//console.log('Request', params.reqObj);
			send(params.reqObj, (function (data) {
				// validate data
				//console.log('Response', data);
				if (helpers.checkForErrorsInResponse(params.reqObj, data)) {
					if (typeof props.onSuccess === 'function') props.onSuccess(data);
					if (typeof params.onSuccess === 'function') params.onSuccess(data);
					return false;
				} else {
					if (typeof props.onError === 'function') props.onError(data);
				}
			}));
		};
	},

	checkForErrorsInResponse: function (requestParams, response) {

		return response.status && response.status.success === true;

		//if (typeof response.status === 'undefined' || response.status.success === false) {

		////console.log(response.status.error.type, (response.status.error.type === 'token mismatch'));
		//if (response.status.error.type === 'token mismatch') {
		//
		//	// try {
		//	//	console.log("token:", {
		//	//		request: requestParams,
		//	//		response: response
		//	//	});
		//	// } catch (err) { }
		//
		//	psPlugin.application.events.onTokenMismatch.trigger({
		//		request: requestParams,
		//		response: response
		//	});
		//	return false;
		//}

		//	if (typeof errorEvent !== 'undefined') {
		//		psPlugin.application.events[errorEvent].trigger({
		//			request: requestParams,
		//			response: response
		//		});
		//	}
		//	return false;
		//}
		//return true;
	}
};


export default Listener;
