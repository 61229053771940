import immutable from 'immutable';
import {sharedReducerLib} from '../libs/sharedReducerLib';
import {coBroLib} from '../libs/coBroLib';
import {conversationReducerLib as lib} from '../libs/conversationReducerLib';
import {filters} from '../libs/filters';

import INITIAL_STATE from '../../INITIAL_STATE';
import FlowStates from '../../constants/FlowStates';
import Routing from '../../utils/Routing';


export function setDebugFlag(state, action) {
	state = state.setIn(['debugFlags', action.flagName], immutable.fromJS(action.value));
	return state;
}

export function setAccountId(state, action) {
	state = state.update('accountId', prev => action.accountId
		? action.accountId
		: prev);
	return state;
}

export function setUserId(state, action) {
	state = state.updateIn(['user', 'id'], prev => action.userId
		? action.userId
		: prev);
	return state;
}

export function setVisitorSettings(state, action) {
	state = state.set('visitorSettings', immutable.fromJS(action.visitorSettings));
	return state;
}

export function setAccount(state, action) {
	state = state.set('account', immutable.fromJS(action.account));
	const hasCoBrowserRestricted = state.getIn(['account', 'coBrowserRestricted']) !== undefined;
	const hasCoBrowserDoNotDisturb = state.getIn(['account', 'coBrowserDoNotDisturb']) !== undefined;
	const hasCoBrowserNoFollow = state.getIn(['account', 'coBrowserNoFollow']) !== undefined;
	state = hasCoBrowserDoNotDisturb
		// has doNotDisturb setting
		? state
		: hasCoBrowserNoFollow
			// no doNotDisturb setting use noFollow
			? state.setIn(['account', 'coBrowserDoNotDisturb'], state.getIn(['account', 'coBrowserNoFollow']))
			// no doNotDisturb and noFollow, use empty list
			: state.setIn(['account', 'coBrowserDoNotDisturb'], immutable.fromJS([]));
	state = hasCoBrowserRestricted
		? state
		: state.setIn(['account', 'coBrowserRestricted'], immutable.fromJS([]));
	return state;

}

export function cleanUpBeforeLeaving(state, action) {
	state = INITIAL_STATE;
	state = state.set('netId', action.netId);
	return state;
}

export function receiveAsState(state, action) {
	const {conversationId} = action;
	const prevConversation = state.getIn(['conversations', conversationId]) || immutable.Map();
	const conversationState = lib.addRequiredPropsToConversationState(action.conversationState, {isRead: true});
	conversationState.messages.sort((a, b) => a.createdRaw - b.createdRaw);

	const title = filters.getOneInArray(filters.identifier.title, conversationState.messages);
	if (title) {
		const bannerInput = conversationState.messages.find(message => {
			return (message.messageType === 'chat' && message.message === title.message);
		});

		if (bannerInput) {
			bannerInput.messageType = 'bannerInput';
		}
	}
	const validMessages = filters.getFilteredArray(filters.identifier.messages, conversationState.messages);

	const groupId = filters.getLastFoundInArray(filters.identifier.groupId, conversationState.events).groupId;
	const routingType = state.getIn(['groupRouting', groupId]) || 0;
	const participantInvitedEvent = filters.getLastFoundInArray(filters.identifier.participantInvited, conversationState.events);

	const eligibleUntil = Routing.requiresStart(routingType) && (conversationState.state === 'pendingStart' || conversationState.state === 'suspended') && participantInvitedEvent
		? participantInvitedEvent.eligableUntil
		: '';

	const caseMsg = filters.getLastFoundInArray(filters.identifier.caseId, conversationState.events);
	const caseId = caseMsg
		? caseMsg.caseId
		: '';
	const currentCaseType = caseMsg
		? caseMsg.caseTypeId
		: '';

	const panelHeaderColor = prevConversation.getIn(['UI', 'panelHeaderColor']) || lib.getNewPanelHeaderColor(state);
	const flowState = prevConversation.get('flowState') || FlowStates.READY;

	const conversationStartedEvent = filters.getOneInArray(filters.identifier.conversationStarted, conversationState.events) || {createdAt: conversationState.stateTime};
	const startTime = lib.getTime(conversationStartedEvent.createdAt);
	const pluginButtons = state.get('plugins')
		.filter(plugin => plugin.get('sectionButtonType') === 'button')
		.reduce((buttonStates, plugin) => ({
			...buttonStates,
			[plugin.get('id')]: 'enabled'
		}), {});

	const conversation = {
		startTime,
		flowState,
		latestError: {},
		localMessages: [],
		me: state.getIn(['user', 'id']),
		conversationStatus: conversationState.state,
		refNumber: conversationState.refNumber,
		connectionStatus: 'ok',
		title,
		browserInfo: filters.getLastFoundInArray(filters.identifier.browserInfo, conversationState.messages),
		events: conversationState.events,
		messages: validMessages,
		activities: filters.getFilteredArray(filters.identifier.activities, conversationState.messages),
		participants: filters.getAsObjectWithIds(filters.identifier.participants, conversationState.participants),
		idle: null,
		since: conversationState.stateTime,
		abort: false,
		showMeta: true,
		inviteOptions: null,
		groupId,
		routingType,
		caseId,
		visitId: lib.getCurrentVisitId(conversationState.participants) || '',
		currentCaseType,
		solutionId: filters.getOneInArray(filters.identifier.solutionId, conversationState.events).solutionId || '',
		opportunityId: filters.getOneInArray(filters.identifier.opportunityId, conversationState.events).opportunityId || '',
		isTyping: false,
		forms: [],
		lastNavigationIsDomUpload: coBroLib.getLastNavigationType(conversationState.messages) === 'domUpload',
		UI: {
			userIsTyping: false,
			panelHeaderColor,
			activeSections: {},
			unreadMessages: 0,
			oldestMessageRanking: 8,
			inputMenuIsVisible: false,
			currentMessage: conversationState.currentMessage || '',
			newCaseAfterClose: false,
			emailConversationTranscript: false, //initialize with accountConfig settings.
			currentNote: '',
			isMessageCopied: false,
			updateInputText: 0,
			selectedClosure: '',
			eligibleUntil,
			currentInput: 'input',
			panelPosition: 'normal',
			pluginButtons,
		}
	};

	state = state.setIn(['conversations', conversationId], immutable.fromJS(conversation));
	state = lib.updateConnectionStatus(state, conversationId);
	state = lib.updateUserTyping(state, conversationId);
	state = lib.setOldestMessageRanking(state);
	state = lib.updatePanelOrder(state);
	state = state.update('nbrOfDialogsSoFar', n => n + 1);
	state = sharedReducerLib.removeSelectAnimation(state, conversationId);
	state = coBroLib.addNavigationFromMessages(state, conversationId, conversationState.messages);
	state = coBroLib.setCoBroMetadataExchangeMessages(state, conversationId, conversationState.messages);

	return state;
}

export function receiveSince(state, action) {
	const {conversationId} = action;
	if (!state.hasIn(['conversations', conversationId])) {
		return state;
	}
	const rawMessages = lib.addRequiredPropsToMessages(action.rawMessages, state.getIn(['conversations', conversationId]));
	state = lib.updateLocalMessages(state, action);

	const since = rawMessages[rawMessages.length - 1].createdAt;
	let messageKeys = state.getIn(['conversations', conversationId, 'messages']).map(conv => conv.get('id'));
	let eventKeys = state.getIn(['conversations', conversationId, 'events']).map(conv => conv.get('eventId'));
	let activityKeys = state.getIn(['conversations', conversationId, 'activities']).map(conv => conv.get('id'));
	const incomingEvents = rawMessages.filter(msg => !!msg.eventId).map(event => ({
		...event,
		id: event.eventId
	}));

	const conversation = {
		events: filters.getFilteredWithExclusion(filters.identifier.events, incomingEvents, eventKeys),
		messages: filters.getFilteredWithExclusion(filters.identifier.messages, rawMessages, messageKeys),
		activities: filters.getFilteredWithExclusion(filters.identifier.activities, rawMessages, activityKeys),
		since
	};

	let events = state.getIn(['conversations', conversationId, 'events']).concat(immutable.fromJS(conversation.events));
	let activities = state.getIn(['conversations', conversationId, 'activities']).concat(immutable.fromJS(conversation.activities));

	let messages = state.getIn(['conversations', conversationId, 'messages']).concat(immutable.fromJS(conversation.messages));

	const browserInfo = filters.getLastFoundInArray(filters.identifier.browserInfo, rawMessages);

	state = state.updateIn(['conversations', conversationId, 'browserInfo'], n => browserInfo ? immutable.fromJS(browserInfo): n);

	// For booked meetings: We may not have a visitor (visitId) from start,
	// so we need to be able to add it here in RECEIVE_SINCE as well
	const visitId = state.getIn(['conversations', conversationId, 'visitId']);
	if (!visitId) {
		// console.log('no visitId');
		const participantJoinedMsgs = rawMessages.filter(msg => filters.identifier.participantJoined(msg)).map(msg => msg.participant);
		const lastJoinedVisitId = lib.getCurrentVisitId(participantJoinedMsgs);
		state = state.setIn(['conversations', conversationId, 'visitId'], lastJoinedVisitId || '');
	}

	const currentConversationStatus = state.getIn(['conversations', conversationId, 'conversationStatus']);
	if (currentConversationStatus === 'closed') {
		state = sharedReducerLib.forceCloseInfoTip(state, 'doNotDisturb');
	}

	state = state.setIn(['conversations', conversationId, 'UI', 'eligibleUntil'], '');

	state = lib.updateParticipants(state, conversationId, rawMessages);

	// connectionStatus will update if agent is alone or if agent was alone
	state = lib.updateConnectionStatus(state, conversationId);

	state = state.setIn(['conversations', conversationId, 'events'], events);
	state = state.setIn(['conversations', conversationId, 'activities'], activities);

	messages = messages.sortBy((msg, key) => msg.get('createdRaw'));
	state = state.setIn(['conversations', conversationId, 'messages'], messages);
	state = lib.updateNrOfUnreadMessages(state, conversationId);

	const lastNavigationType = coBroLib.getLastNavigationType(rawMessages);
	// only update if new navigation or domUpload is received, otherwise keep old value
	state = state.updateIn(['conversations', conversationId, 'lastNavigationIsDomUpload'], prevValue =>
		lastNavigationType === 'none' ? prevValue: lastNavigationType === 'domUpload');

	state = state.setIn(['conversations', conversationId, 'since'], since);
	state = lib.updateUserTyping(state, conversationId);
	state = lib.setOldestMessageRanking(state);
	state = sharedReducerLib.updateVisitorProfile(state, rawMessages);
	state = coBroLib.addNavigationFromMessages(state, conversationId, rawMessages);
	state = coBroLib.addCoBroMetadataExchangeMessages(state, conversationId, rawMessages);
	state = lib.updateCase(state, conversationId, rawMessages);

	return state;

}

export function updateCaseType(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'UI', 'selectedClosure'], '');
	state = state.setIn(['conversations', conversationId, 'currentCaseType'], action.caseType);
	return state;
}

export function closeCaseAndConversation(state, action) {
	const {conversationId} = action;
	// only used by playback
	state = state.get('activePanel') === conversationId
		? state = state.set('activePanel', '')
		: state;

	state = state.deleteIn(['conversations', conversationId]);
	state = lib.updatePanelOrder(state);
	state = lib.setOldestMessageRanking(state);
	return state;
}

export function addHistoryMarkers(state, action) {
	const {conversationId} = action;
	state = lib.addHistoryMarkers(state, conversationId, action.caseHistory);
	return state;
}

export function expandHistoryMarker(state, action) {
	const {conversationId} = action;
	state = lib.expandHistoryMarker(state, conversationId, action.markerId, action.caseData);
	return state;
}

export function addLocalMessage(state, action) {
	const {conversationId} = action;
	state = lib.insertLocalMessages(state, conversationId, [action.msg], action.keepTimeStamp, false);
	state = lib.markMessagesAsRead(state, conversationId);
	state = lib.setOldestMessageRanking(state);
	return state;
}

export function setCurrentMessage(state, action) {
	state = state.setIn(['conversations', action.conversationId, 'UI', 'currentMessage'], action.msg);
	return state;
}

export function setCurrentMessageAndShow(state, action) {
	state = state.setIn(['conversations', action.conversationId, 'UI', 'currentMessage'], action.msg);
	state = state.updateIn(['conversations', action.conversationId, 'UI', 'updateInputText'], n => n + 1);
	return state;
}

export function setCurrentNote(state, action) {
	state = state.setIn(['conversations', action.conversationId, 'UI', 'currentNote'], action.noteText);
	return state;
}

export function changeCase(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'UI', 'selectedClosure'], '');
	state = state.setIn(['conversations', conversationId, 'caseId'], action.caseObj.to.caseId);
	return state;
}

export function participantTyping(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'isTyping'], action.isTyping);
	return state;

}

export function indicateMessageCopied(state, action) {
	const {conversationId} = action;
	return state.updateIn(['conversations', conversationId, 'UI', 'isMessageCopied'], isCopied => !isCopied);
}

export function toggleNewCaseAfterClose(state, action) {
	const {conversationId} = action;
	return state.updateIn(['conversations', conversationId, 'UI', 'newCaseAfterClose'], visible => !visible);
}

export function toggleEmailConversationTranscript(state, action) {
	const {conversationId} = action;
	return state.updateIn(['conversations', conversationId, 'UI', 'emailConversationTranscript'], send => !send);
}

export function setActiveSection(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'UI', 'activeSections', action.section], true);
	return state;
}

export function removeActiveSection(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'UI', 'activeSections', action.section], false);
	return state;
}

export function setPendingRemoval(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'flowState'], FlowStates.REMOVING);
	const isCoBrowsing = state.getIn(['coBro', 'conversationId']) === conversationId;

	state = isCoBrowsing
		? sharedReducerLib.setPanelPosition(state, conversationId, 'normal')
		:
		state;

	state = isCoBrowsing
		? coBroLib.endCoBro(state)
		: state;

	state = state.set('activePanel', '');
	return state;
}

export function setSelectedClosure(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'UI', 'selectedClosure'], action.selectedClosure);
	return state;

}

export function setForms(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'forms'], immutable.fromJS(action.forms));
	return state;
}

export function processConversationDetails(state, action) {
	state = lib.manageActiveConversations(state, action.details);
	state = sharedReducerLib.removeUnusedData(state);
	state = sharedReducerLib.incSelectAnimationTime(state);
	state = sharedReducerLib.removeDeadSelectAnimations(state, state.get('selectedItemRemoveTime') || 7);
	return state;
}

export function textSize(state, action) {
	state = state.setIn(['userPreferences', 'textSize'], action.textSize);
	return state;
}

export function toggleSystemMessages(state, action) {
	state = state.updateIn(['userPreferences', 'displaySystemMessages'], visible => !visible);
	return state;
}

export function toggleActiveInfoTip(state, action) {
	state = state.hasIn(['userPreferences', 'activeInfoTip', action.infoType])
		? state = state.updateIn(['userPreferences', 'activeInfoTip', action.infoType], state => !state)
		: state;
	return state;
}

export function hideInfoTip(state, action) {
	state = state.hasIn(['infoTip', action.infoType])
		? state.setIn(['infoTip', action.infoType], false)
		: state;
	return state;
}

export function toggleNoteMode(state, action) {
	const {conversationId} = action;
	state = state.updateIn(['conversations', conversationId, 'UI', 'currentInput'], currentInput => currentInput !== 'note' ? 'note': 'input');
	return state;
}

export function setCurrentInput(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'UI', 'currentInput'], action.currentInput);
	return state;
}

export function updateCobroUrl(state, action) {
	state = coBroLib.addNavigation(state, action.url, action.owner, action.updateType);
	return state;
}

export function setCobroHighlightMode(state, action) {
	state = coBroLib.setHighlightMode(state, action.isActive);
	return state;
}

export function toggleCobroScale(state, action) {
	state = coBroLib.toggleCoBroScale(state);
	return state;
}

export function toggleCobroPause(state, action) {
	state = coBroLib.toggleCoBroPause(state);
	return state;
}

export function setCobroDomuploadStatus(state, action) {
	state = coBroLib.setCoBroDomUploadStatus(state, action.status);
	return state;
}

export function setFlowState(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'flowState'], action.flowState);
	return state;
}

export function startConversationFromQueue(state, action) {
	const {conversationId} = action;
	// add queue selected item with startY
	state = sharedReducerLib.addSelectAnimation(state, conversationId, action.startY);
	// create new conversation
	state = lib.addNewConversation(state, conversationId, {
		groupId: action.groupId,
		flowState: FlowStates.LOADING,
		latestError: {},
	});
	return state;
}

export function initRoutedConversation(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'flowState'], FlowStates.LOADING);
	return state;
}

export function startRoutedConversation(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'flowState'], FlowStates.LOADING);
	return state;
}

export function initAndStartRoutedConversation(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'flowState'], FlowStates.LOADING);
	return state;
}

export function resumeConversation(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'flowState'], FlowStates.LOADING);
	return state;
}

export function conversationReady(state, action) {
	const {conversationId} = action;
	state = sharedReducerLib.removeSelectAnimation(state, conversationId);
	state = state.setIn(['conversations', conversationId, 'flowState'], FlowStates.READY);
	return state;
}

export function removeConversation(state, action) {
	const {conversationId} = action;
	state = state.deleteIn(['conversations', conversationId]);
	state = sharedReducerLib.removeSelectAnimation(state, conversationId);
	state = lib.updatePanelOrder(state);
	return state;
}

export function setConversationCapabilities(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'capabilities'], immutable.fromJS(action.capabilities));
	return state;
}

export function updateLocalMessageStatus(state, action) {
	const {conversationId} = action;
	return lib.updateLocalMessageStatus(state, conversationId, action.messageId, action.status);
}

export function setConversationError(state, action) {
	const {conversationId} = action;
	state = state.setIn(['conversations', conversationId, 'latestError'], immutable.fromJS(action.latestError));
	state = sharedReducerLib.removeSelectAnimation(state, conversationId);
	return state;
}
