import React from "react";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const ToolBarButton = ({cls, onClickAction, tooltipText, ubTrack}) => {

	const tooltip = (
		<Tooltip id="toolbar-tooltip">
			{tooltipText}
		</Tooltip>);

	return (
		<OverlayTrigger placement="bottom" overlay={tooltip} delay={400}>
			<i className={cls} onClick={onClickAction} ub-track={ubTrack}/>
		</OverlayTrigger>
	);
};

export default ToolBarButton;

