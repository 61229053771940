import React, {useState, useEffect, useRef} from 'react';
import StatusBar from './StatusBar.react';
import AddressBarItem from './AddressBarItem.react';
import IndicateVisitorNavigation from './IndicateVisitorNavigation.react';
import immutable from 'immutable';
import {useGetTranslation} from './../../LangContext';

const AddressBar = (props) => {
	const getTranslation = useGetTranslation();
	const [open, setOpen] = useState(false);
	const [shakeIndicator, setShakeIndicator] = useState(false);

	const {navigation} = props;

	const updateCoBroUrl = (url, agent) => {
		props.updateCoBroUrl(url);
		setOpen(!open);
	};

	//componentWillReceiveProps
	const savedProps = useRef(navigation);
	useEffect(() => {
		const prevProps = savedProps.current;
		const nextProps = navigation;

		const lastNavigation = prevProps.last() || immutable.fromJS({url: '', owner: 'agent'});
		const nextVisitor = (nextProps.findLast(nav => nav.get('owner') === 'visitor') || lastNavigation).get('url');
		const prevVisitor = (prevProps.findLast(nav => nav.get('owner') === 'visitor') || lastNavigation).get('url');

		setShakeIndicator(nextVisitor !== prevVisitor);

		savedProps.current = navigation;

	}, [navigation]);

	const errorState = props.connectionStatus !== 'ok' ? 'error': '';
	const cls = `wrapper ${errorState}`;

	const overlay = <div onClick={() => setOpen(!open)} className="co-overlay"/>;
	const dropDown = <div className="dropdown">
		<div className="list">
			{props.navigation.butLast().reverse().entrySeq().map(([index, nav]) => <AddressBarItem
				text={getTranslation('coStatusPaused')}
				connectionStatus={props.connectionStatus}
				updateCoBroUrl={updateCoBroUrl} {...nav.toJS(nav)} key={index}/>)}
		</div>
	</div>;

	const isDomUpload = props.addressbarNavigation.get('owner') === 'domUpload';
	const domUploadUrl = <span>
		<i className="vngage-icon-line-download"/>
		{getTranslation('domUpload')}
	</span>;
	const isPaused = props.paused;
	const lastVisitorUrl = (props.navigation.findLast(nav => nav.get('owner') === 'visitor') || immutable.Map({url: ''})).get('url');

	const addressbarUrl = props.restricted
		? getTranslation('coStatusRestricted')
		: isDomUpload
			? domUploadUrl
			: props.addressbarNavigation.get('url');

	const decoratedURLPaused = <strong
		style={{fontSize: '13px', marginLeft: '2px'}}>{getTranslation('roleAgent')}:</strong>;

	return (
		<div className="co-address-bar">
			{open
				? overlay
				: ''
			}
			<div className={cls}>
				<StatusBar {...props}/>
				{isPaused
					? decoratedURLPaused
					: null
				}
				<div className="current-address">
					<span className="lineClampEllipsis1LineExtraLong">{addressbarUrl}</span>
					<span onClick={() => setOpen(!open)} className="handle"> </span>
				</div>
				{open
					? dropDown
					: ''
				}
			</div>
			{isPaused && lastVisitorUrl
				? <IndicateVisitorNavigation
					indicate={shakeIndicator}
					size={props.navigation.size}
					url={lastVisitorUrl}/>
				: null
			}
		</div>
	);
};
export default AddressBar;

