import _find from 'lodash/find';

import GroupFeaturesController from './GroupFeatures/GroupFeaturesController';
import GroupCasesController from './GroupCases/GroupCasesController';
import GroupDetailsController from './GroupDetails/GroupDetailsController';
import GroupDialogController from './GroupDialog/GroupDialogController';
import GroupOpeningHoursController from './GroupOpeningHours/GroupOpeningHoursController';
import GroupOpeningHoursEditModalController from './GroupOpeningHours/GroupOpeningHoursEditModalController';
import GroupOpeningHoursService from './GroupOpeningHours/GroupOpeningHoursService';
import GroupUsersController from './GroupUsers/GroupUsersController';
import GroupAddModalController from './GroupAddModalController';
import GroupEditController from './GroupEditController';
import GroupListController from './GroupListController';
import groupTranslations from './GroupTranslations';

import GroupListTemplate from './GroupListTemplate';
import GroupEditTemplate from './GroupEditTemplate';
import GroupDetailsTemplate from './GroupDetails/GroupDetailsTemplate';
import GroupDialogTemplate from './GroupDialog/GroupDialogTemplate';
import GroupOpeningHoursTemplate from './GroupOpeningHours/GroupOpeningHoursTemplate';
import GroupUsersTemplate from './GroupUsers/GroupUsersTemplate';
import GroupCasesTemplate from './GroupCases/GroupCasesTemplate';
import GroupFeaturesTemplate from './GroupFeatures/GroupFeaturesTemplate';
import GroupQueueReservationOpeningHoursModule
	from './GroupQueueReservationOpeningHours/GroupQueueReservationOpeningHoursModule';

import '../../../common/VngageWorkspaceTranslationModule';

import groupTypes from './GroupTypes';

export default angular.module('vngageApp.configure.group', [
	'ui.router',
	'vngageApp.configure.group.groupQueueReservationsOpeningHours',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('GroupFeaturesController', GroupFeaturesController)
	.controller('GroupCasesController', GroupCasesController)
	.controller('GroupDetailsController', GroupDetailsController)
	.controller('GroupDialogController', GroupDialogController)
	.controller('GroupOpeningHoursController', GroupOpeningHoursController)
	.controller('GroupOpeningHoursEditModalController', GroupOpeningHoursEditModalController)
	.factory('GroupOpeningHoursService', GroupOpeningHoursService)
	.controller('GroupUsersController', GroupUsersController)
	.controller('GroupAddModalController', GroupAddModalController)
	.controller('GroupEditController', GroupEditController)
	.controller('GroupListController', GroupListController)
	.constant('groupTranslations', groupTranslations)

	.constant('groupModuleConfig', {
		state: 'root.configure.group',
		access: 'Engage/Groups'
	})

	.config(function ($stateProvider, $translateProvider, groupTranslations, groupModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(groupTranslations);

		$stateProvider.state(groupModuleConfig.state, {
			url: 'group/',
			controller: 'GroupListController as vm',
			templateUrl: GroupListTemplate,
			data: {
				ncyBreadcrumbLabel: 'Groups',
				access: groupModuleConfig.access
			},
			resolve: {
				groupList: function (APIEndpointService, AccountSitesService) {
					'ngInject';
					var siteId = AccountSitesService.getSelectedSiteId();
					return APIEndpointService.group.query({siteId: siteId}).$promise;
				},
				groupTypes: function () {
					return groupTypes;
				},
				featuresList: function (APIConfigurationSectionService) {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.group, true);
				}
			}
		});
		$stateProvider.state('root.configure.group.edit', {
			url: ':id',
			controller: 'GroupEditController as vm',
			templateUrl: GroupEditTemplate,
			data: {
				ncyBreadcrumbLabel: 'Edit',
				access: {action: 'GET', resource: 'Group/*'}
			},
			params: {currentList: []},
			resolve: {
				group: function ($stateParams, groupList, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(groupList, $stateParams.id);
				},
				groupType: function (group, groupTypes) {
					'ngInject';
					return ((_find(groupTypes, function (type) {
						return _find(type.reservationBehaviours, function (behaviour) {
							return behaviour === group.data.reservationBehaviour;
						});
					})) || (_find(groupTypes, function (type) {
						return type.id === 'normal';
					})));
				},
				groupId: function ($stateParams) {
					'ngInject';
					return $stateParams.id;
				}

			}
		});

		// DETAILS
		$stateProvider.state('root.configure.group.edit.details', {
			controller: 'GroupDetailsController as vm',
			templateUrl: GroupDetailsTemplate,
			data: {
				ncyBreadcrumbLabel: 'General',
				access: {action: 'GET', resource: 'Group'}
			},
			resolve: {
				timeZones: function (TimeZoneService) {
					'ngInject';
					return TimeZoneService.query();
				},
				features: function (groupId, featuresList, VngageUtil) {
					'ngInject';
					return VngageUtil.getSectionResource(featuresList, groupId, 'groupId');
				}
			}
		});

		// DIALOG
		$stateProvider.state('root.configure.group.edit.dialog', {
			controller: 'GroupDialogController as vm',
			templateUrl: GroupDialogTemplate,
			data: {
				ncyBreadcrumbLabel: 'Dialog options',
				access: {action: 'GET', resource: 'Group'}
			}
		});

		// OPENING HOURS
		$stateProvider.state('root.configure.group.edit.openingHours', {
			controller: 'GroupOpeningHoursController as vm',
			templateUrl: GroupOpeningHoursTemplate,
			data: {
				ncyBreadcrumbLabel: 'Opening Hours',
				access: {action: 'GET', resource: 'Group'}
			}
		});

		// USERS
		$stateProvider.state('root.configure.group.edit.users', {
			controller: 'GroupUsersController as vm',
			templateUrl: GroupUsersTemplate,
			data: {
				ncyBreadcrumbLabel: 'Users',
				access: {action: 'GET', resource: 'Group'}
			},
			resolve: {
				userList: function (APIEndpointService, IdentityService) {
					'ngInject';
					return APIEndpointService.user.query({limit: 10000}).$promise.then(function (userList) {
						if (IdentityService.currentlyDelegated()) {
							return userList;
						} else {
							// Only return non-delegated users
							return userList.filter(function (user) {
								return user.data.type === 0;
							});
						}
					});
				}
			}
		});

		// CASES
		$stateProvider.state('root.configure.group.edit.cases', {
			controller: 'GroupCasesController as vm',
			templateUrl: GroupCasesTemplate,
			data: {
				ncyBreadcrumbLabel: 'Cases',
				access: {action: 'GET', resource: 'Group'}
			},
			resolve: {
				caseTypeList: function (APIConfigurationSectionService) {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.customerCase, true);
				}
			}
		});

		// FEATURES
		$stateProvider.state('root.configure.group.edit.features', {
			controller: 'GroupFeaturesController as vm',
			templateUrl: GroupFeaturesTemplate,
			data: {
				ncyBreadcrumbLabel: 'Features',
				access: {action: 'GET', resource: 'Group'}
			},
			resolve: {
				features: function (groupId, featuresList, VngageUtil) {
					'ngInject';
					return VngageUtil.getSectionResource(featuresList, groupId, 'groupId');
				}
			}
		});
	});
