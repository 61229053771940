import AppConstants from '../../../components/constants/AppConstants';

const Plugin = AppConstants.Plugin;

export default {

	toggleEnablePlugins() {
		return {
			type: Plugin.TOGGLE_PLUGINS_ENABLED,
		};
	},

	togglePlugin(pluginNr) {
		return {
			type: Plugin.TOGGLE_PLUGIN,
			pluginNr
		};
	},

	pluginReady(pluginId) {
		return {
			type: Plugin.PLUGIN_READY,
			pluginId
		};
	},

	addPlugins(plugins) {
		return {
			type: Plugin.ADD_PLUGINS,
			plugins
		};
	},

	pluginButtonClicked(conversationId, pluginId) {
		return {
			type: Plugin.PLUGIN_BUTTON_CLICKED,
			conversationId,
			pluginId
		};
	},

	setSectionButtonState(conversationId, pluginId, buttonState) {
		return {
			type: Plugin.SET_PLUGIN_BUTTON_STATE,
			conversationId,
			pluginId,
			buttonState
		};
	},

	setMeta(pluginId, meta) {
		return {
			type: Plugin.SET_META,
			pluginId,
			meta
		};
	},

	updatePluginMeta(metaUpdate) {
		return {
			type: Plugin.UPDATE_PLUGIN_META,
			metaUpdate
		};
	},

	setSelectedPlugin(pluginId) {
		return {
			type: Plugin.SET_SELECTED_PLUGIN,
			pluginId,
		};
	},

	notify(pluginId, text, showToast) {
		return {
			type: Plugin.NOTIFY,
			pluginId,
			text,
			showToast,
		};
	}
};

