import _each from 'lodash/each';
import _groupBy from 'lodash/groupBy';

export default function ($controller, $scope, APIStatisticsService, StatisticsFilterService, StatisticsDefaultsService) {
	'ngInject';

		var dialogUsage = this;
		dialogUsage.loading = false;
		dialogUsage.statistics = null;

		activate();

		function activate() {
			$controller('BaseDashboardController', {query: queryDialogUsage, $scope: $scope});
		}

		function queryDialogUsage() {
			dialogUsage.loading = true;

			var val = {period: 'day', range: 'thisMonth'};

			function factory() {
				return {
					numberOfDialogs: 0
				};
			}

			return APIStatisticsService.queryDialogUsage(StatisticsFilterService.create(val)).then(function (res) {

				var result = process(res.data);

				dialogUsage.statistics = StatisticsDefaultsService.assignMissingDayValues(result, factory);
				dialogUsage.loading = false;
			});
		}

		function process(data) {
			var result = [];
			var groups = _groupBy(data, function (d) { return d.calculatedAt; });
			_each(groups, function (g) {

				var totalNumberOfDialogs = g.reduce(function (numberOfDialogs, v) {
					return numberOfDialogs + v.numberOfDialogs;
				}, 0);

				result.push({calculatedAt: g[0].calculatedAt, numberOfDialogs: totalNumberOfDialogs});
			});

			return result;
		}
	};
