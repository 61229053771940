import vngageEditNavigationTemplate from './vngageEditNavigationTemplate';

const getId = (item) => {
	return item.data && item.data.id
		? item.data.id
		: item.id;
};

export default function ($state) {
	'ngInject';

	return {
		restrict: 'AE',
		replace: true,
		scope: {
			route: '=',
			backRoute: '=',
			item: '=',
			currentList: '=',
		},
		templateUrl: vngageEditNavigationTemplate,
		link: function (scope, element, attrs) {
			scope.show = scope.currentList && scope.currentList.length > 0;

			const itemId = getId(scope.item);
			scope.itemIndex = scope.currentList.findIndex(listItem => getId(listItem) === itemId);

			const backRoute = scope.backRoute || '^';

			scope.goBack = function () {
				$state.go(backRoute, {}, {
					reload: true,
					inherit: false,
					notify: true
				});
			};

			scope.goToItem = function (offset) {
				$state.go(attrs.route, {
					id: getId(scope.currentList[scope.itemIndex + offset]),
					currentList: scope.currentList
				}, {
					reload: false,
					inherit: false,
					notify: true
				});
			};
			const baseCls = 'back-navigation';
			scope.leftCls = `${baseCls}${scope.itemIndex === 0 ? ' disabled': ''}`;
			scope.rightCls = `${baseCls}${scope.itemIndex < scope.currentList.length - 1 ? '': ' disabled'}`;
		}
	};
}
