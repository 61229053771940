import {getItemNavigationDataAfterRemove} from '../../../../common/UiHelpers';

export default function (roleClaim, systemConfiguration, SystemRolesService, APIEndpointService, VngageUtil, $state, $stateParams) {
	'ngInject';

	var vm = this;
	vm.roleClaim = angular.copy(roleClaim);
	vm.submit = submit;
	vm.remove = remove;
	vm.currentList = $stateParams.currentList || [];

	function submit() {
		vm.isSaving = true;
		return APIEndpointService.systemConfRoleClaim.save({id: vm.roleClaim.id}, {data: vm.roleClaim}).$promise.then(function (updatedRoleClaim) {
			SystemRolesService.replaceRoleClaim(systemConfiguration.roleClaims, updatedRoleClaim).finally(() => {
				vm.isSaving = false;
				$state.transitionTo($state.current, {
					id: vm.roleClaim.id,
					roleClaim: updatedRoleClaim,
					currentList: vm.currentList
				});
			});
		}, err => {
			vm.isSaving = false;
			console.log('Error saving roleClaim (SystemRoleDetailsController)');
		});
	}

	function remove() {
		if (VngageUtil.confirmSimple('Are you sure you want to delete?')) {
			vm.isSaving = true;
			APIEndpointService.systemConfRoleClaim.remove({id: vm.roleClaim.id}).$promise
				.then(function () {
					vm.systemRoleEditForm.$setPristine();
					const itemNavigationData = getItemNavigationDataAfterRemove(vm.roleClaim, 'root.system.role.edit', vm.currentList, '^.^');
					vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
					// add roleClaim to params
					itemNavigationData.params = itemNavigationData.params
						? {
							...itemNavigationData.params,
							roleClaim: vm.currentList.find(role => role.id === itemNavigationData.params.id)
						}
						: null;
					$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
				})
				.catch(function () {
					vm.isSaving = false;
				});
		}
	}
}
