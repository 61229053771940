export default {
	'en-GB': {
		statistics: {
			menu: {
				HEADING: 'STATISTICS',
				EXPORT: 'Export'
				// Other sub menu items are dynamically generated from viewConfigs
			},
			RESET_FILTERS: 'Reset filters',
			FILTERS_HEADING: 'Filters',
			SORT: '(sort)',
			TIME: 'Time',
			TOTALS: 'Totals'
		}
	},
	'fr-FR': {
		statistics: {
			menu: {
				HEADING: 'STATISTIQUES',
				EXPORT: 'Exporter'
				// Other sub menu items are dynamically generated from viewConfigs
			},
			RESET_FILTERS: 'Réinitialiser les filtres',
			FILTERS_HEADING: 'Filtres',
			SORT: '(trier)',
			TIME: 'Heure',
			TOTALS: 'Totaux'
		}
	}
};
