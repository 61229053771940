import moment from 'moment';

export default {
	// get next interval for timestamp
	getNextInterval(delta, isMostRecentMessage) {
		// offset used to get to >60 instead of >=60
		const offset = 1;

		let nextInterval = 1;
		if (isMostRecentMessage) {
			if (delta < 45) {
				// seconds interval
				nextInterval = 1;
			} else if (delta >= 45 && delta < 60) {
				// remaining seconds until minute interval
				nextInterval = 60 - delta + offset;
			} else {
				// minutes interval
				nextInterval = 60;
			}
		} else {
			if (delta < 60) {
				// remaining seconds until minute interval
				nextInterval = 60 - delta + offset;
			} else {
				// minutes interval
				nextInterval = 60;
			}
		}
		return nextInterval;
	},

	asRelative(deltaSeconds) {
		if (deltaSeconds < 45) {
			return deltaSeconds + ' sec';
		} else {
			if (deltaSeconds < 60) {
				return '1 min';
			} else {
				return Math.floor(deltaSeconds / 60) + ' min';
			}
		}
	},
	asRelativeWithoutSeconds(deltaSeconds) {
		if (deltaSeconds < 45) {
			return '< 1 min';
		} else {
			if (deltaSeconds < 60) {
				return '1 min';
			} else {
				return Math.floor(deltaSeconds / 60) + ' min';
			}
		}
	},

	asNormal(timestamp) {
		return (moment(timestamp).format('MMMM DD, YYYY, h:mm A'));
	},

	asJustTime(timestamp) {
		return (moment(timestamp).format('h:mm A'));
	},

	asJustDate(timestamp) {
		return (moment(timestamp).format('MMMM DD, YYYY'));
	},


	asReservationTime(timeString, long) {
		let reservationTime = '';
		try {
			reservationTime = (long ? moment(timeString).format('D MMMM YYYY, HH:mm'): moment(timeString).format('HH:mm'));
		} catch (err) {
			reservationTime = timeString;

		}
		return reservationTime;
	}
}
