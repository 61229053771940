// Simple directive to circumvent AngularJS putting "unsafe:" in front of an URL starting with "data:"

import JSZip from 'jszip';
import _endsWith from 'lodash/endsWith';

export default function($timeout) {
	'ngInject';

	return {
		restrict: 'A',
		scope: {
			jsonData: '<vngageDebugInfoJsonData',
			fileName: '@download',
			callback: '&'
		},
		link: function($scope, element, attrs) {

			let blobUrl=null;

			$scope.$on("$destroy",
				function handleDestroyEvent() {
					if (blobUrl) {
						// Revoke the blob if exists to avoid memory leaks!
						URL.revokeObjectURL(blobUrl);
					}
				}
			);

			if (element[0].tagName === 'A') {
				let jsonFileName=$scope.fileName;
				if (_endsWith(jsonFileName,'.zip')) {
					jsonFileName=jsonFileName.substring(0,jsonFileName.length-4);
				}

				$timeout(() => {
					// Put this in a $timeout because JSON.stringify() and zip.generateAsync() below will take a few seconds with large data, and call $scope.$apply() when done...
					// While crunching, the UI will show "Generating download link. Please wait..."
					var zip = new JSZip();
					zip.file(jsonFileName, JSON.stringify($scope.jsonData, null, '\t'));
					zip.generateAsync({
						type: 'blob',
						compression: 'DEFLATE',
						compressionOptions: {level: 6}
					}).then(function (blob) {
						if (window.navigator.msSaveBlob) {
							// Internet Explorer
							element.on('click', function() {
								window.navigator.msSaveBlob(blob, jsonFileName+'.zip');
							});
						} else {
							blobUrl = URL.createObjectURL(blob, {type: 'application/zip', autoRevoke: false});	// New way: Unlimited(?) filesize. Does not work in IE 11 however...
							element[0].href = blobUrl;
						}
						$scope.callback();
						$scope.$apply(); // Make sure angular updates view after setting href
					});
				},0);
			}
		}
	}
}
