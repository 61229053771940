import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _keys from 'lodash/keys';

export default function ($q, $http, $translate, vngageConfig) {
	'ngInject';

    var systemTranslations;

    var service = {
        querySystemTranslations: querySystemTranslations,
        getAvailableLanguages: getAvailableLanguages
    };

    return service;

    function querySystemTranslations() {

		if (systemTranslations) {
			return $q(function(resolve) {
				resolve(systemTranslations);
			});
		}

		return $http.get(vngageConfig.staticWorkspaceUrl + 'visitor/translations.json', { skipAuthorization: true }).then(function(res) {
			systemTranslations = res;
			return res;
		});

    }

    function getAvailableLanguages(addedLanguages) {
        return $q(function(resolve) {
            querySystemTranslations().then(function(systemTranslations) {

                var promises = [];
                var result = [];
                var allLanguages = _keys(systemTranslations.data);
                var filteredLanguages = _filter(allLanguages, function(availableLanguage) {
                    return !_find(addedLanguages, function(resourceObj) {
                        return resourceObj.data.languageCode === availableLanguage;
                    });
                });

                filteredLanguages.forEach(function(languageCode) {
                    var dfd = $q.defer();
                    promises.push(dfd.promise);
                    $translate('languageCodes.' + languageCode).then(function(codeName) {
                        result.push({
                            code: languageCode,
                            name: codeName + ' (' + languageCode + ')'
                        });
                        dfd.resolve();
                    });
                });
                $q.all(promises).then(function() {
                    resolve(result);
                });

            });
        });
    }
};
