import OpportunityGeneratorItemTemplate from './OpportunityGeneratorItemTemplate';

export default function(OpportunityGeneratorService) {
    'ngInject';

    return {
        restrict: 'E',
        scope: {
            condition: '=',
            conditionDefault: '=',
            poiList: '='
        },
        templateUrl: OpportunityGeneratorItemTemplate,
        link: function(scope, elem) {

			elem.data('isolateScope', scope); // Set scope in element's data to allow unit-testing with $compileProvider.debugInfoEnabled(false)

            scope.tpl = OpportunityGeneratorService.getConditionValueTemplates(scope.poiList);

            // TODO why doesn't this trigger on the item on the first level after saving?
            scope.$watch('condition', function(condition) {
                OpportunityGeneratorService.initializeCondition(condition, scope.tpl);
            }, true);
        }
    };
};
