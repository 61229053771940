import React, {useState} from 'react';
import {connect} from 'react-redux';
import Group from '../../actionCreators/Group'
import {Table} from 'react-bootstrap';
import {useGetTranslation} from './../LangContext';

export const GroupStatus = (props) => {
	const getTranslation = useGetTranslation();
	const [groupIds, setGroupIds] = useState(props.myGroupAvailability.toJS());

	const setGroupAvailability = (groupId, value) => {
		const groupIdsObj = {...groupIds};
		groupIdsObj[groupId] = value;
		setGroupIds(groupIdsObj);
		props.setGroupAvailability(groupIdsObj);
	};
	const groups = props.groups.entrySeq().map(([groupId, group]) => {
		const groupStatus = props.myGroupAvailability.get(groupId);
		const disabledClass = groupStatus ? '': 'away';
		const cls = `switch pull-right`;
		return (
			<tr className={disabledClass} key={groupId}>
				<th>{group.get('name')}</th>
				<th>{group.get('numberOfVisitorsInQueue')}</th>
				<th>{group.get('numberOfOnlineUsers')}</th>
				<th>{group.get('numberOfVisitorsInDialog')}</th>
				<th>{groupStatus !== undefined ? <label className="flipswitch">
					<input
						type="checkbox"
						className="switch"
						defaultChecked={groupStatus}
						onChange={(e) => setGroupAvailability(groupId, e.target.checked)}
						ub-track="group-status-toggle"
					/>
					<span className={cls}/>
				</label>: ''}

				</th>
			</tr>
		)
	});

	return (
		<Table>
			<thead>
			<tr>
				<th>{getTranslation('groupNameHeader')}</th>
				<th>{getTranslation('queuedVisitorsHeader')}</th>
				<th>{getTranslation('activeAgentsHeader')}</th>
				<th>{getTranslation('activeDialogsHeader')}</th>
				<th>{getTranslation('agentPresenceHeader')}</th>
			</tr>
			</thead>
			<tbody>
			{groups}
			</tbody>
		</Table>
	)
};

function mapStateToProps(state) {
	return {
		myGroupAvailability: state.get('myGroupAvailability'),
		groups: state.get('groups'),
	}
}

function mapDispatchToProps(dispatch) {
	return {
		// Action Creators
		setGroupAvailability: (groupIdsObj) => dispatch(Group.setGroupAvailabilityDebounced(groupIdsObj)),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GroupStatus)

