export default function (APIEndpointService, accountList, $uibModalInstance) {
    'ngInject';

    var modal = this;
    modal.newAccount = null;
    modal.selectedTemplateAccount = null;
    modal.isSaving = false;
    modal.accounts = accountList;
    modal.save = save;
    modal.cancel = $uibModalInstance.dismiss;

    function save() {
        modal.isSaving = true;
		$uibModalInstance.freeze(true);

        if (modal.selectedTemplateAccount) {
            APIEndpointService.account.create({ copyFromAccountId: modal.selectedTemplateAccount.data.id }, modal.newAccount).$promise
                .then(function(res) {
					$uibModalInstance.close(res);
                })
                .catch(function() {
					$uibModalInstance.freeze(false);
                    vm.isSaving = false;
                });
        } else {
            APIEndpointService.account.create(modal.newAccount).$promise
                .then(function(res) {
					$uibModalInstance.close(res);
                })
                .catch(function() {
					$uibModalInstance.freeze(false);
                    vm.isSaving = false;
                });
        }
    }
};
