import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function (APIEndpointService, $stateParams, $state, pointOfInterest, VngageUtil, $scope, vngageRemoveModalService) {
	'ngInject';

	var vm = this;
	vm.pointOfInterestId = $stateParams.id;
	vm.pointOfInterest = pointOfInterest;
	vm.submit = submit;
	vm.remove = remove;
	vm.cancel = cancel;
	vm.currentList = $stateParams.currentList || [];

	$scope.$on('resetResettables', () => {
		vm.poiForm.$setPristine();
		pointOfInterest.$get().catch(() => {
		});
	});

	function submit() {
		vm.isSaving = true;
		pointOfInterest.$save().finally(function () {
			vm.isSaving = false;
		});
	}

	function cancel() {
		$state.go('^');
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: pointOfInterest,
			itemType: 'pointOfInterest'
		}).then(function () {
			vm.poiForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(pointOfInterest, 'root.analyze.pointOfInterest.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(function () {
			vm.isSaving = false;
		});
	}
}
