var stateMachine = function (params, events) {
	/*
	 * ---------------------------------------------
	 * Possible states
	 * ---------------------------------------------
	 */
	var states = params.states;
	//this.states = states;
	/*
	 * ---------------------------------------------
	 * Current state
	 * ---------------------------------------------
	 */
	var root = this;
	var currentState = false;
	this.currentState = currentState;
	var setCurrentState = function (state) {
		if (currentState == state) return false;
		currentState = state;
		root.currentState = state;
		//run entry function
		//for (var a in states[currentState].entry) {
		for (var a = 0; a < states[currentState].entry.length; a++) {
			var func = states[currentState].entry[a];
			try {
				func();
			} catch (err) {
				//console.error('psPlugin.core.stateMachine: enter function failed! See error below', err);
			}
		}
		// set new available transitions
		setStateTransitions();
	};

	/*
	 * ---------------------------------------------
	 * Change state
	 * ---------------------------------------------
	 */
	this.setCurrentState = setCurrentState;
	var changeState = function (state) {
		//console.log('psPlugin.core.stateMachine - change state to: ' + state);
		if (currentState == state) return false;
		// run exit functions
		//for (var a in states[currentState].exit) {
		for (var a = 0; a < states[currentState].exit.length; a++) {
			var func = states[currentState].exit[a];
			try {
				func();
			} catch (err) {
				//console.error('psPlugin.core.stateMachine: exit function on failed! See error below',err);
			}
		}
		setCurrentState(state);
	};
	this.changeState = changeState;
	/*
	 * ---------------------------------------------
	 * Transitions
	 * ---------------------------------------------
	 */
	var allowedTransitions = {};


	var setStateTransitions = function () {
		for (var t in allowedTransitions) {
			if (!psPlugin.core.jsExt.isFunction(t)) {
				//console.log('Allowed transition: ' + t);
				allowedTransitions[t].close();
				allowedTransitions[t] = {
					test: function () {
						return false;
					},
					run: function () {
						return false;
					},
					close: function () {
						return false;
					}
				};
			}
		}
		for (var t in states[currentState].transitions) {
			if (!psPlugin.core.jsExt.isFunction(t)) {
				var transition = states[currentState].transitions[t];
				allowedTransitions[t] = new createTransition(events, root, t, transition);
			}
		}
		for (var t in allowedTransitions) {
			if (!psPlugin.core.jsExt.isFunction(t)) { root[t] = allowedTransitions[t]; }
		}
		//console.log('State machine - CurrentState: "' + currentState + '"', root);
	};
	/*
	 * ---------------------------------------------
	 * Run onStart functions
	 * ---------------------------------------------
	 */
	var runOnstart = function () {
		//for (var f in params.onStart) {
		for (var f = 0; f < params.onStart.length; f++) {
			params.onStart[f]();
		}
	};
	/*
	 * ---------------------------------------------
	 * Start the stateMachine
	 * ---------------------------------------------
	 */
	var start = function () {
		runOnstart();
		if (!currentState) {
			setCurrentState(params.initialState);
		}
		//remove this from scope after it's started
		delete root.start;
	};
	this.start = start;
	//console.log('State machine started!');
};
/*
 * -----------------------------------------------------------
 * transition
 * -----------------------------------------------------------
 */
var createTransition = function (events, root, name, transition) {
	var guid = psPlugin.shell.jsExt.guid();
	/*
	 * ---------------------------------------------
	 * Trigger for the actual transition
	 * ---------------------------------------------
	 */
	//console.log(transition.trigger);
	if (typeof transition.trigger != 'undefined' && transition.trigger !== '') {
		events[transition.trigger].subscribe(guid, function () {
			if (root[name].run()) {
				setTimeout(function () {
					root.changeState(transition.nextState);
				}, 100);
				return true;
			}
		});
	}
	/*
	 * ---------------------------------------------
	 * Test if transition can run
	 * ---------------------------------------------
	 */
	var test = function () {
		//for (var a in transition.conditions) {
		for (var a = 0; a < transition.conditions.length; a++) {
			var func = transition.conditions[a];
			if (typeof func == 'string') return;
			if (!func()) {

				//console.error('psPlugin.core.stateMachine: Transition condition for: "' + name + '" failed!', err);
				return false;
			}
		}
		return true;
	};
	this.test = test;
	/*
		 * ---------------------------------------------
		 * run the transition
		 * ---------------------------------------------
		 */
	this.run = function () {
		if (!test()) return false;
		// run transition actions
		//for (var a in transition.actions) {
		for (var a = 0; a < transition.actions.length; a++) {
			var func = transition.actions[a];
			try {
				func();
			} catch (err) {
				//console.error('psPlugin.core.stateMachine: actions function on transition: "' + name + '" failed! See error below', err);
			}
		}
		return true;
	};
	var close = function () {
		try {
			events[transition.trigger].unsubscribe(guid);
		} catch (err) {

		}
	};
	this.close = close;
};

export default stateMachine;
