export default {
	'en-GB': {
		statistics: {
			views: {
				devicecategories: {
					MENU_LABEL: 'Device categories',
					TITLE: 'Device categories',
					TABLE_HEADING: 'Hour report',
					TIME_LINE_GRAPH_HEADING: 'Visits by hour',
					measures: {
						siteName: {
							NAME: 'Site',
							TITLE: 'The name of the site presenting the chat option',
							LONG_DESCRIPTION: ''
						},
						deviceCategory: {
							NAME: 'Device category',
							TITLE: 'The type of device the visitor is browsing from',
							LONG_DESCRIPTION: ''
						},
						dimensionName: {
							NAME: 'Device category (Site)',
							TITLE: '',
							LONG_DESCRIPTION: ''
						},
						numberOfUniqueVisitors: {
							NAME: 'Visitors',
							TITLE: 'Number of unique visitors (Unique per hour)',
							LONG_DESCRIPTION: ''
						},
						numberOfUniqueVisits: {
							NAME: 'Visits',
							TITLE: 'Number of visits',
							LONG_DESCRIPTION: ''
						},
						numberOfQueueAdded: {
							NAME: 'Requests',
							TITLE: 'Number of queued assistance requests',
							LONG_DESCRIPTION: ''
						},
						numberOfQueueDialogs: {
							NAME: 'Dialogs',
							TITLE: 'Number of dialogs with customer service',
							LONG_DESCRIPTION: ''
						},
						numberOfCasesClosed: {
							NAME: 'Cases closed',
							TITLE: 'Number of cases closed',
							LONG_DESCRIPTION: ''
						},
						numberOfPurchaseConfirmations: {
							NAME: 'Purchases',
							TITLE: 'Number of purchases - Percent of visitors who purchased',
							LONG_DESCRIPTION: ''
						},
						numberOfRelatedAssistedPurchasePois_sameVisit: {
							NAME: 'Ass. Pur. (same visit)',
							TITLE: 'Number of purchases where the visitor received assistance during the same visit - Percent of cases related to purchases',
							LONG_DESCRIPTION: ''
						},
						numberOfRelatedAssistedPurchasePois_6Hours: {
							NAME: 'Ass. Pur. (6hours)',
							TITLE: 'Number of purchases where the visitor received assistance within 6hours - Percent of cases related to purchases',
							LONG_DESCRIPTION: ''
						}
					},
					barFilters: {
						siteName: {
							HEADING: 'Visits per site'
						},
						deviceCategory: {
							HEADING: 'Visits per device category'
						}
					}
				}
			}
		}
	},
	'fr-FR': {
		statistics: {
			views: {
				devicecategories: {
					MENU_LABEL: 'Catégories d\'appareils',
					TITLE: 'Catégories d\'appareils',
					TABLE_HEADING: 'Rapport horaire',
					TIME_LINE_GRAPH_HEADING: 'Visites par heure',
					measures: {
						siteName: {
							NAME: 'Site',
							TITLE: 'Nom du site présentant l\'option de chat',
							LONG_DESCRIPTION: ''
						},
						deviceCategory: {
							NAME: 'Catégorie d\'appareil',
							TITLE: 'Type d\'appareil utilisé par le visiteur',
							LONG_DESCRIPTION: ''
						},
						dimensionName: {
							NAME: 'Catégorie d\'appareil (site)',
							TITLE: '',
							LONG_DESCRIPTION: ''
						},
						numberOfUniqueVisitors: {
							NAME: 'Visiteurs',
							TITLE: 'Nombre de visiteurs uniques (par heure)',
							LONG_DESCRIPTION: ''
						},
						numberOfUniqueVisits: {
							NAME: 'Visites',
							TITLE: 'Nombre de visites',
							LONG_DESCRIPTION: ''
						},
						numberOfQueueAdded: {
							NAME: 'Demandes',
							TITLE: 'Nombre de demandes d\'assistance en attente',
							LONG_DESCRIPTION: ''
						},
						numberOfQueueDialogs: {
							NAME: 'Dialogues',
							TITLE: 'Nombre de dialogues avec le service clients',
							LONG_DESCRIPTION: ''
						},
						numberOfCasesClosed: {
							NAME: 'Dossiers clôturés',
							TITLE: 'Nombre de dossiers clôturés',
							LONG_DESCRIPTION: ''
						},
						numberOfPurchaseConfirmations: {
							NAME: 'Achats',
							TITLE: 'Nombre d\'achats - Pourcentage de visiteurs ayant effectué un achat',
							LONG_DESCRIPTION: ''
						},
						numberOfRelatedAssistedPurchasePois_sameVisit: {
							NAME: 'Ach. Aid. (Même visite)',
							TITLE: 'Nombre d\'achats pour lesquels le visiteur a reçu de l\'aide au cours de la même visite - Pourcentage de dossiers liés à des achats',
							LONG_DESCRIPTION: ''
						},
						numberOfRelatedAssistedPurchasePois_6Hours: {
							NAME: 'Ach. Aid. (6 heures)',
							TITLE: 'Nombre d\'achats pour lesquels le visiteur a reçu de l\'aide dans les 6 heures - Pourcentage des dossiers liés à des achats',
							LONG_DESCRIPTION: ''
						}
					},
					barFilters: {
						siteName: {
							HEADING: 'Visites par site'
						},
						deviceCategory: {
							HEADING: 'Visites par catégorie d\'appareil'
						}
					}
				}
			}
		}
	}
};
