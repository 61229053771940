import React from 'react';
import createReactClass from 'create-react-class';
import EventStore from  '../stores/EventStore';
import ParticipantStore from  '../stores/ParticipantStore';
import ConversationsStore from  '../stores/ConversationsStore';
import ViewActions from  '../actions/ViewActions';
import VisualGuidanceIframe from  './VisualGuidanceIframe.react';
import VisualGuidanceStatusBar from  './VisualGuidanceStatusBar.react';
import VisualGuidanceToolbar from  './VisualGuidanceToolbar.react';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';

export default createReactClass({

	getInitialState () {
		return getStateFromStores(this.props.conversationId);
	},

	handleResize: _debounce(function(){
		this.setState({
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight
		});
	}, 500),

	componentDidMount () {
		window.addEventListener('resize', this.handleResize);
		EventStore.addChangeListener(this._onChange);
		ViewActions.activateVisualGuidanceChannel(this.props.conversationId);
	},

	componentWillUnmount () {
		window.removeEventListener('resize', this.handleResize);
		EventStore.removeChangeListener(this._onChange);
		ViewActions.deactivateVisualGuidanceChannel(this.props.conversationId);
	},

	_onChange () {
		this.setState(getStateFromStores(this.props.conversationId));
	},

	render: function() {
		if (!this.state.status) return false;

		return (
			<div className="vg-wrapper-thingy">
				<VisualGuidanceToolbar
					conversationId={this.props.conversationId}
					paused={this.state.paused}
					highlightMode={this.state.highlightMode}
					guestAuthorized={this.state.guestAuthorized}
					guestPresent={this.state.guestPresent}
					useDomCoBrowsing={this.state.useDomCoBrowsing}
					/>
				<div className="vg-legacy-container visual-guidance-container">
					<VisualGuidanceStatusBar
						status={this.state.status}
						visitorIsAt={this.state.visitorIsAt}
						show={this.state.displayStatusBar}
						askingForDOMUpload={this.state.askingForDOMUpload}
						domUploadStatus={this.state.domUploadStatus}
						/>

					<VisualGuidanceIframe
						url={this.state.url}
						width={this.state.browserAttributes.width}
						height={this.state.browserAttributes.height}
						windowHeight = {this.state.windowHeight}
						windowWidth = {this.state.windowWidth}
						isScaled = {this.state.isScaled}
						/>
				</div>
			</div>
		);
	}

});

function getStateFromStores(conversationId) {
	let visitorCurrentUrl,
		vgState,
		guestWithVfAuth,
		guestPresent,
		visitor,
		conversation = ConversationsStore.find(conversationId);

	if (!conversation || conversation && !conversation.visualGuidance) return {};

	visitor = ParticipantStore.getVisitorParticipantOfConversation(conversationId);
	guestPresent = ParticipantStore.isGuestPresentInConversation(conversationId);
	guestWithVfAuth = ParticipantStore.guestHasVisualGuidanceAuthoriation(conversationId);

	visitorCurrentUrl = _get(visitor, 'currentNavigation.originalSource', false);
	vgState = conversation.visualGuidance.getState();

	return {
		status: _get(vgState, 'status', 'disabled'),
		displayStatusBar : _get(vgState, 'displayStatusBar', true),
		highlightMode: _get(vgState ,'highlightMode', false),
		url: _get(vgState, 'url', ''),
		visitorIsAt: visitorCurrentUrl || _get(visitor, 'currentNavigation.url', ''),
		isScaled: _get(vgState, 'isScaled', false),
		paused: _get(vgState, 'paused', false),
		guestAuthorized: guestWithVfAuth || _get(vgState, 'guestAuthorized', false),
		guestPresent: !!guestPresent,
		browserAttributes: _get(vgState, 'browserAttributes', {height:0, width:0}),
		useDomCoBrowsing: _get(conversation.visualGuidance, 'useDomCoBrowsing', false),
		askingForDOMUpload : _get(vgState, 'askingForDOMUpload', false),
		domUploadStatus: _get(vgState, 'domUploadStatus', 'done'),
		sendPageUrl: _get(vgState, 'sendPageUrl', ''),
		sendPageTitle: _get(vgState, 'sendPageTitle', '')
	};

}
