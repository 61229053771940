import reservationModalTemplate from './reservationModalTemplate';

export default function($uibModal) {
	'ngInject';

	return {
		open: function(options) {
			return $uibModal.open({
				backdrop: 'static',
				templateUrl: reservationModalTemplate,
				controller: 'reservationModalController as vm',
				resolve: {
					options: function() {
						return options;
					}
				}
			}).result;
		}
	};
};
