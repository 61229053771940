import vngageAddModalTemplate from '../../../common/vngageAddModalTemplate';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

export default function ($state, $uibModal, dataLists, AccountActionService) {
	'ngInject';

	const vm = this;
	const actionPanelList = dataLists.actionPanel;
	vm.actionList = dataLists.actions;

	vm.tableColumns = [{
		header: 'action.NAME',
		property: 'data.name',
		sortDefault: true,
		useInFilter: true
	}, {
		header: 'GUID',
		property: 'data.id',
		hidden: true,
		useInFilter: true
	}, {
		header: 'action.ACTION_TYPE',
		property: 'data.section.itemType',
		width: '28%',
		transform: value => {
			return AccountActionService.getActionTypes()[value];
		},
		translate: true
	}, {
		header: 'Action Panels',
		property: 'data.id',
		useInFilter: true,
		transform: getActionPanelName,
		type: 'label-list'
	},];
	vm.tableOptions = {
		stateLink: {
			state: 'root.configure.action.edit',
			property: 'data.id'
		},
		createItem: {
			langKey: 'action.ADD_HEADER',
			method: addNew
		}
	};

	function openAddModal() {
		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: vngageAddModalTemplate,
			controller: 'ActionAddModalController as vm',
			resolve: {
				options: () => {
					return {
						header: 'action.ADD_HEADER',
						label: 'action.NAME'
					};
				}
			}
		});
		modal.result.then(newAction => {
			vm.actionList.push(newAction);
			$state.go('.edit', {id: newAction.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		// If the state transition to the parent state is ok, then open modal
		$state.go('root.configure.action').then(openAddModal);
	}

	function getActionPanelName(actionGuid) {
		const resource = _map(_filter(actionPanelList, actionPanel => {
			return _find(actionPanel.data.section.actions, actions => {
				return actionGuid === actions;
			});
			return actionGuid === actionPanel.data;
		}), res => {
			return res.data.name;
		});
		return resource;
	}
};
