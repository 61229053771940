import React, {useState} from 'react';
import {
	Modal,
	Nav
} from 'react-bootstrap';
import PollDurations from './poll/PollDurations.react';
import PollIntervals from './poll/PollIntervals.react';

const DebugPollStatsModal = (props) => {
	const [selectedTab, setSelectedTab] = useState('durations');
	const {data, moveToAbsolutePosition} = props;

	const handleSelect = (eventKey) => setSelectedTab(eventKey);
	const goToPos = (index) => moveToAbsolutePosition(index);

	const payload = {
		data,
		goToPos
	};

	const tabContent = selectedTab === 'durations'
		? <PollDurations {...payload}/>
		: <PollIntervals {...payload}/>;


	return (
		<Modal className="case-manager-section-modal" show={true} size="lg"
			   onHide={() => props.hideModal()}>
			<Modal.Header closeButton>
				<Modal.Title>Poll stats</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Nav variant="pills" activeKey={selectedTab} onSelect={handleSelect}>
					<Nav.Item size="xs">
						<Nav.Link eventKey="durations">
							durations
						</Nav.Link>
					</Nav.Item>
					<Nav.Item size="xs">
						<Nav.Link eventKey="intervals">
							intervals
						</Nav.Link>
					</Nav.Item>
				</Nav>
				{tabContent}
			</Modal.Body>
		</Modal>
	);
};

export default DebugPollStatsModal;


