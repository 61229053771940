import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _reject from 'lodash/reject';
import _find from 'lodash/find';
import _omit from 'lodash/omit';

import assign from 'object-assign';
import CSDispatcher from '../dispatcher/CSDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import WebAPIUtils from '../utils/WebAPIUtils'
import MessageUtils from '../../../components/utils/MessageUtils';
import ParticipantUtils from '../../../components/utils/ParticipantUtils';
import VisualGuidance from '../utils/VisualGuidance';

import ConversationsStore from './ConversationsStore';
import ParticipantStore from './ParticipantStore';
import ProfileStore from './ProfileStore';
import StoreFactory from '../../../components/utils/StoreFactory';

import AccountConfiguration from './../../../common/AccountConfiguration';
import AlertService from '../legacy/AlertService';

const ActionTypes = AppConstants.ActionTypes;

var state = {
	messages: [],
	events: {},
	groups: []
};

var EventStore = StoreFactory.create({

	/**
	 * return conversation by ref
	 * @param conversationRef
	 */
	getConversationByRef (conversationRef) {
		let c = ConversationsStore.findByRef(conversationRef);
		return c || {};
	},


	/**
	 * returns any reservation (if exists) mapped this conversation
	 * @param {String} conversationId
	 * @returns {Object} reservation object
	 */
	getReservationOfConversation (conversationId) {
		let conversation = ConversationsStore.find(conversationId);
		if (!conversation) {
			return {};
		}

		let conversationReservation = _find(conversation.events, event => {
			return (event.type === 'conversationReservation');
		});


		if (conversationReservation) {
			return conversationReservation;
		} else {
			return {};
		}
	},

	/**
	 * returns messages mapped with speaker of conversation
	 * @param {String} conversationId
	 * @returns {Array} messages filtered array
	 */
	getMappedMessagesOfConversation (conversationId) {
		let conversation = ConversationsStore.find(conversationId);
		if (!conversation) {
			return [];
		}

		let conversationMsgs = _filter(state.messages, msg => {
			return msg.conversationId === conversationId;
		});

		let messageTypes = ['chat', 'link', 'meta', 'note'];
		let metaTypes = ['navigation'];

		const USE_WEBRTC = AccountConfiguration.configuration.account.useWebRTC;
		if (USE_WEBRTC) {
			messageTypes.push('video');
		}

		//if (conversation.showMeta) {
		//	messageTypes.push(...metaTypes);
		//}

		conversationMsgs = _filter(conversationMsgs, function(msg) {
			return messageTypes.indexOf(msg.messageType) > -1;
		});

		conversationMsgs = conversationMsgs.map( (msg) => {
			if (msg.speaker && !msg.speaker.info) {
				const speakerId = msg.speaker.userId || msg.speaker.visitId;
				msg.speaker = ParticipantStore.getParticipantOfConversationById(conversationId, speakerId);
			}
			return msg;
		});

		return MessageUtils.sortByDate(conversationMsgs).map( (msg, index) => {
			msg.isMostRecentMessage = index === conversationMsgs.length - 1;
			return msg;
		});
	},

	getGroupStatus () {
		return state.groups;
	}

});

const registeredCallback = function(payload) {
	const action = payload.action;
	const { conversationId, participant } = action;
	const conversation = ConversationsStore.find(conversationId);
	const MY_ID = ProfileStore.getProfileId();
	const ME = ParticipantStore.getParticipantMe();

	let conversationMsgs = [],
		msgInConversation = [],
		message = action.message,
		mappedMessages,
		mappedProfile;

	switch (action.type) {

		case ActionTypes.CREATE_VIDEO_INVITATION :
		case ActionTypes.CREATE_NOTE :
		case ActionTypes.CREATE_MESSAGE :
			message = MessageUtils.addProfileToMsg(message, ME);
			message = MessageUtils.addConversationIdToMsg(message, conversationId);
			state.messages.push(message);
			break;

		case ActionTypes.RECEIVE_AS_STATE :
			CSDispatcher.waitFor([ParticipantStore.dispatchToken]);

			mappedMessages = MessageUtils.mapConversationMessages(action.conversationState.messages, conversationId);
			_addMessages(mappedMessages, false);
			break;

		case ActionTypes.CONVERSATION_IDLE:
			if (!action.idle) {
				state.messages = state.messages.map( msg => {
					msg.isRead = true;
					return msg;
				});
			}
			break;

		case ActionTypes.CONVERSATION_IDLE:
			if (!action.idle) {
				state.messages = state.messages.map( msg => {
					msg.isRead = true;
					return msg;
				});
			}
			break;

		case ActionTypes.RECEIVE_SINCE :
			//This here? AppDispatcher.waitFor([ParticipantStore.dispatchToken]);
			let didUpdateOptimisticMsg = false;

			msgInConversation = MessageUtils.addConversationIdToMsg(action.rawMessages, conversationId);
			conversationMsgs = MessageUtils.getConversationMessages(msgInConversation);

			let msgsWithoutMe;
			if (!MessageUtils.conversationHasChatMessages(conversationId, state.messages)) {
				msgsWithoutMe = conversationMsgs;
			} else {
				msgsWithoutMe = MessageUtils.getChatMessagesWithoutMe(conversationMsgs, MY_ID);
			}
			let isIdle = ConversationsStore.isConversationIdle(conversationId);
			_addMessages(msgsWithoutMe, true, !isIdle);

			// map over incoming messages and check if a message has a conversationTag
			// (aka response of optimistic insert by agent)
			conversationMsgs = conversationMsgs.map( message => {
				if (MessageUtils.hasTagOfConversation(conversationId, message, MY_ID)) {
					state.messages = state.messages.map( msg => {
						// TODO: this assumes it's the only tag... needs refactoring
						if (msg.tags && msg.tags[0] === message.tags[0]) {
							didUpdateOptimisticMsg = true;
							return assign({}, msg, MessageUtils.convertRawMessage(message));
						} else {
							return msg;
						}
					});
				}
				return message;
			});

			if (!msgsWithoutMe.length && !didUpdateOptimisticMsg) {
				return false;
			}

			break;

		case ActionTypes.PARTICIPANT_JOINED :
			CSDispatcher.waitFor([ParticipantStore.dispatchToken]);

			let joinedFiltered = participant.filter( p => {
				let conversationEvents = state.events[conversationId];
				let processed = conversationEvents && conversationEvents.indexOf(p.eventId) > -1;
				if (!processed) {
					state.events[conversationId] = state.events[conversationId] || [];
					state.events[conversationId].push(p.eventId);
				}
				return !processed;
			});

			let joinedParticipants = joinedFiltered.map(ParticipantUtils.getParticipantEntityId);

			joinedParticipants.map( (participantId, index) => {
				mappedProfile = ParticipantStore.getParticipantOfConversationById(conversationId, participantId);
				message = MessageUtils.getMetaMessageData({action: 'participantJoined'}, mappedProfile );
				message = MessageUtils.addConversationIdToMsg(message, conversationId);

				if (participantId !== MY_ID) {
					state.messages.push(message); // addMessage(message);
				}

			});
			break;

		case ActionTypes.PARTICIPANT_LEFT :
			CSDispatcher.waitFor([ParticipantStore.dispatchToken]);

			let leftFiltered = participant.filter( p => {
				var conversationEvents = state.events[conversationId];
				let processed = conversationEvents && conversationEvents.indexOf(p.eventId) > -1;
				if (!processed) {
					state.events[conversationId] = state.events[conversationId] || [];
					state.events[conversationId].push(p.eventId);
				}
				return !processed;
			});

			leftFiltered.map(participant => {
				let participantLeftId = ParticipantUtils.getParticipantEntityId(participant);
				mappedProfile = ParticipantStore.getParticipantOfConversationById(conversationId, participantLeftId);
				message = MessageUtils.getMetaMessageData({action: 'participantLeft'}, mappedProfile );
				message = MessageUtils.addConversationIdToMsg(message, conversationId);

				if (participantLeftId !== MY_ID) {
					state.messages.push(message); // addMessage(message);
				}
			});
			break;

		case ActionTypes.CASE_CLOSED :
			state.messages = _reject(state.messages, p => p.conversationId === conversationId);
			break;

		case ActionTypes.TOGGLE_VISUAL_GUIDANCE_SCALE :
			conversation.visualGuidance.setScaled(action.isScaled);
			break;

		case ActionTypes.VISUAL_GUIDANCE :
			if (!conversation || !conversation.visualGuidance) {
				return false;
			}

			switch (action.action) {
				case 'sendHighlight' :
					conversation.visualGuidance.sendHighlight(action.data);
					break;

				case 'toggleHighlight' :
					conversation.visualGuidance.toggleHighlight(action.isActive);
					break;

				case 'togglePause' :
					conversation.visualGuidance.togglePause(action.isPaused);
					break;

				case 'displayStatusBar' :
					conversation.visualGuidance.toggleStatusBar(action.show);
					break;

				case 'authorizeGuest' :
					conversation.visualGuidance.toggleGuestAuthorized(action.value);
					break;
				default :
					return false;
			}
			break;

		case ActionTypes.VISITOR_RESIZE :
			if (conversation && conversation.visualGuidance) {
				conversation.visualGuidance.setState(assign({ browserAttributes: action.browserInfo }));
			} else {
				return false;
			}
			break;

		case ActionTypes.COBROWSER_DOMUPLOAD :
			if (conversation && conversation.visualGuidance) {
				conversation.visualGuidance.askingForDOMUpload(action.reqState);
			} else {
				return false;
			}

			break;

		case ActionTypes.GROUP_STATUS_UPDATED :
			state.groups = action.availableGroups;
			break;

		default:
			return false;
	}


	// if we made down here... emit change event
	EventStore.emitChange();
};

EventStore.dispatchToken = CSDispatcher.register( registeredCallback );


// adds message to state.messages
function addMessage(message, fromSince) {
	if (MessageUtils.getById(state.messages, message.id)) {
		return false;
	}

	state.messages.push(MessageUtils.convertRawMessage(message));

	//Go tell the world about this new message we've received
	if (fromSince && message.messageType === 'chat') {
		AlertService.incomingMessage(message);
	}
}


// loops through rawMessages and calls method for each message
function _addMessages(rawMessages, fromSince, markAsRead) {
	if(Array.isArray(rawMessages)) {
		rawMessages.map(function(msg){
			msg.isRead = markAsRead || !fromSince;
			addMessage(msg, fromSince);
		});
	} else {
		rawMessages.isRead = markAsRead || !fromSince;
		addMessage(rawMessages, fromSince);
	}
}

export default EventStore;
