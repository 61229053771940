import React from 'react';
import vngageConfig from '../../../vngageConfig'

export default function ContentPreview({blobUrl, uploadInfo}) {
	const mime = uploadInfo && uploadInfo.mimeType && uploadInfo.mimeType.split && uploadInfo.mimeType.split('/') || [''];
	let previewElement;
	switch (mime[0]) {
		case 'image':
			previewElement = <img className="content-upload-image" src={blobUrl}/>;
			break;
		case 'video':
			previewElement = <video className="content-upload-image" controls autoPlay><source src={blobUrl} type={uploadInfo.mimeType}/></video>;
			break;
		default:
			previewElement = <img className="content-upload-image" src={vngageConfig.staticWorkspaceUrl + 'img/genericMimeType.svg'}/>;
	}

	return (
		<div className="content-upload-image-container">
			{previewElement}
		</div>
	)
}

