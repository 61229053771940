import React from 'react';
import ReactDOM from 'react-dom';
import ViewActions from  '../actions/ViewActions';
import { injectIntl } from 'react-intl';
import createReactClass from 'create-react-class';

export default injectIntl(createReactClass({

	getDefaultProps () {
		return {
			isScaled: true,
			browserAttributes: {
				width:980,
				height:500,
				hasFlash:true
			}
		};
	},

	componentDidMount () {
		this.domScaleSwitch = ReactDOM.findDOMNode(this.refs.scaleSwitch);
		this.domBrowserWrap = ReactDOM.findDOMNode(this.refs.browserWrap);
		//this.props.conversation.visualGuidance.getChannel();
		ViewActions.activateVisualGuidanceChannel(this.props.conversationId);
	},

	componentWillUnmount () {
		//this.props.conversation.visualGuidance.getChannel().destroy();
		ViewActions.deactivateVisualGuidanceChannel(this.props.conversationId);
	},

	toggleScale () {
		ViewActions.toggleVisualGuidanceScale(this.props.conversationId, this.domScaleSwitch.checked);
	},

	toggleHighlight () {
		//console.log('toggleHighlight');
	},

	calcScale () {
		let scale = 1;
		if (this.props.isScaled && this.domBrowserWrap && this.domBrowserWrap.offsetWidth < this.props.width) {
			scale = this.domBrowserWrap.offsetWidth / this.props.width;
		}

		return scale;
	},

	iframeStyle () {
		let scale = this.calcScale();
		let height = (this.domBrowserWrap) ? this.domBrowserWrap.offsetHeight : this.props.height;
		return {
			width: this.props.width + 'px',
			height: Math.floor(height / scale) + 'px',
			MozTransform:'scale(' + scale + ')',
			WebkitTransform:'scale(' + scale + ')',
			msTransform:'scale(' + scale + ')',
			transform:'scale(' + scale + ')'
		};
	},

	continueBrowse () {
		let overlayElem = ReactDOM.findDOMNode(this.refs.browserWrap);
			overlayElem.className = 'vg-iframe-wrap';
	},

	render: function() {
		let cls = `vg-metabar-inner ${this.props.status}`;
		let vgTitle;
		let statusMsg;
		let visitorURL;
		let displayRestricted;

		switch (this.props.status) {
			case 'paused':
				statusMsg = this.props.intl.formatMessage({id: 'vgGuestStatusDisabled'});
				vgTitle = this.props.intl.formatMessage({id: 'vgCoBrowse'});
				displayRestricted = false;
				break;

			case 'active':
				statusMsg = this.props.intl.formatMessage({id: 'vgGuestStatusActive'});
				vgTitle = this.props.intl.formatMessage({id: 'vgCoBrowse'});
				displayRestricted = false;
				break;

			case 'passive':
				vgTitle = this.props.intl.formatMessage({id: 'vgRestricted'});
				visitorURL = this.props.url;
				displayRestricted = true;
				break;
		}

		let iframeClass = displayRestricted ? 'vg-iframe-wrap display' : 'vg-iframe-wrap';

		return (
			<div className="visual-guidance-container">
				<div className="vg-metabar">
					<div className={cls}>
						<div className="vg-meta-info">
							<span className="vg-metatitle">{vgTitle}</span>
							<div className="vg-status">
								<span title={visitorURL}> {statusMsg} {visitorURL}</span>
							</div>
						</div>
						<div className="vg-actions">
							<label className="flipswitch">
								<span className="flipswitch-label">{this.props.intl.formatMessage({id: 'vgCoBrowseScale'})}</span>
								<input onChange={this.toggleScale} type="checkbox" ref="scaleSwitch" className="switch" defaultChecked={this.props.isScaled} />
								<span className="switch"></span>
							</label>
						</div>
					</div>

				</div>
 				<div ref="browserWrap" className={iframeClass}>
					<div className="restricted-backdrop">
						<div className="restricted-container">
							<i className="vngage-icon-lock"></i>
							<p>{this.props.intl.formatMessage({id: 'vgRestrictedTitle'})}</p>
							<a onClick={this.continueBrowse}>{this.props.intl.formatMessage({id: 'vgRestrictedContinueBrowsing'})}</a>
						</div>
					</div>
					<iframe
						className="vg-iframe"
						style={this.iframeStyle()}
						src={this.props.url}
						ref="browser"
						width={this.props.width}
					></iframe>
				</div>
			</div>
		);
	}

}));
