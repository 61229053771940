import React from 'react';
import ModalFullscreen from './ModalFullscreen.react'
import VideoView from '../video/VideoView.react';

const VideoFullscreen = (props) => {
	const onClose = () => {
		props.hideModal();
		props.showVideoSection();
	};
	const payload = {
		isFullscreen: true
	};

	return (
		<ModalFullscreen close={onClose}>
			<VideoView {...payload} />
		</ModalFullscreen>
	)
};
export default VideoFullscreen

