export default function() {
    'ngInject';

    var service = {};

    service.formatSelectableActions = function(actions) {
        var formattedActions = [];
        actions.forEach(function(action) {
            formattedActions.push({
                displayName: action.data.name,
                refValue: action.data.id
            });
        });
        return formattedActions;
    };

    return service;
};
