export default {
	'en-GB': {
		account: {
			HEADER: 'Site settings',
			PAGE_DESCRIPTION: 'These settings control features and functions in the desktop area. Site settings span across all existing sites and affect all sites if multiple sites are implemented.',
			DEPRECATED_NOTIS: 'Deprecated',
			DEFAULT_NOTIS: 'Default',
			DEFAULT_OFF_NOTIS: 'Default: Off',
			CHROME_ONLY_NOTIS: 'Chrome only',

			SECTION_DIALOG: 'Chat/dialog settings',
			LABEL_VIDEO: 'Use WebRTC video',
			LABEL_CASE_HISTORY: 'Display case history',
			LABEL_VISITOR_PROFILE: 'Display visitor profile',
			LABEL_RELATIVE_TIME: 'Display relative time for messages in a dialog',
			LABEL_VISITOR_ALERT: 'New visitor message alert',
			LABEL_DESC_VISITOR_ALERT: 'How many seconds should an alert for new visitor messages be visible',
			VISITOR_ALERT_TIME_FORMAT: 'Seconds',
			LABEL_EMAIL_CASES: 'Email address for closed cases',
			LABEL_DESC_EMAIL_CASES: 'The destination for cases closed with email option. Either enter one email where all cases are sent, or leave blank to disable',
			LABEL_EMAIL_CASES_PLACEHOLDER: 'all-cases@email.com',

			SECTION_COBROWSE: 'Co-browse settings',
			LABEL_NOFOLLOW: 'No follow',
			LABEL_DESC_NOFOLLOW: 'This is replaced by "Restricted" and "Do not disturb". Using any of them will disable "No follow".',
			LABEL_RESTRICTED: 'Restricted',
			LABEL_DESC_RESTRICTED: 'Enter URL or URL fragment. This will prevent the agent from viewing the visitor page',
			LABEL_EG_TITLE_RESTRICTED: 'Usage:',
			LABEL_EG_RESTRICTED: 'If a visitor enters a section on your website where authentication is required, the agent won\'t follow. This is to avoid endless redirection loops.',
			LABEL_DONOTDISTURB: 'Do not disturb',
			LABEL_DESC_DONOTDISTURB: 'Enter URL or URL fragment. This will prevent the agent from affecting the visitor, but still view the page',
			LABEL_EG_TITLE_DONOTDISTURB: 'Usage:',
			LABEL_EG_DONOTDISTURB: 'If a visitor enters a checkout section where forms shouldn\'t be reloaded, the agent passively follows without affecting the visitor.',

			SECTION_QUEUE: 'Queue section settings',
			LABEL_SHOW_QUEUE: 'Show queue list in desktop',

			SECTION_SOUND: 'Sound playback',
			LABEL_HTML5: 'Use HTML5 audio',
			LABEL_LEGACY_FLASH: 'Use legacy Flash-based audio',
			LABEL_MUTE_AUDIO: 'Mute audio in desktop',

			SECTION_FEATURES: 'Features/functions',
			LABEL_PHONE_TO_COBROWSE: 'Enable phone to Co-browse',
			LABEL_PDF_CAPTURE: 'Enable PDF capture',
			LABEL_VIDEO_CHAT: 'Enable video chat',
			LABEL_WEB_CALL: 'Enable web call',
			LABEL_CONVERSATION_HISTORY: 'Enable conversation history',
			HELP_CONVERSATION_HISTORY: 'For this functionality to work it requires that the conversations are stored with a unique customer id.',

			SECTION_INTEGRATION: 'Integration',
			LABEL_INTEGRATION_TITLE: 'Start code url',
			LABEL_INTEGRATION_DESCRIPTION: 'An optional url that will be combined with a start code to create a conversation integration link.' +
				'If left empty only the start code will be used.',
			LABEL_INTEGRATION_URL: 'https://...',

			SECTION_NOTIFICATIONS: 'Notifications',
			SECTION_NOTIFICATIONS_DESC: 'Uses the Web Notifications API to notify users of new events when not active in the application. Chrome and Firefox only.',
			LABEL_DURATION_TITLE: 'Display duration',
			LABEL_DURATION_UNTIL_ACTION: 'Until closed',
			LABEL_DURATION_SECONDS: '4 seconds',
			LABEL_DURATION_OFF: 'Off',
			LABEL_SHOW_ON_RECEIVE: 'Show notifications when receiving chat messages',
			LABEL_SHOW_ON_PROACTIVE: 'Show notifications on new proactive visitors'
		},
		visitor_settings: {
			HEADER: 'Visitor settings',
			LABEL_DAYS: 'Days',
			LABEL_MINUTES: 'Minutes',
			SECTION_GENERAL: 'General',
			LABEL_DEFAULT_SITE: 'Default site',
			LABEL_DESC_DEFAULT_SITE: 'The default site that will be used unless otherwise implemented (by custom scripts)',
			LABEL_THEME: 'Theme',
			LABEL_DESC_THEME: 'Appearance of the visitor dialog panel',
			LABEL_HTTPS_ONLY: 'Customer\'s site is https only',
			LABEL_DESC_HTTPS_ONLY: 'Will make the visitor application set all cookies with "secure" attribute.<br>' +
				'<strong>Warning:</strong> Only enable for customers that are not (and never will!) host pages on http from which they want to chat or expose banners. ' +
				'Because a side-effect is that the visitor app will not load banners, chat, custom-script, etc, on pages served over http.<br>' +
				'So for existing accounts: Only enable this if the customer specifically wants "secure" cookies.',
			LABEL_HASH_CHANGE: 'Re-evaluate opportunities on hash change',
			LABEL_DESC_HASH_CHANGE: 'Will re-evaluate all opportunities if the URL hash changes',
			LABEL_REEVALUATE_AFTER_CHAT: 'Re-evaluate opportunities after chat',
			LABEL_DESC_REEVALUATE_AFTER_CHAT: 'Will re-evaluate opportunities and present banners again after visitor has completed a chat or left the queue',
			SECTION_QUARANTINE: 'Quarantine settings',
			LABEL_BLOCKED: 'Blocked visitor',
			LABEL_DESC_BLOCKED: 'Number of days the visitor is blocked',
			LABEL_DIALOG_ENDED: 'Ended dialog (Normal)',
			LABEL_DESC_DIALOG_ENDED: 'Number of minutes a visitor quarantined from proactive sessions (banners not integrated in site)',
			LABEL_DIALOG_ENDED_PROACTIVE: 'Ended dialog (Proactive)',
			LABEL_DESC_DIALOG_ENDED_PROACTIVE: 'Number of minutes a visitor quarantined from proactive sessions',
			LABEL_DONT_ASK: 'Visitor declined (Don\'t ask again)',
			LABEL_DESC_DONT_ASK: 'Number of minutes a visitor quarantined from solution exposure',
			SECTION_COBROWSE: 'Co-browse settings',
			LABEL_DOM_VG: 'DOM visual guidance',
			LABEL_SEND_NAVIGATION_ON_HASH_CHANGE: 'Send navigation on hash change',
			LABEL_DESC_SEND_NAVIGATION_ON_HASH_CHANGE: 'If enabled, this setting makes the visitor application send a new navigation message if the URL hash changes (typically happens in singe-page-apps). If "DOM visual guidance" is enabled, this will result in a DOM-navigation upload, and if not, a normal navigation message will be sent to the agent. In more complex single-page-apps where simply detecting hash-changes may not be sufficient, a DOM-navigation message can be manually triggered by calling vngage.sendDomNavigation() from custom script',
			LABEL_HTTPS_VG: 'Switch visitor to https when entering chat on http pages',
			LABEL_DESC_HTTPS_VG: 'Requires all pages on site to be reachable over https',
			LABEL_PASSIVE_VG: 'Enable passive co-browsing',
			LABEL_DESC_PASSIVE_VG: 'Requires all links from agent to be sent to visitor by using "Send Link" button',

			LABEL_EXCLUDE_VG: 'Exclude pages from co-browsing',
			LABEL_DESC_EXCLUDE_VG: 'Entering a dialog on pages with a URL matching any of these patterns will not reload the page. For more info, see "?"-tooltip',
			HELP_EXCLUDE_VG: '<h4>Exclude pages from co-browsing</h4>' +
				'Entering a dialog on a page with a URL matching any of these listed patterns will not reload the page into an iframe. Used for pages where the visitor may have entered ' +
				'information in a form, and we do not want to disturb the visitor so that entered information is lost. ' +
				'If an agent enters co-browsing while the visitor is on a page matching this list, co-browsing will automatically be set to "Do not disturb"-mode.<br><br>' +
				'Note: This setting is only used when entering the dialog. If the visitor enters the dialog on a different page (that allows iframing), ' +
				'and later navigates to a page matching any of these patterns, the page will not break out of the iframe.',

			LABEL_TRANS_DOMAINS: 'Transport domains',
			LABEL_DESC_TRANS_DOMAINS: 'Request transport-tokens to keep the same session when navigating between these domains. For more info, see "?"-tooltip',
			HELP_TRANS_DOMAINS: '<h4>Transport domains</h4>All links to domains listed here will be decorated with transport-tokens to allow the visitor\'s session to be preserved when navigating. Both base-domains and full domains can be entered.<br><br>' +
				'Base-domains should be entered as wildcard domains (e.g. "*.xyz.com"), and they will match any sub-domain thereunder (e.g. "www.xyz.com", "mobile.xyz.com", etc) as well as "xyz.com" itself. Full domains-names in this list (e.g. "www.vergic.com") will be matched exactly.<br><br>' +
				'Example: By listing only base-domains here (e.g. "*.vergic.com", "*.vngage.com"), in combination with enabling the site-setting "Use base-domain cookies", the visitor keeps the same session while navigating everywhere. ' +
				'Navigations under the same sub-domain will not require any transport-tokens because of the use of base-domain cookies, and cross-base-domain navigations will use transport-tokens.<br><br>' +
				'All domains must exist in "domainWhiteList"',

			LABEL_IFRAME_VISITOR: 'Iframe visitor during chat',
			LABEL_DESC_IFRAME_VISITOR: 'Keep the visitor\'s page in an iframe during chat (whenever possible) to allow smoother page loads without reloading the chat window',

			LABEL_HANDLE_HTTP_METHODS: 'Handle visitor pages on http',
			LABEL_DESC_HTTP_METHODS: 'Determine how to handle visitors navigating to http pages during chat',
			HELP_HANDLE_HTTP_METHODS: '<h4>Handle visitor http</h4>When a visitor navigates to a http page, some different problems may occur. Since a http-page is not allowed to be iframed in ' +
				'a https-page, the agent will not be able to co-browse it (as the agent\'s desktop is always https).<br><br>' +
				'This setting offers some different methods to handle visitors navigating to http-pages:<br><br>' +
				'<ul><li>Do nothing (will make co-browsing fail when visitor navigates to a http-page)</li>' +
				'<li>Switch to https (force the visitor to reload http-pages to https. All pages must be served on https as well. This method is the same setting as the old deprecated setting "enableHTTPSCoBrowsing")</li>' +
				'<li>Transform navigations to https (let visitor browse on http, but transform protocol to https in all navigation-messages sent to agent. For the agent to be able to follow, all pages must be served on https as well. Note: Agent navigations in co-browsing may cause visitor switch to https)</li>' +
				'<li>Upload DOM (let visitor browse on http, but make a DOM-upload when the visitor navigates to a http-page)</li></ul>',
			HANDLE_HTTP_METHOD_ignore: 'Don\'t do anything',
			HANDLE_HTTP_METHOD_switchToHttps: 'Switch visitor to https when chatting on http pages',
			HANDLE_HTTP_METHOD_transformNavigations: 'Transform visitor navigations to https for agent',
			HANDLE_HTTP_METHOD_uploadDOM: 'Upload DOM for http pages to agent',

			LABEL_STRIP_PROTOCOL: 'Strip protocol for all URLs',
			LABEL_DESC_STRIP_PROTOCOL: 'All cobrowsing URLs will be converted from https://site.com to //site.com for both visitors and agents',
			LABEL_RESPONSIVE_BANNERS: 'Use responsive banners',
			LABEL_DESC_RESPONSIVE_BANNERS: 'Requires the newer banner configuration',
			LABEL_STREAM_SERVER: 'Override default video streaming server',

			SECTION_VIDEO: 'Video',

			SECTION_DEBUGGING: 'Visitor debugging',
			SECTION_DEBUGGING_DESC: '',
			VISITOR_PROBLEM_LOGGING_ENABLED: 'Enable Kibana-logging of critical errors in visitor application (find by searching for "visitorProblemTracker" in Kibana)',
			DESC_VISITOR_LOGGING_TYPES: 'Add visitor problem types to detect and log',
			HELP_VISITOR_LOGGING_TYPES: '<h4>Visitor problem types</h4>Determine which types of problems to detect and log to Kibana from the visitor application. This function is highly technical, and it\'s only recommended for debugging specific problems.<br><br>' +
				'Currently, these problem types are available:<br><br>' +
				'<ul><li>"queueTime" (logs visitor in queue > 30 minutes)</li>' +
				'<li>"bookedMeetingsRefNumber" (logs conversations with refNumber == 0 on joined booked meetings)</li>' +
				'<li>"successfulPollAfter403" (logs conversations that recovered after a 403 error in poll)</li></ul>' +
				'For an up-to-date list of available problem types, check the visitor source code (file "problemTracker.js"), var allAvailableProblemTypes.',

			SECTION_STREAMING: 'Streaming Conversations',
			SECTION_STREAMING_DESC: 'Streaming conversations only work on .NET Core backend. By default, polling conversations will be disabled when streaming is enabled, and only if visitor fails to connect to the broker or fails subscribing to the conversation events, visitor will fallback to legacy polling. ' +
				'However, it is possible to keep polling enabled in parallel with streaming, while testing.<br><br>' +
				'The settings can be overridden in the browser by setting these localStorage-keys to true/false:<br>' +
				'<strong>vngage.betafeatures.streaming.enabled</strong><br>' +
				'<strong>vngage.betafeatures.streaming.pollConversationsWhileStreaming</strong><br>' +
				'<strong>vngage.betafeatures.streaming.debug</strong>  (enables streaming related console-logging for debugging)',
			VISITOR_STREAMING_ENABLED: 'Enable streaming conversations in visitor',
			VISITOR_POLL_WHILE_STREAMING: 'Keep polling conversations while streaming is enabled',

			SECTION_WCAG: 'Accessibility',
			SECTION_WCAG_DESC: 'Enable WCAG 2.1 support in the Visitor application chat UI.<br><br>' +
				'<strong>Note:</strong> When enabling this feature, additional translation keys (starting with the prefix "wcag") should also be configured in the "Account/Translations"-settings ' +
				'for all languages enabled in the account.<br>' +
				'<strong>Also note</strong> that enabling this feature only enables WCAG support in the Visitor application UI (i.e. the Vergic chat). For full WCAG 2.1 support, ' +
				'all banners in use should also be checked, migrated to WCAG 2.1 and re-published.<br>' +
				'We recommend contacting Vergic support for more info before enabling this feature.',
			WCAG_ENABLED: 'Enable WCAG 2.1 support'
		},
		panel_settings: {
			DISPLAY_FIRST_MSG_IF_SENT_BY_VISITOR_DESC: '<h4>Display the first message in visitor\'s chat, if it was sent by visitor</h4>' +
				'Normally the first chat message in a conversation is the welcome message sent by the agent. ' +
				'If a message send by visitor should exist in the conversation before the welcome message, ' +
				'it is assumed to be a "banner.join"-message, and it has historically been skipped/hidden in the visitor\'s chat.<br><br>' +
				'Enabling this setting will make these messages visible to the visitor as well. ' +
				'Pre-chat messages (like banner.join-messages) can also be inserted into the conversation using the visitor api-function vngage.sendChatMessage() while in queue, ' +
				'and these messages will be handled the same way as banner.join-messages.',
			FILE_UPLOAD_MIME_TYPES_DESC: '<h4>Custom mine/file-types for visitor file uploads</h4>' +
				'When visitor file upload is enabled, this setting controls which mime/file-types a visitor should be allowed to select for file uploading. ' +
				'The value of this property will be used as the "accept"-property of the file input element for file upload in visitor.<br><br>' +
				'Default setting (if this value is left blank) is: <strong>"image/*,video/*,application/pdf,.pdf"</strong><br><br>' +
				'This will let a visitor select all image file types, all video file types, and pdf-files for upload.'
		},
	},
	'sv-SE': {
		account: {
			HEADER: 'Site settings',
			PAGE_DESCRIPTION: 'These settings control features and functions in the desktop area. Site settings span across all existing sites and affect all sites if multiple sites are implemented.',
			DEPRECATED_NOTIS: 'Deprecated',
			DEFAULT_NOTIS: 'Default',
			DEFAULT_OFF_NOTIS: 'Default: Off',
			CHROME_ONLY_NOTIS: 'Chrome only',

			SECTION_DIALOG: 'Chat/dialog settings',
			LABEL_VIDEO: 'Use WebRTC video',
			LABEL_CASE_HISTORY: 'Display case history',
			LABEL_VISITOR_PROFILE: 'Display visitor profile',
			LABEL_RELATIVE_TIME: 'Display relative time for messages in a dialog',
			LABEL_VISITOR_ALERT: 'New visitor message alert',
			LABEL_DESC_VISITOR_ALERT: 'How many seconds should an alert for new visitor messages be visible',
			VISITOR_ALERT_TIME_FORMAT: 'Seconds',
			LABEL_EMAIL_CASES: 'Email address for closed cases',
			LABEL_DESC_EMAIL_CASES: 'The destination for cases closed with email option. Either enter one email where all cases are sent, or leave blank to disable',
			LABEL_EMAIL_CASES_PLACEHOLDER: 'all-cases@email.com',

			SECTION_COBROWSE: 'Co-browse settings',
			LABEL_NOFOLLOW: 'No follow',
			LABEL_DESC_NOFOLLOW: 'This is replaced by "Restricted" and "Do not disturb". Using any of them will disable "No follow".',
			LABEL_RESTRICTED: 'Restricted',
			LABEL_DESC_RESTRICTED: 'Enter URL or URL fragment. This will prevent the agent from viewing the visitor page',
			LABEL_EG_TITLE_RESTRICTED: 'Usage:',
			LABEL_EG_RESTRICTED: 'If a visitor enters a section on your website where authentication is required, the agent won\'t follow. This is to avoid endless redirection loops.',
			LABEL_DONOTDISTURB: 'Do not disturb',
			LABEL_DESC_DONOTDISTURB: 'Enter URL or URL fragment. This will prevent the agent from affecting the visitor, but still view the page',
			LABEL_EG_TITLE_DONOTDISTURB: 'Usage:',
			LABEL_EG_DONOTDISTURB: 'If a visitor enters a checkout section where forms shouldn\'t be reloaded, the agent passively follows without affecting the visitor.',

			SECTION_QUEUE: 'Queue section settings',
			LABEL_SHOW_QUEUE: 'Show queue list in desktop',

			SECTION_SOUND: 'Sound playback',
			LABEL_HTML5: 'Use HTML5 audio',
			LABEL_LEGACY_FLASH: 'Use legacy Flash-based audio',
			LABEL_MUTE_AUDIO: 'Mute audio in desktop',

			SECTION_FEATURES: 'Features/functions',
			LABEL_PHONE_TO_COBROWSE: 'Enable phone to Co-browse',
			LABEL_PDF_CAPTURE: 'Enable PDF capture',
			LABEL_VIDEO_CHAT: 'Enable video chat',
			LABEL_WEB_CALL: 'Enable web call',
			LABEL_CONVERSATION_HISTORY: 'Enable conversation history',
			HELP_CONVERSATION_HISTORY: 'For this functionality to work it requires that the conversations are stored with a unique customer id.',

			SECTION_INTEGRATION: 'Integration',
			LABEL_INTEGRATION_TITLE: 'Start code url',
			LABEL_INTEGRATION_DESCRIPTION: 'An optional url that will be combined with a start code to create a conversation integration link.' +
				'If left empty only the start code will be used.',
			LABEL_INTEGRATION_URL: 'https://...',

			SECTION_NOTIFICATIONS: 'Notifications',
			SECTION_NOTIFICATIONS_DESC: 'Uses the Web Notifications API to notify users of new events when not active in the application. Chrome and Firefox only.',
			LABEL_DURATION_TITLE: 'Display duration',
			LABEL_DURATION_UNTIL_ACTION: 'Until closed',
			LABEL_DURATION_SECONDS: '4 seconds',
			LABEL_DURATION_OFF: 'Off',
			LABEL_SHOW_ON_RECEIVE: 'Show notifications when receiving chat messages',
			LABEL_SHOW_ON_PROACTIVE: 'Show notifications on new proactive visitors'
		},
		visitor_settings: {
			HEADER: 'Visitor settings',
			LABEL_DAYS: 'Days',
			LABEL_MINUTES: 'Minutes',
			SECTION_GENERAL: 'General',
			LABEL_DEFAULT_SITE: 'Default site',
			LABEL_DESC_DEFAULT_SITE: 'The default site that will be used unless otherwise implemented (by custom scripts)',
			LABEL_THEME: 'Theme',
			LABEL_DESC_THEME: 'Appearance of the visitor dialog panel',
			LABEL_HTTPS_ONLY: 'Customer\'s site is https only',
			LABEL_DESC_HTTPS_ONLY: 'Will make the visitor application set all cookies with "secure" attribute.<br>' +
				'<strong>Warning:</strong> Only enable for customers that are not (and never will!) host pages on http from which they want to chat or expose banners. ' +
				'Because a side-effect is that the visitor app will not load banners, chat, custom-script, etc, on pages served over http.<br>' +
				'So for existing accounts: Only enable this if the customer specifically wants "secure" cookies.',
			LABEL_HASH_CHANGE: 'Re-evaluate opportunities on hash change',
			LABEL_DESC_HASH_CHANGE: 'Will re-evaluate all opportunities if the URL hash changes',
			LABEL_REEVALUATE_AFTER_CHAT: 'Re-evaluate opportunities after chat',
			LABEL_DESC_REEVALUATE_AFTER_CHAT: 'Will re-evaluate opportunities and present banners again after visitor has completed a chat or left the queue',
			SECTION_QUARANTINE: 'Quarantine settings',
			LABEL_BLOCKED: 'Blocked visitor',
			LABEL_DESC_BLOCKED: 'Number of days the visitor is blocked',
			LABEL_DIALOG_ENDED: 'Ended dialog (Normal)',
			LABEL_DESC_DIALOG_ENDED: 'Number of minutes a visitor quarantined from proactive sessions (banners not integrated in site)',
			LABEL_DIALOG_ENDED_PROACTIVE: 'Ended dialog (Proactive)',
			LABEL_DESC_DIALOG_ENDED_PROACTIVE: 'Number of minutes a visitor quarantined from proactive sessions',
			LABEL_DONT_ASK: 'Visitor declined (Don\'t ask again)',
			LABEL_DESC_DONT_ASK: 'Number of minutes a visitor quarantined from solution exposure',
			SECTION_COBROWSE: 'Co-browse settings',
			LABEL_DOM_VG: 'DOM visual guidance',
			LABEL_SEND_NAVIGATION_ON_HASH_CHANGE: 'Send navigation on hash change',
			LABEL_DESC_SEND_NAVIGATION_ON_HASH_CHANGE: 'If enabled, this setting makes the visitor application send a new navigation message if the URL hash changes (typically happens in singe-page-apps). If "DOM visual guidance" is enabled, this will result in a DOM-navigation upload, and if not, a normal navigation message will be sent to the agent. In more complex single-page-apps where simply detecting hash-changes may not be sufficient, a DOM-navigation message can be manually triggered by calling vngage.sendDomNavigation() from custom script',
			LABEL_HTTPS_VG: 'Switch visitor to https when entering chat on http pages',
			LABEL_DESC_HTTPS_VG: 'Requires all pages on site to be reachable over https',
			LABEL_PASSIVE_VG: 'Enable passive co-browsing',
			LABEL_DESC_PASSIVE_VG: 'Requires all links from agent to be sent to visitor by using "Send Link" button',

			LABEL_EXCLUDE_VG: 'Exclude pages from co-browsing',
			LABEL_DESC_EXCLUDE_VG: 'Entering a dialog on pages with a URL matching any of these patterns will not reload the page. For more info, see "?"-tooltip',
			HELP_EXCLUDE_VG: '<h4>Exclude pages from co-browsing</h4>' +
				'Entering a dialog on a page with a URL matching any of these listed patterns will not reload the page into an iframe. Used for pages where the visitor may have entered ' +
				'information in a form, and we do not want to disturb the visitor so that entered information is lost. ' +
				'If an agent enters co-browsing while the visitor is on a page matching this list, co-browsing will automatically be set to "Do not disturb"-mode.<br><br>' +
				'Note: This setting is only used when entering the dialog. If the visitor enters the dialog on a different page (that allows iframing), ' +
				'and later navigates to a page matching any of these patterns, the page will not break out of the iframe.',

			LABEL_TRANS_DOMAINS: 'Transport domains',
			LABEL_DESC_TRANS_DOMAINS: 'Request transport-tokens to keep the same session when navigating between these domains. For more info, see "?"-tooltip',
			HELP_TRANS_DOMAINS: '<h4>Transport domains</h4>All links to domains listed here will be decorated with transport-tokens to allow the visitor\'s session to be preserved when navigating. Both base-domains and full domains can be entered.<br><br>' +
				'Base-domains should be entered as wildcard domains (e.g. "*.xyz.com"), and they will match any sub-domain thereunder (e.g. "www.xyz.com", "mobile.xyz.com", etc) as well as "xyz.com" itself. Full domains-names in this list (e.g. "www.vergic.com") will be matched exactly.<br><br>' +
				'Example: By listing only base-domains here (e.g. "*.vergic.com", "*.vngage.com"), in combination with enabling the site-setting "Use base-domain cookies", the visitor keeps the same session while navigating everywhere. ' +
				'Navigations under the same sub-domain will not require any transport-tokens because of the use of base-domain cookies, and cross-base-domain navigations will use transport-tokens.<br><br>' +
				'All domains must exist in "domainWhiteList"',

			LABEL_IFRAME_VISITOR: 'Iframe visitor during chat',
			LABEL_DESC_IFRAME_VISITOR: 'Keep the visitor\'s page in an iframe during chat (whenever possible) to allow smoother page loads without reloading the chat window',

			LABEL_HANDLE_HTTP_METHODS: 'Handle visitor pages on http',
			LABEL_DESC_HTTP_METHODS: 'Determine how to handle visitors navigating to http pages during chat',
			HELP_HANDLE_HTTP_METHODS: '<h4>Handle visitor http</h4>When a visitor navigates to a http page, some different problems may occur. Since a http-page is not allowed to be iframed in ' +
				'a https-page, the agent will not be able to co-browse it (as the agent\'s desktop is always https).<br><br>' +
				'This setting offers some different methods to handle visitors navigating to http-pages:<br><br>' +
				'<ul><li>Do nothing (will make co-browsing fail when visitor navigates to a http-page)</li>' +
				'<li>Switch to https (force the visitor to reload http-pages to https. All pages must be served on https as well. This method is the same setting as the old deprecated setting "enableHTTPSCoBrowsing")</li>' +
				'<li>Transform navigations to https (let visitor browse on http, but transform protocol to https in all navigation-messages sent to agent. For the agent to be able to follow, all pages must be served on https as well. Note: Agent navigations in co-browsing may cause visitor switch to https)</li>' +
				'<li>Upload DOM (let visitor browse on http, but make a DOM-upload when the visitor navigates to a http-page)</li></ul>',
			HANDLE_HTTP_METHOD_ignore: 'Gör ingenting',
			HANDLE_HTTP_METHOD_switchToHttps: 'Byt besökarens protokoll till https under chat på http-sidor',
			HANDLE_HTTP_METHOD_transformNavigations: 'Transformera besökarens navigationsmeddelanden till https för agenten',
			HANDLE_HTTP_METHOD_uploadDOM: 'Ladda upp DOM för http-sidor till agenten',

			LABEL_STRIP_PROTOCOL: 'Strip protocol for all URLs',
			LABEL_DESC_STRIP_PROTOCOL: 'All cobrowsing URLs will be converted from https://site.com to //site.com for both visitors and agents',
			LABEL_RESPONSIVE_BANNERS: 'Use responsive banners',
			LABEL_DESC_RESPONSIVE_BANNERS: 'Requires the newer banner configuration',
			LABEL_STREAM_SERVER: 'Override default video streaming server',

			SECTION_VIDEO: 'Video',

			SECTION_DEBUGGING: 'Visitor debugging',
			SECTION_DEBUGGING_DESC: '',
			VISITOR_PROBLEM_LOGGING_ENABLED: 'Enable Kibana-logging of critical errors in visitor application (find by searching for "visitorProblemTracker" in Kibana)',
			DESC_VISITOR_LOGGING_TYPES: 'Add visitor problem types to detect and log',
			HELP_VISITOR_LOGGING_TYPES: '<h4>Visitor problem types</h4>Determine which types of problems to detect and log to Kibana from the visitor application. This function is highly technical, and it\'s only recommended for debugging specific problems.<br><br>' +
				'Currently, these problem types are available:<br><br>' +
				'<ul><li>queueTime (logs visitor in queue > 30 minutes)</li>' +
				'<ul><li>bookedMeetingsRefNumber (logs conversations with refNumber == 0 on joined booked meetings)</li>' +
				'<ul><li>successfulPollAfter403 (logs conversations that recovered after a 403 error in poll)</li></ul>' +
				'For an up-to-date list of available problem types, check the visitor source code (file "problemTracker.js"), var allAvailableProblemTypes.',

			SECTION_STREAMING: 'Streaming Conversations',
			SECTION_STREAMING_DESC: 'Streaming conversations only work on .NET Core backend. By default, polling conversations will be disabled when streaming is enabled, and only if visitor fails to connect to the broker or fails subscribing to the conversation events, visitor will fallback to legacy polling. ' +
				'However, it is possible to keep polling enabled in parallel with streaming, while testing.<br><br>' +
				'The values below can be overridden in the browser by setting these localStorage-keys to true/false:<br>' +
				'<strong>vngage.betafeatures.streaming.enabled</strong><br>' +
				'<strong>vngage.betafeatures.streaming.pollConversationsWhileStreaming</strong><br>' +
				'<strong>vngage.betafeatures.streaming.debug</strong>  (enables streaming related console-logging for debugging)',
			VISITOR_STREAMING_ENABLED: 'Enable streaming conversations in visitor',
			VISITOR_POLL_WHILE_STREAMING: 'Keep polling conversations while streaming is enabled',

			SECTION_WCAG: 'Accessibility',
			SECTION_WCAG_DESC: 'Enable WCAG 2.1 support in the Visitor application chat UI.<br><br>' +
				'<strong>Note:</strong> When enabling this feature, additional translation keys (starting with the prefix "wcag") should also be configured in the "Account/Translations"-settings ' +
				'for all languages enabled in the account.<br>' +
				'<strong>Also note</strong> that enabling this feature only enables WCAG support in the Visitor application UI (i.e. the Vergic chat). For full WCAG 2.1 support, ' +
				'all banners in use should also be checked, migrated to WCAG 2.1 and re-published.<br>' +
				'We recommend contacting Vergic support for more info before enabling this feature.',
			WCAG_ENABLED: 'Enable WCAG 2.1 support'
		},
		panel_settings: {
			DISPLAY_FIRST_MSG_IF_SENT_BY_VISITOR_DESC: '<h4>Display the first message in visitor\'s chat, if it was sent by visitor</h4>' +
				'Normally the first chat message in a conversation is the welcome message sent by the agent. ' +
				'If a message send by visitor should exist in the conversation before the welcome message, ' +
				'it is assumed to be a "banner.join"-message, and it has historically been skipped/hidden in the visitor\'s chat.<br><br>' +
				'Enabling this setting will make these messages visible to the visitor as well. ' +
				'Pre-chat messages (like banner.join-messages) can also be inserted into the conversation using the visitor api-function vngage.sendChatMessage() while in queue, ' +
				'and these messages will be handled the same way as banner.join-messages.',
			FILE_UPLOAD_MIME_TYPES_DESC: '<h4>Custom mine/file-types for visitor file uploads</h4>' +
				'When visitor file upload is enabled, this setting controls which mime/file-types a visitor should be allowed to select for file uploading. ' +
				'The value of this property will be used as the "accept"-property of the file input element for file upload in visitor.<br><br>' +
				'Default setting (if this value is left blank) is: <strong>"image/*,video/*,application/pdf,.pdf"</strong><br><br>' +
				'This will let a visitor select all image file types, all video file types, and pdf-files for upload.'
		}
	}
};
