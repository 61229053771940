/**
 * Controllers
 */
import myHistoryController from './myHistoryController';
/**
 * Services
 */
import myHistoryService from './myHistoryService';
/**
 * Templates
 */
import myHistoryTemplate from './myHistoryTemplate.html';
/**
 * Models
 */
import myHistoryTranslations from './myHistoryTranslations';

/**
 * Case modal
 */
import '../../../common/VngageWorkspaceTranslationModule';
import caseBrowserModalService from '../../caseBrowser/caseBrowserModal/caseBrowserModalService';


export default angular.module('vngageApp.calendar.myHistory', [
	'ui.router',
	'ui.calendar',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.constant('myHistoryTranslations', myHistoryTranslations)
	.controller('myHistoryController', myHistoryController)
	.factory('myHistoryService', myHistoryService)
	.factory('caseBrowserModalService', caseBrowserModalService)

	.constant('myHistoryModuleConfig', {
		state: 'root.calendar.myHistory',
		access: 'Engage/Actions' // todo correct access rights..
	})

	.config(function ($stateProvider, $translateProvider, myHistoryTranslations, myHistoryModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';

		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(myHistoryTranslations);

		$stateProvider.state(myHistoryModuleConfig.state, {
			url: 'myHistory/',
			controller: 'myHistoryController as vm',
			templateUrl: myHistoryTemplate,
			data: {
				access: myHistoryModuleConfig.access,
				ncyBreadcrumbLabel: 'Booked Meetings'
			},
			resolve: {
				groupList: function (APIEndpointService, AccountSitesService) {
					'ngInject';

					var siteId = AccountSitesService.getSelectedSiteId();
					return APIEndpointService.group.query({siteId: siteId}).$promise.then(function (result) {
						return result.filter(o => (o.data.name.indexOf(myHistoryModuleConfig.bookingNamePrefix) != 0));
					}, function (error) {
						return $q.reject(error);
					});
				},
				userList: function (APIEndpointService, IdentityService) {
					'ngInject';

					return APIEndpointService.user.query({limit: 10000}).$promise.then(function (userList) {

						if (IdentityService.currentlyDelegated()) {
							return userList;
						} else {
							// Only return non-delegated users
							return userList.filter(function (user) {
								return user.data.type === 0;
							});
						}
					});
				}
			}
		});
	});
