export default {
    'en-GB': {
        theme: {
            ADD_HEADER: 'New theme',
			LIST_HEADER: 'Themes',
			LIST_TITLE: 'List of themes for this account',
			LIST_DESCRIPTION: 'With the theme editor it is possible to create many different themes for the dialog panels, which can be used for different sites or for different seasons and events.',
            NAME: 'Name'
        }
    },
	'sv-SE': {
		theme: {
			ADD_HEADER: 'New theme',
			LIST_HEADER: 'Themes',
			LIST_TITLE: 'List of themes for this account',
			LIST_DESCRIPTION: 'With the theme editor it is possible to create many different themes for the dialog panels, which can be used for different sites or for different seasons and events.',
			NAME: 'Name'
		}
	}
};
