export default {
	'en-GB': {
		securityWarning: {
			HEADER: 'Welcome!',
			INTRODUCTION: 'Before you start working you need to do some minor preparation to make co-browsing work.',
			HOW1: 'Look for a <strong>shield icon</strong> in the browser address bar',
			HOW1_IE: 'Look for a <strong>"Only secure content is displayed"</strong> notification in the bottom of the browser window.',
			HOW2: 'Click on the icon and then the button telling you to "Load unsafe script"',
			HOW2_IE: 'Click on <strong>"Show all content"</strong>.',
			HOW3: 'Great! You are ready to use co-browsing which allows you to guide and view the visitors browser.',
			OK_LABEL: 'OK - let’s start working!'
		},
		connection: {
			CONNECTION_LOST: 'Connection lost.',
			TRY_RECONNECT: 'Try reconnecting'
		},
		inactiveDesktopWindow: {
			TITLE: 'The Desktop application is open in another browser tab',
			DESCRIPTION: 'You can only have one Desktop instance open at the same time.',
			BTN_RESUME: 'Resume here',
			BTN_GOTO_DASHBOARD: 'Go to Dashboard'
		},
		debugInfo: {
			LINK_TEXT: 'Get debug info',
			TITLE_PAGE1: 'Create debug file',
			DESCRIPTION_LABEL: '<b>Did something unexpected happen?</b><br>Describe what you were expecting and what actually happened',
			DESCRIPTION_PLACEHOLDER: 'Description...',
			CANCEL: 'Cancel',
			CREATE: 'Create file',
			TITLE_PAGE2: 'Download debug file',
			SECTION_TITLE: 'Almost there',
			DOWNLOAD_DESCRIPTION: 'Download and send the file to Vergic support for analysis, together with a screenshot if possible.',
			GRATITUDE: 'Thank you for helping us improve',
			GENERATING_DOWNLOAD_LINK: 'Generating download link. Please wait...',
			DOWNLOAD_LINK_LABEL2: 'Download file',
			CLOSE: 'Done'
		}
	},
	'da-DK': {
		securityWarning: {
			HEADER: 'Velkommen!',
			INTRODUCTION: 'Før du kan komme igang med at arbejde med co-browsing bliver du nødt at gøre folgende:',
			HOW1: 'Kig efter en <strong>skjold</strong> i browserens adressefelt',
			HOW1_IE: 'Kig efter en <strong>"Kun sikkert indhold vises"</strong> notifiering i bunden av weblæservinduet',
			HOW2: 'Klik på ikonet og siden knappen for at "hente usikkert script"',
			HOW2_IE: 'Klik på <strong>"Vis alt indhold"</strong>.',
			HOW3: 'Godt! du er nu klar til at benytte dig af "co-browsing" og se hvad besøgeren ser i sin weblæser.',
			OK_LABEL: 'OK!'
		},
		connection: {
			CONNECTION_LOST: 'Forbindelsen er afbrudt.',
			TRY_RECONNECT: 'Prøv at genoptage'
		},
		inactiveDesktopWindow: {
			TITLE: 'Desktop er åben i et andet browservindue',
			DESCRIPTION: 'Du kan kun have en instance af Desktop åben ad gangen.',
			BTN_RESUME: 'Genoptag her',
			BTN_GOTO_DASHBOARD: 'Gå til Dashboard'
		},
		debugInfo: {
			LINK_TEXT: 'Get debug info',
			TITLE_PAGE1: 'Create debug file',
			DESCRIPTION_LABEL: '<b>Did something unexpected happen?</b><br>Describe what you were expecting and what actually happened',
			DESCRIPTION_PLACEHOLDER: 'Description...',
			CANCEL: 'Cancel',
			CREATE: 'Create file',
			TITLE_PAGE2: 'Download debug file',
			SECTION_TITLE: 'Almost there',
			DOWNLOAD_DESCRIPTION: 'Download and send the file to Vergic support for analysis, together with a screenshot if possible.',
			GRATITUDE: 'Thank you for helping us improve',
			GENERATING_DOWNLOAD_LINK: 'Generating download link. Please wait...',
			DOWNLOAD_LINK_LABEL2: 'Download file',
			CLOSE: 'Done'
		}
	},
	'sv-SE': {
		securityWarning: {
			HEADER: 'Hej,',
			INTRODUCTION: 'Innan du börjar arbeta måste du göra en inställning för att kunna dela webbläsare med besökarna:',
			HOW1: 'Leta efter en <strong>sköld</strong> i webbläsarens adressfönster',
			HOW1_IE: 'Leta efter en notifiering i botten av webbläsarfönstret där det står <strong>"Endast säkert innehåll visas"</strong>',
			HOW2: 'Klicka på skölden och välj att "läsa in osäkra script"',
			HOW2_IE: 'Klicka på <strong>"Visa allt innehåll"</strong>.',
			HOW3: 'Bra! Nu kan du använda dig av “co-browsing” funktionen och se vad besökaren ser i sin webbläsare',
			OK_LABEL: 'OK - Börja arbeta'
		},
		connection: {
			CONNECTION_LOST: 'Förbindelsen bröts.',
			TRY_RECONNECT: 'Försök ansluta igen'
		},
		inactiveDesktopWindow: {
			TITLE: 'Desktop är öppen i ett annat browserfönster',
			DESCRIPTION: 'Du kan bara ha en instans av Desktop öppen åt gången.',
			BTN_RESUME: 'Återuppta här',
			BTN_GOTO_DASHBOARD: 'Gå till Dashboard'
		},
		debugInfo: {
			LINK_TEXT: 'Skapa debug-fil',
			TITLE_PAGE1: 'Skapa debug-fil',
			DESCRIPTION_LABEL: '<b>Inträffade något oväntat?</b><br>Beskriv vad du förväntade dig och vad som faktiskt hände',
			DESCRIPTION_PLACEHOLDER: 'Beskrivning',
			CANCEL: 'Avbryt',
			CREATE: 'Skapa fil',
			TITLE_PAGE2: 'Ladda ner debug-fil',
			SECTION_TITLE: 'Snart klar',
			DOWNLOAD_DESCRIPTION: 'Ladda ner och skicka filen till Vergic support för analys. Har du möjlighet kan du även bifoga en skärmbild som visar problemet.',
			GRATITUDE: 'Tack för att du hjälper oss bli bättre',
			GENERATING_DOWNLOAD_LINK: 'Skapar länk. Vänta...',
			DOWNLOAD_LINK_LABEL2: 'Ladda ner fil',
			CLOSE: 'Klar'
		}
	},
	'fr-FR': {
		securityWarning: {
			HEADER: 'Bienvenue !',
			INTRODUCTION: 'Avant de commencer, il vous faut faire quelques légers préparatifs pour que la co-navigation fonctionne.',
			HOW1: 'Recherchez une <strong>icône de bouclier</strong> dans la barre d\'adresse du navigateur',
			HOW1_IE: 'Recherchez une notification disant <strong>« Seul le contenu sécurisé est affiché »</strong> en bas de la fenêtre du navigateur.',
			HOW2: 'Cliquez sur l\'icône puis sur le bouton vous dire « Charger scénario dangereux »',
			HOW2_IE: 'Cliquez sur <strong>« Afficher tout le contenu »</strong>.',
			HOW3: 'Parfait ! Vous êtes prêt(e) à utiliser la co-navigation qui vous permet de guider les visiteurs et d\'afficher leur navigateur.',
			OK_LABEL: 'OK, allons-y !'
		},
		connection: {
			CONNECTION_LOST: 'Connexion perdue.',
			TRY_RECONNECT: 'Essayez de vous reconnecter'
		},
		inactiveDesktopWindow: {
			TITLE: 'L\'application de bureau est ouverte dans un autre onglet du navigateur',
			DESCRIPTION: 'Vous ne pouvez ouvrir qu\'un seul bureau à la fois.',
			BTN_RESUME: 'Reprendre ici',
			BTN_GOTO_DASHBOARD: 'Aller sur le tableau de bord'
		},
		debugInfo: {
			LINK_TEXT: 'Obtenir les informations de débogage',
			TITLE_PAGE1: 'Créer un fichier de débogage',
			DESCRIPTION_LABEL: '<b>Un événement inattendu s\'est-il produit ?</b><br>Veuillez décrire ce à quoi vous vous attendiez et ce qui s\'est réellement produit',
			DESCRIPTION_PLACEHOLDER: 'Description...',
			CANCEL: 'Annuler',
			CREATE: 'Créer un fichier',
			TITLE_PAGE2: 'Télécharger le fichier de débogage',
			SECTION_TITLE: 'On y est presque',
			DOWNLOAD_DESCRIPTION: 'Téléchargez et envoyez le fichier à l\'équipe d\'assistance de Vergic afin qu\'elle l\'analyse, en joignant si possible une capture d\'écran.',
			GRATITUDE: 'Merci de nous aider à nous améliorer',
			GENERATING_DOWNLOAD_LINK: 'Génération du lien de téléchargement. Veuillez patienter...',
			DOWNLOAD_LINK_LABEL2: 'Télécharger le fichier',
			CLOSE: 'Terminé'
		}
	}
};
