import React from 'react';
import Dialog from './Dialog.react';
import VideoContainer from './VideoContainer.react';
import EventStore from './../stores/EventStore';
import ProfileStore from './../stores/ProfileStore';
import ParticipantStore from './../stores/ParticipantStore';
import ConversationsStore from './../stores/ConversationsStore';
import VisualGuidance from './VisualGuidance.react';
import DialogPanel from './common/DialogPanel.react';
import DialogMeta from './DialogMeta.react';
import MessageListContainer from './MessageListContainer.react';
import MessageComposer from './MessageComposer.react';
import ParticipantsWritingContainer from './ParticipantsWritingContainer.react';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _keys from 'lodash/keys';
import createReactClass  from 'create-react-class';

const resizeDelay = 500;

function getStateFromStores(conversationId) {
	let conversation = ConversationsStore.find(conversationId),
		visitor,
		visitorIsAt,
		vgState;

	if (!conversation) {
		return {};
	}
	visitor = ParticipantStore.getVisitorParticipantOfConversation(conversationId);
	visitorIsAt = _get(visitor,'currentNavigation.url', '');
	vgState = conversation.visualGuidance ? conversation.visualGuidance.getState() : {};

	return {
		profile: ParticipantStore.getParticipantOfConversationById(conversationId, ProfileStore.getProfileId()),
		conversationChatMessages: EventStore.getMappedMessagesOfConversation(conversationId),
		participants: ParticipantStore.getParticipantsOfConversation(conversationId),
		windowWidth: window.innerWidth,
		windowHeight: window.innerHeight,
		conversationShowMeta: conversation.showMeta,
		inVideoMeeting: true,
		videoStreamingServer: null,
		vgStatus: _get(vgState, 'status', 'disabled'),
		vgDisplayStatusbar: true,
		vgHighlightMode: _get(vgState,'highlightMode', false),
		vgUrl: _get(vgState, 'url', ''),
		vgVisitorIsAt: visitorIsAt,
		vgIsScaled: _get(vgState, 'isScaled', false),
		vgBrowserAttributes: _get(vgState, 'browserAttributes', {height:0, width:0})
	};
}

export default createReactClass({

	getInitialState () {
		return getStateFromStores(this.props.conversationId);
	},

	componentDidMount () {
		ParticipantStore.addChangeListener(this._onChange);
		EventStore.addChangeListener(this._onChange);
		ProfileStore.addChangeListener(this._onChange);

		window.addEventListener('resize', this.handleResize);

		// Todo: handle in other way, force update of child components to handle scaling... yikes. (It's a LIE!)
		this.setState({forceUpdate:true});
	},

	componentWillUnmount () {
		ParticipantStore.removeChangeListener(this._onChange);
		EventStore.removeChangeListener(this._onChange);
		ProfileStore.removeChangeListener(this._onChange);
		window.removeEventListener('resize', this.handleResize);
	},

	handleResize: _debounce(function() {
		this.setState({
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight
		});
	}, resizeDelay),

	render () {
		if (!_keys(this.state.participants).length) {
			return false;
		}
		return (
			<div className="workarea flex-container">
					<Dialog>
						<VideoContainer
							conversationId={this.props.conversationId}
							streamingServer={this.state.videoStreamingServer}
							streamId={this.props.conversationId}
							useFlash={false}
							active={this.state.inVideoMeeting}
							roomOwner={false}
							/>
						<DialogMeta
							showMeta={this.state.conversationShowMeta}
							conversationId={this.props.conversationId}
							className='dialog-metas'
							participants={this.state.participants}/>
						<MessageListContainer
							messages={this.state.conversationChatMessages}
							groupList={this.props.groupList} />
						<MessageComposer
							profile={this.state.profile}
							id={this.props.conversationId}/>
						<ParticipantsWritingContainer conversationId={this.props.conversationId} />
					</Dialog>
					<VisualGuidance
						status={this.state.vgStatus}
						conversationId={this.props.conversationId}
						url={this.state.vgUrl}
						width={this.state.vgBrowserAttributes.width}
						height={this.state.vgBrowserAttributes.height}
						isScaled={this.state.vgIsScaled}
						windowHeight={this.state.windowHeight}
						windowWidth={this.state.windowWidth}
						forceUpdate={this.state.forceUpdate}
					/>

			</div>
		)
	},

	_onChange () {
		this.setState(getStateFromStores(this.props.conversationId));
	}
});
