import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useGetTranslation} from './../LangContext';

const NavigationHistoryCompact = (props) => {
	const getTranslation = useGetTranslation();

	const navigationHistory = props.navigation.map((nav, index) => {
		if (index <= 5) {
			const cls = `history-marker marked ${nav.get('color')}`;
			const navigationHistoryCompactTooltip =
					  <Tooltip id="compact-navigation-tooltip">
						  <p className="line-clamp line-clamp-1">{getTranslation('navigationHistoryDescription')} {nav.get('description')}</p>
						  <p className="line-clamp line-clamp-1">{getTranslation('navigationHistoryURL')} {nav.get('url')}</p>
					  </Tooltip>;

			return (
				<OverlayTrigger key={index} delay={{show: 200, hide: 200}} placement="left"
								overlay={navigationHistoryCompactTooltip}>
					<li>
						<span className={cls}/>
						<span className="history-url">{nav.get('url')}</span>
					</li>
				</OverlayTrigger>
			)
		}
	});

	return (
		<ul>
			{navigationHistory}
		</ul>
	)

};

export default NavigationHistoryCompact

