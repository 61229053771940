import JSChannel from '../../utils/plugin/JSChannelWrapper';
import React, {useState, useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import Draggable from 'react-draggable';
import {executePluginAction} from '../../utils/plugin/PluginActionHandler';
import PluginSubscriptionHandler from '../../utils/plugin/PluginSubscriptionHandler';
import urlHelpers from '../../../../common/urlHelpers';

const sendToPlugin = (channel, id, subscriptions) => {
	if (subscriptions.size > 0) {
		const jsSubscriptions = subscriptions.toJS();
		// console.log('desktop sending', jsSubscriptions);
		channel.call({
			method: 'subscriptions',
			params: jsSubscriptions,
			success: function (resp) {
				// console.log('RESP:subscriptions', resp);
			}
		});
		JSChannel.subscriptionsSent(id, jsSubscriptions);
	} else {
		JSChannel.noSubscriptionsSent(id);
	}
};

const Plugin = (props) => {
	const [isDragging, setIsDragging] = useState(false);
	const iFrameRef = useRef();
	const channel = useRef();

	const onStart = () => setIsDragging(true);
	const onStop = () => setIsDragging(false);

	const dragHandlers = {
		onStart,
		onStop,
	};
	const pluginData = (trans, params) => {
		// console.log('DESKTOP receives action from plugin', trans, params);
		props.runAction(trans, params, savedProps.current);
	};
	const plugOut = props.plugin.get('displayMode') === 'external';

	const setupChannel = () => {
		let jsChannel;
		const url = props.plugin.get('url');
		const origin = urlHelpers.getOriginFromURL(url);

		if (plugOut) {
			jsChannel = JSChannel.build(window.parent, origin);
		} else {
			jsChannel = JSChannel.build(iFrameRef.current.contentWindow, origin);
		}
		jsChannel.bind('pluginData', pluginData);

		channel.current = jsChannel;
	};

	//componentWillReceiveProps
	const savedProps = useRef(props);
	useEffect(() => {
		const prevProps = savedProps.current;
		const nextProps = props;

		const isPlugOut = props.plugin.get('displayMode') === 'external';
		const refreshCounter = nextProps.plugin.get('refreshCounter');
		if (refreshCounter > 0 || isPlugOut) {
			// Send subscriptions after refreshCounter is updated. This means plugin_ready has been received
			// PlugOuts should always get subscriptions since they have been started before the desktop has loaded.
			// PlugOuts also exist when agent is no longer in desktop
			const reset = prevProps.plugin.get('refreshCounter') !== refreshCounter;
			if (channel.current) {
				// handle subscriptions
				const subscriptions = PluginSubscriptionHandler.getCurrentSubscriptions(prevProps.plugin, prevProps.state, nextProps.state, reset);
				const id = props.plugin.get('id');
				sendToPlugin(channel.current, id, subscriptions);
			}
		}
		savedProps.current = props;
	}, [props]);

	// onComponentDidMount
	// onComponentWillUnmount
	useEffect(() => {
		if (plugOut) {
			// it's a plug out
			setupChannel();
		}

		return () => {
			if (channel.current) {
				channel.current.destroy();
			}
			channel.current = null;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const url = props.plugin.get('url');
	const displayMode = props.plugin.get('displayMode') || 'floating'; // default to floating

	const pluginX = parseInt(props.plugin.get('x'), 10);
	const pluginY = parseInt(props.plugin.get('y'), 10);

	// if width/height is a number add px
	// if it's % then calculate
	// otherwise keep as is
	const pluginWidth = props.plugin.get('width');
	const pluginHeight = props.plugin.get('height');
	const isNumber = (str) => (typeof str === 'number') || /^\d+$/.test(str.trim());
	const width = isNumber(pluginWidth)
		? pluginWidth + 'px'
		: pluginWidth.substring(pluginWidth.length - 1) === '%'
			? (props.windowWidth * parseInt(pluginWidth.substring(0, pluginWidth.length - 1)) / 100).toFixed() + 'px'
			: pluginWidth;

	const height = isNumber(pluginHeight)
		? pluginHeight + 'px'
		: pluginHeight.substring(pluginHeight.length - 1) === '%'
			? (props.windowHeight * parseInt(pluginHeight.substring(0, pluginHeight.length - 1)) / 100).toFixed() + 'px'
			: pluginHeight;

	const visibleIframeStyle = {
		width,
		height
	};

	const isSelected = props.plugin.get('id') === props.selectedPlugin;

	const content = {
		'external': <div/>,
		'pinned':
			<div className={`plugin-pinned ${isSelected ? 'plugin-selected': 'plugin-not-selected'}`}>
				<iframe
					style={visibleIframeStyle}
					src={url}
					ref={iFrameRef}
					onLoad={setupChannel}
				/>
			</div>,
		'hidden':
			<div className="plugin-hidden">
				<iframe
					src={url}
					ref={iFrameRef}
					onLoad={setupChannel}
				/>
			</div>,
		'floating':
			<div className="plugin-floating">
				<Draggable
					handle=".plugin-header"
					{...dragHandlers}
					defaultPosition={{x: pluginX, y: pluginY}}
					bounds={{top: 0}}>
					<div className="plugin-tool">
						<div className="plugin-tool-handle">
							<div className="plugin-header">
								{props.plugin.get('name')}
							</div>
							{(isDragging ? <div className="drag-overlay"/>: null)}
							<iframe
								className="plugin-iframe"
								style={visibleIframeStyle}
								src={url}
								ref={iFrameRef}
								onLoad={setupChannel}
							/>
						</div>
					</div>
				</Draggable>
			</div>
	};

	return (
		<React.Fragment>
			{content[displayMode]}
		</React.Fragment>
	);
};

function mapStateToProps(state) {
	return {
		state: state
	};
}

function mapDispatchToProps(dispatch, props) {
	return {
		runAction: (trans, incomingData, props) => dispatch(executePluginAction(trans, incomingData, props)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Plugin);

