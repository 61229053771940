export default {

	INIT_MEDIA_FAILED : 'initMediaFailed',
	CONNECT_FAILED : 'connectFailed',
	JOIN_ROOM_FAILED : 'joinRoomFailed',
	SET_ROOM_CREDENTIALS_FAILED : 'setRoomCredentialsFailed',
	LEAVE_ROOM_FAILED : 'leaveRoomFailed',
 	ROOM_ID_CHANGED : 'roomIdChanged',
	STREAM_ERROR : 'streamError',
	VISITOR_DISCONNECTED : 'visitorDisconnected',
	DISCONNECTED_FROM_SERVER: 'disconnectedFromServer'
}
