export default function (casesAndVisitsService, $scope, $controller) {
	'ngInject';

	var casesAndVisits = this;
	casesAndVisits.loading = false;

	activate();

	function activate() {
		$controller('BaseDashboardController', { query: queryCasesAndVisits, $scope: $scope });
	}

	function queryCasesAndVisits() {
		casesAndVisits.loading = true;
		return casesAndVisitsService.query().then(function(res) {
			casesAndVisits.loading = false;
			casesAndVisits.data = res;
		});
	}
}
