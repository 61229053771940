export default {
	sessionId: 'sessionId',
	sessionType: 'sessionType',
	delegatedSessionId: 'delegatedSessionId',
	sessionTimeToLive: 'sessionTimeoutSeconds',
	userPresence: 'vngage.user.presence',
	userAwayReason: 'AwayReason',
	userAwayReasons: 'awayReasons',
	forcedAway: 'forcedAway'
};
