import immutable from 'immutable'
import GroupActions from '../actions/GroupActions'
import LogActions from '../actions/LogActions'
import SessionStorage from '../stores/SessionStorageWrapper';
import AuthKeys from '../../../common/auth/AuthKeys';
import Helpers from './Helpers';
import Format from '../utils/Format'
import ServerRequests from '../actionCreators/ServerRequests'

const groupChangeDelay = 1000;

function setGroupAvailabilityDebounced(groupIdsObj) {

	const thunk = (dispatch, getState) => {
		dispatch(sendGroupAvailability(groupIdsObj));
		dispatch(saveGroupAvailability(groupIdsObj));
		dispatch(GroupActions.setGroupAvailability(groupIdsObj));
	};

	thunk.meta = {
		debounce: {
			time: groupChangeDelay,
			key: 'groupAvailabilityChange'
		}
	};
	return thunk;
}

const sendGroupAvailability = (groupIdsObj) => {
	return async (dispatch, getState) => {
		try {
			const groupIdStr = getState().get('presence') === 'online'
				? Format.asGroupAvailabilityStr(groupIdsObj)
				: Format.asGroupAvailabilityStr({});
			await ServerRequests.sendGroupAvailability(groupIdStr);
			dispatch(LogActions.netCall(0, 'sendGroupAvailability', {groupIdsObj, groupIdStr}));
		} catch (error) {
			dispatch(LogActions.netCallError(0, {name: 'sendGroupAvailability', error, groupIdsObj}));
		}
	};
};

const saveGroupAvailability = groupIdsObj => (dispatch, getState) => {
	SessionStorage.setItem('groupAvailability', JSON.stringify(groupIdsObj));
};


const sendCurrentGroupAvailability = () => (dispatch, getState) => {
	const groupIdsObj = getState().get('myGroupAvailability').toJS();
	dispatch(sendGroupAvailability(groupIdsObj));
};

const setPresence = (currentPresence) => (dispatch, getState) => {
	const AuthKeyUserPresence = AuthKeys.userPresence;
	dispatch(LogActions.logAc('setPresence', {currentPresence, AuthKeyUserPresence}));
	dispatch(GroupActions.setPresence(currentPresence));
	Helpers.notifyWorkspaceValueUpdated(AuthKeys.userPresence, currentPresence);
	dispatch(sendCurrentGroupAvailability());
};

const GroupActionCreators = {
	setGroupAvailabilityDebounced,
	sendGroupAvailability,
	saveGroupAvailability,
	sendCurrentGroupAvailability,
	setPresence,
};

export default GroupActionCreators;

