import AccountConfig from '../utils/AccountConfig';
import DesktopActions from '../actions/DesktopActions';
import LogActions from '../actions/LogActions';
import ServerRequests from '../actionCreators/ServerRequests'
// Here you can select what storage will be used
import {SessionStorage as AsyncStorage} from '../stores/AsyncStorageWrapper'
import WebAPIUtils from '../utils/WebAPIUtils';
import InContactTools from '../utils/InContactTools'
import Helpers from './Helpers';
import AuthKeys from '../../../common/auth/AuthKeys';


const getInContactTokenData = (username, password) => {
	const {applicationName, vendorName, businessUnit, authUrl} = AccountConfig.getInInContactData();
	const authCode = InContactTools.getAuthCode(applicationName, vendorName, businessUnit);
	const data = {
		grant_type: 'password',
		username,
		password,
		scope: ''
	};
	const config = {
		skipErrorHandling: true,
		skipAuthorization: true,
		headers: {
			Authorization: 'basic ' + authCode
		}
	};
	return ServerRequests.postGeneric(authUrl, data, config);
};

const getInContactSession = (loginId, loginType, tokenData) => {
	const {access_token, token_type, resource_server_base_uri} = tokenData;
	const url = resource_server_base_uri + 'services/v12.0/agent-sessions';
	const data = {
		[loginType]: loginId,
	};
	const config = {
		skipErrorHandling: true,
		skipAuthorization: true,
		headers: {
			Authorization: token_type + ' ' + access_token
		}
	};
	return ServerRequests.postGeneric(url, data, config);
};

const getUnavailableCodes = (tokenData) => {
	const {access_token, token_type, resource_server_base_uri, team_id} = tokenData;
	const config = {
		skipErrorHandling: true,
		skipAuthorization: true,
		headers: {
			Authorization: token_type + ' ' + access_token
		}
	};
	// GET {base resource uri}/admin/teams/{teamId}/unavailable-codes
	const url = `${resource_server_base_uri}services/v11.0/admin/teams/${team_id}/unavailable-codes`;
	return ServerRequests.getGeneric(url, config);
};

const inContactErrorHandler = (loginProcess, error, username, loginId, loginType, tokenData, sessionId, awayReasons) => async (dispatch, getState) => {
	const inContactData = AccountConfig.getInInContactData();
	const status = error.status || 0;
	let savedInContactSessionId;
	try {
		savedInContactSessionId = await AsyncStorage.getItem('inContactSessionId');
	} catch (error) {
		savedInContactSessionId = error;
	}

	dispatch(LogActions.netError(0, loginProcess, {
		status,
		username,
		loginId,
		loginType,
		tokenData,
		sessionId,
		awayReasons,
		savedInContactSessionId,
		inContactData,
	}));

	if (status === 409) {
		// benign error, continue to desktop
		dispatch(startDesktop());
	} else if (loginProcess === 'getAwayReasons') {
		// dead end, show error screen
		dispatch(DesktopActions.setDesktopStatus('inContactError'));
	} else {
		dispatch(DesktopActions.setInContactData({pending: false, errorMsg: getErrorMsg(error)}));
	}
};

const inContactLogin = (username, password, loginId, loginType) => {
	return async (dispatch, getState) => {
		let tokenData;
		let sessionId;
		let awayReasons;
		let loginProcess = 'getInContactTokenData';
		try {
			// show spinner
			dispatch(DesktopActions.setInContactData({pending: true, errorMsg: ''}));

			// get token
			const tokenResponse = await getInContactTokenData(username, password);
			tokenData = tokenResponse.data;

			// save token
			loginProcess = 'saveInContactTokenData';
			await AsyncStorage.setItem('inContactTokenData', JSON.stringify(tokenData));

			// get away reasons
			// must be done before getting session in case session returns 409
			loginProcess = 'getAwayReasons';
			const awayReasonsResponse = await getUnavailableCodes(tokenData);
			awayReasons = awayReasonsResponse.unavailableList || [];

			Helpers.notifyWorkspaceValueUpdated(AuthKeys.userAwayReasons, JSON.stringify(InContactTools.formatAwayReasons(awayReasons)));

			// get inContactSession
			loginProcess = 'getInContactSession';
			const sessionResponse = await getInContactSession(loginId, loginType, tokenData);
			sessionId = sessionResponse.data.sessionId;

			// save inContactSession to session storage, to skip login when returning to desktop or reloading page
			loginProcess = 'saveInContactSession';
			await AsyncStorage.setItem('inContactSessionId', sessionId);
			dispatch(startDesktop());

		} catch (error) {
			dispatch(inContactErrorHandler(loginProcess, error, username, loginId, loginType, tokenData, sessionId, awayReasons));
		}
	};
};

const startInContact = () => async (dispatch, getState) => {
	let tokenData;
	let sessionId;
	let awayReasons;
	let processStep = 'getInContactSession';
	try {
		sessionId = await AsyncStorage.getItem('inContactSessionId');
		if (!sessionId) {
			// no session, show form
			dispatch(DesktopActions.setDesktopStatus('inContactLoginForm'));
		} else {
			// get saved token
			processStep = 'getInContactTokenData';
			const inContactTokenDataStr = await AsyncStorage.getItem('inContactTokenData');
			tokenData = JSON.parse(inContactTokenDataStr);
			if (!tokenData) {
				throw 'broken token'
			}
			// get away reasons
			processStep = 'getUnavailableCodes';
			const awayReasonsResponse = await getUnavailableCodes(tokenData);
			awayReasons = awayReasonsResponse.unavailableList || [];
			awayReasons = InContactTools.formatAwayReasons(awayReasons);

			Helpers.notifyWorkspaceValueUpdated(AuthKeys.userAwayReasons, JSON.stringify(awayReasons));

			dispatch(LogActions.logAc('startInContactDone', {
				unavailableList: awayReasonsResponse.unavailableList,
				awayReasons,
				sessionId,
				tokenData
			}));

			// start desktop
			dispatch(startDesktop());
		}
	} catch (error) {
		dispatch(LogActions.netError(0, 'startInContact', {processStep, error, sessionId, tokenData, awayReasons}));
		dispatch(DesktopActions.setDesktopStatus('inContactError'));
	}
};

const getErrorMsg = (error) => {
	const errorType = error.data && error.data.error
		? error.data.error
		: 'networkError';
	let errorMsg = '';
	switch (errorType) {
		case 'access_denied':
			errorMsg = error.statusText;
			break;
		case 'invalid_request':
			errorMsg = error.statusText;
			break;
		case 'networkError':
			errorMsg = 'KEY:inContactNetworkError';
			break;

		default:
			// unknown error, use statusText or all of error
			errorMsg = error.statusText || JSON.stringify(error);
			break;
	}
	return errorMsg;
};

const initDesktop = () => (dispatch, getState) => {
	const integrationType = AccountConfig.getIntegrationType();
	dispatch(LogActions.logAc('initDesktop', {integrationType}));
	if (integrationType === 'inContact') {
		dispatch(startInContact());
	} else {
		dispatch(startDesktop());
	}
};

const initDesktopFailed = () => (dispatch, getState) => {
	dispatch(DesktopActions.setDesktopStatus('desktopFailed'));
};

const startDesktop = () => (dispatch, getState) => {
	// only start if desktopStatus is not error
	if (getState().get('desktopStatus') !== 'inContactError') {
		// set desktop status
		dispatch(DesktopActions.setDesktopStatus('desktop'));

		// start availability poll
		WebAPIUtils.setShouldPoll(true);
	}
};

export default {
	initDesktop,
	initDesktopFailed,
	inContactLogin,
	startInContact,
}

