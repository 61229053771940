/*

var pageLayout = psPlugin.core.ui.pageLayout({
	menuSection: {MENU_OBJECT}, //optional
	rightSection: {RIGHT_SECTION_OBJECT}, //optional
});
pageLayout.makeLeftContentSectionFlexible();
pageLayout.makeRightContentSectionFlexible();
pageLayout.toggleShowRightSection();
 *
 */
import pageSections from './pageSections';

var defaults = {
	prefix: 'page_',
	sectionClass: 'pageSection ',
	menuSectionWidth: 160,
	rightSectionWidth: 450,
	sectionWidthStatic: 380,
	rightSectionMinimizedWidth: 20,
	margin: 10
};
var pageLayout = function (params) {
	/*
	 * -----------------------------------------------------------
	 * pageSections sections:
	 * -----------------------------------------------------------
	 */
	//				var header = new psPlugin.core.pageSections.pageHeader();
	//				this.header = header;

	var content = new pageSections.content([]);
	this.content = content;

	var sections = [];
	var clearSections = function () {
		sections.forEach(function (s) {
			delete this[s];
		});
		sections = [];
	};
	this.setContentAreas = function (elemsArr) {
		content.elem.innerHTML = '';
		clearSections();
		content = null;
		content = new pageSections.content(elemsArr);
		for (var s in content.sections) {
			this[s] = content.sections[s];
		}
	};

	// notification sections
	var overlay = document.createElement("div");
	overlay.className = defaults.prefix + "overlay";

	// notification on page
	var notice = document.createElement("div");
	notice.className = defaults.prefix + "notice";
	notice.style.display = 'none';

	var messageUI = false;

	var addMessage = function (params) {
		notice.innerHTML = '';
		params.parent = notice;
		messageUI = new psPlugin.core.messages.create(params);

		overlay.style.display = 'block';
		overlay.style.opacity = 0.9;
		notice.style.display = 'block';
	};

	this.addMessage = addMessage;
	var closeMessage = function () {
		if (messageUI) {
			messageUI.close();
			overlay.style.opacity = 0;

			notice.style.display = 'none';
			setTimeout(function () {
				overlay.style.display = 'none';
			}, 600);
			messageUI = false;
		}
	};
	this.closeMessage = closeMessage;


	document.getElementsByTagName('body')[0].appendChild(overlay);
	document.getElementsByTagName('body')[0].appendChild(notice);
};

export default pageLayout;
