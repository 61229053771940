import pdfCaptureExampleData from './pdfCaptureExampleData';
import ComServer3 from './comServer3';

var pdfCapture = {
	id: null,
	visitor: null,
	readOnly: true,
	viewHandler: false,
	interval: null,
	elem: false,
	flexibleSize: false,
	saveButton: false,
	browser: false,
	errorHandling: false,
	markedElements: [],
	markFields: false,
	actionEvents: false,
	reqData: [],
	// TODO: next two properties should be rewritten, quickfix for share and save button issue
	justVerified: false,
	justUpdated: false,
	buttons: [
		{
			label: 'Dela',
			title: 'Klicka för att dela formuläret med besökaren. Besökaren måste klicka på knappen "Godkänn" för att du kan överföra formulärdata tilbaka till PDF dokumentet',
			id: 'shareButton',
			action: function () {
				this.disabled = true;
				psPlugin.application.modules.pdfCapture.visitor.actionEvents.send('pdfCapture', {
					command: 'initiate',
					data: {
						initialData: psPlugin.application.modules.pdfCapture.reqData,
						updatedData: psPlugin.application.modules.pdfCapture.viewHandler.checkForChanges()
					}
				});
				psPlugin.application.modules.pdfCapture.viewHandler.setReadOnly();
			}
		}, {
			label: 'Avbryt',
			title: 'Klicka för att avbryta formulärdelningen',
			id: 'cancelButton',
			action: function () {
				psPlugin.application.modules.pdfCapture.visitor.actionEvents.send('pdfCapture', {
					command: 'close'
				});
				psPlugin.application.listener.pdfCaptureDone();
			}
		}, {
			label: 'Överför',
			title: 'Klicka för att överföra formulärdata tilbaka till PDF dokumentet',
			id: 'saveButton',
			action: function () {
				//psPlugin.application.modules.pdfCapture.saveButton.disabled = true;
				var changedChildren = psPlugin.application.modules.pdfCapture.viewHandler.checkForChanges();
				if (changedChildren.length > 0) {
					//console.log(changedChildren);
					psPlugin.application.listener.pdfCaptureSave(changedChildren);
				} else {
					psPlugin.application.listener.pdfCaptureDone();
				}
			},
			hidden: true
		}
		/*,{
				label: 'Save and Quit',
				action: function () {
					psPlugin.application.modules.pdfCapture.visitor.actionEvents.send('pdfCapture', {
						command: 'close'
					});
					var changedChildren = psPlugin.application.modules.pdfCapture.viewHandler.checkForChanges();
					if (changedChildren.length > 0) psPlugin.application.listener.pdfCaptureSave(changedChildren);
					console.log(changedChildren);
					//psPlugin.listener.pdfCaptureDone();
				}
		}*/
	],
	clearMarkedElements: function () {
		var i = 0;
		while (i < this.markedElements.length) {
			document.getElementById(this.markedElements[i]).style.border = '1px solid #888';
			i++;
		}
	},

	// Run when module is loaded
	initiateCaptureSession: function (visitor) {
		if (this.id) {
			psPlugin.application.listener.pdfCaptureDone();
			visitor.coWorker.coBrowserModule.PDFButtonSetAction('start');
			return;
		}
		this.actionEvents = visitor.actionEvents;
		this.browser = false;
		this.actionEvents.send('pdfCapture', {
			command: 'browser',
			data: {}
		});
		visitor.coWorker.coBrowserModule.PDFButtonSetAction('pending');
		visitor.actionEvents.unsubscribe(false, 'pdfCapture');
		visitor.actionEvents.subscribe('pdfCapture', 'pdfCapture', (function (data) {

			switch (data.command) {

				case 'updateChildren':
					//console.log('PDF: ', data);
					// TODO: quickfix for share issue
					psPlugin.application.modules.pdfCapture.justUpdated = true;
					var item, elem, i = 0;
					while (i < data.data.length) {
						item = data.data[i];
						elem = document.getElementById('PDFcaptureElem_' + item.InternalId);
						switch (item.type) {
							case "CheckBox":
								if (item.CheckState === 0) {
									elem.checked = false;
								} else {
									elem.checked = true;
								}
								//console.log(elem);
								break;
							case "SingleLineEdit":
							case "MultipleLineEdit":
							case "TextEdit":
								elem.value = item.Text;
								break;
						}
						i++;
					}
					break;

				case 'verify':
					psPlugin.application.modules.pdfCapture.justVerified = true;
					psPlugin.application.modules.pdfCapture.saveButton.style.display = 'inline';
					//uncomment to enable reshare
					//var b = document.getElementById('shareButton');
					//b.disabled = false;
					psPlugin.application.modules.pdfCapture.viewHandler.setWriteable();
					break;

				case 'active':
					var b = document.getElementById('shareButton');
					b.disabled = true;
					break;

				case 'browser':
					//console.log(data.data);
					psPlugin.application.modules.pdfCapture.browser = data.data;
					break;

				case 'inActive':
					var b = document.getElementById('shareButton');
					b.disabled = false;
					psPlugin.application.modules.pdfCapture.viewHandler.setWriteable();
					break;

				case 'close':
					//console.log(data);
					break;

				case 'selectOn':
					try {
						var elem = document.getElementById(data.data);
						elem.style.border = '2px solid black';
						elem.data.selected = true;
					} catch (err) {
						//console.log(err);
					}
					break;

				case 'selectOff':
					try {
						var elem = document.getElementById(data.data);
						elem.style.border = '1px solid #888';
						elem.data.selected = false;
					} catch (err) {
						//console.log(err);
					}
					break;
			}
		}));

		this.visitor = visitor;
		psPlugin.application.listener.pdfCapture();
	},

	 //Run when module is loaded
	load: function () {

 		// add listener methods
		psPlugin.core.Listener.prototype.pdfCapture = function () {

			//console.log(JSON.stringify(data));
			//console.log(body);

			// response id add to GET polling request
			// type cancel
			var interval;

			var reqObj = {
				type: "formCapture",
				captureType: "Rectangle",
				includeImage: true,
				tag: {}
			};

			//// start testing
			//var items = psPlugin.application.modules.pdfCapture.exampleData;
			//// if items in response, stop interval
			//if (typeof items === 'object' && items.length > 1) {
			//	//loop and check for "captureError" and quit
			//	psPlugin.application.modules.pdfCapture.reqData = items;
			//	console.log('WindowsCaptureRequest/' + psPlugin.application.modules.pdfCapture.id + ' items: ', items.length, items);
			//	psPlugin.application.modules.pdfCapture.createView(items[1].Form);
			//}
			//return;
			//// end testing

			// Start new capture request
			ComServer3.post({
				url: 'WindowsCaptureRequest',
				data: {
					items: [reqObj]
				}
			}, function (resp) {
				psPlugin.application.modules.pdfCapture.id = resp.data.id;
				// start interval to poll for captured items
				psPlugin.application.modules.pdfCapture.interval = setInterval(function () {
					ComServer3.get({
						url: 'WindowsCaptureRequest/' + psPlugin.application.modules.pdfCapture.id + '/'
					}, function (data) {
						var items = data.data.items;
						// TEST ITEMS:
						//items = psPlugin.application.modules.pdfCapture.exampleData;

						if (typeof items === 'object' && items.length > 1) {
							//loop and check for "captureError" and quit
							if (items[1].Type === "captureError") {
								psPlugin.application.modules.pdfCapture.visitor.coWorker.coBrowserModule.PDFButtonSetAction('start');
								return;
							}
							//console.info('PDF CAPTURE RESPONSE ', items);
							psPlugin.application.modules.pdfCapture.visitor.coWorker.coBrowserModule.PDFButtonSetAction('inactive');
							clearInterval(psPlugin.application.modules.pdfCapture.interval);
							psPlugin.application.modules.pdfCapture.reqData = items;

							var form = items[1].Form,
								//imgMaxLength = 10000,
								imgMaxLength = 5500000,
								imgLength = form.Image.Png.length,
								message = 'PDF-området är för stort. Vill du försöka igen?';
							//console.info(imgLength);
							//console.log(psPlugin.application.modules.pdfCapture.browser, psPlugin.application.modules.pdfCapture.browser.browser, (psPlugin.application.modules.pdfCapture.browser.version < 30));
							if (psPlugin.application.modules.pdfCapture.browser && psPlugin.application.modules.pdfCapture.browser.browser == "Explorer" && psPlugin.application.modules.pdfCapture.browser.version < 9) {
								//alert(imgLength);
								imgMaxLength = 20112;
								message = 'Besökaren använder Internet ' + psPlugin.application.modules.pdfCapture.browser.browser + ' version ' + psPlugin.application.modules.pdfCapture.browser.version + '. ' + message;
							}
							// allow only less then 64000 chars;
							if (imgLength > imgMaxLength) {
								psPlugin.application.modules.pdfCapture.visitor.coWorker.coBrowserModule.PDFButtonSetAction('start');
								psPlugin.application.modules.pdfCapture.reqData = [];
								psPlugin.application.modules.pdfCapture.id = null;
								var retry = confirm(message);
								if (retry) psPlugin.application.modules.pdfCapture.initiateCaptureSession(psPlugin.application.modules.pdfCapture.visitor);
							} else {
								//console.log('WindowsCaptureRequest/' + psPlugin.application.modules.pdfCapture.id + ' items: ', items.length, items);
								psPlugin.application.modules.pdfCapture.createView(form);
							}
						}

					});
				}, 3000);
			});
		};
		psPlugin.core.Listener.prototype.pdfCaptureSave = function (form) {

			var reqObj = {
				type: "feedback",
				Form: {
					Surface: {
						Components: form
					}
				}
			};
			psPlugin.application.modules.pdfCapture.reqData.push(reqObj);
			psPlugin.application.modules.pdfCapture.saveButton.style.display = 'none';
			ComServer3.post({
				url: 'WindowsCaptureRequest/' + psPlugin.application.modules.pdfCapture.id + '/',
				data: {
					id: psPlugin.application.modules.pdfCapture.id,
					items: psPlugin.application.modules.pdfCapture.reqData
				}
			}, function () {
				psPlugin.application.modules.pdfCapture.interval = setInterval(function () {
					ComServer3.get({
						url: 'WindowsCaptureRequest/' + psPlugin.application.modules.pdfCapture.id + '/'
					}, function (data) {

						clearInterval(psPlugin.application.modules.pdfCapture.interval);

						var items = data.data.items;
						// TEST ITEMS:
						//items = psPlugin.application.modules.pdfCapture.exampleData;
						//console.log(items[(items.length - 1)], items[items.length]);

						psPlugin.application.modules.pdfCapture.reqData = items;

						psPlugin.application.modules.pdfCapture.saveButton.style.display = 'inline';
						var lastItem = items[(items.length - 1)];
						if (psPlugin.application.modules.pdfCapture.errorHandling) {
							if (lastItem.Type === 'feedbackOk') {
								psPlugin.application.modules.pdfCapture.clearMarkedElements();
								return;
							}
							if (lastItem.Type === 'captureError') {
								return;
							}
							// if error elements
							if (typeof lastItem.FailedComponents !== 'undefined') {
								clearInterval(psPlugin.application.modules.pdfCapture.interval);
								var i = 0, errorElem, elem;

								// clear all error marked elements
								psPlugin.application.modules.pdfCapture.clearMarkedElements();


								while (i < lastItem.FailedComponents.length) {
									errorElem = lastItem.FailedComponents[i];
									elem = document.getElementById('PDFcaptureElem_' + errorElem.InternalId);
									psPlugin.application.modules.pdfCapture.markedElements.push('PDFcaptureElem_' + errorElem.InternalId);
									elem.style.border = '2px solid red';
									/*
						while (i2 < errorElems.length) {
							errorElem = errorElems[i2];
							console.log('Error elems: ',errorElem, errorElems);
							// mark error element
							elem = document.getElementById('PDFcaptureElem_' + errorElem.InternalId);
							psPlugin.application.modules.pdfCapture.markedElements.push('PDFcaptureElem_' + errorElem.InternalId);
							elem.style.border = '2px solid red';

							i2++;
						}
						*/
									i++;
								}
							}
						}

						//--------------------- start temp
						//TODO: start listener to listen for errors
						/*

						psPlugin.application.modules.pdfCapture.visitor.coWorker.coBrowserModule.PDFButtonSetAction('start');
						//console.log('pdfCaptureDone response: ', resp);
						psPlugin.application.modules.pdfCapture.reqData = [];
						psPlugin.application.modules.pdfCapture.id = null;
						try {
							psPlugin.application.modules.pdfCapture.elem.parentElement.removeChild(psPlugin.application.modules.pdfCapture.elem);
							psPlugin.application.modules.pdfCapture.viewHandler = false;
						} catch (err) {

						}
								*/
						//--------------------- end temp
					});
				}, 3000);

				//psPlugin.application.listener.pdfCaptureDone();
				//console.log('pdfCaptureSave response: ', resp);
			});
		};
		// cancel = before capture response
		// done = in capture update pdf mode
		psPlugin.core.Listener.prototype.pdfCaptureDone = function () {
			clearInterval(psPlugin.application.modules.pdfCapture.interval);
			var reqObj = {
				type: 'done'
			};
			psPlugin.application.modules.pdfCapture.reqData.push(reqObj);
			if (this.id) {
				ComServer3.post({
					url: 'WindowsCaptureRequest/' + psPlugin.application.modules.pdfCapture.id + '/',
					data: {
						id: psPlugin.application.modules.pdfCapture.id,
						items: psPlugin.application.modules.pdfCapture.reqData
					}
				}, function () {
					psPlugin.application.modules.pdfCapture.endCaptureSession();
				});
			} else {
				psPlugin.application.modules.pdfCapture.endCaptureSession();
			}
		};

		psPlugin.core.Listener.prototype.pdfCaptureCancel = function () {
			clearInterval(psPlugin.application.modules.pdfCapture.interval);
			var reqObj = {
				type: 'cancel'
			};
			psPlugin.application.modules.pdfCapture.reqData.push(reqObj);

			ComServer3.post({
				url: 'WindowsCaptureRequest/' + psPlugin.application.modules.pdfCapture.id + '/',
				data: {
					id: psPlugin.application.modules.pdfCapture.id,
					items: psPlugin.application.modules.pdfCapture.reqData
				}
			}, function () {
				psPlugin.application.modules.pdfCapture.visitor.coWorker.coBrowserModule.PDFButtonSetAction('start');
				//console.log('pdfCaptureCancel response: ', resp);
				psPlugin.application.modules.pdfCapture.reqData = [];
				psPlugin.application.modules.pdfCapture.id = null;
			});
		};
	},

	endCaptureSession: function () {
		psPlugin.application.modules.pdfCapture.visitor.coWorker.coBrowserModule.PDFButtonSetAction('start');
		//console.log('pdfCaptureDone response: ', resp);
		psPlugin.application.modules.pdfCapture.reqData = [];
		psPlugin.application.modules.pdfCapture.id = null;
		try {
			psPlugin.application.modules.pdfCapture.elem.parentElement.removeChild(psPlugin.application.modules.pdfCapture.elem);
			psPlugin.application.modules.pdfCapture.viewHandler = false;
		} catch (err) {

		}
	},

	createView: function (form) {
		this.viewHandler = new psPlugin.application.modules.pdfCapture.lib.makeArea(form, function (updatedChildren) {
			//console.log(updatedChildren);
			if (!psPlugin.application.modules.pdfCapture.justVerified && !psPlugin.application.modules.pdfCapture.justUpdated && typeof updatedChildren === 'object' && updatedChildren.length > 0) {
				psPlugin.application.modules.pdfCapture.saveButton.style.display = 'none';
				var b = document.getElementById('shareButton');
				b.disabled = false;
			}
			// TODO: quickfix for share issue and save button issue
			psPlugin.application.modules.pdfCapture.justVerified = false;
			psPlugin.application.modules.pdfCapture.justUpdated = false;
		});
		var elem = document.createElement('div');
		elem.style.position = 'absolute';
		elem.style.bottom = '0px';
		elem.style.left = '10px';
		elem.style.zIndex = '100000';
		elem.style.display = 'block';
		elem.style.backgroundColor = '#888';
		elem.style.padding = '2px';

		elem.appendChild(this.viewHandler.elem);

		var buttons = document.createElement('div');

		var i = 0;
		while (i < this.buttons.length) {
			var b = this.buttons[i];
			var button = document.createElement('button');
			button.innerHTML = b.label;
			button.id = b.id;
			button.title = b.title;
			button.onclick = b.action;
			buttons.appendChild(button);
			if (b.id === 'saveButton') {
				this.saveButton = button;
				button.style.display = 'none';
			}
			i++;
		}
		buttons.style.position = 'absolute';
		buttons.style.bottom = '0px';
		//buttons.style.right = '400px';
		buttons.style.width = '400px';

		elem.style.height = (parseInt(this.viewHandler.elem.style.height) + 25) + 'px';
		elem.style.width = (parseInt(this.viewHandler.elem.style.width) + 4) + 'px';
		elem.appendChild(buttons);

		//console.log(elem);

		psPlugin.application.modules.pdfCapture.visitor.coWorker.coBrowsingArea.appendChild(elem);

		//document.body.appendChild(elem);
		this.elem = elem;
	},

	exampleData: pdfCaptureExampleData
};


var lib = {
	lineHeight: 30,
	colSize: 10,
	errorElem: false,
	caclulateElemInputLimits: function (width, height) {
		var rows = Math.floor(height / this.lineHeight);
		var resp = {
			rows: (rows < 1) ? 1: rows,
			cols: Math.floor(width / this.colSize),
			width: width,
			height: height
		};
		//console.log('caclulateElemInputLimits: ', resp);
		return resp;
	},
	createElem: function (childElem) {
		if (typeof childElem.ControlType !== 'undefined') {delete childElem.ControlType;}
		if (typeof childElem.Name !== 'undefined') {delete childElem.Name;}
		if (typeof childElem.AutomationElement !== 'undefined') {delete childElem.AutomationElement;}
		if (typeof childElem.RuntimeId !== 'undefined') {delete childElem.RuntimeId;}
		if (typeof childElem.ControlType !== 'undefined') {delete childElem.ControlType;}
		var elem,
			updateCheck = function () {
				return false;
			},
			changedCheck = function () {
				return false;
			},
			updateObj = JSON.parse(JSON.stringify(childElem));
		//console.log(type);
		switch (childElem.type) {
			case 'CheckBox':
				elem = document.createElement('input');
				elem.type = 'checkbox';
				//elem.value = 'checked';
				if (childElem.CheckState === 1) {elem.checked = 'checked';}
				var checkedMap = function (checked) {
					if (checked) {return 1;}
					return 0;
				};
				if (psPlugin.application.modules.pdfCapture.readOnly) {
					elem.disabled = 'disabled';
				}
				updateCheck = function () {
					if ((checkedMap(elem.checked) !== childElem.CheckState && checkedMap(elem.checked) !== updateObj.CheckState) || (checkedMap(elem.checked) === childElem.CheckState && childElem.CheckState !== updateObj.CheckState)) {
						updateObj.CheckState = checkedMap(elem.checked);
						//console.log('Field updated: ' + childElem.InternalId + ' --- Original value: ' + childElem.Text + ' --- Last value: ' + updateObj.Text + ' --- New value: ' + elem.value);
						return updateObj;
					} else {
						return false;
					}
				};
				changedCheck = function () {
					if (childElem.CheckState !== checkedMap(elem.checked)) {
						updateObj.CheckState = checkedMap(elem.checked);
						return updateObj;
					} else {
						return false;
					}
				};
				//console.log('CheckBox: ", childElem);
				break;
			case 'SingleLineEdit':
			case 'MultipleLineEdit':
			case 'TextEdit':
				var maxProps = lib.caclulateElemInputLimits(childElem.Bounds.Width, childElem.Bounds.Height);
				if (maxProps.rows < 2) {
					elem = document.createElement('input');
					elem.type = 'text';
					elem.maxLength = maxProps.cols;
				} else {
					elem = document.createElement('textarea');

					elem.rows = maxProps.rows;
					elem.maxChars = maxProps.cols;

					elem.wrap = 'hard';

					elem.onkeyup = function () {
						lib.limitTextArea(this, maxProps.rows, maxProps.cols);
					};
					elem.style.lineHeight = lib.lineHeight + 'px';
					elem.style.overflow = 'hidden';
					if (childElem.Bounds.Width < 100) {elem.style.resize = 'none';}
					elem.maxLength = '1000';
				}

				elem.style.width = (childElem.Bounds.Width - 2) + 'px';
				elem.style.height = (childElem.Bounds.Height - 2) + 'px';

				if (typeof childElem.Text !== 'undefined') {elem.value = childElem.Text;}

				updateCheck = function () {
					//console.log(elem.value, elem.innerHTML);
					if ((elem.value !== childElem.Text && elem.value !== updateObj.Text) || (elem.value === childElem.Text && childElem.Text !== updateObj.Text)) {
						updateObj.Text = elem.value;
						//console.log('Field updated: ' + childElem.InternalId + ' --- Original value: ' + childElem.Text + ' --- Last value: ' + updateObj.Text + ' --- New value: ' + elem.value);
						return updateObj;
					} else {
						return false;
					}
				};
				changedCheck = function () {
					//console.log(elem.value, elem.innerHTML);
					if (childElem.Text !== elem.value) {
						updateObj.Text = elem.value;
						return updateObj;
					} else {
						return false;
					}
				};
				break;
			/*
	case "SingleLineEdit":
		elem = document.createElement('input');
		elem.type = 'text';
		if (typeof childElem.Text !== 'undefined') elem.value = childElem.Text;
		updateCheck = function () {
			if ((elem.value !== childElem.Text && elem.value !== updateObj.Text) || (elem.value === childElem.Text && childElem.Text !== updateObj.Text)) {
				updateObj.Text = elem.value;
				//console.log('Field updated: ' + childElem.InternalId + ' --- Original value: ' + childElem.Text + ' --- Last value: ' + updateObj.Text + ' --- New value: ' + elem.value);
				return updateObj;
			} else {
				return false;
			}
		};
		changedCheck = function () {
			if (childElem.Text !== elem.value) {
				updateObj.Text = elem.value;
				return updateObj;
			} else {
				return false;
			}
		};
		break;*/
			default:
				elem = false;
		}
		if (elem) {
			if (psPlugin.application.modules.pdfCapture.readOnly) {
				elem.readOnly = true;
			} else {
				this.setReadOnly = function () {
					elem.readOnly = true;
				};
				this.setWriteable = function () {
					elem.readOnly = false;
				};
			}
			elem.style.position = 'absolute';
			elem.style.top = childElem.Bounds.Top + 'px';
			elem.style.left = childElem.Bounds.Left + 'px';

			elem.style.border = '1px solid #888';
			elem.style.backgroundColor = '#FAEDF2';
			elem.id = 'PDFcaptureElem_' + childElem.InternalId;
			elem.style.fontSize = '12px';
			elem.style.padding = '0';
			elem.style.margin = '0';
			//elem.onchange = onUpdate;
			//psPlugin.shell.jsExt.addEventListener("Click", elem, onUpdate, false);
			if (psPlugin.application.modules.pdfCapture.markFields) {
				elem.data = {selected: false};
				elem.ondblclick = function () {
					try {
						var id = this.id;
						//console.log('DBL clicked: ', this.id, this.data);
						if (!this.data.selected) {
							elem.style.border = '2px solid black';
							psPlugin.application.modules.pdfCapture.actionEvents.send('pdfCapture', {
								command: 'selectOn',
								data: id
							});
							this.data.selected = true;
						} else {
							elem.style.border = '1px solid #888';
							psPlugin.application.modules.pdfCapture.actionEvents.send('pdfCapture', {
								command: 'selectOff',
								data: id
							});
							this.data.selected = false;
						}
					} catch (err) {
						//console.log(err);
					}
				};
			}
		}
		this.updateCheck = updateCheck;
		this.changedCheck = changedCheck;
		this.elem = elem;
	},
	makeArea: function (form, updateCallback) {
		//console.log(form);
		var i = 0,
			wrapper = document.createElement('div'),
			elem = document.createElement('div'),
			errorElem = document.createElement('div'),
			children = [],
			updatedChildren = [],
			changedChildren = [],
			interval,
			width = form.Surface.Bounds.Width,
			height = form.Surface.Bounds.Height,
			windowWidth = window.innerWidth || document.documentElement.clientWidth || document.documentElement.getElementsByTagName('body')[0],
			maxWidth = (windowWidth < 1400) ? (windowWidth - 200) : 1200,
			maxHeight = 400;

		// create the PDF view with screenshot background
		elem.style.position = 'absolute';
		elem.style.top = '2px';
		elem.style.left = '2px';
		elem.style.zIndex = '100000';
		//console.log(form.Image.Png);
		//alert('here');
		wrapper.style.backgroundImage = 'url("data:image/png;base64,' + form.Image.Png + '")';
		wrapper.style.backgroundRepeat = 'no-repeat';
		wrapper.style.width = width + 'px';
		wrapper.style.height = height + 'px';
		elem.style.overflow = 'auto';

		elem.appendChild(wrapper);

		errorElem.style.position = 'absolute';
		errorElem.style.top = '1px';
		errorElem.style.right = '1px';
		errorElem.style.display = 'none';
		errorElem.style.backgroundColor = '#FFF';
		errorElem.style.border = '1px solid red';
		errorElem.style.padding = '4px';
		errorElem.style.color = 'red';

		lib.errorElem = errorElem;

		elem.appendChild(errorElem);

		if (width > maxWidth && psPlugin.application.modules.pdfCapture.flexibleSize) {
			elem.style.width = maxWidth + 'px';
		} else {
			elem.style.width = width + 'px';
		}

		if (height > maxHeight) {
			elem.style.height = maxHeight + 'px';
		} else {
			elem.style.height = height + 'px';
		}

		// create form elements and their functionality
		while (i < form.Surface.Components.length) {
			var child = form.Surface.Components[i];
			//console.log(child);
			var childElem = new lib.createElem(child);
			if (childElem.elem) {
				children.push(childElem);
				//console.log(childElem);
				wrapper.appendChild(childElem.elem);
			}
			i++;
		}
		var updateCheck = function () {
			updatedChildren = [];
			var i = 0;
			while (i < children.length) {
				var updatedChild = children[i].updateCheck();
				if (updatedChild) {updatedChildren.push(updatedChild);}
				i++;
			}
			if (updatedChildren.length > 0) {updateCallback(updatedChildren);}
		};
		// interval check for changed element values for syncronized form sharing
		if (typeof updateCallback === 'function') {
			interval = setInterval(updateCheck, 2000);
		}
		this.updateCheck = updateCheck;
		this.setReadOnly = function () {
			var i = 0;
			while (i < children.length) {
				try {
					children[i].setReadOnly();
				} catch (err) { }
				i++;
			}
		};
		this.setWriteable = function () {
			var i = 0;
			while (i < children.length) {
				try {
					children[i].setWriteable();
				} catch (err) { }
				i++;
			}
		};
		// check if any form elements has change from it's original value before save
		this.checkForChanges = function () {
			changedChildren = [];
			var i = 0;
			while (i < children.length) {
				var changedChild = children[i].changedCheck();
				if (changedChild) {changedChildren.push(changedChild);}
				i++;
			}
			return changedChildren;
		};
		this.elem = elem;
		//console.log(form);
	},
	limitTextArea: function (el, maxLines, maxChar) {
		if (!el.x) {
			el.x = psPlugin.shell.jsExt.guid();
			el.onblur = function () {
				clearInterval(window['int' + el.x]);
			};
		}
		window['int' + el.x] = setInterval(function () {
			var lines = el.value.replace(/\r/g, '').split('\n'), i = lines.length, linesRemoved, charRemoved;
			if (maxLines && i > maxLines) {

				// This is where I have to customize the alert for limiting the lines
				lib.displayError('Det maximala antalet rader är uppnått.');

				lines = lines.slice(0, maxLines);
				linesRemoved = 1;
			}
			if (maxChar) {
				i = lines.length;
				while (i-- > 0) {
					if (lines[i].length > maxChar) {
						lines[i] = lines[i].slice(0, maxChar);
						charRemoved = 1;
					}
				}
				if (charRemoved) {

					// This is where I have to customize the alert for limiting the characters per line
					lib.displayError('Vänligen fortsätt på ny rad.');

				}
			}
			if (charRemoved || linesRemoved) {el.value = lines.join('\n'); }
		}, 50);
	},
	displayError: function (errorText) {
		lib.errorElem.style.display = 'block';
		lib.errorElem.innerHTML = (errorText);
		setTimeout(function () {
			//lib.errorElem.innerHTML = '';
			lib.errorElem.style.display = 'none';
		}, 2000);
	}
};
pdfCapture.lib = lib;


export default pdfCapture;
