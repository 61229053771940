import React from 'react';
import Timestamp from './Timestamp.react';

const MessageRender = (props) => {
	const {speaker} = props;

	return (
		<div className="message lineClampEllipsis">
			<strong>{speaker}: </strong>
			<span>
				<i className="vngage-icon-flash"/>
				{props.title}
				</span>
			<Timestamp
				timestamp={props.timestamp}
				isMostRecentMessage={props.isMostRecentMessage}
				displayRelativeDialogTime={props.displayRelativeDialogTime}
			/>
		</div>
	)
};
export default MessageRender

