import SessionService from '../../../common/auth/SessionService';
import MessageType from './../constants/MessageType';
import moment from 'moment-timezone';
import immutable from 'immutable';
import Records from './Records';
import ErrorLangKeys from '../constants/ErrorLangKeys';

const MAX_LENGTH = 1000;

export default {
	mergeInDates(record) {

		const userTimezone = SessionService && SessionService.getUserTimezone
			? SessionService().getUserTimezone()
			: 'CET';
		const createdRaw = Date.now();
		const createdAt = new Date(moment(new Date(createdRaw)).utc().tz(userTimezone).format());
		return record.merge(immutable.fromJS({
			createdAt,
			createdRaw,
			tags: [createdRaw + '']
		}));
	},

	asCaseUpdate(caseType, form = {}) {
		return {
			caseType: {
				id: caseType,
				revision: 0
			},
			form
		};
	},

	asCaseClosed(closureObj) {
		return {
			'reason': {
				'id': closureObj.id,
				'revision': 0
			},
			'name': closureObj.name,
			'outcome': closureObj.outcome
		};
	},

	asMessage(text, type = 'chat') {
		text = this.trimRight(text).slice(0, MAX_LENGTH);
		let record = Records.get(type).merge(immutable.fromJS({message: text}));
		record = this.mergeInDates(record);
		return record.toJS();
	},

	asMetadataExchangeMessage(params) {
		let record = Records.get(MessageType.METADATA_EXCHANGE).merge(immutable.fromJS({metadataBag: params}));
		record = this.mergeInDates(record);
		return record.toJS();
	},

	asNote(text) {
		return this.asMessage(text, 'note');
	},

	asVideoMessage(streamId, message, activity) {
		let record = Records.get(MessageType.VIDEO).merge(immutable.fromJS({
			message,
			activity,
			streamId: streamId
		}));
		record = this.mergeInDates(record);
		return record.toJS();
	},

	asActivity(change) {
		return {
			messageType: 'activity',
			type: 'participantWriting',
			change,
			activity: 'writing'
		};
	},

	asAction(actionObj, actionData) {

		const types = [
			MessageType.actionItemTypes.LINK,
			MessageType.actionItemTypes.JS,
			MessageType.actionItemTypes.VIDEO_LINK,
			MessageType.actionItemTypes.PDF,
			MessageType.actionItemTypes.PHOTO,
			MessageType.actionItemTypes.FORM,
			MessageType.actionItemTypes.RENDER
		];

		const currentAction = types[parseInt(actionObj.itemType)];

		let mergeObj = {};
		switch (currentAction) {
			case MessageType.actionItemTypes.JS:
				mergeObj = {};
				break;
			case MessageType.actionItemTypes.FORM:
				mergeObj = {};
				break;
			case MessageType.actionItemTypes.RENDER:
				mergeObj = {
					linkId: actionObj.parameter,
					title: actionObj.name
				};
				if (actionData) {
					// Custom actionData with a key/value-object to inject when rendering the template exists.
					// We pass this data with the message's url-parameter, simply as a json-serialized object
					mergeObj.url = JSON.stringify(actionData);
				}
				break;
			default:
				// link, videoLink, pdf, photo, have same format
				// mergeObj = {url: actionObj.parameter, linkType: currentAction, title: actionObj.name};
				mergeObj = {url: actionObj.parameter, title: actionObj.name};
				break;
		}

		let record = Records.get(currentAction);
		record = record.merge(immutable.fromJS(mergeObj));
		record = this.mergeInDates(record);

		return record.toJS();
	},

	asContentMessage(conversationId, uploadInfo) {
		let record = this.mergeInDates(Records.get(MessageType.CONTENT))
			.merge(immutable.fromJS({
				uploadInfo,
				tags: [uploadInfo.tag]
			}));
		return record.toJS();
	},

	asHistoryMarker(caseId, createdAt, createdRaw) {
		const record = Records.get('historyMarker').merge(immutable.fromJS({
			caseId,
			createdAt,
			createdRaw,
			tags: [caseId]
		}));
		return record.toJS();
	},

	asDivider(createdRaw, message) {
		const record = Records.get('divider').merge(immutable.fromJS({
			message,
			createdRaw,
			tags: [createdRaw]
		}));
		return record.toJS();
	},

	// takes an immutable returns a js object
	messageAsTextData: (message) => {
		const messageType = message.get('messageType');
		let msgObj = {
			text: message.get('message') || '',
			type: messageType
		};

		switch (messageType) {
			case 'chat':
				// msgObj.text = msgText;
				break;
			case 'note':
				// msgObj.text = msgText;
				break;
			case 'navigation':
				msgObj.text = message.get('url');
				msgObj.title = message.get('title');
				break;
			case 'domUpload':
				msgObj.text = message.get('originalSource');
				msgObj.title = message.get('title');
				break;
			case 'link':
				msgObj.text = message.get('url') || '';
				msgObj.linkType = message.get('linkType');
				msgObj.title = message.get('title');
				break;
			case 'video':
				msgObj.text = message.get('url') || '';
				msgObj.linkType = message.get('linkType');
				msgObj.title = message.get('title');
				break;
			case 'pdf':
				msgObj.text = message.get('url') || '';
				msgObj.linkType = message.get('linkType');
				msgObj.title = message.get('title');
				break;
			case 'photo':
				msgObj.text = message.get('url') || '';
				msgObj.linkType = message.get('linkType');
				msgObj.title = message.get('title');
				break;
			case 'content':
				msgObj.text = '';
				msgObj.linkType = 'content';
				msgObj.key = message.get('key');
				msgObj.uploadInfo = (message.get('uploadInfo') || immutable.Map()).toJS();
				if (msgObj.uploadInfo.filename) {
					// decode filename to handle special characters in filename, like åäö
					// Transform dangerous chars to harmless
					msgObj.uploadInfo.filename = decodeURI(msgObj.uploadInfo.filename)
						.replace(/ /g, '_')
						.replace(/[/\\]/g, '-')
						.replace(/[^a-zA-Z0-9_\-.À-Ýà-ý]/g, '_');
				}
				break;
			case 'visitorProfile':
				msgObj.text = '';
				msgObj.claims = message.get('claims').toJS();
				break;
			case 'divider':
				break;
			case 'historyMarker':
				msgObj.caseId = message.get('caseId');
				break;
		}

		return msgObj;
	},

	asFormValuesString(form) {

		const strings = form.formFields.map(field => {
			return `*${field.label}:* ${field.value}`;
		});

		return '*\nForm:* ' + form.name + '\n' + strings.join('\n');

	},

	// formats a property name as a label, makes it pretty
	asLabel(fieldName) {
		let str = fieldName;
		str = str.replace(/([A-Z][a-z])/g, ' $1');
		str = str.replace(/[\s_-]+/g, ' ');
		str = str.trim();
		str = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
		return str;
	},

	isUrl(str) {
		return /^https?:\/\//.test(str);
	},

	asSearchFilteredList(list, searchStr, filterProperty = 'message', sortProperty) {
		sortProperty = !sortProperty ? filterProperty: sortProperty;
		let filteredList = [...list];
		if (searchStr.length > 1) {
			const re = new RegExp(searchStr.toLowerCase().trim());
			filteredList = list.filter(obj => re.test(obj[filterProperty] ? obj[filterProperty].toLowerCase(): ''));
		}
		filteredList.sort((a, b) => {
			const valA = a[sortProperty].toLowerCase().trim();
			const valB = b[sortProperty].toLowerCase().trim();
			return valA < valB
				? -1
				: valA > valB
					? 1
					: 0;
		});
		return filteredList;
	},

	asHighlightMessage(targetData) {
		let record = Records.get('domHighlight').merge(immutable.fromJS(targetData));
		record = this.mergeInDates(record);
		return record.toJS();
	},

	asDomNavigationMessage(url) {
		let record = Records.get('domNavigation').merge(immutable.fromJS({url}));
		record = this.mergeInDates(record);
		return record.toJS();
	},

	asDomUploadRequestMessage() {
		let record = Records.get('domUploadRequest');
		record = this.mergeInDates(record);
		return record.toJS();
	},

	getBookedMeetingTime(visitorTitle) {
		const re = /<%(.+?)%>/;
		const stripped = this.stripMarkdown(visitorTitle);
		const match = stripped.match(re) || ['', ''];
		return match[1];
	},

	isBookedMeeting(visitorTitle) {
		const re = /<%.+%>(.+)/;
		const match = visitorTitle.match(re) || ['', ''];
		return !!match[1];
	},

	getBookedMeetingTitle(visitorTitle) {
		const re = /<%.+%>(.+)/;
		const stripped = this.stripMarkdown(visitorTitle);
		const match = stripped
			.replace(/[\r\t\n]/g, ' ')
			.replace(/"/g, '\\"')
			.trim()
			.match(re) || ['', ''];

		return match[1];
	},

	asVisitorProfileMessage(conversationId, createdRaw, claims) {
		const mappedClaims = claims.map(claim => {
			return {
				type: this.asLabel(claim.type),
				values: claim.values
			};
		});

		let record = Records.get('visitorProfile')
			.merge(immutable.fromJS({claims: mappedClaims, createdRaw}));
		return record.toJS();
	},

	asType(conversationId, message, messageType, additionalData) {
		switch (messageType) {
			case MessageType.CHAT:
				return this.asMessage(message);
			case MessageType.NOTE:
				return this.asNote(message);
			case MessageType.ACTION:
				return this.asAction(message, additionalData);
			case MessageType.ASK_FOR_DOM_UPLOAD:
				return this.asDomUploadRequestMessage();
			case MessageType.DOM_HIGHLIGHT:
				return this.asHighlightMessage(message);
			case MessageType.METADATA_EXCHANGE:
				return this.asMetadataExchangeMessage(message);
			case MessageType.NAVIGATION:
				return this.asDomNavigationMessage(message);
			case MessageType.TYPING_STATUS:
				return this.asActivity(message);
			case MessageType.VIDEO:
				return this.asVideoMessage(conversationId, message, additionalData);
			default:
				return message;
		}
	},

	/**
	 * @param message - must be immutable
	 * @returns immutable message
	 */
	asCleanMessage(message) {
		const type = message.get('messageType') === MessageType.ACTION
			? message.get('linkType')
			: message.get('messageType');

		return Records.get(type).map((value, key) => message.get(key));
	},


	isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	},

	// used for content upload errors
	extractErrorType(error) {
		let errorType = '';
		if (error && error.response !== '' && (typeof error.response === 'string')) {
			// error has a response
			if (this.isJsonString(error.response)) {
				// is json, check for message property.
				const json = JSON.parse(error.response);
				errorType = json.message
					? json.message
					: 'uploadError';
			} else {
				// not json
				errorType = 'uploadError';
			}
		} else {
			// no response, error not recognized
			errorType = 'uploadError';
		}
		return errorType;
	},

	//
	// String parsing
	//

	trimRight(str) {
		return str.replace(/[\s\uFEFF\xA0]+$/g, '');
	},

	stripMarkdown(markdownStr) {
		return markdownStr.replace(/[*_`]/g, '');
	},
	//
	// request param formatting
	//
	asGroupAvailabilityStr(groupIdsObj) {
		return Object.keys(groupIdsObj)
			.filter(groupId => groupIdsObj[groupId] === true)
			.map(groupId => 'groupId=' + groupId)
			.join('&');
	},

	//
	// Convert error to readable object
	//
	asErrorObject(callName, error) {
		error = error ? error: {};
		const errObj = error.data
			? error.data
			: error;

		const errorLangKey = errObj.subErrorCode
			? errObj.subErrorCode
			: errObj.errorCode
				? errObj.errorCode
				: typeof errObj === 'string' || errObj instanceof String
					? errObj
					: 'unknown';

		const errorCode = errObj.errorCode
			? errObj.errorCode
			: 'unknown';

		const langKey = ErrorLangKeys[errorLangKey] || ErrorLangKeys.unknown;

		return {
			callName,
			errorCode,
			status: error.status || '',
			statusText: error.statusText || '',
			title: errObj.title || '',
			message: errObj.message || '',
			subErrorCode: errObj.subErrorCode || '',
			errorLangKey,
			langKey,
		};
	},


	//
	// device icons
	//
	asDeviceIcon(device) {
		const iconData = {
			Phone: {className: 'vngage-icon-mobile', style: {}},
			Tablet: {className: 'vngage-icon-tablet', style: {}},
			Desktop: {className: 'vngage-icon-desktop', style: {}},
			'facebook-messenger': {className: 'facebook-messenger-icon', style: {}},
			'sms': {className: 'sms-icon', style: {}},
			email: {className: 'email-icon', style: {}},
			default: {className: 'vngage-icon-chat', style: {}},
		};
		return iconData[device] ? iconData[device]: iconData['default'];
	},

	//
	// Case browser formatting
	//
	getClaimSource(claim) {
		return claim.source.split('/').pop();
	},

	getClaimName(claim) {
		return this.getClaimNameBySource(claim.type, claim.source);
	},

	/**
	 * @param type = the claim name
	 * @param source = the source (facebook-messenger, sms, ...)
	 * @returns formatted name or empty string.
	 * If a claim is not to be shown it will be an empty string
	 */
	getClaimNameBySource(type, source) {
		source = source.split('/').pop();
		const nameMap = {
			'facebook-messenger': {
				'facebook_name': 'Facebook name',
				'ChannelId': 'Id',
			},
			'sms': {'ChannelId': 'Phone number'},
			'email': {'email_email': 'Email'},
		};
		const sourceClaimNames = nameMap[source];
		if (sourceClaimNames) {
			return sourceClaimNames[type] || '';
		} else {
			return this.asLabel(type);
		}
	},
	/**
	 *
	 * @param type
	 * @param value
	 * @returns a pretty formatted value
	 */
	getClaimValueByType(type, value) {
		const nameMappers = {
			'email_email': (value) => value.replace(/"+/g, ''),
			default: (value) => value
		};
		const mapper = nameMappers[type] || nameMappers.default;
		return mapper(value);
	},

	/**
	 *
	 * @param message
	 * @returns the source that is most prominent in  message claims, excluding commServer
	 */
	getMessageClaimsSource(message) {
		if (message.claims && message.claims.length > 0) {
			const sources = message.claims
				// get claim source
				.map(claim => this.getClaimSource(claim))
				// remove commServer
				.filter(source => source !== 'commServer')
				// count sources
				.reduce((sourceObj, source) => {
					if (!sourceObj[source]) {
						sourceObj[source] = 0;
					}
					sourceObj[source]++;
					return sourceObj;

				}, {});
			return Object.keys(sources).reduce((a, b) => sources[a] && (sources[a] > sources[b]) ? a: b, '');

		} else {
			return '';
		}
	},

	/**
	 *
	 * @param message
	 * @returns a list with formatted claims from a message
	 */
	getClaimsFromMessage(message) {
		if (message.claims && message.claims.length > 0) {
			return message.claims
				// remove commServer
				.filter(claim => this.getClaimSource(claim) !== 'commServer')
				// select claims that should be shown
				.filter(claim => this.getClaimName(claim) !== '')
				.filter(claim => claim.type.indexOf('__') !== 0)	// [TL] Do we also want to skip hidden claims (types starting with '__') from the case browser? Probably yes...
				// format claims to suit caseBrowser
				.map(claim => {
					return {
						name: this.getClaimName(claim),
						value: this.getClaimValueByType(claim.type, claim.values[0])
					};
				});
		} else {
			return [];
		}
	},

	/**
	 *
	 * @param messages
	 * @returns the probable visitor platform based on message claims
	 */
	getVisitorPlatform(messages) {
		const sourcePriority = {
			'Web': -Infinity,
			'Visitor': 1,
			'facebook-messenger': 2,
			'email': 2,
			'sms': 2,
			'telephone': 2,
		};
		const probableSource = messages
			.map(message => this.getMessageClaimsSource(message))
			.reduce((uniqueSources, source) => uniqueSources.includes(source) ? uniqueSources: uniqueSources.concat([source]), []) // remove duplicates
			.reduce((bestFit, source) => (sourcePriority[source] && sourcePriority[source] > sourcePriority[bestFit]) ? source: bestFit, 'Web');// find most probable source

		return this.asLabel(probableSource);
	},

	getFormattedError(error) {
		return error instanceof Error
			? JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)))
			: error;
	}

};

