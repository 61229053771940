import CSDispatcher from '../dispatcher/CSDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import WebAPIUtils from '../utils/WebAPIUtils';

const ActionTypes = AppConstants.ActionTypes;
const AuthorizationTypes = AppConstants.Authorization;

export default {

	participantConnectionStatus (information) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.PARTICIPANT_CONNECTIONSTATUS,
			participant: information
		});
	},

	participantAuthorizationPending () {
		CSDispatcher.handleServerAction({
			type: AuthorizationTypes.PENDING
		});
	},

	participantAuthorized (conversationId) {
		CSDispatcher.handleServerAction({
			type: AuthorizationTypes.AUTHORIZED,
			conversationId: conversationId
		});
	},

	participantUnauthorized () {
		CSDispatcher.handleServerAction({
			type: AuthorizationTypes.NOT_AUTHORIZED
		});
	},

	participantJoined (conversationId, participants) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.PARTICIPANT_JOINED,
			conversationId: conversationId,
			participant: participants
		});
	},

	participantLeft (conversationId, participants) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.PARTICIPANT_LEFT,
			conversationId: conversationId,
			participant: participants
		});
	},

	participantLeave (conversationId) {
		WebAPIUtils.leaveConversation(conversationId);
	}
};

