import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function ($scope, APIEndpointService, $stateParams, $state, form, VngageUtil, vngageRemoveModalService, APIConfigurationSectionService) {
	'ngInject';

	var vm = this;
	vm.form = form;
	vm.submit = submit;
	vm.remove = remove;
	vm.cancel = cancel;
	vm.isValidJSON = true;
	vm.validateJSON = validateJSON;
	vm.visibleHelp = false;
	vm.toggleHelp = toggleHelp;
	vm.currentList = $stateParams.currentList || [];

	$scope.$on('resetResettables', () => {
		vm.formEditForm.$setPristine();
		form.$get().catch(() => {
		});
	});

	function submit() {
		validateJSON(form.data.section.template);
		if (vm.isValidJSON) {
			vm.isSaving = true;
			form.$save().finally(function () {
				vm.isSaving = false;
			});
		}
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: form,
			itemType: APIConfigurationSectionService.sectionMap.form
		}).then(function () {
			vm.formEditForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(form, 'root.configure.form.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(function () {
			vm.isSaving = false;
		});
	}

	function cancel() {
		form.$get();
		$state.go('^');
	}

	function validateJSON(str) {
		try {
			JSON.parse(str);
			vm.isValidJSON = true;
		} catch (err) {
			vm.isValidJSON = false;
		}
	}

	function toggleHelp() {
		vm.visibleHelp = !vm.visibleHelp;
	}
}
