import React, {useEffect, useState} from 'react';

const MAX_LENGTH = 1000;
const ENTER_KEY_CODE = 13;

const ChatTextarea = (props) => {
	const [hasMounted, setHasMounted] = useState(false);
	const onChange = (event) => {
		const newText = event.target.value;
		props.typingChatText(newText);
	};

	useEffect(() => {
		// hasMounted is used to prevent focus from jumping to new conversation
		// textarea is disabled on the first render
		setHasMounted(true);
	}, []);

	const onKeyDown = (event) => {
		if (event.keyCode === ENTER_KEY_CODE) {
			if (!event.shiftKey && !event.ctrlKey) {
				event.preventDefault();
				if (props.chatText.trim()) {
					props.typingChatText('');
					props.sendChatMessage(props.conversationId, props.chatText);
				}
			}
		}
	};

	const onFocus = (event) => {
		// set selection
		const [selectionStart, selectionEnd] = props.chatSelection;
		event.target.setSelectionRange(selectionStart, selectionEnd);
	};

	const onBlur = (event) => {
		// save selection position
		props.setChatSelection([event.target.selectionStart, event.target.selectionEnd]);
	};

	const disabled = !props.inputEnabled || props.panelPosition === 'collapsed' || props.panelAnimating;
	return (
		<textarea
			id={'input' + props.conversationId}
			className="message-composer form-control"
			rows="3"
			name="message"
			disabled={!hasMounted || disabled}
			onChange={onChange}
			onKeyDown={onKeyDown}
			maxLength={MAX_LENGTH}
			onFocus={onFocus}
			onBlur={onBlur}
			tabIndex="-1"
			value={props.chatText}
		/>
	);

};

export default ChatTextarea

