export default function($q, $parse, $timeout, vngageTheme, APIConfigurationSectionService) {
	'ngInject';
	var service = {}, bannerThemeType, previewTimer, previewDebounce;


	service.create = createHandler;
	service.get = getTheme;

	previewDebounce = 300;
	bannerThemeType = APIConfigurationSectionService.sectionMap.bannerTheme;


	/**
	 *     HANDLER
	 */
	function createHandler(theme) {
		var handler, themeConfig, themeParser;

		themeParser = createParser(getThemeLess(theme), getThemeBase(theme));
		themeConfig = themeParser.config();
		themeConfig.result = getThemeCss(theme);

		handler = {
			save: saveTheme.bind(this, themeParser, theme),
			preview: previewTheme.bind(this, themeParser, themeConfig),
			config: themeConfig
		};

		themeParser.variables().then(function(variables) {
			handler.variables = variables;
		});

		return handler;
	}

	function getThemeCss(theme) {
		return $parse('data.section.css')(theme) || '';
	}
	function getThemeLess(theme) {
		return $parse('data.section.less')(theme) || '';
	}
	function getThemeBase(theme) {
		return $parse('data.section.baseTheme')(theme) || '';
	}

	function createParser(less, baseTheme) {
		var themeConfig;

		themeConfig = {
			defaultVariables: 'banners/variables',
			themes: [
				{
					name: 'banners',
					defaultTheme: true,
					displayName: 'Banners',
					path: 'banners/main'
				}
			],
			variables: {
				bannerResponsive: true,
				bannerPrimary: '',
				bannerBackground: ''
			},
			activeTranslation: ''
		};

		return vngageTheme(less, baseTheme, themeConfig);
	}


	/**
	 *     THEME RETRIEVAL
	 */
	function getTheme() {

		return APIConfigurationSectionService.get(bannerThemeType).then(function(themes) {
			if (themes[0]) {
				return themes[0];
			}

			return createTheme();
		});

	}

	function createTheme() {

		var parser = createParser();

		return parser.preview().then(function(css) {
			var newTheme = {
				name: 'Default',
				section: {
					css: css,
					less: '',
					baseTheme: parser.config().selectedTheme.name
				}
			};

			return APIConfigurationSectionService.create(newTheme, bannerThemeType);
		});

	}


	/**
	 *     HANDLER METHODS
	 */
	function saveTheme(parser, theme) {

		return $q(function(resolve, reject) {

			$q.all({
				css: parser.preview(),
				less: parser.source(),
				baseTheme: parser.config().selectedTheme.name
			}).then(function(section) {
				theme.data.section = section;
				theme.$save().then(function(theme) {
					resolve(theme);
				}, function(res) {
					reject(res);
				});
			}, function(res) {
				reject(res);
			});

		});
	}

	function previewTheme(parser, config) {
		$timeout.cancel(previewTimer);
		previewTimer = $timeout(doThemePreview.bind(this, parser, config), previewDebounce);
	}

	function doThemePreview(parser, config) {
		parser.preview().then(function(css) {
			config.result = css;
		}).catch((error) => {
			// Error in less
		});
	}

	return service;
}
