import BannerEditTemplate from './BannerEditTemplate';
import BannerEditGeneralTemplate from './BannerEditGeneralTemplate';
import BannerEditSettingsTemplate from './BannerEditSettingsTemplate';
import BannerEditIntroTemplate from './BannerEditIntroTemplate';
import BannerEditHtmlTemplate from './BannerEditHtmlTemplate';
import BannerEditCssTemplate from './BannerEditCssTemplate';
import BannerEditMappingTemplate from './BannerEditMappingTemplate';
import BannerEditTranslationTemplate from './BannerEditTranslationTemplate';
import BannerEditMappingService from './BannerEditMappingService';
import BannerEditMappingController from './BannerEditMappingController';
import BannerEditTranslationController from './BannerEditTranslationController';
import BannerEditMappingAddEditModalController from './BannerEditMappingAddEditModalController';
import BannerEditTranslationAddEditModalController from './BannerEditTranslationAddEditModalController';
import BannerExportTranslationsModalController from './BannerExportTranslationsModalController';
import BannerImportTranslationsModalController from './BannerImportTranslationsModalController';
import _find from 'lodash/find';

export default angular.module('vngageApp.engage.banner.edit', [
	'ui.router'
])
	.value('filterXSS', window.filterXSS)
	.factory('BannerEditMappingService', BannerEditMappingService)
	.controller('BannerEditMappingController', BannerEditMappingController)
	.controller('BannerEditTranslationController', BannerEditTranslationController)
	.controller('BannerEditMappingAddEditModalController', BannerEditMappingAddEditModalController)
	.controller('BannerEditTranslationAddEditModalController', BannerEditTranslationAddEditModalController)
	.controller('BannerExportTranslationsModalController', BannerExportTranslationsModalController)
	.controller('BannerImportTranslationsModalController', BannerImportTranslationsModalController)

	.filter('orderObjectBy', function () {
		return function (items, field, reverse) {
			var filtered = [];
			angular.forEach(items, function (item) {
				filtered.push(item);
			});
			filtered.sort(function (a, b) {
				return (a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1);
			});
			if (reverse) {
				filtered.reverse();
			}
			return filtered;
		};
	})

	.config(function ($urlRouterProvider, $stateProvider) {
		'ngInject';

		$urlRouterProvider.when('/engage/banner/:id/', '/engage/banner/:id/general/');

		$stateProvider
			.state('root.engage.banner.edit', {
				url: ':id/',
				abstract: true,
				controller: 'BannerEditController',
				controllerAs: 'vm',
				templateUrl: BannerEditTemplate,
				data: {ncyBreadcrumbLabel: 'Edit'},
				params: {currentList: []},
				resolve: {
					banner: function (bannerList, VngageUtil, $stateParams) {
						'ngInject';
						return VngageUtil.findResource(bannerList, $stateParams.id);
					},
					theme: function (BannerThemeService) {
						'ngInject';
						return BannerThemeService.get();
					},
					caseTypeList: function (APIConfigurationSectionService) {
						'ngInject';
						return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.customerCase, true);
					},
					groupList: function (APIEndpointService, AccountSitesService, $q) {
						'ngInject';
						var siteId = AccountSitesService.getSelectedSiteId();
						return APIEndpointService.group.query({siteId: siteId}).$promise.then(function (result) {
							return result.filter(o => (o.data.reservationBehaviour !== 0));
						}, function (error) {
							return $q.reject(error);
						});
					},
					wcagEnabled: function (APIConfigurationSectionService) {
						'ngInject';
						const type = APIConfigurationSectionService.sectionMap.visitor;
						return APIConfigurationSectionService.get(type).then(function (res) {
							const visitorSettings = _find(res, r => r.data.siteId === '00000000-0000-0000-0000-000000000000');
							if (visitorSettings) {
								return !!visitorSettings.data.section.wcag;
							}
							return false;
						});
					}
				}
			});
		$stateProvider
			.state('root.engage.banner.edit.general', {
				url: 'general/',
				views: {bannerEdit: {templateUrl: BannerEditGeneralTemplate}}
			});
		$stateProvider
			.state('root.engage.banner.edit.settings', {
				url: 'settings/',
				views: {bannerEdit: {templateUrl: BannerEditSettingsTemplate}}
			});
		$stateProvider
			.state('root.engage.banner.edit.intro', {
				url: 'intro/',
				views: {bannerEdit: {templateUrl: BannerEditIntroTemplate}}
			});
		$stateProvider
			.state('root.engage.banner.edit.html', {
				url: 'html/',
				views: {bannerEdit: {templateUrl: BannerEditHtmlTemplate}}
			});
		$stateProvider
			.state('root.engage.banner.edit.css', {
				url: 'css/',
				views: {themeEdit: {templateUrl: BannerEditCssTemplate}}
			});
		$stateProvider
			.state('root.engage.banner.edit.mapping', {
				url: 'mapping/',
				views: {
					bannerEdit: {
						controller: 'BannerEditMappingController',
						controllerAs: 'vm',
						templateUrl: BannerEditMappingTemplate,
					}
				}
			});
		$stateProvider
			.state('root.engage.banner.edit.translations', {
				url: 'translations/',
				views: {
					bannerEdit: {
						controller: 'BannerEditTranslationController',
						controllerAs: 'vm',
						templateUrl: BannerEditTranslationTemplate
					}
				}
			});
	});
