import _each from 'lodash/each';

export default function($document) {
	'ngInject';

    return {
        restrict: 'A',
        scope: {
            onColorPickerChanged: '&'
        },
        link: function($scope, element) {

            $document.on('click', notifyThatColorPickerWasChanged);

            $scope.$on('$destroy', function() {
                $document.off('click', notifyThatColorPickerWasChanged);
            });

            var changed = false;

            function notifyThatColorPickerWasChanged() {
                var pickers = element[0].querySelectorAll('.colorpicker');
                if (!pickers) {
                    return;
                }

                _each(pickers, function(picker) {

                    if (changed) {
                        return;
                    }

                    changed = angular.element(picker).attr('class').split(' ').indexOf('colorpicker-visible') >= 0;
                });

                if (changed) {
                    $scope.onColorPickerChanged();
                    changed = false;
                    return;
                }
            }
        }
    };
};
