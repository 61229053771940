export default function (APIConfigurationSetService, visitorConfigurationSectionsValidation, visitorSettings, sites, themes, scripts, $state, $scope, $translate) {
	'ngInject';

	let vm = this;
	vm.scripts = scripts;
	vm.themes = themes;
	vm.sites = sites;
	vm.visitorSettings = visitorSettings;
	vm.visitorProblemLoggingTypesArray = (typeof vm.visitorSettings.data.section.visitorProblemLoggingTypes === 'string' ? vm.visitorSettings.data.section.visitorProblemLoggingTypes.split(',') : []);
	vm.save = save;
	vm.cancel = cancel;
	vm.visitorConfigurationSectionsValidation = visitorConfigurationSectionsValidation;

	vm.handleHttpMethods = {
		ignore: {
			value: 'ignore',
			text: $translate.instant('visitor_settings.HANDLE_HTTP_METHOD_ignore')
		},
		switchToHttps: {
			value: 'switchToHttps',
			text: $translate.instant('visitor_settings.HANDLE_HTTP_METHOD_switchToHttps')
		},
		transformNavigations: {
			value: 'transformNavigations',
			text: $translate.instant('visitor_settings.HANDLE_HTTP_METHOD_transformNavigations')
		},
		uploadDOM: {
			value: 'uploadDOM',
			text: $translate.instant('visitor_settings.HANDLE_HTTP_METHOD_uploadDOM')
		}
	};

	vm.validateVisitorProblemLoggingTypes = (typeStr) => {
		return /^[a-zA-Z0-9]+$/.test(typeStr);
	};

	function save() {
		if (vm.visitorSettings.data.section.handleHttpMethod === 'switchToHttps') {
			// "enableHTTPSCoBrowsing" is deprecated (replaced by handleHttpMethod === 'switchToHttps'), but keep it in sync for now to not break compatibility with older visitor versions.
			// When all customers are using new visitor version we can delete vm.visitorSettings.data.section.enableHTTPSCoBrowsing in all configs
			// (or ignore it in "VisitorSettingsModule.js")
			vm.visitorSettings.data.section.enableHTTPSCoBrowsing = true;
		} else {
			delete vm.visitorSettings.data.section.enableHTTPSCoBrowsing;
		}

		vm.visitorSettings.data.section.visitorProblemLoggingTypes = vm.visitorProblemLoggingTypesArray.join(',');
		vm.visitorSettings.$save();
	}

	function cancel() {
		$state.go('^');
	}

	$scope.$on('resetResettables', () => {
		vm.visitorSettingsForm.$setPristine();
	});
}
