import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {
	Form,
	Row,
	Col,
	ButtonToolbar,
	Button,
	Modal,
	Tabs,
	Tab,
} from 'react-bootstrap';
import AddFieldButton from './AddFieldButton.react';
import VisitorProfileSelectors from '../../../selectors/VisitorProfileSelectors';
import FormHelpers from '../../../utils/FormHelpers';
import Format from '../../../utils/Format';
import ServerActions from '../../../actionCreators/ServerActions';
import ConversationActions from '../../../actions/ConversationActions';
import VisitorProfileActions from '../../../actions/VisitorProfileActions';
import CoBrowsing from '../../../actionCreators/CoBrowsing';
import CopyButton from './CopyButton.react';
import immutable from 'immutable';
import ToolBarSectionType from '../../../constants/ToolBarSectionType';
import {useGetTranslation} from '../../LangContext';
import {VisitorPreChatJourney} from './VisitorPreChatJourney';

export const VisitorProfileForm = (props) => {
	const getTranslation = useGetTranslation();
	const [fields, setFields] = useState(props.profileFormFields.toJS());
	const [indicateSent, setIndicateSent] = useState(false);
	const [timer, setTimer] = useState(null);
	const [activeTab, setActiveTab] = useState('profile');
	useEffect(() => {
		return () => {
			if (timer) {
				clearInterval(timer);
			}
		};
	}, [timer]);

	const onFormChange = (type, value) => {
		const newFields = FormHelpers.updateFieldValue(type, value, fields);
		setFields(newFields);
	};

	const removeField = (type) => {
		const newFields = FormHelpers.removeField(type, fields);
		setFields(newFields);
	};

	const addField = (type, value) => {
		const newFields = FormHelpers.addField(type, value, fields);
		setFields(newFields);
	};

	const resetField = (type) => {
		const newFields = FormHelpers.resetFieldValue(type, fields);
		setFields(newFields);
	};

	const isFieldTypeValid = (type) => {
		return FormHelpers.isFieldTypeValid(type, fields);
	};

	const isFieldValueValid = (value) => {
		return FormHelpers.isFieldValueValid(value);
	};

	const onSend = () => {
		props.setClaimsPatch(props.visitId, []);
		props.updateVisitorClaims(props.visitId, fields);

		setIndicateSent(true);
		setTimer(setTimeout(() => {
			setIndicateSent(false);
		}, 2000));
	};

	const hideModal = () => {
		props.setClaimsPatch(props.visitId, fields);
		props.removeActiveSection(props.conversationId, ToolBarSectionType.VISITOR_PROFILE);
	};

	const isUpdated = FormHelpers.fieldsAreUpdated(fields);
	const claims = fields.map((field, index) => {
		const rowButton = field.status === 'updated'
			? <Button variant="warning" onClick={() => resetField(field.type)}>undo</Button>
			: field.status === 'new'
				? <Button variant="danger" onClick={() => removeField(field.type)}>
					<i style={{color: 'fff'}} className="vngage-icon-cancel"/>
				</Button>
				: null;
		return (
			<Form.Group key={'claims_' + index}>
				<Form.Label column={false}>{Format.asLabel(field.type)}</Form.Label>
				<div className="form-row">
					<Form.Control
						type="text"
						name={field.type}
						value={field.updatedValue}
						onChange={(event) => onFormChange(field.type, event.target.value)}
					/>
					{rowButton}
				</div>
			</Form.Group>
		);
	});

	const visitorIdentification = props.visitorIdentificationClaims.map((claim, index) => {
		const label = claim.get('type');
		const value = claim.getIn(['values', 0]);
		return (
			<Row key={'vid_' + index}>
				<Col sm={5}>
					<span className="col-4">{label}</span>
				</Col>
				<Col sm={6}>
					{Format.isUrl(value) ?
						<a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
						:
						<span>{value}</span>}
				</Col>
				<Col sm={1}>
					<CopyButton txt={value}/>
				</Col>
			</Row>
		);
	});

	const otherClaimsElements = props.otherClaims.entrySeq().map(([claimCategory, fields]) => {
		const fieldElements = fields.map((field, index) => {
			return (<div key={claimCategory + '_' + index}>
				<Row>
					<Col sm={4}>
						<span className="">{field.get('type')}</span>
					</Col>
					<Col sm={7}>
						<span className="">{field.get('value')}</span>
					</Col>
				</Row>
			</div>);
		});
		return (<div className="visitor-claims-area" key={claimCategory}>
			<h5>{Format.asLabel(claimCategory)}</h5>
			{fieldElements}
		</div>);
	});

	const claimButtons = (
		<ButtonToolbar>
			{indicateSent
				? <Button className="indicate-sent" variant="success"><i className="vngage-icon-ok"/>
					{getTranslation('visitorInformationIsSendBtn')}
				</Button>
				: <Button variant="success" disabled={!isUpdated} onClick={onSend}>
					{getTranslation('visitorInformationSendBtn')}
				</Button>
			}
		</ButtonToolbar>
	);

	const defaultButtons = (
		<ButtonToolbar>
			<Button
				className="btn btn-default"
				onClick={hideModal}
			>
				{getTranslation('visitorInformationCloseBtn')}
			</Button>
		</ButtonToolbar>
	);

	const hasClaims = fields.length > 0;
	const hasIdentification = props.visitorIdentificationClaims.size > 0;
	const hasOtherClaims = props.otherClaims.size > 0;
	const addFieldButton = <AddFieldButton
		addField={addField}
		isFieldTypeValid={isFieldTypeValid}
		isFieldValueValid={isFieldValueValid}
	/>;

	return (
		<Modal className="visitor-profile-section-modal" show={true} onHide={hideModal}>
			<Modal.Header closeButton>
				<Modal.Title>{getTranslation('visitorInformationTitle')}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Tabs
					id="tabs"
					activeKey={activeTab}
					onSelect={(t) => setActiveTab(t)}
				>
					<Tab eventKey="profile" title={getTranslation('visitorInformationTitle')}>
						{(hasClaims || hasIdentification || hasOtherClaims)
							? (<React.Fragment>
								{hasIdentification ?
									<div className="visitor-identification-area">
										<div className="area-head">
											<h5 className="no-wrap">{getTranslation('visitorIdentificationDataTitle')}</h5>
											<div className="inline-divider"/>
										</div>
										{visitorIdentification}
									</div>: ''}
								{otherClaimsElements}
								{hasClaims ?
									<div className="visitor-claims-area">
										<div className="area-head">
											<h5 className="no-wrap">{getTranslation('visitorProfileDataTitle')}</h5>
											<div className="inline-divider"/>
										</div>
										<form className="form-visitorprofile">
											{claims}
										</form>
									</div>: ''}
								{/*{addFieldButton}*/}
								{/*{hasClaims ? claimButtons: defaultButtons}*/}
							</React.Fragment>)
							: (<React.Fragment>
								{getTranslation('visitorInformationMissing')}
								<br/><br/>
							</React.Fragment>)
						}
					</Tab>
					<Tab eventKey="preChatJourney" title={getTranslation('visitorPreChatJourney')}>
						<VisitorPreChatJourney
							key={activeTab} // force remount to init ScrollText
							navigation={props.navigation}
							coBrowseFromVisitorProfile={props.coBrowseFromVisitorProfile}
							inCoBro={props.inCoBro}
							getTranslation={getTranslation}
						/>
					</Tab>
				</Tabs>
				{defaultButtons}
			</Modal.Body>
		</Modal>
	);
};


// ownProps =
// conversationId
// visitId
const mapStateToProps = (state, ownProps) => {
	const conversationId = ownProps.conversationId;
	const visitId = state.getIn(['conversations', conversationId, 'visitId']);
	const visitorProfile = state.getIn(['visitorProfiles', visitId]) || immutable.Map();
	const claimsPatch = visitorProfile.get('claimFieldsPatch') || immutable.List();
	const profileFormFields = VisitorProfileSelectors.getVisitorProfileForm(visitorProfile);
	const visitorIdentificationClaims = VisitorProfileSelectors.getVisitorIdentificationClaims(visitorProfile);
	const otherClaims = VisitorProfileSelectors.getOtherClaimsByCategory(visitorProfile);
	const navigation = visitorProfile.get('navigation') || immutable.List();
	const inCoBro = !!state.getIn(['coBro', 'conversationId'], conversationId);

	return {
		conversationId,
		visitId,
		profileFormFields,
		claimsPatch,
		visitorIdentificationClaims,
		otherClaims,
		navigation,
		inCoBro,
	};
};

function mapDispatchToProps(dispatch) {
	return {
		updateVisitorClaims: (visitId, claims) => dispatch(ServerActions.updateVisitorClaims(visitId, claims)),
		removeActiveSection: (conversationId, section) => dispatch(ConversationActions.removeActiveSection(conversationId, section)),
		setClaimsPatch: (visitId, fields) => dispatch(VisitorProfileActions.setClaimsPatch(visitId, fields)),
		coBrowseFromVisitorProfile: (url) => dispatch(CoBrowsing.coBrowseFromVisitorProfile(url)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitorProfileForm);

