export default function (APIEndpointService, caseBrowserService, caseBrowserModalService, userList, enableConversationHistory, usSpinnerService, APICaseService, $scope, $window, caseBrowserNotificationService, $translate) {
	'ngInject';

	var vm = $scope.vm = this;
	vm.caseBrowserService = caseBrowserService;
	vm.viewCase = $scope.viewCase = viewCase;
	vm.take = '100'; // Needs to be a string in newer Angular (to match the corresponding select-option in the template)
	vm.customerId = '';
	vm.customerIdType = '';
	vm.gridOptions = caseBrowserService.initializeGrid($scope);
	vm.queryGridData = queryGridData;
	vm.queryParams = caseBrowserService.queryParams;
	vm.initQueryParams = caseBrowserService.initQueryParams;
	vm.userList = userList;
	vm.enableConversationHistory = enableConversationHistory;
	vm.getCellCls = $scope.getCellCls = getCellCls;
	vm.selectedCaseId = null;
	vm.selectedUser = null;
	vm.formatSatisfactionScore = $scope.formatSatisfactionScore = caseBrowserService.formatSatisfactionScore;

	vm.openDatepicker = function ($event, type) {
		$event.preventDefault();
		$event.stopPropagation();

		if (type === 'from') {
			vm.dateFromOpened = true;
		} else if (type === 'to') {
			vm.dateToOpened = true;
		}
	};

	vm.pickUser = function (user) {
		vm.queryParams.ownedByUserId = user.data.id;
	};

	vm.tabSelected = function (tab) {
		// reset query params that are not valid for the tab
		switch (tab) {
			case 'agent':
				vm.customerId = null;
				vm.queryParams.customerId = null;
				break;
			case 'caseId':
				vm.customerId = null;
				vm.queryParams.customerId = null;
				break;
			case 'customerId':
				vm.queryParams.ownedByUserId = null;
				break;

		}
	};

	function queryGridData(options) {
		if (vm.customerId) {
			vm.queryParams.customerId = vm.customerIdType + vm.customerId;
		}
		vm.selectedCaseId = null;
		caseBrowserService.queryGridData(options).then(function (res) {
			caseBrowserNotificationService.notify(res);
		});
	}

	function getCellCls(caseId) {
		return caseId === vm.selectedCaseId
			? 'selected'
			: '';
	}

	function viewCase(caseId) {
		vm.selectedCaseId = caseId;
		vm.loadingCase = true;
		usSpinnerService.spin('spinner-view-case-loading');
		caseBrowserService.getRelatedData()
			.then(function () {
				APIEndpointService.case.get({id: caseId}).$promise.then(function (res) {
					caseBrowserModalService.open({
						caseItem: res,
						userList: vm.userList
					}).then((res) => {
						if (res && res.operation === 'cleaned') {
							caseBrowserService.updateCleanedCase(res.caseId);
						}
					}).catch(() => {
						// Modal dismissed...
					});
				}).catch(errObject => {
					if (!errObject.data) {
						// Will happen if case ID has wrong format (will cause a "Response to preflight request doesn't pass access control check" from BE, and data will be empty)
						caseBrowserNotificationService.error($translate.instant('caseBrowser.NOTIFICATION_INVALID_CASE_ID'), null);
					}
				}).finally(() => {
					vm.loadingCase = false;
					usSpinnerService.stop('spinner-view-case-loading');
				});
			});
	}

	vm.viewCaseLoadingSpinnerOpts = {
		className: 'us-spinner',
		lines: 11, length: 4, width: 2, radius: 4,
		left: '120px',
		top: '18px'
	};

	vm.casesLoadingSpinnerOpts = {
		className: 'us-spinner',
		lines: 11, length: 4, width: 2, radius: 4,
		left: '255px',
		top: '18px'
	};

	$scope.$watch('vm.caseBrowserService.isFetching', function (isFetching) {
		if (isFetching) {
			usSpinnerService.spin('spinner-cases-loading');
		} else {
			usSpinnerService.stop('spinner-cases-loading');
		}
	});

	$scope.$watch('vm.queryParams.ownedByUserName', function (user) {
		if (!user) {
			vm.queryParams.ownedByUserId = null;
		}
	});
}
