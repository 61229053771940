import ConfigurationSetRepairController from './ConfigurationSetRepairController';
import configurationSetRepairTranslations from './ConfigurationSetRepairTranslations';
import ConfigurationSetRepairTemplate from './ConfigurationSetRepairTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

/*
To access:
1) Create new Authorization with:
	Resource: Workspace
	Action: Engage/RepairConfiguration
2) Add this Authorization to role "Support"
*/

export default angular.module('vngageApp.publish.repair', ['ui.router', 'vngageApp.workspaceTranslation'])

	.controller('ConfigurationSetRepairController', ConfigurationSetRepairController)
	.constant('configurationSetRepairTranslations', configurationSetRepairTranslations)

	.constant('configurationSetRepairModuleConfig', {
		state: 'root.publish.repair',
		access: 'Engage/RepairConfiguration'
	})

	.config(function ($stateProvider, configurationSetRepairModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(configurationSetRepairTranslations);

		$stateProvider.state(configurationSetRepairModuleConfig.state, {
			url: 'repair',
			controller: 'ConfigurationSetRepairController as vm',
			templateUrl: ConfigurationSetRepairTemplate,
			data: {
				ncyBreadcrumbLabel: 'Repair',
				access: configurationSetRepairModuleConfig.access
			},
			resolve: {
				context: function (APIConfigurationSetService) {
					'ngInject';
					return APIConfigurationSetService.getConfigurationSetContext();
				},
				activateContext: function (APIConfigurationSetService, context) {
					'ngInject';
					return APIConfigurationSetService.getActivateContext(context.dependencies);
				},
				userList: function (APIEndpointService, IdentityService, context) {
					'ngInject';
					if (IdentityService.currentlyDelegated()) {
						return context.dependencies.users;
					} else {
						// Only return non-delegated users
						return context.dependencies.users.filter(user => user.data.type === 0);
					}
				}
			}
		});
	});
