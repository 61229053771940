export default {
	username: {
		notEmpty: {
			errorMsg: ''
		},
	},
	password: {
		notEmpty: {
			errorMsg: ''
		},
	},

	stationId: {
		notEmpty: {
			errorMsg: ''
		},
		rightFormat: [
			{
				// just numbers
				value: /^[0-9]+$/,
				errorMsg: 'inContactStationIdWrongFormat'
			},

		],
		rightLength: {
			value: [2, 100],
			errorMsg: 'inContactStationIdWrongLength'
		},
	},

	stationPhoneNumber: {
		notEmpty: {
			errorMsg: ''
		},
		rightFormat: [
			{
				value: /^[+]{0,1}[0-9-#*]*$/,
				errorMsg: 'inContactPhoneNumberWrongFormat'
			},
			{
				//atLeastOneNumber
				value: /[0-9]+/,
				errorMsg: 'inContactPhoneNumberWrongFormat'
			},

		],
		rightLength: {
			value: [6, 28],
			errorMsg: 'inContactPhoneNumberWrongLength'
		},

	}

};
