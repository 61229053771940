export default function ($controller, $scope, APIStatisticsService, UserProfilePictureCacheService, TimeRangeService, APIRealtimeService) {
	'ngInject';

	var vm = this;

		UserProfilePictureCacheService.activate().then(activate);

        function activate() {
            $scope.$watch(function() {
                return APIRealtimeService.dataWrap;
            }, function(res) {

				vm.users = populate(res);
            }, true);
        }

		function populate(res) {
			if (!res.interactionOverview){
				return [];
			}
			(res.interactionOverview.users || []).forEach(function (u) {
				u.profilePicture = UserProfilePictureCacheService.get(u.userId);
			});

			return res.interactionOverview.users;
		}
	};
