import _throttle from 'lodash/throttle';
import _merge from 'lodash/merge';
import _find from 'lodash/find';

export default function (generalWorkspaceSettings, $state, $parse, $scope, APIEndpointService, StateReloadService, Session, WorkspaceSettingsService, WhiteLabelService, NotificationService, workspaceSectionsValidation) {
	'ngInject';

	const workspaceSettingsSectionsDefaults = {
		dashboard: {
			groupOverview: {}
		},
		logoutRouting: false,
		logoutLandingPageUrl: '',
		whiteLabel: WhiteLabelService.getWhiteLabelDefaults(),
		integration: {
			inContact: {
				enabled: false,
				vendorName: '',
				applicationName: '',
				businessUnit: '',
				authUrl: 'https://api.incontact.com/InContactAuthorizationServer/Token',
			}
		}
	};

	const vm = this;
	vm.workspaceSettingsSections = _merge({}, workspaceSettingsSectionsDefaults);
	if (generalWorkspaceSettings) {
		vm.workspaceSettingsSections = _merge(vm.workspaceSettingsSections, generalWorkspaceSettings);
		// vm.needsMigration = !!_find(vm.workspaceSettingsSections, setting => setting['__legacyBucket']);
	}

	vm.workspaceSectionsValidation = workspaceSectionsValidation;

	vm.save = save;
	vm.cancel = cancel;
	let savedWhiteLabelSettings = _merge({}, vm.workspaceSettingsSections.whiteLabel);	// Remember the original whiteLabel-object (to be able to restore after previewing + not saving)

	function save() {
		WorkspaceSettingsService.saveWorkspaceSettingsSections({general: vm.workspaceSettingsSections}).then(() => {
			savedWhiteLabelSettings = vm.workspaceSettingsSections.whiteLabel;
		}, (error) => {
			NotificationService.error({
				header: 'Workspace settings',
				body: 'Could not save settings:' + (error ? (error.data ? error.data.message: error.statusText): 'unknown')
			});
			vm.workspaceSettingsSectionsForm.$setDirty();
		});
	}

	function cancel() {
		$state.go('^');
	}

	$scope.$on('resetResettables', () => {
		vm.workspaceSettingsSectionsForm.$setPristine();
	});

	$scope.$watch('vm.workspaceSettingsSections.whiteLabel', _throttle(() => {
		if (vm.workspaceSettingsSections && vm.workspaceSettingsSections.whiteLabel) {
			WhiteLabelService.injectWhiteLabelConfig(vm.workspaceSettingsSections.whiteLabel);
		}
	}, 10), true);

	$scope.$on('$destroy', () => {
		WhiteLabelService.injectWhiteLabelConfig(savedWhiteLabelSettings);
	});
};
