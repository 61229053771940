import React from 'react';
import createReactClass from 'create-react-class';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import MessageChat from './MessageChat.react';
import MessageNavigation from './MessageNavigation.react';
import MessageNote from './MessageNote.react';
import MessageLink from './MessageLink.react';
import MessageVideo from './MessageVideo.react';
import MessageMeta from './MessageMeta.react';

export default createReactClass({

	mixins: [PureRenderMixin],

	render () {
		let message = this.props.message;
		let speaker = message.speaker;

		if (!speaker) {
			// do nothing;
			return false;
		}

		let speakerType = speaker.info.titleId;
		let cls;
		let messageItem;

		switch (this.props.messageType) {
			case 'chat' :
				messageItem = <MessageChat
					relative={message.isMostRecentMessage}
					isMostRecentMessage={message.isMostRecentMessage}
					speaker={speaker.info}
					message={message}
					key={message.id}/>;
				cls = 'message-' + speakerType;
				break;

			case 'navigation' :
				messageItem = <MessageNavigation
					speaker={speaker.info}
					message={message}
					key={message.id}/>;
				cls = 'navigation-' + speakerType;
				break;

			case 'note' :
				messageItem = <MessageNote
					speaker={speaker.info}
					message={message}
					key={message.id  || Date.now()}/>;
				cls = 'message-' + speakerType;
				break;

			case 'link' :
				messageItem = <MessageLink
					relative={message.isMostRecentMessage}
					isMostRecentMessage={message.isMostRecentMessage}
					speaker={speaker.info}
					message={message}
					key={message.id}/>;
				cls = 'message-' + speakerType;
				break;

			case 'video' :
				//don't render anything for now!
				return false;
				//don't render video:stop in conversation
				if (message.activity !== 'start'){
					return false;
				}
				messageItem = <MessageVideo
					relative={message.isMostRecentMessage}
					isMostRecentMessage={message.isMostRecentMessage}
					speaker={speaker.info}
					message={message}
					key={message.id}/>;
				cls = 'message-' + speakerType;
				break;

			case 'meta' :
				messageItem = <MessageMeta
					speaker={speaker.info}
					message={message}
					key={message.id || Date.now()}/>;
				cls = 'meta-' + speakerType;
				break;
		}

		if (!message.isRead && !speaker.info.isCurrentUser && (this.props.messageType !== 'meta')) {
			// console.log(this.props.messageType)
			cls += ' unread';
		}


		return (
			<li className={cls}>
				{messageItem}
			</li>
		);
	}
});
