import JSChannel from '../../../vendor/JSChannel';

const postAllMetadataMessagesToChildFrame = (trans, params, props, channel) => {
	// Uncomment below to enable "per-URL-filtering" of metadataExchangeMessages
	// WARNING: "this.props.url" is not correct if in DOM-cobrowsning (in that case, it's not the visitor's URL)
	// To enable "per-URL-filtering" we must think this through... (maybe use "originalSource"? Or last navigation in "navigations"?)
	// But then again, metadataExchangeMessages in DOM-cobrowsing has a lot of challenges anyway since scripts are stripped on visitor page, so skip for now...
	/*
			metadataMessages = metadataMessages.filter(metadataMsg => {
				//console.log('metadataMsg.metadataBag._vngage_url, this.props.url: ',metadataMsg.metadataBag._vngage_url, this.props.url);
				return (metadataMsg.metadataBag._vngage_url === this.props.url);
			});
	*/
	var metadataMessages = props.metadataExchangeMessages.toJS().map((msg) => {
		msg.metadataBag = msg.metadataBag || {};
		msg.metadataBag._vngage_recieveType = 'state';
		return msg;
	});
	//if (channel && !props.paused && metadataMessages.length > 0) {  // TODO: Decide how to deal with pause!
	if (channel && metadataMessages.length > 0) {
		channel.call({
			method: 'metadataExchange',
			params: metadataMessages.map(metadataMsg => metadataMsg.metadataBag), // Only send the metadataBag-content...
			success: response => {
				// console.log('postAllMetadataMessagesToChildFrame() post successful: ',response);
			}
		});
	}
};

const postNewMetadataMessagesToChildFrame = (metadataMessages, channel) => {
	// Same URL-filtering as suggested in function above?

	metadataMessages = metadataMessages.filter(metadataMsg => {
		// Only post new incoming messages to child
		return (metadataMsg.metadataBag._vngage_direction === 'in');
	});

	//if (this.state.channel && !this.props.paused && metadataMessages.length > 0) {  // TODO: Decide how to deal with pause!
	if (channel && metadataMessages.length > 0) {
		channel.call({
			method: 'metadataExchange',
			params: metadataMessages.map(metadataMsg => metadataMsg.metadataBag), // Only send the metadataBag-content...
			success: response => {
				// console.log('postNewMetadataMessagesToChildFrame() post successful: ',response);
			}
		});
	}
};

const whoAreYou = (trans, params, props) => {
	// New visitor versions in iframed child frames are supposed to immediately send a 'whoAreYou' to parent (this script) to complete the handshake
	const response = {
		identity: 'desktop',
		//version: vngage.info, // Is it possible to get build version in Workspace?
		accountId: props.accountId
	};
	if (!params || !params.accountId || params.accountId.toLowerCase() !== response.accountId.toLowerCase()) {
		// AccountId mismatch
		response.identity = 'invalid_desktop';
		props.cobroError('invalid desktop', {trans, params});
	} else {
		props.cobroLog('valid desktop', {trans, params});
	}
	return response;
};

const onPageLoaded = (trans, params, props, channel) => {
	const owner = props.url !== params.url
		? 'agent'
		: (props.lastUrl && props.lastUrl.get('url') !== params.url)
			? 'agent'
			: 'visitor';
	if (owner === 'agent' || props.paused) {
		props.pageLoaded(params.url);
	}
	props.setPageTitle(params.title);
	postAllMetadataMessagesToChildFrame(trans, params, props, channel); // To support URL-filtering: Also supply "params.url" in this call?
	// AND: Do we always want to post all MetadataExchangeMessages on page load? Could it be too much data?
	// Maybe instead implement a new call 'getConversationHistory' that replies with the conversation's full history, incl. metadata so that the child frame can request it when/if needed?
};

const updateHighlight = (highlightMode, channel) => {
	if (channel) {
		channel.call({
			method: 'highlight',
			params: highlightMode ? 'start': 'stop',
			success: resp => {
				// console.log('highlight', resp);
			}
		});
	}
};

const createChannel = (channelType, iFrameContentWindow, scope, channelReadyFunc, subscriberFunc, methodKeys) => {
	const channel = JSChannel.build({
		debugOutput: false,
		window: iFrameContentWindow,
		origin: '*',
		scope: scope,
		reconnect: true,
		onReady: (ch, trans) => {
			// [TL] The onReady()-handler uses modified JSChannel with the extra parameter "trans" to be able to validate origin here. Not yet in use...
			if (channelReadyFunc) {
				channelReadyFunc(ch);
			}
		}
	});
	methodKeys.forEach(method => {
		channel.bind(method, (...args) => subscriberFunc(channelType, method, ...args));
	});
	return channel;
};


export {
	postNewMetadataMessagesToChildFrame,
	whoAreYou,
	onPageLoaded,
	updateHighlight,
	createChannel,
}

