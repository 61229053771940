import React, {createContext, useContext} from 'react';

const LangContext = createContext();

const useGetTranslation = () => useContext(LangContext).getTranslation;


export {
	LangContext,
	useGetTranslation
}
