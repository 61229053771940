import immutable from 'immutable';
import DesktopPluginAPI from '../../pluginAPI/DesktopPluginAPI';

export const getSortedSubscriptions = (subscriptions, subscriptionPriority) => {
	return subscriptions.map(sub => {
		return {
			sub,
			prio: subscriptionPriority[sub] || subscriptionPriority.defaultSub
		};
	}).sort((a, b) => a.prio - b.prio)
		.map(sub => sub.sub);

};

// returns list with plugins if any
export const getPlugins = (desktopPluginsSettings) => {
	if (desktopPluginsSettings && desktopPluginsSettings.pluginsList) {
		const enabledPlugins = desktopPluginsSettings.pluginsList
			.filter(plugin => DesktopPluginAPI.apiVersionSupported(plugin.apiVersion))	// Only include plugins with an apiVersion supported in this Workspace build
			.filter(plugin => plugin.enabled);
		return enabledPlugins.map(plugin => {
			const actualApiVersion = DesktopPluginAPI.getActualVersion(plugin.apiVersion);
			const subscriptionPriority = DesktopPluginAPI.getSubscriptionPriority(actualApiVersion);
			return {
				...plugin,
				actualApiVersion,
				refreshCounter: 0,
				enabledSubscriptions: getSortedSubscriptions(plugin.enabledSubscriptions, subscriptionPriority)

			};
		});
	} else {
		return [];
	}
};
