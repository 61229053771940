import SystemAuthorizationAddModalTemplate from './SystemAuthorizationAddModalTemplate';

export default function ($uibModal, $state, systemConfiguration) {
	'ngInject';

	var vm = this;
	vm.allowedAuthorizations = systemConfiguration.allowedAuthorizations;

	vm.options = {
		stateLink: {
			state: 'root.system.authorization.edit',
			property: stateParameter
		},
		createItem: {
			langKey: 'systemAuthorization.ADD_HEADER',
			method: addNew
		},
		hideRemove: true,
		itemsPerPage: 50
	};
	vm.columns = [
		{
			header: 'systemAuthorization.RESOURCE',
			property: 'resource',
			sortDefault: true,
			useInFilter: true
		}, {
			header: 'systemAuthorization.ACTION',
			property: 'action',
			useInFilter: true
		}
	];


	function stateParameter(allowedAuthorization) {
		return encodeURIComponent(allowedAuthorization.resource + '&' + allowedAuthorization.action);
	}

	function openAddModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: SystemAuthorizationAddModalTemplate,
			controller: 'SystemAuthorizationAddModalController as vm'
		});
		modal.result.then(newAuth => {
			vm.allowedAuthorizations.push(newAuth);
			var newAuthUrlParam = encodeURIComponent(newAuth.resource + '&' + newAuth.action);
			$state.go('.edit', {id: newAuthUrlParam});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		$state.go('root.system.authorization').then(openAddModal);
	}

};
