import React from 'react';
import TimeFormat from '../../../utils/TimeFormat';

const MessageDivider = (props) => {
	const text = `${TimeFormat.asJustDate(props.timestamp)}`;
	return (
		<div className="message">
			<i className="vngage-icon-history"/>
			<span className="inner-white">{text}</span>
		</div>
	)
};


export default MessageDivider;

