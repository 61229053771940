import _assign from 'lodash/assign';

export default function(APIStatisticsService, StatisticsFilterService, APIBatchRequestService, $q) {
	'ngInject';

    var service = {
        query
    };

	function getParams(paramsObj) {
		var paramsStr = '?';
		for (var key in paramsObj) {
			paramsStr += (key + '=' + encodeURIComponent(paramsObj[key]) + '&');
		}
		return paramsStr.substr(0, paramsStr.length - 1);
	}

	function getTotal(arrPerDay) {
		var totalMonth = 0;
		arrPerDay.forEach(function(day) {
			totalMonth += day.number;
		});
		return totalMonth;
	}

	function formatResult(res) {
		return {
            totalVisitsThisMonth: getTotal(angular.isArray(res.items[0].body) ? [] : JSON.parse(res.items[0].body)),
			totalVisitsLastMonth: getTotal(angular.isArray(res.items[1].body) ? [] : JSON.parse(res.items[1].body))
		}
	}

	function getActions() {

		// Need to create new filters in case the browser is not refreshed at month shift
		var filterLastMonth = StatisticsFilterService.create({ period: 'month', range: 'lastMonth' });
		var filterThisMonth	= StatisticsFilterService.create({ period: 'month', range: 'thisMonth' });

		return [
			_assign({}, {
				method: APIStatisticsService.endpoints.queryTotalNumberOfVisitsPerDay.verb,
				url: APIStatisticsService.endpoints.queryTotalNumberOfVisitsPerDay.url + getParams(filterThisMonth.range)
			}),
			_assign({}, {
				method: APIStatisticsService.endpoints.queryTotalNumberOfVisitsPerDay.verb,
				url: APIStatisticsService.endpoints.queryTotalNumberOfVisitsPerDay.url + getParams(filterLastMonth.range)
			})
		]
	}

    function query() {
        return $q(function(resolve, reject) {
            APIBatchRequestService.execute(getActions())
            .then(function(res) {
                var formattedResult = formatResult(res);
                resolve(formattedResult);
            });
        })
    }

    return service;
}
