import _each from 'lodash/each';
import _groupBy from 'lodash/groupBy';

export default function ($controller, $scope, APIStatisticsService, StatisticsFilterService, StatisticsDefaultsService) {
	'ngInject';

		var userUsage = this;
		userUsage.loading = false;
		userUsage.statistics = null;

		activate();

		function activate() {
			$controller('BaseDashboardController', {query: queryUserUsage, $scope: $scope});
		}

		function queryUserUsage() {
			userUsage.loading = true;

			var val = {period: 'day', range: 'thisMonth'};

			function factory() {
				return {
					numberOfUsers: 0
				};
			}

			return APIStatisticsService.queryUserUsage(StatisticsFilterService.create(val)).then(function (res) {

				var result = process(res.data);

				userUsage.statistics = StatisticsDefaultsService.assignMissingDayValues(result, factory);
				userUsage.loading = false;
			});
		}

		function process(data) {
			var result = [];
			var groups = _groupBy(data, function (d) {
				return d.calculatedAt;
			});
			_each(groups, function (g) {

				var totalNumberOfUsers = g.reduce(function (numberOfUsers, v) {
					return numberOfUsers + v.numberOfUsers;
				}, 0);

				result.push({calculatedAt: g[0].calculatedAt, numberOfUsers: totalNumberOfUsers});
			});

			return result;
		}

	};
