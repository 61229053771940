import _findIndex from 'lodash/findIndex';

export default function() {
	'ngInject';

	var service = {
		positionSetter: positionSetter
	};

	function positionSetter(list) {
		var resourceList=list;

		function swapListPosition(resource, relativeIndex) {
			var index = getIndex(resource);
			resourceList[index] = resourceList.splice(index + relativeIndex, 1, resourceList[index])[0];
		}

		function getIndex(resource) {
			return _findIndex(resourceList, function(item) {
				return resource.id === item.id;
			});
		}

		return {
			canRaise: function(resource) {
				return getIndex(resource) > 0;
			},
			canLower: function(resource) {
				return getIndex(resource) < (resourceList.length - 1);
			},
			raise: function(resource) {
				swapListPosition(resource, -1);
			},
			lower: function(resource) {
				swapListPosition(resource, 1);
			},
			position: function(resource) {
				return getIndex(resource) + 1;
			},
			setList: function(list) {
				resourceList=list;
			}
		};
	}
	return service;
};
