import vngageConfig from '../vngageConfig';

export default function (APIConfigurationSectionService, APIEndpointService, $window) {
	'ngInject';

	var vm = this;

	// EXPORT TOOL
	vm.exportTypes = [
		'cannedResponse',
		'closure',
		'user'
	];
	vm.exportValuesForType = function (exportType) {
		switch (exportType) {
			case 'cannedResponse':
				APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.cannedResponse, true).then(function (res) {
					vm.exportedEntities = '';
					res.forEach(function (cannedResponse, index) {
						vm.exportedEntities = vm.exportedEntities +
							(index + 1).toString() +
							'\n' +
							cannedResponse.data.name +
							'\n' +
							cannedResponse.data.section.message +
							'\n\n';
					});
				});
				break;
			case 'closure':
				APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.closure, true).then(function (res) {
					vm.exportedEntities = '';
					res.forEach(function (closure, index) {
						vm.exportedEntities = vm.exportedEntities +
							(index + 1).toString() +
							'\n' +
							closure.data.name +
							'\n' +
							closure.data.section.outcome +
							'\n' +
							(closure.data.section.textToVisitor ? closure.data.section.textToVisitor : '') +
							'\n\n';
					});
				});
				break;

			case 'user':
				vm.exportedEntities = '';
				APIEndpointService.user.query({limit: 10000}).$promise.then(function (res) {
					res.forEach(function (user, index) {
						vm.exportedEntities = vm.exportedEntities +
							(index + 1).toString() +
							'\n' +
							user.data.profile.displayName +
							'\n' +
							user.data.id +
							'\n\n';
					});
				});
				break;
		}
	};

	// ENV TOOL
	vm.importAndActivateConfig = importAndActivateConfig;
	vm.removeTempEnv = removeTempEnv;

	try {
		if (sessionStorage.getItem('tempVngageConfig')) {
			vm.alreadyTemp = true;
		}
	} catch (e) {
		// Accessing sessionStorage may cause security exceptions - ignore...
	}

	function exportConfig() {
		var vngageTempConfig = {
			//config: $window.engage ? $window.engage.config : $window.config,
			config: vngageConfig,
			sessionId: localStorage.getItem('sessionId'),
			delegatedSessionId: localStorage.getItem('delegatedSessionId')
		};

		vm.exportedConfig = JSON.stringify(vngageTempConfig);
	}

	exportConfig();

	function importAndActivateConfig() {
		var configToImport = $window.prompt('The page will reload with the imported config activated');
		if (configToImport) {
			try {
				var tempVngageConfig = JSON.parse(configToImport);
				sessionStorage.setItem('tempVngageConfig', JSON.stringify(tempVngageConfig.config));
				localStorage.setItem('sessionId', tempVngageConfig.sessionId);
				localStorage.setItem('delegatedSessionId', tempVngageConfig.delegatedSessionId);
				$window.location.reload();
			} catch (e) {
				// Accessing sessionStorage may cause security exceptions - ignore...
			}
		}
	}

	function removeTempEnv() {
		if ($window.confirm('The page will reload with the temp config removed')) {
			try {
				sessionStorage.removeItem('tempVngageConfig');
				$window.location.reload();
			} catch (e) {
				// Accessing sessionStorage may cause security exceptions - ignore...
			}
		}
	}
}
