// add some padding between sections
var padding = 10;
var renderWidth = 0;
var renderFrom = 'left';
var flexibleElem = null;

var content = function (elemsArr) {
	//create content section
	var elem = document.getElementById('desktopLegacy');

	if (!elem) {
		return false;
	}
	this.elem = elem;

	// build sections
	var contentSections = {
		// stored data
		arrData: [],
		objData: {},
		// functions
		init: function (array) {
			var sortedData = helpers.sortLayoutArray(array);
			this.arrData = sortedData.arr;
			this.objData = sortedData.obj;
			this.render();
			//this.addOptions();
		},
		render: function () {
			helpers.renderWidth = 0;
			helpers.renderFrom = 'left';
			helpers.flexibleElem = null;
			if (this.arrData.length > 0) {
				for (var s in this.arrData) {
					var section = helpers.getKeyAndObj(this.arrData[s]);
					if (typeof this.objData[section.key].elem == 'undefined') {
						this.objData[section.key].elem = document.createElement('div');
						elem.appendChild(this.objData[section.key].elem);
					}
					helpers.setElemAttributes(this.objData[section.key].elem, section.key, section.obj);
				}
				helpers.setFlexibleWidth();
			}
		},
		addOptions: function () {
			//var sections = {};
			//for (var s in this.objData) {
			//	var obj = this.objData[s];
			//	switch (obj.conf.type) {
			//		case 'section':
			//			break;
			//	}
			//}
		}
	};
	contentSections.init(elemsArr);
	this.sections = contentSections.objData;

};

var helpers = {
	renderWidth: 0,
	renderFrom: 'left',
	flexibleElem: null,
	sortLayoutArray: function (arr) {
		var res = {arr: [], obj: {}};

		var arrLength = arr.length;
		var f = 'left';
		var i = 0;
		for (var s in arr) {
			var obj = this.getKeyAndObj(arr[s]);
			if (obj.obj.width != 'flexible' && f == 'left') {
				res.arr.push(arr[i]);
				res.obj[obj.key] = {
					conf: obj.obj,
					key: obj.key,
					arrPos: i
				};
				i++;
			} else {
				res.arr.push(arr[i]);
				res.obj[obj.key] = {
					conf: obj.obj,
					key: obj.key,
					arrPos: i
				};
				if (f == 'left') {
					i = arrLength;
					f = 'right';
				}
				i = i - 1;
			}
		}
		return res;
	},
	getKeyAndObj: function (obj) {
		var res = {
			key: false,
			obj: {}
		};
		for (var o in obj) {
			res.key = o;
			res.obj = obj[o];
		}
		return res;
	},
	zIndex: 100,
	setElemAttributes: function (elem, key, params) {
		elem.style.position = 'absolute';
		elem.style[this.renderFrom] = this.renderWidth + 'px';
		if (params.width == 'flexible') {
			elem.style.width = 'auto';
			this.flexibleElem = elem;
			this.renderFrom = 'right';
			this.renderWidth = 0;

		} else {
			if (typeof params.collapsableOnlyOnWindowLessThan !== 'undefined') {
				var windowSize = psPlugin.core.jsExt.windowSize();
				//console.log('windowSize', windowSize.width,params.collapsableOnlyOnWindowLessThan);
				if (params.collapsableOnlyOnWindowLessThan > windowSize.width) {
					params.collapsable = true;
					elem.style.width = params.width + 'px';
					this.renderWidth = this.renderWidth + params.width + padding;
				} else {
					params.collapsable = false;
					elem.style.width = params.expandWidth + 'px';
					this.renderWidth = this.renderWidth + params.expandWidth + padding;
				}
			} else {
				elem.style.width = params.width + 'px';
				this.renderWidth = this.renderWidth + params.width + padding;
			}
		}
		// TODO: (remove) this is only to visualize the areas while testing
		//elem.style.backgroundColor = '#fff';
		//elem.style.opacity = '0.5';
		elem.id = '___contentSection' + key;
		// end
		elem.style.paddingTop = padding + 'px';
		elem.style.top = '0';
		elem.style.bottom = '0';

		// TODO: really clean up this file... rewrite is probably best
		if (typeof params.collapsable != 'undefined' && params.collapsable) {
			elem.data = false;
			elem.style.zIndex = 1000 + this.zIndex;
			this.zIndex--;
			elem.className = elem.className + ' transitionAll';
			if (params.expandableOn == 'onmouseover') {
				elem.onclick = function () {
					this.style.width = params.expandWidth + 'px';
				};
			}
			elem[params.expandableOn] = function () {
				if (params.expandableOn == 'onmouseover') {
					var self = this;
					if (!elem.data) {
						elem.data = setTimeout(function () {
							self.style.width = params.expandWidth + 'px';
						}, 300);
					}
				} else {
					this.style.width = params.expandWidth + 'px';
				}
				//psHelperLib.changeWidth('actionsMenu',180,200);
			};
			elem.onmouseout = function (e) {
				clearTimeout(this.data);
				this.data = false;
				//if(psHelperLib.__checkForValidMouseOut(e,elem)) psHelperLib.delayAndRunAnim('changeWidth',1000,'actionsMenu',30,200);
				if (psPlugin.core.jsExt.checkForValidMouseOut(e, elem)) {
					this.style.width = params.width + 'px';

					if (typeof params.onCollapse === 'function') {
						params.onCollapse();
					}
				}

				//this.style.width = params.width + 'px';
			};
		}

		return elem;
	},

	// handleAreaOnWindowSize: function (elem, params) {
	//     elem.style.zIndex = 1000 + helpers.zIndex;
	//     helpers.zIndex--;
	//     elem.className = elem.className + ' transitionAll';
	//     var listenerFunction = function () {
	//         var windowSize = psPlugin.core.jsExt.windowSize();
	//         console.log('windowSize', windowSize.width,params.collapsableOnlyOnWindowLessThan);
	//         if (params.collapsableOnlyOnWindowLessThan > windowSize.width) {
	//             elem[params.expandableOn] = function () {
	//                 //psHelperLib.changeWidth('actionsMenu',180,200);
	//                 this.style.width = params.expandWidth + 'px';
	//             };
	//             elem.onmouseout = function (e) {
	//                 //if(psHelperLib.__checkForValidMouseOut(e,elem)) psHelperLib.delayAndRunAnim('changeWidth',1000,'actionsMenu',30,200);
	//                 if (psPlugin.core.jsExt.checkForValidMouseOut(e, elem)) this.style.width = params.width + 'px';
	//                 //this.style.width = params.width + 'px';
	//             };
	//             elem.style.width = params.width + 'px';
	//         } else {
	//             elem[params.expandableOn] = function () { return false; };
	//             elem.onmouseout = function () { return false; };
	//             elem.style.width = params.expandWidth + 'px';
	//         }
	//     };
	//     psPlugin.core.jsExt.addEventListener("resize", window, function () {
	//         listenerFunction();
	//     });
	//     this.close = function () {
	//         psPlugin.core.jsExt.removeEventListener("resize", window, listenerFunction);
	//     };
	//     listenerFunction();
	// },

	setFlexibleWidth: function () {
		this.flexibleElem.style.right = this.renderWidth + 'px';
	}
};


export default {
	content: content,
	rightPopOut: function (params) { console.log('deprecated'); }
};
