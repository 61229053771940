import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';

export default createReactClass({

	onToggle () {
		let dropdown = ReactDOM.findDOMNode(this.refs.dropdown);
		dropdown.classList.toggle('open');
	},

	render () {
		return (
			<div ref="dropdown" className="dropdown">
				<span id="participant-status" className="dropdown-toggle vngage-icon-torso" data-toggle="dropdown" onClick={this.onToggle} aria-expanded="true">{this.props.title}<span className="caret"></span></span>
				<ul className="meta-participants dropdown-menu" role="menu" aria-labelledby="participant-status">
					{this.props.children}
				</ul>
			</div>
		);
	}
});
