import '../../common/VngageWorkspaceTranslationModule';

export default function ($q, APIEndpointService, AccountSitesService, SessionService, AuthKeys, TimeZoneService, VngageWorkspaceTranslationService, WhiteLabelService, WorkspaceSettingsService) {
	'ngInject';

	const defaultAwayReason = {name: 'Away', id: 0};
	let userPresence    = getPresence(),
		userAwayReason  = defaultAwayReason,
		authDataResolve = angular.noop,
		authDataPromise = $q(function (resolve) {
			authDataResolve = resolve;
		});
	let integrationEnabled = false;

	function prime() {
		return $q(function (resolve, reject) {
			// This should *REALLY* look a lot better with async-await...
			// Any "reject()" inside any function in the promise-chain will stop it and go to "catch()", which rejects prime
			// For the chain to continue, the functions must resolve()...
			// ...or if a rejection in one function is ok and can be handled, the following then() can implement an error-handler and the chain will continue...
			// Resolved values in a function will be input to the next handler in the chain

			// TODO: We also want a WorkspaceSettingsService that caches workspaceSettings, etc!!
			return getUser()
				.then(setUserWorkspaceLanguage)
				.then(prepareTimeZone)
				.then(fetchAccountAndAuth)
				.then(setWorkspaceProperties)
				.then(tryGetIntegrationData)
				.then(initAwayReasons)
				.then(authDataLoaded)
				.then(getSites)
				.then(() => {
					// prime done ok
					resolve();
				})
				.catch(() => {
					//console.error('prime failed');
					reject();
				});
		});
	}

	/*
	const prime = () => {
		// With async-await, the above function would look something like this...
		// (wrapped in an Angular $q, since all calls are from Angular and rejects need to go though $q's reject() for angular to notice it)
		// Note that all functions called below should probably also be converted to async-await, to avoid "Unhandled reject"-warnings from Angular...
		return $q(async (resolve, reject) => {
			try {
				const user = await getUser();
				await setUserWorkspaceLanguage(user);
				await prepareTimeZone();
				const accountId = await fetchAccountAndAuth();
				await setWorkspaceProperties(accountId);
				await tryGetIntegrationData();
				await initAwayReasons();
				await authDataLoaded();
				await getSites();
				// prime done ok
				resolve();
			} catch (e) {
				// console.error('prime failed', e);
				reject(e);
			}
		});
	};
	*/

	function getUser() {
		return $q(function (resolve, reject) {
			// NOTE: this is the first call of the workspace, don't add anything in here
			if (isAuthenticated()) {
				APIEndpointService.userMe.tryGet().$promise
					.then(res => {
						SessionService.setUser(res.data);
						resolve(res.data);
					}, () => {
						SessionService.clearSessionId();
						reject('invalid_session');
					});
			} else {
				// We have no sessionId, so no use trying to GET "/User/Me" (would always fail)...
				SessionService.clearSessionId();
				reject('no_session');
			}
		});
	}

	function setUserWorkspaceLanguage(user) {
		const langCode = (user && user.profile && user.profile.language || 'en-GB');
		return VngageWorkspaceTranslationService.setWorkspaceLanguage(langCode);
	}

	function prepareTimeZone() {
		return $q(function (resolve) {
			if (SessionService.userHasTimezone()) {
				// [TL] Optimization: Don't fetch APIEndpointService.accountCurrentConfiguration unless needed (saves one call if the user has timezone)
				TimeZoneService.query().then(function (timezones) {
					//SessionService.setUserTimezoneOffset(timezones, res.data.timeZoneId);
					resolve();
				});
			} else {
				APIEndpointService.accountCurrentConfiguration.get().$promise
					.then(function (res) {
						if (res.data.timeZoneId) {
							TimeZoneService.query().then(function (timezones) {
								//SessionService.setUserTimezoneOffset(timezones, res.data.timeZoneId);
								resolve();
							});
						} else {
							resolve();
						}
					}, resolve);
			}
		});
	}

	function fetchAccountAndAuth() {
		return $q(function (resolve, reject) {
			$q.all([
				APIEndpointService.accountCurrent.get().$promise,
				APIEndpointService.sessionAuthorizations.query().$promise
			]).then(function (res) {
				SessionService.setAccount(res[0].data);
				SessionService.setAuthorizations(res[1]);
				resolve(res[0].data.id); // resolve with accountId for input to next prime-handler in chain...
			}, function () {
				console.error('prime: Could not get accountCurrent or sessionAuthorizations');
				reject();
			});
		});
	}

	function setWorkspaceProperties(accountId) {
		return $q(function (resolve) {
			if (!accountId) {
				// No accountId => Fallback to default whiteLabelConfig
				WhiteLabelService.injectWhiteLabelConfig(null);
				resolve();
			} else {
				WorkspaceSettingsService.forceGetSections('general').then(generalWorkspaceSettings => {
					if (generalWorkspaceSettings) {
						WhiteLabelService.injectWhiteLabelConfig(generalWorkspaceSettings.whiteLabel);

						const integrations = generalWorkspaceSettings.integration
							? generalWorkspaceSettings.integration
							: {};
						service.integrationEnabled = Object.keys(integrations).some(key => integrations[key] && integrations[key].enabled);
						// service.integrationEnabled  = true;

					} else {
						WhiteLabelService.injectWhiteLabelConfig(null);
					}
					resolve();
				}, () => {
					WhiteLabelService.injectWhiteLabelConfig(null);
					resolve();
				});
			}
		});
	}

	function tryGetIntegrationData() {
		return $q(function (resolve) {
			if (service.integrationEnabled) {
				APIEndpointService.sessionBucket.tryGet({key: AuthKeys.userAwayReason}).$promise
					.then(response => {
						service.awayReason = {id: response.id, name: response.name};
						resolve();
					}, () => {
						resolve();
					});
			} else {
				resolve();
			}
		});
	}

	function authDataLoaded() {
		return $q(function (resolve) {
			// Resolve the authorizationDataLoadedPromise
			// This will trigger move from "starting"-state to "dashboard" (or what state the user was trying to go before login) and stop the spinner
			authDataResolve();
			resolve();
		});
	}

	function getSites() {
		return AccountSitesService.getSites();
	}

	function isAuthenticated() {
		return SessionService.isAuthenticated();
	}

	function currentlyDelegated() {
		return SessionService.isUserDelegated();
	}

	function authorizationDataLoaded() {
		return SessionService.loaded();
	}

	function authorizationDataLoadedPromise() {
		return authDataPromise;
	}

	function getPresence() {
		return localStorage.getItem(AuthKeys.userPresence) || 'online';
	}

	function _setAwayReason(awayReasonId) {
		const awayReason = service.awayReasons.find(reason => reason.id === awayReasonId) || defaultAwayReason;
		return $q(function (resolve) {
			APIEndpointService.sessionBucket.post({key: AuthKeys.userAwayReason}, {...awayReason}).$promise
				.then(response => {
					localStorage.setItem(AuthKeys.userAwayReason, JSON.stringify(awayReason)); // Notify other tabs/windows
					service.awayReason = awayReason;
					resolve();
				}, (err) => {
					console.error('Error posting sessionBucket:', err);
					resolve();
				});
		});
	}

	function initAwayReasons() {
		return $q(function (resolve) {
			const reasons = localStorage.getItem(AuthKeys.userAwayReasons);
			service.awayReasons = JSON.parse(reasons);
			resolve();
		});
	}

	function setAwayReasons(reasons) {
		// Shouldn't we store the reasons in localStorage "AuthKeys.userAwayReasons" here as well?
		// The initAwayReasons() above reads it from localStorage, but it's never set anywhere in our code...
		service.awayReasons = JSON.parse(reasons) || [];
	}

	function setAwayReason(awayReason) {
		service.awayReason = JSON.parse(awayReason) || {};
	}

	function setPresence(presence, awayReasonId) {
		if (presence === service.presence && awayReasonId === service.awayReason.id) {
			return false;
		}
		localStorage.setItem(AuthKeys.userPresence, presence);
		service.presence = presence;
		if (service.integrationEnabled && awayReasonId !== undefined && awayReasonId !== null && awayReasonId !== service.awayReason.id) {
			_setAwayReason(awayReasonId);
		}
	}

	function setForcedAwayVisibility(state) {
		localStorage.setItem(AuthKeys.forcedAway, state);
	}

	function getForcedAwayVisibility() {
		return localStorage.getItem(AuthKeys.forcedAway) || 'hidden';
	}

	const service = {
		prime: prime,
		getUser: getUser,
		presence: userPresence,
		awayReason: userAwayReason,
		awayReasons: [],	// Why empty array?
		setAwayReasons: setAwayReasons,
		setAwayReason: setAwayReason,
		integrationEnabled,
		getForcedAwayVisibility: getForcedAwayVisibility,
		setForcedAwayVisibility: setForcedAwayVisibility,
		getPresence: getPresence,
		setPresence: setPresence,
		isAuthenticated: isAuthenticated,
		currentlyDelegated: currentlyDelegated,
		authorizationDataLoaded: authorizationDataLoaded,
		authorizationDataLoadedPromise: authorizationDataLoadedPromise
	};

	return service;
}
