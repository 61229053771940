import './action/ActionModule';
import './actionPanel/ActionPanelModule';
import './cannedResponse/CannedResponseModule';
import './caseType/CaseTypeModule';
import './closure/ClosureModule';
import './form/FormModule';
import './group/GroupModule';
import './renderTemplate/RenderTemplateModule';
import './urlCategory/UrlCategoryModule';
import configureTranslations from './ConfigureTranslations';
import ConfigureContentTemplate from './ConfigureContentTemplate';
import ConfigureMenuTemplate from './ConfigureMenuTemplate';
import '../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.configure', [
	'ui.router',
	'vngageApp.configure.action',
	'vngageApp.configure.actionPanel',
	'vngageApp.configure.cannedResponse',
	'vngageApp.configure.caseType',
	'vngageApp.configure.closure',
	'vngageApp.configure.form',
	'vngageApp.configure.group',
	'vngageApp.configure.renderTemplate',
	'vngageApp.configure.urlCategory',
	'vngageApp.workspaceTranslation'
])

	.constant('configureTranslations', configureTranslations)

	.constant('configureModuleConfig', {
		state: 'root.configure',
		access: 'Account'
	})

	.config(function ($stateProvider, $urlRouterProvider, $translateProvider, configureTranslations, configureModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(configureTranslations);

		$urlRouterProvider.when('/configure/', function (transitionService,
			actionModuleConfig,
			actionPanelModuleConfig,
			cannedResponseModuleConfig,
			caseTypeModuleConfig,
			closureModuleConfig,
			formModuleConfig,
			groupModuleConfig,
			urlCategoryModuleConfig) {
			'ngInject';

			transitionService.navigate([
				actionModuleConfig,
				actionPanelModuleConfig,
				cannedResponseModuleConfig,
				caseTypeModuleConfig,
				closureModuleConfig,
				formModuleConfig,
				groupModuleConfig,
				urlCategoryModuleConfig
			]);
		});

		$stateProvider.state(configureModuleConfig.state, {
			url: '/configure/',
			views: {
				content: {
					templateUrl: ConfigureContentTemplate
				},
				menu: {
					templateUrl: ConfigureMenuTemplate
				}
			},
			data: {
				access: configureModuleConfig.access,
				ncyBreadcrumbLabel: 'Configure'
			}
		});
	});
