const getId = (item) => {
	return item.data && item.data.id
		? item.data.id
		: item.id;
};

// get item to show when current item is removed
// usage: $state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
// will either go to next item, previous item, or to list
export function getItemNavigationDataAfterRemove(itemToRemove, route, currentList, backRoute = '^') {

	const itemToRemoveId = getId(itemToRemove);
	const removeIndex = currentList.findIndex(item => getId(item) === itemToRemoveId);

	const nextItem = removeIndex < (currentList.length - 1)
		? currentList[removeIndex + 1]
		: currentList.length > 1
			? currentList[removeIndex - 1]
			: null;

	currentList = [...currentList.slice(0, removeIndex), ...currentList.slice(removeIndex + 1)];
	return nextItem
		? {
			route,
			params: {
				id: getId(nextItem),
				currentList,
			}
		}
		: {
			route: backRoute,
			params: null
		};
}

