import SystemAuthorizationAddModalController from './SystemAuthorizationAddModalController';
import SystemAuthorizationEditController from './SystemAuthorizationEditController';
import SystemAuthorizationListController from './SystemAuthorizationListController';
import systemAuthorizationTranslations from './SystemAuthorizationTranslations';
import SystemAuthorizationListTemplate from './SystemAuthorizationListTemplate';
import SystemAuthorizationEditTemplate from './SystemAuthorizationEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.system.authorization', [
    'ui.router',
    'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

.controller('SystemAuthorizationAddModalController', SystemAuthorizationAddModalController)
.controller('SystemAuthorizationEditController', SystemAuthorizationEditController)
.controller('SystemAuthorizationListController', SystemAuthorizationListController)
.constant('systemAuthorizationTranslations', systemAuthorizationTranslations)

.constant('systemAuthorizationModuleConfig', {
    state: 'root.system.authorization',
    access: 'System/Authorizations'
})

.config(function($stateProvider, $translateProvider, systemAuthorizationTranslations, systemAuthorizationModuleConfig, VngageWorkspaceTranslationServiceProvider) {
	'ngInject';
	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(systemAuthorizationTranslations);

    $stateProvider.state(systemAuthorizationModuleConfig.state, {
        url: 'authorization/',
        controller: 'SystemAuthorizationListController as vm',
        templateUrl: SystemAuthorizationListTemplate,
        data: {
            ncyBreadcrumbLabel: 'Authorizations',
            access: systemAuthorizationModuleConfig.access
        },
        resolve: {
            systemConfiguration: function(APIEndpointService) {
				'ngInject';
                return APIEndpointService.systemConfiguration.get().$promise;
            }
        }
    });

    $stateProvider.state('root.system.authorization.edit', {
        url: ':id',
        controller: 'SystemAuthorizationEditController as vm',
        templateUrl: SystemAuthorizationEditTemplate,
        data: {
            ncyBreadcrumbLabel: 'Edit',
            access: { action:'GET', resource: 'SystemConfiguration' }
        },
        resolve: {
            allowedAuthorization: function($stateParams, systemConfiguration, $q, VngageUtil) {
				'ngInject';
                return $q(function(resolve, reject) {
					var authorizationMatches,
						searchKeys = decodeURIComponent($stateParams.id).split('&');

					systemConfiguration.allowedAuthorizations.some(function(auth) {
						authorizationMatches = auth.resource === searchKeys[0] && auth.action === searchKeys[1];
						if (authorizationMatches) {
							resolve(auth);
							return true;
						}
					});

					if (!authorizationMatches) {
						reject(VngageUtil.resourceNotFoundMessage);
					}
				});

            }
        }
    });
});
