export default function() {
    'ngInject';

    var service = {
        generateFiltrexForOneCondition: generateFiltrexForOneCondition,
        generateFiltrexForAllConditions: generateFiltrexForAllConditions,
        availableAdditionalFunctions: {
            contains: contains
        },
        textTrimming: {
            removeLastChar: function(str) {
                return str.substring(0, str.length - 1);
            },
            removeNewlines: function(str) {
                return str.replace(/(\r\n|\n|\r)/gm,'');
            },
            removeMultipleWhitespaces: function(str) {
                return str.replace(/\s{2,}/g, ' ') + ' }';
            }
        }
    };

    function contains(fullStr, partialStr) {
        return !!~fullStr.indexOf(partialStr);
    }

    function generateFiltrexForOneCondition(condition) {
        var expr = condition.expression;
        var resultObj = {
            filtrexString: '',
            additionalFunctions: {},
            dataObjectVariables: {}
        };
        var operatorMap = {
            equal:          '==',
            notEqual:       '!=',
            greaterThan:    '>',
            lessThan:       '<'
        };
        var operatorValue = null;
        var secondExpr = null;

        resultObj.filtrexString = '';
        var exprFieldType = expr.field.type;

        if ((exprFieldType !== 'visitor') && (exprFieldType !== 'navigation')) {
            exprFieldType = 'POI' + exprFieldType.replace(/-/g, '');
        }

        if (expr.operator.value === 'contains' || expr.operator.value === 'notContain') {
            // the additional function 'contains' is needed for this condition
            resultObj.additionalFunctions.contains = service.availableAdditionalFunctions.contains;

            secondExpr = 'contains(' + 'poi.' + exprFieldType + '.' + expr.field.value + ', "' + expr.value.value + '")';
            if (expr.operator.value === 'notContain') {
                secondExpr = '(not ' + secondExpr + ')';
            }
        } else {
            operatorValue = operatorMap[expr.operator.value] || expr.operator.value;
            secondExpr = '(' + 'poi.' + exprFieldType + '.' + expr.field.value + ' ' + operatorValue + ' "' + expr.value.value + '"' + ')';
        }

        resultObj.filtrexString += secondExpr;

        if (condition.operator) {
            resultObj.filtrexString = ' ' + condition.operator + ' ' + resultObj.filtrexString;
        }

        return resultObj;
    }

    function generateFiltrexForAllConditions(condition) {
        var resultObj = {
            filtrexString: '',
            additionalFunctions: {}
        };

        function addStringAndFunctionsForCondition(condition, resultObj) {
            var generatedFiltrexForOneCondition = generateFiltrexForOneCondition(condition);
            resultObj.filtrexString += generatedFiltrexForOneCondition.filtrexString;
            angular.forEach(generatedFiltrexForOneCondition.additionalFunctions, function(value, key) {
                resultObj.additionalFunctions[key] = value;
            });
        }

        addStringAndFunctionsForCondition(condition, resultObj);

        if (condition.conditions) {
            condition.conditions.forEach(function(nestedCond) {
                addStringAndFunctionsForCondition(nestedCond, resultObj);
            });
        }

        return resultObj;
    }

    return service;
};
