export default {
    'en-GB': {
        engage: {

        }
    },
    'sv-SE': {
        engage: {

        }
    }
};
