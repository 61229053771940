export default {
		guid: /^[0-9A-Fa-f]{8}\-[0-9A-Fa-f]{4}\-[0-9A-Fa-f]{4}\-[0-9A-Fa-f]{4}\-[0-9A-Fa-f]{12}$/,
		url: /^((https?:)?\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
		secureUrl: /(https:\/\/([^\s("&]+\.[^\s("\.\:]+))/,
		size: /^(0|\d{1,4}(px|%|em))$/,
		color: /^(#[A-Fa-f0-9]{3}([A-Fa-f0-9]{3})?|(rgba?\(\d{1,3},\s?\d{1,3},\s?\d{1,3}(,\s?([01]|0?\.[0-9]+))?\)))$/,
		hexColor: /^#[A-Fa-f0-9]{3}([A-Fa-f0-9]{3})?$/,
		accountDisplayName: /[\S]+[\S ]+/,
		hostWhitelist: /^([-a-zA-Z0-9._\+~#=]{2,256})|(\*)\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.]*)$/,
		hoursMinutes: /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/,
		domain: /^([a-z0-9\-]+\.){1,2}[a-z]{2,4}/
	};
