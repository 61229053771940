import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import immutable from 'immutable';
import Conversation from '../../actionCreators/Conversation';
import CoBrowsing from '../../actionCreators/CoBrowsing';
import AccountConfig from './../../utils/AccountConfig';
import ToolBarSectionType from '../../constants/ToolBarSectionType';
import ToolBarButton from './ToolBarButton.react';
import {useGetTranslation} from './../LangContext';
import PluginButton from './PluginButton.react';
import VisitorProfileSelectors from '../../selectors/VisitorProfileSelectors';

export const ToolBar = (props) => {
	const getTranslation = useGetTranslation();

	const {
			  conversationId,
			  groupId,
			  video,
			  modal,
			  activeSections,
			  reservation,
			  plugins,
			  pluginButtons,
			  hasVisitorProfile,
			  isMaximized,
			  connectionStatus,
			  enabledCapabilities,
			  hasWarningSign,
			  toggleCoBro,
			  toggleSection
		  } = props;


	const toggleCoBroCallback = useCallback(() => {
		toggleCoBro(conversationId);
	}, [conversationId, toggleCoBro]);

	const toggleSectionCallback = useCallback((sectionType) => {
		toggleSection(conversationId, sectionType);
	}, [conversationId, toggleSection]);

	const buttons = [];
	const isVideoChatEnabled = !!enabledCapabilities.get('video');
	const isWebCallEnabled = !!enabledCapabilities.get('webCall');
	const isCoBroEnabled = !!enabledCapabilities.get('coBro');

	const roomId = video.get('roomId');
	const comType = video.get('comType');
	const rtcReady = !roomId;
	const currentIsActive = roomId === conversationId;
	const videoButtonEnabled = connectionStatus !== 'terminate' && (rtcReady || (currentIsActive && comType === 'video' && !modal));
	const webCallButtonEnabled = connectionStatus !== 'terminate' && (rtcReady || (currentIsActive && comType === 'webCall'));

	let payload = {
		sectionName: ToolBarSectionType.CASE_MANAGER,
		active: !!activeSections.get(ToolBarSectionType.CASE_MANAGER),
		disabled: false,
		toggleSection: toggleSectionCallback,
		tooltipText: getTranslation('cmTitle'),
		icon: 'vngage-icon-case-mange-line',
		hasWarningSign: hasWarningSign
	};
	buttons.push(<ToolBarButton key={ToolBarSectionType.CASE_MANAGER} {...payload} />);

	payload = {
		sectionName: ToolBarSectionType.VISITOR_PROFILE,
		active: !!activeSections.get(ToolBarSectionType.VISITOR_PROFILE),
		disabled: !hasVisitorProfile,
		toggleSection: toggleSectionCallback,
		tooltipText: getTranslation('visitorProfileDataTitle'),
		icon: 'vngage-icon-user-line',
	};
	buttons.push(<ToolBarButton key={ToolBarSectionType.VISITOR_PROFILE} {...payload} />);

	if (reservation) {
		payload = {
			sectionName: ToolBarSectionType.RESERVATION,
			active: !!activeSections.get(ToolBarSectionType.RESERVATION),
			disabled: false,
			toggleSection: toggleSectionCallback,
			tooltipText: getTranslation('reservationInfoHeader'),
			icon: 'vngage-icon-calendar'
		};
		buttons.push(<ToolBarButton key={ToolBarSectionType.RESERVATION} {...payload} />);
	}

	if (isVideoChatEnabled) {
		const videoTooltip = activeSections.get(ToolBarSectionType.VIDEO)
			? getTranslation('videoChatButtonStop')
			: getTranslation('videoChatButtonStart');

		payload = {
			sectionName: ToolBarSectionType.VIDEO,
			active: !!activeSections.get(ToolBarSectionType.VIDEO),
			disabled: !videoButtonEnabled,
			toggleSection: toggleSectionCallback,
			tooltipText: videoTooltip,
			icon: 'vngage-icon-videocam-1'
		};
		buttons.push(<ToolBarButton key={ToolBarSectionType.VIDEO} {...payload} />);
	}

	if (isWebCallEnabled) {
		const webCallTooltip = activeSections.get(ToolBarSectionType.WEB_CALL)
			? getTranslation('webCallButtonStop')
			: getTranslation('webCallButtonStart');

		payload = {
			sectionName: ToolBarSectionType.WEB_CALL,
			active: !!activeSections.get(ToolBarSectionType.WEB_CALL),
			disabled: !webCallButtonEnabled,
			toggleSection: toggleSectionCallback,
			tooltipText: webCallTooltip,
			icon: 'vngage-icon-phone mirrored-x'
		};
		buttons.push(<ToolBarButton key={ToolBarSectionType.WEB_CALL} {...payload} />);
	}

	if (isCoBroEnabled) {
		const coBroTooltip = isMaximized
			? getTranslation('actionExitCoBrowse')
			: getTranslation('actionInitCoBrowse');

		payload = {
			sectionName: ToolBarSectionType.COBRO,
			active: isMaximized,
			disabled: !isMaximized && connectionStatus === 'terminate',
			toggleSection: toggleCoBroCallback,
			tooltipText: coBroTooltip,
			icon: 'vngage-icon-ico_cobrowsing',
		};
		buttons.push(<ToolBarButton key={ToolBarSectionType.COBRO} {...payload} />);
	}

	plugins
		.filter(plugin => {
			return pluginButtons.has(plugin.get('id'))
				&& plugin.get('enabled')
				&& plugin.get('enabledGroups').includes(groupId);
		})
		.forEach(plugin => {
			const pluginId = plugin.get('id');
			const buttonState = pluginButtons.get(pluginId) || 'disabled';
			buttons.push(<PluginButton
				key={pluginId}
				conversationId={conversationId}
				buttonState={buttonState}
				plugin={plugin}
			/>);
		});

	return (
		<div className="toolbar">
			{buttons}
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	// INCOMING
	// conversationId
	const conversationId = ownProps.conversationId;
	const conversation = state.getIn(['conversations', conversationId]) || immutable.fromJS({events: []});
	const visitId = conversation.get('visitId');

	const groupId = conversation.get('groupId');
	const video = state.get('video');
	const modal = state.get('modal');
	const activeSections = conversation.getIn(['UI', 'activeSections']);

	const events = conversation.get('events') || immutable.List();
	const event = events.findLast(event => event.get('type') === 'conversationReservation');
	const reservation = event
		? event.get('reservation')
		: null;

	const plugins = state.get('plugins');
	const pluginButtons = conversation.getIn(['UI', 'pluginButtons']) || immutable.Map();

	const displayVisitorProfile = !!state.getIn(['account', 'displayVisitorProfile']);
	const visitorProfile = state.getIn(['visitorProfiles', visitId]) || immutable.Map();
	const authenticationLevel = visitorProfile.getIn(['data', 'authenticationLevel']) || null;
	const hasVisitorProfile = displayVisitorProfile;
	// const hasVisitorProfile = displayVisitorProfile && (authenticationLevel === 'Identified' || VisitorProfileSelectors.hasClaims(visitorProfile));

	const isMaximized = conversation.getIn(['UI', 'panelPosition']) === 'coBro';
	const connectionStatus = conversation.get('connectionStatus');

	const enabledCapabilities = conversation.getIn(['capabilities', 'enabled']) || immutable.Map();

	const currentCaseType = conversation.get('currentCaseType');
	const okToClose = (AccountConfig.caseTypeHasForm(currentCaseType) && conversation.get('forms').size > 0 && conversation.get('forms').every(form => form.get('isValid'))) || !AccountConfig.caseTypeHasForm(currentCaseType);
	const hasWarningSign = !okToClose;

	return {
		conversationId,
		groupId,
		video,
		modal,
		activeSections,
		reservation,
		plugins,
		pluginButtons,
		hasVisitorProfile,
		isMaximized,
		connectionStatus,
		enabledCapabilities,
		hasWarningSign
	};
};

function mapDispatchToProps(dispatch) {
	return {
		toggleSection: (conversationId, sectionType) => dispatch(Conversation.toggleSection(conversationId, sectionType)),
		toggleCoBro: (conversationId) => dispatch(CoBrowsing.toggleCoBro(conversationId)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolBar);

