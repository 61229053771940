import immutable from 'immutable';
import INITIAL_STATE from '../INITIAL_STATE';
import AppConstants from '../../../components/constants/AppConstants';
import {playback as playback_initial} from './PlaybackReducers';
let playback = playback_initial;	// We need to put this in a local var to be able to re-assign it on hot-reload

const {Fakes, ActionTypes} = AppConstants;
const maxHistorySize = 10000;
let history = [];
let origState = INITIAL_STATE;
const bannedActions = [Fakes.NEW_STATE_DEBUG, ActionTypes.SET_DEBUG_FLAG];

function mergeActionsTo(pos) {
	pos++;

	return history
		.slice(0, pos)
		.reduce((prevState, action) => {
			return playback(prevState, action.get('action').toJS());
		}, origState);
}

function mergeActionsToState(startPos, endPos, state) {
	endPos++;
	return history
		.slice(startPos, endPos)
		.reduce((prevState, action) => {
			return playback(prevState, action.get('action').toJS());
		}, state);
}

// old function slow function, to be removed
function _getStatesObj(interval) {
	// IE .fill fix
	const a = Array.apply(null, new Array(Math.floor(history.length / interval) + 1)).map(Number.prototype.valueOf, 0);
	return {
		interval,
		states: a.map((dummy, index) => mergeActionsTo(index * interval - 1))
	};
}

function getStatesObj(interval) {
	const states = history.reduce((prevObj, action, index) => {
		const newState = playback(prevObj.state, action.get('action').toJS());
		const newList = prevObj.list.concat((index + 1) % interval === 0 ? [newState]: []);
		return {
			state: newState,
			list: newList
		};
	}, {state: origState, list: [origState]}).list;

	return {
		interval,
		states
	};

}

function getStateUsingStatesObj(pos, statesObj) {
	const statePos = Math.floor(pos / statesObj.interval);
	const state = statesObj.states[statePos];
	return mergeActionsToState(statePos * statesObj.interval, pos, state);
}

const StateHistory = {
	getStatesObj: (interval) => getStatesObj(interval),
	getStateUsingStatesObj: (pos, statesObj) => getStateUsingStatesObj(pos, statesObj),
	getStateAt: (at) => mergeActionsTo(at),
	getJSActionAt: (at) => {
		at = at >= history.length ? history.length - 1: at;
		return history.length > 0
			? history[at].get('action').toJS()
			: {type: ''}
	},

	getActionTypeAt: (at) => {
		at = at >= history.length ? history.length - 1: at;
		return history.length > 0
			? history[at].getIn(['action', 'type'])
			: '';
	},

	getActionTimestampAt: (at) => {
		at = at >= history.length ? history.length - 1: at;
		return history.length > 0
			? history[at].get('timestamp')
			: 0;
	},

	isLastAction: (at) => {
		return at === history.length - 1;
	},

	reset: () => {
		history = [];
		origState = INITIAL_STATE;
	},

	addAction: (action) => {
		const timestamp = Date.now();
		const {type, ...restOfAction} = action;

		if (bannedActions.indexOf(type) !== -1) {
			return;
		}

		const newAction = immutable.fromJS({timestamp, action});

		history.push(newAction);

		if (history.length > maxHistorySize) {
			origState = mergeActionsTo(0);
			history.shift();
		}
	},

	getLength: () => history.length,
	getActions: () => history.map(action => action.get('action')),
	getMaxHistorySize: () => maxHistorySize,
	setOrigState: (state) => origState = immutable.fromJS(state),
	getEveryThing: () => {
		return {
			origState: origState,
			history: history
		};
	},
	setEveryThing: (everything) => {
		origState = immutable.fromJS(everything.origState);
		history = everything.history.map((item, index) => {
			if (item.timestamp) {
				return immutable.fromJS(item);
			} else {
				return immutable.fromJS({timestamp: index * 1000, action: item});
			}
		});
	},
	trimTo: (pos) => {
		const posLimit = history.length - 2;
		const trimPos = pos < posLimit ? pos: posLimit;
		origState = mergeActionsTo(trimPos);
		history = history.slice(trimPos + 1);
	}
};

export default StateHistory;

// Enable Webpack hot module replacement for reducers
// [TL] We need to "accept" HMR here as well (not only on "Store.js"), because when a reducer in "PlaybackReducers" is updated,
// HMR will follow *ALL* dependency paths for that module (into "Store" through CombineReducers", and also here).
// If this module/path does not "accept" the update, HMR will fail and require a full-reload.
// What needs to be done here (on hot reload), is to re-assign the local "playbackFn" to a re-required
// instance of PlaybackReducers.playback() so that the updated reducer is used on playback.
// (the module.hot.accept() in "Store" will take care of re-requiring all modules as well, and replacing the reducer in the Store)
if (module.hot) {
	module.hot.accept('./PlaybackReducers', () => {
		// console.log('Feeling hot in StateHistory');
		playback = require('./PlaybackReducers').playback;
	});
}
