import AppDispatcher from '../dispatcher/AppDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import WebAPIUtils from '../utils/WebAPIUtils';

const ActionTypes = AppConstants.ActionTypes;
const AuthorizationTypes = AppConstants.Authorization;

export default {

	participantConnectionStatus (information) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.PARTICIPANT_CONNECTIONSTATUS,
			information: information
		});
	},

	participantAuthorizationPending () {
		AppDispatcher.handleServerAction({
			type: AuthorizationTypes.PENDING
		});
	},

	participantAuthorized (conversationId) {
		AppDispatcher.handleServerAction({
			type: AuthorizationTypes.AUTHORIZED,
			conversationId: conversationId
		});
	},

	participantUnauthorized (error) {
		AppDispatcher.handleServerAction({
			type: AuthorizationTypes.NOT_AUTHORIZED,
			error: error
		});
	},

	participantBlocked () {
		AppDispatcher.handleServerAction({
			type: AuthorizationTypes.BLOCKED
		});
	},

	participantJoined (conversationId, information) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.PARTICIPANT_JOINED,
			conversationId: conversationId,
			participant: information
		});
	},

	participantLeft (conversationId, information) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.PARTICIPANT_LEFT,
			conversationId: conversationId,
			participant: information
		});
	},

	participantLeave (conversationId) {
		WebAPIUtils.leaveConversation(conversationId);
	}
};

