import React from 'react';
import {Button} from 'react-bootstrap';
import MessageBannerInput from './MessageBannerInput.react';
import MessageChat from './MessageChat.react';
import MessageContentUpload from './MessageContentUpload.react';
import MessageNavigation from './MessageNavigation.react';
import MessageNote from './MessageNote.react';
import MessageLinkTypes from './MessageLinkTypes.react';
import MessageVisitorProfile from './MessageVisitorProfile.react';
import MessageHistoryMarker from './MessageHistoryMarker.react';
import MessageDivider from './MessageDivider.react';
import {useGetTranslation} from './../../LangContext';

const Item = (props) => {
	const getTranslation = useGetTranslation();
	const unReadMsg = !props.isRead ? 'unread' + props.oldestMessageRanking + ' ': '';
	let itemCls = `message-${props.role} ${unReadMsg}type-${props.type} ${props.status ? props.status: ''}`;
	const hasError = props.status === 'error';

	const resendMessage = () => {
		props.resendMessage(props.conversationId, props.id);
	};


	let messageItem;
	switch (props.type) {
		case 'bannerInput':
			messageItem = <MessageBannerInput {...props} />;
			break;
		case 'chat':
			messageItem = <MessageChat {...props} />;
			break;
		case 'note':
			messageItem = <MessageNote {...props} />;
			break;
		case 'navigation':
			messageItem = <MessageNavigation {...props} />;
			break;
		case 'domUpload':
			messageItem = <MessageNavigation {...props} />;
			break;
		case 'link':
			messageItem = <MessageLinkTypes {...props} />;
			break;
		case 'content':
			messageItem = <MessageContentUpload {...props} />;
			break;
		case 'visitorProfile':
			// [TL] Only display visitorProfile-item if there are any visible claims in its messageData
			messageItem = (props.messageData && props.messageData.claims && props.messageData.claims.length ? <MessageVisitorProfile {...props} /> : null);
			break;
		case 'divider':
			messageItem = <MessageDivider {...props}/>;
			break;
		case 'historyMarker':
			messageItem = <MessageHistoryMarker {...props}/>;
			break;
	}
	const wrappedItem = hasError
		? <div>
			<div className="error-message">
				{messageItem}
			</div>
			<div className="error-marker">
				<div className="error-text">
					<i className="vngage-icon-attention"/>
					{getTranslation('failedToSend')}
				</div>
				<div className="try-again-button">
					<Button className="btn" variant="primary" size="xs" onClick={resendMessage}>
						<span><i className="vngage-icon-arrows-cw"/>{getTranslation('tryAgain')}</span>
					</Button>
				</div>
			</div>
		</div>
		: messageItem;


	return (wrappedItem ?
		<li className={itemCls}>
			{wrappedItem}
		</li> : null);
};

const areEqual = (prevProps, nextProps) => {
	return prevProps.isRead === nextProps.isRead
		&& prevProps.isMostRecentMessage === nextProps.isMostRecentMessage
		&& prevProps.oldestMessageRanking === nextProps.oldestMessageRanking
		&& prevProps.status === nextProps.status;
};

Item.displayName = 'Item';
export default React.memo(Item, areEqual);

