import Data from './Data';
import version120 from '../1.2.0';
import Actions from './Actions';
import Subscriptions from './Subscriptions';

export default {
	actionFormats: {...version120.actionFormats, ...Actions.actionFormats},
	actionMap: {...version120.actionMap, ...Actions.actionMap},
	...Data,
	subscriptions: {...version120.subscriptions, ...Subscriptions.subscriptions}
};