import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'react-bootstrap';
import DateWrapper from '../../../utils/DateWrapper';
import {useGetTranslation} from './../../LangContext';

const uploadTimeout = 1000 * 9;

const DomUploadSpinner = (props) => {
	const getTranslation = useGetTranslation();
	const [startTime, setStartTime] = useState(DateWrapper.now());
	const [showFailure, setShowFailure] = useState(false);
	const timer = useRef();

	useEffect(() => {
		timer.current = setInterval(() => {
			const currentTime = DateWrapper.now();
			const limit = currentTime - startTime - uploadTimeout;
			if (limit >= 0) {
				clearTimeout(timer.current);
				setShowFailure(true);
			}
		}, 500);
		return () => {
			clearInterval(timer.current);
		};
	}, [startTime]);

	const msgStr = showFailure
		? getTranslation('coPendingFormDataError')
		: getTranslation('coPendingFormData');

	return (
		<span>
			<span className="spinner-container">
				{msgStr}
				<br/>
				{showFailure
					? <Button
						onClick={() => props.setCoBroDomUploadStatus('ready')}>{getTranslation('coPendingAbort')}</Button>
					: null
				}
			</span>
		</span>
	);
};

export default DomUploadSpinner;

