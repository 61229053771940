import _cloneDeep from 'lodash/cloneDeep';
import _zipObject from 'lodash/zipObject';
import _find from 'lodash/find';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';

export default function ($q, APIEndpointService, APIConfigurationSectionService, Session, NotificationService, ConfigValidationService) {
	'ngInject';

	const workspaceSettingsKeysToMigrateToGeneralSection = ['dashboard', 'logoutRouting', 'logoutLandingPageUrl', 'whiteLabel'];
	const unsupportedSectionTypeErrorString = 'unsupported_section_type';

	let cachedWorkspaceSettings = null;
	let legacyWorkspaceBucketResource = null;

	const _filterSections = (workspaceSettings, sectionTypes) => {
		// Accepts an array of configurationSections and an array of sectionTypes
		// Returns an object with sectionTypes as keys, and data as value
		// If sectionTypes is an array, all listed types are returned
		// If sectionTypes is empty, all sectionTypes will be returned
		// If sectionTypes is a string, only the data will be returned (i.e. not under a "sectionTypes"-key)

		const getMultipleTypes = Array.isArray(sectionTypes);
		sectionTypes = (sectionTypes ? (getMultipleTypes ? sectionTypes : [sectionTypes]) : null);

		let filteredConfSections = (sectionTypes ? _zipObject(sectionTypes, sectionTypes.map(() => null)) : {});
		workspaceSettings.forEach(setting => {
			if (!sectionTypes || sectionTypes.indexOf(setting.data.section.sectionType) !== -1) {
				if (!filteredConfSections[setting.data.section.sectionType]) {
					filteredConfSections[setting.data.section.sectionType] = setting.data.section;
				} else {
					// TODO: If we get here we have a config corruption! (multiple "workspace"-sections with the same "sectionType")
					// How to handle?
					// Update: Workspace config sections can now be checked using new workspaceSectionsValidation()-function (don't change anything here)
				}
			}
		});
		return (!sectionTypes || getMultipleTypes ? filteredConfSections : filteredConfSections[sectionTypes[0]]);
	};

	const _get = (sectionTypes, force) => {
		// Get workspaceSettings, returns cached data if available and !force
		// If force == true, the cache is invalidated and data is fetched from backend
		return $q((resolve, reject) => {
			if (cachedWorkspaceSettings && !force) {
				resolve(_cloneDeep(_filterSections(cachedWorkspaceSettings, sectionTypes)));
			} else {
				const accountId = Session.account.id;

				APIConfigurationSectionService.getAll().then(configurationSections => {
					let workspaceSettings = APIConfigurationSectionService.filterConfigurationSections(configurationSections, APIConfigurationSectionService.sectionMap.workspace).filter(setting => {
						return setting.data.siteId === '00000000-0000-0000-0000-000000000000';
					});

					if (workspaceSettings.length) {
						// Configuration section(s) of type "workspace" exists

						cachedWorkspaceSettings = workspaceSettings;
						resolve(_cloneDeep(_filterSections(workspaceSettings, sectionTypes)));
					} else {
						// No configuration section of type "workspace" exists
						// Try to fetch settings from legacy workspaceBucket instead

						APIEndpointService.workspaceSettings.tryGet({accountId: accountId}).$promise.then(res => {
							const migratedWorkspaceSettings = [];

							if (res && res.data) {
								// Save a reference to the resource to be able to update an existing legacy workspace bucket on release42 BE
								legacyWorkspaceBucketResource = res;

								// Migrate some settings in the bucket base object to a new sectionType "general"
								res.data.general = res.data.general || {};

								workspaceSettingsKeysToMigrateToGeneralSection.forEach(key => {
									if (typeof res.data[key] !== 'undefined') {
										res.data.general[key] = res.data[key];
									}
								});

								const migrateSections = ['general', 'desktopPlugins'];
								Object.keys(res.data).forEach(sectionType => {
									if (migrateSections.indexOf(sectionType) !== -1) {
										const legacySection = res.data[sectionType];
										legacySection['sectionType'] = sectionType;
										legacySection['__legacyBucket'] = true;
										migratedWorkspaceSettings.push({
											data: {
												section: legacySection
											}
										});
									} else {
										// Delete all non-recognized sectionTypes
										delete res.data[sectionType];
									}
								});
							}

							cachedWorkspaceSettings = migratedWorkspaceSettings;
							resolve(_cloneDeep(_filterSections(migratedWorkspaceSettings, sectionTypes)));
						}, (error) => {
							cachedWorkspaceSettings = null;
							reject(error);
						});
					}
				});
			}
		});
	};

	const get = (force) => {
		// Get all sections from server
		return _get(null, force);
	};

	const forceGet = () => {
		// Force fetch all sections from server, alias for get(true)...
		return _get(null, true);
	};

	const getSections = (sectionTypes) => {
		// Force fetch from server...
		return _get(sectionTypes);
	};

	const forceGetSections = (sectionTypes) => {
		// Force fetch from server...
		return _get(sectionTypes, true);
	};

	const _generateSectionSavePromises = (newWorkspaceSettingsSections, existingWorkspaceSettings) => {
		// This function generates save- and create-promises for updating/creating configuration sections for all the workspace settings in "newWorkspaceSettingsSections"
		// It will also migrate any legacy bucket data from "existingWorkspaceSettings" for saving in configuration sections instead
		// NOTE: This function also mutates "existingWorkspaceSettings", so the array will contain updated, migrated data afterwards
		// If any promise fails, "existingWorkspaceSettings" can be used to save the data by any legacy method as fallback (e.g. in a workspace bucket on release42 BE)

		existingWorkspaceSettings = existingWorkspaceSettings || [];
		const promises = [];

		Object.keys(newWorkspaceSettingsSections).forEach(key => {
			const newSectionData = _cloneDeep(newWorkspaceSettingsSections[key]);
			newSectionData['sectionType'] = key;	// Make sure the new data has correct 'sectionType'
			delete newSectionData['__legacyBucket'];  // Remove key "__legacyBucket" if exists

			const existingSection = _find(existingWorkspaceSettings, setting => (setting.data && setting.data.section && setting.data.section.sectionType === key));
			if (existingSection) {
				existingSection.data.section = newSectionData;
				if (existingSection.$silentSave) {
					// Updating an existing section
					promises.push(existingSection.$silentSave());
				} else {
					// Creating an updated legacy section
					existingSection.data.name = key + ' settings';
					promises.push(APIConfigurationSectionService.create(existingSection.data, APIConfigurationSectionService.sectionMap.workspace, false, true));
				}
			} else {
				// Creating new section
				const newSection = {
					data: {
						name: key + ' settings',
						section: newSectionData
					}
				};
				existingWorkspaceSettings.push(newSection);
				promises.push(APIConfigurationSectionService.create(newSection.data, APIConfigurationSectionService.sectionMap.workspace, false, true));
			}
		});

		// Look for other settings to migrate (besides the ones being updated/created in newWorkspaceSettingsSections)
		// This is necessary because after any workspace setting is saved in a configurationSection, the legacy workspaceBucket is not used/fetched anymore
		// So we need to migrate all settings at once.
		existingWorkspaceSettings.forEach(section => {
			if (section.data.section['__legacyBucket']) {
				section.data.name = section.data.section['sectionType'] + ' settings';
				delete section.data.section['__legacyBucket'];
				promises.push(APIConfigurationSectionService.create(section.data, APIConfigurationSectionService.sectionMap.workspace, false, true));
			}
		});

		return promises;
	};

	const _saveAsConfigurationSections = (newWorkspaceSettingsSections, existingWorkspaceSettings) => {
		// This function tries to save newWorkspaceSettingsSections as a configuration section of type "workspace"

		return $q((resolve, reject) => {
			const promises = _generateSectionSavePromises(newWorkspaceSettingsSections, existingWorkspaceSettings);
			if (promises.length > 0) {
				$q.all(promises).then(resolve, (error) => {
					// Saving section(s) failed
					// Try to parse/interpret the error to see if we're on a release42-BE (that does not support the new section type "workspace")

					// Problem: $q.all() will fail on the very first failed promise in the input-array
					// That means that if we get different kinds of errors for the different promises, the error parsing below will only look at one of the errors (the one occurring first)
					// But I guess this is a corner case we can live with... (if saving fails for other reasons as well, we're in trouble anyway, so we can just display an error message)

					if (error
						&& error.status == 500
						&& error.data
						&& error.data.errorCode === 'unexpectedException'

						// The error-message-checking below is not always present (AF, etc), so disable for now...
						// NOTE: This means that *ALL* 500-errors when saving workspace settings in a section will save to the legacy bucket instead.
						// && typeof error.data.message === 'string'
						// && error.data.message.indexOf('vngage.WebApi.Model.ConfigurationSectionDto -> vngage.Model.Internal.ConfigurationSectionType') !== -1
					) {
						// Release42 BE detected (does not support section type "workspace")
						// Reject with "unsupportedSectionTypeErrorString", but do not clear the cache (used for saving legacy bucket)
						reject(unsupportedSectionTypeErrorString);
					} else {
						cachedWorkspaceSettings = null;
						reject(error);
					}
				});
			} else {
				// If we have no settings to save, just clear the cache
				cachedWorkspaceSettings = null;
				resolve();
			}
		});
	};

	const _saveAsLegacyBucket = (workspaceSettingsSections) => {
		// This function tries to save workspaceSettingsSections as a legacy workspace bucket (for compatibility with release42 BE)

		return $q((resolve, reject) => {

			// Generate a legacy bucket data object from workspaceSettingsSections (that contains data in new format)
			const legacyWorkspaceSettingsBucketData = {};
			workspaceSettingsSections.forEach(section => {
				if (typeof section.data.section['sectionType'] === 'string') {
					if (section.data.section['sectionType'] === 'general') {
						const generalSections = _pick(section.data.section, workspaceSettingsKeysToMigrateToGeneralSection);
						Object.keys(generalSections).forEach(key => {
							legacyWorkspaceSettingsBucketData[key] = _cloneDeep(section.data.section[key]);
						});
					} else {
						const key = section.data.section['sectionType'];
						legacyWorkspaceSettingsBucketData[key] = _cloneDeep(_omit(section.data.section, ['__legacyBucket', 'sectionType']));
					}
				} else {
					console.log('Skipping invalid sectionType found in data.section: ',section.data.section);
				}
			});

			const accountId = Session.account.id;
			if (legacyWorkspaceBucketResource && legacyWorkspaceBucketResource.$save) {
				// Update existing legacy workspace bucket
				legacyWorkspaceBucketResource.data = legacyWorkspaceSettingsBucketData;
				legacyWorkspaceBucketResource.$save({accountId: accountId}).then(resolve, reject);
			} else {
				// Create a new legacy workspace bucket
				APIEndpointService.workspaceSettings.create({accountId: accountId}, legacyWorkspaceSettingsBucketData).$promise.then(resolve, reject);
			}
		});
	};

	const saveWorkspaceSettingsSections = (newWorkspaceSettingsSections) => {
		return $q((resolve, reject) => {
			forceGet().then(() => {
				// Force fetching latest workspaceSettings before saving the bucket.
				// If successful, "cachedWorkspaceSettings" will now be up-to-date and newWorkspaceSettingsSections can be merged to it before saving it

				// If the cache is empty: Create a new, empty one to insert new settings
				cachedWorkspaceSettings = cachedWorkspaceSettings || [];
			}, (error) => {
				// If forceGet() was rejected:
				//  - Create new workspaceSetting if (and only if) status = 404 (no previous workspaceSettings exists for this account)
				// 	- reject if any other reason (don't try to save/create as it may overwrite existing data - forceGet() may fail while create() works, and it would overwrite data already in the bucket)
				if (error && error.status === 404) {
					cachedWorkspaceSettings = [];
				} else {
					cachedWorkspaceSettings = null;
					console.log('Error saving workspace sections (forceGet failed)');
					reject();
				}
			}).finally(() => {
				if (cachedWorkspaceSettings !== null) {
					_saveAsConfigurationSections(newWorkspaceSettingsSections, cachedWorkspaceSettings).then((result) => {
						NotificationService.success({
							header: 'Success',
							body: 'Successfully saved'
						});
						resolve(result);
					}, (error) => {
						if (error === unsupportedSectionTypeErrorString) {
							// Try to save the data in a legacy workspaceBucket
							// NOTE: Here, "cachedWorkspaceSettings" contains the NEW data, as this has been added by _generateSectionSavePromises() called from _saveAsConfigurationSections() above!
							// Not pretty, but this code is only for release42 BE and will soon be obsolete anyway...
							_saveAsLegacyBucket(cachedWorkspaceSettings).then(resolve, (error) => {
								console.log('Error saving legacy bucket: ',error);
								cachedWorkspaceSettings = null;
								reject(error);
							});
						} else {
							console.log('Error saving configuration sections:', error);
							reject(error);
						}
					});
				}
			});
		});
	};

	// For testing
	const _clearAllConfigSections = function () {
		// Test function to remove all configurationSection of type 'workspace'
		APIConfigurationSectionService.getAll().then(configurationSections => {
			let workspaceSettings = APIConfigurationSectionService.filterConfigurationSections(configurationSections, APIConfigurationSectionService.sectionMap.workspace).filter(setting => {
				return true;//setting.data.siteId === '00000000-0000-0000-0000-000000000000';
			});

			const promises = [];
			workspaceSettings.forEach(section => {
				if (section.$remove) {
					promises.push(section.$remove());
				}
			});
			$q.all(promises).then(() => {
				console.log('_clearAllConfigSections ok');
				cachedWorkspaceSettings = null;
			}, (error) => {
				console.log('_clearAllConfigSections error:', error);
				cachedWorkspaceSettings = null;
			});
		});
	};

	const workspaceSectionsValidation = (force) => {
		return $q((resolve, reject) => {
			if (cachedWorkspaceSettings && !force) {
				// Validate cached workspace settings
				resolve(ConfigValidationService.validateSectionType(cachedWorkspaceSettings, APIConfigurationSectionService.sectionMap.workspace));
			} else {
				// Fetch workspace settings and validate
				APIConfigurationSectionService.getAll().then(configurationSections => {
					let workspaceSettings = APIConfigurationSectionService.filterConfigurationSections(configurationSections, APIConfigurationSectionService.sectionMap.workspace).filter(setting => {
						return setting.data.siteId === '00000000-0000-0000-0000-000000000000';
					});
					resolve(ConfigValidationService.validateSectionType(workspaceSettings, APIConfigurationSectionService.sectionMap.workspace));
				});
			}
		});
	};

	return {
		get,
		forceGet,
		getSections,
		forceGetSections,
		saveWorkspaceSettingsSections,
		workspaceSectionsValidation,
		// _clearAllConfigSections
	};
}
