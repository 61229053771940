export default function(VngageUtil) {
    'ngInject';

    return {
        restrict: 'A',
        compile: function(tElem, attrs) {
            tElem.addClass('vngage-validation');
            var opts = VngageUtil.parseOptions(attrs.vngageBasicValidation);

            var formName = opts.form || VngageUtil.getClosestParentElem(tElem, 'form').attr('name');
            var inputEl = VngageUtil.findFirstEl(tElem, ['input', 'textarea', 'select']);
            var inputName = inputEl.attr('name');
            var patternMsg = '';
            if (inputEl.attr('data-ng-pattern')) {
                patternMsg = [
                    '<div data-ng-message="pattern" class="alert alert-danger">',
                        '<span data-translate>' + attrs.patternTranslation + '</span>',
                    '</div>'
                ].join('');
            }
            var appendTo = opts.parent ? tElem.parent() : tElem;
            var inputIdentifier = formName + '.' + inputName;
            appendTo.append([
                '<span class="form-warning" ',
                'data-ng-if="' + inputIdentifier + '.$dirty" ',
                'data-ng-messages="' + inputIdentifier + '.$error">',
                patternMsg,
                '<span data-ng-messages-include="common/vngageFormDirectives/validationMessagesTemplate.html"></span>',
                '</span>'
            ].join(''));
        }
    };
};
