import React from 'react';
import {useGetTranslation} from './LangContext';

const DesktopDeuxFailedView = (props) => {
	const getTranslation = useGetTranslation();
	return (
		<div className="desktop-failed">
			<div className="desktop-failed-overlay"/>
			<div className="desktop-failed-modal">
				<div className="modal-content">
					<div className="modal-header">
						<h3>{getTranslation('desktopFailedTitle')}</h3>
					</div>
					<div className="modal-body">
						<div className="error-msg">
							<i className="vngage-icon-attention"/>
							{getTranslation('desktopFailedError')}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default DesktopDeuxFailedView

