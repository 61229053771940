export default function() {
    'ngInject';

    var validPattern = /^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]{0,}$/;

    return {
        require: 'ngModel',
        link: function($scope, element, attrs, ngModel) {
            ngModel.$validators.subdomain = function(value) {
                if (value) {
                    return validPattern.test(value);
                } else {
                    return true;
                }
            };
        }
    };
};
