export default function (Session, $scope, trustRelationships, accountRoleClaim, account, accountList, TrustRelationshipService, APIEndpointService) {
	'ngInject';

	var vm = this;
	vm.account = account;
	vm.trustRelationships = trustRelationships;
	vm.accountRoleClaim = accountRoleClaim;
	vm.selectedTrustedAccount = null;
	vm.selectedTrustedAccountRoleClaims = null;
	vm.selectedAccountRoleClaim = null;
	vm.selectedTrustedRoleClaims = [];
	vm.selectableRoleClaims = [];

	vm.selectAccountRoleClaim = selectAccountRoleClaim;
	vm.trustedRolesSubmit = trustedRolesSubmit;

	TrustRelationshipService.populateTrustRelationships(accountList, vm.trustRelationships);

	$scope.$watch('vm.selectedTrustedAccount', () => {
		if (vm.selectedAccountRoleClaim) {
			// If there is already a selected role when account changes, the new account's roleClaims needs to be re-fetched...
			selectAccountRoleClaim(vm.selectedAccountRoleClaim);
		}
	});

	function selectAccountRoleClaim(role) {
		APIEndpointService.accountRoleClaim.query({id: vm.selectedTrustedAccount.data.trustedAccountId}).$promise.then(function (res) {
			var trustedRoleClaimTypes = TrustRelationshipService.getTrustedRoleClaims(role, vm.selectedTrustedAccount.data.trustedClaimTypes);
			vm.selectedAccountRoleClaim = role;
			vm.selectedTrustedRoleClaims = trustedRoleClaimTypes;
			vm.selectableRoleClaims = TrustRelationshipService.mapSelectableRoleClaims(res);
			vm.selectedAccountRoleClaimReloaded = Date.now();	// Trigger update of <vngage-selectable-items>
		});
	}

	function trustedRolesSubmit() {
		var trustedRoleClaimType = {
			claimType: vm.selectedAccountRoleClaim.data.claimType,
			trustedRoleClaimTypes: vm.selectedTrustedRoleClaims
		};

		TrustRelationshipService.updateTrustedClaimTypes(trustedRoleClaimType, vm.selectedTrustedAccount.data);
		vm.selectedTrustedAccount.data.trustEstablishedBy = Session.user.id;

		APIEndpointService.trustRelationship.save({
			accountId: account.data.id,
			id: vm.selectedTrustedAccount.data.trustedAccountId
		}, vm.selectedTrustedAccount).$promise.then(function (res) {
			if (res.data.trustedAccountId === vm.selectedTrustedAccount.data.trustedAccountId) {
				// The property "data.trustedAccountName" is not included in the response after save.
				// Copy it from the previous selectedTrustedAccount if "data.trustedAccountId"s match (which they should).
				res.data.trustedAccountName = vm.selectedTrustedAccount.data.trustedAccountName;
			}
			vm.selectedTrustedAccount = res;
		});
	}
}
