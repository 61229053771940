import {
	getDataRows,
	getDataStatus,
	getFileStatus,
	getImportIntegrity,
	getItemsToSave,
	getFormatDescription,
	downloadFile,
	getHeaderNames
} from './importModalHelpers';

const Papa = require('papaparse');

export default function ($scope, $uibModalInstance, APIConfigurationSectionService, APIEndpointService, options, itemsList, itemType, isSection, headers, extraData, NotificationService) {
	'ngInject';

	var vm = this;
	vm.options = options;
	vm.addItems = addItems;
	vm.cancelNew = $uibModalInstance.dismiss;
	vm.itemsList = itemsList;
	vm.itemType = itemType;
	vm.isSection = !!isSection;
	vm.headers = headers;
	vm.fileHeaders = headers;
	vm.view = 'start';
	vm.tableRows = [];
	vm.errorRows = [];
	vm.itemsToSave = [];
	vm.extraData = extraData;
	vm.formatDescription = getFormatDescription(vm.headers);
	vm.showDescription = false;

	vm.toggleDescription = function ($event) {
		$event.preventDefault();
		vm.showDescription = !vm.showDescription;
	};


	vm.downloadFile = () => {
		// const csvContent = vm.headers.map((header) => getHeaderName(header)).join(';') + '\r\n';
		const csvContent = getHeaderNames(vm.headers).join(';') + '\r\n';
		const csvFilename = vm.itemType + '.csv';
		downloadFile(csvFilename, csvContent);
	};

	vm.fileSelected = () => {
		vm.showDescription = false;
		const inputData = document.querySelector('input[name=\'importName\']');
		const file = inputData.files[0];
		importFile(file);
	};

	async function createNewSections(itemsToSave) {
		for (const [i, item] of itemsToSave.entries()) {
			console.log('saving item', i + 1, item);
			if (vm.isSection) {
				try {
					const name = item.name;
					const newItem = await APIConfigurationSectionService.create({name}, vm.itemType, true, true);
					newItem.data.section = {
						...newItem.data.section,
						...item
					};
					await newItem.$save();
					vm.itemsList.push(newItem);
				} catch (error) {
					const errorMsg = `Could not add ${name} on line ${parseInt(i + 1)}`;
					console.log(errorMsg, error);
					NotificationService.error({body: errorMsg});
					break;
				}
			} else {
				try {
					const newItem = await APIEndpointService[vm.itemType].create(item);
					vm.itemsList.push(newItem);
				} catch (error) {
					const errorMsg = `Could not add line ${parseInt(i + 1)}`;
					console.log(errorMsg, error);
					NotificationService.error({body: errorMsg});
					break;
				}
			}
		}
		$scope.$apply();
		$uibModalInstance.close(vm.itemsList);
	}

	async function addItems() {
		await createNewSections(vm.itemsToSave);
	}

	function importFile(file) {
		if (!file) {
			return;
		}
		Papa.parse(file, {
			complete: async function (result) {
				vm.itemsToSave = [];
				const importIntegrity = getImportIntegrity(vm.headers, vm.options, vm.itemType, vm.isSection, vm.extraData);
				const fileStatus = getFileStatus(result, vm.headers, vm.isSection);
				const overallStatus = importIntegrity !== 'ok'
					? 'FileImportHeaders error: ' + importIntegrity
					: fileStatus !== 'ok'
						? fileStatus
						: 'ok';
				if (overallStatus === 'ok') {
					const dataRows = getDataRows(result, vm.headers, vm.extraData);
					vm.tableRows = dataRows;
					vm.fileHeaders = result.data[0];
					const dataStatus = getDataStatus(dataRows);
					if (dataStatus === 'ok') {
						vm.errorRows = vm.tableRows.map(() => false);
						vm.displayError = false;
						vm.view = 'ready';
						vm.itemsToSave = getItemsToSave(result, vm.headers);
						console.log('new items', vm.itemsToSave);
					} else {
						// data error show message
						vm.errorRows = vm.tableRows.map(row => row.some(cell => cell.status !== 'ok'));
						vm.displayError = true;
						vm.errorMessage = dataStatus;
						vm.view = 'error';
					}
				} else {
					// general error cannot show data
					vm.displayError = true;
					vm.errorMessage = overallStatus;
					// vm.view = 'error';
					vm.tableRows = [];
				}
				$scope.$apply();
			}
		});
	}

}

