import LogActions from '../actions/LogActions';
import LocalStorage from '../stores/LocalStorageWrapper';
import QueueActions from '../actions/QueueActions';
import ConversationActions from '../actions/ConversationActions';

const setTextSize = (textSize) => (dispatch, getState) => {
	dispatch(LogActions.logAc('setTextSize', {textSize}));
	dispatch(ConversationActions.setTextSize(textSize));
	LocalStorage.setItem('userPreferences', JSON.stringify(getState().get('userPreferences')));
};

const toggleSystemMessages = (visible) => (dispatch, getState) => {
	dispatch(LogActions.logAc('toggleSystemMessages', {visible}));
	dispatch(ConversationActions.toggleSystemMessages(visible));
	LocalStorage.setItem('userPreferences', JSON.stringify(getState().get('userPreferences')));
};

const toggleActiveInfoTip = (infoType) => (dispatch, getState) => {
	dispatch(LogActions.logAc('toggleActiveInfoTip', {infoType}));
	dispatch(ConversationActions.toggleActiveInfoTip(infoType));
	LocalStorage.setItem('userPreferences', JSON.stringify(getState().get('userPreferences')));
};


const setQueuePanelPosition = () => (dispatch, getState) => {
	dispatch(LogActions.logAc('setQueuePanelPosition', {}));
	dispatch(QueueActions.setQueuePanelPosition());
	LocalStorage.setItem('userPreferences', JSON.stringify(getState().get('userPreferences')));
};


export default {
	setTextSize,
	toggleSystemMessages,
	toggleActiveInfoTip,
	setQueuePanelPosition
}


