import moment from 'moment-timezone';
export default function (SessionService, $uibModalInstance, itemHistory, users, selectedVersion) {
    'ngInject';

    var vm = this;
    vm.itemHistory = itemHistory;
    vm.users = users;
    vm.cancel = $uibModalInstance.dismiss;
    vm.selectedVersion = selectedVersion;
    vm.selectNewVersion = selectNewVersion;
    vm.formatWithTZ = formatWithTZ;

    function formatWithTZ(date) {
        return moment.utc(date).tz(SessionService.getUserTimezone()).format('L LTS');
    }

    function selectNewVersion() {
		$uibModalInstance.close(vm.selectedVersion);
    }
};
