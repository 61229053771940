import _debounce from 'lodash/debounce';
import vngageCodemirrorJsTemplate from './vngageCodemirrorJsTemplate';

export default function ($window, jshint, $timeout, $interval) {
	'ngInject';

	var validateScriptTimeoutInMilliseconds = 800,
		notifications = [];

	return {
		replace: true,
		restrict: 'A',
		require: ['^form', '^ngModel'],
		scope: {
			model: '=ngModel',
			refresh: '=',
			matchBracketsDebounceSize: '=',	// If set and editor text is larger than this size (in bytes), codemirror bracket-matching will be debounced for performance (by 250ms or "matchBracketsDebounceTime" if set)
			matchBracketsDebounceTime: '='
		},
		templateUrl: vngageCodemirrorJsTemplate,
		link: function (scope, element, attrs, ctrls) {
			scope.form = ctrls[0];
			var ngModel = ctrls[1];

			scope.$watch('model', function (newValue) {
				if (scope.form.$pristine && newValue) {
					editor.doc.setValue(newValue);
				}
			});

			var editor = element.next()[0].CodeMirror;
			scope.matchBracketDebounce = false;
			scope.$watch('matchBracketDebounce', function (enableDebounce) {
				if (editor) {
					editor.setOption('matchBrackets', {
						matchBracketsDebounceTime: (enableDebounce ? scope.matchBracketsDebounceTime || 250 : 0)
					});
				}
			});
			function adjustCodeMirrorMatchBracketDebounce() {
				// If codemirror editor-text is larger than <matchBracketsDebounceSize> bytes: Set matchBracketsDebounce-time to <matchBracketsDebounceTime> ms for performance
				scope.matchBracketDebounce = (scope.matchBracketsDebounceSize && editor && editor.getValue().length > scope.matchBracketsDebounceSize);
			}
			$interval(adjustCodeMirrorMatchBracketDebounce, 5000);

			$timeout(function () {
				if (scope.model) {
					editor.doc.setValue(scope.model);
					adjustCodeMirrorMatchBracketDebounce();
				}
			}, 0);

			editor.on('change', _debounce(change, validateScriptTimeoutInMilliseconds));

			function change() {

				editor.operation(function () {
					clearEditorNotifications();
					jshint(editor.getValue());
					displayJshintErrors();
				});

				setModelValidity();
				setScrollPosition();
			}

			function setModelValidity() {
				if (jshint.errors.length === 0) {
					ngModel.$setValidity('script', true);
				} else {
					ngModel.$setValidity('script', false);
				}

				scope.$apply(function () {
					scope.model = editor.getValue();
				});
			}

			function setScrollPosition() {
				var info = editor.getScrollInfo();
				var after = editor.charCoords({
					line: editor.getCursor().line + 1,
					ch: 0
				}, 'local').top;
				if (info.top + info.clientHeight < after) {
					editor.scrollTo(null, after - info.clientHeight + 3);
				}
			}

			function clearEditorNotifications() {
				for (var i = 0; i < notifications.length; ++i) {
					editor.removeLineWidget(notifications[i]);
				}
				notifications = [];
			}

			function displayJshintErrors() {
				for (var i = 0; i < jshint.errors.length; ++i) {
					var err = jshint.errors[i];

					if (!err) {
						continue;
					}

					var msg = $window.document.createElement('div');
					var icon = msg.appendChild($window.document.createElement('span'));
					icon.innerHTML = '!!';
					icon.className = 'CodeMirror-lint-error-icon';
					msg.appendChild($window.document.createTextNode(err.reason));
					msg.className = 'CodeMirror-lint-error';
					notifications.push(editor.addLineWidget(err.line - 1, msg, {
						coverGutter: false,
						noHScroll: true
					}));
				}
			}


			scope.$watch('refresh', (update) => {
				/*
					If incoming parameter 'refresh' is changed (and not falsy), editor will be refreshed.
					ui-CodeMirror has a bug that will make the editor blank if it's not visible on init.
					E.g. when placed in a tab that is not focused/visible from start (when navigating to the tab containing the code mirror component, it will be blank until it gets focus or window is resized).
					To fix, the tab containing the component could set it's "active"-attribute, like "<tab heading="White label" active="vm.whiteLabelTabActive">",
					and the codemirror-component can set it's "refresh"-attribute to the same like:
					<textarea
							name="customJs"
							vngage-codemirror-js
							data-refresh="vm.TabActive"
							data-ng-model="vm.code">
					</textarea>
					That way, 'refresh' will -> 'true' when the tab is selected, the editor will be refreshed and content will be visible

					"refresh" can also be set to a timestamp to trigger updates when the editor's content is set
				*/
				if (update && editor) {
					$timeout(() => {
						editor.refresh();
					}, 0);
				}
			}, true); // <--- Important: We need to watch incoming scope-variables with deep-checking in directives!!!!

		}
	};
};
