import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function ($scope, $state, $translate, caseType, closureList, cannedResponseList, actionPanelList, formList, VngageUtil, AccountCaseTypesService, APIConfigurationSectionService, vngageRemoveModalService, $stateParams) {
	'ngInject';

	const vm = this;
	vm.caseType = caseType;
	vm.submit = submit;
	vm.remove = remove;
	vm.cancel = cancel;
	vm.currentList = $stateParams.currentList || [];

	vm.timeToLiveUnits = [
		{name: $translate.instant('caseType.TTL_SECONDS'), unit: 'seconds'},
		{name: $translate.instant('caseType.TTL_MINUTES'), unit: 'minutes'},
		{name: $translate.instant('caseType.TTL_HOURS'), unit: 'hours'},
		{name: $translate.instant('caseType.TTL_DAYS'), unit: 'days'}
	];

	vm.timeToLiveEnabled = (vm.caseType.data.section.caseTimeToLive && vm.caseType.data.section.caseTimeToLive !== 0);
	if (vm.timeToLiveEnabled) {
		const {amount, unit} = AccountCaseTypesService.timeSpanToTimeUnits(vm.caseType.data.section.caseTimeToLive);
		vm.timeToLiveAmount = amount;
		vm.timeToLiveUnit = unit;
	} else {
		// Default TTL on enable: 14 days
		vm.timeToLiveAmount = 14;
		vm.timeToLiveUnit = 'days';
	}

	vm.formattedSelectableClosures = AccountCaseTypesService.formatForSelectableList(closureList);
	vm.formattedSelectableCannedResponses = AccountCaseTypesService.formatForSelectableList(cannedResponseList);
	vm.formattedSelectableActionPanels = AccountCaseTypesService.formatForSelectableList(actionPanelList);
	vm.formattedSelectableForms = AccountCaseTypesService.formatForSelectableList(formList);

	$scope.$on('resetResettables', () => {
		vm.caseTypeEditForm.$setPristine();
		caseType.$get().catch(() => {
		});
	});

	function submit() {
		vm.isSaving = true;

		if (vm.timeToLiveEnabled) {
			const timeToLiveInSeconds = AccountCaseTypesService.timeUnitsToSeconds(vm.timeToLiveAmount, vm.timeToLiveUnit);
			vm.caseType.data.section.caseTimeToLive = AccountCaseTypesService.secondsToTimeSpan(timeToLiveInSeconds);
		} else {
			vm.caseType.data.section.caseTimeToLive = null;
		}

		caseType.$save().finally(() => {
			vm.isSaving = false;
		});
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: caseType,
			itemType: APIConfigurationSectionService.sectionMap.customerCase
		}).then(() => {
			vm.caseTypeEditForm.$setPristine();
			const itemNavigationData = getItemNavigationDataAfterRemove(caseType, 'root.configure.caseType.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(() => {
			vm.isSaving = false;
		});
	}

	function cancel() {
		$state.go('^');
	}
}
