import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import VideoActions from '../../actions/VideoActions';
import Video from '../../actionCreators/Video';
import VideoLib from '../../reducers/libs/videoLib';
import OverlayView from './OverlayView.react';

const VideoView = (props) => {
	const agentVideoElement = useRef();
	const visitorVideoElement = useRef();
	const {initVideo, disposeVideoElements} = props;

	useEffect(() => {
		const agentElement = agentVideoElement.current;
		const visitorElement = visitorVideoElement.current;
		initVideo(agentElement, visitorElement);
		return () => {
			disposeVideoElements(agentElement, visitorElement);
		};
	}, [initVideo, disposeVideoElements]);

	const {isModal, isFullscreen} = props;
	const overlayPayload = {
		init: () => initVideo(agentVideoElement.current, visitorVideoElement.current),
		isModal,
		isFullscreen
	};

	return (
		<div className='video-chat-container'>
			<video
				className="video-visitor-window"
				id="visitor_video"
				ref={visitorVideoElement}/>
			<video
				className="video-agent-window"
				id="agent_video"
				muted="muted"
				ref={agentVideoElement}/>
			<OverlayView {...overlayPayload} />
		</div>
	);
};

function mapStateToProps(state) {
	return {videoState: VideoLib.getState(state)};
}

function mapDispatchToProps(dispatch) {
	return {
		initVideo: (agentVideoElement, visitorVideoElement) => dispatch(Video.initVideo(agentVideoElement, visitorVideoElement)),
		disposeVideoElements: (agentVideoElement, visitorVideoElement) => dispatch(Video.disposeVideoElements(agentVideoElement, visitorVideoElement)),
		setError: (error, origin) => dispatch(VideoActions.setError(error, origin))
	};
}

export default connect(mapStateToProps,
	mapDispatchToProps)(VideoView);
