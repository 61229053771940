import _find from 'lodash/find';

import RenderTemplateTranslations from './RenderTemplateTranslations';
import RenderTemplateListController from './RenderTemplateListController';
import RenderTemplateEditController from './RenderTemplateEditController';
import RenderTemplateAddModalController from './RenderTemplateAddModalController';
import RenderTemplateListTemplate from './RenderTemplateListTemplate';
import RenderTemplateEditTemplate from './RenderTemplateEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.configure.renderTemplate', [
	'ui.router',
	'ui.codemirror',
	'vngageApp.workspaceTranslation'
])

	.constant('renderTemplateTranslations', RenderTemplateTranslations)
	.controller('RenderTemplateEditController', RenderTemplateEditController)
	.controller('RenderTemplateAddModalController', RenderTemplateAddModalController)
	.controller('RenderTemplateListController', RenderTemplateListController)

	.constant('renderTemplateConfig', {
		state: 'root.configure.renderTemplate',
		access: 'Engage/Actions'
	})

	.config(function ($stateProvider, $translateProvider, renderTemplateTranslations, renderTemplateConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';

		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(renderTemplateTranslations);

		$stateProvider.state(renderTemplateConfig.state, {
			url: 'renderTemplate/',
			controller: 'RenderTemplateListController as vm',
			templateUrl: RenderTemplateListTemplate,
			data: {
				access: renderTemplateConfig.access,
				ncyBreadcrumbLabel: 'Templates'
			},
			resolve: {
				templateList: function (APIConfigurationSectionService) {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.renderTemplate);
				}
			}
		});

		$stateProvider.state('root.configure.renderTemplate.edit', {
			url: ':id',
			controller: 'RenderTemplateEditController as vm',
			templateUrl: RenderTemplateEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				template: function ($stateParams, templateList, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(templateList, $stateParams.id);
				}
			}
		});
	});
