import assign from 'object-assign';

export default function(AuthenticationService, sessionTrustList) {
	'ngInject';

    var vm = this;
    vm.delegate = delegate;

    vm.sessionTrustList = sessionTrustList;

	vm.filterAccountsFn = function(filterStr) {
		filterStr = (filterStr ? filterStr.toLowerCase() : '');
		return function (item) {
			// Empty string in vm.filterInput will match everything...
			return (item.accountId.toLowerCase().indexOf(filterStr) !== -1 || item.accountDisplayName.toLowerCase().indexOf(filterStr) !== -1);
		}
	};

    if (sessionTrustList !== 'delegated') {
        vm.sessionTrustList = sessionTrustList.map( account => {
            const roleClaims = account.roleClaims.map( claim => {
                return {
                    id: claim,
                    name: claim.split('role#')[1]
                }
            });

            return assign({}, account, {
                roleClaims: roleClaims,
                selectedRoleClaim: account.roleClaims[0]
            });
        });
    }


    function delegate(trust, selectedRoleClaim) {
        AuthenticationService.requestDelegation(trust.accountId, selectedRoleClaim);
    }
};
