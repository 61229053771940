import {
	setDesktopStatus,
	setInContactData,
} from './stateModifiers/desktop';
import AppConstants from '../../../components/constants/AppConstants';

const ActionTypes = AppConstants.ActionTypes;

export default function DesktopReducer(state, action) {
	switch (action.type) {
		case ActionTypes.SET_DESKTOP_STATUS:
			return setDesktopStatus(state, action);

		case ActionTypes.SET_INCONTACT_DATA:
			return setInContactData(state, action);


		default:
			return state;
	}
}

