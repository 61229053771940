export function getCellValue(cell) {
	const format = cell.format;
	let value;
	let splitList;
	switch (format) {
		case 'enum':
			value = cell.value === ''
				? cell.default || ''
				: cell.value.trim();
			value = cell.valueMap ? cell.valueMap[value]: value;
			value = cell.prefix || cell.suffix
				? `${cell.prefix ? cell.prefix: ''}${value}${cell.suffix ? cell.suffix: ''}`
				: value;
			break;
		case 'list':
			value = cell.value.length === 0
				? cell.default || ''
				: cell.value;
			splitList = value.split(',');
			value = splitList.length === 1 && splitList[0] === ''
				? []
				: value.split(',')
					.map(value => value.trim())
					.reduce((list, value) => list.includes(value) ? list: [...list, value], [])
					.map(value => cell.valueMap ? cell.valueMap[value]: value)
					.map(value => cell.prefix || cell.suffix ? `${cell.prefix ? cell.prefix: ''}${value}${cell.suffix ? cell.suffix: ''}`: value);
			break;
		default:
			value = cell.value === ''
				? cell.default || ''
				: cell.value;
			value = cell.valueMap
				? cell.valueMap[value] !== undefined
					? cell.valueMap[value]
					: value
				: value;
			value = cell.prefix || cell.suffix
				? `${cell.prefix ? cell.prefix: ''}${value}${cell.suffix ? cell.suffix: ''}`
				: value;

			break;
	}

	return value;
}

function insertValueByPath(obj, cellPath, value) {
	const pathFragment = cellPath[0];
	if (cellPath.length > 1) {
		if (!obj[pathFragment]) {
			// new path
			obj[pathFragment] = {};
		}
		insertValueByPath(obj[pathFragment], cellPath.slice(1), value);
	} else {
		obj[pathFragment] = value;
	}
}

export function mapData(row, headers, fileHeaders) {
	const topObj = {};
	const dataRow = createDataRow(row, headers, fileHeaders)
		.map(cell => ({...cell, realValue: getCellValue(cell)}));
	dataRow.forEach(cell => {
		const cellPath = cell.name.split('.');
		const value = cell.realValue;
		insertValueByPath(topObj, cellPath, value);
	});
	return topObj;

}

export function trimTrailingRows(rows) {
	if (rows.length === 0) {
		return rows;
	}
	return rows[rows.length - 1].every(cell => cell === '')
		? trimTrailingRows(rows.slice(0, rows.length - 1))
		: rows;
}

export const semicolonReplace = '$Xz##'; // yes a magic string
export function getCleanedRows(rows) {
	const cleaned = rows[0].length > 1
		? trimTrailingRows(rows).map(row => [row.join(';')])
		: trimTrailingRows(rows);

	if (cleaned.some(row => row.some(cell => cell.includes(semicolonReplace)))) {
		// magic string exists, error
		return [];
	}

	// replace \; with ;
	return cleaned.map(row => row.reduce((oneRow, cell) => {
		return oneRow
			.concat(cell
				.replace('\\;', semicolonReplace)
				.split(';')
				.map(str => str.replace(semicolonReplace, ';')));
	}, []));

}

export function getHeaderName(header) {
	return header.name.split('.').pop();
}

export function getHeaderNames(headers) {
	return headers.map(header => getHeaderName(header));
}

export function getDataRows(result, headers, extraData = {}) {
	const rows = getCleanedRows(result.data);
	const fileHeaders = rows[0];
	const dataRows = rows.slice(1).map(row => createDataRow(row, headers, fileHeaders));

	// get a map for duplicates
	const uniqueMap = dataRows
		.reduce((rowObj, row) => {
			return row.filter(cell => cell.unique)
				.reduce((cellObj, cell) => {
					const name = cell.name;
					if (!cellObj[name]) {
						cellObj[name] = {};
					}
					const id = getCellValue(cell);
					if (cellObj[name][id] === undefined) {
						cellObj[name][id] = 0;
					} else {
						cellObj[name][id] = cellObj[name][id] + 1;
					}
					return cellObj;
				}, rowObj);
		}, {});

	// set status on dupes
	return dataRows.map(row => row.map(cell => {
		const extraValues = extraData && extraData[cell.name] && extraData[cell.name].uniqueValues
			? extraData[cell.name].uniqueValues
			: [];
		const value = getCellValue(cell);
		const duplicate = !!cell.unique && (extraValues.includes(value) || uniqueMap[cell.name][value] > 0);
		return duplicate
			? {...cell, status: 'Value not unique'}
			: cell;
	}));

}

export function getDataRows_(result, headers) {
	const rows = getCleanedRows(result.data);
	const fileHeaders = rows[0];
	return rows.slice(1).map(row => createDataRow(row, headers, fileHeaders));
}


export function createDataRow(row, headers, fileHeaders) {
	if (row.length !== headers.length) {
		// Row has wrong number of values
		return fileHeaders.map((fileHeader) => {
			const header = headers.find(header => header.name.split('.').pop() === fileHeader);
			return {
				...header,
				value: 'row error',
				status: 'Row has wrong number of values'
			};
		});
	}
	return row.map((item, index) => {
		const fileHeader = fileHeaders[index];
		const header = headers.find(header => header.name.split('.').pop() === fileHeader);
		const cell = {
			...header,
			value: item,
		};
		cell.status = getCellStatus(cell);
		return cell;
	});
}

// eslint-disable-next-line no-useless-escape
const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const integerRegEx = /^\d+$/;


export function getCellStatus(cell) {
	// check required
	// parse format
	const format = cell.format ? cell.format: '';
	const values = cell.values ? cell.values: [];
	let status;
	let listValues;
	const cellEmpty = cell.value === '' && !cell.required;

	switch (format) {
		case 'https':
			status = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(cell.value) || cellEmpty
				? 'ok'
				: 'bad url';
			break;
		case 'email':
			status = emailRegEx.test(cell.value) || cellEmpty
				? 'ok'
				: 'bad email';
			break;
		case 'integer':
			status = integerRegEx.test(cell.value.trim()) || cellEmpty
				? 'ok'
				: 'Invalid integer';
			break;
		case 'enum':
			// enum is always required
			// or if default is set
			status = values.includes(cell.value.trim()) || (cell.default !== undefined && cellEmpty)
				? 'ok'
				: 'Invalid enum';
			break;
		case 'list':
			// remove duplicates and trim
			listValues = cell.value.split(',')
				.reduce((list, value) => list.includes(value) ? list: [...list, value], [])
				.map(value => value.trim());
			status = listValues.every(value => values.includes(value)) || cellEmpty
				? 'ok'
				: 'Invalid list';
			break;
		default:
			status = cell.required && cell.value === ''
				? 'Required cell is empty'
				: 'ok';
			break;
	}

	return status;
}

// check data status
export function getDataStatus(dataRows) {
	// check that all data is ok
	const brokenLines = dataRows.reduce((lines, dataRow, index) => dataRow.some(cell => cell.status !== 'ok') ? [...lines, index + 1]: lines, []);
	if (brokenLines.length > 0) {
		return `Errors found on lines: ${brokenLines}`;
	}
	return 'ok';
}

export function getImportIntegrity(headers, options, itemType, isSection, extraData) {
	const headerIntegrity = getHeaderIntegrity(headers);
	if (headerIntegrity.length > 0) {
		return headerIntegrity[0];
	}

	const okOptions = options && options.header;
	if (!okOptions) {
		return 'Missing header in options';
	}

	const okItemType = itemType !== undefined && typeof itemType === 'string';
	if (!okItemType) {
		return 'Bad itemType';
	}

	const okIsSection = isSection !== undefined;
	if (!okIsSection) {
		return 'Bad isSection';
	}
	const hasExtraData = extraData !== undefined && extraData !== null;
	if (hasExtraData) {
		const okExtraData = Object.keys(extraData).length > 0 && Object.keys(extraData).every(key => !!extraData[key].uniqueValues);
		if (!okExtraData) {
			return 'ExtraData is missing uniqueValues';
		}
		const uniqueHeaders = headers.filter(header => header.unique).map(header => header.name);
		const noUnusedExtraData = Object.keys(extraData).every(key => uniqueHeaders.includes(key));
		if (!noUnusedExtraData) {
			return 'ExtraData has unused values';
		}
	}
	return 'ok';
}

export function getHeaderIntegrity(headers) {
	return headers.map((header, index) => {
		const format = header.format;
		let status = 'ok';
		const valueMapKeys = header.valueMap ? Object.keys(header.valueMap): [];
		switch (format) {
			case 'enum':
				if (!header.values || header.values.length === 0) {
					status = `Missing values in header ${header.name}`;
					break;
				}
				if (header.default === '') {
					status = `Default value should not be empty string in header ${header.name}`;
					break;
				}
				if (header.default) {
					if (!header.values.includes(header.default)) {
						status = `Default value missing in values in header ${header.name}`;
						break;
					}
					if (header.valueMap && !valueMapKeys.includes(header.default)) {
						status = `Default value missing in valueMap in header ${header.name}`;
						break;
					}
				}
				break;
			case 'list':
				if (!header.values || header.values.length === 0) {
					status = `Missing values in header ${header.name}`;
					break;
				}
				if (header.default === '') {
					status = `Default value should not be empty string in header ${header.name}`;
					break;
				}
				if (header.default) {
					if (Array.isArray(header.default)) {
						status = `Default value must be a string in header ${header.name}`;
						break;
					}
					if (!header.default.split(',').every(value => header.values.includes(value))) {
						status = `Default value missing in values in header ${header.name}`;
						break;
					}

					if (header.valueMap && !header.default.split(',').every(value => valueMapKeys.includes(value))) {
						status = `Default value missing in valueMap in header ${header.name}`;
						break;
					}
				}
				break;
			default:
				if (header.default === '') {
					status = `Default value should not be empty string in header ${header.name}`;
					break;
				}
				if (header.values && header.values.length === 0) {
					status = `Values cannot be empty in header ${header.name}`;
					break;
				}
		}
		return status;
	}).filter(status => status !== 'ok');
}


// check overall status of the file
// if fileStatus is not ok, do not show table
export function getFileStatus(result, headers, isSection = true) {
	const rows = getCleanedRows(result.data);
	const headerNames = getHeaderNames(headers);

	if (rows.length < 2) {
		return 'File is too small or broken';
	}
	const fileHeaders = rows[0];

	const hasName = isSection ? fileHeaders.includes('name'): true;
	if (!hasName) {
		return 'Missing header: name';
	}

	// check that same number of headers are present
	const sameNumberOfHeaders = fileHeaders.length === headers.length;
	if (!sameNumberOfHeaders) {
		return `Wrong number of headers in file. Found ${rows[0].length}, should be ${headers.length}. Correct headers: ${headerNames}`;
	}

	// check that all headers are present
	const sameHeaders = fileHeaders.every(header => headerNames.includes(header));
	if (!sameHeaders) {
		return `Headers missing or not spelled correctly. Correct headers: ${headerNames}`;
	}
	return 'ok';
}

export function getItemsToSave(result, headers) {
	const rows = getCleanedRows(result.data);
	const fileHeaders = rows[0];
	return rows.slice(1).map(row => mapData(row, headers, fileHeaders)); // create items
}

export function getFormatDescription(headers) {

	const langStringFunc = (key, value) => {
		const langStrings = {
			'https': 'Field must contain a valid https url',
			'email': 'Field must contain a valid email address',
			'integer': 'Field must contain a positive integer',
			'default': `If field is empty "${value}" is used`,
			'enum': 'Field must be a value in values',
			'list': 'Field can be empty or contain one or several values in values. Values are separated by comma.',
			'required': value ? 'Field value is required': 'Optional',
			'unique': 'Field must contain a unique value',
		};

		return key === 'format'
			? langStrings[value] || ''
			: langStrings[key] || '';

	};
	const getFormattedValues = header => {
		const headerValues = header.values || [];
		return headerValues.length === 0
			? ''
			: headerValues.length < 10
				? headerValues.join(', ')
				: headerValues.slice(0, 10).concat(['...']).concat([headerValues[headerValues.length - 1]]).join(', ');
	};

	return headers.map(header => {
		const name = header.name.split('.').pop();
		// const headerValues = header.values || [];
		// const values = headerValues.reduce((str, value) => str + value + '\n', '');
		const values = getFormattedValues(header);
		return {
			strings: Object.keys(header)
				.map(headerName => langStringFunc(headerName, header[headerName]))
				.filter(str => str.length > 0),
			values,
			name
		};
	});

}

export function downloadFile(fileName, csvContent) {
	const isIE = () => {
		const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
		let isIE = false;
		if (match !== -1) {
			isIE = true;
		}
		return isIE;
	};
	const ieVersion = isIE();

	const D = document;
	const a = D.createElement('a');
	const strMimeType = 'application/octet-stream;charset=utf-8';
	let rawFile;

	// IE10+
	if (navigator.msSaveBlob) {
		return navigator.msSaveOrOpenBlob(new Blob([csvContent],
			{type: strMimeType}),
			fileName);
	}

	if (ieVersion) {
		const frame = D.createElement('iframe');
		document.body.appendChild(frame);

		frame.contentWindow.document.open('text/html', 'replace');
		frame.contentWindow.document.write(csvContent);
		frame.contentWindow.document.close();
		frame.contentWindow.focus();
		frame.contentWindow.document.execCommand('SaveAs', true, fileName);

		document.body.removeChild(frame);
		return true;
	}

	//html5 A[download]
	if ('download' in a) {
		const blob = new Blob([csvContent],
			{type: strMimeType});
		rawFile = URL.createObjectURL(blob);
		a.setAttribute('download', fileName);
	} else {
		rawFile = 'data:' + strMimeType + ',' + encodeURIComponent(csvContent);
		a.setAttribute('target', '_blank');
	}

	a.href = rawFile;
	a.setAttribute('style', 'display:none;');
	D.body.appendChild(a);
	setTimeout(() => {
		if (a.click) {
			a.click();
			// Workaround for Safari 5
		} else if (document.createEvent) {
			const eventObj = document.createEvent('MouseEvents');
			eventObj.initEvent('click', true, true);
			a.dispatchEvent(eventObj);
		}
		D.body.removeChild(a);

	}, 100);
}
