export default {
	sessionId: null,
	visitingPageNow: null,
	psVisitorId: null,//TODO: refactor/remove
	langId: null,
	langID: null,//TODO: remove?
	siteId: null,
	score: null,
	toGroup: null,
	//previousGroupId: null,
	//isTransferred: null,
	isIdentified: null,
	//identification: null,
	//namedIdentity: null,

	identificationState: 'new', //possible states: [new, identified, suggested]
	visitorId: null, //used to query the REST api

	vId: null,
	pageVisits: [],
	queueData: {},
	joinedQueue: null,
	lostDialog: null,
	timeStampSessionStart: null,
	siteEntranceTime: null,

	connectionState: 2,
	approvalStatus: 0,

	initialState: null,

	actions: null,
	chatMessages: null,
	memberOf: null,

	timeSinceQueueEntry: null,
	timeSinceTransfer: null,

	visitAuthenticationState: null
};
