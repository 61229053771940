import PublishConfigurationController from './PublishConfigurationController';
import PublishConfigurationModalController from './PublishConfigurationModalController';
import PublishConfigurationTemplate from './PublishConfigurationTemplate';

export default angular.module('vngageApp.publish.publish', ['ui.router'])

	.controller('PublishConfigurationController', PublishConfigurationController)
	.controller('PublishConfigurationModalController', PublishConfigurationModalController)

	.constant('publishConfigurationModuleConfig', {
		state: 'root.publish.publish',
		access: 'Engage/Publish'
	})

	.config(function ($stateProvider, publishConfigurationModuleConfig) {
		'ngInject';

		$stateProvider.state(publishConfigurationModuleConfig.state, {
			url: 'publish',
			controller: 'PublishConfigurationController as vm',
			templateUrl: PublishConfigurationTemplate,
			data: {
				ncyBreadcrumbLabel: 'Publish',
				access: publishConfigurationModuleConfig.access
			},
			resolve: {
				context: function (APIConfigurationSetService) {
					'ngInject';
					return APIConfigurationSetService.getConfigurationSetContext();
				},
				activateContext: function (APIConfigurationSetService, context) {
					'ngInject';
					return APIConfigurationSetService.getActivateContext(context.dependencies);
				},
				userList: function (APIEndpointService, IdentityService, context) {
					'ngInject';
					if (IdentityService.currentlyDelegated()) {
						return context.dependencies.users;
					} else {
						// Only return non-delegated users
						return context.dependencies.users.filter(user => user.data.type === 0);
					}
				}
			}
		});
	});
