import _find from 'lodash/find';
import _filter from 'lodash/filter';

import ConversationRequest from './ConversationRequest';
import ServerActions from '../actions/ServerActions';
import ParticipantActions from '../actions/ParticipantActions';
import ParticipantUtils from '../../../components/utils/ParticipantUtils';
import AbilityAuthorizations from '../../../components/records/AbilityAuthorizationsRecord';

export default class ConversationSinceRequest extends ConversationRequest {
	constructor(reqGuid, restUrl, conversationId, since = 0) {
		super(reqGuid, restUrl, conversationId);
		this.since = since;
	}

	get asRequest () {
		return {
			body: {},
			method: 'get',
			tag: this.guid,
			uri: `${this.restUrl}Conversation/${this.conversationId}/Since?timestamp=${this.since}`
		}
	}

	resolve (response, statusCode) {
		return new Promise((resolve, reject) => {
			if (statusCode >= 400 && statusCode < 600) {
				console.log('<Since ERROR> ', response, statusCode);
				ServerActions.requestError(this.conversationId, statusCode, response.errorCode);
				reject(response);
			} else {
				if (response && response.length > 0) {
					console.log('<DG Since> ', response);
					let responseEvent;

					if (_find(response, {type: 'conversationClosed'})) {
						ServerActions.conversationClosed(this.conversationId);
					}

					if (responseEvent = _filter(response, {type: 'participantJoined'}) && isFilledArray(responseEvent)) {
						console.warn('PJ', responseEvent);
						ParticipantActions.participantJoined(this.conversationId, responseEvent);
					}

					if (responseEvent = _filter(response, {type: 'participantLeft'}) && isFilledArray(responseEvent)) {
						ParticipantActions.participantLeft(this.conversationId, responseEvent);
					}

					if (responseEvent = _find(response, {type: 'participantAuthorized'})) {
						const addedAuth = ParticipantUtils.participantAuthorizationChanged(responseEvent.authorizations);
						if (addedAuth.coBrowsing || addedAuth.video) {
							//console.log('addedAuth:cobrow / video', addedAuth.coBrowsing, addedAuth.video);
							ServerActions.rewindConversation(this.conversationId);
						}
					}

					if (responseEvent = _find(response, {type: 'participantDeauthorized'})) {
						const removedAuth = ParticipantUtils.participantAuthorizationChanged(responseEvent.authorizations);
						if (removedAuth.coBrowsing) {
							ServerActions.visualGuidanceStatus(this.conversationId, 'paused');
						}
						if (removedAuth.video) {
							ServerActions.receiveVideoAction(this.conversationId, {}, false);
						}
					}

					if (responseEvent = _find(response, {messageType: 'domHighlight'})) {
						ServerActions.visualGuidanceHighlight(this.conversationId, responseEvent);
					}

					if (responseEvent = _find(response, {messageType: 'browserInfo'})) {
						ServerActions.receiveBrowserInfo(this.conversationId, responseEvent);
					}

					//if (responseEvent = _find(response.slice().reverse(), {messageType: 'video'})) {
					if (responseEvent = _find(response, {messageType: 'video'})) {
						const videoConfig = {
							outStreamId: responseEvent.streamId,
							inStreamId: '1' + responseEvent.streamId
						};
						ServerActions.receiveVideoAction(this.conversationId, videoConfig, responseEvent.activity === 'start');
					}

					ServerActions.receiveSince(response, this.conversationId);
				}
				resolve('since ok');
			}

		});

	}
}

function isFilledArray(array) {
	return array && array.length > 0;
}
