import { EventEmitter } from 'events';
import assign from 'object-assign';

const CHANGE_EVENT = 'change';

function create(storeConfig) {

	return assign({}, EventEmitter.prototype, storeConfig, {

		emitChange () {
			this.emit(CHANGE_EVENT);
		},

		addChangeListener (callback) {
			this.on(CHANGE_EVENT, callback);
		},

		removeChangeListener (callback) {
			this.removeListener(CHANGE_EVENT, callback);
		}

	});
}

export default {
	create: create
};
