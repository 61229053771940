export default function() {
    return {
        formatSelectableRoles: function (roleClaims) {
            return roleClaims.map(function(roleClaim) {
                return {
                    refValue: roleClaim.data.claimType,
                    displayName: roleClaim.data.displayName
                };
            });
        },
        formatSelectableGroups: function(groups) {
            return groups.map(function (group) {
                return {
                    refValue: group.data.id,
                    displayName: group.data.name
                };
            });
        }
    };
};
