import React, {useState} from 'react';
import {
	Button,
	Input,
	OverlayTrigger,
	Tooltip,
	Radio
} from 'react-bootstrap';
import StateHistory from '../../utils/StateHistory';
import AccountConfigurationWrapper from '../../utils/AccountConfigurationWrapper';
import {getSortedState} from '../../utils/DebugTools';

const DebugButtons = (props) => {
	const [mockAccountEnabled, setMockAccountEnabled] = useState(AccountConfigurationWrapper.isMockEnabled());

	const logAccount = () => {
		console.log('account', AccountConfigurationWrapper.configuration);
	};

	const logState = () => {
		console.log('state', getSortedState(props.entireState));
		// console.log('state', JSON.parse(JSON.stringify(props.entireState)));
	};

	const logAction = () => {
		console.log('action', StateHistory.getJSActionAt(props.pos));
	};

	const logPoll = () => {
		props.logPoll();
	};

	const logCls = () => {
		console.clear();
	};

	// mock account
	const toggleMock = () => {
		if (mockAccountEnabled) {
			AccountConfigurationWrapper.disableMock();
		} else {
			AccountConfigurationWrapper.enableMock();
		}
		setMockAccountEnabled(AccountConfigurationWrapper.isMockEnabled());
	};

	const onHistoryStateChanged = (event) => {
		props.setHistoryState(event.currentTarget.value);
	};

	const userInfoText = Object.keys(props.debugInfo).map(key => <p key={key}>
		<strong>{key + ': '}</strong>{props.debugInfo[key] + ''}</p>);
	const userInfo = (
		<Tooltip id="tooltip"><h3>User info</h3><br/>{userInfoText}</Tooltip>
	);

	const accountLabel = mockAccountEnabled ? 'mocked Account': 'standard Account';
	const timestamp = new Date(StateHistory.getActionTimestampAt(props.pos)).toISOString();

	const KibanaButtons = props.entireState.get('panelOrder').map(conversationId => {
		const bgColor = props.entireState.getIn(['conversations', conversationId, 'UI', 'panelHeaderColor']);
		return <Button key={conversationId} style={{backgroundColor: bgColor, color: '#FFF'}} size="xs"
					   title="Kibana" onClick={() => props.openKibana(conversationId)}>Kibana</Button>;
	});

	const logConversation = (conversationId) => {
		console.log('conversationId', conversationId);
		console.log('caseType', props.entireState.getIn(['conversations', conversationId, 'currentCaseType']));
		console.log('caseId', props.entireState.getIn(['conversations', conversationId, 'caseId']));
		console.log('groupId', props.entireState.getIn(['conversations', conversationId, 'groupId']));
		console.log('refNr', props.entireState.getIn(['conversations', conversationId, 'refNumber']));
		console.log('connectionStatus: ' + props.entireState.getIn(['conversations', conversationId, 'connectionStatus']), 'conversationStatus: ' + props.entireState.getIn(['conversations', conversationId, 'conversationStatus']));
	};

	const infoButtons = props.entireState.get('panelOrder').map(conversationId => {
		const bgColor = props.entireState.getIn(['conversations', conversationId, 'UI', 'panelHeaderColor']);
		return <Button
			key={conversationId} style={{backgroundColor: bgColor, color: '#FFF'}} size="xs"
			title="info" onClick={() => logConversation(conversationId)}>
			<i className="vngage-icon-info"/>
		</Button>;
	});

	const userName = AccountConfigurationWrapper.configuration?.user?.profile?.displayName ?? '';

	return (
		<div>
			<div className="group-container">
				<Button variant="primary" size="xs" title="account" onClick={logAccount}>account</Button>
				<Button variant="primary" size="xs" title="action" onClick={logAction}>action</Button>
				<Button variant="primary" size="xs" title="state" onClick={logState}>state</Button>
				<Button variant="primary" size="xs" title="poll" onClick={logPoll}>poll</Button>
				<Button variant="primary" size="xs" title="cls" onClick={logCls}>cls</Button>
				<Button variant="outline-warning" size="xs" title="refresh" onClick={props.refresh}>refresh</Button>
			</div>
			<div className="group-container history-states">
				<span className="padding-right">Play mode:</span>
				<span className="padding-right">
					<input type="radio"
						   name="state"
						   value='state'
						   checked={props.historyState === 'state'}
						   onChange={(event) => onHistoryStateChanged(event)}
					/>
					<span className="padding-left">State</span>
				</span>
				<span>
					<input type="radio"
						   name="action"
						   value='action'
						   checked={props.historyState === 'action'}
						   onChange={(event) => onHistoryStateChanged(event)}/>
					<span className="padding-left">Action</span>
				</span>
			</div>
			<div className="group-container step-controls">
				<i onClick={() => props.moveToPosition(-props.hugeStep)}
				   className="control-item vngage-icon-faster-backward"><span className="badge">1000</span></i>
				<i onClick={() => props.moveToPosition(-props.evenBiggerStep)}
				   className="control-item vngage-icon-faster-backward"><span className="badge">100</span></i>
				<i onClick={() => props.moveToPosition(-props.bigStep)}
				   className="control-item vngage-icon-fast-backward"><span className="badge">10</span></i>
				<i onClick={() => props.moveToPosition(-props.step)}
				   className="control-item vngage-icon-backward"><span className="badge">1</span></i>
				{!props.isPlaying
					? <i onClick={props.togglePlay} className="control-item large vngage-icon-line-play"/>
					: <i onClick={props.togglePlay} className="control-item large vngage-icon-stop"/>
				}
				<i onClick={() => props.moveToPosition(props.step)}
				   className="control-item vngage-icon-forward-2"><span className="badge">1</span></i>
				<i onClick={() => props.moveToPosition(props.bigStep)}
				   className="control-item vngage-icon-fast-forward"><span className="badge">10</span></i>
				<i onClick={() => props.moveToPosition(props.evenBiggerStep)}
				   className="control-item vngage-icon-faster-forward"><span className="badge">100</span></i>
				<i onClick={() => props.moveToPosition(props.hugeStep)}
				   className="control-item vngage-icon-faster-forward"><span className="badge">1000</span></i>
			</div>
			<div className="row well">
				<div className="col-sm-3">
					<strong>Position:</strong><br/>
					<strong>Time:</strong><br/>
					<strong>Action:</strong>
				</div>
				<div className="col-sm-9">
					{props.pos + '/' + (props.historySize - 1)}
					<Button
						variant="warning"
						size="xs"
						onClick={() => props.trimToPosition(props.pos)}>
						trim
					</Button>
					<br/>
					{timestamp}<br/>
					{StateHistory.getActionTypeAt(props.pos)}
				</div>
			</div>
			<div>user:{userName}</div>
			<div className="group-container">
				<div className="switchContainer">
					<div className="flip-container">
						<label className="flipswitch">
							<input
								type="checkbox"
								className="switch"
								defaultChecked={mockAccountEnabled}
								onChange={toggleMock}
							/>
							<span className='switch'/>
							<span className="badge">{accountLabel}</span>
						</label>
					</div>
					<div className="flip-container">
						<label className="flipswitch">
							<input
								type="checkbox"
								className="switch"
								defaultChecked={props.useDebugKeys}
								onChange={props.toggleDebugKeys}
							/>
							<span className='switch'/>
							<span className="badge">Use debug keys</span>
						</label>
					</div>
					<div className="flip-container">
						<label className="flipswitch">
							<input
								type="checkbox"
								className="switch"
								defaultChecked={props.timestampMockEnabled}
								onChange={props.toggleTimestampsMock}
							/>
							<span className='switch'/>
							<span className="badge">mock timestamps</span>
						</label>
					</div>
				</div>
				<OverlayTrigger trigger="click" placement="right" overlay={userInfo}>
					<Button className="dark iButton" variant="default" size="xs">i</Button>
				</OverlayTrigger>
			</div>
			<div className="group-container">
				{infoButtons}
			</div>
			<div className="group-container">
				{KibanaButtons}
			</div>
		</div>
	);
};
export default DebugButtons;


