import React from 'react';
import createReactClass from 'create-react-class';

export default createReactClass({
	render () {
		return (
			<div className="dialog-panel">
				<div className="panel-heading">
					{this.props.title}
				</div>
				<div className="panel-body">
					{this.props.children}
				</div>
			</div>
		)
	}
});
