import moment from 'moment-timezone';
import _isEmpty from '../../../../node_modules/lodash/isEmpty';
import _get from '../../../../node_modules/lodash/get';

export default function($q, d3, APIStatisticsService, APICaseService, APIEndpointService, APIConfigurationSectionService, MetricFormatter) {
	'ngInject';

	const useMockedTestData=false;

	// Define an object for looking up IDs and outcome-codes
	let lookup = {
		populated: false,
		siteNames: {},
		userNames: {},
		groupNames: {},
		groupSiteIds: {},
		caseTypeNames: {},
		outcomes: {
			0: 'Positive',
			1: 'Neutral',
			2: 'Negative'
		}
	};

	function populateUserAndGroupLookupLists(sites,users,groups,caseTypes) {
		sites.forEach((s) => {
			lookup.siteNames[s.data.id] = s.data.name;
		});
		users.forEach((u) => {
			lookup.userNames[u.data.id] = u.data.profile.displayName;
		});
		groups.forEach((g) => {
			lookup.groupNames[g.data.id] = g.data.name;
			lookup.groupSiteIds[g.data.id] = g.data.siteId;
		});
		caseTypes.forEach((g) => {
			lookup.caseTypeNames[g.data.id] = g.data.name;
		});
		lookup.populated=true;
	}

	function resultIsEmpty(res) {
		return ((!res) || (!res.data) || _isEmpty(res.data));
	}

	function addCaseSpecificProperties(caseData) {
		caseData.groupName 	= _get(lookup.groupNames,_get(caseData,'ownedBy.groupId.id'),'--');
		caseData.userName 	= _get(lookup.userNames,_get(caseData,'ownedBy.userId'), '--');
		caseData.caseType 	= _get(lookup.caseTypeNames,_get(caseData,'type.id'), '--');
		caseData.siteName 	= _get(lookup.siteNames,_get(lookup.groupSiteIds,_get(caseData,'ownedBy.groupId.id')),'--');
		caseData.outcome 	= _get(lookup.outcomes,_get(caseData,'closure.outcome'),'--');
		caseData.reason 	= _get(caseData, 'closure.name', '--');
		caseData.bucket 	= caseData.createdAt || '--';
		return caseData;
	}

	function getPreparedCaseData(data) {
		let cases = [];
		data.forEach((c) => {
			cases.push(addCaseSpecificProperties(c.data));
		});
		return cases;
	}

	function addPropertiesToData(viewConfig, viewData, projection) {
		if (!viewData.data) {
			console.error('no data');
			return [];
		}

		if (viewConfig.metricName === 'caseBrowser') {
			viewData.data=getPreparedCaseData(viewData.data);
		}

		const dtgFormatter = MetricFormatter.getFormatter('dtgFormat');
		for (let i=0; i<viewData.data.length; i++) {
			let d = viewData.data[i];

			if (projection && Array.isArray(d)) {
				// We have a projection object and the data is an array: Expand it using the projection...
				let dExpanded = {};
				for (let j = 0; j < projection.length; j++) {
					dExpanded[projection[j]] = d[j];
				}
				d = viewData.data[i] = dExpanded;
			}

			d.dtg = moment(d.bucket).tz(viewData.timezone);
			d.hour = dtgFormatter.parse(d.dtg.format('YYYY-MM-DDTHH:00:00ZZ'));	// Hourly bucket
			// d.day  = dtgFormatter.parse(d.dtg.format('YYYY-MM-DDT00:00:00ZZ'));	// Daily bucket

			viewConfig.measures.forEach((m) => {
				if (m.calculation) {
					d[m.key] = MetricFormatter.calculate(m.calculation,d);
				}

				switch (m.type) {
					case 'string':
						if (d[m.key] === null || d[m.key] === undefined) {
							d[m.key] = '[unknown]';
						}
						break;
					case 'date':
						d[m.key] = moment(d[m.key]).tz(viewData.timezone).format(m.format);
						break;
					case 'count':
					case 'number':
					case 'duration':
						d[m.key] = +d[m.key];
						break;
				}
			});
		}
	}

	function fetchCases(params) {
		const caseParams = {
			caseState: 2,
			maxDate: params.range.to,
			maxTime: null,
			minDate: params.range.from,
			minTime: null,
			ownedByUserId: null,
			skip: 0,
			sortBy: 'createdAt',
			sortOrder: 'desc'/*,
			take: 100*/
		};

		if (!lookup.populated) {
			return $q((resolve, reject) => {
				$q.all({
					sites: APIEndpointService.site.query().$promise,
					users: APIEndpointService.user.query({limit: 10000}).$promise,
					groups: APIEndpointService.group.query().$promise,
					caseTypes: APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.customerCase),
					cases: (useMockedTestData ? null: APICaseService.getCases(caseParams))
				}).then((res) => {
					populateUserAndGroupLookupLists(res.sites, res.users, res.groups, res.caseTypes);
					if (useMockedTestData) {
						d3.json('http://localhost:2992/src/app/statistics/dynamic/mockData/cases_mock.json', function (data) {
							console.log('1 data.length:', data.length);
							resolve({data: data});
						});
					} else {
						resolve(res.cases);
					}
				}, (err) => {
					reject(err);
				});
			});
		} else {
			if (useMockedTestData) {
				return $q((resolve, reject) => {
					d3.json('http://localhost:2992/src/app/statistics/dynamic/mockData/cases_mock.json', function (data) {
						console.log('2 data.length:', data.length);
						resolve({data: data});
					});
				});
			} else {
				return APICaseService.getCases(caseParams);
			}
		}
	}

	function fetch(metricName, params) {
		if (metricName === 'caseBrowser') {
			return fetchCases(params);
		} else {
			// return APIStatisticsService.queryMetricData(metricName, params);
			return APIStatisticsService.queryMetricDataMinimized(metricName, params);	// [ TL ] Using the minimized endpoint instead (the result will be an array, expandable using params.projection)
		}
	}

	function query(viewConfig,viewData) {
		return $q((resolve, reject) => {
			viewData.queryParams.projection=['bucket'];
			viewConfig.measures.forEach((m) => {
				if (!m.calculation){
					viewData.queryParams.projection.push(m.key);
				}
			});
			fetch(viewConfig.metricName, viewData.queryParams).then((res) => {
				if (viewData.disposed) { // Abort if this view is disposed (happens when user navigates during long lasting calls to API)
					reject('disposed');
				} else if (resultIsEmpty(res)) {
					reject('empty');
				} else {
					viewData.data=res.data;
					// console.time('addPropertiesToData');
					addPropertiesToData(viewConfig, viewData, viewData.queryParams.projection);
					// console.timeEnd('addPropertiesToData');
					resolve(viewData.data);
				}
			}, (err) => {
				reject(err);
			});
		});
	}

	const service = {
		query: query
	};
	return service;
};
