import moment from 'moment-timezone';
import _find from 'lodash/find';
import Format from '../../desktopDeux/utils/Format';

export default function ($scope, $uibModalInstance, $translate, $timeout, $q, options, caseBrowserService, APIConfigurationSectionService, APICaseService, caseBrowserNotificationService, SessionService, NotificationService, vngageYesNoModalService) {
	'ngInject';

	var vm = this;
	vm.advanced = false; // default false
	vm.showEvents = false; // default false
	vm.options = options;
	vm.caseItem = caseBrowserService.formatCase(vm.options.caseItem);
	vm.formatSatisfactionScore = caseBrowserService.formatSatisfactionScore;
	vm.satisfactionScore = vm.caseItem.satisfactionScore;
	vm.participants = caseBrowserService.getParticipants(vm.options.userList, vm.caseItem.originalCaseItem);
	vm.conversations = caseBrowserService.getConversations(vm.caseItem.originalCaseItem);
	vm.cancel = $uibModalInstance.dismiss;

	vm.doCleanCase = () => {
		return $q((resolve, reject) => {
			APICaseService.cleanCase(vm.caseItem.caseId).then(() => {
				NotificationService.success({
					header: $translate.instant('caseBrowser.NOTIFICATION_CASE_CLEANED_OK')
				});
				resolve({
					operation: 'cleaned',
					caseId: vm.caseItem.caseId
				});
			}).catch((errObject) => {
				if (!errObject.data) {
					// Will happen if case ID has wrong format (will cause a "Response to preflight request doesn't pass access control check" from BE, and data will be empty)
					NotificationService.error({
						header: $translate.instant('caseBrowser.NOTIFICATION_ERROR_CLEANING_CASE')
					});
				}
				reject(errObject);
			});
		});
	};

	vm.cleanCase = () => {
		const yesNoModalPromise = vngageYesNoModalService.open({
			title: $translate.instant('caseBrowserModal.CLEAN_CASE_TITLE'),
			heading: $translate.instant('caseBrowserModal.CLEAN_CASE_HEADING'),
			bodyTexts: [$translate.instant('caseBrowserModal.CLEAN_CASE_DESCRIPTION')]
		}, vm.doCleanCase).result;

		yesNoModalPromise.then(cleanCaseResult => {
			if (cleanCaseResult) {
				$uibModalInstance.close(cleanCaseResult);
			}
		}).catch(() => {
			// Abort/modal dismissed
		});
	};

	vm.caseCreatedDate = moment.utc(vm.caseItem.originalCaseItem.data.createdAt).tz(SessionService.getUserTimezone()).format('MMMM D - YYYY'); // TODO: Localize more?
	vm.caseClosedDate = (vm.caseItem.originalCaseItem.data.closedAt ? moment.utc(vm.caseItem.originalCaseItem.data.closedAt).tz(SessionService.getUserTimezone()).format('MMMM D - YYYY'): null); // TODO: Localize more?
	if (vm.caseCreatedDate === vm.caseClosedDate) {
		// Case created and closed on the same date - only display one date for the case...
		vm.caseDate = vm.caseCreatedDate;
	}

	const conversation = vm.caseItem.originalCaseItem.data.conversations[0];
	if (conversation) {
		vm.reservation = _find(conversation.events, obj => obj.type === 'conversationReservation');
		vm.messages = conversation.messages;
		vm.refNumber = conversation.refNumber;
		vm.authenticationLevel = (_find(conversation.messages, obj => obj.authenticationLevel) || {authenticationLevel: ''}).authenticationLevel;
		vm.messageTitleObj = _find(conversation.messages, obj => obj.messageType === 'title');

		if (vm.reservation) {
			if (vm.reservation.reservation.groupId.toLowerCase() === vm.caseItem.originalCaseItem.data.groupId.toLowerCase()) {
				// The reservation's groupId matches the case's groupId (as it should): Use the case's groupName in the reservation as well
				vm.reservation.reservation.groupName = vm.caseItem.group;
			} else {
				// The reservation's groupId does NOT match the case's groupId: Display the groupId (should not happen!)
				vm.reservation.reservation.groupName = vm.reservation.reservation.groupId;
			}
		}

		let opportunityId = (_find(conversation.events, obj => obj.opportunityId) || {opportunityId: ''}).opportunityId;
		let solutionId = (_find(conversation.events, obj => obj.solutionId) || {solutionId: ''}).solutionId;

		vm.visitorPlatform = Format.getVisitorPlatform(vm.messages);
		APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.opportunity).then(opportunities => {
			const opportunity = _find(opportunities, obj => obj.data.id === opportunityId);
			if (opportunity) {
				vm.opportunityName = opportunity.data.name;
			}
		});

		APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.solution).then(solutions => {
			const solution = _find(solutions, obj => obj.data.id === solutionId);
			if (solution) {
				vm.solutionName = solution.data.name;
			}
		});
	}

	vm.formatWithTZ = date => {
		return moment.utc(date).tz(SessionService.getUserTimezone()).format('LTS');
	};

/*	vm.formatSatisfactionScore = function(score){
		switch (score) {
			case '100%':
				return 100;
			case '75%':
				return 75;
			case '50%':
				return 50;
			case '25%':
				return 25;
			default:
				return '-';
		}
	}; */
}
