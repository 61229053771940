import React from 'react';
import {Modal, ModalTitle, ModalHeader, Button} from 'react-bootstrap';
import ContentUploadTools from '../../utils/ContentUploadTools';
import ContentPreview from './ContentPreview.react';
import {useGetTranslation} from './../LangContext';

const DownloadModal = (props) => {
	const getTranslation = useGetTranslation();

	const saveAndHideModal = () => {
		ContentUploadTools.saveFile(props.fileObject);
		props.contentLog('saving file', {filename: props.fileObject.uploadInfo.filename});
		props.hideModal();
	};

	const downloadReady = !!props.fileObject;
	const fObj = props.fileObject;
	return (
		<Modal show={true} onHide={props.hideModal}>
			<Modal.Header closeButton>
				<Modal.Title>{downloadReady ? fObj.uploadInfo.filename: ''}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				{downloadReady
					? <ContentPreview {...fObj}/>
					: <div className="content-spinner"/>
				}

			</Modal.Body>

			<Modal.Footer className="content-upload-footer">
				<Button disabled={!downloadReady} onClick={saveAndHideModal}>
					{getTranslation('btnSave')}
				</Button>
				<Button variant="default" onClick={props.hideModal}>{getTranslation('btnCancel')}</Button>
			</Modal.Footer>

		</Modal>
	);
};
export default DownloadModal;

