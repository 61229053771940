import React from 'react';
import createReactClass from 'create-react-class';
import { injectIntl } from 'react-intl';

function capitalizeString(string) {
	return (string ? string.charAt(0).toUpperCase() + string.slice(1) : '');
};

export default injectIntl(createReactClass({

	getDefaultProps () {
		return {
			title: '',
			name: '',
			isCurrentUser: false
		}
	},

	render () {

		let key = `role${capitalizeString(this.props.title)}`;
		let title = this.props.intl.formatMessage({id:key});
		let name = this.props.name && this.props.name.length > 0 ? `${this.props.name}` : '';
		let isMe = this.props.isMe;
		let output = (<span>
						<span className="author-title">{title}</span> <span className="author-name">{name}</span>
					</span>);

		if (isMe) {
			output = (<span>
						<span className="author-title">{this.props.intl.formatMessage({id:'roleYou'})}</span>
					</span>) ;
		}

		return (
			<span>{output}</span>
		)
	}

}));
