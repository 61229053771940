import assign from 'object-assign';
import _difference from 'lodash/difference';
import _filter from 'lodash/filter';

import AppDispatcher from '../dispatcher/CSDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import StoreFactory from '../../../components/utils/StoreFactory';
import MessageUtils from '../../../components/utils/MessageUtils';
import VisualGuidance from '../utils/VisualGuidance';
import conversationRecord from '../../../components/records/ConversationRecord';

import ProfileStore from './ProfileStore';
import ParticipantUtils from '../../../components/utils/ParticipantUtils';
import VGSettings from '../utils/vgSettings';
import ParticipantStore from './ParticipantStore';

const ActionTypes = AppConstants.ActionTypes;

let state = {
	CONVERSATIONS: new Map()
};

var ConversationsStore = StoreFactory.create({

	/**
	 * find conversation
	 * @param {String} conversationId
	 * @returns {Object} conversation found or undefined
	 */
	find (conversationId) {
		return state.CONVERSATIONS.get(conversationId);
	},

	/**
	 * returns all conversations
	 * @returns {Array} conversations
	 */
	get all() {
		return state.CONVERSATIONS;
	},

	getConversationState (conversationId) {
		const conversation = this.find(conversationId);
		return conversation && conversation.state || '';
	},


	isConversationIdle (conversationId) {
		let conversation = this.find(conversationId);
		return conversation && !!conversation.idle;
	},

	/**
	 * returns if conversation is streaming video or audio
	 * @param {String} conversationId
	 * @returns {Boolean}
	 */
	isConversationStreaming (conversationId) {
		let conversation = this.find(conversationId);
		return conversation && (conversation.streaming.video || conversation.streaming.audio);
	},

	getActiveVideoConversation () {
		var conversations = this.all();
		let result = conversations.find(function(conversation){
			return (conversation.streaming.video || conversation.streaming.audio);
		});

		return result && result.id;
	},

	getInviteOptionsForConversation (conversationId) {
		let conversation = this.find(conversationId);
		if (conversation && conversation.inviteOptions){
			return conversation.inviteOptions;
		}
		return null;
	}

});

const registeredCallback = function(payload) {
	const action = payload.action;
	const { conversationState, rawMessages, conversationId, conversations, propsToSet } = action;
	const MY_ID = ProfileStore.getProfileId();

	let conversation = ConversationsStore.find(conversationId),
		mappedMessages,
		since;

	switch (action.type) {

		case ActionTypes.CONVERSATION_IDLE :
			setConversationProps(conversationId, {idle: action.idle});
			break;

		case ActionTypes.RECEIVE_AS_STATE :

			since = conversationState.stateTime;
			mappedMessages = MessageUtils.mapConversationMessages(conversationState.messages, conversationId);


			if (!conversation || conversation && !conversation.visualGuidance) {

				conversation = assign({}, conversationRecord(), conversationState, {
					showMeta: true,
					visualGuidance: new VisualGuidance(conversationState.id, VGSettings.getSettings())
				});
				conversation = mapVideoState(conversation, mappedMessages);
				setConversationProps(conversation.id, conversation);
			} else {
				setConversationProps(conversation.id, assign({}, conversation, conversationState));
			}

			updateConversationSince(conversationId, since);

			addNavigations(conversation, mappedMessages);
			setBrowserInfo(conversation, conversationState.messages);

			// Handle 'metadataExchange' on RECEIVE_AS_STATE (both directions, i.e. include all messages)
			handleMetadataExchange(conversation, mappedMessages, 'state', MY_ID);

			break;

		case ActionTypes.RECEIVE_SINCE :
			since = rawMessages[rawMessages.length-1].createdAt;
			mappedMessages = MessageUtils.mapConversationMessages(action.rawMessages, conversationId);
			addNavigations(conversation, mappedMessages);

			// Handle 'metadataExchange' on RECEIVE_SINCE (only incoming, i.e. "MessagesWithoutMe")
			handleMetadataExchange(conversation, MessageUtils.getMessagesWithoutMe(mappedMessages, MY_ID), 'since', MY_ID);

			if (conversation && !conversation.isInitialized && !conversation.abort) {
				addConversation(conversationId);
				updateConversationSince(conversationId, since)
			} else {
				setConversationProps(conversationId, {abort:false});
			}
			break;

		case ActionTypes.REQUEST_ERROR :
			let statusCode = action.statusCode,
				errorCode = action.errorCode;

			if ([401, 403, 403].indexOf(errorCode) > -1) {
				ConversationsStore.remove(conversationId);
			}
			break;

		case ActionTypes.PROCESS_CONVERSATIONS :
			if (!filterOutInactive(conversations)) return false;
			break;

		case ActionTypes.CONVERSATION_CLOSED :
			setConversationProps(conversationId, {state:'closed'});
			break;

		case ActionTypes.CLEAR_CONVERSATIONS :
			clearConversations();
			break;

		case ActionTypes.ADD_CONVERSATION :
			addConversation(conversationId);
			break;

		case ActionTypes.REMOVE_CONVERSATION :
			removeConversation(conversationId);
			break;

		case ActionTypes.UPDATE_CONVERSATION :
			setConversationProps(conversationId, propsToSet);
			break;

		case ActionTypes.VIDEO_STREAM :
		case ActionTypes.CREATE_VIDEO_INVITATION :
			setConversationProps(conversationId, {streaming: {
				video: action.isStreaming,
				audio: action.isStreaming
			}});
			break;

		case ActionTypes.PARTICIPANT_LEFT :
		case ActionTypes.PARTICIPANT_JOINED :
			let participant = action.participant,
				didLeaveOrJoin = false,
				joinedParticipants = participant.map(ParticipantUtils.getParticipantEntityId);

			joinedParticipants.map( pid => {
				if (pid === MY_ID) {
					didLeaveOrJoin = true;
					setConversationProps(conversationId, {abort: true, initialized: false, since: 0});
				}
			});

			// Close conversation when visitor leaves
			if (action.type === ActionTypes.PARTICIPANT_LEFT &&
				action.participant.length &&
				!didLeaveOrJoin &&
				ParticipantStore.getParticipantsCountJoinedOfConversation(conversationId).length < 2
			) {
				setConversationProps(conversationId, {state: 'closed'});
			}

			// Remove invitation (if exists) when an 'internalExpert' joins
			if (action.type === ActionTypes.PARTICIPANT_JOINED && participant.length && participant[0].info.role==='internalExpert') {
				setConversationProps(conversationId, {inviteOptions: null});
			} else if (!didLeaveOrJoin) {
				return false;
			}
			break;

		case ActionTypes.INVITE_PARTICIPANT_SUCCESS :
			setConversationProps(conversationId, {inviteOptions: {inviteCode : action.inviteCode}});
			break;

		case ActionTypes.INVITE_PARTICIPANT_ERROR :
			setConversationProps(conversationId, {inviteOptions: {error: true}});
			break;

		case ActionTypes.INVITE_PARTICIPANT_DISMISSED :
			// Agent closed the modal invite dialog: Remove invitation
			setConversationProps(conversationId, {inviteOptions: null});
			break;

		default :
			return true;
	}

	// if we made down here... emit change event
	ConversationsStore.emitChange();
};

/**
 * add converssation
 * @param conversationId
 */
function addConversation(conversationId) {
	if (!conversationId || ConversationsStore.find(conversationId)) {
		return;
	}
	state.CONVERSATIONS.set(conversationId, assign({}, conversationRecord()));
}

/**
 * remove conversation
 * @param conversationId
 */
function removeConversation(conversationId = '') {
	if (Array.isArray(conversationId)) {
		conversationId.forEach( c => {
			state.CONVERSATIONS.delete(c);
		});
	} else {
		state.CONVERSATIONS.delete(conversationId);
	}
}

/**
 * set properties in conversation
 * @param conversationId
 * @param properties
 */
function setConversationProps(conversationId, properties) {
	let conversation = ConversationsStore.find(conversationId);
	if (conversation) {
		let convo = assign(conversation, properties);
		state.CONVERSATIONS.set(conversationId, convo);
	}
}

/**
 * update timestamp on last since-request of conversation
 * @param conversationId
 * @param since
 */
function updateConversationSince(conversationId, since) {
	let conversation = ConversationsStore.find(conversationId);
	if (conversation && !conversation.isInitialized) {
		setConversationProps(conversationId,  {
			initialized: true,
			since: since
		});
	}
}

/**
 * filter out inactive conversations
 * @returns {boolean}
 * @param activeConversations
 */
function filterOutInactive(activeConversations) {
	let storeDiff;

	if (activeConversations.length === 0) {
		clearConversations();
		return true;
	} else {
		var inStore = [];
		ConversationsStore.all.forEach(function(value, key) {
			inStore.push(key);
		});

		activeConversations.map(addConversation);

		// get the diff in order to clear no longer locked visitors
		storeDiff = _difference(inStore, activeConversations);

		if (storeDiff.length > 0) {
			storeDiff.map(removeConversation);
			return true;
		}

		return false;
	}
}

/**
 * clear conversation
 */
function clearConversations() {
	state.CONVERSATIONS.clear();
}

/**
 * add navigations to conversation
 * @param conversation
 * @param msgs
 */
function addNavigations(conversation, msgs) {
	// update vg
	let navigations = msgs.filter( msg => {
		return msg.messageType === 'navigation' || msg.messageType === 'domUpload';
	});

	if (navigations.length > 0) {
		conversation.visualGuidance.addNavigations(navigations, true);
	}
}

/**
 * handle metadataExchange messages in conversation
 * @param conversation
 * @param msgs
 */
function handleMetadataExchange(conversation, msgs, receiveType, myVisitId) {
	// Handle 'metadataExchange' for vg
	let metadataExchangeMessages = msgs.filter( msg => {
		return msg.messageType === 'metadataExchange';
	});
	if (metadataExchangeMessages.length > 0) {
		conversation.visualGuidance.handleReceivedMetadataExchangeMessages(metadataExchangeMessages, receiveType, myVisitId);
	}
}

/**
 * set browserinfo of visitor in conversation
 * @param conversation
 * @param messages
 */
function setBrowserInfo(conversation, messages) {
	let browserInfoOfState = _filter(messages, { messageType: 'browserInfo' });
	if (browserInfoOfState.length > 0) {
		let lastBrowserInfo = browserInfoOfState[browserInfoOfState.length-1];
		let browserInfo = {
			width: lastBrowserInfo.width,
			height: lastBrowserInfo.height,
			hasFlash: lastBrowserInfo.hasFlash
		};
		conversation.visualGuidance.setState({browserAttributes: browserInfo})
	}
}

/**
 * map video state upon reload in state of conversation
 * @param conversation
 * @param messages
 * @returns {*}
 */
function mapVideoState(conversation, messages) {
	let videoOfState = _filter(messages, { messageType: 'video'});
	if (videoOfState.length > 0) {
		let lastVideoState = videoOfState[videoOfState.length-1];
		let isStreaming = lastVideoState.activity;

		//TODO: never pass to here as a string
		if (typeof isStreaming === 'string') {
			isStreaming = isStreaming === 'start';
		}

		conversation.streaming = {
			video: isStreaming,
			audio: isStreaming
		};
	}

	return conversation;
}


ConversationsStore.dispatchToken = AppDispatcher.register( registeredCallback );

export default ConversationsStore;
