import ServerActions from '../../actions/ServerActions';

var panel = function (params) {

	// fade in and out class names
	var cssBase = "psPanel_container";
	if (typeof params.className != 'undefined') cssBase += ' ' + params.className;
	var cssInactive = cssBase + " psPanel_opacityNull";
	var cssActive = cssBase + " psPanel_opacityFull";

	var cssOverLayLoader = 'psPanel_overlay psPanel_overlayVisible psPanel_overlayLoader';
	var cssOvelayInactive = 'psPanel_overlay psPanel_overlayVisible';
	var cssOvelayActive = 'psPanel_overlay psPanel_overlayHidden';
	var cssOvelayRemoved = 'psPanel_overlay psPanel_overlayRemoved';

	// panel sections
	var container = document.createElement("div");
	container.className = cssBase;

	var headerClassName = params.headerClassName || "psPanel_header";
	var header = document.createElement("div");
	header.className = headerClassName;


	// close button
	if (typeof params.closeButton != 'undefined' && params.closeButton == true) {
		var closeX = document.createElement("div");
		closeX.className = "psPanel_close";
		closeX.innerHTML = '&times;'; //'x';
		closeX.onclick = close;
		header.appendChild(closeX);
	}

	var title = document.createElement("span");
	title.innerHTML = params.header;
	title.className = 'psPanel_header-title';
	header.appendChild(title);

	var content = document.createElement("div");
	content.className = "psPanel_content";

	var footer = document.createElement("div");
	footer.className = "psPanel_footer";
	if (typeof params.displayFooter != 'undefined' && params.displayFooter) {
		footer.style.display = 'block';
	} else {
		footer.style.display = 'none';
	}

	// notification sections
	var overlay = document.createElement("div");
	overlay.className = "psPanel_overlay";

	var notice = document.createElement("div");
	notice.className = "psPanel_notice";

	// build panel
	container.appendChild(header);
	container.appendChild(content);
	container.appendChild(footer);
	container.appendChild(overlay);
	container.appendChild(notice);

	// Element
	this.elem = container;
	if (typeof params.parentElem != 'undefined') params.parentElem.appendChild(container);
	// set differnt statuses on window
	/*
	* -----------------------------------------------------------
	* Set panel state
	* -----------------------------------------------------------
	*/
	var setState = function (state, messageContent) { // loading,active,alert,deactive

		var addMessage = function (content) {
			notice.innerHTML = '';
			notice.style.display = 'block';
			notice.appendChild(content);
		};

		switch (state) {
			case 'loading':
				container.className = cssInactive;
				overlay.className = cssOverLayLoader;
				if (typeof messageContent != 'undefined') addMessage(messageContent);
				break;
			case 'inactive':
				overlay.className = cssOvelayInactive;
				if (typeof messageContent != 'undefined') addMessage(messageContent);
				break;
			case 'active':
				overlay.className = cssOvelayActive;
				notice.style.display = 'none';
				//overlay.style.visibility = 'hidden';
				var timeout = setTimeout(function () {
					overlay.className = cssOvelayRemoved;
				});
				container.className = cssActive;
				break;
		}
	};
	this.setState = setState;
	this.setTitle = function (newTitle) {
		if (typeof newTitle == "string") {
			title.innerHTML = newTitle;
		} else {
			title.appendChild(newTitle);
		}
	};
	this.setContent = function (newContent) {
		container.className = cssInactive;
		var timeout = setTimeout(function () {
			if (typeof newContent == "string") {
				content.innerHTML = newContent;
			} else {
				content.innerHTML = '';
				content.appendChild(newContent);
			}
			container.className = cssActive;
			overlay.className = cssOvelayRemoved;
		}, 100);
	};

	// add content from params
	if (typeof params.content != 'undefined') {
		this.setContent(params.content);
	} else {
		this.setState('loading');
	}
	/*
	* -----------------------------------------------------------
	* Set panel selected
	* -----------------------------------------------------------
	*/
	var panelSelectedClass = params.panelSelectedClass || 'panelSelected';
	var deSelect = function () {
		header.className = header.className.replace(" " + panelSelectedClass, '');
	};
	this.deSelect = deSelect;
	this.setSelected = function () {
		header.className = header.className + ' ' + panelSelectedClass;
	};
	this.setSelected();

	//Children
	//adds possibilities for panel owning other panels.
	//If closed children will also be closed
	var children = {};
	var addChild = function (child) {
		if (typeof params.staySelected == 'undefined') deSelect();
		children[child.id] = new psPlugin.core.ui.panel(child);
	};
	var removeChildren = function () {
		for (var c in children) {
			children[c].close();
			delete children[c];
		}
	};
	this.addChild = addChild;
	this.removeChildren = removeChildren;
	this.children = children;

	// add children from params
	if (typeof params.children == 'object') {
		for (var c in params.children) {
			addChild(params.children[c]);
		}
	}
	/*
	* -----------------------------------------------------------
	* Close panel
	* -----------------------------------------------------------
	*/
	var close = function () {
		container.className = cssInactive;
		removeChildren();
		var parEl = container.parentElement;
		var timeout = setTimeout(function () {
			try {
				if (parEl && container) {
					parEl.removeChild(container);
					if (parEl.parentNode) {
						parEl.parentNode.removeChild(parEl);
					}
					parEl = null;
				}
			} catch (err) {
				console.error('cannot close child', err);
			}
		}, 200);

		ServerActions.caseClosed(params.id);

		params.onClose();
		//delete this;
		delete psPlugin.application.visitorHandler.visitors[params.id];
	};

	this.close = close;
};

export default panel;


/*
 * NAME: psPlugin.uiElements.Panel2
 * EX:
var panel = psPlugin.uiElements.Panel2({
	id:'SOME_ID',
	parentElem:document.getElementById('SOME_ID'),
	header:'Panel header',
	content:'Hello world',
	closeButton:true,
	children:[{
		id:'some_id',
		parentElem:document.getElementById('SOME_ID'),
		header:'Panel header',
		content:'Hello world',
		closeButton:true
	}]
});
panel.setState(loading); // 'loading' || 'inactive' || 'active';
panel.setContent(content) // string or node
panel.addChild({
		id:'some_id2',
		header:'Panel header',
		content:'Hello world',
		closeButton:true
	});
panel.removeChildren(); // remove all children
panel.close();
panel.children['some_id'].setContent(content) // string or node
panel.children...... // has same functions as it's parents
 *
 */
