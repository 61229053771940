import React, {useState, useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import CoBrowser from '../../desktopDeux/components/dialogPanel/co-browser/CoBrowser.react';
import PluginContainer from '../../desktopDeux/components/plugin/PluginContainer.react';
import {useEventListener, useDebounce} from '../../desktopDeux/ReactHooks';
import WidgetPanel from './WidgetPanel';
import Overlay from '../../desktopDeux/components/overlay/OverlayView.react';
import StartCode from './StartCode.react';

const panelWidth = 273;
const CoBroDesktopView = (props) => {
	const [windowDim, setWindowDim] = useState({windowHeight: window.innerHeight, windowWidth: window.innerWidth});
	const debouncedWindowDim = useDebounce(windowDim, 500);

	// handler for window resize
	const dimHandler = useCallback(({target: {innerWidth, innerHeight}}) => {
		setWindowDim({windowHeight: innerHeight, windowWidth: innerWidth});
	}, [setWindowDim]);
	useEventListener('resize', dimHandler);

	const panelStyle = {
		width: '100%'
	};

	const enabledFeatures = {
		exit: false
	};

	const coBroElement = props.coBroConversationId
		? <div className="dialog-panel maximized active" style={panelStyle}>
			<div className="co-container">
				<CoBrowser panelContainerWidth={debouncedWindowDim.windowWidth + panelWidth}
						   conversationId={props.coBroConversationId}
						   enabledFeatures={enabledFeatures}/>
			</div>
		</div>
		: null;

	const widgetPayload = {
		conversationId: props.coBroConversationId,
		windowDim,
		disabled: false
	};

	const coBroWidget = props.coBroConversationId
		? <WidgetPanel {...widgetPayload}/>
		: null;


	return (
		<div className="desktop-wrapper">
			<Overlay/>
			<StartCode/>
			{coBroElement}
			{coBroWidget}
			{props.pluginsEnabled ? <PluginContainer plugins={props.plugins} {...windowDim}/>: null}
		</div>
	);

};

CoBroDesktopView.displayName = 'CoBroDesktopView';

function mapStateToProps(state, ownProps) {
	const pluginsEnabled = state.get('pluginsEnabled');
	const plugins = state.get('plugins');
	return {
		coBroConversationId: state.getIn(['coBro', 'conversationId']) || '',
		pluginsEnabled,
		plugins,
		conversationId: ownProps.conversationId,
	};
}

function mapDispatchToProps(dispatch) {
	return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CoBroDesktopView);

