export default {
	'en-GB': {
        urlCategory: {
            ADD_HEADER: 'New page category',
            EDIT_HEADER: 'Edit page category',
            LIST_HEADER: 'Page categories',
			LIST_TITLE: 'List of page categories for working site',
			LIST_DESCRIPTION: 'Page categories are used to indicate the path that the visitor has navigated on the web site. The agents will be able to see which pages that the visitor has visited',
            ICON: 'Icon',
            COLOR: 'Color',
            NAME: 'Name',
            DESCRIPTION: 'Description',
            URL_FRAGMENT: 'URL Fragment'
        }
    },
    'sv-SE': {
        urlCategory: {
            ADD_HEADER: 'New page category',
            EDIT_HEADER: 'Edit page category',
            LIST_HEADER: 'Page categories',
            LIST_TITLE: 'List of page categories for working site',
            LIST_DESCRIPTION: 'Page categories are used to indicate the path that the visitor has navigated on the web site. The agents will be able to see which pages that the visitor has visited',
            ICON: 'Icon',
            COLOR: 'Color',
            NAME: 'Name',
            DESCRIPTION: 'Description',
            URL_FRAGMENT: 'URL Fragment'
        }
    }
};
