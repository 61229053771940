import React from 'react';
import {connect} from 'react-redux';
import VideoState from '../../constants/VideoState';
import VideoLib from '../../reducers/libs/videoLib';
import {useGetTranslation} from '../LangContext';

const LoadingView = (props) => {
	const getTranslation = useGetTranslation();
	const {videoState} = props;
	let loadingText = '';

	switch (videoState) {

		case VideoState.RELOADED:
			loadingText = getTranslation('videoConnecting');
			return <div className="video-loading">
				{loadingText}
				<span className="loading-spinner"/>
			</div>;

		case VideoState.INIT_MEDIA:
			loadingText = getTranslation('videoInitMedia');
			return <div className="video-loading">
				{loadingText}
				<span className="loading-spinner"/>
			</div>;

		case VideoState.JOIN_ROOM:
			loadingText = getTranslation('videoConnecting');
			return <div className="video-loading">
				{loadingText}
				<span className="loading-spinner"/>
			</div>;

		case VideoState.CONNECTING:
			loadingText = getTranslation('videoConnecting');
			return <div className="video-loading">
				{loadingText}
				<span className="loading-spinner"/>
			</div>;

		case VideoState.CALLING_PEER:
			loadingText = getTranslation('videoConnectingToPeer');
			return <div className="video-loading">
				{loadingText}
				<span className="loading-spinner"/>
			</div>;

		case VideoState.STREAMING:
			loadingText = getTranslation('videoConnectedToPeer');
			return <div className="video-loading">
				{loadingText}
				<i className=" vngage-icon-ok icon"/>
			</div>;

		case VideoState.LEAVING:
			loadingText = getTranslation('videoClosing');
			return <div className="video-loading">
				{loadingText}
				<span className="loading-spinner"/>
			</div>;

		default:
			return <div className="video-loading">
				<span className="loading-spinner"/>
			</div>;
	}
};

function mapStateToProps(state) {

	return {
		videoState: VideoLib.getState(state)
	};
}

export default connect(
	mapStateToProps
)(LoadingView);
