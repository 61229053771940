export default function (AuthKeys, $scope, AuthenticationService, IdentityService, Session, $state, $timeout, $rootScope, vngageConfig, vngagePatterns, APIEndpointService, AuthorizationService, WorkspaceSettingsService) {
	'ngInject';

	var appVm = this;
	appVm.patterns = vngagePatterns;
	$scope.$state = $state;

	// Display waring icon in nav if there are unpublished configurationSets
	/*if (AuthorizationService.authorize('Publish')) {
		APIEndpointService.accountCurrentConfiguration.get().$promise.then(res => {

			appVm.liveRevision = res.data.liveConfigurationSetIdRevision;

			let params = {
				id: res.data.liveConfigurationSetId
			};

			APIEndpointService.configurationSet.get(params).$promise.then(res => {
				appVm.revision = res.revision;
				$scope.unpublished = (appVm.revision > appVm.liveRevision) ? true: false;
			}, res => {
				console.error(res);
			});

		}, res => {
			console.error(res);
		});
	}*/

	$scope.logout = () => {
		WorkspaceSettingsService.getSections('general').then(generalWorkspaceSettings => {
			if (generalWorkspaceSettings) {
				AuthenticationService.logout(generalWorkspaceSettings.logoutRouting, generalWorkspaceSettings.logoutLandingPageUrl);
			} else {
				AuthenticationService.logout();
			}
		}, () => {
			AuthenticationService.logout();
		});
	};

	$scope.logoutDelegation = function () {
		AuthenticationService.logoutDelegation();
	};


	$scope.navOpen = localStorage.getItem('vngage.navMenuExpanded') !== 'false';

	function setNavMenuState(expanded) {
		localStorage.setItem('vngage.navMenuExpanded',expanded);
		$scope.navOpen = expanded;
	}
	$scope.clickNavMenu = function (e) {
		if (e.target.tagName === 'UL' || e.target.name === 'logo') {
			setNavMenuState(!$scope.navOpen);
		}
	};
	$rootScope.$on('setNavMenuState', function(event, data) {
		setNavMenuState(data);
	});

	$scope.removeMessage = function(e) {
		// LEGACY DESKTOP USAGE \\
		if (e.target.tagName === 'DIV') {
			e.target.className = '';
		} else {
			e.target.parentElement.className = '';
		}
		// ==================== \\
		IdentityService.setForcedAwayVisibility('hidden');
	};

	// Exposing $rootScope to template to show or hide sidebar depending on view
	// TODO remove this and put proper classes instead of #sidebar
	$scope.$rootScope = $rootScope;

	// Expose the routes for desktop/statistics/configurator
	$scope.vngageConfig = vngageConfig;

	// TODO perhaps this should be the first view, then routing will handle waiting for authorization data
	$scope.userDisplayName = Session.user.profile.displayName;
	$scope.accountDisplayName = Session.account.displayName;


	$scope.profilePic = Session.user.profile.profilePicture || vngageConfig.staticWorkspaceUrl + 'img/userIcon.png';

	appVm.IdentityService = IdentityService;
	appVm.setPresence = IdentityService.setPresence;

	// Handle presence- and awayReasons-updates triggered by other tabs/windows
	//TODO: broadcast storage events in the future?
	window.addEventListener('storage', function (e) {
		if (e.newValue !== e.oldValue) {
			switch (e.key) {
				case AuthKeys.userPresence:
					appVm.IdentityService.presence = e.newValue;
					$scope.$apply();
					break;
				case AuthKeys.userAwayReasons:
					appVm.IdentityService.setAwayReasons(e.newValue);
					$scope.$apply();
					break;
				case AuthKeys.userAwayReason:
					appVm.IdentityService.setAwayReason(e.newValue);
					$scope.$apply();
					break;
				default:
			}
		}
	}, false);

	// Handle presence- and awayReasons-updates triggered by other parts of Workspace in the same window/tab (via a dispatched CustomEvent)
	window.addEventListener('vergicWorkspaceEvent', function (e) {
		if (!e.detail || !e.detail.key) {
			return;
		}
		switch (e.detail.key) {
			case AuthKeys.userPresence:
				appVm.IdentityService.presence = e.detail.value;
				break;
			case AuthKeys.userAwayReasons:
				appVm.IdentityService.setAwayReasons(e.detail.value);
				break;
			case AuthKeys.userAwayReason:
				// This value is only updated from IdentityService, so no need to handle it here
				// (in fact, no CustomEvent will be dispatched for it right now)
				break;
			default:
		}
	}, false);

	$scope.isDelegated = IdentityService.currentlyDelegated();

	$scope.pageLoadingSpinnerOpts = {
		className: 'us-spinner',
		lines: 11, length: 4, width: 2, radius: 4,
		top: '28px', left: '50%',
		color: '#fff'
	};
};
