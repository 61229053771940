import analyzeTranslations from './AnalyzeTranslations';
import './opportunity/OpportunityModule';
import './pointOfInterest/PointOfInterestModule';
import AnalyzeContentTemplate from './AnalyzeContentTemplate';
import AnalyzeMenuTemplate from './AnalyzeMenuTemplate';
import '../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.analyze', [
    'ui.router',
    'vngageApp.analyze.opportunity',
    'vngageApp.analyze.pointOfInterest',
	'vngageApp.workspaceTranslation'
])

.constant('analyzeTranslations', analyzeTranslations)

.constant('analyzeModuleConfig', {
    state: 'root.analyze',
    access: 'Account'
})

.config(function($stateProvider, $urlRouterProvider, $translateProvider, analyzeTranslations, analyzeModuleConfig, VngageWorkspaceTranslationServiceProvider) {
	'ngInject';
	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(analyzeTranslations);

    $urlRouterProvider.when('/analyze/', function (transitionService,
        opportunityModuleConfig,
        pointOfInterestModuleConfig) {
		'ngInject';

        transitionService.navigate([
            opportunityModuleConfig,
            pointOfInterestModuleConfig
        ]);
    });

    $stateProvider.state(analyzeModuleConfig.state, {
        url: '/analyze/',
        views: {
            content: {
                templateUrl: AnalyzeContentTemplate
            },
            menu: {
                templateUrl: AnalyzeMenuTemplate
            }
        },
        data: {
            access: analyzeModuleConfig.access,
            ncyBreadcrumbLabel: 'Analyze'
        }
    });
});
