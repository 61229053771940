export default function ($state, account, $scope, $stateParams) {
	'ngInject';

	var vm = this;
	vm.account = account;
	vm.currentList = $stateParams.currentList || [];

	if ($state.current.name === 'root.system.account.edit') {
		$state.transitionTo('root.system.account.edit.details', {
			id: account.data.id,
			account: account,
			currentList: vm.currentList
		});
	}

	$scope.$on('resetResettables', () => {
		vm.wrapperForm.$setPristine();
	});
}
