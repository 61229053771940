import vngageAddModalTemplate from '../../../common/vngageAddModalTemplate';
import vngageImportModalTemplate from '../../../common/vngageImportModal/vngageImportModalTemplate';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import {fileImportHeaders} from './FileImportHeaders';

export default function ($state, $uibModal, dataLists) {
	'ngInject';

	const vm = this;
	const customerCase = dataLists.customerCase;
	vm.closureList = dataLists.closure;

	vm.tableColumns = [{
		header: 'Name',
		property: 'data.name',
		sortDefault: true,
		useInFilter: true,
		width: '33%'
	}, {
		header: 'GUID',
		property: 'data.id',
		hidden: true,
		useInFilter: true
	}, {
		header: 'CaseType',
		property: 'data.id',
		transform: getCaseTypeName,
		type: 'label-list',
		useInFilter: true
	}];
	vm.tableOptions = {
		stateLink: {
			state: 'root.configure.closure.edit',
			property: 'data.id'
		},
		createItem: {
			langKey: 'closure.ADD_HEADER',
			method: addNew
		},
		importCSV: {
			langKey: 'uiElement.IMPORT_CSV',
			method: importNew,
			id: 'import-btn'
		}
	};

	function openAddModal() {
		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: vngageAddModalTemplate,
			controller: 'ClosureAddModalController as vm',
			resolve: {
				options: () => {
					return {
						header: 'closure.ADD_HEADER',
						label: 'closure.NAME'
					};
				}
			}
		});
		modal.result.then(newClosure => {
			vm.closureList.push(newClosure);
			$state.go('.edit', {id: newClosure.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function openImportModal() {
		const modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: vngageImportModalTemplate,
			controller: 'ImportModalController as vm',
			size: 'lg',
			resolve: {
				options: () => {
					return {header: 'closure.IMPORT_HEADER'};
				},
				itemsList: () => {
					return vm.closureList;
				},
				itemType: () => 'closure',
				isSection: () => true,
				headers: () => fileImportHeaders,
				extraData: () => {
					return null;
				}

			}
		});
		modal.result.then(newClosure => {
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		$state.go('root.configure.closure').then(openAddModal);
	}

	function importNew() {
		$state.go('root.configure.closure').then(openImportModal);
	}

	function getCaseTypeName(guid) {
		const resource = _map(_filter(customerCase, resource => {
			return _find(resource.data.section.closures, closures => {
				return closures === guid;
			});
		}), res => {
			return res.data.name;
		});
		const list = resource || [];
		const trimmedList = list.length > 10 ? list.slice(0, 9): list.slice(0, 10);

		if (trimmedList.length < list.length) {
			trimmedList.push('+' + (list.length - trimmedList.length) + ' more');
		}
		return trimmedList;
	}
};
