import React, {useState, useEffect, useRef, useMemo} from 'react';
import {connect} from 'react-redux';
import NavigationActions from '../../actions/NavigationActions';
import ConversationActions from '../../actions/ConversationActions';
import ConversationActionCreators from '../../actions/ConversationActionCreators';
import FakeActions from '../../actions/FakeActions';
import i18nLoader from '../../../../i18n';
import Lang from '../../utils/Lang';
import {Button, ButtonGroup, Badge} from 'react-bootstrap';
import DebugButtons from './DebugButtons.react';
import DebugAnalysisTab from './DebugAnalysisTab.react';
import DebugPluginsTab from './DebugPluginsTab.react';
import DebugKeyHandler from './DebugKeyHandler.react';
import DebugDevTab from './DebugDevTab.react';
import DebugVideoTab from './DebugVideoTab.react';
import WebAPIUtils from '../../utils/WebAPIUtils.js';
import StateHistory from '../../utils/StateHistory';
import AccountConfigurationWrapper from '../../utils/AccountConfigurationWrapper';
import FakeNameHelper from '../../utils/FakeNameHelper';
import ModalType from '../../constants/ModalType';
import Draggable from 'react-draggable';
import LocalStorage from '../../stores/LocalStorageWrapper';
import JSZip from 'jszip';
import DebugInfo from './../../utils/DebugInfo';
import DateWrapper from './../../utils/DateWrapper';
import JSChannelWrapper from '../../utils/plugin/JSChannelWrapper';
import INITIAL_STATE from './../../INITIAL_STATE';
import ServerRequests from '../../actionCreators/ServerRequests';
import {getSortedState} from '../../utils/DebugTools';
import DebugAnalysis from '../../utils/DebugAnalysis';
import {getUrl, kibanaInfo} from './KibanaData';

const {
		  getShouldPoll,
		  setShouldPoll
	  } = WebAPIUtils;

const step = 1;
const bigStep = 10;
const evenBiggerStep = 100;
const hugeStep = 1000;
const statesInterval = 100;

const httpMock = (pollOn) => {
	if (pollOn) {
		ServerRequests.resetHttpMock();
	} else {
		ServerRequests.setHttpMock({
			post: (url, payload, config) => {
				console.groupCollapsed('mocked post ' + url.split('/').pop());
				console.log('url', url);
				console.log('payload', payload);
				console.log('config', config);
				console.groupEnd();
				return Promise.resolve({});
			},
			get: (url, config) => {
				console.groupCollapsed('mocked get ' + url.split('/').pop());
				console.log('url', url);
				console.log('config', config);
				console.groupEnd();
				Promise.resolve({});
			}
		});
	}
};

const localValue = LocalStorage.getItem('shouldPoll');
if (localValue) {
	const trueSet = localValue === 'true';
	setShouldPoll.call(WebAPIUtils, trueSet);
	httpMock(trueSet);
	JSChannelWrapper.setDebug(!trueSet);
}

const getPanelPos = () => {
	let debugPanelPosition = LocalStorage.getItem('debugPanelPosition');
	let posXValue = 10;
	let posYValue = 10;
	if (debugPanelPosition) {
		try {
			debugPanelPosition = JSON.parse(debugPanelPosition);
			posXValue = debugPanelPosition.x;
			posYValue = debugPanelPosition.y;
		} catch (e) {
			console.log('error in getPanelPos', e);
		}
	}
	return [posXValue, posYValue];
};

const getPanelCollapsed = () => {
	const debugPanelCollapsed = LocalStorage.getItem('debugPanelCollapsed');
	let isCollapsed = false;
	if (debugPanelCollapsed) {
		try {
			isCollapsed = JSON.parse(debugPanelCollapsed);
		} catch (e) {
			console.log('error in getPanelCollapsed', e);
		}
	}
	return isCollapsed;
};

const getPanelBadge = () => {
	const debugPanelBadge = LocalStorage.getItem('debugPanelBadge');
	let isBadged = false;
	if (debugPanelBadge) {
		try {
			isBadged = JSON.parse(debugPanelBadge);
		} catch (e) {
			console.log('error in getPanelBadge', e);
		}
	}
	return isBadged;
};

const getAllDebugData = () => {
	return {
		debugInfo: DebugInfo.getAllLocal(),
		accountConfig: AccountConfigurationWrapper.configuration,
		stateData: StateHistory.getEveryThing()
	};
};

const getLocalStorageObject = (key) => {
	let obj;
	try {
		obj = JSON.parse(LocalStorage.getItem(key) || '{}');
	} catch (e) {
		obj = {};
	}
	return obj;
};

const setSavedPosition = (wID, timePos) => {
	if (wID) {
		const debugPositions = getLocalStorageObject('debugPositions');
		LocalStorage.setItem('debugPositions', JSON.stringify({...debugPositions, [wID]: timePos}));
	}
};

const getSavedPosition = (wID) => {
	const debugPositions = getLocalStorageObject('debugPositions');
	return debugPositions?.[wID] ?? 0;
};

const DebugTest = (props) => {
	const [pois, setPois] = useState([]);
	const [triggerLog, updateTriggerLog] = useState(0);
	const [poll, setPoll] = useState(getShouldPoll());
	const [debugInfo, setDebugInfo] = useState({});
	const [tabIndex, setTabIndex] = useState(0);
	const [collapsed, setCollapsed] = useState(getPanelCollapsed());
	const [badge, setBadge] = useState(getPanelBadge());
	const [posX, setPosX] = useState(getPanelPos()[0]);
	const [posY, setPosY] = useState(getPanelPos()[1]);
	const [timestampMockEnabled, setTimestampMockEnabled] = useState(DateWrapper.isMockEnabled());
	const [useDebugKeys, setUseDebugKeys] = useState(!getShouldPoll());
	const historySize = useRef(StateHistory.getLength());
	const statesObj = useRef(StateHistory.getStatesObj(statesInterval));
	const [isPlaying, setIsPlaying] = useState(false);
	const [playingIntervalId, setPlayingIntervalId] = useState(0);
	const [historyState, setHistoryState] = useState('state');
	const [searchText, setSearchText] = useState('');
	const [actionText, setActionText] = useState('');
	const [timePos, setTimePos] = useState(0);

	const dragStop = (e, data) => {
		LocalStorage.setItem('debugPanelPosition', JSON.stringify({
			x: data.x,
			y: data.y
		}));
	};

	const toggleCollapse = () => {
		setCollapsed(!collapsed);
		LocalStorage.setItem('debugPanelCollapsed', JSON.stringify(!collapsed));
	};

	const toggleBadge = () => {
		setBadge(!badge);
		LocalStorage.setItem('debugPanelBadge', JSON.stringify(!badge));
	};

	const getActiveClass = (index) => {
		return tabIndex === index ? 'nav-item active': 'nav-item';
	};

	const handleTabIndex = (index) => {
		setTabIndex(index);
	};

	const saveEverythingToLocal = () => {
		console.log('saveEverythingToLocal');
		try {
			LocalStorage.setItem('everything', JSON.stringify(getAllDebugData()));
		} catch (e) {
			console.error('could not save debugData to local storage');
		}
	};

	const handleImportFile = (event) => {
		if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
			console.error('No browser support for file reader');
			return;
		}

		let files = event.target.files;
		if (!files || !files[0]) {
			return false;
		}

		let reader = new FileReader();

		if (files[0].name.toLowerCase().endsWith('.json')) {
			reader.onload = function (e) {
				let debugData = JSON.parse(e.target.result);
				setAllDebugData(debugData);
			};
			reader.readAsText(files[0]);
		} else if (files[0].name.toLowerCase().endsWith('.zip')) {
			reader.onload = function (e) {
				var zip = new JSZip();
				zip.loadAsync(e.target.result)
					.then(function (zip) {
						let fileNames = Object.keys(zip.files);
						if (!fileNames || fileNames.length < 0) {
							console.error('No files in zip-file');
							return;
						}
						zip.file(fileNames[0]).async('string').then(content => { // Only read the first file in zip...
							let debugData = JSON.parse(content);
							setAllDebugData(debugData);
						}, err => {
							console.error('General zip-error:', err);
						});
					});
			};
			reader.readAsArrayBuffer(files[0]);
		}
		return true;
	};


	// fakes
	const addFakePanel = () => {
		props.addFakePanel(FakeNameHelper.getNew(), {});
	};

	const removeAllFakePanels = () => {
		const ids = props.entireState.get('panelOrder');
		ids.forEach(conversationId => {
			props.setPendingRemoval(conversationId);
		});
		setTimeout(() => props.removeAllFakePanels(), 500);
	};

	const getFocusedConversationId = () => {
		return props.entireState.get('activePanel');
	};

	const handleDeveloperActions = (eventKey) => {
		switch (eventKey) {
			case 'addPanel':
				addFakePanel();
				break;
			case 'addConversation':
				props.addFakeConversation();
				break;
			case 'addVisitor':
				props.addFakeVisitor();
				break;
			case 'message':
				props.addFakeMessage();
				break;
			case 'systemMessage':
				props.addFakeSystemMessage();
				break;
			case 'typing':
				props.toggleFakeTyping();
				break;
			case 'removePanels':
				removeAllFakePanels();
				break;
			case 'removeFocus':
				alert('not implemented');
				break;
			case 'id':
				props.addFakeVisitorProfile(getFocusedConversationId(), eventKey);
				break;
			case 'profile_id_url':
				props.addFakeVisitorProfile(getFocusedConversationId(), eventKey);
				break;
			case 'profile_id':
				props.addFakeVisitorProfile(getFocusedConversationId(), eventKey);
				break;
			case 'profile':
				props.addFakeVisitorProfile(getFocusedConversationId(), eventKey);
				break;
			case 'logPoll':
				WebAPIUtils.poll.call(WebAPIUtils, true);
				break;
			case 'crash':
				// do something which creates an error
				setActionText('premeditated crash');
				break;
			case 'reservation':
				props.addFakeReservation();
				break;
			case 'videoModal':
				props.showModal(ModalType.VIDEO_MODAL);
				break;
			case 'videoFullscreen':
				props.showModal(ModalType.VIDEO_FULLSCREEN);
				break;
			case 'hideModal':
				props.hideModal();
				break;
		}
	};

	const handleLangAction = (langKey) => {
		switch (langKey) {
			case 'orig':
				Lang.mock = null;
				props.setDebugFlag('mockedLocale', null);
				break;
			case 'en':
			case 'sv':
			case 'de':
			case 'fr':
				i18nLoader(langKey, i18n => {
					Lang.mock = i18n;
					props.setDebugFlag('mockedLocale', langKey);
				});
				break;
			case '*':
				i18nLoader('en', i18n => {
					Lang.mock = {
						messages: Object.keys(i18n.messages).reduce((obj, key) => ({
							...obj,
							[key]: '***'
						}), {})
					};
					props.setDebugFlag('mockedLocale', '*');
				});
				break;
			default:
			// Unknown langKey, do nothing...
		}
	};

	const refresh = () => {
		props.newStateDebug(INITIAL_STATE);
		setTimeout(() => moveToPosition(0), 20);
	};

	const logCls = () => {
		console.clear();
	};

	const logPoll = () => {
		const pollData = WebAPIUtils.poll.call(WebAPIUtils, true) || {
			getRequests: [],
			postRequests: []
		};

		console.log('poll info: get requests');
		pollData.getRequests.forEach(item => {
			const uriPart = item.uri.replace('?', '/').split('/').slice(5, 8).join(' ');
			console.groupCollapsed(uriPart);
			console.log(item);
			console.groupEnd();
		});

		if (pollData.postRequests.length > 0) {
			console.log('poll info: post requests');
		}

		pollData.postRequests.forEach(({
										   reqUrl,
										   payload
									   }) => {
			const uriPart = reqUrl.replace('?', '/').split('/').slice(5, 8).join(' ');
			console.groupCollapsed(uriPart);
			console.log(reqUrl, 'payload', payload);
			console.groupEnd();
		});
	};

	const logPluginSubscriptions = () => {
		const subs = JSChannelWrapper.latestSubscriptions;
		const valid = Object.keys(subs).filter(key => subs[key] !== null);
		if (valid.length > 0) {
			console.log('pluginSubscriptions:');
			Object.keys(subs).forEach(key => {
				console.groupCollapsed(key);
				subs[key].forEach(sub => {
					console.log(sub);
				});
				console.groupEnd();
			});
		}
	};

	const logState = () => {
		console.log('state', getSortedState(props.entireState));
		// console.log('state', JSON.parse(JSON.stringify(props.entireState)));
	};

	const logAction = () => {
		console.log('action', StateHistory.getJSActionAt(timePos));
	};

	const logAll = () => {
		logAction();
		logState();
		logPluginSubscriptions();
		const actionName = StateHistory.getActionTypeAt(timePos);
		if (actionName === 'NET_POLL') {
			logPoll();
		} else if (actionName.includes('ERROR') || actionName.includes('CRASH')) {
			const jsAction = StateHistory.getJSActionAt(timePos);
			if (jsAction.error && jsAction.error.stack) {
				console.warn(jsAction.error.stack);
			}
		}
	};

	const saveActionText = (text) => {
		setActionText(text);
	};

	const saveSearchText = (text) => {
		setSearchText(text);
	};

	const reloadHistorySize = () => {
		historySize.current = StateHistory.getLength();
	};

	const getNewPosition = (oldPos, steps) => {
		let pos = oldPos;
		pos += steps;
		if (pos < 0) {
			pos = 0;
		} else if (pos >= historySize.current) {
			pos = historySize.current - 1;
		}
		return pos;
	};

	const getStateAt = (pos) => {
		return StateHistory.getStateUsingStatesObj(pos, statesObj.current);
	};

	const moveToPosition = (steps) => {
		const newTimePos = getNewPosition(timePos, steps);
		setTimePos(newTimePos);
		// update mocked date
		const timestamp = new Date(StateHistory.getActionTimestampAt(newTimePos));
		DateWrapper.setMock(timestamp);

		const state = StateHistory.getStateUsingStatesObj(newTimePos, statesObj.current);
		props.newStateDebug(state);
	};

	const moveToAbsolutePosition = (pos) => {
		const steps = pos - timePos;
		moveToPosition(steps);
		console.log('pos: ' + pos);
		updateTriggerLog(n => n + 1);
	};

	// key functions
	const toggleDebugKeys = () => {
		setUseDebugKeys(!useDebugKeys);
	};

	// after save, load and trim
	const resetAfterNewData = (savedPosition = 0) => {
		if (isPlaying) {
			clearInterval(playingIntervalId);
		}
		setPois([]);
		setTimePos(0);
		historySize.current = StateHistory.getLength();
		setIsPlaying(false);
		setPlayingIntervalId(0);
		moveToAbsolutePosition(savedPosition);
	};

	const setAllDebugData = (debugData) => {
		StateHistory.setEveryThing(debugData.stateData);
		statesObj.current = StateHistory.getStatesObj(statesInterval);
		AccountConfigurationWrapper.setMock(debugData.accountConfig);
		AccountConfigurationWrapper.enableMock();
		setDebugInfo(debugData.debugInfo);
		DebugInfo.setAllLocal(debugData.debugInfo);
		props.newStateDebug(debugData.stateData.origState);
		const savedPosition = getSavedPosition(debugData?.debugInfo?.wID ?? '');
		resetAfterNewData(savedPosition);
	};

	const getEverythingFromLocal = () => {
		const prevWindowId = debugInfo.wID || '';
		setSavedPosition(prevWindowId, timePos);

		let debugData;
		if (window.savedData) {
			console.log('getEverythingFromTamperMonkeyGM');
			debugData = JSON.parse(window.savedData);
		} else {
			console.log('getEverythingFromLocal');
			debugData = JSON.parse(LocalStorage.getItem('everything'));
		}
		setAllDebugData(debugData);
	};

	// poll
	const onTogglePoll = () => {
		setShouldPoll.call(WebAPIUtils, !getShouldPoll());
		setPoll(getShouldPoll());
		LocalStorage.setItem('shouldPoll', getShouldPoll());
		const pollOn = getShouldPoll();
		httpMock(pollOn);
		if (pollOn) {
			// poll is restarted
			StateHistory.reset();
			DateWrapper.setMockEnabled(false);
			resetAfterNewData();
		} else {
			// poll is turned off
			setDebugInfo(DebugInfo.getAll());
			DebugInfo.setAllLocal(DebugInfo.getAll());
			setUseDebugKeys(true);
			resetAfterNewData();
		}
	};

	const trimToPosition = (trimPos) => {
		StateHistory.trimTo(trimPos);
		resetAfterNewData();
	};

	const stopPlay = () => {
		clearInterval(playingIntervalId);
		setPlayingIntervalId(0);
		setIsPlaying(false);
	};

	const togglePlay = () => {
		isPlaying ? stopPlay(): startPlay();
	};

	const playStep = (pos) => {
		if (StateHistory.isLastAction(pos)) {
			stopPlay();
			return;
		}

		const currentActionTimestamp = StateHistory.getActionTimestampAt(pos);
		const nextActionTimestamp = StateHistory.getActionTimestampAt(pos + 1);
		const timeoutDuration = nextActionTimestamp - currentActionTimestamp;

		setPlayingIntervalId(setTimeout(() => {
			const timestamp = new Date(StateHistory.getActionTimestampAt(pos));
			DateWrapper.setMock(timestamp);
			if (historyState === 'state') {
				const state = StateHistory.getStateUsingStatesObj(pos, statesObj.current);
				props.newStateDebug(state);
			} else if (historyState === 'action') {
				const action = StateHistory.getJSActionAt(pos);
				props.dispatchAction(action);
			}
			pos++;
			setTimePos(pos);
			playStep(pos);
		}, timeoutDuration));
	};

	const startPlay = () => {
		setIsPlaying(true);
		playStep(timePos);
	};

	// mock timestamps
	const toggleTimestampsMock = () => {
		DateWrapper.setMockEnabled(!DateWrapper.isMockEnabled());
		setTimestampMockEnabled(DateWrapper.isMockEnabled());
	};
	// kibana
	const getKibanaUrl = (conversationId) => {
		// search history for a request and extract url from it.
		const requestUrl = DebugAnalysis.findRequestUrl(StateHistory.getEveryThing().history);
		const urlSplit = requestUrl.split('/');
		if (urlSplit.length < 3) {
			// no valid url found
			return 'error:no valid request url found';
		}
		const urlPart = urlSplit[2];

		const matches = kibanaInfo.filter(matchObj => matchObj.re.test(urlPart));
		if (!matches[0]) {
			return 'error:no valid kibana url match found for:' + urlPart;
		}
		const base = matches[0].base;
		const index = matches[0].index || '';
		const timeRange = 30 * 60 * 1000;

		const timestamp = Math.floor(StateHistory.getActionTimestampAt(timePos) / 1000) * 1000;
		const from = new Date(timestamp - timeRange).toISOString();
		const to = new Date(timestamp + timeRange).toISOString();

		return getUrl(base, index, from, to, conversationId);
	};


	const openKibana = (conversationId) => {
		const kibanaUrl = getKibanaUrl(conversationId);
		if (!kibanaUrl.includes('error')) {
			window.open(kibanaUrl, '_blank');
		} else {
			console.warn('no kibanaUrl found:', kibanaUrl);
		}
	};

	useEffect(() => {
		if (triggerLog > 0) {
			logAll();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [triggerLog]);

	// componentDidMount, componentWillUnmount
	useEffect(() => {
		const localValue = LocalStorage.getItem('shouldPoll');
		if (localValue) {
			const trueSet = localValue === 'true';
			setShouldPoll.call(WebAPIUtils, trueSet);
			JSChannelWrapper.setDebug(!trueSet);
			httpMock(trueSet);
		}
		return () => {
			if (isPlaying) {
				clearInterval(playingIntervalId);
			}
		};
	}, [isPlaying, playingIntervalId]);

	const currentVersion = debugInfo.build
		? debugInfo.build
		: 'local version';

	const divStyle = {
		position: 'absolute',
		height: 0,
		width: 0,
		zIndex: 9999
	};

	const buttonText = 'Polling ' + (poll ? 'on': 'off');

	const debugButtonsPayload = {
		toggleDebugKeys,
		togglePlay,
		entireState: props.entireState,
		historyState,
		debugInfo,
		pos: timePos,
		logPoll,
		refresh,
		moveToPosition,
		openKibana,
		setHistoryState,
		step,
		bigStep,
		evenBiggerStep,
		hugeStep,
		isPlaying,
		historySize: historySize.current,
		trimToPosition,
		useDebugKeys,
		timestampMockEnabled,
		toggleTimestampsMock,
	};

	const debugAnalysisTabPayload = {
		moveToAbsolutePosition: moveToAbsolutePosition,
		pois,
		setPois,
		getStateAt: getStateAt,
		searchText,
		actionText,
		saveSearchText: saveSearchText,
		saveActionText: saveActionText,
	};

	const logTypes = ['verbose', 'errors', 'plugins', 'requests', 'none'];

	const langButtons = ['orig', 'en', 'sv', 'de', 'fr', '*'].map(lang => {
		const mockedLocale = props.mockedLocale ? props.mockedLocale: 'orig';
		const active = lang === mockedLocale;
		const cls = active ? 'dark-lang-button active': 'dark-lang-button';
		return <Button variant="default" className={cls} key={lang} size="xs"
					   onClick={() => handleLangAction(lang)}>{lang}</Button>;
	});
	const contentPolling = collapsed ? <div/>: (
		<div>

			{logTypes.map(t => {
				return props.logType === t
					? <Button key={t} size="xs" variant="default" className="dark"><i className="vngage-icon-eye"/>{t}
					</Button>
					: <Button key={t} size="xs" variant="default" className="dark"
							  onClick={() => props.setLogTypeFlag(t)}>{t}</Button>;
			})}
			{
				!props.pauseDebugLogging
					? <Button variant="default" className="dark" block
							  onClick={() => props.setPauseDebugFlag(!props.pauseDebugLogging)}>
						<i className="vngage-icon-pause"/> Pause logging</Button>
					: <Button variant="default" className="dark" block
							  onClick={() => props.setPauseDebugFlag(!props.pauseDebugLogging)}>
						<i className="vngage-icon-line-play"/> Start logging</Button>
			}
			<span className="badge">Language:</span>
			<ButtonGroup>
				{langButtons}
			</ButtonGroup>
			<div/>

		</div>
	);
	const poiElements = pois.map(poi => <a key={poi} href="#"
										   onClick={() => moveToAbsolutePosition(poi)}>{poi} </a>);

	const debugContent = (
		<div>
			<Button id="save-to-local" variant="default" className="dark" onClick={() => saveEverythingToLocal()}>Save
				to local</Button>
			<Button variant="default" className="dark" onClick={() => getEverythingFromLocal()}>Get from local</Button>
			<input className="vergic-file-input" type="file" id="debug-file-import"
				   onChange={handleImportFile}/>

			<span className="badge">Language:</span>
			<ButtonGroup>
				{langButtons}
			</ButtonGroup>
			<div/>

			<DebugButtons {...debugButtonsPayload}/>
			<div style={{width: '100%'}}>
				{poiElements}
			</div>

		</div>
	);

	let tabContent;
	switch (tabIndex) {
		case 0:
			tabContent = debugContent;
			break;
		case 1:
			tabContent = <DebugDevTab handleDeveloperActions={handleDeveloperActions}/>;
			break;
		case 2:
			tabContent = <DebugAnalysisTab {...debugAnalysisTabPayload}/>;
			break;
		case 3:
			tabContent = <DebugPluginsTab/>;
			break;
		case 4:
			tabContent = <DebugVideoTab/>;
			break;
		default:
			break;
	}
	tabContent = collapsed ? <div/>: tabContent;

	const contentNotPolling = collapsed ? <div/>: (
		<div className="tab-container">
			<div className="tab-nav">
				<div className={getActiveClass(0)} onClick={() => handleTabIndex(0)}><i
					className="vngage-icon-line-restore-screen">
					<div className="badge">Debug</div>
				</i></div>
				<div className={getActiveClass(2)} onClick={() => handleTabIndex(2)}><i
					className="vngage-icon-beaker">
					<div className="badge">Analysis</div>
				</i></div>
				<div className={getActiveClass(3)} onClick={() => handleTabIndex(3)}><i
					className="vngage-icon-radar">
					<div className="badge">Plugins</div>
				</i></div>

				<div className={getActiveClass(1)} onClick={() => handleTabIndex(1)}><i
					className="vngage-icon-wrench">
					<div className="badge">Develop</div>
				</i></div>
				<div className={getActiveClass(4)} onClick={() => handleTabIndex(4)}><i
					className="vngage-icon-videocam-1">
					<div className="badge">Video</div>
				</i></div>
			</div>
			<div className="tab-content">
				{tabContent}
			</div>
		</div>
	);


	const keyPayload = {
		moveForward1: () => moveToPosition(step),
		moveForward10: () => moveToPosition(bigStep),
		moveForward100: () => moveToPosition(evenBiggerStep),
		moveForward1000: () => moveToPosition(hugeStep),
		moveBackward1: () => moveToPosition(-step),
		moveBackward10: () => moveToPosition(-bigStep),
		moveBackward100: () => moveToPosition(-evenBiggerStep),
		moveBackward1000: () => moveToPosition(-hugeStep),
		logAll,
		logCls,
		useDebugKeys,
	};

	const crash = actionText === 'premeditated crash'
		? <div>{{...props['superCrash']}}</div>
		: null;

	const badgeContent = (
		<Badge className="minimized-debug-container bg-primary" onClick={toggleBadge}>
			Debugger
		</Badge>
	);

	const notBadged = (
		<div style={divStyle} className="debug-tool">
			{!poll && <DebugKeyHandler {...keyPayload}/>}
			{crash}
			<Draggable
				handle=".debugger-tool-handle"
				defaultPosition={{
					x: getPanelPos()[0],
					y: getPanelPos()[1]
				}}
				onStop={dragStop}>
				<div className="debugger-tool">
					<div className="debugger-tool-handle">
						<div className="badge-btn" onClick={toggleBadge}>
							<span className="badge-icon"/>
						</div>
						<div className="collapse-bar" onClick={toggleCollapse}/>
						<div className="flip-container">
							<label className="flipswitch">
								<input
									type="checkbox"
									className="switch"
									defaultChecked={poll}
									onChange={() => onTogglePoll()}
								/>
								<span className="switch"/>
								<span className="badge">{buttonText}</span>
							</label>
						</div>
						<div className="debugger-header">
							<h5>Debugger</h5>
							<span className="badge">
								{poll
									? <i className="vngage-icon-target" onClick={() => reloadHistorySize()}/>
									: null
								}
								<strong>Version: </strong>{currentVersion} {timePos + '/' + (historySize.current - 1)}
							</span>
						</div>
					</div>
					<div className="debugger-tool-container">
						{poll
							? contentPolling
							: contentNotPolling
						}
					</div>
				</div>
			</Draggable>
		</div>
	);

	return (
		badge
			? badgeContent
			: notBadged
	);
};

function mapStateToProps(state) {
	return {
		entireState: state,
		mockedLocale: state.getIn(['debugFlags', 'mockedLocale']),
		pauseDebugLogging: state.getIn(['debugFlags', 'pauseDebugLogging']) || false,
		logType: state.getIn(['debugFlags', 'logType']) || 'none'
	};
}

function mapDispatchToProps(dispatch) {
	return {
		newStateDebug: (newState) => dispatch(FakeActions.newStateDebug(newState)),
		removeAllFakePanels: () => dispatch(FakeActions.removeAllFakePanels()),
		addFakePanel: (conversationId, conversationState) => dispatch(FakeActions.addFakePanel(conversationId, conversationState)),
		addFakeConversation: () => dispatch(FakeActions.addFakeConversation()),
		addFakeMessage: () => dispatch(FakeActions.addFakeMessage()),
		addFakeSystemMessage: () => dispatch(FakeActions.addFakeSystemMessage()),
		toggleFakeTyping: () => dispatch(FakeActions.toggleFakeTyping()),
		addFakeVisitorProfile: (conversationId, whatToShow) => dispatch(FakeActions.addFakeVisitorProfile(conversationId, whatToShow)),
		addFakeVisitor: () => dispatch(FakeActions.addFakeVisitor()),
		setPendingRemoval: (conversationId) => dispatch(ConversationActions.setPendingRemoval(conversationId)),
		setDebugFlag: (flagName, value) => dispatch(ConversationActions.setDebugFlag(flagName, value)),
		addFakeReservation: () => dispatch(FakeActions.addFakeReservation()),
		setPauseDebugFlag: (value) => dispatch(ConversationActionCreators.setPauseDebugFlag(value)),
		setLogTypeFlag: (value) => dispatch(ConversationActionCreators.setLogTypeFlag(value)),
		dispatchAction: (action) => dispatch(action),
		showModal: (type) => dispatch(NavigationActions.showModal(type)),
		hideModal: (type) => dispatch(NavigationActions.hideModal()),
	};
}

DebugTest.setDebugData = (data) => {
	window.savedData = data;
};

DebugTest.getDebugData = () => {
	return JSON.stringify(getAllDebugData());
};
export default connect(mapStateToProps, mapDispatchToProps)(DebugTest);


