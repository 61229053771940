import GroupReducer from './../reducers/GroupReducer';
import QueueReducer from './../reducers/QueueReducer';
import ConversationReducer from './../reducers/ConversationReducer';
import VisitorProfileReducer from './../reducers/VisitorProfileReducer';
import NavigationReducer from './../reducers/NavigationReducer';
import FakerReducer from './../reducers/FakeReducer';
import PluginReducer from './../reducers/PluginReducer';
import VideoReducer from './../reducers/VideoReducer';
import DesktopReducer from './../reducers/DesktopReducer';

export const reducers = [
	QueueReducer,
	ConversationReducer,
	VisitorProfileReducer,
	GroupReducer,
	NavigationReducer,
	FakerReducer,
	PluginReducer,
	VideoReducer,
	DesktopReducer
];

export function playback(state, action) {
	return (reducers.reduce((prevState, func) => func(prevState, action), state));
}
