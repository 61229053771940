import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _cloneDeep from 'lodash/cloneDeep';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _each from 'lodash/each';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _once from 'lodash/once';
import _keys from 'lodash/keys';
import _sortBy from 'lodash/sortBy';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';

import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom';
import assign from 'object-assign';
import {IntlProvider} from 'react-intl';
import i18nLoader from '../../i18n';
import ConversationParticipants from './components/ConversationParticipants.react';
import ParticipantsWritingContainer from './components/ParticipantsWritingContainer.react';
import MessageListContainer from './components/MessageListContainer.react';
import MessageCaseHistoryContainer from './components/MessageCaseHistoryContainer.react';
import VisualGuidanceContainer from './components/VisualGuidanceContainer.react';
import VisitorProfileContainer from './components/VisitorProfileContainer.react';

import VngageVisitor from './visitor/vngageVisitor';

import VGSettings from './utils/vgSettings';
import ProfileActions from './actions/ProfileActions';
import ServerActions from './actions/ServerActions';
import ViewActions from './actions/ViewActions';

import connectionLostTemplate from './connectionLostTemplate';
import BrowserValidation from './legacy/browserValidation';
import Avgrund from './legacy/Avgrund';
import DragDrop from './legacy/dragDrop';
import stateMachine from './legacy/stateMachine';
import CommandLoader from './legacy/commandLoader';
import ComServer3 from './legacy/comServer3';
import ExceptionHandler from './legacy/ExceptionHandler';
import legacyJsExt from './legacy/jsExt';
import EventLoader from './legacy/eventLoader';
import legacyChatModule from './legacy/chatModule';
import JSChannel from '../../../vendor/JSChannel';
import pdfCapture from './legacy/pdfCapture';
import actionEvents from './legacy/actionEvents';
//import actionPanels from './legacy/actionPanels';
import coWorker from './legacy/coWorker';
import LegacyListener from './legacy/LegacyListener';

import visitorStateDiagram from './legacy/visitorStateDiagram';
import visitorEvents from './legacy/visitorEvents';
import VisitorObjTemplate from './legacy/VisitorObjTemplate';
//import VisitorHandler from './legacy/VisitorHandler';

import LegacyApplication from './legacy/LegacyApplication';

//import psCaseModule from  './legacy/psCaseModule';
import videoChatModule from  './legacy/videoChat';

import uiView from './legacy/ui/view';
import uiMessage from './legacy/ui/message';
import uiNotification from './legacy/ui/notification';
import uiPageLayout from './legacy/ui/pageLayout';
import uiPanel from './legacy/ui/panel';
import ExpandableAreaView from './legacy/ExpandableAreaView';
import ExpandableTextArea from './legacy/ExpandableTextArea';
import psDOM from './legacy/ui/psDOM';
import psForm from './legacy/ui/psForm';

import WebNotificationService from './WebNotificationService';

import User from './User';
import ParticipantStore from './stores/ParticipantStore';
import VisitorProfileStore from './stores/VisitorProfileStore';
import WebAPIUtils from './utils/WebAPIUtils';
import DesktopLegacyTranslations from './DesktopLegacyTranslations';

// Manual DI annotations, simply felt safer
export default [
	'AuthorizationService', '$uibModal', '$q', '$scope', '$http', '$parse', '$translate', '$compile', '$timeout', '$interval',
	'IdentityService', 'SessionService', 'Session', 'vngageGuid', 'vngageConfig', 'vngageMarkdown', 'NotificationService',
	'usSpinnerService', 'DesktopServiceLegacy',
	'ActiveCase', 'CaseService', 'StateReloadService',
	function (
		AuthorizationService, $uibModal, $q, $scope, $http, $parse, $translate, $compile, $timeout, $interval,
		IdentityService, SessionService, Session, vngageGuid, vngageConfig, vngageMarkdown, NotificationService,
		usSpinnerService, DesktopService,
		ActiveCase, CaseService, StateReloadService
	) {
		var vm = this;

		// Hack: If revisiting Desktop, make sure to reload page to avoid timeout issues and mem leaks.
		if (window.psPlugin && window.psPlugin.application && window.psPlugin.application.visitorHandler){
			window.location.reload();
		}


		// shared mutable state... help!
		if (!Session.user.profile.language) {
			Session.user.profile.language = 'en-GB';
		}

		WebNotificationService.init({
			duration: DesktopService.configuration.account.notificationDuration,
			notificationOnChatMessages: DesktopService.configuration.account.notificationOnChatMessages,
			notificationOnProactive: DesktopService.configuration.account.notificationOnProactive
		});


		User.canInviteGuest = AuthorizationService.authorize('Desktop/InviteGuest');

		//var onViewportResize = false;
		User.props = {sessionProps:Session, sessionId:SessionService};
		ProfileActions.profileIdentified(Session.user);

		$scope.$on('$destroy', function () {
			WebNotificationService.dispose();
			psPlugin.legacyHasStopped = true;
			try {
				for (var v in psPlugin.application.visitorHandler.visitors) {
					delete psPlugin.application.visitorHandler.visitors[v];
				}
				psPlugin.application.visitorHandler.visitors = {};
			} catch (err) {}

			psPlugin.application.close();
		});

		window.psPlugin = {
			core:{
				ui: {
					message: uiMessage,
					notification: uiNotification,
					pageLayout: uiPageLayout,
					view: uiView,
					DOM: psDOM,
					panel: uiPanel
				},
				connection: {},
				dragDrop: DragDrop,
				jsExt: legacyJsExt
			}
		};

		var connectionModal;
		$scope.isConnectionRecoverable = true;
		var vgSettings = assign(DesktopService.configuration.account, DesktopService.configuration.visitor);
		vgSettings = _pick(vgSettings, 'domCoBrowsing', 'enableHTTPSCoBrowsing', 'enablePassiveCoBrowsing', 'useProtocolRelativeUrls', 'videoStreamingServer', 'PDFcapture', 'coBrowserNoFollow', 'deactivatePhoneToPs', 'displayCaseHistory', 'displayVisitorProfile', 'enableVideoChat', 'hideQueue');
		VGSettings.setSettings(vgSettings);

		WebAPIUtils.init($http, vngageConfig);

		// display connection-lost modal
		function displayConnectionLostModal() {
			if (connectionModal) {
				connectionModal.dismiss();
			}
			$timeout(function () {
				connectionModal = $uibModal.open({
					windowClass: 'modal-connection',
					templateUrl: connectionLostTemplate,
					backdrop: 'static',
					backdropClass: 'modal-backdrop'
				});

				var statusChecker=$interval(() => {
					if (DesktopService.connection.status === 2) {
						// If connection comes back, dismiss modal and continue!
						$interval.cancel(statusChecker);
						connectionModal.dismiss();
					}
				},1000);

				connectionModal.result.then(() => {
					DesktopService.connection.status = 2;
					//StateReloadService.reload();
					if (statusChecker) {
						$interval.cancel(statusChecker);
					}
					location.reload();
					connectionModal = null;
				}, () => {
					DesktopService.connection.status = 2;
					//StateReloadService.reload();
					//location.reload();
					if (statusChecker) {
						$interval.cancel(statusChecker);
					}
					connectionModal = null;
				});
			}, 300);
		}

		// status changed
		function onConnectionStatusChange(response) {
			if (response === 1) {
				usSpinnerService.spin('spinner-page-loading');
			} else if (response === 2) {
				usSpinnerService.stop('spinner-page-loading');
			}
		}

		// status changed to 0 and there by $q.rejected
		// display connection-lost modal
		function onConnectionStatusLost(error) {
			if (error === 0) {
				displayConnectionLostModal();
			}
		}
		//Temporary hack to allow for seprating into modules
		psPlugin.onConnectionStatusChange = onConnectionStatusChange;
		psPlugin.onConnectionStatusLost = onConnectionStatusLost;

		ComServer3.init($http, vngageConfig, SessionService);

		psPlugin.core.messages = {
			loader: function (params) {
				var container = document.createElement('div');
				container.className = 'psPlugin_loader';

				return container;
			},

			create: function (params) {
				var parent = params.parent;
				try {
					var elem = psPlugin.core.messages[params.type](params.content);
					parent.appendChild(elem);
				} catch (err) {
					console.error(err);
					return false;
				}
				this.elem = elem;
				this.close = function () {
					elem.parentNode.removeChild(elem);
				};
			},

			warning: function (elems) {
				return elems;
			}
		};



		psPlugin.core.exceptionHandler = new ExceptionHandler();

		(function () {
			var shellLoader = function (params) {

				this.browser = new BrowserValidation();
				this.session = {};
				var ui = psPlugin.core.ui;
				this.ui = ui;
				var jsExt = psPlugin.core.jsExt;
				this.jsExt = jsExt;
				var messages = psPlugin.core.messages;
				this.messages = messages;
				var pageLayout = new psPlugin.core.ui.pageLayout();
				this.pageLayout = pageLayout;
				var scriptTag = false;

				var close = function () {
					if (scriptTag) {
						try {
							psPlugin.application.close();
						} catch (err) {
							console.log('cannot close an application that has not been opened yet', err);
						}
						pageLayout.clearMenu();
						psPlugin.application = null;

						scriptTag.parentElement.removeChild(scriptTag);
					}
				};

				this.close = close;

				this.logout = function (notifyServer) {

					var logoutTimer = false;

					function endSession() {
						if (logoutTimer) {
							clearTimeout(logoutTimer);
						}

						sessionStorage.removeItem('accountId');
						sessionStorage.removeItem('engage_sessionId_' + User.props.accountId);

						var delegatedSession = localStorage.getItem('delegatedSessionId');
						if (delegatedSession !== null) {
							localStorage.setItem('sessionId', delegatedSession);
							localStorage.removeItem('delegatedSessionId');
						} else {
							localStorage.removeItem('sessionId');
						}

						psPlugin.shell.session = {};
						window.location.reload(true);
					}

					//stop listener if active
					try { psPlugin.application.listener.stop(); } catch (err) {}

					//if the server told us that we're logged out, don't send logout cmd
					if (notifyServer === false) {
						endSession();
						return;
					}

					try {
						//if the server doesn't respond within 4s, use a timer to clear sessionId and reload().
						logoutTimer = setTimeout(endSession, 4000);
						ComServer3.logout(endSession);
					} catch (err) {
						endSession();
					}
				};

			};

			psPlugin.core.shellLoader = shellLoader;

		}());

		psPlugin.shell = new psPlugin.core.shellLoader();
		psPlugin.shell.session.account = {
			id: User.props.accountId
		};


		//Initialize our application and LanuageService
		DesktopLegacyTranslations.setLanguage(Session.user.profile.language);
		LegacyApplication(DesktopService, IdentityService);

		psPlugin.application.writeOutLanguage = DesktopLegacyTranslations.translate;

		psPlugin.core.Listener = LegacyListener;
		psPlugin.core.stateMachine = stateMachine;


		(function () {

			var visitor = function (data) {
				var propertiesMap = _cloneDeep(VisitorObjTemplate);
				var sessionId = data.sessionId,
					properties = {},
					firstTimeDataInsert = true,
					events = new EventLoader(),
					evt = {};

				this.properties = properties;
				this.events = events;

				// creates what will later be the actual businesslayer of an Visitor
				this.identity = new VngageVisitor(data.vId);

				// add visitor event names
				visitorEvents.forEach(function(eventName){
					evt[eventName] = [];
				});

				// add properties event names
				for (var p in propertiesMap) {
					evt['on' + p] = [];
					properties[p] = propertiesMap[p];
				}
				//initialize events
				events.addEvents(evt);


				var checkIdentification = function (data) {
					if (!data.vId) {
						return;
					}

					ViewActions.visitorGetProfile(data.vId);
				};

				var updateData = function (newData) {

					// this first loop is neccessary due to an issue in the current setup:
					// modules subscribing to the change events expects to receive the change
					// _BEFORE_ they are updated in the properties object
					// TODO: change so that all values in the updateData are propagated,
					// then emit changeEvents to all interested parties.
					// Requires quite a bit of refactoring though.
					// -----
					// First, loop through visitor identification properties and look for changes
					// if changed, check ID.
					var hasChanged = false,
						props = ['visitorId', 'visitRevision', 'identificationState'],
						key;

					for (var p in props) {
						key = props[p];
						if (newData[key] && newData[key] !== properties[key]) {
							//console.warn('props', key, newData[key], properties[key]);
							hasChanged = true;
							properties[key] = newData[key];
						}
					}

					if (hasChanged) {
						checkIdentification(newData);
					}

					var changes = [];

					// Convert connectionState  =3 (Terminated) to 0 (Dead).
					// We prefer to call them 'walkers' though.
					if (newData.connectionState && newData.connectionState === 3) {
						newData.connectionState = 0;
					}


					for (var d in newData) {
						try {

							if (!firstTimeDataInsert) {
								if (d === 'pageVisits' && newData[d][0] && properties[d][0]) {
									if (newData[d][0] && properties[d][0].timeEntered === newData[d][0].timeEntered) {
										continue;
									}
									//console.log('changed:', newData[d][0].url);

								} else {
									if (dataChangeHandler.checkValue(properties[d], newData[d])) {
										continue;
									}
									//console.log('changed ' + d + ':', properties[d], '>', newData[d]);
								}


								changes.push({
									name: d,
									old: properties[d],
									value: newData[d]
								});

							}

							properties[d] = newData[d];

						} catch (err) {
							console.error('newData ERROR [err,newData,data]:', err, newData, data);
						}
					}

					//Trigger events for changed properties:
					changes.forEach(function (item) {
						if (events['on' + item.name]) {
							//console.log('on' + item.name + '.trigger', item.value, item.old);
							events['on' + item.name].trigger(item.value, item.old);
						}
					});

					// always check for identification on first run:
					if (firstTimeDataInsert && !hasChanged) {
						checkIdentification(properties);
					}

					firstTimeDataInsert = false;
				};

				this.updateData = updateData;


				updateData(data);

				//console.log('New visitor:', this.properties);

				var commands = new CommandLoader();
				//var commandsObj = {};
				for (var t in psPlugin.application.visitorHandler.commands) {
					var commandsObj = {};
					commandsObj[t] = {
						name: t,
						canExecute: function () {
							var args = [].slice.call(arguments);
							args[args.length] = properties.sessionId;
							psPlugin.application.visitorHandler.commands[this.name].canExecute.apply(psPlugin.application.visitorHandler.commands[this.name], args);
						},
						execute: function () {
							var args = [].slice.call(arguments);
							args[args.length] = properties.sessionId;
							psPlugin.application.visitorHandler.commands[this.name].execute.apply(psPlugin.application.visitorHandler.commands[this.name], args);
							//psPlugin.application.visitorHandler.commands[this.name].execute();
						}
					};
					commands.addCommands(commandsObj);
				}
				this.commands = commands;


				// add state change listener
				events.onapprovalStatus.subscribe('visitor', (function (approvalStatus) {
					switch (approvalStatus) {
						case 0: // Queued / PendingRouting
							break;
						case 1: // PendingApproval
							break;
						case 2: // InDialog (Approved)
							commands.Approve.execute();
							break;
						case 3: // visitor ended
							//commands.EndDialog.execute();
							commands.VisitorEnded.execute();
							break;
						case 4: // user ended
							break;
						case 5 : // Declined
							commands.Reject.execute();
							break;
					}
				}));


				events.onClose.subscribe('visitor', (function () {
					close();
				}));

				// StateMachine
				for (var s in psPlugin.application.visitorStateDiagram.states) {
					var state = psPlugin.application.visitorStateDiagram.states[s];

					state.entry = [(function () {
						//console.log('Visitor state change: ' + stateMachine.currentState);
						events['enterState' + s].trigger();
					})];

				}

				psPlugin.application.visitorStateDiagram.initialState = properties.initialState || 'Locked';
				properties.initialState = psPlugin.application.visitorStateDiagram.initialState;

				var stateMachine = new psPlugin.core.stateMachine(psPlugin.application.visitorStateDiagram, this.events);
				stateMachine.start();
				this.stateMachine = stateMachine;
				// Functions to run on Close
				var onClose = [];
				var close = function () {
					for (var f in onClose) {
						try {
							onClose[f]();
						} catch (err) {
							console.error('slight timing issue when closing visitor:', err, onClose[f]);
						}
					}
					delete psPlugin.application.visitorHandler.visitors[properties.sessionId];
				};
				this.onClose = onClose;
				this.close = close;
			};


			var helpers = {
				typeOf: function (value) {
					var s = typeof value;
					if (s === 'object') {
						if (value) {
							if (Object.prototype.toString.call(value) == '[object Array]') {
								s = 'array';
							}
						} else {
							s = 'null';
						}
					}
					return s;
				}
			};
			var dataChangeHandler = {

				checkValue: function (oldValue, newValue) {
					switch (helpers.typeOf(oldValue)) {
						case 'string':
						case 'number':
						case 'boolean':
							//if (newValue == '' || newValue == null) return true;
							return (oldValue === newValue);

						case 'object':
							var i = 0;
							for (var a in newValue) {
								try {
									if (newValue[a] !== oldValue[a]) { i++; }
								} catch (err) {
									return false;
								}
							}
							//if (i > 0) return false;
							//return true;
							return (i === 0);

						case 'array':
							if (newValue.length > 0) {
								return false;
							}
							return true;
					}
					//console.log('checkValue:type not recognized',helpers.typeOf(oldValue) );
				}
			};

			psPlugin.application.visitor = visitor;

		}());

		psPlugin.application.visitorStateDiagram = visitorStateDiagram;


		/* File: ~/js/applications/desktopLegacy/listener.js */
		(function () {

			function request(data, callback, type) {
				//if (type !== 'commServer23Proxy'){console.log('::REQUEST::', data, callback, type);}

				// send through 3.0
				var id = psPlugin.shell.jsExt.guid();
				var request = function (id, data, callback, type) {
					var obj;
					if (type == 'metaDataGet' || type == 'emailSend') {
						obj = data;
						obj.tag = {};
					} else {
						obj = {
							serviceName: 'psDesktop.ashx',
							data: data,
							tag: {}
						};
					}
					obj.tag[id] = 'responseHandler';

					var reqObj = {};
					reqObj[type] = obj;

					ComServer3.send(reqObj);

					var responseHandler = function (data) {
						if (psPlugin.legacyHasStopped === true) {
							return;
						}
						delete psPlugin.application[id];

						try {
							if (type == 'metaDataGet' || type == 'emailSend') {
								callback(data);
							} else {
								if (typeof data.data === 'string' && data.data !== '') {
									callback(JSON.parse(data.data));
								} else {
									callback(data);
								}
							}
						} catch (err) {
							console.error(err);
						}
					};
					this.responseHandler = responseHandler;
				};
				psPlugin.application[id] = new request(id, data, callback, type);
			}

			function send(data, callback) {

				if (data.method == "listVisitors") {
					if (typeof psPlugin.application.listener.initialDialogsLoaded === "undefined") {
						psPlugin.application.listener.initialDialogsLoaded = true;
					}
				}

				var filteredData = function (data) {
					var dOut = "rid2=" + Math.random();
					if (typeof data !== 'undefined' && data !== '') {
						for (var index in data) {
							if (typeof data[index] === 'object') data[index] = JSON.stringify(data[index]);
							dOut += '&' + index + '=' + encodeURIComponent(data[index]);
						}
					}
					return dOut;
				};
				psPlugin.application.listener.request(filteredData(data), callback, 'commServer23Proxy');
			}


			function handleDialog(dialogData, newData) {
				var stateMap = {
					0: 'Queued',
					1: 'PendingApproval',
					2: 'InDialog', // approved
					3: 'Completed', // visitor ended
					4: 'Completed', // user ended
					5: 'Completed' // declined
				};

				newData.approvalStatus = dialogData.status;
				newData.initialState = newData.initialState || stateMap[dialogData.status];
				newData.connectionState = dialogData.clientConnectionStatus;

				if (!newData.visitingPageNow) {
					newData = _omit(newData, 'urlClassification');
				}


				return newData;
			}


			//add method to scope
			psPlugin.core.Listener.prototype.handleDialog = handleDialog;


			function handleActions(data, memberOf) {
				var newData = {};
				for (var k in data) {
					if (k != 'actions') {
						newData[k] = data[k];
					}
				}

				switch (memberOf) {
					case 'lockedVisitors':
						//console.log(data.visitingPageNow + "  "+ data.urlClassification);
						if (typeof data.actions != 'undefined') {
							if (typeof data.actions.dialog !== 'undefined') {
								newData = psPlugin.application.listener.handleDialog(data.actions.dialog, newData);
							}
							newData.chatMessages = data.actions.newChatMessages;
							var actions = [];
							for (var a in data.actions.clientMessages) {
								var ac = data.actions.clientMessages[a];
								var newAction = {
									type: ac.func,
									data: JSON.parse(ac.args.attributes[3].msg)
								};
								actions.push(newAction);
							}
							newData.actions = actions;
						}
						//newData.initialState = "InDialog";
						break;
					case 'proactives':
						newData.initialState = "Proactive";
						break;
					case 'visitorsInQueues':
						//console.log('handleActions', data);
						newData.initialState = "Queued";
						break;
				}
				return newData;
			}

			function createSortedLockedVisitorsArray(lockedVisitors) {
				// [ TL ] Fix for VEP-2564
				let sortedLockedVisitorsArray = [];
				if (lockedVisitors) {
					const stateMap = {
						0: 'Queued',
						1: 'PendingApproval',
						2: 'InDialog', // approved
						3: 'Completed', // visitor ended
						4: 'Completed', // user ended
						5: 'Completed' // declined
					};

					// Convert lockedVisitors to array (to be able to sort it)
					sortedLockedVisitorsArray = _map(lockedVisitors, v => {
						return v;
					});

					// Sort lockedVisitors so that visitors 'InDialog' are listed first (to be able to determine if we can accept any new auto-routed visitors)
					sortedLockedVisitorsArray.sort((v1,v2) => {
						return (v1.actions && v1.actions.dialog && stateMap[v1.actions.dialog.status] === 'InDialog' ? -1 : 1);
						//return (v1.actions && v1.actions.dialog && v1.actions.dialog.status >= 2 ? -1 : 1); // Put both "InDialog" and "Completed" first?
					});
					// console.log('lockedVisitors,sortedLockedVisitorsArray:',lockedVisitors,sortedLockedVisitorsArray);
				}
				return sortedLockedVisitorsArray;
			}

			function visitorOverAssigned(visitorData, acceptedVisitors) {
				// [ TL ] Fix for VEP-2564
				// Check if this visitor is incorrectly assigned by BE
				// If:
				//  1: Visitor is locked and initialState === 'Queued' (i.e. auto-routed and assigned to this agent, but not yet picked up)
				//  2: Group is auto-routed
				//  3: Current number of lockedVisitors matching this group equals (or exceeds) maxInteractionsPerUser for the group
				// Then:
				//  SKIP this visitor (as it is incorrectly auto-routed by BE):
				//  1: Do not add the visitor (or conversation)
				//  2: Call "Conversation/Leave" to notify BE

				//if (!psPlugin.application.visitorHandler.visitors[sessionId] && visitorData.initialState === 'Queued') {
				if (visitorData.initialState === 'Queued') {
					let groupConfig = psPlugin.application.configuration.groups[visitorData.toGroup.toUpperCase()];

					// Count number of conversations already accepted for this group ("InDialog" + any new "Queued" that just accepted)
					// NOTE: data['lockedVisitors'] must be sorted on "status", with "InDialog" first, so we know how many new conversations we can accept
					let lockedVisitorsToGroup = 0;
					_forEach(acceptedVisitors, v => {
						if (v.memberOf === 'lockedVisitors' && v.toGroup.toUpperCase() === visitorData.toGroup.toUpperCase()) {
							lockedVisitorsToGroup++;
						}
					});
					// console.log('Total # of assigned visitors in group \''+visitorData.toGroup+'\': '+lockedVisitorsToGroup);

					// groupConfig.maxInteractionsPerUser = 1; // Simulate over-assignment from BE - only uncomment for simulating the race-condition!
					const offline = (IdentityService.getPresence() !== 'online');
					if (groupConfig.routingType === 1 && (offline || lockedVisitorsToGroup >= groupConfig.maxInteractionsPerUser)) {
						console.log('Auto-routed visitor over-assigned! ('+(offline ? 'agent is \'away\'' : 'max number of auto-routed visitors to group ('+groupConfig.maxInteractionsPerUser+') exceeded')+') Leaving conversation:', visitorData.sessionId);
						return true;
					}
				}
				return false;
			}

			function handleListenerResponse(data) {
				var acceptedVisitors = {};
				var conversations = [];
				if (typeof data.responseType != 'undefined' && data.responseType == 'register') return;

				// [ TL ] Workaround for issue: VEP-2564 Filter out excess dialogs from auto routing in old desktop
				// Due to a race condition in BE, auto-routing may in some cases assign too many dialogs to an agent, i.e. no respect the group setting "maxInteractionsPerUser"
				// As a workaround, FE will now check all visitors in "lockedVisitors" in the state "Queued" and make sure we only accept as many as allowed by "maxInteractionsPerUser"
				// If the agent is assigned too many conversations, a "Conversation/Leave"-call is made to BE for each of them

				// First we need to sort data['lockedVisitors'] on state so that 'InDialog' is added first.
				// Otherwise we can't determining how many new auto-routed visitors we can accept (a 'queued' or 'pending' visitor could otherwise be accepted before the 'InDialog' ones are counted, and we would end up with too many conversations anyway (we never reject visitors 'InDialog'))
				data['lockedVisitors'] = createSortedLockedVisitorsArray(data['lockedVisitors']);

				for (var memberOf in data) {
					if (memberOf === 'lockedVisitors' || DesktopService.configuration.account.hideQueue !== true) {
						_forEach(data[memberOf], visitorData => {

							// distribute data
							if (visitorData) {
								visitorData = handleActions(visitorData, memberOf);
							}

							// Check all locked visitors so that BE has not over-assigned this agent (auto-routing)
							if (memberOf === 'lockedVisitors') {
								if (visitorOverAssigned(visitorData, acceptedVisitors)) {
									// BE has over-assigned. Skip this visitor and call Conversation/Leave to BE
									WebAPIUtils.leaveConversation(visitorData.sessionId);
									return;  // i.e. continue _forEach()-loop
								}

								// Visitor ok, push all locked visitors into the conversations array
								// this array of conversations will be added to ConversationsStore
								conversations.push(visitorData.sessionId);
							}

							acceptedVisitors[visitorData.sessionId] = visitorData; // v[sessionId] = visitordata
							acceptedVisitors[visitorData.sessionId].memberOf = memberOf; // lockedVisitors, proactives,visitorsInQueues

							// fix for faulty comserver caseing on attribute (langId / langID), langId is correct for the rest of the application but phone to ps uses langID
							if (acceptedVisitors[visitorData.sessionId].langId == undefined) {
								acceptedVisitors[visitorData.sessionId].langId = acceptedVisitors[visitorData.sessionId].langID;
							}
						});
					}
				}

				ServerActions.processConversations(conversations);

				psPlugin.application.visitorHandler.update(acceptedVisitors);
			}


			//add the custom send method to the listener module
			psPlugin.core.Listener.prototype.send = send;
			psPlugin.core.Listener.prototype.request = request;

			psPlugin.core.Listener.prototype.getNavigationHistory = function (visitorId, sessionId, callback) {
				CaseService.getNavigation(visitorId).then(function(result) {
					callback(result);
				}, function(error) {
					// no op
				});
			};


			var F = function () {
				this.start = function () {
					if (psPlugin.legacyHasStopped === true) { return; }
					//psPlugin.application.listener = new psPlugin.core.Listener(
					psPlugin.application.listener = new LegacyListener(
						function () {
							var heartbeatParams;


							//console.log(psPlugin.application.menu.pausedLanguages);
							var availableForGroups = [];
							if (!psPlugin.application.configuration.user.availableForGroup) {
								psPlugin.application.configuration.user.availableForGroup = {};
							}

							if (IdentityService.getPresence() === 'online') {
								var i = 0;
								while (i < psPlugin.application.visitorFilter.availableForGroups.length) {
									var g = psPlugin.application.visitorFilter.availableForGroups[i];
									if (psPlugin.application.configuration.user.availableForGroup[g.group] !== false) {
										availableForGroups.push(g.group);
									}
									i++;
								}
							}

							heartbeatParams = {
								// heartbeatParams
								method: 'listVisitors',
								viewGroups: availableForGroups,
								availableForGroups: availableForGroups,
								proactiveLang: psPlugin.application.visitorFilter.proactiveLang
							};
							//console.log(availableForGroups, psPlugin.application.visitorFilter.selectedGroups);
							return heartbeatParams;

						},
						{

							//commands

							Lock: {
								parameters: function (sessionID, callback) {
									return {
										reqObj: {
											method: 'adminServerCommand',
											command: 'session_adminlock',
											parameters: '',
											SessionID: sessionID,
											actionId: 's1'
										},
										onSuccess: function (data) {
											callback(data);
										}
									};
								}
							},

							checkGroupQueueStatuses: {
								parameters: function (callback) {

									return {
										reqObj: {
											method: 'groupQueueStatuses',
											psID: vngageConfig.accountId, //psPlugin.shell.session.account.id,
											groupIDs: psPlugin.application.configuration.groupIds
											//groupIDs: psPlugin.application.visitorFilter.allGroups
										},
										onSuccess: function (data) {
											if (typeof data.data.groupQueueStatuses === 'undefined') {
												return false;
											}
											//											var out = {};
											//											_each(data.data.groupQueueStatuses, function(val, key){
											//												out[key.toUpperCase()] = val;
											//											});
											//											callback(out);
											callback(data.data.groupQueueStatuses);
										}
									};
								},
								onError: function () {
								}
							},

							sendChatMessage: {
								parameters: function (message, type, messageOption, sessionId) {
									//console.log(message);
									return {
										reqObj: {
											method: 'adminchat',
											chatMessage: message,
											SessionID: sessionId,
											actionId: 'c1',
											type: type
										}
									};
								},
								onError: function () {
								}
							},

							getCase: {
								parameters: function (caseId, sessionId, callback) {

									var reqObj = {
										method: 'customercase',
										SessionID: sessionId
									};

									if (caseId) {
										reqObj.caseId = caseId;
									} else {
										reqObj.action = 'read';
									}

									return {
										reqObj: reqObj,
										onSuccess: function (data) {
											callback(data);
										}
									};
								}
							},
							saveCase: {
								parameters: function (data, sessionId, callback) {
									var reqObj = {};
									for (var k in data) {
										if (typeof data[k] != 'function') reqObj[k] = data[k];
									}
									reqObj.method = 'customercase';
									reqObj.SessionID = sessionId;
									reqObj.action = 'save';
									return {
										reqObj: reqObj,
										onSuccess: function (data) {
											callback(data);
										}
									};
								}
							},

							closeCase: {
								parameters: function (data, sessionId, callback) {
									var reqObj = {};
									for (var k in data) {
										if (typeof data[k] != 'function') reqObj[k] = data[k];
									}
									reqObj.method = 'customercase';
									reqObj.SessionID = sessionId;
									reqObj.action = 'close';
									return {
										reqObj: reqObj,
										onSuccess: function (data) {
											callback(data);
										}
									};
								}
							},

							codeStarter: {
								parameters: function (callback) {
									return {
										reqObj: {
											method: 'makephoneid'
										},
										onSuccess: function (data) {
											callback(data.data.phoneId);
										}
									};
								}
							},

							//handleDialog: {
							//	runCommand:'handleDialog' //handleDialog: function (dialogData, newData)
							//},


							beginDialog: {
								parameters: function (sessionId, data, callback) {
									return {
										reqObj: {
											method: 'beginDialog',
											SessionID: sessionId,
											defaultGroupID: data.toGroup,
											data: data.dialogObject
										},
										onSuccess: function (data) {
											callback(psPlugin.application.listener.handleDialog(data.data.clientDialog, {}));
										}
									};
								}
							},

							confirmDialogueRec: {
								parameters: function (sessionID, dialogID, token) {
									return {
										reqObj: {
											method: 'adminDialogConfirmNotify',
											SessionID: sessionID,
											dialogID: dialogID,
											token: token
										}
									};
								}
							},

							sendAction: {
								parameters: function (func, callFunction, parameters, sessionID) {
									parameters.type = callFunction;
									//console.log('sendAction', parameters);
									return {
										reqObj: {
											method: 'clienttask',
											func: func,
											callFunction: callFunction,
											parameters: parameters,
											SessionID: sessionID,
											actionId: 'a1'
										}
									};
								}
							},

							closeDialog: {
								parameters: function (sessionID, callback) {
									//console.log('closeDialog request');
									return {
										reqObj: {
											method: 'closeDialogue',
											'SessionID': sessionID
										},
										onSuccess: function (data) {
											//console.log('closeDialog response:',data);
											callback(data);
										}
									};
								}
							},

							unLock: {
								parameters: function (sessionID, callback) {
									//console.log('unLock');
									return {
										reqObj: {
											method: 'adminServerCommand',
											command: 'session_releaseadminlock',
											parameters: '',
											SessionID: sessionID,
											actionId: 's1'
										},
										onSuccess: function (data) {
											//console.log(data);
											callback(data);
										}
									};
								}
							},
							transfer: {
								parameters: function (sessionID, toGroup, callback) {
									return {
										reqObj: {
											method: 'adminServerCommand',
											command: 'session_releaseadminlock',
											releaseToGroupId: toGroup,
											parameters: '',
											SessionID: sessionID,
											actionId: 's1'
										},
										onSuccess: function (data) {
											callback(data);
										}
									};
								}
							},

							Begin: {
								runCommand: 'beginDialog' //beginDialog(sessionId, data, callback);
							},

							end: {
								runCommand: 'closeDialog' //closeDialog(sessionId, callback)
							},

							close: {
								runCommand: 'unLock' //unLock(sessionId, callback);
							}
						},
						//inject the services needed
						IdentityService, DesktopService, NotificationService
					);

					// start it up!
					psPlugin.application.listener.start();
				};
			};
			// create new listener instance
			psPlugin.application.listener = new F();


			psPlugin.application.events.onListenerResponse.subscribe("listener", handleListenerResponse);


		}());


		psPlugin.application.modules.actionEvents = actionEvents;

		//here be dragons
		psPlugin.application.modules.coWorker = coWorker;

		(function () {
			var coBrowser = {
				visitors: null,

				load: function () {
					this.visitors = psPlugin.application.visitorHandler.visitors;

					// start new instance on coWorker loaded
					psPlugin.application.events.onCoWorkerLoaded.subscribe('coBrowser', (function (visitor) {
						try {
							//console.warn('<DS> onCoWorkerLoaded', visitor.properties.sessionId);
							// conversation to ConversationsStore
							ServerActions.addConversation(visitor.properties.sessionId);
							// add as an initialConversationRequest
							WebAPIUtils.requestConversationStart(visitor.properties.sessionId)
								.then(response => {
									//console.warn('<DS> requestConversationStart', response);
									// loop through responses and find corresponding visitor
									_each(response, res => {
										var pvis = psPlugin.application.visitorHandler.visitors,
											vis = _get(pvis, res.id);

										if (vis) {
											vis.coWorker.refCodeArea.innerHTML = 'Ref: ' + res.refNumber;

											var navigations = _filter(res.messages, {messageType: 'navigation'}),
												lastNavigation = navigations[navigations.length-1];

											var browserInfo = _filter(res.messages, {messageType: 'browserInfo'});

											if (browserInfo.length){
												var nav = browserInfo[browserInfo.length-1];
												vis.properties.browserInfo = {
													width : nav.width,
													height: nav.height,
													hasFlash: nav.hasFlash
												};
											}

											if (!lastNavigation) {
												lastNavigation = {
													url: '',
													classification: 0
												};
											}

											vis.properties.visitingPageNow = lastNavigation.url;
											vis.properties.urlClassification = lastNavigation.classification;
											vis.coWorker.coBrowserModule = new psPlugin.application.modules.coBrowser.panel(vis);
										}
									});
								});

						} catch (err) {
							console.log(err);
						}
					}));
				}
			};

			psPlugin.application.modules.coBrowser = coBrowser;
		}(psPlugin.application.modules));


		/* File: ~/js/modules/coBrowser/desktop/view.js */


		(function (coBrowser) {

			var panel = function (visitor) {

				var usePassiveCoBrowsing = psPlugin.application.configuration.visitor.enablePassiveCoBrowsing === true,
					useDomCoBrowsing = psPlugin.application.configuration.visitor.domCoBrowsing === true,
					useProtocolRelativeUrls = psPlugin.application.configuration.visitor.useProtocolRelativeUrls === true,
					//declare shortcut for translation within this module
					lang = psPlugin.application.writeOutLanguage;

				//parent element
				var parent = visitor.coWorker.coBrowsingArea,
					//post message channel
					channel = false,
					//states for the visitor cobrowse
					lastPage = visitor.properties.visitingPageNow,
					userPage = lastPage,
					firstPage = lastPage,
					//if passiveCoBrowsing is on, default paused to false
					paused = usePassiveCoBrowsing,
					locked = false,
					//disabled = true,
					disabled = false, //FIXME: disabled should be a config option?
					lockedByEvent = false;

				/**
				 * verify navigation with account cobrowser config
				 */
				function verifyNavigation(url) {
					if (!verifyUrlClassification()) return false;

					if (coBrowserFollow(url) && !lockedByEvent) {
						locked = false;
						return true;
					} else {
						locked = true;
						return false;
					}
				}

				/**
				 * check if visitor has a valid urlClassification
				 */
				function verifyUrlClassification() {
					if (visitor.properties.urlClassification !== 1) {
						locked = true;
						return false;
					}
					locked = false;
					return true;
				}

				/**
				 * check if page is an excluded page
				 */
				function verifyExcludedPage(url) {
					var urlToMatch = url || visitor.properties.visitingPageNow,
						ep = psPlugin.application.configuration.account.excludePagesFromCoBrowsingStart;
					if (ep && !!~ep.indexOf(urlToMatch)) return true;
				}

				/**
				 * check if @url matches fragment/url in account CoBrowserNoFollow listing

				 */
				function coBrowserFollow(url) {
					var urlToMatch = url || visitor.properties.visitingPageNow;
					if (verifyExcludedPage(urlToMatch)) return true;
					if (allowCoBrowserFollowNavigation(urlToMatch)) {
						return true;
					} else {
						return false;
					}
					return true;
				}

				/**
				 * loop through account nofollow fragments to verify valid navigation follow
				 */
				function allowCoBrowserFollowNavigation(followUrl) {

					var fragments = psPlugin.application.configuration.account.coBrowserNoFollow,
						result;

					if (!fragments.length || !_isArray(fragments)) {
						return true;
					}

					var result = fragments.every(function (fragment, key) {
						if (followUrl.indexOf(fragment) !== -1) {
							return false;
						}
						return true;
					});

					return result;

				}

				var buildBrowser = helpers.buildCoBrowser();
				var wrapper = buildBrowser.wrapper;

				// ------------- PDF capture
				/* TODO: re-enable PDF Capture once new API is in place
				if (psPlugin.application.configuration.account.PDFcapture === true) {
					var PDFcaptureButton = document.createElement('button');

					this.PDFButtonSetAction = function (action) {
						switch (action) {
							case 'pending':
								PDFcaptureButton.innerHTML = 'Avbryt PDF markering';
								PDFcaptureButton.title = 'Klicka för att avbryta markering av PDF formulär';
								PDFcaptureButton.onclick = function () {
									psPlugin.application.listener.pdfCaptureCancel();
								};
								PDFcaptureButton.disabled = false;
								break;
							case 'inactive':
								PDFcaptureButton.innerHTML = 'Aktiv PDF delning';
								PDFcaptureButton.title = '';
								PDFcaptureButton.disabled = true;
								PDFcaptureButton.onclick = function () {
								};
								break;
							default:
								PDFcaptureButton.innerHTML = 'Starta PDF delning';
								PDFcaptureButton.title = 'Klicka för att starta PDF markering av formulär. När önskat formulär är markerat, kan du dela det med besökaren';
								PDFcaptureButton.onclick = function () {
									psPlugin.application.modules.pdfCapture.initiateCaptureSession(visitor);
								};
								PDFcaptureButton.disabled = false;
								break;
						}
					};
					this.PDFButtonSetAction('start');
					topMenu.appendChild(PDFcaptureButton);
				}
				*/
				// -------------* end PDF capture

				psPlugin.showSendPageModal = function(link, conversationId) {
					Avgrund.show(makeModalSendPage(link, conversationId));
				}

				function makeModalSendPage(link, conversationId) {
					let id = 'modalSendPage';
					let el = document.getElementById(id) || document.createElement('div');
					let title = link.title || lang('coWorkerSendPageToVisitorLinkTitle');
					let url = link.url || "";

					el.id = id;

					el.innerHTML = '<h2 class="avgrund-title">' + lang('coWorkerSendPageToVisitor') + '</h2>' +
						'<form>' +
						'<div class="avgrund-body">' +
						'<label>' + lang('coWorkerSendPageToVisitorLinkLabel') + '</label><br>' +
						'<input autofocus maxlength="140" size="30" placeholder="' + lang('coWorkerSendPageToVisitorLinkTitle') + '" type="text" name="title" value="' + title + '" />' +
						((!url) ? '': '<p><span class="url-preview-text" title="' + url + '">' + url + '</span></p>') +
						'</div>' +
						'<div class="avgrund-actions">' +
						'<button class="btn-cancel" type="button">' + lang('coWorkerSendPageToVisitorButtonCancel') + '</button>' +
						'<button class="btn-submit" type="submit">' + lang('coWorkerSendPageToVisitorButtonSend') + '</button>' +
						'</div>' +
						'</form>';


					var form = el.querySelector('form');
					var cancelBtn = el.querySelector('.btn-cancel');
					var isSent = false;

					cancelBtn.onclick = function () {
						Avgrund.hide();
					}

					form.onsubmit = function (event) {
						event.preventDefault();

						if (!!isSent) {
							return false;
						}

						var urlTitle = (this.elements['title']) ? this.elements['title'].value : title;

						Avgrund.hide();
						ViewActions.createSendPageLink({url:url, title: urlTitle}, conversationId);
						isSent = true;

					}.bind(form);

					setTimeout(function () {
						var field = el.querySelector('[autofocus]');
						if (field && typeof field.focus === 'function') {
							field.focus();
						}
					}, 100);

					document.body.appendChild(el);
					return el;
				}

				var cob = buildBrowser.iframeWrapper,
					fitWidth = true,
					visitorSize = {};



				parent.appendChild(wrapper);





				// save for unsubscription array;
				var eventSubscriptions = [];

				//need to set initial browser info once from here, because OEAUF.
				var setInitialVGBrowserInfo = _once(function(){
					visitor.properties.browserInfo = visitor.properties.browserInfo || {
							width: '100%',
							height: '100%',
							hasFlash: false
						};
					ServerActions.receiveBrowserInfo(visitor.properties.sessionId, visitor.properties.browserInfo);
				});


				visitor.events.onCoWorkerMaximize.subscribe('coBrowser', function () {

					i18nLoader(Session.user.profile.language, function(i18n) {
						const locale = (Array.isArray(i18n.locales) ? i18n.locales[0] : i18n.locales);
						ReactDOM.render(<IntlProvider locale={locale} messages={i18n.messages}><VisualGuidanceContainer key={visitor.properties.sessionId} conversationId={visitor.properties.sessionId} /></IntlProvider>, cob);
						visitor.reactComponents = visitor.reactComponents || [];
						visitor.reactComponents.push(cob);
					});

					if (!useDomCoBrowsing) {
						lastPage = visitor.properties.visitingPageNow;
						//TODO: refactor status-check
						var initialStatus = (locked) ? 'passive': (paused) ? 'paused': 'active';
					} else {
						ViewActions.requestDomUpload(visitor.properties.sessionId);
					}
				});

				eventSubscriptions.push('onCoWorkerMaximize');


				// on coworker panel minimize
				visitor.events.onCoWorkerMinimize.subscribe('coBrowser', function () {

					if (channel) channel.destroy();
					channel = false;
					// unmount VG component
					ReactDOM.unmountComponentAtNode(cob);
				});
				eventSubscriptions.push('onCoWorkerMinimize');

			};


			var helpers = {

				captureEnter: function (elem, callback) {
					elem.onkeypress = function (e) {
						var evt = (e) ? e: (window.event) ? window.event: null;
						if (evt) {
							var key = (evt.charCode) ? evt.charCode: ((evt.keyCode) ? evt.keyCode: ((evt.which) ? evt.which: 0));
							if (key === 13) {
								evt.preventDefault();
								callback();
							}
						}
						return true;
					};
				},

				buildCoBrowser: function () {
					//declare shortcut for translation within this module
					var lang = psPlugin.application.writeOutLanguage;
					var layout = uiView.createNodes({
						reference: 'wrapper',
						tag: 'div',
						className: 'psBrowserWrapper',
						children: [{
							reference: 'iframeWrapper',
							tag: 'div',
							className: 'psIframeWrapper'
						}]
					});

					return layout;
				}
			};

			coBrowser.panel = panel;

		}(psPlugin.application.modules.coBrowser));



		psPlugin.application.modules.chat = legacyChatModule;




		//visitorProfilePanel
		(function (chat) {
			function visitorProfilePanel (visitor) {
				var viewArea = new ExpandableAreaView({
					heightCompact: 200,
					previewHeightCompact: 0,
					height: 300,
					previewHeight: 1,

					startState: 'preview',
					startSize: 'compact'
				});

				var headerElem = viewArea.layout.header;
				var contentElem = viewArea.layout.content;

				headerElem.innerHTML = psPlugin.application.writeOutLanguage('chatvisitorProfilePanelTitle');

				// Todo: this will be moved from here
				ServerActions.visitorProfileAdd(visitor.properties.vId);
				i18nLoader(Session.user.profile.language, function(i18n) {
					const locale = (Array.isArray(i18n.locales) ? i18n.locales[0] : i18n.locales);
					ReactDOM.render(<IntlProvider locale={locale} messages={i18n.messages}><VisitorProfileContainer visitId={visitor.properties.vId} conversationId={visitor.properties.sessionId} /></IntlProvider>, contentElem);
					visitor.reactComponents = visitor.reactComponents || [];
					visitor.reactComponents.push(contentElem);
				});

				visitor.coWorker.visitorDetailsArea.appendChild(viewArea.layout.wrapper);

				// save for unsubscription array;
				var eventSubscriptions = [];

				// on coworker panel maximize
				visitor.events.onCoWorkerMaximize.subscribe('psCaseList', (function () {
					viewArea.setSize('normal');
					viewArea.render();
				}));
				eventSubscriptions.push('onCoWorkerMaximize');

				// on coworker panel minimize
				visitor.events.onCoWorkerMinimize.subscribe('psCaseList', (function () {
					viewArea.setSize('compact');
					viewArea.render();
				}));
				eventSubscriptions.push('onCoWorkerMinimize');
			};

			chat.visitorProfilePanel = visitorProfilePanel;
		}(psPlugin.application.modules.chat));


		(function (chat) {
			function participants (visitor) {
				var parentEl = visitor.coWorker.visitorDetailsArea;
				var el = document.createElement('div');

				el.className = 'conversation-participants';
				parentEl.insertBefore(el, parentEl.childNodes[1]);

				i18nLoader(Session.user.profile.language, function(i18n) {
					const locale = (Array.isArray(i18n.locales) ? i18n.locales[0] : i18n.locales);
					ReactDOM.render(<IntlProvider locale={locale} messages={i18n.messages}><ConversationParticipants conversationId={visitor.properties.sessionId} {...i18n} /></IntlProvider>, el);
					visitor.reactComponents = visitor.reactComponents || [];
					visitor.reactComponents.push(el);
				});
			}

			chat.participants = User.canInviteGuest ? participants : function(){};

		}(psPlugin.application.modules.chat));



		(function (chat) {
			var notesPanel = function (visitor) {
				var viewArea = new ExpandableAreaView({
					height: 40,
					heightCompact: 40,
					previewHeight: 0,
					previewHeightCompact: 0,

					startState: 'preview',
					startSize: 'compact'
				});

				var headerElem = viewArea.layout.header;
				var contentElem = viewArea.layout.content;

				headerElem.innerHTML = psPlugin.application.writeOutLanguage('chatNotesPanelTitle');
				var view = helpers.view();


				this.sendComment = function (msg) {
					//msg = psPlugin.core.jsExt.return2br(msg);
					ViewActions.createNoteMessage(msg, visitor.properties.sessionId);
					//visitor.coWorker.chatModule.sendComment(msg);
				};

				//add input field and append it to the view
				var expandableTextArea = new ExpandableTextArea({
					onSubmit: this.sendComment,
					showCounter: false,
					expandWhenTyping: false,
					height: 40,
					expandedHeight: 40,
					//,
					//onExpand: function () {
					//	addEventListener('click', function () {
					//		view.wrapper.style.display = 'block';
					//	});
					//},
					//onCollapse: function () {
					//	addEventListener('click', function () {
					//		view.wrapper.style.display = 'none';
					//	});
					//},
					placeholderText: psPlugin.application.writeOutLanguage('chatNotesPlaceholderText')
				});

				//todo move to css
				//console.log(expandableTextArea);
				expandableTextArea.view.input.style.backgroundColor = 'rgba(255,255,200,1)';

				view.form.appendChild(expandableTextArea.view.chat);

				contentElem.appendChild(view.wrapper);


				visitor.coWorker.visitorDetailsArea.appendChild(viewArea.layout.wrapper);

				// save for unsubscription array;
				var eventSubscriptions = [];

				// on coworker panel maximize
				//visitor.events.onCoWorkerMaximize.subscribe('psCaseList', (function () {
				//	viewArea.setSize('normal');
				//	viewArea.render();
				//}));
				//eventSubscriptions.push('onCoWorkerMaximize');

				// on coworker panel minimize
				//visitor.events.onCoWorkerMinimize.subscribe('psCaseList', (function () {
				//	//viewArea.setSize('hidden');
				//	viewArea.setState('collapsed');
				//	viewArea.render();
				//}));
				//eventSubscriptions.push('onCoWorkerMinimize');

				// on try case close
				visitor.events.onTryCaseClose.subscribe('psCaseList', (function () {
					viewArea.setState('collapsed');
					viewArea.render();
				}));
				eventSubscriptions.push('onTryCaseClose');
			};
			var helpers = {
				view: function () {
					return uiView.createNodes({
						reference: 'wrapper',
						tag: 'div',
						className: 'chatWrapper notes',
						children: [{
							reference: 'form',
							tag: 'div'
						}]
					});
				}
			};

			chat.notesPanel = notesPanel;
		}(psPlugin.application.modules.chat));


		/* File: ~/js/modules/chat/desktop/view.js */


		(function (chat) {
			var panel = function (visitor) {

				//TODO:username
				// parent element
				var parent = visitor.coWorker.visitorDetailsArea;
				var groupConfig = psPlugin.application.configuration.groups[visitor.properties.toGroup.toUpperCase()];
				var endMessage = groupConfig.dialogOptions.signature.replace('[[name]]', User.props.configuration.displayName);

				// Do not replace user.id in the chat view of the agent (#4395)
				//endMessage = endMessage.replace('[[id]]', User.props.id);

				var viewArea = new ExpandableAreaView({
					height: 400,
					heightCompact: 250,
					previewHeight: 180,
					previewHeightCompact: 140,

					startState: 'preview',
					startSize: 'compact',
					//showHeader: false
					scrollToOnMouseOut: 'bottom',
					onCollapse: function () {
						wrapper.style.display = 'none';
					},
					onExpand: function () {
						wrapper.style.display = 'block';
					}
				});
				var chatHistory = document.createElement('div');
				chatHistory.className = 'psPlugin_chatHistoryBackground';

				viewArea.layout.content.appendChild(chatHistory);

				i18nLoader(Session.user.profile.language, function(i18n) {
					const locale = (Array.isArray(i18n.locales) ? i18n.locales[0] : i18n.locales);
					ReactDOM.render(<IntlProvider locale={locale} messages={i18n.messages}><MessageListContainer key={visitor.properties.sessionId} viewArea={viewArea} conversationId={visitor.properties.sessionId} groupList={psPlugin.application.configuration.groups} /></IntlProvider>, chatHistory);
					visitor.reactComponents = visitor.reactComponents || [];
					visitor.reactComponents.push(chatHistory);
				});

				//setTimeout(function() {
				//	viewArea.render();
				//}, 4000);

				var headerElem = viewArea.layout.header;
				headerElem.innerHTML = psPlugin.application.writeOutLanguage('chatPanelTitle');

				function writeUserAgent(){
					var userAgent = VisitorProfileStore.getUserAgent(visitor.properties.vId),
						chatPanelTitle = psPlugin.application.writeOutLanguage('chatPanelTitle'),
						browserName,
						browserVersion,
						device,
						os,
						icon;

					if (userAgent && userAgent.browser) {
						browserName = userAgent.browser.name;
						browserVersion = parseInt(userAgent.browser.version, 10);
						device = userAgent.os;

						if (device && device.osName) { os = device.osName; }

						if (device && device.phone) {
							icon = '<i class="vngage-icon-mobile" title="Mobile"></i>';
						} else if (device && device.tablet) {
							icon = '<i class="vngage-icon-tablet" title="Tablet"></i>';
						} else {
							icon = '<i class="vngage-icon-desktop" title="Desktop"></i>';
						}

						headerElem.innerHTML = `${chatPanelTitle} <span class="uaString" title="version ${userAgent.browser.version}">${icon} ${os} ${browserName} ${browserVersion}</span>`;


					} else {
						setTimeout(writeUserAgent,1000);
					}
				}

				writeUserAgent();

				var layout = view();

				var wrapper = layout.wrapper;

				var chatFormArea = layout.form;
				var sendMessage = function (message) {
					var parts = message.match(/^(?:\[\[)([^\]]*)(?:\]\]\s*)/);
					if (parts && parts[1]) {
						message = message.replace(/^(\[\[[^\]]*\]\]\s*)/, '');
					}

					ViewActions.createMessage(message, visitor.properties.sessionId);

					chatInputView.setText('');
					chatInputView.focus();
				};
				var sendChatMessage = function (m) {
					var msg = m || chatInput.value,
						username = User.props.configuration.displayName;

					if (!msg || msg === '') {
						return false;
					}


					//Markdown:
					//TODO:username
					//msg = '[[' + username + ']]' + msg;

					//convert newlines to <br> for viewing
					//msg = psPlugin.core.jsExt.return2br(msg);
					//msg = '<b>' + username + ':</b> ' + msg;

					sendMessage(msg);
				};

				this.sendChatMessage = sendChatMessage;
				var chatInputView = new ExpandableTextArea({
					showCounter: true,
					maxChars: 1000,
					//forceMaxLength: true,
					placeholderText: psPlugin.application.writeOutLanguage('chatPlaceholderText'),
					minChars: 0,
					height: 32,
					expandedHeight: 90,
					onSubmit: this.sendChatMessage
				});

				chatFormArea.appendChild(chatInputView.view.chat);

				var chatInput = chatInputView.view.input;

				chatInput.addEventListener('focus', function () {
					ViewActions.setConversationIdleState(visitor.properties.sessionId, false);
				});

				chatInput.addEventListener('blur', function () {
					ViewActions.setConversationIdleState(visitor.properties.sessionId, true);
				});

				var autotextIcon = layout.autotextIcon; //document.createElement("div");
				//autotextIcon.className = "psCaseAutoTextIcon";
				//autotextIcon.innerHTML = "Autotexts";
				//autotextIcon.title = "Click to show autotexts";

				//chatInputView.view.content.appendChild(autotextIcon);

				i18nLoader(Session.user.profile.language, function(i18n) {
					const locale = (Array.isArray(i18n.locales) ? i18n.locales[0] : i18n.locales);
					ReactDOM.render(<IntlProvider locale={locale} messages={i18n.messages}><ParticipantsWritingContainer conversationId={visitor.properties.sessionId} /></IntlProvider>, layout.participantsWritingWrapper);
					visitor.reactComponents = visitor.reactComponents || [];
					visitor.reactComponents.push(layout.participantsWritingWrapper);
				});

				var autoTexts = document.createElement('div');
				autoTexts.className = 'popover psCaseAutoTextContainer transitionOpacity';
				//chatInputView.view.extras.appendChild(autoTexts);
				visitor.coWorker.view.appendChild(autoTexts);


				this.chatInput = chatInput;
				this.autoTexts = autoTexts;
				this.autotextIcon = autotextIcon;
				this.chatHistory = chatHistory;
				this.chatInputView = chatInputView;


				parent.appendChild(viewArea.layout.wrapper);
				parent.appendChild(wrapper);


				var writeEndMessage = function () {
					var message = {
						msg: endMessage,
						created: null
					};
					//chatHistory.appendChild(chat.helpers.renderUserMessage(message, false));
					viewArea.render('bottom');
					//if (chatHistoryHeightMinimized > chatHistory.scrollHeight) chatHistory.style.height = chatHistory.scrollHeight + 'px';
				};


				var sendComment = function (message) {
					ViewActions.createNoteMessage(message, visitor.properties.sessionId);
				};
				this.sendComment = sendComment;

				var sendActionMessage = function (obj) {

					//return;*/
					obj.data = decodeURIComponent(obj.data);

					var message = 'COMMAND:::' + JSON.stringify(obj);
					//console.log('sendActionMessage', message);

					var m = chat.helpers.renderActionMessage(message, function (obj) {
						var data = obj.params.data;
						if (typeof visitor.coWorker.coBrowserModule[obj.command] !== 'undefined') {
							//TODO open for other commands then navigate
							visitor.coWorker.coBrowserModule[obj.command](decodeURIComponent(data));
						} else {
							try {
								visitor.commands[obj.command].execute(obj.params);
							} catch (err) {
								console.log(err);
							}
						}
					}, true);
					//TODO: implement messageType:'navigation' everywhere
					//visitor.commands.sendChatMessage.execute(message, 6, -1);
					visitor.coWorker.dropZoneArea.appendChild(m.largeIcon);
					//chatHistory.appendChild(m.wideIcon);
					viewArea.render('bottom');
				};
				this.sendActionMessage = sendActionMessage;


				var blinkingElem = false;
				psPlugin.shell.jsExt.addEventListener('mouseover', parent, function () {
					if (blinkingElem) {
						setTimeout(function () {
							if (blinkingElem) {
								blinkingElem.stop(function () {
									blinkingElem = false;
								});
							}
						}, 2000);
					}
				});

				this.typeCheck = chat.helpers.writingNowCheck(chatInput);

				/*
				* --------------------------------------------------
				* actionEvent listeners
				* --------------------------------------------------
				*/

				var eventSubscriptions = [];


				// on incoming chat
				//TODO: split up actions and change this event listener
				//visitor.events.onchatMessages.subscribe('chat', (function (messages) {
				//	visitorWritingNowTimeout = setTimeout(function () {
				//		clearTimeout(visitorWritingNowTimeout);
				//		visitorWritingNowTimeout = false;
				//	}, 300);
				//	SoundFxService.play('visitorchat');
				//	clearVisitorIsWriting();
				//	addChatMessageToHistory(messages);
				//}));
				//eventSubscriptions.push('onchatMessages');

				// on coworker panel maximize
				visitor.events.onCoWorkerMaximize.subscribe('chat', (function () {
					viewArea.setSize('normal');
					viewArea.render();

					chatInput.focus();

				}));
				eventSubscriptions.push('onCoWorkerMaximize');

				// on coworker panel minimize
				visitor.events.onCoWorkerMinimize.subscribe('chat', (function () {
					viewArea.setSize('compact');
					viewArea.render();
				}));
				eventSubscriptions.push('onCoWorkerMinimize');

				// on approve dialog
				visitor.events.onApprove.subscribe('chat', (function () {
					chatFormArea.style.display = 'block';
				}));
				eventSubscriptions.push('onApprove');

				// on try case close
				visitor.events.onTryCaseClose.subscribe('chat', (function () {
					viewArea.setState('collapsed');
					viewArea.render();
				}));
				eventSubscriptions.push('onTryCaseClose');

				// on open new case
				visitor.events.onOpenNewCase.subscribe('chat', (function () {
					viewArea.setState('preview');
					viewArea.render();
				}));
				eventSubscriptions.push('onOpenNewCase');

				// on End dialog event
				visitor.events.onEndDialog.subscribe('chat', (function () {
					writeEndMessage();
					var joinedAround = ParticipantStore.getParticipantsCountJoinedOfConversation(visitor.properties.sessionId);
					if (joinedAround < 2) {
						chatFormArea.style.display = 'none';
					}
				}));
				eventSubscriptions.push('onEndDialog');

				if (visitor.coWorker.inDialog) {
					chatFormArea.style.display = 'block';
				} else {
					chatFormArea.style.display = 'none';
				}
			};


			var view = function () {
				return uiView.createNodes({
					reference: 'wrapper',
					tag: 'div',
					className: 'chatWrapper',
					children: [
						{
							reference: 'participantsWritingWrapper',
							tag: 'div',
							className: 'participants-writing-wrapper'
						},
						{
							reference: 'autotextIcon',
							tag: 'div',
							className: 'psCaseAutoTextIcon',
							innerHTML: psPlugin.application.writeOutLanguage('chatAutoTextButton'),
							title: psPlugin.application.writeOutLanguage('chatAutoTextButtonToolTip')
						}, {
							reference: 'form',
							tag: 'div',
							className: 'chatFormArea transitionAll'
						}]
				});
			};
			/*
			 * -----------------------------------------------------------
			 * Set namespace
			 * -----------------------------------------------------------
			 */
			chat.panel = panel;
		}(psPlugin.application.modules.chat));

		/* File: ~/js/modules/videoChat/desktop/application.js */
		/* File: ~/js/modules/videoChat/desktop/lib.link -> ~/js/modules/videoChat/lib/helpers.js */
		/* File: ~/js/modules/videoChat/desktop/view.js */
		psPlugin.application.modules.videoChat = videoChatModule;


		/* File: ~/js/modules/psCase/desktop/application.js */
		(function () {
			var psCase = {
				visitors: null,
				load: function () {
					this.visitors = psPlugin.application.visitorHandler.visitors;
					psPlugin.application.events.onCoWorkerLoaded.subscribe('psCase', (function (visitor) {
						try {
							visitor.coWorker.psCase = new psPlugin.application.modules.psCase.panel(visitor);
							visitor.commands.getActiveCase.execute(false);
						} catch (err) {
							console.log(err);
						}
					}));
				}
			};

			psPlugin.application.modules.psCase = psCase;
		}());


		/* File: ~/js/modules/psCase/desktop/listView.js */
		(function (psCase) {

			// left zero fill a number
			// see http://jsperf.com/left-zero-filling for performance comparison
			function leftZeroFill(number, targetLength) {
				var output = number + '';
				while (output.length < targetLength) {
					output = '0' + output;
				}
				return output;
			}

			//Check if the input value is a valid Date object
			//see thread on http://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
			function isValidDate(d) {
				if (Object.prototype.toString.call(d) !== "[object Date]") {
					return false;
				}
				return !isNaN(d.getTime());
			}


			function formatDate(timestamp) {

				var d = new Date(timestamp),
					monthsShort = psPlugin.application.writeOutLanguage('monthsShort');

				if (!isValidDate(d)) return '';

				d = leftZeroFill(d.getDate(), 2) + '-' + monthsShort[d.getMonth()] + '-' + leftZeroFill(d.getFullYear(), 2) + ', ' + leftZeroFill(d.getHours(), 2) + ':' + leftZeroFill(d.getMinutes(), 2);

				return d;
			}

			//Store a reference to currentCaseItem. TODO:refactor
			var currentCase = false;


			var listPanel = function (visitor) {

				var self = this,
					CONF = {
						groups: ''
					},
					viewArea = new ExpandableAreaView({
						height: 500,
						heightCompact: 500,
						previewHeight: 100,
						previewHeightCompact: 0,
						startState: 'preview',
						startSize: 'compact'
					}),
					headerElem = viewArea.layout.header,
					contentElem = viewArea.layout.content,
					currentItem = false,
					view = document.createElement('div');

				contentElem.style.backgroundColor = '#fff';
				headerElem.innerHTML = psPlugin.application.writeOutLanguage('caseListHeading');

				if (DesktopService.configuration.account.displayCaseHistory) {
					view.innerHTML = '<b>' + psPlugin.application.writeOutLanguage('caseListNotPresent') + '</b>';
				} else {
					view.innerHTML = '<b>' + psPlugin.application.writeOutLanguage('caseListDeactivated') + '</b>';
				}

				contentElem.appendChild(view);

				self.isMaximized = false;

				var popup = new Popover({
					el: document.body.appendChild(document.createElement('div')),
					parentObj: self, //send reference over to popover to solve positioning,
					maxHeight: 200
				});

				visitor.coWorker.visitorDetailsArea.appendChild(viewArea.layout.wrapper);


				// save for unsubscription array;
				var eventSubscriptions = [];

				// on coworker panel maximize
				visitor.events.onCoWorkerMaximize.subscribe('psCaseList', (function () {
					viewArea.setSize('normal');
					viewArea.render();
					self.isMaximized = true;

				}));
				eventSubscriptions.push('onCoWorkerMaximize');

				// on coworker panel minimize
				visitor.events.onCoWorkerMinimize.subscribe('psCaseList', (function () {
					viewArea.setSize('compact');
					viewArea.render();
					self.isMaximized = false;
				}));
				eventSubscriptions.push('onCoWorkerMinimize');

				visitor.identity.getCases().then(function(response) {
					if (DesktopService.configuration.account.displayCaseHistory) {
						helpers.renderCaseList(contentElem, response, visitor, popup);
						viewArea.render();
					}
				});

			};


			var Popover = function (config) {

				if (!config || !config.el) return;

				var defaults = {
					hideOnEsc: true,
					hideOnClick: false,
					maxHeight: 500,
					minHeight: 100,
					triggerEl: null,
					zIndex: 1001
				};


				var options = psPlugin.shell.jsExt.extend({}, defaults, config);


				var overlay = document.createElement('DIV');
				overlay.setAttribute('style', 'z-index:' + options.zIndex + '; position:fixed; left:0; top:0; bottom:0; right:0; background:rgba(0,0,0,.1); display:none;');
				document.body.appendChild(overlay);


				var el = options.el,
					triggerEl = options.triggerEl,
					isShown = false;

				var view = (function () {
					return uiView.createNodes({
						reference: 'wrapper',
						tag: 'div',
						className: 'popover left',
						children: [
							{
								reference: 'title',
								tag: 'h3',
								className: 'popover-title',
								innerHTML: 'Autotexts'
							}, {
								reference: 'arrow',
								tag: 'div',
								className: 'arrow'
							}, {
								reference: 'inner',
								tag: 'div',
								className: 'popover-inner',
								children: [{
									reference: 'content',
									tag: 'div',
									className: 'popover-content'
								}]
							}]
					});
				})();


				function title(str) {
					if (typeof str === 'string') {
						view.title.innerText = view.title.textContent = str;
					} else {
						return view.title;
					}
				}

				function content(str) {
					if (typeof str === 'string') {
						view.content.innerHTML = str;
					} else {
						return view.content;
					}
				}

				//place the element
				el.parentNode.replaceChild(view.wrapper, el);
				el = view.wrapper;

				el.style.zIndex = options.zIndex + 1;

				view.content.style.maxHeight = options.maxHeight + 'px';
				view.content.style.minHeight = options.minHeight + 'px';
				view.content.style.position = "relative";
				//if (!options.hideOnClick) psPlugin.shell.jsExt.addEventListener("click", el, function (e) { e.stopPropagation(); }, false);


				function findPos(obj, e) {
					// we need to know the parentObj at the moment, to calculate right offset positioning
					// due to CSS changes between max/minimized state and issues re. timing, the rightOffset is defined here.
					var cursorPosition = psPlugin.shell.jsExt.cursorPosition(e),
						curleft = ((options.parentObj.isMaximized) ? 360: 80) + view.wrapper.offsetWidth;

					return [curleft, cursorPosition.y];
				}

				var show = function show(e, evt) {
					//if (isShown) return;

					el.style.display = 'block';
					el.style.opacity = 0;


					var triggerEl = (e && e.target) ? e.target: e, //triggerEl,
						pos = findPos(triggerEl, evt),
						y = pos[1],
						triggerY = Math.round(triggerEl.offsetHeight / 2),
						height = (el.offsetHeight > 100) ? el.offsetHeight: 100,
						parentHeight = document.body.offsetHeight - 100,
						centeredY = y + triggerY - (height - 30);


					if (centeredY < 0) centeredY = 0;

					if (height > parentHeight) {
						el.style.top = '0px';
						view.arrow.style.top = y + 'px';
					} else {
						el.style.top = centeredY + 'px';
						view.arrow.style.top = y - centeredY + 'px';
					}


					el.style.right = pos[0] + 'px';
					el.style.opacity = 1;


					//add listener for ESC to close
					if (options.hideOnEsc) psPlugin.shell.jsExt.addEventListener("keydown", document, escape, false);


					//delay onclick event a bit so we don't trigger it with the propagating click from triggerEl
					psPlugin.shell.jsExt.removeEventListener("click", document.body, handleHide, false);
					setTimeout(function () {
						psPlugin.shell.jsExt.addEventListener("click", document.body, handleHide, false);
					}, 100);

					isShown = true;

					return false;
				};
				var handleHide = function (e) {
					var selectedElement = document.getSelection(),
						elemParam = selectedElement && selectedElement.anchorNode ? selectedElement.anchorNode.parentNode: false;

					if (elemParam && elemParam.offsetParent.className.indexOf('popover-content') > -1) return;
					hide(e);
				};

				var hide = function hide(e) {
					//if (!isShown) return;
					el.style.display = 'none';
					//overlay.style.display = 'none';


					isShown = false;

					if (options.hideCallback && typeof options.hideCallback === 'function') {
						options.hideCallback();
					}
					//overlay.removeEventListener("click", hide, false);
					psPlugin.shell.jsExt.removeEventListener("click", document.body, handleHide, false);
					if (options.hideOnEsc) psPlugin.shell.jsExt.removeEventListener("keydown", document, escape, false);
					//if (options.hideOnClick) document.body.removeEventListener("click", hide, false);

				};


				function toggle(e) {
					if (isShown) {
						hide(e);
					} else {
						show(e);
					}
				}

				function escape(e) {
					if (e.which === 27) hide();
				}

				function setHideCallback(cb) {
					options.hideCallback = cb;
				}

				function clearHideCallback() {
					options.hideCallback = null;
				}

				return {
					options: options,
					el: el,
					show: show,
					hide: hide,
					toggle: toggle,
					title: title,
					content: content,
					triggerEl: triggerEl,
					setHideCallback: setHideCallback,
					clearHideCallback: clearHideCallback
				};

			};
			var helpers = {
				renderCaseView: function (data) {
					var view = uiView.createNodes({
						reference: 'wrapper',
						tag: 'div',
						className: 'psPlugin_caseListItem',
						//title:'Click to view history',
						children: [ {
							reference: 'caseType',
							tag: 'h3',
							className: 'caseListType',
							text: data.caseType
						}, {
							reference: 'group',
							tag: 'div',
							className: 'caseListGroup',
							text: psPlugin.application.writeOutLanguage('caseListItemGroup') + ': ' + data.group
						}, {
							reference: 'timestamp',
							tag: 'div',
							className: 'caseListDate',
							text: data.created

						}]
					});
					return view;
				},
				caseView: function (caseData, visitor, popup) {
					// caseData:object
					// bannerId: "371204ca-4f08-43fd-b5fb-c1da5c96675b"
					// caseStatusId: 1
					// caseType: "1044401f-41c4-4a2b-b81a-d0a20b560abe"
					// groupId: "8f2d4912-40c9-4808-aa63-0ce5f1399045"
					// id: "D74C4395-868B-4A05-B553-83CF41F498BA"
					// isIdentified: 1
					// languageIsoCode: "fc2f75ca-4086-444c-b6d4-0a2febcb468c"
					// messageText: "this is a takeover!"
					// originId: 0 <-- whether proactive or not
					// siteId: 2095473031
					// updated: 1354702101306
					// created: 1354702101306 <-- better use this for display

					//var lang = psPlugin.application.configuration.desktop.languages[caseData.languageIsoCode.toUpperCase()];
					var caseType = _find(DesktopService.configuration.customerCases, { id: caseData.type.id });
					var group = _find(DesktopService.configuration.groups, { id: caseData.groupId });

					var data = {
						caseType: caseType.name,
						created: formatDate(caseData.createdAt),
						group: group.name,
						isIdentified: !!caseData.isIdentified
					};

					var view = helpers.renderCaseView(data);

					//debug: show current caseId on :hover
					view.wrapper.title = psPlugin.application.writeOutLanguage('caseListItemId') + ':' + caseData.id;

					view.wrapper.onclick = function (evt) {
						//debug: log current caseId when showing history
						//console.log(psPlugin.application.writeOutLanguage('caseListItemId')+':', caseData.id);

						//hide popup if clicking on the same item
						if (currentCase === view.wrapper) {
							currentCase = false;
							//console.warn('popup', popup);
							popup.hide();
							return false;
						}
						//Keep a reference to currentCaseItem. TODO:refactor
						currentCase = view.wrapper;


						popup.content('<span class="spinner">' + psPlugin.application.writeOutLanguage('previousCasePanelLoading') + '</span>');
						popup.title(data.caseType);
						popup.show(view.wrapper, evt);

						visitor.identity.getMessageOfCase(caseData.id).then(function(response) {
							if (typeof response === 'object') {
								var elem = document.createElement('div');
								if (response.messages.length === 0) {

									//set emptyClass on current case:
									if (currentCase) { currentCase.className += ' psPlugin_caseListItem-empty'; }

									elem.innerHTML = '<div class="psPlugin_chatMsg_comment psPlugin_chatMsg"><br>' + psPlugin.application.writeOutLanguage('previousCasePanelNoData') + '</div>';
								} else {
									i18nLoader(Session.user.profile.language, function(i18n) {
										const locale = (Array.isArray(i18n.locales) ? i18n.locales[0] : i18n.locales);
										ReactDOM.render(<IntlProvider locale={locale} messages={i18n.messages}><MessageCaseHistoryContainer key={Date.now()} chatMessages={response.messages} participants={response.participants} /></IntlProvider>, elem);
										visitor.reactComponents = visitor.reactComponents || [];
										visitor.reactComponents.push(elem);
									});

									popup.setHideCallback(function() {
										ReactDOM.unmountComponentAtNode(elem);
										popup.clearHideCallback();
									});

								}

								popup.content(elem.innerHTML);

							} else {
								//console.log('No messages');
							}
						});

						return false;

					};
					this.wrapper = view.wrapper;
				},
				renderCaseList: function (parent, data, visitor, popup) {
					parent.innerHTML = '';
					var listContainer = document.createElement('div');
					var i = 0;
					while (i < data.length) {
						var item = new this.caseView(data[i].data, visitor, popup);
						listContainer.appendChild(item.wrapper);
						i++;
					}
					parent.appendChild(listContainer);
				}
			};

			psCase.listPanel = listPanel;
		}(psPlugin.application.modules.psCase));


		/* File: ~/js/modules/psCase/desktop/view.js */
		(function (psCase) {
			var panel = function (visitor) {

				var CONF = psPlugin.application.configuration.desktop.languages[visitor.properties.langID].cases;

				//DesktopService holds all cases for all sites, which is not what we want here...
				//var activeCase = new ActiveCase({}, _keys(DesktopService.configuration.customerCases));
				var activeCase = new ActiveCase({}, _keys(CONF));

				var caseConf = {};
				var panelExpanded = true;
				// parent element
				var parent = visitor.coWorker.visitorDetailsArea;
				// the visitor element
				var viewArea = new ExpandableAreaView({
					height: 800,
					heightCompact: 800,
					previewHeight: 150,
					previewHeightCompact: 0,

					startState: 'preview',
					startSize: 'compact'
				});
				var headerElem = viewArea.layout.header;
				var contentElem = viewArea.layout.content;

				var layout = uiView.createNodes({
					reference: 'wrapper',
					tag: 'div',
					className: 'psCaseWrapper transitionAll',
					children: [{
						reference: 'content',
						tag: 'div',
						className: 'psCaseContent'
					}, {
						reference: 'buttons',
						tag: 'div',
						className: 'psCaseContent'
					}, {
						reference: 'emailCheckboxWrapper',
						tag: 'div',
						className: 'psCaseContent',
						children: [{
							reference: 'emailLabel',
							tag: 'label',
							title: psPlugin.application.writeOutLanguage('caseCheckBoxEmailHistoryOnCaseCloseToolTip'),
							children: [{
								reference: 'emailCaseCheckBox',
								tag: 'input',
								type: 'checkbox',
								className: 'psCaseContent'
							}, {
								reference: 'emailCaseTitle',
								tag: 'span',
								innerHTML: psPlugin.application.writeOutLanguage('caseCheckBoxEmailHistoryOnCaseClose')
							}]
						}]
					}, {
						reference: 'formsArea',
						tag: 'div',
						className: 'psCaseContent'
					}]
				});
				var wrapper = layout.wrapper;
				var content = layout.content;
				var formsArea = layout.formsArea;

				var emailCaseCheckBox = layout.emailCaseCheckBox;
				var emailAddress = psPlugin.application.configuration.account.alwaysMailToThisEmailOnDialogClose;

				if (psPlugin.shell.jsExt.validateEmailAddress(emailAddress)) {
					emailCaseCheckBox.setAttribute('checked', 'checked');
				}

				var autoTexts = visitor.coWorker.chatModule.autoTexts;
				var autoTextIcon = visitor.coWorker.chatModule.autotextIcon;

				var buttons = layout.buttons;

				headerElem.innerHTML = psPlugin.application.writeOutLanguage('casePanelTitle');
				contentElem.appendChild(layout.wrapper);

				parent.appendChild(viewArea.layout.wrapper);

				viewArea.render();

				var logComment = function (comment) {
					ViewActions.createNoteMessage(comment,visitor.properties.sessionId);
				};
				var self = this;

				var forms = [];
				//instantiate our autotext module
				var autoText = new Autotext({
					el: autoTexts,
					triggerEl: autoTextIcon,
					offsetParentEl: '___contentSectionworkspace',
					callback: function (message) {
						psCase.visitors[visitor.properties.sessionId].coWorker.chatModule.chatInputView.setText(message);
					}
				});
				this.autoText = autoText;
				autoTextIcon.addEventListener('click', function (e) {
					autoText.toggle(e);
				}, false);
				psCase.visitors[visitor.properties.sessionId].coWorker.chatModule.chatInputView.config.onCtrlEnter = function () {
					autoTextIcon.click();
				};

				//here, caseType is undefined
				var casePrintOut = function (caseType, changed) {
					try {
						var i = 0;
						while (i < forms.length) {
							forms[i].form.close();
							i++;
						}
						forms = [];
						content.innerHTML = '';
						buttons.innerHTML = '';
						formsArea.innerHTML = '';

						var formTemplates = psPlugin.application.configuration.forms;
						var closures = psPlugin.application.configuration.closures;
						if (caseType) {
							activeCase.props.caseType = caseType.toUpperCase();
						}

						//provide fallback if caseType cannot be found for some reason
						caseConf = CONF[activeCase.props.caseType];
						if (!caseConf) {

							caseConf = {
								id: activeCase.props.caseType,
								name: activeCase.props.caseType,
								type: 'customerCase',
								section: {
									actionPanels: [],
									cannedResponses: [],
									closures: [],
									forms: []
								}
							};
						}


						// write message to chat area
						if (typeof changed !== 'undefined' && changed) {
							logComment('' + psPlugin.application.writeOutLanguage('caseCommentChangedTypeTo') + ': "' + caseConf.name + '"');
						}

						//update autotext items
						autoText.renderItems(
							caseConf.section.cannedResponses,//preDefinedTexts,
							psPlugin.application.configuration.cannedResponses
						);

						helpers.caseTypeChooser(content, activeCase.props.caseType, CONF, function (newType) {
							casePrintOut(newType, true);
						}, visitor.properties.langID.toUpperCase());


						self.closeCase = function () {
							visitor.events.onTryCaseClose.trigger();
							viewArea.setState('full');
							viewArea.render();
							closureForm.submitForm();
						};

						for (var i in caseConf.section.forms) {
							var id = caseConf.section.forms[i];
							var formObjTemplate = formTemplates[id] || false;
							if (!formObjTemplate) continue;

							if (formObjTemplate.section.template && formObjTemplate.section.template !== '') {
								var template = JSON.parse(formObjTemplate.section.template) || false;
								if (!template) continue;
							}

							var data = activeCase.props.forms[id] || {};

							var form = new helpers.caseForm(formObjTemplate, activeCase.props.forms, template, function (templateId, form, comment) {
								activeCase.props.forms = form;
								visitor.commands.saveCase.execute(activeCase.props);
								logComment(comment);
							});

							formsArea.appendChild(form.form.elem);
							forms.push(form);
						}

						var closureForm = helpers.closures(buttons, caseConf.section.closures, closures, function (data) {

							var closureOption = closures[data.closures];
							var outcome = closureOption.section.outcome;

							switch (outcome) {
								case 'positive':
								case 'negative':
								case 'neutral':
								case 'dismissed':

									// write message visitor if set in configurator
									if (typeof closureOption.section.textToVisitor !== 'undefined' || closureOption.section.textToVisitor !== '') {
										visitor.coWorker.chatModule.sendChatMessage(closureOption.section.textToVisitor);
									}

									try {
										if (emailCaseCheckBox.checked === true) {
											emailCase(outcome, closureOption.name, activeCase, formTemplates);
										}
										// write message to log
										logComment(helpers.caseToText(outcome, closureOption.name, activeCase, formTemplates));

									} catch (err) {
										//console.log('emailCase error!', err);
									}
									break;
							}

							activeCase.close(outcome, closureOption);

							//if (typeof emailCaseCheckBox.checked !== 'undefined' && emailCaseCheckBox.checked == true) emailCase();
							if (
								visitor.stateMachine.currentState !== 'InDialog' ||
								visitor.properties.connectionState === 0
							) {

								visitor.commands.EndDialog.execute();
								visitor.commands.closeCase.execute(activeCase.props);
								visitor.coWorker.panel.close();
							} else {
								visitor.coWorker.panel.setState('inactive', psPlugin.core.ui.message({
									heading: psPlugin.application.writeOutLanguage('caseMessageDialogStillActiveHeading'),
									content: '',
									icon: 'warning',
									buttons: [{
										text: psPlugin.application.writeOutLanguage('caseMessageDialogStillActiveButtonContinueWithNew'),
										onclick: function () {
											visitor.events.onOpenNewCase.trigger();
											visitor.commands.closeCase.execute(activeCase.props);
											visitor.commands.getActiveCase.execute(false);
											visitor.coWorker.panel.setState('active');
										}
									}, {
										text: psPlugin.application.writeOutLanguage('caseMessageDialogStillActiveButtonHangUp'),
										onclick: function () {
											visitor.commands.EndDialog.execute();
											visitor.commands.closeCase.execute(activeCase.props);
											//TODO: should probably call one of the events for visitor object instead.
											visitor.coWorker.panel.close();
										}
									}]
								}));
							}

						}, function (data) {
							// onValidate
							//console.log('CLOSURE FORM ON VALIDATE!', data);

							if (typeof data.closures === 'undefined') {
								return false;
							}
							var closureOption = closures[data.closures];
							var outcome = closureOption.section.outcome;
							if (outcome === 'positive') {
								while (i < forms.length) {
									if (!forms[i].form.submitForm()) {
										return false;
									}
									i++;
								}
								return true;
							} else {
								return true;
							}
						});


						var emailCase = function (outcome, closureOptionName, activeCase, formTemplates) {
							const userEmail = User.props.email;
							const accountEmail = psPlugin.application.configuration.account.alwaysMailToThisEmailOnDialogClose;
							const mailTo = (psPlugin.shell.jsExt.validateEmailAddress(accountEmail)) ? accountEmail : userEmail;

							ViewActions.emailConversationTranscript(visitor.properties.sessionId, mailTo);

							// write message to log
							var comment = '' + psPlugin.application.writeOutLanguage('caseCommentEmailSentTo') + ': ' + mailTo;
							logComment(comment);
						};

						helpers.actionPanels(caseConf.section.actionPanels, visitor.properties.langID.toUpperCase(), visitor.coWorker);
					} catch (err) {
						console.error(err);
					}
				};


				// save for unsubscription array;
				var eventSubscriptions = [];

				visitor.events.onGetActiveCase.subscribe('psCase', (function (psCase) {
					try {
						delete psCase.data.customerCase['$id'];
						activeCase.assign(psCase.data.customerCase);
						//visitor.coWorker.setTitle(undefined, activeCase.props.customerCaseData.message);

						casePrintOut(activeCase.props.caseType);
					} catch (err) {
						console.error(err);
					}
				}));

				eventSubscriptions.push('onGetActiveCase');

				// on coworker panel maximize
				visitor.events.onCoWorkerMaximize.subscribe('psCase', (function () {
					viewArea.setSize('normal');
					viewArea.render();
				}));
				eventSubscriptions.push('onCoWorkerMaximize');

				// on coworker panel minimize
				visitor.events.onCoWorkerMinimize.subscribe('psCase', (function () {
					viewArea.setSize('compact');
					viewArea.render();
				}));
				eventSubscriptions.push('onCoWorkerMinimize');

				/*
				 * --------------------------------------------------
				 * On first run
				 * --------------------------------------------------
				 */
				this.caseList = new psCase.listPanel(visitor);
				this.activeCase = activeCase;
			};


			var helpers = {
				caseForm: function (formObjTemplate, data, template, callback) {
					var printAsText = function () {
						return form.getAsHtml();
					};
					var form = new psForm({
						id: 'caseForm' + formObjTemplate.id,
						template: template,
						buttonText: psPlugin.application.writeOutLanguage('caseFormValidateButton'),
						data: data,
						callback: function (data) {
							//console.log(data);
							var comment = '' + psPlugin.application.writeOutLanguage('caseCommentFormValidated') + ':\n\n' + printAsText();

							callback(formObjTemplate.id, data, comment);
						}
					});
					this.form = form;
					this.printAsText = printAsText;
				},
				caseToText: function (outcome, closureOption) {
					var message = '' + psPlugin.application.writeOutLanguage('caseCommentClosedAs') + ' "' + closureOption + ' (' + outcome + ')"';
					return message;
				},

				caseTypeChooser: function (elem, caseType, caseTypes, onchange, langId) {

					var chooser = document.createElement('select');

					if (caseType){
						caseType = caseType.toUpperCase();
					}

					chooser.onchange = function () {
						onchange(this.value);
					};

					chooser.className = 'psCaseTypeChooser';
					chooser.title = psPlugin.application.writeOutLanguage('caseTypeChooserTitle');

					for (var t in caseTypes) {
						var option,
							text = caseTypes[t] && caseTypes[t].name || t;

						if ( !this.isCaseAvailableForUser(t, langId) ) {
							continue;
						}

						option = document.createElement('option');
						option.value = t;
						option.text = text;

						if (t.toUpperCase() === caseType) {
							option.selected = true;
						}

						chooser.appendChild(option);
					}

					elem.appendChild(chooser);

				},
				isCaseAvailableForUser: function (caseId, langId) {
					//console.log('caseId: ', caseId);
					try {
						var valid = false;
						var availableForGroups = psPlugin.application.visitorFilter.availableForGroups;
						var i = 0;
						while (i < availableForGroups.length) {
							if (typeof psPlugin.application.configuration.desktop.languages[langId].groups[availableForGroups[i].group] !== 'undefined' && typeof psPlugin.application.configuration.desktop.languages[langId].groups[availableForGroups[i].group].caseTypes &&
								psPlugin.application.configuration.desktop.languages[langId].groups[availableForGroups[i].group].caseTypes.indexOf(caseId) > -1
							) {
								return true;
							}
							i++;
						}
						return false;
					} catch (err) {
						//console.log(err);
						return true;
					}
				},
				closures: function (elem, closures, closuresData, onchange, onValidate) {

					closures = closures || [];

					// Always append a 'dismiss' closure to the end of the list:
					closures = closures.concat('dismiss');

					closuresData['dismiss'] = {
						name: psPlugin.application.writeOutLanguage('caseDismiss'),
						section: {outcome: 'dismissed'}
					};


					var formTemplate = {
						name: 'closures',
						type: 'radioGroup',
						elementStack: 'vertical',
						required: 'required',
						errorMessage: {
							header: psPlugin.application.writeOutLanguage('caseClosureErrorHeader'),
							content: psPlugin.application.writeOutLanguage('caseClosureError')
						},
						title: psPlugin.application.writeOutLanguage('caseCloseTitle'),
						childElements: []
					};

					_each(closures, function (id) {
						var opt = closuresData[id];
						if (opt) {
							formTemplate.childElements.push({value: id, label: opt.name});
						}
					});

					var form = new psForm({
						id: 'closureForm' + psPlugin.shell.jsExt.guid(),
						template: [formTemplate],
						buttonText: '',
						data: {},
						callback: function (data) {
							//console.log(data);
							onchange(data, false);
						},
						onValidate: function (data) {
							return onValidate(data);
						}
					});
					elem.appendChild(form.elem);
					return form;

				},
				actionPanels: function (panels, language, coWorker) {
					var panelsToRemove = coWorker.actionPanels,
						panel;

					for (var p in panelsToRemove) {
						panelsToRemove[p].close();
					}

					if (panels && panels.length){
						panels.forEach(function (panelId) {
							panel = psPlugin.application.configuration.actionPanels[panelId];
							if (panel) {
								coWorker.addActionPanel(panel);
							}
						});
					}
				}

			};


			var Autotext = function (options) {

				if (!options || !options.el) return;

				var el = options.el,
					offsetParentEl,
					triggerEl = options.triggerEl,
					isShown = false;

				var view = (function () {
					return uiView.createNodes({
						reference: 'wrapper',
						tag: 'div',
						className: 'popover left',
						children: [
							{
								reference: 'title',
								tag: 'h3',
								className: 'popover-title',
								innerHTML: psPlugin.application.writeOutLanguage('caseAutoTextPanelHeader')
							},
							{
								reference: 'arrow',
								tag: 'div',
								className: 'arrow'
							},
							{
								reference: 'inner',
								tag: 'div',
								className: 'popover-inner',
								children: [{
									reference: 'content',
									tag: 'div',
									className: 'popover-content'
								}]
							}]
					});
				})();

				el.parentNode.replaceChild(view.wrapper, el);
				el = view.wrapper;


				// fixes a glitch in the offset calculation.
				var bodyOffset = psPlugin.shell.jsExt.findPos(document.getElementById('___contentSectionworkspace'))[1]; // 47px


				var show = function show(e) {

					if (isShown) return;

					el.style.display = 'block';
					isShown = true;

					var triggerEl = (e) ? e.target: triggerEl,
						maxH = window.innerHeight,
						pos = psPlugin.shell.jsExt.findPos(triggerEl),
						y = pos[1],
						triggerY = Math.round(triggerEl.offsetHeight / 2),
						height = el.offsetHeight,
						parentHeight = document.body.offsetHeight - 100,
						centeredY = y + triggerY - (height / 2);


					if (centeredY < bodyOffset) centeredY = bodyOffset;

					view.content.style.maxHeight = (maxH > 400) ? 400 + 'px': maxH + 'px';

					if (height > parentHeight) {
						el.style.top = '0px';
						view.arrow.style.top = y + 'px';
					} else {
						el.style.top = centeredY - bodyOffset + 'px';
						view.arrow.style.top = y - centeredY + 'px';
					}

					//add listener for ESC to close
					document.addEventListener("keydown", escape, false);

					//delay onclick event a bit so we don't trigger it with the propagating click from triggerEl
					setTimeout(function () {
						document.body.addEventListener("click", hide, false);
					}, 100);
				};

				var hide = function hide() {
					if (!isShown) return;
					el.style.display = 'none';
					isShown = false;

					document.removeEventListener("keydown", escape, false);
					document.body.removeEventListener("click", hide, false);
				};

				function toggle(e) {
					if (isShown) {
						hide(e);
					} else {
						show(e);
					}
				}

				function escape(e) {
					if (e.which === 27) hide();
				}


				//helper for event delegation. Walk up the DOM until the correct node is matched
				function getAncestor(node, tagName) {
					tagName = tagName.toUpperCase();
					while (node) {
						if (node.nodeType === 1 && node.nodeName === tagName) {
							return node;
						}
						node = node.parentNode;
					}
					return null;
				}

				//only bind click events on list autotext items if we have a callback
				if (typeof options.callback === 'function') {
					el.addEventListener("click", function (e) {
						var target = getAncestor(e.target, "li");
						if (target !== null) {
							options.callback(decodeURIComponent(target.getAttribute('data-txt')));
						}
						hide();
					});
				}

				function renderItems(cannedResponses, collection) {
					var html = [], txt, msg, intro,
						sortedCollection = {};

					_each(cannedResponses, function (cId) {
						sortedCollection[cId] = collection[cId];
					});

					sortedCollection = _sortBy(sortedCollection, 'name');

					_each(sortedCollection, function (txt) {
						if (txt.section.message) {
							msg = txt.section.message;
							intro = (msg.length > 140) ? msg.substring(0, 140) + ' ...' : msg;
							intro = psPlugin.core.jsExt.htmlEncode(intro);

							html.push(
								'<li class="canned-response" data-txt="' + encodeURIComponent(msg) + '">' +
								'<strong>' + txt.name + ': </strong>' + (txt.description || '') +
								'<p>' + intro + '</p>' +
								'</li>'
							);
						}
					});


					if (html.length < 1) {
						view.content.innerHTML = '<div class="popover-msg">' + psPlugin.application.writeOutLanguage('caseAutoTextNotPresent') + '.</div>';
					} else {
						view.content.innerHTML = '<ul class="list">' + html.join('') + '</ul>';
					}

				}


				return {
					el: el,
					show: show,
					hide: hide,
					toggle: toggle,
					triggerEl: triggerEl,
					renderItems: renderItems
				};
			};


			psCase.panel = panel;
		}(psPlugin.application.modules.psCase));

		psPlugin.application.modules.pdfCapture = pdfCapture;


		psPlugin.application.start();
	}]
