import CSDispatcher from '../dispatcher/CSDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
//import ConversationsStore from '../stores/ConversationsStore';

const ActionTypes = AppConstants.ActionTypes;

export default {

	receiveQueue (visitors) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.RECEIVE_VISITORS,
			visitors: visitors
		});
	},

	receiveAsState (conversationState, conversationId) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.RECEIVE_AS_STATE,
			conversationState: conversationState,
			conversationId: conversationId
		});
	},

	receiveSince (conversationEvents, conversationId) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.RECEIVE_SINCE,
			rawMessages: conversationEvents,
			conversationId: conversationId
		});
	},

	processConversations (conversations) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.PROCESS_CONVERSATIONS,
			conversations: conversations
		});
	},

	clearConversations () {
		CSDispatcher.handleServerAction({
			type: ActionTypes.CLEAR_CONVERSATIONS
		});
	},

	addConversation (conversationId) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.ADD_CONVERSATION,
			conversationId: conversationId
		});
	},

	removeConversation (conversationId) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.REMOVE_CONVERSATION,
			conversationId: conversationId
		});
	},

	updateConversation (conversationId, propsToSet) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.UPDATE_CONVERSATION,
			conversationId: conversationId,
			propsToSet : propsToSet
		});
	},

	receiveBrowserInfo (conversationId, browserInfo) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.VISITOR_RESIZE,
			conversationId: conversationId,
			browserInfo: {
				width: browserInfo.width,
				height: browserInfo.height,
				hasFlash: browserInfo.hasFlash
			}
		});
	},

	selectVisitor (visitorId) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.VISITOR_SELECTED,
			visitorId: visitorId
		});
	},

	lockVisitor (visitorId) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.VISITOR_LOCKED,
			visitorId: visitorId
		});
	},

	caseClosed (conversationId) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.CASE_CLOSED,
			conversationId: conversationId
		});

		this.removeConversation(conversationId);
	},

	conversationClosed (conversationId) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.CONVERSATION_CLOSED,
			conversationId: conversationId
		});

	},

	conversationSuspended (conversationId) {
		this.updateConversation(conversationId, {initialized: false, since: new Date('1970').toString()});
		//ConversationsStore.setProperties(conversationId, {initialized: false, since: new Date('1970').toString()});
	},

	requestError (conversationId, statusCode, errorCode) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.REQUEST_ERROR,
			conversationId: conversationId,
			statusCode: statusCode,
			errorCode: errorCode
		})
	},

	visitorProfileAdd (visitId) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.VISITOR_PROFILE_ADD,
			visitId: visitId
		});
	},

	visitorProfileFetched (profile) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.VISITOR_PROFILE,
			profile: profile
		});
	},

	groupStatusUpdate (availableGroups) {
		CSDispatcher.handleServerAction({
			type: ActionTypes.GROUP_STATUS_UPDATED,
			availableGroups: availableGroups
		})
	}
};
