import './myHistory/myHistoryModule';
import './reservations/reservationsModule';
//import './openingHours/openingHourModule';
import './holidays/holidaysModule';

import calendarTranslations from './CalendarTranslations';
import CalendarContentTemplate from './CalendarContentTemplate.html';
import CalendarMenuTemplate from './CalendarMenuTemplate.html';
import '../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.calendar', [
        'ui.router',
        'vngageApp.calendar.reservations',
//        'vngageApp.calendar.openingHour',
        'vngageApp.calendar.holidays',
        'vngageApp.calendar.myHistory',
	    'vngageApp.workspaceTranslation'
    ])

    .constant('calendarTranslations', calendarTranslations)

    .constant('calendarModuleConfig', {
        state: 'root.calendar',
        //host:'https://fk-bokning.vergic.com',
        host:'http://localhost:8081',
        hash:'e9115ed612a8f94adf0885906cf1c916224af6f1',
        //defaultGroupId:'D78D3FC0-26B8-4311-A069-5539B82D24E6' // FK live
        //defaultGroupId:'85090669-7549-4200-BB72-58AD6F0885E6' // vergic test account group
    })
    .directive('jsonText', function() {
        return {
            restrict: 'A', // only activate on element attribute
            require: 'ngModel', // get a hold of NgModelController
            link: function(scope, element, attrs, ngModelCtrl) {

                var lastValid;

                // push() if faster than unshift(), and avail. in IE8 and earlier (unshift isn't)
                ngModelCtrl.$parsers.push(fromUser);
                ngModelCtrl.$formatters.push(toUser);

                // clear any invalid changes on blur
                element.bind('blur', function() {
                    element.val(toUser(scope.$eval(attrs.ngModel)));
                });

                // $watch(attrs.ngModel) wouldn't work if this directive created a new scope;
                // see http://stackoverflow.com/questions/14693052/watch-ngmodel-from-inside-directive-using-isolate-scope how to do it then
                scope.$watch(attrs.ngModel, function(newValue, oldValue) {
                    lastValid = lastValid || newValue;

                    if (newValue != oldValue) {
                        ngModelCtrl.$setViewValue(toUser(newValue));

                        // TODO avoid this causing the focus of the input to be lost..
                        ngModelCtrl.$render();
                    }
                }, true); // MUST use objectEquality (true) here, for some reason..

                function fromUser(text) {
                    // Beware: trim() is not available in old browsers
                    if (!text || text.trim() === '') {
                        return {};
                    } else {
                        try {
                            lastValid = angular.fromJson(text);
                            ngModelCtrl.$setValidity('invalidJson', true);
                        } catch (e) {
                            ngModelCtrl.$setValidity('invalidJson', false);
                        }
                        return lastValid;
                    }
                }

                function toUser(object) {
                    // better than JSON.stringify(), because it formats + filters $$hashKey etc.
                    return angular.toJson(object, true);
                }
            }
        };
    })

    .config(function ($stateProvider, $urlRouterProvider, $translateProvider, calendarTranslations, calendarModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';

		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(calendarTranslations);

       //$urlRouterProvider.when('/calendar/', function (transitionService, reservationsModuleConfig,openingHourModuleConfig,holidaysModuleConfig,myHistoryModuleConfig){
        $urlRouterProvider.when('/calendar/', function (transitionService, reservationsModuleConfig,holidaysModuleConfig,myHistoryModuleConfig) {
			'ngInject';
            transitionService.navigate([
                reservationsModuleConfig,
                //openingHourModuleConfig,
                holidaysModuleConfig,
                myHistoryModuleConfig
            ]);
        });

        $stateProvider.state(calendarModuleConfig.state, {
            url: '/calendar/',
            views: {
                content: {
                    templateUrl: CalendarContentTemplate
                },
                menu: {
                    templateUrl: CalendarMenuTemplate
                }
            },
            data: {
                ncyBreadcrumbLabel: 'Calendar'
            }
        });
    });
