import React from 'react';
import {Button} from 'react-bootstrap';

const SmallButton = ({title, callback}) => (
	<Button
		variant="primary"
		size="xs"
		onClick={() => {
			callback();
		}}>
		{title}
	</Button>
);

export default SmallButton;
