import {getItemNavigationDataAfterRemove} from '../../../../common/UiHelpers';

export default function ($stateParams, group, groupType, VngageUtil, $state, timeZones, vngageRemoveModalService, features) {
	'ngInject';
	const vm = this;
	vm.timeZones = timeZones;
	vm.group = group;
	vm.groupType = groupType;
	vm.submit = submit;
	vm.remove = remove;
	vm.queueEnabled = group.data.maxVisitorsPerUserOnline > 0;
	vm.currentList = $stateParams.currentList || [];

	vm.onToggleQueueEnabled = function () {
		vm.queueEnabled ?
			group.data.maxVisitorsPerUserOnline = 1:
			group.data.maxVisitorsPerUserOnline = 0;
	};

	vm.availableRoutingTypes = [
		{value: 0, name: 'Manual - Pick from the queue'},
		{value: 1, name: 'Automatic - Round-robin distribution'},
		{value: 3, name: 'Automatic - Assign max interactions per agent distribution'}, //Fill agent quota first
		{value: 2, name: 'External - Externally started'},
		{value: 4, name: 'External - Started in Desktop'}
	];

	vm.availableReservationBehaviours = [
		{value: 1, name: 'Normal'},
		{value: 2, name: 'Auto start'}
	];

	function submit() {
		vm.isSaving = true;
		vm.group.$save().finally(function () {
			vm.isSaving = false;
		});
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: group,
			itemType: 'group'
		}).then(function () {
			if (features) {
				// if features exist remove them too
				features.$remove().finally(() => {
					vm.isSaving = false;
					vm.groupEditForm.$setPristine();
					const itemNavigationData = getItemNavigationDataAfterRemove(group, 'root.configure.group.edit', vm.currentList);
					vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
					$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
					// $state.go('root.configure.group', null, {reload: true});
				});
			} else {
				vm.isSaving = false;
				vm.groupEditForm.$setPristine();
				const itemNavigationData = getItemNavigationDataAfterRemove(group, 'root.configure.group.edit', vm.currentList);
				vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
				$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
				// $state.go('root.configure.group', null, {reload: true});
			}
		}).catch(function () {
			vm.isSaving = false;
		});
	}
};
