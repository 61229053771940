import React from "react";
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';

const ToolBarButton = (props) => {
	const toggleSection = () => {
		const {
				  sectionName,
				  disabled,
				  toggleSection
			  } = props;

		if (!disabled) {
			toggleSection(sectionName);
		}
	};

	const {
			  active,
			  disabled,
			  icon,
			  hasWarningSign,
		  } = props;

	const activeCls = active ? ' selected': '';
	const disabledCls = disabled ? ' disabled': '';
	const cls = `toolbar-btn${activeCls}${disabledCls}`;

	const warning = hasWarningSign
		? <div className="vngage-icon-attention"/>
		: '';
	const tooltip = (
		<Tooltip id="toolbar-tooltip">
			{props.tooltipText}
		</Tooltip>);

	// ub-track
	const ubData = `toggle-section:${props.sectionName}-${!active ? 'open': 'close'}`;

	return (
		<OverlayTrigger placement="bottom" overlay={tooltip} delay={400}>
			<div className="toolbar-btn-container" onClick={toggleSection}>
				{warning}
				<Button size="sm" tabIndex="-1" className={cls} ub-track={ubData} variant="outline-secondary">
					<i className={icon} ub-track={ubData}/>
				</Button>
			</div>
		</OverlayTrigger>
	);
};

export default ToolBarButton;
