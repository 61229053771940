import moment from 'moment-timezone';
import _get from 'lodash/get';

export default function ($scope, uiCalendarConfig, holidaysService, $uibModal, $state, holidaysConfigModalService, APIEndpointService, usSpinnerService, Session) {
	'ngInject';

	var vm = this;
	vm.workspaceLang = _get(Session, 'user.profile.language', 'en').split('-')[0];
	vm.unsaveData = false;
	vm.model = {};

	var eventClickHandler = function (event) {
		viewDay(event);
	};
	var dayClickHandler = function (event) {
		viewDay({
			start: moment.utc(event.format()).startOf('day'),
			end: moment.utc(event.format()).endOf('day')
		});
	};
	function viewDay(event) {
		vm.model.item = event;
		holidaysConfigModalService.open({
			newHoliday: !event.id,
			model: vm.model,
			refetchEvents: () => {
				setTimeout(() => {
					uiCalendarConfig.calendars.holidayCalendar.fullCalendar('refetchEvents');
				}, 500);
			}
		}).catch(() => {
			// Should not happen since we never dismiss the modal, but catch for good practice...
		});
	}

	vm.uiConfig = {
		// todo why is the calendar not responding to height?
		calendar: {
			lang: vm.workspaceLang,
			locale: vm.workspaceLang,
			height: 'auto',
			firstDay: 1,
			minTime: moment.duration('08:00:00'),
			maxTime: moment.duration('17:00:00'),
			defaultView: 'month',
			header: {
				left: 'title',
				right: 'today prev,next'
			},
			dayClick: dayClickHandler,
			eventClick: eventClickHandler,
			displayEventTime: false,
			slotEventOverlap: false,
			allDaySlot: false,
			slotLabelFormat: 'H:mm',
			views: {
				day: {
					timeFormat: 'H:mm'
				},
				week: {
					timeFormat: 'H:mm',
					columnFormat: 'dddd'
				}
			}
		}
	};

	vm.calendarEventSources = {
		holidays: {
			stick: true,
			color: '#F2DADA',
			textColor: '#5E1919',
			displayEventTime: false,
			events: function (start, end, timezone, callback) {
				holidaysService.queryHolidays({
					range: {
						from: start,
						to: end
					}
				}).then(function (res) {
					var events = res;
					events.forEach(function (e) {
						var start = e.start;
						e.start = moment.utc(start).startOf('day');
						e.end = moment.utc(start).endOf('day');
						e.textColor = '#fff';
					});
					callback(events);
				});
			}
		}
	};
	vm.calendarEvents = [vm.calendarEventSources.holidays];
};
