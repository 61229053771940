import _find from '../../../../node_modules/lodash/find';
import moment from 'moment-timezone';

export default function (d3, SessionService) {
	'ngInject';

	var ranges;

	var service = {
		create: create
	};

	//var browserOffset = new Date().getTimezoneOffset();
	//var userOffset = SessionService.getUserTimezoneOffset();

	function create(rangeType) {
		var found = _find(ranges, function (rangeTypeHandler) {
			return rangeTypeHandler.canHandle(rangeType);
		});

		return found.create();
	}

	var formatWithTime = 'MMM DD ddd HH:mm';
	var formatWithoutTime = 'MMM DD ddd';

	var hour = {
		units: d3.time.hour,
		ticks: 7,
		format: d3.time.format('%a %H:%M')
	};

	var day = {
		units: d3.time.day,
		ticks: 7,
		format: d3.time.format('%a %d')
	};

	var week = {
		units: d3.time.day,
		ticks: 7,
		format: d3.time.format('%b %d')
	};

	function createScale(dimension) {
		var top = dimension.top(1)[0];
		var bottom = dimension.bottom(1)[0];

		if (!top || !bottom) {
			return d3.time.scale().domain([]);
		}

		return d3.time.scale().domain([parseDate(top.calculatedAt), parseDate(bottom.calculatedAt)]);
	}

	function parseDate(date) {
		return moment(date).tz(SessionService.getUserTimezone()); // ._d ? //.utcOffset(userOffset).add(browserOffset, 'm')._d;
	}

	var todayRange = {
		canHandle: function (period) {
			return period === 'today';
		},

		create: function () {

			return {
				to: function () {
					return moment().utc().tz(SessionService.getUserTimezone()).format(); //.utcOffset(userOffset).utc().format();
				},

				from: function () {
					return moment().utc().tz(SessionService.getUserTimezone()).startOf('day').format(); //.utcOffset(userOffset).startOf('day').utc().format();
				},

				axis: hour,
				formatDate: formatWithTime,
				parseDate: parseDate,
				createScale: createScale
			};

		}
	};

	var yesterdayRange = {
		canHandle: function (period) {
			return period === 'yesterday';
		},

		create: function () {

			return {
				to: function () {
					return moment().tz(SessionService.getUserTimezone()).subtract('days', 1).endOf('day').utc().format(); //.utcOffset(userOffset).subtract('days', 1).endOf('day').utc().format();
				},

				from: function () {
					return moment().tz(SessionService.getUserTimezone()).startOf('day').subtract('days', 1).utc().format(); //.utcOffset(userOffset).startOf('day').subtract('days', 1).utc().format();
				},

				axis: hour,
				formatDate: formatWithTime,
				parseDate: parseDate,
				createScale: createScale
			};

		}
	};

	var twoDaysAgoRange = {
		canHandle: function (period) {
			return period === 'twoDaysAgo';
		},

		create: function () {

			return {
				to: function () {
					return moment().tz(SessionService.getUserTimezone()).subtract('days', 2).endOf('day').utc().format(); //.utcOffset(userOffset).subtract('days', 2).endOf('day').utc().format();
				},

				from: function () {
					return moment().tz(SessionService.getUserTimezone()).startOf('day').subtract('days', 2).utc().format(); //.utcOffset(userOffset).startOf('day').subtract('days', 2).utc().format();
				},

				axis: hour,
				formatDate: formatWithTime,
				parseDate: parseDate,
				createScale: createScale
			};

		}
	};

	var threeDaysAgoRange = {
		canHandle: function (period) {
			return period === 'threeDaysAgo';
		},

		create: function () {

			return {
				to: function () {
					return moment().tz(SessionService.getUserTimezone()).subtract('days', 3).endOf('day').utc().format(); //.utcOffset(userOffset).subtract('days', 3).endOf('day').utc().format();
				},

				from: function () {
					return moment().tz(SessionService.getUserTimezone()).startOf('day').subtract('days', 3).utc().format(); //.utcOffset(userOffset).startOf('day').subtract('days', 3).utc().format();
				},

				axis: hour,
				formatDate: formatWithTime,
				parseDate: parseDate,
				createScale: createScale
			};

		}
	};

	var thisWeekRange = {
		canHandle: function (period) {
			return period === 'thisWeek';
		},

		create: function () {

			return {
				to: function () {
					return moment().utc().tz(SessionService.getUserTimezone()).format(); //.utcOffset(userOffset).utc().format();
				},

				from: function () {
					return moment().utc().tz(SessionService.getUserTimezone()).startOf('isoweek').format(); //.utcOffset(userOffset).startOf('isoweek').utc().format();
				},

				axis: day,
				formatDate: formatWithoutTime,
				parseDate: parseDate,
				createScale: createScale
			};

		}
	};

	var lastWeekRange = {

		canHandle: function (period) {
			return period === 'lastWeek';
		},

		create: function () {

			return {
				to: function () {
					return moment().tz(SessionService.getUserTimezone()).subtract('week', 1).endOf('isoweek').utc().format(); //.utcOffset(userOffset).subtract('week', 1).endOf('isoweek').utc().format();
				},

				from: function () {
					return moment().tz(SessionService.getUserTimezone()).subtract('week', 1).startOf('isoweek').utc().format(); //.utcOffset(userOffset).subtract('week', 1).startOf('isoweek').utc().format();
				},

				axis: day,
				formatDate: formatWithoutTime,
				parseDate: parseDate,
				createScale: createScale
			};

		}
	};

	var sevenDaysRange = {

		canHandle: function (period) {
			return period === 'sevenDays';
		},

		create: function () {

			return {
				to: function () {
					return moment().tz(SessionService.getUserTimezone()).utc().format(); //.utcOffset(userOffset).utc().format();
				},

				from: function () {
					return moment().tz(SessionService.getUserTimezone()).subtract('days', 7).utc().format(); //.utcOffset(userOffset).subtract('days', 7).utc().format();
				},

				axis: day,
				formatDate: formatWithoutTime,
				parseDate: parseDate,
				createScale: createScale
			};

		}
	};

	var thirtyDaysRange = {

		canHandle: function (period) {
			return period === 'thirtyDays';
		},

		create: function () {

			return {
				to: function () {
					return moment().tz(SessionService.getUserTimezone()).utc().format(); //.utcOffset(userOffset).utc().format();
				},

				from: function () {
					return moment().tz(SessionService.getUserTimezone()).subtract('days', 30).utc().format(); //.utcOffset(userOffset).subtract('days', 30).utc().format();
				},

				axis: day,
				formatDate: formatWithoutTime,
				parseDate: parseDate,
				createScale: createScale
			};

		}
	};

	var thisMonthRange = {

		canHandle: function (period) {
			return period === 'thisMonth';
		},

		create: function () {

			return {
				to: function () {
					return moment().utc().tz(SessionService.getUserTimezone()).format(); //.utcOffset(userOffset).utc().format();
				},

				from: function () {
					return moment().utc().tz(SessionService.getUserTimezone()).startOf('month').format(); //.utcOffset(userOffset).startOf('month').utc().format();
				},

				axis: week,
				formatDate: formatWithoutTime,
				parseDate: parseDate,
				createScale: createScale
			};

		}
	};

	var lastMonthRange = {

		canHandle: function (period) {
			return period === 'lastMonth';
		},

		create: function () {

			return {
				to: function () {
					return moment().utc().tz(SessionService.getUserTimezone()).subtract('month', 1).endOf('month').format(); //.utcOffset(userOffset).subtract('month', 1).endOf('month').utc().format();
				},

				from: function () {
					return moment().utc().tz(SessionService.getUserTimezone()).subtract('month', 1).startOf('month').format(); //.utcOffset(userOffset).subtract('month', 1).startOf('month').utc().format();
				},

				axis: week,
				formatDate: formatWithoutTime,
				parseDate: parseDate,
				createScale: createScale
			};

		}
	};

	function init() {
		ranges = [
			todayRange,
			yesterdayRange,
			twoDaysAgoRange,
			threeDaysAgoRange,
			thisWeekRange,
			lastWeekRange,
			thisMonthRange,
			lastMonthRange,
			sevenDaysRange,
			thirtyDaysRange
		];
	}

	init();

	return service;
};
