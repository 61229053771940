import CaseTypeAddModalController from './CaseTypeAddModalController';
import ImportModalController from '../../../common/vngageImportModal/ImportModalController';
import CaseTypeEditController from './CaseTypeEditController';
import CaseTypeListController from './CaseTypeListController';
import AccountCaseTypesService from './CaseTypeService';
import caseTypeTranslations from './CaseTypeTranslations';
import CaseTypeListTemplate from './CaseTypeListTemplate';
import CaseTypeEditTemplate from './CaseTypeEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.configure.caseType', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('CaseTypeAddModalController', CaseTypeAddModalController)
	.controller('ImportModalController', ImportModalController)
	.controller('CaseTypeEditController', CaseTypeEditController)
	.controller('CaseTypeListController', CaseTypeListController)
	.factory('AccountCaseTypesService', AccountCaseTypesService)
	.constant('caseTypeTranslations', caseTypeTranslations)

	.constant('caseTypeModuleConfig', {
		state: 'root.configure.caseType',
		access: 'Engage/CaseTypes'
	})

	.config(function ($stateProvider, $translateProvider, caseTypeTranslations, caseTypeModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(caseTypeTranslations);

		$stateProvider.state(caseTypeModuleConfig.state, {
			url: 'casetype/',
			controller: 'CaseTypeListController as vm',
			templateUrl: CaseTypeListTemplate,
			data: {
				access: caseTypeModuleConfig.access,
				ncyBreadcrumbLabel: 'Case Types'
			},
			resolve: {
				dataLists: function (APIConfigurationSectionService, $q) {
					'ngInject';
					return $q((resolve, reject) => {
						$q.all({
							configSections: APIConfigurationSectionService.get([
								APIConfigurationSectionService.sectionMap.closure,
								APIConfigurationSectionService.sectionMap.actionPanel,
								APIConfigurationSectionService.sectionMap.cannedResponse,
								APIConfigurationSectionService.sectionMap.customerCase
							], true)
						}).then((result) => {
							resolve({
								closure: result.configSections[APIConfigurationSectionService.sectionMap.closure],
								actionPanel: result.configSections[APIConfigurationSectionService.sectionMap.actionPanel],
								cannedResponse: result.configSections[APIConfigurationSectionService.sectionMap.cannedResponse],
								customerCase: result.configSections[APIConfigurationSectionService.sectionMap.customerCase]
							});
						}, (error) => {
							console.log('Error resolving lists: ', error);
							reject(error);
						});
					});
				},
				formList: function (APIConfigurationSectionService) {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.form, true);
				}
			}
		});

		$stateProvider.state('root.configure.caseType.edit', {
			url: ':id',
			controller: 'CaseTypeEditController as vm',
			templateUrl: CaseTypeEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				caseType: function ($stateParams, dataLists, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(dataLists.customerCase, $stateParams.id);
				},
				closureList: function (dataLists) {
					'ngInject';
					return dataLists.closure;
				},
				cannedResponseList: function (dataLists) {
					'ngInject';
					return dataLists.cannedResponse;
				},
				actionPanelList: function (dataLists) {
					'ngInject';
					return dataLists.actionPanel;
				},
				formList: function (APIConfigurationSectionService) {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.form, true);
				}
			}
		});
	});
