import AppConstants from '../../../components/constants/AppConstants';

const ActionTypes = AppConstants.ActionTypes;


export default {

	addVisitorProfile(profile) {
		return {
			type: ActionTypes.VISITOR_PROFILE_ADD,
			profile
		};
	},

	setVisitorProfileNavigation(visitId, navigation) {
		return {
			type: ActionTypes.SET_VISITOR_PROFILE_NAVIGATION,
			visitId,
			navigation
		};
	},

	setClaimsPatch(visitId, fields) {
		return {
			type: ActionTypes.SET_CLAIMS_PATCH,
			visitId,
			fields
		};
	},

}
