import _debounce from 'lodash/debounce';

export default function ($timeout) {
	'ngInject';

    return {
        replace: true,
        restrict: 'A',
        require: ['^form', '^ngModel'],
        scope: {
            model: '=ngModel',
			orig: '=orig',
			collapseIdentical: '=collapseIdentical'
        },
		transclude: true,
        template: '<div></div>',
        link: function (scope, element) {

			var target = element[0];
			target.innerHTML = "";

			var config = {
				value: scope.model,
				orig: scope.orig,
				lineNumbers: true,
				mode: "javascript",
				highlightDifferences: true,
				allowEditingOriginals: false,
				connect: 'align',
				collapseIdentical: false
			}

			if (scope.collapseIdentical) {

				config.collapseIdentical = scope.collapseIdentical;
			}

			$timeout(() => {
				CodeMirror.MergeView(target, config);
			}, 0);
        }
    };
};
