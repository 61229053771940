import AppConstants from '../../../components/constants/AppConstants';

const VideoActionTypes = AppConstants.Video;

const setStartTime = time => {
	return {
		type: VideoActionTypes.SET_START_TIME,
		time
	};
};

const setRoomId = roomId => {
	return {
		type: VideoActionTypes.SET_ROOM_ID,
		roomId
	};
};

const dispose = () => {
	return {
		type: VideoActionTypes.DISPOSE
	};
};

const setPeerId = peerId => {
	return {
		type: VideoActionTypes.SET_PEER_ID,
		peerId
	};
};

const setState = state => {
	return {
		type: VideoActionTypes.SET_VIDEO_STATE,
		state
	};
};

const setError = (error, origin = '') => {
	return {
		type: VideoActionTypes.SET_VIDEO_ERROR,
		origin,
		error
	};
};

const setMicrophoneEnabled = enabled => {
	return {
		type: VideoActionTypes.SET_MICROPHONE_ENABLED,
		enabled
	};
};

const setCameraEnabled = enabled => {
	return {
		type: VideoActionTypes.SET_CAMERA_ENABLED,
		enabled
	};
};

const setComType = comType => {
	return {
		type: VideoActionTypes.SET_COM_TYPE,
		comType
	};
};

const VideoActions = {
	setStartTime,
	setRoomId,
	dispose,
	setPeerId,
	setState,
	setError,
	setMicrophoneEnabled,
	setCameraEnabled,
	setComType
};

export default VideoActions;
