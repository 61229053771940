import SiteAddModalController from './SiteAddModalController';
import SiteSettingsMigrationModalController from './SiteSettingsMigrationModalController';
import SiteEditController from './SiteEditController';
import SiteListController from './SiteListController';
import SiteService from './SiteService';
import siteTranslations from './SiteTranslations';
import SiteListTemplate from './SiteListTemplate';
import SiteEditTemplate from './SiteEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.account.site', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('SiteAddModalController', SiteAddModalController)
	.controller('SiteSettingsMigrationModalController', SiteSettingsMigrationModalController)
	.controller('SiteEditController', SiteEditController)
	.controller('SiteListController', SiteListController)
	.factory('SiteService', SiteService)
	.constant('siteTranslations', siteTranslations)

	.constant('siteModuleConfig', {
		state: 'root.account.site',
		access: 'Engage/Sites'
	})

	.config(function ($stateProvider, $translateProvider, siteTranslations, siteModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(siteTranslations);

		$stateProvider.state(siteModuleConfig.state, {
			url: 'site/',
			controller: 'SiteListController as vm',
			templateUrl: SiteListTemplate,
			data: {
				access: siteModuleConfig.access,
				ncyBreadcrumbLabel: 'Sites'
			},
			resolve: {
				siteList: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.site.query().$promise;
				},
				translationList: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.translation.query().$promise;
				}
			}
		});
		$stateProvider.state('root.account.site.edit', {
			url: ':id',
			controller: 'SiteEditController as vm',
			templateUrl: SiteEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				site: function ($stateParams, siteList, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(siteList, $stateParams.id);
				},
				availableLanguages: function (SiteService, translationList) {
					'ngInject';
					return SiteService.getLanguageSelectOptions(translationList);
				}
			}
		});
	});
