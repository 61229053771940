import React from 'react';
import ReactDOM from 'react-dom';
import MessageItem from './MessageItem.react';
import createReactClass  from 'create-react-class';

export default createReactClass({

	componentDidMount () {
		this._scrollToBottom();
		this.refs.messageList.addEventListener('scroll', (event) => {
			this.props.onScrollChange(event.currentTarget.scrollTop);
		}, false);
	},

	componentDidUpdate () {
		this._scrollToBottom();
	},

	render () {
		return (
			<div className="dialog-list-container" ref="messageList">

				<ul className="dialog-list">
					{this.props.messages.map( message => {
						return (
							<MessageItem
								key={message.id}
								message={message}
								messageType={message.messageType}
								relative={message.isMostRecentMessage}
								isMostRecentMessage={message.isMostRecentMessage}
							/>
						)
					})}
				</ul>
			</div>
		)
	},

	animate(ul) {
		let st = ul.scrollTop, sh = ul.scrollHeight;
		if(!ul) return;
		if ((Math.floor(sh - st) > ul.clientHeight)) {
			let ratio = Math.round(sh/st);
			ul.scrollTop += (10 * ratio);
			requestAnimationFrame(() => {
				this.animate(ul);
			});
		}
	},

	_scrollToBottom () {
		let ul = this.refs.messageList;
		let addToHeight = 0;

		if (!ul) return;

		let domLastInserted = ReactDOM.findDOMNode(this.refs.messageList).firstChild.lastChild;
		if (domLastInserted) {
			addToHeight = domLastInserted.offsetHeight;
		}

		// if we're at the top.. scroll down.
		if (ul.scrollTop === 0) {
			ul.scrollTop = ul.scrollHeight;
		}

		// scroll down only when in ~ bottom
		if( (ul.scrollHeight - ul.scrollTop)-(100+addToHeight) < ul.clientHeight) {
			requestAnimationFrame(() => {
				this.animate(ul);
			});
		}
	}

});
