import ConfigValidationError from './ConfigValidationError';

export default function (APIConfigurationSectionService) {
	'ngInject';

	const workspaceSectionSubTypes = ['general', 'desktopPlugins'];

	const validateSectionType = (sections, sectionType, sectionSubType) => {
		let filteredSections = APIConfigurationSectionService.filterConfigurationSections(sections, sectionType);
		const validationErrors = [];

		switch (sectionType) {
			case APIConfigurationSectionService.sectionMap.accountSettings:
			case APIConfigurationSectionService.sectionMap.panelSettings:
			case APIConfigurationSectionService.sectionMap.visitor:
				// Find missing data or data.section...
				filteredSections.forEach(section => {
					if (!section.data || !section.data.section) {
						validationErrors.push(new ConfigValidationError('invalidSectionData', sectionType, section));
					}
				});

				// Check that the config contains one (and only one) object of these section types...
				if (filteredSections.length < 1) {
					validationErrors.push(new ConfigValidationError('sectionMissing', sectionType));
				}
				if (filteredSections.length > 1) {
					validationErrors.push(new ConfigValidationError('multipleSections', sectionType));
				}
				break;
			case APIConfigurationSectionService.sectionMap.workspace:
				// Section type "workspace" is a bit special - they should also have a "data.section.sectionType"-property, and some checks depend on that...
				if (!sectionSubType) {
					// If validateSectionType() is called without "sectionSubType", perform general checks on the "workspace"-type regardless of "sectionSubType".
					// Then recursively call validateSectionType() again with all possible section sub types (handled below)
					filteredSections.forEach(section => {
						if (!section.data || !section.data.section) {
							// Find missing data or data.section...
							validationErrors.push(new ConfigValidationError('invalidSectionData', sectionType, section));
						} else {
							// Find invalid section sub types...
							if (workspaceSectionSubTypes.indexOf(section.data.section.sectionType) === -1) {
								validationErrors.push(new ConfigValidationError('invalidSectionSubType', sectionType, section));
							}
						}
					});
					workspaceSectionSubTypes.forEach(sectionSubType => {
						// Also check all subtypes and concat any errors to validationErrors...
						validationErrors.push(...validateSectionType(filteredSections, sectionType, sectionSubType).validationErrors);
					});
				} else {
					// Check a specific workspace section sub type
					filteredSections = filteredSections.filter(section => section.data && section.data.section && section.data.section.sectionType === sectionSubType);
					if (filteredSections.length > 1) {
						// We allow missing sectionSubTypes, but not multiple...
						validationErrors.push(new ConfigValidationError('multipleSections', sectionType, null, sectionSubType));
					}
				}
				break;
			default:
		}

		return {
			valid: (validationErrors.length === 0),
			validationErrors,
			sectionType,
			sectionSubType,
			filteredSections
		};
	};

	const validateResolvedSet = (configurationSet, configurationSetResolved) => {
		// Check configurationSet and configurationSetResolved...
		const errors = [];
		if (configurationSet.data.id !== configurationSetResolved.id) {
			errors.push(new ConfigValidationError('configurationSetResolvedIdMismatch', 'configurationSet', {
				configurationSet: configurationSet.data,
				configurationSetResolved: configurationSetResolved
			}));
		}
		if (configurationSet.revision !== configurationSetResolved.revision) {
			errors.push(new ConfigValidationError('configurationSetResolvedRevisionMismatch', 'configurationSet', {
				configurationSet: configurationSet.data,
				configurationSetResolved: configurationSetResolved
			}));
		}
		configurationSet.data.configurationSections.forEach((section, index) => {
			if (!configurationSetResolved.configurationSections[index] ||
				configurationSetResolved.configurationSections[index].data.id !== section.id ||
				configurationSetResolved.configurationSections[index].revision !== section.revision) {
				errors.push(new ConfigValidationError('configurationSetResolvedSectionMismatch', 'configurationSet', {
					configurationSetEntry: section,
					configurationSetResolvedEntry: configurationSetResolved.configurationSections[index]
				}));
				console.log('configurationSetResolvedSectionMismatch', section, configurationSetResolved.configurationSections[index]);
			}
		});
		// TODO: Many more set checks can be added...

		return errors;
	};

	const validateAllSections = (configurationSections, configurationSetResolved) => {
		// Check every existing section type...
		const errors = [];

		Object.values(APIConfigurationSectionService.sectionMap).forEach(sectionType => {
			const validationResult = validateSectionType(configurationSections, sectionType);
			if (!validationResult.valid) {
				console.log('invalid sectionType:', sectionType, validationResult);
				const sectionsInSet = APIConfigurationSectionService.filterConfigurationSections(configurationSetResolved.configurationSections, sectionType);
				let filteredSectionsInValidationResult = null;
				let filteredSectionsInSet = null;
				validationResult.validationErrors.forEach(validationError => {
					switch (validationError.errorType) {
						case 'invalidSectionData':
							console.error('Error: Invalid section data in:', validationError);
							if (validationError.section) {
								validationError.repairActions.push({
									action: 'delete',
									resource: validationError.section
								});
							}
							break;
						case 'sectionMissing':
							if (sectionsInSet[0]) {
								// Missing, but still present in set -  is this really an error (an "unpublished delete")?
								// For "sectionMissing"-errors, this is an error, since it should not be possible to ever delete these section types...
								// But no easy "fix" though (propose "create")...
								console.error('Error: Section type "' + sectionType + '" is missing (deleted) but exists in configurationSet:', sectionsInSet);
							} else {
								console.warn('Warning: Section type "' + sectionType + '" is missing (not yet created).');
							}
							validationError.repairActions.push({
								action: 'create'
							});
							break;
						case 'multipleSections':
							// If there is a validationError.sectionSubType, we need further filtering (due to workspace sub types)
							filteredSectionsInValidationResult = (validationError.sectionSubType ? validationResult.filteredSections.filter(section => section.data && section.data.section && section.data.section.sectionType === validationError.sectionSubType) : validationResult.filteredSections);
							filteredSectionsInSet = (validationError.sectionSubType ? sectionsInSet.filter(section => section.data && section.data.section && section.data.section.sectionType === validationError.sectionSubType) : sectionsInSet);
							if (!filteredSectionsInSet[0]) {
								console.error('Error: Multiple sections of type "' + sectionType + '" found. No section in the configurationSet', validationResult.filteredSections);
							} else if (filteredSectionsInValidationResult[0].data.id !== filteredSectionsInSet[0].data.id) {
								console.error('Error: Multiple sections of type "' + sectionType + '" found. The first section in the configurationSet is *NOT* the same!', filteredSectionsInValidationResult, filteredSectionsInSet);
							} else {
								console.warn('Warning: Multiple sections of type "' + sectionType + '". The first section in the configurationSet is the same', filteredSectionsInValidationResult, filteredSectionsInSet);
							}
							console.log('multipleSections', filteredSectionsInValidationResult, filteredSectionsInSet);
							validationError.keepId = (filteredSectionsInSet[0] || filteredSectionsInValidationResult[0]).data.id;
							filteredSectionsInValidationResult.forEach(section => {
								// On multipleSections: Keep only the first entity in resolved set (or if missing, first in sections)
								console.log('keepId:', validationError.keepId);
								if (section.data.id !== validationError.keepId) {
									console.log('delete', section);
									validationError.repairActions.push({
										action: 'delete',
										resource: section
									});
								}
							});
							break;
						case 'invalidSectionSubType':
							console.error('Error: Invalid section sub type:', validationError);
							validationError.repairActions.push({
								action: 'delete',
								resource: validationError.section
							});
							break;
						default:
					}
					errors.push(validationError);
				});
			}
		});

		return errors;
	};

	const validateScrips = (scripts, configurationSetResolved) => {
		// Check every existing section type...
		const errors = [];

		if (scripts.length > 1) {
			const validationError = new ConfigValidationError('multipleScripts', 'scripts', {
				scripts: scripts
			});

			if (configurationSetResolved) {
				// If a resolved set is also present, also add repairActions
				const scriptsInSet = configurationSetResolved.scripts;
				validationError.keepId = (scriptsInSet[0] || scripts[0]).data.id;
				scripts.forEach(script => {
					if (script.data.id !== validationError.keepId) {
						console.log('delete script', script);
						validationError.repairActions.push({
							action: 'delete',
							resource: script
						});
					}
				});
			}
			errors.push(validationError);
		}

		return errors;
	};

	const validateAll = (context) => {
		const validationErrors = [];

		// Check configurationSet and configurationSetResolved...
		// Note: Errors like these are hard/impossible to "fix" though (probably CommSrv-bugs if they occur!)
		const configurationSetErrors = validateResolvedSet(context.dependencies.configurationSet, context.dependencies.configurationSetResolved);
		if (configurationSetErrors.length > 0) {
			// ABORT if there are configurationSet <--> configurationSetResolved mismatch issues!!
			// We can't do any reliable repair if the set itself is corrupt (root cause is probably CommSrv issue that needs to be resolved first!)
			console.error('configurationSet <--> configurationSetResolved mismatch issues found - aborting!', context.dependencies);
			return;
		}
		validationErrors.push(...configurationSetErrors);

		// Check all existing configurationSection types...
		const configurationSectionErrors = validateAllSections(context.configurationDependencies.configurationSections, context.dependencies.configurationSetResolved);
		validationErrors.push(...configurationSectionErrors);

		// Check scripts...
		const scriptErrors = validateScrips(context.configurationDependencies.scripts, context.dependencies.configurationSetResolved);
		validationErrors.push(...scriptErrors);

		// TODO: Add more checks for other config types
		return validationErrors;
	};

	return {
		validateSectionType,
		validateResolvedSet,
		validateAllSections,
		validateScrips,
		validateAll
	};
}