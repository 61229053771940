import React from 'react';
import {duration} from 'moment';
import {useGetTranslation} from '../LangContext';

const VisitorBalloon = ({selectVisitor, inTransfer, durationSinceTransfer, durationSinceBegin}) => {
	const getTranslation = useGetTranslation();

	const d = inTransfer
		? durationSinceTransfer
		: durationSinceBegin;

	const sinceBeginHours = duration(d).hours() > 0 ? duration(d).hours() + 'h ': '';
	const sinceBeginMinutes = duration(d).minutes() > 0 ? duration(d).minutes() + 'm ': '';
	const sinceBeginSeconds = duration(d).seconds() + 's';

	return (
		<div onClick={selectVisitor} className="summary">
			<i className="vngage-icon-queue"/>
			<span className="duration">
				<div>
					{sinceBeginHours}
					{sinceBeginMinutes}
					{sinceBeginSeconds}
				</div>
			</span>
			<span className="action-label">{getTranslation('actionSelect')}</span>
		</div>
	)
};

export default VisitorBalloon;
