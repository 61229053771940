import {createSelector} from 'reselect';

const getPlugins = state => state.get('plugins');
const getSelectedPluginId = state => state.get('selectedPlugin');

const getSelectedPlugin = createSelector(
	[getPlugins, getSelectedPluginId],
	(plugins, selectedPlugin) => plugins.find(plugin => plugin.get('id') === selectedPlugin)
);

export const getPinnedPlugins = createSelector(
	[getPlugins],
	plugins => plugins.filter(plugin => plugin.get('enabled') && plugin.get('displayMode') === 'pinned')
);


export const getSelectedPluginWidth = createSelector(
	[getSelectedPlugin],
	plugin => plugin ? parseInt(plugin.get('width')): 0
);