import FormAddModalController from './FormAddModalController';
import FormEditController from './FormEditController';
import FormListController from './FormListController';
import formTranslations from './FormTranslations';
import FormListTemplate from './FormListTemplate';
import FormEditTemplate from './FormEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.configure.form', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('FormAddModalController', FormAddModalController)
	.controller('FormEditController', FormEditController)
	.controller('FormListController', FormListController)
	.constant('formTranslations', formTranslations)

	.constant('formModuleConfig', {
		state: 'root.configure.form',
		access: 'Engage/Forms'
	})

	.config(function ($stateProvider, $translateProvider, formTranslations, formModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(formTranslations);

		$stateProvider.state(formModuleConfig.state, {
			url: 'form/',
			controller: 'FormListController as vm',
			templateUrl: FormListTemplate,
			data: {
				access: formModuleConfig.access,
				ncyBreadcrumbLabel: 'Forms'
			},
			resolve: {
				formList: function (APIConfigurationSectionService) {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.form, true);
				}
			}
		});

		$stateProvider.state('root.configure.form.edit', {
			url: ':id',
			controller: 'FormEditController as vm',
			templateUrl: FormEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				form: function ($stateParams, formList, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(formList, $stateParams.id);
				}
			}
		});
	});
