import SiteAddModalTemplate from './SiteAddModalTemplate';

export default function ($uibModal, siteList, $state, translationList, SiteService) {
	'ngInject';

	var vm = this;
	vm.siteList = siteList;

	vm.tableOptions = {
		stateLink: {
			state: 'root.account.site.edit',
			property: 'data.id'
		},
		createItem: {
			langKey: 'site.ADD_HEADER',
			method: addNew
		}
	};
	vm.tableColumns = [{
		header: 'Name',
		property: 'data.name',
		sortDefault: true,
		useInFilter: true
	}, {
		header: 'GUID',
		property: 'data.id',
		hidden: true,
		useInFilter: true
	}];

	function openAddModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: SiteAddModalTemplate,
			controller: 'SiteAddModalController as vm',
			resolve: {
				options: () => {
					return {
						header: 'site.ADD_HEADER',
						label: 'site.NAME'
					};
				},
				availableLanguages: () => {
					return SiteService.getLanguageSelectOptions(translationList);
				}
			}
		});
		modal.result.then(newSite => {
			vm.siteList.push(newSite);
			$state.go('.edit', {id: newSite.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		$state.go('root.account.site').then(openAddModal);
	}
};
