export default function () {
	'use strict';

	var commandsArr = [],
		root = this;

	var addCommand = function (root, name, command) {
		commandsArr.push(name);
		root[name] = command;
	};

	this.addCommands = function (commands) {
		for (var c in commands) {
			//if (!psPlugin.core.jsExt.isFunction(c)) {
			if (typeof value !== 'function') {
				addCommand(root, c, commands[c]);
			}
		}
	};
}
