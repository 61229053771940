import React from 'react';
import Header from './Header.react.js';
import Chat from './Chat.react.js';
import VisitorProfile from './VisitorProfile.react.js';
import Footer from './Footer.react.js';
import CoBrowser from './co-browser/CoBrowser.react.js';
import ToolBar from './ToolBar.react.js';
import SectionView from './Sections/SectionView.react';

const Panel = (props) => {
	const {conversationId} = props;

	const panelCls = props.isMaximized ? 'maximized': props.isCollapsed ? 'collapsed': '';
	const panelActiveCls = props.panelIsActive ? 'active': '';

	const sectionPayload = {
		conversationId,
		activeSections: props.activeSections,
	};

	const coBrowsingElement = props.isMaximized
		? <CoBrowser {...{
			conversationId,
			panelContainerWidth: props.panelContainerWidth,
		}}/>
		: null;

	// A fix for cobro size using react spring in View.
	// a css solution would be better.
	const style = props.isMaximized
		? {width: props.panelContainerWidth + 'px'}
		: {};

	const cls = `dialog-panel ${panelCls} ${panelActiveCls}`;
	return (
		<div className={cls} style={style}>
			<span className="dialog-container">
				 <div className="dialog-menu">
					 <span className="refCode">{props.refCode}</span>
					<Header conversationId={conversationId}/>
					<VisitorProfile conversationId={conversationId}/>
				</div>
				<div className="dialog-action-area">
					<ToolBar conversationId={conversationId}/>
					<SectionView {...sectionPayload}/>
					<Chat conversationId={conversationId}/>
					<Footer conversationId={conversationId}/>
				</div>
			</span>
			<span className="co-container">
				{coBrowsingElement}
			</span>
		</div>
	);
};

Panel.displayName = 'Panel';
export default Panel;

