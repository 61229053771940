export default function(APIEndpointService, accountConfiguration, accountId, timeZones, languagesConfig) {
	'ngInject';

    var vm = this;
    vm.accountConfiguration = accountConfiguration;
    vm.timeZones = angular.copy(timeZones);
    vm.timeZones.push({id: '', displayName: '(None)'}); // It is possible to not have a timezone as well
	vm.desktopLanguages = languagesConfig.supportedLanguages.map(langCode => {
		return languagesConfig.lookupByLangCode(langCode);
	});

    vm.submit = function() {
        vm.isSaving = true;
        vm.accountConfiguration.$save({ id: accountId }).finally(function() {
            vm.isSaving = false;
        });
    };
};
