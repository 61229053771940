import {fromJS} from 'immutable'

export default {
	pluginTemplate: {
		'id': '',
		'name': '',
		'apiVersion': '',
		'enabled': false,
		'sectionButtonType': 'none',
		'sectionButtonTitle': '',
		'sectionButtonIconUrl': '',
		'displayMode': 'floating',
		'width': 100,
		'height': 100,
		'x': 0,
		'y': 0,
		'url': '',
		'enabledGroups': [],
		'enabledSubscriptions': [],
		'enabledActions': [],
		'mappedActionMessages': []
	},

	availableSubscriptions: [
		'agentPresence',
		'agentInfo',
		'agentGroups',
		'agentActiveGroups',
		'conversationOpened',
		'conversationClosed',
		'conversationActive',
		'conversationStatus',
		'connectionStatus',
		'chatMessages',
		'caseInfo',
		'visitorClaims',
		'webRTCInfo',
		'sectionButtonClicked'
	],

	availableActions: [
		'setChatInputText',
		'sendChatMessage',
		'sendActionMessage',
		'setSectionButtonState',
		'sendNote',
	],

	internalActions: [
		'pluginReady',
	],

	subscriptionPriority: {
		agentPresence: 10,
		agentInfo: 10,
		agentGroups: 10,
		sectionButtonClicked: 10,
		conversationActive: 50,
		conversationStatus: 50,
		connectionStatus: 50,
		agentActiveGroups: 50,
		conversationOpened: 100,
		caseInfo: 150,
		chatMessages: 200,
		visitorClaims: 250,
		webRTCInfo: 260,
		conversationClosed: 300,
		defaultSub: 1000,
	},

}

