import React from 'react';

const NoteButton = ({noteMode, toggleNoteMode, conversationId}) => {
	const noteModeCls = `message-toolbar-btn notes-symbol ${noteMode ? 'active': ''}`;
	return (
		<button className={noteModeCls}
				tabIndex="-1"
				ub-track="toggle-note-mode"
				onClick={() => toggleNoteMode(conversationId)}>
		</button>
	)
};

export default NoteButton;




