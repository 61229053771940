import SolutionAddModalController from './SolutionAddModalController';
import SolutionEditController from './SolutionEditController';
import SolutionListController from './SolutionListController';
import SolutionTranslations from './SolutionTranslations';
import SolutionListTemplate from './SolutionListTemplate';
import SolutionEditTemplate from './SolutionEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';
import SolutionEditGroupFilter from './SolutionEditGroupFilter';

export default angular.module('vngageApp.engage.solution', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('SolutionAddModalController', SolutionAddModalController)
	.controller('SolutionEditController', SolutionEditController)
	.controller('SolutionListController', SolutionListController)
	.constant('SolutionTranslations', SolutionTranslations)
	.filter('SolutionEditGroupFilter', SolutionEditGroupFilter)

	.constant('solutionModuleConfig', {
		state: 'root.engage.solution',
		access: 'Engage/Solutions'
	})

	.config(function ($stateProvider, $translateProvider, SolutionTranslations, solutionModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(SolutionTranslations);

		$stateProvider.state(solutionModuleConfig.state, {
			url: 'solution/',
			controller: 'SolutionListController as vm',
			templateUrl: SolutionListTemplate,
			data: {
				access: solutionModuleConfig.access,
				ncyBreadcrumbLabel: 'Solutions'
			},
			resolve: {
				lists: function (APIConfigurationSectionService, AccountSitesService, APIEndpointService, $q) {
					'ngInject';
					var siteId = AccountSitesService.getSelectedSiteId();
					return $q((resolve, reject) => {
						$q.all({
							configSections: APIConfigurationSectionService.get([
								APIConfigurationSectionService.sectionMap.solution,
								APIConfigurationSectionService.sectionMap.customerCase,
								APIConfigurationSectionService.sectionMap.banner,
								APIConfigurationSectionService.sectionMap.opportunity
							], true),
							groups: APIEndpointService.group.query({siteId: siteId})
						}).then((result) => {
							resolve({
								solutions: result.configSections[APIConfigurationSectionService.sectionMap.solution],
								caseTypes: result.configSections[APIConfigurationSectionService.sectionMap.customerCase],
								banners: result.configSections[APIConfigurationSectionService.sectionMap.banner],
								opportunities: result.configSections[APIConfigurationSectionService.sectionMap.opportunity],
								groups: result.groups
							});
						}, (error) => {
							console.log('Error resolving lists: ', error);
							reject(error);
						});
					});
				}
			}
		});
		$stateProvider.state('root.engage.solution.edit', {
			url: ':id',
			controller: 'SolutionEditController as vm',
			templateUrl: SolutionEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				solution: function (lists, VngageUtil, $stateParams) {
					'ngInject';
					return VngageUtil.findResource(lists.solutions, $stateParams.id);
				},
				bannerList: function (APIConfigurationSectionService) {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.banner, true);
				}
			}
		});
	});
