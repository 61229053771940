import vngageGuid from './../../common/vngageGuid/vngageGuidService';

export default function ($uibModalInstance, $scope, APIConfigurationSectionService, Session, editMappedAction, actionMessagesList, mappedActionMessages) {
	'ngInject';

	const vm = this;
	vm.actionMessagesList = actionMessagesList;
	vm.mappedActionMessages = mappedActionMessages;
	vm.addNewMapping = !editMappedAction;

	vm.mappedAction = (vm.addNewMapping ? {
		id: vngageGuid().generate(),
		actionMessageName: '',
		actionId: ''
	} : {
		id: editMappedAction.id,
		actionMessageName: editMappedAction.actionMessageName,
		actionId: editMappedAction.actionId
	});

	vm.addOrUpdateMapping = addOrUpdateMapping;
	vm.cancelAddMapping = $uibModalInstance.dismiss;
	vm.deleteMapping = deleteMapping;

	$scope.$watch('vm.mappedAction.actionMessageName', actionMessageName => {
		// Look for duplicate names...
		if (actionMessageName && vm.mappedActionMessages.find(item => (item.actionMessageName === actionMessageName && item.id !== vm.mappedAction.id))) {
			vm.editActionMessageForm.actionMessageName.$setValidity('duplicate', false);
		} else {
			vm.editActionMessageForm.actionMessageName.$setValidity('duplicate', true);
		}

		// Check illegal characters...
		if (/[^a-zA-Z0-9 ()\\.\\_\\-]/g.test(actionMessageName)) {
			vm.editActionMessageForm.actionMessageName.$setValidity('invalidName', false);
		} else {
			vm.editActionMessageForm.actionMessageName.$setValidity('invalidName', true);
		}
	});

	function addOrUpdateMapping() {
		if (vm.mappedActionMessages.find(item => (item.actionMessageName === vm.mappedAction.actionMessageName && item.id !== vm.mappedAction.id))) {
			vm.editActionMessageForm.actionMessageName.$setValidity('duplicate', false);
		} else {
			$uibModalInstance.close(vm.mappedAction);
		}
	}

	function deleteMapping() {
		vm.mappedAction.actionId = null; // actionId set to null indicates delete
		$uibModalInstance.close(vm.mappedAction);
	}
};
