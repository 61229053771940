import moment from 'moment-timezone';
import assign from 'object-assign';
import _filter from 'lodash/filter';
import _reject from 'lodash/reject';
import _find from 'lodash/find';
import _map from 'lodash/map';
import SessionService from '../../common/auth/SessionService';
import Records from '../records/MessageRecords';

export default {

	convertRawMessage (rawMessage) {
		return assign({}, rawMessage, {
			createdAt: new Date(moment(rawMessage.createdAt).utc().tz(SessionService().getUserTimezone()).format())//new Date(rawMessage.createdAt)
		});
	},

	getCreatedMessageData (text, conversationId = null) {
		let now = Date.now(),
			created = new Date(moment(new Date(now)).utc().tz(SessionService().getUserTimezone()).format());

		return assign({}, Records.ChatMessageRecord, {
			id: 'id' + now,
			message: text,
			createdAt: created,
			tags: conversationId ? [`${conversationId}_optmsg_${now}`]: []
		});
	},

	getNoteData (text, conversationId) {
		let now = Date.now();

		return assign({}, Records.NoteMessageRecord, {
			id: 'id' + now,
			message: text,
			createdAt: new Date(now),
			tags: conversationId ? [`${conversationId}_optmsg_${now}`]: []
		});
	},

	getVideoMessageData (data) {
		let msgData = {
			// Due to enforced server validation of conversationMessages,
			// "message" must be a string between 1 and 1000 chars
			message: data.activity,
			activity: data.activity,
			createdAt: new Date(Date.now())
		};

		return assign({}, Records.VideoMessageRecord, msgData, data);
	},

	getMetaMessageData (data, speaker) {
		let msgData = {
			createdAt: new Date(Date.now())
		};

		if (speaker) {
			msgData.speaker = speaker;
		}

		return assign({}, Records.MetaMessageRecord, msgData, data);
	},

	getLinkData (data, speaker) {
		let now = Date.now();
		let msgData = {
			id: 'id' + now,
			createdAt: new Date(now)
		};

		if (speaker) {
			msgData.speaker = speaker;
		}
		return assign({}, Records.LinkMessageRecord, msgData, data);
	},

	getNavigationData (data) {
		let msgData = {
			url: data.url,
			bidirectional: data.bidirectional || true,
			createdAt: new Date(Date.now())
		};

		return assign({}, Records.NavigationMessageRecord, msgData);
	},

	getHighlightMessageData (data) {
		//return assign({}, Records.DomHighlightMessageRecord, {
		//	createdAt: new Date(Date.now())
		//}, data);
		return assign({}, Records.DomHighlightMessageRecord, data);
	},

	getAskForDomUploadMessageData () {
		return assign({}, Records.AskDomUploadMessageRecord, {
			createdAt: new Date(Date.now())
		});
	},

	getMetadataExchangeMessageData(data) {
		return assign({}, Records.MetadataExchangeRecord, {
			metadataBag: data
		})
	},

	sortByDate (messages) {
		let sorted = messages.sort((a, b) => {
			if (a.createdAt.getTime() < b.createdAt.getTime()) {
				return -1;
			} else if (a.createdAt.getTime() > b.createdAt.getTime()) {
				return 1;
			}
			return 0;
		});
		return sorted;
	},

	getConversationMessages (messages) {
		var blacklistedMessageTypes = ['activity', 'browserInfo'];
		return _filter(messages, msg => {
			return msg.type === 'conversationMessage' && blacklistedMessageTypes.indexOf(msg.messageType) === -1;
		});
	},

	getMessagesWithoutMe (messages, profileId) {
		return _reject(messages, msg => {
			return msg.speaker.userId === profileId;
		});
	},

	conversationHasChatMessages (conversationId, messages) {
		var l = _filter(messages, msg => {
			return msg.conversationId === conversationId && msg.messageType === 'chat';
		}).length;
		return l > 0;
	},

	/**
	 * map messages to conversation
	 * @param messages
	 * @param conversationId
	 * @returns {*}
	 */
	mapConversationMessages (messages, conversationId) {
		if (!messages || !conversationId) {
			return [];
		}
		let msgInConversation = this.addConversationIdToMsg(messages, conversationId);

		if (!Array.isArray(msgInConversation)) {
			msgInConversation = [msgInConversation];
		}

		return this.getConversationMessages(msgInConversation);
	},

	// this needs some tidying up to be more chat-msg defined as ref
	hasTagOfConversation (conversationId, message, profileId) {
		return message.conversationId === conversationId
			&& message.tags && message.tags.join('').indexOf(message.conversationId) > -1
			&& message.speaker.userId === profileId;
	},

	getChatMessagesWithoutMe (messages, profileId) {
		let messageTypes = ['chat', 'navigation', 'note', 'video'];
		return _reject(messages, msg => {
			return messageTypes.indexOf(msg.messageType) > -1 && msg.speaker.userId === profileId;
		});
	},

	addConversationIdToMsg (messages, conversationId) {
		if (Array.isArray(messages)) {
			messages = _map(messages, msg => {
				msg.conversationId = conversationId;
				return msg;
			});
			return messages;
		} else {
			messages.conversationId = conversationId;
			return messages;
		}

	},

	addProfileToMsg (message, speaker) {

		if (speaker) {
			message.speaker = speaker;
		}
		return message;
	},

	getById (messages, id) {
		return _find(messages, msg => {
			return msg.id === id;
		});
	}
};
