import React from 'react';
import VisitorProfileSelectors from '../../selectors/VisitorProfileSelectors'
import {useGetTranslation} from './../LangContext';

const MetaInfo = ({visitorProfile, visitorJoined, conversationStatus}) => {
	const getTranslation = useGetTranslation();
	const metaInfo = VisitorProfileSelectors.getVisitorTitle(visitorProfile) || visitorProfile.getIn(['meta', 'metaInfo']);

	const className = (visitorJoined && !metaInfo ? 'loading-meta-spinner': 'fadeIn browser-meta-info') + (!visitorJoined ? ' no-visitor-joined': '');
	const metaInfoText = (visitorJoined ? metaInfo: getTranslation('noVisitorHasArrived'));

	if (!visitorJoined && conversationStatus === 'pendingStart') {
		// Hide text/spinner if no visitor has joined yet *AND* conversationStatus === 'pendingStart' (do not display [noVisitorHasArrived] until we know for sure, i.e. until we leave state 'pendingStart')
		return null;
	} else {
		return (
			<span className={className}>{metaInfoText}</span>
		)
	}
};
export default MetaInfo
