import _each from 'lodash/each';

export default function validateConfiguration () {

	var valid = false;

	try {
		if (!psPlugin.application.configuration.user.groupIds.length) {
			psPlugin.application.events.onConfigurationNotSufficient.trigger('Your user profile has not yet been added to any user groups.');
			return false;
		}

		psPlugin.application.configuration.user.languages = {};
		_each(psPlugin.application.configuration.user.groupIds, function (gId) {
			var group = psPlugin.application.configuration.groups[gId];
			if (!group) { return; } // the group has been removed but is still referenced by the user
			var siteId = group.siteId,
				lng = psPlugin.application.configuration.user.languages[siteId];

			psPlugin.application.configuration.user.languages[siteId] = psPlugin.application.configuration.user.languages[siteId] || {
					groups: {}
				};
			psPlugin.application.configuration.user.languages[siteId].groups[gId] = true;
		});


		for (var l in psPlugin.application.configuration.user.languages) {
			for (var g in psPlugin.application.configuration.user.languages[l].groups) {
				//this.applyConfig();
				if (psPlugin.application.configuration.user.languages[l].groups[g] == true) {
					valid = true;
					break;
				}
			}
		}
		if (!valid) {
			psPlugin.application.events.onConfigurationNotSufficient.trigger('Your user profile has not yet been added to any user groups.');
			return false;
		}

		valid = true;


		// TODO move somewhere else and cleanup || wait until new configuration will replace :)
		psPlugin.application.configuration.visitorList = {
			languages: {}
		};


		_each(psPlugin.application.configuration.desktop.languages, function (l) {
			var siteId = l.id;
			if (l && l.groups) {
				psPlugin.application.configuration.visitorList.languages[siteId] = {display: true};
				if (psPlugin.application.configuration.user.languages[siteId] && psPlugin.application.configuration.user.languages[siteId].groups) {
					for (var g in psPlugin.application.configuration.user.languages[siteId].groups) {
						if (typeof psPlugin.application.configuration.user.languages[siteId].groups[g] != 'undefined') {
							if (typeof psPlugin.application.configuration.visitorList.languages[siteId].groups != 'object') {
								psPlugin.application.configuration.visitorList.languages[siteId].groups = {};
							}
							psPlugin.application.configuration.visitorList.languages[siteId].groups[g] = true;
						}
					}
				}

			}

		});

		psPlugin.application.visitorFilter.allGroups = psPlugin.application.configuration.groupIds;


		// line belov is used to test the issue with users not memeber of groups in a language
		//psPlugin.application.configuration.visitorList.languages["fc2f75ca-4086-444c-b6d4-0a2febcb468c"].groups = {};
		var visitorList = psPlugin.application.configuration.visitorList,
			showLanguage;

		for (var lang in visitorList.languages) {
			if (visitorList.languages[lang].display) {
				showLanguage = false;
				for (var group in visitorList.languages[lang].groups) {
					if (visitorList.languages[lang].groups[group]) {
						psPlugin.application.visitorFilter.selectedGroups.push(group);
						psPlugin.application.visitorFilter.availableForGroups.push({
							group: group,
							lang: lang
						});
						showLanguage = true;
					}
				}
				if (showLanguage) {
					psPlugin.application.visitorFilter.selectedLanguages.push(lang);
					if (typeof visitorList.languages[lang].hideProactive == 'undefined' || !visitorList.languages[lang].hideProactive) {
						psPlugin.application.visitorFilter.proactiveLang.push(lang);
					}
				}
			}
		}

		return valid;


	} catch (err) {

		if (!valid) {
			psPlugin.application.events.onConfigurationNotSufficient.trigger('Your user profile has not yet been added to any user groups.');
		} else {
			psPlugin.application.events.onConfigurationNotSufficient.trigger('Issues were found in the account configuration.');
		}
		console.error('psPlugin:configuration:error:', err);
		return false;
	}
};
