import OpportunityFiltrexHelperService from './OpportunityFiltrexGenerator/OpportunityFiltrexHelperService';
import OpportunityFiltrexService from './OpportunityFiltrexGenerator/OpportunityFiltrexService';
import vngageOpportunityGenerator from './OpportunityGenerator/OpportunityGeneratorDirective';
import vngageOpportunityGeneratorItem from './OpportunityGenerator/OpportunityGeneratorItemDirective';
import OpportunityGeneratorService from './OpportunityGenerator/OpportunityGeneratorService';
import OpportunityAddModalController from './OpportunityAddModalController';
import OpportunityEditController from './OpportunityEditController';
import OpportunityListController from './OpportunityListController';
import OpportunityService from './OpportunityService';
import opportunityTranslations from './OpportunityTranslations';
import OpportunityListTemplate from './OpportunityListTemplate';
import OpportunityEditTemplate from './OpportunityEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.analyze.opportunity', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.factory('OpportunityFiltrexHelperService', OpportunityFiltrexHelperService)
	.factory('OpportunityFiltrexService', OpportunityFiltrexService)
	.directive('vngageOpportunityGenerator', vngageOpportunityGenerator)
	.directive('vngageOpportunityGeneratorItem', vngageOpportunityGeneratorItem)
	.factory('OpportunityGeneratorService', OpportunityGeneratorService)
	.controller('OpportunityAddModalController', OpportunityAddModalController)
	.controller('OpportunityEditController', OpportunityEditController)
	.factory('OpportunityService', OpportunityService)
	.constant('opportunityTranslations', opportunityTranslations)
	.controller('OpportunityListController', OpportunityListController)

	.constant('opportunityModuleConfig', {
		access: 'Engage/Opportunities',
		state: 'root.analyze.opportunity'
	})

	.config(function ($stateProvider, $translateProvider, opportunityTranslations, opportunityModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(opportunityTranslations);

		$stateProvider.state(opportunityModuleConfig.state, {
			url: 'opportunity/',
			controller: 'OpportunityListController as vm',
			templateUrl: OpportunityListTemplate,
			data: {
				access: opportunityModuleConfig.access,
				ncyBreadcrumbLabel: 'Opportunity'
			},
			resolve: {
				configurationSections: function (APIConfigurationSectionService) {
					'ngInject';
					// Get all ConfigurationSections at once, use as dependency in the other resolves below and filter out the correct type there
					// This is to avoid calling APIEndpointService.configurationSection.query() multiple times for one single view.
					return APIConfigurationSectionService.getAll(true);
				},
				opportunityList: function (APIConfigurationSectionService, configurationSections) {
					'ngInject';
					return APIConfigurationSectionService.filterConfigurationSections(configurationSections, APIConfigurationSectionService.sectionMap.opportunity);
				},
				solutionList: function (APIConfigurationSectionService, configurationSections) {
					'ngInject';
					return APIConfigurationSectionService.filterConfigurationSections(configurationSections, APIConfigurationSectionService.sectionMap.solution);
				}
			}
		});

		$stateProvider.state('root.analyze.opportunity.edit', {
			url: ':id',
			controller: 'OpportunityEditController as vm',
			templateUrl: OpportunityEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				pointOfInterestList: function (APIEndpointService, AccountSitesService) {
					'ngInject';
					var siteId = AccountSitesService.getSelectedSiteId();
					return APIEndpointService.pointOfInterest.query({siteId: siteId}).$promise;
				},
				opportunity: function ($stateParams, opportunityList, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(opportunityList, $stateParams.id);
				},
				solutionList: function (APIConfigurationSectionService) {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.solution, true);
				}
			}
		});
	});
