export default {
	initialState: 'Ready',
	onStart: [],
	states: {
		Ready: {
			entry: [],
			exit: [],
			transitions: {
				JoinQueue: {
					conditions: [],
					actions: [],
					trigger: 'onQueueJoined',
					nextState: 'Queued'
				},
				Enlist: {
					conditions: [],
					actions: [],
					trigger: 'onEnlist',
					nextState: 'Proactive'
				}
			}
		}, // Ready
		Proactive: {
			entry: [],
			exit: [],
			transitions: {
				JoinQueue: {
					conditions: [],
					actions: [],
					trigger: 'onQueueJoined',
					nextState: 'Queued'
				},
				Leave: {
					conditions: [],
					actions: [],
					trigger: 'onLeave',
					nextState: 'Ready'
				},
				Lock: {
					conditions: [],
					actions: [],
					trigger: 'onLock',
					nextState: 'Locked'
				},
				Begin: {
					conditions: [],
					actions: [],
					trigger: 'onBegin',
					nextState: 'PendingApproval'
				}
			}
		}, // Proactive
		Queued: {
			entry: [],
			exit: [],
			transitions: {
				Leave: {
					conditions: [],
					actions: [],
					trigger: 'onLeave',
					nextState: 'Ready'
				},
				Lock: {
					conditions: [],
					actions: [],
					trigger: 'onLock',
					nextState: 'Locked'
				},
				Begin: {
					conditions: [],
					actions: [],
					trigger: 'onBegin',
					nextState: 'InDialog'
				},
				Approve: {
					conditions: [],
					actions: [],
					trigger: 'onApprove',
					nextState: 'InDialog'
				},
				EndDialog: {
					conditions: [],
					actions: [],
					trigger: 'onEndDialog',
					nextState: 'Completed'
				}
			}
		}, // Queued
		Locked: {
			entry: [],
			exit: [],
			transitions: {
				UnlockToProactive: {
					conditions: [],
					actions: [],
					trigger: 'onUnlockToProactive',
					nextState: 'Proactive'
				},
				UnlockToQueue: {
					conditions: [],
					actions: [],
					trigger: 'onUnlockToQueue',
					nextState: 'Queue'
				},
				Begin: {
					conditions: [],
					actions: [],
					trigger: 'onBegin',
					nextState: 'InDialog'
				},
				Approve: {
					conditions: [],
					actions: [],
					trigger: 'onApprove',
					nextState: 'InDialog'
				},
				EndDialog: {
					conditions: [],
					actions: [],
					trigger: 'onEndDialog',
					nextState: 'Completed'
				}
			}
		}, // Locked
		PendingApproval: {
			entry: [],
			exit: [],
			transitions: {
				Approve: {
					conditions: [],
					actions: [],
					trigger: 'onApprove',
					nextState: 'InDialog'
				},
				Reject: {
					conditions: [],
					actions: [],
					trigger: 'onReject',
					nextState: 'Ready'
				},
				NotToday: {
					conditions: [],
					actions: [],
					trigger: 'onNotToday',
					nextState: 'Ready'
				}
			}
		}, // PendingApproval
		InDialog: {
			entry: [],
			exit: [],
			transitions: {
				TransferToPerson: {
					conditions: [],
					actions: [],
					trigger: '',
					nextState: 'PendingTransfer'
				},
				EndDialog: {
					conditions: [],
					actions: [],
					trigger: 'onEndDialog',
					nextState: 'Completed'
				}
			}
		}, // InDialog
		PendingTransfer: {
			entry: [],
			exit: [],
			transitions: {
				Accept: {
					conditions: [],
					actions: [],
					trigger: '',
					nextState: 'InDialog'
				},
				Reject: {
					conditions: [],
					actions: [],
					trigger: '',
					nextState: 'InDialog'
				}
			}
		}, // PendingTransfer
		Completed: {
			entry: [],
			exit: [],
			transitions: {
				Close: {
					conditions: [],
					actions: [],
					trigger: 'onClose',
					nextState: 'Ready'
				}
			}
		} // Completed

	}
};
