import React from 'react';
import {Button} from 'react-bootstrap';
import {useGetTranslation} from '../../LangContext';

const DisconnectedOverlay = ({toggleCoBro, conversationId, enabledFeatures = {}}) => {
	const {exit} = enabledFeatures;
	const getTranslation = useGetTranslation();
	return (
		<div className="disconnected-overlay">
			<div className="container">
				<h1>{getTranslation('overlayDisconnectedHeader')}</h1>
				<p>{getTranslation('overlayDisconnectedBody')}</p>
				{exit && <Button
					onClick={() => toggleCoBro(conversationId)}
					className="default-line-btn-contrast">
					{getTranslation('overlayBtnDisconnectedDismiss')}
				</Button>
				}
			</div>
		</div>
	);
};
export default DisconnectedOverlay;

