export const fileImportHeaders = [
	{
		name: 'name',
		required: true,
		unique: true
	},
	{
		name: 'actionPanels',
		required: false,
		format: 'list',
		values: []// needs to be filled
	},
	{
		name: 'cannedResponses',
		required: false,
		format: 'list',
		values: []// needs to be filled
	},
	{
		name: 'closures',
		required: false,
		format: 'list',
		values: []// needs to be filled
	},
	{
		name: 'forms',
		required: false,
		format: 'list',
		values: []// needs to be filled
	},
	{
		name: 'caseTimeToLive',
		format: 'integer',
		suffix: '.00:00:00',
		default: '14', // default to 30 days
	},
];

