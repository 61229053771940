import _keys from 'lodash/keys';

export default function ($scope, $uibModalInstance, APIEndpointService, translations, systemTranslations, TranslationService, $translate) {
    'ngInject';

    var vm = this;
    vm.newData = {};
    vm.saveNew = saveNew;
    vm.cancelNew = $uibModalInstance.dismiss;
    vm.languageCodes = _keys(systemTranslations.data);
    TranslationService.getAvailableLanguages(translations).then(function(languagesObj) {
        vm.availableLanguages = languagesObj;
    });

    $scope.$watch('vm.newData.languageCode', function(langCode) {
        $translate('languageCodes.' + langCode).then(function(codeName) {
            vm.newData.name = codeName;
        });
    });

    function saveNew() {
        vm.isSaving = true;
		$uibModalInstance.freeze(true);

        APIEndpointService.translation.create(vm.newData).$promise
            .then(function(res) {
				$uibModalInstance.close(res);
            })
            .catch(function() {
				$uibModalInstance.freeze(false);
                vm.isSaving = false;
            });
    }
};
