import React from 'react';

const EmojiButton = (props) => {
	return (
		<button
			className="message-toolbar-btn emoji-symbol"
			disabled={props.disabled}
			tabIndex="-1"
			id="emojiPicker"
			ub-track="toggle-emoji-picker"
			onClick={props.togglePicker}
		/>
	)
};
export default EmojiButton

