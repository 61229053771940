import MessageType from './../../constants/MessageType';

// exclusion is immutable
function getFilteredWithExclusion(filterFunc, data, exclusion) {
	return data.filter(entry => filterFunc(entry)).filter(entry => messagesExclude(entry, exclusion));
}

function getFilteredArray(filterFunc, data) {
	return data.filter(entry => filterFunc(entry));
}

function getAsObjectWithIds(filterFunc, data) {
	return data.reduce((obj, entry) => filterFunc(obj, entry), {});
}

function getLastFoundInArray(filterFunc, data) {
	return data.filter(entry => filterFunc(entry)).pop();
}

function getOneInArray(filterFunc, data) {
	return data.find(entry => filterFunc(entry));
}

function getLastValid(filterFunc, data, defaultValue) {
	return data.reduce((prev, entry) => filterFunc(entry, prev), defaultValue);
}


//
// Identifiers
//
function events(msg) {
	return msg.type !== 'conversationMessage';
}

function activities(msg) {
	return msg.messageType === 'activity';
}

function participants(obj, participant) {
	let id = participant.userId || participant.visitId;
	obj[id] = participant;
	return obj;
}

function me(participant) {
	return participant.info.owner;
}

function visitor(participant) {
	return participant.visitId;
}

function browserInfo(msg) {
	return msg.messageType === 'browserInfo';
}

function title(msg) {
	return msg.messageType === 'title';
}

function participantJoined(msg) {
	return msg.type === 'participantJoined';
}

function participantLeft(msg) {
	return msg.type === 'participantLeft';
}

function participantInvited(msg) {
	return msg.type === 'participantInvited';
}

function chatMessages(msg) {
	return msg.messageType === 'chat';
}

function videoMessage(msg) {
	return msg.messageType === MessageType.VIDEO;
}

function messages(msg) {
	return ['bannerInput', 'chat', 'navigation', 'note', 'link', 'video', 'meta', 'browserInfo', 'domUpload', 'metadataExchange', 'content'].indexOf(msg.messageType) !== -1;
}

function metadataExchange(msg) {
	return msg.messageType === 'metadataExchange';
}

function navigation(msg) {
	return ['navigation', 'domUpload'].indexOf(msg.messageType) !== -1;
}

function groupId(msg) {
	return msg.type === 'associatedWithGroup';
}

function caseId(msg) {
	return msg.type === 'associatedWithCase';
}

function closed(msg) {
	return msg.type === 'conversationClosed';
}

function solutionId(msg) {
	return msg.solutionId;
}

function opportunityId(msg) {
	return msg.opportunityId;
}

// excludeList is immutable
function messagesExclude(msg, excludeList) {
	return !excludeList.includes(msg.id);
}

function connectionStatus(msg, prev) {
	if (msg.connectionState && (msg.participant && msg.participant.visitId)) {
		return msg.connectionState;
	} else if ((['conversationResumed'].indexOf(msg.type) > -1)) {
		return 'ok';
	} else if ((['participantJoined'].indexOf(msg.type) > -1) && (msg.participant && msg.participant.visitId)) {
		// Also set connectionStatus = 'ok' if a visitor joins (can happen in booked meetings - a visitor has dropped out (connection lost/dead) and re-joined
		return 'ok';
	} else {
		return prev;
	}
}

function visitProfile(msg) {
	return msg.messageType === 'visitProfile';
}

function conversationStarted(msg) {
	return msg.type === 'conversationStarted';
}

export const filters = {
	getFilteredWithExclusion,
	getFilteredArray,
	getAsObjectWithIds,
	getLastFoundInArray,
	getOneInArray,
	getLastValid,
	identifier: {
		events,
		activities,
		participants,
		me,
		visitor,
		browserInfo,
		title,
		participantJoined,
		participantLeft,
		participantInvited,
		chatMessages,
		videoMessage,
		messages,
		metadataExchange,
		navigation,
		groupId,
		caseId,
		closed,
		solutionId,
		opportunityId,
		messagesExclude,
		connectionStatus,
		visitProfile,
		conversationStarted,
	}
};

