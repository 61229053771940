import AppDispatcher from '../dispatcher/AppDispatcher';
import AppConstants from '../../../components/constants/AppConstants';

const ActionTypes = AppConstants.ActionTypes;

export default {

	connectionDown () {
		AppDispatcher.handleConnectionAction({
			type: ActionTypes.CONNECTION_DOWN
		});
	},

	connectionUp () {
		AppDispatcher.handleConnectionAction({
			type: ActionTypes.CONNECTION_UP
		});
	}
};
