import AppConstants from '../../../components/constants/AppConstants';
import Format from '../utils/Format';

const ActionTypes = AppConstants.ActionTypes;

export default {

	contentLog(name, params) {
		return {
			type: ActionTypes.CONTENT_LOG,
			name,
			params
		};
	},

	contentErrorLog(error, params) {
		return {
			type: ActionTypes.CONTENT_ERROR_LOG,
			error,
			params
		};
	},

	logAc(name, params) {
		return {
			type: ActionTypes.LOG_AC,
			name,
			params
		};
	},

	netError(netId, event, data) {
		return {
			type: ActionTypes.NET_ERROR,
			netId,
			event,
			data: {
				...data,
				error: Format.getFormattedError(data.error)
			}
		};
	},

	cobroLog(description, data) {
		return {
			type: ActionTypes.COBRO_LOG,
			description,
			data
		};
	},

	cobroError(error, data) {
		return {
			type: ActionTypes.COBRO_ERROR,
			error,
			data
		};
	},

	netPoll(netId, pollStats) {
		return {
			type: ActionTypes.NET_POLL,
			netId,
			pollStats
		};
	},

	netPollDone(netId, pollStats) {
		return {
			type: ActionTypes.NET_POLL_DONE,
			netId,
			pollStats
		};
	},

	netPollSkip(netId, pollStats) {
		return {
			type: ActionTypes.NET_POLL_SKIP,
			netId,
			pollStats
		};
	},

	netPollWarning(netId, error, pollStats) {
		return {
			type: ActionTypes.NET_POLL_WARNING,
			netId,
			error,
			pollStats
		};
	},

	netPollError(netId, error, pollStats) {
		return {
			type: ActionTypes.NET_POLL_ERROR,
			netId,
			error: Format.getFormattedError(error),
			pollStats
		};
	},

	netCall(netId, event, data) {
		return {
			type: ActionTypes.NET_CALL,
			netId,
			event,
			data
		};
	},

	netCallError(netId, error) {
		return {
			type: ActionTypes.NET_CALL_ERROR,
			netId,
			error: Format.getFormattedError(error),
		};
	},

	logCrash(error, errorInfo) {
		return {
			type: ActionTypes.LOG_CRASH,
			error: Format.getFormattedError(error),
			errorInfo: Format.getFormattedError(errorInfo),
		};
	},

	pluginAction(pluginName, pluginId, action, data) {
		return {
			type: ActionTypes.PLUGIN_ACTION,
			pluginName,
			pluginId,
			action,
			...data
		};
	},

	pluginActionError(pluginName, pluginId, action, data) {
		return {
			type: ActionTypes.PLUGIN_ACTION_ERROR,
			pluginName,
			pluginId,
			action,
			...data
		};
	},

	pluginError(pluginName, pluginId, errorMsg, actionData, origin) {
		return {
			type: ActionTypes.PLUGIN_ERROR,
			pluginName,
			pluginId,
			errorMsg,
			actionData,
			origin
		};
	},

	pluginSubscriptions(pluginId, subscriptions) {
		return {
			type: ActionTypes.PLUGIN_SUBSCRIPTIONS,
			pluginId,
			subscriptions,
		};
	},

	logRequest(data) {
		return {
			type: ActionTypes.LOG_REQUEST,
			data
		};
	},

};

