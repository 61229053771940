import React from 'react';
import createReactClass from 'create-react-class';

export default createReactClass({
	changeHandler () {
		this.props.onChangeHandler(this.props.action);
	},

	render () {
		if (this.props.displayedInList) {
			return (
				<li className="dropdown-item">
					<label className="flipswitch clearfix">
						<span className="flipswitch-label">{this.props.label}</span>
						<input
							onChange={this.changeHandler}
							type="checkbox"
							className="switch"
							defaultChecked={this.props.isChecked}
						/>
						<span className="switch"></span>
					</label>
				</li>
			);
		} else {
			return (
				<label className="flipswitch clearfix">
					<span className="flipswitch-label">{this.props.label}</span>
					<input
						onChange={this.changeHandler}
						type="checkbox"
						className="switch"
						defaultChecked={this.props.isChecked}
					/>
					<span className="switch"></span>
				</label>
			);
		}
	}
});
