// Polling service for realtime data

// Add a watcher inside the controller where the data is going to be used
//
// $scope.$watch(function () {
//     return APIRealtimeService.dataWrap;
// }, function (rtData) {
//     // data ready to use here
// });

export default function (APIEndpointService, $timeout, $q) {
	'ngInject';

	var defaultIntervalMs = 10000;

	var service = {
		dataWrap: {
			interactionOverview: null,
			interactionOverviewOn: true,
			conversationCurrent: null,
			conversationCurrentOn: true
		},
		start: start,
		stop: stop,
		setIntervalMs: setIntervalMs
	};

	var serviceOn = false;
	var intervalMs = defaultIntervalMs;
	var pendingCallInstance;

	function addPendingCall() {
		pendingCallInstance = $timeout(function () {
			if (serviceOn) {
				makeCall();
				addPendingCall();
			}
		}, intervalMs);
	}

	function makeCall () {
		var calls = {};
		if (service.dataWrap.interactionOverviewOn) {
			calls.interactionOverview = APIEndpointService.interactionOverview.get().$promise;
		}
		if (service.dataWrap.conversationCurrentOn) {
			calls.conversationCurrent = APIEndpointService.conversationCurrent.query().$promise;
		}

		$q.all(calls).then(function (res) {
			if (res.interactionOverview){
				service.dataWrap.interactionOverview = res.interactionOverview;
			}
			if (res.conversationCurrent) {
				service.dataWrap.conversationCurrent = res.conversationCurrent;
			}
		}).catch(() => {});
	}

	// Both endpoints are activated by default, explicitly say false to turn off
	// options = { conversationCurrent: true, interactionOverview: true }
	function start(options) {

		service.dataWrap.conversationCurrentOn = options.conversationCurrent !== false;
		service.dataWrap.interactionOverviewOn = options.interactionOverview !== false;

		if (options.intervalMs) {
			setIntervalMs(options.intervalMs);
		} else {
			setIntervalMs(defaultIntervalMs);
		}

		// If the service is already on, avoid starting double loops
		if (!serviceOn) {
			serviceOn = true;
			makeCall();         // Make first call immediately
			addPendingCall();   // Add pending call to start loop
		}
	}

	function stop() {
		$timeout.cancel(pendingCallInstance);
		serviceOn = false;
	}

	function setIntervalMs(newIntervalMs) {
		intervalMs = newIntervalMs;
	}

	return service;
};
