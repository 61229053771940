import VisitorProfileActions from '../actions/VisitorProfileActions';
import {Store} from '../stores/Store';
import PollRequest from './PollRequest';
import WebAPIUtilsFilters from '../utils/WebAPIUtilsFilters';

export default class VisitorProfileRequest extends PollRequest {
	constructor(reqGuid, restUrl, visitId) {
		super(reqGuid, restUrl);
		this.visitId = visitId;
	}

	get asRequest() {
		return {
			body: {},
			method: 'get',
			tag: this.guid,
			uri: `${this.restUrl}Visit/${this.visitId}`
		}
	}

	resolve(response, statusCode) {
		return new Promise((resolve, reject) => {
			if (statusCode > 399) {
				console.log('<VisitorProfileRequest> ', response);
				reject(response);
			} else {
				resolve(VisitorProfileActions.addVisitorProfile(WebAPIUtilsFilters.getAsVisitorProfile(response)));
			}
		});
	}
}


