import _reject from 'lodash/reject';
import _find from 'lodash/find';

import assign from 'object-assign';
import AppDispatcher from '../dispatcher/CSDispatcher';
import AppConstants from '../../../components/constants/AppConstants';
import StoreFactory from '../../../components/utils/StoreFactory';
import MessageUtils from '../../../components/utils/MessageUtils';
import VisitorProfileClaims from './../utils/VisitorProfileClaims';
import ConversationsStore from './ConversationsStore';

import UserAgentDetection from '../../../common/UserAgentDetection';

const ActionTypes = AppConstants.ActionTypes;

var state = {
	profiles : []
};

window.VisitorProfileStore = {
	state: state
};

var VisitorProfileStore = StoreFactory.create({

	/**
	 * return profile by id ref
	 * @param {String} id
	 * @returns {Object} matched profile or undefined
	 */
	getProfileById (id) {
		return _find(state.profiles, { id: id });
	},

	/**
	 * returns claims of profile
	 * @param {String} id
	 * @returns {Array} claims
	 */
	getProfileClaimsById (id) {
		let profile = this.getProfileById(id);
		if (!profile) {
			return [];
		}
		let profileClaims = profile.claims.filter( c => c.source !== VisitorProfileClaims.sources.VISITORIDENTIFICATION);
		return VisitorProfileClaims.getClaims(profileClaims);
	},

	getTurnServerCredentials (conversationId) {
		const convo = ConversationsStore.find(conversationId);
		if (!convo) {return false;}

		const visitId = convo.participants[0].visitId;
		const profile = this.getProfileById(visitId);

		if (!profile || !profile.claims) {
			return false;
		}
		const result = _find(profile.claims, c => c.type === 'turnServerAuthenticationHash');

		return result && result.values && result.values[0];
	},

	/**
	 * returns identification claims
	 * @param {String} id
	 * @returns {Array} claims
	 */
	getIdentificationClaims (id) {
		let profile = this.getProfileById(id);
		if (!profile) {
			return [];
		}
		return profile.claims.filter( c => c.source === VisitorProfileClaims.sources.VISITORIDENTIFICATION);
	},

	/**
	 * return profile by id ref
	 * @param {String} id
	 * @returns {String} namedIdentity
	 */
	getNamedIdentity (id) {
		let profile = this.getProfileById(id);
		if (!profile) {
			return '';
		}
		return profile.namedIdentity;
	},

	/**
	 * returns profile title
	 * @param {String} id
	 * @returns {String} title
	 */
	getTitle (id) {
		let profile = this.getProfileById(id);
		if (!profile) {
			return '';
		}
		return profile.title;
	},

	getUserAgent(id) {
		let profile = this.getProfileById(id);
		if (!profile) {
			return '';
		}
		return profile.userAgent;
	},

	/**
	 * return authentication level of profile
	 * @param {String} id
	 * @returns {String} authenticationLevel
	 */
	getAuthenticationLevel (id) {
		let profile = this.getProfileById(id);
		if (!profile) {
			return '';
		}
		return profile.authenticationLevel;
	}

});

const registeredCallback = function(payload) {
	const action = payload.action;
	const { conversationId, profile, visitId, claim, claimValue } = action;

	switch (action.type) {

		case ActionTypes.VISITOR_PROFILE:
			let profileToUpdate = VisitorProfileStore.getProfileById(profile.data.id);

			if (!profileToUpdate) {
				addVisitorProfile(MessageUtils.addConversationIdToMsg(profile.data, profile.data.id));
			} else {
				const profileData = profile.data;
				profileToUpdate.claims = profileData.claims;
				profileToUpdate.namedIdentity = profileData.namedIdentity;
				profileToUpdate.authenticationLevel = profileData.authenticationLevel;
				if (!profileToUpdate.userAgent) {
					profileToUpdate.userAgent = UserAgentDetection.detect(profileData.userAgentRaw);
				}
			}
			break;

		case ActionTypes.VISITOR_PROFILE_ADD:
			let profileToAdd = VisitorProfileStore.getProfileById(visitId);
			if (!profileToAdd) {
				addVisitorProfile(MessageUtils.addConversationIdToMsg({
					id: visitId,
					claims: [],
					namedIdentity: '',
					title: ''

				}, visitId));
			} else {
				return false;
			}
			break;

		case ActionTypes.VISITOR_HEADER_TITLE:
			let profileToUpdateTitle = VisitorProfileStore.getProfileById(visitId);
			if (!profileToUpdateTitle) {
				addVisitorProfile(MessageUtils.addConversationIdToMsg({
					id: visitId,
					claims: [],
					namedIdentity: '',
					title: action.title
				}, visitId));
			} else {
				profileToUpdateTitle.title = action.title;
			}
			break;

		case ActionTypes.VISITOR_PROFILE_UDPATECLAIMS:
			let profileToUpdateClaims = VisitorProfileStore.getProfileById(visitId);
			if (!profileToUpdateClaims) {
				return false;
			}

			let existingClaim = false;
			existingClaim = _find(profileToUpdateClaims.claims, {type: claim.type});

			profileToUpdateClaims.claims = profileToUpdateClaims.claims.map( c => {
				if (c.type === claim.type) {
					c.values = [claimValue];
				}
				return c;
			});

			if (!existingClaim) {
				claim.values = [claimValue];
				profileToUpdateClaims.claims.push(claim);
			}

			if (!updateVisitorProfileClaims(visitId, profileToUpdateClaims.claims)) {
				return false;
			}
			break;

		case ActionTypes.VISITOR_PROFILE_CLEAR_IDENTIFIED :
			let profileToClear = VisitorProfileStore.getProfileById(visitId);
			if (!profileToClear || (profileToClear && !profileToClear.claims)) {
				return false;
			}

			state.profiles = state.profiles.map( p => {
				if (p.id === visitId) {
					p.claims = _reject(p.claims, c => c.source === VisitorProfileClaims.sources.VISITORIDENTIFICATION);
				}
				return p;
			});
			break;

		case ActionTypes.CONVERSATION_CLOSED:
			state.profile = _reject(state.profile, p => p.conversationId === conversationId);
			break;

		default :
			return true;
	}

	VisitorProfileStore.emitChange();

};

function updateVisitorProfileClaims (visitId, claims) {
	let exists = _find(state.profiles, {  id : visitId });
	if (exists) {
		exists.claims = claims;
		return true;
	}

	return false;
}

function addVisitorProfile (profile) {
	const pid = profile.id || profile.data.id;
	if (pid === '00000000-0000-0000-0000-000000000000') {
		// VEP-2247: vID will be '00000000-0000-0000-0000-000000000000' for auto-started booked meetings with no joined visitor - do not add these profiles, as they will be mixed up (same GUID)!
		// console.log('addVisitorProfile() vID=00000000-0000-0000-0000-000000000000 - skip');
		return;
	}

	let exists = _find(state.profiles, p => {
		return p.id === pid;
	});

	if (exists) {
		let data = profile;
		if (profile.data) {
			data = profile.data;
		}
		assign(exists, data);
	} else {
		state.profiles.push(profile);
	}
}

VisitorProfileStore.dispatchToken = AppDispatcher.register( registeredCallback );

export default VisitorProfileStore;
