import React from 'react';
import PluginChannel from './PluginChannel.react';
import immutable from 'immutable';

const desktopIsIFramed = window !== window.top;
const PluginContainer = ({plugins, ...props}) => {

	const pluginElements = plugins
		.filter(plugin => plugin.get('enabled'))
		.filter(plugin => {
			const isPinned = plugin.get('displayMode') === 'pinned';
			const plugOut = plugin.get('displayMode') === 'external';
			return !isPinned && (!plugOut || (plugOut && desktopIsIFramed));
		})
		.map(plugin =>
			<PluginChannel key={plugin.get('name')} plugin={plugin} {...props}/>);

	return (
		<div>
			{pluginElements}
		</div>
	);
};

PluginContainer.displayName = 'PluginContainer';
export default PluginContainer;
