import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {hot} from 'react-hot-loader/root';
import KeyRoutes from '../../desktopDeux/utils/KeyRoutes';
import CoBroDesktop from '../components/CoBroDesktopView.react';
import DebugPanel from '../../desktopDeux/components/debugPanel/DebugPanel.react';
import LocalStorage from '../../desktopDeux/stores/LocalStorageWrapper';
import LogActions from '../../desktopDeux/actions/LogActions';
import DesktopDeuxFailed from '../../desktopDeux/components/DesktopDeuxFailedView.react';
import ErrorBoundary from '../../desktopDeux/components/ErrorBoundary.react';
import Lang from '../../desktopDeux/utils/Lang';
import {LangContext} from '../../desktopDeux/components/LangContext';

const debug = LocalStorage.getItem('desktopDeuxDebug') === 'true';

const CoBroDesktopLandingPage = () => {
	useEffect(() => {
		KeyRoutes.resume();
		return () => {
			KeyRoutes.pause();
		};
	}, []);
	const dispatch = useDispatch();
	const logCrash = (error, errorInfo) => dispatch(LogActions.logCrash(error, errorInfo));

	const mockedLocale = useSelector(state => state.getIn(['debugFlags', 'mockedLocale']));
	const desktopStatus = useSelector(state => state.get('desktopStatus'));
	const scope = 'dialog';

	KeyRoutes.setContext(scope);

	const lang = mockedLocale
		? {
			getTranslation(id) {
				return Lang.getTranslation(id);
			}
		}
		: Lang;

	let page;
	switch (desktopStatus) {
		case 'new':
			// pre everything state
			page = null;
			break;
		case 'desktopFailed':
			page = <DesktopDeuxFailed/>;
			break;
		case 'desktop':
			page = <CoBroDesktop/>;
			break;
		default:
			page = <CoBroDesktop/>;
			break;

	}

	return (
		<ErrorBoundary logCrash={logCrash}>
			<LangContext.Provider value={lang}>
				{debug ? <DebugPanel/>: null}
				{page}
			</LangContext.Provider>
		</ErrorBoundary>
	);
};


export default hot(CoBroDesktopLandingPage);


