import React from 'react';
import {Button} from 'react-bootstrap';
import {useGetTranslation} from './../../LangContext';

const ClosureButton = (props) => {
	const getTranslation = useGetTranslation();
	return (
		<span className="finish-btn-container">
				<Button className="finish-btn" variant="primary" size="xs"
						tabIndex="-1"
						id={`button-basic-${0}`}
						onClick={props.showCaseModal}
				>{getTranslation('finishBtn')}...
				</Button>
			</span>
	);
};
export default ClosureButton

