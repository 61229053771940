export default {
	'en-GB': {
		openingHours: {
			PAGE_HEADER: 'Bookable hours',
			PAGE_DESCRIPTION: 'Click on the weekday column to configure the bookable hours for the selected day. ' +
				'You can navigate in the calendar to preview your configured bookable hours',
			OPTION_TOOLTIP: 'Choose configuration period to replace the bookable hours in the selected date range',
			OPTION_DEFAULT: 'Recurring bookable hours',
			OPTION_NEW: 'Create a new override period (e.g. different bookable hours during vacation period)',
			LABEL_FROM: 'From',
			LABEL_TO: 'To (end of day)',
			BTN_REMOVE: 'Remove',
			BTN_CANCEL: 'Cancel',
			BTN_SAVE: 'Save configuration',

			// Modal
			EDIT_RECURRING_TITLE: 'Edit recurring bookable hours',
			EDIT_OVERRIDE_TITLE: 'Edit override period:',
			NEW_OVERRIDE_TITLE: 'New override period:',
			OPEN_BETWEEN_LABEL: 'Open between:',
			BTN_CLOSE: 'Close',
			BTN_PREVIEW: 'Preview',
			MEETING_INTERVALS_LABEL: 'Meeting intervals:',
			MEETING_INTERVAL_TIME_FORMAT: 'minutes',
			SIMULTANEOUS_MEETINGS_LABEL: 'Simultaneous meetings:',
			WEEK_VALIDITY_LABEL: 'Apply to weekday(s):',
			SHORT_MON: 'Mon',
			SHORT_TUE: 'Tue',
			SHORT_WED: 'Wed',
			SHORT_THU: 'Thu',
			SHORT_FRI: 'Fri',
			SHORT_SAT: 'Sat',
			SHORT_SUN: 'Sun',
			EDIT: 'Edit',
			NEW_BLOCK: 'New block',
			EDIT_BLOCK: 'Edit block',
			CREATE_NEW_BLOCK: 'Create new block',
			ADD_BLOCK: 'Add block',
			ON: 'On',
			WORKDAYS: ' workdays',
			WORKDAYS_AND_HOLIDAYS: ' workdays and holidays',
			HOLIDAYS: ' holidays',
			BOOKABLE_BLOCKS: 'Bookable blocks',
			ERROR: 'Error:',
			ERROR_START_END_TIME: 'The block\'s start time is the same or later than the end time.',
			ERROR_EDITED_BLOCK_CONFLICT: 'The block is in conflict with at least one existing block (marked in red).',
			ERROR_NO_DAYS_SELECTED: 'You have to apply the configuration to at least one weekday',
			ERROR_EXISTING_BLOCKS_CONFLICT: 'Conflicting blocks found (marked in red). Please review your blocks.',
			ERROR_INVALID_CONFIG: 'Invalid blocks found (marked in red). Please review your blocks.',
		}
	},
	'sv-SE': {
		openingHours: {
			PAGE_HEADER: 'Bokningsbara tider',
			PAGE_DESCRIPTION: 'Klicka på en veckodag för att konfigurera dess öppettider. Du kan navigera i kalendern ' +
			'för att förhandsgranska de konfigurerade öppettiderna',
			OPTION_TOOLTIP: 'Välj konfigurationsperiod för att ersätta bokningsbara tider i det valda datumintervallet',
			OPTION_DEFAULT: 'Återkommande bokningsbara tider',
			OPTION_NEW: 'Skapa tillfällig period (t.ex. en tillfällig period under semestertid)',
			LABEL_FROM: 'Från',
			LABEL_TO: 'Till (slut av dag)',
			BTN_REMOVE: 'Ta bort',
			BTN_CANCEL: 'Avbryt',
			BTN_SAVE: 'Spara konfiguration',

			// Modal
			EDIT_RECURRING_TITLE: 'Ändra återkommande bokningsbara tider',
			EDIT_OVERRIDE_TITLE: 'Ändra tillfällig period:',
			NEW_OVERRIDE_TITLE: 'Ny tillfällig period:',
			OPEN_BETWEEN_LABEL: 'Öppet mellan:',
			BTN_CLOSE: 'Stäng',
			BTN_PREVIEW: 'Förhandsgranska',
			MEETING_INTERVALS_LABEL: 'Mötesintervall:',
			MEETING_INTERVAL_TIME_FORMAT: 'minuter',
			SIMULTANEOUS_MEETINGS_LABEL: 'Samtidiga möten:',
			WEEK_VALIDITY_LABEL: 'Giltligt dessa dagar:',
			SHORT_MON: 'Mån',
			SHORT_TUE: 'Tis',
			SHORT_WED: 'Ons',
			SHORT_THU: 'Tors',
			SHORT_FRI: 'Fre',
			SHORT_SAT: 'Lör',
			SHORT_SUN: 'Sön',
			EDIT: 'Ändra',
			NEW_BLOCK: 'Nytt block',
			EDIT_BLOCK: 'Ändra block',
			CREATE_NEW_BLOCK: 'Skapa nytt block',
			ADD_BLOCK: 'Lägg till block',
			ON: 'På',
			WORKDAYS: ' vardagar',
			WORKDAYS_AND_HOLIDAYS: ' vardagar och helgdagar',
			HOLIDAYS: ' helgdagar',
			BOOKABLE_BLOCKS: 'Bokningsbara block',
			ERROR: 'Fel:',
			ERROR_START_END_TIME: 'Blockets har samma eller senare start-tid än slut-tid.',
			ERROR_EDITED_BLOCK_CONFLICT: 'Blocket är i konflikt med minst ett annat block (markerade i rött).',
			ERROR_NO_DAYS_SELECTED: 'Du måste applicera konfigurationen på minst en veckodag',
			ERROR_EXISTING_BLOCKS_CONFLICT: 'Konflikt mellan block (markerade i rött). Vänligen kontrollera blocken.',
			ERROR_INVALID_CONFIG: 'Ogiltiga block (markerade i rött). Vänligen kontrollera blocken.',
		}
	}
};
