/*eslint quotes:0 */
export default {
	'conversations': [

		// USER: Bjorn
		{
			"id": "2D34DCA6-DD4A-41D2-8455-110ADA913702",

			// Sales
			"groupId": "8F2D4912-40C9-4808-AA63-0CE5F1399045",
			"visitId": "E3A5D772-0DEF-433D-93A3-B5F5F7C68882",
			//"ownerUserId": "B21DB311-2C7B-4030-A20A-392E32F38D5B",
			"title": "Visitor profile visitor",

			// In queue
			"state": 1,
			"timeSinceRequestConversation": 1373002,
			"timeSinceLock": 1266863,
			"timeSinceTransfer": 0,
			"inTransfer": false,
			"lostByUser": false
		},
		{
			"id": "2D34DCA6-DD4A-41D2-8455-110ADA913702",
			"groupId": "8F2D4912-40C9-4808-AA63-0CE5F1399045",
			"visitId": "E3A5D772-0DEF-433D-93A3-B5F5F7C68882",
			"ownerUserId": "B21DB311-2C7B-4030-A20A-392E32F38D5B",
			"title": "Visitor profile visitor",

			// In dialog
			"state": 4,
			"timeSinceRequestConversation": 1373002,
			"timeSinceLock": 1266863,
			"timeSinceTransfer": 0,
			"inTransfer": false,
			"lostByUser": false
		},

		// USER: Carl-Ola
		{
			"id": "2D34DCA6-DD4A-41D2-8455-110ADA913702",

			// Support
			"groupId": "B305306F-5E26-4AE2-8F9A-E2709C7EBE00",
			"visitId": "E3A5D772-0DEF-433D-93A3-B5F5F7C68882",
			"ownerUserId": "4BD5702D-45F0-42A0-AB89-2ADF7AC2F296",
			"title": "Visitor profile visitor",

			// In queue
			"state": 1,
			"timeSinceRequestConversation": 1373002,
			"timeSinceLock": 1266863,
			"timeSinceTransfer": 0,
			"inTransfer": false,
			"lostByUser": false
		},
		{
			"id": "2D34DCA6-DD4A-41D2-8455-110ADA913702",

			// Support
			"groupId": "B305306F-5E26-4AE2-8F9A-E2709C7EBE00",
			"visitId": "E3A5D772-0DEF-433D-93A3-B5F5F7C68882",
			"ownerUserId": "4BD5702D-45F0-42A0-AB89-2ADF7AC2F296",
			"title": "Visitor profile visitor",

			// In dialog
			"state": 4,
			"timeSinceRequestConversation": 1373002,
			"timeSinceLock": 1266863,
			"timeSinceTransfer": 0,
			"inTransfer": false,
			"lostByUser": false
		},

		// USER: Carl-Ola
		{
			"id": "2D34DCA6-DD4A-41D2-8455-110ADA913702",
			"groupId": "8F2D4912-40C9-4808-AA63-0CE5F1399045",
			"visitId": "E3A5D772-0DEF-433D-93A3-B5F5F7C68882",
			"ownerUserId": "0AFB8B1D-3CC3-4698-BC78-F3E5496E150D",
			"title": "Visitor profile visitor",

			// In dialog
			"state": 4,
			"timeSinceRequestConversation": 1373002,
			"timeSinceLock": 1266863,
			"timeSinceTransfer": 0,
			"inTransfer": false,
			"lostByUser": false
		},
		{
			"id": "2D34DCA6-DD4A-41D2-8455-110ADA913702",
			"groupId": "8F2D4912-40C9-4808-AA63-0CE5F1399045",
			"visitId": "E3A5D772-0DEF-433D-93A3-B5F5F7C68882",
			"ownerUserId": "0AFB8B1D-3CC3-4698-BC78-F3E5496E150D",
			"title": "Visitor profile visitor",

			// In dialog
			"state": 4,
			"timeSinceRequestConversation": 1373002,
			"timeSinceLock": 1266863,
			"timeSinceTransfer": 0,
			"inTransfer": false,
			"lostByUser": false
		}
	],
	conversations2: [
	  {
		"id": "7E02C060-AE53-4516-B0EE-C44789E6E715",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "03B3394D-A0CC-4C64-9881-55EB2E31BD2B",
		"ownerUserId": "4B0C1F6D-C52F-421E-AA05-061B7E494C99",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 115514,
		"durationSinceLockMs": 45843,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "96C4BED6-4D7E-454F-927F-C0A8CBDB55EF",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "84D22EBB-94B0-4099-9D35-5AF754008732",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 114264,
		"durationSinceLockMs": 42468,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "69A0BC61-8E14-419C-9A1A-EAB423831D6C",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "2D672005-093B-4395-BDEB-A72C9CEEE0F2",
		"ownerUserId": "9FDDA91C-4E3E-4EC0-98F5-F106910782A6",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 112545,
		"durationSinceLockMs": 33577,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "696D9CE9-29E1-4DE8-AC33-F9DFE56E4893",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "8F38185E-700E-43BD-9E25-666CFFF7B56D",
		"ownerUserId": "4B0C1F6D-C52F-421E-AA05-061B7E494C99",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 110967,
		"durationSinceLockMs": 19265,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "489D3DF4-8886-42EB-8EB8-0A91D665C175",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "D8BC8588-12A7-4AE8-870F-B5D601752D22",
		"ownerUserId": "62576B94-A5BF-4F85-B01D-10B3468CD776",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 110592,
		"durationSinceLockMs": 11478,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "7F96054A-CC76-4B43-9E85-1B905ECA14BE",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "9468537C-64B1-45B3-A5D7-D360BCE24987",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 109936,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "C1141AC3-3567-4482-8A5F-4544A7E96424",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "4316C8FF-BD8F-4AAB-8B9B-DF43D9884014",
		"ownerUserId": "EB56568F-AEB4-4A59-B9A3-BFAFA3AFB272",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 109873,
		"durationSinceLockMs": 10406,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "8D2E137F-886C-4329-A7EB-DD030F5E160D",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "5557015C-EA79-4A79-8BC2-0ED06F467958",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 107623,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "724D264F-6E31-42BA-940E-87264760C880",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "DC3B9B3C-0F9C-478F-A241-C28FC275668A",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 107186,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "E728D563-1144-4028-ABD6-42A90539879E",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "DC3AD9F6-E1CD-4EF4-84AC-E05B697D6C97",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 105998,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "0E8A1E74-EDFE-46DF-9AC8-3F7F7E70DC77",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "414B8122-AD6B-4F0A-855A-C38EF053CF18",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 105076,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "D0AC5EA8-432C-4EC3-82B1-FE814FC48219",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "565C4240-00D7-45B5-AAE7-B06E25EC4F22",
		"ownerUserId": "3EF5C66B-7014-4862-8FF5-33EE613261CA",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 115529,
		"durationSinceLockMs": 46655,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "40D55F76-43FA-4736-B9F2-EE9C3065E945",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "49FA989F-E235-492C-8E40-CDDF1D9D9556",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 114529,
		"durationSinceLockMs": 42468,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "8A500BAF-5E8D-4D81-87FA-31A6012704D9",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "E58E3896-E7FF-4E27-BF6E-3139A950A8A0",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 114514,
		"durationSinceLockMs": 42468,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "4F12402C-D95C-411A-92F1-DB8D70A39684",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "515AE49C-59EC-4BAF-92E2-DA7E9092329A",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 114607,
		"durationSinceLockMs": 42468,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "DBC4B5FB-B307-473A-9C62-6F493B5E261D",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "080D7C7E-23F4-4138-9E6E-F3EEADFB18E6",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 112717,
		"durationSinceLockMs": 37187,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "130F8C3D-155D-4C90-946F-4776FF8CFE72",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "AF0356D9-5077-477A-8128-823DFD10303B",
		"ownerUserId": "9FDDA91C-4E3E-4EC0-98F5-F106910782A6",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 112576,
		"durationSinceLockMs": 35655,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "02BE94F1-5641-49E4-A350-1962795FC1F8",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "23D93295-2DCF-4046-9F0F-6107E4D398F3",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 112686,
		"durationSinceLockMs": 37187,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "040EFF19-0F66-4767-80CB-030924A32F7E",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "1CDA6E6C-5B21-4A0C-866E-8FBFF425F0CE",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 112686,
		"durationSinceLockMs": 37171,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "9094C683-A7F1-4AFC-AC2E-15B49F313BA7",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "0B31E6F7-C930-44DD-AE7C-DC6B8175142E",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 112701,
		"durationSinceLockMs": 37187,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "507DDFE2-BA7D-4B0D-BD6D-05D71AC7C6C3",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "229FF7B1-FBA0-48E9-8FED-46884DD03254",
		"ownerUserId": "7A87C829-F494-455A-B83B-DDD4C9EC84C3",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 111904,
		"durationSinceLockMs": 28421,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "CBE380DD-4580-41FD-AEB1-8BB0FB1E4B55",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "0372EF15-B457-4A03-B3F8-8609EF8CE62A",
		"ownerUserId": "41380B73-82ED-4BCC-80B5-7A54639241A8",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 111514,
		"durationSinceLockMs": 23218,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "64357595-BB70-495B-A3ED-FD678AAEFF8B",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "FB0DEE5D-CB8B-409F-B3B5-7A0793D2BCE4",
		"ownerUserId": "4B0C1F6D-C52F-421E-AA05-061B7E494C99",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 111108,
		"durationSinceLockMs": 20843,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "6F87C537-1252-457D-9B46-2BB60BBCCED0",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "42CFFB6E-5C74-47C2-AF3E-633BA36B5571",
		"ownerUserId": "41380B73-82ED-4BCC-80B5-7A54639241A8",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 111356,
		"durationSinceLockMs": 21827,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "42020B77-60BD-475B-AE91-94009C2224C2",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "81D87C3C-465B-4F09-80AB-465503255C9C",
		"ownerUserId": "FEC768CF-A143-41C3-BF22-B09AD835A132",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 110967,
		"durationSinceLockMs": 18187,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "E2A635DF-B003-4FC9-A667-9F90D6E7887F",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "D0CF1215-6FB9-4F25-8887-EC4C6C69FB01",
		"ownerUserId": "EB56568F-AEB4-4A59-B9A3-BFAFA3AFB272",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 110529,
		"durationSinceLockMs": 13202,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "0197A1C0-CEB7-4940-B265-B525CB0DB576",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "6127BE7E-E2F6-4F22-B36B-4A2E74E401A3",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 110264,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "5D2C8F44-C83D-4E0D-9724-3192743E96CC",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "1DF43947-B707-4B2D-9747-833DE7EDBB36",
		"ownerUserId": "0E5F4D74-3320-4052-BF7A-7436D36F0DF7",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 110264,
		"durationSinceLockMs": 11000,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "565F3EC9-87AD-4C8E-BC63-19D8DE9221E3",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "3E056B11-8988-47CC-B6F4-2577A86BDA34",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 110108,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "FCBD76EA-FEA8-4C31-B33C-2EBCF22EC459",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "567AB1CF-5741-48D6-8EAB-A2787EA0E56F",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 109717,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "EF3A5583-8073-4406-828B-03142B4A25BC",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "0B142814-635B-4252-8075-CB620332719F",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 108936,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "009B3978-8971-4C00-81F8-445BFF71DB87",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "8A4FDE41-8A33-45E2-88BC-F569698EC42C",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 108733,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "EFA647FD-EF4A-49A9-9C76-E8BDDA52B51E",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "75C3E835-6823-40D6-9BAA-847EBFC74660",
		"ownerUserId": "A0770E14-1181-42EC-87E3-3C720772CB17",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 108249,
		"durationSinceLockMs": 6109,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "05FF8F67-8884-49F8-866F-2522279B9FAC",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "BA483172-C84C-4E48-BCD6-D2F8B8930CA2",
		"ownerUserId": "A0770E14-1181-42EC-87E3-3C720772CB17",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 108045,
		"durationSinceLockMs": 5000,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "896C9554-FF67-4D9B-B70F-87EE6BA16BD4",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "8984E60A-6F1C-49BC-BBFA-0DCE656F4671",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 107061,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "6CF77860-BB6E-476E-B81E-33DB5A830D6D",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "6C882307-3265-4B29-9060-32B40B1CBBE7",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 106608,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "7DC4B0B7-0A55-4DA9-93F8-B019E3F4E430",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "5744AD52-D407-4D25-9202-3B03F3BD8D64",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 106295,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "1224F436-72B9-4DA2-8012-ED6284797AE3",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "A2C7B194-A082-4554-A839-3D3181D487D1",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 106170,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "1A878DFB-E5E7-47DD-B688-68F4BFA44F6E",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "07FC061C-D1EF-4119-9669-E11B1ECBE58A",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 106014,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "D98CA45E-1A36-4149-99BF-6E83A8A424FE",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "B8A00188-7A7A-40D7-B19E-41930C469600",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 105842,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "8EC8BCD7-72AA-4F2E-9A5D-8473A55465B4",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "3E159B42-E4BD-4DFE-B1E9-CE97B966868B",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 105874,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "899D3805-1A20-40A8-B8A5-3E5A11974ED3",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "6CBDF317-762C-421D-8F76-9012192A3183",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 105702,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "13E93959-2DD9-44C6-8462-C2EE2FD60380",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "A856DEE8-F39A-404D-9AC4-B47006437C35",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 105280,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "72C06D4B-ED1D-4BEE-B787-1A1BD405A3C8",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "15AB294E-7450-4538-93A7-88BA9927EEBB",
		"ownerUserId": "3E037048-241E-4E1E-A18E-1C78E1CBE1E3",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 115639,
		"durationSinceLockMs": 47343,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "A5BE6F50-D427-4F57-B1DB-550770592399",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "A7D9D337-038E-4DB6-A908-C9961A35B04E",
		"ownerUserId": "EB56568F-AEB4-4A59-B9A3-BFAFA3AFB272",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 115639,
		"durationSinceLockMs": 46952,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "ECFDAB0D-9B21-4EC9-BDD8-DC3C64127CEE",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "538B5532-7EAE-4800-8434-A375DA103A54",
		"ownerUserId": "3EF5C66B-7014-4862-8FF5-33EE613261CA",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 115452,
		"durationSinceLockMs": 45327,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "DBDF0634-A80C-49D6-B5C4-20A59BF460CD",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "402BF3BE-7A99-4729-98CF-D4CF557B3CE9",
		"ownerUserId": "1A19E1E5-EFE4-44E4-99EC-B7CD715543D7",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 115374,
		"durationSinceLockMs": 42796,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "0A70158D-C21C-43BE-93C7-7E08E8204BED",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "9EC8A03E-1A64-4330-8F1C-1EFD4DE90C7F",
		"ownerUserId": "1DF7C207-6E61-4820-9898-433B9B090FAB",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 115327,
		"durationSinceLockMs": 41187,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "C537A729-14FD-418B-A969-F9741AC76CBD",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "3F9D7023-FF91-4275-BAA8-2672E1BCA9DB",
		"ownerUserId": "344D19F2-DC1B-4EF2-8043-DEFD44FAAACD",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 115264,
		"durationSinceLockMs": 39437,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "C245CE16-9A9D-4802-9617-DC2AADBC0838",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "75741EEB-91A6-437B-BD4D-0D704EC06B5C",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 115123,
		"durationSinceLockMs": 42515,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "570992FE-81FA-491A-95E1-AE7587BEDAAA",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "E8A9388F-B571-4CD1-BD67-E628137D5429",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 115123,
		"durationSinceLockMs": 42515,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "94C6BC8B-A9F0-48DE-B06C-B981AFB56B4E",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "67DB8A95-1C9C-4F57-AD0C-FEF587801F48",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 115123,
		"durationSinceLockMs": 42499,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "0CB73FC2-AC2A-4F6A-A0CE-9653DF2C3991",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "9F29AA9A-CD51-4AA8-8B14-956EEA6FC395",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 114936,
		"durationSinceLockMs": 42499,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "F02AD8E2-7FFD-4824-95EB-417840667E6E",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "A9A852D7-551C-47C0-A8CB-D7014AFE5312",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 114858,
		"durationSinceLockMs": 42499,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "7D84D4A2-6ED2-446A-94B2-E9A9598D87B3",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "690C792D-FAF2-4749-B36F-1D91BD778FC4",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 114889,
		"durationSinceLockMs": 42499,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "B9FCA06A-7740-452E-BEDC-3C1C70AE40FF",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "065B32CD-9FE4-4D0B-85CD-2EB18A07D6EC",
		"ownerUserId": "1A19E1E5-EFE4-44E4-99EC-B7CD715543D7",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 115436,
		"durationSinceLockMs": 43859,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "47319C8B-58A0-46F4-8720-751DDBA483F6",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "1B476E5F-34BA-4FFE-A061-22ED2FD85ECD",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 115030,
		"durationSinceLockMs": 42499,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "426B00C4-15B2-4D05-8B19-6788614C71A5",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "9A1A0E93-B9E0-49E0-87D7-88E745E339C6",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 114670,
		"durationSinceLockMs": 42499,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "DCD754DC-E896-473B-B792-A73BF7BE4990",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "08E0B509-E12D-4EBB-A260-16D93CF82028",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 114655,
		"durationSinceLockMs": 42499,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "998166FE-3EF4-458D-BD0E-6F262ACC3F81",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "AB8DDBED-843D-43B0-8BA0-E18456226856",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 114592,
		"durationSinceLockMs": 42484,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "0292508C-B8F6-444D-8B0E-AF7B42B22F13",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "177F6B0E-3DF4-4212-B82A-57408B53EFCE",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 114405,
		"durationSinceLockMs": 42484,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "142271D9-8253-4312-91B0-2AB46AE0A5A4",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "E536181D-4451-4A69-950C-998241CF8AE2",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 114530,
		"durationSinceLockMs": 42484,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "F58D861B-5D65-4204-A88C-1B02D50930E4",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "33EE59EA-8A8F-4B1C-A403-D02CAA241344",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 114202,
		"durationSinceLockMs": 42468,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "4D7DDDB0-F6F7-400E-B583-6C75EB5256C4",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "85435BE0-B57B-440E-A965-6652F24666E3",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 113998,
		"durationSinceLockMs": 42452,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "BC95CA40-809A-4AB8-86B5-A4720B812809",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "97F1EBB8-5CEB-423D-83A7-6C332F8C3858",
		"ownerUserId": "ACE91653-094F-4AC2-B7EC-4872CD65E3DB",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 113842,
		"durationSinceLockMs": 38265,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "E7D4BF27-38CD-4202-8C58-540543E95A28",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "D329A139-42C0-4E74-AC10-EEFD91595813",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 113998,
		"durationSinceLockMs": 42452,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "C1A3F58A-3BAC-4DB8-A942-2FAD015AE886",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "3A633C4D-243F-41B1-8992-DE363ED887A5",
		"ownerUserId": "FEC768CF-A143-41C3-BF22-B09AD835A132",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 113983,
		"durationSinceLockMs": 40874,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "B88718A1-322A-4AC8-83BA-FD9F4BE0BC37",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "532EA68B-D859-474D-BC4C-60B6C9F019E1",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 113717,
		"durationSinceLockMs": 37218,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "4E12D20F-6469-4692-AAB0-E0387C29CAC5",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "6F35ECF4-CBB0-4D58-A3C2-F0D87C2544D8",
		"ownerUserId": "62576B94-A5BF-4F85-B01D-10B3468CD776",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 113608,
		"durationSinceLockMs": 33249,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "052536B5-59AA-4CE3-9FBD-1A460892CE78",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "339E67CB-FABE-4290-8F83-D0017796277F",
		"ownerUserId": "EB56568F-AEB4-4A59-B9A3-BFAFA3AFB272",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 113967,
		"durationSinceLockMs": 40203,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "4B0B465E-AB01-4FB6-9A1A-C22F68320A80",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "D261A4D1-3019-49D5-86D4-F721D69669F8",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 113530,
		"durationSinceLockMs": 37218,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "2D699C58-A5E6-4041-9830-43E0DD794EA2",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "7548A400-F0C1-4B71-A979-8B8AC833CF25",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 113342,
		"durationSinceLockMs": 37203,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "70DE1090-7072-4C52-8BE4-992AA982B726",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "A0C70FFD-463B-4D81-9D81-5C7F9F5F95D5",
		"ownerUserId": "EB56568F-AEB4-4A59-B9A3-BFAFA3AFB272",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 113936,
		"durationSinceLockMs": 39640,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "36E41680-D2D1-4825-8D83-37FBC062D6C7",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "3826B813-5908-4F1B-8B10-35D133BBBAAB",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 113061,
		"durationSinceLockMs": 37203,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "8D212A5B-1427-41F1-8423-51F9157F1DCF",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "ABD89CD8-6246-40D0-B23B-7A0F7ABE2B60",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 113092,
		"durationSinceLockMs": 37203,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "6D97D907-95EB-4A98-839B-E11682977A75",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "33AF26B1-466A-4217-ACB8-E677FEE45240",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 112952,
		"durationSinceLockMs": 37203,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "A4104C2E-CC19-4C9A-BE48-E1CA215022C9",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "12279DF1-5A03-44BE-8335-5C391505B39F",
		"ownerUserId": "3EF5C66B-7014-4862-8FF5-33EE613261CA",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 112249,
		"durationSinceLockMs": 31796,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "A7ED5C79-F559-4CCE-85FE-F98902EDAC3D",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "2175868D-11FC-4099-BA37-36684D6A5331",
		"ownerUserId": "EB56568F-AEB4-4A59-B9A3-BFAFA3AFB272",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 112108,
		"durationSinceLockMs": 30046,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "EBCD5D92-8F4A-4361-BD9F-68AF06FDC247",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "9F0B2765-C329-415C-AEDB-DFBFB6AFD0B6",
		"ownerUserId": "3EF5C66B-7014-4862-8FF5-33EE613261CA",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 112061,
		"durationSinceLockMs": 29250,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "A7807E5E-699B-48B9-BFFF-999BE03DAC65",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "3722EF40-86C6-4F9E-9C46-BAFD2FEEC735",
		"ownerUserId": "EB56568F-AEB4-4A59-B9A3-BFAFA3AFB272",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 112249,
		"durationSinceLockMs": 31078,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "129E376E-E64B-4B22-8A52-2573D51570FF",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "BEE2C47D-7796-4B3D-969D-0BE118B27040",
		"ownerUserId": "4B0C1F6D-C52F-421E-AA05-061B7E494C99",
		"title": "unspecified",
		"state": 4,
		"durationSinceRequestConversationMs": 111920,
		"durationSinceLockMs": 26906,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "C6D8CA2F-5707-4C03-BBB5-0B8417A37E04",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "32AE6436-3814-417D-B868-228E83723990",
		"ownerUserId": "4B0C1F6D-C52F-421E-AA05-061B7E494C99",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 111905,
		"durationSinceLockMs": 26500,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "73E7B2E0-31EE-4DE2-8E1D-53ADB85075AD",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "F7FF2880-43FC-4B6C-B6DB-FBBED8C80A0F",
		"ownerUserId": "4B0C1F6D-C52F-421E-AA05-061B7E494C99",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 111905,
		"durationSinceLockMs": 25953,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "2B1BC07B-7E5F-4C37-AAC1-94DC03FF1814",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "0645537D-4A89-4073-BCF1-D6C9EDA79D3C",
		"ownerUserId": "ACE91653-094F-4AC2-B7EC-4872CD65E3DB",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 111577,
		"durationSinceLockMs": 25421,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "47172A77-1E23-4062-A05D-7B662FD4B84C",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "B7A2FBCB-F526-4365-B2F5-FECDB53822B9",
		"ownerUserId": "41380B73-82ED-4BCC-80B5-7A54639241A8",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 111577,
		"durationSinceLockMs": 24140,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "0B5EDFD5-9A9C-46A1-A613-C2F13B951E99",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "003BA3F5-97A5-4545-8398-6A88AF866987",
		"ownerUserId": "62576B94-A5BF-4F85-B01D-10B3468CD776",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 110811,
		"durationSinceLockMs": 15125,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "42419EE8-C4C2-4556-AC6C-609660AF37BC",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "F2B5A806-F96F-4ACC-A2D8-9E91D151F796",
		"ownerUserId": "FEC768CF-A143-41C3-BF22-B09AD835A132",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 110983,
		"durationSinceLockMs": 16640,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "BA3249EE-5CFF-4563-BB61-B83B776FCFF6",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "FF9E138F-67BB-4AFD-BF31-E7C1C738E6DE",
		"ownerUserId": "A0770E14-1181-42EC-87E3-3C720772CB17",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 110983,
		"durationSinceLockMs": 16031,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "01175A62-4BE6-4D6E-8259-3C22ED5C6780",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "FAE28150-B4BB-49DE-8FE7-E2D894E3A8CE",
		"ownerUserId": "62576B94-A5BF-4F85-B01D-10B3468CD776",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 110983,
		"durationSinceLockMs": 16250,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "76CAC4CC-2DF7-475E-90A3-C662AE764B8B",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "89295213-16D6-4476-8313-AA4E9EFB6D26",
		"ownerUserId": "3E037048-241E-4E1E-A18E-1C78E1CBE1E3",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 110420,
		"durationSinceLockMs": 12234,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "46B39B19-E15D-4886-92E6-56AAA84B4B2E",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "33DC6699-3F17-497B-9BD9-A3CD0D04CA4C",
		"ownerUserId": "A0770E14-1181-42EC-87E3-3C720772CB17",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 109843,
		"durationSinceLockMs": 8297,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "516C7D56-EC25-4DE2-86A1-30AA6E5FB8A7",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "E43E7C00-A568-4887-A916-B143AAD9C148",
		"ownerUserId": "A0770E14-1181-42EC-87E3-3C720772CB17",
		"title": "unspecified",
		"state": 3,
		"durationSinceRequestConversationMs": 109483,
		"durationSinceLockMs": 7406,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "3B4B59B0-3317-4EC3-8B3A-285CD4430253",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "63A3088E-DCDF-4EA8-B3EF-09B8F7DA40E3",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 107936,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "FDF97CB8-FE54-4812-94DC-3CF9BB9721F1",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "7AFD1EE8-C72F-4C36-AB6A-96078C0076E4",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 108061,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "6FD52D43-A83C-4BAA-8074-2EC32492B0AE",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "C31C32F9-F893-4484-A132-E6B7C587FEEA",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 107780,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "F6AF890F-5BE6-4F6E-BA39-EAA03B2B4D2E",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "5974385C-C073-41F2-A881-FCE7CD04560B",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 107390,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  },
	  {
		"id": "E5D83412-38A1-4AFF-A83F-4B47BE8831F9",
		"groupId": "28188527-5961-49E0-824D-6958916F4C2F",
		"visitId": "241ED6F6-1FFF-4E9B-8082-3D3E097FAF4B",
		"ownerUserId": "00000000-0000-0000-0000-000000000000",
		"title": "unspecified",
		"state": 1,
		"durationSinceRequestConversationMs": 105718,
		"durationSinceLockMs": 0,
		"durationSinceTransferMs": 0,
		"inTransfer": false,
		"lostByUser": false
	  }
	]
}
