import ActionPanelAddModalController from './ActionPanelAddModalController';
import ActionPanelEditController from './ActionPanelEditController';
import ActionPanelListController from './ActionPanelListController';
import ActionPanelsService from './ActionPanelService';
import actionPanelTranslations from './ActionPanelTranslations';
import ActionPanelListTemplate from './ActionPanelListTemplate';
import ActionPanelEditTemplate from './ActionPanelEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.configure.actionPanel', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('ActionPanelAddModalController', ActionPanelAddModalController)
	.controller('ActionPanelEditController', ActionPanelEditController)
	.controller('ActionPanelListController', ActionPanelListController)
	.factory('ActionPanelsService', ActionPanelsService)
	.constant('actionPanelTranslations', actionPanelTranslations)

	.constant('actionPanelModuleConfig', {
		state: 'root.configure.actionPanel',
		access: 'Engage/ActionPanels'
	})

	.config(function ($stateProvider, $translateProvider, actionPanelTranslations, actionPanelModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(actionPanelTranslations);

		$stateProvider.state(actionPanelModuleConfig.state, {
			url: 'actionpanel/',
			controller: 'ActionPanelListController as vm',
			templateUrl: ActionPanelListTemplate,
			data: {
				access: actionPanelModuleConfig.access,
				ncyBreadcrumbLabel: 'Action Panels'
			},
			resolve: {
				dataLists: function (APIConfigurationSectionService, $q) {
					'ngInject';
					return $q((resolve, reject) => {
						$q.all({
							configSections: APIConfigurationSectionService.get([
								APIConfigurationSectionService.sectionMap.actionPanel,
								APIConfigurationSectionService.sectionMap.customerCase,
								APIConfigurationSectionService.sectionMap.action
							], true)
						}).then((result) => {
							resolve({
								actionPanel: result.configSections[APIConfigurationSectionService.sectionMap.actionPanel],
								customerCase: result.configSections[APIConfigurationSectionService.sectionMap.customerCase],
								actions: result.configSections[APIConfigurationSectionService.sectionMap.action]
							});
						}, (error) => {
							console.log('Error resolving lists: ', error);
							reject(error);
						});
					});
				}
			}
		});


		$stateProvider.state('root.configure.actionPanel.edit', {
			url: ':id',
			controller: 'ActionPanelEditController as vm',
			templateUrl: ActionPanelEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				actionPanel: function ($stateParams, dataLists, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(dataLists.actionPanel, $stateParams.id);
				},
				actionList: function (dataLists) {
					'ngInject';
					return dataLists.actions;
				}
			}
		});
	});
