import _find from 'lodash/find';
import _uniq from 'lodash/uniq';

export default function () {
	'ngInject';

	var analyzeStatus = {
		created: 'Created',
		updated: 'Updated',
		removed: 'Removed',
		unchanged: 'Unchanged',
		missing: 'Error: Missing in set'
	};

	var configurationKeys = [
		'configurationSections',
		'groups',
		'pointOfInterests',
		'scripts',
		'sites',
		'translations'
	];

	var service = {
		analyzeSet: analyzeSet,
		revertSet: revertSet,
		combine: combine,
		saveSet: saveSet,
		statuses: analyzeStatus,
		updatedItem: updatedItem
	};

	return service;

	function combine(setReferences, resolvedSet) {

		var result = {};

		configurationKeys.forEach(function (type) {
			result[type] = [];

			var property = setReferences.data[type];
			if (!property) {
				return;
			}

			property.forEach(function (item) {

				var found = _find(resolvedSet[type], function (i) {
					return i.data.id === item.id;
				});

				if (found) {

					result[type].push({
						data: found.data,
						revision: item.revision
					});
				}
			});
		});

		return result;
	}

	function analyzeSet(configurationSet, configurationSetResolved, newestComponents) {
		var configurationSetExtended = angular.copy(configurationSet);

		configurationKeys.forEach(function (componentKey) {
			findNewAndUpdatedItems(configurationSetExtended.data, newestComponents, componentKey);
			findRemovedItems(configurationSetExtended.data, configurationSetResolved, newestComponents, componentKey);
		});

		return {
			configurationSetExtended: configurationSetExtended,
			configurationSetListified: listifySetMap(configurationSetExtended)
		};
	}

	function revertSet(configurationSet, configurationSetResolved, olderComponents) {
		var configurationSetExtended = angular.copy(configurationSet);

		configurationKeys.forEach(function (componentKey) {
			findOlderItems(configurationSetExtended.data, olderComponents, componentKey);
			findRemovedItems(configurationSetExtended.data, configurationSetResolved, olderComponents, componentKey);
		});

		return {
			configurationSetExtended: configurationSetExtended,
			configurationSetListified: listifySetMap(configurationSetExtended)
		};
	}

	function findOlderItems(configuration, older, itemType) {
		if (!older[itemType]) {
			return;
		}

		older[itemType].forEach(function (olderItem) {

			var specificItemType = olderItem.data.type || itemType;

			var mapItem = _find(configuration[itemType], {
				id: olderItem.data.id
			});

			if (!mapItem) {

				var newItem = {
					// data from set
					id: olderItem.data.id,
					revision: undefined,
					// data not to save, but needed for visualization
					status: analyzeStatus.created,
					updatedAt: olderItem.updatedAt,
					userId: olderItem.userId,
					userIdName: '',
					revisionToSave: olderItem.revision,
					revisionNewest: olderItem.revision,
					include: true,
					includeUpdate: false,
					itemName: olderItem.data.name,
					itemType: specificItemType
				};

				configuration[itemType].push(newItem);

			} else {

				mapItem.updatedAt = olderItem.updatedAt;
				mapItem.userId = olderItem.userId;
				mapItem.userIdName = '';
				mapItem.revisionToSave = olderItem.revision;
				mapItem.revisionNewest = olderItem.revision;
				mapItem.include = true;
				mapItem.includeUpdate = true;
				mapItem.status = analyzeStatus.updated;
				mapItem.itemName = olderItem.data.name;
				mapItem.itemType = specificItemType;
			}
		});
	}

	function findNewAndUpdatedItems(configuration, newest, itemType) {
		if (!newest[itemType]) {
			return;
		}

		newest[itemType].forEach(function (latestItem) {

			var specificItemType = latestItem.data.type || itemType;

			var mapItem = _find(configuration[itemType], {
				id: latestItem.data.id
			});

			if (!mapItem) {
				// Item added
				var newItem = {
					// data from set
					id: latestItem.data.id,
					revision: undefined,
					// data not to save, but needed for visualization
					status: analyzeStatus.created,
					updatedAt: latestItem.updatedAt,
					userId: latestItem.userId,
					userIdName: '',
					revisionToSave: latestItem.revision,
					revisionNewest: latestItem.revision,
					include: true,
					includeUpdate: false,
					itemName: latestItem.data.name,
					itemType: specificItemType
				};
				configuration[itemType].push(newItem);
			} else {
				// Item exists in set
				mapItem.updatedAt = latestItem.updatedAt;
				mapItem.userId = latestItem.userId;
				mapItem.userIdName = '';
				mapItem.revisionToSave = latestItem.revision;
				mapItem.revisionNewest = latestItem.revision;
				mapItem.include = true;
				mapItem.includeUpdate = latestItem.revision === mapItem.revision;
				mapItem.status = latestItem.revision !== mapItem.revision ? analyzeStatus.updated : analyzeStatus.unchanged;
				mapItem.itemName = latestItem.data.name;
				mapItem.itemType = specificItemType;
			}
		});
	}

	function findRemovedItems(configurationReferences, configurationSetResolved, newest, itemType) {
		if (configurationReferences[itemType]) {
			configurationReferences[itemType].forEach(setItem => {
				const itemExist = _find(newest[itemType], setComponent => setComponent.data.id === setItem.id);
				if (!itemExist) {
					const resolvedItem = _find(configurationSetResolved[itemType], item => item.data.id === setItem.id);
					if (resolvedItem) {
						setItem.updatedAt = null;
						setItem.status = analyzeStatus.removed;
						setItem.itemName = resolvedItem.data.name;
						setItem.itemType = resolvedItem.data.type || itemType;
						setItem.include = true;
						setItem.includeUpdate = false;
					} else {
						// Item exists in /ConfigurationSet but not in /ConfigurationSet/Resolved
						// This should not happen, but may if CommSrv responds with weird resolved data
						// https://support.psplugin.com/issue/VEP-4289
						setItem.status = analyzeStatus.missing;
						setItem.itemName = setItem.id;
						setItem.itemType = itemType;
					}
				}
			});
		}
	}

	function listifySetMap(configurationSetExtended) {
		var list = [];
		configurationKeys.forEach(function (sectionKey) {
			list = list.concat(configurationSetExtended.data[sectionKey]);
		});
		return list;
	}

	function updatedItem(item) {
		if (angular.isUndefined(item.revision)) {
			// new item
			return true;
		} else if (angular.isUndefined(item.revisionNewest)) {
			// removed item
			return true;
		} else {
			// updated item
			return item.revisionNewest !== item.revision;
		}
	}

	function saveSet(configurationSet, analyzedSet, comments) {
		configurationSet.data.comments = comments;

		configurationKeys.forEach(function (sectionKey) {

			if (!analyzedSet.configurationSetExtended.data[sectionKey]) {
				return;
			}

			var included = analyzedSet.configurationSetExtended.data[sectionKey].filter(function (setItem) {
				return setItem.include;
			});

			included.forEach(function (setItemToInclude) {
				if (setItemToInclude.includeUpdate) {
					if (setItemToInclude.status === analyzeStatus.created) {
						configurationSet.data[sectionKey].push({
							id: setItemToInclude.id,
							revision: setItemToInclude.revisionToSave
						});
					} else if (setItemToInclude.status === analyzeStatus.updated) {

						var found = _find(configurationSet.data[sectionKey], function (setItem) {
							return setItem.id === setItemToInclude.id;
						});

						if (found) {
							found.revision = setItemToInclude.revisionToSave;
						}

					} else if (setItemToInclude.status === analyzeStatus.removed) {

						configurationSet.data[sectionKey] = configurationSet.data[sectionKey].filter(function (setItem) {
							return setItem.id !== setItemToInclude.id;
						});
					}
				}
			});

		});

		// TODO it has happened that duplicates were present, this fixes the symptom
		stripItemDuplicates(configurationSet);
		return configurationSet.$save();
	}

	function stripItemDuplicates(configurationSet) {
		configurationKeys.forEach(function (sectionKey) {
			configurationSet.data[sectionKey] = _uniq(configurationSet.data[sectionKey], 'id');
		});
	}
};
