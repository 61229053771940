import {useState, useRef, useEffect, useCallback} from 'react';
import immutable, {isImmutable} from 'immutable';

const useEventListener = (eventName, handler, element = window) => {
	// Create a ref that stores handler
	const savedHandler = useRef();

	// Update ref.current value if handler changes.
	// This allows our effect below to always get latest handler ...
	// ... without us needing to pass it in effect deps array ...
	// ... and potentially cause effect to re-run every render.
	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {

		// Make sure element supports addEventListener
		// On
		const isSupported = element && element.addEventListener;
		if (!isSupported) {
			return;
		}

		// Create event listener that calls handler function stored in ref
		const eventListener = event => savedHandler.current(event);

		// Add event listener
		element.addEventListener(eventName, eventListener);

		// Remove event listener on cleanup
		return () => {
			element.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element]); // Re-run if eventName or element changes

};

// usage
// useWhyDidYouUpdate('componentName', props);
function useWhyDidYouUpdate(name, props) {
	// Get a mutable ref object where we can store props ...
	// ... for comparison next time this hook runs.
	const previousProps = useRef();

	useEffect(() => {
		if (previousProps.current) {
			// Get all keys from previous and current props
			const allKeys = Object.keys({...previousProps.current, ...props});
			// Use this object to keep track of changed props
			const changesObj = {};
			// Iterate through keys
			allKeys.forEach(key => {
				// If previous is different from current
				if (previousProps.current[key] !== props[key]) {
					// Add to changesObj
					const from = isImmutable(previousProps.current[key])
						? previousProps.current[key].toJS()
						: previousProps.current[key];
					const to = isImmutable(props[key])
						? props[key].toJS()
						: props[key];

					changesObj[key] = {
						from,
						to
					};
				}
			});

			// If changesObj not empty then output to console
			if (Object.keys(changesObj).length) {
				console.log('[why-did-you-update]', name, changesObj);
			}
		}

		// Finally update previousProps with current props for next hook call
		previousProps.current = props;
	});
}

function useDebounce(value, delay) {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		// Update debounced value after delay
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		// Cancel the timeout if value changes (also on delay change or unmount)
		// This is how we prevent debounced value from updating if value is changed ...
		// .. within the delay period. Timeout gets cleared and restarted.
		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]); // Only re-call effect if value or delay changes


	return debouncedValue;
}

export {
	useEventListener,
	useWhyDidYouUpdate,
	useDebounce
};

