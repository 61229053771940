import GroupAddModalTemplate from './GroupAddModalTemplate';
import _find from 'lodash/find';

export default function (groupList, groupTypes, featuresList, $uibModal, $state) {
	'ngInject';

	var vm = this;
	vm.featuresList = featuresList;
	vm.groupList = groupList;
	vm.groupTypes = groupTypes;

	vm.tableColumns = [{
		header: 'Name',
		property: 'data.name',
		sortDefault: true,
		useInFilter: true
	}, {
		header: 'Guid',
		property: 'data.id',
		sortDefault: true,
		useInFilter: true
	}, {
		header: 'Type',
		property: 'data.reservationBehaviour',
		sortDefault: true,
		useInFilter: true,
		transform: (reservationBehaviour => {
			let groupType = _find(groupTypes, function (type) {
				return _find(type.reservationBehaviours, function (behaviour) {
					return behaviour === reservationBehaviour;
				});
			});
			return groupType ? groupType.displayName: 'Normal';
		})
	}];
	vm.tableOptions = {
		stateLink: {
			state: 'root.configure.group.edit',
			property: 'data.id'
		},
		createItem: {
			langKey: 'group.ADD_HEADER',
			method: addNew
		}
	};

	function openAddModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: GroupAddModalTemplate,
			controller: 'GroupAddModalController as vm',
			resolve: {
				accountConfiguration: (APIEndpointService) => {
					'ngInject';
					return APIEndpointService.accountCurrentConfiguration.get().$promise;
				},
				timeZoneList: (TimeZoneService) => {
					'ngInject';
					return TimeZoneService.query();
				},
				groupTypes: () => {
					return vm.groupTypes;
				},
				featuresList: () => {
					return vm.featuresList;
				}
			}
		});
		modal.result.then(newGroup => {
			groupList.push(newGroup);
			$state.go('.edit', {id: newGroup.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		$state.go('root.configure.group').then(openAddModal);
	}
};
