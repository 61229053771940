export default function($uibModalInstance, content, yesHandler) {
	'ngInject';

	var vm = this;
	vm.yesNoSpinner = false;
	vm.content = {...content};

	vm.yes = () => {
		vm.yesNoSpinner = true;
		$uibModalInstance.freeze(true);
		if (yesHandler) {
			yesHandler().then(result => {
				$uibModalInstance.close(result);
			}).catch(error => {
				$uibModalInstance.dismiss(error);
			});
		} else {
			$uibModalInstance.close();
		}
	};
	vm.no = $uibModalInstance.dismiss;
}