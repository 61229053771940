import React from 'react';
import VisitorProfileStore from './../stores/VisitorProfileStore';
import ClaimList from './../components/ClaimList.react';
import EventStore from '../stores/EventStore';
import { injectIntl } from 'react-intl';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import createReactClass from 'create-react-class';

function parseMessage(message) {
	const re = /<%(.+?)%>/g;
	let cursor = 0,
		title = '',
		meetingStartTime = '',
		match;

	const extractTitle = function(line) {
		title += line != '' ? line.replace(/"/g, '\\"') : '';
		title = (title).replace(/[\r\t\n]/g, ' ').trim();
	};

	while (match = re.exec(message)) {
		meetingStartTime = match[1];
		cursor = match.index + match[0].length;
	}
	extractTitle(message ? message.substr(cursor, message.length - cursor) : '');

	return {
		title: title,
		meetingStartTime: meetingStartTime
	};
}

export default injectIntl(createReactClass({

	getInitialState () {
		return getStateFromStores(this.props.conversationId, this.props.visitId);
	},

	componentDidMount () {
		VisitorProfileStore.addChangeListener(this._onChange);
		EventStore.addChangeListener(this._onChange);
	},

	componentWillUnmount () {
		VisitorProfileStore.removeChangeListener(this._onChange);
		EventStore.removeChangeListener(this._onChange);
	},

	_onChange () {
		this.setState(getStateFromStores(this.props.conversationId, this.props.visitId));
	},

	render () {
		const clipboardLabel = this.props.intl.formatMessage({id:'clipboardDefaultTip'});
		const clipboardCls = 'copy-title';
		let cls = '';
		// Ignore "Suggested" authenticationLevel for now
		//switch (this.state.authenticationLevel) {
		//	case 'Identified' :
		//		cls = 'header-identification';
		//		break;
		//	case 'Suggested' :
		//		cls = 'header-identification suggested';
		//		break;
		//}
		let tooltip = (
			<Tooltip id="tooltip">{clipboardLabel}</Tooltip>
		);

		if (this.state.authenticationLevel === 'Identified') {
			cls = 'header-identification ' + clipboardCls;
		}
		const title = parseMessage(this.state.title).title;

		return (
			<div>
				<OverlayTrigger placement="bottom" overlay={tooltip}><span
					className={cls}
					data-clipboard-text={this.state.namedIdentity}
					style={{ marginRight: this.state.namedIdentity ? '7px' : '0' }}
					>
					{this.state.namedIdentity}
				</span></OverlayTrigger>
				<span style={{ marginRight: '7px' }}>{title}</span>
				<ClaimList claims={this.state.claims} />
			</div>
		)
	}
}));

function getStateFromStores(conversationId, visitId) {
	var conversationReservation = EventStore.getReservationOfConversation(conversationId);
	return {
		claims: VisitorProfileStore.getIdentificationClaims(visitId),
		namedIdentity: VisitorProfileStore.getNamedIdentity(visitId),
		title: (conversationReservation && conversationReservation.reservation ? conversationReservation.reservation.title : VisitorProfileStore.getTitle(visitId)),
		authenticationLevel: VisitorProfileStore.getAuthenticationLevel(visitId)
	};
}
