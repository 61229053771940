export default function (crossfilter) {
	'ngInject';

    var service = {
        calculate: calculate
    };

    var ndx = null,
        dimensions = null;

    function create() {
        var dim = {
            by: {}
        };

        dim.by.userId = ndx.dimension(function (d) { return d.userId; });

        return dim;
    }

    function calculate(data) {

        if (!dimensions) {

            ndx = crossfilter(data);
            dimensions = create();

        } else {

            ndx.remove();
            ndx.add(data);
        }

        return dimensions;
    }

    return service;
}
