import DynamicStatisticsBaseTemplate from './DynamicStatisticsBaseTemplate';
import DynamicStatisticsBaseController from './DynamicStatisticsBaseController';

import DynamicStatisticsConfigProvider from './DynamicStatisticsConfigProvider';

import DynamicStatisticsModel from './DynamicStatisticsModelService';
import DynamicStatisticsRenderViewService from './DynamicStatisticsRenderViewService';
import DynamicStatisticsQueryService from './DynamicStatisticsQueryService';

import MetricFormatter from './common/MetricFormatterService';
import weekPicker from './common/weekPicker/weekPicker';
import momentDateFilter from './common/weekPicker/momentDateFilter';
import stickyHeader from './common/stickyHeader/stickyHeader';

export default angular.module('vngageApp.statistics.dynamic', [
		'ui.router',
		'vngageApp.workspaceTranslation'
	])

	.provider('DynamicStatisticsConfig', DynamicStatisticsConfigProvider)
	.factory('DynamicStatisticsModel', DynamicStatisticsModel)
	.factory('DynamicStatisticsRenderViewService', DynamicStatisticsRenderViewService)
	.factory('DynamicStatisticsQueryService', DynamicStatisticsQueryService)
	.factory('MetricFormatter', MetricFormatter)
	.directive('weekPicker', weekPicker)
	.directive('stickyHeader', stickyHeader)
	.filter('momentDateFilter', momentDateFilter)

	.controller('DynamicStatisticsBaseController', DynamicStatisticsBaseController)

	.constant('DynamicStatisticsModuleConfig', {
		state: 'root.statistics.dynamic',
		access: 'Statistics'
	})

	.config(function ($stateProvider, DynamicStatisticsModuleConfig) {
		'ngInject';

		$stateProvider.state(DynamicStatisticsModuleConfig.state, {
			url: 'dynamic/*id',
			templateUrl: DynamicStatisticsBaseTemplate,
			controller: 'DynamicStatisticsBaseController as vm',
			data: {
				access: DynamicStatisticsModuleConfig.access,
				ncyBreadcrumbLabel: 'Statistics'
			}
		});
	})
