export default function() {
    'ngInject';

    return function(userId, userList) {
        var user = null;
        if (userList) {
            angular.forEach(userList, function(currUser) {
                if (currUser.data.id === userId) {
                    user = currUser;
                    return;
                }
            });
        }

        return user ? user.data.profile.displayName : 'N/A';
    };
};
