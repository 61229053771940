import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import ContentUploadTools from '../../../utils/ContentUploadTools';
import Timestamp from './Timestamp.react';
import DownloadModal from './../DownloadModal.react';
import ServerRequests from '../../../actionCreators/ServerRequests';
import LogActions from '../../../actions/LogActions';
import {useGetTranslation} from './../../LangContext';

export const MessageContentUpload = (props) => {
	const getTranslation = useGetTranslation();
	const [modalOpen, setModalOpen] = useState(false);
	const [fileObject, setFileObject] = useState(null);

	const viewContent = async () => {
		const key = props.messageData.key;
		try {
			const data = await ServerRequests.downloadContent(key);
			const {filename, mimeType, fileSize} = props.uploadInfo;
			props.contentLog('downloaded for view', {
				key,
				filename,
				mimeType,
				fileSize
			});
			setFileObject({
				data,
				uploadInfo: props.uploadInfo,
				blobUrl: ContentUploadTools.generateBlobUrl(data)
			});
		} catch (error) {
			props.contentErrorLog('download error', {key, error});
		}
	};

	const showModal = () => {
		setModalOpen(true);
		if (!fileObject) {
			viewContent();
		}
	};

	const hideModal = () => {
		setModalOpen(false);
	};

	useEffect(() => {
		return () => {
			if (fileObject) {
				// clean up to avoid memory leaks
				ContentUploadTools.revokeBlobUrl(fileObject.blobUrl);
			}
		};
	}, [fileObject]);

	const {speaker} = props;
	const messageLangKey = ContentUploadTools.getMessageLangKey(props.uploadInfo.mimeType);
	const truncatedFilename = ContentUploadTools.truncateFilename(props.uploadInfo.filename, 18);
	const messageText = getTranslation(messageLangKey) + ' "' + truncatedFilename + '"';
	const iconClass = ContentUploadTools.getIconClass(props.uploadInfo.mimeType);

	let element;
	switch (props.status) {
		case 'received':
			element = <a className={iconClass}
						 onClick={() => showModal()}>{messageText}</a>;
			break;
		case 'pending':
			element = <span>{getTranslation('sendingFile')} {truncatedFilename}</span>;
			break;
		case 'uploadError':
			element =
				<span className="error">{getTranslation('sendingFileError')} {truncatedFilename}</span>;
			break;
		case 'Virus detected':
			element =
				<span className="error">{getTranslation('sendingFileVirus')} {truncatedFilename}</span>;
			break;
		default:
			element =
				<span className="error">{getTranslation('sendingFileError')} {truncatedFilename}</span>;
			break;

	}

	return (
		<span className="message">
			<strong>{speaker}: </strong>
			{element}
			<Timestamp
				timestamp={props.timestamp}
				isMostRecentMessage={props.isMostRecentMessage}
				displayRelativeDialogTime={props.displayRelativeDialogTime}
			/>
			{modalOpen
				? <DownloadModal
					hideModal={hideModal}
					fileObject={fileObject}
					contentLog={props.contentLog}
				/>
				: null
			}

		</span>
	);
};

function mapStateToProps(state, ownProps) {
	return {uploadInfo: ownProps.messageData.uploadInfo};
}

function mapDispatchToProps(dispatch) {
	return {
		contentLog: (name, params) => dispatch(LogActions.contentLog(name, params)),
		contentErrorLog: (error, params) => dispatch(LogActions.contentErrorLog(error, params)),
	};
}

export default connect(mapStateToProps,
	mapDispatchToProps)(MessageContentUpload);
