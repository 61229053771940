import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useGetTranslation} from './../LangContext';

const NavigationHistoryExpanded = (props) => {
	const getTranslation = useGetTranslation();

	const navigationHistory = props.navigation.map((nav, index) => {
		const marked = nav.get('color') === 'gray' ? '': 'marked';
		const cls = `history-marker ${marked} ${nav.get('color')}`;
		const navigationHistoryExpandedTooltip =
				  <Tooltip id="expanded-navigation-tooltip">
					  <p>{getTranslation('navigationHistoryDescription')} {nav.get('description')}</p>
					  <p>{getTranslation('navigationHistoryURL')} {nav.get('url')}</p>
				  </Tooltip>;

		return (
			<li key={index}>
				<OverlayTrigger placement="left" overlay={navigationHistoryExpandedTooltip}>
					<span>
						<span className={cls}/>
						<span className="history-url">{nav.get('url')}</span>
					</span>
				</OverlayTrigger>
			</li>
		)
	});

	return (
		<ul>
			{navigationHistory}
		</ul>
	);
};
export default NavigationHistoryExpanded

