import uiView from './ui/view';
import Notification from './ui/notification';

class ExpandableTextArea {
	constructor (params) {
		var self = this;

		this.config = {
			maxChars: params.maxChars || 0,
			minChars: params.minChars || 0,
			placeholderText: params.placeholderText || '',
			height: params.height || 40,
			expandedHeight: params.expandedHeight || 120,
			expandWhenTyping: params.expandWhenTyping || true,
			forceMaxLength: params.forceMaxLength || false,
			showCounter: params.showCounter || false,
			onCtrlEnter: params.onCtrlEnter || function () { }
		};


		// setup the onSubmit callback from the options:
		this.onSubmit = (typeof params.onSubmit === 'function') ? params.onSubmit: function (msg) {
			alert(msg);
		};

		this.view = getLayout();

		//this.view.input.setAttribute('spellcheck', false);

		// force so that the user cannot type more than maxChars
		if (this.config.maxChars > 0 && this.config.forceMaxLength) {
			this.view.input.maxLength = this.config.maxChars;
		}
		this.view.input.placeholder = this.config.placeholderText;
		this.view.input.style.minHeight = this.config.height + 'px';

		//removeCounterElement if we don't use it
		if (this.config.showCounter) {
			this.view.counter.innerHTML = this.config.maxChars;
		}


		//disable button on init
		this.view.btn.setAttribute('disabled', 'disabled');
		this.view.btn.addEventListener('click', function () {
			self.send.apply(self);
		});

		this.view.input.addEventListener('keydown', function (e) {
			self.submitOrEnter.call(self, e, function () {
				self.send();
			});
		}, false);

		this.view.input.addEventListener('keyup', function (e) {
			self.typing.apply(self);
		}, false);

		this.view.input.addEventListener('drop', this.update, false);

		return this;
	}

	focus () {
		this.view.input.focus();
	}

	send (message) {
		var msg = (typeof message === 'string') ? message: this.view.input.value;

		//first strip leading and trailing newlines
		//msg = msg.replace(/^[\r\n]+|[\r\n]+$/g, "");
		msg = msg.replace(/(^\s+)/g, '');

		var count = msg.length,
			max = this.config.maxChars,
			min = this.config.minChars;

		var el = this.view.input;

		if (count > min && (count <= max || max === 0)) {
			this.onSubmit(msg);
			this.view.input.value = '';
		} else if (count > max) {
			setTimeout(function(){
				new Notification({
					title: 'Message too long',
					content: `<p>Maximum length is ${max} characters.<br>Your message is ${count} characters.</p><p>Please shorten it a bit and try again.</p>`,
					showAt: el
				});
			}, 100);
		}
	}

	typing (e) {
		var el = this.view.input,
			btn = this.view.btn,
			counter = this.view.counter,
			count = el.value.length,
			max = this.config.maxChars,
			min = this.config.minChars;
		if (count > 0 && (count <= max || max === 0)) {
			btn.removeAttribute('disabled');
			el.className = 'chat-textarea notempty';
			if (this.config.expandWhenTyping) el.style.minHeight = this.config.expandedHeight + 'px';
		} else if (count === 0) {
			el.className = 'chat-textarea empty';
			if (this.config.expandWhenTyping) el.style.minHeight = this.config.height + 'px';
			btn.setAttribute('disabled', 'disabled');
		} else if (count > max && max > 0) {
			if (this.config.forceMaxLength) btn.setAttribute('disabled', 'disabled');
			el.className = 'chat-textarea notempty exceeded';
		}

		if (this.config.showCounter) {
			// >=IE9, otherwise use innerText or innerHTML. TextContent does not trigger a reflow
			//counter.innerHTML = max - count;
			counter.textContent = (max > 0) ? max - count: count;
		}
		return;
	}

	update () {
		//update counter and textarea.
		//called with timeout to ensure we catch dropped text
		var self = this;
		setTimeout(function () {
			self.typing.call(self);
		}, 1);
	}

	setText (msg) {
		msg = msg || '';
		this.view.input.value = msg;
		this.update();
		//move caret to end of message
		setCaretPosition(this.view.input, this.view.input.value.length);
	}

	text (msg) {
		if (typeof msg === 'string') {
			this.setText(msg);
		} else {
			return this.view.input.value;
		}
	}

	submitOrEnter (e, callback) {
		//console.log('key', e.which);
		if (e.which === 13) {
			if (e.ctrlKey) {
				if (typeof this.config.onCtrlEnter === 'function') this.config.onCtrlEnter();
				return false;
			}
			if (!e.shiftKey) {
				e.preventDefault();
				if (typeof callback === 'function') callback();
				return false;
			}
		}
		return;
	}
};

//helper functions to set caret inside a textarea:
function setSelectionRange(input, selectionStart, selectionEnd) {
	try {
		if (input.setSelectionRange) {
			input.focus();
			input.setSelectionRange(selectionStart, selectionEnd);
		}
		else if (input.createTextRange) {
			var range = input.createTextRange();
			range.collapse(true);
			range.moveEnd('character', selectionEnd);
			range.moveStart('character', selectionStart);
			range.select();
		}
	} catch (err) { }
}

function setCaretPosition(input, pos) {
	setSelectionRange(input, pos, pos);
}


function getLayout () {
	return uiView.createNodes({
		reference: 'chat',
		tag: 'div',
		className: 'chat',
		children: [{
			reference: 'content',
			tag: 'div',
			className: 'chat-content',
			children: [{
				reference: 'input',
				tag: 'textarea',
				placeholder: 'Press enter to send and shift+enter for new line. ',
				rows: 2,
				className: 'chat-textarea'
			}, {
				reference: 'btn',
				tag: 'button',
				type: 'button',
				innerHTML: '',
				tabindex: '-1',
				children: [{
					reference: 'counter',
					tag: 'span',
					className: 'charcount'
				}]
			}]
		}, {
			reference: 'toolbar',
			tag: 'div',
			className: 'chat-toolbar cf',
			children: [{
				reference: 'extras',
				tag: 'span',
				className: 'chat-extras'
			}]
		}]
	});
}


export default ExpandableTextArea;
