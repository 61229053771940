import _find from 'lodash/find';
import _reject from 'lodash/reject';

/**
 * filter participants from writing activities
 * @param conversationId
 * @param activities
 * @param writings
 * @returns {*}
 */
export function filterWritings(conversationId, activities, writings) {
	activities.map( (t) => {
		var senderId = t.speaker.userId || t.speaker.visitId;
		t.conversationId = conversationId;
		t.pid = senderId;

		var exists = _find(writings, { pid: senderId });

		if (!!exists) {
			writings = _reject(writings, obj => {
				return obj.pid === exists.pid;
			});
		} else if (t.change === 'set') {
			writings.push(t);
		}
	});

	return writings;
}

export default {
	filterWritings: filterWritings
};
