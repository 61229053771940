import React, {useState, useEffect} from 'react';
import {
	Dropdown,
	Form,
	Button,
	Modal,
	Tooltip,
	OverlayTrigger,
	Col,
} from 'react-bootstrap';
import DotsMenu from './../DotsMenu.react';
import SendPageModal from './SendPageModal.react';
import {useGetTranslation} from './../../LangContext';

const ExtrasMenu = (props) => {
	const getTranslation = useGetTranslation();
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [pageTitle, setPageTitle] = useState(props.pageTitle);

	const hideModal = () => {
		setModalIsOpen(false);
		setPageTitle(props.pageTitle);
	};

	const menuActions = (eventKey) => {
		let actionObj;
		switch (eventKey) {

			case 'toggleHighlightMode':
				props.setCoBroHighlightMode(!props.highlightMode);
				break;

			case 'toggleScale':
				props.toggleCoBroScale();
				break;

			case 'togglePause':
				props.toggleCoBroPause();
				break;

			case 'domUploadRequest':
				props.sendDomUploadRequest();
				break;

			case 'openModal':
				setModalIsOpen(true);
				break;

			case 'hide-modal':
				hideModal();
				break;

			case 'sendAction':
				actionObj = {
					'name': pageTitle,
					'icon': 'webpage',
					'parameter': props.addressbarNavigation.get('url'),
					'itemType': '0'
				};
				props.sendAction(props.conversationId, actionObj);
				hideModal();
				break;

		}
	};

	//componentWillReceiveProps
	useEffect(() => {
		setPageTitle(props.pageTitle);
	}, [props.pageTitle]);

	const ub = props.ubTracking;
	const activeClsPaused = props.paused ? 'active': '';
	const clsPause = `vngage-icon-pause toolbar-item ${activeClsPaused}`;
	const activeClsScale = !props.isScaled ? 'active': '';
	const clsScale = !props.isScaled ? `vngage-icon-line-zoom-out toolbar-item ${activeClsScale}`: 'vngage-icon-line-zoom-in toolbar-item ';
	const activeClsHighlight = props.highlightMode ? 'active': '';
	const clsHighlight = `vngage-icon-highlight toolbar-item ${activeClsHighlight}`;

	const toolTipStyle = dropdownOpen
		? {display: 'none'}
		: {};

	const tooltip = (
		<Tooltip id="toolbar-tooltip" style={toolTipStyle}>
			{getTranslation('coFuncTitle')}
		</Tooltip>);

	const menuPayload = {
		onSelect: menuActions,
		onToggle: setDropdownOpen,
		iconCls: 'menu-icon',
	};

	const modalPayload = {
		modalIsOpen,
		hideModal,
		pageTitle,
		setPageTitle,
		restricted: props.restricted,
		menuActions,
		ubTracking: props.ubTracking
	};

	return (
		<div className="cobro-header-menu">
			<OverlayTrigger placement="bottom" overlay={tooltip} delay={400}>
				<div>
					<DotsMenu {...menuPayload}>
						<Dropdown.Item
							id="white-label-cobrowser-menu-item"
							disabled={props.paused}
							eventKey="toggleHighlightMode"
							ub-track={'cobrowsing-menu:' + ub.highlight}>
							<i className={clsHighlight}
							   ub-track={'cobrowsing-menu:' + ub.highlight}/> {getTranslation('coFuncHighlight')}
						</Dropdown.Item>
						<Dropdown.Item
							id="white-label-cobrowser-menu-item"
							eventKey="toggleScale"
							ub-track={'cobrowsing-menu:' + ub.scale}>
							<i className={clsScale}
							   ub-track={'cobrowsing-menu:' + ub.scale}/> {getTranslation('coFuncScale')}
						</Dropdown.Item>
						<Dropdown.Item
							id="white-label-cobrowser-menu-item"
							eventKey="togglePause"
							ub-track={'cobrowsing-menu:' + ub.pause}>
							<i className={clsPause}
							   ub-track={'cobrowsing-menu:' + ub.pause}/> {getTranslation('coFuncPause')}
						</Dropdown.Item>
						<Dropdown.Item
							id="white-label-cobrowser-menu-item"
							disabled={props.paused}
							eventKey="domUploadRequest"
							ub-track={'cobrowsing-menu:' + ub.domUpload}>
							<i className="vngage-icon-line-download toolbar-item"
							   ub-track={'cobrowsing-menu:' + ub.domUpload}/> {getTranslation('coFuncGetDOM')}
						</Dropdown.Item>
						{props.paused && !props.restricted
							?
							<Dropdown.Item
								id="white-label-cobrowser-menu-item"
								disabled={props.restricted}
								eventKey="openModal"
								ub-track={'cobrowsing-menu:' + ub.sendPage}>
								<i className="vngage-icon-co-browsing toolbar-item"
								   ub-track={'cobrowsing-menu:' + ub.sendPage}/> {getTranslation('coFuncSendPage')}
							</Dropdown.Item>
							: null
						}
					</DotsMenu>
				</div>
			</OverlayTrigger>
			<SendPageModal {...modalPayload}/>
		</div>
	);

};
export default ExtrasMenu;

