export default {
    'en-GB': {
        delegation: {
            LIST_HEADER: 'Account delegation',
            SELECT_ROLE: 'Select role',
			GUID_COLUMN_HEADER: 'GUID',
            DELEGATE: 'Delegate',
            NAME: 'Name'
        }
    },
    'sv-SE': {
        delegation: {
            LIST_HEADER: 'Account delegation',
            SELECT_ROLE: 'Select role',
			GUID_COLUMN_HEADER: 'GUID',
            DELEGATE: 'Delegate',
            NAME: 'Name'
        }
    }
};
