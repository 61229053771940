import _cloneDeep from 'lodash/cloneDeep';

const desktopPluginsTemplate = {
	desktopPlugins: {
		pluginsList: []
	}
};

export default {

	updateSubscriptions(plugin, subscriptions, availableSubscriptions) {
		if (plugin.sectionButtonType === 'button' && !subscriptions.find(item => item.refValue === 'sectionButtonClicked')) {
			// Add necessary subscriptions if button is enabled
			const sectionButtonClickedSubscription = availableSubscriptions.find(item => item.refValue && item.refValue.refValue === 'sectionButtonClicked');
			subscriptions.push(sectionButtonClickedSubscription.refValue);
		} else if (plugin.sectionButtonType !== 'button' && subscriptions.find(item => item.refValue === 'sectionButtonClicked')) {
			// Remove button-specific subscriptions if button is disabled
			const i = subscriptions.findIndex(item => item.refValue === 'sectionButtonClicked');
			if(i !== -1) {
				subscriptions.splice(i, 1);
			}
		}
		return this.updatePluginProperty(plugin, 'enabledSubscriptions', subscriptions.map(item => item.refValue));
	},

	updateActions(plugin, actions, availableActions) {

		if (plugin.sectionButtonType === 'button' && !actions.find(item => item.refValue === 'setSectionButtonState')) {
			// Add necessary actions if button is enabled
			const setSectionButtonStateAction = availableActions.find(item => item.refValue && item.refValue.refValue === 'setSectionButtonState');
			actions.push(setSectionButtonStateAction.refValue);
		} else if (plugin.sectionButtonType !== 'button' && actions.find(item => item.refValue === 'setSectionButtonState')) {
			// Remove button-specific actions if button is disabled
			const i = actions.findIndex(item => item.refValue === 'setSectionButtonState');
			if(i !== -1) {
				actions.splice(i, 1);
			}
		}
		return this.updatePluginProperty(plugin, 'enabledActions', actions.map(item => item.refValue));
	},

	updateGroups(plugin, groups) {
		return this.updatePluginProperty(plugin, 'enabledGroups', groups.map(item => item.refValue));
	},

	updateActionMessageMappings(plugin, mappings) {
		return this.updatePluginProperty(plugin, 'mappedActionMessages', mappings.map(item => {
			return {
				id: item.id,
				actionMessageName: item.actionMessageName,
				actionId: item.actionId
			}
		}));
	},

	updatePluginProperty(plugin, propertyName, propertyData) {
		const newPlugin = JSON.parse(JSON.stringify(plugin));
		newPlugin[propertyName] = propertyData;
		return newPlugin;
	},

	formatActionMessageMappings(mappings = [], actionsList = []) {
		return mappings.map(item => {
			const action = actionsList.find(action => action.data.id === item.actionId);
			if (action) {
				return {
					id: item.id,
					actionMessageName: item.actionMessageName,
					actionId: item.actionId,
					actionName: action.data.name,
					itemTypeName: action.data.section.itemTypeName
				};
			} else {
				console.error('Warning! ActionMessageMapping refers to unknown Action:',item);
				return item;
			}
		});
	},




	formatSelectableItems(itemList) {
		return itemList.map(item => {
			const name = item.data && item.data.name
				? item.data.name
				: item;
			const value = item.data && item.data.id
				? item.data.id
				: name;
			return {
				refValue: {
					refValue: value,
					displayName: name
				},
				displayName: name
			};
		});
	},

	formatSelectedItems(itemList) {
		itemList = itemList.filter(item => !!item); // Cleanup: Remove null-values if exists...
		return itemList.map(item => {
			if (typeof item === 'object' && item.refValue) { // Cleanup: Try to fix invalid items...
				item = item.refValue;
			}
			return {
				refValue: item,
				displayName: item
			};
		});
	},

	formatSelectedGroupItems(itemList, groupList = []) {
		return itemList.map(item => {
			const groupData = groupList.find(group => group.data.id === item);
			const displayName = (groupData || {data: {name: ''}}).data.name;
			return {
				refValue: item,
				displayName
			};
		});
	},

	addOrUpdateDesktopPluginsList(pluginsList, plugin) {
		pluginsList = _cloneDeep(pluginsList);
		const pluginIndex = pluginsList.findIndex(p => p.id === plugin.id);
		if (pluginIndex === -1) {
			// add new
			pluginsList.push(plugin);
		} else {
			// if plugin exists, overwrite it
			pluginsList[pluginIndex] = plugin;
		}
		return pluginsList;
	},

	deleteFromDesktopPluginsList(pluginsList, pluginId) {
		return pluginsList.filter(p => p.id !== pluginId);
	}
}

