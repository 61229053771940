import React, {useState} from 'react';
import UploadModal from './UploadModal.react'

const UploadButton = (props) => {
	const [showModal, setShowModal] = useState(false);
	const disabledCls = props.disabled ? 'disabled': '';
	const cls = `message-toolbar-btn content-file-symbol ${disabledCls}`;
	return (
		<React.Fragment>
			<button
				className={cls}
				tabIndex="-1"
				id="contentUpload"
				disabled={props.disabled}
				onClick={() => setShowModal(true)}
				ub-track="open-file-upload"
			/>
			{showModal
				? <UploadModal
					hideModal={() => setShowModal(false)}
					conversationId={props.conversationId}
				/>
				: null
			}
		</React.Fragment>
	)

};
export default UploadButton

