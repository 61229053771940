import themeFrame from './directives/themeFrame';
import vngageColorPickerWatcher from './directives/vngageColorPickerWatcher';
import ThemeAddModalController from './ThemeAddModalController';
import ThemeEditController from './ThemeEditController';
import ThemeListController from './ThemeListController';
import themeTranslations from './ThemeTranslations';
import ThemeListTemplate from './ThemeListTemplate';
import ThemeEditTemplate from './ThemeEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';
import panelSettingsDefaults from '../panelSettings/PanelSettingsDefaults';
import _find from 'lodash/find';

export default angular.module('vngageApp.account.theme', ['ui.router', 'colorpicker.module', 'vngageApp.workspaceTranslation'])

	.directive('themeFrame', themeFrame)
	.directive('vngageColorPickerWatcher', vngageColorPickerWatcher)
	.controller('ThemeAddModalController', ThemeAddModalController)
	.controller('ThemeEditController', ThemeEditController)
	.controller('ThemeListController', ThemeListController)
	.constant('themeTranslations', themeTranslations)
	.constant('panelSettingsDefaults', panelSettingsDefaults)

	.constant('themeModuleConfig', {
		state: 'root.account.theme',
		access: 'Engage/Themes'
	})

	.config(function ($stateProvider, $translateProvider, themeTranslations, themeModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(themeTranslations);

		$stateProvider.state(themeModuleConfig.state, {
			url: 'theme/',
			controller: 'ThemeListController as vm',
			templateUrl: ThemeListTemplate,
			data: {
				access: themeModuleConfig.access,
				ncyBreadcrumbLabel: 'Theme'
			},
			resolve: {
				themeList: function (APIConfigurationSectionService) {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.theme);
				}
			}
		});

		$stateProvider.state('root.account.theme.edit', {
			url: ':id',
			controller: 'ThemeEditController as vm',
			templateUrl: ThemeEditTemplate,
			data: {ncyBreadcrumbLabel: 'Edit'},
			params: {currentList: []},
			resolve: {
				theme: function ($stateParams, themeList, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(themeList, $stateParams.id);
				},
				panelSettings: function (APIConfigurationSectionService, panelSettingsDefaults) {
					'ngInject';
					var type = APIConfigurationSectionService.sectionMap.panelSettings;
					return APIConfigurationSectionService.get(type).then(function (res) {
						var panelSettings = _find(res, function (r) {
							return r.data.siteId === '00000000-0000-0000-0000-000000000000';
						});
						return (panelSettings && panelSettings.data && panelSettings.data.section ? panelSettings.data.section : {});
					});
				}
			}
		});
	});
