export default {
    'en-GB': {
        pointOfInterest: {
            EDIT_HEADER: 'Edit point of interest',
            ADD_HEADER: 'New point of interest',
            LIST_HEADER: 'Points of interest',
            LIST_TITLE: 'List of point of interests for working site',
            LIST_DESCRIPTION: 'Point of interests (POI) are special measure points. It can be a certain URL, something that matches when a script is executed, or an element on the page. The point of interest can be referenced from an opportunity.',
            NAME: 'Name',
            TYPE: 'Type',
            DESCRIPTION: 'Description',
            KEY: 'Type of point of interest',
            VALUE: 'Value',
            TAGS: 'Tags',
            KEY_HTML: 'HTML',
            KEY_URL: 'URL Match',
            KEY_PATTERN: 'URL Pattern match',
            KEY_SCRIPT: 'JavaScript',
            KEY_PURCHASE: 'Purchase confirmation',

            REMOVE_HEADER: 'Remove point of interest "{{ name }}"',
            REMOVE_MESSAGE: 'Note that any existing references to this point of interest from opportunities also will be removed.'
        }
    },
    'sv-SE': {
        pointOfInterest: {
            EDIT_HEADER: 'Edit point of interest',
            ADD_HEADER: 'New point of interest',
            LIST_HEADER: 'Points of interest',
            LIST_TITLE: 'List of point of interests for working site',
            LIST_DESCRIPTION: 'Point of interests (POI) are special measure points. It can be a certain URL, something that matches when a script is executed, or an element on the page. The point of interest can be referenced from an opportunity.',
            NAME: 'Name',
            TYPE: 'Type',
            DESCRIPTION: 'Description',
            KEY: 'Type of point of interest',
            VALUE: 'Value',
            TAGS: 'Tags',
            KEY_HTML: 'HTML',
            KEY_URL: 'URL Match',
            KEY_PATTERN: 'URL Pattern match',
            KEY_SCRIPT: 'JavaScript',
            KEY_PURCHASE: 'Purchase confirmation',

            REMOVE_HEADER: 'Remove point of interest "{{ name }}"',
            REMOVE_MESSAGE: 'Note that any existing references to this point of interest from opportunities also will be removed.'
        }
    }
};
