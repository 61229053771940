export default function () {
	'ngInject';

	return {
		restrict: 'A',
		link: function (scope, element, attrs) {
			const onChangeFunc = function () {
				scope.vm.fileSelected();
			};
			element.bind('change', onChangeFunc);
		}
	};

}
