import immutable from 'immutable';
import {conversationReducerLib as lib} from './conversationReducerLib';
import {sharedReducerLib} from './sharedReducerLib';
import FakeNameHelper from '../../utils/FakeNameHelper';
import AccountConfigurationWrapper from '../../utils/AccountConfigurationWrapper';
import {Conversation, Participant, Profile, Reservation, Visitor} from '../../utils/Records';
import * as constants from './../../constants';
import vngageGuid from './../../../../common/vngageGuid/vngageGuidService';


let refNumber = 0;

function removeFocus(state) {
	state = state.set('activePanel', '');
	return state;
}

function checkNr(state) {
	if (!state.has('nr')) {
		state = state.set('nr', 0);
	}
	return state;
}


function removeAllFakePanels(state) {
	state = removeFocus(state);
	FakeNameHelper.reset();
	state = state.set('conversations', immutable.fromJS({}));

	state = lib.updatePanelOrder(state);
	state = state.set('activePanel', '');
	return state;
}

function removeFakePanel(state, conversationId) {
	if (state.get('activePanel') === conversationId) {
		state = removeFocus(state);
	}
	FakeNameHelper.remove(conversationId);
	state = state.removeIn(['conversations', conversationId]);

	state = lib.updatePanelOrder(state);
	if (conversationId === state.get('activePanel')) {
		state = state.set('activePanel', '');
	}
	return state;

}

function addFakePanel(state, conversationId, conversationState) {
	const visitId = conversationId;
	let dummyEvents = [
		{
			'groupId': '8F2D4912-40C9-4808-AA63-0CE5F1399045',
			'createdAt': '2016-01-11T10:39:42.77Z',
			'type': 'associatedWithGroup',
			'eventId': 3,
			'createdRaw': 1452508782770
		},
		{
			'caseId': 'case0',
			'createdAt': '2015-11-03T12:32:02.747Z',
			'type': 'associatedWithCase',
			'eventId': 4
		},
		{
			'groupId': '3B292958-3703-45E9-B04C-1C6F20525EDF',
			'createdAt': '2016-03-22T08:16:40.594Z',
			'type': 'associatedWithGroup',
			'eventId': 3
		},
		{
			'siteId': 'FC2F75CA-4086-444C-B6D4-0A2FEBCB468C',
			'queueType': 'queue',
			'opportunityId': 'C6FD0600-22C6-4836-871F-4CF872CE88CE',
			'solutionId': '850E4113-5E06-4D06-80F6-16DC6085D417',
			'eventId': 1,
			'title': '-',
			'type': 'visitorRequestConversation',
			'createdAt': '2016-04-20T07:43:22.499Z'
		}
	];

	dummyEvents[0].groupId = state.get('myGroupAvailability').keyOf(true);
	conversationState.events = dummyEvents;
	const participants = {
		'F129E4AA-14FC-4844-9236-8A4E37DFC647': {
			'id': 'BEC26E1A-C57A-4FA4-BCD4-49272E85F4DF',
			'connectionState': 'ok',
			visitId,
			'state': 'joined',
			'info': {
				'owner': false,
				'titleId': 'visitor',
				'role': 'visitor'
			},
			'addedAuthorizations': [],
			'removedAuthorizations': [],
			'createdRaw': null
		},
		'EB5EB3E1-6AE9-475C-927D-5E6B091914CD': {
			'id': '74E69789-73DC-4BC1-8157-2343BEB985B0',
			'connectionState': 'ok',
			'userId': 'EB5EB3E1-6AE9-475C-927D-5E6B091914CD',
			'state': 'joined',
			'info': {
				'name': 'Anders C',
				'owner': true,
				'titleId': 'agent',
				'role': 'agent'
			},
			'addedAuthorizations': [],
			'removedAuthorizations': [],
			'createdRaw': null
		}
	};

	const caseType = '95AA4E3B-AA49-40AB-9645-87ACF31BA450';//'FBAE3DAC-7491-41DC-A429-D08BCD3150D9';
	const conversation = {
		me: conversationId,
		conversationStatus: 'open',
		refNumber: 1234,
		connectionStatus: 'ok',
		events: conversationState.events,
		participants,
		title: {message: 'Title'},
		messages: [],
		activities: [],
		isTyping: false,
		routingType: 0,
		groupId: '3B292958-3703-45E9-B04C-1C6F20525EDF',
		caseId: '',
		visitId,
		currentCaseType: caseType,
		solutionId: '850E4113-5E06-4D06-80F6-16DC6085D417',
		forms: [],
		claimFieldsPatch: {},
		UI: {
			userIsTyping: false,
			panelHeaderColor: lib.getNewPanelHeaderColor(state),
			activeSections: {},
			unreadMessages: 0,
			oldestMessageRanking: 8,
			inputMenuIsVisible: false,
			currentMessage: conversationState.currentMessage || '',
			currentNote: '',
			newCaseAfterClose: false,
			emailConversationTranscript: false,
			isMessageCopied: false,
			updateInputText: 0,
			selectedClosure: '',
			currentInput: 'input',
			eligibleUntil: '',
			panelPosition: 'normal',
			pluginButtons: {},
		}
	};

	state = state.setIn(['conversations', conversationId], immutable.fromJS(conversation));

	state = lib.updatePanelOrder(state);
	state = sharedReducerLib.removeSelectAnimation(state, visitId);

	state = state.update('nbrOfDialogsSoFar', n => n + 1);
	state = lib.updateUserTyping(state, conversationId);
	state = lib.setOldestMessageRanking(state);

	return state;
}

function addFakeMessage(state) {
	const wordList1 = ['jag', 'du', 'vi'];
	const wordList2 = ['är', 'var', 'äter', 'hämtar'];
	const wordList3 = ['katter', 'lingon', 'mackor', 'skåningar'];

	const longMsg = 'Bacon ipsum dolor amet meatloaf chuck t-bone, venison ball tip picanha pastrami turducken short loin bacon pork belly pancetta fatback pig. Tail ribeye sirloin landjaeger pig kielbasa andouille shoulder sausage.';

	const w1 = wordList1[Math.floor(Math.random() * wordList1.length)];
	const w2 = wordList2[Math.floor(Math.random() * wordList2.length)];
	const w3 = wordList3[Math.floor(Math.random() * wordList3.length)];
	const sentence = w1 + ' ' + w2 + ' ' + w3 + ' ' + longMsg;

	state = checkNr(state);
	let nr = state.get('nr');

	let conversationKeys = Object.keys(state.get('conversations').toJS());
	conversationKeys.forEach(key => {
		const createdRaw = Date.now() + nr;
		const createdAt = new Date(createdRaw).toString();
		const fakeMessage = {
			'id': key + nr,
			'messageType': 'chat',
			'message': sentence,
			'isRead': false,
			'recipients': [
				{
					'userId': key
				}
			],
			'speaker': {
				'visitId': key
			},
			'tags': [],
			createdAt,
			createdRaw,
			'type': 'conversationMessage',
			'conversationId': key
		};
		nr++;
		let messages = state.getIn(['conversations', key, 'messages']).push(immutable.fromJS(fakeMessage));
		//messages = messages.sortBy( (msg, key) => msg.get('createdRaw'));
		state = state.setIn(['conversations', key, 'messages'], messages);
		state = lib.updateNrOfUnreadMessages(state, key);
		state = state.set('nr', nr);
	});

	state = lib.setOldestMessageRanking(state);
	return state;
}

function addFakeSystemMessage(state) {
	state = checkNr(state);
	let nr = state.get('nr');

	let conversationKeys = Object.keys(state.get('conversations').toJS());
	conversationKeys.forEach(key => {
		const urls = ['run', 'kill', 'submit', 'derp'];
		const url = urls[Math.floor(Math.random() * urls.length)];
		const createdRaw = Date.now() + nr;
		const createdAt = new Date(createdRaw).toString();
		const fakeSystemMessage = {
			'url': `http://localhost:9011/${url}/`,
			'classification': 'ok',
			'bidirectional': true,
			'id': key + nr,
			'messageType': 'navigation',
			'recipients': [
				{
					'userId': key
				}
			],
			'speaker': {
				'visitId': key
			},
			'tags': [],
			createdAt,
			createdRaw,
			'type': 'conversationMessage',
			'conversationId': key
		};
		nr++;
		let messages = state.getIn(['conversations', key, 'messages']).push(immutable.fromJS(fakeSystemMessage));

		state = state.setIn(['conversations', key, 'messages'], messages);
		state = lib.updateNrOfUnreadMessages(state, key);
		state = lib.setOldestMessageRanking(state);
		state = state.set('nr', nr);
	});
	return state;

}

function toggleFakeTyping(state) {
	state.get('panelOrder').forEach((v, k) => {
		state = state.updateIn(['conversations', v, 'UI', 'userIsTyping'], n => !n);
	});
	return state;
}

function addFakeVisitorProfile(state, conversationId, whatToShow) {
	if (!conversationId) {
		return state;
	}
	let claims = [];
	switch (whatToShow) {
		case 'id':
			claims.push({
				'type': 'PNR',
				'values': ['197701011234'],
				'source': 'http://schemas.vergic.com/api/visitorClaimSource/VisitorIdentification'
			});
			break;

		case 'profile_id_url':
			claims = claims.concat([{
				'type': 'url',
				'values': ['http://www.vergic.com'],
				'source': 'http://schemas.vergic.com/api/visitorClaimSource/VisitorIdentification'
			}]);
		// yes deliberate fallthrough
		case 'profile_id':
			claims = claims.concat([{
				'type': 'PNR',
				'values': ['197701011234'],
				'source': 'http://schemas.vergic.com/api/visitorClaimSource/VisitorIdentification'
			}]);
		// yes deliberate fallthrough
		case 'profile':
			claims = claims.concat([
				{
					'type': 'phone',
					'values': ['123456'],
					'source': 'http://schemas.vergic.com/api/visitorClaimSource/Visitor'
				}, {
					'type': 'email',
					'values': ['anders@vergic.com'],
					'source': 'http://schemas.vergic.com/api/visitorClaimSource/Visitor'
				}, {
					'type': 'customerNumber',
					'values': ['1233'],
					'source': 'http://schemas.vergic.com/api/visitorClaimSource/Visitor'
				}
			]);
			break;
	}
	const profile = {
		'userId': '00000000-0000-0000-0000-000000000000',
		'data': {
			'id': conversationId,
			'authenticationLevel': 'Identified',
			'namedIdentity': '197701011234',
			'userAgentRaw': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_4) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/50.0.2661.94 Safari/537.36',
			claims
		},
		'revision': 2,
		'updatedAt': '2016-05-10T08:19:50.4902426Z',
		'meta': {
			'os': {
				'osName': 'Mac',
				'osx': true,
				'win': false,
				'tablet': false,
				'phone': false,
				'touch': false,
				'deviceName': 'Desktop'
			}, 'browser': {'webkit': true, 'version': '50.0.2661.94', 'chrome': true, 'name': 'Chrome'}
		}
	};
	return sharedReducerLib.setVisitorProfile(state, profile);
}

function addFakeVisitor(state) {
	state = checkNr(state);
	const nr = state.get('nr');
	const fakeVisitorCount = state.get('visitors').reduce((sum, visitor) => sum + (visitor.get('id').length < 20 ? 1: 0), 0);
	const visitId = FakeNameHelper.peek(fakeVisitorCount);
	const visitor = {
		'id': visitId + nr,
		'groupId': '85090669-7549-4200-BB72-58AD6F0885E6',
		visitId,
		'visitRevision': 0,
		'ownerUserId': '00000000-0000-0000-0000-000000000000',
		'title': visitId + nr,
		'state': 1,
		'durationSinceRequestConversationMs': 6031,
		'durationSinceLockMs': 0,
		'durationSinceTransferMs': 0,
		'durationSinceStateChangeMs': 6031,
		'inTransfer': false,
		'lostByUser': false
	};

	state = state.update('nr', n => n + 1);
	const visitors = state.get('visitors').push(immutable.fromJS(visitor));
	return state.set('visitors', visitors);
}

function addFakeReservation(state) {

	const conversationId = vngageGuid().generate();
	const caseType = vngageGuid().generate();
	const caseId = vngageGuid().generate();
	const userId = vngageGuid().generate();
	const accountId = vngageGuid().generate();
	const participantId = vngageGuid().generate();
	const opportunityId = vngageGuid().generate();
	const solutionId = vngageGuid().generate();
	const siteId = vngageGuid().generate();
	const visitId = vngageGuid().generate();
	const currentCaseType = vngageGuid().generate();
	const groupId = vngageGuid().generate();
	const userName = 'Test';
	const maker = new FakeMaker();

	state = state.setIn(['cases', conversationId], caseType);

	// make account config
	const config = maker.makeAccountConfig({groupId, currentCaseType, siteId});
	AccountConfigurationWrapper.setMock(config);
	AccountConfigurationWrapper.enableMock();

	// make visitor
	let visitor = maker.makeVisitorProps({visitId, groupId});

	// make agent profile
	let id = vngageGuid().generate();
	let profileProps = maker.makeProfileProps({id, userId});
	let profile = Profile.parse(profileProps);
	state = state.mergeIn(['visitorProfiles', id], profile);

	// make visitor profile
	profileProps = maker.makeProfileProps({id: visitId});
	profile = Profile.parse(profileProps);
	state = state.mergeIn(['visitorProfiles', visitId], profile);

	let conversation = maker.makeConversation({userId, visitId, currentCaseType, solutionId, groupId});

	const invitedEvent = maker.makeInvitedEvent({userId});
	conversation = Conversation.addEvent(conversation, invitedEvent);

	const reservation = maker.makeReservation({
		accountId,
		groupId,
		siteId,
		solutionId,
		opportunityId,
		conversationId,
		caseId,
		caseType
	});
	const reservationEvent = maker.makeReservationEvent(reservation);
	conversation = Conversation.addImmutableEvent(conversation, reservationEvent);

	// add agent participant
	let participantProps = maker.makeParticipantProps({userId});
	let participantInfoProps = maker.makeUserInfoProps({name: userName, owner: false});
	let participant = Participant.parse(participantProps);
	participant = Participant.setInfo(participant, participantInfoProps);
	conversation = Conversation.addParticipant(conversation, participant);

	// add visitor participant
	participantProps = maker.makeParticipantProps({visitId});
	participantInfoProps = maker.makeUserInfoProps({name: 'visitor name', owner: false});
	participant = Participant.parse(participantProps);
	participant = Participant.setInfo(participant, participantInfoProps);
	conversation = Conversation.addParticipant(conversation, participant);

	state = state.setIn(['conversations', conversationId], conversation);

	state = lib.updatePanelOrder(state);
	state = lib.updateUserTyping(state, conversationId);
	state = lib.setOldestMessageRanking(state);

	return state;
}

function addFakeConversation(state) {

	const conversationId = vngageGuid().generate();
	const caseType = vngageGuid().generate();
	const userId = vngageGuid().generate();
	const solutionId = vngageGuid().generate();
	const siteId = vngageGuid().generate();
	const visitId = vngageGuid().generate();
	const currentCaseType = vngageGuid().generate();
	const groupId = vngageGuid().generate();
	const userName = 'Test';
	const maker = new FakeMaker();

	state = state.setIn(['cases', conversationId], caseType);

	// make account config
	const config = maker.makeAccountConfig({groupId, currentCaseType, siteId});
	AccountConfigurationWrapper.setMock(config);
	AccountConfigurationWrapper.enableMock();

	let id = vngageGuid().generate();
	let profileProps = maker.makeProfileProps({id, userId});
	let profile = Profile.parse(profileProps);
	state = state.mergeIn(['visitorProfiles', id], profile);

	// make visitor profile
	profileProps = maker.makeProfileProps({id: visitId});
	profile = Profile.parse(profileProps);
	state = state.mergeIn(['visitorProfiles', visitId], profile);

	let conversation = maker.makeConversation({userId, visitId, currentCaseType, solutionId, groupId});

	const invitedEvent = maker.makeInvitedEvent({userId});
	conversation = Conversation.addEvent(conversation, invitedEvent);

	// add agent participant
	let participantProps = maker.makeParticipantProps({userId});
	let participantInfoProps = maker.makeUserInfoProps({name: userName, owner: false});
	let participant = Participant.parse(participantProps);
	participant = Participant.setInfo(participant, participantInfoProps);
	conversation = Conversation.addParticipant(conversation, participant);

	// add visitor participant
	participantProps = maker.makeParticipantProps({visitId});
	participantInfoProps = maker.makeUserInfoProps({name: 'visitor name', owner: false});
	participant = Participant.parse(participantProps);
	participant = Participant.setInfo(participant, participantInfoProps);
	conversation = Conversation.addParticipant(conversation, participant);

	state = state.setIn(['conversations', conversationId], conversation);
	state = lib.updatePanelOrder(state);
	state = lib.updateUserTyping(state, conversationId);
	state = lib.setOldestMessageRanking(state);

	return state;
}

class FakeMaker {

	constructor() {

		this.eventId = 0;
		this.refNumber = 999999;
		this.titlePosition = 0;
	}

	makeAccountConfig({groupId, caseType, siteId, solutionId}) {

		let config = {
			groupIds: [groupId],
			groups: {},
			customerCases: {},
			solutions: {},
			account: {
				deactivatePhoneToPs: false,
				displayCaseHistory: true,
				notificationOnProactive: false,
				PDFcapture: true,
				alwaysMailToThisEmailOnDialogClose: '',
				chatMessagePendingTimeoutForVisitor: 10,
				coBrowserNoFollow: [],
				enableVideoChat: true,
				hideQueue: false,
				notificationDuration: 2,
				notificationOnChatMessages: true,
				oldSoundPlayer: false,
				displayVisitorProfile: true,
				useWebRTC: true,
				muteDesktopAudio: false,
				messageTimestampDisplayAsCountdown: true
			},
			user: {
				profile: {
					displayName: 'Anders Corlin',
					publicDisplayName: 'Anders C',
					preferredEmail: 'andersc@vergic.com',
					language: 'sv-SE',
					timeZoneId: 'Europe/Berlin'
				},
				groupIds: [groupId]
			}
		};

		config.groups[groupId] = {
			name: 'mock group',
			caseTypes: [caseType]
		};

		config.customerCases[caseType] = {
			id: caseType,
			siteId,
			section: {
				actionPanels: [],
				cannedResponses: [],
				closures: [
					'closure0'
				]
			}
		};

		config.solutions[solutionId] = {
			id: solutionId,
			name: 'solution',
			siteId,
			section: {
				isProactive: true,
				caseTypeId: caseType,
				groupId,
				messageToCustomerCase: 'hello world',
				bannerId: null,
				bannerIdGroupBusy: null,
				bannerIdGroupClosed: null,
				bannerIdGroupOpen: null
			}
		};

		return config;
	}

	makeConversation({

						 visitId = vngageGuid().generate(),
						 userId = vngageGuid().generate(),
						 currentCaseType = vngageGuid().generate(),
						 solutionId = vngageGuid().generate(),
						 groupId = vngageGuid().generate()
					 }) {

		const conversationProps = {

			me: userId,
			solutionId,
			groupId,
			visitId,
			currentCaseType,
			conversationStatus: constants.ConversationStatus.PENDING_START,
			connectionStatus: constants.ConnectionState.OK,
			stateTime: Date.now(),
			refNumber: this.refNumber,
			title: 'test',
			browserInfo: 'browser',
			flowState: constants.FlowStates.LOADING
		};

		return Conversation.parse(conversationProps);
	}

	makeReservation(
		{
			accountId = vngageGuid().generate(),
			groupId = vngageGuid().generate(),
			siteId = vngageGuid().generate(),
			solutionId = vngageGuid().generate(),
			opportunityId = vngageGuid().generate(),
			conversationId = vngageGuid().generate(),
			caseId = vngageGuid().generate(),
			caseType = vngageGuid().generate()
		}
	) {

		const props = {

			accountId,
			groupId,
			siteId,
			solutionId,
			opportunityId,
			conversationId,
			caseId,
			caseType,
			title: 'reservation title',
			name: 'firstname',
			lastname: 'lastname',
			email: 'mathias@vergic.com',
			start: Date.now() + 1000 * 10,
			created: Date.now(),
			duration: 1000 * 60 * 10,
			phone: '012-34567890',
			comment: 'reservation comment',
			cancelationMessage: 'reservation cancelled',
			hasReplied: false,
			pendingReplied: false,
			cancelled: false
		};

		let reservation = Reservation.parse(props);

		reservation = this.addFormToReservation(reservation, constants.ReservationFormValidation.TIME);
		reservation = this.addFormToReservation(reservation, constants.ReservationFormValidation.LETTERS);
		reservation = this.addFormToReservation(reservation, constants.ReservationFormValidation.PHONE);
		reservation = this.addFormToReservation(reservation, constants.ReservationFormValidation.EMAIL);

		return reservation;
	}

	addFormToReservation(reservation, type) {

		let props = null;

		switch (type) {

			case constants.ReservationFormValidation.PHONE:

				props = this.makePhoneFormProps();
				break;

			case constants.ReservationFormValidation.LETTERS:

				props = this.makeLettersFormProps();
				break;

			case constants.ReservationFormValidation.EMAIL:

				props = this.makeEmailFormProps();
				break;

			case constants.ReservationFormValidation.TIME:

				props = this.makeTimeFormProps();
				break;
		}

		if (!props) {

			return;
		}

		props.titlePosition = this.titlePosition;

		this.titlePosition++;

		return Reservation.addForm(reservation, props);
	}

	makeEmailFormProps(
		name  = 'email',
		label = 'email',
		value = 'mathias@vergic.com'
	) {

		return {

			type: constants.ReservationFormType.TEXT,
			validateAs: constants.ReservationFormValidation.EMAIL,
			name,
			label,
			value
		};
	}

	makeLettersFormProps(
		name  = 'letters',
		label = 'letters',
		value = '說定標香不語知要相有'
	) {

		return {

			type: constants.ReservationFormType.TEXT,
			validateAs: constants.ReservationFormValidation.LETTERS,
			name,
			label,
			value
		};
	}

	makePhoneFormProps(
		name  = 'phone',
		label = 'phone',
		value = '012-34567890'
	) {

		return {

			type: constants.ReservationFormType.TEXT,
			validateAs: constants.ReservationFormValidation.PHONE,
			name,
			label,
			value
		};
	}

	makeTimeFormProps(
		name  = 'time',
		label = 'time',
		value = Date.now()
	) {

		return {

			type: constants.ReservationFormType.TEXT,
			validateAs: constants.ReservationFormValidation.TIME,
			name,
			label,
			value
		};
	}

	makeEvent(type) {

		const event = {

			type: type,
			eventId: this.eventId,
			createdAt: Date.now()
		};

		this.eventId++;

		return event;
	}

	makeReservationEvent(reservation) {

		let event = this.makeEvent(constants.ConversationEventTypes.CONVERSATION_RESERVATION);
		let immutableEvent = immutable.Map(event);

		immutableEvent = immutableEvent.set('reservation', reservation);

		return immutableEvent;
	}

	makeInvitedEvent({

						 participantId = vngageGuid().generate(),
						 userId = vngageGuid().generate(),
						 userName = 'participant name',
						 eligibleUntil = Date.now() + 1000 * 60 * 60
					 }) {

		let event = this.makeEvent(constants.ConversationEventTypes.PARTICIPANT_INVITED);

		event.participationId = participantId;
		event.participant = {
			userId: userId
		};
		event.info = this.makeUserInfoProps({userName});
		event.eligableUntil = eligibleUntil;

		return event;
	}

	makeEventOfType({type, ...props}) {

		let event = this.makeEvent(type);

		switch (type) {

			case constants.ConversationEventTypes.CONVERSATION_CREATED:

				event.refNumber = this.refNumber;
				event.conversationType = constants.ConversationType.INTERACTIVE;
				break;

			case constants.ConversationEventTypes.VISITOR_REQUEST_CONVERSATION:

				event.queueType = constants.QueueType.QUEUED;
				event.opportunityId = props.opportunityId;
				event.solutionId = props.solutionId;
				event.siteId = props.siteId;
				event.title = 'Fake title';
				break;

			case constants.ConversationEventTypes.ASSOCIATED_WITH_GROUP:

				event.groupId = props.groupId;
				break;

			case constants.ConversationEventTypes.CONVERSATION_KEEP_ALIVE:

				event.until = Date.now() + 1000 * 60 * 60;
				break;

			case constants.ConversationEventTypes.ASSOCIATED_WITH_CASE:

				event.caseId = props.caseId;
				break;

			case constants.ConversationEventTypes.PARTICIPANT_CONNECTION_STATUS:

				event.connectionState = constants.ConnectionState.LOST;
				break;

			case constants.ConversationEventTypes.PARTICIPANT_LEFT:

				event.participationId = this.participantId;
				event.participant = {
					userId: props.userId
				};
				event.reason = 'connectionLost';
				break;

			case constants.ConversationEventTypes.RELEASE_TO_GROUP:

				break;
		}

		return event;
	}

	makeParticipantProps({

							 id = vngageGuid().generate(),
							 userId = vngageGuid().generate(),
							 visitId = '',
							 connectionState = constants.ConnectionState.OK,
							 state = constants.ParticipantState.JOINED,
							 name = 'name',
							 owner = false,
							 titleId = 'agent',
							 role = constants.ParticipantRole.AGENT,
							 addedAuthorizations = [],
							 removedAuthorizations = []
						 }) {

		return {

			id,
			userId,
			visitId,
			connectionState,
			state,
			addedAuthorizations,
			removedAuthorizations,
			info: {
				name,
				owner,
				titleId,
				role,
			}
		};
	}

	makeUserInfoProps({

						  name = 'name',
						  owner = false,
						  titleId = 'agent',
						  role = constants.ParticipantRole.AGENT
					  }) {

		return {

			name,
			owner,
			titleId,
			role
		};
	}

	makeProfileProps({

						 userId = vngageGuid().generate(),
						 id = vngageGuid().generate(),
						 authenticationlevel = 'Identified',
						 namedIdentity = '197701011234',
						 userAgentRaw = 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_11_4) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/50.0.2661.94 Safari/537.36',
						 claims = [],
						 revision = 2,
						 updatedAt = '2016-05-10T08:19:50.4902426Z',
						 osName = 'Mac',
						 osx = true,
						 win = false,
						 tablet = false,
						 phone = false,
						 touch = false,
						 deviceName = 'Desktop',
						 webkit = true,
						 browserVersion = '50.0.2661.94',
						 chrome = true,
						 browserName = 'Chrome'
					 }) {

		return {

			userId,
			revision,
			updatedAt,
			data: {
				id,
				authenticationlevel,
				namedIdentity,
				userAgentRaw,
				claims
			},
			meta: {
				os: {
					osName,
					osx,
					win,
					tablet,
					phone,
					touch,
					deviceName
				},
				browser: {
					webkit,
					version: browserVersion,
					chrome,
					name: browserName
				}
			}
		};
	}

	makeClaimOfType(type) {

		switch (type) {

			case 'phone':

				return {
					type: 'phone',
					values: ['123456'],
					source: 'http://schemas.vergic.com/api/visitorClaimSource/Visitor'
				};

			case 'email':

				return {
					type: 'email',
					values: ['m@il.se'],
					source: 'http://schemas.vergic.com/api/visitorClaimSource/Visitor'
				};
		}
	}

	makeVisitorProps({

						 id = vngageGuid().generate(),
						 visitId = vngageGuid().generate(),
						 groupId = vngageGuid().generate()
					 }) {

		return {
			id,
			groupId,
			visitId,
			visitRevision: 0,
			ownerUserId: '',
			title: '',
			state: 0,
			durationSinceRequestConversationMs: 0,
			durationSinceLockMs: 0,
			durationSinceTransferMs: 0,
			durationSinceStateChangeMs: 0,
			inTransfer: false,
			lostByUser: false
		};
	}
}


export const fakeLib = {
	removeFocus,
	checkNr,
	removeAllFakePanels,
	removeFakePanel,
	addFakePanel,
	addFakeMessage,
	addFakeSystemMessage,
	toggleFakeTyping,
	addFakeVisitorProfile,
	addFakeVisitor,
	addFakeReservation,
	addFakeConversation,
};