import moment from 'moment-timezone';
import GroupOpeningHoursEditModalTemplate from './GroupOpeningHoursEditModalTemplate';

export default function(group, $stateParams, $uibModal, GroupOpeningHoursService) {
    'ngInject';

	var vm = this;
	vm.resourceId = $stateParams.id;
	vm.group = group;
	vm.editOpeningHours = editOpeningHours;
	vm.addNewEvent = addNewEvent;
	vm.calendarEvents = [];

	refreshEvents();

	function editOpeningHours(options) {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: GroupOpeningHoursEditModalTemplate,
			controller: 'GroupOpeningHoursEditModalController as vm',
			resolve: {
				group: function () {
					'ngInject';
					return vm.group;
				},
				options: function () {
					'ngInject';
					return options;
				}
			}
		});
		modal.result.then(() => {
			refreshEvents();
		}).catch(() => {
			// Modal dismissed...
		});
	}

	vm.calendarConfig = {
		height: 595,
		editable: false,
		slotDuration: '01:00:00',
		firstDay: 1,
		defaultView: 'agendaWeek',
		header: {
			left: '',
			center: '',
			right: ''
		},
		dayClick: dayClickHandler,
		eventClick: eventClickHandler,
		allDaySlot: false,
/*
		axisFormat: 'H:mm',
		timeFormat: {
			agendaWeek: 'H:mm'
		},
		columnFormat: {
			week: 'dddd'
		},
*/
		// The formatting config above is deprecated in fullcalendar as per v2.2.4, and removed in v3
		// New config style below:
		slotLabelFormat: 'H:mm',
		views: {
			week: {
				timeFormat: 'H:mm',
				columnFormat: 'dddd'
			}
		}
	};

	function dayClickHandler(event) {
		editOpeningHours({
			newAllocation: true,
			momentObj: event
		});
	}

	function eventClickHandler(event) {
		editOpeningHours({
			newAllocation: false,
			allocationIndex: event.originalIndex
		});
	}

	function addNewEvent() {
		editOpeningHours({
			newAllocation: true,
			momentObj: moment()
		});
	}

	function refreshEvents() {
		vm.calendarEvents.splice(0, 1);
		vm.calendarEvents.push(GroupOpeningHoursService.formatTimeAllocsForCalendar(vm.group.data.timeAllocations));
	}
};
