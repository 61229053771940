import vngageQueueOverviewTemplate from './vngageQueueOverviewTemplate';
import formatDuration from '../../../common/formatDuration';

export default function (monitorConversationsService) {
	'ngInject';

	return {
		restrict: 'A',
		scope: {
			statistics: '='
		},
		templateUrl: vngageQueueOverviewTemplate,
		link: function (scope) {
			scope.formatDuration = formatDuration;
			scope.monitorConversationsService = monitorConversationsService;
		}
	};
}
