import _find from 'lodash/find';
import _clone from 'lodash/clone';
import _fill from 'lodash/fill';
import GroupFeaturesDefault from './GroupFeatures/GroupFeaturesDefault';

export default function ($uibModalInstance, APIEndpointService, APIConfigurationSectionService, featuresList, AccountSitesService, Session, accountConfiguration, timeZoneList, groupTypes, $q) {
	'ngInject';

	var vm = this;
	vm.featuresList = featuresList;
	vm.timeZoneList = timeZoneList;
	vm.groupTypes = groupTypes;
	vm.cancelNew = $uibModalInstance.dismiss;
	vm.saveNew = saveNew;

	const alwaysOpenTimeAllocations = _fill(new Array(7), null).map((o, i) => {
		return _clone({
			dayOfWeek: i,
			duration: '23:59',
			groupAllocationType: 0,
			name: '',
			startTime: '00:00'
		});
	});

	vm.newData = {
		dialogOptions: {
			startMessage: '',
			signature: '',
		},
		reservationBehaviour: 0,
		timeZone: accountConfiguration.data.timeZoneId || 'UTC',
		timeAllocations: [],
	};

	function saveNew() {
		vm.isSaving = true;
		$uibModalInstance.freeze(true);
		vm.newData.siteId = AccountSitesService.getSelectedSiteId();

		let groupType = _find(groupTypes, function (type) {
			return _find(type.reservationBehaviours, function (behaviour) {
				return behaviour === vm.newData.reservationBehaviour;
			});
		});

		if (groupType && groupType.id === 'queueReservation') {
			// If this is a queueReservations group, make sure it's "regular" opening hours are always open...
			vm.newData.timeAllocations = alwaysOpenTimeAllocations;
		}

		APIEndpointService.group.create(vm.newData).$promise
			.then(function (res) {
				let additionalSettings = [];
				if (groupType && groupType.id === 'queueReservation') {
					// If this is a queueReservations group, also create an empty reservationsOpeningHoursConfig object...
					let r = {
						groupId: res.data.id,
						openingHours: [
							{openHourEvents: []},
							{openHourEvents: []},
							{openHourEvents: []},
							{openHourEvents: []},
							{openHourEvents: []},
							{openHourEvents: []},
							{openHourEvents: []}
						],
						siteId: vm.newData.siteId,
						accountId: Session.account.id
					};
					additionalSettings.push(APIEndpointService.reservationsOpeningHourConfigs.create(r).$promise);
				}

				// add group features
				const featureData = {
					...GroupFeaturesDefault.newSettings
				};

				featureData.name = vm.newData.name;
				featureData.section.groupId = res.data.id;

				additionalSettings.push(APIConfigurationSectionService.create(featureData, APIConfigurationSectionService.sectionMap.group, true));

				$q.all(additionalSettings
				).then(function (res) {
					vm.featuresList.push(res[res.length - 1]);
				}, function (err) {
				});

				$uibModalInstance.close(res);
			})
			.catch(function () {
				$uibModalInstance.freeze(false);
				vm.isSaving = false;
			});
	}
};
