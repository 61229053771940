export default [
	{
		id: 'normal',
		displayName: 'Normal',
		reservationBehaviours: [0],
		extraSettingsTabName: ''
	},
	{
		id: 'queueReservation',
		displayName: 'Queue Reservation',
		reservationBehaviours: [1,2],
		extraSettingsTabName: 'Queue Reservation Settings'
	}
]
