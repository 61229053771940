export default function ($state, $stateParams) {
	'ngInject';

	return {
		reload: function () {
			return $state.transitionTo($state.current, $stateParams, {
				reload: true,
				inherit: true,
				notify: true
			});
		}
	};
};
