import assign from 'object-assign';
import ConversationsStore from '../stores/ConversationsStore';
import GuidGenerator from '../../../components/utils/GuidGenerator';
import VisitorProfileClaims from './VisitorProfileClaims';
import ConversationRequest from '../requests/ConversationRequest';
import ConversationSinceRequest from '../requests/ConversationSinceRequest';
import ServerActions from '../actions/ServerActions';

let $http, $restUrl, $batchRestUrl, shouldPoll = false;

const REQUESTS = new Map();
const INITIAL_REQUESTS = new Map();
const BATCH_RETRIES = 200;  // On fail: Retry max 200 polls ( = 10 minutes) as a emergency safety break to not overload servers on server failure

let initialConversationRequests = { items: [] };
let initialConversationGroup = false;
let batchDidRetries = 0;

var WebApiUtils = {

	init (http, vngageConfig) {
		$http = http;
		$restUrl = vngageConfig.restUrl;
		$batchRestUrl = vngageConfig.batchRestUrl;

		shouldPoll = true;
		this.poll();
	},

	/**
	 * poll method
	 * starts a poll where it grabs all conversations from ConversationsStore
	 * and sends a batch request every 3 seconds
	 */
	poll () {
		REQUESTS.clear();

		if (!shouldPoll) {
			return false;
		}

		ConversationsStore.all.forEach( (value, key) => {
			var reqGuid;
			if (!value.initialized) {
				reqGuid = GuidGenerator.create();
				REQUESTS.set(reqGuid, new ConversationRequest(reqGuid, $batchRestUrl, key));
			} else {
				reqGuid = GuidGenerator.create();
				REQUESTS.set(reqGuid, new ConversationSinceRequest(reqGuid, $batchRestUrl, key, value.since));
			}
		});

		var requestItems = {
			items: []
		};

		for (var item of REQUESTS.values()) {
			requestItems.items.push(item.asRequest);
		}

		if (requestItems.items.length > 0) {
			let resolvePromises = [];
			$http.post($restUrl + 'Batch', JSON.stringify(requestItems))
				.then(response => {
					batchDidRetries = 0;

					if (!shouldPoll) {
						return false;
					}

					response.data.items.forEach( item => {
						let requestItem = REQUESTS.get(item.tag);
						resolvePromises.push(requestItem.resolve(item.body, item.statusCode));
					});

					requestItems = {
						items: []
					};

					Promise.all(resolvePromises)
						.then( response => {
							setTimeout( () => {
								this.poll();
							}, 3000);
						}, error => {
							console.log('resolvePromises rejected', error);
							setTimeout( () => {
								this.poll();
							}, 3000);
						});

				}, err => {
					if (batchDidRetries < BATCH_RETRIES) {
						batchDidRetries++;
						setTimeout( () => {
							this.poll();
						}, 3000);
					} else {
						// Reload after 10 minutes of failure (200 polls)
						location.reload();
					}
				});
		} else {
			setTimeout( () => {
				this.poll()
			}, 3000);
		}

	},

	/**
	 * makes a batch request for all ConversationRequests passed in
	 * @param reqs
	 */
	makeInitialConversationRequests (reqs) {
		return new Promise((resolve, reject) => {
			$http.post($restUrl + 'Batch', JSON.stringify(reqs))
				.then(response => {
					if (!shouldPoll) { return false; }

					let resolvePromises = [];
					response.data.items.forEach(item => {
						let requestItem = INITIAL_REQUESTS.get(item.tag);
						if (requestItem) {
							resolvePromises.push(requestItem.resolve(item.body, item.statusCode));
							INITIAL_REQUESTS.delete(item.tag);
						}
					});

					Promise.all(resolvePromises)
						.then(response => {
							resolve(response);
						}, err => {
							reject(err);
						});
				});
		});
	},

	/**
	 * request inital /Conversation request
	 * holds to 100 ms before calling makeInitialConversationRequests
	 * @param conversationId
	 */
	requestConversationStart (conversationId) {
		return new Promise((resolve, reject) => {
			const reqGuid = GuidGenerator.create();
			INITIAL_REQUESTS.set(reqGuid, new ConversationRequest(reqGuid, $batchRestUrl, conversationId));
			let req = INITIAL_REQUESTS.get(reqGuid);

			initialConversationRequests.items.push(req.asRequest);
			if (!initialConversationGroup) {
				initialConversationGroup = true;
				setTimeout(() => {
					let reqs = assign({}, initialConversationRequests);
					initialConversationRequests.items = [];
					this.makeInitialConversationRequests(reqs)
						.then(response => {
							resolve(response);
						}, err => {
							reject(err);
						});
					initialConversationGroup = false;
				}, 100);
			}
		});
	},

	conversationStart (conversationId) {
		return $http.post(`${$restUrl}Conversation/${conversationId}/Start`);
	},

	conversationClose (conversationId) {
		return $http.post(`${$restUrl}Conversation/${conversationId}/Close`);
	},

	/**
	 * create chat conversationMessage
	 * @param message
	 * @param conversationId
	 */
	createMessage (message, conversationId) {
		let createdMessage = assign(message, {
			type: message.type || 'conversationMessage'
		});

		$http.post(`${$restUrl}Conversation/${conversationId}/Message`,createdMessage)
			.then( response => {
				//ServerActions.receiveCreatedMessage(response.data);
			}, err => {
				console.log('error posting message', err);
			});
	},

	/**
	 * create activity conversationMessage
	 * @param conversationId
	 * @param data
	 */
	createActivityMessage (conversationId, data) {
		let createdMessage = assign({
			messageType: 'activity',
			type: 'conversationMessage'
		}, data);

		$http.post(`${$restUrl}Conversation/${conversationId}/Message`,createdMessage)
			.then( response => {
				// ServerActions.receiveCreatedMessage(response.data);
			}, err => {
				console.log('error posting activity', err);
			});
	},

	/**
	 * leave conversation
	 * @param conversationId
	 * @param data
	 */
	leaveConversation (conversationId) {
		//this.dispose(conversationId);
		$http.post(`${$restUrl}Conversation/${conversationId}/Leave`)
			.then( response => {
				//shouldPoll = false;
				// ServerActions.conversationLeave();
			}, err => {
				console.log('error leaving conversation', err);
			});
	},

	/**
	 * add or remove authorization of participant in conversation
	 * @param conversationId
	 * @param data
	 */
	authorizeParticipant (conversationId, data) {
		return $http.post(`${$restUrl}Conversation/${conversationId}/Authorization`,data);
	},

	/**
	 * dispose polling and clear ConversationsStore
	 */
	dispose () {
		ServerActions.clearConversations();
		shouldPoll = false;
		// this should also abort ongoing requests
	},

	/**
	 * visitor profile
	 * returns visitor profile with mapped claims
	 */
	getProfile (id) {
		if ((id) && (id != '00000000-0000-0000-0000-000000000000')) {
			$http.get(`${$restUrl}Visit/${id}`)
				.then(function (result) {
					//result.data.claims = VisitorProfileClaims.getClaims(result.data.data.claims);
					ServerActions.visitorProfileFetched(result.data);
				}, function (err) {
					console.warn('visitor profile fetched failed ', err);
				});
		}
	},

	/**
	 * visitor profile
	 * updates the profile claims
	 * @param id
	 * @param inClaims
	 */
	updateProfile (id, inClaims) {
		var claims = VisitorProfileClaims.filter(inClaims);

		$http.post(`${$restUrl}Visit/${id}/Claims`, claims).
			then( result => {
				result.data.claims = VisitorProfileClaims.getClaims(result.data.claims);
				ServerActions.visitorProfileFetched(result.data);
			}, function (err) {
				console.warn('visitor profile update failed ', err);
			});
	},

	/**
	 * returns cases related to visitId
	 * @param visitId
	 */
	getCases (visitId) {
		return new Promise((resolve, reject) => {
			$http.get(`${$restUrl}Case?visitId=${visitId}&caseState=2`)
				.then(function (result) {
					resolve(result.data);
					//ServerActions.visitorCasesFetched(result.data);
				}, function (error) {
					reject(error);
				});
		});
	},

	/**
	 * Close case
	 * @param caseId {String}
	 * @param closure {Object}
	 */
	closeCase (caseId, closure) {
		const payload = closure || {
			'outcome':'Neutral' // Positive | Neutral | Negative
		};
		return new Promise((resolve, reject) => {
			$http.post(`${$restUrl}Case/${caseId}/Close`, payload)
					.then(function (result) {
						resolve(result.data);
					}, function (error) {
						reject(error);
					});
		});
	},

	/**
	 * returns navigation of visitId
	 * @param visitId
	 */
	getNavigation (visitId) {
		return new Promise((resolve, reject) => {
			$http.get(`${$restUrl}Tracking/Navigation/${id}?visitId=${visitId}`)
				.then(function (result) {
					resolve(result);
					//ServerActions.visitorNavigationsFetched(result.data);
				}, function (error) {
					reject(error);
				});
		});
	},

	kickParticipant (conversationId, participantId) {
		$http.post(`${$restUrl}conversation/${conversationId}/Kick?participantId=${participantId}`);
	},

	banParticipant (conversationId, participantId) {
		return new Promise((resolve, reject) => {
			$http.post(`${$restUrl}conversation/${conversationId}/Ban?participantId=${participantId}`)
				.then(function (result) {
					console.info('banParticipant:resolve', result);
					resolve(result);
				}, function (error) {
					console.info('banParticipant:reject', error);
					reject(error);
				});
		});
	},

	inviteGuest (conversationId, payload) {
		return new Promise((resolve, reject) => {
			$http.post(`${$restUrl}conversation/${conversationId}/InviteCode`, payload)
				.then(function (result) {
					resolve(result);
				}, function (error) {
					reject(error);
				});
		});
	},
	/**
	 * Send transcript of conversation by email
	 * @param {String} conversationId
	 * @param {String} email
	 */
	emailTranscript (conversationId, email) {
		$http.post(`${$restUrl}conversation/${conversationId}/Transcript/SendEmail`, {email: email});
	},

	getStartCode () {
		return new Promise((resolve, reject) => {
			$http.post(`${$restUrl}Conversation/StartCode`)
				.then(function (result) {
					resolve(result.data);
				}, function (error) {
					reject(error);
				});
		});
	}
};

export default WebApiUtils;
