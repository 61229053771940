import AppConstants from '../../../components/constants/AppConstants';

const ActionTypes = AppConstants.ActionTypes;
const Ui = AppConstants.Ui;

export default {

	setDesktopStatus(status) {
		return {
			type: ActionTypes.SET_DESKTOP_STATUS,
			status
		};
	},

	setInContactData(data) {
		return {
			type: ActionTypes.SET_INCONTACT_DATA,
			data
		};
	},

	// TODO: move these actions from group and queue to here where they belong
	/*
		toggleUserPreferences () {
			return {
				type: Ui.TOGGLE_USER_PREFERENCES
			};
		},

		setUserPreferences (userPreferences) {
			return {
				type: Ui.SET_USER_PREFERENCES,
				userPreferences
			};
		},

		setStartCode (code) {
			return {
				type: ActionTypes.SET_START_CODE,
				code
			};
		},

		toggleStartCodeView () {
			return {
				type: Ui.TOGGLE_START_CODE_VIEW
			};
		},
		*/

}

