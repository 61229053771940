export default function (crossfilter, GroupOverviewKPIService, $q) {
	'ngInject';

	const service = {
		calculate: calculate
	};

	let ndx        = null,
		dimensions = null;

	function create() {
		const dim = {
			by: {},
			groupBy: {}
		};

		dim.by.groupId = ndx.dimension(function (d) { return d.groupId; });
		dim.groupBy.groupId = dim.by.groupId.group().reduce(add, remove, initial);

		return dim;
	}

	function calculate(data) {

		data = data.filter(function (d) { return d.groupName; });

		if (!dimensions) {
			ndx = crossfilter(data);
			dimensions = create();
		} else {
			ndx.remove();
			ndx.add(data);
		}

		dimensions.colors = createColors(dimensions);

		return $q(function (resolve) {
			GroupOverviewKPIService.initialize().then(function () {
				dimensions.groupBy.groupId.all().forEach(function (group) {
					group.value.kpis = GroupOverviewKPIService.validate(group.value);
				});
				resolve(dimensions);
			});
		});
	}

	function createColors(dimension) {
		const colors = {};
		const arrCol = ['#f04e53', '#ab3192', '#00b7c9', '#84b859', '#ffcb05', '#d2612a', '#d2612a'];
		const groups = dimension.groupBy.groupId.top(Infinity);
		groups.forEach(function (d, i) { colors[d.key] = arrCol[i]; });

		return colors;
	}

	function add(p, v) {

		if (v.groupName) {
			p.groupName = v.groupName;
		}

		p.usersOnline += v.numberOfOnlineUsers;
		p.visitorsWaitingForDialog += v.numberOfVisitorsInQueue;
		p.visitorsInDialog += v.numberOfVisitorsInDialog;
		p.averageDialogTime += v.avgDialogDurationMs;
		p.averageWaitingTime += v.avgWaitingDurationMs;
		p.maxWaitingDurationMs += v.maxWaitingDurationMs;

		p.visitorsWaitingForDialogCalculated += v.numberOfVisitorsInQueueCalculated;    // VEP-3476: Can't be used as long as /Conversation/Current contains ghost visitors in queue
		p.visitorsInDialogCalculated += v.numberOfVisitorsInDialogCalculated;

		p.totalWaitingDurationCalculatedMs += v.totalWaitingDurationCalculatedMs;
		p.totalWaitingDurationCalculatedMs += v.totalWaitingDurationCalculatedMs;
		p.averageWaitingTimeCalculated = (p.visitorsWaitingForDialogCalculated > 0 ? p.totalWaitingDurationCalculatedMs / p.visitorsWaitingForDialogCalculated : 0);    // VEP-3476: Can't be used as long as /Conversation/Current contains ghost visitors in queue
		p.maxWaitingDurationCalculatedMs = Math.max(p.maxWaitingDurationCalculatedMs, v.maxWaitingDurationCalculatedMs);    // VEP-3476: Can't be used as long as /Conversation/Current contains ghost visitors in queue

		p.totalDialogDurationCalculatedMs += v.totalDialogDurationCalculatedMs;
		p.averageDialogTimeCalculated = (p.visitorsInDialogCalculated > 0 ? p.totalDialogDurationCalculatedMs / p.visitorsInDialogCalculated : 0);
		p.maxDialogDurationCalculatedMs = Math.max(p.maxDialogDurationCalculatedMs, v.maxDialogDurationCalculatedMs);

		return p;
	}

	function remove(p, v) {
		// Will we ever add/remove more than one object with data for each group?
		// If not, we could just 0 everything!

		p.usersOnline -= v.numberOfOnlineUsers;
		p.visitorsWaitingForDialog -= v.numberOfVisitorsInQueue;
		p.visitorsInDialog -= v.numberOfVisitorsInDialog;
		p.averageDialogTime -= v.avgDialogDurationMs;
		p.averageWaitingTime -= v.avgWaitingDurationMs;
		p.maxWaitingDurationMs -= v.maxWaitingDurationMs;

		p.visitorsWaitingForDialogCalculated -= v.numberOfVisitorsInQueueCalculated;    // VEP-3476: Can't be used as long as /Conversation/Current contains ghost visitors in queue
		p.visitorsInDialogCalculated -= v.numberOfVisitorsInDialogCalculated;

		p.totalWaitingDurationCalculatedMs -= v.totalWaitingDurationCalculatedMs;
		p.averageWaitingTimeCalculated = (p.totalWaitingDurationCalculatedMs > 0 ? p.totalWaitingDurationCalculatedMs / p.totalWaitingDurationCalculatedMs : 0);    // VEP-3476: Can't be used as long as /Conversation/Current contains ghost visitors in queue
		p.maxWaitingDurationCalculatedMs = 0;   // VEP-3476: Can't be used as long as /Conversation/Current contains ghost visitors in queue

		p.totalDialogDurationCalculatedMs -= v.totalDialogDurationCalculatedMs;
		p.averageDialogTimeCalculated = (p.visitorsInDialogCalculated > 0 ? p.totalDialogDurationCalculatedMs / p.visitorsInDialogCalculated : 0);
		p.maxDialogDurationCalculatedMs = 0;//Math.max(p.longestDialogTime, v.maxDialogDurationCalculatedMs);

		return p;
	}

	function initial() {
		return {
			groupName: '',
			usersOnline: 0,
			visitorsWaitingForDialog: 0,
			visitorsInDialog: 0,
			averageDialogTime: 0,
			averageWaitingTime: 0,
			maxWaitingDurationMs: 0,

			visitorsWaitingForDialogCalculated: 0,
			visitorsInDialogCalculated: 0,

			totalWaitingDurationCalculatedMs: 0,
			averageWaitingTimeCalculated: 0,
			maxWaitingDurationCalculatedMs: 0,

			totalDialogDurationCalculatedMs: 0,
			averageDialogTimeCalculated: 0,
			maxDialogDurationCalculatedMs: 0
		};
	}

	return service;
}
