export default {
	'en-GB': {
        systemSetting: {
            ADD_HEADER: 'New setting',
            EDIT_HEADER: 'Edit setting',
            LIST_HEADER: 'System settings',
            LIST_TITLE: 'List of system settings for this account',
            KEY: 'Key',
            VALUE: 'Value',
            ACCOUNT_ID: 'Account ID',
            GROUP: 'Group',
            VALIDATE_GUID: 'GUID format: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
        }
    },
    'sv-SE': {
        systemSetting: {
            ADD_HEADER: 'New setting',
            EDIT_HEADER: 'Edit setting',
            LIST_HEADER: 'System settings',
            LIST_TITLE: 'List of system settings for this account',
            KEY: 'Key',
            VALUE: 'Value',
            ACCOUNT_ID: 'Account ID',
            GROUP: 'Group',
            VALIDATE_GUID: 'GUID format: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
        }
    }
};
