import _find from 'lodash/find';

export default function ($controller, $scope, APIStatisticsService, TimeRangeService, APIRealtimeService) {
	'ngInject';

	const groupOverview = this;
	groupOverview.loading = false;
	groupOverview.statistics = {};

	let realtimeInteractionOverviewData;
	// let realtimeConversationCurrentData;

	// watch for changes of the realtime dataservice
	$scope.$watch(function () {
		return APIRealtimeService.dataWrap;
	}, function (rtData) {
		// map the response to local ref
		realtimeInteractionOverviewData = rtData.interactionOverview;
		// realtimeConversationCurrentData = rtData.conversationCurrent;

		// try to map the realtime-data to actual statistics data
		mapRealTime(groupOverview.statistics.groups);
	}, true);

	// map data
	function mapRealTime(groupOverviewData) {
		groupOverviewData = groupOverviewData || [];
		const interactionOverviewData = realtimeInteractionOverviewData && realtimeInteractionOverviewData.groups ? realtimeInteractionOverviewData : {groups: []};	// Data from endpoint "/Statistics/InteractionOverview"
		// const conversationCurrentData = realtimeConversationCurrentData || [];	// Data from endpoint "/Conversation/Current"

		let maxWaitingDurationMs     = 0,
			numberOfOnlineUsers      = 0,
			numberOfVisitorsInQueue  = 0,
			numberOfVisitorsInDialog = 0;

		// VEP-3476: We need to store some more data to be able to calculate avg. and max values for summary
		let globalNumberOfVisitorsInQueueCalculated  = 0,
			globalTotalWaitingDurationMs             = 0,
			globalMaxWaitingDurationCalculatedMs     = 0,
			globalAvgWaitingDurationCalculatedMs     = 0,

			globalNumberOfVisitorsInDialogCalculated = 0,
			globalTotalDialogDurationMs              = 0,
			globalMaxDialogDurationCalculatedMs      = 0,
			globalAvgDialogDurationCalculatedMs      = 0;

		// if no realtime-data present or if actual statistics data present then
		// just set groupOverview.statistics to its current value
		if (!interactionOverviewData) {
			groupOverview.statistics.groups = groupOverviewData;
			return groupOverview.statistics;
		}

		if (interactionOverviewData.users) {
			interactionOverviewData.users.forEach(function (user) {
				if (user.availableInGroups.length > 0) {
					numberOfOnlineUsers++;
				}
			});
		}

		// honor groups of realtime-data
		const newGroupOverviewData = [];
		interactionOverviewData.groups.forEach(function (g) {
			const statisticsGroup = _find(groupOverviewData, {groupId: g.groupId}) || {};
			newGroupOverviewData.push({
				'groupId': g.groupId,
				'numberOfOnlineUsers': g.numberOfOnlineUsers,
				'numberOfVisitorsInQueue': g.numberOfVisitorsInQueue,
				'numberOfVisitorsInDialog': g.numberOfVisitorsInDialog,
				'calculatedAt': statisticsGroup.calculatedAt || new Date(),
				'groupName': g.name,
				'maxWaitingDurationMs': g.maxWaitTimeMs || 0,
				'avgDialogDurationMs': statisticsGroup.avgDialogDurationMs || 0,
				'avgWaitingDurationMs': statisticsGroup.avgWaitingDurationMs || 0,

				// VEP-3476: We need to store some more data to be able to calculate avg. and max values for this group (values calculated below when analyzing conversationCurrentData)
				'numberOfVisitorsInQueueCalculated': 0,
				'totalWaitingDurationCalculatedMs': 0,
				'avgWaitingDurationCalculatedMs': 0,
				'maxWaitingDurationCalculatedMs': 0,

				'numberOfVisitorsInDialogCalculated': 0,
				'totalDialogDurationCalculatedMs': 0,
				'avgDialogDurationCalculatedMs': 0,
				'maxDialogDurationCalculatedMs': 0
			});

			if (g.maxWaitTimeMs > maxWaitingDurationMs) {
				maxWaitingDurationMs = g.maxWaitTimeMs;
			}
			numberOfVisitorsInQueue += g.numberOfVisitorsInQueue || 0;
			numberOfVisitorsInDialog += g.numberOfVisitorsInDialog || 0;
		});

		// VEP-3476: Analyze conversationCurrentData (from endpoint "/Conversation/Current")
		// Disabled for now, since a normal "User" isn't authorized to GET /Conversation/Current
/*
			conversationCurrentData.forEach(function (conversation) {
				const currentDataGroup = _find(newGroupOverviewData, {groupId: conversation.groupId});
				if (currentDataGroup) {
					// Only process conversations belonging to known groups

					switch (conversation.state) {
						case 1:
							// Conversation for visitor waiting in queue
							globalNumberOfVisitorsInQueueCalculated++;
							currentDataGroup.numberOfVisitorsInQueueCalculated++;

							const waitingTime = conversation.durationSinceStateChangeMs; // Or use conversation.durationSinceRequestConversationMs?
							globalTotalWaitingDurationMs += waitingTime;
							currentDataGroup.totalWaitingDurationCalculatedMs += waitingTime;

							currentDataGroup.avgWaitingDurationCalculatedMs = (currentDataGroup.totalWaitingDurationCalculatedMs / currentDataGroup.numberOfVisitorsInQueueCalculated);
							console.log('currentDataGroup.avgWaitingDurationCalculatedMs:',currentDataGroup.avgWaitingDurationCalculatedMs);

							if (waitingTime > globalMaxWaitingDurationCalculatedMs) {
								globalMaxWaitingDurationCalculatedMs = waitingTime;
							}
							if (waitingTime > currentDataGroup.maxWaitingDurationCalculatedMs) {
								currentDataGroup.maxWaitingDurationCalculatedMs = waitingTime;
							}
							break;
						case 4:
							// Conversation in dialog
							globalNumberOfVisitorsInDialogCalculated++;
							currentDataGroup.numberOfVisitorsInDialogCalculated++;

							globalTotalDialogDurationMs += conversation.durationSinceStateChangeMs;
							currentDataGroup.totalDialogDurationCalculatedMs += conversation.durationSinceStateChangeMs;

							currentDataGroup.avgDialogDurationCalculatedMs = (currentDataGroup.totalDialogDurationCalculatedMs / currentDataGroup.numberOfVisitorsInDialogCalculated);
							console.log('currentDataGroup.avgDialogDurationCalculatedMs:',currentDataGroup.avgDialogDurationCalculatedMs);

							if (conversation.durationSinceStateChangeMs > globalMaxDialogDurationCalculatedMs) {
								globalMaxDialogDurationCalculatedMs = conversation.durationSinceStateChangeMs;
							}
							if (conversation.durationSinceStateChangeMs > currentDataGroup.maxDialogDurationCalculatedMs) {
								currentDataGroup.maxDialogDurationCalculatedMs = conversation.durationSinceStateChangeMs;
							}
							break;
						default:
					}
				}
			});
			if (globalNumberOfVisitorsInQueueCalculated > 0) {
				globalAvgWaitingDurationCalculatedMs = (globalTotalWaitingDurationMs / globalNumberOfVisitorsInQueueCalculated);
			}
			if (globalNumberOfVisitorsInDialogCalculated > 0) {
				globalAvgDialogDurationCalculatedMs = (globalTotalDialogDurationMs / globalNumberOfVisitorsInDialogCalculated);
			}
*/

		groupOverview.statistics.summary = {
			numberOfOnlineUsers: numberOfOnlineUsers,
			numberOfVisitorsInQueue: numberOfVisitorsInQueue,
			numberOfVisitorsInDialog: numberOfVisitorsInDialog,

			// numberOfVisitorsInQueue: 		globalNumberOfVisitorsInQueueCalculated,
			// numberOfVisitorsInDialog: 		globalNumberOfVisitorsInDialogCalculated,
			avgWaitingDurationCalculatedMs: globalAvgWaitingDurationCalculatedMs,	// VEP-3476: Can't be used as long as /Conversation/Current contains ghost visitors in queue. Show -- in UI instead
			maxWaitingDurationCalculatedMs: globalMaxWaitingDurationCalculatedMs,	// VEP-3476: Can't be used as long as /Conversation/Current contains ghost visitors in queue. Show the old "maxWaitingDurationMs" instead
			avgDialogDurationCalculatedMs: globalAvgDialogDurationCalculatedMs,
			maxDialogDurationCalculatedMs: globalMaxDialogDurationCalculatedMs
		};

		// assign mapped data to statistics
		groupOverview.statistics.groups = newGroupOverviewData;
		groupOverview.loading = false;

		return groupOverview.statistics;
	}

	activate();

	function activate() {
		$controller('BaseDashboardController', {
			query: queryGroupOverview,
			$scope: $scope
		});
	}

	function queryGroupOverview() {
		groupOverview.loading = true;

		const today = TimeRangeService.create('today');
		const range = {
			from: today.from(),
			to: today.to()
		};

		return APIStatisticsService.queryGroupOverview(range).then(function (res) {
			// try to map with realtime data
			groupOverview.statistics = mapRealTime(res.data);
			groupOverview.loading = false;
		});
	}
};
