import React, {useState, useEffect} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import CopyToast from './../common/CopyToast.react';
import Clipboard from 'react-copy-to-clipboard';
import VisitorProfileSelectors from '../../selectors/VisitorProfileSelectors';
import {useGetTranslation} from './../LangContext';

const VisitorIdentification = (props) => {
	const getTranslation = useGetTranslation();
	const [isCopied, setIsCopied] = useState(false);
	const [timer, setTimer] = useState(null);

	const onCopy = () => {
		setIsCopied(true);
		setTimer(setTimeout(() => setIsCopied(false), 2000));
	};

	useEffect(() => {
		return () => {
			if (timer) {
				clearInterval(timer);
			}
		};
	}, [timer]);

	const visitorIdentification = VisitorProfileSelectors.getVisitorIdentificationString(props.visitorProfile);
	if (visitorIdentification) {

		// do manual clamping
		// take into account the copy icon
		const clampLength = isCopied
			? 23
			: 27;
		let clamped = visitorIdentification;
		if (clamped.length >= clampLength) {
			clamped = clamped.substring(0, clampLength) + '..';
		}
		const identified = props.visitorIdentified
			? <i style={{color: 'green'}} className="vngage-icon-vcard"/>
			: <i className="vngage-icon-vcard"/>;

		return (

			<OverlayTrigger
				placement="top"
				overlay={<Tooltip id="tooltip">{getTranslation('clipboardTip')} </Tooltip>}>
				<Clipboard onCopy={onCopy} text={visitorIdentification}>
					<span className="visitor-identification">
						{identified}
						{clamped}
						{isCopied
							? <CopyToast/>
							: null
						}
					</span>
				</Clipboard>
			</OverlayTrigger>
		);
	} else {
		return null;
	}
};
export default VisitorIdentification;


