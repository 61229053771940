import vngageSiteSelectorTemplate from './vngageSiteSelectorTemplate';
import _find from 'lodash/find';

export default function (StateReloadService) {
	'ngInject';

	function linker(scope) {
		var activeSiteId = JSON.parse(sessionStorage.getItem('account_site_selected_id'));

		scope.sites = JSON.parse(sessionStorage.getItem('account_sites'));
		if (localStorage.getItem('vngage.zeroSiteId') === 'true') {
			scope.sites.push({
				id: '00000000-0000-0000-0000-000000000000',
				name: '00000000'
			});
		}

		scope.selectedSite = _find(scope.sites, {id: activeSiteId});
		scope.onSiteUpdated = onSiteUpdated;

		function onSiteUpdated(site) {
			sessionStorage.setItem('account_site_selected_id', JSON.stringify(site.id));
			StateReloadService.reload();
		}

	}

	return {
		restrict: 'E',
		scope: true,
		replace: true,
		templateUrl: vngageSiteSelectorTemplate,
		link: linker
	};
}
