import _find from 'lodash/find';
import React from 'react';
import createReactClass from 'create-react-class';
import ViewActions from '../actions/ViewActions';
import VisitorProfileStore from '../stores/VisitorProfileStore';
import VisitorProfile from './VisitorProfile.react';

export default createReactClass({

	getInitialState () {
		return getStateFromStores(this.props.visitId);
	},

	componentDidMount () {
		VisitorProfileStore.addChangeListener(this._onChange);
		ViewActions.visitorGetProfile(this.props.visitId);
	},

	componentWillUnmount () {
		VisitorProfileStore.removeChangeListener(this._onChange);
	},

	_onChange () {
		this.setState(getStateFromStores(this.props.visitId));
	},

	saveProfile () {
		ViewActions.visitorUpdateProfile(this.props.visitId, this.state.profileClaims);
		return false;
	},

	resetProfile () {
		ViewActions.visitorGetProfile(this.props.visitId);
	},

	updateProfileClaims (claim, value) {
		ViewActions.visitorUpdateProfileClaims(this.props.visitId, claim, value);
	},

	render () {
		return <VisitorProfile
					claims={this.state.profileClaims}
					visitId={this.props.visitId}
					onSave={this.saveProfile}
					onReset={this.resetProfile}
					onUpdate={this.updateProfileClaims}
					userAgent={this.state.userAgent}
			/>
	}

});

function getStateFromStores(id) {
	return {
		profileClaims : VisitorProfileStore.getProfileClaimsById(id),
		userAgent: VisitorProfileStore.getUserAgent(id)
	};
}
