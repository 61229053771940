import React from 'react';

const Modal = (props) => {
	let title = null;
	if (props.title) {
		title = <div className="overlay-header">
			<h5>
				{props.title}
			</h5>
		</div>
	}

	return (
		<div className="overlay-content pointer-events-auto">
			<div className="overlay-top-bar">
				{title}
				<div className="overlay-filler"/>
				<div className="overlay-close">
					<i className="vngage-icon-cancel"
					   onClick={props.close}/>
				</div>
			</div>
			<div className="overlay-body">
				{props.children}
			</div>
		</div>
	)
};
export default Modal

