import caseBrowserTranslations from './CaseBrowserTranslations';
import CaseBrowserController from './CaseBrowserController';
import caseBrowserService from './caseBrowserService';
import caseBrowserNotificationService from './CaseBrowserNotificationService';
import caseBrowserConversation from './caseBrowserModal/caseBrowserConversationDirective';
import caseBrowserMessageTagFilter from './caseBrowserModal/caseBrowserMessageTagFilter';
import CaseBrowserModalController from './caseBrowserModal/CaseBrowserModalController';
import caseBrowserModalService from './caseBrowserModal/caseBrowserModalService';
import participantNameFilter from './caseBrowserModal/caseBrowserParticipantNameFilter';
import CaseBrowserTemplate from './CaseBrowserTemplate';
import '../../common/VngageWorkspaceTranslationModule';
import caseBrowserReservation from './caseBrowserModal/caseBrowserReservationDirective';

export default angular.module('vngageApp.caseBrowser', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.constant('caseBrowserTranslations', caseBrowserTranslations)
	.controller('CaseBrowserController', CaseBrowserController)
	.factory('caseBrowserService', caseBrowserService)
	.factory('caseBrowserNotificationService', caseBrowserNotificationService)
	.directive('caseBrowserConversation', caseBrowserConversation)
	.filter('caseBrowserMessageTagFilter', caseBrowserMessageTagFilter)
	.controller('CaseBrowserModalController', CaseBrowserModalController)
	.factory('caseBrowserModalService', caseBrowserModalService)
	.filter('participantNameFilter', participantNameFilter)
	.directive('caseBrowserReservation', caseBrowserReservation)

	.constant('caseBrowserModuleConfig', {
		state: 'root.caseBrowser',
		access: 'Account'
	})

	.config(function ($stateProvider, $urlRouterProvider, $translateProvider, caseBrowserTranslations, caseBrowserModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(caseBrowserTranslations);

		$stateProvider.state(caseBrowserModuleConfig.state, {
			url: '/casebrowser/',
			views: {
				content: {
					controller: 'CaseBrowserController as vm',
					templateUrl: CaseBrowserTemplate
				}
			},
			data: {
				access: caseBrowserModuleConfig.access,
				ncyBreadcrumbLabel: 'Case Browser'
			},
			resolve: {
				userList: function (APIEndpointService, IdentityService) {
					'ngInject';
					return APIEndpointService.user.query({limit: 10000}).$promise.then(userList => {

						if (IdentityService.currentlyDelegated()) {
							return userList;
						} else {
							// Only return non-delegated users
							return userList.filter(user => {
								return user.data.type === 0;
							});
						}
					});
				},
				enableConversationHistory: function (APIConfigurationSectionService) {
					'ngInject';

					const type = APIConfigurationSectionService.sectionMap.accountSettings;

					return APIConfigurationSectionService.get(type).then(function (res) {
						return res && res[0] && res[0].data && res[0].data.section && res[0].data.section.enableConversationHistory;
					});

				}
			}
		});
	});
