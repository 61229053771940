import VisitorProfileActions from '../actions/VisitorProfileActions';
import PollRequest from './PollRequest';
import AccountConfig from '../utils/AccountConfig';

export default class VisitorNavigationRequest extends PollRequest {
	constructor(reqGuid, restUrl, visitId) {
		super(reqGuid, restUrl);
		this.visitId = visitId;
	}

	get asRequest() {
		return {
			body: {},
			method: 'get',
			tag: this.guid,
			uri: `${this.restUrl}Tracking/Navigation?visitId=${this.visitId}`
		}
	}

	resolve(response, statusCode) {
		return new Promise((resolve, reject) => {
			if (statusCode > 399) {
				console.log('<VisitorNavigationRequest> ', response);
				reject(response);
			} else {
				resolve(VisitorProfileActions.setVisitorProfileNavigation(this.visitId, AccountConfig.getURLInfoArray(response)));
			}
		});
	}
}


