import React, {useState, useEffect, useCallback} from 'react';
import immutable from 'immutable';
import {useSelector, useDispatch} from 'react-redux';
import ServerActions from '../actionCreators/ServerActions';
import QueuePanelView from '../components/queuePanel/QueuePanelView.react';
import View from '../components/dialogPanel/View.react.js';
import Overlay from '../components/overlay/OverlayView.react';
import PluginContainer from '../components/plugin/PluginContainer.react';
import {Modal, Button} from 'react-bootstrap';
import NetId from '../utils/NetId';
import FlowStates from '../constants/FlowStates';
import {useGetTranslation} from './LangContext';
import {useEventListener, useDebounce} from '../ReactHooks';


const DesktopDeuxView = (props) => {
	const [windowDim, setWindowDim] = useState({windowHeight: window.innerHeight, windowWidth: window.innerWidth});
	const [willLeaveDesktop, setWillLeaveDesktop] = useState(false);
	const [hash, setHash] = useState(null);
	const getTranslation = useGetTranslation();
	const debouncedWindowDim = useDebounce(windowDim, 500);
	const scope = 'dialog';

	// redux
	const dispatch = useDispatch();
	const cleanUpBeforeLeavingDesktop = (netId, hash) => dispatch(ServerActions.cleanUpBeforeLeavingDesktop(netId, hash));

	const isOkToLeaveDesktop = useSelector(state => {
		return state.get('conversations').size === 0
			|| state.get('conversations').every(conversation => conversation.get('flowState') === FlowStates.REMOVING);
	});

	const textSize = useSelector(state => state.getIn(['userPreferences', 'textSize']));
	const pluginsEnabled = useSelector(state => state.get('pluginsEnabled'));
	const plugins = useSelector(state => state.get('plugins'));
	const selectedPlugin = useSelector(state => state.get('selectedPlugin'));


	const cancelLeaveDesktop = () => {
		setWillLeaveDesktop(false);
		setHash(null);
	};

	const closeModalAndLeaveDesktop = () => {
		const tempHash = hash;
		setWillLeaveDesktop(false);
		setHash(null);
		setTimeout(() => {
			cleanUpBeforeLeavingDesktop(NetId.getNewNetId(), tempHash);
		}, 40);
	};

	// handler for window resize
	const dimHandler = useCallback(({target: {innerWidth, innerHeight}}) => {
		setWindowDim({windowHeight: innerHeight, windowWidth: innerWidth});
	}, [setWindowDim]);
	useEventListener('resize', dimHandler);

	useEffect(() => {
		const promptLeaveDesktop = (e) => {
			const metaKey = !!e.metaKey || !!e.shiftKey;
			const target = e.target || e.srcElement;
			const tagName = e.target.tagName;
			const hash = tagName === 'A'
				? target.hash
				: e.target.parentNode.hash; // icon was clicked

			if (metaKey) {
				// metaKey is true if user clicked to open in new tab
				// i which case the desktop should do nothing
				return;
			}

			setHash(hash);
			e.preventDefault();

			if (isOkToLeaveDesktop) {
				const cleanUpBeforeLeavingDesktop = (netId, hash) => dispatch(ServerActions.cleanUpBeforeLeavingDesktop(netId, hash));
				cleanUpBeforeLeavingDesktop(NetId.getNewNetId(), hash);
			} else {
				setWillLeaveDesktop(true);
			}
		};

		// Get navigation-elements to catch clicks (a-tags under #navmenu)
		const navigationElements = Array.apply(null, document.querySelectorAll('#navmenu a')).filter(el => {
			// But don't catch clicks on 'desktopDeux'
			if (el.getAttribute('data-ui-sref') === 'root.desktopDeux') {
				return false;
			}

			// ...and only catch clicks on a-tags with actual links
			return (
				el.getAttribute('href') ||
				el.getAttribute('data-ui-sref') ||
				el.getAttribute('ui-sref')
				// || el.getAttribute('data-ng-click') === 'logout()'	// Does not work, because the click-event will fire anyway. The "logout"-function needs to be re-designed as a ui-route instead...
			);
		});

		navigationElements.forEach(el => {
			el.addEventListener('click', promptLeaveDesktop);
		});
		return () => {
			navigationElements.forEach(el => {
				el.removeEventListener('click', promptLeaveDesktop);
			});
		};
	}, [hash, isOkToLeaveDesktop, dispatch]);

	const cls = `desktop-content ${textSize}-text-size`;
	return (
		<div className="desktop-wrapper">
			<Overlay/>
			<div className={cls}>
				{pluginsEnabled ?
					<PluginContainer plugins={plugins} selectedPlugin={selectedPlugin} {...windowDim}/>: null}
				<View {...debouncedWindowDim} />
				<QueuePanelView windowDim={windowDim}/>
				<Modal show={willLeaveDesktop} onHide={cancelLeaveDesktop}>
					<Modal.Header closeButton>
						<Modal.Title>
							<i className="vngage-icon-attention"/> {getTranslation('leaveDesktopWarningTitle')}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>{getTranslation('leaveDesktopWarningBody1')}</p>
						<p>{getTranslation('leaveDesktopWarningBody2')}</p>
					</Modal.Body>
					<Modal.Footer>
						<Button
							onClick={closeModalAndLeaveDesktop}>{getTranslation('btnLeaveDesktop')}</Button>
						<Button onClick={cancelLeaveDesktop}>{getTranslation('btnCancel')}</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</div>
	);
};
DesktopDeuxView.displayName = 'DesktopDeuxView';
export default DesktopDeuxView;
