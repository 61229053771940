import _isArray from 'lodash/isArray';
import _isFunction from 'lodash/isFunction';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _isUndefined from 'lodash/isUndefined';
import _values from 'lodash/values';
import _remove from 'lodash/remove';
import _get from 'lodash/get';

export default function ($filter, $parse, $translate) {
	'ngInject';

	return function resourceModelCreator(resource, options, columns) {

		var resourceModel, filteredList;

		resource = resource || [];
		options = options || {};
		columns = columns || [];

		// GENERAL
		// ================
		resourceModel = {
			update: update,
			show: {
				index: options.hideIndex !== true,
				remove: options.hideRemove !== true
			}
		};

		function update(updatedResource) {
			resource = updatedResource || resource;
			resourceModel.listSize = resource.length;
			sortList();
			filterList();
		}


		// Export Csv
		// Export Csv
		// ================
		resourceModel.export = {
			apply: exportCsv
		};

		function exportCsv(csvFileName) {
			const downloadFile = (fileName, csvContent) => {
				const isIE = () => {
					const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
					let isIE = false;
					if (match !== -1) {
						isIE = true;
					}
					return isIE;
				};
				const ieVersion = isIE();

				const D = document;
				const a = D.createElement('a');
				const strMimeType = 'application/octet-stream;charset=utf-8';
				let rawFile;

				// IE10+
				if (navigator.msSaveBlob) {
					return navigator.msSaveOrOpenBlob(
						new Blob(
							[csvContent],
							{type: strMimeType}),
						fileName
					);
				}

				if (ieVersion) {
					const frame = D.createElement('iframe');
					document.body.appendChild(frame);

					frame.contentWindow.document.open('text/html', 'replace');
					frame.contentWindow.document.write(csvContent);
					frame.contentWindow.document.close();
					frame.contentWindow.focus();
					frame.contentWindow.document.execCommand('SaveAs', true, fileName);

					document.body.removeChild(frame);
					return true;
				}

				//html5 A[download]
				if ('download' in a) {
					const blob = new Blob(
						[csvContent],
						{type: strMimeType}
					);
					rawFile = URL.createObjectURL(blob);
					a.setAttribute('download', fileName);
				} else {
					rawFile = 'data:' + strMimeType + ',' + encodeURIComponent(csvContent);
					a.setAttribute('target', '_blank');
				}

				a.href = rawFile;
				a.setAttribute('style', 'display:none;');
				D.body.appendChild(a);
				setTimeout(() => {
					if (a.click) {
						a.click();
						// Workaround for Safari 5
					} else if (document.createEvent) {
						const eventObj = document.createEvent('MouseEvents');
						eventObj.initEvent('click', true, true);
						a.dispatchEvent(eventObj);
					}
					D.body.removeChild(a);

				}, 100);
			};

			const getTable = (list, columns) => {
				const getRow = (item, columns) => {
					return columns.map(col => {
						const propertyPath = col.property;
						const transform = col.transform;
						const exportRaw = col.exportRaw;
						const property = _get(item, propertyPath) || '';
						let transformedValue = transform && !exportRaw ? transform(property): property;
						transformedValue = typeof transformedValue !== 'number' ? '"' + transformedValue + '"': transformedValue;
						return transformedValue;
					});
				};
				const headers = columns.map(col => col.header ? '"' + $translate.instant(col.header) + '"': col.type ? '"' + col.type.charAt(0).toUpperCase() + col.type.slice(1) + '"': '"noHeader"');
				return [headers, ...list.map(item => getRow(item, columns))];
			};
			const tableToCsv = (table) => table.map(row => row.join(',')).join('\r\n');

			csvFileName = csvFileName ? csvFileName : 'temp.csv';
			const list = (resourceModel.nonPaginatedList || resourceModel.list || []);	// Use the non-paginated (but sorted and filtered) list when exporting data
			downloadFile(csvFileName, tableToCsv(getTable(list, columns)));
		}


		// SORTING
		// ================
		resourceModel.sort = {
			apply: sortBy
		};

		function sortBy(property, reverse, transform, noUpdate, sortOnRawData) {
			reverse = !!reverse;

			if (this.property === property &&
				this.reverse === reverse) {
				this.reverse = !reverse;
			} else {
				this.reverse = reverse;
			}
			this.property = property;
			this.transform = transform;
			this.sortOnRawData = sortOnRawData;

			if (!noUpdate) {
				resourceModel.update(resource);
			}
		}

		function sortList() {
			var sort = resourceModel.sort;

			if (_isObject(resource) && !_isArray(resource)) {
				resource = _values(resource);
			}

			resource = $filter('orderBy')(resource, getSortValue, sort.reverse);

			function getSortValue(item) {
				var value = $parse(sort.property)(item);
				value = value || item;
				return _isFunction(sort.transform) && !sort.sortOnRawData ? sort.transform(value): value;
			}
		}

		// Set initial values
		columns.forEach(function (col) {
			if (!resourceModel.sort.property && col.sortDefault) {
				sortBy.call(resourceModel.sort, col.property, !!col.sortReverse, col.transform, true, col.sortOnRawData);
			}
		});


		// PAGINATION
		// ================
		resourceModel.pagination = {
			active: options.itemsPerPage > 0,
			currentPage: 0,
			currentIndex: 0,
			itemsPerPage: options.itemsPerPage || 0,
			setPage: setPage,
			nextPage: nextPage,
			previousPage: previousPage
		};

		function setPage(pageNumber) {
			this.currentPage = pageNumber;
			paginateList();
		}

		function nextPage() {
			this.currentPage = Math.min(this.currentPage + 1, this.totalPages);
			paginateList();
		}

		function previousPage() {
			this.currentPage = Math.max(this.currentPage - 1, 0);
			paginateList();
		}

		function paginateList() {
			var pgntn         = resourceModel.pagination,
				paginatedList = filteredList || [];

			if (pgntn.active) {
				pgntn.currentIndex = pgntn.currentPage * pgntn.itemsPerPage;
				pgntn.totalPages = Math.max(1, Math.ceil(paginatedList.length / pgntn.itemsPerPage));
				pgntn.canShowNext = pgntn.currentPage + 1 < pgntn.totalPages;
				pgntn.canShowPrevious = pgntn.currentPage > 0;

				paginatedList = paginatedList.slice(pgntn.currentIndex, pgntn.currentIndex + pgntn.itemsPerPage);
			}

			resourceModel.nonPaginatedList = filteredList;	// Keep a reference to the non-paginated (but sorted and filtered) list for use when exporting data
			resourceModel.list = paginatedList;
		}

		// FILTER
		// ================
		resourceModel.filter = filterList;

		function filterList(filterStr) {

			if (_isUndefined(filterStr)) {
				filterStr = resourceModel.filter.value;
			} else {
				resourceModel.filter.value = filterStr;
			}

			var filteredListTmp = [];
			var remainingListTmp = resource.slice();
			if (filterStr && _isString(filterStr) && resourceModel.filterColumns.length > 0) {
				resourceModel.filterColumns.forEach(function (col) {
					var filterProp = col.property;
					var matchedObjs = _remove(remainingListTmp, function (resourceInst) {
						var resValue = $parse(filterProp)(resourceInst);
						resValue = col.transform ? col.transform(resValue): resValue;
						if (_isString(resValue)) {
							return resValue.toLowerCase().indexOf(filterStr.toLowerCase()) > -1;
						} else {
							return false;
						}
					});
					filteredListTmp = filteredListTmp.concat(matchedObjs);
				});
			} else {
				filteredListTmp = resource;
			}
			filteredList = filteredListTmp;
			resourceModel.pagination.setPage(0);
			paginateList();
		}

		// Set initial values
		resourceModel.filterColumns = [];
		columns.forEach(function (col) {
			if (col.useInFilter) {
				resourceModel.filterColumns.push(col);
			}
		});

		// INIT
		// ================
		resourceModel.update();

		return resourceModel;
	};

};
