import * as scheduler from 'vngage-scheduler';

const workerTimerEnabledBrowser = true;	// We may want to adjust this for certain browsers. But just set true for now...
const useWorkerTimers = (workerTimerEnabledBrowser && localStorage.getItem('vngage.disable-worker-timers') !== 'true') ;
let timerTools;

if (useWorkerTimers) {
	timerTools = {...scheduler };
} else {
	timerTools = {
		setInterval: (fn, interval) => window.setInterval(fn, interval),
		setTimeout: (fn, timeout) => window.setTimeout(fn, timeout),
		clearInterval: (id) => window.clearInterval(id),
		clearTimeout: (id) => window.clearTimeout(id)
	};
}
export default timerTools;