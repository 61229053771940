import React from 'react';
import SpeakerTitle from '../../../components/SpeakerTitle.react';
import { injectIntl } from 'react-intl';
import createReactClass  from 'create-react-class';

export default injectIntl(createReactClass({

	render () {
		let message = this.props.message;
		let speaker = this.props.speaker;
		let cls = `message-meta ${message.action}`;

		return (
			<div className="flex-container">
				<div className={cls}>
					<div>
						<SpeakerTitle name={speaker.name} title={speaker.titleId} isMe={speaker.isCurrentUser} />
					</div> {this.props.intl.formatMessage({id:message.action})}
				</div>
			</div>
		);
	}
}));
