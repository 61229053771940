import React from 'react';
import Timestamp from './Timestamp.react';
import CopyMessage from './CopyMessage.react';
import mmd from '../../../../../common/vngageMarkdown/mmd';

const MessageBannerInput = (props) => {

	return (
		<div>
			<span className="message"
				  dangerouslySetInnerHTML={{__html: `<strong>${props.speaker}: </strong>` + mmd(props.txt)}}/>
			<Timestamp
				timestamp={props.timestamp}
				isMostRecentMessage={props.isMostRecentMessage}
				displayRelativeDialogTime={props.displayRelativeDialogTime}
			/>
			<CopyMessage txt={props.txt}/>
		</div>
	)
};
export default MessageBannerInput

