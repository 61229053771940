import React, {useState} from 'react';
import DebugAnalysis from '../../../utils/DebugAnalysis';
import {
	Button,
} from 'react-bootstrap';

const PollIntervals = (props) => {
	const [selectedItemIndex, setSelectedItemIndex] = useState(0);
	const {data, goToPos} = props;


	const onClickItem = (index, t) => {
		const item = data[index];
		console.log('time since last poll:', t);
		console.log(item);
		setSelectedItemIndex(index);
	};

	const intervalTimes = data.map(item => item.start)
		.map((time, index, list) => {
			return index > 0
				? list[index - 1] ? time - list[index - 1]: 0
				: 0;
		});
	const {mean, min, max, median, std} = DebugAnalysis.getMetrics(intervalTimes.filter(n => n > 0));
	const colors = [
		'#000000',
		'#a00000',
		'#c00000',
		'#ff0000',
	];

	const pollList = intervalTimes.map((t, index) => {
		const stdNr = Math.floor(Math.max(t - mean, 0) / std);
		const colorIndex = Math.min(colors.length - 1, stdNr);
		const color = colors[colorIndex];

		const style = {
			color,
			background: index === selectedItemIndex
				? 'aquamarine'
				: 'none'

		};
		return <a
			href="#"
			key={index}
			style={style}
			onClick={() => onClickItem(index, t)}>
			{t + ', '}
		</a>;
	});


	const pollTimesStyle = {
		display: 'inline',
		maxHeight: '200px',
		overflow: 'scroll'
	};

	return (
		<div className="case-type-area">
			<h3>Poll intervals</h3>
			<div style={pollTimesStyle}>
				{pollList}
			</div>
			<h3 style={{paddingTop: '10px'}}>Min: {min}</h3>
			<h3>Max: {max}</h3>
			<h3>Mean: {mean}</h3>
			<h3>Median: {median}</h3>
			<h3>Standard deviation: {Math.round(std)}</h3>
			<Button
				onClick={() => goToPos(data[selectedItemIndex].index)}>
				goto pos
			</Button>
		</div>
	);
};

export default PollIntervals;




