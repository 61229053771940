import {find as _find} from '../../../../node_modules/lodash/collection';

import SitesViewConfig from './viewConfigs/SitesViewConfig.js';
import SitesViewTranslations from './viewConfigs/SitesViewTranslations.js';

import SolutionsViewConfig from './viewConfigs/SolutionsViewConfig.js';
import SolutionsViewTranslations from './viewConfigs/SolutionsViewTranslations.js';

import QueuesViewConfig from './viewConfigs/QueuesViewConfig.js';
import QueuesViewTranslations from './viewConfigs/QueuesViewTranslations.js';

import AgentsByGroupViewConfig from './viewConfigs/AgentsByGroupViewConfig.js';
import AgentsByGroupViewTranslations from './viewConfigs/AgentsByGroupViewTranslations.js';

import AgentsViewConfig from './viewConfigs/AgentsViewConfig.js';
import AgentsViewTranslations from './viewConfigs/AgentsViewTranslations.js';

import DeviceCategoriesViewConfig from './viewConfigs/DeviceCategoriesViewConfig.js';
import DeviceCategoriesViewTranslations from './viewConfigs/DeviceCategoriesViewTranslations.js';

import CasesViewConfig from './viewConfigs/CasesViewConfig.js';
import CasesViewTranslations from './viewConfigs/CasesViewTranslations.js';

export default function (VngageWorkspaceTranslationServiceProvider) {
	'ngInject';
	// Create a simple provider to be able to inject translations for all view dynamically before instantiating the service

	// Add all translations for all the dynamic views here...
	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(SitesViewTranslations);
	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(SolutionsViewTranslations);
	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(QueuesViewTranslations);
	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(AgentsByGroupViewTranslations);
	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(AgentsViewTranslations);
	VngageWorkspaceTranslationServiceProvider.addModuleTranslations(DeviceCategoriesViewTranslations);
	// VngageWorkspaceTranslationServiceProvider.addModuleTranslations(CasesViewTranslations);

	return {
		$get: instantiateService
	};

	function instantiateService() {
		const defaultAccess = 'Statistics';

		let views = [
			SitesViewConfig(defaultAccess),				// Sites
			SolutionsViewConfig(defaultAccess),			// Solutions
			QueuesViewConfig(defaultAccess),			// Queues
			AgentsByGroupViewConfig(defaultAccess),		// Agents by group
			AgentsViewConfig(defaultAccess),			// Agents
			DeviceCategoriesViewConfig(defaultAccess)	// Device Categories
			// CasesViewConfig(defaultAccess)			// Cases
		];

		function prepareView(view) {
			if (!view) {
				return null;
			}

			if (view.viewConfig.measureLookup) {
				// View already prepared: simply return it
				return view;
			}

			// Create a viewConfig.measureLookup-object for easy measure look-ups
			view.viewConfig.measureLookup = {};
			view.viewConfig.measures.forEach((m) => {
				view.viewConfig.measureLookup[m.key] = m;
			});

			// Add properties to the "viewConfig.totals" measure-objects.
			// The properties of the corresponding measure in viewConfig.measures will be used, unless the totals-object have it's own "name", "title" or "width" set that overrides the default
			view.viewConfig.totals.forEach((m) => {
				m.name = m.name || view.viewConfig.measureLookup[m.measure].name;
				m.title = m.title || view.viewConfig.measureLookup[m.measure].title;
				m.width = m.width || view.viewConfig.measureLookup[m.measure].width;
			});

			// Add properties to the "viewConfig.table" measure-objects.
			view.viewConfig.table.measures.forEach((m) => {
				m.name = m.name || view.viewConfig.measureLookup[m.measure].name;
				m.title = m.title || view.viewConfig.measureLookup[m.measure].title;
				m.width = m.width || view.viewConfig.measureLookup[m.measure].width;
			});
			return view;
		}

		function getView(id) {
			return prepareView(_find(views, v => v.id === id));
		}

		function availableViews() {
			let viewsInfo = [];
			views.map(v => viewsInfo.push({
				id: v.id,
				access: v.access,
				menuLabel: v.menuLabel
			}));
			return viewsInfo;
		}

		return {
			getView: getView,
			availableViews: availableViews
		}
	}
}
