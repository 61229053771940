/**
 * Controllers
 */
import reservationsController from './reservationsController';
/**
 * Services
 */
import reservationsService from './reservationsService';
/**
 * Templates
 */
import reservationsTemplate from './reservationsTemplate.html';
/**
 * Models
 */
import reservationsTranslations from './reservationsTranslations';

/**
 * Reservations modal + case browser modal
 */
import reservationModalController from './reservationModal/reservationModalController';
import reservationModalService from './reservationModal/reservationModalService';
import '../../../common/VngageWorkspaceTranslationModule';
import caseBrowserModalService from '../../caseBrowser/caseBrowserModal/caseBrowserModalService';

export default angular.module('vngageApp.calendar.reservations', [
		'ui.router',
		'ui.calendar',
		'pascalprecht.translate',
		'vngageApp.workspaceTranslation'
	])

	.constant('reservationsTranslations', reservationsTranslations)
	.controller('reservationsController', reservationsController)
	.factory('reservationsService', reservationsService)
	.controller('reservationModalController', reservationModalController)
	.factory('reservationModalService', reservationModalService)
	.factory('caseBrowserModalService', caseBrowserModalService)

	.constant('reservationsModuleConfig', {
		state: 'root.calendar.reservations',
		access: 'Account' // todo correct access rights..
	})

	.config(function($stateProvider, $translateProvider, reservationsTranslations, reservationsModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';

		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(reservationsTranslations);

		$stateProvider.state(reservationsModuleConfig.state, {
			url: 'reservations/',
			controller: 'reservationsController as vm',
			templateUrl: reservationsTemplate,
			data: {
				ncyBreadcrumbLabel: 'Reservations'
			},
			resolve: {
				groupList: function(APIEndpointService, AccountSitesService, $q) {
					'ngInject';

					var siteId = AccountSitesService.getSelectedSiteId();
					return $q(function(resolve, reject) {
						APIEndpointService.group.query({siteId: siteId}).$promise.then(function (res) {
							resolve(res.filter(o => (o.data.reservationBehaviour === 1 || o.data.reservationBehaviour === 2)));
						}, function (err) {
							reject(err);
						});
					});
				},
				userList: function(APIEndpointService, IdentityService) {
					'ngInject';

					return APIEndpointService.user.query({limit: 10000}).$promise.then(function(userList) {
						if (IdentityService.currentlyDelegated()) {
							return userList;
						} else {
							// Only return non-delegated users
							return userList.filter(function(user) {
								return user.data.type === 0;
							});
						}
					});
				}
			}
		});
	});
