import React, {useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import DotsMenu from './DotsMenu.react';
import TransferMenu from './TransferMenu.react';
import ConfirmModal from '../common/ConfirmModal.react';
import {useGetTranslation} from '../LangContext';

const HeaderMenu = (props) => {
	const [showBanModal, setShowBanModal] = useState(false);
	const getTranslation = useGetTranslation();

	const {conversationId, caseId, participantId} = props;

	const banAndCloseModal = () => {
		props.banParticipant(conversationId, participantId);
		setShowBanModal(false);
	};

	const onSelect = (eventKey) => {
		switch (eventKey) {
			case 'disconnect':
				props.disconnect(conversationId);
				break;
			case 'ban':
				setShowBanModal(true);
				break;
			case 'putAway':
				props.putAwayConversation(conversationId, caseId, 'reasonName');
				break;
			default:
				break;
		}
	};

	const putAwayEnabled = !!props.enabledCapabilities.get('putAway');

	const putAway = putAwayEnabled
		? <Dropdown.Item key={'putAway'} eventKey={'putAway'}>{getTranslation('actionPutAway')}</Dropdown.Item>
		: null;
	const disabledByConnection = props.connectionStatus !== 'ok' || props.conversationStatus !== 'open';
	const transferMenuPayload = {
		groupId: props.groupId,
		groups: props.groups,
		transferToGroup: props.transferToGroup,
		conversationId,
	};
	const menuPayload = {
		onSelect,
		disabled: disabledByConnection,
		drop: props.isCollapsed ? 'up': 'down',
		iconCls: 'dots-icon',

	};

	return (
		<div className="header-menu">
			<DotsMenu {...menuPayload}>
				{putAway}
				<TransferMenu {...transferMenuPayload}/>
				<Dropdown.Item eventKey="ban" className="header-item"
							   ub-track="header-menu:block">{getTranslation('actionBlock')}</Dropdown.Item>
				<Dropdown.Item eventKey="disconnect" className="header-item"
							   ub-track="header-menu:disconnect">{getTranslation('actionDisconnect')}</Dropdown.Item>
			</DotsMenu>
			{showBanModal && <ConfirmModal
				title={getTranslation('actionBlock')}
				text={getTranslation('blockText')}
				confirm={banAndCloseModal}
				confirmText={getTranslation('actionBlock')}
				cancel={() => setShowBanModal(false)}
			/>}
		</div>
	);
};

export default HeaderMenu;
