import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function ($scope, $state, VngageUtil, pointOfInterestList, OpportunityFiltrexService, NotificationService, opportunity, solutionList, OpportunityService, vngageRemoveModalService, APIConfigurationSectionService, $stateParams) {
	'ngInject';

	var vm = this;
	vm.pointOfInterestList = pointOfInterestList;
	vm.opportunity = opportunity;
	vm.compileFiltrex = compileFiltrex;
	vm.save = save;
	vm.remove = remove;
	vm.cancel = cancel;
	vm.currentList = $stateParams.currentList || [];

	opportunity.data.section.solutions = opportunity.data.section.solutions || [];

	vm.selectableSolutions = OpportunityService.formatSelectableItems(solutionList);
	vm.sortableSolutions = OpportunityService.formatSelectedItems(opportunity, solutionList);

	function compileFiltrex() {
		var condition = vm.opportunity.data.section.condition;
		var compiledFiltrex = OpportunityFiltrexService.compileFiltrex(condition);
		if (!compiledFiltrex) {
			NotificationService.error({header: 'Error', body: 'Filtrex compilation failed'});
		} else {
			vm.compiledExpression = compiledFiltrex.toString();
			NotificationService.success({header: 'Success', body: 'Filtrex compiled'});
		}
		vm.exportedFiltrex = OpportunityFiltrexService.getFiltrexAndFuncsStr(condition);
	}

	function save() {
		vm.isSaving = true;
		vm.opportunity.data.section.solutions = [];
		vm.sortableSolutions.forEach(function (sortedSolution) {
			vm.opportunity.data.section.solutions.push(sortedSolution.refValue);
		});
		vm.opportunity.$save().then(function () {
			vm.opportunityReload = true;
		}).finally(function () {
			vm.isSaving = false;
		});
	}

	function remove() {
		if (VngageUtil.confirmSimple('Are you sure you want to delete?')) {
			vm.isSaving = true;
			vm.opportunity.$remove().then(function () {
				vm.oForm.$setPristine();
				const itemNavigationData = getItemNavigationDataAfterRemove(vm.opportunity, 'root.analyze.opportunity.edit', vm.currentList);
				vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
				$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
			}).catch(function () {
				vm.isSaving = false;
			});
		}
	}

	function cancel() {
		$state.go('^');
	}

	// when accepting to reset unsaved changes, reload the entity
	$scope.$on('resetResettables', () => {
		vm.oForm.$setPristine();
		opportunity.$get().catch(() => {
		});
	});
}
