let dateMock = null;
let mockActive = false;
export default {

	isMockEnabled() {
		return mockActive;
	},

	setMockEnabled(enabled) {
		mockActive = !!enabled;
	},

	setMock(mock) {
		dateMock = mock;
	},

	now() {
		return mockActive && dateMock !== null
			? dateMock.getTime()
			: Date.now();
	},

}
