export default function ($timeout, BannerPreview, vngageConfig, BookedMeetingsMarkupService) {
	'ngInject';

	const assetRoot = vngageConfig.staticWorkspaceUrl + 'visitor/ui'; // Path/prefix to where visitor assets are stored, for correct preview of banners with a {{assetRoot}}-placeholder
	let doc, theme, banner, wcagEnabled, iframeEl;

	function getBannerEl() {
		const tpl = banner.data.section.template;
		return (doc.getElementById('banner') || (tpl === 'queueReservation' && doc.getElementById('queueReservation')));
	}

	function updateClasses() {
		const bannerEl = getBannerEl(),
			  overlay  = doc.getElementById('overlay');

		if (!bannerEl) {
			return;
		}

		bannerEl.classList.toggle('vngage-active', BannerPreview.active);
		bannerEl.classList.toggle('vngage-inactive', !BannerPreview.active);
		bannerEl.classList.toggle('vngage-expand', BannerPreview.expanded);

		bannerEl.classList.toggle('vngage-open', BannerPreview.isOpen);
		bannerEl.classList.toggle('vngage-busy', BannerPreview.isBusy);
		bannerEl.classList.toggle('vngage-closed', BannerPreview.isClosed);

		bannerEl.classList.toggle('vngage-env-touch', !!(BannerPreview.size.mobile || BannerPreview.size.tablet));
		bannerEl.classList.toggle('vngage-env-phone', !!(BannerPreview.size.mobile));

		overlay.classList.remove('vngage-show', 'vngage-mobile-show');

		if (banner.data.section.template === 'takeover' && BannerPreview.active) {
			overlay.classList.add('vngage-show');
		} else if (banner.data.section.template !== 'injected' && BannerPreview.expanded) {
			overlay.classList.add('vngage-mobile-show');
		}
	}

	function updateTemplate() {
		var result   = '',
			bannerEl = getBannerEl(),
			tpl      = banner.data.section.template;

		if (!bannerEl) {
			return;
		}

		switch (tpl) {
			case 'injected':
				bannerEl.className = 'vngage-injected';
				break;
			case 'queueReservation':
				bannerEl.className = 'vngage-queueReservation';
				break;
			default:
				bannerEl.className = 'vngage-banner';
				switch (tpl) {
					case 'tabbanner':
						bannerEl.className += ' vngage-tabbanner';
						result += '<div class="vngage-tab"><div class="vngage-tab-text vngage-focusable" tabindex="0"></div></div>';
						break;
					case 'dooreye':
						bannerEl.className += ' vngage-dooreye';
						result += '<div class="vngage-tab vngage-focusable" tabindex="0"><div class="vngage-tab-text"></div></div>';
						break;
					case 'takeover':
						bannerEl.className += ' vngage-takeover';
						break;
					default:
				}
				result += '<div class="vngage-content">';
				if (tpl === 'dooreye') {
					result += '<div class="vngage-message"></div>';
				}
				result += '</div>';
		}

		if (banner.data.section.extraClasses) {
			bannerEl.className += ' ' + banner.data.section.extraClasses;
		}
		bannerEl.innerHTML = result;

		updateClasses();
		updateContent();

		transitionTimeout();
	}

	function updateContent() {
		var htmlTarget,
			htmlTabTarget,
			htmlQueueReservation,
			bannerEl = getBannerEl(),
			tpl      = banner.data.section.template;

		if (!bannerEl) {
			return;
		}

		if (tpl === 'injected') {
			htmlTarget = bannerEl;
		} else if (tpl === 'dooreye') {
			htmlTarget = bannerEl.querySelector('.vngage-message');
			htmlTabTarget = bannerEl.querySelector('.vngage-tab-text');
		} else if (tpl === 'tabbanner') {
			htmlTarget = bannerEl.querySelector('.vngage-content');
			htmlTabTarget = bannerEl.querySelector('.vngage-tab-text');
		} else if (tpl === 'takeover') {
			htmlTarget = bannerEl.querySelector('.vngage-content');
		} else if (tpl === 'queueReservation') {
			bannerEl.setAttribute('id', 'queueReservation');
			htmlQueueReservation = bannerEl;
		}

		if (htmlTarget) {
			htmlTarget.innerHTML = BannerPreview.compileHtml(banner.data.section.html, banner.data.section.texts);
		}
		if (htmlTabTarget) {
			htmlTabTarget.innerHTML = BannerPreview.compileHtml(banner.data.section.htmlTab, banner.data.section.texts);
		}
		if (htmlQueueReservation) {
			if (theme.config.activeTranslation) {
				// This is a booked meetings banner with an active translation
				// We want to compile the preview html from the template specified by "BannerPreview.bookedMeetingsView"

				// First merge the translation variables (regular + custom) into one object
				const variables = angular.extend({}, banner.data.section.translations[theme.config.activeTranslation].translations, banner.data.section.translations[theme.config.activeTranslation].custom);

				// Then we'll get the "bookedMeetingsPreviewVariables", which are special, internal doT-variables for generating the booked meetings banner preview only
				// (variables prefixed by "_preview_" to avoid name conflicts)
				// Some of these values may need to be compiled (with translations) as well, so "variables" needs to be supplied...
				const bookedMeetingsPreviewVariables = BookedMeetingsMarkupService.getPreviewVariables(banner, theme.config.activeTranslation, variables, wcagEnabled, BannerPreview);

				// Finally merge the internal "bookedMeetingsPreviewVariables" to "variables" and compile the final banner preview html
				angular.extend(variables, bookedMeetingsPreviewVariables);
				const bookedMeetingsPreviewTemplate = BookedMeetingsMarkupService.getQueueReservationMarkup(BannerPreview.bookedMeetingsView);
				const globalVars = {
					wcagAriaLabel: function(label) {
						if (wcagEnabled && label) {
							return 'aria-label="' + label + '"';
						} else {
							return '';
						}
					}
				};
				htmlQueueReservation.innerHTML = BannerPreview.compileHtml(bookedMeetingsPreviewTemplate, variables, globalVars);
				BookedMeetingsMarkupService.localizePreviewElement(htmlQueueReservation, BannerPreview.bookedMeetingsView, variables, wcagEnabled);
			} else {
				htmlQueueReservation.innerHTML = '';
			}
		}
		adjustIframeHeightFromContent();
	}

	function renderRuntimeCssPlaceholders(css) {
		// If the css contains the placeholder {{assetRoot}}, it should be replaced at runtime by the root URL of where the @bannerImagePath-assets should be loaded
		// Note: On visitor, assetRoot *must* be absolute, as the bannerCss is injected into the customer's website, but here it can be relative...
		return css.replace(/\{\{assetRoot\}\}/g, assetRoot);
	}

	function updateCss() {
		const style = doc.getElementById('css');

		if (theme && theme.config.result) {
			BannerPreview.style = theme.config.result;
		}

		if (style) {
			style.textContent = renderRuntimeCssPlaceholders(BannerPreview.style);
		}
		adjustIframeHeightFromContent();
	}

	function sizeChanged() {
		updateClasses();
		transitionTimeout();
	}

	function transitionTimeout() {
		var bannerEl = getBannerEl(),
			overlay  = doc.getElementById('overlay');

		angular.element(bannerEl).addClass('no-transition');
		$timeout(function () {
			angular.element(bannerEl).removeClass('no-transition');
			// Removes initial transition hide for overlay
			angular.element(overlay).css('transition', '');
		}, 500);
	}

	function checkStatus() {
		var statuses = ['Open', 'Busy', 'Closed'];

		angular.forEach(statuses, function (status) {
			if (!BannerPreview['is' + status]) {
				return;
			}
			if (!BannerPreview.active && banner.data.section['displayWhen' + status]) {
				BannerPreview.display('expand');
			} else if (!banner.data.section['displayWhen' + status]) {
				BannerPreview.display('hide');
			}
		});

		updateContent();
		updateClasses();
	}

	function adjustIframeHeightFromContent() {
		if (BannerPreview.dynamicSizeBanner) {
			setTimeout(() => {
				try {
					iframeEl.style.height = iframeEl.contentWindow.document.body.scrollHeight + 25 + 'px';
				} catch (e) {}
			}, 400);
		}
	}

	return {
		restrict: 'A',
		scope: {
			theme: '=',
			banner: '=',
			wcag: '='
		},
		link: function (scope, element) {

			iframeEl = element[0];
			doc = iframeEl.contentDocument;
			theme = scope.theme;
			banner = scope.banner;
			wcagEnabled = scope.wcag;
			scope.preview = BannerPreview;

			BannerPreview.state('open');
			BannerPreview.display('hide');
			BannerPreview.setDynamicSizeBanner(banner.data.section.template === 'queueReservation');

			scope.$watch('theme.config.result', updateCss);
			scope.$watchGroup([
				'banner.data.section.template',
				'banner.data.section.extraClasses'
			], updateTemplate);
			scope.$watchGroup([
				'preview.active',
				'preview.expanded'
			], updateClasses);
			scope.$watchGroup([
				'banner.data.section.html',
				'banner.data.section.htmlTab'
			], updateContent);
			scope.$watch('banner.data.section.texts', updateContent, true);
			scope.$watch('banner.data.section.translations', updateContent, true);
			scope.$watch('theme.config.activeTranslation', updateContent, true);

			if (banner.data.section.template === 'queueReservation') {
				scope.$watchGroup([
					'preview.bookedMeetingsView',
					'preview.bookedMeetingsShowForm',
					'banner.data.section.settings.lengthOfWeek',
					'banner.data.section.settings.startWithFirstAvailableWeek',
					'banner.data.section.settings.timeFormat'
				], updateContent);
			} else {
				scope.$watchGroup([
					'preview.isOpen',
					'preview.isBusy',
					'preview.isClosed',
					'banner.data.section.displayWhenOpen',
					'banner.data.section.displayWhenBusy',
					'banner.data.section.displayWhenClosed'
				], checkStatus);
			}

			scope.$watch('preview.size', sizeChanged, true);

			element.bind('load', function () {
				doc = this.contentDocument;
				updateCss();
				updateTemplate();
			});

			element[0].addEventListener('resized', () => {
				adjustIframeHeightFromContent();
			}, false);
		}
	};
}
