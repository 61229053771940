export default function ($uibModalInstance, BannerPreview) {
	'ngInject';

	var vm = this;
	vm.saveNew = saveNew;
	vm.address = BannerPreview.address;

	function saveNew() {
		$uibModalInstance.close(vm.address);
	}
}
