export default function(errorType, dataType, data, sectionSubType) {
	const errorObject = {
		errorType,
		dataType,
		repairActions: []
	};
	if (data) {
		errorObject.data = data;
	}
	if (sectionSubType) {
		errorObject.sectionSubType = sectionSubType;
	}
	return errorObject;
}
