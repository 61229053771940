import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import ViewActions from  '../actions/ViewActions';

export default createReactClass({

	getDefaultProps () {
		return {
			width: 200,
			height: 200,
			isScaled: true,
			hasFlash: true
		}
	},

	componentDidMount () {
		this.domBrowserWrap = ReactDOM.findDOMNode(this.refs.browserWrap);
		this.forceUpdate();
	},

	calcScale () {
		let scale = 1;
		if (this.props.isScaled && (this.domBrowserWrap && this.domBrowserWrap.offsetWidth < this.props.width)) {
			scale = this.domBrowserWrap.offsetWidth / this.props.width;
		}

		return scale;
	},

	iframeStyle () {
		let scale = this.calcScale();
		let height = '100%';

		if (scale < 1) {
			height = Math.round(100/scale) + '%';
		}

		return {
			height: height,
			MozTransform:'scale(' + scale + ')',
			WebkitTransform:'scale(' + scale + ')',
			msTransform:'scale(' + scale + ')',
			transform:'scale(' + scale + ')'
		};
	},


	// toggle class "fullsize" on cob to get scrollbars right
	render: function() {
		return (
			<div ref="browserWrap"
				 className="vg-legacy-iframe-wrap"
				><iframe
					src={this.props.url}
					className="vg-legacy-iframe"
					id="psDialogFrame"
					style={this.iframeStyle()}
					ref="browser"
					width={this.props.width}
					//height="100%"
					frameBorder="none"
				></iframe>
			</div>
		);
	}
});
