import {
	activatePanel,
	hideModal,
	setPanelPosition,
	showModal
} from './stateModifiers/navigation';
import AppConstants from '../../../components/constants/AppConstants';

const Ui = AppConstants.Ui;

export default function NavigationReducer(state, action) {
	switch (action.type) {
		case Ui.ACTIVATE_PANEL:
			return activatePanel(state, action);

		case Ui.SET_PANEL_POSITION:
			return setPanelPosition(state, action);

		case Ui.SHOW_MODAL:
			return showModal(state, action);

		case Ui.HIDE_MODAL:
			return hideModal(state, action);

		default:
			return state;

	}
}
