import RoleAddModalTemplate from './RoleAddModalTemplate';

export default function ($uibModal, $state, APIEndpointService, roleClaimList) {
	'ngInject';

	var vm = this;
	vm.roleClaimList = roleClaimList;

	vm.tableOptions = {
		stateLink: {
			state: 'root.account.role.edit',
			property: 'data.id'
		},
		createItem: {
			langKey: 'role.ADD_HEADER',
			method: addNew
		}
	};
	vm.tableColumns = [{
		header: 'Name',
		property: 'data.displayName',
		sortDefault: true,
		useInFilter: true
	}, {
		header: 'GUID',
		property: 'data.id',
		hidden: true,
		useInFilter: true
	}];

	function openAddModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: RoleAddModalTemplate,
			controller: 'RoleAddModalController as vm'
		});
		modal.result.then(newRole => {
			vm.roleClaimList.push(newRole);
			$state.go('.edit', {id: newRole.data.id});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		$state.go('root.account.role').then(openAddModal);
	}
};
