export default {
	'en-GB': {
        systemAccount: {
            ADD_HEADER: 'New account',
            EDIT_HEADER: 'Edit account',
            LIST_HEADER: 'List of accounts',
            LIST_TITLE: 'List of Vergic Engage accounts',
            LIST_PAGE_HEADER: 'Accounts',
            LIST_NAME: 'Name',
            LIST_GUID: 'Guid',
            LIST_ACTIVE: 'Active',
            LIST_TAGS: 'Tags',
            SUB_DOMAIN_NAME: 'Sub domain name',
            ACCOUNT_ID: 'Account id: ',
            ENABLED: 'Enabled',
            CHAT_HISTORY_EMAIL: 'Visitor chat history email from name (max 50 characters)',
            ACCOUNT_TAGS: 'Account tags',
            COBROWSING_WHITE_TAGS: 'Co-browsing white list host names',
			COBRO_WHITELIST_DESC: 'White list domain or sub-domain names so files aren\'t blocked. Example: *.site.com (whitespace delimited)',
            ALLOWED_LOGIN_TARGET_TAGS: 'Allowed login target urls (whitespace delimited)',
            CALLBACK_EMAILS: 'Callback email addresses (whitespace delimited)',
            NAME: 'Name',
            DESCRIPTION: 'Description',
            ACCOUNT_ACTIVE: 'true',
            ACCOUNT_INACTIVE: 'false',
			TAB_DETAILS: 'Details',
			TAB_CONFIGURATION: 'Configuration',
			TAB_TRUSTS: 'Trusts',
			TAB_TRUSTEDROLES: 'Trusted roles',
			TAB_SANDBOX: 'Sandbox',
			TIME_ZONE: 'Time zone',
			LANGUAGE: 'Language'
        }
	},
	'sv-SE': {
		systemAccount: {
			ADD_HEADER: 'New account',
			EDIT_HEADER: 'Edit account',
			LIST_HEADER: 'List of accounts',
			LIST_TITLE: 'List of Vergic Engage accounts',
			LIST_PAGE_HEADER: 'Accounts',
			LIST_NAME: 'Name',
			LIST_GUID: 'Guid',
			LIST_ACTIVE: 'Active',
			LIST_TAGS: 'Tags',
			SUB_DOMAIN_NAME: 'Sub domain name',
			ACCOUNT_ID: 'Account id: ',
			ENABLED: 'Enabled',
			CHAT_HISTORY_EMAIL: 'Visitor chat history email from name (max 50 characters)',
			ACCOUNT_TAGS: 'Account tags',
			COBROWSING_WHITE_TAGS: 'Co-browsing white list host names',
			COBRO_WHITELIST_DESC: 'White list domain or sub-domain names so files aren\'t blocked. Example: *.site.com (whitespace delimited)',
			ALLOWED_LOGIN_TARGET_TAGS: 'Allowed login target urls (whitespace delimited)',
			CALLBACK_EMAILS: 'Callback email addresses (whitespace delimited)',
			NAME: 'Name',
			DESCRIPTION: 'Description',
			ACCOUNT_ACTIVE: 'true',
			ACCOUNT_INACTIVE: 'false',
			TAB_DETAILS: 'Details',
			TAB_CONFIGURATION: 'Configuration',
			TAB_TRUSTS: 'Trusts',
			TAB_TRUSTEDROLES: 'Trusted roles',
			TAB_SANDBOX: 'Sandbox',
			TIME_ZONE: 'Time zone',
			LANGUAGE: 'Language'
		}
	}
};
