import React from 'react';
import HistorySection from './HistorySection.react';
import CaseManagerSection from './CaseManagerSection.react';
import VisitorProfileForm from './VisitorProfileForm.react';
import VideoChatSection from './VideoChatSection.react';
import WebCallSection from './WebCallSection.react';
import BookedMeetingInfoSection from './BookedMeetingInfoSection.react';
import ToolBarSectionType from '../../../constants/ToolBarSectionType';

const SectionView = (props) => {
	const {activeSections} = props;

	const elements = activeSections.filter(section => section).keySeq().map(sectionType => {
		switch (sectionType) {
			case ToolBarSectionType.VISITOR_PROFILE:
				return <VisitorProfileForm key={sectionType} conversationId={props.conversationId}/>;
			case ToolBarSectionType.CASE_MANAGER:
				return <CaseManagerSection key={sectionType} conversationId={props.conversationId}/>;
			case ToolBarSectionType.VIDEO:
				return <VideoChatSection key={sectionType}/>;
			case ToolBarSectionType.WEB_CALL:
				return <WebCallSection key={sectionType}/>;
			case ToolBarSectionType.RESERVATION:
				return <BookedMeetingInfoSection key={sectionType} conversationId={props.conversationId}/>;
			default:
				return null;
		}
	});

	return (
		<div className="section-view">
			{elements}
		</div>
	)
};
export default SectionView
