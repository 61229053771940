import { Record, Map, List, fromJS } from 'immutable';

export const VisitorRecord = Record({

	id: '',
	groupId: '',
	visitId: '',
	visitRevision: 0,
	ownerUserId: '',
	title: '',
	state: 0,
	durationSinceRequestConversationMs: 0,
	durationSinceLockMs: 0,
	durationSinceTransferMs: 0,
	durationSinceStateChangeMs: 0,
	inTransfer: false,
	lostByUser: false

}, 'VisitorRecord');