import React from 'react';
import {Modal, Button, ButtonGroup, Row, Col} from 'react-bootstrap';
import {useGetTranslation} from './../LangContext';

const DesktopSettings = (props) => {
	const getTranslation = useGetTranslation();

	const cls = `user-preferences-modal ${props.textSize}-text-size`;
	const toggle = props.userPreferences.get('displaySystemMessages');

	const infoTipSettings = props.userPreferences.get('activeInfoTip').entrySeq().map(([key, value]) => {
		return (
			<Row key={key}>
				<Col sm={10}>
					{getTranslation(key + 'InfoTip')}
					<b>{value ? getTranslation('sysMsgEnabled'): getTranslation('sysMsgDisabled')}</b>
				</Col>
				<Col sm={2}>
					<label className="flipswitch">
						<input
							type="checkbox"
							className="switch"
							defaultChecked={value}
							ub-track="desktop-settings:info-tip"
							onChange={(e) => props.toggleActiveInfoTip(key)}
						/>
						<span className="switch pull-right"/>
					</label>
				</Col>
			</Row>
		);
	});

	return (
		<Modal show={props.displayUserPreferences}
			   onHide={props.toggleUserPreferences}
			   className={cls}>
			<Modal.Header closeButton>
				<Modal.Title>{getTranslation('desktopSettingsTitle')}</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<h5>{getTranslation('msgSizeTitle')}</h5>

				<ButtonGroup>
					<Button className="btn-default-text-size" size="xs"
							ub-track="desktop-settings:text-size-default"
							onClick={() => props.setTextSize('default')}>
						{getTranslation('sizeLabelDefault')}
					</Button>
					<Button className="btn-medium-text-size" size="xs"
							ub-track="desktop-settings:text-size-medium"
							onClick={() => props.setTextSize('medium')}>
						{getTranslation('sizeLabelMedium')}
					</Button>
					<Button className="btn-large-text-size" size="xs"
							ub-track="desktop-settings:text-size-large"
							onClick={() => props.setTextSize('large')}>
						{getTranslation('sizeLabelLarge')}
					</Button>
				</ButtonGroup>

				<h5>{getTranslation('sysMsgTitle')}</h5>
				<Row>
					<Col sm={10}>
						{getTranslation('sysMsgLabel')}
						<b>{props.displaySystemMessages ? getTranslation('sysMsgEnabled'): getTranslation('sysMsgDisabled')}</b>
					</Col>
					<Col sm={2}>
						<label className="flipswitch">
							<input
								type="checkbox"
								className="switch"
								defaultChecked={toggle}
								ub-track="desktop-settings:toggle-system-messages"
								onChange={(e) => props.toggleSystemMessages(!props.userPreferences.get('displaySystemMessages'))}
							/>
							<span className="switch pull-right"/>
						</label>
					</Col>
				</Row>
				<br/>
				<h5>{getTranslation('infoTipsTitle')}</h5>
				{infoTipSettings}
			</Modal.Body>

			<Modal.Footer>
				<Button
					onClick={props.toggleUserPreferences}>{getTranslation('btnClose')}</Button>
			</Modal.Footer>

		</Modal>
	)
};
export default DesktopSettings;

