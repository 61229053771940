export default {
	'en-GB': {
		configurationSetRepair: {
			REPAIR_HEADER: 'Configuration analysis tool',
			errorTypes: {
				multipleSections: 'Multiple configuration sections found (only one is allowed)',
				multipleScripts: 'Multiple scripts found (only one is allowed)',
			},
			entityTypes: {
				group: 'group (configurationSection)'
			}
		}
	},
	'sv-SE': {
		configurationSetRepair: {
			REPAIR_HEADER: 'Configuration analysis tool',
			errorTypes: {
				multipleSections: 'Multiple configuration sections found (only one is allowed)',
				multipleScripts: 'Multiple scripts found (only one is allowed)',
			},
			entityTypes: {
				group: 'group (configurationSection)'
			}
		}
	}
};
