export default function($uibModalInstance, APIConfigurationSectionService, options) {
    'ngInject';

    var vm = this;
    vm.newData = {
        section: {
			less: '@panelHasBoxShadow: true;\u000a@hasGradient: true;\u000a@btnHasIcons: true;\u000a',
			baseTheme: 'themeDark'
		}
    };

    vm.options = options;
    vm.saveNew = saveNew;
    vm.cancelNew = $uibModalInstance.dismiss;

    function saveNew() {
        var type = APIConfigurationSectionService.sectionMap.theme;
        vm.isSaving = true;
		$uibModalInstance.freeze(true);

        APIConfigurationSectionService.create(vm.newData, type, false)
            .then(function(res) {
				$uibModalInstance.close(res);
            })
            .catch(function() {
				$uibModalInstance.freeze(false);
                vm.isSaving = false;
            });
    }
};
