import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import VideoActions from '../../actions/VideoActions';
import Video from '../../actionCreators/Video';
import VideoLib from '../../reducers/libs/videoLib';
import {useGetTranslation} from '../LangContext';

const WebCallView = (props) => {
	const getTranslation = useGetTranslation();
	const agentVideoElement = useRef();
	const visitorVideoElement = useRef();

	const {initWebCall, disposeVideoElements} = props;

	useEffect(() => {
		const agentElement = agentVideoElement.current;
		const visitorElement = visitorVideoElement.current;
		initWebCall(agentElement, visitorElement);
		return () => {
			disposeVideoElements(agentElement, visitorElement);
		};
	}, [initWebCall, disposeVideoElements]);
	const microphoneButtonCls = props.microphoneEnabled
		? 'vngage-icon-mic active'
		: 'vngage-icon-mute';

	const microphoneCls = props.microphoneEnabled
		? 'mic recording'
		: 'mic';

	const microphoneStatusText = props.microphoneEnabled
		? getTranslation('microphoneOn')
		: getTranslation('microphoneMuted');

	return (
		<div className='web-call-container'>
			<audio
				className="audio-div"
				id="visitor_video"
				ref={visitorVideoElement}/>
			<audio
				className="audio-div"
				id="agent_video"
				muted="muted"
				ref={agentVideoElement}/>
			<div className={microphoneCls}>
				<i className={microphoneButtonCls}
				   onClick={() => props.toggleMicrophone()}/>
			</div>
			<div className="mic-status-text">
				{microphoneStatusText}
			</div>

		</div>
	);
};

function mapStateToProps(state) {
	return {
		videoState: VideoLib.getState(state),
		microphoneEnabled: VideoLib.getMicrophoneEnabled(state),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		initWebCall: (agentVideoElement, visitorVideoElement) => dispatch(Video.initWebCall(agentVideoElement, visitorVideoElement)),
		disposeVideoElements: (agentVideoElement, visitorVideoElement) => dispatch(Video.disposeVideoElements(agentVideoElement, visitorVideoElement)),
		setError: (error, origin) => dispatch(VideoActions.setError(error, origin)),
		toggleMicrophone: () => dispatch(Video.toggleMicrophone()),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WebCallView);

