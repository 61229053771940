import uiView from './ui/view';

var ExpandableAreaView = function (params) {
	this.config = {
		height: params.height || 300,
		heightCompact: params.heightCompact || 80,
		previewHeight: params.previewHeight || 150,
		previewHeightCompact: params.previewHeightCompact,// || 40,

		startState: params.startState || 'preview',
		startSize: params.startSize || 'compact',

		showHeader: params.showHeader || true,
		scrollToOnMouseOut: params.scrollToOnMouseOut || false,
		onCollapse: params.onCollapse || function () {
			return true;
		},
		onExpand: params.onExpand || function () {
			return true;
		}
	};
	this.viewState = this.config.startState;


	//the smallState is used to decide what happens if clicking the actual arrow.
	this.smallState = (this.viewState == 'preview') ? 'preview': 'collapsed';
	this.viewSize = this.config.startSize;

	this.layout = layout();

	this.mouseoverTimeout = false;
	this.mouseoutTimeout = false;

	this.scrollToOnMouseOutPaused = false;

	var self = this;


	this.states = ['collapsed', 'preview', 'full'];
	this.currentState = this.states.indexOf(this.viewState);
	function nextState() {
		self.currentState = self.states.indexOf(self.viewState);
		self.currentState++;
		if (self.currentState > self.states.length - 1) self.currentState = 0;
		self.setState(self.states[self.currentState]);
	}


	var pendingClicks = 0;

	function handleHeaderClick(e) {
		// kill any pending single clicks
		if (pendingClicks) {
			clearTimeout(pendingClicks);
			pendingClicks = 0;
		}
		switch (e.detail) {
			case 1: //singleClick
				pendingClicks = setTimeout(function () {
					self.toggleFullState();
				}, 160); // you need to be rather swift about it...
				break;

			default: //dblclick or more...
				changeSmallState();
		}
	}


	function changeSmallState(e) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		var newState = (self.smallState == 'preview') ? 'collapsed': 'preview';
		self.setState(newState);
		self.smallState = newState;
	}


	if (this.config.scrollToOnMouseOut) {
		var clickElem = this.layout.content;
		//clickElem.setAttribute('title', 'Dubble-click to toggle auto-scroll. If a message is received, auto-scroll will be disabled!');
		clickElem.addEventListener('dblclick', function () {

			if (!self.scrollToOnMouseOutPaused) {
				self.scrollToOnMouseOutPaused = true;
				return;
			}
			self.scrollToOnMouseOutPaused = false;
		}, false);
	}

	if (this.config.showHeader) {

		this.layout.headerElem.addEventListener('click', function () {
			self.toggleFullState();
		}, false); //
		this.layout.minimizeButton.addEventListener('click', function (e) {
			e.stopPropagation();
			e.preventDefault();
			self.setState('collapsed');
		}, false);
		this.layout.previewButton.addEventListener('click', this.layout.previewButton, function (e) {
			e.stopPropagation();
			e.preventDefault();
			self.setState('preview');
		}, false);
		this.layout.maximizeButton.addEventListener('click', function (e) {
			e.stopPropagation();
			e.preventDefault();
			self.setState('full');
		}, false);

	} else {
		this.layout.header.style.display = 'none';
	}


	setTimeout(function () {
		self.setState(self.viewState);
		self.setSize(self.viewSize);
	}, 300);
	//this.render();
};


ExpandableAreaView.prototype.setState = function (state) {
	switch (state) {
		case 'collapsed':
			this.config.onCollapse();
			this.viewState = 'collapsed';
			this.layout.wrapper.className = 'psExpandableArea ' + 'stateCollapsed';
			break;
		case 'preview':
			this.config.onExpand();
			this.viewState = 'preview';
			this.layout.wrapper.className = 'psExpandableArea ' + 'statePreview';
			break;
		case 'full':
			this.config.onExpand();
			this.viewState = 'full';
			this.layout.wrapper.className = 'psExpandableArea ' + 'stateFull';
			break;
	}
	this.render();
};

ExpandableAreaView.prototype.setSize = function (size) {
	switch (size) {
		case 'compact':
			this.layout.headerElem.style.display = '';
			this.viewSize = 'compact';
			break;
		case 'hidden':
			this.layout.headerElem.style.display = 'none';
			this.viewSize = 'hidden';
			break;
		case 'normal':
			this.layout.headerElem.style.display = '';
			this.viewSize = 'normal';
			break;
	}
	this.render();
};

//respect current smallState when toggling size:
ExpandableAreaView.prototype.toggleFullState = function () {

	var newState = (this.viewState == 'preview') ? 'full': this.smallState;

	if (this.config.heightCompact == this.config.previewHeight) {
		if (this.smallState == 'collapsed') {
			this.setSize('normal');
			newState = 'full';
		} else {
			this.setSize('hidden');
			newState = 'collapsed';
		}
	}

	this.setState(newState);
};

ExpandableAreaView.prototype.collapseExpand = function () {
	//console.log(this);
	if (this.viewState != 'collapsed') {
		this.setState('collapsed');
	} else {
		this.setState('preview');
	}
};

ExpandableAreaView.prototype.render = function (scrollTo) {
	var config = this.config;
	var realHeight = 0;

	try {
		realHeight = this.layout.content.children[0].scrollHeight;// + 5;
	} catch (err) {
		//console.log(err);
		realHeight = 30;
	}
	//console.log('scrollToOnMouseOut', config.scrollToOnMouseOut, typeof scrollTo == 'undefined', this.viewState);

	// if auto scroll paused and ! scrollTo
	if (this.scrollToOnMouseOutPaused && typeof scrollTo == 'undefined') scrollTo = 'paused';

	// if not scrollTo && auto scroll is enabled;
	if (config.scrollToOnMouseOut && typeof scrollTo == 'undefined' && this.viewState == 'preview') scrollTo = config.scrollToOnMouseOut;

	//console.log('realHeight', realHeight, this.layout.content.innerHTML);
	var contentSize = {
		compact: {
			collapsed: function () {
				return 0;
			},
			preview: function () {
				var maxHeight = config.previewHeightCompact;
				if (realHeight < maxHeight) return realHeight;
				return maxHeight;
			},
			full: function () {
				var maxHeight = config.heightCompact;
				if (realHeight < maxHeight) return realHeight;
				return maxHeight;
			}
		},
		normal: {
			collapsed: function () {
				return 0;
			},
			preview: function () {
				var maxHeight = config.previewHeight;
				if (realHeight < maxHeight) return realHeight;
				return maxHeight;
			},
			full: function () {
				var maxHeight = config.height;
				if (realHeight < maxHeight) return realHeight;
				return maxHeight;
			}
		},
		hidden: {
			collapsed: function () {
				return 0;
			},
			preview: function () {
				return 0;
			},
			full: function () {
				return 0;
			}
		}
	};
	//console.log('set size', this.viewSize, this.viewState, contentSize[this.viewSize][this.viewState]());
	if (this.layout.content.style.height !== contentSize[this.viewSize][this.viewState]() + 'px') {
		this.layout.content.style.height = contentSize[this.viewSize][this.viewState]() + 'px';
	}

	if (typeof scrollTo !== 'undefined') {
		var scrollElem = this.layout.content;
		switch (scrollTo) {
			case 'top':
				this.scrollToOnMouseOutPaused = false;
				setTimeout(function () {
					scrollElem.scrollTo(0, 0);
				}, 500);
				break;
			case 'bottom':
				this.scrollToOnMouseOutPaused = false;
				setTimeout(function () {
					scrollElem.scrollTop = scrollElem.scrollHeight;
				}, 500);
				break;
			case 'paused':
				break;
		}
	}
};

ExpandableAreaView.prototype.collapse = function () {};
ExpandableAreaView.prototype.hide = function () {};
ExpandableAreaView.prototype.show = function () {};
ExpandableAreaView.prototype.expand = function () {};

function layout() {
	return uiView.createNodes({
		reference: 'wrapper',
		tag: 'div',
		className: 'psExpandableArea',
		children: [{
			reference: 'headerElem',
			tag: 'div',
			className: 'psExpandableAreaHeader',
			children: [{
				reference: 'header',
				tag: 'div',
				className: 'psExpandableAreaHeaderTitle'
			}, {
				reference: 'minimizeButton',
				tag: 'div',
				className: 'psExpandableAreaHeaderIcon psExpandableAreaHeaderIconMinimize'
			}, {
				reference: 'previewButton',
				tag: 'div',
				className: 'psExpandableAreaHeaderIcon psExpandableAreaHeaderIconPreview'
			}, {
				reference: 'maximizeButton',
				tag: 'div',
				className: 'psExpandableAreaHeaderIcon psExpandableAreaHeaderIconMaximize'
			}]
		}, {
			reference: 'content',
			tag: 'div',
			className: 'psExpandableAreaContent transitionAll'
		}]
	});
}

export default ExpandableAreaView;
