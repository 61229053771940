import React from 'react';
import AccountConfig from '../../../utils/AccountConfig';
import Format from '../../../utils/Format';
import {Form, Dropdown} from 'react-bootstrap';
import {useGetTranslation} from './../../LangContext';

const ClosureMenu = (props) => {
	const getTranslation = useGetTranslation();
	const putAway = () => {
		const {conversationId, caseId} = props;
		props.putAwayConversation(conversationId, caseId, '');
	};

	const closures = Format.asSearchFilteredList(AccountConfig.getClosures(props.currentCaseType), '', 'name', 'name').map((closure, idx) => {
		return (
			<Dropdown.Item key={idx}
						  onClick={() => props.closeCase(props.conversationId, closure)}>{closure.name}</Dropdown.Item>
		);
	});

	const putAwayEnabled = !!props.enabledCapabilities.get('putAway');

	const putAwayItem = putAwayEnabled
		? <Dropdown.Item key={'putAway'} onClick={putAway}
						 eventKey={'putAway'}>{getTranslation('actionPutAway')}</Dropdown.Item>
		: null;

	return (
		<Dropdown id="dropdown-custom-1"
				  size="sm"
				  className="finish-dropdown"
				  alignRight
				  tabIndex="-1"
				  drop="up"
		>
			<Dropdown.Toggle variant="outline-success" size="sm" id="dropdown-closure"
							 className="closure-dropdown">
				{getTranslation('finishBtn')}
			</Dropdown.Toggle>
			<Dropdown.Menu className="dropdown-menu">

				<div className="dropdown-head">
					<strong className="title">{getTranslation('closureOptionLabel')}:</strong>
					<Form>
						<Form.Group>
							<Form.Check
								name="newCaseAfterClose"
								label={getTranslation('saveNewCase')}
								onChange={() => props.toggleNewCaseAfterClose(props.conversationId)}
								isInvalid={false}
								feedback={''}
								id="newCaseAfterClose"
								checked={props.newCaseAfterClose}
							/>
							{props.alwaysEmailTranscript
								? null
								: <Form.Check
									name="emailTranscript"
									label={getTranslation('sendAsEmailLabel')}
									onChange={() => props.toggleEmailConversationTranscript(props.conversationId)}
									isInvalid={false}
									feedback={''}
									id="emailTranscript"
									checked={props.emailConversationTranscript}
								/>
							}


						</Form.Group>
					</Form>
				</div>

				<strong className="title">{getTranslation('closureSectionLabel')}:</strong>
				{closures}
				<Dropdown.Divider/>
				{putAwayItem}
				<Dropdown.Item key="moreCaseOptions" className="to-more-options"
							  onClick={() => props.setActiveSection(props.conversationId, 'caseManager')}>{getTranslation('moreCaseOptions')}</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default ClosureMenu;

