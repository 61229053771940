import React from 'react';
import UserTyping from './UserTyping.react.js';
import {useGetTranslation} from './../../LangContext';

const VisitorStatus = ({connectionStatus, userIsTyping}) => {
	const getTranslation = useGetTranslation();
	let feedback = null;

	switch (connectionStatus) {
		case 'ok':
			feedback = userIsTyping
				? <UserTyping/>
				: null;
			break;
		case 'lost':
			break;
		case 'dead':
			break;
		case 'terminate':
			feedback =
				<span className="connection-feedback-error">{getTranslation('inputMsgVisitorLeft')}</span>;
			break;
		default:
			break;
	}

	return (
		feedback
	)
};

export default VisitorStatus

