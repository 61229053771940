import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';

import AccountConfiguration from './AccountConfiguration';



export function pickData(entities) {
	return _map(entities, 'data');
}

/**
 * filter configurationSections
 * @param {Object} sections
 * @returns {Array} returns whitelisted configurationSections
 */
function filterSections(sections) {
	return function(type) {
		return pickData(sections.filter(section => section.data.type === type));
	};
}


export function parse (configurationSet) {
	const groups = _keyBy(pickData(configurationSet.groups), 'id');
	const groupFeatures = configurationSet.configurationSections.filter(entry => entry.data.type === 'group');
	groupFeatures.forEach(features => {
		if(groups[features.data.section.groupId]){
			groups[features.data.section.groupId].features = features.data;
		}
	});

	const conf = {},
		sections = configurationSet.configurationSections,
		filterByType = filterSections(sections),
		accountSettings = filterByType('accountSettings')[0] || {},
		visitorSettings = filterByType('visitor')[0] || {};

	conf.account 			= accountSettings.section || {};
	conf.visitor 			= visitorSettings.section || {};
	conf.sites 				= _keyBy(pickData(configurationSet.sites), 'id');
	conf.actionPanels 		= _keyBy(filterByType('actionPanel'), 'id') || {};
	conf.actions 			= _keyBy(filterByType('action'), 'id') || {};
	conf.customerCases 		= _keyBy(filterByType('customerCase'), 'id') || {};
	conf.groups 			= _keyBy(groups, 'id');
	conf.cannedResponses 	= _keyBy(filterByType('preDefinedText'), 'id') || {};
	conf.closures 			= _keyBy(filterByType('closure'), 'id') || {};
	conf.forms 				= _keyBy(filterByType('forms'), 'id') || {};
	conf.urlCategories 		= _keyBy(filterByType('urlCategory'), 'id') || {};
	conf.solutions 			= _keyBy(filterByType('solution'), 'id') || {};
	conf.opportunities 		= _keyBy(filterByType('opportunity'), 'id') || {};
	conf.workspace 			= (filterByType('workspace').find(item => item.sectionType === 'general') || {section:{}}).section;

	//map translations onto configuration
	conf.translations = _keyBy(pickData(configurationSet.translations), 'languageCode');

	return conf;
}

