export default function () {

    return {

        range: {
            today: 'today',
            yesterday: 'yesterday',
            twoDaysAgo: 'twoDaysAgo',
            threeDaysAgo: 'threeDaysAgo',
            thisWeek: 'thisWeek',
            lastWeek: 'lastWeek',
            thisMonth: 'thisMonth',
            lastMonth: 'lastMonth',
            sevenDays: 'sevenDays',
            thirtyDays: 'thirtyDays'
        },

        period: {
            hour: 'hour',
            day: 'day'
        },

        formatDuration: function (ms) {
            if (typeof ms !== 'number') {
                ms = 0;
            }
            function pad(n) {
                return (n > 9) ? +n : '0' + n;
            }

            var seconds = ms / 1000,
                h = Math.floor(seconds / 3600),
                m = Math.floor(seconds / 60) % 60,
                s = Math.floor(seconds) % 60;

            return pad(h) + ':' + pad(m) + ':' + pad(s);
        }
    };

};
