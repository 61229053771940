import React from 'react';
import Format from '../../utils/Format'

const MetaDevice = ({device}) => {
	const {className, style} = Format.asDeviceIcon(device);
	const deviceIcon = <i className={className} style={style}/>;

	return (
		deviceIcon
	)
};
export default MetaDevice;

