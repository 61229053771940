export default {
	'en-GB': {
		monitor: {
			HEADER: 'Workforce monitor',
			VISITORS_ON_SITE: 'Visitors on site',
			COMPLETED_DIALOGS_TODAY: 'Completed dialogs today',
			PAUSED: 'Paused',
			GROUP_OVERVIEW: 'Group overview',
			CLEAR_GROUPS_FILTER: 'Clear groups filter',
			AGENTS_IN_DESKTOP: 'Agents in desktop',
			CLEAR_AGENTS_FILTER: 'Clear agents filter',
			TAB_GROUPS: 'Groups',
			TAB_AGENTS: 'Agents',
			queue: {
				GROUP: 'Group',
				ONLINE_AGENTS_IN_DESKTOP: 'Online agents in desktop',
				VISITORS_IN_QUEUE: 'Visitors in queue',
				VISITORS_IN_DIALOG: 'Active dialogs',
				AVG_DIALOG: 'Avg. dialog',
				AVG_DIALOG_HELP: 'Closed dialogs today',
				AVG_WAITING_TIME: 'Avg. waiting time',
				AVG_WAITING_TIME_HELP: 'Last 15 minutes',
				AVG_HUNG_UP_TIME: 'Avg. hung-up time',
				LONGEST_TIME_IN_QUEUE: 'Longest waiting time',
				LONGEST_TIME_IN_QUEUE_HELP: 'Current visitor in queue',
				LONGEST_DIALOG_TIME: 'Longest dialog time',
				LONGEST_DIALOG_TIME_HELP: 'Active dialogs',
				FILTER: 'Filter',
				THRESHOLD_VALUES_EXCEEDED: 'The threshold values for this queue have been exceeded',
				MAX_VISITORS_WAITING_REACHED: 'The allowed amount of users in queue ({{ nbr }}) has been exceeded',
				MAX_WAITING_TIME_REACHED: 'The max allowed waiting time in queue ({{ timeStr }}) has been exceeded',
				SUMMARY: 'Summary:'
			},
			users: {
				AGENTS: 'Agents:',
				LOCKED_CHATS: 'Locked',
				ACTIVE_DIALOGS: 'In dialog',
				HANGUPS: 'Hang up',
				FILTER: 'Filter',
				NO_AGENTS_IN_DESKTOP: 'No agents in desktop',
				LOGOUT_AGENT:'Logout agent',
				LOGOUT:'Logout',
			},
			conversations: {
				NO_VISITOR_ACTIVITY: 'There is no visitor activity at the moment',
				NO_QUEUE_ACTIVITY: 'There is no queue activity at the moment',
				DURATION: 'Duration',
				states: {
					'0_PENDING_ROUTING': 'Pending routing',
					'1_QUEUE': 'In queue',
					'2_LOCKED': 'Locked',
					'3_PENDING_VISITOR_APPROVAL': 'Pending visitor approval',
					'4_DIALOG': 'In dialog',
					'5_HUNG_UP_BY_VISITOR': 'Hung up',
					'6_HUNG_UP_BY_USER': 'Hung up',
					'7_DECLINED': 'Declined'
				}
			}
		}
	},
	'sv-SE': {
		monitor: {
			HEADER: 'Workforce monitor',
			VISITORS_ON_SITE: 'Visitors on site',
			COMPLETED_DIALOGS_TODAY: 'Completed dialogs today',
			PAUSED: 'Paused',
			GROUP_OVERVIEW: 'Group overview',
			CLEAR_GROUPS_FILTER: 'Clear groups filter',
			AGENTS_IN_DESKTOP: 'Agents in desktop',
			CLEAR_AGENTS_FILTER: 'Clear agents filter',
			TAB_GROUPS: 'Groups',
			TAB_AGENTS: 'Agents',
			queue: {
				GROUP: 'Group',
				ONLINE_AGENTS_IN_DESKTOP: 'Online agents in desktop',
				VISITORS_IN_QUEUE: 'Visitors in queue',
				VISITORS_IN_DIALOG: 'Active dialogs',
				AVG_DIALOG: 'Avg. dialog',
				AVG_DIALOG_HELP: 'Closed dialogs today',
				AVG_WAITING_TIME: 'Avg. waiting time',
				AVG_WAITING_TIME_HELP: 'Last 15 minutes',
				AVG_HUNG_UP_TIME: 'Avg. hung-up time',
				LONGEST_TIME_IN_QUEUE: 'Longest waiting time',
				LONGEST_TIME_IN_QUEUE_HELP: 'Current visitor in queue',
				LONGEST_DIALOG_TIME: 'Longest dialog time',
				LONGEST_DIALOG_TIME_HELP: 'Active dialogs',
				FILTER: 'Filter',
				THRESHOLD_VALUES_EXCEEDED: 'The threshold values for this queue have been exceeded',
				MAX_VISITORS_WAITING_REACHED: 'The allowed amount of users in queue ({{ nbr }}) has been exceeded',
				MAX_WAITING_TIME_REACHED: 'The max allowed waiting time in queue ({{ timeStr }}) has been exceeded',
				SUMMARY: 'Summary:'
			},
			users: {
				AGENTS: 'Agents:',
				LOCKED_CHATS: 'Locked',
				ACTIVE_DIALOGS: 'In dialog',
				HANGUPS: 'Hang up',
				FILTER: 'Filter',
				NO_AGENTS_IN_DESKTOP: 'No agents in desktop',
				LOGOUT_AGENT:'Logga ut agenten',
				LOGOUT:'Logga ut',
			},
			conversations: {
				NO_VISITOR_ACTIVITY: 'There is no visitor activity at the moment',
				NO_QUEUE_ACTIVITY: 'There is no queue activity at the moment',
				DURATION: 'Duration',
				states: {
					'0_PENDING_ROUTING': 'Pending routing',
					'1_QUEUE': 'In queue',
					'2_LOCKED': 'Locked',
					'3_PENDING_VISITOR_APPROVAL': 'Pending visitor approval',
					'4_DIALOG': 'In dialog',
					'5_HUNG_UP_BY_VISITOR': 'Hung up',
					'6_HUNG_UP_BY_USER': 'Hung up',
					'7_DECLINED': 'Declined'
				}
			}
		}
	},
	'fr-FR': {
		monitor: {
			HEADER: 'Moniteur de main-d\'œuvre',
			VISITORS_ON_SITE: 'Visiteurs sur le site',
			COMPLETED_DIALOGS_TODAY: 'Dialogues effectués aujourd\'hui',
			PAUSED: 'En pause',
			GROUP_OVERVIEW: 'Vue d\'ensemble des group',
			CLEAR_GROUPS_FILTER: 'Effacer le filtre des groupes',
			AGENTS_IN_DESKTOP: 'Agents sur le bureau',
			CLEAR_AGENTS_FILTER: 'Effacer le filtre des agents',
			TAB_GROUPS: 'Groupes',
			TAB_AGENTS: 'Agents',
			queue: {
				GROUP: 'Groupe',
				ONLINE_AGENTS_IN_DESKTOP: 'Agents en ligne sur le bureau',
				VISITORS_IN_QUEUE: 'Visiteurs en file d\'attente',
				VISITORS_IN_DIALOG: 'Active dialogs',
				AVG_DIALOG: 'Dialogue moy.',
				AVG_DIALOG_HELP: 'Dialogues fermés aujourd\'hui',
				AVG_WAITING_TIME: 'Moy. temps d\'attente',
				AVG_WAITING_TIME_HELP: '15 dernières minutes',
				AVG_HUNG_UP_TIME: 'Avg. hung-up time',
				LONGEST_TIME_IN_QUEUE: 'Longest waiting time',
				LONGEST_TIME_IN_QUEUE_HELP: 'Visiteur actuel dans la file',
				LONGEST_DIALOG_TIME: 'Longest dialog time',
				LONGEST_DIALOG_TIME_HELP: 'Active dialogs',
				FILTER: 'Filtre',
				THRESHOLD_VALUES_EXCEEDED: 'Les valeurs limites de cette file d\'attente ont été dépassées',
				MAX_VISITORS_WAITING_REACHED: 'Le nombre d\'utilisateurs dans la file d\'attente autorisé ({{ nbr }}) a été dépassé',
				MAX_WAITING_TIME_REACHED: 'La durée totale dans la file d\'attente ({{maxTime}}) a été dépassée',
				SUMMARY: 'Résumé :'
			},
			users: {
				AGENTS: 'Agents :',
				LOCKED_CHATS: 'Verrouillé',
				ACTIVE_DIALOGS: 'En cours de dialogue',
				HANGUPS: 'Raccroché',
				FILTER: 'Filtre',
				NO_AGENTS_IN_DESKTOP: 'Aucun agent sur le bureau',
				LOGOUT_AGENT:'Agent de déconnexion',
				LOGOUT:'De déconnexion',
			},
			conversations: {
				NO_VISITOR_ACTIVITY: 'Il n\'y a pas de visiteurs pour le moment',
				NO_QUEUE_ACTIVITY: 'Il n\'y a pas de file d\'attente pour le moment',
				DURATION: 'Durée',
				states: {
					'0_PENDING_ROUTING': 'En attente d\'acheminement',
					'1_QUEUE': 'En file d\'attente',
					'2_LOCKED': 'Verrouillé',
					'3_PENDING_VISITOR_APPROVAL': 'En attente de l\'approbation des visiteurs',
					'4_DIALOG': 'En cours de dialogue',
					'5_HUNG_UP_BY_VISITOR': 'Raccroché',
					'6_HUNG_UP_BY_USER': 'Raccroché',
					'7_DECLINED': 'Refusé'
				}
			}
		}
	}
};
