import QueueActions from '../actions/QueueActions';
import PollRequest from './PollRequest';

export default class ConversationUnassignedRequest extends PollRequest {
	constructor(reqGuid, restUrl, groupIds) {
		super(reqGuid, restUrl);
		this.groups = groupIds || [];
	}

	get asRequest() {
		const params = '?groupId=' + this.groups.join('&groupId=') + '&take=8';

		return {
			body: {},
			method: 'get',
			tag: this.guid,
			uri: `${this.restUrl}Conversation/Current/Unassigned${params}`
		}
	}

	resolve(response, statusCode) {
		return new Promise((resolve, reject) => {
			if (statusCode > 399) {
				console.log('<Unassigned ERROR> ', response);
				reject(response);
			} else {
				resolve(QueueActions.receiveVisitors(response));
			}
		});
	}
}
