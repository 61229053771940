import SystemRoleAddModalTemplate from './SystemRoleAddModalTemplate';

export default function ($uibModal, $state, systemConfiguration) {
	'ngInject';

	var vm = this;
	vm.roleClaims = systemConfiguration.roleClaims;

	vm.tableOptions = {
		stateLink: {
			state: 'root.system.role.edit',
			property: 'id'
		},
		createItem: {
			langKey: 'systemRole.ADD_HEADER',
			method: addNew
		}
	};
	vm.tableColumns = [{
		header: 'Name',
		property: 'displayName',
		sortDefault: true,
		useInFilter: true
	}, {
		header: 'GUID',
		property: 'id',
		hidden: true,
		useInFilter: true
	}];

	function openAddModal() {
		var modal = $uibModal.open({
			backdrop: 'static',
			templateUrl: SystemRoleAddModalTemplate,
			controller: 'SystemRoleAddModalController as vm'
		});
		modal.result.then(newRoleClaim => {
			vm.roleClaims.push(newRoleClaim);
			$state.go('.edit', {
				id: newRoleClaim.id,
				roleClaim: newRoleClaim
			});
		}).catch(() => {
			// Modal dismissed...
		});
	}

	function addNew() {
		$state.go('root.system.role').then(openAddModal);
	}
};
