export default function () {
	'ngInject';

	return {
		restrict: 'E',
		scope: {
			tags: '='
		},
		replace: true,
		template: '<ul class="tag-list"><li ng-repeat="tag in tags track by $index"><vngage-tag data-text="{{tag}}"></vngage-tag></li></ul>'
	};

};
