export default {
	'en-GB': {
		statistics: {
			views: {
				agentsbygroup: {
					MENU_LABEL: 'Agents by group',
					TITLE: 'Agents by group (Queue)',
					TABLE_HEADING: 'Hour report',
					TIME_LINE_GRAPH_HEADING: 'Dialogs by hour',
					measures: {
						siteName: {
							NAME: 'Site',
							TITLE: 'The name of the site presenting the chat option',
							LONG_DESCRIPTION: ''
						},
						groupName: {
							NAME: 'Group (Queue)',
							TITLE: 'The name of the queue / group that handled the chat request',
							LONG_DESCRIPTION: ''
						},
						dimensionName: {
							NAME: 'User In Group (Site)',
							TITLE: '',
							LONG_DESCRIPTION: '*This measure is actually a generated text to group the dimensions visually in the data table. It is generated by "User name" + From: "Group name + "(Site name)"'
						},
						userName: {
							NAME: 'Agent',
							TITLE: 'The full name of the Agent',
							LONG_DESCRIPTION: 'The Agents full name, this is not the name displayed to the visitors!'
						},
						loggedInTimeMs: {
							NAME: 'Logged in time',
							TITLE: 'Total login time H:mm:ss',
							LONG_DESCRIPTION: 'This duration is counted per hour and represents the time the agent has been logged in during this hour'
						},
						availableTimeMs: {
							NAME: 'Available time',
							TITLE: 'The time the agent(s) has been available for the the queue in H:mm:ss',
							LONG_DESCRIPTION: 'This duration is counted per hour and represents the time the agent has been available for the queue during this hour. Time is represented per queue so if the agent was available 1 hour for 2 queues, the total will be 2 hours',

						},
						engagedTimeMs: {
							NAME: 'Engaged time',
							TITLE: 'Total engaged in dialog time H:mm:ss',
							LONG_DESCRIPTION: 'This duration is counted per hour and represents the time the agent has been active in dialogs during this hour'
						},
						dialogTimeMs: {
							NAME: 'Dialog time',
							TITLE: 'Total dialog time. This number presents dialog durations and is written on the dialog hang up event. Per hour this measure can look strange since it can include time from before the hour the event was tracked.',
							LONG_DESCRIPTION: 'This duration is counted on dialog end and represents the time the agent has spendt in dialogs ended this hour'
						},
						numberOfDialogs: {
							NAME: 'Dialogs',
							TITLE: 'Number of dialogs the agent(s) has been involved in',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of dialogs the agent has closed this hour'
						},
						avg_sim_dialogs: {
							NAME: 'Avg. sim. diag',
							TITLE: 'Average simultaneous dialogs',
							LONG_DESCRIPTION: 'This number represents the number of simultaneous dialogs the agent has had during the engage time'
						},
						avg_dialog_time: {
							NAME: 'Avg. dialog time',
							TITLE: 'Average dialog duration',
							LONG_DESCRIPTION: 'This number is calculated by dividing total dialog time on the number of dialogs'
						},
						numberOfClosedCases: {
							NAME: 'Cases closed',
							TITLE: 'Number of cases closed',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of cases the agent(s) has closed during this hour'
						},
						numberOfRelatedAssistedPurchasePois_sameVisit: {
							NAME: 'Ass. Pur. (same visit)',
							TITLE: 'Number of purchases where the visitor received assistance during the same visit - Percent of cases related to purchases',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of registered purchase confirmations where assistance was provided during the same visit.(A case is related to the same visit) PS! There must be a configured POI of type Purchase confirmation!'
						},
						numberOfRelatedAssistedPurchasePois_6Hours: {
							NAME: 'Ass. Pur. (6hours)',
							TITLE: 'Number of purchases where the visitor received assistance within 6hours - Percent of cases related to purchases',
							LONG_DESCRIPTION: 'This number is counted per hour and represents the number of registered purchase confirmations where assistance was provided to the visitor within 6hours. (A case that has been engaged in or closed is related to the same visitor) PS! There must be a configured POI of type Purchase confirmation!'
						},
						satisfactionScore: {
							NAME: 'Customer Satisfaction',
							TITLE: 'Customer satisfaction score of the service they received',
							LONG_DESCRIPTION: 'This number is counted per conversation'
						}
					},
					barFilters: {
						siteName: {
							HEADING: 'Dialogs per site'
						},
						groupName: {
							HEADING: 'Dialogs per group'
						},
						userName: {
							HEADING: 'Dialogs per agent'
						}
					}
				}
			}
		}
	},
	'fr-FR': {
		statistics: {
			views: {
				agentsbygroup: {
					MENU_LABEL: 'Agents par groupe',
					TITLE: 'Agents selon le groupe (file d\'attente)',
					TABLE_HEADING: 'Rapport horaire',
					TIME_LINE_GRAPH_HEADING: 'Dialogues par heure',
					measures: {
						siteName: {
							NAME: 'Site',
							TITLE: 'Nom du site présentant l\'option de chat',
							LONG_DESCRIPTION: ''
						},
						groupName: {
							NAME: 'Groupe (File d\'attente)',
							TITLE: 'Nom de la file d\'attente / groupe qui a traité la demande de chat',
							LONG_DESCRIPTION: ''
						},
						dimensionName: {
							NAME: 'Utilisateur dans le groupe (site)',
							TITLE: '',
							LONG_DESCRIPTION: '*Cette mesure est en fait un texte généré pour regrouper visuellement les dimensions dans le tableau de données. Elle est générée par « Nom d\'utilisateur » + De : « Nom du groupe » + « (Nom du site) »'
						},
						userName: {
							NAME: 'Agent',
							TITLE: 'Nom complet de l\'agent',
							LONG_DESCRIPTION: 'Nom complet des agents, ce n\'est pas le nom que voient les visiteurs !'
						},
						loggedInTimeMs: {
							NAME: 'Connecté en temps',
							TITLE: 'Durée totale de connexion H : mn: s',
							LONG_DESCRIPTION: 'Cette durée est calculée par heure et représente le temps pendant lequel l\'agent est resté connecté pendant cette heure'
						},
						availableTimeMs: {
							NAME: 'Durée de disponibilité',
							TITLE: 'Durée pendant laquelle le ou les agent(s) a ou ont été disponible(s) pour la file d\'attente (H: mn: s)',
							LONG_DESCRIPTION: 'Cette durée est calculée pour chaque heure et représente le temps durant lequel l\'agent est resté disponible pour la file d\'attente au cours de cette heure. La durée est représenté par la file d\'attente : si l\'agent est resté disponible 1 heure pour 2 files d\'attente, la durée totale sera de 2 heures'

						},
						engagedTimeMs: {
							NAME: 'Durée de prise en charge',
							TITLE: 'Durée totale de dialogue HH : mn : ss',
							LONG_DESCRIPTION: 'Cette durée est calculée par heure et représente le temps pendant lequel l\'agent a été actif dans les dialogues pendant cette heure'
						},
						dialogTimeMs: {
							NAME: 'Durée de dialogue',
							TITLE: 'Durée totale du dialogue. Ce nombre représente les durées des dialogues et est affiché au moment du raccrochage. Cette mesure par heure peut sembler étrange puisqu\'elle peut inclure la durée avant l\'heure à laquelle l\'événement a fait l\'objet d\'un suivi.',
							LONG_DESCRIPTION: 'Cette durée est calculée à la fin du dialogue et représente le temps que l\'agent a passé dans les dialogues terminés durant cette heure'
						},
						numberOfDialogs: {
							NAME: 'Dialogues',
							TITLE: 'Nombre de dialogues dans lesquels le ou les agent(s) a été impliqué ',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de dialogues que l\'agent a fermés durant cette heure'
						},
						avg_sim_dialogs: {
							NAME: 'Moy. diag. sim.',
							TITLE: 'Moyenne des dialogues simultanés',
							LONG_DESCRIPTION: 'Ce nombre représente le nombre de dialogues simultanés que l\'agent a menés pendant la durée de prise en charge'
						},
						avg_dialog_time: {
							NAME: 'Moy. durée du dialogue',
							TITLE: 'Durée moyenne de dialogue',
							LONG_DESCRIPTION: 'Ce nombre est calculé en divisant la durée totale de dialogue par le nombre de boîtes de dialogue'
						},
						numberOfClosedCases: {
							NAME: 'Dossiers fermés',
							TITLE: 'Nombre de dossiers fermés',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de dossiers fermés par le ou les agent(s) pendant cette heure'
						},
						numberOfRelatedAssistedPurchasePois_sameVisit: {
							NAME: 'Ach. Aid. (Même visite)',
							TITLE: 'Nombre d\'achats pour lesquels le visiteur a reçu de l\'aide au cours de la même visite - Pourcentage de dossiers liés aux achats',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de confirmations d\'achat enregistrées pour lesquelles une assistance a été fournie au cours de la même visite. (Un dossier est lié à la même visite) PS ! Il doit y avoir une preuve d\'identité configurée de type Confirmation d\'achat !'
						},
						numberOfRelatedAssistedPurchasePois_6Hours: {
							NAME: 'Ach. Aid. (6 heures)',
							TITLE: 'Nombre d\'achats où le visiteur a reçu une aide dans un intervalle de six heures - Pourcentage de dossiers liés à des achats',
							LONG_DESCRIPTION: 'Ce nombre est calculé par heure et représente le nombre de confirmations d\'achat enregistrées où une assistance a été fournie au visiteur dans un intervalle de 6 heures. (Un dossier qui a été ouvert ou fermé est lié au même visiteur) PS ! Il doit y avoir un POI configuré de type Confirmation d\'achat !'
						},
						satisfactionScore: {
							NAME: 'Satisfaction du client',
							TITLE: 'Score de satisfaction client du service reçu',
							LONG_DESCRIPTION: 'Ce nombre est compté par conversation'
						}
					},
					barFilters: {
						siteName: {
							HEADING: 'Dialogues par site'
						},
						groupName: {
							HEADING: 'Dialogues par groupe'
						},
						userName: {
							HEADING: 'Dialogues par agent'
						}
					}
				}
			}
		}
	}
};
