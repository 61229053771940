export default function () {
	'ngInject';

	return function (input) {
		if (!input && input !== 0) {
			return '--';
		}

		return input;
	};
};
