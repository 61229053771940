import React from 'react';
import {ResponsiveLine} from '@nivo/line';

const tooltipFunction = ({point}) => {
	const {data} = point;
	const {item} = data;
	const {duration, status, start, end} = item;

	const color = {
		'ok': '#000000',
		'warning': '#f0a000',
		'error': '#ff0000',
		'skip': '#777777',
	}[status];
	const style = {
		color,
		marginLeft: '50%',
		marginBottom: '-30px',
		fontSize: '10px'
	};
	const startTime = new Date(start).toISOString().split('T').pop();
	const endTime = new Date(end).toISOString().split('T').pop();

	return (
		<div style={style}>
			<div>duration:{duration}</div>
			<div>status:{status}</div>
			<div>{startTime}</div>
			<div>{endTime}</div>
			<br/>
		</div>
	);
};

const commonProperties = {
	animate: true,
	enableArea: true,
	colors: {scheme: 'brown_blueGreen'},
	margin: {
		top: 10,
		right: 10,
		bottom: 10,
		left: 10
	},

	useMesh: true,
	tooltip: tooltipFunction

};

const DebugPollChart = (props) => {
	const {data} = props;
	const max = data.reduce((v0, layer) => Math.max(layer.data.reduce((v1, coord) => Math.max(v1, coord.x), 0), v0), 0);
	const min = data.reduce((v0, layer) => Math.min(layer.data.reduce((v1, coord) => Math.min(v1, coord.x), Infinity), v0), Infinity);

	return (
		<div style={{height: '200px', width: '100%'}}>
			<ResponsiveLine
				{...commonProperties}
				data={data}
				axisBottom={null}
				axisLeft={null}
				yScale={{type: 'linear', stacked: false, min: 0, max: 12}}
				xScale={{type: 'linear', stacked: false, max, min}}
			/>

		</div>
	);
};

export default DebugPollChart;
