const create = (params, videoStreamingServer) => {
	videoStreamingServer = videoStreamingServer || psPlugin.application.configuration.visitor.videoStreamingServer;

	const VIDEO_ROOM_ID = 'is8xi1z';

	var broadcaster = params.inStreamId;
	var receiver = params.outStreamId;
	var type = 'live';
	var streamOutWidth = 300;
	var previewWidth = 112;
	var streamOutHeight = streamOutWidth * 0.75;
	var previewHeight = previewWidth * 0.75;

	var debug = window.vngageDebugVideo ? 'true' : 'false';
	var meetingId = params.meetingId || VIDEO_ROOM_ID;

	var mediaServer, mediaServer2;
	if (videoStreamingServer && videoStreamingServer.length > 1) {
		mediaServer = 'rtmp://' + videoStreamingServer + ':1935';
		mediaServer2 = 'rtmpt://' + videoStreamingServer + ':80';
	} else {
		mediaServer = 'rtmp://stream.psplugin.com:1935';
		mediaServer2 = 'rtmpt://stream.psplugin.com:80';
	}

	var height = parseInt(params.width * 0.85);

	var psCamRtmp = debug;

	psCamRtmp += '$' + mediaServer + '/dimdimPublisher/dimdim-v4.5____' + meetingId;
	psCamRtmp += '$' + broadcaster + '.' + meetingId + '.V';
	psCamRtmp += '$' + receiver + '.' + meetingId + '.V';
	psCamRtmp += '$' + type; //$PH.live
	psCamRtmp += '$' + mediaServer2 + '/dimdimPublisher/dimdim-v4.5____' + meetingId;
	psCamRtmp += '$AudioVideo';
	psCamRtmp += '$/dimdim/';
	psCamRtmp += '$' + params.inStreamId;
	psCamRtmp += '$' + meetingId;


	psCamRtmp += '$' + streamOutWidth;
	psCamRtmp += '$' + streamOutHeight;
	psCamRtmp += '$' + previewWidth;
	psCamRtmp += '$' + previewHeight;
	psCamRtmp += '$' + params.baseUrl;

	//console.info(params.autostart);
	if (typeof params.autostart !== 'undefined' && params.autostart) {
		psCamRtmp += '$true';
	}

	return ('<embed '
	+ 'height="' + height + 'px" '
	+ 'width="' + params.width + 'px" '
	+ 'wmode="transparent" '
	+ 'swliveconnect="true" '
	+ 'allowscriptaccess="always" '
	+ 'quality="high" bgcolor="ffffff" '
	+ 'name="swfID_1" id="swfID_1" '
	+ 'src="' + params.playerUrl + '?' + psCamRtmp + '"'
	+ 'type="application/x-shockwave-flash"'
	+ '/>');
}

export default {
	create
};
