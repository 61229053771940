import immutable from 'immutable'

const subscriptions = {
	testSubscription(plugin, prevState, state, reset) {
		if (reset) {
			return immutable.List();
		}
		return immutable.fromJS([{
			test: true
		}]);
	},
};

export default {
	subscriptions
}
