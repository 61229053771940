export default {
	'en-GB': {
		myHistory: {
			PAGE_HEADER: 'My cases'
		}
	},
	'sv-SE': {
		myHistory: {
			PAGE_HEADER: 'Mina ärenden'
		}
	},
	'fr-FR': {
		myHistory: {
			PAGE_HEADER: 'Mes dossiers'
		}
	}
};
