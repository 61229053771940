export default {
	setItem(key, value){
		localStorage.setItem(key,value);
	},

	removeItem(key, value){
		localStorage.removeItem(key);
	},

	getItem(key){
		return localStorage.getItem(key);
	},

	hasStorage(){
		let test = 'test919299394';
		try {
			localStorage.setItem(test, test);
			localStorage.removeItem(test);
			return true;
		} catch(e) {
			return false;
		}
	}
}
