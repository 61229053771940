import React, {useState, useEffect, useRef} from 'react';
import DoNotDisturbOverlay from './DoNotDisturbOverlay.react';
import {
	postNewMetadataMessagesToChildFrame,
	whoAreYou,
	onPageLoaded,
	updateHighlight,
	createChannel
} from './../../../PostMessageHandlers';

const panelWidth = 273;
const legacyMethods = ['whoAreYou', 'onPageLoaded', 'onCapturedNavigation', 'onElementHighlighted', 'metadataExchange'];
const accountMethods = ['onPageLoaded', 'onCapturedNavigation', 'onElementHighlighted', 'metadataExchange'];

const getScaledStyle = ({isScaled, visitorWidth, panelContainerWidth}) => {
	visitorWidth = visitorWidth === 0
		? panelContainerWidth
		: visitorWidth;

	const scale = isScaled ? (panelContainerWidth - panelWidth) / visitorWidth: 1;
	const width = visitorWidth + 'px';
	const height = scale !== 1 ? Math.floor(100 / scale) + '%': '100%';
	return {
		width,
		height,
		MozTransform: 'scale(' + scale + ')',
		WebkitTransform: 'scale(' + scale + ')',
		msTransform: 'scale(' + scale + ')',
		transform: 'scale(' + scale + ')'
	};
};

const CoChannel = (props) => {
	const iFrameRef = useRef();

	const [legacyChannel, setLegacyChannel] = useState(null);
	const [accountChannel, setAccountChannel] = useState(null);
	const [channel, setChannel] = useState(null);


	const channelSubscriber = (channelType, methodName, trans, params) => {
		switch (methodName) {
			case 'whoAreYou':
				return whoAreYou(trans, params, props);
			case 'onPageLoaded':
				onPageLoaded(trans, params, props, channel);
				break;
			case 'onCapturedNavigation':
				props.pageLoaded(params.url);
				break;
			case 'onElementHighlighted':
				props.elementHighLighted(params);
				break;
			case 'metadataExchange':
				props.sendMetadataExchange(props.conversationId, params);
				break;
			default:
				props.cobroError('invalid method', {methodName, trans, params});
				break;
		}
	};

	const accountChannelReady = (ch) => {
		props.cobroLog('account jsChannel ready', '');
		setChannel(ch);
	};

	const legacyChannelReady = () => {
		props.cobroLog('legacy jsChannel ready');
	};

	// componentDidMount componentWillUnmount
	useEffect(() => {
		const iFrameContentWindow = iFrameRef.current.contentWindow;

		let scope = 'cobrowser';
		const legacyCh = createChannel('legacyChannel', iFrameContentWindow, scope, legacyChannelReady, channelSubscriber, legacyMethods);
		setLegacyChannel(legacyCh);

		scope = `cobrowser_${props.accountId.toLowerCase()}`; // [TL] Set up the accountChannel with embedded accountId to prevent cross-account-cobrowsing! (NOTE: Case sensitive!)
		const accountCh = createChannel('accountChannel', iFrameContentWindow, scope, accountChannelReady, channelSubscriber, accountMethods);
		setAccountChannel(accountCh);

		setChannel(legacyCh);
		return () => {
			if (accountChannel) {
				accountChannel.destroy();
			}

			if (legacyChannel) {
				legacyChannel.destroy();
			}

			if (channel) {
				channel.destroy();
			}
			setAccountChannel(null);
			setLegacyChannel(null);
			setChannel(null);

		};
	}, []);

	//componentWillReceiveProps
	const savedProps = useRef(props);
	useEffect(() => {
		const prevProps = savedProps.current;
		const nextProps = props;

		if (nextProps.highlightMode !== prevProps.highlightMode) {
			updateHighlight(nextProps.highlightMode, channel);
		}

		if (nextProps.isScaled !== prevProps.isScaled || nextProps.panelContainerWidth !== prevProps.panelContainerWidth || nextProps.visitorWidth !== prevProps.visitorWidth) {
			setStyle(getScaledStyle(nextProps));
		}

		if ((nextProps.paused !== prevProps.paused && !nextProps.paused) || nextProps.refreshUrlCounter !== prevProps.refreshUrlCounter) {
			// force reload of same url
			iFrameRef.current.src = nextProps.url;
		}

		if (nextProps.metadataExchangeMessages.size > prevProps.metadataExchangeMessages.size) {
			// New metadataExchangeMessages in props...
			const newIncomingMessages = nextProps.metadataExchangeMessages.slice(prevProps.metadataExchangeMessages.size).toJS();
			postNewMetadataMessagesToChildFrame(newIncomingMessages, channel);
		}

		savedProps.current = props;

	}, [props]);

	const [style, setStyle] = useState(getScaledStyle(props));
	const url = props.url;
	return (
		<div className="co-iframe">
			<DoNotDisturbOverlay
				infoTipDoNotDisturb={props.infoTipDoNotDisturb}
				restricted={props.restricted}
				toggleActiveInfoTip={props.toggleActiveInfoTip}
				hideInfoTip={props.hideInfoTip}
				paused={props.paused}/>
			<iframe
				src={url}
				style={style}
				ref={iFrameRef}
				sandbox="allow-popups allow-same-origin allow-scripts allow-forms"
			>
			</iframe>
		</div>
	);
};
export {CoChannel};

