import React from 'react';
import VideoView from '../../video/VideoView.react';

const VideoChatSection = () => {
	return (
		<div className="video-chat-section">
			<VideoView/>
		</div>
	)
};
export default VideoChatSection;

