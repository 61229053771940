import moment from 'moment-timezone';

export default function ($scope, $state, $timeout, $q, SessionService, vngageUserFilter, userList, customScript, liveRevision, customScriptsValidationError, NotificationService, APIEndpointService, usSpinnerService) {
	'ngInject';

	var vm = this;
	vm.customScript = customScript;
	vm.liveRevision = liveRevision;
	vm.save = save;
	vm.cancel = cancel;
	vm.compare = compare;
	vm.updateEditor = updateEditor;
	vm.advanced = false;
	vm.showCode = true;
	vm.collapseIdentical = false;
	vm.selectedOrigin = customScript;
	vm.selectedOriginNumber = customScript.revision;
	vm.selectedRevision = customScript;
	vm.selectedRevisionNumber = customScript.revision;
	vm.revisionUpdatedAt = formatWithTZ(vm.customScript.updatedAt);
	vm.revisionArr = Array.apply(null, new Array(vm.selectedRevisionNumber + 1).map(Number.prototype.valueOf, 0)).map((n, i) => i).reverse();
	vm.byUser = vngageUserFilter(vm.selectedRevision.userId, userList);
	vm.customScriptsValidationError = customScriptsValidationError;
	vm.history = null;	// Only fetch history when needed! (very much data on old accounts!)
	vm.toggleAdvanced = toggleAdvanced;

	$scope.$on('resetResettables', () => {
		vm.customScriptForm.$setPristine();
		customScript.$get().catch(() => {});
	});

	function formatWithTZ(date) {
		return moment.utc(date).tz(SessionService.getUserTimezone()).format('dddd MMM D HH:mm - YYYY');
	}

	function updateEditor() {
		vm.showCode = false;
		$timeout(() => {
			// forced render of codemirror
			vm.showCode = true;
		}, 0);
	}

	function toggleAdvanced() {
		if (!vm.history) {
			if (!vm.advanced) {
				// Activation "advanced", and history not yet fetched: fetch it! (may be a very large fetch on old accounts with long history, so don't fetch in module resolve!)
				usSpinnerService.spin('spinner-history-loading');
				APIEndpointService.scriptHistory.query({id: customScript.data.id}).$promise.then(res => {
					vm.history = res;
					vm.advanced = true;
					usSpinnerService.stop('spinner-history-loading');
				}, err => {
					NotificationService.error({
						body: err.statusText,
						header: 'Fetching history failed'
					});
					usSpinnerService.stop('spinner-history-loading');
				});
			} else {
				vm.advanced = false;
			}
		} else {
			vm.advanced = !vm.advanced;
		}
	}

	function compare() {
		vm.selectedOrigin = vm.history[vm.selectedOriginNumber];
		vm.selectedRevision = vm.history[vm.selectedRevisionNumber];
		vm.revisionUpdatedAt = formatWithTZ(vm.selectedRevision.updatedAt);
		vm.byUser = vngageUserFilter(vm.selectedRevision.userId, userList);

		updateEditor();
	}

	function save() {
		vm.isSaving = true;
		vm.customScript.$save().finally(function() {
			vm.isSaving = false;
		});
	}

	function cancel() {
		$state.go('^');
	}
};
