import moment from 'moment-timezone';
import caseBrowserReservationTemplate from './caseBrowserReservationTemplate';
import {find as _find} from 'lodash/collection';
import {sortBy as _sortBy} from 'lodash/collection';

export default function (SessionService, $translate) {
	'ngInject';

	function formatFormFieldItem(formFieldItem) {
		let value;
		switch (formFieldItem.type) {
			case 'time':
				value = moment.utc(formFieldItem.value).tz(SessionService.getUserTimezone()).format('L LTS');
				break;
			case 'checkbox':
				value = (formFieldItem.value ? $translate.instant('caseBrowserModal.RESERVATION_YES'): $translate.instant('caseBrowserModal.RESERVATION_NO'));
				break;
			default:
				value = formFieldItem.value;
		}
		return value;
	}

	function analyzeCustomFormData(reservation) {
		// Convert to array, sorted on 'position'...
		reservation.metadata.customForms = _sortBy(reservation.metadata.customForms, ['position']);

		// Remove customForm elements that are 'hiddenForAgent'
		reservation.metadata.customForms = reservation.metadata.customForms.filter(item => !item.hiddenForAgent);

		// If customForms is incomplete, fill it with values from reservation.name, reservation.lastName, reservation.phone, reservation.email
		if (reservation.name && !_find(reservation.metadata.customForms, {'name': 'firstName'}) && !_find(reservation.metadata.customForms, {'name': 'name'})) {
			// For backwards compatibility: Add firstName from reservation base object if missing in metadata.customForms
			reservation.metadata.customForms.push({
				'name': 'firstName',
				'label': $translate.instant('caseBrowserModal.RESERVATION_FIRST_NAME'),
				'type': 'text',
				'validateAs': 'letters',
				'value': reservation.name
			});
		}
		if (reservation.lastName && !_find(reservation.metadata.customForms, {'name': 'lastName'})) {
			// For backwards compatibility: Add lastName from reservation base object if missing in metadata.customForms
			reservation.metadata.customForms.push({
				'name': 'lastName',
				'label': $translate.instant('caseBrowserModal.RESERVATION_LAST_NAME'),
				'type': 'text',
				'validateAs': 'letters',
				'value': reservation.lastName
			});
		}
		if (reservation.email && !_find(reservation.metadata.customForms, {'name': 'email'})) {
			// For backwards compatibility: Add email from reservation base object if missing in metadata.customForms
			reservation.metadata.customForms.push({
				'name': 'email',
				'label': $translate.instant('caseBrowserModal.RESERVATION_EMAIL'),
				'type': 'text',
				'validateAs': 'email',
				'value': reservation.email
			});
		}
		if (reservation.phone && !_find(reservation.metadata.customForms, {'name': 'phone'})) {
			// For backwards compatibility: Add phone from reservation base object if missing in metadata.customForms
			reservation.metadata.customForms.push({
				'name': 'phone',
				'label': $translate.instant('caseBrowserModal.RESERVATION_PHONE'),
				'type': 'text',
				'validateAs': 'phone',
				'value': reservation.phone
			});
		}

		// Also add group name and start time first
		if (!_find(reservation.metadata.customForms, {'name': '__groupName'})) {
			reservation.metadata.customForms.unshift({
				'name': '__groupName',
				'label': $translate.instant('caseBrowserModal.RESERVATION_GROUP'),
				'type': 'text',
				'validateAs': 'text',
				'value': reservation.groupName
			});
		}
		if (!_find(reservation.metadata.customForms, {'name': '__meetingStartTime'})) {
			reservation.metadata.customForms.unshift({
				'name': '__meetingStartTime',
				'label': $translate.instant('caseBrowserModal.RESERVATION_START_TIME'),
				'type': 'time',
				'validateAs': 'time',
				'value': reservation.start
			});
		}

		reservation.metadata.customForms_analyzed = true;
	}

	return {
		restrict: 'E',
		scope: {
			reservation: '='
		},
		templateUrl: caseBrowserReservationTemplate,
		link: function (scope) {
			analyzeCustomFormData(scope.reservation);

			scope.customForms = scope.reservation.metadata && scope.reservation.metadata.customForms || [];
			scope.customForms.map(formFieldItem => {
				formFieldItem.parsedValue = formatFormFieldItem(formFieldItem);
			});
		}
	};
};
