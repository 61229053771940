import WhiteLabelStyleTemplate from './WhiteLabelStyleTemplate.css?raw';
import WhiteLabelDefaults from './WhiteLabelDefaults';

export default function($interpolate) {
	'ngInject';

	let logoUrls = {
		urlLogoExpanded: '',
		urlLogoCollapsed: ''
	};
	let updateObservers = [];

	const getWhiteLabelDefaults = () => {
		return Object.assign({}, WhiteLabelDefaults.defaultConfig);
	};
	const getVergicWhiteLabel = () => {
		return Object.assign({}, WhiteLabelDefaults.vergicConfig);
	};

	const getLogoUrls = () => {
		return logoUrls;
	};

	const registerUpdateObserver = observer => {
		updateObservers.push(observer);
	};
	const removeUpdateObserver = observer => {
		updateObservers = updateObservers.filter(item => {
			return (item !== observer);
		});
	};
	const notifyUpdateObservers = whiteLabelConfig => {
		whiteLabelConfig && whiteLabelConfig.enabled || (whiteLabelConfig = getVergicWhiteLabel());
		updateObservers.forEach(observer => {
			observer(whiteLabelConfig);
		});
	};

	const injectStyle = (whiteLabelConfig, styleTagId = 'whiteLabelCss') => {
		whiteLabelConfig && whiteLabelConfig.enabled || (whiteLabelConfig = getVergicWhiteLabel());
		const css = (whiteLabelConfig.enabled ? $interpolate(WhiteLabelStyleTemplate)(whiteLabelConfig) : '');

		let styleElem = document.getElementById(styleTagId);
		if (!styleElem) {
			const head = document.head || document.getElementsByTagName('head')[0];
			styleElem = document.createElement('style');
			styleElem.setAttribute('type', 'text/css');
			styleElem.setAttribute('id', styleTagId);
			head.appendChild(styleElem);
		}
		styleElem.innerHTML = css;
	};

	const updateLogos = (whiteLabelConfig) => {
		whiteLabelConfig && whiteLabelConfig.enabled || (whiteLabelConfig = getVergicWhiteLabel());
		logoUrls = {
			urlLogoExpanded: whiteLabelConfig.urlLogoExpanded,
			urlLogoCollapsed: whiteLabelConfig.urlLogoCollapsed
		};
	};

	const updateFavIcon = (whiteLabelConfig, favIconId = 'favIconId') => {
		whiteLabelConfig && whiteLabelConfig.enabled || (whiteLabelConfig = getVergicWhiteLabel());
		document.head || (document.head = document.getElementsByTagName('head')[0]);

		// First remove all existing favicons specified in HEAD
		const links = document.head.getElementsByTagName('link');
		for (let i = 0; i < links.length; i++) {
			const linkEl = links[i];
			const rel = linkEl.getAttribute('rel');
			if (rel && rel.indexOf('icon') !== -1 && linkEl.getAttribute('id') !== favIconId) {
				document.head.removeChild(linkEl);
			}
		}

		let favIconElem = document.getElementById(favIconId);
		if (!favIconElem) {
			favIconElem = document.createElement('link');
			favIconElem.setAttribute('id', favIconId);
			favIconElem.setAttribute('rel', 'shortcut icon');
			favIconElem.setAttribute('type', 'image/x-icon');
			document.head.appendChild(favIconElem);
		}
		favIconElem.setAttribute('href', whiteLabelConfig.urlFavicon);
	};

	const _setBlankValuesToDefault = (whiteLabelConfig) => {
		// Don't set empty strings in the white label-css. Instead, convert them to defaults before injecting
		const defaults = getVergicWhiteLabel();
		let resultConfig = Object.assign({}, defaults, whiteLabelConfig);
		for (let key in defaults) {
			if (defaults.hasOwnProperty(key)) {
				resultConfig[key] || (resultConfig[key] = defaults[key]);
			}
		}
		return resultConfig;
	};

	const injectWhiteLabelConfig = (whiteLabelConfig) => {
		whiteLabelConfig = _setBlankValuesToDefault(whiteLabelConfig);
		injectStyle(whiteLabelConfig);
		updateLogos(whiteLabelConfig);
		updateFavIcon(whiteLabelConfig);
		window.document.title = whiteLabelConfig.documentTitle;
		notifyUpdateObservers(whiteLabelConfig);
	};

	return{
		getWhiteLabelDefaults,
		getVergicWhiteLabel,
		getLogoUrls,
		registerUpdateObserver,
		removeUpdateObserver,
		notifyUpdateObservers,
		injectStyle,
		updateLogos,
		injectWhiteLabelConfig
	};
};
