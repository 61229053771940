import React from 'react';
import { injectIntl, FormattedTime } from 'react-intl';

import createReactClass from 'create-react-class';

export default injectIntl(createReactClass({

	render () {
		let formFieldItem = this.props.formFieldItem;
		let value;

		switch (formFieldItem.type) {
			case 'time':
				value = <FormattedTime value={formFieldItem.value}
									   day = "numeric"
									   month = "long"
									   year = "numeric"
									   hour = "numeric"
									   minute = "numeric"/>;
				break;
			case 'checkbox':
				value = this.props.intl.formatMessage({id: formFieldItem.value ? 'reservationYes' : 'reservationNo'});
				break;
			default:
				value = formFieldItem.value;
		}

		return (
			<li className="reservation-info">
				<div>
					<span className="reservation-form-field-label" style={{
						fontWeight: 'bold',
						paddingRight: '5px'
					}}>{formFieldItem.label || formFieldItem.name}:</span>
					<span className="reservation-form-field-value">{value}</span>
				</div>
			</li>
		);
	}
}));
