import WebNotificationService from '../WebNotificationService';
import React from 'react';
import ReactDOM from 'react-dom';
import {IntlProvider} from 'react-intl';
import i18nLoader from '../../../i18n';
import uiView from './ui/view';
import User from '../User';
import ViewActions from '../actions/ViewActions';
import Session from '../../../common/auth/Session';
import PanelTitleContainer from '../components/PanelTitleContainer.react';
//import InviteGuest from './InviteGuest';
import ParticipantStore from '../stores/ParticipantStore';
import TransferToGroup from '../components/TransferToGroup.react';
import HangUp from '../components/HangUp.react';
import ButtonToolBar from '../components/ButtonToolBar.react';
import ServerActions from '../actions/ServerActions';
import EventStore from '../stores/EventStore';
import SoundFx from '../SoundFx';

var coWorker = {
	visitors: null,
	activeVisitor: null,
	placements: [
		{visitor: false, color: 'Green'},
		{visitor: false, color: 'Blue'},
		{visitor: false, color: 'Yellow'},
		{visitor: false, color: 'Red'},
		{visitor: false, color: 'Orange'}
	],
	parentElem: null,
	/*
	 * --------------------------------------------------
	 * Run when module is loaded
	 * --------------------------------------------------
	 */
	load: function () {
		this.visitors = psPlugin.application.visitorHandler.visitors;
		// parent node
		this.parentElem = psPlugin.shell.pageLayout.workspace.elem;

		// Add eventlistener for when to start
		psPlugin.application.events.onLockedList.subscribe('coWorker', (function (list) {
			psPlugin.application.modules.coWorker.handleLocked(list);
		}));
	},

	handleLocked: function (list) {
		// loop visitor list and assign panel view to visitor
		var newVisitorsArr = [],
			visitor;

		for (var i in list) {

			visitor = this.visitors[list[i]];
			if (typeof visitor.coWorker == 'undefined') {
				//newVisitorsArr.push(list[i]); // no longer needed to delay loading, com server requests are bundled
				this.createNew(list[i]);

				// only notify if it is an active dialog, Completed dialogs will also get new coWorker panels
				// after a page refresh.
				// routingTypes: 0=manual, 1=automatic, 2=external
				if (visitor.properties.initialState !== 'Completed' &&
					psPlugin.application.configuration.groups[visitor.properties.toGroup].routingType === 1
				) {
					WebNotificationService.notify('lockedVisitor', list);
				}

			}
			try {
				this.visitors[list[i]].coWorker.confirmStatus();
			} catch (err) { }
		}
		/*
		var i = 0;
		var createNext = function () {
			if (i < newVisitorsArr.length) psPlugin.application.modules.coWorker.createNew(newVisitorsArr[i]);
			i++;
			// set delay for loading of other coBrowsers
			setTimeout(function () {
				createNext();
			}, 500);
		};
		// set short delay for loading of first coBrowser
		setTimeout(function () {
			createNext();
		}, 200);
		*/
	},
	createNew: function (id) {
		var visitorList = this.visitors;
		this.visitors[id].coWorker = new coWorker.panel(coWorker.visitors[id], coWorker.placement({
			id: id
		}));
		psPlugin.application.events.onCoWorkerLoaded.trigger(coWorker.visitors[id]);
	},
	/*
	 * --------------------------------------------------
	 * Minimize all views when another is maximized
	 * --------------------------------------------------
	 */
	minimizeAll: function () {
		for (var id in this.visitors) {
			try {
				this.visitors[id].coWorker.minimize();
			} catch (err) {}
		}
	},
	/*
	 * --------------------------------------------------
	 * Minimize all views when another is maximized
	 * --------------------------------------------------
	 */
	displayActionPanel: function (panelData) {
		//setTimeout(function () {
		//	psPlugin.shell.pageLayout.menuSection.menu.deSelectAll();
		//}, 400);
		if (psPlugin.application.modules.coWorker.activeVisitor) {
			psPlugin.application.modules.coWorker.visitors[psPlugin.application.modules.coWorker.activeVisitor].coWorker.addActionPanel(panelData);
		}

	},
	/*
	 * --------------------------------------------------
	 * Placements and colors
	 * --------------------------------------------------
	 */
	placement: function (params) {
		for (var p in this.placements) {
			if (!this.placements[p].visitor) {
				this.placements[p].visitor = params.id;
				params.color = 'panelSelected' + this.placements[p].color;
				return params;
			}
		}
	},

	checkIfAvailablePlacements: function (params) {
		for (var p in this.placements) {
			if (!this.placements[p].visitor) {
				return true;
			}
		}
		return false;
	},

	removeFromPlacements: function (id) {
		for (var p in this.placements) {
			if (this.placements[p].visitor == id) {
				this.placements[p].visitor = false;
				return true;
			}
		}
		return false;
	}
};


var panel = function (visitor, params) {

	var parent = coWorker.parentElem;
	var inDialog = false;
	// create wrapper element to manage position
	var positionWrapperElem = document.createElement('div');

	params = params || {};

	positionWrapperElem.className = 'visitorPanelWrapper';
	positionWrapperElem.style.display = 'none';
	parent.appendChild(positionWrapperElem);

	/*var actionSectionElem = document.createElement('div');

	actionSectionElem.className = 'coWorkerActionPanelArea';
	parent.appendChild(actionSectionElem);*/

	visitor.events.addEvents({
		onCoWorkerMaximize: [],
		onCoWorkerMinimize: [],
		onCoworkerApproved: [],
		onCoworkerNotApproved: []
	});

	/*
	 * --------------------------------------------------
	 * Panel content
	 * --------------------------------------------------
	 */
	var title = visitor.properties.title || '';
	this.title = title;
	var titleState = false;
	var writeTitle = function (state, newTitle) {

		if (typeof state === 'undefined' && titleState) {
			state = titleState;
		} else {
			titleState = state;
		}
		if (typeof newTitle !== 'undefined') title = newTitle;

		switch (state) {
			//case 'Locked':
			//	return title + ' (' + psPlugin.application.writeOutLanguage('coWorkerTitleStateLocked') + ')';
			case 'Active':
				return title;
			//case 'PendingApproval':
			//	return title + ' (' + psPlugin.application.writeOutLanguage('coWorkerTitleStatePendingApproval') + ')';
			case 'Declined':
				return title + ' (' + psPlugin.application.writeOutLanguage('coWorkerTitleStateDeclined') + ')';
			case 'TimedOut':
				return title + ' (' + 'Timed out' + ')';
			case 'Ended':
				return title + ' (' + psPlugin.application.writeOutLanguage('coWorkerTitleStateEnded') + ')';
			case 'ConnectionDead':
				return title + ' (' + psPlugin.application.writeOutLanguage('coWorkerTitleStateConnectionDead') + ')';
			case 'ConnectionLost':
				return title; //+ ' (' + psPlugin.application.writeOutLanguage('coWorkerTitleStateConnectionLost') + ')';
			default:
				return title;
		}
	};

	function makeTitleElements() {
		var titleElems = document.createElement('div');

		try {
			panel.setTitle(titleElems);
			i18nLoader(Session.user.profile.language, function(i18n) {
				const locale = (Array.isArray(i18n.locales) ? i18n.locales[0] : i18n.locales);
				ReactDOM.render(<IntlProvider locale={locale} messages={i18n.messages}><PanelTitleContainer
					key={'claim_'+visitor.properties.sessionId}
					conversationId={visitor.properties.sessionId}
					visitId={visitor.properties.vId}
					/></IntlProvider>, titleElems);
				visitor.reactComponents = visitor.reactComponents || [];
				visitor.reactComponents.push(titleElems);
			});
		} catch (err) {
			console.log(err);
		}
	}

	//var identified = false;
	var setTitle = function (state, newTitle) {
		var title = writeTitle(state, newTitle);
		ViewActions.visitorSetTitle(visitor.properties.vId, title);
	};

	this.setTitle = setTitle;


	var layout = helpers.layout();

	//internal references
	var leftArea = layout.leftArea;
	var coBrowsingArea = layout.coBrowsingArea;
	var dropZoneWrapper = layout.dropZoneWrapper;
	var dropZoneArea = layout.dropZoneArea;
	var plugininArea = layout.plugininArea;
	var actionPanelArea = layout.actionPanelArea;
	var visitorDetailsArea = layout.visitorDetailsArea;
	//--------var endButton = layout.endButton;
	//--------var startButton = layout.startButton;
	//--------var blockButton = layout.blockButton;
	//var inviteButton = layout.inviteButton;
	//var kickInviteeButton = layout.kickInviteeButton;

	//if (!User.canInviteGuest) {
	//	inviteButton.style.display = 'none !important';
	//	kickInviteeButton.style.display = 'none !important';
	//}

	//--------var releaseButton = layout.releaseButton;
	//--------var hangUpButton = layout.hangUpButton;


	// external references
	this.refCodeArea = layout.refCodeArea;
	this.coBrowsingArea = coBrowsingArea;
	this.dropZoneArea = dropZoneArea;
	this.actionPanelArea = actionPanelArea;
	this.plugininArea = plugininArea;
	this.visitorDetailsArea = visitorDetailsArea;
	this.view = positionWrapperElem;

	/*------endButton.onclick = function () {

		//console.log(visitor.stateMachine.currentState);
		// if (visitor.stateMachine.currentState == "Locked") {
		//	transfer(visitor.properties.toGroup);
		// } else {
		//	visitor.coWorker.psCase.closeCase(inDialog);
		// }
		visitor.coWorker.psCase.closeCase(inDialog);
	};*/


	//var logComment = function (comment) {
	//	ViewActions.createNoteMessage(comment,visitor.properties.sessionId);
	//};

	/*----startButton.onclick = function () {
		visitor.commands.Begin.execute();
		setState('PendingApproval');
	};*/

	//kickInviteeButton.onclick = function () {
	//	var conversationId = visitor.properties.sessionId,
	//		guestParticipant = ParticipantStore.getGuestParticipantOfConversation(conversationId);
	//
	//	if (guestParticipant) {
	//		ViewActions.kickGuest(conversationId, guestParticipant.id);
	//	}
	//};
	//
	//inviteButton.onclick = function () {
	//	InviteGuest(visitor.properties.sessionId);
	//};

	/*------blockButton.onclick = function () {
		panel.setState('inactive', psPlugin.core.ui.message({
			heading: psPlugin.application.writeOutLanguage('coWorkedBlockConfirmHeading'),
			content: '',
			icon: 'warning',
			buttons: [{
				text: psPlugin.application.writeOutLanguage('coWorkerBlockConfirmCancel'),
				onclick: function () {
					panel.setState('active');
				}
			}, {
				text: psPlugin.application.writeOutLanguage('coWorkerBlockConfirm'),
				onclick: function () {
					// write message to log
					var comment = psPlugin.application.writeOutLanguage('coWorkerBlockBlocked');
					logComment(comment);


					//TODO: finalize Block functionality
					// var conversationId = visitor.properties.sessionId,
					//	visitorParticipant = ParticipantStore.getVisitorParticipantOfConversation(conversationId);
					//if (visitorParticipant) {
					//	$http.post( vngageConfig.restUrl + 'conversation/' + conversationId + '/Ban?participantId='+visitorParticipant.id);
					//}

					visitor.commands.block.execute();
					visitor.commands.EndDialog.execute();
					setState('Completed');
					visitor.coWorker.panel.close();
					console.info('**BAN**')

					//visitor.commands.closeCase.execute(visitor.coWorker.psCase.activeCase);
					//visitor.coWorker.panel.close();
					//visitor.close();
					//panel.close();
					//close();
				}
			}]
		}));
	};*/


	function transfer(toGroup) {
		visitor.commands.saveCase.execute(visitor.coWorker.psCase.activeCase);
		visitor.commands.Transfer.execute(toGroup);
		//transfer now handles panel close
		//visitor.coWorker.panel.close();
	}

	this.transfer = transfer;

	function handleTransferClick(e) {
		e.preventDefault();

		var el = e.target,
			groupId = el.getAttribute('data-id');

		if (!!groupId) {
			transfer(groupId);
		}
	}

	function renderTransferMenu(items) {
		var item,
			i,
			el = '',
			className = '',
			indicate = '',
			len = items.length,
			meta = '',
			isCurrentGroup;

		for (i = 0; i < len; i++) {

			item = items[i];
			isCurrentGroup = (item.id === currentGroup);

			className = isCurrentGroup ? 'class="item current" ': '';
			meta = '<div class="item-meta">' + psPlugin.application.writeOutLanguage('coWorkerTransferUsersOnline') + ': ' + item.numberOfUsers + '<br>' + psPlugin.application.writeOutLanguage('coWorkerTransferVisitorsInQueue') + ': ' + item.numberOfVisitors + '</div>';
			if (item.numberOfUsers > 0) {
				el += '<li><a ' + className + ' href="#/transferTo/' + item.id + '" data-id="' + item.id + '">' + item.name + '</a>' + meta + '</li>';
			} else {
				el += '<li><span class="item disabled">' + item.name + '</span>' + meta + '</li>';
			}
		}
		dd.innerHTML = el;
	};


	//keep tabs on current group
	//var currentGroup = visitor.properties.toGroup.toUpperCase();
	var lang = Session.user.profile.language,
		currentGroup = visitor.properties.toGroup.toUpperCase();


	// TODO: turn this into a seperate configuration property once ININ configuration works with queue transfers:
	// only show transfer functionality if the queue is not hidden in config
	// Trying to live without explicit angular services
	//if (DesktopService.configuration.account.hideQueue !== true) {
	if (psPlugin.application.configuration.account.hideQueue !== true) {


		/*----releaseButton.onclick = function () {
			// write message to log
			var comment = psPlugin.application.writeOutLanguage('coWorkerReleaseButtonConfirmed');
			logComment(comment);
			visitor.commands.saveCase.execute(visitor.coWorker.psCase.activeCase.props);
			visitor.coWorker.panel.close();
		};*/
		/*---releaseButton.setAttribute('data-id', currentGroup);
		releaseButton.className = 'psPlugin-dd-trigger';
		releaseButton.id = 'transfer';*/

		var dd = document.createElement('ul');
		dd.className = 'psPlugin-dd-menu';


		function renderTransferMenu(items) {

			var item,
				i,
				el = '',
				className = '',
				indicate = '',
				len = items.length,
				meta = '',
				isCurrentGroup;

			for (i = 0; i < len; i++) {

				item = items[i];
				isCurrentGroup = (item.id === currentGroup);

				className = isCurrentGroup ? 'class="item current" ': '';
				meta = '<div class="item-meta">' + psPlugin.application.writeOutLanguage('coWorkerTransferUsersOnline') + ': ' + item.numberOfUsers + '<br>' + psPlugin.application.writeOutLanguage('coWorkerTransferVisitorsInQueue') + ': ' + item.numberOfVisitors + '</div>';
				if (item.numberOfUsers > 0) {
					el += '<li><a ' + className + ' href="#/transferTo/' + item.id + '" data-id="' + item.id + '">' + item.name + '</a>' + meta + '</li>';
				} else {
					el += '<li><span class="item disabled">' + item.name + '</span>' + meta + '</li>';
				}
			}
			dd.innerHTML = el;
		};

		//---layout.transferToGroupWrap.appendChild(dd);
		//---psPlugin.core.jsExt.addEventListener('click', layout.transferToGroupWrap, handleTransferClick);
		/*i18nLoader(lang, function (i18n) {
			ReactDOM.render(<TransferToGroup currentGroupId={currentGroup} conversationId={visitor.properties.sessionId} {...i18n} />, layout.transferButton);
			visitor.reactComponents = visitor.reactComponents || [];
			visitor.reactComponents.push(layout.transferButton);
		});

		i18nLoader(lang, function (i18n) {
			ReactDOM.render(<HangUp conversationId={visitor.properties.sessionId} label={psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonHangUp')} title={psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonHangUpTitle')} {...i18n} />, layout.hangUpElem);
			visitor.reactComponents = visitor.reactComponents || [];
			visitor.reactComponents.push(layout.hangUpElem);
		});*/





		i18nLoader(lang, function (i18n) {
			const locale = (Array.isArray(i18n.locales) ? i18n.locales[0] : i18n.locales);
			ReactDOM.render(<IntlProvider locale={locale} messages={i18n.messages}><ButtonToolBar inDialog={inDialog} conversationId={visitor.properties.sessionId} currentGroupId={currentGroup} text={psPlugin.application.writeOutLanguage} groupList={psPlugin.application.configuration.groups} /></IntlProvider>, layout.buttonToolBar);
			visitor.reactComponents = visitor.reactComponents || [];
			visitor.reactComponents.push(layout.buttonToolBar);
		});

		var currentAvailableGroups = '';

		psPlugin.application.events.onQueueStatusUpdated.subscribe('visitorPanel', function (status) {

			var langs = psPlugin.application.configuration.desktop.languages,
				availableGroups = [],
				grp, g, lang, l,
				gStatus;

			currentGroup = visitor.properties.toGroup.toUpperCase()
			lang = langs[visitor.properties.langId.toUpperCase()];

			for (g in lang.groups) {
				grp = lang.groups[g];
				if (typeof grp === 'function' || grp.type === 'phoneToPs') continue;
				gStatus = status.languages[lang.id].groups[g];

				availableGroups.push({
					id: grp.id,
					name: grp.name,
					description: grp.description,
					//users:grp.users, //not needed yet
					numberOfUsers: gStatus.availableUsers, //status[g].users.length,
					numberOfVisitors: gStatus.numberOfVisitors
				});
			}

			var nextAvailableGroups = JSON.stringify(availableGroups);

			// Only render if changed group status
			if (currentAvailableGroups !== nextAvailableGroups) {
				ServerActions.groupStatusUpdate(availableGroups);
				currentAvailableGroups = nextAvailableGroups;
			}

			if (availableGroups.length) {
				renderTransferMenu(availableGroups);
			}

		});

	} else {

		i18nLoader(lang, function (i18n) {
			const locale = (Array.isArray(i18n.locales) ? i18n.locales[0] : i18n.locales);
			ReactDOM.render(<IntlProvider locale={locale} messages={i18n.messages}><ButtonToolBar inDialog={inDialog} conversationId={visitor.properties.sessionId} currentGroupId={currentGroup} text={psPlugin.application.writeOutLanguage} groupList={psPlugin.application.configuration.groups} /></IntlProvider>, layout.buttonToolBar);
			visitor.reactComponents = visitor.reactComponents || [];
			visitor.reactComponents.push(layout.buttonToolBar);
		});

	}//end of release/transfer logic


	/*----hangUpButton.onclick = function () {
		// write message to log
		inDialog = false;
		hangUpButton.style.visibility = 'hidden';
		releaseButton.style.visibility = 'hidden';
		visitor.commands.EndDialog.execute();
	};*/

	/*
	 * --------------------------------------------------
	 * The panel
	 * --------------------------------------------------
	 */
	var panel = new psPlugin.shell.ui.panel({
		id: visitor.properties.sessionId,
		parentElem: positionWrapperElem,
		header: '',
		content: layout.wrapper,
		onClose: function () {
			//visitor.close();
			for(var rc = 0; rc < visitor.reactComponents.length; rc++) {
				ReactDOM.unmountComponentAtNode(visitor.reactComponents[rc]);
			}
			visitor.reactComponents = [];
			panel.elem.children[0].removeEventListener('click', toggleMaximized);
			visitor.commands.Close.execute();
		},
		closeButton: false,
		children: [],
		panelSelectedClass: params.color || '#FC0',
		headerClassName: 'psPanel_header psPanel_headerGray'
	});
	this.panel = panel;
	panel.deSelect();
	makeTitleElements();
	var panelHeader = panel.elem.children[0];


	var toggleMaximized = function (e) {

		// FIXME: this is a rather hacky way to prevent toggling if copying text to clipboard.
		if (e.target && e.target.classList.contains('copy-title')) {
			return;
		}

		if (maximized) {
			minimizedView();
		} else {
			maximizedView();
		}
	};


	var minMaxButton = document.createElement('span');
	minMaxButton.className = 'icon icon-minmax';
	panelHeader.insertBefore(minMaxButton, panelHeader.firstChild);
	//minMaxButton.onclick = toggleMaximized;
	panelHeader.addEventListener('click', toggleMaximized);

	//var minMaxButton = panelHeader;
	// make better close / release button for panel
	/*
	var releaseButton = layout.releaseButton;
	releaseButton.onclick = function () {
		panel.close();
	};
	*/
	/*
	 * --------------------------------------------------
	 * Set state
	 * --------------------------------------------------
	 */
	var pendingApprovalTimeout = false,
		loadingIndicatorTimer = false;

	var setState = function (state) {
		switch (state) {
			case 'PendingApproval':
				inDialog = false;
				//---endButton.style.display = 'none';
				//---startButton.style.display = 'none';
				//inviteButton.style.display = 'none';
				//kickInviteeButton.style.display = 'none';
				setTitle('PendingApproval');
				panel.deSelect();

				if (!loadingIndicatorTimer) {
					loadingIndicatorTimer = setTimeout(function () {
						panel.setState('loading');
					}, 500);
				}

				pendingApprovalTimeout = setTimeout(function () {
					if (visitor.stateMachine.currentState === 'PendingApproval' ||
						visitor.stateMachine.currentState === 'Ready' ||
						visitor.stateMachine.currentState === 'Locked'
					) {
						onTimedOut();
					}
				}, 100000);
				break;

			case 'Queued':
			case 'Locked':
				inDialog = false;
				//---endButton.style.display = 'none';

				if (!psPlugin.application.configuration.account.muteDesktopAudio) {
					setTimeout(function(){
						if (visitor.stateMachine.currentState === 'Queued') {
							SoundFx.play('queue');
						}
					}, 500);
				}

				setTitle('Locked');
				//---startButton.style.display = 'inline';
				//---hangUpButton.style.display = 'none';
				//inviteButton.style.display = 'none';
				//kickInviteeButton.style.display = 'none';
				break;

			case 'InDialog':
				inDialog = true;
				//---endButton.style.display = '';
				//---hangUpButton.style.display = '';
				//if (!User.canInviteGuest) {
				//	inviteButton.style.display = 'none';
				//	kickInviteeButton.style.display = 'none';
				//} else {
				//	inviteButton.style.display = '';
				//	kickInviteeButton.style.display = '';
				//}
				//---startButton.style.display = 'none';
				panel.setSelected();
				panel.setState('active');
				setTitle('Active');
				if (pendingApprovalTimeout) {
					clearTimeout(pendingApprovalTimeout);
					pendingApprovalTimeout = false;
				}
				if (loadingIndicatorTimer) {
					clearTimeout(loadingIndicatorTimer);
					loadingIndicatorTimer = false;
				}
				break;

			case 'Completed':

				inDialog = false;
				setTitle('Ended');
				panel.deSelect();
				//---endButton.style.display = '';
				//hangUpButton.style.display = 'none';
				//---startButton.style.display = 'none';
				//inviteButton.style.display = 'none';
				//kickInviteeButton.style.display = 'none';
				break;

			case 'ConnectionDead':
				inDialog = false;
				setTitle('ConnectionDead');
				panel.deSelect();
				//---endButton.style.display = '';
				//---hangUpButton.style.display = 'none';
				//---startButton.style.display = 'none';
				//inviteButton.style.display = 'none';
				//kickInviteeButton.style.display = 'none';
				break;
		}
	};
	this.setState = setState;

	/*
	 * --------------------------------------------------
	 * Minimized / Maximized / on approve and not approved
	 * --------------------------------------------------
	 */
	var maximized = false;
	var minimizedView = function () {

		visitor.events.onCoWorkerMinimize.trigger();
		positionWrapperElem.style.width = '80%';
		positionWrapperElem.style.opacity = 0.3;
		setTimeout(function () {

			positionWrapperElem.style.display = 'block';
			//minMaxButton.innerHTML = 'Maximize';

			coBrowsingArea.style.display = 'none';
			dropZoneWrapper.style.display = 'none';
			plugininArea.style.display = 'none';
			actionPanelArea.style.display = 'none';
			positionWrapperElem.className = 'visitorPanelWrapper visitorPanelMinimized';
			positionWrapperElem.style.left = '';//'auto';
			positionWrapperElem.style.width = '';
			setTimeout(function () {
				positionWrapperElem.style.opacity = 1;
			}, 400);
		}, 200);
		maximized = false;
	};
	var minimize = function () {
		if (!maximized) return false;
		minimizedView();
	};
	this.minimize = minimize;
	// mimimize on first run

	minimizedView();


	var maximizedView = function () {
		if (maximized) return false;

		coWorker.minimizeAll();
		coWorker.activeVisitor = visitor.properties.sessionId;
		maximized = true;

		setTimeout(function(){
			visitor.events.onCoWorkerMaximize.trigger();
		}, 600);

		setTimeout(function () {
			if (inDialog) {
				plugininArea.style.display = '';
				actionPanelArea.style.display = '';
			}
			coBrowsingArea.style.display = '';
			dropZoneWrapper.style.display = '';
			positionWrapperElem.className = 'visitorPanelWrapper visitorPanelMaximized';

		}, 200);
	};

	var approved = false;
	var onApproved = function () {
		setState(visitor.stateMachine.currentState);
		//inDialog = true;
		visitor.events.onCoworkerApproved.trigger();
		//panel.setSelected();
		//panel.setState('active');
		//setTitle('Active');
		//endButton.style.display = '';
		dropZoneWrapper.style.display = 'none';
		actionPanelArea.style.display = 'none';
		//approved = true;
	};

	var closed = false;
	var onNotApproved = function () {
		inDialog = false;
		visitor.events.onCoworkerNotApproved.trigger();
		panel.setState('inactive');
		//setTitle('Ended');
		panel.deSelect();
		actionPanelArea.style.display = 'none';
	};

	var onTimedOut = function () {

		inDialog = false;
		visitor.events.onCoworkerNotApproved.trigger();
		panel.deSelect();
		//---endButton.style.display = '';
		actionPanelArea.style.display = 'none';

		panel.setState('inactive', helpers.messages.timedOut(function () {
			if (visitor.coWorker.psCase.activeCase) {
				visitor.coWorker.psCase.activeCase.decline();

				visitor.commands.closeCase.execute(visitor.coWorker.psCase.activeCase.props);
				visitor.coWorker.panel.close();
				visitor.close();
			}

			return;
		}));

		setTitle('TimedOut');

		if (loadingIndicatorTimer) {
			clearTimeout(loadingIndicatorTimer);
			loadingIndicatorTimer = false;
		}

		if (pendingApprovalTimeout) {
			clearTimeout(pendingApprovalTimeout);
			pendingApprovalTimeout = false;
		}

		visitor.events.onconnectionState.unsubscribe('coWorker');

	};
	this.onTimedOut = onTimedOut;


	var onRejected = function () {
		var sessionId = visitor.properties.sessionId;
		psPlugin.application.listener.close(sessionId, (function () {
			try {
				if (psPlugin.application.visitorHandler.visitors[sessionId]) {
					psPlugin.application.visitorHandler.visitors[sessionId].events.onClose.trigger();
				}
			} catch (err) {
				console.error('could not trigger onClose for ', sessionId);
			}
		}));
		return false;

		////console.log('onRejected:Declined');
		//onNotApproved();
		//setTitle('Declined');
		//
		//panel.setState('inactive', helpers.messages.declined(function () {
		//	//console.log('closeCase view');
		//	//visitor.commands.EndDialog.execute();
		//	visitor.coWorker.psCase.activeCase.decline();
		//
		//	visitor.commands.closeCase.execute(visitor.coWorker.psCase.activeCase.props);
		//	visitor.coWorker.panel.close();
		//	visitor.close();
		//	return;
		//}));
		//
		//if (loadingIndicatorTimer) {
		//	clearTimeout(loadingIndicatorTimer);
		//	loadingIndicatorTimer = false;
		//}
		//
		//if (pendingApprovalTimeout) {
		//	clearTimeout(pendingApprovalTimeout);
		//	pendingApprovalTimeout = false;
		//}
		//
		//visitor.events.onconnectionState.unsubscribe('coWorker');
	};
	this.onRejected = onRejected;


	this.confirmStatus = function () {
		//console.log('confirmStatus', visitor.stateMachine.currentState, approved, inDialog, closed);
		if (visitor.stateMachine.currentState === 'InDialog' && !inDialog && !closed) {
			//onApproved();
			visitor.events.onApprove.trigger();
			inDialog = true;
		}
	};

	// console.log('Initial state:', visitor.properties.initialState);
	// console.log('Current state:', visitor.stateMachine.currentState);

	//setState(visitor.properties.initialState);
	setState(visitor.stateMachine.currentState);

	/*
	 * --------------------------------------------------
	 * Event listeners
	 * --------------------------------------------------
	 */
	// save for unsubscription array;
	var eventSubscriptions = [];

	// on Approve event
	visitor.events.onApprove.subscribe('coWorker', (function () {
		//alert('onApprove');
		//console.log('visitor.events.onApprove');
		onApproved();
	}));
	eventSubscriptions.push('onApprove');

	//connDead
	visitor.events.oninitialState.subscribe('coWorker', function (newState) {
		//console.log(':::::::::::::::',newState, visitor.properties.approvalStatus);

		if (newState === 'Completed' && visitor.properties.approvalStatus === 3) {
			// 3: Completed (hung up by visitor)visitor.events.onEndDialog.subscribe(
			//console.log('calling onRejected, but should call onEndDialog?');
			setTitle('Ended');

			//ignore connectionState changes since the dialog has ended
			visitor.events.onconnectionState.unsubscribe('coWorker');

			panel.setState('Completed', helpers.messages.ended(function () {
					//console.log('closeCase view');

					visitor.coWorker.psCase.activeCase.decline();

					visitor.commands.closeCase.execute(visitor.coWorker.psCase.activeCase.props);
					visitor.coWorker.panel.close();
					visitor.close();
					return;
				})
			);


		}
		//if (visitor.properties.initialState === 'PendingApproval' && newState === 'Completed') {
		//	setState(newState);
		//	panel.setState('active');
		//}
	});

	if (visitor.properties.approvalStatus === 2) { // 2: InDialog
		visitor.events.onApprove.trigger();
	} else {
		onNotApproved();
	}

	// on Reject event
	visitor.events.onReject.subscribe('coWorker', (function () {
		if (visitor.properties.connectionState !== 2 && visitor.properties.connectionState !== 0) {
			//console.log('should be TimedOut');
			onTimedOut();
		} else {
			onRejected();
		}

	}));
	eventSubscriptions.push('onReject');

	// on End dialog event
	visitor.events.onEndDialog.subscribe('coWorker', (function () {
		closed = true;


		//Close conversation for real
		ViewActions.conversationClose(visitor.properties.sessionId);

		panel.setState('inactive', helpers.messages.ended(function () {
			panel.setState('active');
			setTitle('Ended');
		}, function () {
			panel.setState('active');
			visitor.coWorker.psCase.closeCase(inDialog);
			setTitle('Ended');
		}));

	}));
	eventSubscriptions.push('onEndDialog');

	// on visitor connection event

	if (visitor.properties.initialState !== 'Completed') {
		visitor.events.onconnectionState.subscribe('coWorker', (function (state) {

			switch (parseInt(state, 10)) {
				case 0: //Terminated or Dead. We prefer to call them walkers...
					setTitle('ConnectionDead');
					if (visitor.stateMachine.currentState !== 'PendingApproval') {
						panel.setState('inactive', helpers.messages.connectionDead(function () {
							//visitor.commands.EndDialog.execute();
							panel.setState('active');
							setState('ConnectionDead');

						}, function () {
							panel.setState('active');
							visitor.coWorker.psCase.closeCase(inDialog);
							visitor.commands.EndDialog.execute();
							setState('ConnectionDead');
						}));
					} else {
						//onRejected();
						onTimedOut();
					}
					break;
				case 1:

					if (visitor.stateMachine.currentState !== 'PendingApproval') {
						setTitle('ConnectionLost');
						panel.setState('inactive', helpers.messages.connectionLost(function () {
							panel.setState('active');
							setState(visitor.stateMachine.currentState);
							setTitle('ConnectionLost');
						}));
					}
					break;
				case 2:
					setTitle('Active');
					panel.setState('active');
					setState(visitor.stateMachine.currentState);
					break;
			}
		}));

		eventSubscriptions.push('onconnectionState');
	}
	/*
	 * --------------------------------------------------
	 * Action panels
	 * --------------------------------------------------
	 */
	var actionPanels = {};
	this.actionPanels = actionPanels;
	var addActionPanel = function (panelData) {
		try {
			//console.log('panelData', panelData);
			if (typeof actionPanels[panelData.id] === 'undefined') {
				actionPanels[panelData.id] = new psPlugin.application.actionPanel(panelData);
				actionPanels[panelData.id].onClose.push(function () {
					delete actionPanels[panelData.id];
				});
				actionPanelArea.appendChild(actionPanels[panelData.id].elem);
			}
		} catch (err) {
			console.log(err);
		}
	};
	this.addActionPanel = addActionPanel;
	/*
	 * --------------------------------------------------
	 * Drop zone
	 * --------------------------------------------------
	 */
	psPlugin.core.dragDrop.createDropZone(dropZoneArea, (function (obj) {
		if (inDialog) {
			//console.log(obj);
			try {
				//visitor.actionEvents.send('dragDropActions', obj);
				visitor.events.onDroppedAction.trigger(obj);
				var linkType;
				switch (obj.command) {
					case 'showImage' :
						linkType = 'photo';
						break;
					case 'navigate' :
						linkType = 'link';
						break;
					case 'showPDF' :
						linkType = 'pdf';
						break;
					case 'showVideoPlayer' :
						linkType = 'video';
						break;
				}
				var linkData = {
					url: obj.params.data,
					linkType: linkType,
					title: obj.params.icon.name
				};
				//console.log('LINKDATA',linkData);
				ViewActions.createLinkMessage(linkData, visitor.properties.sessionId);
				//WebAPIUtils.createMessage({
				//	messageType: 'link',
				//	data: {
				//		url: obj.params.data,
				//		type: obj.command,
				//		title: obj.params.icon.name
				//	}
				//}, visitor.properties.sessionId);
				//console.log('drop obje', obj);
				//var createdMessage = {
				//	messageType: 'link',
				//	type: 'conversationMessage',
				//	message: obj.params.icon.name,
				//	data: {
				//		url: obj.params.data,
				//		linkType: obj.command
				//	}
				//};
				//
				//$http.post(vngageConfig.restUrl + 'Conversation/' + visitor.properties.sessionId  + '/Message',createdMessage)
				//	.then( function(response) {
				//		console.log('sent message', response);
				//	}, function(err) {
				//		console.log('error posting message', err);
				//	});
				//
				var iconName = obj.params.icon.name;
				//
				//var wideIcon = psPlugin.shell.jsExt.createIcon('wide', iconName, obj.params.icon.className, obj.params.icon.bgColor, true);
				//wideIcon.data = obj;
				////messageOut.title = url;
				//wideIcon.onclick = function () {
				//	//console.log(this.data);
				//	//alert('click');
				//	//onclick(this.data);
				//};
				//
				var largeIcon = psPlugin.shell.jsExt.createIcon(
					'big',
					iconName,
					obj.params.icon.className,
					obj.params.icon.bgColor,
					true
				);
				largeIcon.data = obj;
				////messageOut.title = url;
				//largeIcon.onclick = function () {
				//	//onclick(this.data);
				//};
				//
				visitor.coWorker.dropZoneArea.appendChild(largeIcon);
				//chatHistory.appendChild(m.wideIcon);
				//viewArea.render('bottom');
			} catch (err) {
				console.log(err);
			}

		}
	}));

	/*
	 * --------------------------------------------------
	 * Close
	 * --------------------------------------------------
	 */
	var close = function () {
		// remove event listeners
		try {
			for (var i in eventSubscriptions) {
				visitor.events[eventSubscriptions[i]].unsubscribe('coWorker');
			}
			if (visitor.coWorker.coBrowserModule.channel) visitor.coWorker.coBrowserModule.channel.destroy();

		} catch (err) {
			console.log(err);
		}

		try {
			coWorker.removeFromPlacements(visitor.properties.sessionId);
			delete coWorker.visitors[visitor.properties.sessionId];
		} catch (err) {
			console.log(err);
		}
		panel.close();

	};
	visitor.onClose.push(close);
	this.close = close;
	this.inDialog = inDialog;
};
var helpers = {
	messages: {
		connectionDead: function (button1, button2) {
			return psPlugin.core.ui.message({
				heading: psPlugin.application.writeOutLanguage('coWorkerMessagesConnectionDeadHeading'),
				content: '',
				icon: 'error',
				buttons: [{
					text: psPlugin.application.writeOutLanguage('coWorkerMessagesConnectionDeadButtonContinue'),
					onclick: function () {
						button1();
					}
				}, {
					text: psPlugin.application.writeOutLanguage('coWorkerMessagesConnectionDeadButtonClose'),
					onclick: function () {
						button2();
					}
				}]
			});
		},
		connectionLost: function (button1) {
			return psPlugin.core.ui.message({
				heading: psPlugin.application.writeOutLanguage('coWorkerMessagesConnectionLostHeading'),
				content: '',
				icon: 'warning',
				buttons: [{
					text: psPlugin.application.writeOutLanguage('coWorkerMessagesConnectionLostButtonContinue'),
					onclick: function () {
						button1();
					}
				}]
			});
		},
		timedOut: function (button1) {
			return psPlugin.core.ui.message({
				heading: psPlugin.application.writeOutLanguage('coWorkerMessagesTimedOutHeading'),
				content: '',
				icon: 'warning',
				buttons: [{
					text: psPlugin.application.writeOutLanguage('coWorkerMessagesDeclinedButtonClose'),
					onclick: function () {
						button1();
					}
				}]
			});
		},
		declined: function (button1) {
			return psPlugin.core.ui.message({
				heading: psPlugin.application.writeOutLanguage('coWorkerMessagesDeclinedHeading'),
				content: '',
				icon: 'warning',
				buttons: [{
					text: psPlugin.application.writeOutLanguage('coWorkerMessagesDeclinedButtonClose'),
					onclick: function () {
						button1();
					}
				}]
			});
		},
		ended: function (button1, button2) {
			return psPlugin.core.ui.message({
				heading: psPlugin.application.writeOutLanguage('coWorkerMessagesEndedHeading'),
				content: '',
				icon: 'warning',
				buttons: [{
					text: psPlugin.application.writeOutLanguage('coWorkerMessagesEndedButtonContinue'),
					onclick: function () {
						button1();
					}
				}, {
					text: psPlugin.application.writeOutLanguage('coWorkerMessagesEndedButtonClose'),
					onclick: function () {
						button2();
					}
				}]
			});
		}
	},
	layout: function () {
		return uiView.createNodes({
			reference: 'wrapper',
			tag: 'div',
			className: 'coWorkerContentElem coworker-container',
			children: [
				{
					reference: 'refCodeArea',
					tag: 'div',
					className: 'refCodeArea'
				},
				{
					reference: 'leftArea',
					tag: 'div',
					className: 'coWorkerLeftArea coworker-col col-flex',
					children: [{
						reference: 'coBrowsingArea',
						tag: 'div',
						className: 'coWorkerCoBrowsingArea'
					}, {
						reference: 'plugininArea',
						tag: 'div',
						className: 'coWorkerPlugininArea'
					}, {
						reference: 'dropZoneWrapper',
						tag: 'div',
						className: 'coWorkerDropZoneWrapper',
						children: [{
							reference: 'dropZoneArea',
							tag: 'div',
							className: 'coWorkerDropZoneArea',
							title: psPlugin.application.writeOutLanguage('coWorkerDropZoneToolTip')
						}]
					}, {
						reference: 'actionPanelArea',
						tag: 'div',
						className: 'coWorkerActionPanelArea',
						title: psPlugin.application.writeOutLanguage('coWorkerActionPanelAreaToolTip'),
						children: []
					}]
				}, {
					reference: 'rightArea',
					tag: 'div',
					className: 'coWorkerRightArea coworker-col',
					children: [{
						reference: 'visitorDetailsArea',
						tag: 'div',
						className: 'coWorkerVisitorDetailsArea',
						children: [{
							reference: 'buttonsArea',
							tag: 'div',
							className: 'coWorkerButtonArea',
							children: [
								/*{
									reference: 'startButton',
									tag: 'button',
									className: 'coworker-btn-start',
									innerHTML: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonStart'),
									title: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonStartTitle')
								},*/ /*{
									reference: 'blockButton',
									tag: 'button',
									innerHTML: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonBlock'),
									title: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonBlockTitle')
								},*/
								/*{
									reference: 'transferButton',
									tag: 'span'
								},*/
								/*{
									reference: 'transferToGroupWrap',
									tag: 'div',
									className: 'psPlugin-dd',
									children: [{
										reference: 'releaseButton',
										tag: 'button',
										innerHTML: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonRelease'),
										title: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonReleaseTitle')
									}]
								}, {
									reference: 'hangUpButton',
									tag: 'button',
									innerHTML: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonHangUp'),
									title: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonHangUpTitle')
								},{
									reference: 'hangUpElem',
									tag: 'span'
									//innerHTML: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonHangUp'),
									//title: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonHangUpTitle')
								}, {
									reference: 'endButton',
									tag: 'button',
									className: 'coworker-btn-end',
									innerHTML: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonEnd'),
									title: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonEndTitle')
								//}, {
								//	reference: 'inviteButton',
								//	tag: 'button',
								//	className: 'coworker-btn-invite',
								//	innerHTML: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonInvite'),
								//	title: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonInviteTitle')
								//}, {
								//	reference: 'kickInviteeButton',
								//	tag: 'button',
								//	className: 'coworker-btn-kickInvitee',
								//	innerHTML: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonKickInvitee'),
								//	title: psPlugin.application.writeOutLanguage('coWorkerVisitorDetailsButtonKickInviteeTitle')
								},*/ {
									reference: 'buttonToolBar',
									tag: 'div'
								}]
						}]
					}]
				}
			]
		});
	}
};


coWorker.panel = panel;


export default coWorker;
