import AppDispatcher from '../dispatcher/CSDispatcher';
import AppConstants from '../../../components/constants/AppConstants';

const ActionTypes = AppConstants.Profile;

export default {

	profileIdentified (profile) {
		AppDispatcher.handleServerAction({
			type: ActionTypes.REQUEST_PROFILE_SUCCESS,
			profile: profile
		});
	}
};

