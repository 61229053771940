import React from 'react';
import createReactClass from 'create-react-class';
import VisitorProfileStore from './../stores/VisitorProfileStore';

export default createReactClass({

	render () {
		if (!this.props.claims) {
			return false;
		}

		return (
			<span>
				{this.props.claims.map(function (claim, i) {
					if (claim.values) {
						let str = claim.type + ': ' + claim.values.join(', ');
						return str;
					}
			}).join(', ')}
			</span>
		)
	}
});
