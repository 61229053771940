import defaultTranslations from './BannerEditTranslationDefaults';
import _values from 'lodash/values';
import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';

export default function ($scope, $uibModalInstance, vngageGuid, translationId, translations) {
	'ngInject';

	var vm = this;
	vm.addNew = (!translationId);

	var systemTranslations = _values(_cloneDeep(defaultTranslations)).sort((a, b) => {
		return (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
	});
	vm.translations = _values(translations).sort((a, b) => {
		return (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
	});
	vm.translations = systemTranslations.concat(vm.translations);

	vm.save = save;
	vm.cancel = $uibModalInstance.dismiss;
	vm.remove = remove;
	vm.setDefaultTranslation = setDefaultTranslation;

	vm.translation = null;
	if (translationId) {
		// console.log('edit translation: ',translationId);
		vm.translation = _cloneDeep(translations[translationId]);
		vm.translation.id = translationId;
	} else {
		// console.log('add translation');
		vm.translation = {
			id: vngageGuid.generate(),
			displayName: '',
			textDirection: 'LTR',
			defaultTranslation: false,
			translations: {},
			custom: {},
			errorTranslations: {},
			locale: ''
		};
	}
	vm.translation.defaultTranslation = !(_find(vm.translations, translation => {
		return (translation.defaultTranslation && vm.translation.id !== translation.id);
	}));
	vm.baseTranslation = null;

	$scope.$watch('vm.translation.displayName', (displayName) => {
		if (displayName && _find(translations, (translation, key) => {
			return (translation.displayName && translation.displayName.toUpperCase() === displayName.toUpperCase() && key !== vm.translation.id);
		})) {
			$scope.translationForm.displayName.$setValidity('duplicate', false);
		} else {
			$scope.translationForm.displayName.$setValidity('duplicate', true);
		}
	});

	function save() {
		if (vm.baseTranslation) {
			vm.translation.translations = _cloneDeep(vm.baseTranslation.translations);
			vm.translation.custom = _cloneDeep(vm.baseTranslation.custom);
			vm.translation.errorTranslations = _cloneDeep(vm.baseTranslation.errorTranslations);
		}
		$uibModalInstance.close(vm.translation);
	}

	function remove() {
		$uibModalInstance.close();
	}

	function setDefaultTranslation() {
		vm.translation.defaultTranslation = true;
		$scope.translationForm.$setDirty();
	}
}
