export default function ($state, $stateParams, roleClaim, systemConfiguration, $scope) {
	'ngInject';

	var vm = this;
	vm.roleClaim = roleClaim;
	vm.currentList = $stateParams.currentList || [];

	if ($state.current.name === 'root.system.role.edit') {
		$state.transitionTo('root.system.role.edit.role', {
			id: roleClaim.id,
			roleClaim: roleClaim,
			currentList: vm.currentList
		});
	}

	$scope.$on('resetResettables', () => {
		vm.wrapperForm.$setPristine();
	});
};
