import _defaults from 'lodash/defaults';
import _keys from 'lodash/keys';
import _each from 'lodash/each';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import _isArray from 'lodash/isArray';
import _values from 'lodash/values';
import _find from 'lodash/find';

export default function() {
	'ngInject';
	var defaults, service;

	// Defaults and allowed keys
	defaults = {
		all: {
			texts: {},
			template: '',

			html: '',
			introDelay: '',
			introDuration: '',
			introOnce: false,
			introQuarantine: '',
			introPerTemplate: false,
			extraClasses: '',
			description: '',
			displayWhenOpen: false,
			displayWhenBusy: false,
			displayWhenClosed: false,
			visitorUIMode: 'normal'
		},
		tabbanner: {
			htmlTab: '',
			mouseExpand: false,
			mouseCollapse: false
		},
		dooreye: {
			htmlTab: '',
			mouseExpand: false,
			mouseCollapse: false
		},
		injected: {
			tag: '',
			target: '',
			targetMethod: 'append'
		},
		takeover: {},
		queueReservation: {
			translations: {},
			subjectMapping: [],
			settings: {
				firstDayOfWeekMonday: true,
				lengthOfWeek: '5',
				maxWeeksAhead: '1',
				minHoursAhead: '1',
				requirePhoneNumber: false,
				startWithFirstAvailableWeek: false,
				disableReservationScroll: false
			},
			tag: '',
			target: '',
			targetMethod: 'append'
		}
	};

	service = {
		cleanup: templateCleanup,
		defaults: templateDefaults,
		templates: templateKeys,
		getDefaultBannerTranslation: getDefaultBannerTranslation
	};

	function getDefaultBannerTranslation(banner) {
		if (!banner.data.section.translations) {
			return null;
		}
		var defaultTranslation = _find(banner.data.section.translations, translation => {
			return translation.defaultTranslation;
		});
		if (!defaultTranslation) {
			// No translation marked as defaultTranslation, fall back to first in display name alphabetical order
			var translations = _values(banner.data.section.translations).sort((a, b) => {
				return (a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1);
			});
			if (translations && translations.length>0) {
				defaultTranslation = translations[0];
			}
		}
		return defaultTranslation;
	}

	function templateKeys() {
		return {
			dooreye: 'banner.TEMPLATE_DOOREYE',
			injected: 'banner.TEMPLATE_INJECTED',
			tabbanner: 'banner.TEMPLATE_TABBANNER',
			takeover: 'banner.TEMPLATE_TAKEOVER',
			queueReservation: 'banner.TEMPLATE_QUEUERESERVATION'
		};
	}

	function templateDefaults(banner) {
		_defaults(banner.data.section, defaults.all, defaults[banner.data.section.template]);
	}

	function templateCleanup(banner) {
		var allowed = _keys(defaults[banner.data.section.template]);

		allowed = allowed.concat(_keys(defaults.all));

		_each(banner.data.section, function(val, key) {
			if (_isObject(val)) {
				if (key !== 'translations') {
					// filterEmptyStrings() will remove *all* empty strings in the whole banner config (recursive/deep).
					// But we don't want that for translations (empty translation-values should be allowed)
					banner.data.section[key] = filterEmptyStrings(val);
				}
			} else if (val === '' || !_includes(allowed, key)) {
				delete banner.data.section[key];
			}
		});
	}

	function filterEmptyStrings(object) {
		var result = (_isArray(object) ? [] : {});
		_each(object, function(value, key) {
			if (_isObject(value)) {
				result[key] = filterEmptyStrings(value);
			//} else if (value !== null && !_isEmpty(value)) { // Hmmm - this makes the whole function ONLY preserve non-empty strings! (objects that matches  !_isObject() && !_isEmpty() and that can be ONLY non-empty strings!) Probably not what was intended...
			} else if (value !== null && value !== '') { // Fix: Now this function removes empty string like before, but preserves any props of other types (integers, booleans, etc)
				result[key] = value;
			}
		});
		return result;
	}


	return service;
}
