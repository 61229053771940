import vngageConfig from '../../app/vngageConfig';

export default {
	defaultConfig: {
		enabled: false,
		documentTitle: '',
		urlLogoExpanded: '',
		urlLogoCollapsed: '',
		urlFavicon: '',
		brandPrimary: '',
		mainMenuBackgroundColor: '',
		mainMenuTextColor: '',
		subMenuBackgroundColor: '',
		subMenuTextColor: '',
		sectionHeaderTextColor: '',
		customCss: ''
	},
	vergicConfig: {
		enabled: false,
		documentTitle: 'Vergic AB',
		urlLogoExpanded: vngageConfig.assetPath + 'vngage/assets/images/vergic-logo.svg',
		urlLogoCollapsed: vngageConfig.assetPath + 'vngage/assets/images/vergic-icon.svg',
		//urlFavicon: vngageConfig.assetPath + 'vngage/assets/images/favicon.ico',
		urlFavicon: 'data:image/x-icon;base64,AAABAAEAEBAAAAEAIABoBAAAFgAAACgAAAAQAAAAIAAAAAEAIAAAAAAAAAQAABILAAASCwAAAAAAAAAAAAD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8A////AP///wD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACTK61wkyut/5Mrrf+TK63/kyut/5Mrrf+TK63/kyut/wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAkyutVJMrrcCTK63AkyutwJMrrcCTK63AkyutwJMrrcBdTvKAXU7ygF1O8oBdTvKAXU7ygF1O8oBdTvKAXU7yOJMrrTiTK62AkyutgJMrrYCTK62AkyutgJMrrYCTK62AXU7y/11O8v9dTvL/XU7y/11O8v9dTvL/XU7y/11O8nCTK61wkyut/5Mrrf+TK63/kyut/5Mrrf+TK63/kyut/11O8kBdTvJAXU7yQF1O8kBdTvJAXU7yQF1O8kBdTvIckyutHJMrrUCTK61AkyutQJMrrUCTK61AkyutQJMrrUBdTvLgXU7y4F1O8uBdTvLgXU7y4F1O8uBdTvLgXU7yYgAAAAAAAAAAkyutPpMrrZaTK62qkyute5MrrRMAAAAAXU7y4F1O8uBdTvLgXU7y4F1O8uBdTvLgXU7y4F1O8mIAAAAAkyuteJMrrf+TK63/kyut/5Mrrf+TK63okyutJgAAAAAAAAAAAAAAAF1O8g5dTvIFAAAAAAAAAAAAAAAAkyutJZMrrf2TK63wkyutUZMrrSWTK62Ykyut/5MrrbgAAAAAXU7yYV1O8uFdTvL/XU7y+F1O8qRdTvISAAAAAJMrrWiTK63/kyutggAAAAAAAAAAkyutApMrreqTK638XU7yYV1O8v9dTvL6XU7yv11O8uNdTvL/XU7yyl1O8gOTK61dkyut/5MrrZkAAAAAAAAAAJMrrRCTK630kyut811O8t9dTvL9XU7yOQAAAABdTvIIXU7yxl1O8v9dTvJKkyutE5MrrfGTK639kyutk5MrrWeTK63Mkyut/5MrrZldTvL/XU7y5AAAAAAAAAAAAAAAAF1O8nldTvL/XU7ybQAAAACTK61Ckyut7ZMrrf+TK63/kyut/5MrrbqTK60MXU7y211O8v5dTvJEAAAAAF1O8g1dTvLMXU7y/11O8kYAAAAAAAAAAJMrrQ+TK61UkyutaZMrrToAAAAAAAAAAF1O8lhdTvL/XU7y/V1O8s1dTvLsXU7y/11O8sFdTvICAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAXU7yU11O8tJdTvL/XU7y711O8pVdTvINAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//8AAP8AAAD/AAAAAAAAAAAAAAAAAAAAAMEAAACAAADnAAAAgRgAAAAYAAAQAAAAOIAAABDDAAAA/wAAgf8AAA==',
		brandPrimary: '#55043B',
		mainMenuBackgroundColor: '#020202',
		mainMenuTextColor: '#FFFFFF',
		subMenuBackgroundColor: '#434544',
		subMenuTextColor: '#FFFFFF',
		sectionHeaderTextColor: '#00B7C9',
		customCss: ''
	}
}
