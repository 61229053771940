import React from 'react';
import GroupStatus from './GroupStatus.react.js';
import {
	Dropdown,
	Modal,
	Button,
	OverlayTrigger,
	Tooltip
} from 'react-bootstrap';
import {useGetTranslation} from './../LangContext';

const MetaBar = (props) => {
	const getTranslation = useGetTranslation();
	const handleMenu = (eventKey) => {
		switch (eventKey) {
			case 'pin':
				props.setQueuePanelPosition();
				break;
			case 'groupStatus':
				props.toggleGroupStatus();
				break;
			case 'showCode':
				props.showStartCodeView();
				break;
			case 'userPreferences':
				props.toggleUserPreferences();
				break;
		}
	};
	const usersOnline = props.counters.get('numberOfOnlineUsers');
	const visitorsInQueue = props.counters.get('numberOfVisitorsInQueue');
	const visitorsInDialog = props.counters.get('numberOfVisitorsInDialog');

	// ub-track
	const ubDataPin = `queue-menu:${props.queuePanelPinned ? 'pined': 'not-pined'}`;

	const pinLabel = props.queuePanelPinned ?
		<span ub-track={ubDataPin}><i className="vngage-icon-right-open"
									  ub-track={ubDataPin}/> {getTranslation('unpinQueue')}</span>:
		<span ub-track={ubDataPin}><i className="vngage-icon-left-open"
									  ub-track={ubDataPin}/> {getTranslation('pinQueue')}</span>;

	const visitorsInQueueTooltip = <Tooltip
		id="visitors-in-queue">{getTranslation('metaBarVisitorsInQueue')}</Tooltip>;
	const usersOnlineTooltip = <Tooltip
		id="agents-online">{getTranslation('metaBarAgentsOnline')}</Tooltip>;
	const visitorsInDialogTooltip = <Tooltip
		id="in-dialog">{getTranslation('metaBarOngoingDialogs')}</Tooltip>;


	const menu = <Dropdown onSelect={handleMenu} alignRight className="queue-menu">
		<Dropdown.Toggle id="meta" className="meta-dropdown-button" size="sm">
		</Dropdown.Toggle>
		<Dropdown.Menu>
			<Dropdown.Item eventKey="pin" ub-track={ubDataPin}>{pinLabel}</Dropdown.Item>
			<Dropdown.Item eventKey="groupStatus" ub-track="queue-menu:group-status"><i
				className="vngage-icon-group"
				ub-track="queue-menu:group-status"/> {getTranslation('groupStatusLabel')}</Dropdown.Item>
			<Dropdown.Item eventKey="showCode" ub-track="queue-menu:meeting-code"><i
				className="vngage-icon-phone"
				ub-track="queue-menu:meeting-code"/> {getTranslation('generateCodeLabel')}</Dropdown.Item>
			<Dropdown.Item eventKey="userPreferences" ub-track="queue-menu:desktop-settings"><i
				className="vngage-icon-cog-alt"
				ub-track="queue-menu:desktop-settings"/> {getTranslation('desktopSettingsLabel')}</Dropdown.Item>
		</Dropdown.Menu>
	</Dropdown>;


	const status = <div className="status">
		<OverlayTrigger key="1" placement="bottom" overlay={visitorsInQueueTooltip}>
			<div className="queue-meta">
				<span className="queue-count">{visitorsInQueue}</span>
				<i className="vngage-icon-queue-line"/>
			</div>
		</OverlayTrigger>
		<OverlayTrigger key="2" placement="bottom" overlay={usersOnlineTooltip}>
			<div className="agents-meta">
				<span className="agents-count">{usersOnline}</span>
				<i className="vngage-icon-agent-line"/>
			</div>
		</OverlayTrigger>
		<OverlayTrigger key="3" placement="bottom" overlay={visitorsInDialogTooltip}>
			<div className="dialogs-meta">
				<span className="dialogs-count">{visitorsInDialog}</span>
				<i className="vngage-icon-multi-chat-line"/>
			</div>
		</OverlayTrigger>
	</div>;


	const groupStatusModal = props.displayGroupStatus
		? (
			<Modal show={props.displayGroupStatus}
				   onHide={props.toggleGroupStatus}>
				<Modal.Header closeButton>
					<Modal.Title>{getTranslation('groupStatusTitle')}</Modal.Title>
				</Modal.Header>

				<Modal.Body className="group-status">
					<GroupStatus/>
				</Modal.Body>

				<Modal.Footer>
					<Button
						onClick={props.toggleGroupStatus}>{getTranslation('btnClose')}</Button>
				</Modal.Footer>

			</Modal>)
		: null;

	return (
		<div className="queue-toolbar">
			{props.queuePanelPinned ? status: ''}
			{menu}
			<div className="separator"/>
			{groupStatusModal}
		</div>
	);
};
export default MetaBar;
