import React from 'react';
import {Button} from 'react-bootstrap';
import {useGetTranslation} from '../../LangContext';

const BlockedOverlay = ({url, toggleCoBro, conversationId, enabledFeatures = {}}) => {
	const getTranslation = useGetTranslation();
	const {exit} = enabledFeatures;
	const domain = url.split('//').pop().split('/').shift();
	return (
		<div className="restricted-overlay">
			<div className="container">
				<h1>{getTranslation('overlayBlockedHeader1')}</h1>
				<p>{domain}</p>
				<h1>{getTranslation('overlayBlockedHeader2')}</h1>
				{exit && <Button onClick={() => toggleCoBro(conversationId)}
								 className="default-line-btn-contrast">
					{getTranslation('overlayBtnDisconnectedDismiss')}
				</Button>
				}
			</div>
		</div>
	);
};

export default BlockedOverlay;

