import User from '../User';

export default {

	sendAction: {
		canExecute: function (sessionId) {
			var state = psPlugin.application.visitorHandler.visitors[sessionId].stateMachine.currentState;
			return (state === 'InDialog');
		},
		execute: function (func, callFunction, parameters, sessionId) {
			if (!this.canExecute(sessionId)) return false;
			psPlugin.application.listener.sendAction(func, callFunction, parameters, sessionId);
			return true;
		}
	},
	sendChatMessage: {
		canExecute: function () {
			return true;
		},
		execute: function (message, type, messageOption, sessionId) {
			psPlugin.application.listener.sendChatMessage(message, type, messageOption, sessionId);
			return true;
		}
	},
	block: {
		canExecute: function () {
			return true;
		},
		execute: function (sessionId) {
			psPlugin.application.listener.sendAction('runClientFunction', 'block', {}, sessionId);
			setTimeout(function () {
				try {
					psPlugin.application.visitorHandler.visitors[sessionId].commands.EndDialog.execute();
				} catch (err) {
					console.log('error:visitor.block');
				}
			}, 600);
			return true;
			/*
	if (psPlugin.application.visitorHandler.visitors[sessionId].stateMachine.currentState == 'InDialog') {
		setTimeout(function(){
			psPlugin.application.visitorHandler.visitors[sessionId].commands.EndDialog.execute();
		},500);
	}
	return true;
	*/
		}
	},
	getNavigationHistory: {
		canExecute: function () {
			return true;
		},
		execute: function (visitorId, sessionId) {
			psPlugin.application.listener.getNavigationHistory(visitorId, sessionId, function (data) {
				psPlugin.application.visitorHandler.visitors[sessionId].events.onGetNavigationHistory.trigger(data);
			});
			return true;
		}
	},
	/*
	getCaseMessagesForCase:{
		canExecute: function (sessionId) {
			return true;
		},
		execute: function (visitorId, caseId, sessionId) {
			//console.log('getActiveCase request', sessionId);
			psPlugin.application.listener.getCaseMessagesForCase(visitorId, sessionId, function (data) {
				//console.log('getActiveCase response', sessionId, data);
				psPlugin.application.visitorHandler.visitors[sessionId].events.getCaseMessagesForCase.trigger(data);
			});
			return true;
		}
	},*/
	getActiveCase: {
		canExecute: function () {
			return true;
		},
		execute: function (caseId, sessionId) {
			psPlugin.application.listener.getCase(caseId, sessionId, function (data) {
				psPlugin.application.visitorHandler.visitors[sessionId].events.onGetActiveCase.trigger(data);
			});
			return true;
		}
	},
	saveCase: {
		canExecute: function () {
			return true;
		},
		execute: function (data, sessionId) {
			psPlugin.application.listener.saveCase(data, sessionId, function () {
				try {
					psPlugin.application.visitorHandler.visitors[sessionId].events.onSaveCase.trigger();
				} catch (err) {}
			});
			return true;
		}
	},
	closeCase: {
		canExecute: function () {
			return true;
		},
		execute: function (data, sessionId) {
			psPlugin.application.listener.closeCase(data, sessionId, function () {
				try { psPlugin.application.visitorHandler.visitors[sessionId].events.onCloseCase.trigger(); } catch (err) { }
			});
			return true;
		}
	},

	/*
	 * -----------------------------------------------------------
	 * Transitions
	 * -----------------------------------------------------------
	 */
	Lock: {
		canExecute: function (sessionId) {
			if (psPlugin.application.visitorHandler.visitors[sessionId].stateMachine.Lock.test()) return true;
			return false;
		},
		execute: function (sessionId) {
			//this.canExecute has never been active
			//if (!this.canExecute) return false;
			psPlugin.application.listener.Lock(sessionId, (function (data) {
				//console.log('Lock.execute, data:', data)
				try {
					psPlugin.application.visitorHandler.visitors[sessionId].events.onLock.trigger();
				} catch (err) {
					console.log('session_adminlock:', err, 'data:', data);
				}
			}));
		}
	},
	Begin: {
		canExecute: function () {
			// TODO error in statemachine, must fix
			return true;
			//if (psPlugin.application.visitorHandler.visitors[sessionId].stateMachine['Begin'].test()) return true;
			//return false;
		},
		execute: function (sessionId) {
			var visitorProperties = psPlugin.application.visitorHandler.visitors[sessionId].properties;
			var groupId = visitorProperties.toGroup.toUpperCase();
			var language = visitorProperties.langId.toUpperCase();
			var groupConfig = psPlugin.application.configuration.groups[groupId];

			var startMessage = groupConfig.dialogOptions.startMessage
				.replace('[[name]]', User.props.configuration.displayName);
			var signature = groupConfig.dialogOptions.signature
				.replace('[[name]]', User.props.configuration.displayName)
				.replace('[[id]]', User.props.id);


			var dialogParams = {
				toGroup: groupId,
				dialogObject: {
					CSid: User.props.id,
					displayName: User.props.configuration.displayName,
					profilePicture: User.props.configuration.profilePicture,
					startMessage: startMessage,
					signature: signature
				}
			};
			psPlugin.application.visitorHandler.visitors[sessionId].events.onBegin.trigger();
			psPlugin.application.listener.beginDialog(sessionId, dialogParams, (function (data) {
				psPlugin.application.visitorHandler.visitors[sessionId].updateData(data);
			}), startMessage);
		}
	},
	Approve: {
		canExecute: function () {
			// TODO error in statemachine, must fix
			return true;
			//if (psPlugin.application.visitorHandler.visitors[sessionId].stateMachine['Approve'].test()) return true;
			//return false;
		},
		execute: function (sessionId) {
			psPlugin.application.visitorHandler.visitors[sessionId].events.onApprove.trigger();
		}
	},
	Reject: {
		canExecute: function () {
			// TODO error in statemachine, must fix
			return true;
			//if (psPlugin.application.visitorHandler.visitors[sessionId].stateMachine['Begin'].test()) return true;
			//return false;
		},
		execute: function (sessionId) {
			psPlugin.application.visitorHandler.visitors[sessionId].events.onReject.trigger();
		}
	},
	EndDialog: {
		canExecute: function () {
			// TODO error in statemachine, must fix
			return true;
			//if (psPlugin.application.visitorHandler.visitors[sessionId].stateMachine['EndDialog'].test()) return true;
			//return false;
		},
		execute: function (sessionId) {
			try {
				psPlugin.application.listener.end(sessionId, function(){});
			} catch (err) {
				console.error(err, sessionId);
			}
			psPlugin.application.visitorHandler.visitors[sessionId].events.onEndDialog.trigger();
		}
	},
	VisitorEnded: {
		canExecute: function () {
			// TODO error in statemachine, must fix
			return true;
			//if (psPlugin.application.visitorHandler.visitors[sessionId].stateMachine['EndDialog'].test()) return true;
			//return false;
		},
		execute: function (sessionId) {
			psPlugin.application.visitorHandler.visitors[sessionId].events.onEndDialog.trigger();
		}
	},
	Close: {
		canExecute: function () {
			// TODO error in statemachine, must fix
			return true;
			//if (psPlugin.application.visitorHandler.visitors[sessionId].stateMachine['Close'].test()) return true;
			//return false;
		},
		execute: function (sessionId) {
			psPlugin.application.visitorHandler.quarantineClosed[sessionId] = true;

			var visitor = psPlugin.application.visitorHandler.visitors[sessionId],
				statePicks = ['Queued', 'PendingApproval'];


			//visitor has left the building by own means.
			if (!visitor) {
				return;
			}

			if (statePicks.indexOf(visitor.properties.initialState) === -1) {
				psPlugin.application.listener.close(sessionId, (function () {
					try {
						if (psPlugin.application.visitorHandler.visitors[sessionId]) {
							psPlugin.application.visitorHandler.visitors[sessionId].events.onClose.trigger();
						}
					} catch (err) {
						console.error('could not trigger onClose for ', sessionId);
					}
				}));
			} else {
				setTimeout(function () {
					try {
						if (psPlugin.application.visitorHandler.visitors[sessionId]) {
							psPlugin.application.visitorHandler.visitors[sessionId].events.onClose.trigger();
						}
					} catch (err) {
						console.error('cannot close visitor', err);
					}
				}, 300);
			}


		}
	},
	Transfer: {
		canExecute: function () {
			// TODO error in statemachine, must fix
			return true;
		},
		execute: function (toGroup, sessionId) {
			psPlugin.application.listener.transfer(sessionId, toGroup, function(){

				try {
					if (psPlugin.application.visitorHandler.visitors[sessionId]) {
						psPlugin.application.visitorHandler.visitors[sessionId].events.onClose.trigger();
					}
				} catch (err) {
					//console.error('transferred ok, onClose has already been triggered');
				}
			});
		}
	}
};
