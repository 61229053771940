
/*


var browser = new BrowserValidation({
	requiredMinimumVersion:{ // minimum required versions
		Firefox:8,
		Chrome:4
	},
	excludeVersions:{ // exclude specific versions
		Chrome:11
	},
	excludeQuiet:false, //quietly ignore browser
		recommendBrowser:'Firefox version 11 or later'
	});

	if(!browser.approved) return false; // exit application

*/
function hasFlash() {
	try {
		return !!(navigator.mimeTypes['application/x-shockwave-flash'] || window.ActiveXObject && new window.ActiveXObject('ShockwaveFlash.ShockwaveFlash'));
	} catch (err) { }
	return false;
}

/*
* -----------------------------------------------------------
* helpers
* -----------------------------------------------------------
*/
var browserDetect = {
	detect: function () {
		var res = {};
		res.browser = this.searchString(this.dataBrowser) || 'An unknown browser';
		res.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || 'an unknown version';
		res.OS = this.searchString(this.dataOS) || 'an unknown OS';
		//console.log('psPlugin reports', JSON.stringify(res));
		return res;
	},
	searchString: function (data) {
		for (var i = 0; i < data.length; i++) {
			var dataString = data[i].string;
			var dataProp = data[i].prop;
			this.versionSearchString = data[i].versionSearch || data[i].identity;
			if (dataString) {
				if (dataString.indexOf(data[i].subString) != -1) {
					return data[i].identity;
				}
			}
			else if (dataProp) {
				return data[i].identity;
			}
		}
	},
	searchVersion: function (dataString) {
		var index = dataString.indexOf(this.versionSearchString);
		if (index == -1) return;
		return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
	},
	dataBrowser: [
		{
			string: navigator.userAgent,
			subString: 'Chrome',
			identity: 'Chrome'
		},
		{
			string: navigator.userAgent,
			subString: 'OmniWeb',
			versionSearch: 'OmniWeb/',
			identity: 'OmniWeb'
		},
		{
			string: navigator.vendor,
			subString: 'Apple',
			identity: 'Safari',
			versionSearch: 'Version'
		},
		{
			prop: window.opera,
			identity: 'Opera'
		},
		{
			string: navigator.vendor,
			subString: 'iCab',
			identity: 'iCab'
		},
		{
			string: navigator.vendor,
			subString: 'KDE',
			identity: 'Konqueror'
		},
		{
			string: navigator.userAgent,
			subString: 'Firefox',
			identity: 'Firefox'
		},
		{
			string: navigator.vendor,
			subString: 'Camino',
			identity: 'Camino'
		},
		{               // for newer Netscapes (6+)
			string: navigator.userAgent,
			subString: 'Netscape',
			identity: 'Netscape'
		},
		{
			string: navigator.userAgent,
			subString: 'MSIE',
			identity: 'Explorer',
			versionSearch: 'MSIE'
		},
		{
			string: navigator.userAgent,
			subString: 'Trident',
			identity: 'Explorer',
			versionSearch: 'rv'
		},
		{
			string: navigator.userAgent,
			subString: 'Gecko',
			identity: 'Mozilla',
			versionSearch: 'rv'
		},
		{               // for older Netscapes (4-)
			string: navigator.userAgent,
			subString: 'Mozilla',
			identity: 'Netscape',
			versionSearch: 'Mozilla'
		}
	],
	dataOS: [
		{
			string: navigator.platform,
			subString: 'Win',
			identity: 'Windows'
		},
		{
			string: navigator.platform,
			subString: 'Mac',
			identity: 'Mac'
		},
		{
			string: navigator.userAgent,
			subString: 'iPhone',
			identity: 'iPhone/iPod'
		},
		{
			string: navigator.platform,
			subString: 'Linux',
			identity: 'Linux'
		}
	]

};




export default function (params) {
	'use strict';



	// Set approved false as default
	var approved = false;
	var error = '';
	this.approved = approved;

	// retrieve info
	this.info = browserDetect.detect();
	this.hasFlash = hasFlash();
	/*
	// verify browser version
	if (typeof params.requiredMinimumVersion == 'object') {
		if (typeof params.requiredMinimumVersion[this.info.browser] != 'undefined') {
			var requiredVersion = params.requiredMinimumVersion[this.info.browser];
			if (requiredVersion <= this.info.version &&
				(
				typeof params.excludeVersions == 'undefined' ||
				typeof params.excludeVersions[this.info.browser] == 'undefined' ||
				params.excludeVersions[this.info.browser] != this.info.version
				)) {
				this.approved = true;
			} else {
				error += 'Your browser is supported, but you need to update to a newer version! ';
			}
		} else {
			error += 'Your browser cannot display this application! ';
		}
	}

	// if (!this.approved) {
	// 	console.log('browserValidation.js:', error);
	// }

	if (!this.approved && !params.excludeQuiet) {
		if (typeof params.recommendBrowser != 'undefined') error += 'Recommended browser: ' + params.recommendBrowser + ' ';
		window.alert(error);
	} //
	*/
	var DetectCSS3transition = (function () {
		function cssTransitions() {
			var div = document.createElement('div');
			var p, ext, pre = ['ms', 'O', 'Webkit', 'Moz'];
			//for (p in pre) {
			for (p = 0; p < pre.length; p++) {
				if (div.style[pre[p] + 'Transition'] !== undefined) {
					ext = pre[p];
					break;
				}
			}
			//delete div;
			return ext;
		}

		return {
			'cssTransitions': cssTransitions
		};
	}());
	this.info.CSS3transitions = DetectCSS3transition.cssTransitions();
}
