import VisitorSettingsController from './VisitorSettingsController';
import visitorSettingsDefaults from './VisitorSettingsDefaults';
import VisitorSettingsEditTemplate from './VisitorSettingsEditTemplate';

export default angular.module('vngageApp.account.visitorSettings', [
	'ui.router'
])

	.controller('VisitorSettingsController', VisitorSettingsController)
	.value('visitorSettingsDefaults', visitorSettingsDefaults)

	.constant('visitorSettingsModuleConfig', {
		state: 'root.account.visitorSettings',
		access: 'Engage/VisitorSettings'
	})

	.config(function ($stateProvider, visitorSettingsModuleConfig) {
		'ngInject';

		$stateProvider.state(visitorSettingsModuleConfig.state, {
			url: 'visitorsettings/',
			controller: 'VisitorSettingsController as vm',
			templateUrl: VisitorSettingsEditTemplate,
			data: {
				access: visitorSettingsModuleConfig.access,
				ncyBreadcrumbLabel: 'Visitor settings'
			},
			resolve: {
				configurationSections: function (APIConfigurationSectionService) {
					'ngInject';
					// Get all ConfigurationSections at once, use as dependency in the other resolves below and filter out the correct type there
					// This is to avoid calling APIEndpointService.configurationSection.query() multiple times for one single view.
					return APIConfigurationSectionService.getAll();
				},
				visitorConfigurationSections: function (APIConfigurationSectionService, configurationSections) {
					'ngInject';
					return APIConfigurationSectionService.filterConfigurationSections(configurationSections, APIConfigurationSectionService.sectionMap.visitor);
				},
				visitorConfigurationSectionsValidation: function (APIConfigurationSectionService, ConfigValidationService, configurationSections) {
					'ngInject';
					return ConfigValidationService.validateSectionType(configurationSections, APIConfigurationSectionService.sectionMap.visitor);
				},
				visitorSettings: function (APIConfigurationSectionService, visitorSettingsDefaults, visitorConfigurationSections) {
					'ngInject';

					const visitorSettings = visitorConfigurationSections[0]; // Just pick the first item (like visitor app does)

					if (visitorSettings) {
						//// Migrate some added settings to existing account configs:
						// new setting: iframeVisitorDuringChat
						if (typeof visitorSettings.data.section.iframeVisitorDuringChat === 'undefined') {
							// Default all existing configurations' "iframeVisitorDuringChat" to true (while defaulting all newly created accounts' setting to "false")
							visitorSettings.data.section.iframeVisitorDuringChat = true;
						}
						// new setting: handleHttpMethod
						if (visitorSettings.data.section.enableHTTPSCoBrowsing) {
							// If old setting "enableHTTPSCoBrowsing" exists and is true: Force handleHttpMethod = 'switchToHttps'
							visitorSettings.data.section.handleHttpMethod = 'switchToHttps';
						} else if (typeof visitorSettings.data.section.handleHttpMethod === 'undefined') {
							// Default all existing configurations' handleHttpMethod = 'ignore' if not exists
							visitorSettings.data.section.handleHttpMethod = 'ignore';
						}

						// Set defaults for new visitor conversation streaming feature flags
						if (typeof visitorSettings.data.section.streamingConversations === 'undefined') {
							visitorSettings.data.section.streamingConversations = false;
						}
						if (typeof visitorSettings.data.section.pollConversationsWhileStreaming === 'undefined') {
							visitorSettings.data.section.pollConversationsWhileStreaming = false;
						}

						// Set defaults for new visitor setting reEvaluateAfterChat
						// Determines if opportunities should be re-evaluated and banners presented after chat automatically
						if (typeof visitorSettings.data.section.reEvaluateAfterChat === 'undefined') {
							// Default all existing configurations' "reEvaluateAfterChat" to true
							visitorSettings.data.section.reEvaluateAfterChat = true;
						}

						return visitorSettings;
					} else {
						// visitor settings section missing => create it
						return APIConfigurationSectionService.create(visitorSettingsDefaults, APIConfigurationSectionService.sectionMap.visitor);
					}
				},
				sites: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.site.query().$promise;
				},
				themes: function (APIConfigurationSectionService, configurationSections) {
					'ngInject';
					return APIConfigurationSectionService.filterConfigurationSections(configurationSections, APIConfigurationSectionService.sectionMap.theme);
				},
				scripts: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.script.query().$promise;
				}
			}
		});
	});
