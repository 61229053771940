import _merge from 'lodash/merge';
import GroupFeaturesDefault from './GroupFeaturesDefault';

export default function (APIConfigurationSectionService, group, groupId, features, featuresList) {
	'ngInject';
	const vm = this;
	const isNew = !features;

	vm.isSaving = false;
	vm.group = group;
	vm.features = features || {data: {}};
	vm.data = GroupFeaturesDefault.defaultSettings;

	_merge(vm.data, vm.features.data);
	vm.data.name = vm.group.data.name;
	vm.data.section.groupId = groupId;

	vm.submit = submit;

	vm.validateFileSharingMimeType = function (mimeType) {
		if (!mimeType) {
			return false;
		}
		const testSplit = mimeType.split('/');
		const splitOk = testSplit.length === 2;
		const splitLengthOk = splitOk
			? testSplit[0].length > 1 && testSplit[1].length >= 1
			: false;
		const lowercaseOk = mimeType === mimeType.toLowerCase();
		return splitOk && splitLengthOk && lowercaseOk;
	};

	function submit() {
		const type = APIConfigurationSectionService.sectionMap.group;
		vm.isSaving = true;

		if (isNew) {
			APIConfigurationSectionService.create(vm.data, type, true)
				.then(function (res) {
					featuresList.push(res);
					vm.isSaving = false;
				})
				.catch(function () {
					vm.isSaving = false;
				});

		} else {
			vm.features.data = vm.data;
			vm.features.$save().then(function (response) {
				vm.isSaving = false;
			});
		}
	}

};

