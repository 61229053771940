import _find from 'lodash/find';

import PanelSettingsController from './PanelSettingsController';
import panelSettingsDefaults from './PanelSettingsDefaults';
import PanelSettingsEditTemplate from './PanelSettingsEditTemplate';

export default angular.module('vngageApp.account.panelSettings', [
	'ui.router'
])
	.controller('PanelSettingsController', PanelSettingsController)
	.constant('panelSettingsDefaults', panelSettingsDefaults)

	.constant('panelSettingsModuleConfig', {
		state: 'root.account.panelSettings',
		access: 'Engage/PanelSettings'
	})

	.config(($stateProvider, panelSettingsModuleConfig) => {
		'ngInject';

		$stateProvider.state(panelSettingsModuleConfig.state, {
			url: 'panelsettings/',
			controller: 'PanelSettingsController as vm',
			templateUrl: PanelSettingsEditTemplate,
			data: {
				access: panelSettingsModuleConfig.access,
				ncyBreadcrumbLabel: 'Panel settings'
			},
			resolve: {
				panelSettingsConfigurationSections: APIConfigurationSectionService => {
					'ngInject';
					return APIConfigurationSectionService.get(APIConfigurationSectionService.sectionMap.panelSettings);
				},
				panelSettingsConfigurationSectionsValidation: (APIConfigurationSectionService, ConfigValidationService, panelSettingsConfigurationSections) => {
					'ngInject';
					return ConfigValidationService.validateSectionType(panelSettingsConfigurationSections, APIConfigurationSectionService.sectionMap.panelSettings);
				},
				panelSettings: (APIConfigurationSectionService, panelSettingsConfigurationSections, panelSettingsDefaults) => {
					'ngInject';

					var panelSettings = _find(panelSettingsConfigurationSections, r => r.data.siteId === '00000000-0000-0000-0000-000000000000');
					if (panelSettings) {
						if (typeof panelSettings.data.section.poweredByLabel === 'undefined') {
							// Set default to the same value previously hardcoded in visitor's doT-file "draggablePanel.dot"
							panelSettings.data.section.poweredByLabel = panelSettingsDefaults.section.poweredByLabel;
						}
						return panelSettings;
					}
					return APIConfigurationSectionService.create(panelSettingsDefaults, APIConfigurationSectionService.sectionMap.panelSettings);
				}
			}
		});
	});
