import RoleAddModalController from './RoleAddModalController';
import RoleEditController from './RoleEditController';
import RoleListController from './RoleListController';
import RoleService from './RoleService';
import roleTranslations from './RoleTranslations';
import RoleListTemplate from './RoleListTemplate';
import RoleEditTemplate from './RoleEditTemplate';
import '../../../common/VngageWorkspaceTranslationModule';

export default angular.module('vngageApp.account.role', [
	'ui.router',
	'pascalprecht.translate',
	'vngageApp.workspaceTranslation'
])

	.controller('RoleAddModalController', RoleAddModalController)
	.controller('RoleEditController', RoleEditController)
	.controller('RoleListController', RoleListController)
	.factory('RoleService', RoleService)
	.constant('roleTranslations', roleTranslations)

	.constant('roleModuleConfig', {
		state: 'root.account.role',
		access: 'Account/Roles'
	})

	.config(function ($stateProvider, $translateProvider, roleTranslations, roleModuleConfig, VngageWorkspaceTranslationServiceProvider) {
		'ngInject';
		VngageWorkspaceTranslationServiceProvider.addModuleTranslations(roleTranslations);

		$stateProvider.state(roleModuleConfig.state, {
			url: 'role/',
			controller: 'RoleListController as vm',
			templateUrl: RoleListTemplate,
			data: {
				ncyBreadcrumbLabel: 'Roles',
				access: roleModuleConfig.access
			},
			resolve: {
				roleClaimList: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.roleClaim.query().$promise;
				}
			}
		});

		$stateProvider.state('root.account.role.edit', {
			url: ':id',
			controller: 'RoleEditController as vm',
			templateUrl: RoleEditTemplate,
			data: {
				ncyBreadcrumbLabel: 'Edit',
				access: {action: 'GET', resource: 'Account/Current/RoleClaim'}
			},
			params: {currentList: []},
			resolve: {
				roleClaim: function ($stateParams, roleClaimList, VngageUtil) {
					'ngInject';
					return VngageUtil.findResource(roleClaimList, $stateParams.id);
				},
				roleClaimListExceptThis: function (roleClaimList, $q, $stateParams) {
					'ngInject';
					return $q(function (resolve) {
						resolve(roleClaimList.filter(function (r) {
							return r.data.id !== $stateParams.id;
						}));
					});
				},
				userList: function (APIEndpointService) {
					'ngInject';
					return APIEndpointService.user.query({limit: 10000}).$promise.then(function (res) {
						// Only return non-delegated users
						return res.filter(function (user) {
							return user.data.type === 0;
						});
					});
				}
			}
		});
	});
