export default function () {
	return {
		getActionTypes: function () {
			return [
				'action.ACTION_TYPE_URL',
				'action.ACTION_TYPE_JAVSCRIPT',
				'action.ACTION_TYPE_VIDEO_PLAYER',
				'action.ACTION_TYPE_PDF',
				'action.ACTION_TYPE_IMAGE_OVERLAY',
				'action.ACTION_TYPE_FORM',
				'action.ACTION_TYPE_EMBED'
			];
		},
		formatSelectableActionPanels: function (actionPanels) {
			return actionPanels.map(function (actionPanel) {
				return {
					refValue: actionPanel.data.id,
					displayName: actionPanel.data.name
				};
			});
		},

		getSelectedPanelsForAction: function (actionPanels, actionId) {
			var selectedPanels = [];

			actionPanels.forEach(function (actionPanel) {
				if (actionPanel.data.section.actions && actionPanel.data.section.actions.indexOf(actionId) > -1) {
					selectedPanels.push(actionPanel.data.id);
				}
			});

			return selectedPanels;
		}
	};
}
