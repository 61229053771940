import immutable from 'immutable';
import {fakeLib} from '../libs/fakeLib';
import * as Records from '../../utils/Records';

export function newStateDebug(state, action) {
	state = immutable.fromJS(action.newState);
	return state;
}

export function removeAllFakePanels(state, action) {
	return fakeLib.removeAllFakePanels(state);
}

export function removeFakePanel(state, action) {
	const {conversationId} = action;
	return fakeLib.removeFakePanel(state, conversationId);
}

export function addFakePanel(state, action) {
	const {conversationId} = action;
	return fakeLib.addFakePanel(state, conversationId, action.conversationState);
}

export function addFakeMessage(state, action) {
	return fakeLib.addFakeMessage(state);
}

export function addFakeSystemMessage(state, action) {
	return fakeLib.addFakeSystemMessage(state);
}

export function toggleFakeTyping(state, action) {
	return fakeLib.toggleFakeTyping(state);
}

export function addFakeVisitorProfile(state, action) {
	const {conversationId} = action;
	return fakeLib.addFakeVisitorProfile(state, conversationId, action.whatToShow);
}

export function addFakeVisitor(state, action) {
	return fakeLib.addFakeVisitor(state);
}

export function addFakeReservation(state, action) {
	return fakeLib.addFakeReservation(state);
}

export function setFakeConversationStatus(state, action) {
	const {status, conversationId} = action;
	if (!state.hasIn(['conversations', conversationId])) {
		return state;
	}

	let conversation = state.getIn(['conversations', conversationId]);

	conversation = Records.Conversation.setStatus(conversation, status);
	state = state.setIn(['conversations', conversationId], conversation);

	return state;
}

export function addFakeConversation(state, action) {
	return fakeLib.addFakeConversation(state);
}

