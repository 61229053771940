import React, {useState, useEffect} from 'react';
import {Toast, Button} from 'react-bootstrap';
import ScrollText from '../common/ScrollText.react';

const PluginToast = ({name, notification, pluginId, setSelectedPlugin, selectedPlugin}) => {
	const [show, setShow] = useState(false);

	const isSelected = pluginId !== selectedPlugin;

	useEffect(() => {
		setShow(true);
	}, [notification, setShow]);

	const onClickOpen = () => {
		setShow(false);
		setSelectedPlugin(pluginId);
	};

	const onClose = () => {
		setShow(false);
	};

	return (
		<Toast className="plugin-toast" onClose={onClose} show={show} delay={10000} autohide>
			<Toast.Header className="toast-header">
				<ScrollText
					key={'scroll' + isSelected}
					text={name}
					cls="title-wrapper line-clamp line-clamp-1"
				/>
				{
					isSelected && (
						<Button
							variant="primary"
							size="xs"
							onClick={onClickOpen}
						>
							open
						</Button>
					)
				}
			</Toast.Header>
			<Toast.Body className="toast-body">
				{notification.get('text')}
			</Toast.Body>
		</Toast>
	);
};

export default PluginToast;