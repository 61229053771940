import WebAPIUtils from './utils/WebAPIUtils';
import Clipboard from 'clipboard';
import DesktopLegacyTranslations from './DesktopLegacyTranslations';
//import React from 'react';
//import ReactDOM from 'react-dom';
//import QueueApplication from './components/QueueApplication.react';
//import CSEndpointService from './utils/CSEndpointService';

export default function DesktopController($http, vngageConfig, Session, DesktopServiceLegacy, $scope, NotificationService) {
	'ngInject';

	var vm = this;
	var clippy = null;
	vm.coBrowserSecurity = DesktopServiceLegacy.configuration.coBrowserSecurity;
	vm.desktopConnection = DesktopServiceLegacy.connection;

	var ua = navigator.userAgent,
		//chrome = ua.match(/Chrome\/([\d.]+)/) || ua.match(/CriOS\/([\d.]+)/),
		//firefox = ua.match(/Firefox\/([\d.]+)/),
		ie = ua.match(/MSIE\s([\d.]+)/) || ua.match(/Trident\/[\d](?=[^\?]+).*rv:([0-9.].)/);

	vm.browser = {
		ie: ie
		//chrome: chrome,
		//firefox: firefox
	};

	clippy = new Clipboard('.copy-title');

	DesktopLegacyTranslations.setLanguage(Session.user.profile.language);
	var clippyConfirmMsg = DesktopLegacyTranslations.translate('clipboardConfirmTip');

	clippy.on('success', function(e){
		NotificationService.info({
			header: clippyConfirmMsg + ':',
			body: e.text
		}, {
			timeOut: 1000
		});
		e.clearSelection();
	});

	DesktopServiceLegacy.windowChecker.start();

	$scope.$on('$destroy', function() {
		clippy.destroy();
		DesktopServiceLegacy.windowChecker.stop();
		WebAPIUtils.dispose();
	});
}
