import Lang from '../utils/Lang'
import Message from './Message'
import AccountConfig from '../utils/AccountConfig';

const sendLocalizedNote = (conversationId, message) => async (dispatch, getState) => {
	const translated = Lang.getTranslation(message) || message;
	await dispatch(Message.sendNote(conversationId, translated));
};

const sendTransferToGroupNote = (conversationId, groupId) => async (dispatch, getState) => {
	const systemMsgPrefix = Lang.getTranslation('systemMsgPrefix');
	const systemMsgTransferredTo = Lang.getTranslation('systemMsgTransferredTo');
	const message = systemMsgPrefix + systemMsgTransferredTo + ' "' + AccountConfig.getGroupName(groupId) + '"';
	await dispatch(Message.sendNote(conversationId, message));
};

const sendDisconnectNote = (conversationId) => async (dispatch, getState) => {
	const systemMsgPrefix = Lang.getTranslation('systemMsgPrefix');
	const systemMsgDisconnectedByAgent = Lang.getTranslation('systemMsgDisconnectedByAgent');
	const message = systemMsgPrefix + systemMsgDisconnectedByAgent;
	await dispatch(Message.sendNote(conversationId, message));
};

const sendBlockedByAgentNote = (conversationId) => async (dispatch, getState) => {
	const systemMsgPrefix = Lang.getTranslation('systemMsgPrefix');
	const systemMsgVisitorBlockedByAgent = Lang.getTranslation('systemMsgVisitorBlockedByAgent');
	const message = systemMsgPrefix + systemMsgVisitorBlockedByAgent;
	await dispatch(Message.sendNote(conversationId, message));
};

const sendCloseCaseNote = (conversationId, closureObj) => async (dispatch, getState) => {
	const sysMsgCloseCase = Lang.getTranslation('sysMsgCloseCase');
	const message = `*${sysMsgCloseCase}* "${closureObj.name} (${closureObj.outcome})"`;
	await dispatch(Message.sendNote(conversationId, message));
};

const sendChangeCaseTypeNote = (conversationId, caseName) => async (dispatch, getState) => {
	const sysMsgChangedCase = Lang.getTranslation('sysMsgChangedCase');
	const message = `*${sysMsgChangedCase}* "${caseName}"`;
	await dispatch(Message.sendNote(conversationId, message));
};

export default {
	sendLocalizedNote,
	sendTransferToGroupNote,
	sendDisconnectNote,
	sendBlockedByAgentNote,
	sendCloseCaseNote,
	sendChangeCaseTypeNote,
}

