export default class PollRequest {
	constructor(reqGuid, restUrl) {
		this._guid = reqGuid;
		this.restUrl = restUrl;
	}

	get guid () {
		return this._guid;
	}

	resolve (data) {
		console.log('do resolve' , data);
	}

	reject (err) {

	}
}
