export const fileImportHeaders = [
	{
		name: 'name',
		required: true,
	},
	{
		name: 'description',
		required: false,
	},
	{
		name: 'textToVisitor',
		required: false,
	},
	{
		name: 'outcome',
		format: 'enum',
		values: ['neutral', 'positive', 'negative']
	},
];
