export default function (d3, crossfilter, dc, UserUsageDimensionService, $window, $translate) {
	'ngInject';

		return {
			restrict: 'A',
			require: '^ngModel',
			link: function ($scope, element, attrs) {

				var chart;

				$scope.$watch(attrs.ngModel, function (v) {
					if (!v) {
						return;
					}

					draw(v);
				});

				function draw(data) {

					var dimensions = UserUsageDimensionService.calculate(data);

					if (!chart) {

						chart = bar(dc.barChart(element[0]), dimensions, data);
						chart.render();
						$window.addEventListener('resize', onResize, false);

					} else {

						chart.redraw();
					}
				}

				function onResize () {
					chart.width(element[0].clientWidth);
					chart.rescale();
					chart.redraw();
				}

				function bar(barChart, dimension, data) {

					var domain = [
						d3.time.hour.offset(new Date(data[0].calculatedAt), -12),
						d3.time.hour.offset(new Date(data[data.length - 1].calculatedAt), 12)
					];

					var x = d3.time.scale()
						.domain(domain)
						.rangeRound([0, 300 - 40 - 10]);

					var width = element[0].clientWidth;

					barChart
						.width(width)
						.height(200)
						.margins({top: 10, right: 30, bottom: 30, left: 30})
						.dimension(dimension.by.when)
						.group(dimension.groupBy.numberOfUsers)
						.gap(1)
						.x(x)
						.elasticY(true)
						.centerBar(true)
						.colors('#1fb35f')
						.round(d3.time.day.round)
						.xUnits(d3.time.days)
						.renderHorizontalGridLines(true)
						.yAxisLabel($translate.instant('dashboard.userUsage.USERS'))
						.xAxisLabel($translate.instant('dashboard.userUsage.DATE'))
						.title(function (d) { return d.value; })
						.brushOn(false);

					barChart.on('postRender', function () {

						var g = barChart.svg()
							.attr('class', 'svg-content')
							.append('g');
					});

					barChart.xAxis()
						.ticks(d3.time.days, 2)
						.tickFormat(d3.time.format('%_d'));

					barChart.yAxis()
						.tickFormat(d3.format('d'));

					return barChart;
				}
				$scope.$on('$destroy', function(){
					$window.removeEventListener('resize', onResize, false);
				});
			}
		};
	}
