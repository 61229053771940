import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import ParticipantStore from './../stores/ParticipantStore';
import ParticipantWriting from './../../../components/ParticipantsWriting.react';

function getStatesFromStores(conversationId) {
	return {
		writings : ParticipantStore.getWritingParticipantsOfConversation(conversationId)
	};
}

export default createReactClass({

	getInitialState () {
		return getStatesFromStores(this.props.conversationId);
	},

	componentDidMount () {
		ParticipantStore.addChangeListener(this._onChange);
	},

	componentWillUnmount () {
		ParticipantStore.removeChangeListener(this._onChange);
	},

	_onChange () {
		this.setState(getStatesFromStores(this.props.conversationId));
	},

	shouldComponentUpdate (nextProps, nextState) {
		return nextState.writings.length !== this.state.writings.length;
	},

	render () {
		return (
			<div className="dialog-tools">
				<span className="participants-writing">
				{this.state.writings.map( t => {
					let participant = ParticipantStore.getParticipantOfConversationById(t.conversationId, t.pid).info;
					return (
						<ParticipantWriting key={t.pid} name={participant.name} title={participant.titleId} />
					)
				})}
				</span>
			</div>
		)
	}
});
