import moment from 'moment-timezone';
import _map from 'lodash/map';
export default function (SessionService, vngageConfig) {
	'ngInject';

	const rootPath = vngageConfig.restUrl + 'Statistics/Export';

	const fileNames = [
		{entityType:'UserCaseUsagePerHour', granularity:'hour'},
		{entityType:'UserCaseUsagePerHourPerSitePerGroup', granularity:'hour'},
		{entityType:'ConversionFunnelSitePerCaseTypePerHour', granularity:'hour'},
		{entityType:'ConversionFunnelSitePerCaseTypePerOpportunityPerHour', granularity:'hour'},
		{entityType:'ConversionFunnelSitePerCaseTypePerOpportunityPerSolutionPerHour', granularity:'hour'},
		{entityType:'ConversionFunnelSitePerGroupPerHour', granularity:'hour'},
		{entityType:'ConversionFunnelSitePerHour', granularity:'hour'},
		{entityType:'ConversionFunnelSitePerOpportunityPerHour', granularity:'hour'},
		{entityType:'ConversionFunnelSitePerOpportunityPerSolutionPerHour', granularity:'hour'},
		{entityType:'ConversionFunnelSitePerSolutionPerHour', granularity:'hour'},
		// {entityType:'ConversionFunnelSitePerCaseTypePerDay', granularity:'day'},
		// {entityType:'ConversionFunnelSitePerCaseTypePerOpportunityPerDay', granularity:'day'},
		// {entityType:'ConversionFunnelSitePerCaseTypePerOpportunityPerSolutionPerDay', granularity:'day'},
		// {entityType:'ConversionFunnelSitePerGroupPerDay', granularity:'day'},
		// {entityType:'ConversionFunnelSitePerDay', granularity:'day'},
		// {entityType:'ConversionFunnelSitePerOpportunityPerDay', granularity:'day'},
		// {entityType:'ConversionFunnelSitePerOpportunityPerSolutionPerDay', granularity:'day'},
		// {entityType:'ConversionFunnelSitePerSolutionPerDay', granularity:'day'}
	];

	const entityTypes = [
		'Tracking',
		'Case',
		'ConversationMessage',
		'Navigation',
		'Opportunity',
		'PointOfInterest',
		'Queue',
		'Solution',
		'UserActivity',
		'VisitorIdentification',
		'VisitStart',
		'ConversionFunnel',
		'ConversionFunnelPerDay',
		'UserCaseUsagePerHour',
		'UserCaseUsagePerHourPerSitePerGroup',
		'ConversionFunnelSitePerCaseTypePerDay',
		'ConversionFunnelSitePerCaseTypePerHour',
		'ConversionFunnelSitePerCaseTypePerOpportunityPerDay',
		'ConversionFunnelSitePerCaseTypePerOpportunityPerHour',
		'ConversionFunnelSitePerCaseTypePerOpportunityPerSolutionPerDay',
		'ConversionFunnelSitePerCaseTypePerOpportunityPerSolutionPerHour',
		'ConversionFunnelSitePerGroupPerDay',
		'ConversionFunnelSitePerGroupPerHour',
		'ConversionFunnelSitePerDay',
		'ConversionFunnelSitePerHour',
		'ConversionFunnelSitePerOpportunityPerDay',
		'ConversionFunnelSitePerOpportunityPerHour',
		'ConversionFunnelSitePerOpportunityPerSolutionPerDay',
		'ConversionFunnelSitePerOpportunityPerSolutionPerHour',
		'ConversionFunnelSitePerSolutionPerDay',
		'ConversionFunnelSitePerSolutionPerHour'
	];

	function getQueryString(commonParams, fileParams){
		let params = Object.assign({}, commonParams, fileParams);
		return _map(params, function (value, key) {
			return key + '=' + value;
		}).join('&');
	}

	//var userOffset = SessionService.getUserTimezoneOffset();

	function getFileNames (queryParams) {
		const params = {
			from: moment(queryParams.from).format('YYYY-MM-DD'),
			to: moment(queryParams.to).add(1, 'days').format('YYYY-MM-DD'),
			//from: moment().utcOffset(userOffset).startOf('day').utc().toISOString(),
			//to: moment().utcOffset(userOffset).endOf('day').utc().toISOString(),
			type: queryParams.type,
			extension: queryParams.extension,
			granularity: queryParams.granularity,
			SessionId: SessionService.getSession().sessionId
		};

		return fileNames.map(function(file){
			return {
				url: rootPath + '?' + getQueryString(params, file),
				name: `${file.entityType}.${file.extension || params.extension}`
			};
		});
	}

	return {
		getFilenames: getFileNames,
		getEntityTypes () {
			return entityTypes;
		}
	};
}
