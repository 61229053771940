import BannerPreviewTemplate from './BannerPreviewTemplate';

export default function () {
	return {
		restrict: 'AE',
		replace: true,
		scope: {
			banner: '=',
			theme: '=',
			wcag: '='
		},
		bindToController: true,
		controller: 'BannerPreviewController as vm',
		templateUrl: BannerPreviewTemplate
	};
}
