export default function ($interval, $window, vngageConfig) {
	'ngInject';

    return {
        replace: true,
        restrict: 'A',
        link: function($scope, element) {

            element[0].onload = function() {
                $scope.loaded = true;
            };

            var isFrameReadyInterval;
			var isFrameReadyIntervalPreview;
			var origin;

			if (vngageConfig.staticWorkspaceUrl) {
				//vngageConfig.staticWorkspaceUrl + 'visitor/ui/index.html';
				origin = vngageConfig.staticWorkspaceUrl.match(/(https?:\/\/[^\/]*)/gi);
				if (origin) {
					origin = origin[0];
				}
			} else {
				//rolling our own origin, since $window.location.origin is IE11+
				origin = $window.location.protocol + '//' + $window.location.host;
			}

            $scope.$watch('loaded', function (value) {
                if (value) {
                    $interval.cancel(isFrameReadyInterval);
					$interval.cancel(isFrameReadyIntervalPreview);
                }
            });

            $scope.showFrame = function() {
                return $scope.rendered;
            };

            $scope.$on('renderTheme', function(e, conf) {
                if ($scope.loaded) {
                    render(conf);
                } else {

                    isFrameReadyInterval = $interval(function() {

                        if ($scope.loaded) {
                            render(conf);
                        }

                    }, 100);

                }
            });

            $scope.$on('updatePreview', function(e, conf) {

				isFrameReadyIntervalPreview = $interval(function() {

					if ($scope.loaded) {
						$interval.cancel(isFrameReadyIntervalPreview);
						render(conf);
					}

				}, 500);
			});

            function render(conf) {
                var receiver = element[0].contentWindow;
                if (receiver) {
					receiver.postMessage(conf, origin);
				}

                $scope.rendered = true;
                $scope.$emit('themeRendered');
            }
        }
    };
};
