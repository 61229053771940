import immutable from 'immutable';
import helpers from '../../utils/plugin/PluginSubscriptionHelpers';

const subscriptions = {
	conversationCoBrowsing(plugin, prevState, state) {
		const oldCoBroConversationId = prevState.getIn(['coBro', 'conversationId']);
		const coBroConversationId = state.getIn(['coBro', 'conversationId']);
		const updated = oldCoBroConversationId !== coBroConversationId;
		return updated
			? immutable.fromJS([{
				type: 'conversationCoBrowsing',
				conversationId: coBroConversationId
			}])
			: immutable.List();

	},

	pluginStatus(plugin, prevState, state) {
		// pluginStatus is a string open/closed
		const id = plugin.get('id');

		const prevId = prevState.get('selectedPlugin');
		const currentId = state.get('selectedPlugin');

		return prevId === id && currentId !== id
			? immutable.fromJS([{
				type: 'pluginStatus',
				status: 'closed'
			}])
			: prevId !== id && currentId === id
				? immutable.fromJS([{
					type: 'pluginStatus',
					status: 'open'
				}])
				: immutable.List(); // no change

	},

	currentConversationIds(plugin, prevState, state, reset) {
		const prevMyDetailsCounter = prevState.getIn(['pluginData', 'myDetailsCounter']);
		const currentMyDetailsCounter = state.getIn(['pluginData', 'myDetailsCounter']);
		const firstDetails = prevMyDetailsCounter === 0 && currentMyDetailsCounter === 1;
		const alwaysSend = (reset && currentMyDetailsCounter > 0) || firstDetails;

		const prevConversations = helpers.getConversationIds(plugin, prevState);
		const currentConversations = helpers.getConversationIds(plugin, state);

		const sameSize = currentConversations.size === prevConversations.size;
		const sameConversationIds = currentConversations.every(conversationId => prevConversations.contains(conversationId));

		return sameSize && sameConversationIds && !alwaysSend
			? immutable.List()
			: immutable.fromJS([{
				type: 'currentConversationIds',
				ids: currentConversations
			}]);
	},
};

export default {
	subscriptions
};
