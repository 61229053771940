import LogActions from '../../actions/LogActions';
import {isActionValid} from './PluginActionValidation'
import DesktopPluginAPI from '../../pluginAPI/DesktopPluginAPI'

export const executePluginAction = (trans, incomingData, props) => {

	return (dispatch) => {
		const validStr = isActionValid(trans, incomingData, props);


		const pluginId = props.plugin.get('id');
		const pluginName = props.plugin.get('name');
		if (validStr === 'ok') {
			const {action, ...data} = incomingData;
			const actionToExecute = DesktopPluginAPI.getAction(props.plugin.get('actualApiVersion'), action);
			dispatch(LogActions.pluginAction(pluginName, pluginId, action, data));
			dispatch(actionToExecute({...data, pluginId}))
		} else {
			dispatch(LogActions.pluginError(pluginName, pluginId, validStr, incomingData, trans.origin));
		}
	}
};


