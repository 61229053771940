import {getItemNavigationDataAfterRemove} from '../../../common/UiHelpers';

export default function (template, $state, $scope, vngageRemoveModalService, NotificationService, $stateParams) {
	'ngInject';

	const vm = this;
	vm.isSaving = false;
	vm.template = template;
	vm.save = save;
	vm.cancel = cancel;
	vm.remove = remove;
	vm.currentList = $stateParams.currentList || [];

	vm.template.data.section.script = vm.template.data.section.script || '';

	vm.originalConfig = {
		name: angular.copy(vm.template.data.name),
		script: angular.copy(vm.template.data.section.script)
	};

	function save() {
		vm.isSaving = true;

		vm.template.$save().then(response => {
			vm.originalConfig.name = response.data.name;
			vm.templateEditForm.$setPristine();
			vm.isSaving = false;
		}).catch(error => {
			// Error saving template
			console.error('Error saving template:', error);
			NotificationService.error({
				header: 'Error',
				body: 'Error saving template'
			}, {timeOut: 10000});
			vm.templateEditForm.$setDirty();
			vm.isSaving = false;
		});
	}

	function remove() {
		vm.isSaving = true;
		vngageRemoveModalService.open({
			itemToRemove: vm.template,
			itemType: 'renderTemplate'
		}).then(function () {
			const itemNavigationData = getItemNavigationDataAfterRemove(vm.template, 'root.configure.renderTemplate.edit', vm.currentList);
			vm.currentList = itemNavigationData.params ? itemNavigationData.params.currentList: [];
			$state.go(itemNavigationData.route, itemNavigationData.params, {reload: true});
		}).catch(function () {
			vm.isSaving = false;
		});
	}

	function cancel() {
		$state.go('^');
	}

	$scope.$on('resetResettables', () => {
		vm.templateEditForm.$setPristine();

		// Undo any config changes when leaving dirty view
		vm.template.data.name = angular.copy(vm.originalConfig.name);
		vm.template.data.section.script = angular.copy(vm.originalConfig.script);
	});
}
