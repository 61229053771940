let activePolls = [];
let maxActivePolls = 4;
let netIdDiffLimit = 10;

export default {

	getMaxActive() {
		return maxActivePolls;
	},

	setMaxActive(max) {
		maxActivePolls = max;
	},

	// id is sent in but not used at the moment.
	getPollStats(latestNetId) {
		return {
			latestNetId,
			activePolls,
			maxActivePolls,
			netIdDiffLimit,
		}

	},

	getActivePolls() {
		return activePolls;
	},

	reset() {
		activePolls = [];
	},

	add(id) {
		activePolls = this.isOkToAdd(id)
			? activePolls.concat([id])
			: this.hasWaitedLong(id)
				? [id]
				: activePolls;
	},

	remove(id) {
		activePolls = this.isValid(id)
			? []
			: activePolls.filter(pollId => pollId !== id);
	},

	isValid(id) {
		return activePolls.indexOf(id) === activePolls.length - 1 && activePolls.length > 0;
	},

	// if netId diff is to big it has waited long
	hasWaitedLong(id = 0) {
		return id >= (activePolls[activePolls.length - 1] + netIdDiffLimit);
	},

	// max capacity is not reached
	isOkToAdd(id = 0) {
		return activePolls.length < maxActivePolls;
	},

	okToPoll(id = 0) {
		return this.isOkToAdd(id) || this.hasWaitedLong(id);
	},
};

